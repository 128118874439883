import { Grid, Typography } from '@material-ui/core';
import React, { CSSProperties } from 'react'
import CheckIcon from '@material-ui/icons/CheckCircle';
import CrossIcon from '@material-ui/icons/CancelRounded';

export const TestFixStyles: {[key:string]: CSSProperties} = {
    iconCell: {
        display: "flex",
       justifyContent: 'space-between'
    },

    deviceCellIcon: {
        fontSize: "16px"
    },
    issueAndAutofixIcon: {
         marginTop:'3px',
        marginRight: "11px",
        marginLeft: "8px",
        fontSize: "16px"
    }
};

const IssueAndAutoFixComponent = (props: { name: string, count: number, icon: any, type: "autoFix" | "issues",reportType:string }) => {
    const { icon, name, count, type,reportType } = props;
    return <Grid container item justify='space-between' style={{marginTop:reportType === "mini" ? '5%':'0px'}}>
        <Grid item>
            {icon}
        </Grid>
        <Grid item style={{flexGrow: 1,paddingBottom:'5%'}}>
        <Grid container direction="column">
            <Grid item>
                <Typography variant="body1" style={{ fontWeight: 500,textAlign: 'left',marginLeft:reportType === "mini" ? '3px':'0px' }}>{name}</Typography>
            </Grid>
      </Grid>
        </Grid>


        {type === "issues" ? 
        <Grid item>
            <span style={{display: "flex",marginLeft:'0%'}}>
                <CrossIcon style={{ ...TestFixStyles.issueAndAutofixIcon, color: count > 0 ? "#077187" : "lightgray" }} /> {count} 
                </span>
                </Grid> : ''}
        {type === "autoFix" ? 
        <Grid item>
            <span style={{display: "flex",marginLeft:'0%'}}>
                <CheckIcon style={{ ...TestFixStyles.issueAndAutofixIcon, color: count > 0 ? "#8bc53f" : "lightgray" }} /> {count}
            </span>
        </Grid> : ''}


    </Grid>
}

export default IssueAndAutoFixComponent;