export interface IJobRename {
    id: number;
    newJobName: string;
}
export class JobRename implements IJobRename {
    id: number;
    newJobName: string;
    constructor(init?: IJobRename) {
       Object.assign(this, init); //this will initialize our object of class JobActions
                                 // with the above values
   }
}