import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/root.store';
import { TextField, FormControl, makeStyles, withStyles, Switch, Typography, Paper, Divider, InputAdornment, Icon } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DateFnsUtils from '@date-io/date-fns';
import { useFormik } from 'formik';
import { EDRTextField } from '../../app/common/EDRTextField';
import { DashboardSettingSchema } from './validation';

const SettingsDashboardComponent: React.FC<{onChange: (val) => void}> = (props) => {
    const rootStore = useContext(RootStoreContext); 
    const { updateSettingObj, dashboardSettings, settingsLoaded} = rootStore.settingStore;
    const [t] = useTranslation("settings");
    const [rtoState, setRtoState] = useState("MAX");
    const [graphState, setgraphState] = useState("Single");
    const [showNextAuditstate, setshowNextAuditstate] = useState(false);
    const [DateState, setDateState] = useState("Jan 01, 2020");
    //const [numberOfIOPState, setnumberOfIOPState] = useState("30000");  
    let initialValuesList = {iopsnum:0};  

    const populateFields = () => {
        if (dashboardSettings!=null){ 
            Object.keys(dashboardSettings).forEach(key => {
                switch(dashboardSettings[key].settingKey.toLowerCase()){
                    case "setiops": 
                        //let iopsNum= (JSON.parse(rootStore.settingStore.dashboardSettings[key].settingValue));  
                        //setnumberOfIOPState(iopsNum);
                        initialValuesList.iopsnum = isNaN(dashboardSettings[key].settingValue)? 1 :parseInt(dashboardSettings[key].settingValue);
                        break;
                    case "nextauditdate":
                        let auditDate = (dashboardSettings[key].settingValue);
                        setDateState(auditDate);                        
                        break;
                    case "rto":
                        let rtoMode = (dashboardSettings[key].settingValue); 
                        setRtoState(rtoMode);                        
                        break;
                    case "shownextauditdate":
                        let showauditVal = (dashboardSettings[key].settingValue === 'true');  
                        setshowNextAuditstate(showauditVal);              
                        break;
                    case "graph":
                        let graphMode = (dashboardSettings[key].settingValue);  
                        setgraphState(graphMode);              
                        break;
                    default:
                        break;
                }
            })    
         }
         form.setValues(initialValuesList, true);
     }

     const form = useFormik({
        initialValues: initialValuesList,
        validationSchema:DashboardSettingSchema,
        validateOnMount: false,
        onSubmit: () => { },
      })

      useEffect(() => {
        if(form.dirty) {
            updateSettingObj(JSON.stringify(form.values.iopsnum), "SetIOPS", "Dashboard")
        }
        props.onChange && props.onChange(form.values);
    },[form.values])
    
      useEffect(() => {
        if (settingsLoaded) 
            populateFields()
    }, [settingsLoaded]); 

    const useStyles = makeStyles({
        dashboardDetails: {
            display: "flex",
            flexDirection: "column",
            color: "#4d5f75",
            '& > div': {
              padding: 5,
              display: "flex",
              justifyContent: "space-between",
              marginRight: 20,
              marginLeft: 10
            }
        },
        label: {
            textTransform: 'capitalize',
        },
          formControl: {
            width: '41%',
            '& label': {
                background: "white",
                width: "auto",
                padding: "0 4px 0 4px",
                fontSize: '17px'
            }
        }
    })

    const DateTextField = withStyles({
        root: {
            '& .MuiInput-underline:hover': {
                borderWidth: "1"
            },
            '& .MuiInput-underline:hover:before': {
                borderWidth: "1"
            },
            '& .MuiInput-underline:after': {
                borderWidth: "1"
            },
            '& .MuiInput-underline:before': {
                borderWidth: "1"
            },
            background: "white",
            borderRadius: 28,
            overflow: "hidden"
        },
    })(TextField);

    const BlueSwitch = withStyles({
        switchBase: {
          color: blue[300],
          "&$checked": {
            color: blue[500],
          },
          "&$checked + $track": {
            backgroundColor: blue[500],
          },
        },
        checked: {},
        track: {},
      })(Switch);
     
    const classes = useStyles();

    const onToggleRTOChange = (event, value) => {
        setRtoState(event.currentTarget.value);
        updateSettingObj(event.currentTarget.value,"RTO","Dashboard")
    };

    const onToggleGraphChange = (event, value) => {
        setgraphState(event.currentTarget.value);
        updateSettingObj(event.currentTarget.value,"Graph","Dashboard")
    };

    const onDateChange = (date: Date) => {
        setDateState(date.toLocaleDateString());
        updateSettingObj(moment(date).format('MMM DD, YYYY'),"NextAuditDate","Dashboard")
    };

    function DateFormatter (date: Date, invalidLabel: string) {
        if(!date)
            return invalidLabel;
        return moment(date).format('MMM DD, YYYY')
    }

    return (
        <div data-test='settings-dashboard-component'>
            <Paper>
                <div className={classes.dashboardDetails}>
                    <div style={{marginBottom: "1em", marginTop: "1em"}}>
                        <Typography classes={{ root: "settingsText"}}>{t("rto")}</Typography>
                        <ToggleButtonGroup 
                            classes={{ root: "settings_toggle_button_group" }} 
                            style={{width:"112px"}}
                            value={rtoState}
                            onChange={onToggleRTOChange}
                            >
                                <ToggleButton 
                                    classes={{ root: "settings_toggle_button", selected: "settings_toggle_button_selected", label: classes.label }} 
                                    style={{width:"56px"}}
                                    value="MAX">
                                        {t("max")}
                                    </ToggleButton>
                                <ToggleButton 
                                    classes={{ root: "settings_toggle_button", selected: "settings_toggle_button_selected", label: classes.label }} 
                                    style={{width:"56px"}}
                                    value="AVG">
                                        {t("avg")}
                                    </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <Divider />
                    <div style={{marginBottom: "1em", marginTop: "1em"}}>
                        <Typography classes={{ root: "settingsText"}}>{t("graph")}</Typography>
                        <ToggleButtonGroup 
                            classes={{ root: "settings_toggle_button_group" }} 
                            value={graphState}
                            onChange={onToggleGraphChange}
                            >
                                <ToggleButton 
                                    classes={{ root: "settings_toggle_button", selected: "settings_toggle_button_selected", label: classes.label }} 
                                    value="Single">
                                        {t("single")}
                                    </ToggleButton>
                                <ToggleButton 
                                    classes={{ root: "settings_toggle_button", selected: "settings_toggle_button_selected", label: classes.label }} 
                                    value={"AVG"}>
                                        {t("avg")}
                                    </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <Divider />
                    <div>
                        <Typography classes={{ root: "settingsText"}}>{t("showNextAudit")}</Typography>
                        <BlueSwitch
                            checked={showNextAuditstate}
                            onChange={(event) => {setshowNextAuditstate(event.target.checked);updateSettingObj(JSON.stringify(event.target.checked),"ShowNextAuditDate","Dashboard")}}
                            color="default"
                            name="autoPlayCheck"
                        />
                    </div>
                    <div>
                        <span style={{width: "160px"}}><Typography classes={{ root: "settingsText"}}>{t("auditDate")}</Typography></span>
                        <div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                                disableToolbar
                                autoOk
                                variant="dialog"
                                inputVariant="outlined"
                                size="small"
                                style={{width: "302px",height: "52px",borderRadius: "4px",backgroundColor: "#ffffff",paddingBottom: "1em"}}
                                format="MMM DD, YYYY"
                                placeholder="Start Date"
                                minDate={'Jan 01, 2020'}
                                maxDate={'Jan 01, 2030'}
                                value={DateState}
                                labelFunc={DateFormatter}
                                onChange={onDateChange}
                                TextFieldComponent={DateTextField}
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position={'end'}>
                                      <Icon>
                                        <DateRangeIcon />
                                      </Icon>
                                    </InputAdornment>)
                                }}
                        />
                        </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <Divider />
                    <div>                
                        <Typography classes={{ root: "settingsText"}}>{t("setIOPS")}</Typography>
                        <FormControl className={classes.formControl} variant='outlined'>
                        <EDRTextField
                            name="iopsnum"
                            label={t("iopsLabel")}
                            placeholder="Type Here"
                            type="number"
                            value={form.values.iopsnum}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={Boolean( form.errors.iopsnum)}
                            helperText={ t(form.errors.iopsnum)}
                            margin="normal"
                            size= "small"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            fullWidth
                            variant="outlined">
                        </EDRTextField>
                        </FormControl>
                        {/* <TextField id="setIOPS" 
                                value={numberOfIOPState} 
                                style={{width:'302px', height: '32px' , borderRadius: '4px', backgroundColor: '#ffffff' , marginTop:'1em', marginBottom: '1em'}}
                                variant="outlined"
                                size="small"
                                label={t("iopsLabel")}
                                onChange={(event) => {setnumberOfIOPState(event.target.value);updateSettingObj(event.target.value,"SetIOPS","Dashboard")}}
                        />  */}
                    </div>
                </div>
            </Paper>
        </div>
    );
}

export default (observer(SettingsDashboardComponent));