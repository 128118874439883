import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, makeStyles, Switch, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { EDRTextField } from '../../../app/common/EDRTextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import jobAgent from '../../../app/api/jobAgent';
import { toast } from 'react-toastify';
//import { exception } from 'console';
import { runInAction } from 'mobx';
import { RootStoreContext } from '../../../app/stores/root.store';
import jobSettingsStore from '../../../app/stores/jobSettings.store';
import { DATA_MOVER_CONFIGURATION_NAME } from '../../../app/models/BaseSettings.enum';
import CheckCircle from '@material-ui/icons/CheckCircle';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import { EDROutlinedInput } from '../../../app/common/EDROutlinedInput';
import ReactDOM from 'react-dom';

const useStyles = makeStyles({
    formControl: {
        width: '100%',
        height:'70px',
        '& label': {
            background: "white",
            width: "auto",
            padding:"0 4px 0 4px",
            fontSize: '17px',
          }
    },
    validateIcon: {
        position: 'absolute',
        top: 15,
        right:8,
        color: '#8bc53f'
      }
    });   

const LicenseCreateComponent : React.FC = () => {
    const classes = useStyles();
    const [t] = useTranslation("createJobBase");
    const rootStore = useContext(RootStoreContext); 
    const {jobSettingsStore,createJobStore,displayStore} =rootStore;
    const [licenseVisibility,setLicenseVisibility] = useState(true);
    const [PassAutocomplete,setPassAutocomplete] = useState(false);
    let labelRef;

      const validation = yup.object({
        licenseKey: yup.string(),
        licenseName: yup.string()
        .max(30, 'up to 30 characters'),
    })

      const form = useFormik({
        initialValues:
        {
            licenseKey:'',
            licenseName:'',
        },
        validationSchema: validation,
        onSubmit: () => { },
    })
    
    useEffect(() => {
        let initialValues;
        initialValues =
        {
            licenseKey:'',
            licenseName:window.location.href.includes('firstRun=1') ? "License_"+ DATA_MOVER_CONFIGURATION_NAME[createJobStore.baseSettings.dataMover] +"_1":'',
        };
   
    form.setValues(initialValues, true);
    }, [])

    useEffect(() => {
        form.setFieldValue("licenseName",window.location.href.includes('firstRun=1') ? "License_"+ DATA_MOVER_CONFIGURATION_NAME[createJobStore.baseSettings.dataMover] +"_1":'');
    }, [createJobStore.baseSettings.dataMover])

    const LicenseNamehandleChange =(event:any)=>{
        form.setFieldValue("licenseName",event.target.value);
    }

    const LicenseKeyhandleChange =(event:any)=>{
        setPassAutocomplete(true);
        setLicenseVisibility(true);
        form.setFieldValue("licenseKey",event.target.value);
    }

    const handleBlur =()=>
    {
        form.setFieldTouched('licenseName',true);
        if(form.values.licenseKey !='' && form.values.licenseName !='')
        {
            runInAction(() => {
                jobAgent.JobActions.addLicense({key:form.values.licenseKey,name:form.values.licenseName}).then(res=>{
                    jobSettingsStore.getLicenese();
                    jobSettingsStore.updateSelectedLicense(res);
                    rootStore.createJobStore.updateBaseSettings({licenseId: res.id,licenseName:res.name});
                    rootStore.displayStore.updateScreenSettings({addedLicense:true});
                })
                .catch(err=>{
                    toast.error(t('wrongLicense'));
                    displayStore.updateScreenSettings({LicenseApplyProcessing:false});
                })
            })
        }
    }
    
    
    return(
    <Grid container item direction="row" style={{marginTop:'8px'}} spacing={2}>
        <Grid item xs={6}>
    <FormControl className={classes.formControl} variant='outlined'>
            <EDRTextField id="licenseName"
            name="licenseName"
            label={t('licenseName')}
            aria-autocomplete="none"
            disabled={true}
            value={form.values.licenseName}
            onChange={form.handleChange}
            onBlur={handleBlur}
            error={Boolean(form.touched.licenseName && form.errors.licenseName)}
            helperText={form.touched.licenseName && t(form.errors.licenseName)}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined">
          </EDRTextField>
          <React.Fragment>{form.values.licenseName && displayStore.screenSettings.addedLicense && <CheckCircle className={classes.validateIcon} />}</React.Fragment>
          </FormControl>
      </Grid>
        <Grid item xs={6}>
        <FormControl className={classes.formControl} variant='outlined'>
        <InputLabel disabled={true} ref={ref => {labelRef = ReactDOM.findDOMNode(ref)}} style={{transform: 'translate(14px, -5px) scale(0.75)'}}>{t('licenseKey')}</InputLabel>
        <EDROutlinedInput id="licenseKey" 
                          name="licenseKey"
                          value={form.values.licenseKey}
                          labelWidth={labelRef ? labelRef.offsetWidth:0}
                          autoComplete="off"
                          type={licenseVisibility ? 'password' : 'text'}
                          disabled={true}
                          style={{backgroundColor:"rgb(233, 235, 238)"}}
                          placeholder={t('pasteLicenseHere')}
                          aria-autocomplete="none"
                          onChange={LicenseKeyhandleChange}
                          onBlur={handleBlur}
                          endAdornment={ form.values.licenseKey  && PassAutocomplete?
                          <InputAdornment position="end">
                          <IconButton
                          onClick={()=> setLicenseVisibility(licenseVisibility? false: true)}
                          >
                          {licenseVisibility ?  <VisibilityOff />: <Visibility />}
                      </IconButton>
              </InputAdornment>
                :null}/>  
            
          <React.Fragment>{form.values.licenseKey && displayStore.screenSettings.addedLicense && <CheckCircle className={classes.validateIcon} />}</React.Fragment>
          </FormControl>
      </Grid> 
</Grid>);
};

export default observer(LicenseCreateComponent);