import { FormControl, Grid, InputAdornment, InputLabel, makeStyles, OutlinedInput, Typography,IconButton, DialogContent, Dialog, Divider, Select, MenuItem, Button, Tooltip, Switch,CircularProgress } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Autocomplete } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { EDRAutocomplete } from "../../../app/common/EDRAutocomplete";
import { EDRTextField } from "../../../app/common/EDRTextField";
import { SaveButton } from "../../../app/common/saveButton";
import { DATABASE_TYPE_NAME, OS_TYPE_NAME } from "../../../app/models/BaseSettings.enum";
import { CONSTS, DeviceTest } from "../../../app/models/createjob/createJob.model";
import { JobDevice } from "../../../app/models/jobDevice/jobDevice.model";
import { DatabaseType, DevicePlatform } from "../../../app/models/jobProperties.model";
import { TestType } from "../../../app/models/jobTest.model";
import { RootStoreContext } from "../../../app/stores/root.store";
import { AuthProps } from "./NetworkTestDialog.component";
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import HelpIcon from '@material-ui/icons/Help';
import jobAgent from "../../../app/api/jobAgent";
import { EDROutlinedInput } from "../../../app/common/EDROutlinedInput";

const AuthTestDialog: React.FC<AuthProps> = (props) => {
    const useStyles = makeStyles({
        formControl: {
            width: '100%',
            '& label': {
                background: "white",
                width: "auto",
                padding:"0 4px 0 4px",
                fontSize: '17px',
              }
        },
        helpIcon:
        {
          color: '#1976d2',
          fontSize: "large"
        },
        divider: {
            backgroundColor: '#eaeef4',
            width: '290%',
            margin: '0',
            marginTop: '10px',
            marginLeft: '10px',
    },
        SecondHeading:{
            color:'#1f396d',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1
        },
        centerVertical: {
            display: 'flex',
            alignItems: 'center',
            justifyContent:'space-between'
        },
        toggleItem: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
    
            '& .MuiListItemText-root' : {
                marginLeft: 10
            },
            '& .MuiSvgIcon-root': {
                color: '#8092a9'
            }
        }
        });   
    let labelRef;
    const classes = useStyles();
    const rootStore = useContext(RootStoreContext); 
    const { onApply,discard,closeDrawer,reloadUser,reloadPass,setParentUsernameValue,setParentPasswordValue} = props;
    const {createJobStore,jobSettingsStore} = useContext(RootStoreContext);
    const device= createJobStore.editedDevice;
    const AuthTest = device.tests.find(test=> test.testType == TestType.authentication);
    const [cancel,setCancel]=useState(false);
    const [UserPassAutocomplete,setUserPassAutocomplete] = useState(false);
    const [showEyePassword, setShowEyePassword]= useState(true);
    const [passwordVisibility,setPasswordVisibility] = useState(true);
    const [processingTest,setProcessingTest] = useState(false);
    const [t] = useTranslation("createJobDevices");
    const [Info] = useTranslation('createJobBase');
    
const validationSchema = yup.object({
    username: yup.string().required('username is  a required field'),
    password: yup.string().required('password is  a required field')
})

const getAuthUsername = (platform:DevicePlatform) => {
    if(platform === DevicePlatform.Windows)
    {
        if(jobSettingsStore.selectedPrimaryUser != null && jobSettingsStore.selectedPrimaryUser != undefined && jobSettingsStore.selectedPrimaryUser.userName != '')
            return jobSettingsStore.selectedPrimaryUser.userName;
        else if(createJobStore.baseSettings.workflowTest.PrimaryUser != '')
            return createJobStore.baseSettings.workflowTest.PrimaryUser;
        return '';
    }
    else if(platform === DevicePlatform.UNIX)
    {
        if(jobSettingsStore.selectedSecondaryUser != null && jobSettingsStore.selectedSecondaryUser != undefined && jobSettingsStore.selectedSecondaryUser.userName != '')
            return jobSettingsStore.selectedSecondaryUser.userName;
        else if(createJobStore.baseSettings.workflowTest.SecondaryUser != '')
            return createJobStore.baseSettings.workflowTest.SecondaryUser;
        return '';
        
    }
}

const defaultSettings = {
    PreviousCredentials: jobSettingsStore.accessCredentials.concat(jobSettingsStore.linuxCredentails),
    AuthUsername: getAuthUsername(device.os) ,
    AuthPassword: CONSTS.MaskedPassword,
};

    const form = useFormik({
        initialValues: {
            username: AuthTest!=undefined ? (AuthTest.authenticationUserToTest!="" ? AuthTest.authenticationUserToTest : defaultSettings.AuthUsername):defaultSettings.AuthUsername,
            password: AuthTest!=undefined ? (AuthTest.authenticationPassToTest!="" ?AuthTest.authenticationPassToTest :defaultSettings.AuthPassword):defaultSettings.AuthPassword
        },
        onSubmit: () => { },
        validationSchema
    })

      useEffect(() => {
        form.setFieldValue("username",reloadUser,true);
      }, [reloadUser]); 

      useEffect(() => {
        form.setFieldValue("password",reloadPass,true);
      }, [reloadPass]); 


      const handleTest=() =>{        
        var details =
        {
            machineName:device.sourceName,
            username:form.values.username,
            password: form.values.password,
            devicePlatform: device.os,
            id:device.os === DevicePlatform.Windows ? jobSettingsStore.selectedPrimaryUser.id :jobSettingsStore.selectedSecondaryUser.id
        };
        setProcessingTest(true);
        jobAgent.JobTestsActions.TestAuthentication(details).then(res => {
            if(res)
                toast.success("Authentication Test succeeded");
            else
                toast.error("Authentication Test failed");
            setProcessingTest(false);
        }).catch(err=>{
        if(err && err.data && err.data.errors)
            toast.error(err.data.errors);
        setProcessingTest(false);
        });           
    }

    const handleCancle =()=>{
        if(form.dirty && form.touched)
            setCancel(!cancel);
        else
            closeDrawer();
    }

    const handleSave =()=> {
        try{
            let DeviceTest = {
                testCategory:device.tests[0].testCategory, 
                testType:TestType.authentication, 
                selected: true,
                customCommandToTest: '',
                customCommandExpectedResult: '', 
                customCommandToTestIsExternal: false,
                databaseType:DatabaseType.SQL,
                databaseNameToTest: '',
                databaseQueryToTest: '',
                databaseServerNameToTest: '',
                databaseUsername:'',
                databasePassword:'',
                databasePort:'',
                dbWindowsAuth:false,
                serviceToTest:'',
                testFromEDRC:false,
                script: '',
                thirdDeviceNetworkToTest: '',
                authenticationUserToTest:form.values.username,
                authenticationPassToTest:form.values.password,
                webPortalToTest: '',
                webPortalResult:''
            };
            createJobStore.updateTestDevice(TestType.authentication,DeviceTest,device.planName.includes("Custom Devices") ? device.sourceName : device.deviceName);
            onApply();
            discard();
            setParentUsernameValue(form.values.username);
            setParentPasswordValue(form.values.password);
            toast.success(t('saveSuccessful'));
        }
        catch(error)
        {
            toast.success(t('savefailed')+error);
        }
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    const handleYes=()=>{
        form.setFieldValue("username",AuthTest.authenticationUserToTest!="" ? AuthTest.authenticationUserToTest :'',true);
        form.setFieldValue("password",AuthTest.authenticationPassToTest!="" ? AuthTest.authenticationPassToTest:'',true);
        discard();
        setCancel(false);
    }

    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const handleAuthUserChange =(event:React.ChangeEvent,value:any)=>{
        if(event!=null)
        {
            let onInput= event.type=="click"? false:true;
            setUserPassAutocomplete(onInput);
            if (value!=null && typeof value === 'object') 
            {   
                form.setFieldValue("username",value?.userName || '',true);
                form.setFieldValue("password",value?.password || '',true);
                //form.setValues({...form.values, username: value?.userName || '', password: value?.password || ''}, true);
            }
            if (typeof value === 'string')
            {
              const userObj = defaultSettings.PreviousCredentials.find(e => e.userName.toLocaleLowerCase() == value.toLocaleLowerCase());
              let pass = userObj== undefined ? '' :userObj.password;
              form.setFieldValue("username",value,true);
              form.setFieldValue("password",pass,true);
              //form.setValues({...form.values, username: value, password: pass}, true);
            }
            setShowEyePassword(onInput);
            setParentUsernameValue(value?.userName || '');
        }
    }

    const handlePasswordChange =(event)=>{
        if(event!=null)
        {
            setUserPassAutocomplete(true);
            setShowEyePassword(true);
            form.setFieldValue("password",event.target.value,true);
            setParentUsernameValue(event.target.value === "" ? '' :event.target.value);
        }
    }

    const handleClickShowPassword  = () => {
        setPasswordVisibility(passwordVisibility? false: true);
    };
    
    const handleMouseClick =()=>{
        if(form.values.password === CONSTS.MaskedPassword)
        {
            form.setFieldValue("password",'',true);
        }
    }

    const handleMouseOut =()=>{
        if(form.dirty  && form.values.password == '')
        {
            if(form.values.username!='')
            {
                return;
            }
            else form.setFieldValue("password",CONSTS.MaskedPassword,true);
        }
    }

    
    return(
           <Grid container spacing={2} style={{paddingRight: '5%',paddingLeft:'5%', alignContent:'flex-start'}}>
            
            <Grid item container direction="row" spacing={2} style={{marginTop:'0px'}}>
                <div style={{ display: 'flex', flexDirection: 'row',marginLeft:'1%'}}>
                <Typography variant='h6' className={classes.SecondHeading}>{t('authsetting')}</Typography>
                    <Tooltip title={Info("webInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider} style={{width:'140%'}}/>
                </div>
            </Grid>
           
            <Grid item container direction="row" spacing={2} style={{marginTop:'1%'}}>
                <Grid item xs={12}>
                <EDRAutocomplete id="username"
                        freeSolo
                        disableClearable
                        fullWidth
                        size='small'
                        value={form.values.username}
                        options={defaultSettings.PreviousCredentials}
                        style={{borderColor:'#c7cfdb'}}
                        onChange={handleAuthUserChange}
                        onInputChange={handleAuthUserChange}
                        renderOption={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
	                    getOptionLabel={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={Info('usernameLabel')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="username"
                        aria-autocomplete="none"
                        onBlur={form.handleBlur}
                        error={form.touched && form.touched.username && form.errors && Boolean(form.errors.username)}
                        helperText={form.touched.username && form.errors.username}
                        />)}
                        /> 
                </Grid>
            </Grid>

            <Grid item container direction="row" spacing={2} style={{marginTop:'1%'}}>
            <Grid item xs={12}>
            <FormControl className={classes.formControl} variant='outlined'>
            <InputLabel ref={ref => {labelRef = ReactDOM.findDOMNode(ref)}} style={{transform: 'translate(14px, -6px) scale(0.65)'}}>{ Info("passwordLabel")}</InputLabel>
                    <EDROutlinedInput id="password" 
                        name="password"
                        value={form.values.password}
                        labelWidth={labelRef ? labelRef.offsetWidth:0}
                        autoComplete="off"
                        aria-autocomplete="none"
                        style={{borderColor:'#c7cfdb',height:'39px'}}
                        type={passwordVisibility ? 'password':'text'}
                        placeholder="Type Here"
                        error={form.touched.password && Boolean(form.errors.password)}
                        onChange={handlePasswordChange}
                        onClick={handleMouseClick}
                        onBlur={handleMouseOut}
                        endAdornment={form.values.password && UserPassAutocomplete ?
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            aria-autocomplete="none"
                            onClick={()=>handleClickShowPassword()}
                            >
                            {passwordVisibility ?  <VisibilityOff />: <Visibility />}
                            </IconButton>
                    </InputAdornment>:null}
            />  
            </FormControl>
                </Grid>
            </Grid>
             

            <Grid item container direction="row">
            <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end'}}>
            <Button onClick={handleCancle} color="primary" style={{textTransform:'none'}}>
                {t('Cancel')}
            </Button>
            <Button onClick={handleTest} disabled={form.values.username =='' || form.values.password =='' ||processingTest} color="primary" style={{textTransform:'none'}}>
            {processingTest ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                {t('test')}
            </Button>
            <SaveButton onClick={handleSave} disabled={form.values.username =='' || form.values.password ==''} variant="contained" color="primary" style={{textTransform:'none',marginRight:'-8px'}}>
                {t('apply')}
            </SaveButton>
            </Grid>
            </Grid>
            
        </Grid>
    );
}

export default AuthTestDialog;