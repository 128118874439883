import { Button, CircularProgress, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, makeStyles, TextField, Tooltip} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { RootStoreContext } from "../../../app/stores/root.store";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { observer } from "mobx-react-lite";
import React from "react";
import { MachineType } from "../../../app/models/jobProperties.model";
import { DataMover } from "../../../app/models/BaseSettings.enum";
import { EDRAutocomplete } from "../../../app/common/EDRAutocomplete";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { EDROutlinedInput } from "../../../app/common/EDROutlinedInput";
import ReactDOM from "react-dom";
import { EDRTextField } from "../../../app/common/EDRTextField";
import { useTranslation } from "react-i18next";
import { UpdateType } from "../../../app/stores/activityStore";
import { CONSTS, CredentialUserType } from "../../../app/models/createjob/createJob.model";

const useStyles = makeStyles(() => ({
  button: {
    height: '100%',
    borderRadius: '4px',
    border: 'solid 1px #c7cfdb',
    backgroundColor: '#fafafc'
  },
  advanced: {
    width: '14px',
    height: '14px',
    objectFit: 'contain',
  },
  formControl: {
    width: '100%',
    '& label': {
      background: "white",
      width: "auto",
      padding: "0 4px 0 4px",
      fontSize: '17px'
    }
  }
}));

const WorkflowSecondaryCreateComponent: React.FC = () => {
    
    let labelRef;
    const rootStore = useContext(RootStoreContext);
    const {createJobStore, activityStore,jobSettingsStore,displayStore} = rootStore;
    const isPaaS= createJobStore.baseSettings.dataMover==  DataMover.PaaS;
    const {dataMover} = createJobStore.baseSettings;
    const [linuxPasswordVisibility, setLinuxPasswordVisibility] = useState(true);
    const [showEyeLinuxPassword, setShowEyeLinuxPassword]= useState(true);
    const previousSecondaryUsername = jobSettingsStore.linuxCredentails;
    const [secondaryAutocomplete,setSecondaryAutocomplete] = useState(false);
    const [t] = useTranslation("createJobBase");

    useEffect(() => {
      form.resetForm();
     },[createJobStore.PlatformChanged]);
    
    const useStyles = makeStyles(() => ({
        button: {
          height: '100%',
          borderRadius: '4px',
          border: 'solid 1px #c7cfdb',
          backgroundColor: '#fafafc'
        },
        advanced: {
          width: '14px',
          height: '14px',
          objectFit: 'contain',
        },
        formControl: {
          width: '100%',
          '& label': {
            background: "white",
            width: "auto",
            padding: "0 4px 0 4px",
            fontSize: '17px'
          },
          '& .Mui-error': {
            color: 'red',
        },
         '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red'
        }
        }
    }));
    const classes = useStyles();
    
    useEffect(() => {
        let initialValues ={
                SecondaryUser:createJobStore.baseSettings.workflowTest.SecondaryUser,
                SecondaryPassword:createJobStore.baseSettings.workflowTest.SecondaryPassword,
                SecondaryUserCredsId:createJobStore.baseSettings.workflowTest.SecondaryUserCredsId
        };
        form.resetForm({ values: initialValues });

        const onCopyInfraCredentials = () => {
          if(!isPaaS) {
            form.setValues(
            {
                SecondaryUser:createJobStore.baseSettings.workflowTest.SecondaryUser,
                SecondaryPassword:createJobStore.baseSettings.workflowTest.SecondaryPassword,
            }, true
            )
          }
        }

        activityStore.on(UpdateType.CopyInfraCredentials, onCopyInfraCredentials);
        return () => {
          activityStore.off(UpdateType.CopyInfraCredentials, onCopyInfraCredentials);
        }
    }, [createJobStore.baseSettings]); 
    
    const validation = yup.object({
      SecondaryUser:yup.string()
      .nullable(),
      
      SecondaryPassword:yup.string()
      .nullable()
    })
    
    const form = useFormik({
        initialValues: 
        {
          SecondaryUser:'',
          SecondaryPassword:''
        },
        validationSchema:validation,
        validateOnChange: true,
        onSubmit: () => { },
    })

    useEffect(() => {
        displayStore.updateNextStepValid({secondaryValid:form.isValid});
    },[form.values, form.errors])  
    
    const setType =()=> {
        if(rootStore.createJobStore.baseSettings.workflowTest.PrimaryUser!='' && rootStore.createJobStore.baseSettings.workflowTest.SecondaryUser!='')
          createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest, Type: MachineType.WindowsLinux}});
        else if(rootStore.createJobStore.baseSettings.workflowTest.PrimaryUser!='' && rootStore.createJobStore.baseSettings.workflowTest.SecondaryUser =='')
          createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest, Type: MachineType.Windows}});
        else
          createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest, Type: MachineType.Linux}});
    };

    const handleSecondaryUserChange = (event:React.ChangeEvent,value:any) => {
      if(event!=null){
        let onInput= event.type=="click"? false:true;
        setSecondaryAutocomplete(onInput);
        if (value!=null && typeof value === 'object') 
        {
          form.setFieldValue("SecondaryUser",value=="" ? '':value.userName,true);
          form.setFieldValue("SecondaryPassword",'',true);
          createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest, SecondaryUser: value=="" ? '':value.userName,SecondaryPassword:''}});
          let credentials= {id: createJobStore.networkSetting.domainController.domainControllerESXCredentials.id,userName: value=="" ? '':value.userName ,password: '',userType:CredentialUserType.ESXUser};
          rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController,  domainControllerESXCredentials: credentials}});
        }
        if (typeof value === 'string')
        {
          const userObj = previousSecondaryUsername.find(e => e.userName.toLocaleLowerCase() == value.toLocaleLowerCase());
          form.setFieldValue("SecondaryUser", userObj!=undefined ? value:(value==""?'':value),true);
          let pass = '';
          form.setFieldValue("SecondaryPassword",pass,true);
          createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest, SecondaryUser: value=="" ? '':value,SecondaryPassword:pass}});
          if(createJobStore.networkSetting.domainController != null && createJobStore.networkSetting.domainController != undefined && createJobStore.networkSetting.domainController.domainControllerESXCredentials != null)
          {
            let credentials= {id: createJobStore.networkSetting.domainController.domainControllerESXCredentials.id,userName: value=="" ? '':value ,password: pass,userType:CredentialUserType.ESXUser};
            rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController,  domainControllerESXCredentials: credentials}});
          }
          
        }
        setShowEyeLinuxPassword(onInput);
        if(!onInput)
        {
          setLinuxPasswordVisibility(true);
        }
        setType();
        CheckSectionAndDrawerStatus("workflow");
      }
    };
  
    const handleSecondaryPasswordChange = (event) => {
      setSecondaryAutocomplete(true);
      setShowEyeLinuxPassword(true);
      form.setFieldValue("SecondaryPassword",event.target.value,true);
      createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest,SecondaryPassword : event.target.value}});
      //let credentials= {id: createJobStore.networkSetting.domainController.domainControllerESXCredentials.id,userName: rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials.userName,password:event.target.value,userType:CredentialUserType.ESXUser};
      //rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController,  domainControllerESXCredentials: credentials}});
    };

    const handleMouseClick =()=>{
      if(form.values.SecondaryPassword === CONSTS.MaskedPassword)
      {
          form.setFieldValue("SecondaryPassword",'',true);
      }
  }

  const handleMouseOut =()=>{
      if(form.dirty && form.values.SecondaryPassword == '')
      {
        if(form.values.SecondaryUser!='')
        {
          return;
        }
        else form.setFieldValue("SecondaryPassword",CONSTS.MaskedPassword,true);
      }
  }

    const handleClickShowPassword  = () => {
        setLinuxPasswordVisibility (linuxPasswordVisibility ?false:true);
    };

    const CheckSectionAndDrawerStatus= (section:string) =>{
        switch(section)
        {
          case 'EDRServerAdvanced':
            {
              if(displayStore.screenSettings.displayBaseSettings=='InfrastructureAdvanced' || displayStore.screenSettings.displayBaseSettings=='DataMoverAdvanced' ||
              displayStore.screenSettings.displayBaseSettings=='InfrastructureCreateAdvanced' || displayStore.screenSettings.displayBaseSettings=='DataMoverCreateAdvanced' || 
              displayStore.screenSettings.displayBaseSettings=='WorkflowAdvanced')
              {
                  displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
                  displayStore.updateBaseDrawerSettings({InfraDrawerOpen: false});
                  displayStore.updateBaseDrawerSettings({DataMoverDrawerOpen: false});
              } 
            }
        }
    }

    const loadSecondaryUsername= ()=>{
      return (<Tooltip title={t('linuxUserPassInfo')} placement="top">
      <FormControl className={classes.formControl} variant='outlined'> 
      <EDRAutocomplete id="SecondaryUser"
        freeSolo
        disableClearable
        disabled={true}
        value={form.values && form.values.SecondaryUser !== null ? form.values.SecondaryUser : '' }
        options={previousSecondaryUsername}
        renderOption={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
	      getOptionLabel={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
        onChange={handleSecondaryUserChange}
        onInputChange={handleSecondaryUserChange}
        renderInput={(params) => (<EDRTextField {...params}
        label= {t('linuxUsername')}
        InputLabelProps={{shrink: true}} 
        variant="outlined"
        placeholder="Select/ Create New" 
        aria-autocomplete="none"
        onBlur={form.handleBlur}
        error={form.touched && form.touched.SecondaryUser && form.errors && Boolean(form.errors.SecondaryUser)}
        helperText={form.touched && form.touched.SecondaryUser && form.errors && Boolean(form.errors.SecondaryUser) && t(form.errors.SecondaryUser)}
        />)}
      />
      </FormControl>
      </Tooltip>
);
    }

    const loadSecondaryPassword=()=>{
    return(<Tooltip title={t('linuxUserPassInfo')} placement="top">
    <FormControl className={classes.formControl} variant='outlined'>
        <InputLabel ref={ref => {labelRef = ReactDOM.findDOMNode(ref)}}  style={{transform: 'translate(14px, -8px) scale(0.75)'}}>{t('linuxPass')}</InputLabel>
        <EDROutlinedInput id="SecondaryPassword" className="MuiFormControl"
            value={form.values.SecondaryPassword}
            labelWidth={labelRef ? labelRef.offsetWidth:0}
            autoComplete="off"
            type={linuxPasswordVisibility ? 'password':'text'}
            placeholder="Type Here"
            disabled={true}
          //  style={{backgroundColor:(displayStore.sectionSettings.WorkflowSection? 'rgb(233, 235, 238)': '#ffffff')}}
            onChange={handleSecondaryPasswordChange}
            onClick={handleMouseClick}
            onBlur={handleMouseOut}
            endAdornment={form.values.SecondaryPassword && secondaryAutocomplete ?
            <InputAdornment position="end">
            <IconButton
            aria-label="toggle password visibility"
            aria-autocomplete="none"
            onClick={()=>handleClickShowPassword()}
            >
            {linuxPasswordVisibility ?  <VisibilityOff />: <Visibility />}
        </IconButton>
</InputAdornment>
 :null }
/>
<FormHelperText id="helperTextSecondaryPassword" style={{color:'red',marginTop:'0px'}}>{form.touched && form.touched.SecondaryPassword && form.errors &&  Boolean(form.errors.SecondaryPassword) &&t(form.errors.SecondaryPassword)}</FormHelperText>  
</FormControl>
</Tooltip>
    )
    }

return(
  <React.Fragment>
    {!isPaaS && 
    <Grid container spacing={2} style={{marginTop:'2%'}}>
        {dataMover !== DataMover.VeeamSQL && <React.Fragment>
            <Grid item xs={6}> 
                {loadSecondaryUsername()}
            </Grid>
            <Grid item xs={6}>
              {loadSecondaryPassword()}
            </Grid>
        </React.Fragment>}
    </Grid>}
        {/* {isPaaS|| dataMover === DataMover.VeeamSQL && <EDRInfoFormComponent/>} */}
  </React.Fragment>

);
};

export default observer(WorkflowSecondaryCreateComponent); 