import React, { useEffect, useState, useContext } from "react";
import { observer } from "mobx-react-lite";
import { Line } from "react-chartjs-2";
import { IGraph } from "../../app/models/dashboard/dashboard.model";
import { ChartOptions } from "chart.js";
import { makeStyles, Grid, Typography, CircularProgress } from "@material-ui/core";
import { RootStoreContext } from "../../app/stores/root.store";
import moment from "moment";
import { settingObj } from "../../app/models/setting.model";
import { NavLink } from "react-router-dom";
import Cog from "../dashboard/Cog.component";
import EmptyGraph from "../../assets/dashboardAssets/empty_graph.svg"
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({});
const options: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          color: "rgb(255, 255, 255)",
          display: false,
        },
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 100,
          callback: (d) => {
            return d + "%";
          },
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          color: "#eaeef4",
          display: true,
        },
        type: 'time',
        ticks: {
          autoSkip: true,
          maxTicksLimit: 5
        },
        time: {
          stepSize: 1,
          unit: "day"
        }
      },
    ],
  },
};
type GraphProps = {
  Graph: IGraph,
  graphData: { values: Array<number>, labels: Array<Date> };
}

const Graph = (props: GraphProps) => {
  //const [graphData, setgraphData] = useState<{ values: Array<number>, labels: Array<Date> }>({ values: [], labels: [] });
  const [dataLoaded, setDataLoaded] = useState(false);
  const { dashboardStore } = useContext(RootStoreContext);
  const rootStore = useContext(RootStoreContext);
  //const { dashboardSettings, pointCounter } = rootStore.settingStore;
  //const graphSettingObj: settingObj = dashboardSettings.find((element: settingObj) => element.settingKey == "Graph");
  //const graphSetting: string = graphSettingObj != undefined ? graphSettingObj.settingValue : "Single";
  const [t] = useTranslation("dashboard");
  
  

  useEffect(() => {
    if (dashboardStore.filteredJobResults.length > 0)
      setDataLoaded(true);
  }, [props.graphData, setDataLoaded])

  useEffect(() => {
    if (dashboardStore.dashboardEmpty)
      setDataLoaded(true);
  }, [dashboardStore.dashboardEmpty, setDataLoaded])


  const data: any = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 510);
    gradient.addColorStop(0, 'rgba(40,146,200,0)');
    gradient.addColorStop(1, 'rgba(40,146,200,1)');
    return {
      labels: props.graphData.labels,
      datasets: [
        {
          fill: true,
          lineTension: 0.4,
          backgroundColor: gradient,
          borderColor: "rgba(40, 146, 215, 1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(40, 146, 215, 1)",
          pointHoverBorderColor: "rgba(40, 146, 215, 1)",
          pointHoverBorderWidth: 2,
          pointRadius: 5, //the circles on the graph
          pointHitRadius: 10,
          data: props.graphData.values,
        },
      ],
    };
  };




  const classes = useStyles();

  return (
    <Grid data-test="component-graph" container direction="column" style={{ height: "100%" }}>
      <Grid item container>
        <Grid item style={{ width: "70%" }}>
          <Typography classes={{ root: "dashboard_header" }}>{props.Graph.graphHeader}</Typography>
        </Grid>
       
      </Grid>
      <Grid item container style={{flexGrow: 1}}>
        <Line data={data} options={options} height={100} width={100} />
      </Grid>
    </Grid>
  );
};

export default observer(Graph);
