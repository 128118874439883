import React, { useContext, useEffect } from 'react';
import { Toolbar, AppBar as MaterialAppBar, LinearProgress, withStyles, Typography, makeStyles, Tooltip, CircularProgress, Button, Grid } from '@material-ui/core';
import ProfileFormComponent from '../../user/ProfileForm.component';
import RegisterFormComponent from '../../user/RegisterForm.component';
import UserMenuComponent from './userMenu.component';
import DraftsMenuComponent from './draftsMenu.component';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useTranslation } from 'react-i18next';
import HelpMenuComponent from './helpMenu.component';
import UserGuideDialogComponent from '../../help/userGuideDialog.component';
import { history } from '../../../app/common/util/history';
import { RootStoreContext } from '../../../app/stores/root.store';
import ResourceProgressBar from '../../../app/common/drResource/dRProgressbar.component';
import { observer } from 'mobx-react-lite';
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        background: "white",
        color: "#1f396d"
    },
    title: {
        flexGrow: 1,
        textAlign: 'left',
        textOverflow: 'string',
        overflow: 'visible',
        whiteSpace: 'nowrap',
        paddingRight: '0.5em'
    },
    button: {
        color: 'white',
        borderRadius: '26px',
        width: '145px',
        height: '40px',
        textTransform: 'none',
        marginLeft: '1em'
    },
}));



type AppBarProps = {
    showDevices?: boolean
    title?: string
    className?: string
}

const renderProgressBar = (value) => <EDRProgressbar variant="determinate" style={{ color: "#2892d7" }} value={value} />;
const renderProgressBarFull = (value) => <EDRProgressbarRed variant="determinate" style={{ color: "red" }} value={value} />;

const EDRProgressbar = withStyles({
    root: {
        backgroundColor: "#ebeef4",
        borderRadius: 20
    },
    bar: {
        backgroundColor: '#2892d7',
    },
    colorPrimary: {
        paddingRight: 10
    }
})(LinearProgress);

const EDRProgressbarRed = withStyles({
    root: {
        backgroundColor: "#ebeef4",
        borderRadius: 20
    },
    bar: {
        backgroundColor: 'red',
    },
    colorPrimary: {
        paddingRight: 10
    }
})(LinearProgress);


const AppBar: React.FC<AppBarProps> = (props) => {
    const classes = useStyles();
    const [t] = useTranslation("user");
    const { userStore, jobSettingsStore, jobResultStore } = useContext(RootStoreContext);

    const handlePurchase = () => {
        window.open("https://www.ensuredr.com/buy-now", "_blank");
    }




    const loadTrialSection = () => {
        return (
            <React.Fragment>
                <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', whiteSpace: 'nowrap' }}>
                    <Typography style={{ color: "red", fontWeight: "bold", paddingRight: '10px', marginLeft: '1em'}}>{t("trailMode")}</Typography>
                    <Button className={classes.button} variant="contained" style={{ color: "white", backgroundColor: 'rgb(139, 197, 63)' }} onClick={() => handlePurchase()}>{t('upgrade')}</Button>
                </div>
            </React.Fragment>
        )
    }
    
    return(
       <React.Fragment>
        <MaterialAppBar position="sticky" className={classes.root}>
          <Toolbar>
              {props.title && <Typography variant="h6" className={classes.title}>{props.title}</Typography>}
            {props.children}
            {/* {jobResultStore.jobResults.length <= 1 ? loadTrialSection():null} */}
            {/* {userStore.user.username!="readonly"?
                    <Tooltip  title="Drafts">
                        <DraftsMenuComponent fromWelcome={false} />
                    </Tooltip> : null
            } */}
            <HelpMenuComponent />
            <UserMenuComponent />
        </Toolbar>
    </MaterialAppBar>
    <UserGuideDialogComponent />
    <ProfileFormComponent  />
    <RegisterFormComponent  />
  </React.Fragment>
  );
};
const AppBarTitle: React.FC = (props) => {
    return <div style={{ width: '100%', display: 'flex' }}>
        {props.children}
    </div>
}

export { AppBarTitle };
export default AppBar;