import { Grid, Typography, Card } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import BaseSettingStepsComponent from "./baseSettings/BaseSettingSteps.component";
import BaseSettingMainComponent from "./baseSettings/BaseSettingMain.component";
import BaseSettingAdvancedComponent from './baseSettings/BaseSettingAdvanced.component';
import { Prompt, RouteComponentProps } from 'react-router-dom';
import { Location, Action } from 'history';
import { AccessCredentials, CredentialUserType } from '../../app/models/createjob/createJob.model';
import { RootStoreContext } from '../../app/stores/root.store';
import { InfrastructureType } from '../../app/models/jobProperties.model';
import jobAgent from '../../app/api/jobAgent';
import { toast } from 'react-toastify';
import { DataMover } from '../../app/models/BaseSettings.enum';



const BaseSettingComponent: React.FC<RouteComponentProps<any>> = ({history}) => {
    const rootStore = useContext(RootStoreContext);
 


    return (
        <Grid className='BaseSettings' style={{ background: 'rgb(233, 235, 238)', padding: '16px 16px 16px 16px', height: '100%', overflow: 'hidden' }} direction="row" container>
            <Grid item xs={2} style={{ marginBottom: '-30px' }}>
                <BaseSettingStepsComponent />
            </Grid>
            <Grid item xs={6} style={{ overflowY: 'scroll', height: '100%', overflowX: 'hidden' }} className="hide-scrollbar">
                <BaseSettingMainComponent />
            </Grid>
            <Grid item xs={4} style={{ overflowY: 'scroll', height: '100%', overflowX: 'hidden' }} className="hide-scrollbar">
                <BaseSettingAdvancedComponent />
            </Grid>
         

        </Grid>
    );
}

export default BaseSettingComponent;