import {Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { RootStoreContext } from "../../../app/stores/root.store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import Create from "@material-ui/icons/Create";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { DataMover } from "../../../app/models/BaseSettings.enum";
import { useFormik } from "formik";
import { useLocation } from 'react-router';

const WorkflowSecondaryComponent: React.FC = () => {

    const rootStore = useContext(RootStoreContext);
    const {createJobStore, activityStore,jobSettingsStore,displayStore} = rootStore;
    const {dataMover}=createJobStore.baseSettings;
    const credsOptions = jobSettingsStore.linuxCredentails;
    const [t] = useTranslation("createJobBase");
    const location = useLocation();
    const isEditMode = () => {
      return location.pathname.includes("edit");
    }

    const useStyles = makeStyles(() => ({
        editIcon: {
            cursor: 'pointer'
        },
        button: {
          height: '100%',
          borderRadius: '4px',
          border: 'solid 1px #c7cfdb',
          backgroundColor: '#fafafc'
        },
        advanced: {
          width: '14px',
          height: '14px',
          objectFit: 'contain',
        },
        formControl: {
          width: '100%',
          '& label': {
            background: "white",
            width: "auto",
            padding: "0 4px 0 4px",
            fontSize: '17px'
          },
          '& .Mui-error': {
            color: 'red',
        },
         '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red'
        }
        }
    }));
    const classes = useStyles();

    const form = useFormik({
        initialValues: 
        {
          SecondaryUser:'',//createJobStore.baseSettings.workflowTest.SecondaryUser,
          SecondaryPassword:'',//createJobStore.baseSettings.workflowTest.SecondaryPassword,
          linuxCredentialsId:0,//createJobStore.baseSettings.workflowTest.linuxCredentialsId,
        },
        onSubmit: () => { },
      })
      
    const loadCircularProgress =()=>{
        return <div style={{width:'100%',display:'flex'}}>
        {t('SecondaryInProgress')}
        <CircularProgress id="secondaryLoading" size={18} style={{color: '#2892D7', marginLeft: '10px',marginTop: '-3px'}}/>
        </div>
    }

    const buttonRedirect= (subComponent:any)=> {
        switch(subComponent)
        {
          case "SecondaryAdvanced":
          {
            if(displayStore.BaseDrawerSettings.PrimaryUserDrawerOpen || displayStore.BaseDrawerSettings.SecondaryUserDrawerOpen)
              {
                if(displayStore.BaseDrawerSettings.SecondaryUserDrawerOpen)
                {
                if(displayStore.BaseDrawerSettings.addSecondaryUser)
                {
                  displayStore.updateScreenSettings({displayBaseSettings: subComponent});
                  displayStore.updateBaseDrawerSettings({addSecondaryUser: false});
                  displayStore.updateBaseDrawerSettings({SecondaryUserDrawerOpen: true});
                  displayStore.closeAllOtherBaseDrawersExcept("SecondaryUserDrawer");
                }
                else
                {
                  displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
                  displayStore.updateBaseDrawerSettings({SecondaryUserDrawerOpen: false});
                }
              }
              else
              {
                displayStore.updateScreenSettings({displayBaseSettings: subComponent});
                displayStore.updateBaseDrawerSettings({addSecondaryUser: false});
                displayStore.updateBaseDrawerSettings({SecondaryUserDrawerOpen: true});
                displayStore.closeAllOtherBaseDrawersExcept("SecondaryUserDrawer");
              }
              }
              else
              { 
                displayStore.updateScreenSettings({displayBaseSettings: subComponent});
                displayStore.updateBaseDrawerSettings({addSecondaryUser: false});
                displayStore.updateBaseDrawerSettings({SecondaryUserDrawerOpen: true});
                displayStore.closeAllOtherBaseDrawersExcept("SecondaryUserDrawer");
              }  
          }
          break;
        }
    };
    useEffect(() => {
   
      let initialValues;
      initialValues =
      {
        SecondaryUser:createJobStore.baseSettings.workflowTest.SecondaryUser,
        SecondaryPassword:createJobStore.baseSettings.workflowTest.SecondaryPassword,
        linuxCredentialsId:createJobStore.baseSettings.workflowTest.SecondaryUserCredsId,
      };
     
      form.setValues(initialValues, true);
    }, [createJobStore.baseSettings, jobSettingsStore.linuxCredentails]);
    
    useEffect(() => {
      if(credsOptions.length == 0)
      return;
      
      const cred = credsOptions.find(d => d.id == createJobStore.baseSettings.workflowTest.SecondaryUserCredsId);
      if(!cred && !isEditMode()) 
      {
          setUser(credsOptions[0]);
          createJobStore.updateBaseSettings({ workflowTest: { ...createJobStore.baseSettings.workflowTest, SecondaryUserCredsId:credsOptions[0].id }});
      }
      else 
      {
          if(cred == undefined && isEditMode() && credsOptions.length > 0)
            setUser(credsOptions[0]);
          else
            setUser(cred);
      }  
    }, [jobSettingsStore.linuxCredentails])
    
    const setUser=(cred: any) => {
        if(cred!=undefined)
        {
          form.setFieldValue("SecondaryUser", cred.userName);
          form.setFieldValue("SecondaryPassword", cred.password);
          form.setFieldValue("linuxCredentialsId", cred.id);
          createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest, SecondaryUserCredsId: cred.id}});
          jobSettingsStore.updateSelectedSecondaryUser(cred.userName);
          jobSettingsStore.updateSelectedSecondaryUserId(cred.id);
          jobSettingsStore.updateSelectedSecondaryPassword(cred.password);
        }
    }
    const handleSecondaryUsernameChange= (event:any)=>{
      if(displayStore.BaseDrawerSettings.SecondaryUserDrawerOpen)
      {
        displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
        displayStore.updateBaseDrawerSettings({SecondaryUserDrawerOpen: false});
      }  
      let user = jobSettingsStore.linuxCredentails.find(l => l.id === event.target.value);
      setUser(user)
      createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest, SecondaryUserCredsId: user.id}});
    }

    const handleAddSecondary =()=>{
      displayStore.updateScreenSettings({displayBaseSettings: "SecondaryAdvanced"});
      displayStore.updateBaseDrawerSettings({addSecondaryUser: true});
      displayStore.updateBaseDrawerSettings({SecondaryUserDrawerOpen: true});
      displayStore.closeAllOtherBaseDrawersExcept("SecondaryUserDrawer");
    }

return(
    <React.Fragment>
      <Grid container item direction='row' spacing={2} style={{marginTop:'1%'}}>
      <Grid item xs={1} style={{display:'flex',alignItems:'center'}}>
      <IconButton disabled={true} onClick={()=> {handleAddSecondary()}}>
        <AddCircleOutlineIcon />
      </IconButton>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={11}>
        <FormControl className={classes.formControl} variant='outlined' style={{backgroundColor:'#ffffff',paddingTop:'1%',width:'97%'}}>
          <InputLabel style={{transform:'translate(14px, 2px) scale(0.75)'}}>{t(dataMover=== DataMover.VeeamSQL? 'workflowUser': 'secondaryUser')}</InputLabel>
		      <Select
                name="SecondaryUser"
                required={true}
                labelId="SecondaryUser"
                disabled={true}
                value={form.values.linuxCredentialsId}
                onBlur={form.handleBlur}
                error={form.touched?.linuxCredentialsId && Boolean(form.errors?.linuxCredentialsId)}
                onChange={handleSecondaryUsernameChange}>
                {credsOptions.map((data) => (
                <MenuItem key={data.userName} value={data.id}>
                {`${data.userName}`}
                </MenuItem>))}
            </Select>
          <em style={{fontSize: 12, fontStyle:"italic", color: "red"}}>{form.touched?.linuxCredentialsId && form.errors.linuxCredentialsId}</em>
        </FormControl>
        </Grid>

        <Grid item xs={1} style={{paddingTop:'5px'}}>
          <Button  disabled={true} className={classes.button} onClick={()=> {buttonRedirect('SecondaryAdvanced')}}>
          <Create className={classes.editIcon} ></Create>
          </Button>
        </Grid>

      </Grid>
    </Grid>
   </React.Fragment>
);

};

export default observer(WorkflowSecondaryComponent); 