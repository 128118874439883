import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { CircularProgress, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, makeStyles, Switch, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RootStoreContext } from '../../../../app/stores/root.store';
import { EDRTextField } from '../../../../app/common/EDRTextField';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import HelpIcon from '@material-ui/icons/Help';
import { SaveButton } from '../../../../app/common/saveButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import jobAgent from '../../../../app/api/jobAgent';
import CreateJobStore from '../../../../app/stores/createJob.store';
import { toast } from 'react-toastify';
//import { exception } from 'console';
import { runInAction } from 'mobx';
import ConfirmationDialog from '../../../../app/common/confirmationDialog.component';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import ReactDOM from 'react-dom';
import { EDROutlinedInput } from '../../../../app/common/EDROutlinedInput';
import { UpdateType } from '../../../../app/stores/activityStore';

const useStyles = makeStyles({
    formControl: {
        width: '100%',
        height:'70px',
        '& label': {
            background: "white",
            width: "auto",
            padding:"0 4px 0 4px",
            fontSize: '17px',
          }
    },
    helpIcon:
    {
      color: '#1976d2',
      fontSize: "large"
    },
    MainHeading:
    {
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.19',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#1f396d',
        marginLeft:'10px'
    },
    SecondHeading:{
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        flexGrow: 1
    },
    divider: {
            backgroundColor: '#eaeef4',
            margin: '0',
            marginTop: '10px',
            marginLeft: '10px',
    },
    });   

const LicenseAdvancedComponent : React.FC = () => {
    const classes = useStyles();
    const [t] = useTranslation("createJobBase");
    const rootStore = useContext(RootStoreContext); 
    const {displayStore, activityStore} = rootStore;
    const {BaseDrawerSettings} =displayStore;
    const {jobSettingsStore,createJobStore} =rootStore;
    const [openConfirmationDialog, setopenConfirmationDialog] = useState(false);
    let lic= jobSettingsStore.selectedLicense;
    const [licenseVisibility,setLicenseVisibility] = useState(true);
    const [PassAutocomplete,setPassAutocomplete] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    let labelRef;
    

      const validation = yup.object({

        licenseKey: yup.string(),
        licenseName: yup.string()
        .max(30, 'up to 30 characters'),
    })

      const form = useFormik({
        initialValues:
        {
            licenseKey: lic !=undefined ? '********':'',
            licenseName: lic !=undefined ? lic.name:'',
        },
        validationSchema: validation,
        onSubmit: () => { },
    })

    useEffect(() => {
        let lic = jobSettingsStore.licensesList.find(l => l.id === createJobStore.baseSettings.licenseId);
        const initialValues =
        {
            licenseKey: BaseDrawerSettings.addLicense ?'' :lic!=undefined ? '********':'',
            licenseName: BaseDrawerSettings.addLicense ?'': lic!=undefined ? lic.name:'',
        };
        form.setValues(initialValues);
    }, [BaseDrawerSettings.addLicense]);

    const handleAdd = async() =>{
        setButtonsDisabled(true);
        let LicenseDetails ={
            id:0,
            key:form.values.licenseKey,
            name:form.values.licenseName
        }
        await jobSettingsStore.createLicense(LicenseDetails).then(license => {
            if(displayStore.BaseDrawerSettings.LicenseDrawerOpen)
            {
                displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
                displayStore.updateBaseDrawerSettings({LicenseDrawerOpen: false});
            };
            activityStore.sendMessage(UpdateType.LicenseAdded, license);
        }).catch(err => {});
        setButtonsDisabled(false);
    }

    const handleDelete = async() =>{
        setButtonsDisabled(true);
        await jobSettingsStore.deleteLicense().then(res => {
            activityStore.sendMessage(UpdateType.LicenseDeleted, lic.id);
        }).catch (err => {});
        setButtonsDisabled(false);
    }

    const handleUpdate= async() =>{
        setButtonsDisabled(true);
        let LicenseDetails =
        {
            id:createJobStore.baseSettings.licenseId,
            name:form.values.licenseName
        }
       await jobSettingsStore.editLicense(LicenseDetails).then(res=>{
        if(displayStore.BaseDrawerSettings.LicenseDrawerOpen)
        {
          displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
          displayStore.updateBaseDrawerSettings({LicenseDrawerOpen: false});
        }  
        activityStore.sendMessage(UpdateType.LicenseUpdated, res);
       }).catch(err => {});
       setButtonsDisabled(false);
    }

    const handleConfirmationPopup =(confirm:boolean)=>{
        if(confirm)
        {
            handleDelete();
        }
        setopenConfirmationDialog(false);
    }

    const LicenseKeyhandleChange =(event:any)=>{
        setPassAutocomplete(true);
        setLicenseVisibility(true);
        form.setFieldValue("licenseKey",event.target.value);
    }
    
    
    return(
    <Grid container direction="column" style={{paddingRight: 16}}>

        <div style={{ display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
            <Typography variant='h6' className={classes.MainHeading}>{BaseDrawerSettings.addLicense ? t("addLicense"):t("editLicense")}</Typography>
            <div style={{ display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
                <SaveButton onClick={()=>setopenConfirmationDialog(true)} disabled={buttonsDisabled||!form.isValid} variant="contained" color="primary" style={{height:'25px',textTransform:'none',marginRight:'10px'}}>
                    {t('delete')}
                    {buttonsDisabled ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                </SaveButton>
                <SaveButton data-test='licenseAdvancedSaveButton' onClick={BaseDrawerSettings.addLicense ? handleAdd :handleUpdate} disabled={buttonsDisabled || !form.isValid} variant="contained" color="primary" style={{height:'25px',textTransform:'none'}}>
                    {BaseDrawerSettings.addLicense ? t('add'): t('apply')}
                    {buttonsDisabled ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                </SaveButton>   
            </div>
        </div>

        <Grid item container direction="row">
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px' ,marginLeft:'10px'}}>
            <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{ BaseDrawerSettings.addLicense ? t('pasteLicense'):t('changeLicense')}</Typography>
            <Tooltip title={BaseDrawerSettings.addLicense ? t("licenseInfo"): t('editLicenseInfo')} arrow>
                <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
            </Tooltip> 
            <Divider className={classes.divider} style={{width:BaseDrawerSettings.addLicense? '245%':'200%'}}/>
        </div>
    </Grid>

        <Grid item direction="row" container xs={12} style={{marginTop:'8px',marginLeft:'1%'}} spacing={2}>
        <Grid item xs={6}>
    <FormControl className={classes.formControl} variant='outlined'>
            <EDRTextField id="licenseName"
            name="licenseName"
            label={t('licenseName')}
            value={form.values.licenseName}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={Boolean(form.touched.licenseName && form.errors.licenseName)}
            helperText={form.touched.licenseName && t(form.errors.licenseName)}
            aria-autocomplete="none"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            variant="outlined">
          </EDRTextField>
          </FormControl>
      </Grid>
    
    <Grid item xs={6}>
    <FormControl className={classes.formControl} variant='outlined'>
    <InputLabel ref={ref => {labelRef = ReactDOM.findDOMNode(ref)}} style={{transform: 'translate(14px, -5px) scale(0.75)'}}>{t('licenseKey')}</InputLabel>
        <EDROutlinedInput id="licenseKey" 
                          name="licenseKey"
                          value={form.values.licenseKey}
                          labelWidth={labelRef ? labelRef.offsetWidth:0}
                          disabled={!displayStore.BaseDrawerSettings.addLicense}
                          type={licenseVisibility ? 'password' : 'text'}
                          placeholder={t('pasteLicenseHere')}
                          aria-autocomplete="none"
                          autoComplete="off"
                          onChange={LicenseKeyhandleChange}
                          onBlur={form.handleBlur}
                          endAdornment={ form.values.licenseKey  && PassAutocomplete?
                          <InputAdornment position="end">
                          <IconButton
                          onClick={()=> setLicenseVisibility(licenseVisibility? false: true)}
                          >
                          {licenseVisibility ?  <VisibilityOff />: <Visibility />}
                      </IconButton>
              </InputAdornment>
                :null}/>  
          </FormControl>
      </Grid> 
</Grid>
<ConfirmationDialog type="deleteLicense" device={null} open={openConfirmationDialog} onClose={(confrim)=>handleConfirmationPopup(confrim)} children={"You are about to delete existing license, are you sure ?"}></ConfirmationDialog>
    </Grid>);
};

export default observer(LicenseAdvancedComponent);