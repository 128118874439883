import axios, {AxiosResponse} from 'axios';
import { history } from '../common/util/history'; 
import { toast } from 'react-toastify';
import { ServerStatusManager } from "../stores/ServerStatusManager";
import { MatomoInstance } from '../common/util/matomo';

// axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})
//axios interceptors can handle the request - as well as the response
//here, we check if we already have a stored token, and if we do - we
//send it over in our authorization header's bearer token
axiosInstance.interceptors.request.use((config) => {
    const token = window.localStorage.getItem('jwt');
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
}, error => {
    return Promise.reject(error);
})

//axios interceptors catch the server response before
//it hits the ui flow, so we could do something about it
axiosInstance.interceptors.response.use((res) => {
    // console.log('intercepted response', res);
    // try {
    //     MatomoInstance.trackEvent({
    //         category: 'ApiHits',
    //         name: res.request.responseURL,
    //         value: 1,
    //         action: res.status+''
    //     });
    // }catch(e) {
    //     console.log('exception', e)
    // }
    return Promise.resolve(res);
},error => {
    console.log('error', error.response.request.responseURL, error.response)
    MatomoInstance.trackEvent({
        category: 'ApiErrors',
        name: error,
        value: 1,
        action: error.response.request.responseURL
    })
    if(!ServerStatusManager.isServerAvailable()) {
        return Promise.reject(error) ;
    }

   if (error.message === 'Network Error' && !error.response) {
        return Promise.reject(error) ;
     }
     const {status, data, config, headers} = error.response || {}; //this way we are deconstructing the 
     //error response parameters so we don't need to type it every time
   if (status === 404) {
        history.push('/notfound');
     }

    if (status === 400 && config.method === 'get' && data.errors.hasOwnProperty('id')){
        //the combination of these 3 conditions is most likely indication that we have
        //a request with the wrong guid of the job
        history.push('/notfound');
    }
    /*if (status === 500) {
        toast.error(error.message);
    }*/
    throw error;
})


const responseBody = (response: AxiosResponse) => response.data;

//we're adding here the sleep methos in order to simulate server
//slow response/internet slow connection on production - this is
//a good practice for development phase to add delay for loading indicators
//we are using a technique called Currying which turns a function 
//with multiple arguments into a sequence of nesting functions
const sleep = (ms: number) => (response: AxiosResponse) => 
    new Promise<AxiosResponse>(resolve => setTimeout(() => resolve(response), ms));


const requests = {
    //the below get request is the one with the artificial delay
    //in order to see loading times
    get: 
        (url: string) => 
        axiosInstance.get(url)
        .then(responseBody),
    getPhoto:
    (url: string) => {
        return axiosInstance.get(url, {
            url: 'https://localhost/api/photos',
        }).then(responseBody)
    },
    post: 
        (url: string, body: {}) => 
        axiosInstance.post(url, body)
        .then(responseBody),
    postRemote: 
        (url: string, body: {}) => {
        axiosInstance.post(url, body)
        .then(responseBody);
    },
    put: 
        (url: string, body: {}) => 
        axiosInstance.put(url, body)
        .then(responseBody),
    del: 
        (url: string,body: {}) => 
        axiosInstance.delete(url, {data: body} )
        .then(responseBody),
    postForm: 
        (url: string, file: Blob) => {
        let formData = new FormData();
        formData.append('File', file);
        return axiosInstance.post(url, formData, {
            headers: {'Content-type': 'multipart/form-data'}
        }).then(responseBody)
    },
}

export default {
   requests,
   sleep,
   axios: axiosInstance,
   responseBody,
}