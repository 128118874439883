
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Divider, FormControl, Grid, InputAdornment, InputLabel, ListItemText, makeStyles, Switch, Tooltip, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EDRTextField } from "../../../../app/common/EDRTextField";
import { SaveButton } from "../../../../app/common/saveButton";
import { EDRDialogTitle } from "../../../../app/common/EDRDialogTitle";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EDRAutocomplete } from '../../../../app/common/EDRAutocomplete';
import { RootStoreContext } from '../../../../app/stores/root.store';
import { TestType } from '../../../../app/models/jobTest.model';
import { SettingProps } from './customTestSetting.component';
import { DatabaseType, DevicePlatform, MachineType } from '../../../../app/models/jobProperties.model';
import HelpIcon from '@material-ui/icons/Help';
import { toast } from 'react-toastify';
import { compressFile } from '../../../../app/common/util/util';
import jobAgent from '../../../../app/api/jobAgent';

const useStyles = makeStyles(theme => ({
    helpIcon:
    {
      color: '#1976d2',
      fontSize: "large"
    },
    blueDiv: {
        width: '100%',
        height: '165px',
        borderRadius: '4px',
        border: 'solid 1px rgba(40, 146, 215, 0.25)',
        backgroundColor: 'rgba(40, 146, 215, 0.05)'
    },
    SecondHeading:{
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        flexGrow: 1
    },
    divider: {
        backgroundColor: '#eaeef4',
        maxWidth: '100%',
        margin: '0',
        marginTop: '10px',
        marginLeft: '10px',
    },
    centerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between'
    },
    toggleItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiListItemText-root' : {
            marginLeft: 10
        },
        '& .MuiSvgIcon-root': {
            color: '#8092a9'
        }
    },
    root: {
        minWidth: '30vw'
    },
    title:{
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontstyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        textAlign: 'left'
    },
    div: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'block',
        overflow: 'hidden'
    },
    textLabel: {
        textTransform:'uppercase',
        paddingTop:'5%'
    },
    formControl: {
        width: '100%',
        '& label': {
            background: "white",
            width: "auto",
            padding:"0 4px 0 4px",
            fontSize: '17px',
          }
    }
}))

const NetworkTestSettingDialog: React.FC<SettingProps> = (props) => {
    const { deviceName, onClose,onSave } = props;
    const classes = useStyles();
    const { t } = useTranslation('createJobDevices');
    const [Info] = useTranslation('createJobBase');
    const {createJobStore,jobSettingsStore} = useContext(RootStoreContext);
    const [device, setDevice] = useState(createJobStore.selectedDevices.find(device=>device.deviceName == deviceName));
    const [thirdDeviceLabel,setThirdDevice]= useState("3rd Device");
    const [NetworkthirdDeviceTest,setNetworkThirdDeviceTest] =useState(device!= undefined ? device.tests.find(test=> test.testType == TestType.thirdDeviceNetwork).selected ? device.tests.find(test=> test.testType == TestType.thirdDeviceNetwork) : device.tests.find(test=> test.testType == TestType.network):null);
    const [cancel,setCancel]=useState(false);
    const [useThirdDevice,setUseThirdDevice] =useState(device!= undefined ? (device.tests.find(test=> test.testType == TestType.thirdDeviceNetwork).selected ? true:false):false);
    const [processingTest,setProcessingTest] = useState(false);

    const handleClose = () => {
        if(form.touched.thirdDevice && form.dirty)
        {
            setCancel(!cancel);
        }
        else
            onClose();
    }

    const handleSave=()=>{
        try{
        let DeviceTest = {
                testCategory:NetworkthirdDeviceTest.testCategory, 
                testType: useThirdDevice ? TestType.thirdDeviceNetwork :TestType.network , 
                selected: true,
                customCommandToTest: '',
                customCommandExpectedResult: '', 
                customCommandToTestIsExternal: false,
                databaseType: DatabaseType.SQL,
                databaseNameToTest: '',
                databaseQueryToTest: '',
                databaseServerNameToTest: '',
                databaseUsername:'',
                databasePassword: '',
                databasePort:'',
                dbWindowsAuth:false,
                serviceToTest:'',
                testFromEDRC: false,
                script: '',
                thirdDeviceNetworkToTest: useThirdDevice ? form.values.thirdDevice :'',
                authenticationUserToTest:'',
                authenticationPassToTest:'',
                webPortalToTest: '',
                webPortalResult: ''
        };
        createJobStore.updateTestDevice(useThirdDevice ? TestType.thirdDeviceNetwork :TestType.network,DeviceTest,device.planName.includes("Custom Device") ? device.sourceName : deviceName);
        toast.success('Save successful');
    }
    catch(error){
        toast.success('Save failed'+error);
    }
        setCancel(false);
        onClose();
        onSave(useThirdDevice);
    }

    const handleTest=() =>{
        let network= useThirdDevice ? form.values.thirdDevice :device.sourceName;
        var details =
        {
            NetworkIP:network,
            UseThirdDevice:useThirdDevice,
            machineName:device.sourceName,
            id: createJobStore.baseSettings.workflowTest.PrimaryUserCredsId
        };
        jobAgent.JobTestsActions.TestNetwork(details).then(res => {
            if(res)
                toast.success("Network Test succeeded");
            else
                toast.error("Network Test failed");
        }).catch(err=>{
            if(err && err.data && err.data.errors)
                toast.error(err.data.errors);
        });        ;  
    }

    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel style={{marginLeft:'10px'}}>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const handleYes=()=>{
        let initialValues = {
            thirdDevice: ''
        };
        setUseThirdDevice(false);
        form.setValues(initialValues);
        setCancel(false);
        onClose();
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    const validation = yup.object({
        thirdDevice: yup.string()
        .nullable()
        .test('empty','third device must be not empty',thirdDevice => thirdDevice!='')
        .test('thirdDevice','Invalid third device hostname/IP',
        function(value){
                let IpRegex=/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
                let hostnameRegex=/^(([a-zA-Z0-9\-\(\)]|[a-zA-Z0-9\-\(\)][a-zA-Z0-9\-\(\)]*[a-zA-Z0-9\-\(\)])\.)*([A-Za-z0-9\-\(\)]|[A-Za-z0-9\-\(\)][A-Za-z0-9\-\(\)]*[A-Za-z0-9\-\(\)])$/;
                let isValidIP = IpRegex.test(value);
                let isValidHostname = hostnameRegex.test(value);
                if(isValidIP || isValidHostname ) 
                    return true;
                else
                    return false;
        })
    })

    const form = useFormik({
        initialValues: {
            thirdDevice: ''
        },
        validationSchema:validation,
        validateOnMount: true,
        onSubmit: () => { },
    })

    useEffect(() => {
        const newDevice = createJobStore.selectedDevices.find(device=>device.deviceName == deviceName);
        setDevice(newDevice);
        const newNetworkThirdDeviceTest = newDevice!= undefined ? (newDevice.tests.find(test=> test.testType == TestType.thirdDeviceNetwork).selected ? newDevice.tests.find(test=> test.testType == TestType.thirdDeviceNetwork) :newDevice.tests.find(test=> test.testType == TestType.network)):null;
        setNetworkThirdDeviceTest(newNetworkThirdDeviceTest);
        let initialValues;
        initialValues =
        {
            thirdDevice: newNetworkThirdDeviceTest ? newNetworkThirdDeviceTest.thirdDeviceNetworkToTest: deviceName
        };
        form.resetForm();
        form.setValues(initialValues);
        form.setFieldTouched("thirdDevice",false);  
    }, [deviceName]);

    const handleSwitchNetwork =(event:any)=>{
        if(event!=null)
        {
            setUseThirdDevice(event.target.checked);
            if(!event.target.checked)
                form.setFieldValue("thirdDevice",device.deviceName);
            form.setFieldTouched("thirdDevice",true);  
        }
    }

    const handleChange3rdDevice= React.useCallback( (event)=>{
        form.setFieldValue("thirdDevice", event.target.value);
        form.setFieldTouched("thirdDevice",true,false);  
    }
,[form.setFieldValue])

    const loadTextBased =() =>{
        if(useThirdDevice)
        return(
        <Grid item container direction="row" alignContent="center">
        <Grid item xs={4} alignContent="center" style={{display:'flex',justifyContent:'center'}}>
            <Tooltip title={device.deviceName} arrow>
            <InputLabel className={classes.textLabel}>{t("edrc")}</InputLabel>
            </Tooltip>
        </Grid>
        <Grid item xs={4} alignContent="center" style={{display:'flex'}}>
            <div className={classes.div}>
            <Tooltip title={device.deviceName} arrow>
            <InputLabel className={classes.textLabel}>{device.deviceName}</InputLabel>
            </Tooltip>
            </div>
        </Grid>
        <Grid item xs={4} alignContent="center" style={{display:'flex'}}>
            <div className={classes.div}>
            <Tooltip title={thirdDeviceLabel} arrow>
            <InputLabel className={classes.textLabel}>{thirdDeviceLabel}</InputLabel>
            </Tooltip>
            </div>
        </Grid>
    </Grid>)
    else
    return (
        <Grid item container direction="row" alignContent="center" style={{marginLeft:'16%'}}>
        <Grid item xs={4} alignContent="center" style={{display:'flex',justifyContent:'center'}}>
            <Tooltip title={device.deviceName} arrow>
            <InputLabel className={classes.textLabel}>{t("edrc")}</InputLabel>
            </Tooltip>
        </Grid>
        <Grid item xs={4} alignContent="center" style={{display:'flex'}}>
            <div className={classes.div}>
            <Tooltip title={device.deviceName} arrow>
            <InputLabel className={classes.textLabel}>{device.deviceName}</InputLabel>
            </Tooltip>
            </div>
        </Grid>
    </Grid>);
    }

    return <Dialog disableBackdropClick
        open={true}
        classes={{ paper: classes.root }}
        onClose={handleClose}
        scroll="paper"
    >
        <EDRDialogTitle >
        <div style={{ display: 'flex', flexDirection: 'row'}}>
            <Typography variant="h6" className={classes.title} style={{display:'flex',alignItems:'center'}}>{t('networksetting')}</Typography>
            {device !== undefined ? <img style={{marginLeft:'5px'}} src={device.os=== DevicePlatform.UNIX ?"/assets/images/editDeviceIcons/linuxIcon.svg":"/assets/images/editDeviceIcons/windowsIcon.svg"}></img>:null}
        </div>
        </EDRDialogTitle>
        <DialogContent dividers={true}>
        <Grid item container direction="row">
        <div style={{ display: 'flex', flexDirection: 'row',paddingBottom:'5%'}}>
            <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{useThirdDevice ? Info('subThirdDevice')+deviceName: Info('subNetwork')+deviceName}</Typography>
            <Tooltip title={useThirdDevice ? t("thirdDeviceInfo"): t("networkInfo")} arrow>
                <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
            </Tooltip> 
            <Divider className={classes.divider}/>
        </div>
        </Grid>

        <Grid item xs={12}>
            <div className={classes.blueDiv}>
                    <Grid item container direction="row" alignContent="center">
                        <InputLabel style={{marginLeft:'2%',marginTop:'1%' ,lineHeight:1.9 }} >{useThirdDevice ? t('thirddivInfo'): t('netowrkdivInfo')}</InputLabel>
                        <img src={useThirdDevice ? '/assets/images/editDeviceIcons/testdrImage.svg':'/assets/images/editDeviceIcons/networkImage.svg'} style={{marginLeft:useThirdDevice ?'14%':'30%',width:useThirdDevice ?'60%':'35%'}}></img>
                    </Grid>
                    {loadTextBased()}
                    </div>
        </Grid>

        <Grid item container xs={12}>
                <Grid item xs={10}>
                <div style={{width: '100%',display: 'flex',justifyContent: 'space-between',marginTop:'8px'}}>
                    <ListItemText >{t("switchNetworkThird")}</ListItemText>
                </div>
                </Grid>
                <Grid item xs={2}>
                <div className={classes.centerVertical} style={{justifyContent:'flex-end'}}>
                    <Switch checked={useThirdDevice} name="switch"  onChange={handleSwitchNetwork} onBlur={form.handleBlur} color='primary' />
                </div>
                </Grid>  
        </Grid>

        <Grid item xs={12}>
					<FormControl className={classes.formControl} variant='outlined'>
					<EDRTextField id="3rddevice"
					name="3rddevice"
					size="small"
					label={useThirdDevice ? t("thirdDevice"):t("networkLabel")}
					disabled={!useThirdDevice}
					value={form.values.thirdDevice}
					onChange={handleChange3rdDevice}
					onBlur={form.handleBlur}
					error={form.touched && form.touched.thirdDevice && form.errors && Boolean(form.errors.thirdDevice)}
					helperText={form.touched && form.touched.thirdDevice && form.errors && Boolean(form.errors.thirdDevice) && t(form.errors.thirdDevice)}
					margin="normal"
					InputLabelProps={{
					shrink: true,
					}}
					variant="outlined">
					</EDRTextField>
					</FormControl>
		</Grid>
        </DialogContent>

        <DialogActions>
        <Grid item container direction="row">
            <Grid item xs={7}>
                {loadConfirmation()}
            </Grid>
            <Grid item xs={5} style={{display:'flex',justifyContent:'flex-end',paddingRight:'3%'}}>
            <Button onClick={handleClose} color="primary" style={{height:'25px',textTransform:'none'}}>
                {t('Cancel')}
            </Button>
            <Button onClick={handleTest} color="primary" style={{height:'25px',textTransform:'none',marginLeft:'-11px'}}>
                {t('Test')}
            </Button>
            <SaveButton color="primary" variant="contained" disabled={!useThirdDevice ? false: (!form.isValid || form.values.thirdDevice ==="" ? true:false)} onClick={handleSave} style={{height:'25px',textTransform:'none'}}>
            {t("Save")}
            </SaveButton> 
            </Grid>
        </Grid>
       
        </DialogActions>

    </Dialog>
}

export default NetworkTestSettingDialog;
