import { Button, Card, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { relative } from 'path';
import { useTranslation } from 'react-i18next';

const InfoCloneComponent: React.FC = () => {

    const useStyles = makeStyles({
        Image:{
            objectFit: 'contain',
            marginTop:'16px',
            marginBottom:'16px',
            marginLeft:'10px'
        },
        card: {
            padding: 16,
            height:'100%',
            marginTop: '-14px',
            marginLeft: '-15px'
        },
        HeaderDR:
        {
            fontFamily:'Roboto',
            fontWeight:500,
            fontSize:'15px',
            fontStretch:'normal',
            fontStyle: 'normal',
            lineHeight:'1.14',
            letterSpacing:'normal',
            textAlign:'left',
            marginLeft:'9px'
        },
        TextDR:{
            marginTop:'10px',
            color: '#8092a9',
            margin: '9px 10px 20px',
            fontSize:'14px',
            fontFamily:'Roboto',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.30',
            letterSpacing: 'normal',
            textAlign: 'left'
        },
        backgroundDiv:{
            marginLeft:'10px',
            marginTop: '16px',
            width: '112px',
            height: '144px',
            borderRadius: '4px',
            border: 'solid 1px rgba(40, 146, 215, 0.25)',
            backgroundColor: 'rgba(40, 146, 215, 0.05)'
        }
        });
        
    const classes = useStyles();
    const [t] = useTranslation("createJobNetwork");
    const [AllInfoDisplay, setAllInfoDisplay] = useState(false);

    const ExpandToAllInfo =()=>{
        setAllInfoDisplay(true);
    }

    const ShrinkToMinInfo =()=>{
        setAllInfoDisplay(false);
    }

    const loadInfo =()=>{
        return(
            <React.Fragment>
                <Typography variant='body1' className={classes.TextDR} style={{top:'58%'}}>
                {t('infoClonePart2')}
                </Typography>
                <Typography variant='body1' className={classes.TextDR} style={{top:'58%'}}>
                {t('infoClonePart3')}
                </Typography>
            </React.Fragment>
        )
    }

    return(
        <Card elevation={1} classes={{root: clsx([classes.card])}}>
            <Grid item container style={{paddingBottom: 16}}>
                <Grid item container direction="column" style={{height: '100%', overflowY: 'scroll'}}>
                    <Typography color='primary' className={classes.HeaderDR}  style={{color: '#2892d7', marginTop:'16px'}}>{t('controllerClone')}</Typography>
                    <img src='/assets/images/createjob/controllerClone.svg' className={classes.Image}></img>
                    <Typography variant='body1' className={classes.TextDR}>{t('infoClonePart1')}</Typography>
                    {AllInfoDisplay ? loadInfo():null}
                    {!AllInfoDisplay ? <Button size="small" color="primary"  onClick={ExpandToAllInfo}>{t("showmore")}</Button>
                    :<Button size="small" color="primary" style={{paddingBottom:'10%'}} onClick={ShrinkToMinInfo}>{t("showless")}</Button>}
                </Grid>
                <Grid item>
                    
                </Grid>
            </Grid> 
        </Card>
    );
};

export default observer(InfoCloneComponent);