
declare class CancelablePromise<T> extends Promise<T> {
    declare cancel: () => void
  }
  

declare global {
  interface Promise<T> {
    asCancelable(cancelFunc: () => void): CancelablePromise<T>
  }

}


  Promise.prototype.asCancelable = function(cancelFunc: () => void) {
    
    const p = this as CancelablePromise<unknown>;
    p.cancel=cancelFunc;
    return p;
  }


export {
    CancelablePromise,
    
}
