import React, { useContext, useEffect, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/root.store';
import { Button, Dialog, DialogActions, DialogContent, Divider, FormControl, FormControlLabel, Grid, IconButton, Input, InputAdornment, InputLabel, makeStyles, OutlinedInput, Switch, TextField, Typography, useMediaQuery} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Theme from '../../../app/layout/Theme';
//import clsx from 'clsx';
//import ClearIcon from '@material-ui/icons/Clear';
//import ReactDOM from 'react-dom';
//import  VisibilityOff  from '@material-ui/icons/VisibilityOff';
//import Visibility from '@material-ui/icons/Visibility';
//import SettingsLogoComponent from '../../settings/SettingsLogo.component';
import { SaveButton } from "../../../app/common/saveButton";
import { EDRDialogTitle } from "../../../app/common/EDRDialogTitle";
//import { EDRTextField } from '../../../app/common/EDRTextField';
//import { EDROutlinedInput } from '../../../app/common/EDROutlinedInput';
import { useFormik } from 'formik';
import * as yup from 'yup';
//import { EDRAutocomplete } from '../../../app/common/EDRAutocomplete';
import SettingEmailSMTPComponent from '../../settings/SettingEmailSMTP.component';
import { EmailSettingSchema } from './../../settings/validation';
import { toast } from 'react-toastify';

type props = {
    onClose: () => void;
    open: boolean;
  }

const CustomEmailSettingsComponent : React.FC<props> = ({onClose, open}) => {
    
    const [t] = useTranslation("settings");
    const matchesSM = useMediaQuery(Theme.breakpoints.down("sm"));
    const rootStore = useContext(RootStoreContext);
    const { emailSettings, sendTestEmail, updateSettings } = rootStore.settingStore;

    const [passwordVisibility, setPasswordVisibility] = useState(true);
    const [orgSMTPstate, setorgSMTPstate] = useState("organization");
    const [cancel,setCancel]=useState(false);
    let labelRef;
    const useStyles = makeStyles({
      centerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between'
    },
    toggleItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiListItemText-root' : {
            marginLeft: 10
        },
        '& .MuiSvgIcon-root': {
            color: '#8092a9'
        }
    },
          inputLabel: {
            fontFamily:'Roboto',
            fontWeight:500,
            fontSize:'12px',
            fontStretch:'normal',
            fontStyle: 'normal',
            lineHeight:'1.14',
            letterSpacing: '0.13px',
            textAlign:'left',
            color:'#2892d7',
            marginLeft:'10px'
        },
          label: {
            textTransform: 'capitalize',
          },
        advanced: {
            width: '14px',
            height: '14px',
            marginTop:'-1%',
            objectFit: 'contain',
          },
    });   
    const classes = useStyles(); 
    const form = useFormik({
      initialValues: {
          emailtype: "",
          emailSettings: {}
      },
      validationSchema:yup.object({
              emailtype: yup.string(), 
              emailSettings: yup.object().when("emailtype",{ is: 'system', then: yup.object(), otherwise: EmailSettingSchema})
      }),
      validateOnMount: false,
      onSubmit: () => { },
    })
      
    useEffect(() => {
      if(open) {
       const values=  form.values;
       form.resetForm({values});
      }
   }, [open])

    const handleClose = () => {
    if(form.touched && form.dirty)
        setCancel(!cancel);
      else
        onClose();
  }

    const handleSaveClick =() => {
            if (form.isValid){
              let values = { Settings: [] };
              emailSettings.forEach(key=>{
                values.Settings.push(key);
              })
              if (JSON.stringify(values) !='{"Settings":[]}'){
                  updateSettings(values).finally(
                    () => {
                      toast.success(t("updateOK"))
                      rootStore.settingStore.settingsUpdateError ?
                      //toast.error(t("updateFailed"))
                      console.error("email update failed")
                      :
                      rootStore.settingStore.settingUpdateSuccess ?
                         //toast.error(t("updateFailed"))
                        console.log("email update success")
                        : console.error("email update failed")
                    }
                  )
                }
            }
            else {
              toast.info(t("validateFirst"))
            }
            setCancel(false);
            onClose();
    }

    const loadConfirmation=()=>{
      if(cancel)
      {
          return (
              <React.Fragment>
                 <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                      <div className={classes.centerVertical}>
                          <InputLabel style={{marginLeft:'10px'}}>{t('disacrdChanges')}</InputLabel>
                          <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                          <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                      </div>
                  </div> 
              </React.Fragment>
          );
      }
  }

  const handleYes=()=>{
    // //updateEmailObj("","LocalSMTPAddress","CustomEmail");
    // form.setFieldValue("smtpAddress","");
    // updateEmailObj("","LocalSMTPPort","CustomEmail");
    // form.setFieldValue("smtpPort",25);
    // updateEmailObj("","EmailFrom","CustomEmail");
    // form.setFieldValue("emailFrom","");
    // updateEmailObj("","SMTPUser","CustomEmail");
    // form.setFieldValue("smtpUser","");   
    // updateEmailObj("","SMTPPassword","CustomEmail");
    // form.setFieldValue("smtpPassword","");
    // setorgSMTPstate("organization");   
    setCancel(false);
    onClose();
  }

  const handleNo=()=>{
      setCancel(!cancel);
  }
    
  // const handleClickShowPassword = () => {
  //   setPasswordVisibility(passwordVisibility ? false : true);
  // };

  const handleTestEmail =(event,key) => {
      //updateEmailObj(rootStore.createJobStore.jobReportSetting.emailTo,"EmailTo","CustomEmail");
      sendTestEmail(key,false);
  }


      return <Dialog disableBackdropClick
      open={open}
      onClose={handleClose}
      scroll="paper"
      style={{ zIndex: 1302, minHeight: 600}}
      maxWidth="md"          
      fullWidth={true}
    >
            <EDRDialogTitle >{t('createJobEmailHeader')}</EDRDialogTitle>
            <DialogContent style={{height: '40vh', width: '100%'}}  dividers={true} >
            <SettingEmailSMTPComponent emailType={'organization'} onChange={val => form.setFieldValue('emailSettings', val)} />
            </DialogContent>
            <DialogActions style={{marginRight:'35px'}}>
            <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end'}}>
            <Button onClick={handleClose} color="primary" style={{textTransform:'capitalize',paddingRight:'6%'}}>
                {t("cancel")}
            </Button>
            <Grid item style={{display:'flex', marginTop:'10px'}}>
              <img className={classes.advanced} src={'/assets/images/createjob/envelope.svg'}></img>
              <InputLabel className={classes.inputLabel} style={{cursor:'pointer'}} onClick={(event) => {handleTestEmail(event,'organization')}}>{t('testEmail')}</InputLabel>
            </Grid>
            <SaveButton color="primary" variant="contained" disabled={!form.isValid} onClick={handleSaveClick} style={{textTransform:'none',marginLeft:'5%'}}>
                {t("save")}
            </SaveButton>
            </Grid>
        </DialogActions>
        </Dialog>

}

export default observer(CustomEmailSettingsComponent);