 import React, { useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/root.store';
import { Button, Chip, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputLabel, makeStyles, MenuItem, Select, Switch, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DataMover } from '../../../app/models/BaseSettings.enum';
import { useFormik } from 'formik';
import * as yup from 'yup';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Create from '@material-ui/icons/Create';
import { useLocation } from 'react-router';
import { UpdateType } from '../../../app/stores/activityStore';
import { CancelablePromise } from '../../../app/common/util/cancellablePromise';
import { toast } from 'react-toastify';

const DataMoverComponent: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {createJobStore,jobSettingsStore,displayStore,activityStore} = rootStore;
  //const DataMoverOptions = jobSettingsStore.dataMoverList;

  const selectedDataMover = jobSettingsStore.selectedDataMover;
  const isPaaS= createJobStore.baseSettings.dataMover==  DataMover.PaaS || DataMover.ASR;
  const isAzure = createJobStore.baseSettings.dataMover==  DataMover.ZertoAzure;
  const [t] = useTranslation("createJobBase");
  const location = useLocation();
  const dataMover = createJobStore.baseSettings.dataMover

  const isEditMode = () => {
    return location.pathname.includes("edit");
  }
const useStyles = makeStyles(() => ({
  editIcon: {
    cursor: 'pointer'
  },
  button: {
    height: '100%',
    borderRadius: '4px',
    border: 'solid 1px #c7cfdb',
    backgroundColor: '#fafafc'
  },
  advanced: {
    width: '14px',
    height: '14px',
    objectFit: 'contain',
  },
  formControl: {
    width: '100%',
    '& label': {
      background: "white",
      width: "auto",
      padding: "0 4px 0 4px",
      fontSize: '17px'
    }
  }
}));
const classes = useStyles();

  useEffect(() => {
   
    let initialValues;
    initialValues =
    {
      datamoverId:createJobStore.baseSettings.dataMoverId,
    };
   
    form.setValues(initialValues, true);
  }, [createJobStore.baseSettings,jobSettingsStore.dataMoverList]);







const validateDataMover: () =>CancelablePromise<boolean> = () => {
  let cancelled = false;
  return new Promise<boolean>((res, rej) => {
    const {selectedInfra, selectedDataMover} = jobSettingsStore;
    const {baseSettings: {dataMover}} = createJobStore;
    if(dataMover == DataMover.ASR)
      {
        res(Boolean(selectedDataMover.id));
        return;
      }
    if(dataMover == DataMover.VeeamSQL) {
      if(!selectedDataMover?.id) 
      {
        res(false);
        return;
      }
    } else {
      if(!selectedDataMover?.id || !selectedInfra?.id) 
      {
        res(false);
        return;
      }
    }

      const targetValidation =  jobSettingsStore.authenticateDataMover(selectedDataMover, false).then(res => {
        if(cancelled)
          return;
        if(res) {
          toast.success(`Successfully autheticated to ${selectedDataMover.dataMoverTarget}`);
        } else {
          toast.error(`Failed to autheticate to ${selectedDataMover.dataMoverTarget}`);
        }
        return res;
      }) ;

      const sourceValidation = dataMover !== DataMover.VeeamSQL ? jobSettingsStore.authenticateDataMover(selectedDataMover, true).then(res => {
        if(cancelled)
          return;
        if(res) {
          toast.success(`Successfully autheticated to ${selectedDataMover.dataMoverSource}`);
        } else {
          toast.error(`Failed to autheticate to ${selectedDataMover.dataMoverSource}`);
        }
        return res;
      }): Promise.resolve(true);

       Promise.allSettled([targetValidation, sourceValidation]).then(([targetValid, sourceValid]) => {
         if(targetValid.status === 'rejected' || sourceValid.status === 'rejected') {
           res(false);
           return;
         } else {

           res(targetValid.value || sourceValid.value);
         }


       });

  }).asCancelable(() => {
    cancelled =true;
  })
}


useEffect(() => {

  let pendingPromise: CancelablePromise<any> = null;
  let cancelled = false;
  const validate = () => {
    if(!displayStore.nextStepValid.dmValid) {
      displayStore.updateScreenSettings({DMApplyProcessing:true});
      displayStore.updateNextStepValid({dmValid: false});
     pendingPromise = validateDataMover();
     pendingPromise.then(res => {
       if(!cancelled)
        displayStore.updateNextStepValid({dmValid: res});
    }).finally(() => {
     displayStore.updateScreenSettings({DMApplyProcessing:false})
    })
    }
  }
  activityStore.on(UpdateType.ReValidate, validate);
  return () => {
      cancelled = true;
      pendingPromise && pendingPromise.cancel();
      activityStore.off(UpdateType.ReValidate,validate);
  }
}, [])


useEffect(() => {
  let pendingPromise: CancelablePromise<any> = null;
  let cancelled = false;

  let timer = setTimeout(() => {
    if(cancelled)
      return;
      displayStore.updateScreenSettings({DMApplyProcessing:true});
      displayStore.updateNextStepValid({dmValid: false});
      pendingPromise = validateDataMover();
      pendingPromise.then(res => {
        if(!cancelled)
          displayStore.updateNextStepValid({dmValid: res});
      }).finally(() => {
        displayStore.updateScreenSettings({DMApplyProcessing:false})
      })
  }, 500);


  return () => {
    cancelled = true;
    pendingPromise?.cancel();
    clearTimeout(timer);
  }

}, [selectedDataMover?.id])


  // select first element by default when in create mode
  useEffect(() => {
    displayStore.updateScreenSettings({DMApplyProcessing:true});
    jobSettingsStore.getDataMovers().then(res => {
        if(jobSettingsStore.dataMoverList.length == 0)
        return;
      
        const dm = jobSettingsStore.dataMoverList.find(d => d.id == createJobStore.baseSettings.dataMoverId);
        if(!dm) {
            form.setFieldValue("datamoverId", jobSettingsStore.dataMoverList[0].id);
            createJobStore.updateBaseSettings({dataMoverId:jobSettingsStore.dataMoverList[0].id});
            jobSettingsStore.updateSelectedDataMover(jobSettingsStore.dataMoverList[0]);
        } 
        else 
        {
            form.setFieldValue("datamoverId",dm != undefined && dm != null ? dm.id : 0);
            jobSettingsStore.updateSelectedDataMover(dm != undefined && dm != null ? dm : null);
        }
    })
    displayStore.updateScreenSettings({DMApplyProcessing:false});
    
  }, [])

useEffect(() => {
  if(createJobStore.PlatformChanged)
    form.resetForm();
    
},[createJobStore.PlatformChanged]);

const validation = yup.object({
 datamoverId: yup.number().required().min(1, "Required")
})

const form = useFormik({
    initialValues: 
    {
        datamover: '',
        datamoverId: 0,
    },
    validationSchema:validation,
    validateOnChange:true,
    onSubmit: () => { },
    
})

const buttonRedirect= (subComponent:any)=> {
  switch(subComponent)
  {
    case "DataMoverAdvanced":
      {
        if(displayStore.BaseDrawerSettings.InfraDrawerOpen || displayStore.BaseDrawerSettings.DataMoverDrawerOpen)
      {
        if(displayStore.BaseDrawerSettings.DataMoverDrawerOpen)
        {
        if(displayStore.BaseDrawerSettings.addDataMover)
        {
          displayStore.updateScreenSettings({displayBaseSettings: subComponent});
          displayStore.updateBaseDrawerSettings({addDataMover: false});
          displayStore.updateBaseDrawerSettings({DataMoverDrawerOpen: true});
          displayStore.closeAllOtherBaseDrawersExcept("DataMoverDrawer");
        }
        else
        {
          displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
          displayStore.updateBaseDrawerSettings({DataMoverDrawerOpen: false});
        }
      }
      else
      {
        displayStore.updateScreenSettings({displayBaseSettings: subComponent});
        displayStore.updateBaseDrawerSettings({addDataMover: false});
        displayStore.updateBaseDrawerSettings({DataMoverDrawerOpen: true});
        displayStore.closeAllOtherBaseDrawersExcept("DataMoverDrawer");
      }
      }
      else
      { 
        displayStore.updateScreenSettings({displayBaseSettings: subComponent});
        displayStore.updateBaseDrawerSettings({addDataMover: false});
        displayStore.updateBaseDrawerSettings({DataMoverDrawerOpen: true});
        displayStore.closeAllOtherBaseDrawersExcept("DataMoverDrawer");
      }  
      break;
      }
  }
};

useEffect(() => {
  displayStore.updateNextStepValid({dmValid: form.isValid});
},[form.isValid]) 


const handleDataMoverChange =(event: any)=>{
  if(event != null)
  {
    if(displayStore.BaseDrawerSettings.DataMoverDrawerOpen)
    {
      displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
      displayStore.updateBaseDrawerSettings({DataMoverDrawerOpen: false});
    }  
    form.setFieldValue("datamoverId",event.target.value);
    let dm = jobSettingsStore.dataMoverList.find(l => l.id === event.target.value);
    jobSettingsStore.updateSelectedDataMover(dm);
    // jobSettingsStore.authenticateDataMover(dm,false);
    // if(createJobStore.baseSettings.dataMover != DataMover.VeeamSQL)
    //     jobSettingsStore.authenticateDataMover(dm,true);
    rootStore.createJobStore.updateBaseSettings({dataMoverId: event.target.value});
  }
  
}

const handleAddDataMover =()=>{
  displayStore.updateScreenSettings({displayBaseSettings: "DataMoverAdvanced"});
  displayStore.updateBaseDrawerSettings({addDataMover: true});
  displayStore.updateBaseDrawerSettings({DataMoverDrawerOpen: true});
  displayStore.closeAllOtherBaseDrawersExcept("DataMoverDrawer");
}

const loadDataMoverRow=()=>{
  return(
    <React.Fragment>
  <Grid container item direction='row' spacing={2} style={{marginTop:'8px'}}>
    <Grid item xs={1} style={{display:'flex',alignItems:'center'}}>
      <IconButton disabled={true} onClick={()=>handleAddDataMover()}>
        <AddCircleOutlineIcon />
      </IconButton>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={11}>
        <FormControl className={classes.formControl} variant='outlined' style={{backgroundColor:'#ffffff',paddingTop:'1%',width:'97%'}}>
          <InputLabel style={{transform:'translate(14px, 2px) scale(0.75)'}}>{displayStore.screenSettings.DMApplyProcessing ?loadCircularProgress(): (createJobStore.baseSettings.dataMover ==  DataMover.ASR ?t('recoveryFault'):t('datamover'))}</InputLabel>
		      <Select
          required={true}
          name="datamoverId"
          onBlur={form.handleBlur}
          labelId={createJobStore.baseSettings.dataMover ==  DataMover.ASR ?t('recoveryFault'):t('datamover')}
          disabled={true}
          value={form.values.datamoverId}
          error={form.touched?.datamoverId && Boolean(form.errors?.datamoverId)}
          // renderValue={name => name<0? 'None': name}
          onChange={handleDataMoverChange}>
          {jobSettingsStore.dataMoverList.map((dm) => (
          <MenuItem key={dm.id} value={dm.id}>
          {dataMover == DataMover.CohesityRunbook  || dataMover == DataMover.ZertoVmwareAzure ? dm.dataMoverTarget :`${dm.dataMoverSource} --> ${dm.dataMoverTarget}`}
          </MenuItem>))}
          </Select>
          <em style={{fontSize: 12, fontStyle:"italic", color: "red"}}>{form.touched?.datamoverId && form.errors.datamoverId}</em>
        </FormControl>
        </Grid>

        <Grid item xs={1} style={{paddingTop:'5px'}}>
          <Button  disabled={true}  className={classes.button} onClick={()=> {buttonRedirect('DataMoverAdvanced')}}>
          <Create className={classes.editIcon} ></Create>
          </Button>
        </Grid>

      </Grid>
    </Grid>
    </React.Fragment>
  );
}

const loadCircularProgress =()=>{
  return <div style={{width:'100%',display:'flex'}}>
  {t('DMInProgress')}
  <CircularProgress id="dmLoading" size={18} style={{color: '#2892D7', marginLeft: '10px',marginTop: '-3px'}}/>
  </div>
}

  return (
        <Grid container spacing={2}>
            {loadDataMoverRow()}
        </Grid>
  );
};

export default observer(DataMoverComponent)