import React, { useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, makeStyles, Tooltip, Typography } from '@material-ui/core';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useTranslation } from 'react-i18next';
import { RootStoreContext } from '../../../../app/stores/root.store';
import ReactDOM from 'react-dom';
import { InfrastructureType } from '../../../../app/models/jobProperties.model';
import { EDROutlinedInput } from "../../../../app/common/EDROutlinedInput";
import { EDRAutocomplete } from "../../../../app/common/EDRAutocomplete";
import { EDRTextField } from "../../../../app/common/EDRTextField";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { INFRA_NAME } from '../../../../app/models/BaseSettings.enum';
import HelpIcon from '@material-ui/icons/Help';
import { DataMover } from '../../../../app/models/BaseSettings.enum';
import JobSettingsStore from '../../../../app/stores/jobSettings.store';

const InfrastructAdvancedComponent: React.FC = () => {

    let targetlabelRef;
    const useStyles = makeStyles({
        helpIcon:
        {
            color: '#1976d2',
            fontSize: "large"
        },
        formControl: {
            width: '100%',
            '& label': {
                background: "white",
                width: "auto",
                padding: "0 4px 0 4px",
                fontSize: '17px',
            }
        },
        MainHeading:
        {
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.19',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#1f396d',
            marginLeft: '10px'
        },
        SecondHeading: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1
        },
        divider: {
            backgroundColor: '#eaeef4',
            width: '290%',
            margin: '0',
            marginTop: '10px',
            marginLeft: '10px',
        },
        button: {
            borderRadius: 15,
            color: 'white',
            backgroundColor: '#2892d7',
            width: '23%',
            height: '32px',
            marginTop: '24px',
            marginLeft: '485px'
        }
    });

    const classes = useStyles();
    const [t] = useTranslation("createJobBase");
    const rootStore = useContext(RootStoreContext);
    const {jobSettingsStore} = rootStore;
    const { dataMover } = rootStore.createJobStore.baseSettings;
    const [sourcepasswordVisibility, setSourcePasswordVisibility] = useState(true);
    const [UserPassAutocomplete, setUserPassAutocomplete] = useState(false);
    const [showEyeSourcePassword, setShowEyeSourcePassword] = useState(true);
    const portRegex = /^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/;
    const previousSourceUsername = jobSettingsStore.sourceUserNamesAndPass;
    let tempInfra = jobSettingsStore.tempInfra;

    function setInitialPort() {
        if (rootStore.createJobStore.baseSettings.infrastructureType == InfrastructureType.VMware)
            return 902;
        else
            return 0;
    }

    const validation = yup.object({
        infraSourceUsername: yup.string()
            .when('dataMover', {
                is: DataMover.SRM,
                then: yup.string(),
                otherwise: yup.string()
                    .nullable()
                    .required("Required")
                    .max(50, 'username max length is 50 characters')
            }),

        infraSourcePassword: yup.string().when('dataMover', {
            is: DataMover.SRM,
            then: yup.string(),
            otherwise: yup.string().required('Required')
                .max(50, 'up to 50 characters')
        }),

        infraSourcePort: yup.string()
            .max(6, 'port max length is 5')
            .matches(portRegex, "port is invalid"),

        infraTargetPort: yup.string()
            .max(6, 'port max length is 5')
            .matches(portRegex, "port is invalid")
    })

    const form = useFormik({
        initialValues: {
            infraSourceUsername: '',
            infraSourcePassword: '',
            infraSourcePort: setInitialPort(),
            infraTargetPort: setInitialPort(),
            dataMover: null
        },
        validationSchema:
            validation,
        onSubmit: () => { },
    })

    useEffect(() => {
        const initialValues =
        {
            infraSourceUsername: tempInfra.sourceInfraCredentials.userName,
            infraSourcePassword: tempInfra.sourceInfraCredentials.password,
            infraSourcePort: tempInfra.infrastructureSourcePort == 0 ? setInitialPort() :  tempInfra.infrastructureSourcePort,
            infraTargetPort: tempInfra.infrastructureTargetPort == 0 ? setInitialPort() : tempInfra.infrastructureTargetPort,
            dataMover: rootStore.createJobStore.baseSettings.dataMover
        };
        form.setValues(initialValues);
    }, []);

    const handleClickShowPassword = () => {
        setSourcePasswordVisibility(sourcepasswordVisibility ? false : true);
    };

    const handleSourceUsernameChange = (event: React.ChangeEvent, value: any) => {
        let onInput = event.type == "click" ? false : true;
        setUserPassAutocomplete(onInput);
        if (value != null && typeof value === 'object') {
            form.setFieldValue("infraSourceUsername", value.username);
            form.setFieldValue("infraSourcePassword", value.password);
            rootStore.jobSettingsStore.updateTempInfra({ sourceInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.sourceInfraCredentials, userName: value.username, password:value.password  } });
        }
        if (typeof value === 'string') {
            const userObj = previousSourceUsername.find(e => e.username.toLocaleLowerCase() == value.toLocaleLowerCase());
            form.setFieldValue("infraSourceUsername", userObj? userObj.username : value);
            let pass = !userObj? '' : userObj.password;
            form.setFieldValue("infraSourcePassword", pass);
            rootStore.jobSettingsStore.updateTempInfra({ sourceInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.sourceInfraCredentials, userName: value, password:pass  } });
        }
        setShowEyeSourcePassword(onInput);
    };

    const handleSourcePassChange = (event) => {
        setUserPassAutocomplete(true);
        setShowEyeSourcePassword(true);
        rootStore.jobSettingsStore.updateTempInfra({ sourceInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.sourceInfraCredentials, password:event.target.value  } });
        
    };
    
    const handleSourcePasswordOnBlur = () => {
        if(tempInfra.infrastructureSource && tempInfra.sourceInfraCredentials.userName && tempInfra.sourceInfraCredentials.password)
        {
            rootStore.jobSettingsStore.authenticateVCenterSource();
            jobSettingsStore.listNetworkTupples(); 
        }
    }

    const handleSourcePortChange = (event) => {
        form.setFieldValue("infraSourcePort",parseInt(event.target.value));
        rootStore.jobSettingsStore.updateTempInfra({infrastructureSourcePort:event.target.value})
    };

    const handleTargetPortChange = (event) => {
        form.setFieldValue("infraTargetPort",parseInt(event.target.value));
        rootStore.jobSettingsStore.updateTempInfra({infrastructureTargetPort:event.target.value})
    };

    const handleValueChange = (event: React.ChangeEvent) => {
        form.handleChange(event);
        switch (event.target['name']) {
            case 'infraSourcePassword': handleSourcePassChange(event); break;
            case 'infraSourcePort': handleSourcePortChange(event); break;
            case 'infraTargetPort': handleTargetPortChange(event); break;
        }
    }

    const handleAutoCompleteChange = (event: React.ChangeEvent, value: any) => {
        if (event != null) {
            let fieldName = event.target['id'].includes('-') ? event.target['id'].split('-')[0] : event.target['id'];
            // form.setFieldValue(fieldName, value);
            switch (fieldName) {
                case 'infraSourceUsername': handleSourceUsernameChange(event, value); break;
            }
        }
        
    }

    const loadSourceSettings = () => {
        switch (rootStore.createJobStore.baseSettings.dataMover) {
            default:
                {
                    return (
                        <React.Fragment>
                            {dataMover !== DataMover.SRM && <Grid container style={{ marginTop: '16px' }} spacing={2}>
                                <Grid item xs={6}>
                                <Tooltip title={t('infraUserPassInfo')} placement="top">
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <EDRAutocomplete id="infraSourceUsername"
                                            freeSolo
                                            value={form.values.infraSourceUsername}
                                            options={previousSourceUsername}
                                            renderOption={(option: string | { username: string, password: string }) => (typeof option === 'string' ? option : `${option.username}`)}
                                            getOptionLabel={(option: string | { username: string, password: string }) => (typeof option === 'string' ? option : `${option.username}`)}
                                            onChange={handleAutoCompleteChange}
                                            onInputChange={handleAutoCompleteChange}
                                            renderInput={(params) => (<EDRTextField {...params}
                                                label={t('username')}
                                                InputLabelProps={{ shrink: true }}
                                                margin="normal"
                                                variant="outlined"
                                                placeholder="Type Here"
                                                style={{ margin: 8, backgroundColor: '#ffffff' }}
                                                onBlur={form.handleBlur}
                                                error={form.touched.infraSourceUsername && Boolean(form.errors.infraSourceUsername)}
                                                helperText={form.touched.infraSourceUsername && form.errors.infraSourceUsername && t(form.errors.infraSourceUsername)}
                                            />)}
                                        />
                                    </FormControl>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6}>
                                <Tooltip title={t('infraUserPassInfo')} placement="top">
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <InputLabel ref={ref => { targetlabelRef = ReactDOM.findDOMNode(ref) }} style={{ transform: 'translate(14px, 2px) scale(0.75)' }}>{t('password')}</InputLabel>
                                        <EDROutlinedInput id="infraSourcePassword"
                                            name="infraSourcePassword"
                                            value={form.values.infraSourcePassword}
                                            autoComplete="new-password"
                                            type={sourcepasswordVisibility ? 'password' : 'text'}
                                            placeholder="Type Here"
                                            label={<InputLabel style={{ marginTop: '8px' }}>{t('password')}</InputLabel>}
                                            style={{ margin: 8, backgroundColor: '#ffffff' }}
                                            error={form.touched.infraSourcePassword && Boolean(form.errors.infraSourcePassword)}
                                            onChange={handleValueChange}
                                            onBlur={handleSourcePasswordOnBlur}
                                            endAdornment={showEyeSourcePassword && UserPassAutocomplete ?
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}>
                                                        {sourcepasswordVisibility ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton></InputAdornment> : null} />
                                    </FormControl>
                                    </Tooltip>
                                </Grid>
                            </Grid>}
                            <Grid item xs={3} style={{ marginTop: '16px' }}>
                                {/*<FormControl className={classes.formControl} variant='outlined'>
                                    <EDRTextField id="infraSourcePort"
                                        name="infraSourcePort"
                                        label={t('port')}
                                        placeholder=""
                                        style={{ margin: 8 }}
                                        value={form.values.infraSourcePort}
                                        onChange={handleValueChange}
                                        onBlur={form.handleBlur}
                                        error={Boolean(form.errors.infraSourcePort)}
                                        helperText={t(form.errors.infraSourcePort)}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined">
                                    </EDRTextField>
                                    </FormControl>*/}
                            </Grid>
                        </React.Fragment>
                    )
                }
        }
    };

    const loadTargetSettings = () => {
        switch (rootStore.createJobStore.baseSettings.dataMover) {
            default:
                {
                    return (
                        <React.Fragment>
                            <Grid item xs={3} style={{ marginTop: '16px' }}>
                                {/*<FormControl className={classes.formControl} variant='outlined'>
                                    <EDRTextField id="infraTargetPort"
                                        name="infraTargetPort"
                                        label={t('port')}
                                        placeholder=""
                                        style={{ margin: 8, backgroundColor: '#ffffff' }}
                                        value={form.values.infraTargetPort}
                                        onChange={handleValueChange}
                                        onBlur={form.handleBlur}
                                        error={Boolean(form.errors.infraTargetPort)}
                                        helperText={t(form.errors.infraTargetPort)}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined">
                                    </EDRTextField>
                                    </FormControl>*/}
                            </Grid>
                        </React.Fragment>
                    )
                }
        }
    };

    return (
        <Grid container direction="column" style={{ paddingRight: 16 }}>

            <Typography variant='h6' className={classes.MainHeading}>{t('infrastrucutreSettings')}</Typography>

            <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '30px', marginLeft: '10px' }}>
                    <Typography className={classes.SecondHeading} style={{ color: '#4d5f75' }}>{t('sourceSettings')}</Typography>
                    <Tooltip title={t("sourceSettingsInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider} />
                </div>
            </Grid>
            {loadSourceSettings()}
            {/*<Grid container item direction="row">
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '30px', marginLeft: '10px' }}>
                    <Typography className={classes.SecondHeading} style={{ color: '#4d5f75' }}>{t('targetSettings')}</Typography>
                    <Tooltip title={t("targetSettingsInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider} />
                </div>
            </Grid>*/}
            {loadTargetSettings()}
        </Grid>
    );
};


export default observer(InfrastructAdvancedComponent);