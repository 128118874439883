
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Divider, Grid, InputAdornment, InputLabel, makeStyles, Tooltip, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EDRTextField } from "../../../../app/common/EDRTextField";
import { SaveButton } from "../../../../app/common/saveButton";
import { EDRDialogTitle } from "../../../../app/common/EDRDialogTitle";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EDRAutocomplete } from '../../../../app/common/EDRAutocomplete';
import { RootStoreContext } from '../../../../app/stores/root.store';
import { TestType } from '../../../../app/models/jobTest.model';
import { PowerupSettingProps, TestSettingProps } from './customTestSetting.component';
import { DatabaseType, DevicePlatform, MachineType } from '../../../../app/models/jobProperties.model';
import HelpIcon from '@material-ui/icons/Help';
import { toast } from 'react-toastify';
import { compressFile } from '../../../../app/common/util/util';
import DeviceBarComponent from '../../../dashboard/devicesPanel/DeviceBar.component';
import jobAgent from '../../../../app/api/jobAgent';

const useStyles = makeStyles(theme => ({
    helpIcon:
    {
      color: '#1976d2',
      fontSize: "large"
    },
    BrowseButton:{
        backgroundColor: '#fafafc',
        textTransform: 'none',
    },
    SecondHeading:{
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        flexGrow: 1
    },
    divider: {
        backgroundColor: '#eaeef4',
        maxWidth: '100%',
        margin: '0',
        marginTop: '10px',
        marginLeft: '10px',
    },
    centerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between'
    },
    toggleItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiListItemText-root' : {
            marginLeft: 10
        },
        '& .MuiSvgIcon-root': {
            color: '#8092a9'
        }
    },
    root: {
        minWidth: '30vw'
    },
    title:{
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontstyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        textAlign: 'left'
    },
    autoWithButton:{
        '& .MuiOutlinedInput-adornedEnd' : {
            paddingRight:'0px'
        }
    }
}))


const validationSchema = yup.object({
    script: yup.string()
})

const ScriptTestSettingDialog: React.FC<PowerupSettingProps> = (props) => {
    const { open,deviceName,isDefault, onClose,onSave } = props;
    const classes = useStyles();
    const { t } = useTranslation('createJobBase');
    const {createJobStore,jobSettingsStore} = useContext(RootStoreContext);
    const [device, setDevice] = useState(createJobStore.selectedDevices.find(device=>device.deviceName == deviceName));
    const [scriptTest,setScriptTest] =useState(device!= undefined ? device.tests.find(test=> test.testType == TestType.PreScriptRes):null);
    const getPreviousSettings = jobSettingsStore.scriptList;
    const [cancel,setCancel]=useState(false);
    const inputFile = useRef(null);
    const [ShowBrowse,setShowBrowse]= useState(device.scriptData!=null && device.scriptName!="" ?false:(device.scriptName!=""?false:true));
    const [processingTest,setProcessingTest] = useState(false);
    
    const defaultSettings = {
        script: device.os == DevicePlatform.Windows ? 'WindowsPowerup.ps1':'LinuxPowerup.sh'
    };

    const handleClose = () => {
        if(form.touched && form.dirty)
            setCancel(!cancel);
        else
            onClose(false);
    }

    const handleSave=()=>{
        try{
        let DeviceTest = {
                testCategory:scriptTest.testCategory, 
                testType: TestType.PreScriptRes, 
                selected: true,
                customCommandToTest: '',
                customCommandExpectedResult: '', 
                customCommandToTestIsExternal: false,
                databaseType: DatabaseType.SQL,
                databaseNameToTest: '',
                databaseQueryToTest: '',
                databaseServerNameToTest: '',
                databaseUsername:'',
                databasePassword: '',
                databasePort:'',
                dbWindowsAuth:false,
                serviceToTest:'',
                testFromEDRC: false,
                script: form.values.script,
                thirdDeviceNetworkToTest: '',
                authenticationUserToTest:'',
                authenticationPassToTest:'',
                webPortalToTest: '',
                webPortalResult: ''
        };
        createJobStore.updateTestDevice(TestType.PreScriptRes,DeviceTest,device.planName.includes("Custom Device") ? device.sourceName : device.deviceName);
        device.scriptName= form.values.script;
        device.scriptData= device.scriptData;
        createJobStore.updateDevice(device);
        toast.success('Save successful');
        setCancel(false);
        onSave();
        onClose(true);
    }
    catch(error){
        toast.success('Save failed'+error);
        setCancel(false);
        onSave();
        onClose(false);
    }  
    }

    const handleTest=() =>{        
        var details =
        {
            machineName:device.sourceName,
            scriptName:form.values.script,
            scriptData: device.scriptData,
            devicePlatform: device.os,
            id: device.os == DevicePlatform.UNIX ? createJobStore.baseSettings.workflowTest.SecondaryUserCredsId : createJobStore.baseSettings.workflowTest.PrimaryUserCredsId

        };
        setProcessingTest(true);
        jobAgent.JobTestsActions.TestPowerupScript(details).then(res => {
            if(res)
                toast.success("Script Test succeeded");
            else
                toast.error("Script Test failed");
            setProcessingTest(false);
        }).catch(err=>{
        if(err && err.data && err.data.errors)
            toast.error(err.data.errors);
        setProcessingTest(false);
        });           
    }

    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel style={{marginLeft:'10px'}}>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const handleYes=()=>{
        let initialValues = {
            script: scriptTest.script
        };
        form.setValues(initialValues);
        device.scriptName= scriptTest.script;
        device.scriptData= device.scriptData;
        createJobStore.updateDevice(device);
        setCancel(false);
        onClose(false);
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    const form = useFormik({
        initialValues: {
            script: defaultSettings.script
        },
        onSubmit: () => { },
        validationSchema
    })

    useEffect(() => {
        const newDevice = createJobStore.selectedDevices.find(device=>device.deviceName == deviceName);
        setDevice(newDevice);
        const newScriptTest = newDevice!= undefined ? newDevice.tests.find(test=> test.testType == TestType.PreScriptRes):null;
        setScriptTest(newScriptTest);
        let initialValues;
        initialValues =
        {
            script: newScriptTest && newScriptTest.script!="" ? newScriptTest.script: defaultSettings.script
        };
        form.resetForm({values: initialValues});
    }, [deviceName]);

    const handleAutoCompleteChange = (event: React.ChangeEvent,value:any) => {
        if(event!=null)
        {
          let fieldName= event.target['id'].includes('-') ? event.target['id'].split('-')[0]: event.target['id'];
          form.setFieldValue(fieldName,value);
          switch(fieldName) {
            case 'script': handleScriptChange(event); break;
          }
        }
    }
    
    const handleScriptChange=(event)=>{
        form.setFieldValue("script",event.target.value,true);
        if(event.target.value==='')
        {
            device.scriptData=null;
            device.scriptName='';
            form.setFieldValue('script','',true);
            setShowBrowse(true);
        }
        if(event.target.value!==null && event.target.value!=='')
            device.scriptData = event.target.files!=null ? compressFile(event.target.files[0]):null;
    }


    const handleFileUpload = e => {
        const { files } = e.target;
        if (files && files.length) 
        {
            let fileSizebytes=files[0].size/1024;
            const filename = files[0].name;
            var parts = filename.split(".");
            const fileType = parts[parts.length - 1];
            const fileTypePerOSInvalid = false; //OS === "windows" ? ((fileType !== 'bat' && fileType !=='ps1') ?true:false) : ((fileType !== 'py' && fileType !=='sh') ?true:false);
            let invalidFileSize = fileSizebytes > 512;
            if(!invalidFileSize && !fileTypePerOSInvalid)
            {
            form.setFieldValue("script",files[0].name);
            const toBase64 = file => new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = error => reject(error);
            });
            toBase64(files[0]).then(function(res) {
                device.scriptData = res;
            });
            setShowBrowse(false);
        }
        else
        {
            if(invalidFileSize)
                form.setErrors({ script: 'script size can not exceed 512KB' });
            else
                form.setErrors({ script: 'file type must be '});
        }
        }
    };

    const handleBrowseScript= ()=>{
        inputFile.current.click();
    }

    const removeFile =()=>{
        if(device!=undefined)
        {
            device.scriptData=null;
            device.scriptName='';
            form.setFieldValue('script','',true);
            setShowBrowse(true);
        }
    }

    return <Dialog disableBackdropClick
        open={open}
        classes={{ paper: classes.root }}
        onClose={handleClose}
        scroll="paper"
    >
        <EDRDialogTitle>
        <div style={{ display: 'flex', flexDirection: 'row'}}>
            <Typography variant="h6" className={classes.title} style={{display:'flex',alignItems:'center'}}>{t('scriptSetting')}</Typography>
            {device !== undefined ? <img style={{marginLeft:'5px'}} src={device.os=== DevicePlatform.UNIX ?"/assets/images/editDeviceIcons/linuxIcon.svg":"/assets/images/editDeviceIcons/windowsIcon.svg"}></img>:null}
        </div>
        </EDRDialogTitle>
        <DialogContent dividers={true}>
        <Grid item container direction="row">
        <div style={{ display: 'flex', flexDirection: 'row',paddingBottom:'5%'}}>
            <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('subScript')+deviceName}</Typography>
            <Tooltip title={t('scriptInfo')} arrow>
                <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
            </Tooltip> 
            <Divider className={classes.divider}/>
        </div>
        </Grid>

            <Grid container direction="column" spacing={1}>
            <input style={{ display: "none" }} ref={inputFile} type="file" onChange={(event)=> { 
               handleFileUpload(event) 
               event.target.value=null
            }}/>
                    <Grid item xs={12}>
                    <EDRAutocomplete id="script"
                        freeSolo
                        disableClearable
                        value={form.values.script}
                        options={getPreviousSettings}
                        className={classes.autoWithButton}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderOption={(option: string|{script: string}) => (typeof option === 'string' ? option : `${option.script}`)}
	                    getOptionLabel={(option: string|{script: string}) => (typeof option === 'string' ? option : `${option.script}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={t('uploadedScript')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="script"
                        placeholder="Uploaded Script"
                        onBlur={form.handleBlur}
                        error={form.touched.script && Boolean(form.errors.script)}
                        helperText={form.touched.script && form.errors.script}
                        InputProps ={{
                            endAdornment:(
                            <InputAdornment position="end">
                                {device!=undefined && ShowBrowse === true ?<Button className={classes.BrowseButton} size="small" onClick={handleBrowseScript} style={{height: '52px'}}>{ t("browse")}</Button>
                                :<Button size="small" className={classes.BrowseButton} onClick={removeFile} style={{height: '52px'}}>{t("remove")}</Button>}
                            </InputAdornment>
                            )
                        }}
                    />)}
                    />
                    </Grid>

            </Grid>
        </DialogContent>
        <DialogActions>
        <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end',marginRight:'3%'}}>
            <Button onClick={handleClose} color="primary" style={{textTransform:'none'}}>
                {t('cancel')}
            </Button>
            <Button onClick={handleTest} color="primary" disabled={form.values.script ==""||processingTest} style={{textTransform:'none',marginLeft:'-11px'}}>
            {processingTest ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                {t('Test')}
            </Button>
            <SaveButton onClick={handleSave} variant="contained" color="primary" style={{textTransform:'none'}} disabled={form.values.script === ''}>
                {t('save')}
            </SaveButton>
            </Grid>
        </DialogActions>
    </Dialog>
}

export default ScriptTestSettingDialog;
