import { Button, Card, CircularProgress, Divider, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField, Tooltip, Typography} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import HelpIcon from '@material-ui/icons/Help';
import { useTranslation } from 'react-i18next';
import { RootStoreContext } from '../../../app/stores/root.store';
import { DnsResolveType, InfrastructureType, JobType, LicenseType, NetworkOption, NetworkType } from '../../../app/models/jobProperties.model';
import jobAgent from '../../../app/api/jobAgent';
import { toast } from 'react-toastify';
import { runInAction } from 'mobx';
import { Autocomplete } from '@material-ui/lab';
import { EDRAutocomplete } from '../../../app/common/EDRAutocomplete';
import { EDRTextField } from '../../../app/common/EDRTextField';
import CreateJobStore from '../../../app/stores/createJob.store';
import ConfirmationDialog from '../../../app/common/confirmationDialog.component';
import Create from '@material-ui/icons/Create';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { NetworkTuppleDTO } from '../../../app/models/createjob/createJob.model';
import TupleComponent from './Tuple.component';
import TupleCreateComponent from './TupleCreate.component';
const useStyles = makeStyles((theme) => ({
    editIcon: {
        cursor: 'pointer'
    },
    button:{
        width: '50%',
        height: '100%',
        borderRadius: '4px',
        border: 'solid 1px #c7cfdb',
        backgroundColor: '#fafafc'
    },
    advanced:{
        width: '14px',
        height: '14px',
        objectFit: 'contain',
    },
    MainHeading:{
        fontFamily: 'Roboto',
        color: '#1f396d',
        fontSize: '16px'
    },
    SecondHeading:{
        fontFamily:'Roboto',
        fontWeight:500,
        fontSize:'100%',
        fontStretch:'normal',
        fontStyle: 'normal',
        lineHeight:'1.14',
        letterSpacing:'normal',
        textAlign:'left'
    },
    card: {
        padding:25,
        height: '100%',
        overflowY: "auto"
    },
    helpIcon:
    {
        color: '#1976d2',
        fontSize: "large"
    },
    formControl: {
        width: '100%',
        '& label': {
            background: "white",
            width: "auto",
            padding:"0 4px 0 4px",
            fontSize: '17px'
          }
    },
    divider:{
        backgroundColor: '#eaeef4',
        width:'85%',
        margin:'0',
        marginTop: '8px',
        marginLeft: '10px'
    }
}));    
const NetworkVlanComponent: React.FC = (props) => {
    const [t] = useTranslation("createJobNetwork");
    const rootStore = useContext(RootStoreContext);
    const {jobSettingsStore,createJobStore,displayStore} = rootStore;
   
    const classes = useStyles();
    const NetworkOptions: Array<NetworkOption>=[0,1];
    const [openConfirmationDialog, setopenConfirmationDialog] = useState(false);
    const [openChangeNetworkDialog, setopenChangeNetworkDialog] = useState(false);
    
    
    //permenent section variables
    const [networkType, setNetworkType] = useState<NetworkOption>(createJobStore.networkSetting.NetworkType);
    const [networkTypeEvent, setNetworkTypeEvent] = useState(null);
    const AnyNetworkTupple = jobSettingsStore.anyNetworkTupples;
    const {isFirstTrial} = jobSettingsStore;

useEffect(() => {
    runInAction(async () => {
    displayStore.updateScreenSettings({NetworkProcessing:true});
    await  jobAgent.CreateJobActions.anyNetworkTupple(jobSettingsStore.selectedInfra.id).then(res => {
        jobSettingsStore.updateAnyNetworkTupple(res);
      });
    if(jobSettingsStore.selectedInfra && jobSettingsStore.selectedInfra.id) 
    {
        jobSettingsStore.getVcenterNetworks(jobSettingsStore.selectedInfra.id);
    }
    displayStore.updateScreenSettings({NetworkProcessing:false});
    });
    if(jobSettingsStore.selectedInfra.infrastructureTypeId == InfrastructureType.AWS)
    {
        createJobStore.updateNetworkSettings({NetworkType: NetworkOption.None});
        setNetworkType(NetworkOption.None);
    }
  }, [jobSettingsStore.selectedInfra])


  useEffect(() => {
    if(isFirstTrial || jobSettingsStore.selectedInfra.infrastructureTypeId == InfrastructureType.AWS) {
        createJobStore.updateNetworkSettings({NetworkType: NetworkOption.None});
        setNetworkType(NetworkOption.None);
    }
  }, [jobSettingsStore.selectedLicense])

    const clearValuesRootStore =() => {
        let emptyEDRC: [{name:'',IPValue:''}];
        let emptyDC: [{DCName:'',source:'',target:'',dataStore:''}];
        switch(rootStore.createJobStore.baseSettings.jobType)
        {
            case JobType.Live:
                {
                    if(rootStore.createJobStore.networkSetting.liveDRNetwork.type == NetworkOption.None)
                    {
                        rootStore.createJobStore.updateNetworkSettings({liveDRNetwork: {...rootStore.createJobStore.networkSetting.liveDRNetwork,allowInbubble : false}});
                        rootStore.createJobStore.updateNetworkSettings({liveDRNetwork: {...rootStore.createJobStore.networkSetting.liveDRNetwork,allowOutbubble : false}});
                    }
                    break;
                }
            
            case JobType.Test:
                {
                    if(rootStore.createJobStore.networkSetting.testDRNetwork.type == NetworkOption.None)
                    {
                        rootStore.createJobStore.updateNetworkSettings({testDRNetwork: {...rootStore.createJobStore.networkSetting.testDRNetwork,allowInbubble : false}});
                        rootStore.createJobStore.updateNetworkSettings({testDRNetwork: {...rootStore.createJobStore.networkSetting.testDRNetwork,allowOutbubble : false}});
                    }
                    break;
                }
            case JobType.LiveTest:
                {
                    rootStore.createJobStore.updateNetworkSettings({liveDRNetwork: {...rootStore.createJobStore.networkSetting.liveDRNetwork,allowInbubble : false}});
                    rootStore.createJobStore.updateNetworkSettings({liveDRNetwork: {...rootStore.createJobStore.networkSetting.liveDRNetwork,allowOutbubble : false}});
                    rootStore.createJobStore.updateNetworkSettings({testDRNetwork: {...rootStore.createJobStore.networkSetting.testDRNetwork,allowInbubble : false}});
                    rootStore.createJobStore.updateNetworkSettings({testDRNetwork: {...rootStore.createJobStore.networkSetting.testDRNetwork,allowOutbubble : false}});
                    break;
                }
        }
        //rootStore.createJobStore.updateNetworkSettings({EnsureDRController: {...rootStore.createJobStore.networkSetting.EnsureDRController, ...emptyEDRC}});
        //rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController, ...emptyDC}});
        rootStore.createJobStore.updateNetworkSettings({dnsResolve: DnsResolveType.None});   
    }

    const disableAdvancedSettings =()=>{
        if(networkType==0)  
            return true;
        if(networkType==1)
        {
            return false;
        }
    }

    const buttonRedirect =(subComponent:any)=>{
        if(displayStore.NetworkDrawerSettings.testDrawerOpen)
        {
          if(displayStore.screenSettings.AddModeNetworks)
          {
            displayStore.updateScreenSettings({displayNetwork: subComponent});
            displayStore.updateScreenSettings({AddModeNetworks: false});
            displayStore.updateNetworkDrawerSettings({testDrawerOpen: true});
            displayStore.updateScreenSettings({displayInfo: "InfoDR"}); 
            displayStore.closeAllOtherBaseDrawersExcept("testDrawer");
          }
          else
          {
            displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
            displayStore.updateNetworkDrawerSettings({testDrawerOpen: false});
          }
        }
        else
        { 
          displayStore.updateScreenSettings({displayNetwork: subComponent});
          displayStore.updateScreenSettings({AddModeNetworks: false});
          displayStore.updateNetworkDrawerSettings({testDrawerOpen: true});
          displayStore.updateScreenSettings({displayInfo: "InfoDR"}); 
          displayStore.closeAllOtherBaseDrawersExcept("testDrawer");
        }  
    }

    const loadPermenentSection =()=> {
        return(<React.Fragment>
                            <Grid container direction="column" style={{ flexGrow:4}}>
                            <Grid item direction="row">
                                    <div style={{display: 'flex',flexDirection:'row' ,marginTop:'25px',marginLeft:'4%'}}>
                                    <Typography className={classes.SecondHeading} style={{color: '#1f396d'}}>{t('testdr')}</Typography>
                                    <Tooltip title={t("testdrInfo")} arrow>
                                        <HelpIcon className={classes.helpIcon} style={{marginLeft:'16px'}}/>
                                    </Tooltip>
                                    <Divider className={classes.divider}/>
                                    </div>
                            </Grid>
                            <Grid item container spacing={2} direction="row">
                                <Grid item xs={1}></Grid>
                                <Grid item xs={11} container>
                                <Grid item xs={11}>
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <InputLabel>{t('networkType')}</InputLabel>
                                        <Select
                                        disabled={true}
                                        style={{width: '97%'}}
                                        labelId="network-select-label"
                                        value={networkType}
                                        displayEmpty={true}
                                        renderValue={name => name<0? 'None': NetworkOption[name as string]}
                                        onChange={handleNetworkTypeChange}
                                        onFocus={()=>displayStore.updateScreenSettings({ displayInfo: "InfoDR" })}
                                        label="None">
                                        {NetworkOptions.map((name) => (
                                        <MenuItem key={name} value={name}>
                                        {NetworkOption[name]}
                                        </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1} >
                                    <Button className={classes.button} disabled={true} onClick={() => buttonRedirect('NetworkControlSettings')}>
                                        <img className={classes.editIcon} src={disableAdvancedSettings() ?'/assets/images/createjob/advancedNetworkDisabled.svg':'/assets/images/createjob/advancedNetwork.svg'}></img>
                                    </Button> 
                                </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
        </React.Fragment>)
    }

    const handleNetworkTypeChange =(event)=>{
        if(createJobStore.selectedDevices.length > 0 )
        {
            setNetworkTypeEvent(event);
            setopenConfirmationDialog(true);
        }
        else
        {
            handleChangeNetworkType(event);
            if(event.target.value == NetworkOption.None)
            {
                setNetworkTypeEvent(event);
                setopenChangeNetworkDialog(true);
            }
        }
           
    }

    const handleChangeNetworkTypeDialog =(confirm:boolean,event:any)=>{
        setopenConfirmationDialog(false);
        if(confirm)
        {
            createJobStore.updateSelectedDevices([]);
            rootStore.createJobStore.updateBaseSettings({workflowSetting: {...rootStore.createJobStore.baseSettings.workflowSetting, serverTest : false}});
            handleChangeNetworkType(networkTypeEvent);
        }
    }

    const handleCloseDialog =(confirm:boolean)=>{
        setopenChangeNetworkDialog(false);
        if(confirm)
        {
            handleChangeNetworkType(networkTypeEvent);
        }
    }

    const handleChangeNetworkType = (event) => {
        let jobType= rootStore.createJobStore.baseSettings.jobType;
        setNetworkType(event.target.value);
        
        rootStore.createJobStore.updateNetworkSettings({NetworkType: event.target.value});
        if(jobType==JobType.Test)
            rootStore.createJobStore.updateNetworkSettings({testDRNetwork: {...rootStore.createJobStore.networkSetting.testDRNetwork, type: event.target.value}});
        else if(jobType==JobType.Live)
            rootStore.createJobStore.updateNetworkSettings({liveDRNetwork: {...rootStore.createJobStore.networkSetting.liveDRNetwork, type: event.target.value}});
        else
        {
            rootStore.createJobStore.updateNetworkSettings({testDRNetwork: {...rootStore.createJobStore.networkSetting.testDRNetwork, type: event.target.value}});
            rootStore.createJobStore.updateNetworkSettings({liveDRNetwork: {...rootStore.createJobStore.networkSetting.liveDRNetwork, type: event.target.value}});
        }
        if(event.target.value == NetworkOption.None)
        {
            displayStore.updateNetworkSectionSettings({DisableButtonDC:true,DisableButtonEDRC:true,DisableSection:true});
            displayStore.updateNextStepValid({edrcValid:true});
        }
        else
        {
            if(rootStore.createJobStore.networkSetting.EnsureDRController == null || rootStore.createJobStore.networkSetting.EnsureDRController == undefined || rootStore.createJobStore.networkSetting.EnsureDRController.id == 0)
                displayStore.updateNextStepValid({edrcValid:false});
            displayStore.updateNetworkSectionSettings({DisableButtonDC:false,DisableButtonEDRC:false,DisableSection:false});
        }
        if(displayStore.NetworkDrawerSettings.testDrawerOpen)
        {
            displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
            displayStore.updateNetworkDrawerSettings({testDrawerOpen: false});
        }
    };

    const CheckSectionAndDrawerStatus= (current:string) =>{
        if(displayStore.screenSettings.displayNetwork=='ControllerSettings'||displayStore.screenSettings.displayNetwork=='' || displayStore.screenSettings.displayNetwork=='CloneSettings' || displayStore.screenSettings.displayNetwork == 'EDRServerSettings')
        {
            if(displayStore.screenSettings.displayNetwork!=current )
                displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});

            displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
            displayStore.updateNetworkDrawerSettings({controllerDrawerOpen:false});
            displayStore.updateNetworkDrawerSettings({cloneDrawerOpen:false});
            displayStore.updateNetworkDrawerSettings({liveDrawerOpen:false});
            displayStore.updateNetworkDrawerSettings({testDrawerOpen:false});
        }
    }

    const loadText =()=>{
        if(networkTypeEvent!=null)
        {
            if(networkTypeEvent.target.value == NetworkOption.None)
            return "This change will also cause all the devices and tests you have selected in the next step of the wizard to be reset (you will need to configure them again)."+'\n\n'+"If You have chosen scripts that will run in the Pre/Post of the Test Step - this can't be done in 'None' type network - those scripts will not be used "+'\n\n'+"You are about to change the network type - this will cause all the devices and tests you have selected in the next step of the wizard to be reset."
        else 
            return "You are about to change the network type - this will cause all the devices and tests you have selected in the next step of the wizard to be reset."
        }
        return "You are about to change the network type - this will cause all the devices and tests you have selected in the next step of the wizard to be reset."
    }

    return(
             <Grid container>
            <Grid item xs style={{padding: '0 16px 16px 16px'}}>
                <Card elevation={1} classes={{root: clsx([classes.card])}}>
                    <Typography variant='h6' className={classes.MainHeading} style={{marginLeft:'4%'}}>{t('networkMapping')}</Typography>
                    {loadPermenentSection()}
                    {AnyNetworkTupple ? <TupleComponent></TupleComponent>:<TupleCreateComponent></TupleCreateComponent>}
                </Card>
            </Grid>
            <ConfirmationDialog type="changeNetworkType" device={null} open={openConfirmationDialog} event={networkTypeEvent} 
            onClose={(confrim,event)=>handleChangeNetworkTypeDialog(confrim,event)} children={loadText()}></ConfirmationDialog>
            <ConfirmationDialog type="changeNetworkType" device={null} open={openChangeNetworkDialog} event={networkTypeEvent}
            onClose={(confirm)=>handleCloseDialog(confirm)} children={"You are about to switch to a ‘None' network type, meaning EnsureDR will let your replication provider handle the devices failover to a test network. Please make sure the plans you choose in the next step are set (in the provider configuration) to this network which is an isolated network preventing any split brains and duplicate IP."}></ConfirmationDialog>
        </Grid>
    );
};

export default observer(NetworkVlanComponent);