import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/root.store';
import { Typography, FormControl, InputAdornment, IconButton, TextField, makeStyles, OutlinedInput } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ReactComponent as LocationIcon } from "../../assets/settingsAssets/locationIcon.svg";
import { ReactComponent as SiteIcon } from "../../assets/settingsAssets/siteIcon.svg";
import { cities } from "./data/cities";
import { locationObj } from '../../app/models/setting.model';
import { useTranslation } from 'react-i18next';
import { EDRAutocomplete } from '../../app/common/EDRAutocomplete';
import { EDROutlinedInput } from '../../app/common/EDROutlinedInput';
import { EDRTextField } from '../../app/common/EDRTextField';





interface Props {
    settingValue?: locationObj,
    settingKey: string,
    countryCode: string,
    onChange?: (settingValue: locationObj) => void;
}

const SettingLocationPartComponent: React.FC<Props> = (props) => {
    const {settingValue, settingKey,countryCode} = props;
    const rootStore = useContext(RootStoreContext); 
    const { setContentValid} = rootStore.settingStore;
    const [locationState,setLocationState] = useState(settingValue? settingValue.location : "");
    const defaultCoords = { "name": "Choose City", "location": "", "country":"", "id": 0, "lng": 0, "lat":0};
    const [nameState,setNameState] = useState(settingValue? cities[settingValue.id]: defaultCoords);
    const [t] = useTranslation("settings");
    const [citiesForCountry,setcitiesForCountry] = useState(cities);

    useEffect(() => {
      setTimeout(() => filterCitiesByCountry(),500);
    }, [props.countryCode]);

    useEffect(() => {
      if(settingValue) 
      {
        const nameState =  cities[props.settingValue.id];
        setNameState(nameState);
        setLocationState(props.settingValue.location);
      }
    }, [settingValue]);

    const wipePreviousData = () => {
      if (props && props.settingValue && props.settingValue!= undefined && props.settingValue != defaultCoords) {
              if (props.countryCode === props.settingValue.country){
                setContentValid(true);
                rootStore.settingStore.updateLocationObj(JSON.stringify(props.settingValue),settingKey,"Location");
                setNameState(props.settingValue? cities[props.settingValue.id]: defaultCoords);
                setLocationState(props.settingValue.location);
              }
              else{
                  if (settingKey==="coords_dr" || (settingKey.startsWith("coords_prod") && rootStore.settingStore.locationSettings.length<=2))
                      setContentValid(false);
                  setNameState({ "name": "Choose City", "location": "", "country":"", "id": 0, "lng": 0, "lat":0});
                  setLocationState("");
                  rootStore.settingStore.updateLocationObj("remove",settingKey,"Location");
              }
      }
      else{
        if (settingKey==="coords_dr" || (settingKey.startsWith("coords_prod") && rootStore.settingStore.locationSettings.length<=2)){
            setContentValid(false);
        }
        setNameState({ "name": "Choose City", "location": "", "country":"", "id": 0, "lng": 0, "lat":0});
        setLocationState("");
      }
    }

    const changeLocation = (event:object,value:any) => {
      if(event!=null)
      {
        if (value){
          setContentValid(true);
          setLocationState(value.location);
          setNameState(value);
          rootStore.settingStore.updateLocationObj(JSON.stringify(value),settingKey,"Location");
      }
      else {
        if (settingKey==="coords_dr" || (settingKey.startsWith("coords_prod") && rootStore.settingStore.locationSettings.length<=2))
            setContentValid(false);
        setLocationState("");
        setNameState({ "name": "Choose City", "location": "", "country":"", "id": 0, "lng": 0, "lat":0});
        rootStore.settingStore.updateLocationObj("remove",settingKey,"Location");
      }
      props.onChange(value);
      }
    }

    const filterCitiesByCountry = () => {
      setcitiesForCountry(countryCode && countryCode.length==2? cities.filter(item => item.country === countryCode) : cities.filter(item => item.country == rootStore.settingStore.currentCountryCode));
    }

    const useStyles = makeStyles((theme) => ({
        locationDetails: {
          display: "flex",
          flexDirection: "column",
          color: "#4d5f75",
          "& > div": {
            display: "flex",
            justifyContent: "space-between",
          },
        },
        textBoxLocation: {
          width: "264px",
          height: "32px",
          borderRadius: "4px",
          border: "solid 1px #c7cfdb",
          backgroundColor: "#ffffff",
        }
      }));
    
    const classes = useStyles();
    
    return (
        <div data-test='component-location-part'  className={classes.locationDetails}>
        { settingKey === "coords_dr" ? (
        <div>
            <Typography classes={{ root: "settingsTextBold" }}>
            {t("drSite")}
            </Typography>
        </div>  
        ) : ""}
         
      
        <div style={{marginLeft:settingKey === "coords_dr" ?'15%':'3%' }}>
        <FormControl>
        <EDRAutocomplete 
        id={"autocompleteField"+settingKey}
        disableClearable
        value={nameState}
        options={citiesForCountry}
        getOptionLabel={(option: string | { name: string}) => (typeof option === 'string' ? option : `${option.name}`)}
        renderOption ={(option: string | { name: string}) => (typeof option === 'string' ? option : `${option.name}`)}
        onChange={(event: object, value: any) => {changeLocation(event,value)}}
        onInputChange={(event: object, value: any) => {changeLocation(event,value)}}
        renderInput={(params) => (
        <EDRTextField {...params}
        id="location1"
        variant="outlined"
        style={{ width: '302px', height: '32px', borderRadius: '4px', backgroundColor: '#ffffff', marginTop: '1em', marginBottom: '1em' }}
        size="small"
        label={t("siteName")}
        placeholder="Type Here"
        InputProps={{
        ...params.InputProps,
        endAdornment: (
        <>
        <InputAdornment position="end">
         <SiteIcon />
         </InputAdornment>
        {params.InputProps.endAdornment}
        </>
         )
        }} 
				/>
        )}
/>
        </FormControl>
        
        <FormControl style={{marginLeft:settingKey === "coords_dr" ?'20%':'6%'}}>
          <EDROutlinedInput
            disabled
            id={ "site"+settingKey }
            type="text"
            placeholder="Site Location"
            style={{width:'302px', height: '37px' , borderRadius: '4px', backgroundColor: '#ffffff' , marginLeft:'1em',marginTop:'1em', marginBottom: '1em'}}
            value={locationState}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label={"location" + settingKey}>
                  <LocationIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      </div>
    );
}

export default (observer(SettingLocationPartComponent));