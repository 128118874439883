
import Chart from 'chart.js';

const RoundedDoughnutArc: Chart.PluginServiceRegistrationOptions = {

    afterDraw: function (chartInstance, ease) {
        var ctx = chartInstance.ctx;
        const arc = chartInstance.getDatasetMeta(0).data[0]
        var vm = arc._view;
        var radius = (vm.outerRadius + vm.innerRadius) / 2;
        var thickness = (vm.outerRadius - vm.innerRadius) / 2;
        var angle = Math.PI - vm.endAngle - Math.PI / 2;

        ctx.save();
        ctx.fillStyle = vm.backgroundColor;
        ctx.translate(vm.x, vm.y);
        ctx.beginPath();
        ctx.arc(radius * Math.sin(angle), radius * Math.cos(angle), thickness, 0, 2 * Math.PI);
        ctx.arc(radius * Math.sin(Math.PI), radius * Math.cos(Math.PI), thickness, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fill();
        ctx.restore();

    },
}

export default RoundedDoughnutArc;