import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { FormControl, makeStyles, Grid, Select, MenuItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface NetworkProps {
    settingValue: string,
    settingKey: string,
    type:string,
    allValues:string[],
    onChange?: (sourceNetworkName: string, targetNetworkName: string) => void
}

const DRVlanPartComponent: React.FC<NetworkProps> = (props) => {

    const [t] = useTranslation("");
    const useStyles = makeStyles({
        card: {
            padding: 16,
            width: '100%',
            height: '100%'
        },
        MainHeading:
        {
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.19',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#1f396d'
        },
        SecondHeading:{
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
        },
        divider:{
            backgroundColor: '#eaeef4',
            width:'100%',
            marginTop:'10px'
        },
        Image:{
            objectFit: 'contain',
            marginRight:'8px',
            marginBottom: '10px'
        },
        formControl: {
            width: '100%',
        },
        button: {
            borderRadius: 15,
            color: 'white',
            backgroundColor:'#2892d7',
            width: '23%',
            height: '32px',
            marginTop: '24px',
            marginLeft: '207px'
        },
        item: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.71,
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#4d5f75'
        }
        });    
    const classes = useStyles();
  
    const changeDRVlanPart = (sourceNetwork: string, targetNetwork: string) => {
        props.onChange && props.onChange(sourceNetwork, targetNetwork);
    }

    return (
        <Grid item container direction="row" style={{display: 'flex',flexDirection:'row', marginTop:'16px'}} spacing={1}>
                <Grid item xs={6} direction="row" style={{display:'flex'}}>
                    <img src='/assets/images/createjob/net.svg' style={{marginLeft:'10px',marginTop:'16px'}} className={classes.Image}></img>
                    <Typography className={classes.item} style={{marginTop:'16px'}}>{props.settingKey}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className={classes.formControl} variant='outlined'>
                    <Select
                    value={props.settingValue}
                    onChange={(event) =>changeDRVlanPart(props.settingKey,  event.target.value as string)}
                    displayEmpty={true}
                    >
                    {props.allValues.map((name) => (
                    <MenuItem key={name} value={name}>
                    {name}
                    </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                </Grid> 
         </Grid>      
    );
}

export default observer(DRVlanPartComponent);