import { JobTest } from "../jobTest.model";
import { DeviceType } from "../deviceType.enum";
import { DevicePlatform } from "../jobProperties.model";
export enum DeviceStatus {
    ok ="ok",
    failed="failed",
    warning="warning"
}
export interface JobDeviceResultResponse {
    id?: number,
    jobId?: number,
    jobSummaryResultId?: number,
    machineName?: string,
    status?: DeviceStatus,
    type?: DeviceType,  //plan / custom /physical etc.
    results?: string,
    testGroup?: number,
    testGroupName?: string,
    targetServer?: string,
    jobHealthBeforeTest?: string,
    hostServer?: string,
    hostServerType?: string,
    undoTestFailOverStatus?: string,
    autoTroubleshooting?: "No" | "Yes",
    autoTroubleshootingResult?: string,
    smallGif?: string,
    largeGif?: string,
    jobTestResults?: Array<JobTest>,
    webPortalScreenShot: string,
    devicePlatform:DevicePlatform,
    scriptName:string,
    deviceUserName:string
}

//TODO choose a better name
export type JobDeviceResultShort = Pick<JobDeviceResultResponse, 'id' | 'type' | 'status' | 'machineName' | 'testGroup' | 'testGroupName' | 'jobSummaryResultId' | 'autoTroubleshooting' | 'jobTestResults' | 'devicePlatform'|'deviceUserName'>;
