import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { IActivityIconConfig } from "../../../app/models/dashboard/activitiesPanel.model";
import { ActivityIconType } from "../../../app/models/dashboard/dashboard.enum";
import WatchIcon  from "../../../assets/dashboardAssets/watch.svg";
import AlarmClock from "../../../assets/dashboardAssets/alarmClock.svg";
import  StopWatch from "../../../assets/dashboardAssets/stopWatch.svg";
import { makeStyles } from "@material-ui/core";

const ActivityIcon = (props: IActivityIconConfig) => {
  
  useEffect(() => {
    //callMatomo("ActivityIcon","/dashboard");
  }, []);

  let icon;
  let text: string;
  const dateFormat = "DD.MM.YY";

  switch (props.activityIconType) {
    case ActivityIconType.nextRun:
      icon = WatchIcon;
      text = props.text || "Not Set";
      break;
    case ActivityIconType.lastRun:
      icon = AlarmClock;
      text = props.text || "Not Ran";
      break;
    case ActivityIconType.schedule:
      icon = StopWatch;
      text = props.text.toString() + " Jobs"
      break;
  };

  const useStyles = makeStyles({
    ActivityIcon: {
      fontFamily:"Roboto",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      flexDirection: "column",
      textAlign: "center",
      padding: "2%",
      marginBottom: "12%"
    },
    boldText:{
      color:"#1f396d",
      fontSize:"1rem",
      fontWeight: 500
    },
    text:{
      color:"#4d5f75"
    }
  });

  const classes = useStyles();

  return (
    <div data-test="component-ActivityIcon" className={classes.ActivityIcon}>
      <div>{<img src={icon} style={{width: 16}}></img>}</div>
      <div className={classes.boldText}>{props.header}</div>
      <div className={classes.text}>{text}</div>
    </div>
  );
};

export default observer(ActivityIcon);
