import { useEffect, useState } from "react";

export enum StatusColors {
    passed = '#8bc53f',
    warning = '#2892d7',
    failed = '#077187',
    disabled = 'lightgray'
}

export function useStatusColor(variant: 'passed' | 'failed' | 'warning', value: number) {
    const [color, setcolor] = useState(variant === 'passed' ? StatusColors.passed: (variant === 'failed' ?StatusColors.failed: StatusColors.warning));

    useEffect(() => {
        if(!value)
            setcolor(StatusColors.disabled);
        else {
            setcolor(variant === 'passed' ? StatusColors.passed:(variant === 'failed' ? StatusColors.failed: StatusColors.warning));
        }
        
    }, [variant, value]);
    return color;
}