import { IJobAction, IJobActionsEnvelope, JobAction } from '../models/jobactions/jobaction.model';
import agentbase from './agentBase';
import { jobRunWithAssetInfo } from '../models/jobRunWithAssetInfo.model';
import { JobDelete } from '../models/jobactions/jobDelete.model';
import { JobDuplicate } from '../models/jobactions/jobDuplicate.model';
import { JobRename } from '../models/jobactions/jobRename.model';
import { JobRestore } from '../models/jobactions/jobRestore.model';
import { JobLicense } from '../models/jobactions/jobLicense.model';
import { JobSync } from '../models/jobactions/jobSync.model';
import { CreateJobProperties, CredentialUserType, DatabaseTestProperties, DataMoverDTO, InfrastructureDTO, NetworkTuppleDTO, WebPortalTestProperties } from '../models/createjob/createJob.model';
import { JobEdit } from '../models/jobactions/jobEdit.model';
import { CreateJobDefaults } from '../models/createjob/createJobDefault.model';
import { memoise } from '../common/util/memoise';
import {CancelablePromise} from '../common/util/cancellablePromise';
import '../common/util/cancellablePromise';
 import _axios from 'axios';
import { InfrastructureType, TestGroup, TestGroups } from '../models/jobProperties.model';
import { DataMover } from '../models/BaseSettings.enum';

const requests = agentbase.requests;
const sleep = agentbase.sleep;
const axios = agentbase.axios;
const responseBody = agentbase.responseBody;


const JobActions = {
    list: 
        (params: URLSearchParams): Promise<IJobActionsEnvelope> => 
        axios.get('/jobactions', {params: params})
        .then(sleep(1000))
        .then(responseBody),
    details: (id: string) => requests.get(`/jobactions/${id}`),
    run: (action: JobAction): Promise<jobRunWithAssetInfo> => {
       return requests.post('/jobactions', {...action});
    },
    delete: (action: JobDelete): Promise<string> => {
        return requests.del('/Jobs',{...action})
    },
    syncJob: (action: JobSync): Promise<string> => {
        return requests.post('/Jobs/syncJob',{...action})
    },
    duplicate: (action: JobDuplicate): Promise<string> => {
        return requests.post('/Jobs',{...action})
    },
    rename: (action: JobRename): Promise<string> => {
        return requests.put('/Jobs',{...action})
    },
    getJob: (id:number): Promise<CreateJobProperties> => {
        return requests.get(`/Jobs/GetJob/${id}`);
    },
    getNewJob: (platform:any): Promise<CreateJobProperties> => {
        return requests.post('/Jobs/GetNewJob',{...platform});
    },
    editJob: (jobProperties: CreateJobProperties): Promise<any> =>{
        return requests.put('/Jobs/editJob', {...jobProperties});
    },
    createEnabled :():Promise<boolean> =>{
        return Promise.resolve(true);
    },
    restoreJob: (action: JobRestore): Promise<string> => {
        return requests.post('/Jobs/restore',{...action})
    },
    updateLicense: (action: JobLicense): Promise<string> => {
        return requests.post('/Jobs/updateLicense',{...action})
    },
    createJob: (jobProperties: CreateJobProperties): Promise<any> =>{
        return requests.post('/Jobs/create', {...jobProperties});
    },
    ValidatePortal: (webPortal: WebPortalTestProperties): Promise<any> =>{
        return requests.post('/Jobs/testWebPortal', {...webPortal});
    },
    getCreateJobDefaults: (): Promise<CreateJobDefaults> =>{
        return requests.get('/Jobs/createjobdefaults');
    },
    getDefaulTDataMover: (): Promise<DataMover> =>{
        return requests.get('/Jobs/getDefaulTDataMover');
    },
    NextJobIdAvilable :(prefix:string):Promise<string> =>{
        return requests.get(`/Jobs/NextJobIdAvilable?prefix=${prefix}`);
    },
    CheckJobNameNotExist :(name:string):Promise<boolean> =>{
        return requests.get(`/Jobs/CheckJobNameNotExist?name=${name}`);
    },
    getLicenses :():Promise<any> =>{
        return requests.get('/Licenses');
    }, 
    addLicense :(keyAndName:any):Promise<any> =>{
        return requests.post('/Licenses',{...keyAndName});
    }, 
    deleteLicense :(id:number):Promise<any> =>{
        return requests.del(`/Licenses/${id}`,null);
    },
    changeLicName :(idAndName:any):Promise<any> => {
        return requests.put('/Licenses',{...idAndName});
    },
    ListInfrastructures: (infraTypeId: number):Promise<any> => {
        return requests.get(`/Jobs/ListInfrastructures?infrastructureTypeId=${infraTypeId}`);
    },
    GetInfrastructures: (id: number):Promise<any> => {
        return requests.get(`/Jobs/GetInfrastructure?id=${id}`);
    },
    CreateInfrastructure:(infraDetails: any):Promise<any> => {
        return requests.post('Jobs/CreateInfrastructure',{...infraDetails});
    },
    EditInfrastructure:(infraDetails: any):Promise<any> => {
        return requests.put('Jobs/EditInfrastructure', {...infraDetails});
    },
    DeleteInfrastructure:(id: number):Promise<any> => {
        return requests.del(`Jobs/DeleteInfrastructure/${id}`, null);
    },
    CheckAnyInfrastructure:(infrastructureTypeId: number):Promise<boolean> => {
        return requests.get(`Jobs/CheckAnyInfrastructure?infrastructureTypeId=${infrastructureTypeId}`)
    },
    CheckAnyUser:(type: CredentialUserType):Promise<boolean> => {
        return requests.get(`Jobs/CheckAnyUser?type=${type}`)
    },
    ListDataMovers: (dataMoverTypeId: number):Promise<any> => {
        return requests.get(`/Jobs/ListDataMovers?dataMoverTypeId=${dataMoverTypeId}`);
    },
    GetDataMover: (id: number):Promise<any> => {
        return requests.get(`/Jobs/GetDataMover?id=${id}`);
    },
    CreateDataMover:(dmDetails: any):Promise<{addedDataMoverId: number, dataMovers:DataMoverDTO[]}> => {
        return requests.post('Jobs/CreateDataMover',{...dmDetails});
    },
    EditDataMover:(dmDetails: any):Promise<any> => {
        return requests.put('Jobs/EditDataMover', {...dmDetails});
    },
    DeleteDataMover:(id: number):Promise<any> => {
        return requests.del(`Jobs/DeleteDataMover/${id}`,null);
    },
    CheckAnyDataMover:(dataMoverTypeId: number):Promise<boolean> => {
        return requests.get(`Jobs/CheckAnyDataMover?dataMoverTypeId=${dataMoverTypeId}`)
    },
    CheckAnyLicense:():Promise<boolean> => {
        return requests.get('Jobs/CheckAnyLicense')
    },
    EditCredentials:(credentialsDetails: any):Promise<any> => {
        return requests.put('/UserCredentials',{...credentialsDetails});
    },
    AddCredentials:(credentialsDetails: any):Promise<any> => {
        return requests.post('/UserCredentials',{...credentialsDetails});
    },
    DeleteCredentials:(id: number):Promise<any> => {
        return requests.del(`/UserCredentials/${id}`,null);
    },
    ValidateDomainCredentials:(credentialsDetails: any):Promise<boolean> => {
        return requests.post('/UserCredentials/ValidateDomainCredentials',{...credentialsDetails});
    },
    listTestGroups: (jobId: number):Promise<Array<TestGroup>> => {
        return requests.get(`Jobs/${jobId}/testGroups`);
    },
    listVRAs: (datamover: DataMover):Promise<Array<string>> => {
        return requests.get(`/jobs/vra?datamover=${datamover}`);
    }
    


}
const CreateJobActions = {
    GetBaseSettingsData: (dataMoverTypeId: number,infrastructureTypeId:number): Promise<any> => {
        return requests.get(`/CreateJob/GetBaseSettingsData?DataMoverTypeId=${dataMoverTypeId}&InfrastructureTypeId=${infrastructureTypeId}`);
    },
    //GetWorkflowBaseData: (dataMoverTypeId: number,infrastructureTypeId:number): Promise<any> => {
    //   return requests.get(`/CreateJob/GetWorkflowBaseData?DataMoverTypeId=${dataMoverTypeId}&InfrastructureTypeId=${infrastructureTypeId}`);
    //},
    CreateJobSetting: (jobSetting:any): Promise<any> => {
        return requests.post('/CreateJob/CreateJobSettings',{...jobSetting});
    },
    GetCredentials: (): Promise<any> => {
        return requests.get(`/CreateJob/GetCredentials`);
    },
    GetJobSettings: (jobSetting:any): Promise<any> => {
        return requests.get('/CreateJob/GetJobSettings');
    },
    LoginInfra: (InfraDetails:any): Promise<any> => {
        return requests.post('/CreateJob/LoginInfra',{...InfraDetails});
    },
    LoginDataMover: (DataMoverDetails:any): Promise<any> => {
        return requests.post('/CreateJob/LoginDataMover',{...DataMoverDetails});
    },
    ListPlans: memoise( (DataMoverDetails:any, cachePolicy: any): CancelablePromise<any> => {
        const CancelToken = _axios.CancelToken;
        const source = CancelToken.source();
        return axios.post('/CreateJob/ListPlans',{...DataMoverDetails}, {cancelToken: source.token}).then(res => res.data)
        .asCancelable(() => {
            console.log("cancelling list plans");
            source.cancel("Cancelling Listplans");
        });
    }),
    ListPlanNames: memoise( (DataMoverDetails:any, cachePolicy: any): CancelablePromise<any> => {
        const CancelToken = _axios.CancelToken;
        const source = CancelToken.source();
        return axios.post('/CreateJob/ListPlanNames',{...DataMoverDetails}, {cancelToken: source.token}).then(res => res.data)
        .asCancelable(() => {
            console.log("cancelling list plan Names");
            source.cancel("Cancelling plan Names");
        });
    }),
    AnyCustomDevices:():Promise<boolean> => {
        return requests.get('/CreateJob/AnyCustomDevices');
    },
    ListInfraNetworks: (id:number): Promise<any> => {
        return requests.post(`/CreateJob/ListInfraNetworks?id=${id}`,null);
    },
    InfraAuthenticate: (id:number,isSource:boolean): Promise<any> => {
        return requests.post(`/CreateJob/InfraAuthenticate?id=${id}&isSource=${isSource}`,null);
    },
    DataMoverAuthenticate: (infraId:number,id:number,isSource:boolean): Promise<boolean> => {
        return requests.post(`/CreateJob/DataMoverAuthenticate?infraId=${infraId}&id=${id}&isSource=${isSource}`,null);
    },
    InfraAuthenticateBeforeAdd: (infra:any): Promise<any> => {
        return requests.post('/CreateJob/InfraAuthenticateBeforeAdd',{...infra});
    },
    ListCustomDevices: (): CancelablePromise<any> => {
        const CancelToken = _axios.CancelToken;
        const source = CancelToken.source();
        return axios.get('CreateJob/ListCustomDevices',{cancelToken: source.token}).then(res => res.data).asCancelable(() => source.cancel("Cancelling list custom device"));
    },
    EditCustomDevice: (customDevice:any): Promise<any> => {
        return requests.put('CreateJob/EditCustomDevice', {...customDevice});
    },
    CreateCustomDevice: (customDevice:any): Promise<any> => {
        return requests.post('/CreateJob/CreateCustomDevice',{...customDevice});
    },
    DeleteCustomDevice: (Id: number, JobId: number): Promise<boolean> => {
        return requests.del(`/CreateJob/DeleteCustomDevice?Id=${Id}&JobId=${JobId}`,null);
    },
    DeleteCustomDevices: (Ids: number[]): Promise<boolean> => {
        return requests.del('/CreateJob/DeleteCustomDevices',Ids);
    },
    CheckCustomDeviceExist: (name: string): Promise<boolean> => {
        return requests.get(`/CreateJob/CheckCustomDeviceNotExist?name=${name}`);
    },
    CheckCustomDeviceDeleted: (name: string): Promise<boolean> => {
        return requests.get(`/CreateJob/CheckCustomDeviceDeleted?name=${name}`);
    },
    ListAllEmails: (): Promise<string[]> => {
        return requests.get('/CreateJob/ListAllEmails');
    },
    validateVcenter:(hostname: string, username: string, password: string): Promise<boolean> => {
        return requests.post('/validation/vcenter', {hostname, username, password});
    },
    validateAWS:(region: string, username: string, password: string): Promise<boolean> => {
        return requests.post('/validation/aws', {region, username, password});
    },
    validateDataMover:(credsId:number,hostname: string,port:number, username: string, password: string, dataMoverType:DataMover, isSource = false): Promise<boolean> => {
        return requests.post('/validation/dataMover', {credsId, hostname, port, username, password, dataMoverType, isSource});
    },
    listNetworkTupples:(infraId: number): Promise<any> => {
        return requests.get(`/CreateJob/ListNetworkTupples/${infraId}`);
    },
    editNetworkTupple:(networkTupple: NetworkTuppleDTO): Promise<any> => {
        return requests.put('/CreateJob/EditNetworkTupple', {...networkTupple});
    },
    getNetworkTupple:(id: number): Promise<any> => {
        return requests.get(`/CreateJob/NetworkTuppleDetails/${id}`);
    },
    deleteNetworkTupple:(id: number): Promise<any> => {
        return requests.del(`/CreateJob/DeleteNetworkTupple/${id}`,null);
    },
    createNetworkTupple:(networkTupple: NetworkTuppleDTO): Promise<any> => {
        return requests.post('/CreateJob/CreateNetworkTupple/',{...networkTupple});
    },
    anyNetworkTupple:(infraId:number): Promise<boolean> => {
        return requests.get(`/CreateJob/AnyNetworkTupple/${infraId}`);
    },
    AnyJobs:():Promise<boolean> => {
        return requests.get('/CreateJob/AnyJobs');
    },
    AnyRuns:():Promise<boolean> => {
        return requests.get('/CreateJob/AnyRuns');
    }

}
const JobTestsActions = {
    TestNetwork:(details:any): Promise<boolean> => {
        return requests.post(`/JobTests/TestNetwork`,{...details});
    },
    TestApplication:(details:any): Promise<boolean> => {
        return requests.post(`/JobTests/TestApplication`,{...details});
    },
    TestWeb:(details:any): Promise<boolean> => {
        return requests.post(`/JobTests/TestWeb`,{...details});
    },
    TestDatabase: (details:any): Promise<any> =>{
        return requests.post('/JobTests/TestDatabase', {...details});
    },
    TestScript:(details:any): Promise<any> =>{
        return requests.post('/JobTests/TestScript', {...details});
    },
    TestPowerupScript:(details:any): Promise<any> =>{
        return requests.post('/JobTests/TestPowerupScript', {...details});
    },
    TestPrePostScript:(details:any): Promise<any> =>{
        return requests.post('/JobTests/TestPrePostScript', {...details});
    },
    TestAuthentication:(details:any): Promise<any> =>{
        return requests.post('/JobTests/TestAuthentication', {...details});
    },
}
const RemoteJob = {
    run: (jobaction: IJobAction) => {
        requests.postRemote('/jobactions?jobname=Job1',jobaction);
    }
}

export default {
    RemoteJob,
    JobActions,
    CreateJobActions,
    JobTestsActions,
}