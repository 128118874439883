import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/root.store';
import { Dialog, DialogContent, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PhotoUploadWidget from './photoUpload/PhotoUploadWidget';

const SettingsLogoComponent: React.FC = () => {
    const rootStore = useContext(RootStoreContext); 
    const { dialogOpen, setOpen, uploadLogo, uploadingPhoto} = rootStore.settingStore;
    const [t] = useTranslation("settings");
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const [addPhotoMode, setAddPhotoMode] = useState(false);

    const handleUploadImage = (photo: Blob) => {
        uploadLogo(photo).then(() => setAddPhotoMode(false))
    }

    return (
        <div data-test='component-set-name'>
            <Dialog
              style={{ zIndex: 1302 }}
              open={dialogOpen}
              fullScreen={matchesSM}
              onClose={() => setOpen(false)}
            >
                <DialogContent style={{ overflow: "hidden"}}>
                    <Grid container direction="column" style={{ overflow: "hidden"}}>
                    <Grid item>
                        <Typography align="left" style={{ marginBottom: "1em" }} classes={{ root: "form_title"}}>
                        {t("cropImage")}
                        </Typography>
                    </Grid>
                    <Grid item>
                    <PhotoUploadWidget uploadPhoto={handleUploadImage} loading={uploadingPhoto} />
                    </Grid>
                    </Grid>          
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default (observer(SettingsLogoComponent));