import React, { useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/root.store';
import { Button, Chip, FormControl, Grid, IconButton, InputAdornment, InputLabel, makeStyles, Switch, Tooltip, Typography} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DataMover, DATA_MOVER_CONFIGURATION_NAME } from '../../../app/models/BaseSettings.enum';
import  VisibilityOff  from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import ReactDOM from 'react-dom';
import { EDROutlinedInput } from "../../../app/common/EDROutlinedInput";
import { EDRAutocomplete } from "../../../app/common/EDRAutocomplete";
import { EDRTextField } from "../../../app/common/EDRTextField";
import { FormikErrors, useFormik } from 'formik';
import * as yup from 'yup';
import { CONSTS } from '../../../app/models/createjob/createJob.model';
import jobAgent from '../../../app/api/jobAgent';
import { debounce } from 'lodash';
import CheckCircle from '@material-ui/icons/CheckCircle';
import {setIn, getIn, yupToFormErrors} from 'formik'
import { UpdateType } from '../../../app/stores/activityStore';
import { useLabels } from './hooks/useDatamoverLabels';
import IfCondition from '../../../app/common/util/if-condition';
import { setDatamoverDefaultPort } from '../../../app/stores/jobSettings.store';


async function validateDataMover (value: {ip: string, username: string, password: string, credentialId: number, port?: number, isSource?: boolean}, resolve:(value: any) => void, datamover: DataMover) {
  try {
    if(!value.ip){
      resolve(false);
      return;
  }
  if(!value.credentialId && (!value.username || !value.password)) {
    resolve(false);
    return;
  }
  var res = await jobAgent.CreateJobActions.validateDataMover(value.credentialId, value.ip, value.port, value.username, value.password, datamover, value.isSource);
   resolve(Boolean(res));
  }catch {
    resolve(false);
  }
}

const SourceDMValidation = debounce(validateDataMover, 800, {
  'leading': false,
  'trailing': true
});
const TargetDMValidation = debounce(validateDataMover, 800, {
  'leading': false,
  'trailing': true
});
const DataMoverCreateComponent: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {createJobStore,jobSettingsStore,displayStore, activityStore} = rootStore;
  const  {dataMover}=createJobStore.baseSettings;
  let labelRef;
  let targetlabelRef;
  let sourcelabelRef;
  const isPaaS= createJobStore.baseSettings.dataMover==  DataMover.PaaS;
  const isAzure = false;
  const [t] = useTranslation("createJobBase");
  const [ImageInfraColor, setImageInfraColor] = useState(false);
  const [CopyState, setCopyState] = useState(false);
  const [ImageDMColor, setImageDMColor] = useState(false);
 
  const [sourcepasswordVisibility,setSourcePasswordVisibility] = useState(true);
  const [targetpasswordVisibility,setTargetPasswordVisibility] = useState(true);
  const [passwordVisibility,setPasswordVisibility] = useState(true);
  const [DMtargetpasswordVisibility,setDMTargetPasswordVisibility] = useState(true);

  const [UserPassAutocomplete,setUserPassAutocomplete] = useState(false);
  const [DMUserPassAutocomplete,setDMUserPassAutocomplete] = useState(false);

  const [showEyeDMPassword, setShowEyeDMPassword]= useState(true);
  const [showEyeDMTargetPassword, setShowEyeDMTargetPassword]= useState(true);

  const getPreviousSourceUsername = !isPaaS ? jobSettingsStore.sourceUserNamesAndPass: [{username:'',password:''}];
  const getPreviousDatabaseUsername =[{username:'edradmin',password:'StartHere99!!'}]
  const previousSourceIPDataMover = jobSettingsStore.PreviousSourceIPDataMover;
  const previousTargetIPDataMover = jobSettingsStore.PreviousTargetIPDataMover;
  const previousTargetUsername = jobSettingsStore.targetUserNamesAndPass;
  const previousSourceUsername = jobSettingsStore.sourceUserNamesAndPass;
  const tempDataMover = jobSettingsStore.tempDataMover;
  const tempInfra = jobSettingsStore.tempInfra;
  const [isFormValidating, setisFormValidating] = useState(false);
  const [formValid, setformValid] = useState(true);
  const labels = useLabels(dataMover);
const useStyles = makeStyles(() => ({
  button: {
    height: '100%',
    borderRadius: '4px',
    border: 'solid 1px #c7cfdb',
    backgroundColor: '#fafafc'
  },
  advanced: {
    width: '14px',
    height: '14px',
    objectFit: 'contain',
  },
  formControl: {
    width: '100%',
    '& label': {
      background: "white",
      width: "auto",
      padding: "0 4px 0 4px",
      fontSize: '17px'
    }
  },
  validateIcon: {
    position: 'absolute',
    top: 15,
    right:8,
    color: '#8bc53f'
  },
  validationStatus: {
    color: 'red',
    position: 'absolute',
    top: 6,
    left: 10,
    display: 'block'
  }
}));
const classes = useStyles();

const getUsername = (type: string)=> {
    if(type == "source")
    {
        if(jobSettingsStore.selectedInfra && jobSettingsStore.selectedInfra.sourceInfraCredentials && jobSettingsStore.selectedInfra.sourceInfraCredentials.userName != '')
        {
            return jobSettingsStore.selectedInfra.sourceInfraCredentials.userName
        }
        if(jobSettingsStore.tempInfra && jobSettingsStore.tempInfra.sourceInfraCredentials) 
        {
            return jobSettingsStore.tempInfra.sourceInfraCredentials.userName
        }
    }
    else
    {
        if(jobSettingsStore.selectedInfra && jobSettingsStore.selectedInfra.targetInfraCredentials && jobSettingsStore.selectedInfra.targetInfraCredentials.userName != '') 
        {
            return jobSettingsStore.selectedInfra.targetInfraCredentials.userName
        }
        if(jobSettingsStore.tempInfra && jobSettingsStore.tempInfra.targetInfraCredentials) 
        {
            return jobSettingsStore.tempInfra.targetInfraCredentials.userName
        }
    }
}
const getPassword = (type: string)=> {
  if(type == "source")
  {
      if(jobSettingsStore.selectedInfra && jobSettingsStore.selectedInfra.sourceInfraCredentials && jobSettingsStore.selectedInfra.sourceInfraCredentials.password != '')
      {
          return jobSettingsStore.selectedInfra.sourceInfraCredentials.password
      }
      if(jobSettingsStore.tempInfra && jobSettingsStore.tempInfra.sourceInfraCredentials) 
      { 
          return jobSettingsStore.tempInfra.sourceInfraCredentials.password
      }
  }
  else
  {
      if(jobSettingsStore.selectedInfra && jobSettingsStore.selectedInfra.targetInfraCredentials && jobSettingsStore.selectedInfra.targetInfraCredentials.password != '')
      {
          return jobSettingsStore.selectedInfra.targetInfraCredentials.password 
      }
      if(jobSettingsStore.tempInfra && jobSettingsStore.tempInfra.targetInfraCredentials) 
      {
          return jobSettingsStore.tempInfra.targetInfraCredentials.password
      }
  }
}
const getCredsId = (type: string)=> {
    if(type == "source")
    {
        if(jobSettingsStore.selectedInfra && jobSettingsStore.selectedInfra.sourceInfraCredentials)
            return jobSettingsStore.selectedInfra.sourceInfraCredentials.id
    }
    else
    {
        if(jobSettingsStore.selectedInfra && jobSettingsStore.selectedInfra.targetInfraCredentials)
            return jobSettingsStore.selectedInfra.targetInfraCredentials.id
    }
}



function validateHostname(value) {
  if(dataMover !=DataMover.VeeamSQL)
  {
    let IpRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    let hostnameRegex = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;
    let isValidIP = IpRegex.test(value);
    let isValidHostname = hostnameRegex.test(value);
    if (isValidIP || isValidHostname)
      return true;
  
    return false;
  }
  else
    return true;
}


useEffect(() => {
  if(createJobStore.PlatformChanged)
    form.resetForm();
    
},[createJobStore.PlatformChanged]);



const validation = yup.object({
  source: yup.object({
    ip: yup.string().when("datamover", {
        is: function(v) {
          console.log(arguments, v);
          var enable =v!=DataMover.PaaS && v!=DataMover.VeeamSQL && v!=DataMover.CohesityRunbook && v!= DataMover.ZertoVmwareAzure;
          return enable;
        },
        then: yup.string()
              .nullable()
              .required("Required")
              .test('datamoverSourceIP',
                'Invalid Source Name/IP', validateHostname),
                otherwise: yup.string()
      }).when("datamover", {
        is: v => v===DataMover.VeeamSQL,
        then: yup.string().required("Required")
    }),
    credentialId: yup.number(),
    username: yup.string()
    .when("source.credentialId", {
      is: v => !v,
      then: yup.string().when('datamover', {
        is: DataMover.PaaS,
        then:yup.string().required('Required')
            .test('empty','username must be not empty',datamoverUsername => datamoverUsername !=='')
            .max(50,'up to 50 characters')
      })
    }),
    password:yup.string().when("source.credentialId",{
      is: v=>!v,
      then:     yup.string().when('datamover', {
        is: DataMover.PaaS,
        then:yup.string().required('Required')
            .test('empty','password must be not empty',datamoverPassword => datamoverPassword !=='')
            .max(50,'up to 50 characters')}),
      })
    }).test("validateDatamover", "Invalid Data Mover details", function(value, context) {
      if(context.parent.datamover == DataMover.ZertoVmwareAzure)
        return Promise.resolve(true);
      return new Promise(res => SourceDMValidation({...value, isSource: true}, res,context.parent.datamover))
    })

  
,
  target: yup.object({
    ip: yup.string().when("datamover", {
      is: v => v!=DataMover.PaaS && v!=DataMover.VeeamSQL && v!=DataMover.VeeamVmware && v!=DataMover.CohesityRunbook,
      then: yup.string()
            .nullable()
            .required("Required")
            .test('datamoverTargetIP',
                'Invalid Source Name/IP', validateHostname),
              otherwise: yup.string()
    }).when("datamover", {
        is: v => v===DataMover.VeeamSQL,
        then: yup.string().required("Required")
    }).when("datamover", {
      is: v => v===DataMover.VeeamVmware,
      then: yup.string().nullable()
  }),
    credentialId: yup.number(),
    username: yup.string().when("target.credentialId", {
      is: v => !v,
      then: yup.string().when('datamover', {
        is: DataMover.PaaS,
        then:yup.string().required('Required')
            .test('empty','username must be not empty',datamoverUsername => datamoverUsername !=='')
            .max(50,'up to 50 characters')
      })
    })
    ,
    password:yup.string().when("target.credentialId",{
      is: v=>!v,
      then:     yup.string().when('datamover', {
        is: DataMover.PaaS,
        then:yup.string().required('Required')
            .test('empty','password must be not empty',datamoverPassword => datamoverPassword !=='')
            .max(50,'up to 50 characters')}),
      })

    }).test("validateDatamover", "Invalid Data Mover details", function(value, context) {
      if(context.parent.datamover !== DataMover.VeeamSQL)
          return new Promise(res => TargetDMValidation(value, res,context.parent.datamover));
      return true;
    }),

    vmHosts:yup.string().when('datamover', {
      is: DataMover.VeeamVmware,
      then:yup.string().required('Required').test('empty','vmhosts must be not empty',vmhosts => vmhosts !=='')})
  })


const form = useFormik({
    initialValues: 
    {
        datamover: dataMover,
        source:{
          ip:'',
          username:'',
          password:'',
          credentialId: 0,
          port: null,
          datamover: dataMover
        },
        target:
        {
          ip:'',
          username:'',
          password:'',
          credentialId: 0,
          port: null,
          datamover: dataMover
        },
        vmHosts: ''
    },
    // validationSchema:validation,
    validateOnChange:false,
    validateOnBlur: false,
    onSubmit: () => { },
})

  useEffect(() => {
    displayStore.updateNextStepValid({ dmValid: isFormValidating ? false : formValid });
  }, [formValid, isFormValidating])


  
  function getFormValues() {

    let usernameSource = createJobStore.baseSettings.dataMover != DataMover.ZertoVmWare && createJobStore.baseSettings.dataMover != DataMover.SRM ? '' : getUsername('source');
    let passSource = createJobStore.baseSettings.dataMover != DataMover.ZertoVmWare && createJobStore.baseSettings.dataMover != DataMover.SRM ? '' : getPassword('source');
    let portSource =  jobSettingsStore.tempDataMover.sourcePort === 0 ? setDatamoverDefaultPort(createJobStore.baseSettings.dataMover, "source") : jobSettingsStore.tempDataMover.sourcePort;
    let credsIdSource = createJobStore.baseSettings.dataMover != DataMover.ZertoVmWare && createJobStore.baseSettings.dataMover != DataMover.SRM ? 0 : getCredsId('source');


    let usernameTarget = createJobStore.baseSettings.dataMover != DataMover.ZertoVmWare && createJobStore.baseSettings.dataMover != DataMover.SRM ? form.values.target.username : getUsername('target');
    let passTarget = createJobStore.baseSettings.dataMover != DataMover.ZertoVmWare && createJobStore.baseSettings.dataMover != DataMover.SRM ? form.values.target.password : getPassword('target');
    let portTarget =  jobSettingsStore.tempDataMover.targetPort === 0 ? setDatamoverDefaultPort(createJobStore.baseSettings.dataMover, "target") : jobSettingsStore.tempDataMover.targetPort;
    let credsIdTarget = createJobStore.baseSettings.dataMover != DataMover.ZertoVmWare && createJobStore.baseSettings.dataMover != DataMover.SRM ? 0 : getCredsId('target');

    const formValues = {
      datamover: form.values.datamover,
      source: {
        ip: form.values.source.ip,
        username: usernameTarget,
        password: passTarget,
        port: portSource,
        credentialId: credsIdSource,
        datamover: form.values.datamover
      },
      target: {
        ip: form.values.target.ip,
        username: usernameTarget,
        password: passTarget,
        port: portTarget,
        credentialId: credsIdTarget,
        datamover: form.values.datamover
      },
      vmHosts: createJobStore.baseSettings.vra||'',
    };
    return formValues;
  }

  useEffect(() => {
    const onDataLoaded = () => {
      const tempDataMover = jobSettingsStore.tempDataMover;
      const datamover= createJobStore.baseSettings.dataMover;
      let initialValues =
    {
      datamover: createJobStore.baseSettings.dataMover,
      source: {
        ip:  dataMover == DataMover.CohesityRunbook ? 'Helios-src': tempDataMover?.dataMoverSource || '',
        username: tempDataMover.sourceDataMoverCredentials.userName,
        password: tempDataMover.sourceDataMoverCredentials.password,
        credentialId: 0,
        port : setDatamoverDefaultPort(dataMover, "source"),
        datamover: createJobStore.baseSettings.dataMover
      },
      target: {
        ip: dataMover == DataMover.CohesityRunbook ? 'Helios-dr': tempDataMover?.dataMoverTarget || '',
        username: tempDataMover.targetDataMoverCredentials.userName,
        password: tempDataMover.targetDataMoverCredentials.password,
        credentialId: 0,
        port : setDatamoverDefaultPort(dataMover, "target"),
        datamover: createJobStore.baseSettings.dataMover
      },
      vmHosts: createJobStore.baseSettings.vra||''
    };
    if(datamover == DataMover.CohesityRunbook)
      jobSettingsStore.updateTempDataMover({dataMoverSource: initialValues.source.ip, dataMoverTarget: initialValues.target.ip})
    form.setValues(initialValues, true);
    }
    onDataLoaded();

    activityStore.on(UpdateType.FirstRunDefaultLoaded, onDataLoaded);

    return () => {
      activityStore.off(UpdateType.FirstRunDefaultLoaded, onDataLoaded);
    }
  }, []);

  useEffect(() => {
    let cancelled = false;
    setisFormValidating(true);
   setTimeout(() => {
    if(cancelled)
      return;
      const formValues = getFormValues()
     
      validation.validate(formValues, {abortEarly: false}).then(res => {
        if(cancelled)
         return; //early exit
        form.setErrors({source: null, target: null});
        setformValid(true);
      }).catch(err => {
        if(cancelled)
          return //early exit
        setformValid(false);  
        form.setErrors(yupToFormErrors(err));
      }).finally(() => {
         if(!cancelled)
          setisFormValidating(false);
      })
   }, 800)
    return () => {
      cancelled=true;
    }
  }, [form.values])

  const handleClickShowPassword  = (type:string) => {
    switch(type)
    {
      case "datamover": setPasswordVisibility(passwordVisibility? false: true); break;
      case "target": setDMTargetPasswordVisibility(DMtargetpasswordVisibility? false: true); break;
    }
};

const handleDMSourceIPChange =(event:object,value:any) => { 
  form.setFieldValue("source.ip",value,true);
  form.setFieldTouched("source.ip",true);
  jobSettingsStore.updateTempDataMover({ dataMoverSource: value });
  // if(rootStore.createJobStore.baseSettings.dataMover == DataMover.VeeamVmware)
  // {
  //   form.setFieldValue("target.ip",value,true);
  //   form.setFieldTouched("target.ip",true);
  //   jobSettingsStore.updateTempDataMover({ dataMoverTarget: value });
  // }
  CheckSectionAndDrawerStatus("datamover");
};

const handleDMTargetIPChange =(event:object,value:any) => { 
  form.setFieldValue("target.ip",value,true);
  form.setFieldTouched("target.ip",true);
  jobSettingsStore.updateTempDataMover({ dataMoverTarget: value });
  CheckSectionAndDrawerStatus("datamover");
};

const handleUsernameChange = (event:object,value:any,onInput:boolean) => {
  setUserPassAutocomplete(onInput);
  if (value!=null && typeof value === 'object') 
  {
    form.setFieldValue("source.username",value=="" ? '':value.username,true);
    form.setFieldValue("source.password",'',true);
    jobSettingsStore.updateTempDataMover({ sourceDataMoverCredentials: { ...rootStore.jobSettingsStore.tempDataMover.sourceDataMoverCredentials,userName:value=="" ? '':value.username, password: ''} });
    jobSettingsStore.updateTempDataMover({ targetDataMoverCredentials: { ...rootStore.jobSettingsStore.tempDataMover.targetDataMoverCredentials,userName:value=="" ? '':value.username,  password:''} });
  }
  if (typeof value === 'string')
  {
    const userObj = getPreviousSourceUsername.find(e => e.username.toLocaleLowerCase() == value.toLocaleLowerCase());
    form.setFieldValue("source.username",value,true);
    let pass = '';
    form.setFieldValue("source.password",pass,true);
    jobSettingsStore.updateTempDataMover({ sourceDataMoverCredentials: { ...rootStore.jobSettingsStore.tempDataMover.sourceDataMoverCredentials,userName:value, password: pass } });
    jobSettingsStore.updateTempDataMover({ targetDataMoverCredentials: { ...rootStore.jobSettingsStore.tempDataMover.targetDataMoverCredentials,userName:value,  password: pass } });
  }
  setShowEyeDMPassword(onInput);
  if(!onInput)
  {
    setPasswordVisibility(true);
  }
};

const handlePassChange = (event) => {
  setUserPassAutocomplete(true);
  setShowEyeDMPassword(true);
  form.setFieldValue("source.password",event.target.value,true);
  jobSettingsStore.updateTempDataMover({ sourceDataMoverCredentials: { ...rootStore.jobSettingsStore.tempDataMover.sourceDataMoverCredentials, password: event.target.value } });
  jobSettingsStore.updateTempDataMover({ targetDataMoverCredentials: { ...rootStore.jobSettingsStore.tempDataMover.targetDataMoverCredentials, password: event.target.value } });
};

const handleDMTargetUsernameChange = (event:object,value:any,onInput:boolean) => {
  setDMUserPassAutocomplete(onInput);
  if (value!=null && typeof value === 'object') 
  {
      form.setFieldValue("target.username",value=="" ? '':value.username,true);
      form.setFieldValue("target.password",'',true);
      jobSettingsStore.updateTempDataMover({ targetDataMoverCredentials: { ...rootStore.jobSettingsStore.tempDataMover.targetDataMoverCredentials, userName: value=="" ? '':value.username, password: ''} });
  }
  if (typeof value === 'string')
  {
      const userObj = getPreviousSourceUsername.find(e => e.username.toLocaleLowerCase() == value.toLocaleLowerCase());
      form.setFieldValue("target.username",value,true);
      let pass ='';
      form.setFieldValue("target.password",pass,true);
      jobSettingsStore.updateTempDataMover({ targetDataMoverCredentials: { ...rootStore.jobSettingsStore.tempDataMover.targetDataMoverCredentials, userName: value, password:pass } });
  }
  setShowEyeDMTargetPassword(onInput);
  if(!onInput)
  {
    setDMTargetPasswordVisibility(true);
  }
  CheckSectionAndDrawerStatus("datamover");
};

const handleDMTargetPassChange = (event) => {
  setDMUserPassAutocomplete(true);
  setShowEyeDMTargetPassword(true);
  form.setFieldValue("target.password",event.target.value,true);
  jobSettingsStore.updateTempDataMover({ targetDataMoverCredentials: { ...rootStore.jobSettingsStore.tempDataMover.targetDataMoverCredentials, password: event.target.value } });
  CheckSectionAndDrawerStatus("datamover");
};

const loadDataMoverSourceIP =() => {
  return (
    <IfCondition condition={dataMover !== DataMover.CohesityRunbook}>
          <Tooltip title={t(labels.source, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})} placement="top">
      <FormControl className={classes.formControl} variant='outlined'>
      <EDRAutocomplete id="datamoverSourceIP"
      freeSolo
      disableClearable
      value={form.values && form.values.source &&form.values.source.ip}
      options={previousSourceIPDataMover}
      onChange={handleAutoCompleteChange}
      onInputChange={handleAutoCompleteChange}
      renderInput={(params) => (<EDRTextField {...params} label={t(labels.source, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})}
      InputLabelProps={{shrink: true}} 
      variant="outlined"
      placeholder="Type Here"
      aria-autocomplete="none"
      name="datamoverSourceIP"
      onBlur={() => {
        form.setTouched({
          ...form.touched,
          source:{
            ip: true
          }
        })
      }}
      error={!isFormValidating && form.touched?.source?.ip && Boolean(typeof form.errors.source == "string"? form.errors.source: form.errors.source?.ip)}
      helperText={!isFormValidating && form.touched?.source?.ip && (t(typeof form.errors?.source == "string" ? form.errors.source : form.errors?.source?.ip)) }
      />)}
    />
    <React.Fragment>{form.touched?.source?.ip && !isFormValidating && !form.errors.source && <CheckCircle className={classes.validateIcon} />}</React.Fragment>
      </FormControl>
      </Tooltip>
    </IfCondition>
  );
}
  
const loadDataMoverTargetIP= ()=> {
  return (
<IfCondition condition={dataMover !== DataMover.CohesityRunbook}>
<Tooltip title={t(labels.target, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})} placement="top">
      <FormControl className={classes.formControl} variant='outlined'>
      <EDRAutocomplete id="datamoverTargetIP"
      freeSolo
      disableClearable
      value={form.values && form.values.target && form.values.target.ip}
      options={previousTargetIPDataMover}
      onChange={handleAutoCompleteChange}
      onInputChange={handleAutoCompleteChange}
      renderInput={(params) => (<EDRTextField {...params} label={t(labels.target, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})} 
      InputLabelProps={{shrink: true}} 
      variant="outlined"
      placeholder="Type Here"
      aria-autocomplete="none"
      onBlur={() => {
        form.setTouched({
          ...form.touched,
          target:{
            ip: true
          }
        })
      }}
      error={!isFormValidating && form.touched?.target?.ip && Boolean(typeof form.errors.target == "string"? form.errors.target: form.errors.target?.ip)}
      helperText={!isFormValidating && form.touched?.target?.ip && (t(typeof form.errors?.target == "string" ? form.errors.target : form.errors?.target?.ip)) }
      />)}
    />
     <React.Fragment>{form.touched?.target?.ip && !isFormValidating && !form.errors.target && <CheckCircle className={classes.validateIcon} />}</React.Fragment>
      </FormControl>
      </Tooltip>
</IfCondition>
  );
}
  
const loadDataMoverDrawer= ()=> {
      return (
          <Button className={classes.button}  disabled={true} onClick={() => buttonRedirect('DataMoverCreateAdvanced')}>
          <img className={classes.advanced} onMouseEnter={()=> setImageDMColor(true)} onMouseLeave={()=> setImageDMColor(false)} src={ImageDMColor ? '/assets/images/createjob/advancedNetworkBlue.svg':'/assets/images/createjob/advancedNetwork.svg'}></img>
          </Button>
      );
}
  
const loadUsername=()=>{
  return(
    <Tooltip title={t(labels.sourceUsername, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})} placement="top">
  <FormControl className={classes.formControl} variant='outlined'>
                     <EDRAutocomplete id="datamoverUsername"
                              freeSolo
                              disableClearable
                              value={form.values && form.values.source &&form.values.source.username}
                              options={getPreviousDatabaseUsername}
                              renderOption={(option: string|{username: string,password:string}) => (typeof option === 'string' ? option : `${option.username}`)}
	                            getOptionLabel={(option: string|{username: string,password:string}) => (typeof option === 'string' ? option : `${option.username}`)}
                              onChange={handleAutoCompleteChange}
                              onInputChange={handleAutoCompleteChange}
                              renderInput={(params) => (<EDRTextField {...params}
                              label= {t(labels.sourceUsername, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})}
                              placeholder="Type Here"
                              aria-autocomplete="none"
                              defaultValue={form.values.source.username}
                              InputLabelProps={{shrink: true}} 
                              variant="outlined"
                              onBlur={() => {
                                form.setTouched({
                                  ...form.touched,
                                  source:{
                                    ip: true
                                  }
                                })
                              }}
                              error={!isFormValidating && form.touched?.source?.ip && Boolean(typeof form.errors.source == "string"? form.errors.source: form.errors.source?.ip)}
                              helperText={!isFormValidating && form.touched?.source?.ip && (t(typeof form.errors?.source == "string" ? form.errors.source : form.errors?.source?.ip)) }
                              />)}
                              />
         
                      </FormControl>
                      </Tooltip>
  );
}
  
const loadPassword=()=>{
    return( <Tooltip title={t(labels.sourcePassword, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})} placement="top">
    <FormControl className={classes.formControl} variant='outlined'>
                      <InputLabel  ref={ref => {labelRef = ReactDOM.findDOMNode(ref)}} style={{transform: 'translate(14px, -5px) scale(0.75)'}}>{isPaaS ||isAzure ?t('databaseSecret'):(createJobStore.baseSettings.dataMover == DataMover.VeeamSQL ? "Veeam "+t('repositoryPassword'):t('password'))}</InputLabel>
                      <EDROutlinedInput id="datamoverPassword" 
                          name="datamoverPassword"
                          value={form.values && form.values.source && form.values.source.password}
                          labelWidth={labelRef ? labelRef.offsetWidth:0}
                          autoComplete="off"
                          type={passwordVisibility ? 'password':'text'}
                          placeholder="Type Here"
                          aria-autocomplete="none"
                        //  style={{ backgroundColor:(displayStore.sectionSettings.platformSection ? 'rgb(233, 235, 238)': '#ffffff')}}
                          error={form.touched && form.touched.source?.password && Boolean(form.errors.source.password)}
                          onChange={handleValueChange}
                          onClick={event=> {handleMouseClick("datamoverPassword")}}
                          onBlur={event=> {handleMouseOut("datamoverPassword")}}
                          endAdornment={ form.values.source?.password  && DMUserPassAutocomplete?
                          <InputAdornment position="end">
                          <IconButton
                          aria-label="toggle password visibility"
                          onClick={()=> handleClickShowPassword("datamover")}
                          >
                          {passwordVisibility ?  <VisibilityOff />: <Visibility />}
                      </IconButton>
              </InputAdornment>
                :null }
              />  
            </FormControl>
            </Tooltip>
    );
}

const buttonRedirect= (subComponent:any)=> {
    if (subComponent=="DataMoverCreateAdvanced")
    {
      if(displayStore.BaseDrawerSettings.DataMoverDrawerOpen)
      {
        displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
        displayStore.updateBaseDrawerSettings({DataMoverDrawerOpen: false});
      }
      else
      {
        displayStore.updateScreenSettings({displayBaseSettings: subComponent});
        displayStore.closeAllOtherBaseDrawersExcept("DataMoverDrawer");
      }
    }
};

const CheckSectionAndDrawerStatus= (section:string) =>{
  switch(section)
  {
    case "infrastructure":
      {
        if(displayStore.screenSettings.displayBaseSettings=='DataMoverCreateAdvanced' || displayStore.screenSettings.displayBaseSettings=='WorkflowAdvanced' || displayStore.screenSettings.displayBaseSettings=='BasicAdvanced' || displayStore.screenSettings.displayBaseSettings=='LicenseAdvanced')
        {
          displayStore.updateScreenSettings({displayBaseSettings: "InfrastructureCreateAdvanced"});
          displayStore.updateBaseDrawerSettings({InfraDrawerOpen: true});
          displayStore.updateBaseDrawerSettings({PrimaryUserDrawerOpen: false});
          displayStore.updateBaseDrawerSettings({SecondaryUserDrawerOpen: false});
          displayStore.updateBaseDrawerSettings({BasicDrawerOpen: false});
          displayStore.updateBaseDrawerSettings({LicenseDrawerOpen: false});
          displayStore.updateBaseDrawerSettings({DataMoverDrawerOpen: false});
          displayStore.updateBaseDrawerSettings({WorkflowDrawerOpen: false});
          displayStore.updateBaseDrawerSettings({EDRDrawerOpen: false});
        }
      }
    case "datamover":
      {
          if(displayStore.screenSettings.displayBaseSettings=='InfrastructureCreateAdvanced'|| displayStore.screenSettings.displayBaseSettings=='WorkflowAdvanced' || displayStore.screenSettings.displayBaseSettings=='BasicAdvanced' || displayStore.screenSettings.displayBaseSettings=='LicenseAdvanced')
          {
              displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
              displayStore.updateBaseDrawerSettings({BasicDrawerOpen: false});
              displayStore.updateBaseDrawerSettings({PrimaryUserDrawerOpen: false});
              displayStore.updateBaseDrawerSettings({SecondaryUserDrawerOpen: false});
              displayStore.updateBaseDrawerSettings({LicenseDrawerOpen: false});
              displayStore.updateBaseDrawerSettings({InfraDrawerOpen: false});
              displayStore.updateBaseDrawerSettings({DataMoverDrawerOpen: true});
              displayStore.updateBaseDrawerSettings({WorkflowDrawerOpen: false});
              displayStore.updateBaseDrawerSettings({EDRDrawerOpen: false});
          } 
      }
  }
}

const handleMouseClick =(type:string)=>{
  switch(type)
  {
    case "datamoverPassword":
        {
            if(form.values.source.password === CONSTS.MaskedPassword)
            {
                form.setFieldValue("source.password",'',true);
            }
            break;
        }
    case "DMTargetPassword":
        {
            if(form.values.target.password === CONSTS.MaskedPassword)
            {
                form.setFieldValue("target.password",'',true);
            }
            break;
        }
  }
}

const handleMouseOut =(type:string)=>{
switch(type)
{
    case "datamoverPassword":
        {
            if(form.dirty && form.values.source.password == '')
            {
              if(form.values.source.username!='')
              {
                  return;
              }
              else form.setFieldValue("source.password",CONSTS.MaskedPassword,true);
            }
            break;
        }
    case "DMTargetPassword":
        {
            if(form.dirty && form.values.target.password == '')
            {
              if(form.values.target.username!='')
              {
                return;
              }
              else form.setFieldValue("target.password",CONSTS.MaskedPassword,true);

              form.setFieldTouched("target.password", true);
            }
            break;
        }
}
}


const handleValueChange = (event: React.ChangeEvent) => {
  form.handleChange(event);
  switch(event.target['name']) {
    case 'datamoverPassword': handlePassChange(event); break;
    case 'DMTargetPassword': handleDMTargetPassChange(event);break;
  }
}

const handleAutoCompleteChange = (event: React.ChangeEvent,value:any) => {
  if(event!=null)
  {
    let fieldName= event.target['id'].includes('-') ? event.target['id'].split('-')[0]: event.target['id'];
    switch(fieldName) {
      case 'datamoverSourceIP': handleDMSourceIPChange(event,value); break;
      case 'datamoverTargetIP': handleDMTargetIPChange(event,value); break;
      case 'datamoverUsername': handleUsernameChange(event,value,event.type=="click"?false:true); break;
      case 'DMTargetUsername': handleDMTargetUsernameChange(event,value,event.type=="click"?false:true); break;
    }
  }
}

const handleVmHostChange = (event, value: Array<string> | string) => {
  if(dataMover == DataMover.CohesityRunbook)
    return;
  const vmHosts =  value instanceof Array? value.join(';'): value;
  form.setFieldValue("vmHosts", vmHosts);
  createJobStore.updateBaseSettings({vra: vmHosts});
}

const handleVmHostInputChange=(event, value:string) => {
  if(dataMover != DataMover.CohesityRunbook)
    return;
  const vmHosts =  value;
  form.setFieldValue("vmHosts", vmHosts);
  createJobStore.updateBaseSettings({vra: vmHosts});
}

const handleVmHostBlur = (event) => {
  const value = event.target.value;
  if(dataMover == DataMover.CohesityRunbook)
    return;
  if(value) {
    const vmHosts = form.values.vmHosts.split(',')|| [];
    if(vmHosts.indexOf(value)< 0 )
      {
        vmHosts.push(value);
        form.setFieldValue('vmHosts', vmHosts.filter(v=>v).join(','));
        createJobStore.updateBaseSettings({vra: vmHosts.filter(v=>v).join(',')});
      }

  }
  form.handleBlur(event);
}

const loadDataMoverTargetUsername =() => {
  let error = !isFormValidating && form.touched?.target?.username && form.errors?.source?.username;
  if(dataMover == DataMover.CohesityRunbook) {
    error = !isFormValidating && form.touched?.target?.username && (typeof form.errors.target == "string"? form.errors.target: form.errors.target?.username);
  }
  return (
<Grid item xs={6} style={{paddingTop:'2%'}}>
<Tooltip title={t(labels.targetUsername, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})} placement="top">
<FormControl className={classes.formControl} variant='outlined'>
                        <EDRAutocomplete id="DMTargetUsername"
                        freeSolo
                        disableClearable
                        value={form.values && form.values.target &&form.values.target.username}
                        options={previousTargetUsername}
                        renderOption={(option: string|{username: string,password:string}) => (typeof option === 'string' ? option : `${option.username}`)}
	                      getOptionLabel={(option: string|{username: string,password:string}) => (typeof option === 'string' ? option : `${option.username}`)}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        onBlur={() => {
                          form.setTouched({
                            ...form.touched,
                            target:{
                              username: true
                            }
                          })
                        }}
                        renderInput={(params) => (<EDRTextField {...params}
                        label= { t(labels.targetUsername, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})}
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        placeholder="Type Here"
                        aria-autocomplete="none"
                        error={Boolean(error)}
                        helperText={error }
          />)}
                        />
                         <IfCondition condition={dataMover == DataMover.CohesityRunbook}>
                           <React.Fragment>{form.touched?.target?.username && !isFormValidating && !form.errors.target && <CheckCircle className={classes.validateIcon} />}</React.Fragment>
                        </IfCondition>
                    </FormControl>
                    </Tooltip>
                    </Grid>
  );
}

const loadDataMoverTargetPassword =() => {
  return (
    <Grid item xs={6} style={{paddingTop:'2%'}}> 
    <Tooltip title={t(labels.targetPassword, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})} placement="top">  
<FormControl className={classes.formControl} variant='outlined'>
                    <InputLabel ref={ref => {targetlabelRef = ReactDOM.findDOMNode(ref)}} style={{transform: 'translate(14px, -7px) scale(0.75)'}}>{t(labels.targetPassword, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})}</InputLabel>
                    <EDROutlinedInput id="DMTargetPassword" 
                        name="DMTargetPassword"
                        value={form.values && form.values.target && form.values.target.password}
                        style={{width:'100%'}}
                       // style={{backgroundColor:(displayStore.sectionSettings.platformSection? 'rgb(233, 235, 238)': '#ffffff')}}
                        label={<InputLabel>{ t("targetPassword")}</InputLabel>}
                        labelWidth={targetlabelRef ? targetlabelRef.offsetWidth:0}
                        autoComplete="off"
                        type={DMtargetpasswordVisibility ? 'password':'text'}
                        placeholder="Type Here"
                        error={form.touched?.target?.password && (Boolean(form.errors?.target?.password) || Boolean(form.errors.target))}
                        onChange={handleValueChange}
                        onClick={event=> {handleMouseClick("DMTargetPassword")}}
                        onBlur={event=> {handleMouseOut("DMTargetPassword")}}
                        endAdornment={ form.values.target.password && DMUserPassAutocomplete?
                        <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        aria-autocomplete="none"
                        onClick={()=> handleClickShowPassword('target')}
                        >
                        {DMtargetpasswordVisibility ?  <VisibilityOff />: <Visibility />}
                    </IconButton>
            </InputAdornment>
              :null }
            />  
          </FormControl>
          </Tooltip>
          {/* <IfCondition condition={dataMover == DataMover.CohesityRunbook && !form.isValid && Boolean(form.errors.target)}>
                  <em>Invalid Api key</em>
          </IfCondition> */}
          </Grid>
  );
}

const loadESXHostname =()=>{
  return(
    <FormControl className={classes.formControl} variant='outlined' style={{width:'100%',paddingTop:'1%'}}>
    <EDRAutocomplete id="vmHosts"
    freeSolo
    disableClearable
    multiple={dataMover != DataMover.CohesityRunbook ? true: false}
    value={form.values.vmHosts? dataMover == DataMover.CohesityRunbook? form.values.vmHosts: form.values.vmHosts.split(','): []}
    options={[]}
    onChange={handleVmHostChange}
    onInputChange={handleVmHostInputChange}
    renderTags={(value: string[], getTagProps) =>
      value.map((option: string, index: number) => (
        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
      ))
    }
    renderInput={(params) => (<EDRTextField {...params} label={dataMover == DataMover.CohesityRunbook ? "Target Profile": "Vmware Host ESXi Name/IP"} 
    InputLabelProps={{shrink: true}} 
    disabled={true}
    variant="outlined"
    placeholder="Type Here" 
    onBlur={dataMover !== DataMover.CohesityRunbook ?handleVmHostBlur: null}
    error={form.touched && form.touched.vmHosts && form.errors && Boolean(form.errors.vmHosts)}
    helperText={form.touched && form.touched.vmHosts && form.errors && Boolean(form.errors.vmHosts) && t(form.errors.vmHosts)}
    />)}
  />
    </FormControl>
  )
}

const loadDataMoverSection=()=>{
  return(
    <React.Fragment>
      <IfCondition condition={dataMover != DataMover.ZertoVmwareAzure}>
      <Grid item xs={6} style={{paddingTop: dataMover != DataMover.CohesityRunbook ? '4%': '2%'}}>
        {loadDataMoverSourceIP()}
      </Grid>
      </IfCondition>
      <Grid container item xs={dataMover == DataMover.ZertoVmwareAzure ? 12: 6} direction='row' spacing={2} style={{paddingTop: dataMover != DataMover.CohesityRunbook ? '4%': '2%'}}>
        <Grid item xs={10}>
          {loadDataMoverTargetIP()}
        </Grid>
        <IfCondition condition={dataMover != DataMover.CohesityRunbook}>
        <Grid item xs={2} style={{paddingBottom:'12px',height:'74px',paddingLeft:'3%'}}>
          {loadDataMoverDrawer()}
        </Grid>
        </IfCondition>
      </Grid>
      {!isPaaS && (dataMover!=DataMover.SRM && dataMover!=DataMover.ZertoVmWare && dataMover!=DataMover.Rubrik ) ? loadDataMoverTargetUsername():null}
      {!isPaaS && (dataMover!=DataMover.SRM && dataMover!=DataMover.ZertoVmWare && dataMover!=DataMover.Rubrik ) ? loadDataMoverTargetPassword():null}
      <IfCondition condition={dataMover == DataMover.Netapp || dataMover == DataMover.VeeamVmware || dataMover == DataMover.CohesityRunbook}>
      <Grid item xs={12}>
          {loadESXHostname()}
      </Grid>
      </IfCondition>
   </React.Fragment>
  );
}

const loadAzureDBSection =()=>{
  return(
    <React.Fragment>
        <Grid item xs={6} style={{marginTop:isPaaS ? '16px':'0px'}}>
          {loadUsername()}
        </Grid>
        <Grid item xs={6} style={{marginTop:isPaaS ? '16px':'0px'}}>
          {loadPassword()}
        </Grid>
   </React.Fragment>
  );
}

  return (

        
        <Grid container spacing={2} style={{position: 'relative'}}>
           {/* <Grid item xs={12} style={{ position: 'absolute'}}> */}
           {isFormValidating && <Typography variant="body2" className={classes.validationStatus}>Validating DataMover...</Typography>}
          {/* </Grid> */}
          {isPaaS || isAzure ?loadAzureDBSection() : loadDataMoverSection()}
        </Grid>

  );
};

export default observer(DataMoverCreateComponent)