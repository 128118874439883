import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Paper, Grid, Typography, Button, makeStyles,Tooltip } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import CheckIcon from '@material-ui/icons/CheckCircle';
import CrossIcon from '@material-ui/icons/CancelRounded';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon from '@material-ui/icons/Settings';
import NetworkIcon from '@material-ui/icons/Language';
import SpannerIcon from '@material-ui/icons/Build';
import WebIcon from '@material-ui/icons/Web';
import BugIcon from '@material-ui/icons/BugReport';
import FlashIcon from '@material-ui/icons/FlashOn';
import DatabaseIcon from '@material-ui/icons/Storage';
import DescriptionIcon from '@material-ui/icons/Description';
import TestWorkFlowComponent from './testWorkflow.component';
import { RootStoreContext } from '../../app/stores/root.store';
import moment from 'moment';
import queryString from 'querystring';
import { convertSecondsToTime, ConvertStringToTimeSpan, convertTimespanToString } from '../../app/common/util/util';

import { history } from '../../app/common/util/history';
import TimeLineSelectorComponent from '../../app/common/timelineSelector.component';
import DeviceListComponent from './deviceList.component';
import JobActionMenu from '../../app/common/jobActionMenu.component';
import JobCategoryIcon from '../../app/common/jobCategoryIcon';
import TestOverViewTile from './TestOverView.component';
import ReportSkeleton from '../report/reportSkeleton.component';
import RecoveryReadinessChart from '../report/recoveryReadiness.component';
import GaugeChart from '../../app/common/gaugeChart.component';
import { WorkFlowStatus, WorkflowStep, WorkflowStepType } from '../../app/models/workflowStep.model';
import { useTranslation } from 'react-i18next';
import { TrendIndicator } from '../../app/common/trendIndicator.component';
import JobResultsApi from '../../app/api/jobResult.api';
import { toast } from 'react-toastify';
import MiniReportJobRunView from './miniReportJobRun.component';
import { UpdateType } from '../../app/stores/activityStore';
import IssueAndAutoFixComponent from '../report/issuesAndAutofix.component';
import RRSDialog from '../../app/common/RRSDialog.component';
import RTADialog from '../../app/common/RTADialog.component';
import { AutofixIcon, CustomIcon, CyberIcon, InternetConnectionIcon, IssuesIcon, PreScriptIcon, ServiceIcon, ThirdDeviceIcon, WebportalIcon } from '../Icons';
import { CPUIcon, IopsIcon, RamIcon } from '../../app/common/drResource/resourcesIcon.component';
import { DeviceType, DEVICE_TYPE_NAME_SEPERATE_DC, DEVICE_TYPE_SMALL_ICON } from '../../app/models/deviceType.enum';
import TestOverviewDevicesComponent from './TestOverviewDevices.component';
import ResourcesAndICComponent from '../report/resourcesAndIC.component';
import { JobDeviceResultShort } from '../../app/models/jobDevice/jobDeviceResult.reponse.model';
import ReportTestsComponent from '../report/reportTests.component';
import { LastJobRunStatus } from '../../app/models/lastjobRun.model';
import DRResourceSectionComponent from '../report/drResourceSection.component';
import { JobRunType } from '../../app/models/BaseSettings.enum';
const useStyles = makeStyles(theme => ({
    iconCell: {
        display: "flex"
    },
    testIcon: {
        color: '#8092a9'
    },
    hover: {
        "&:hover": {
           backgroundColor: '#bfdff3',
           cursor:'pointer'
       }
   },
    deviceCellIcon: {
        marginRight: "5px",
        marginLeft: "8px",
        fontSize: "20px"
    },
    daySelector: {
        background: 'white',
        borderBottom: '1px solid #f4f5f9'
    },
    playPauseButton: {
        color: "#2892d7"
    },
    fullReportButton: {
        borderRadius: "23px",
        color: "white",
        background: "#2994cf",
        marginRight: "10px"
    },
    menuButton: {
        width: "35px",
        height: "35px",
        borderRadius: "18px",
        border : "1px solid #c7cfdb",
        minWidth: "0"
    },
    stickyTop: {
        position: 'sticky',
        top: 0,
        zIndex: 1100
    },
    label: {
        textTransform: 'capitalize',
      },
    jobName: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '100%'
         },
         
    },
    sideBySide: {
        display: 'flex',
        flexDirection: 'row'
    }
})
)


const MiniReportComponent = () => {

    const { reportStore, userStore, jobResultStore, jobRunStore, settingStore, activityStore } = useContext(RootStoreContext);
    const { selectedJobResult: jobReport, jobTestOverview: jobTest, isLoading } = reportStore;
    const [issueCount, setIssuesCount] = useState(0);
    const [selectedDate, setSelectedDate] = useState("");
    const [devices, setDevices] = useState<Array<JobDeviceResultShort>>([]);
    const [openRRSDialog, setopenRRSDialog] = useState(false);
    const [openRTADialog, setopenRTADialog] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [t] = useTranslation("report");
    const jobRunState = jobRunStore.getJobRun(jobResultStore.slectedJobId);
    const job = jobResultStore.selectedJob;
    const { reportSettings,dashboardSettings } = settingStore;
    const animationPlaySettings = reportSettings.find(s => s.settingKey == 'AutoPlayAnimation');
    const maxIops = dashboardSettings.find(s => s.settingKey == 'SetIOPS');
    const classes = useStyles();
    
    useEffect(() => {
        var isCancelled = false;
            if(jobReport){
                let date = moment(jobReport.runDate).format("YYYY-MM-DD")+"T00:00:00";
                setSelectedDate(date);
                JobResultsApi.devices(jobReport.id, 0, 0, 'id,type,status,machineName,testGroup,testGroupName,autoTroubleshooting,jobTestResults,devicePlatform,deviceUserName').then(res => {
                if (isCancelled)
                  return;
                setDevices(res.records.sort((d1,d2) => d1.testGroup - d2.testGroup));
              });
            }
            return () => {
                isCancelled = true
              }
    }, [ jobReport])
    
    useEffect(() => {
       let issueCount = 0;
       Object.keys(jobTest).forEach(test => {
           if(typeof jobTest[test] == 'object')
           issueCount +=jobTest[test].failed
       });
       issueCount += (jobReport && jobReport.knownIssues.filter(k => k.workflowIssue).length) || 0
       setIssuesCount(issueCount);
    }, [jobTest, jobReport])

    useEffect(() => {
        const { selectedJob } = jobResultStore;
        if (selectedJob) {
            reportStore.setSelectedJobSummaryId(selectedJob.id);
            reportStore.setSelectedJobId(selectedJob.jobId);
            reportStore.fetchTimeline(null, selectedJob.runDate);
        }
    }, [jobResultStore.selectedJob]);

    
    // for job action updates
  useEffect(() => {
    const updateHandler = (update: {jobId: number}) => {
        const { selectedJob } = jobResultStore;
        if (selectedJob && selectedJob.jobId === update.jobId) {
            reportStore.fetchJobReport(reportStore.selectedJobSummaryId);
            reportStore.fetchJobTestOverview(reportStore.selectedJobSummaryId)
            reportStore.fetchTimeline(null, selectedJob.runDate);
        }
    }
      activityStore.on(UpdateType.JobRemoved, updateHandler);
      activityStore.on(UpdateType.JobRenamed, updateHandler);
      activityStore.on(UpdateType.JobRestored, updateHandler);
      return () => {
        activityStore.off(UpdateType.JobRemoved, updateHandler);
        activityStore.off(UpdateType.JobRenamed, updateHandler);
        activityStore.off(UpdateType.JobRestored, updateHandler);
      }
  }, []);

    const onDateSelected = (date: string, jobresultId: number) => {
        setSelectedDate(date);
        reportStore.setSelectedJobSummaryId(jobresultId);
    }

    const fetchNextPage = () => {
        reportStore.fetchTimeline(reportStore.timeline.page-1).then(({records: timeline}) => {
            if(timeline.length > 0) {
                const last = timeline[0];
                onDateSelected(last.date, last.results[0].id);
            }
        });
      }
    
      const fetchPrevPage = () => {
        reportStore.fetchTimeline(reportStore.timeline.page+1).then(({records: timeline}) => {
            if(timeline.length > 0) {
                const last = timeline.slice(-1)[0];
                onDateSelected(last.date, last.results[0].id);
            }
        });
      }

    const showFullReport = () => {
        history.push("/report?" + queryString.stringify({ jobId: jobReport.jobId, jobResultId: jobReport.id }));
    }

    const handleMenuClose = () => {
        setMenuAnchor(null);
    }

    const Noloadpopup =()=>{
        if(jobRunStore.isJobRunning(jobReport.jobId))
          return false;
        if(jobReport.score == 0)
          return false;
        return true;
      }

    const handleRandomDateSelection = (date: Date) => {
        JobResultsApi.getJobResultByDate(jobReport.jobId, date.toISOString()).then(res => {
            if(res) {
                onDateSelected(res.runDate as string, res.id);
                reportStore.fetchTimeline(null, res.runDate as string);
            } else {
                toast.warn(t('noJobRunForYear', {date: date.getFullYear()}))
            }
        })
    }

    const getTitle =(type: any)=>{
        if(!type)
        {
            return t("testWorkflow.title") + ' ' + t("testWorkflow.all");
        }
        let title = t("testWorkflow.title");
        let res="";
        if(type === JobRunType.All)
          res = t("testWorkflow.all");
        else if(type === JobRunType.CleanUpOnly)
          res = t("testWorkflow.cleanupOnly");
        else if(type === JobRunType.FailoverOnly)
          res = t("testWorkflow.failoverOnly");
        else
          res = t("testWorkflow.liveFailoverOnly");
        return title + res;
      }
      
    const showError = (steps: WorkflowStep[], endMessage: string) => {
        let Errors=[];
        if(steps && steps.length > 0)
        {
            steps.map(s => {
                if(s.workflowIssue && s.workflowIssue != undefined && s.workflowIssue != "")
                Errors.push( 
                    <React.Fragment>
                        <CrossIcon style={{marginRight: 5, fontSize: 14}} ></CrossIcon>
                        <Typography color="error" variant="body1">{s.workflowIssue}</Typography>
                        <br></br>
                    </React.Fragment> )
                
            })
        }
        if(endMessage && endMessage != '' && endMessage != undefined)
        {
            Errors.push(
                <React.Fragment>
                    <CrossIcon style={{marginRight: 5, fontSize: 14}} ></CrossIcon>
                    <Typography color="error" variant="body1">{endMessage}</Typography>
                    <br></br>
                </React.Fragment>
            )
        }
        if(Errors.length > 0)
            return Errors
        return (
            <React.Fragment>
                    <CrossIcon style={{marginRight: 5, fontSize: 14}} ></CrossIcon>
                    <Typography color="error" variant="body1">{t('failoverIssue')}</Typography>
            </React.Fragment>
        )
      }
    return <Grid container direction="column" data-test="component-miniReport" className='hide-scrollbar'>
            <Grid item xs={12} className={classes.stickyTop}>
                <div className={classes.daySelector}>
                {!jobRunState && <TimeLineSelectorComponent
                        records={reportStore.timeline && reportStore.timeline.records || []}
                        hasPreviousPage = {reportStore.timeline && reportStore.timeline.page < reportStore.timeline.totalPages-1 }
                        hasNextPage = {reportStore.timeline && reportStore.timeline.page > 0}
                        selectedDate={selectedDate}
                        selectedClassName=""
                        onNextPage={(after) => fetchNextPage()}
                        onPrevPage={(before) => fetchPrevPage()}
                        onSelect={onDateSelected}
                        selectedJobResultId={jobReport && jobReport.id}
                        onYearSelected={handleRandomDateSelection}
                    >

                    </TimeLineSelectorComponent>}
                </div>
            </Grid>
            {jobRunState && <> 
                    <MiniReportJobRunView jobReport={{
                        jobName: jobRunState.jobName,
                        createDate:job && job.createDate,
                        id: job && job.id, 
                        jobId: jobRunState.jobId, 
                        dataMover:job && job.dataMover,
                        PrePostScripts:jobReport && jobReport.prePostScripts}} jobRunState={jobRunState}/>
                </>}
            {(!jobResultStore.selectedJob || (isLoading && !jobRunState)) &&  <ReportSkeleton />} 
            {!isLoading && jobReport && !jobRunState && <React.Fragment> 
           
                    <Grid container direction="row" style={{ padding: "8px 15px 0 15px" }}>
                <Grid item style={{ display: "flex", justifyContent: "center", alignItems: "center",marginLeft:'-2%' }} xs={1}>
                    {jobRunState && <img src="/assets/images/running_job_loader_large.gif" style={{width:50, height: 50}}></img>}
                   {!jobRunState && <JobCategoryIcon variant="large" status={jobReport.status || jobReport.score} style={{ height: "70%" }} />}
                </Grid>
                <RRSDialog jobName={jobReport.jobName} jobResultId={jobReport.id} rRSTotals={jobReport.rrsTotals} open={openRRSDialog} onClose={() => setopenRRSDialog(false)} />
                <RTADialog jobName={jobReport.jobName} jobResultId={jobReport.id} rTATimeStamps={jobReport.rtaTimeStamps} open={openRTADialog} onClose={() => setopenRTADialog(false)} />
                {/* {openLogsDialog && <LogViewer jobName={jobReport.jobName} jobResultId={jobReport.id} open={openLogsDialog} onClose={() => setopenLogsDialog(false)} />} */}
                <Grid item xs={8}>
                    <Grid container direction="column">
            <Grid item xs={12}>
                <div data-test="component-testTitle" className={classes.sideBySide} style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Typography variant="h6" style={{color: "#1f396d",overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',width:'100%'}} noWrap align="left">
                {(jobReport.isRenamed || jobReport.isDeleted || jobReport.isDeletedPermanently) && <Tooltip title={"Current Name: " + jobReport.jobNameFromJobs}>
                    <span>
                    {jobReport.isDeleted || jobReport.isDeletedPermanently ? t("deleted") : (jobReport.isRenamed ? t("renamed") : "")}{" - "}
                    </span>
                  </Tooltip>}
                  <Tooltip title={jobReport.jobName}>
                    <span>{t("summaryReport")} - {jobReport.jobName}</span>
                  </Tooltip>
               </Typography>
                </div>
            </Grid>
                        <Grid item data-test="component-dataMover" ><Typography variant="body1" align="left">{reportStore.selectedJobResult.dataMover}</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <div style={{textAlign: "right"}}>
                        <Button data-test="button-fullReport" onClick={showFullReport} variant="contained" classes={{label: classes.label}} className={classes.fullReportButton}><DescriptionIcon />{t("button.fullReport")}</Button>
                        {userStore.user.username != "readonly" ?
                            <Button data-test="button-menu" variant="text"  className={classes.menuButton} onClick={(event) => setMenuAnchor(event.target)}><MoreVert /></Button>
                            : null }
                    </div>
                </Grid>
                <Grid container direction="row" style={{ padding: "8px 0", boxSizing: 'border-box' }}>
                    <Grid item xs onClick={()=> Noloadpopup() ? setopenRRSDialog(true):null}>
                        <Paper style={{ margin: "5px", padding: "15px", height: '90%', boxSizing: 'border-box' }} className={!Noloadpopup() ? null : classes.hover} variant="outlined">
                            <Grid container>
                                <Grid item xs={8}>
                                    <Grid container direction="column" alignItems="flex-start">
                                        <Grid item  data-test="rrs-title">
                                            <Typography variant="body1" style={{fontWeight: 500}}> {t("recoveryReadinessScore")}</Typography>
                                        </Grid>
                                        <Grid item data-test={`rrs-score-${reportStore.selectedJobResult.score}`}>
                                            <Typography variant="h3" style={{width:"100%"}}> {reportStore.selectedJobResult.score}%</Typography>
                                        </Grid>
                                        <Grid item data-test="rrs-change">
                                            <TrendIndicator showText={true} value={jobReport.scoreChange} trendUpLabel={t("morethanLastJob")} trendDownLabel={t("lessthanLastJob")}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <RecoveryReadinessChart width={90} height={90} score={jobReport.score} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs onClick={()=> Noloadpopup() ? setopenRTADialog(true):null}>
                        <Paper style={{ margin: "5px", padding: "15px", height: '90%', boxSizing: 'border-box' }} className={!Noloadpopup() ? null : classes.hover} variant="outlined">
                            <Grid container>
                                <Grid item xs={8}>
                                    <Grid container direction="column" alignItems="flex-start">
                                        <Grid item data-test="rt-title">
                                            <Typography variant="body1" style={{fontWeight: 500}}>{t("recoveryTime")}</Typography>
                                        </Grid>
                                        <Grid item data-test={`rt-time-${ConvertStringToTimeSpan(jobReport.rta).totalSeconds}`}>
                                            <span>
                                                <Typography variant="h3"> {convertSecondsToTime(ConvertStringToTimeSpan(jobReport.rta).totalSeconds)}</Typography>
                                            </span>
                                        </Grid>
                                        <Grid item data-test="rt-expected">
                                            <Typography variant="body2"> {t("RTOwasSetTo")} {convertSecondsToTime(ConvertStringToTimeSpan(jobReport.rto).totalSeconds)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <GaugeChart width={110} height={90} value={ConvertStringToTimeSpan(jobReport.rta).totalSeconds} max={ConvertStringToTimeSpan(jobReport.rto).totalSeconds} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                {/*  chart tiles ends */}
            </Grid>
                <Grid container direction="column" style={{ padding: "0px 15px 0px 15px" }}>
                    <Grid item container justify="space-between" >
                        <Grid item> <Typography variant="h6">{getTitle(jobReport.jobRunType)}</Typography></Grid>
                        <Grid item><Typography variant="body1" align="left" style={{marginRight: 15}}>{t("runFor")} {convertTimespanToString(ConvertStringToTimeSpan(jobReport.totalRunTime))}, {t("finishedAt")}:{moment(reportStore.selectedJobResult.runDate).format("M.D.YYYY, h:mm A")}</Typography></Grid>       
                    </Grid>
                    <Grid item style={{ marginTop: "5px" }}>
                        <TestWorkFlowComponent variant="small" workflowScripts={jobReport && jobReport.prePostScripts} workflowSteps={jobReport.knownIssues || []} jobRunState={jobRunState} runDate={jobReport.runDate}></TestWorkFlowComponent>

                    </Grid>

                </Grid>
                {/* workflow ends */}
                
        {jobReport.score === 0 &&  <Grid container direction="column" style={{ padding: "8px 15px 0 15px" }}>
          <Grid item container justify="space-between" >
            <Grid item> <Typography variant="h6" color="error">{t("failedReport")}</Typography></Grid>
          </Grid>
          <Grid item style={{ marginTop: "15px" }}>
            <div style={{height: "40px",
                      borderRadius: "4px",
                      color: '#077187',
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: 10,
                      border: "solid 1px #83b8c3",
                      backgroundColor: "#f2f8f9"}}>
               {(jobReport.knownIssues && jobReport.knownIssues.length > 0) || (jobReport.EndMessages && jobReport.EndMessages != '') ? showError(jobReport.knownIssues, jobReport.EndMessages) : t('failoverIssue')}
            </div>
          </Grid>

        </Grid>}
                <Grid item container direction="row" style={{padding: "10px 15px 0px 5px" }}>
                    <Grid item xs={12}>
                      <Typography variant="h6" align="left" style={{paddingLeft:'10px'}}> Tests Overview</Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Paper style={{padding: "8px 15px",border:'1px solid white' }} variant="outlined">
                        <Grid container spacing={1}>
                        
                        <Grid item container xs={3}>
                        <Grid container justifyContent="center" alignItems="center" style={{border: '1px solid #eaeef4',borderRadius: '4px', padding: "10px 0"}}>
                            <Grid item container style={{padding: '0 8px', width:'100%', height: '100%' }}>
                                <Grid item container direction="column" justify="space-between" >
                                    <IssueAndAutoFixComponent name={t("tests.issues")} count={issueCount} icon={<IssuesIcon className={classes.testIcon}/>} type="issues" reportType="mini"/>
                                    <IssueAndAutoFixComponent name={t("tests.autoFix")} count={jobTest.autoFix} icon={<AutofixIcon className={classes.testIcon}/>} type="autoFix" reportType="mini"/>
                                   <Grid item>
                                   <DRResourceSectionComponent 
                                        cpu={jobReport?.cpuActual} cpuMax={jobReport?.cpuCapacity} 
                                        ram={jobReport?.ramActual} rammax={jobReport?.ramCapacity}
                                        iops={jobReport?.iopsActual} iopsMax={parseInt(maxIops?.settingValue)}
                                        jobStatus={jobReport?.status}
                                        />
                                    </Grid>
								</Grid>
                            </Grid>
                        </Grid>
                        </Grid>

                        <Grid item xs={9}>
                        <ReportTestsComponent type={"mini"} jobTest={jobTest}/>
                        </Grid>
                    </Grid>
                    </Paper>
                    </Grid>
                </Grid>

                {/* Device List */}
                {jobReport.score > 0 &&  <DeviceListComponent type="mini" jobResultId={jobReport && jobReport.id} jobId={jobReport?.jobId} defaultAnimationPlayState={animationPlaySettings && animationPlaySettings.settingValue === 'true'}/>}
            <JobActionMenu jobId={jobReport.jobId} firstJobEver={jobReport && jobReport.jobId == 1} createDate={jobReport.createDate} edrServer={jobReport.edrServer} jobResultId={jobReport.id} jobName={jobReport.jobName} jobNameFromJobs={jobReport.jobNameFromJobs} isDeleted={job !== null && job !== undefined ? job.isDeleted : true} isDeletedPermanently={job !== null && job !== undefined ? job.isDeletedPermanently : true} attachmentSize={job !== null && job !== undefined ? job.attachmentSize : 0} onClose={handleMenuClose} anchorEl={menuAnchor} />
              
            </React.Fragment> }
        </Grid>
}

export default observer(MiniReportComponent);