

import React from 'react'

import { makeStyles, IconButton, Tooltip} from '@material-ui/core';
// import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon from '@material-ui/icons/Settings';
import SpannerIcon from '@material-ui/icons/Build';
import WebIcon from '@material-ui/icons/Web';
//import NetworkIcon from '@material-ui/icons/Language';
// import DatabaseIcon from '@material-ui/icons/Storage';
import AssignmentIcon from '@material-ui/icons/Assignment';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import clsx from 'clsx';

import { JobTest, TestStatus, TestType } from '../../app/models/jobTest.model';
import { useTranslation } from 'react-i18next';
import {ServiceIcon, DatabaseIcon, NetworkIcon, WebportalIcon, PowerIcon, CustomIcon, ThirdDeviceIcon, PreScriptIcon} from '../../features/Icons'
function getTestName(type: TestType): string {
    switch (type) {
        case TestType.custom: return "Script"
        case TestType.web: return "Web"
        case TestType.power: return "Health"
        case TestType.service: return "Application"
        case TestType.network: return "Network"
        case TestType.database: return "Database"
        case TestType.autofix: return "AutoFix"
        case TestType.thirdDeviceNetwork: return "Segmentation"
        case TestType.PreScriptRes: return "Powerup"
    }
}

const useStyles = makeStyles({

    icon: {
        fontSize: "1em",
        padding: 0
    },
    passed: {
        color: "#8bc53f",
        background: "rgba(139, 197, 63, 0.2)",
        borderRadius: "0px"
    },
    failed: {
        color: "#077187",
        background: "rgba(7, 113, 135,0.2)",
        borderRadius: "0px"
    },
    tooltip: {
        maxWidth: 'none',
        whiteSpace:'pre-wrap'
    }
});

const TestIconFromType:React.FC<{type: TestType, className?: string}> = ({type, className}) =>{
    switch (type) {
        case TestType.custom: return <CustomIcon className={className} />
        case TestType.web: return <WebportalIcon className={className} />
        case TestType.power: return <PowerIcon className={className} />
        case TestType.service: return <ServiceIcon className={className} />
        case TestType.network: return <NetworkIcon className={className} />
        case TestType.database: return <DatabaseIcon className={className} />
        case TestType.thirdDeviceNetwork: return <ThirdDeviceIcon className={className} />
        case TestType.PreScriptRes: return <PreScriptIcon className={className} />
        default: return <> </>
    }
}



const TestStatusIcon: React.FunctionComponent<{ test: JobTest,testInfo: string, className?: string }> = (props) => {
    const { test } = props;
    const classes = useStyles();
    const [t] = useTranslation('report');
    const nullEelement = <i style={{ display: "none" }}> </i>;
    if (!test) {
        return nullEelement;
    }


    const testIcon =<TestIconFromType data-test="component-testStatus-passed" type={test.testType} className={classes.icon}/> ;
    if( test.testStatus === TestStatus.NA)
        return null;
    if (test.testStatus === TestStatus.passed) {
        return  <Tooltip classes={{tooltip:classes.tooltip}} title={<span >{t('testPassTooltip', {testName:getTestName(test.testType), testInfo: props.testInfo || getTestName(test.testType)})}</span>}><IconButton className={clsx([classes.icon, classes.passed, props.className])}>
            {testIcon}
        </IconButton>
        </Tooltip>
    }
    if (test.testStatus === TestStatus.failed || test.testStatus === TestStatus.NA) {
        return  <Tooltip classes={{tooltip:classes.tooltip}} title={<span>{t('testFailedTooltip', {testName:getTestName(test.testType) ,testInfo: props.testInfo, testResult: test.testResult})}</span>}><IconButton data-test="component-testStatus-failed" className={clsx([classes.icon, classes.failed, props.className])}>
            {testIcon}
        </IconButton>
        </Tooltip>
    }
    return <> </>;
}

export default TestStatusIcon;

