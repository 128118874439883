export enum WorkFlowStatus {
    oldValue= 0,
    success = 1,
    failed = 2,
    disabled = 3
}

export enum WorkflowStepType {
    preTask,
    failover,
    serverTest,
    snapshot,
    cleanUp
}

export interface WorkflowStep {
    workflowStep: WorkflowStepType;
    workflowStatus: WorkFlowStatus;
    timeStamp: string;
    workflowIssue: string;
}