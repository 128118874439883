
import {
    Box, Button, Dialog, DialogActions, DialogContent,
    DialogTitle, FormControlLabel, Grid, makeStyles, Radio, RadioGroup,
    TextField, Select, MenuItem, Checkbox, withStyles, InputLabel, FormControl, Divider
} from '@material-ui/core';
import React, { createRef, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceState, DeviceStatus, DeviceType, DEVICE_TYPE_NAME_SEPERATE_DC } from '../../../app/models/deviceType.enum';
import { FieldArray, FormikErrors, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { EDRTextField } from "../../../app/common/EDRTextField";
import { SaveButton } from "../../../app/common/saveButton";
import { EDRDialogTitle } from "../../../app/common/EDRDialogTitle";
import { JobDevice } from '../../../app/models/jobDevice/jobDevice.model';
import { SelectInputLabel } from '../../../app/common/selectInputLabel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { CredentialUserType, SRMRPState } from '../../../app/models/createjob/createJob.model';
import { RootStore, RootStoreContext } from '../../../app/stores/root.store';
import { CustomDeviceLocation, CustomDeviceState, DevicePlatform, JobType } from '../../../app/models/jobProperties.model';
import { OS_TYPE_NAME } from '../../../app/models/BaseSettings.enum';
import jobAgent from '../../../app/api/jobAgent';
import { toast } from 'react-toastify';
import { debounce } from '../../../app/common/util/util';
import { runInAction } from 'mobx';

const validateDuplicateDevicename: (value, resolve) => Promise<boolean> = debounce( async function (value, resolve) {
 
    try{
        const res = await jobAgent.CreateJobActions.CheckCustomDeviceExist(value);
        resolve(!Boolean(res));
    } catch{
      resolve(false);
    }
      
}, 400) as (value) => Promise<boolean>;

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

interface DeviceFormData {
    deviceName: string;
    newtworkLocation: string
    deviceType: string;
    fqdn: string;
    inheritDefaultTests: boolean;
}
const DEVICE_TYPE_OPTIONS: Array<DeviceType> = [
    DeviceType.VirtualMachine,
    DeviceType.PhysicalServer,
    DeviceType.BranchOffice,
    DeviceType.DCPhysical,
    DeviceType.DCVirtual,
    DeviceType.NetworkDevice,
    DeviceType.Firewall,
    //DeviceType.InternetConnection,
    DeviceType.Database,
    DeviceType.Other,
    DeviceType.CloudService,
    DeviceType.CloudDatabase]

    const OSOptions: Array<DevicePlatform> = [
    DevicePlatform.Windows,
    DevicePlatform.UNIX]

const loadDeviceIcon =(device:DeviceType) => {
    switch(device)
    {
        case DeviceType.VirtualMachine:
            return '/assets/images/device_type/vm_device.svg';
        case DeviceType.PhysicalServer :
            return '/assets/images/device_type/physical_device.svg';
        case DeviceType.BranchOffice :
            return '/assets/images/device_type/branch_device.svg';
        case DeviceType.DCPhysical :
            return '/assets/images/device_type/dc_device.svg';
        case DeviceType.DCVirtual :
            return '/assets/images/device_type/dc_device.svg';
        case DeviceType.NetworkDevice:
            return '/assets/images/device_type/network_device.svg';
        case DeviceType.Firewall:
            return '/assets/images/device_type/firewall_device.svg';
        case DeviceType.InternetConnection:
            return '/assets/images/device_type/network_device.svg';
        case DeviceType.Other:
            return '/assets/images/device_type/other_device.svg';
        case DeviceType.Database:
            return '/assets/images/device_type/database_device.svg';
        case DeviceType.CloudService:
            return '/assets/images/device_type/cloud_service_device.svg';
        case DeviceType.CloudDatabase:
            return '/assets/images/device_type/cloud_database_device.svg';
    }
}

const useStyles = makeStyles({
    root: {
        minWidth: '25vw',
    },
    removeIcon: {
        color: '#077187'
    },
    addButton: {
        paddingLeft: 2
    },
    removeButton: {
        paddingRight: 2,
        color: '#077187'
    },
    centerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between'
    },
    formControl: {
        width: '100%',
        '& label': {
          background: "white",
          width: "auto",
          padding: "2px 4px 0 4px",
          fontSize: '15px'
        }
      },
    toggleItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiListItemText-root' : {
            marginLeft: 10
        },
        '& .MuiSvgIcon-root': {
            color: '#8092a9'
        }
    }
})
export interface AddNewDeviceProps {
    open: boolean;
    onClose: () => void;
    onSubmit?: (device: Array<JobDevice>) => void;
    onUpdate? :(device:JobDevice) =>void;
    editDevice: JobDevice;
    isEditMode:boolean;
}

const AddNewDeviceDialog: React.FC<AddNewDeviceProps> = (props) => {
    const { open,editDevice, onClose } = props;
    const classes = useStyles();
    const { t } = useTranslation('createJobBase');
    const [cancel,setCancel]=useState(false);
    let deviceNameRef = useRef(null);
    const {createJobStore,jobSettingsStore} = useContext(RootStoreContext);
    
    const validation = yup.object({
        devices: yup.array().of(yup.object(
        {
            deviceType: yup.number().required('device type is required'),
    
            os: yup.number(),
            
            fqdn: yup.string()
            .nullable()
            .test('empty','fqdn must be not empty',fqdn => fqdn !=='undefined')
            .required('FQDN is required')
            .test('fqdn','Invalid FQDN/IP',
            function(value){
                  let IpRegex=/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
                  let FqdnRegex=/(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63})$/;
                  let URLNoHttpRegex= /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
                  let URLHttpRegex= /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
                  let isValidIP = IpRegex.test(value);
                  let isValidFQDN = FqdnRegex.test(value);
                  let isValidURLNo= URLNoHttpRegex.test(value);
                  let isValidURL= URLHttpRegex.test(value);
                    if(isValidIP || isValidFQDN || isValidURL || isValidURLNo) 
                      return true;
                      else
                    return false;
            }),
    
            
            deviceName: yup.string()
            .test('empty','device name must be not empty',deviceName => deviceName !=='undefined')
            .required('Device Name is required')
            .test('duplicate', 'device with that name already exist',function(value) {
                if(value == deviceNameRef.current)  
                    return true;
                else return jobAgent.CreateJobActions.CheckCustomDeviceExist(value).then(res => {
                    if(res === true)
                        return false;
                    return true;
                });
              })
              .test('invalid','Invalid device Name',
              function(value){
                let Regex= /[a-zA-Z0-9_\-]+$/;
                let isValid = Regex.test(value);
                  if(isValid) 
                    return true;
                else
                  return false;
          }),
    
            newtworkLocation: yup.string().nullable(),
            inheritDefaultTests: yup.bool()
            })
        )
    })

    
    const handleClose = () => {
        if( form.values.devices[0].id!=null)
        {
            let formData = form.values.devices.find(item=>item.id === editDevice.id);
            let deviceData = editDevice;
            if(deviceData!=undefined){
                if(formData.deviceName != deviceData.deviceName || formData.fqdn != deviceData.deviceIP || formData.newtworkLocation != deviceData.newtworkLocation || formData.deviceType != deviceData.deviceType || formData.inheritDefaultTests != deviceData.inheritDefaultTests)
                setCancel(!cancel);
            else
                onClose();
            }
            else
                onClose();
        }
        else
        {
            if(form.values.devices[0].deviceName!="" || form.values.devices[0].fqdn !="" || form.values.devices[0].newtworkLocation !='inBubble' || form.values.devices[0].deviceType !=DeviceType.VirtualMachine ||form.values.devices[0].inheritDefaultTests != false || form.values.devices[0].os!= DevicePlatform.Windows)
                setCancel(!cancel);
            else
                onClose();
        }
    }

    const handleTest =()=>{
        
    }
    const handleSave =()=>{
        runInAction(() => {
        let deviceToAdd = form.values.devices[0];
        if(!props.isEditMode)
        {
                jobAgent.CreateJobActions.CreateCustomDevice({
                    devicePlatform: deviceToAdd.os,
                    deviceName: deviceToAdd.deviceName,
                    deviceFQDN: deviceToAdd.fqdn,
                    customDeviceLocation: deviceToAdd.newtworkLocation === "inBubble" ? 0 : 1,
                    deviceType: deviceToAdd.deviceType,
                    selectAllDefultTests: deviceToAdd.inheritDefaultTests
                })
                .then(res => {
                    const [lastAddedDevice, ...rest] = res;
                    props.onSubmit([{
                        id: lastAddedDevice.id,
                        deviceName: lastAddedDevice.deviceName,
                        sourceName: lastAddedDevice.deviceFQDN,
                        deviceIP: lastAddedDevice.deviceFQDN,
                        targetIP: 'N/A',
                        deviceGroup: '7',
                        os:lastAddedDevice.devicePlatform,
                        deviceType: lastAddedDevice.deviceType,
                        planName: 'Custom Devices',
                        customDeviceState:lastAddedDevice.state,
                        newtworkLocation: lastAddedDevice.customDeviceLocation === CustomDeviceLocation.InBubble ? "inBubble" : "outBubble",
                        inheritDefaultTests: lastAddedDevice.selectAllDefultTests,
                        NumberClickDB: 0,
                        NumberClickWeb: 0,
                        NumberClick3rd: 0,
                        NumberClickAuthen: 0,
                        NumberClickScript: 0,
                        deviceCredentials: null,
                        performSecondRestart: false,
                        showDeviceAsDC: false,
                        guid: lastAddedDevice.guid,
                        deviceStatus:DeviceStatus.Ready,
                        deviceState: DeviceState.PoweredOn,
                        fetchedFromInfra:false,
                        planState:lastAddedDevice.planState
                    }])
                    toast.success("Suucessfully added new custom device");
                   
                    onClose();
                    form.resetForm();
                    setCancel(false);
                })
                .catch(err => {
                    toast.error("Failed to add new custom device " + err);
                });
        }
        else
        {
                jobAgent.CreateJobActions.EditCustomDevice({
                    id:deviceToAdd.id,
                    devicePlatform: deviceToAdd.os,
                    deviceName: deviceToAdd.deviceName,
                    deviceFQDN: deviceToAdd.fqdn,
                    customDeviceLocation: deviceToAdd.newtworkLocation === "inBubble" ? 0 : 1,
                    deviceType: deviceToAdd.deviceType,
                    selectAllDefultTests: deviceToAdd.inheritDefaultTests
                })
                .then(res => {
                    toast.success("Suucessfully edited custom device");
                    const [lastUpdatedDevice, ...rest] = res;
                    props.onUpdate({
                        id: lastUpdatedDevice.id,
                        deviceName: lastUpdatedDevice.deviceName,
                        sourceName: lastUpdatedDevice.deviceFQDN,
                        deviceIP: lastUpdatedDevice.deviceFQDN,
                        targetIP: 'N/A',
                        deviceGroup: editDevice.deviceGroup,
                        os:lastUpdatedDevice.devicePlatform,
                        deviceType: lastUpdatedDevice.deviceType,
                        planName: 'Custom Devices',
                        customDeviceState:lastUpdatedDevice.state,
                        newtworkLocation: lastUpdatedDevice.customDeviceLocation === CustomDeviceLocation.InBubble ? "inBubble" : "outBubble",
                        inheritDefaultTests: lastUpdatedDevice.selectAllDefultTests,
                        NumberClickDB: 0,
                        NumberClickWeb: 0,
                        NumberClick3rd: 0,
                        NumberClickAuthen: 0,
                        NumberClickScript: 0,
                        deviceCredentials: null,
                        performSecondRestart: false,
                        showDeviceAsDC: false,
                        guid: lastUpdatedDevice.guid,
                        deviceStatus:DeviceStatus.Ready,
                        deviceState: DeviceState.PoweredOn,
                        fetchedFromInfra:false,
                        planState: lastUpdatedDevice.planState
                    })
                    onClose();
                    form.resetForm();
                    setCancel(false);
                })
                .catch(err => {
                    toast.error(err.data.errors);
                });
        }
        // form.submitForm();
        // onClose();
    })
    }

    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel style={{marginLeft:'10px'}}>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const handleYes=()=>{
        let initialValues = {
            devices: [
                {
                    newtworkLocation: editDevice!=undefined ? editDevice.newtworkLocation: 'inBubble',
                    deviceType: editDevice!=undefined ? editDevice.deviceType :DeviceType.VirtualMachine,
                    fqdn: editDevice!=undefined ? editDevice.deviceIP :'',
                    os: editDevice!=undefined ? editDevice.os:DevicePlatform.Windows,
                    deviceName: editDevice!=undefined ? editDevice.deviceName: '',
                    inheritDefaultTests: editDevice!=undefined ? editDevice.inheritDefaultTests: false,
                    id: editDevice!=undefined ? editDevice.id: null,
                    sourceName: editDevice!=undefined ? editDevice.sourceName: null,
                    planState:editDevice!=undefined ? editDevice.planState: SRMRPState.Protecting
                }
            ]
        };
        form.setValues(initialValues);
        //form.resetForm();
        setCancel(false);
        onClose();
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    const form = useFormik({
        initialValues: {
            devices: [
                {
                    newtworkLocation: 'inBubble',
                    deviceType:DeviceType.VirtualMachine,
                    fqdn: '',
                    os: DevicePlatform.Windows,
                    deviceName: '',
                    sourceName: '',
                    inheritDefaultTests: false,
                    id:null,
                    planState: SRMRPState.Protecting
                }
            ]
        },
        validationSchema:validation,
        validateOnMount: true,
        onSubmit: (value) => {
            if(editDevice === undefined || editDevice == null)
            {
                let id = new Date().getTime();
                const jobDevices: Array<JobDevice> = value.devices.map((value, index) => ({
                    deviceName: value.deviceName,
                    sourceName: value.sourceName,
                    deviceType: value.deviceType,
                    deviceGroup: 'Custom',
                    deviceIP: value.fqdn,
                    os: value.os,
                    targetIP: '',
                    id: id + index,
                    planName: 'Custom Devices',
                    performSecondRestart: false,
                    showDeviceAsDC: false,
                    inheritDefaultTests:value.inheritDefaultTests,
                    newtworkLocation:value.newtworkLocation,
                    customCommandData:null,
                    customCommandName:'',
                    scriptData:null,
                    scriptName:'',
                    guid: uuidv4(),
                    NumberClickDB:0,
                    NumberClickWeb:0,
                    NumberClick3rd:0,
                    NumberClickAuthen:0,
                    NumberClickScript:0,
                    deviceCredentials: {
                        id:0,
                        userName:'',
                        password:'',
                        userType: CredentialUserType.TestUser
                    },
                    deviceStatus:DeviceStatus.Unknown,
                    customDeviceState: CustomDeviceState.Active,
                    fetchedFromInfra: false,
                    planState: value.planState
                }))
                props.onSubmit && props.onSubmit(jobDevices);
            }
            else
            {
                let deviceValues = form.values.devices[0];
                let updateDevice = {
                    deviceName: deviceValues.deviceName,
                    sourceName: deviceValues.sourceName,
                    deviceType:deviceValues.deviceType,
                    deviceGroup: 'Custom',
                    os:editDevice.os,
                    deviceIP: deviceValues.fqdn,
                    performSecondRestart: false,
                    showDeviceAsDC: false,
                    targetIP: '',
                    id: editDevice.id,
                    planName: 'Custom Devices',
                    planState: editDevice.planState,
                    inheritDefaultTests:editDevice.inheritDefaultTests,
                    newtworkLocation:editDevice.newtworkLocation,
                    customCommandData:editDevice.customCommandData,
                    customCommandName:editDevice.customCommandName,
                    scriptData:editDevice.scriptData,
                    scriptName:editDevice.scriptName,
                    guid:editDevice.guid,
                    NumberClickDB:editDevice.NumberClickDB,
                    NumberClickWeb:editDevice.NumberClickWeb,
                    NumberClick3rd:editDevice.NumberClick3rd,
                    NumberClickAuthen:editDevice.NumberClickAuthen,
                    NumberClickScript:editDevice.NumberClickScript,
                    deviceCredentials: {
                        id:editDevice.deviceCredentials != null ? editDevice.deviceCredentials.id:null,
                        userName:editDevice.deviceCredentials != null ?editDevice.deviceCredentials.userName:null,
                        password:editDevice.deviceCredentials != null ? editDevice.deviceCredentials.password: null,
                        userType: editDevice.deviceCredentials != null ? editDevice.deviceCredentials.userType: null
                    },
                    customDeviceState: editDevice.customDeviceState,
                    deviceStatus:editDevice.deviceStatus,
                    fetchedFromInfra: editDevice.fetchedFromInfra
                }
                props.onUpdate && props.onUpdate(updateDevice);
            }
            form.resetForm();
            setCancel(false);
            onClose();
        }
    })


    useEffect(() => {
        runInAction(()=>{
            let initialValues = {
                devices: [
                    {
                        newtworkLocation: editDevice!=undefined ?  editDevice.newtworkLocation: 'inBubble',
                        deviceType: editDevice!=undefined ? editDevice.deviceType :DeviceType.VirtualMachine,
                        fqdn: editDevice!=undefined ? editDevice.deviceIP :'',
                        os: editDevice!=undefined ? editDevice.os :DevicePlatform.Windows,
                        deviceName: editDevice!=undefined ? editDevice.deviceName: '',
                        inheritDefaultTests: editDevice!=undefined ? editDevice.inheritDefaultTests: false,
                        id: editDevice!=undefined ? editDevice.id: null,
                        sourceName: editDevice!=undefined ? editDevice.sourceName: null,
                        planState:editDevice!=undefined ? editDevice.planState: SRMRPState.Protecting
                    }
                ]
            }
            form.setValues(initialValues);
            if(editDevice!=undefined)
                deviceNameRef.current = editDevice.deviceName;
        })
       },[props.editDevice]);

    const loadDeviceType=(deviceType:DeviceType)=>{
        if(deviceType != DeviceType.InternetConnection) 
        return(
        <MenuItem value={deviceType}>
        <img src={loadDeviceIcon(deviceType)} style={{height:'19px',width:'19px',marginRight:'10px'}}/>
        {" "+ DEVICE_TYPE_NAME_SEPERATE_DC[deviceType]} 
        </MenuItem>);
        else return null;
    }

    const handleDefaultTestsChange =(event:any,index:number)=>{
        form.setFieldValue(`devices.${index}.inheritDefaultTests`,event.target.checked);
    }

    const loadMassAddDevice =(props:any)=> {
        if(editDevice === undefined)
        return (
                <Button color="primary"  style={{textTransform:'none'}} startIcon={<AddCircleIcon />} classes={{ root: classes.addButton }} onClick={() => props.push({
                    newtworkLocation: 'inBubble',
                    deviceType: DeviceType.VirtualMachine,
                    deviceOS: DevicePlatform.Windows,
                    fqdn: '',
                    deviceName: '',
                })}>{t('addDevice')}</Button>)
    }

    const checkBubbble =()=>{
        let isBubbleEmpty;
        switch(createJobStore.baseSettings.jobType)
        {
            /*case JobType.Live:
                {
                    isBubbleEmpty = createJobStore.networkSetting.liveDRNetwork.networkMapping[0].targetNetwork ==="" ?true:false;
                    break;
                }
                */
            case JobType.Test:
                {
                    isBubbleEmpty = jobSettingsStore.anyNetworkTupples ?
                    (jobSettingsStore.selectedNetworkTupple!= undefined ? false:true)
                    :(createJobStore.networkSetting.testDRNetwork.networkMapping[0].targetNetwork ==="" ?true:false);
                    break;
                }
            /*case JobType.LiveTest:
                {
                    let resLive= createJobStore.networkSetting.liveDRNetwork.networkMapping[0].targetNetwork ==="" ?true:false;
                    let resTest = createJobStore.networkSetting.testDRNetwork.networkMapping[0].targetNetwork ==="" ?true:false;
                    isBubbleEmpty= resLive||resTest;
                    break;
                }
            */
        }
        return isBubbleEmpty;
    }
    const handleDeviceOSChange = (event:any, index:number) => {
        form.setFieldValue(`devices.${index}.os`,event.target.value);
    };

    const handleDeviceTypeChange = (event:any, index:number) => {
        form.setFieldValue(`devices.${index}.deviceType`,event.target.value);
    };

    const handleChangeBubble =(event:any, index:number)=>{
        form.setFieldValue(`devices.${index}.newtworkLocation`,event.target.value === "0" ? "inBubble" : "outBubble");
    }

    return <Dialog disableBackdropClick
        open={open}
        classes={{ paper: classes.root }}
        onClose={handleClose}
        scroll="paper"
    >
        <EDRDialogTitle >{editDevice === undefined || editDevice === null ? t('addNewDevice'):(t('editDevice')+editDevice.deviceName)}</EDRDialogTitle>
        <DialogContent dividers={true}>
            <FormikProvider value={form}>
                <FieldArray name="devices" render={(props) => {
                    return <Box p={1}>
                        {
                            form.values.devices.map((device, index) => {
                                const errors = (form.errors.devices && form.errors.devices[index] || {}) as FormikErrors<DeviceFormData>
                                const touchState = form.touched.devices && form.touched.devices[index] || {}
                                return <React.Fragment> 
                                    <Grid container spacing={2}>
                                    {/*<Grid item container direction='row' justify="flex-end">
                                        <Box>
                                            <Button className={classes.removeButton} style={{textTransform:'none'}} disabled={form.values.devices.length <= 1} startIcon={<DeleteIcon color='error' />} onClick={() => props.remove(index)}>{t('Remove')}</Button>
                                        </Box>
                                    </Grid>*/}
                                    <Grid item container xs={12} spacing={1}>
                                    <Grid item xs={6}>
                                        <FormControl className={classes.formControl} variant='outlined'>
                                        <InputLabel>{t('deviceType')}</InputLabel>
                                        <Select
                                        required={true}
                                        labelId={t('deviceType')}
                                        value={device.deviceType}
                                        placeholder= "select"
                                        name={`devices.${index}.deviceType`}
                                        renderValue={name => DEVICE_TYPE_NAME_SEPERATE_DC[name as string]}
                                        onChange={(event)=>handleDeviceTypeChange(event,index)}>
                                        {DEVICE_TYPE_OPTIONS.map((deviceType) => (
                                            loadDeviceType(deviceType)))}
                                        </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <InputLabel>{t('deviceOS')}</InputLabel>
                                        <Select
                                        required={true}
                                        labelId={t('deviceOS')}
                                        name={`devices.${index}.os`}
                                        disabled={form.values.devices[index].deviceType === null}
                                        value={form.values.devices[index].os}
                                        renderValue={name => OS_TYPE_NAME[name as string]}
                                        onChange={(event)=>handleDeviceOSChange(event,index)}>
                                        {OSOptions.map((name) => (
                                        <MenuItem key={name} value={name}>
                                        {OS_TYPE_NAME[name]}
                                        </MenuItem>))}
                                        </Select>
                                    </FormControl>
                                    </Grid>
                                    </Grid>
                                   <Grid item container xs={12} spacing={1} style={{marginTop:'8px'}}>
                                    <Grid item xs={6}>
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <EDRTextField id="deviceName"
                                        name={`devices.${index}.deviceName`}
                                        label={t('deviceName')}
                                        placeholder="Type Here"
                                        disabled={form.values.devices[index].deviceType === null}
                                        value={device.deviceName}
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                        error={Boolean(touchState.deviceName && errors.deviceName)}
                                        helperText={touchState.deviceName && t(errors.deviceName)}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        fullWidth
                                        required
                                        variant="outlined">
                                        </EDRTextField>
                                    </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <EDRTextField id="fqdn"
                                        name={`devices.${index}.fqdn`}
                                        label={t('fqdn')}
                                        placeholder="Type Here"
                                        disabled={form.values.devices[index].deviceType === null}
                                        value={device.fqdn}
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                        error={Boolean(touchState.fqdn && errors.fqdn)}
                                        helperText={touchState.fqdn && t(errors.fqdn)}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        fullWidth
                                        required
                                        variant="outlined">
                                        </EDRTextField>
                                    </Grid>
                                    </Grid>
                                  
                                    <Grid item xs={12} container alignItems="center" justify='space-between'>
                                        <Box>
                                            <Box mr={2}>{t('networkLocation')}</Box>
                                            <Box mr={2}>
                                                <RadioGroup row name={`devices.${index}.newtworkLocation`} value={device.newtworkLocation == "inBubble" ? "0" : "1" } onChange={(event)=>handleChangeBubble(event,index)}>
                                                    <FormControlLabel disabled={checkBubbble()} value="0" control={<Radio color="primary" />} label={t('inBubble')} />
                                                    <FormControlLabel value="1" control={<Radio color="primary" />} label={t('outsideBubble')} />
                                                </RadioGroup>
                                            </Box>
                                        </Box>

                                    </Grid>

                                    <Box ml={1} mb={1} display='block'>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" checked={device.inheritDefaultTests} onChange={(event)=>handleDefaultTestsChange(event,index)} name={`devices.${index}.inheritDefaultTests`} />}
                                            label={t('inheritDefaultTests')}
                                        />
                                    </Box>
                                   
                                </Grid>
                                {/*(index < form.values.devices.length) && (editDevice === undefined) && <Divider style={{width: '100%'}} />*/}
                                </React.Fragment>
                            })
                        }
                       
                        {/*loadMassAddDevice(props)*/}
                    </Box>
                }} />

            </FormikProvider>

        </DialogContent>
        <DialogActions>
        <Grid item xs={6}>
            {loadConfirmation()}
        </Grid>
        <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end'}}>
            <Button onClick={handleClose} color="primary" style={{textTransform:'none'}}>
                {t('cancel')}
            </Button>
            {/*<Button onClick={handleTest} color="primary" style={{textTransform:'none'}}> 
                {t('test')}
            </Button>*/}
            <SaveButton color="primary" variant="contained" disabled={!form.isValid} onClick={handleSave} style={{textTransform:'none'}}>
                {t('save')}
            </SaveButton>
        </Grid>
        </DialogActions>
    </Dialog>
}

export default AddNewDeviceDialog;