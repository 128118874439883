import React from 'react'
import { Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ResourceProgressBar from '../../app/common/drResource/dRProgressbar.component';
import { CPUIcon,IopsIcon, RamIcon } from '../../app/common/drResource/resourcesIcon.component';
import { InternetConnectionIcon} from '../Icons';
import clsx from 'clsx';
import { LastJobRunStatus } from '../../app/models/lastjobRun.model';
import CheckCircle from '@material-ui/icons/CheckCircle';
const useStyles = makeStyles({
    icon: {
        color: "#8092a9"
    },
    disabled: {
        color: "lightGray"
    },
    h50: {
        flexGrow: 1
    },
    testIcon: {
        fontSize: 18
    },
    passed: {
        color: '#8bc53f'
    },
    failed: {
        color: '#8bc53f'
    },
    iconDiv:{
        paddingTop: '10px'
    }
});


type DRSectionProps = {
    iops: number,
    iopsMax: number,
    cpu: number,
    cpuMax: number,
    ram: number,
    rammax: number,
    jobStatus: LastJobRunStatus
}

const DRResourceSectionComponent: React.FC<DRSectionProps> = (props) => {
    const { iops, iopsMax, cpu, cpuMax, ram, rammax, jobStatus } = props;
    const [t] = useTranslation("report");
    const classes = useStyles();
    const { testIcon, disabled, passed, failed, h50, iconDiv } = classes;
    return <Grid data-test="component-drResource" container style={{height: '100%'}} direction="column" justifyContent="space-between" >
        <Grid className={h50} item container justifyContent="space-between">
            <Grid item xs={2} container alignItems="center"><InternetConnectionIcon className={clsx([testIcon, !jobStatus ? disabled: passed ])}/></Grid>
            <Grid item xs={8}>
                <Typography variant="body1" style={{fontWeight: 500, fontSize: 12, textAlign:'center'}}> {t("internetconnection")}</Typography>
            </Grid>
            <Grid item xs={2} style={{textAlign: 'right'}}>
                <CheckCircle className={clsx([testIcon, !jobStatus ? disabled: passed ])}/>
            </Grid>
        </Grid>
        <Grid className={h50} item container justifyContent="space-between">
            <Grid item className={iconDiv}>
                <IopsIcon className={clsx([testIcon, { [passed]:iops>0 && iops > iopsMax / 2, [failed]:iops>0 && iops <= iopsMax / 2, [disabled]: iops == 0 }])} />
            </Grid>
            <Grid item style={{paddingTop: 8}}>
                <CPUIcon className={clsx([testIcon, { [passed]:cpu>0 && cpu > cpuMax / 2, [failed]:cpu>0 && cpu <= cpuMax / 2, [disabled]: cpu == 0 }])} />
            </Grid>
            <Grid item style={{paddingTop: 6}}>
                <RamIcon className={clsx([testIcon, { [passed]:ram>0 && ram > rammax / 2, [failed]: ram>0 && ram <= rammax / 2, [disabled]: ram == 0 }])} />
            </Grid>
        </Grid>
    </Grid>
};

export default DRResourceSectionComponent;