import { BlockquoteHTMLAttributes } from "react";
import { DataMover, VeeamSolution } from "./BaseSettings.enum";
import { AssetDTO, DomainControllerDTO, PrePostScriptType } from "./createjob/createJob.model";

export enum InfrastructureType {
    VMware= 1,
    Azure =2,
    VeeamInfra =3,
    HyperV =4,
    VmwareAzure = 5,
    AWS = 6,
    Physical=7,
    GCP= 8       
}
export enum LicenseType {
    SAS = 0,
    Trial = 1,
    Full = 2,
    FirstTrial = 3
}
export enum ScheduleStaus {
    NA = 0,
    Suceess = 1,
    Failed = 2
}

export function getInfra(InfraId:number) {
    switch(InfraId)
    {
        case 1:
            return InfrastructureType.VMware;
        case 2:
            return InfrastructureType.Azure;
        case 3:
            return InfrastructureType.HyperV;
        case 4:
            return InfrastructureType.VmwareAzure;
    }
}

export enum JobType {
    Test = 0,
    Live = 1,
    LiveTest = 2
}

export enum CustomDeviceState
{
    Active = 0,
    Deleted = 1
}
export enum CustomDeviceLocation
{
    InBubble = 0,
    OutBubble = 1
}
export enum DatabaseType {
    SQL = 0,
    Oracle = 1,
}

export enum MachineType {
    Windows = 0,
    Linux = 1,
    WindowsLinux = 2
}

export enum DevicePlatform 
{
    Windows = 0,
    UNIX = 1,
    Unknown = 2,
    Other = 3
}

export interface DataMoverSetting {
    id?: number;
    jobId?: number;
    server: string;
    port: number;
    credentialsId: number;
    username: string;
    password: string;
}

export interface InfrastructureSetting extends DataMoverSetting {

}

export interface WorkflowSetting {
    Type:MachineType;
    PrimaryUser?: string;
    PrimaryPassword?: string;
    SecondaryUser?: string;
    SecondaryPassword?: string;
    SecondaryUserCredsId?: number;
    PrimaryUserCredsId?: number;
}

export interface ScriptSetting {
    preId?: number;
    postId?: number;
    prePath:string;
    prePathData: string;
    postPath:string;
    postPathData: string;
    preTTL:number;
    postTTL:number;
    type: PrePostScriptType;
}
export interface TestGroups {
    id: number,
    groups: Array<any>
}
export interface TestGroup {
    groupOrder: number;
    groupName: string;
}
export interface JobBaseSettings {
    id:number;
    jobName: string;
    jobType: JobType;
    jobId: number;
    licenseId:number;
    originalLicenseId:number;
    licenseName:string;
    rto:number;
    powerOnTime:number;
    parallelTestExecution:number;
    maxTestTimeout:number;
    timeEngineFinish:number;
    failoverTimeout:number;
    testHeartbeat:boolean;
    fetchByDefault:boolean;
    dataMover: DataMover;
    veeamSolution: VeeamSolution,
    //sourceDataMover: DataMoverSetting;
    //targetDataMover: DataMoverSetting;
    vra: string;
    scriptsSettings:
    {
        failoverScriptSetting:ScriptSetting;
        cleanupScriptSetting:ScriptSetting;
        TestScriptSetting:ScriptSetting;
    },
    workflowSetting: {
        preTest: boolean;
        failover: boolean;
        serverTest: boolean;
        snapshot: boolean;
        cleanup: boolean;
    }
    infrastructureId: number;
    dataMoverId: number;
    infrastructureType:  InfrastructureType;
    //sourceInfrastructure: InfrastructureSetting;
    //targetInfarstructure: InfrastructureSetting;
    workflowTest: WorkflowSetting;
    veeamApiPort: number;
    planNameFilter: Array<string>;
    testGroups: TestGroups;
    networkTuppleId:number;
}

export interface NetworkSetting {
    type: NetworkOption;
    networkMapping: Array<NetworkMapping>;
    allowInbubble:boolean;
    allowOutbubble:boolean;
}

export interface CustomEmailSettings {
    address: string;
    port:number;
    emailFrom: string;
    reportURL: string;
    username: string;
    password:string;
}

export interface NetworkMapping{
    sourceNetwork: string;
    targetNetwork:string;
}

export interface DomainControllerSetting {
    name:string;
    source:string;
    target:string;
    datastore:string;
    targetVlan:string;
    domainName:string;
    DCCredentials:Credentials;
    ESXcredentials:Credentials;
    id:number;
}

export interface EDRCSetting {
    name:string;
    IPValue:string;
    displayName:string;
    EDRCcredentials:Credentials;
    id:number;
}

export interface Credentials {
    id: number;
    userName:string;
    password:string;
}

export interface EDRSetting{
    name: string;
    id:number;
}

export enum NetworkOption {
    None = 0,
    Single = 1,
    Multi = 2,
}

export enum NetworkType {
    Managment = 0,
    DRNetwork = 1,
    VLANMain = 2,
    MultiNetwork = 3,
}

export enum DnsResolveType {
    None= 0,
    AutoHostFileUpdate = 1,
    DnsServer = 2
}

export enum BootCaptureType {
    Animation =0,
    Image = 1
}

export interface DisplayScreenSetting{
    displayBaseSettings:string;
    displayNetwork:string;
    addNetwork:string;
    blurNetwork:string;
    displayInfo:string;
    AddModeNetworks:boolean;
    AddModeEDR:boolean;
    AddModeEDRC:boolean;
    AddModeDC:boolean;
    DCTestValid:boolean;
    DCValuesValid:boolean;
    LicenseApplyProcessing:boolean;
    DMApplyProcessing:boolean;
    InfraApplyProcessing:boolean;
    PrimaryUserApplyProcessing:boolean;
    SecondaryUserApplyProcessing:boolean;
    NetworkProcessing:boolean;
    DCApplyProcessing:boolean;
    EDRApplyProcessing:boolean;
    EDRCApplyProcessing:boolean;
    renderValue:string;
    firstTimeTestDC:boolean;
    addedLicense:boolean;
    EDRValidationInProgress:boolean;
    changeEDR:boolean;
}

export interface NextSetting {
    basicAdvancedValid:boolean;
    testsValid:boolean;
    jobNameValid:boolean;
    licenseValid:boolean;
    dmValid:boolean;
    infraValid:boolean;
    primaryValid:boolean;
    secondaryValid:boolean;
    networkMappingValid:boolean;
    networkSettingValid:boolean;
    edrValid:boolean;
    edrcValid:boolean;
    dcAdvancedValid:boolean;
    selectedDevicesValid:boolean;
}
export interface NetworkDrawerSetting {
    edrDrawerOpen:boolean;
    controllerDrawerOpen:boolean;
    cloneDrawerOpen:boolean;
    testDrawerOpen:boolean;
    liveDrawerOpen:boolean;
}
export interface BaseDrawerSetting {
    BasicDrawerOpen:boolean;
    LicenseDrawerOpen:boolean;
    addLicense:boolean;
    AzureDrawerOpen:boolean;
    addInfrastructure:boolean;
    InfraDrawerOpen:boolean;
    PrimaryUserDrawerOpen:boolean;
    SecondaryUserDrawerOpen:boolean;
    addDataMover:boolean;
    addPrimaryUser:boolean;
    addSecondaryUser:boolean;
    DataMoverDrawerOpen:boolean;
    WorkflowDrawerOpen:boolean;
    EDRDrawerOpen:boolean;
}

export interface SectionSetting{
    platformSection: boolean;
    WorkflowSection:boolean;
    serverDRSection:boolean;
}
export interface NetworkSectionSetting{
    DisableSection: boolean;
    DisableButtonEDR:boolean;
    DisableButtonEDRC:boolean;
    DisableButtonDC:boolean;
}
export interface JobNetworkSetting {
    EDRServer: AssetDTO;
    EnsureDRController: AssetDTO;
    domainController: DomainControllerDTO;
    liveDRNetwork: NetworkSetting;
    testDRNetwork: NetworkSetting;
    dnsResolve: DnsResolveType;
    additionalEdrcIp?: Array<string>;
    additionalEdrcDns?: Array<string>;
    additionalDefaultGateway?: Array<string>;
    inPort:number;
    outPort:number;
    NetworkType:NetworkOption;
}

export interface JobReportSetting {
    emailTo: string;
    phoneNumbersForSMS: string;
    sendEmail: boolean;
    sendSMS: boolean;
    bootCaptureType: BootCaptureType;
    bootTime: number;
}
export enum ScheduleRepeatType {
    Day = 1,
    Week= 2,
    Month = 3,
    OneTime = 4
}
export interface JobScheduleSetting {
    nextRun: string;
    scheduleRepeatType: ScheduleRepeatType;
    repeatInterval: number;
    setSchedule: boolean;
    setRepeat: boolean;
}

export interface JobProperties {
      id: number;
      jobId: number;
      jobName: number;
      jobType: JobType;
      licenseKey: string;
      preTestEnabled: boolean;
      failoverEnabled: boolean;
      serverTestEnabled: boolean;
      snapshotEnabled: boolean;
      cleanupEnabled: boolean;
      accessUsername: string;
      accessPassword: string;
      infrastructure: {
          id?: number;
          infrastructureType: InfrastructureType;
          infrastructureName: string;
      }
      edrServerId: number;
      edrServerName: string;
      inPort: number;
      outPort: number;
      edrcServerName: string;
      domainControllerName: string;
      liveDRNetworkName: string;
      liveDRNetworkSegment: string;
      liveNetworkName: string;
      testDRNetworkName: string;
      testDRNetworkSegment: string;


}