import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/root.store';
import { Dialog, useMediaQuery, useTheme, DialogContent, Grid, Typography, Button, makeStyles, InputLabel, FormControl, OutlinedInput} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import jobAgent from '../../app/api/jobAgent';
import { toast } from 'react-toastify';
import {history} from '../../app/common/util/history';
import queryString from 'querystring';
import { JobSummaryGroupByJob } from '../../app/models/jobSummaryGroupByJob.model';
import { JobLicense } from '../../app/models/jobactions/jobLicense.model';
const { JobActions } = jobAgent;
type RenameProps = {
  jobId: number;
  jobNameFromJobs: string;
  onClose: () => void;
  open: boolean
}


const UpdateJobLicenseComponent: React.FC<RenameProps> = (props) => {
    const {jobId,onClose, open, jobNameFromJobs} = props;
    const [license, setLicense] = useState("");
    const [valid, setValid] = useState(false);
    const theme = useTheme();    
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const {t: errorTranslations} = useTranslation('errors');
    const {t} =  useTranslation('jobmenu');
    
    const getFormValues = (field: string) => {
      
    }
    const keyPress = event => {
        if(event.keyCode == 13){
          updateLicHandler();
        }
    }
    const onChange = event => {
      if(event.target.value === "")
      {
        setLicense("");
        setValid(false);
      }
      else
      {
        setLicense(event.target.value);
        setValid(true);
      }
        
    }
    const updateLicHandler= () => {
      let values = {
        id: jobId,
        license: license,
      }
      JobActions.updateLicense(new JobLicense({id: values.id,license:values.license}))
      .then(res => {
        if(res === "success")
        {
            toast.success('Job License Updated Successfully')
        }
        else
        {
          if(res === "AssetNotAvailable")
          {
            toast.error(<React.Fragment>
              <h3>{errorTranslations('Error')}</h3>
              {<div style={{marginLeft: 10}}><i>{errorTranslations('RunnerServiceNotAvailableJobNotChagnedUpdateLicense')}</i></div>}
            </React.Fragment>, {autoClose: 30000 })                 
          }
          else
          {
            toast.error('Failed To Update Job License')
          }
          
        }
      })
      .catch(err => {
        toast.error(err)
      })
      .finally(
        () => onClose()

      );
    }
    const close = () =>
    {
      setValid(false);
      onClose();
    }  
    const useStyles = makeStyles({
      label: {
        textTransform: 'capitalize',
      },
    });

    const classes = useStyles();

    useEffect(() => {
      //callMatomo("Profile_Form","/user");
    }, []);
    const selectJob = (jobGroup: JobSummaryGroupByJob) => {
      const query = { jobId: jobGroup.jobId, jobResultId: jobGroup.lastJobResultId }
      history.push({ search: queryString.stringify(query) });
    }
      const dialogContents = (
            <Dialog
              style={{ zIndex: 1302 }}
              open={open}
              fullScreen={matchesSM}
            >
              <DialogContent style={{ overflow: "hidden"}}>
                <Grid container direction="column" spacing={3} style={{ overflow: "hidden"}}>
                  <Grid item>
                    <Typography align="left" classes={{ root: "confirm_delete_form_title"}}>
                    {t("licenseChange")}
                    </Typography>
                    <Typography align="left" classes={{ root: "confirm_delete_form_title"}}>
                    {`Job Name: ${jobNameFromJobs}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-displayname">{t("newLicense")}</InputLabel>
                              <OutlinedInput
                                          id="license"
                                          defaultValue={getFormValues('license')}
                                          classes={{ root: "profile_text_box" }}
                                          label={t("newLicense")}
                                          onKeyDown={keyPress}
                                          onChange={onChange}
                              />
                    </FormControl>
                  </Grid>
                  <div style={{width:"372px", height: "40px",marginBottom: "2em", marginTop: "1em", display:"flex",justifyContent:"flex-end"}}>
                    <Grid item>
                      <Button
                        classes={{ root: "Cancel", label: classes.label}}
                        style={{ marginRight: "2em", marginTop: "0.5em" }}
                        onClick={() => close()}
                      >
                        { t("renameCancel")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        disabled={!valid}
                        variant="contained"
                        classes={{ root: "license_set_button" , label: classes.label}}
                        onClick={() => {
                          updateLicHandler()
                        }}
                      >
                        {t("licenseChange")}
                      </Button>
                    </Grid>
                  </div>
                </Grid>
        </DialogContent>
    </Dialog>
      );
      return (
        <div data-test='component-set-name'>
          {dialogContents}        
        </div>
    );
}

export default observer(UpdateJobLicenseComponent);