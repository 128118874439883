import { Button,  CircularProgress,  Grid, makeStyles,IconButton, Step, StepLabel, Stepper, Tooltip, Typography } from '@material-ui/core'
import React, {useContext, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import { Prompt, Redirect, Route, RouteComponentProps, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import AppBar, { AppBarTitle } from '../navigation/appbar/appBar.component'
import BaseSettingComponent from './baseSetting.component';
import DeviceSelectionComponent from './deviceSection/deviceSelection.component';
import NetworkInfoComponent from './networkInfo.component';
import JobPreviewComponent from './jobPreview.component';
import { RootStoreContext } from '../../app/stores/root.store';
import { DataMover, DATA_MOVER_CONFIGURATION_NAME } from '../../app/models/BaseSettings.enum';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import jobAgent from '../../app/api/jobAgent';
import { DataMoverSetting, NetworkOption, NetworkType, WorkflowSetting } from '../../app/models/jobProperties.model';
import { InfrastructureType } from '../../app/models/jobProperties.model';
import { Location} from 'history';
import ConfirmationDialog from '../../app/common/confirmationDialog.component';
import JobSettingsStore from '../../app/stores/jobSettings.store';
import { toast } from 'react-toastify';
import { AssetDTO, AssetType, CreateJobProperties, CredentialUserType, DataMoverDTO, NetworkTuppleDTO } from '../../app/models/createjob/createJob.model';
import DraftsAgent from '../../app/api/draftsAgent';
import { DraftState } from '../../app/models/draft.model';
import { runInAction } from 'mobx';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { UpdateType } from '../../app/stores/activityStore';
import TupleComponent from './NetworkInfo/Tuple.component';
import { loadInfrastructure } from './CreateJobConfiguration.component';

const PaasSteps = ['Basic Settings', 'Resource Groups', 'Preview & Report'];
const RegularSteps = ['Basic Settings', 'Network', 'Devices & Tests', 'Preview & Report'];
const PaasBaselineMap= {0:'basic',1:'resource groups',2:'overview'};
const RegularBaselineMap= {0:'basic',1:'network',2:'devices',3:'overview'};
function getNextJobIdAvailable(prefix:string) {
    return jobAgent.JobActions.NextJobIdAvilable(prefix);
};

function getSteps(dataMover: DataMover) {
    switch(dataMover) {
        case DataMover.PaaS: return ['Basic Settings', 'Resource Groups', 'Preview & Report'];
        case DataMover.VeeamSQL: return ['Basic Settings', 'Devices & Tests', 'Preview & Report'];
        default: return ['Basic Settings', 'Network', 'Devices & Tests', 'Preview & Report'];
    }
}

function getStepsUrlPath(dataMover: DataMover) {
    switch(dataMover) {
        case DataMover.PaaS: return {0:'basic',1:'resource groups',2:'overview'};
        case DataMover.VeeamSQL: return {0:'basic',1:'devices',2:'overview'};
        default: return {0:'basic',1:'network',2:'devices',3:'overview'};
    }
}
const useStyles = makeStyles(theme => ({
    button: {
        borderRadius: 15,
        color: 'white'
    },
    backButton: {
        background: '#1f396d'
    },
    runButton: {
        borderRadius: 15,
        color: 'white',
        backgroundColor:'#4caf50',
        marginLeft:60
    },
    stepperContainer: {
        boxShadow: "0 0 4px 0 rgba(31, 57, 109, 0.15)",
        backgroundColor: "#fafafc",
        height: 90,
        overflowY: 'hidden'
    },
    stepperRoot: {
        width: "70%",
        backgroundColor: "#fafafc",
        marginLeft:'290px'
    },
    alternativeLabel: {
        '& .MuiStepLabel-active': {
            color: theme.palette.primary.main
        }
    },
    completedIcon: {
        '& svg': {
            color: theme.palette.success.main + ' !important'
        },
        '& .MuiStepLabel-completed': {
            color: '#8092a9'
        }
    }
}));

const CreateJobComponent: React.FC<RouteComponentProps<any>> = ({history}) => {
    const rootStore = useContext(RootStoreContext);
    const {dataMover,jobName}= rootStore.createJobStore.baseSettings;
    const {createJobStore, userStore, jobRunStore, jobSettingsStore,displayStore,activityStore} = rootStore;
    const [activeStep, setActiveStep] = useState(0);
    const [nextDisabled, setNextDisabled]= useState(true);
    const [validatingStep, setValidatingStep] = useState(false);
    const [baselineMap,setBaselineMap]= useState(getStepsUrlPath(dataMover));
    const [steps,setSteps]=useState(getSteps(dataMover));
    const [isFirstJobEver, setIsFirstJobEver] = useState(false)
    const classes = useStyles();
    const {url, params,} = useRouteMatch();
    const location = useLocation();
    const {search} = useLocation();
    const isEdit= history.location.pathname.includes('editJob');
    const jobId = params['jobId'];
    const [exitDialogConfig, setExitDialogConfig] = useState({confirmed: false, showDialog: false, location: null});
    const isFirstRender = useRef(true);
    const [dcConfirmationDialogConfig, setDCConfirmationConfig] = useState({showDialog :false, dialogText: ''});

    useEffect(() => {
        if(jobSettingsStore.selectedInfra!=null && jobSettingsStore.selectedInfra.id!=null && jobSettingsStore.selectedInfra.id!=undefined)
            jobSettingsStore.listNetworkTupples();
}, [jobSettingsStore.selectedInfra])

    useEffect(() => {
        if (jobSettingsStore.selectedLicense!=null && jobSettingsStore.selectedLicense.id!=null) {
            userStore.getLicenseDevices(jobSettingsStore.selectedLicense.id);
        }
    },[jobSettingsStore.selectedLicense])

    const [t] = useTranslation("CreateJob");

    const isStepFailed = (stepIndex) => {
        return false;
    }

     useEffect(() => {
         if(displayStore._afterCreate == true)
         {
            displayStore.resetStep(-1);
         }
     }, [displayStore._afterCreate])

     useEffect(() => {
        setNextDisabled(true);
     }, [displayStore._RunJobProcessing])

     useEffect(() => {
        if(displayStore._stepToSet == 0)
        {
            setActiveStep(0);
            loadNextButton();
        }  
     }, [displayStore._stepToSet])

    useEffect(() => {
        if(!isEdit && !createJobStore.isDraft)
        {
            jobAgent.CreateJobActions.AnyJobs().then(res => {
                setIsFirstJobEver(!res)
                const firstRun = search.indexOf('fromPlatform')<=0 && (search.indexOf('firstRun')>=0 || !res);
                if(firstRun) {
                    runInAction(() => {
                        jobSettingsStore.getLicenese();
                        jobAgent.JobActions.getCreateJobDefaults().then(res => {
                            jobSettingsStore.getCredentials().then(() => {
                                if(res.addedCredsId && res.addedCredsId != null && res.addedCredsId != 0)
                                {
                                    let user = jobSettingsStore.accessCredentials.find(a => a.id == res.addedCredsId);
                                    jobSettingsStore.updateSelectedPrimaryUser(user.userName);
                                    jobSettingsStore.updateSelectedPrimaryPassword(user.password);
                                    jobSettingsStore.updateSelectedPrimaryUserId(user.id);
                                }
                            })
                            if(res.datamover!=null)
                            {
                                createJobStore.updateBaseSettings({dataMover:res.datamover.type});
                                jobSettingsStore.updateTempDataMover({ dataMoverSource: res && res.datamover && res.datamover.source ? res.datamover.source : ""});
                                jobSettingsStore.updateTempDataMover({ dataMoverTarget: res && res.datamover && res.datamover.target ? res.datamover.target : "" });
                                createJobStore.updateBaseSettings({infrastructureType:loadInfrastructure(res.datamover?.type)});

                            }
                            if(res.domainUser !=null && res.linuxUser!=null)
                            {
                                const workflowSetting: WorkflowSetting = {...createJobStore.baseSettings.workflowTest, 
                                    PrimaryUserCredsId: res.domainUser.PrimaryUserCredsId, PrimaryUser: res.domainUser.username, PrimaryPassword: res.domainUser.password,
                                ...(res.linuxUser ? { SecondaryUserCredsId: res.linuxUser.SecondaryUserCredsId, SecondaryUser: res.linuxUser.username, SecondaryPassword: res.linuxUser.password}: {})
                                }
                                createJobStore.updateBaseSettings({infrastructureType:res.infra!=null?res.infra.type:InfrastructureType.VMware,workflowTest: workflowSetting});
                            }
                            
                            if(res.infra!=null)
                            {
                                jobSettingsStore.updateTempInfra({
                                    infrastructureSource: res.infra.source,
                                    infrastructureTarget: res.infra.target,
                                    infrastructureTypeId: loadInfrastructure(res.datamover?.type),
                                    sourceInfraCredentials: {
                                        userName: res.infra.username,
                                        password: res.infra.password,
                                        id: 0,
                                        userType: 0,
                                    },
                                    targetInfraCredentials: {
                                        userName: res.infra.username,
                                        password: res.infra.password,
                                        id: 0,
                                        userType: 0,
                                    }
                                });
                            }
                            else
                            {
                                jobSettingsStore.updateTempInfra({
                                    infrastructureSource: res.datamover?.type === 17 || res.datamover?.type === 18 ? 'AWS' : '',
                                    infrastructureTarget: '',
                                    infrastructureTypeId: loadInfrastructure(res.datamover?.type),
                                    sourceInfraCredentials: {
                                        userName: '',
                                        password: '',
                                        id: 0,
                                        userType: 0,
                                    },
                                    targetInfraCredentials: {
                                        userName: '',
                                        password: '',
                                        id: 0,
                                        userType: 0,
                                    }
                                })
                            }

                            jobSettingsStore.updateTempDataMover({
                                dataMoverSource: res.datamover?.source,
                                dataMoverTarget: res.datamover?.target,
                                dataMoverTypeId: res.datamover?.type,
                                sourceDataMoverCredentials: {
                                    id:0, userName: res.datamover?.username, password: res.datamover?.password, userType: CredentialUserType.ReplicationUser
                                },
                                targetDataMoverCredentials: {
                                    id:0, userName: res.datamover?.username, password: res.datamover?.password, userType: CredentialUserType.ReplicationUser
                                }
                            })

                            activityStore.sendMessage(UpdateType.FirstRunDefaultLoaded);
                        
                        }).finally(() => {
                            getNextJobIdAvailable(DATA_MOVER_CONFIGURATION_NAME[jobSettingsStore.tempDataMover.dataMoverTypeId]+"_"+InfrastructureType[jobSettingsStore.tempInfra.infrastructureTypeId]+"_Job_").then(res=>{
                                runInAction(() => {
                                    rootStore.createJobStore.updateBaseSettings({jobName:res});
                                    rootStore.createJobStore.updateNetworkSettings({EDRServer:null})
                                    rootStore.createJobStore.updateNetworkSettings({EnsureDRController:null})
                                    rootStore.createJobStore.updateNetworkSettings({domainController:null})
                                    displayStore.updateScreenSettings({EDRApplyProcessing:true,EDRCApplyProcessing:true,DCApplyProcessing:true});
                                    jobSettingsStore.getAssets().finally(()=>{
                                    displayStore.updateScreenSettings({EDRApplyProcessing:false,EDRCApplyProcessing:false,DCApplyProcessing:false});
                                    displayStore.updateNextStepValid({networkSettingValid: true});
                                    })
                                })
                                
                                let jobProperties = new CreateJobProperties();
                                jobProperties.addBaseSettings(createJobStore.baseSettings,createJobStore.networkSetting);
                                jobProperties.jobName = res;
                                jobProperties.dataMoverId = rootStore.createJobStore.baseSettings.dataMoverId;
                                jobProperties.fetchByDefault = rootStore.createJobStore.baseSettings.dataMoverId == 10 ? false:true;
                                jobProperties.infrastructureId = rootStore.createJobStore.baseSettings.infrastructureId;
                                DraftsAgent.Create({id: 0,jobProperties:jobProperties,jobStep:0,draftState: DraftState.InProgress}).then(res => {
                                    rootStore.createJobStore.setDraftId(res.data);
                                }).catch(err => {
                                    toast.error("Failed to create draft for new job")
                                })
                            }).catch(error=>{
                                let jobName = DATA_MOVER_CONFIGURATION_NAME[rootStore.createJobStore.baseSettings.dataMover]+"_"+InfrastructureType[rootStore.createJobStore.baseSettings.infrastructureType]+"_Job_tmp";
                                runInAction(() => {
                                    rootStore.createJobStore.updateBaseSettings({jobName:jobName});
                                    rootStore.createJobStore.updateNetworkSettings({EDRServer:null})
                                    rootStore.createJobStore.updateNetworkSettings({EnsureDRController:null})
                                    rootStore.createJobStore.updateNetworkSettings({domainController:null})
                                    displayStore.updateScreenSettings({EDRApplyProcessing:true,EDRCApplyProcessing:true,DCApplyProcessing:true});
                                    jobSettingsStore.getAssets().finally(()=>{
                                    displayStore.updateScreenSettings({EDRApplyProcessing:false,EDRCApplyProcessing:false,DCApplyProcessing:false});
                                    displayStore.updateNextStepValid({networkSettingValid: true});
                                    })
                                })
                                let jobProperties = new CreateJobProperties();
                                jobProperties.jobName = jobName;
                                jobProperties.dataMoverId = rootStore.createJobStore.baseSettings.dataMoverId;
                                jobProperties.fetchByDefault = rootStore.createJobStore.baseSettings.dataMoverId == 10 ? false:true;
                                jobProperties.infrastructureId = rootStore.createJobStore.baseSettings.infrastructureId;
                                DraftsAgent.Create({id: 0,jobProperties:jobProperties,jobStep:0,draftState: DraftState.InProgress}).then(res => {
                                    rootStore.createJobStore.setDraftId(res.data);
                                })
                                .catch(err => {
                                    toast.error("Failed to create draft for new job")
                                })
                            })
                        })  
                    })
                }
            })
        }
        else
        {
            runInAction(() => {
                displayStore.updateScreenSettings({EDRApplyProcessing:true,EDRCApplyProcessing:true,DCApplyProcessing:true});
                jobSettingsStore.getAssets().finally(()=>{
                displayStore.updateScreenSettings({EDRApplyProcessing:false,EDRCApplyProcessing:false,DCApplyProcessing:false});
                displayStore.updateNextStepValid({networkSettingValid: true});
                
                })
            })
        }
       // setActiveStep(0)
        loadNextButton()
        jobAgent.CreateJobActions.AnyJobs().then(res => {
            console.log(res)
            setIsFirstJobEver(!res)
        })
    }, [])
     useEffect(() => {
        if(jobId)
        {
            displayStore.updateSectionSettings({WorkflowSection: false,platformSection: false,serverDRSection: false});
        }
     }, [jobId])

    useEffect(() => {
        setBaselineMap(getStepsUrlPath(dataMover));
        setSteps(getSteps(dataMover));
    },[dataMover])
    
    useEffect(() => {
        if(isFirstRender.current && !location.pathname.includes('createJob')) {
            isFirstRender.current =false;
            return;
        }
         if(activeStep>=0)
           history.push(`${url}/${baselineMap[activeStep]}${location.search}`);
    },[activeStep])

    useEffect(()=>{
        return () => {
        rootStore.createJobStore.reset();
        rootStore.jobSettingsStore.reset();
        displayStore.reset();
        
        }
    },[])

    useEffect (()=>{
        setNextDisabled(checkNextDisabled());
        if(dataMover == DataMover.VeeamSQL)
        {
            if(activeStep === 1 && displayStore.nextStepValid.selectedDevicesValid === false)
                loadNextButton();
        }
        else if(activeStep === 2 && displayStore.nextStepValid.selectedDevicesValid === false)
            loadNextButton();
    },[displayStore.nextStepValid])
    
    const checkNextDisabled=()=>{
        if(dataMover === DataMover.PaaS)
        {
            if(activeStep <= 0)
                return (displayStore.nextStepValid.jobNameValid && displayStore.nextStepValid.licenseValid && displayStore.nextStepValid.dmValid && displayStore.nextStepValid.infraValid && displayStore.nextStepValid.primaryValid && displayStore.nextStepValid.testsValid) ? false:true;
            else if(activeStep === 1)
                return displayStore.nextStepValid.networkSettingValid && displayStore.nextStepValid.networkMappingValid ? false:true;
            else if (activeStep === 2)
                return (displayStore.nextStepValid.selectedDevicesValid)? false:true;
        }
        else
        {
            if(activeStep <= 0)
            {
                if(dataMover === DataMover.VeeamSQL) 
                {
                    return (displayStore.nextStepValid.basicAdvancedValid && displayStore.nextStepValid.jobNameValid && displayStore.nextStepValid.licenseValid && displayStore.nextStepValid.dmValid && displayStore.nextStepValid.primaryValid && displayStore.nextStepValid.testsValid && displayStore.nextStepValid.edrValid) ? false:true;
                } 
                else if(dataMover === DataMover.AWSDRS)
                {
                    return (displayStore.nextStepValid.basicAdvancedValid && displayStore.nextStepValid.jobNameValid && displayStore.nextStepValid.licenseValid && displayStore.nextStepValid.infraValid &&  displayStore.nextStepValid.primaryValid && displayStore.nextStepValid.testsValid) ? false:true;
                }
                else if(IsCustom(dataMover))
                {
                    return (displayStore.nextStepValid.basicAdvancedValid && displayStore.nextStepValid.jobNameValid && displayStore.nextStepValid.licenseValid  && displayStore.nextStepValid.infraValid &&  displayStore.nextStepValid.primaryValid && displayStore.nextStepValid.testsValid) ? false:true;
                }
                else 
                {
                    return (displayStore.nextStepValid.basicAdvancedValid && displayStore.nextStepValid.jobNameValid && displayStore.nextStepValid.licenseValid && displayStore.nextStepValid.dmValid && displayStore.nextStepValid.infraValid &&  displayStore.nextStepValid.primaryValid && displayStore.nextStepValid.testsValid) ? false:true;
                }
            }
             else if(activeStep === 1)
            {
                if(isEdit)
                {
                    if(createJobStore.baseSettings.dataMover == DataMover.VeeamSQL)
                    {
                        if(displayStore.nextStepValid.selectedDevicesValid)
                            return false;
                        else
                            return true;
                    }
                    return false;
                }
                if(dataMover === DataMover.VeeamSQL)
                    return (displayStore.nextStepValid.selectedDevicesValid)? false:true;
                else
                {
                    if(createJobStore.networkSetting.NetworkType == NetworkOption.None)
                    {
                        return displayStore.nextStepValid.edrValid ? false : true;
                        /* if(createJobStore.networkSetting.EnsureDRController!=null)
                        {
                            if(createJobStore.networkSetting.EnsureDRController.assetName =="" || createJobStore.networkSetting.EnsureDRController.assetVMName =="")
                                return true;
                        }
                        else return displayStore.nextStepValid.networkMappingValid ? false:true; */
                    }
                    else 
                    {
                        return displayStore.nextStepValid.networkSettingValid && displayStore.nextStepValid.networkMappingValid && displayStore.nextStepValid.dcAdvancedValid && displayStore.nextStepValid.edrcValid && displayStore.nextStepValid.edrValid ? false:true;
                    }
                }
                    
            }
            else if(activeStep === 2)
            {
                return (displayStore.nextStepValid.selectedDevicesValid)? false:true;
            }
        }
    }

    const handleSkip =()=>{
        if(activeStep <= 0)
        {
            if(dataMover === DataMover.VeeamSQL) 
            {
                displayStore.updateNextStepValid({licenseValid:true,dmValid:true,primaryValid:true,testsValid:true,edrValid:true});
            } 
            else if(dataMover === DataMover.AWSDRS)
            {
                return (displayStore.nextStepValid.basicAdvancedValid && displayStore.nextStepValid.jobNameValid && displayStore.nextStepValid.licenseValid && displayStore.nextStepValid.infraValid &&  displayStore.nextStepValid.primaryValid && displayStore.nextStepValid.testsValid) ? false:true;
            }
            else 
            {
                return (displayStore.nextStepValid.basicAdvancedValid && 
                    displayStore.nextStepValid.jobNameValid && 
                    displayStore.nextStepValid.licenseValid && 
                    displayStore.nextStepValid.dmValid &&  
                    displayStore.nextStepValid.primaryValid && displayStore.nextStepValid.testsValid) ? false:true;
                //return (displayStore.nextStepValid.basicAdvancedValid && displayStore.nextStepValid.jobNameValid && displayStore.nextStepValid.licenseValid && displayStore.nextStepValid.dmValid && displayStore.nextStepValid.infraValid &&  displayStore.nextStepValid.primaryValid && displayStore.nextStepValid.testsValid) ? false:true;
            }
        }
        else if(activeStep === 1)
        {

        }
        else if(activeStep === 2)
        {

        }
    }

    const IsCustom = (datamover:DataMover) => 
    {
        if(datamover == DataMover.CustomVmware ||datamover == DataMover.CustomAWS ||datamover == DataMover.CustomAzure ||datamover == DataMover.CustomGCP)
            return true;
        else
            return false;
    }

    const notCustom=()=>
    {
      if(dataMover != DataMover.CustomVmware && dataMover != DataMover.CustomAWS && dataMover != DataMover.CustomAzure && dataMover != DataMover.CustomGCP)
        return true;
      return false;
    }

    const handleNext = () => {
        if(activeStep == 0)
        {
            displayStore.setLicenseEditMode(true);
            const p =[];
            if(jobSettingsStore.infrastructureList.length ==0 && createJobStore.baseSettings.dataMover !=DataMover.VeeamSQL)
                p.push(jobSettingsStore.createInfra());
            if(jobSettingsStore.dataMoverList.length ==0)
            {
                if(dataMover !== DataMover.AWSDRS && notCustom())
                {
                    p.push(jobSettingsStore.createDataMover());
                }
            }
            if(IsCustom(dataMover))
            {
                createJobStore.updateBaseSettings({dataMoverId:dataMover});
            }
            if(jobSettingsStore.accessCredentials.length == 0)
                p.push(jobSettingsStore.createCredentials(CredentialUserType.GeneralUser))
            if(jobSettingsStore.linuxCredentails.length == 0 && createJobStore.baseSettings.workflowTest.SecondaryUser && createJobStore.baseSettings.workflowTest.SecondaryUser != '')
                p.push(jobSettingsStore.createCredentials(CredentialUserType.LinuxUser))

            if(p.length > 0)
            {
                setValidatingStep(true);
                Promise.all(p).then(res => {
                    if(!createJobStore.isAWS && !createJobStore.baseSettings.dataMoverId) {
                        toast.error("Datamover is not selected");
                        return;
                    }
                    if(createJobStore.baseSettings.dataMover !=DataMover.VeeamSQL)
                    {
                        if(!createJobStore.baseSettings.infrastructureId) {
                            toast.error("Infrasturcture is not selected");
                            return;
                    }
                    }
                    if(createJobStore.baseSettings.dataMover === DataMover.VeeamSQL)
                        setActiveStep(1);
                    else
                        setActiveStep((prevActiveStep) => prevActiveStep < 0 ? prevActiveStep + 2 : prevActiveStep + 1);
                }).catch(err => {

                }).finally(() =>{
                    setValidatingStep(false);
                })
            }
            else
            {
                if(createJobStore.baseSettings.dataMover === DataMover.VeeamSQL)
                    setActiveStep(1);
                else
                    setActiveStep((prevActiveStep) => prevActiveStep < 0 ? prevActiveStep + 2 : prevActiveStep + 1);               
            }
            return;

        }
        else if(activeStep == 1 && createJobStore.baseSettings.dataMover != DataMover.VeeamSQL)
        {
            const promise = [];
            if(jobSettingsStore.networkTuppleList.length == 0 && createJobStore.networkSetting.NetworkType !== NetworkOption.None)
            {
                let networkTuple = new NetworkTuppleDTO()
                networkTuple.drNetworkId = jobSettingsStore.vCenterNetworks.find(n => n.name === createJobStore.networkSetting.testDRNetwork.networkMapping[0].targetNetwork).id;
                networkTuple.vmNetworkId = jobSettingsStore.vCenterNetworks.find(n => n.name === createJobStore.networkSetting.testDRNetwork.networkMapping[0].sourceNetwork).id;
                networkTuple.infrastructureId = createJobStore.baseSettings.infrastructureId;
                promise.push(jobSettingsStore.createNetworkTupple(networkTuple));
            }
            if(jobSettingsStore.EDRs.length == 0)
            {
                promise.push(jobSettingsStore.createAsset(jobSettingsStore.tempEDR,AssetType.EDR));
            }
            if(jobSettingsStore.EDRCs.length == 0 && jobSettingsStore.tempEDRC.assetName != '' && createJobStore.networkSetting.NetworkType != NetworkOption.None)
            {
                promise.push(jobSettingsStore.createAsset(jobSettingsStore.tempEDRC,AssetType.EDRC));
            }
            if(promise.length > 0)
            {
                setValidatingStep(true);
                Promise.all(promise).then(res => {    
                    setActiveStep((prevActiveStep) => prevActiveStep < 0 ? prevActiveStep + 2 : prevActiveStep + 1);
                }).catch(err => {
                    toast.error("failed to create network tuple")
                    setActiveStep((prevActiveStep) => prevActiveStep < 0 ? prevActiveStep + 2 : prevActiveStep + 1);
                }).finally(() =>{
                    setValidatingStep(false);
                })
            }
            else
            {
                setActiveStep((prevActiveStep) => prevActiveStep < 0 ? prevActiveStep + 2 : prevActiveStep + 1);
            }
            return;   
        } 
        if(activeStep == 2 && createJobStore.baseSettings.dataMover !=DataMover.VeeamSQL) {
            const {dcSelected, dcValid} = jobSettingsStore.dcValidation;
            if(!dcValid) {
                setDCConfirmationConfig({showDialog: true, dialogText: dcSelected ? t('dcNotValidMessage'): t('dcNotSelectedMessage')});
                return;
            }
        }
            setActiveStep((prevActiveStep) => prevActiveStep < 0 ? prevActiveStep + 2 : prevActiveStep + 1);
        
        
        if(rootStore.createJobStore.isDraft)
            rootStore.createJobStore.updateDraft(activeStep);

        displayStore.updateScreenSettings({AddModeEDRC:false});
        displayStore.updateScreenSettings({AddModeEDR:false});
        displayStore.updateScreenSettings({AddModeDC:false});
        setNextDisabled(checkNextDisabled());
      };
      
    const handleBack = () => {
        displayStore.updateScreenSettings({ displayBaseSettings: 'EmptyVlan',displayNetwork:'EmptyVlan' });
        displayStore.updateNetworkDrawerSettings({controllerDrawerOpen:false,edrDrawerOpen:false,cloneDrawerOpen:false});
        displayStore.updateScreenSettings({AddModeEDRC:false});
        displayStore.updateScreenSettings({AddModeEDR:false});
        displayStore.updateScreenSettings({AddModeDC:false});
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };

    const loadBackButton =()=> {
        return (<Button className={clsx([classes.button, classes.backButton])} 
        disabled={activeStep <= 0 || (rootStore.createJobStore.baseSettings.dataMover != DataMover.VeeamSQL && activeStep === 3 && displayStore._afterCreate === true) || (rootStore.createJobStore.baseSettings.dataMover == DataMover.VeeamSQL && activeStep === 2 && displayStore._afterCreate === true) || displayStore._RunJobProcessing === true || displayStore._disableSectionSave} 
        variant="contained"
        onClick={handleBack} 
        style={{textTransform:'none',marginTop:'0%'}} >{t("back")}</Button>);
    }
    const loadNextButton=()=> {
        if(activeStep == 0 || activeStep == 1)
        return (
            <Tooltip title={rootStore.createJobStore.baseSettings.dataMover == DataMover.VeeamSQL ? (rootStore.createJobStore.selectedDevices.length > 0 ? t("nextStep2DisabledCustom"):t("nextStep2Disabled")):t("nextStepInfo")}>
            <span>
            <Button className={classes.button} 
                variant="contained" 
                color="primary" 
                onClick={handleNext}
                style={{textTransform:'none'}}>{t("next")}</Button>
            </span>
            </Tooltip>);
        else if(activeStep === 2 && displayStore.nextStepValid.selectedDevicesValid === false)
        return (
        <Tooltip title={rootStore.createJobStore.selectedDevices.length > 0 ? t("nextStep2DisabledCustom"):t("nextStep2Disabled")}>
        <span>
        <Button className={classes.button} 
            disabled={nextDisabled}     
            variant="contained" 
            color="primary" 
            onClick={handleNext}
            style={{textTransform:'none'}}>{t("next")}</Button>
        </span>
        </Tooltip>);
        
        else return (
            <Button className={classes.button} 
            disabled={nextDisabled || validatingStep || activeStep === 3} 
            variant="contained" 
            color="primary" 
            onClick={handleNext}
            style={{textTransform:'none'}}>{t("next")}
            {validatingStep && <CircularProgress size={16} style={{marginLeft: 8}} />}
            </Button>
        );
    }
    const loadReValidateButton = () => {
        return <Tooltip title={t("revalidateInfo")} arrow>
        <span>
            <Button className={clsx([classes.button])}
            disabled={activeStep === 0 ? (!jobSettingsStore.anyDM && !jobSettingsStore.anyInfra) || (displayStore.nextStepValid.dmValid && displayStore.nextStepValid.infraValid && displayStore.nextStepValid.primaryValid) || displayStore.screenSettings.DMApplyProcessing || displayStore.screenSettings.InfraApplyProcessing || displayStore.screenSettings.PrimaryUserApplyProcessing : 
                createJobStore.networkSetting.NetworkType == NetworkOption.None ? true :
                    (displayStore.nextStepValid.edrcValid && displayStore.nextStepValid.edrValid) || displayStore.screenSettings.EDRValidationInProgress} //(activeStep === 1 ? (displayStore.nextStepValid.edrValid && displayStore.nextStepValid.edrcValid && displayStore.nextStepValid.networkSettingValid) || displayStore.screenSettings.EDRApplyProcessing || displayStore.screenSettings.EDRCApplyProcessing || displayStore.screenSettings.NetworkProcessing
            variant="contained"
            color="primary"
            onClick={() => activityStore.sendMessage(UpdateType.ReValidate)} 
            style={{textTransform:'none'}} >{"Re-validate"}{<IconButton style={{padding:0, color:'#FFF'}}>
            <AutorenewIcon />
                </IconButton>}
            </Button>
        </span>
         </Tooltip>
    }

    const loadSkipButton = () => {
        return <Tooltip title={t("SkipInfo")} arrow>
        <span>
            <Button className={clsx([classes.button])}
            variant="contained"
            color="primary"
            onClick={()=>handleSkip()} 
            style={{textTransform:'none'}} >{"Skip Validation"}{<IconButton style={{padding:0, color:'#FFF'}}>
            <AutorenewIcon />
                </IconButton>}
            </Button>
        </span>
         </Tooltip>
    }

    const preventNavidation = (nextLocation: Location) => {
        if(exitDialogConfig.confirmed || (location.search && location.search.includes('jobSaved')) ) {
            return true;
        }
        if(nextLocation.pathname.toLocaleLowerCase().includes('createjob') || nextLocation.pathname.toLocaleLowerCase().includes('editjob'))
        {
            return true;
        }
        if(nextLocation.pathname.toLocaleLowerCase().includes('notfound'))
        {
            return true;
        }
        if(nextLocation.pathname.toLocaleLowerCase().includes('/jobs'))
        {
            if(displayStore._saverun)
            {
                return true;
            }
            /*if(window.location.pathname.toLocaleLowerCase().includes('createjob/overview'))  
            {
                return false;
            }*/
            else
            {
                setExitDialogConfig({confirmed: false, location: nextLocation, showDialog: true});  
                return false;
            }
        }
        else
        {
            setExitDialogConfig({confirmed: false, location: nextLocation, showDialog: true});  
            return false;
        }
            
    }

    useEffect(()=> {
        if(exitDialogConfig.confirmed) {
            history.push(exitDialogConfig.location);
        }
    }, [exitDialogConfig.confirmed])

    const handleExitConfirmation = (confirmed: boolean) => {
        if(confirmed) {
            if(rootStore.createJobStore.isDraft)
            {
                rootStore.createJobStore.updateDraft(activeStep);
                rootStore.createJobStore.setDraftId(0);
            }
                
            rootStore.createJobStore.reset();
            rootStore.jobSettingsStore.reset();
            displayStore.reset();
            displayStore.updateScreenSettings({LicenseApplyProcessing:false});
            displayStore.updateScreenSettings({DMApplyProcessing:false});
            displayStore.updateScreenSettings({PrimaryUserApplyProcessing:false});
            displayStore.updateScreenSettings({SecondaryUserApplyProcessing:false});
            displayStore.updateScreenSettings({InfraApplyProcessing:false});
            displayStore.updateScreenSettings({NetworkProcessing:true});
            displayStore.updateScreenSettings({DCApplyProcessing:true});
            displayStore.updateScreenSettings({EDRApplyProcessing:true});
            displayStore.updateScreenSettings({EDRCApplyProcessing:true});
            setExitDialogConfig({...exitDialogConfig, confirmed, showDialog: false});
           
        } else {
            setExitDialogConfig({...exitDialogConfig, confirmed, showDialog: false});
        }
    }

    const checkJobRunning =()=>{
        // let jobId = parseInt((history.location.pathname.split("/")[2]));
        return jobRunStore.isJobRunning(jobId);
    }

    return (
        <Grid container direction="column" data-test="component-createJob" style={{ height: "100vh", overflow:'hidden' }}>
            <AppBar showDevices={true}>
                <Grid container>
                    <Grid xs={9}>
                    <AppBarTitle>
                    <Typography variant="h6" style={{flexWrap:'nowrap',whiteSpace:'nowrap'}}>{(isEdit ? t("editJob")+ jobName : t("createNew"))}</Typography>
                        {checkJobRunning() &&  <Typography variant="h6" style={{color: '#2892D7',cursor:'pointer'}} onClick={()=>history.push(`/jobs?jobId=${jobId}&showReport=true`)}>{t('jobRunning')}</Typography>}
                        {checkJobRunning() && <CircularProgress id="job_running" size={18} style={{color: '#2892D7', marginLeft: '1%'}}/>}
                    </AppBarTitle>
                
                    </Grid>
                    <Grid xs={3}  alignItems='center'>
                    </Grid>
                </Grid>
            </AppBar>
            
            <Grid item container direction="row" className={classes.stepperContainer}>
            <Grid item xs={9} container justifyContent="center">
            <Stepper classes={{root: classes.stepperRoot}} alternativeLabel activeStep={activeStep}>
                 {steps.map((label, index) => {
                     const stepProps: { completed?: boolean } = {};
                     const labelProps: { optional?: React.ReactNode; error?: boolean } = {};

                     if (isStepFailed(index)) {
                         labelProps.error = true;
                     }
                     return (
                         <Step key={label} {...stepProps} classes={{completed: classes.completedIcon, alternativeLabel: classes.alternativeLabel}}>
                             <StepLabel {...labelProps}>{label}</StepLabel>
                         </Step>
                     );
                 })}
             </Stepper>
            </Grid>
            <Grid item container direction="row" xs={3} justifyContent="flex-end" alignItems="center" spacing= {2} style={{marginTop:'-25px',marginLeft:activeStep !== steps.length-1 ?'-12px':'-76px'}}>
            {/*<Grid item>
                {loadSkipButton()}
            </Grid>*/}
            <Grid item>
                {!isFirstJobEver && (activeStep == 0 || activeStep == 1) ? loadReValidateButton() : null}
            </Grid> 
            <Grid item>
                {loadBackButton()}
            </Grid>
            <Grid item>
                {rootStore.createJobStore.baseSettings.dataMover!=DataMover.VeeamSQL ? (activeStep!=3 ?loadNextButton():null):(activeStep!=2 ?loadNextButton():null)}
            </Grid>
            </Grid>
         </Grid>
         <Grid item></Grid>
         <Grid item container style={{flexGrow: 1, height: 'calc(100% - 90px - 64px)'}}>
         <Prompt when={true} message={preventNavidation}></Prompt>
         <ConfirmationDialog type="exitWizard" device={undefined} open={exitDialogConfig.showDialog} modal={true} onClose={(confrim)=>handleExitConfirmation(confrim)} children={"You are about to exit the Create Job Wizard - You may lose un-saved job data"}></ConfirmationDialog>
         <ConfirmationDialog type="warning" title={t('dcValidationConfirmationTitle')} open={dcConfirmationDialogConfig.showDialog} onClose={(confirmed) => {
             if(typeof confirmed == 'boolean' && confirmed) {
                setActiveStep((prevActiveStep) => prevActiveStep < 0 ? prevActiveStep + 2 : prevActiveStep + 1);
             }
             setDCConfirmationConfig({showDialog: false, dialogText: ''});
         }} > 
            <div>
             {dcConfirmationDialogConfig.dialogText}
             </div>
         
         </ConfirmationDialog>
             {userStore.user.username != "readonly" ?
                 <Switch>
                    <Route exact path={`${url}/basic`} component={BaseSettingComponent}/>
                    <Route exact path={`${url}/network`} component={NetworkInfoComponent} />
                    <Route exact path={`${url}/devices`} component={DeviceSelectionComponent} />
                    <Route exact path={`${url}/resource groups`} component={DeviceSelectionComponent} />
                    <Route exact path={`${url}/overview`} component={JobPreviewComponent} />
                    <Route  path={`${url}`}>
                        <Redirect to={`${url}/basic${location.search}`}></Redirect>
                    </Route>
                </Switch>
             : null}    
             
         </Grid>
        </Grid>
    )
}

export default observer(CreateJobComponent)
