import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Drawer, makeStyles, Tooltip } from '@material-ui/core';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import queryString from 'querystring';
import { RootStoreContext } from '../../app/stores/root.store';
import CreateJobConfigurationComponent from '../createJob/CreateJobConfiguration.component';

import { history } from '../../app/common/util/history';
import { observer } from 'mobx-react-lite';
import { useAnyRuns } from './hooks/useAnyRuns';
const useStyles = makeStyles({
    icon: {
        width: "24px",
        height: "20px",
        objectFit: "contain",
        marginLeft: "15px",
        marginTop: "12px",
        marginBottom: "10px",
    },
    navLink: {
        marginLeft: "5px",
        marginTop: "25px",
    },
    linkActive: {
        background: "white",
        color: "#2892d7",
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "5px"
    },
    linkInactive: {
        marginLeft: "5px"
    },
    navigation: {
        background: "#1f396d",
        width: "60px"
    },
})
const Navigation = () => {
    const [dashboarIcondURL, setdashboarIcondURL] = useState("/assets/images/menu/dashboard_active.svg");
    const [reportIconURL, setreportdIconURL] = useState("/assets/images/menu/report_active.svg");
    const [jobsIconURL, setjobsdIconURL] = useState("/assets/images/menu/jobs_active.svg");
    const [settingIconURL, setsettingsdIconURL] = useState("/assets/images/menu/settings_active.svg");
    const [liveIconURL, setLiveIconURL] = useState("/assets/images/menu/live_active.svg");
    const createJobIconUrl = '/assets/images/menu/create_job.svg';
    const params = queryString.parse(window.location.search.slice(1));
    const rootStore = useContext(RootStoreContext);
    const {user} = rootStore.userStore;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [anyRun] = useAnyRuns(true);
    const l = useLocation();



    const loadCreateJobButton = () => {
        if (user.username != "readonly"){
            if (rootStore.dashboardStore.createEnabled)
            return (
                <React.Fragment>
                    {!l.pathname.toLowerCase().includes('createjob') && !l.pathname.toLowerCase().includes('editjob') &&
                        <img src={createJobIconUrl} alt="Create Job" className={classes.icon} style={{ marginLeft: '21px', cursor: 'pointer' }} onClick={() => setDialogOpen(true)} />}
                    <CreateJobConfigurationComponent open={dialogOpen} onClose={() => setDialogOpen(false)} />
                </React.Fragment>
            );
        else return null;
        }
    }

    const handleClickNav =(event:any,to:string)=>{
        if(!anyRun) 
        {
            event.preventDefault();
        }
        else
            history.push(to);    
    }

    const classes = useStyles();
    return (
        <Drawer variant="permanent" >
            <Grid container direction="column" justifyContent="space-between" className={classes.navigation} style={{ height: "100%" }}>
                <Grid item style={{ padding: "10px" }}>
                    <NavLink to="/jobs">
                        <span data-test='logoButton'>
                            <img style={{ width: "40px", height: "40px" }} alt="ensuredr" src="/assets/images/logo_white.svg"></img>
                        </span>
                    </NavLink>
                </Grid>
                <Grid container direction="column">
                    <NavLink activeClassName={classes.linkActive} className={classes.linkInactive} to="/dashboard"  onClick={(event)=>handleClickNav(event,'/dashboard')}>
                    {!anyRun ?<span data-test='dashboardPageButton'>
                        <Tooltip title={"Run one job to view dashboard"}>
                            <img src={dashboarIcondURL} alt="Dashboard" className={classes.icon} />
                        </Tooltip>
                        </span>
                        :<span data-test='dashboardPageButton'>
                            <img src={dashboarIcondURL} alt="Dashboard" className={classes.icon} />
                        </span>}
                    </NavLink>
                    <NavLink activeClassName={classes.linkActive} className={classes.navLink} to="/jobs">
                        <span data-test='jobsPageButton'>
                            <img src={jobsIconURL} alt="Jobs" className={classes.icon} />
                        </span>
                    </NavLink>
                    <NavLink activeClassName={classes.linkActive} className={classes.navLink} to="/report"  onClick={(event)=>handleClickNav(event,'/report')}>
                        {!anyRun  ?<span data-test='reportPageButton'>
                            <Tooltip title={"Run one job to view report"}>
                            <img src={reportIconURL} alt="Report" className={classes.icon} />
                            </Tooltip>
                        </span>
                        :<span data-test='reportPageButton'>
                            <img src={reportIconURL} alt="Report" className={classes.icon} />
                        </span>}
                    </NavLink>
                </Grid>
                <Grid container direction="column">
                     {/*<NavLink activeClassName={classes.linkActive} className={classes.navLink} to="/live">
                        <img src={liveIconURL} alt="Live" className={classes.icon} />
                    </NavLink> */}
                     <span data-test='createJobButton'>
                        {loadCreateJobButton()}
                    </span>
                    {/*{ user.username != "readonly"?
                        <NavLink activeClassName={classes.linkActive} className={classes.navLink} to="/settings">
                        <span data-test='settingsPageButton'>
                            <img src={settingIconURL} alt="Settings" className={classes.icon} />
                        </span>
                    </NavLink> : null
                    }*/}
                    
                </Grid>
            </Grid>
        </Drawer>
    )
}

export default observer(Navigation);