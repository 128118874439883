

export enum TestType {
    power = 0,
    network = 1,
    service = 2,
    custom = 3,
    autofix = 14,
    database = 8,
    testAdvNet = 4,
    advanceSvc = 5,
    NetConnectStat = 6,
    LinuxOSADV = 7,
    web = 9,
    TestADVCYBres = 10,
    Cyberengine = 11,
    SecondPowerOn = 12,
    thirdDeviceNetwork = 16,
    PreScriptRes = 13,
    authentication = 15
}

export enum TestStatus {
    passed = 0,
    failed = 1,
    NA = 2
}

export interface JobTest {
    testType : TestType
    testResult: string,
    testStatus: TestStatus,
    name: string,
}

export type TestNames = 'custom' | 'network' | 'service' | 'database' | 'power' | 'web' | 'autofix' | 'internet' |'branch' | 'drResources'|'PreScriptRes'|'authentication'|'thirdDeviceNetwork';
