
import agentbase from './agentBase';
import { PaginatedResponse } from '../models/paginatedResponse.model';
import { JobRun, JobRunState } from '../models/jobRun.model';
const axios = agentbase.axios;


const JobRunAgent = {
    getJobRun: (jobRunState: JobRunState, page?: number, limit?: number): Promise<PaginatedResponse<Array<JobRun>>> => {
       return axios.get(`/JobRuns`, {params: {jobRunState, page, limit}}).then(res => res.data);
    }
}

export default JobRunAgent;
