import  SvgIcon  from "@material-ui/core/SvgIcon";
import React from "react"
import {ReactComponent as RecoverableSVG} from './svgs/recoverable.svg';

const RecoverableIcon: React.FC<React.HTMLProps<SVGElement>> = (props) => {
    const {style, className} = props;
    return <SvgIcon viewBox={"0,0, 18, 18"}  className={className} style={style} component={RecoverableSVG}>
    </SvgIcon>
}


export default RecoverableIcon;