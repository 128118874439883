import React, { useRef, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Dialog, DialogActions, DialogContent, Divider, Grid, InputLabel, makeStyles, Tooltip, Typography} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import HelpIcon from '@material-ui/icons/Help';
import PrePostSectionComponent from './PrePostSection.component';
import { EDRDialogTitle } from "../../../app/common/EDRDialogTitle";
import { SaveButton } from '../../../app/common/saveButton';
import { toast } from 'react-toastify';

type props = {
    onClose: () => void;
    open: boolean;
  }

const PrePostScriptsComponent : React.FC<props> = ({onClose, open}) => {
    const prefailoverChild =useRef(null);  
    const postfailoverChild=useRef(null);   
    const precleanupChild=useRef(null);   
    const postcleanupChild=useRef(null);   
    const preTestChild=useRef(null);   
    const postTestChild=useRef(null);   
    const [cancel,setCancel]=useState(false);

    const useStyles = makeStyles({
      centerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between'
    },
    toggleItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiListItemText-root' : {
            marginLeft: 10
        },
        '& .MuiSvgIcon-root': {
            color: '#8092a9'
        }
    },
      root: {
        minWidth: '33vw',
        },
        card: {
            width: '568px',
          },
          MainHeading: {
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '100%',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.19',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1
          },
          SecondHeading: {
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.19',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1,
            color: '#4d5f75',
            fontsize:'13px',
            marginTop:'16px'
          },
          helpIcon:
          {
            color: '#1976d2',
            fontSize: "large",
            marginLeft: '16px'
          },
          formControl: {
            width: '100%',
            '& label': {
              background: "white",
              width: "auto",
              padding: "0 4px 0 4px",
              fontSize: '17px'
            }
          },
          divider: {
            backgroundColor: '#eaeef4',
            width: '100%',
            margin: '0',
            marginTop: '8px',
            marginLeft: '26px'
          },
          BrowseButton:{
            backgroundColor: '#fafafc',
            textTransform: 'none',
          }
    });   
    const classes = useStyles(); 
    const [t] = useTranslation("createJobBase");
    
    const handleClose = () => {
      if(prefailoverChild.current.IsDirty() || postfailoverChild.current.IsDirty() || precleanupChild.current.IsDirty() || postcleanupChild.current.IsDirty() || preTestChild.current.IsDirty() || postTestChild.current.IsDirty())
        setCancel(!cancel);
      else
        onClose();
  }

    const handleSaveClick =() => {
      prefailoverChild.current.SaveClick();
      postfailoverChild.current.SaveClick();
      precleanupChild.current.SaveClick();
      postcleanupChild.current.SaveClick();
      preTestChild.current.SaveClick();
      postTestChild.current.SaveClick();
      toast.success('Save successful');
      setCancel(false);
      onClose();
    }

    const loadConfirmation=()=>{
      if(cancel)
      {
          return (
              <React.Fragment>
                 <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                      <div className={classes.centerVertical}>
                          <InputLabel style={{marginLeft:'10px'}}>{t('disacrdChanges')}</InputLabel>
                          <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                          <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                      </div>
                  </div> 
              </React.Fragment>
          );
      }
  }

  const handleYes=()=>{
    prefailoverChild.current.CancelClick();
    postfailoverChild.current.CancelClick();
    precleanupChild.current.CancelClick();
    postcleanupChild.current.CancelClick();
    preTestChild.current.CancelClick();
    postTestChild.current.CancelClick();
    setCancel(false);
    onClose();
  }

  const handleNo=()=>{
      setCancel(!cancel);
  }
   
       return <Dialog disableBackdropClick
          classes={{ paper: classes.root }}
          open={open}
          onClose={handleClose}
          scroll="paper"
        >
           <EDRDialogTitle >{t('prepostScripts')}</EDRDialogTitle>
            <DialogContent dividers={true}>
                <Grid container direction="column" classes={{ root: clsx([classes.card]) }}>
                
              <Grid container item direction="row">
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px' }}>
                <Typography className={classes.MainHeading} style={{ color: '#1f396d' }}>{t('scriptFailover')}</Typography>
                <Tooltip title={t("scriptFailoverInfo")} arrow>
                  <HelpIcon className={classes.helpIcon} />
                </Tooltip>
                <Divider className={classes.divider} style={{marginLeft: '10px',width: '200%'}}/>
              </div>
              </Grid>
              <PrePostSectionComponent ref={prefailoverChild} section={"failover"} name={t('prefailoverseperate')} type={"pre"}/>
              <PrePostSectionComponent ref={postfailoverChild} section={"failover"} name={t('postfailoverseperate')} type={"post"}/>

              <Grid container item direction="row">
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                <Typography className={classes.MainHeading} style={{ color: '#1f396d' }}>{t('scriptTest')}</Typography>
                <Tooltip title={t("scriptTestInfo")} arrow>
                  <HelpIcon className={classes.helpIcon} />
                </Tooltip>
                
                <Divider className={classes.divider} style={{marginLeft:'10px',width:'240%'}}/>
              </div>
              </Grid>
              <PrePostSectionComponent ref={preTestChild} section={"test"} name={t('pretestseperate')} type={"pre"}/>
              <PrePostSectionComponent ref={postTestChild} section={"test"} name={t('posttestseperate')} type={"post"}/>
              
              <Grid container item direction="row">
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                <Typography className={classes.MainHeading} style={{ color: '#1f396d' }}>{t('scriptCleanup')}</Typography>
                <Tooltip title={t("scriptCleanupInfo")} arrow>
                  <HelpIcon className={classes.helpIcon} />
                </Tooltip>
                <Divider className={classes.divider} style={{marginLeft:'10px',width:'195%'}}/>
              </div>
              </Grid>
              <PrePostSectionComponent ref={precleanupChild} section={"cleanup"} name={t('precleanupseperate')} type={"pre"}/>
              <PrePostSectionComponent ref={postcleanupChild} section={"cleanup"} name={t('postcleanupseperate')} type={"post"}/>

            </Grid>
            </DialogContent>
            <DialogActions style={{marginRight:'35px'}}>
            <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end'}}>
            <Button onClick={handleClose} color="primary" style={{textTransform:'none'}}>
                {t("cancel")}
            </Button>
            <SaveButton color="primary" variant="contained" onClick={handleSaveClick} style={{textTransform:'none'}}>
                {t("save")}
            </SaveButton>
            </Grid>
        </DialogActions>
        </Dialog>
}

export default observer(PrePostScriptsComponent);