import * as yup from 'yup';

let validEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
let validSmtpAddress = /^([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/;
export const EmailSettingSchema = yup.object({
  emailto: yup.string()
  .test('emailto',"emailtoIsInvalid",
    function(value){
      console.log(value)
      let finalResult: boolean = true;
      if (value && value.length>0){
        if(value.includes(';'))
        {
            let addressesArr = value.split(";");
            addressesArr.forEach(element => {
              if(element == '')
                  finalResult = false;
              else if (!validEmail.test(element)){
                  finalResult = false;
              }
            })
        }
        else
        {
            if(!validEmail.test(value))
            {
                finalResult = false;
            }
        }
      }
      this.resolve(finalResult)
      return finalResult;
    }),
    emailtype: yup.string()
    .nullable(),
    emailfrom: yup.string()
    .nullable()
    .when('emailtype', {
      is: "organization",
      then: yup
      .string()
      .required("EmailfromIsRequiredInCaseOfEmailtypeOrganization")
    })
    .test('emailfrom',"emailfromIsInvalid",
    function(value){
      let finalResult: boolean = true;
      if (value && value.length>0){
        if (!validEmail.test(value)){
              finalResult = false;
        }
      }
      return finalResult;
    }),
    smtpaddress: yup.string()
    .nullable()
    .when('emailtype', {
      is: "organization",
      then: yup
      .string()
      .required("smtpAddressIsRequiredInCaseOfEmailtypeOrganization")
    })
    .test('smtpaddress',"smtpAddressIsInvalid",
    function(value){
      let finalResult: boolean = true;
      if (value && value.length>0){
        if (!validSmtpAddress.test(value)){
              finalResult = false;
        }
      }
      return finalResult;
    }),
    smtpport: yup.number()
        .max(65535, "maxPort")
        .when('emailtype', {
          is: "organization",
          then: yup
          .number()
          .required("smtpPortIsRequiredInCaseOfEmailtypeOrganization")
        })
        .min(0, "minPort"),
  })