import React, { useState, useEffect, useContext } from 'react'
import { ReportData } from '../../app/models/reportdata.model'
import { Grid, Typography, Button, Paper, makeStyles, Box } from '@material-ui/core';
import { convertTimespanToString, convertSecondsToTime, parseSelectedDevices } from '../../app/common/util/util';
import moment from 'moment';
import { TrendIndicator } from '../../app/common/trendIndicator.component';
import RecoveryReadinessChart from '../report/recoveryReadiness.component';
import GaugeChart from '../../app/common/gaugeChart.component';
import JobActionMenu from '../../app/common/jobActionMenu.component';
import MoreVert from '@material-ui/icons/MoreVert';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon from '@material-ui/icons/Settings';
import NetworkIcon from '@material-ui/icons/Language';
import SpannerIcon from '@material-ui/icons/Build';
import WebIcon from '@material-ui/icons/Web';
import FullLogIcon from '@material-ui/icons/Assignment';
import DatabaseIcon from '@material-ui/icons/Storage';
import BugIcon from '@material-ui/icons/BugReport';
import FlashIcon from '@material-ui/icons/FlashOn';
import { useTranslation } from 'react-i18next';

import { JobRun } from '../../app/models/jobRun.model';
import { Skeleton } from '@material-ui/lab';

import PrintIcon from '@material-ui/icons/Print';
import TestWorkFlowComponent from '../miniReport/testWorkflow.component';
import TestOverViewTile from '../miniReport/TestOverView.component';
import DeviceTestChart from '../../app/common/testChart.component';
import DRResourceSectionComponent from './drResourceSection.component';
import LogViewer from '../../app/common/logViewer.component';
import IssueAndAutoFixComponent from './issuesAndAutofix.component';
import { useShowResourceSetting } from '../../app/common/util/useShowResourcesSetting.hook';
import { DeviceType, DEVICE_TYPE_NAME_SEPERATE_DC, DEVICE_TYPE_SMALL_ICON } from "../../app/models/deviceType.enum";
import { RootStoreContext } from '../../app/stores/root.store';
import { PrePostScriptsDTO } from '../../app/models/createjob/createJob.model';
import RRSDialog from '../../app/common/RRSDialog.component';
import RTADialog from '../../app/common/RTADialog.component';
import { AutofixIcon, InternetConnectionIcon, IssuesIcon } from '../Icons';
import ResourcesAndICComponent from './resourcesAndIC.component';
import { loadOneKindDevice } from './reportMain.component';
import ReportTestsComponent from './reportTests.component';
import { JobRunType } from '../../app/models/BaseSettings.enum';

const useStyles = makeStyles(theme => ({


  verticalBorder: {
    background: "lightGray",
    width: 1
  },

  checkIcon: {
    color: "#8bc53f",
    marginRight: 5
  },
  crossIcon: {
    color: "#077187",
    marginRight: 5
  },
  timelineSelectedItem: {
    borderTop: "0px",
    background: "white",
    borderBottom: "4px solid #2892d7",
  },
  hover: {
    "&:hover": {
      backgroundColor: '#bfdff3',
      cursor: 'pointer'
    }
  },
  menuButton: {
    width: "35px",
    height: "35px",
    borderRadius: "18px",
    border: "1px solid #c7cfdb",
    minWidth: "0"
  },
  testIcon: {
    color: '#8092a9',
    fontSize: 16
  }
}));

const dateFormat = "M.D.YYYY, h:mm A";

const MainReportJobRunView: React.FC<{
  jobReport: {
    id: number,
    jobId: number,
    jobName: string,
    createDate: Date,
    dataMover: string,
    isDeleted: boolean,
    isDeletedPermanently: boolean,
    attachmentSize: number,
    runDate: string,
    PrePostScripts: Array<PrePostScriptsDTO>,
    jobNameFromJobs: string
  }, jobRunState: JobRun,
}> = ({ jobReport, jobRunState }) => {
  const { t } = useTranslation('report');
  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [openLogsDialog, setopenLogsDialog] = useState(false);
  const [openRRSDialog, setopenRRSDialog] = useState(false);
  const [openRTADialog, setopenRTADialog] = useState(false);
  const [progressInfo, setprogressInfo] = useState("");
  const showResources = useShowResourceSetting();
  const { settingStore, jobRunStore, reportStore,createJobStore } = useContext(RootStoreContext);
  const { reportSettings } = settingStore;
  const selectedDevices = reportSettings.find(obj => obj.settingKey.match('SelectedDevices'));
  const [devices, setDevices] = useState<Array<{ type: DeviceType, ok: number, failed: number }>>([]);
  const startTime = jobRunStore.getStartTime(jobReport.jobId);

  useEffect(() => {
    selectedDevices && setDevices(parseSelectedDevices(selectedDevices.settingValue).map(deviceType => {
      return {
        type: deviceType,
        ok: 0,
        failed: 0
      }

    }))
  }, [selectedDevices]);

  useEffect(() => {
    if (jobRunState.jobRunProgressInfos) {
      const lastProgressInfo = jobRunState.jobRunProgressInfos.sort((a, b) => b.id - a.id)[0];
      setprogressInfo(lastProgressInfo && lastProgressInfo.progressInfo);
    }

  }, [jobRunState])
  const handleMenuClose = () => {
    setMenuAnchor(null);
  }

  const getTitle =(type: any)=>{
    let title = t("testWorkflow.title");
    let res="";
    if(!type)
    {
        return title + t("testWorkflow.all");
    }
    if(type == JobRunType.All)
      res = t("testWorkflow.all");
    else if(type == JobRunType.CleanUpOnly)
      res =t("testWorkflow.cleanupOnly");
    else if(type === JobRunType.FailoverOnly)
      res = t("testWorkflow.failoverOnly");
    else
      res = t("testWorkflow.liveFailoverOnly");
    return title + res;
  }

  return <>
    <Grid container direction="row" style={{ padding: "0 15px 0 15px" }}>
      <LogViewer jobName={jobReport.jobNameFromJobs} jobResultId={jobReport.id} open={openLogsDialog} onClose={() => setopenLogsDialog(false)} />
      <RRSDialog jobName={jobReport.jobNameFromJobs} jobResultId={jobReport.id} rRSTotals={null} open={openRRSDialog} onClose={() => setopenRRSDialog(false)} />
      <RTADialog jobName={jobReport.jobNameFromJobs} jobResultId={jobReport.id} rTATimeStamps={null} open={openRTADialog} onClose={() => setopenRTADialog(false)} />
      <div style={{ display: "flex", marginRight: 10, marginLeft: 10, alignItems: 'center' }} >
        <img src="/assets/images/running_job_loader_large.gif" style={{ width: 60, height: 60 }}></img>
      </div>

      <Grid item xs={7}>
        <Grid container direction="column">
          <Grid item xs={12}> <Typography noWrap={true} variant="h6" align="left">{t("summaryReport")} - {jobReport.jobNameFromJobs}</Typography></Grid>
          <Grid item xs={12}><Typography variant="body1" align="left">{jobReport.dataMover}
            <span style={{ margin: "0 5px 0 5px" }}>|</span>
            {startTime != null ? 'Job Started at ' + startTime + ' - ' + t("running") : t("running")}
          </Typography></Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <div style={{ textAlign: "right" }}>
          <Button onClick={() => setopenLogsDialog(true)} variant="outlined" style={{ marginRight: "10px", borderRadius: "20px" }}><FullLogIcon /> {t("button.fullLog")}</Button>
          <Button onClick={() => { window.print() }} variant="outlined" style={{ marginRight: "10px", borderRadius: "20px" }}><PrintIcon /> {t("button.print")}</Button>
          <Button variant="text" className={classes.menuButton} onClick={(event) => setMenuAnchor(event.target)}><MoreVert /></Button>
                  <JobActionMenu jobId={jobReport.jobId} firstJobEver={jobReport && jobReport.jobId == 1}jobResultId={jobReport.id} edrServer={''} jobName={jobReport.jobNameFromJobs} createDate={jobReport.createDate} jobNameFromJobs={jobReport.jobNameFromJobs} isDeleted={jobReport.isDeleted} isDeletedPermanently={jobReport.isDeletedPermanently} attachmentSize={jobReport.attachmentSize} anchorEl={menuAnchor} onClose={handleMenuClose} />
        </div>
      </Grid>
      <Grid container direction="row" style={{ padding: "15px 0 0 0" }}>
        <Grid item xs onClick={() => !jobRunStore.isJobRunning(jobReport.jobId) ? setopenRRSDialog(true) : null}>
          <Paper style={{ margin: "5px", padding: "15px", boxSizing: 'border-box' }} className={jobRunStore.isJobRunning(jobReport.jobId) ? null : classes.hover} variant="outlined">
            <Grid container>
              <Grid item xs={8}>
                <Grid container direction="column" alignItems="flex-start">
                  <Grid item>
                    <Typography variant="body1" > {t("recoveryReadinessScore")}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h3">
                      {0}%</Typography>
                  </Grid>
                  <Grid item>
                    <TrendIndicator value={0} showText={true} trendUpLabel={t("morethanLastJob")} trendDownLabel={t("lessthanLastJob")} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <RecoveryReadinessChart width={110} height={110} score={0} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs onClick={() => !jobRunStore.isJobRunning(jobReport.jobId) ? setopenRTADialog(true) : null}>
          <Paper style={{ margin: "5px", padding: "15px", boxSizing: 'border-box' }} className={jobRunStore.isJobRunning(jobReport.jobId) ? null : classes.hover} variant="outlined">
            <Grid container>
              <Grid item xs={8}>
                <Grid container direction="column" alignItems="flex-start">
                  <Grid item>
                    <Typography variant="body1"> {t("recoveryTime")}</Typography>
                  </Grid>
                  <Grid item>
                    <span>
                      <Typography variant="h3" style={{ width: "100%" }}> {convertSecondsToTime(0)}</Typography>
                    </span>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2"> {t("RTOwasSetTo")} {convertSecondsToTime(0)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <GaugeChart width={100} height={110} value={0} max={0} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {/*  chart tiles ends */}
    </Grid>
    <Grid container direction="column" style={{ padding: "15px" }}>
      <Grid item >
        <Typography variant="h6" align="left"> {getTitle(jobRunState.jobRunType)}</Typography>
      </Grid>
      <Grid item >
        <Typography align="left" variant="body1">{progressInfo}</Typography>
      </Grid>
      <Grid item style={{ marginTop: "15px" }}>
        <TestWorkFlowComponent variant="regularRun" workflowScripts={jobReport.PrePostScripts} workflowSteps={[]} jobRunState={jobRunState} runDate={jobReport.runDate}></TestWorkFlowComponent>

      </Grid>

    </Grid>
    {/* <Grid container direction="column" style={{ padding: "15px" }}>
    <Grid item >
      <Typography variant="h6" align="left"> {t("testedDevices")}</Typography>
    </Grid>
    <Paper style={{ padding: "15px" }} variant="outlined">
      <Grid item >
        
      <Grid container direction="row" style={{ minHeight: "200px" }} justify="space-between">
        {devices.slice(0,5).map((device, index) => {
          return <> <Grid key={index} xs={5} md={3} lg={2}>
            <DeviceTestChart passed={0} failed={0} width={200} icon={DEVICE_TYPE_SMALL_ICON[device.type]} title={t(DEVICE_TYPE_NAME_SEPERATE_DC[device.type])} />
          </Grid>
            {index <= 5 && <Box className={classes.verticalBorder} display={{ xs: 'block', sm: 'block', xl: 'block', md: 'block', lg: 'block' }}></Box>}
          </>
        })}
      </Grid>
      </Grid>
    </Paper>
  </Grid> */}

    {/* tested devices ends */}

    <Grid container direction="row">
      <Grid item xs={12} md={12} xl={12}>

        <Grid item style={{ padding: "0px 15px 0px 15px" }}>
          <Grid item>
            <Typography variant="h6" align="left"> Tests Overview</Typography>
          </Grid>
          <Paper style={{ marginTop: "7px", border: '1px solid white' }} variant="outlined">

            <Grid container spacing={2}>
              <Grid item container xs={4} direction="column">
                {loadOneKindDevice(0,"mainRun", jobReport.dataMover == "VeeamSQL" ? DeviceType.Database :DeviceType.VirtualMachine )}
                <Grid item container style={{ border: '1px solid #eaeef4', borderRadius: '4px',flexGrow:1, height:"calc(50% - 8px)", marginTop: 16, padding: "10px 0"}}>
                  <Grid item xs={6} style={{ borderRight: '1px solid #eaeef4', height: "100%" }}>
                    <Grid container direction="column" justify="space-between" style={{padding: "0 10px"}} >
                      <IssueAndAutoFixComponent name={t("tests.issues")} count={0} icon={<IssuesIcon className={classes.testIcon} />} type="issues" reportType="main" />
                      <IssueAndAutoFixComponent name={t("tests.autoFix")} count={0} icon={<AutofixIcon className={classes.testIcon} />} type="autoFix" reportType="main" />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} container style={{ height: "100%", padding: "0 10px" }}>
                  <DRResourceSectionComponent 
                  cpu={0} cpuMax={10} 
                  ram={0} rammax={10}
                  iops={0} iopsMax={10}
                  jobStatus={null} />
                  </Grid>
                </Grid>
              </Grid>
             <Grid item xs={8}>
             <ReportTestsComponent type={"main"} jobTest={null} />
             </Grid>
            </Grid>

          </Paper>
        </Grid>
      </Grid>
    </Grid>
    <Grid container style={{ padding: "15px" }}>
      <Skeleton variant="rect" style={{ width: "100%", height: "200px" }} />
    </Grid>
  </>;
}

export default MainReportJobRunView;