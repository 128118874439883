import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/root.store';
import { Dialog, useMediaQuery, useTheme, DialogContent, Grid, Typography, TextField, CircularProgress, Button, makeStyles, Theme, createStyles, InputLabel, FormControl, OutlinedInput, InputAdornment, IconButton, FormHelperText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import jobAgent from '../../app/api/jobAgent';
import { toast } from 'react-toastify';
import {JobDelete} from "../../app/models/jobactions/jobDelete.model";
import {history} from '../../app/common/util/history';
import queryString from 'querystring';
import { JobSummaryGroupByJob } from '../../app/models/jobSummaryGroupByJob.model';
import { UpdateType } from '../../app/stores/activityStore';
const { JobActions } = jobAgent;
type RenameProps = {
  jobId: number;
  JobNameFromJobs: string;
  edrServer: string;
  onClose: () => void;
  open: boolean;
}


const ConfirmDeleteComponent: React.FC<RenameProps> = ({jobId,onClose, open,JobNameFromJobs,edrServer}) => {
    const rootStore = useContext(RootStoreContext); 
    const { reportStore, activityStore } = useContext(RootStoreContext); 
    const theme = useTheme();    
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const {t: errorTranslations} = useTranslation('errors');
    const {t} =  useTranslation('jobmenu');
    
    const getFormValues = (field: string) => {
      
    }
    const keyPress = event => {
        if(event.keyCode == 13){
          renameHandler();
        }
    }
    const renameHandler= () => {
        JobActions.delete(new JobDelete({id: jobId}))
        .then(res => {
          if(res === "success")
          {
              //rootStore.activityStore.sendNewUpdatesSignal();
              rootStore.activityStore.sendMessage(UpdateType.JobRemoved, {jobId});
              toast.success(<React.Fragment>
                <h3>{JobNameFromJobs} {errorTranslations('deleteSuccess')} {edrServer}</h3>
                {<div style={{marginLeft: 10}}><i>{errorTranslations('deleteSuccessMessage')}</i></div>}
              </React.Fragment>, {autoClose: 30000 })
          }
          else
          {
              if(res == "AssetNotAvailable")
              {
                toast.error(<React.Fragment>
                  <h3>{errorTranslations('Error')}</h3>
                  {<div style={{marginLeft: 10,fontSize:14}}>{errorTranslations('FailedDelete')} {JobNameFromJobs} {<div style={{fontWeight:700,display:'inline'}}>{JobNameFromJobs}</div>} From {<div style={{fontWeight:700,display:'inline'}}>{edrServer}</div>} </div>}
                  {<div style={{marginLeft: 10,fontSize:14}}>{errorTranslations('Reason')} {errorTranslations('RunnerServiceNotAvailable')}</div>}
                </React.Fragment>, {autoClose: 30000 })
              }
              else if(res === "AssetNotAvailableJobRunning")
              {
                toast.error(<React.Fragment>
                  <h3>{errorTranslations('Error')}</h3>
                  {<div style={{marginLeft: 10}}>{errorTranslations('FailedDelete')} {JobNameFromJobs} {<div style={{fontWeight:700,display:'inline'}}>{JobNameFromJobs}</div>} From {<div style={{fontWeight:700,display:'inline'}}>{edrServer}</div>}</div>}
                  {<div style={{marginLeft: 10,fontSize:14}}>{errorTranslations('Reason')} {errorTranslations('JobAlreadyRunningDelete')}</div>}
                </React.Fragment>, {autoClose: 30000 })              
              }
              else if(res === "AssetNotExistSuccessChange")
              {
               // rootStore.activityStore.sendNewUpdatesSignal();
                toast.warn(<React.Fragment>
                  <h3>{errorTranslations('warning')}</h3>
                  {<div style={{marginLeft: 10}}>{<div style={{fontWeight:700,display:'inline'}}>{JobNameFromJobs}</div>} Deleted From {<div style={{fontWeight:700,display:'inline'}}>{edrServer}</div>} </div>}
                  {errorTranslations('Reason')} {errorTranslations('AssetNotExistDeleteSuccess')}
                </React.Fragment>, {autoClose: 30000 })
              }
              else if(res === "AssetNotExistFailedChange")
              {
                toast.error(<React.Fragment>
                  <h3>{errorTranslations('Error')}</h3>
                  {<div style={{marginLeft: 10}}>{errorTranslations('AssetNotExistDeleteFailed')}</div>}
                </React.Fragment>, {autoClose: 30000 })                 
              }
              else
              {
                toast.error(<React.Fragment>
                  <h3>{errorTranslations('Error')}</h3>
                  {<div style={{marginLeft: 10,fontSize:14}}>{errorTranslations('FailedDelete')} {JobNameFromJobs} {<div style={{fontWeight:700,display:'inline'}}>{JobNameFromJobs}</div>} From {<div style={{fontWeight:700,display:'inline'}}>{edrServer}</div>} {errorTranslations('Reason')} {errorTranslations('RunnerServiceNotAvailable')}</div>}
                </React.Fragment>, {autoClose: 30000 })
              }
              
          }
        })
        .catch(err => {
          toast.error(err)
        })
        .finally(
          () => onClose()

        );
    }  
    const useStyles = makeStyles({
      label: {
        textTransform: 'capitalize',
      },
    });

    const classes = useStyles();

    useEffect(() => {
      //callMatomo("Profile_Form","/user");
    }, []);

      const dialogContents = (
            <Dialog
              style={{ zIndex: 1302 }}
              open={open}
              fullScreen={matchesSM}
            >
              <DialogContent style={{ overflow: "hidden"}}>
                <Grid container direction="column" spacing={3} style={{ overflow: "hidden"}}>
                  <Grid item>
                    <Typography align="left" classes={{ root: "confirm_delete_form_title"}}>
                    {t("areYouSure")}
                    </Typography>
                    <Typography align="left" classes={{ root: "confirm_delete_content"}}>
                    {t("aboutDelete")} {<div style={{fontWeight:500,display:'inline'}}>{JobNameFromJobs}</div>} {t("fromEDR")} {<div style={{fontWeight:500,display:'inline'}}>{edrServer}</div>}.
                    {<div>{t("youCanStill")}</div>}
                    {<div>{t("sureProceed")}</div>}
                    </Typography>
                  </Grid>
                  <div style={{marginBottom: "1em", marginTop: "1em", display:"flex",justifyContent:"flex-end"}}>
                    <Grid item>
                      <span data-test="deleteJobCancelButton">
                        <Button
                          classes={{ root: "Cancel", label: classes.label}}
                          style={{ marginRight: "2em", marginTop: "0.5em" }}
                          onClick={() => onClose()}
                        >
                          { t("confirmCancel")}
                        </Button>
                      </span>
                    </Grid>
                    <Grid item>
                      <span data-test="deleteJobConfirmButton">
                        <Button
                          variant="contained"
                          classes={{ root: "profile_send_button" , label: classes.label}}
                          onClick={() => {
                            renameHandler()
                          }}
                        >
                          {t("confirmDelete") }
                        </Button>
                      </span>
                    </Grid>
                  </div>
                </Grid>
        </DialogContent>
    </Dialog>
      );
      return (
        <div data-test='component-set-name'>
          {dialogContents}
        </div>
    );
}

export default observer(ConfirmDeleteComponent);