import UserStore from "./user.store";
import CommonStore from "./common.store";
import { createContext } from "react";
import { configure } from "mobx";
import DashboardStore from "./dashboard.store";
import { JobResultStore } from "./jobResult.store";
import { ReportStore } from "./report.store";
import SettingStore from "./setting.store";
import { JobRunStore } from "./jobRun.store";
import ActivityStore from "./activityStore";
import CreateJobStore from "./createJob.store";
import JobSettingsStore from "./jobSettings.store";
import ServerStatusStore from "./ServerStatusStore";
import DisplayScreenStore from "./screenStore";


//this enables "strict mode" on every mobx object, and enforces it to run
//within an action - so we should use the "runInAction" method for that
configure({enforceActions: 'observed'});

export class RootStore {
    userStore: UserStore;
    commonStore: CommonStore;
    dashboardStore: DashboardStore;
    jobResultStore: JobResultStore;
    reportStore: ReportStore;
    settingStore: SettingStore;
    jobRunStore: JobRunStore;
    activityStore: ActivityStore;
    createJobStore:CreateJobStore;
    jobSettingsStore:JobSettingsStore;
    displayStore:DisplayScreenStore;
    serverStatusStore: ServerStatusStore

    constructor() {
        this.commonStore = new CommonStore(this);
        this.userStore = new UserStore(this);
        this.activityStore = new ActivityStore();
        this.jobResultStore = new JobResultStore(this);
        this.dashboardStore = new DashboardStore(this);
        this.reportStore = new ReportStore(this);
        this.settingStore = new SettingStore(this);
        this.jobRunStore = new JobRunStore(this);
        this.createJobStore = new CreateJobStore(this);
        this.displayStore = new DisplayScreenStore(this);
        this.jobSettingsStore = new JobSettingsStore(this,this.displayStore);
        this.serverStatusStore = new ServerStatusStore();
    }
}
export const RootStoreInstance = new RootStore();  // required for testing purpose

export const RootStoreContext = createContext(RootStoreInstance);