import React, { useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/root.store';
import { Button, Chip, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputLabel, makeStyles, MenuItem, Select, Switch, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DataMover, DATA_MOVER_CONFIGURATION_NAME, DATA_MOVER_NAME, INFRA_NAME } from '../../../app/models/BaseSettings.enum';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import ReactDOM from 'react-dom';
import { EDROutlinedInput } from "../../../app/common/EDROutlinedInput";
import { EDRAutocomplete } from "../../../app/common/EDRAutocomplete";
import { EDRTextField } from "../../../app/common/EDRTextField";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { UpdateType } from '../../../app/stores/activityStore';
import JobAgent from '../../../app/api/jobAgent';
import JobSettingsStore from '../../../app/stores/jobSettings.store';
import { InfrastructureType } from '../../../app/models/jobProperties.model';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Create from '@material-ui/icons/Create';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

const AZURE_DATAMOVERS = [DataMover.ASR, DataMover.ASRVmwareAzure, DataMover.IaaS, DataMover.PaaS, DataMover.ZertoAzure]
const InfrastructureComponent: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { createJobStore, activityStore, jobSettingsStore, displayStore } = rootStore;
  const { dataMover, infrastructureType } = createJobStore.baseSettings;
  const InfrastructureOptions = jobSettingsStore.infrastructureList;
  const selectedInfrasturcutre = jobSettingsStore.selectedInfra;
  const isPaaS = createJobStore.baseSettings.dataMover == DataMover.PaaS || DataMover.ASR;
  const isAzure = AZURE_DATAMOVERS.indexOf(dataMover) >= 0;
  const [t] = useTranslation("createJobBase");
  const previousTenantID = jobSettingsStore.PreviousTenantID;
  const location = useLocation();
  const isEditMode = () => {
    return location.pathname.includes("edit");
  }
  const useStyles = makeStyles(() => ({
    editIcon: {
      cursor: 'pointer'
    },
    button: {
      height: '100%',
      borderRadius: '4px',
      border: 'solid 1px #c7cfdb',
      backgroundColor: '#fafafc'
    },
    advanced: {
      width: '14px',
      height: '14px',
      objectFit: 'contain',
    },
    formControl: {
      width: '100%',
      '& label': {
        background: "white",
        width: "auto",
        padding: "0 4px 0 4px",
        fontSize: '17px'
      }
    }
  }));
  const classes = useStyles();

  useEffect(() => {
    jobSettingsStore.getInfras()
  }, [createJobStore.baseSettings.infrastructureType])

  useEffect(() => {
    let initialValues;
    initialValues =
    {
      infrastructureId: createJobStore.baseSettings.infrastructureId,
      ZertoOrSRM: dataMover === DataMover.ZertoVmWare || dataMover === DataMover.SRM,
      //tenantId: createJobStore.baseSettings.sourceInfrastructure.server,
      datamover: createJobStore.baseSettings.dataMover,
      vmHosts: createJobStore.baseSettings.vra
    };

    form.setValues(initialValues);
  }, [createJobStore.baseSettings.vra, jobSettingsStore.infrastructureList]);

  useEffect(() => {
    jobSettingsStore.initializeJobBaseData();
  }, [createJobStore.baseSettings.dataMover]);

  useEffect(() => {
    activityStore.on(UpdateType.ReValidate, Validate);
    return () => {
      activityStore.off(UpdateType.ReValidate, Validate);
    }
  }, [])
  const Validate = () => {

    if (createJobStore.baseSettings.dataMover != DataMover.VeeamSQL && createJobStore.baseSettings.dataMover != DataMover.VeeamVmware && !displayStore.nextStepValid.infraValid) {
      const { selectedInfra } = jobSettingsStore;
      jobSettingsStore.authenticateVCenter().then(res => {
        toast.success(`Successfully autheticated to ${selectedInfra.infrastructureTarget}`);
      }).catch(err => {
        toast.error(`Failed to autheticate to ${selectedInfra.infrastructureTarget}`);
      });
      jobSettingsStore.authenticateVCenterSource().then(res => {
        toast.success(`Successfully autheticated to ${selectedInfra.infrastructureSource}`);
      }).catch(err => {
        toast.error(`Failed to autheticate to ${selectedInfra.infrastructureSource}`);
      });
    }

  }
  useEffect(() => {
    if (InfrastructureOptions.length === 0)
      return;

    const infra = InfrastructureOptions.find(i => i.id === createJobStore.baseSettings.infrastructureId);
    if (!infra && !isEditMode()) {
      form.setFieldValue("infrastructureId", InfrastructureOptions[0].id);
      createJobStore.updateBaseSettings({ infrastructureId: InfrastructureOptions[0].id })
      jobSettingsStore.updateSelectedInfra(InfrastructureOptions[0]);
    }
    else {
      form.setFieldValue("infrastructureId", infra != undefined && infra != null ? infra.id : 0);
      jobSettingsStore.updateSelectedInfra(infra != undefined && infra != null ? infra : null);
    }


  }, [jobSettingsStore.infrastructureList])

  useEffect(() => {
    let cancelled = false;
    const timer = setTimeout(() => {
      const { selectedInfra } = jobSettingsStore;
      if (!selectedInfra?.id) {
        return;
      }

      if(createJobStore.baseSettings.dataMover != DataMover.VeeamVmware)
      {
      jobSettingsStore.authenticateVCenter().then(res => {
        if (cancelled)
          return;
        toast.success(`Successfully autheticated to ${selectedInfra.infrastructureTarget}`);
      }).catch(err => {
        if (cancelled)
          return;
        toast.error(`Failed to autheticate to ${selectedInfra.infrastructureTarget}`);
      });
  if(!isAzure) {
    jobSettingsStore.authenticateVCenterSource().then(res => {
      if (cancelled)
        return;
      toast.success(`Successfully autheticated to ${selectedInfra.infrastructureSource}`);
    }).catch(err => {
      if (cancelled)
        return;
      toast.error(`Failed to autheticate to ${selectedInfra.infrastructureSource}`);
    });
  }
  }
    }, 500)
    return () => {

      cancelled = true;
      clearTimeout(timer);
    }

  }, [jobSettingsStore.selectedInfra?.id])

  useEffect(() => {
    if (createJobStore.PlatformChanged)
      form.resetForm();
  }, [createJobStore.PlatformChanged]);

  const validation = yup.object({
    ZertoOrSRM: yup.boolean(),
    vmHosts: yup.string().when("datamover",{ is: v => v == DataMover.VeeamVmware || v == DataMover.Netapp, 
      then: yup.string().required(), otherwise: yup.string()}),

    infrastructureId: yup.number().required().test("valid id", 'Required', function (value) {
      return value > 0;
    })
  })

  const form = useFormik({
    initialValues:
    {
      infrastructureId: 0,
      ZertoOrSRM: dataMover === DataMover.ZertoVmWare || dataMover === DataMover.SRM,
      tenantId: '',
      vmHosts: '',
      datamover: createJobStore.baseSettings.dataMover
    },
    validationSchema: validation,
    validateOnChange: true,
    onSubmit: () => { },

  })

  const buttonRedirect = (subComponent: any) => {
    switch (subComponent) {
      case "AzureAdvanced":
        {
          if (displayStore.BaseDrawerSettings.AzureDrawerOpen) {
            displayStore.updateScreenSettings({ displayBaseSettings: 'EmptyVlan' });
            displayStore.updateBaseDrawerSettings({ AzureDrawerOpen: false });
          }
          else {
            displayStore.updateScreenSettings({ displayBaseSettings: subComponent });
            displayStore.closeAllOtherBaseDrawersExcept("InfraDrawer");
          }
          break;
        }
      case "InfrastructureAdvanced":
        {
          if (displayStore.BaseDrawerSettings.InfraDrawerOpen || displayStore.BaseDrawerSettings.DataMoverDrawerOpen) {
            if (displayStore.BaseDrawerSettings.InfraDrawerOpen) {
              if (displayStore.BaseDrawerSettings.addInfrastructure) {
                displayStore.updateScreenSettings({ displayBaseSettings: subComponent });
                displayStore.updateBaseDrawerSettings({ addInfrastructure: false });
                displayStore.updateBaseDrawerSettings({ InfraDrawerOpen: true });
                displayStore.closeAllOtherBaseDrawersExcept("InfraDrawer");
              }
              else {
                displayStore.updateScreenSettings({ displayBaseSettings: 'EmptyVlan' });
                displayStore.updateBaseDrawerSettings({ InfraDrawerOpen: false });
              }
            }
            else {
              displayStore.updateScreenSettings({ displayBaseSettings: subComponent });
              displayStore.updateBaseDrawerSettings({ addInfrastructure: false });
              displayStore.updateBaseDrawerSettings({ InfraDrawerOpen: true });
              displayStore.closeAllOtherBaseDrawersExcept("InfraDrawer");
            }
          }
          else {
            displayStore.updateScreenSettings({ displayBaseSettings: subComponent });
            displayStore.updateBaseDrawerSettings({ addInfrastructure: false });
            displayStore.updateBaseDrawerSettings({ InfraDrawerOpen: true });
            displayStore.closeAllOtherBaseDrawersExcept("InfraDrawer");
          }
          break;
        }
    }
  };

  const handleAutoCompleteChange = (event: React.ChangeEvent, value: any) => {
    if (event != null) {
      let fieldName = event.target['id'].includes('-') ? event.target['id'].split('-')[0] : event.target['id'];
      switch (fieldName) {
      }
    }
  }

  useEffect(() => {
    displayStore.updateNextStepValid({ infraValid: form.isValid });
  }, [form.isValid, form.isValidating])


  const loadInfraTenantID = () => {
    return <FormControl className={classes.formControl} variant='outlined' style={{ backgroundColor: '#ffffff', paddingTop: '1%', width: '97%' }}>
       <InputLabel style={{ transform: 'translate(14px, 2px) scale(0.75)' }}>{displayStore.screenSettings.InfraApplyProcessing ? loadCircularProgress() : t('TenantID')}</InputLabel>
      <Select
        name="infrastructureId"
        labelId="infrastructure"
        disabled={true}
        value={form.values.infrastructureId}
        onBlur={form.handleBlur}
        error={form.touched?.infrastructureId && Boolean(form.errors?.infrastructureId)}
        onChange={handleInfrastructureChange}
          >
        {InfrastructureOptions.map((infra) => (
          <MenuItem key={infra.infrastructureTarget} value={infra.id}>
            {`${infra.infrastructureTarget}`}
          </MenuItem>))}
      </Select>
    </FormControl >
  }

  const handleInfrastructureChange = (event: any) => {
    if (event != null) {
      if (displayStore.BaseDrawerSettings.InfraDrawerOpen) {
        displayStore.updateScreenSettings({ displayBaseSettings: 'EmptyVlan' });
        displayStore.updateBaseDrawerSettings({ InfraDrawerOpen: false });
      }
      form.setFieldValue("infrastructureId", event.target.value);
      let infra = jobSettingsStore.infrastructureList.find(l => l.id === event.target.value);
      jobSettingsStore.updateSelectedInfra(infra);
      // jobSettingsStore.authenticateVCenter();
      // jobSettingsStore.authenticateVCenterSource();
      jobSettingsStore.listNetworkTupples();
      rootStore.createJobStore.updateBaseSettings({ infrastructureId: infra && infra != null && infra != undefined ? infra.id : 0 });
    }
  }

  const loadCircularProgress = () => {
    return <div style={{ width: '100%', display: 'flex' }}>
      {t('InfraInProgress')}
      <CircularProgress id="infraLoading" size={18} style={{ color: '#2892D7', marginLeft: '10px', marginTop: '-3px' }} />
    </div>
  }

  const handleAddInfra = () => {
    displayStore.updateScreenSettings({ displayBaseSettings: "InfrastructureAdvanced" });
    displayStore.updateBaseDrawerSettings({ addInfrastructure: true });
    displayStore.updateBaseDrawerSettings({ InfraDrawerOpen: true });
    displayStore.closeAllOtherBaseDrawersExcept("InfraDrawer");
  }

  const loadInfraRow = () => {
    return (
      <React.Fragment>
        <Grid container item direction='row' spacing={2}>
          {/* {isPaaS ?loadInfraTenantID():null} */}
          <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton disabled={true} onClick={() => { handleAddInfra() }}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Grid>
          <Grid container item xs={11}>
            <Grid item xs={11}>
              {isAzure ? loadInfraTenantID() : <FormControl className={classes.formControl} variant='outlined' style={{ backgroundColor: '#ffffff', paddingTop: '1%', width: '97%' }}>
                <InputLabel style={{ transform: 'translate(14px, 2px) scale(0.75)' }}>{displayStore.screenSettings.InfraApplyProcessing ? loadCircularProgress() : t('infrastructure')}</InputLabel>
                <Select
                  name="infrastructureId"
                  required={true}
                  labelId="infrastructure"
                  disabled={true}
                  value={form.values.infrastructureId}
                  onBlur={form.handleBlur}
                  error={form.touched?.infrastructureId && Boolean(form.errors?.infrastructureId)}
                  onChange={handleInfrastructureChange}>
                  {InfrastructureOptions.map((infra) => (
                    <MenuItem key={infra.infrastructureTarget} value={infra.id}>
                      {`${infra.infrastructureSource} --> ${infra.infrastructureTarget}`}
                    </MenuItem>))}
                </Select>
                <em style={{ fontSize: 12, fontStyle: "italic", color: "red" }}>{form.touched?.infrastructureId && form.errors.infrastructureId}</em>
              </FormControl>}
            </Grid>

            <Grid item xs={1} style={{ paddingTop: '5px' }}>
              <Button disabled={true} className={classes.button} onClick={() => { buttonRedirect('InfrastructureAdvanced') }}>
                <Create className={classes.editIcon} ></Create>
              </Button>
            </Grid>

          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <Grid container spacing={2}>
      {loadInfraRow()}
    </Grid>
  );
};

export default observer(InfrastructureComponent)