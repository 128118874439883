import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/root.store';
import { Button, Card, Grid, makeStyles, Switch, Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { WorkflowStepNames } from '../../../app/models/BaseSettings.enum';
import { useTranslation } from 'react-i18next';
import PrePostScriptsComponent from './PrePostScripts.component';
import HelpIcon from '@material-ui/icons/Help';
import { LicenseType, NetworkOption, NetworkType } from '../../../app/models/jobProperties.model';
import ConfirmationDialog from '../../../app/common/confirmationDialog.component';
export type SelectedStep =  {
    [key in WorkflowStepNames]: boolean
  }

const BaseSettingStepsComponent : React.FC = () => {
    const rootStore = useContext(RootStoreContext); 
    const {displayStore,jobSettingsStore} = rootStore;
    const {failoverScriptSetting,cleanupScriptSetting,TestScriptSetting}= rootStore.createJobStore.baseSettings.scriptsSettings;
    const [openPrePostScriptsDialog, setopenPrePostScriptsDialog] = useState(false);
    const [ImageColor, setImageColor] = useState(false);
    const isTrial = jobSettingsStore.selectedLicense?.licenseType == LicenseType.FirstTrial;

    const [stepState, setstepState] = useState<SelectedStep>({
        preTestTask: true,
        failover: true,
        test: true,
        cleanup: true,
    });
    
    useEffect(() => {
        setTimeout(() => populateFields(), 1000);
      }, []); 
    
    const populateFields = () => {
        if (rootStore.createJobStore.baseSettings.workflowSetting!=null)
        {
            let newStepState = {
            ['preTestTask']: rootStore.createJobStore.baseSettings.workflowSetting.preTest,
            ['failover']: rootStore.createJobStore.baseSettings.workflowSetting.failover,
            ['test']: rootStore.createJobStore.baseSettings.workflowSetting.serverTest,
            ['snapshot']: rootStore.createJobStore.baseSettings.workflowSetting.snapshot,
            ['cleanup']: rootStore.createJobStore.baseSettings.workflowSetting.cleanup
            };
            setstepState(newStepState);
        }
    }
    
    const onStepSelectionChange = (stepType: string) => {
        let newStepState = {...stepState, [stepType]: !stepState[stepType]};
        setstepState(newStepState);
        switch(stepType)
        {
            case "preTestTask":
                rootStore.createJobStore.updateBaseSettings({workflowSetting: {...rootStore.createJobStore.baseSettings.workflowSetting, preTest : !stepState[stepType]}});
                break;
            case "failover":
                rootStore.createJobStore.updateBaseSettings({workflowSetting: {...rootStore.createJobStore.baseSettings.workflowSetting, failover : !stepState[stepType]}});
                break;
            case "test":
                rootStore.createJobStore.updateBaseSettings({workflowSetting: {...rootStore.createJobStore.baseSettings.workflowSetting, serverTest : !stepState[stepType]}});
                break;
            case "snapshot":
                rootStore.createJobStore.updateBaseSettings({workflowSetting: {...rootStore.createJobStore.baseSettings.workflowSetting, snapshot : !stepState[stepType]}});
                break;
            case "cleanup":
                rootStore.createJobStore.updateBaseSettings({workflowSetting: {...rootStore.createJobStore.baseSettings.workflowSetting, cleanup : !stepState[stepType]}});
                break;
        }
    }

    const openpopUp= ()=>{
        setopenPrePostScriptsDialog(true);
    };

    const loadPrePhase= (preName:string,preTTL:number) => {
        return(
            <React.Fragment>
                <div className={classes.divWithoutCircle}>
                    <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                        <span className={classes.verticalLine}></span>
        <span className={classes.textHeading} style={{color:'#2892d7',fontSize:'12px'}}>{preName}{' ('+preTTL+')'}</span>
                    </div>
                </div>
            </React.Fragment>
            );
    }

    const loadPostPhase= (postName:string,postTTL:number) => {
        return(
        <React.Fragment>
        <div className={classes.divWithoutCircle}>
            <div style={{display: 'flex', width: '100%', alignItems: 'center',marginTop:'-12px'}}>
                <span className={classes.verticalLine}></span>
                <span className={classes.textHeading} style={{color:'#f08700',fontSize:'12px'}}>{postName}{' ('+postTTL+')'}</span>
            </div>
        </div>
        </React.Fragment>
        );
    }

    const useStyles = makeStyles({
        card: {
            padding: 16,
            // width: '100%',
            height: '100%',
            marginTop:'-14px',
            marginLeft:'-15px'
        },
        button:{
            backgroundColor: 'transparent',
            backgroundRepeat:'no-repeat',
            cursor:'pointer',
            overflow: 'hidden',
            outline:'none',
        },
        advanced:{
            width: '14px',
            height: '14px',
            objectFit: 'contain'
        },
        textHeading:{
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.14',
            letterSpacing: 'normal',
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            marginLeft: '20px',
            color: '#4d5f75'
        },
        verticalLine:{
            width: '19px',
            height: '0',
            marginLeft: '3px',
            border: '0.5px solid #2892d7'
        },
        divWithoutCircle:{
            height: '7px',
            width: 'auto',
            position: 'relative',
            alignContent: 'center',
            alignItems: 'center',
            "&:before": {
                width: '0',
                height: '100%',
                position: 'absolute',
                content: "' '",
                display: 'inline-block',
                borderLeft: '1px dashed #2892d7',
                left:'3px',
                bottom:'30px'
            },
            "&:after": {
                width: '10px',
                height: '10px',
                display: 'block',
                content:  "' '",
                left: '-1px',
                top: 'calc(50% - 10px)',
                position: 'absolute'
            }
        },
        div :{
            height: '45px',
            width: 'auto',
            position: 'relative',
            alignContent: 'center',
            alignItems: 'center',
            "&:before": {
                width: '0',
                height: '100%',
                position: 'absolute',
                content: "' '",
                display: 'inline-block',
                borderLeft: '1px dashed #2892d7',
                left:'3px',
                bottom:'30px'
            },
            "&:after": {
                width: '10px',
                height: '10px',
                display: 'block',
                content:  "' '",
                background: '#2892d7',
                borderRadius: '10px',
                left: '-1px',
                top: 'calc(50% - 10px)',
                position: 'absolute'
            },
            "&:first-child:before ": {
                bottom: '-16px'
              },
            "&:last:after": {
                top: 'calc(100% - 5px)'
              }
          },
        divLast :{
            height: '45px',
            width: 'auto',
            position: 'relative',
            alignContent: 'center',
            alignItems: 'center',
            "&:before": {
                width: '0',
                height: '68px',
                position: 'absolute',
                content: "' '",
                display: 'inline-block',
                borderLeft: '1px dashed #2892d7',
                left:'3px',
                bottom:'6px'
            },
            "&:after": {
                width: '10px',
                height: '10px',
                display: 'block',
                content:  "' '",
                background: '#2892d7',
                borderRadius: '10px',
                left: '-1px',
                top: 'calc(50% - 10px)',
                position: 'absolute'
            },
            "&:first-child:before ": {
                bottom: '-16px'
              }
          },
          errorText:{
            color:'red',
            marginTop:'10px',
            fontSize: '0.75rem',
            textAlign: 'left',
            fontFamily: "Roboto",
            fontWeight: 400,
            lineHeight: 1.66,
            letterSpacing: '0.03333em'
          },
          helpIcon:
          {
            color: '#1976d2',
            fontSize: "large",
            marginTop:'8px'
          },
          ScriptButton:{
                borderRadius: '4px',
                border: 'solid 1px #c7cfdb',
                backgroundColor: '#fafafc',
                minWidth: '40px',
                minHeight: '35px',
                marginRight: '8px',
          }
        });   
    const classes = useStyles(); 
    const [t] = useTranslation("createJobBase");
    
    useEffect(() => {
        displayStore.updateNextStepValid({testsValid:(!stepState.failover && !stepState.cleanup && !stepState.test)?false:true});
      },[stepState])
    
    return (
        
        <Card elevation={1} classes={{root: clsx([classes.card])}}>  
        
        <Grid item container direction="row">
          <Grid item xs={6} style={{display:'flex',flexDirection:'row'}}>
            <Typography className={classes.textHeading} style={{ marginLeft:'-1px',color: '#1f396d', fontWeight:500}}>{t('testworkflow')}</Typography>
            <Tooltip title={t("stepsInfo")} arrow>
                <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />    
            </Tooltip>
          </Grid>
          <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end'}}>
          {rootStore.createJobStore.networkSetting.NetworkType == NetworkOption.None ?
          <Tooltip title={isTrial ? t('trialModeDisabled') : t('disabledTestNone')}>
            <span>
          <Button className={classes.ScriptButton} disabled={true} onClick={()=>openpopUp()}>
                <img style={{cursor:'pointer'}} className={classes.advanced} onMouseEnter={()=> setImageColor(true)} onMouseLeave={()=> setImageColor(false)} src={rootStore.createJobStore.networkSetting.NetworkType == NetworkOption.None ?'/assets/images/createjob/advancedNetworkDisabled.svg':(ImageColor ? '/assets/images/createjob/advancedNetworkBlue.svg':'/assets/images/createjob/advancedNetwork.svg')}></img>
          </Button>
          </span>
          </Tooltip>
          :  <Tooltip title={isTrial ? t('trialModeDisabled'): ''}> 
          <Button className={classes.ScriptButton} disabled={true} onClick={()=>openpopUp()}>
                <img style={{cursor:'pointer'}} className={classes.advanced} onMouseEnter={()=> setImageColor(true)} onMouseLeave={()=> setImageColor(false)} src={ImageColor ? '/assets/images/createjob/advancedNetworkBlue.svg':'/assets/images/createjob/advancedNetwork.svg'}></img>
            </Button>
            </Tooltip>}
          </Grid>
        </Grid>
        
        <Grid container direction="column" style={{marginTop:'25px'}}>
        <Tooltip title={isTrial ? t('trialModeDisabled'): ''}> 
        <div className={classes.div}>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
            <span className={classes.textHeading} >{t('datacollection')}</span>
                <span><Switch data-test={"collectionSwitch_" + stepState.preTestTask + "_" + (isTrial ? "disabled" : "enabled")} checked={stepState.preTestTask} disabled={true} onChange={() => onStepSelectionChange('preTestTask')} color='primary' /></span>
        </div>
        </div>
        </Tooltip>

        {failoverScriptSetting.prePath!='' ?loadPrePhase(t('prefailover'),failoverScriptSetting.preTTL):null}
        <Tooltip title={isTrial ? t('trialModeDisabled'): ''}> 
        <div className={classes.div}> 
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                <span className={classes.textHeading}>{t('failover')}</span>
                <span><Switch data-test={"failOverSwitch_" + stepState.failover + "_" + (isTrial ? "disabled" : "enabled")} checked={stepState.failover} disabled={true} onChange={() => onStepSelectionChange('failover')} color='primary' /></span>
            </div>
        </div>
        </Tooltip>
        {failoverScriptSetting.postPath!='' ?loadPostPhase(t('postfailover'),failoverScriptSetting.postTTL):null}
        
        {TestScriptSetting.prePath!='' ?loadPrePhase(t('pretest'),TestScriptSetting.preTTL):null}
        <Tooltip title={isTrial ? t('trialModeDisabled'): ''}> 
        <div className={classes.div}>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                <span className={classes.textHeading}>{t('test')}</span>
                <span><Switch data-test={"testSwitch_" + stepState.test + "_" + (isTrial ? "disabled" : "enabled")} checked={stepState.test} disabled={true} onChange={() => onStepSelectionChange('test')} color='primary' /></span>
            </div>
        </div>
        </Tooltip>
        {TestScriptSetting.postPath!='' ?loadPostPhase(t('posttest'),TestScriptSetting.postTTL):null}

        {cleanupScriptSetting.prePath!='' ?loadPrePhase(t('precleanup'),cleanupScriptSetting.preTTL):null}
        <Tooltip title={isTrial ? t('trialModeDisabled'): ''}> 
        <div className={cleanupScriptSetting.postPath!='' ? classes.divLast :classes.div}>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                <span className={classes.textHeading}>{t('cleanup')}</span>
                <span><Switch data-test={"cleanupSwitch_" + stepState.cleanup + "_" + (isTrial ? "disabled" : "enabled")} disabled={true} checked={stepState.cleanup} onChange={() => onStepSelectionChange('cleanup')} color='primary' /></span>
            </div>
        </div>
        </Tooltip>
        {cleanupScriptSetting.postPath!='' ?loadPostPhase(t('postcleanup'),cleanupScriptSetting.postTTL):null}
        <label className={classes.errorText}>{(!stepState.failover && !stepState.cleanup && !stepState.test)?t('TestValidationText'):null}</label>
        </Grid>
        <PrePostScriptsComponent open={openPrePostScriptsDialog} onClose={() => setopenPrePostScriptsDialog(false)} />
        </Card>
);
}

export default observer(BaseSettingStepsComponent);