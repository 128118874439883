import { Grid, Typography, Card, makeStyles, useTheme, Button, InputLabel, Tooltip, Chip, withStyles, CircularProgress } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import{ loadDataMoverIcon } from './Preview/BaseSettingPreview.component';
import ReportPreview from './Preview/ReportPreview.component';
import ScheduleJobPreview from './Preview/ScheduleJobPreview.component';
import { observer } from 'mobx-react-lite';
import { DataMover, DATA_MOVER_NAME, JobRunType } from '../../app/models/BaseSettings.enum';
import { RootStoreContext } from '../../app/stores/root.store';
import { UpdateType } from '../../app/stores/activityStore';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import FullSummaryDialog from './Preview/FullSummary.component';
import { runInAction } from 'mobx';
import { toast } from 'react-toastify';
import {history} from '../../app/common/util/history';
import { convertMinToTime } from '../../app/common/util/util';
import DescriptionIcon from '@material-ui/icons/Description';
import jobAgent from '../../app/api/jobAgent';
import { JobMenuAction } from '../../app/common/jobActionMenu.component';
import { JobAction } from '../../app/models/jobactions/jobaction.model';
import { AssetState } from '../../app/models/jobRunWithAssetInfo.model';
import AssetAgent from '../../app/api/assetsAgent';
import { ScheduleStaus } from '../../app/models/jobProperties.model';
//import { Asset } from '../../app/models//asset.model';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from "formik";
import { AssetDTO } from '../../app/models/createjob/createJob.model';

 const MailChip = withStyles({
    root: {
        height: 'auto',
        margin: 1
    }
})(Chip)

const useStyles = makeStyles(theme =>({
   InnerCard: {
    marginRight:'9%',
    width: '680px',
    height: 'auto',
    borderRadius: '4px',
    boxShadow: '0 8px 12px 0 rgba(31, 57, 109, 0.05)',
    overflow: 'scroll'
   },
   runButton: {
        borderRadius: 15,
        color: '#2892d7',
        backgroundColor:'white',
        border: 'solid 1px #2892d7',
        marginLeft:10,
        textTransform: 'none'
    },
    saveButton: {
        borderRadius: 15,
        color: 'white',
        textTransform: 'none'
    },
   heading:{
    fontFamily: 'Roboto',
    fontSize: '22px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.14,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#1f396d'
   },
   text: {
    fontFamily:'Roboto',
    fontWeight:500,
    fontSize:'13px',
    fontStretch:'normal',
    fontStyle: 'normal',
    lineHeight:'1.14',
    letterSpacing: '0.13px',
    textAlign:'left',
    color: ' #1f396d'
},
textValues: {
    fontFamily:'Roboto',
    fontSize:'13px',
    fontStretch:'normal',
    fontStyle: 'normal',
    lineHeight:'1.14',
    letterSpacing: '0.13px',
    textAlign:'left',
    color: '#4d5f75'
},
   button: {
    height: '32px',
    borderRadius: '26px',
    backgroundColor: '#2892d7',
    textTransform: 'none'
   },
   cell: {
       display: 'flex',
       alignItems: 'center',
       padding: 16,
       border: 'solid 1px #eaeef4'
   },
   greyCell: {
       background: '#fafafc'
   }
}));
const JobPreviewComponent: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const {displayStore,createJobStore, activityStore,jobRunStore,userStore,jobResultStore, jobSettingsStore} = rootStore;
    const {jobName,dataMover,rto,scriptsSettings}= rootStore.createJobStore.baseSettings;
    const { emailSettings, updateSettings,monitoringSettings } = rootStore.settingStore;
    const isPaaS = rootStore.createJobStore.baseSettings.dataMover== DataMover.PaaS;
    const {selectedDevices}= rootStore.createJobStore;
    const theme = useTheme();
    const classes = useStyles();
    const [t] = useTranslation("CreateJobPreview");
    const [s] = useTranslation("settings");
    const {t: errorTranslations} = useTranslation('errors');
    const [openSummaryDialog, setopenSummaryDialog] = useState(false);
    const [reportValid, setReportValid] = useState(true);
    const [scheduleValid, setScheduleValid] = useState(true);
    const [jobWasCreated,setJobWasCreated] = useState(false);
    const [newJobId,setNewJobId] = useState(-1);
    const [createOnlyOnDB, setCreateOnlyOnDB] = useState(false)
    const isEdit= window.location.pathname.includes('editJob');
    const [isEDRAvailable, setEDRAvailable] = useState(false);
    const [isEDRNotOperational, setEDRNotOperational] = useState(false);
    const [NotOperationalReasons, setNotOperationalReasons] = useState([]);
    const [assetNotOperational, setAssetNotOperational] = useState("");
    const [emailList, setEmailList] = useState([]);
    const [schdState, setSchdState]=useState(ScheduleStaus.NA);
    const location = useLocation();
    const handleFullSummary =()=> {
        setopenSummaryDialog(true);
    }
    let validEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const validation = yup.object({
        emailTo: yup.string().when(["sendEmail", "settingsEmail"], {
            is: (sendEmail, settingsEmail) => {  
                if(sendEmail && settingsEmail.length ==0)
                    return true;
                if(!sendEmail || settingsEmail.length)
                    return false;
                return false;
            },
            then: yup.string().required().test('emailTo',"emailtoIsInvalid",
            function(value){
            let finalResult: boolean = true;
            if (value && value.length>0){
                let addressesArr = value.split(";");
                addressesArr.forEach(element => {
                if (!validEmail.test(element)){
                    finalResult = false;
                }
                })
            }
            return finalResult;
            }),
            otherwise: yup.string()
        }),
      });
      
      const form = useFormik({
        initialValues: { emailTo: "", sendEmail: false, settingsEmail: []},
        validateOnChange: true,
        validateOnMount: false,
        validationSchema: validation,
        onSubmit:(val) => {}
      });

      

    const checkJobRunning=()=>{
        let jobId = parseInt((history.location.pathname.split("/")[2]));
        return jobRunStore.isJobRunning(jobId);
    };
    useEffect(() => {
        AssetAgent.CheckAsset(jobSettingsStore.selectedEDR.id).then(res => {
            let asset = res.data;
            //setEDRAvailable(asset.assetState != AssetState.Idle ? false : true);
            setAssetNotOperational(asset.assetName);
            setEDRNotOperational(asset.assetState === AssetState.NonOperational ? true:false);
            setNotOperationalReasons(asset.nonOperationalReasons != null && asset.nonOperationalReasons != '' ? asset.nonOperationalReasons.split(';'): []);
        })
    }, [])

    useEffect(() => {
        jobAgent.CreateJobActions.ListAllEmails().then(res => {
            if(res && res != null && res.length > 0)
                setEmailList([...res]);
                form.setFieldValue('settingsEmail', [...res]);
        });
    }, []);

    const handleFinish = () => {
        setCreateOnlyOnDB(false);
        setJobWasCreated(true);
        toast.success('Job ' + jobName + ' Edited Successfully') ;
        /*runInAction(() => { 
            jobAgent.CreateJobActions.ListPlans(null,'resetCache').then().catch(err => {}); //Reset plans chach on save.
            displayStore.setDisableSectionSave(true);
            createJobStore.createJob(false).then(res => {
           
            let newJobId = Number(res.split('-')[1]);
            let schdStateRes = res.split('-')[2];
            setSchdState(schdStateRes && schdStateRes === "schdadded" ? ScheduleStaus.Suceess : (schdStateRes === "schdfailed" ? ScheduleStaus.Failed : ScheduleStaus.NA))
            setNewJobId(newJobId);
            loadSaveButton();
            loadJobRunButton();
            displayStore.setAfterCreate(true)
            displayStore.setDisablePreview(true);
            if(res.includes("success"))
            {
                setCreateOnlyOnDB(false);
                isEdit ? toast.success('Job ' + jobName + ' Edited Successfully') : toast.success('Job ' + jobName + ' Created Successfully');

            } 
            else if(res.includes("CreatedDBFailedRunner"))
            {
                setCreateOnlyOnDB(true);
                isEdit ? toast.warn('Job ' + jobName + ' Edited Successfully only on DB') : toast.warn('Job ' + jobName + ' Created Successfully only on DB');
            }
            activityStore.sendMessage(UpdateType.JobAdded);
            let values = { Settings: [] };
            emailSettings.forEach(key=>{
              values.Settings.push(key);
            })
             if (form.dirty && form.isValid){
                updateSettings(values)
              } 
            createJobStore.setFinishDraft();
            setJobWasCreated(true);
            displayStore.setDisablePreview(true);
            displayStore.setDisableSectionSave(false);
            history.push({...location, search: '?jobSaved=true'});
            }).catch(err => {
                toast.error(<React.Fragment>
                    <h3>{t('Error')}</h3>
                    {<div style={{marginLeft: 10}}><i>{isEdit ? 'failed to edit job. Error: '+err.data.errors:'failed to create job. Error: '+err.data.errors}</i></div>}
                </React.Fragment>, {autoClose: 30000 })
                 displayStore.setDisableSectionSave(false);
                 displayStore.setDisablePreview(false);
            });
        })*/
      }
      const handleEdit = () => {
            runInAction(()=>{
                jobAgent.CreateJobActions.ListPlans(null,'resetCache').then().catch(err => {}); //Reset plans chach on save.
                jobSettingsStore.setIsAutheticatedToFalse();
                jobSettingsStore.setIsDMAutheticatedToFalse();
                displayStore.setDisableSectionSave(false);
                displayStore.resetStep(0);
                displayStore.setAfterCreate(false);
                displayStore.setRunJobProcessing(false);
                displayStore.resetDrawers();
                createJobStore.getJob(createJobStore.baseSettings.id, true);
            })
      }

    
      const handleRunJob = ()=> {
        history.push(`/jobs?jobId=${createJobStore.baseSettings.id}&showReport=true&jobSaved=true`);
        /*jobAgent.CreateJobActions.ListPlans(null,'resetCache').then().catch(err => {}); //Reset plans chach on save.
        jobSettingsStore.setIsAutheticatedToFalse();
        jobSettingsStore.setIsDMAutheticatedToFalse();
        displayStore.setRunJobProcessing(true);
        displayStore.setAfterCreate(false);
        runInAction(() => {
        createJobStore.createJob(true).then(res => {
            let JobId = isEdit ? Number(createJobStore.baseSettings.jobId): Number(res.split('-')[1]);
            isEdit ? toast.success('Job ' + jobName + ' Edited Successfully') : toast.success('Job ' + jobName +' Created Successfully');
            activityStore.sendMessage(UpdateType.JobAdded);
            displayStore.setRunJobProcessing(false);
            displayStore.setSaveRunClicked(true);
            createJobStore.setFinishDraft();
            let values = { Settings: [] };
            emailSettings.forEach(key=>{
              values.Settings.push(key);
            })
            history.push(`/jobs?jobId=${JobId}&showReport=true&jobSaved=true`);
        }).catch(err => {
            displayStore.setRunJobProcessing(false);
            toast.error(<React.Fragment>
                <h3>{t('Error')}</h3>
                {<div style={{marginLeft: 10}}><i>{isEdit ? 'failed to edit job and run. Error: '+err.data.errors:'failed to create job and run. Error: '+ err.data.errors}</i></div>}
              </React.Fragment>, {autoClose: 30000 })
        })
    })*/
    }

    const handleRunJobOnly = () => {
        history.push(`/jobs?jobId=${newJobId}&showReport=true&jobSaved=true`);
        /*jobAgent.CreateJobActions.ListPlans(null,'resetCache').then().catch(err => {}); //Reset plans chach on save.
        jobSettingsStore.setIsAutheticatedToFalse();
        jobSettingsStore.setIsDMAutheticatedToFalse();
        displayStore.setAfterCreate(false);
        displayStore.setDisableSectionSave(true);
        displayStore.setDisablePreview(true);
        jobRunStore.setJobRunType(JobRunType.All);
        jobAgent.JobActions.run(new JobAction({
            jobId: newJobId,
            jobName: jobName,
            action: JobMenuAction.run,
            jobRunType:JobRunType.All,
            actionDate: new Date(),
            remoteIP: "",
            userId: userStore.user.username
          })).then(res => {
            if(res.assetState === AssetState.OperationalWarning)
            {
              toast.warn(<React.Fragment>
                <h3>{errorTranslations('warning')}</h3>
                {res.nonOperationalReasons.map(err => <div style={{marginLeft: 10}}><i>- {errorTranslations(err)}</i></div>)}
              </React.Fragment>, {autoClose: 30000 })          
            }
            jobRunStore.updateJobRunState(res);
            //displayStore.setDisableSectionSave(false);
            let jobId = isEdit ? createJobStore.baseSettings.id : newJobId;
            displayStore.setDisableSectionSave(false);
            history.push(`/jobs?jobId=${jobId}&showReport=true&jobSaved=true`);
          }).catch(err => { 
            jobRunStore.removeJobRun(newJobId);
           jobResultStore.updateJobRunState(newJobId, false);
            if(err.status == 409) {
              toast.error(<React.Fragment>
                <h3>{errorTranslations('Error')}</h3>
                {err.data.errors.map(err => <div style={{marginLeft: 10}}><i>- {errorTranslations(err)}</i></div>)}
              </React.Fragment>, {autoClose: 30000 })
            } else {
              toast.error(err.data.errors, {autoClose: 30000});
            }
            displayStore.setDisableSectionSave(false);
          });
          */
    }

    const getOperationalReasons =()=>{
        let descReason=[];
        if(NotOperationalReasons.length != 0 && NotOperationalReasons != null)
        {
            NotOperationalReasons.map(reason=>{
                if(reason =="LowMemory")
                {
                    descReason.push(errorTranslations("LowMemoryPart1") + assetNotOperational + errorTranslations("LowMemoryPart2") + parseInt(monitoringSettings["LowMemoryThresholdInMB"] !=undefined ? monitoringSettings["LowMemoryThresholdInMB"].settingValue: '500') + errorTranslations("LowMemoryPart3"));
                }
                else
                    descReason.push(errorTranslations(reason));
            });
            return descReason.join(",");
        }
    }

    const loadCircularProgress =()=>{
        if(displayStore._RunJobProcessing)
        return <div style={{width:'100%',display:'flex'}}>
        {t("saveRun")}
        <CircularProgress id="run_job_process" size={18} style={{color: '#2892D7', marginLeft: '10px',marginTop: '1%'}}/>
        </div>
        else
        return t("saveRun");
    }

    const loadCircularProgressSave =()=>{
        if(displayStore._disableSectionSave)
        return <div style={{width:'100%',display:'flex'}}>
        {t("save")}
        <CircularProgress id="run_job_process" size={18} style={{color: '#2892D7', marginLeft: '10px',marginTop: '1%'}}/>
        </div>
        else
        return t("save");
    }

    const loadJobRunButton= ()=> {
        let runningState = checkJobRunning();
        
        let notOperational = isEDRNotOperational;
        if(runningState || notOperational)
            return(<Tooltip title={runningState ? t('saveRunDisabled') : getOperationalReasons()} arrow>
             <span>
            <Button className={classes.runButton} variant="contained" onClick={jobWasCreated ? handleRunJobOnly : handleRunJob}>{jobWasCreated ? t("run") : loadCircularProgress()}</Button>
            </span>
            </Tooltip>)
        else return(<Button className={classes.runButton} variant="contained" onClick={jobWasCreated ? handleRunJobOnly : handleRunJob}>{jobWasCreated ? t("run") : loadCircularProgress()}</Button>);
    }

    const loadSaveButton= ()=>{
        return(<Button className={classes.saveButton} disabled={!scheduleValid || !reportValid || displayStore._RunJobProcessing || displayStore._disableSectionSave} variant="contained" color="primary" onClick={(jobWasCreated === true ? handleEdit : handleFinish)}>{jobWasCreated ? t("edit") : loadCircularProgressSave()}</Button>);
    }

    const loadSendReport =()=>{
        return(
            <Grid item container direction="row">
                    <Grid item xs={4} className={clsx([classes.cell, classes.greyCell])}>
                        <Typography className={classes.text}>{t('sendReport')}</Typography>
                    </Grid>
                  <Tooltip title={rootStore.createJobStore.jobReportSetting.emailTo === '' ? t('emptyEmailList'):rootStore.createJobStore.jobReportSetting.emailTo}>
                  <Grid item xs={8} className={classes.cell} style={{display: 'flex', flexWrap: 'wrap',height:'100%'}}>
                        {emailList.map(e => <MailChip label={e}></MailChip>)}
                    </Grid>
                  </Tooltip>
            </Grid>
        )
    }

    const NumberOfScriptsFilled =()=>{
        let count= 0;
        if(scriptsSettings.TestScriptSetting.prePath !="") 
            count+=1;
        if(scriptsSettings.TestScriptSetting.postPath !="") 
            count+=1;
        if(scriptsSettings.failoverScriptSetting.prePath !="") 
            count+=1;
        if(scriptsSettings.failoverScriptSetting.postPath !="") 
            count+=1;
        if(scriptsSettings.cleanupScriptSetting.prePath !="") 
            count+=1;
        if(scriptsSettings.cleanupScriptSetting.postPath !="") 
            count+=1;
        return count;
    }

    const NumberOfVPGs =()=> {
        const uniqueVPGs = [];
        selectedDevices.map(item => {                  
            var findItem = uniqueVPGs.find(x => x.planName === item.planName);
            if (!findItem)
                uniqueVPGs.push(item);
        });
        return uniqueVPGs.length;
    }

    const NumberOfTotalTests= ()=>{
        let totalTests= 0;
        createJobStore.selectedDevices.map(selectedDev=>{
            selectedDev.tests.map(test=>{
                if(test.selected === true)
                totalTests= totalTests + 1 ;
             })
         })
        return totalTests;
    }

    return (
        <div style={{backgroundColor: '#f4f5f9', display:'flex', justifyContent: 'center' ,alignItems: 'center', width:'100%', height:'100%', overflowY: 'scroll'}}>
       <Card className={classes.InnerCard} style={{backgroundColor:'white'}}>
           <Grid item container direction="row" style={{padding: 45}}>

            <Grid item container direction="row" justify="space-between">
                <Grid item xs={8}>
                    <Tooltip title={jobName}>
                            <Typography variant='h6'  style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',width:'100%'}} className={classes.heading}>{t('isReady')} </Typography>
                        </Tooltip>
                    </Grid>
                <Grid item xs={3} style={{paddingLeft:'1%'}}>
                    <Button className={classes.button} variant="contained" color="primary" onClick={handleFullSummary}><DescriptionIcon style={{marginRight:'0px'}}/>{t("fullSummary")}</Button>  
                </Grid>
            </Grid>

            <Grid item container direction="column" style={{paddingTop:16}}>
                
                <Grid item container direction="row">
                    <Grid item xs={4} className={clsx([classes.cell, classes.greyCell])} >
                        <Typography className={classes.text}>{t('customeScripts')}</Typography>
                    </Grid>
                    <Grid item xs={8}  className={classes.cell}>
                        <InputLabel className={classes.textValues}>{NumberOfScriptsFilled()+ t('scripts')}</InputLabel>
                    </Grid>
                </Grid>
                
                <Grid item container direction="row" >
                    <Grid item xs={4} className={clsx([classes.cell, classes.greyCell])}>
                        <Typography className={classes.text}>{t('failover')}</Typography>
                    </Grid>
                    <Grid item xs={8} className={classes.cell}>
                        <img src={loadDataMoverIcon(dataMover)}></img>
                        <InputLabel className={classes.textValues} style={{marginLeft:8}}>{DATA_MOVER_NAME[dataMover]}</InputLabel>
                        <InputLabel className={classes.textValues} style={{whiteSpace: 'nowrap',marginLeft:8}}>{NumberOfVPGs()}{isPaaS ?t('resourceGroups'):(NumberOfVPGs() === 1 ? t('recoveryPlan'):t('recoveryPlans'))}</InputLabel>
                    </Grid>
                </Grid>
                
                <Grid item container direction="row">
                    <Grid item xs={4} className={clsx([classes.cell, classes.greyCell])}>
                        <Typography className={classes.text}>{t('devicesTests')}</Typography>
                    </Grid>
                    <Grid item xs={8} className={classes.cell}>
                        <InputLabel className={classes.textValues}>{NumberOfTotalTests() +t("runningOn")+ selectedDevices.length + t("devices")}</InputLabel>
                    </Grid>
                </Grid>
                {loadSendReport()}
                <Grid item container direction="row">
                    <Grid item xs={4} className={clsx([classes.cell, classes.greyCell])} style={{background:'#8bc53f'}}>
                        <Typography className={classes.text} style={{color:'white'}}>{t('recoveryTime')}</Typography>
                    </Grid>
                    <Grid item xs={8} className={classes.cell}>
                        <InputLabel className={classes.textValues}>{convertMinToTime(rto)+t("hr")}</InputLabel>
                    </Grid>
                </Grid>
            </Grid>

            <ReportPreview noChips= {false} onChange = {(val)=>form.setValues({emailTo: val.emailTo.join(';'), sendEmail: val.sendEmail, settingsEmail: emailList})} />
            <ScheduleJobPreview onChange = {(val)=>setScheduleValid(val)}/>
            <Grid item container direction="row" style={{marginTop:50}} justify="center">
                {loadSaveButton()}
                {loadJobRunButton()}
            </Grid>
            </Grid>
       </Card>
       <FullSummaryDialog open={openSummaryDialog} onClose={() => setopenSummaryDialog(false)}/>
       </div>
    );
}

export default observer(JobPreviewComponent);