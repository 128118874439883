import { Button, Card, CircularProgress, Divider, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { toast } from 'react-toastify';
import { runInAction } from 'mobx';
import Create from '@material-ui/icons/Create';
import { RootStoreContext } from '../../../../app/stores/root.store';
import AssetAgent from '../../../../app/api/assetsAgent';
import { Credentials, LicenseType, NetworkOption } from '../../../../app/models/jobProperties.model';
import { AccessCredentials, AssetDTO } from '../../../../app/models/createjob/createJob.model';
import { UpdateType } from '../../../../app/stores/activityStore';
import LockIcon from '@material-ui/icons/Lock';
import { DataMover } from '../../../../app/models/BaseSettings.enum';

const useStyles = makeStyles({
    editIcon: {
        cursor: 'pointer'
    },
    button: {
        width: '50%',
        height: '100%',
        borderRadius: '4px',
        border: 'solid 1px #c7cfdb',
        backgroundColor: '#fafafc'
    },
    advanced: {
        width: '14px',
        height: '14px',
        objectFit: 'contain',
    },
    MainHeading: {
        fontFamily: 'Roboto',
        color: '#1f396d',
        fontSize: '16px'
    },
    SecondHeading: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '100%',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.14',
        letterSpacing: 'normal',
        textAlign: 'left'
    },
    card: {
        padding: 25,
        height: '100%',
    },
    divider: {
        backgroundColor: '#eaeef4',
        width: '75%',
        marginTop: '8px',
        marginLeft: '7px',
        marginRight: '-107px'
    },
    helpIcon:
    {
        color: '#1976d2',
        fontSize: "large"
    },
    formControl: {
        width: '100%',
        '& label': {
            background: "white",
            width: "auto",
            padding: "0 4px 0 4px",
            fontSize: '17px'
        }
    }
});

const EDRCComponent: React.FC<{onValidationChange:(isValid: boolean) => void}> = (props) => {

    const classes = useStyles();
    const [t] = useTranslation("createJobNetwork");
    const rootStore = useContext(RootStoreContext);
    const {jobSettingsStore,displayStore,createJobStore,activityStore} = rootStore;
    const {controllerDrawerOpen}=displayStore.NetworkDrawerSettings;
    const [ImageEDRCColor, setImageEDRCColor] = useState(false);
    let isEdit = window.location.href.includes('editJob');
    let isDraft = createJobStore.isDraft;
    const {isFirstTrial} = jobSettingsStore;
    const Validate =()=>{
        if(!displayStore.nextStepValid.edrcValid)
        {
            jobSettingsStore.validateEDRC(jobSettingsStore.selectedEDRC)
        }
    }
    useEffect(() => {               
            if(createJobStore.networkSetting.EnsureDRController?.id)
            {
                let valueEDRC = jobSettingsStore.EDRCs.find(a => a.id == createJobStore.networkSetting.EnsureDRController.id)
                jobSettingsStore.updateSelectedEDRC(valueEDRC);
                createJobStore.updateNetworkSettings({ EnsureDRController: valueEDRC ? valueEDRC : null });
                displayStore.updateNextStepValid({edrcValid:true})
            }
            else
            {
                if(jobSettingsStore.EDRCs.length >0)
                {
                    jobSettingsStore.updateSelectedEDRC(jobSettingsStore.EDRCs[0]);
                    rootStore.createJobStore.updateNetworkSettings({ EnsureDRController: jobSettingsStore.EDRCs[0] });
                    displayStore.updateNextStepValid({edrcValid:true})
                    //this.validateEDRC(assets.edrCs[0])
                }
                else
                {
                    displayStore.updateNextStepValid({edrcValid:false});
                    jobSettingsStore.updateSelectedEDRC(null);
                    rootStore.createJobStore.updateNetworkSettings({ EnsureDRController: null });
                }
                
            }
        activityStore.on(UpdateType.ReValidate, Validate);
        return () => {
            activityStore.off(UpdateType.ReValidate,Validate);
        }
    }, [])

    useEffect(() => {
        props.onValidationChange(Boolean(createJobStore.networkSetting.NetworkType === NetworkOption.None || jobSettingsStore.selectedEDRC?.id));
     }, [jobSettingsStore.selectedEDRC, createJobStore.networkSetting.NetworkType])

     useEffect(() => {
         if(createJobStore.networkSetting.NetworkType !== NetworkOption.None && !displayStore.screenSettings.EDRCApplyProcessing && createJobStore.baseSettings.dataMover != DataMover.AWSDRS)
         {
            if(displayStore.screenSettings.AddModeEDRC && jobSettingsStore.selectedEDRC && jobSettingsStore.selectedEDRC.assetName=='' && jobSettingsStore.selectedEDRC.assetVMName=='' )
                return;
            else
            {
                if(!displayStore.nextStepValid.edrcValid)
                    jobSettingsStore.validateEDRC(jobSettingsStore.selectedEDRC);
            }
                
         }
     }, [jobSettingsStore.selectedEDRC])

     useEffect(() => {
        if(createJobStore.networkSetting.NetworkType !== NetworkOption.None && !displayStore.screenSettings.EDRCApplyProcessing && createJobStore.baseSettings.dataMover != DataMover.AWSDRS)
        {
            if(displayStore.screenSettings.AddModeEDRC && jobSettingsStore.selectedEDRC && jobSettingsStore.selectedEDRC.assetName=='' && jobSettingsStore.selectedEDRC.assetVMName=='' )
                return;
            else
                jobSettingsStore.validateEDRC(jobSettingsStore.selectedEDRC);
        } 
    }, [createJobStore.networkSetting.NetworkType])

    const CheckSectionAndDrawerStatus= () =>{
        if(displayStore.screenSettings.displayNetwork=='ControllerSettings' || displayStore.screenSettings.displayNetwork=='CloneSettings' || displayStore.screenSettings.displayNetwork=='AdvancedVlan' || 
            displayStore.screenSettings.displayNetwork=='NetworkControlSettings'|| displayStore.screenSettings.displayNetwork== 'EDRServerSettings' || displayStore.screenSettings.displayNetwork=='EmptyVlan')
        {
            if(displayStore.screenSettings.displayNetwork!='EDRServerSettings')
                displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
            displayStore.updateNetworkDrawerSettings({controllerDrawerOpen:false});
            displayStore.updateNetworkDrawerSettings({cloneDrawerOpen:false});
            displayStore.updateNetworkDrawerSettings({liveDrawerOpen:false});
            displayStore.updateNetworkDrawerSettings({testDrawerOpen:false});
        }
    }

    const handleAddEDRC =()=>{
        if((jobSettingsStore.selectedEDRC==null) || (jobSettingsStore.selectedEDRC!= null && !jobSettingsStore.selectedEDRC.assetName.includes("Add")))
        {
            jobSettingsStore.updatePreviousEDRC(jobSettingsStore.selectedEDRC);
            let cred= new AccessCredentials();
            cred.id=0;cred.userName='';cred.password='';
            let edrcObject = new AssetDTO();
            edrcObject.id =0;
            edrcObject.assetName ="";
            edrcObject.assetVMName="";
            edrcObject.credentials =cred;
            jobSettingsStore.updateSelectedEDRC(edrcObject);
            displayStore.updateScreenSettings({ AddModeEDRC: true ,AddModeEDR:false,AddModeDC:false});
            displayStore.updateScreenSettings({ renderValue: "" });
            displayStore.updateScreenSettings({ displayInfo: "InfoEDRC" });
            CheckSectionAndDrawerStatus();
            displayStore.updateScreenSettings({ displayNetwork: 'ControllerSettings' });
            displayStore.closeAllOtherNetworkDrawersExcept("controllerDrawer");
        }
    }

    const handleChangEDRC = (event: object, value: any) => {
        if(event != null)
        {
            if(typeof value == "object") 
            {
                jobSettingsStore.updateSelectedEDRC(value);
                createJobStore.updateNetworkSettings({EnsureDRController: value});
			}
            if(typeof value == "string")
            {
                const edrcObject= jobSettingsStore.EDRCs.find(edrc => edrc.assetName.toLocaleLowerCase() == value.toLocaleLowerCase());
                if (edrcObject) 
                {
                    displayStore.updateNetworkSectionSettings({DisableButtonEDRC:false});
                    displayStore.updateScreenSettings({ AddModeEDR: false,AddModeEDRC:false,AddModeDC:false });
                    displayStore.updateScreenSettings({ renderValue: edrcObject.assetName });
                    jobSettingsStore.updateSelectedEDRC(edrcObject);
                    displayStore.updateNextStepValid({edrcValid:true})
                }
            }
            CheckSectionAndDrawerStatus();
            displayStore.updateNetworkDrawerSettings({controllerDrawerOpen:false});
            if(event!=null)
            {
                displayStore.updateScreenSettings({ displayInfo: "InfoEDRC" });
            }
        }
    };

    const buttonRedirect= (subComponent:any)=> {
        if(controllerDrawerOpen)
        {
            displayStore.updateScreenSettings({ displayNetwork: 'EmptyVlan' });
            displayStore.updateNetworkDrawerSettings({controllerDrawerOpen:false});
        }  
        else
        {
            displayStore.updateScreenSettings({AddModeEDRC:false});
            displayStore.updateScreenSettings({ displayNetwork: subComponent });
            displayStore.updateScreenSettings({ displayInfo: "InfoEDRC" });
            displayStore.closeAllOtherNetworkDrawersExcept("controllerDrawer");
        }
    };

    const loadCircularProgress =(item:string)=>{
        return <div style={{width:'100%',display:'flex'}}>
        {(t('EDRCInProgress'))}
        <CircularProgress id="dcValidation" size={18} style={{color: '#2892D7', marginLeft: '10px',marginTop: '-3px'}}/>
        </div>
    }
    
    return (
        <Grid container spacing={2} style={{ display: 'flex',justifyContent:'center' }}>
        
            <Grid item xs={1} style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
            {isFirstTrial  ? 
            <Tooltip title={t("trialLock")} arrow>
                <LockIcon />
            </Tooltip>
            : <IconButton onClick={()=> handleAddEDRC()} disabled={true}>
                <AddCircleOutlineIcon/>
            </IconButton>}
            </Grid>
            <Grid container item xs={11} > 
            <Grid item xs={11}>
            <Autocomplete id="edrc-server"
                disableClearable
                disabled={true}
                style={{backgroundColor:(displayStore.networkSectionSettings.DisableButtonEDRC ? 'rgba(235, 238, 244, 0.75)': '#ffffff'),width:'97%'}} 
                getOptionLabel={(option) => (typeof option === 'string' ? option :`${option.assetName}`)}
                getOptionSelected = {(option, value) => option.assetName == value}
                value={jobSettingsStore.selectedEDRC}
                options={jobSettingsStore.EDRCs}
                renderOption={option => (<span>{option.assetIP !=null ? `${option.assetName} (${option.assetIP})`:`${option.assetName}`}</span>)}
                onChange={(event: object, value: any) => { handleChangEDRC(event, value) }}
                onInputChange={handleChangEDRC}
                onFocus={()=>displayStore.updateScreenSettings({ displayInfo: "InfoEDRC" })}
                renderInput={(params) => (<TextField {...params} disabled={true} label={t('edrcServer')} InputLabelProps={{ shrink: true, }} variant="outlined" />)}
            />
            </Grid>
        
        
        <Grid item xs={1}>
            <Button className={classes.button} disabled={true} onClick={() => buttonRedirect('ControllerSettings')}>
                <Create className={classes.editIcon} onMouseEnter={() => setImageEDRCColor(true)} onMouseLeave={() => setImageEDRCColor(false)}></Create>
            </Button>
        </Grid>
        </Grid>
        </Grid>

    );
};

export default observer(EDRCComponent);