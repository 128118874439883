import React, { useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/root.store';
import { Button, Chip, FormControl, Grid, IconButton, InputAdornment, InputLabel, makeStyles, MenuItem, Select, Switch, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DataMover, DATA_MOVER_CONFIGURATION_NAME, DATA_MOVER_NAME, INFRA_NAME } from '../../../app/models/BaseSettings.enum';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import ReactDOM from 'react-dom';
import { EDROutlinedInput } from "../../../app/common/EDROutlinedInput";
import { EDRAutocomplete } from "../../../app/common/EDRAutocomplete";
import { EDRTextField } from "../../../app/common/EDRTextField";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { UpdateType } from '../../../app/stores/activityStore';
import JobAgent from '../../../app/api/jobAgent';
import JobSettingsStore from '../../../app/stores/jobSettings.store';
import { InfrastructureType } from '../../../app/models/jobProperties.model';
import { runInAction } from 'mobx';
import jobAgent from '../../../app/api/jobAgent';
import { toast } from 'react-toastify';
import { AccessCredentials, CONSTS, CredentialUserType } from '../../../app/models/createjob/createJob.model';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { debounce } from 'lodash';
import { AzureValidationSchema, VcvalidationSchema,AWSValidationSchema } from './validations/infraValidators';
import {regions} from '../../../app/regions';
const AZURE_DATAMOVERS = [DataMover.ASR, DataMover.ASRVmwareAzure, DataMover.IaaS, DataMover.PaaS, DataMover.ZertoAzure, DataMover.ZertoVmwareAzure]
const AWS_DATAMOVERS = [DataMover.AWSDRS, DataMover.VmwareAWS];


const useStyles = makeStyles(() => ({
  button: {
    height: '100%',
    borderRadius: '4px',
    border: 'solid 1px #c7cfdb',
    backgroundColor: '#fafafc'
  },
  advanced: {
    width: '14px',
    height: '14px',
    objectFit: 'contain',
  },
  formControl: {
    width: '100%',
    '& label': {
      background: "white",
      width: "auto",
      //padding: "0 4px 0 4px",
      fontSize: '14px'
    }
  },
  validateIcon: {
    position: 'absolute',
    top: 15,
    right:8,
    color: '#8bc53f'
  },
  validationStatus: {
    color: 'red'
  }
}));

const validateVC = async (value, resolve) => {
  try {
    if (!value.username || !value.password || !value.hostname) {
      resolve(false);
      return;
    }
    var res = await jobAgent.CreateJobActions.validateVcenter(value.hostname, value.username, value.password);
    resolve(Boolean(res));
  } catch (error) {
    resolve(false);
  }
};

const targetVCValidation = debounce(validateVC, 800, {
  'leading': false,
  'trailing': true
});
const sourceVCValidation = debounce(validateVC, 800, {
  'leading': false,
  'trailing': true
});


function validateHostname(value) {

  let IpRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
  let hostnameRegex = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;
  let isValidIP = IpRegex.test(value);
  let isValidHostname = hostnameRegex.test(value);

  if (isValidIP || isValidHostname)
    return true;

  return false;

}

function validateGuid(value) {
  let GuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;

  let isValidGuid = GuidRegex.test(value);
  if (isValidGuid)
    return true;
  else
    return false;
}
const InfraCreateComponent: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { createJobStore, activityStore, jobSettingsStore,displayStore } = rootStore;
  const { workflowTest, dataMover, infrastructureType } = createJobStore.baseSettings;
  const { domainController } = createJobStore.networkSetting;
  let labelRef;
  let targetlabelRef;
  let sourcelabelRef;
  const isPaaS = createJobStore.baseSettings.dataMover == DataMover.PaaS;
  const isAzure = AZURE_DATAMOVERS.indexOf(dataMover)>=0;
  const isAWS = AWS_DATAMOVERS.indexOf(dataMover)>=0;
  const [t] = useTranslation("createJobBase");
  const [ImageInfraColor, setImageInfraColor] = useState(false);
  const [CopyState, setCopyState] = useState(false);


  const [sourcepasswordVisibility, setSourcePasswordVisibility] = useState(true);
  const [targetpasswordVisibility, setTargetPasswordVisibility] = useState(true);
  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const [DMtargetpasswordVisibility, setDMTargetPasswordVisibility] = useState(true);

  const [UserPassAutocomplete, setUserPassAutocomplete] = useState(false);
  const [DMUserPassAutocomplete, setDMUserPassAutocomplete] = useState(false);
  const [InfraUserPassAutocomplete, setInfraUserPassAutocomplete] = useState(false);

  const [showEyeInfraPassword, setShowEyeInfraPassword] = useState(true);
  const [showEyeDMPassword, setShowEyeDMPassword] = useState(true);
  const [showEyeDMTargetPassword, setShowEyeDMTargetPassword] = useState(true);

  const getPreviousSourceUsername = !isPaaS ?
    jobSettingsStore.sourceUserNamesAndPass :
    [{ username: '', password: '' }];
  const getPreviousDatabaseUsername = [{ username: 'edradmin', password: 'StartHere99!!' }]

  const previousSourceIPInfra = jobSettingsStore.PreviousSourceIPInfra;
  const previousTargetIPInfra = jobSettingsStore.PreviousTargetIPInfra;
  const previousTargetUsername = jobSettingsStore.targetUserNamesAndPass;
  const previousSourceUsername = jobSettingsStore.sourceUserNamesAndPass;
  const previousTenantID = jobSettingsStore.PreviousTenantID;
  const tempInfra = jobSettingsStore.tempInfra;

  const classes = useStyles();

  useEffect(() => {
 

      const onDataLoaded = () => {
        const tempInfra = jobSettingsStore.tempInfra;
        let initialValues;
        initialValues =
        {
          ZertoOrSRM: dataMover === DataMover.ZertoVmWare || dataMover === DataMover.SRM,
          datamover: dataMover,
          infraSourceIP: tempInfra && tempInfra.infrastructureSource != undefined && tempInfra.infrastructureSource != null ? tempInfra.infrastructureSource : "",
          source: {
            hostname: isPaaS? "Azure": isAWS ? "AWS" : tempInfra && tempInfra.infrastructureSource != undefined && tempInfra.infrastructureSource != null ? tempInfra.infrastructureSource : "",
            username: tempInfra.sourceInfraCredentials.userName,
            password: tempInfra.sourceInfraCredentials.password
          },
          target: {
            hostname: tempInfra && tempInfra.infrastructureTarget != undefined && tempInfra.infrastructureTarget != null ? tempInfra.infrastructureTarget : "",
            username: tempInfra.targetInfraCredentials.userName,
            password: tempInfra.targetInfraCredentials.password
          }
        };
    
    
          form.setValues(initialValues, true);
      }

      onDataLoaded();

      activityStore.on(UpdateType.FirstRunDefaultLoaded, onDataLoaded);

      return () => {
        activityStore.off(UpdateType.FirstRunDefaultLoaded, onDataLoaded);
      }
  }, []);

  useEffect(() => {
    jobSettingsStore.initializeJobBaseData();
  }, [createJobStore.baseSettings.dataMover]);

  useEffect(() => {
    if (createJobStore.PlatformChanged)
      form.resetForm();

  }, [createJobStore.PlatformChanged]);


  const form = useFormik({
    initialValues:
    {
      ZertoOrSRM: dataMover === DataMover.ZertoVmWare || dataMover === DataMover.SRM,
      datamover: dataMover,
      infrastructureType: isAWS ? 6 : isAzure ? 2 : 1,
      tenantId: '',
      target: {
        hostname: '',
        username: '',
        password: ''
      },
      source: {
        hostname: isAWS ? 'AWS' : '',
        username: '',
        password: ''
      }
    },
    validationSchema: isAzure ? AzureValidationSchema: (isAWS ? AWSValidationSchema  :VcvalidationSchema),
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: false,
    onSubmit: () => { },

  })


  useEffect(() => {
    displayStore.updateNextStepValid({ infraValid: form.isValidating ? false : form.isValid });
  }, [form.isValid, form.isValidating])
  function setInitialPort() {
    if (infrastructureType == InfrastructureType.VMware)
      return 902;
    else
      return 0;
  }

  const handleClickShowPassword = (type: string) => {
    switch (type) {
      case "infraTarget": setTargetPasswordVisibility(targetpasswordVisibility ? false : true); break;
      case "infraSource": setSourcePasswordVisibility(sourcepasswordVisibility ? false : true); break;
      case "target": setDMTargetPasswordVisibility(DMtargetpasswordVisibility ? false : true); break;
    }
  };

  const handleInfraSourceIPChange = (event: object, value: any) => {
    form.setFieldValue("source.hostname", value, true);
    jobSettingsStore.updateTempInfra({ infrastructureSource: value });
    CheckSectionAndDrawerStatus("infrastructure");
  };

  const handleInfraTargetIPChange = (event: object, value: any) => {
    form.setFieldValue("target.hostname", value, true);
  
    jobSettingsStore.updateTempInfra({ infrastructureTarget: value });
    if(isAWS)
      jobSettingsStore.updateTempInfra({infrastructureSource: 'AWS'});
    CheckSectionAndDrawerStatus("infrastructure");
  };

  const handleSourceUsernameChange = (event: React.ChangeEvent, value: any, onInput: boolean) => {
    setInfraUserPassAutocomplete(onInput);
    if(!jobSettingsStore.tempInfra.infrastructureTypeId || jobSettingsStore.tempInfra.infrastructureTypeId == 0)
    {
        jobSettingsStore.updateTempInfra({ infrastructureTypeId: createJobStore.baseSettings.infrastructureType });
    }
    if (value != null && typeof value === 'object') {
      form.setFieldValue("infraSourceUsername", value == "" ? '' : value.userName, true);
      form.setFieldValue("infraSourcePassword", '', true);
      jobSettingsStore.updateTempInfra({ sourceInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.sourceInfraCredentials, userName: value == "" ? '' : value.username, password: value == "" ? '' : value.password } });
      let credentials = new AccessCredentials();
      credentials.id = value == "" ? 0 : value.id;
      credentials.userName = value == "" ? '' : value.username;
      credentials.password = '';
      rootStore.createJobStore.updateNetworkSettings({ domainController: { ...rootStore.createJobStore.networkSetting.domainController, domainCredentials: credentials } });
      if (tempInfra.infrastructureSource && tempInfra.sourceInfraCredentials.userName && tempInfra.sourceInfraCredentials.password) {
        jobSettingsStore.authenticateVCenterSource();
      }
    }
    if (typeof value === 'string') {
      const userObj = previousSourceUsername.find(e => e.username.toLocaleLowerCase() == value.toLocaleLowerCase());
      //const userObj = previousSourceUsername.find(e => e.username.toLocaleLowerCase() == value.username.toLocaleLowerCase() && e.password.toLocaleLowerCase() == value.password.toLocaleLowerCase());
      let user = userObj != undefined ? value : (value === "" ? '' : value)
      form.setFieldValue("infraSourceUsername", user);
      let pass = '';
      let id = userObj == undefined ? 0 : userObj.id;
      form.setFieldValue("infraSourcePassword", pass);
      jobSettingsStore.updateTempInfra({ sourceInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.sourceInfraCredentials, userName: user, password: pass } });
      rootStore.createJobStore.updateNetworkSettings({ domainController: { ...rootStore.createJobStore.networkSetting.domainController, domainCredentials: { id: id, userName: user, password: pass,userType:CredentialUserType.GeneralUser } } });
    }
    if (value === null) {
      form.setFieldValue("infraSourceUsername", '');
      form.setFieldValue("infraSourcePassword", '');
      jobSettingsStore.updateTempInfra({ sourceInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.sourceInfraCredentials, userName: '', password: '' } });
    }
    setShowEyeInfraPassword(onInput);
    if(!onInput)
    {
      setSourcePasswordVisibility(true);
    }
    CheckSectionAndDrawerStatus("Azure");
    event.stopPropagation && event.stopPropagation();
    event.preventDefault();
  };

  const handleTargetUsernameChange = (event: React.ChangeEvent, value: any, onInput: boolean) => {
    setInfraUserPassAutocomplete(onInput);
    let username = ""
    let password = ""
    if (value != null && typeof value === 'object') {
      username = value.username;
      password = '';
      jobSettingsStore.updateTempInfra({ sourceInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.sourceInfraCredentials, userName: value == "" ? '' : value.username, password:''} });
      jobSettingsStore.updateTempInfra({ targetInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.targetInfraCredentials, userName: value == "" ? '' : value.username, password: ''} });
      let credentials = { id: value == "" ? 0 : value.id, userName: value == "" ? '' : value.username, password:'', userType: CredentialUserType.GeneralUser };
      rootStore.createJobStore.updateNetworkSettings({ domainController: { ...rootStore.createJobStore.networkSetting.domainController, domainCredentials: credentials } });
    }
    if (typeof value === 'string') {
      const userObj = previousSourceUsername.find(e => e.username.toLocaleLowerCase() == value.toLocaleLowerCase());
      username = userObj ? userObj.username : value
      password = '';
      let id = userObj == undefined ? 0 : userObj.id;


      jobSettingsStore.updateTempInfra({ sourceInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.sourceInfraCredentials, userName: username, password: password } });
      jobSettingsStore.updateTempInfra({ targetInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.targetInfraCredentials, userName: username, password: password } });
      rootStore.createJobStore.updateNetworkSettings({ domainController: { ...rootStore.createJobStore.networkSetting.domainController, domainCredentials: { id: id, userName: username, password: password,userType: CredentialUserType.GeneralUser } } });
    }
    if (value === null) {

      jobSettingsStore.updateTempInfra({ sourceInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.sourceInfraCredentials, userName: '', password: '' } });
      jobSettingsStore.updateTempInfra({ targetInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.targetInfraCredentials, userName: '', password: '' } });
    }


    form.setValues({ ...form.values, target: { ...form.values.target, username: username, password: password }, source: { ...form.values.source, username: username, password: password } })

    //form.validateForm();
    setShowEyeInfraPassword(onInput);
    if(!onInput)
    {
        setTargetPasswordVisibility(true);
    }
    CheckSectionAndDrawerStatus("infrastructure");
    // if (tempInfra.infrastructureTarget && tempInfra.targetInfraCredentials.userName && tempInfra.targetInfraCredentials.password) {
    //   jobSettingsStore.authenticateVCenter();
    // }
    // if (tempInfra.infrastructureSource && tempInfra.sourceInfraCredentials.userName && tempInfra.sourceInfraCredentials.password) {
    //   jobSettingsStore.authenticateVCenterSource();
    // }
    event.stopPropagation && event.stopPropagation();
    event.preventDefault();
  };

  const handleTargetUsernameChangeManual = (event: any) => {
    if (form.values.target.password != "") {
      form.setFieldValue("target.password", "", true);
      jobSettingsStore.updateTempInfra({ sourceInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.sourceInfraCredentials, password: '' } });
      jobSettingsStore.updateTempInfra({ targetInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.targetInfraCredentials, password: '' } });
    }
    form.setFieldValue("target.username", event == "" ? '' : event, true);
    jobSettingsStore.updateTempInfra({ sourceInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.sourceInfraCredentials, userName: event == "" ? '' : event, password: '' } });
    jobSettingsStore.updateTempInfra({ targetInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.targetInfraCredentials, userName: event == ""? '' : event, password: '' } });
    let credentials = { id: createJobStore.networkSetting.domainController.domainCredentials.id, userName: event == "" ? '' : event, password: '',userType:CredentialUserType.GeneralUser };
    rootStore.createJobStore.updateNetworkSettings({ domainController: { ...rootStore.createJobStore.networkSetting.domainController, domainCredentials: credentials } });
  }

  const handleSourceUsernameChangeManual = (event: any) => {
    if (form.values.source.password != "") {
      form.setFieldValue("source.password", "", true);
      jobSettingsStore.updateTempInfra({ sourceInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.sourceInfraCredentials, password: '' } });
    }
    form.setFieldValue("source.username", event == "" ? '' : event, true);
    jobSettingsStore.updateTempInfra({ sourceInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.sourceInfraCredentials, userName: event == "" ? '' : event } });
    let credentials = { id: createJobStore.networkSetting.domainController.domainCredentials.id, userName: event == "" ? '' : event, password: '',userType:CredentialUserType.GeneralUser };
    rootStore.createJobStore.updateNetworkSettings({ domainController: { ...rootStore.createJobStore.networkSetting.domainController, domainCredentials: credentials } });
  }

  const handleTargetPasswordChangeManual = (event: any) => {
    setInfraUserPassAutocomplete(true);
    let password=event.target.value
    jobSettingsStore.updateTempInfra({ sourceInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.sourceInfraCredentials, password: event.target.value == "" ? '' : event.target.value } });
    jobSettingsStore.updateTempInfra({ targetInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.targetInfraCredentials, password: event.target.value == "" ? '' : event.target.value } });
   form.setValues({...form.values, target: {...form.values.target,password }, source: {...form.values.source,password }})
    // form.validateForm();
  }

  const handleTargetPasswordChangeManualBlur = (event: any) => {
    form.handleBlur(event)
    // if (tempInfra.infrastructureTarget && tempInfra.targetInfraCredentials.userName && tempInfra.targetInfraCredentials.password) {
    //   jobSettingsStore.authenticateVCenter();
    // }
    // if (tempInfra.infrastructureSource && tempInfra.sourceInfraCredentials.userName && tempInfra.sourceInfraCredentials.password) {
    //   jobSettingsStore.authenticateVCenterSource();
    // }
  }

  const handleSourcePasswordChangeManual = (event: any) => {
    if(event!=null)
    {
      setInfraUserPassAutocomplete(true);
      form.setFieldValue("infraSourcePassword", event.target.value == "" ? '' : event.target.value, true);
      jobSettingsStore.updateTempInfra({ sourceInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.sourceInfraCredentials, password: event.target.value == "" ? '' : event.target.value } });
      if (tempInfra.infrastructureSource && tempInfra.sourceInfraCredentials.userName && tempInfra.sourceInfraCredentials.password) {
        jobSettingsStore.authenticateVCenterSource();
    }
    }
  }

  const handleSourcePasswordChangeManualBlur = (event: any) => {
    if(event!=null)
    {
      form.handleBlur(event)
      if (tempInfra.infrastructureSource && tempInfra.sourceInfraCredentials.userName && tempInfra.sourceInfraCredentials.password) {
        jobSettingsStore.authenticateVCenterSource();
    }
    }
  }

  const handleTargetPassChange = (event) => {
    setInfraUserPassAutocomplete(true);
    setShowEyeInfraPassword(true);
    jobSettingsStore.updateTempInfra({ targetInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.targetInfraCredentials, password: event.target.value } });
    jobSettingsStore.updateTempInfra({ sourceInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.sourceInfraCredentials, password: event.target.value } });
    CheckSectionAndDrawerStatus("infrastructure");
  };

  const handleCopyChange = (event) => {
    setCopyState(event.target.checked);
    if (event.target.checked == true) {
      createJobStore.updateBaseSettings({ workflowTest: { ...workflowTest, PrimaryUser: form.values.target.username, PrimaryPassword: form.values.target.password } });
      let credentials = { id: createJobStore.networkSetting.domainController.domainCredentials.id, userName: form.values.target.username, password: form.values.target.password,userType:CredentialUserType.GeneralUser }
      createJobStore.updateNetworkSettings({ domainController: { ...domainController, domainCredentials: credentials } });
      if (dataMover != DataMover.SRM && dataMover != DataMover.ZertoVmWare) {
        jobSettingsStore.updateTempInfra({ targetInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.targetInfraCredentials, userName: form.values.target.username, password: form.values.target.password } });
        jobSettingsStore.updateTempInfra({ sourceInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.sourceInfraCredentials, userName: form.values.target.username, password: form.values.target.password } });
      }
    }
    else {
      createJobStore.updateBaseSettings({ workflowTest: { ...workflowTest, PrimaryUser: '', PrimaryPassword: '' } });
      let credentials = { id: 0, userName: '', password: '',userType:CredentialUserType.GeneralUser }
      createJobStore.updateNetworkSettings({ domainController: { ...domainController, domainCredentials: credentials } });
      if (dataMover != DataMover.SRM && dataMover != DataMover.ZertoVmWare) {
        jobSettingsStore.updateTempInfra({ targetInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.targetInfraCredentials, userName: '', password: '' } });
        jobSettingsStore.updateTempInfra({ sourceInfraCredentials: { ...rootStore.jobSettingsStore.tempInfra.sourceInfraCredentials, userName: '', password: '' } });
      }
    }
    activityStore.sendMessage(UpdateType.CopyInfraCredentials);
  }

  const buttonRedirect = (subComponent: any) => {
    if (subComponent == "AzureAdvanced") {
      if (displayStore.BaseDrawerSettings.AzureDrawerOpen) {
        displayStore.updateScreenSettings({ displayBaseSettings: 'EmptyVlan' });
        displayStore.updateBaseDrawerSettings({ AzureDrawerOpen: false });
      }
      else {
        displayStore.updateScreenSettings({ displayBaseSettings: subComponent });
        displayStore.closeAllOtherBaseDrawersExcept("InfraDrawer");
      }
    }
    if (subComponent == "InfrastructureCreateAdvanced") {
      if (displayStore.BaseDrawerSettings.InfraDrawerOpen) {
        displayStore.updateScreenSettings({ displayBaseSettings: 'EmptyVlan' });
        displayStore.updateBaseDrawerSettings({ InfraDrawerOpen: false });
      }
      else {
        displayStore.updateScreenSettings({ displayBaseSettings: subComponent });
        displayStore.closeAllOtherBaseDrawersExcept("InfraDrawer");
      }
    }
  };

  const CheckSectionAndDrawerStatus = (section: string) => {
    switch (section) {
      case "InfrastructureCreateAdvanced":
        {
          if (displayStore.screenSettings.displayBaseSettings == 'DataMoverCreateAdvanced' || displayStore.screenSettings.displayBaseSettings == 'WorkflowAdvanced' || displayStore.screenSettings.displayBaseSettings == 'BasicAdvanced' || displayStore.screenSettings.displayBaseSettings == 'LicenseAdvanced') {
            displayStore.updateScreenSettings({ displayBaseSettings: "InfrastructureCreateAdvanced" });
            displayStore.updateBaseDrawerSettings({ InfraDrawerOpen: true });
            displayStore.updateBaseDrawerSettings({ BasicDrawerOpen: false });
            displayStore.updateBaseDrawerSettings({PrimaryUserDrawerOpen: false});
            displayStore.updateBaseDrawerSettings({SecondaryUserDrawerOpen: false});
            displayStore.updateBaseDrawerSettings({ LicenseDrawerOpen: false });
            displayStore.updateBaseDrawerSettings({ DataMoverDrawerOpen: false });
            displayStore.updateBaseDrawerSettings({ WorkflowDrawerOpen: false });
            displayStore.updateBaseDrawerSettings({ EDRDrawerOpen: false });
          }
        }
    }
  }

  const handleValueChange = (event: React.ChangeEvent) => {
    form.handleChange(event);
    switch (event.target['name']) {
      case 'infraTargetPassword': handleTargetPassChange(event); break;
    }
  }

  const handleMouseClick =(type:string)=>{
    switch(type)
    {
      case "infraSourcePassword":
          {
              if(form.values.source.password === CONSTS.MaskedPassword)
              {
                  form.setFieldValue("source.password",'',true);
              }
              break;
          }
      case "infraTargetPassword":
          {
              if(form.values.target.password === CONSTS.MaskedPassword)
              {
                  form.setFieldValue("target.password",'',true);
              }
              break;
          }
    }
}

const handleMouseOut =(type:string)=>{
  switch(type)
  {
      case "infraSourcePassword":
          {
              if(form.dirty && form.values.source.password == '')
              {
                if(form.values.source.username!='')
                {
                  return;
                }
                else form.setFieldValue("source.password",CONSTS.MaskedPassword,true);
              }
              break;
          }
      case "infraTargetPassword":
          {
              if(form.dirty && form.values.target.password == '')
              {
                  if(form.values.target.username!='')
                  {
                    return;
                  }
                  else form.setFieldValue("target.password",CONSTS.MaskedPassword,true);
              }
              break;
          }
  }
}

  const handleAutoCompleteChange = (event: React.ChangeEvent, value: any) => {
    if (event != null) {
      let fieldName = event.target['id'].includes('-') ? event.target['id'].split('-')[0] : event.target['id'];
      switch (fieldName) {
        case 'infraSourceIP': handleInfraSourceIPChange(event, value); break;
        case 'tenantId': handleInfraSourceIPChange(event, value); break;
        case 'infraTargetIP': handleInfraTargetIPChange(event, value); break;
        case 'infraTargetUsername': handleTargetUsernameChange(event, value, event.type == "click" ? false : true); break;
        case 'infraSourceUsername': handleSourceUsernameChange(event, value, event.type == "click" ? false : true); break;
      }
    }
  }
  const handleAutoCompleteTargetChangeInput = (event: any, value: any) => {
    if (event != null)
      handleTargetUsernameChangeManual(event.target.value);
  }

  const handleAutoCompleteSourceChangeInput = (event: any, value: any) => {
    if (event != null)
      handleSourceUsernameChangeManual(event.target.value);
  }
  const loadInfraTarget = () => {
    return (<Tooltip title={isAzure ? t('TenantID') : t('infraTargetInfo')} placement="top">
    <FormControl className={classes.formControl} variant='outlined'>
      <EDRAutocomplete id="infraTargetIP"
        freeSolo
        disableClearable
        value={form.values && form.values.target != null ? form.values.target.hostname : ''}
        options={previousTargetIPInfra}
        onChange={handleAutoCompleteChange}
        onInputChange={handleAutoCompleteChange}
        onBlur={() => {
          form.setFieldTouched("target.hostname")
        }}
        renderInput={(params) => (<EDRTextField {...params} label={isAzure ? t("TenantID"): `* ${INFRA_NAME[infrastructureType]} ${t("vcenter")} ${t('targetIP')}`}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          placeholder="Type Here"
          aria-autocomplete="none"
          error={ !form.isValidating && form.touched?.target?.hostname && (Boolean(typeof form.errors.target == "string"? form.errors.target: form.errors.target?.hostname) )}
          helperText={!form.isValidating && form.touched?.target?.hostname && (t(typeof form.errors?.target == "string" ? form.errors.target : form.errors?.target?.hostname)) }
        />)}
      />
      <React.Fragment>{form.touched?.target?.hostname && !form.isValidating && !form.errors.target && <CheckCircle className={classes.validateIcon} />}</React.Fragment>
    </FormControl>
    </Tooltip>
    );
  }

  const loadInfraSource = () => {
    return (
    <Tooltip title={isAzure ? t('SubscriptionId'): t('infraSourceInfo')} placement="top">
    <FormControl className={classes.formControl} variant='outlined'>
      <EDRAutocomplete id="infraSourceIP"
        freeSolo
        disableClearable
        value={form.values && form.values.source != null ? form.values.source.hostname : ''}
        options={previousSourceIPInfra}
        onChange={handleAutoCompleteChange}
        onInputChange={handleAutoCompleteChange}
        onBlur={() => {
          form.setTouched({
            ...form.touched,
            source:{
              hostname: true
            }
          })
        }}
        renderInput={(params) => (<EDRTextField {...params} label={isAzure ? t('SubscriptionId'): `* ${INFRA_NAME[createJobStore.baseSettings.dataMover === DataMover.ZertoAzure ? InfrastructureType.VMware : infrastructureType]} ${t("vcenter")} ${t('sourceIP')}`}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          placeholder="Type Here"
          aria-autocomplete="none"
          error={!form.isValidating && form.touched?.source?.hostname && Boolean(typeof form.errors.source == "string"? form.errors.source: form.errors.source?.hostname)}
          helperText={!form.isValidating && form.touched?.source?.hostname && (t(typeof form.errors?.source == "string" ? form.errors.source : form.errors?.source?.hostname)) }
        />)}
      />
      <React.Fragment>{form.touched?.source?.hostname && !form.isValidating && !form.errors.source && <CheckCircle className={classes.validateIcon} />}</React.Fragment>
    </FormControl>
    </Tooltip>
    );
  }
  const loadInfraTargetAWS = () => {
    return (
    <Tooltip title={t('region')} placement="top">
    <FormControl className={classes.formControl} variant='outlined'>
      <EDRAutocomplete id="infraTargetIP"
        freeSolo
        disableClearable
        value={form.values && form.values.target != null ? form.values.target.hostname : ''}
        options={regions.map((data,key) => {return data.RegionName})}
        onChange={handleAutoCompleteChange}
        onInputChange={handleAutoCompleteChange}
        onBlur={() => {
          form.setTouched({
            ...form.touched,
            target:{
              hostname: true
            }
          })
        }}
        renderInput={(params) => (<EDRTextField {...params} label={t('region')}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          placeholder="Type Here"
          aria-autocomplete="none"
          error={!form.isValidating && form.touched?.target?.hostname && Boolean(typeof form.errors.target == "string"? form.errors.target: form.errors.target?.hostname)}
          helperText={!form.isValidating && form.touched?.target?.hostname && (t(typeof form.errors?.target == "string" ? form.errors.target : form.errors?.target?.hostname)) }
        />)}
      />
      <React.Fragment>{form.touched?.target?.hostname && !form.isValidating && !form.errors.target && <CheckCircle className={classes.validateIcon} />}</React.Fragment>
    </FormControl>
    </Tooltip>
    );
  }


  const loadInfraDrawer = () => {
    return (
      <Button className={classes.button} disabled={true} onClick={() => { createJobStore.baseSettings.dataMover === DataMover.ZertoAzure ? buttonRedirect('AzureAdvanced') : buttonRedirect('InfrastructureCreateAdvanced') }}>
        <img className={classes.advanced} onMouseEnter={() => setImageInfraColor(true)} onMouseLeave={() => setImageInfraColor(false)} src={createJobStore.baseSettings.dataMover !== DataMover.ZertoAzure ? '/assets/images/createjob/advancedNetworkDisabled.svg':(ImageInfraColor ? '/assets/images/createjob/advancedNetworkBlue.svg' : '/assets/images/createjob/advancedNetwork.svg')}></img>
      </Button>
    );
  }

  const loadInfraSourceUsername = () => {
    return (<FormControl className={classes.formControl} variant='outlined'>
      <EDRAutocomplete id="infraSourceUsername"
        freeSolo
        disableClearable
        value={form.values && form.values.source != null ? form.values.source.username : ''}
        options={getPreviousSourceUsername}
        renderOption={(option: string | { username: string, password: string }) => (typeof option === 'string' ? option : `${option.username}`)}
        getOptionLabel={(option: string | { username: string, password: string }) => (typeof option === 'string' ? option : `${option.username}`)}
        onChange={handleAutoCompleteChange}
        onInputChange={handleAutoCompleteChange}
        renderInput={(params) => (<EDRTextField {...params}
          label={"* " + INFRA_NAME[InfrastructureType.VMware] + " " +t("vcenter")+ t('sourceUsername')}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          placeholder="Type Here"
          aria-autocomplete="none"
          error={form.touched.source && form.touched.source.username && form.errors && Boolean(form.errors.source.username)}
          helperText={form.touched.source && form.touched.source.username && form.errors && Boolean(form.errors.source.username) && form.errors.source.username && t(form.errors.source.username)}
        />)}
      />
    </FormControl>
    )
  }

  const loadInfraTargetUsername = () => {
    return (<Tooltip title={isAzure ? t("ClientID"):(isAWS ? t('awsKey'): t('infraUserPassInfo'))} placement="top">
    <FormControl className={classes.formControl} variant='outlined'>
      <EDRAutocomplete id="infraTargetUsername"
        freeSolo
        disableClearable
        value={form.values && form.values.target != null ? form.values.target.username : ''}
        options={previousTargetUsername}
        renderOption={(option: string | { username: string, password: string }) => (typeof option === 'object' ? option.username : option)}
        onChange={handleAutoCompleteChange}
        onInputChange={handleAutoCompleteChange}
        onBlur={() => {
          form.setFieldTouched("target.username")
        }}
        getOptionLabel={(option: string | { username: string, password: string }) => (typeof option === 'object' ? option.username : option)}
        renderInput={(params) => (<EDRTextField {...params}
          label={ isAzure ? "ClientID":( isAWS ? t('awsKey'):`* ${INFRA_NAME[infrastructureType]} ${t("vcenter")} ${(isPaaS ? t('ClientID') : t('targetUsername'))}`)}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          placeholder="Type Here"
          aria-autocomplete="none"
          error={ form.touched?.target?.username && Boolean(form.errors?.target?.username)}
          helperText={form.touched?.target?.username && t(form.errors.target?.username)}
        />)}
      />
    </FormControl>
    </Tooltip>
    );
  }

  const loadInfraSourcePassword = () => {
    return (
      <FormControl className={classes.formControl} variant='outlined'>
        <InputLabel ref={ref => { sourcelabelRef = ReactDOM.findDOMNode(ref) }} style={{ transform: 'translate(14px, -7px) scale(0.75)' }}>{"* " + INFRA_NAME[InfrastructureType.VMware] + " " +t("vcenter")+ t('sourcePassword')}</InputLabel>
        <EDROutlinedInput id="infraSourcePassword"
          name="source.password"
          value={form.values.source.password}
          //   style={{backgroundColor:(displayStore.sectionSettings.platformSection? 'rgb(233, 235, 238)': '#ffffff')}}
          labelWidth={sourcelabelRef ? sourcelabelRef.offsetWidth : 0}
          autoComplete="off"
          type={sourcepasswordVisibility ? 'password' : 'text'}
          placeholder="Type Here"
          error={form.touched.source && form.touched.source.password && form.errors && Boolean(form.errors?.source?.password)}
          onChange={form.handleChange}
          onClick={event=> {handleMouseClick("infraSourcePassword")}}
          onBlur={event=> {handleMouseOut("infraSourcePassword")}}
          endAdornment={form.values.source.password && InfraUserPassAutocomplete ?
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                aria-autocomplete="none"
                onClick={() => handleClickShowPassword("infraSource")}
              >
                {sourcepasswordVisibility ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
            : null}
        />
      </FormControl>
    );
  }

  const loadInfraTargetPassword = () => {
    return (
      <Tooltip title={isAzure ? t('Client Secret'):(isAWS ? t('awsSecret'):t('infraUserPassInfo'))} placement="top">
      <FormControl className={classes.formControl} variant='outlined'>
        <InputLabel ref={ref => { targetlabelRef = ReactDOM.findDOMNode(ref) }} style={{ transform: 'translate(14px, -7px) scale(0.75)' }}>{isAzure ? t("Client Secret"): isAWS ? t('awsSecret'): ("* " + INFRA_NAME[infrastructureType] + " " +t("vcenter")+ (isPaaS ? t('secret') : t('targetPassword')))}</InputLabel>
        <EDROutlinedInput id="infraTargetPassword"
          name="infraTargetPassword"
          value={form.values.target.password}
          //   style={{backgroundColor:(displayStore.sectionSettings.platformSection? 'rgb(233, 235, 238)': '#ffffff')}}
          labelWidth={targetlabelRef ? targetlabelRef.offsetWidth : 0}
          autoComplete="off"
          type={targetpasswordVisibility ? 'password' : 'text'}
          placeholder="Type Here"
          error={form.touched.target && form.touched.target.password && form.errors && Boolean(form.errors.target.password)}
          onChange={handleTargetPasswordChangeManual}
          onClick={event=> {handleMouseClick("infraTargetPassword")}}
          onBlur={event=> {handleMouseOut("infraTargetPassword")}}
          endAdornment={form.values.target.password && InfraUserPassAutocomplete ?
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                aria-autocomplete="none"
                onClick={() => handleClickShowPassword("infraTarget")}
              >
                {targetpasswordVisibility ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
            : null}
        />
      </FormControl>
      </Tooltip>
    );
  }

  const showInfraSource = () => {
    if (isAzure)
      return false;
    else
      return true;
  }

  const showInfraTarget = () => {
    if (isPaaS || isAzure)
      return false;
    else
      return true;
  }

  const loadInfraSecondRow = () => {
      if(isAWS)
      {
          return (<React.Fragment>
             <Grid container item xs={12} direction='row' spacing={2} style={{paddingTop:'3%'}}>
               <Grid item xs={11}>
                 {loadInfraTargetAWS()}
               </Grid>
               <Grid item xs={1} style={{ paddingBottom: '12px', height: '74px', paddingLeft: '3%' }}>
                 {loadInfraDrawer()}
               </Grid>
             </Grid>
             
           </React.Fragment>)
      }
      else
      {
        return (<React.Fragment>
       
          <Grid item xs={6} style={{paddingTop:'3%'}}>
            {loadInfraSource()}
          </Grid>
         
           <Grid container item xs={6} direction='row' spacing={2} style={{paddingTop:'3%'}}>
             <Grid item xs={10}>
               {loadInfraTarget()}
             </Grid>
             <Grid item xs={2} style={{ paddingBottom: '12px', height: '74px', paddingLeft: '3%' }}>
               {loadInfraDrawer()}
             </Grid>
           </Grid>
           
         </React.Fragment>)
      }
      
      
  }

  const loadInfraThirdRow = () => {
    if (!isPaaS && !isAzure)
      return (
        <React.Fragment>
          <Grid item xs={6} style={{ paddingTop: '2%' }}>
            {loadInfraTargetUsername()}
          </Grid>
          <Grid container item xs={6} direction='row' spacing={1} style={{ paddingTop: '2%' }}>
            <Grid item xs={10} style={{ paddingRight: '12px' }}>
              {loadInfraTargetPassword()}
            </Grid>
            <Grid item xs={2} style={{ paddingTop: '14px', paddingBottom: '12px', paddingLeft: '13px' }}>
            </Grid>
          </Grid>
        </React.Fragment>
      )
    else {
      if (isAzure)
        return (
          <React.Fragment>
            <Grid item xs={6} style={{ marginTop: '16px' }}>
              {loadInfraTargetUsername()}
            </Grid>
            <Grid item xs={6} style={{ marginTop: '16px' }}>
              {loadInfraTargetPassword()}
            </Grid>
          </React.Fragment>
        )
      else return (
        <React.Fragment>
          <Grid item xs={6} style={{ marginTop: '16px' }}>
            {loadInfraTargetUsername()}
          </Grid>
          <Grid item xs={5} style={{ marginTop: '16px' }}>
            {loadInfraTargetPassword()}
          </Grid>
        </React.Fragment>
      )
    }
  }

  return (
    <Grid container spacing={2} style={{ position: 'relative' }}>
 
      <Grid item xs={12} style={{ position: 'absolute', top: -10, left: 0 }}>
        {form.isValidating && <Typography variant="body2" className={classes.validationStatus}>Validating Infrastructure...</Typography>}
      </Grid>
      {/* <Grid item xs={6} style={{ marginTop: isPaaS ? '16px' : '0px' }}>
        {isPaaS ? loadInfraTenantID() : null}
      </Grid>
      <Grid item xs={6} style={{ marginTop: isPaaS ? '16px' : '0px' }}>
      </Grid> */}
      {loadInfraSecondRow()}
      {loadInfraThirdRow()}
    </Grid>

  );
};

export default observer(InfraCreateComponent)