import React, { useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/root.store';
import { Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, makeStyles, MenuItem, Select, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DataMover, JOB_TYPE_NAME, PlatformOptions } from '../../../app/models/BaseSettings.enum';
import { InfrastructureType, JobType, LicenseType } from '../../../app/models/jobProperties.model';
import { EDRTextField } from "../../../app/common/EDRTextField";
import { useFormik } from 'formik';
import * as yup from 'yup';
import ConfirmDialog from './ConfirmDialog.component';
import jobAgent from '../../../app/api/jobAgent';
import ReactDOM from 'react-dom';
import { EDROutlinedInput } from '../../../app/common/EDROutlinedInput';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import { toast } from 'react-toastify';
import { debounce } from '../../../app/common/util/util';
import CreateJobConfigurationComponent, { loadIcon } from '../CreateJobConfiguration.component';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import JobSettingsStore from '../../../app/stores/jobSettings.store';
import { License } from '../../../app/models/createjob/createJob.model';
import Create from '@material-ui/icons/Create';
import moment from 'moment';
import LicenseComponent from './License.component';
import LicenseCreateComponent from './LicenseCreate.component';
import { runInAction } from 'mobx';
import useLicensesList from './hooks/useLicenseList';

const validateDuplicateJobname: (value, resolve) => Promise<boolean> = debounce( async function (value, resolve) {
 
    try{
        const res = await jobAgent.JobActions.CheckJobNameNotExist(value);
        resolve(!Boolean(res));
    } catch{
      resolve(false);
    }
      
  
}, 400) as (value) => Promise<boolean>;
const BaseMandatoryComponent: React.FC = () => {
    let labelRef;
    const rootStore = useContext(RootStoreContext);
    const {jobSettingsStore,displayStore} =rootStore;
    const JobTypeOptions: Array<JobType> = [0, 1, 2];
    const {dataMover}= rootStore.createJobStore.baseSettings;
    const [ImageColor, setImageColor] = useState(false);
    const [openConfirmDialog, setopenConfirmDialog] = useState(false);
    const [switchAdapter,setSwitchAdapter]=useState(false);
    const [passwordVisibility,setPasswordVisibility] = useState(true);
    const [dialogOpen,setDialogOpen]=useState(false);
    const isEdit= window.location.pathname.includes('editJob');
    const [t] = useTranslation("createJobBase");
    const LicenseKeyOptions = jobSettingsStore.licensesList;
    const dateFormat = "DD.MM.YY";
    const selectedLicense = jobSettingsStore.selectedLicense;
    const [licenseList, isLicenseLoading] = useLicensesList();
    useEffect(() => {
    runInAction(() => {
        jobAgent.JobActions.CheckAnyLicense().then(res => {
          if(res)
            displayStore.setLicenseEditMode(true);
        });
    });
  }, [])
    
  useEffect(() => {
    runInAction(() => {
        jobAgent.JobActions.CheckAnyLicense().then(res => {
          if(res)
            displayStore.setLicenseEditMode(true);
        });
    });
  }, [jobSettingsStore.anyLic])

  
    const useStyles = makeStyles(() => ({
      editIcon: {
        cursor: 'pointer'
    },
      button: {
        height: '100%',
        borderRadius: '4px',
        border: 'solid 1px #c7cfdb',
        backgroundColor: '#fafafc'
      },
      advanced: {
        width: '14px',
        height: '14px',
        objectFit: 'contain',
      },  
      formControl: {
          width: '100%',
          '& label': {
            background: "white",
            width: "auto",
            padding: "0 4px 0 4px",
            fontSize: '17px'
          }
        }
      }));
    const classes = useStyles();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    const validation = yup.object({
        jobName: yup.string()
        .max(30, 'up to 30 characters')
        .required('Required')
        .test('empty','job name must be not empty',jobName => jobName !=='undefined')
        .matches(/^[a-zA-Z0-9-_]*$/,"job name should conatains letters,numbers,hypen and underscore only")
        /*.test('duplicate',"Job with this name already exists", function(value) {
          return new Promise((res, rej) => validateDuplicateJobname(value, res))
        })*/,
        licenseKey: yup.string(),
        licenseId:yup.number()
      })
    
      const form = useFormik({
        initialValues: {
            platform:{
              infrastructure: InfrastructureType.VMware,
              datamover: DataMover.SRM
            },
            jobType:0,
            jobName: '',
            licenseId:0,
            licenseKey: '',
        },
        validationSchema:validation,
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: () => { },
      })


    useEffect(() => {
        const initialValues=
        {
          platform:{
            infrastructure: rootStore.createJobStore.baseSettings.infrastructureType,
            datamover: rootStore.createJobStore.baseSettings.dataMover
          },
          jobType:rootStore.createJobStore.baseSettings.jobType,
          jobName: rootStore.createJobStore.baseSettings.jobName,
          licenseKey:selectedLicense == null ? 'Add New License' : selectedLicense.name,
          licenseId:selectedLicense == null ? 0 : selectedLicense.id
        };
        form.setValues(initialValues);
        //rootStore.createJobStore.updateBaseSettings({licenseId: form.values.licenseId});
      }, [rootStore.createJobStore.baseSettings]); 
      
    useEffect(() => {
         displayStore.setLicenseEditMode(licenseList.length > 0 ? true:false);
    },[licenseList])
    
    useEffect(() => {
      const isLicenseValid = selectedLicense != null && Boolean(selectedLicense.id);
      displayStore.updateNextStepValid({licenseValid:isLicenseValid});
      displayStore.updateNextStepValid({jobNameValid:form.isValid});
    },[form.isValid, selectedLicense, form.isValidating])

      const handleValueChange = (event: React.ChangeEvent) => {
        form.handleChange(event);
        switch(event.target['name']) {
          case 'jobName': handleJobNameChange(event); break;
          case 'licenseKey': handleLicenseKeyChange(event); break;
        }
      }
      const buttonRedirect= (subComponent:any,mode?:string)=> {
        switch(subComponent)
        {
          case "BasicAdvanced":
            {
              if(displayStore.BaseDrawerSettings.BasicDrawerOpen)
              {
                displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
                displayStore.updateBaseDrawerSettings({BasicDrawerOpen: false});
              }  
            else
            {
                displayStore.updateScreenSettings({displayBaseSettings: subComponent});
                displayStore.updateBaseDrawerSettings({BasicDrawerOpen: true});
                displayStore.closeAllOtherBaseDrawersExcept("BasicDrawer");
            }
              break;
            }
          case "LicenseAdvanced":
            {
              if(displayStore.BaseDrawerSettings.LicenseDrawerOpen)
              {
                if(mode === "edit")
                {
                  displayStore.updateBaseDrawerSettings({addLicense: mode ==="edit" ?false:true});
                  displayStore.updateScreenSettings({displayBaseSettings: subComponent});
                }
                else if(mode === "add")
                {
                  displayStore.updateBaseDrawerSettings({addLicense: mode ==="add" ?true:false});
                  displayStore.updateScreenSettings({displayBaseSettings: subComponent});
                }
                else
                {
                  displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
                  displayStore.updateBaseDrawerSettings({LicenseDrawerOpen: false});
                }
              }  
            else
            {
                displayStore.updateBaseDrawerSettings({addLicense: mode ==="add" ?true:false});
                displayStore.updateScreenSettings({displayBaseSettings: subComponent});
                displayStore.updateBaseDrawerSettings({LicenseDrawerOpen: true});
                displayStore.closeAllOtherBaseDrawersExcept("LicenseDrawer");
            }
            break;
            }
        }
    };

    const CheckSectionAndDrawerStatus= () =>{
      if(displayStore.screenSettings.displayBaseSettings=='DataMoverAdvanced' || displayStore.screenSettings.displayBaseSettings=='WorkflowAdvanced' || 
      displayStore.screenSettings.displayBaseSettings=='InfrastructureAdvanced' || displayStore.screenSettings.displayBaseSettings=='InfrastructureCreateAdvanced' || displayStore.screenSettings.displayBaseSettings=='DataMoverCreateAdvanced')
            {
              displayStore.updateScreenSettings({displayBaseSettings: "EmptyVlan"});
              displayStore.updateBaseDrawerSettings({InfraDrawerOpen: false});
              displayStore.updateBaseDrawerSettings({PrimaryUserDrawerOpen: false});
              displayStore.updateBaseDrawerSettings({SecondaryUserDrawerOpen: false});
              displayStore.updateBaseDrawerSettings({BasicDrawerOpen: true});
              displayStore.updateBaseDrawerSettings({DataMoverDrawerOpen: false});
              displayStore.updateBaseDrawerSettings({WorkflowDrawerOpen: false});
              displayStore.updateBaseDrawerSettings({EDRDrawerOpen: false});
            }
    }

      const handleJobNameChange= (event)=>{
        rootStore.createJobStore.updateBaseSettings({jobName: event.target.value});
        if(form.values.jobName && !Boolean(form.errors.jobName))
        {
          displayStore.updateNextStepValid({jobNameValid:true});
        }
        //displayStore.updateSectionSettings({platformSection:false});
          CheckSectionAndDrawerStatus();
      };
    
      const handleLicenseKeyChange= (event)=>{
        if(displayStore.BaseDrawerSettings.LicenseDrawerOpen)
        {
          displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
          displayStore.updateBaseDrawerSettings({LicenseDrawerOpen: false});
        }  
        form.setFieldValue("licenseKey",event.target.value);
        
        let lic = jobSettingsStore.licensesList.find(l => l.name === event.target.value);
        jobSettingsStore.updateSelectedLicense(lic);
        rootStore.createJobStore.updateBaseSettings({licenseName: lic && lic != null && lic != undefined ? lic.name : "", licenseId: lic && lic != null && lic != undefined ? lic.id : 0});
        CheckSectionAndDrawerStatus();
      };

      const handleInfraDatamoverChange = () => {
        setDialogOpen(true);
      };


      const loadJobTypeMenuOptions =() =>{
        if(dataMover === DataMover.SRM || dataMover === DataMover.ZertoVmWare || dataMover == DataMover.VeeamVmware || dataMover == DataMover.VeeamSQL || dataMover == DataMover.ASR ||dataMover == DataMover.ASRVmwareAzure)
        return (
          <MenuItem key={0} value={0}>
            {JOB_TYPE_NAME[0]}
            </MenuItem>
        )
        else
        return (JobTypeOptions.map((name) => (
          <MenuItem key={name} value={name}>
            {JOB_TYPE_NAME[name]}
          </MenuItem>
        )))
      };
    
      const handleJobTypeChange = (event) => {
        form.setFieldValue("jobType",event.target.value);
        rootStore.createJobStore.updateBaseSettings({jobType: event.target.value});
        CheckSectionAndDrawerStatus();
      };

      const handleClickShowPassword  = () => {
        setPasswordVisibility(passwordVisibility? false: true);
      };

      const loadPlatformName= ()=>{
        let platform = PlatformOptions.find(platform=>platform.datamover == form.values.platform.datamover && platform.infra == form.values.platform.infrastructure);
        if(platform !=undefined)
          return `${platform.name.split(" ")[0]}_${platform.name.split(" ")[1]}`;
        else
          return "";
      }

      const ClickableOption = (datamover:DataMover)=> {
        if(datamover == DataMover.CustomAzure || datamover == DataMover.CustomAWS || datamover== DataMover.CustomVmware || datamover== DataMover.Netapp|| datamover== DataMover.AWSDRS || datamover== DataMover.Recoverpoint || datamover== DataMover.ZertoVmWare || datamover == DataMover.SRM || datamover == DataMover.VeeamVmware || datamover == DataMover.VeeamSQL || datamover == DataMover.CohesityRunbook || datamover == DataMover.ASR || datamover == DataMover.ZertoVmwareAzure)
            return true;
        else
            return false;
    }

return(
    <Grid container spacing={1} style={{}}>
    <CreateJobConfigurationComponent open={dialogOpen} onClose={()=>setDialogOpen(false)}/>
    <Grid container item direction='row' spacing={2}>
      <Grid item xs={6}>
      <FormControl className={classes.formControl} variant='outlined' style={{backgroundColor:'#ffffff',paddingLeft:jobSettingsStore.anyLic && displayStore.licenseEditMode ?'17%':0}} >
      <InputLabel style={{transform:jobSettingsStore.anyLic && displayStore.licenseEditMode ? 'translate(85px, -6px) scale(0.75)':'translate(14px, -6px) scale(0.75)'}}>{t('platform')}</InputLabel>
      <Tooltip title={loadPlatformName()}>
        <Button 
        variant="outlined" 
        id="platform"
        disabled={isEdit}
        style={{textTransform:'none',height:'100%',cursor:!isEdit ? 'pointer':'none'}}
        onClick={!isEdit ? handleInfraDatamoverChange:null}><img src={loadIcon(form.values.platform.datamover,ClickableOption(form.values.platform.datamover))}/>
        </Button>
        </Tooltip>
      </FormControl>
      </Grid>
      
      <Grid container item xs={6} direction='row' spacing={2}>
      <Grid item xs={10}>
      <FormControl className={classes.formControl} variant='outlined'>
          <EDRTextField id="job-name"
            name="jobName"
            label={t('jobName')}
            placeholder="Type Here"
            value={form.values.jobName}
            disabled={true}
            onChange={handleValueChange}
            onBlur={form.handleBlur}
            error={Boolean(form.touched.jobName && form.errors.jobName)}
            helperText={form.touched.jobName && t(form.errors.jobName)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            variant="outlined">
          </EDRTextField>
          </FormControl>
      {/*<FormControl className={classes.formControl} variant='outlined' style={{backgroundColor:'#ffffff'}}>
            <InputLabel>{t('jobType')}</InputLabel>
            <Select
              labelId="jobType"
              value={form.values.jobType}
              displayEmpty={true}
              renderValue={name => name<0? 'None': JOB_TYPE_NAME[name as string]}
              onChange={handleJobTypeChange}>
              {loadJobTypeMenuOptions()}
            </Select>
          </FormControl>*/}
        </Grid>
        <Grid item xs={2} style={{paddingBottom:'12px',height:'74px',paddingLeft:'3%'}}>
          <Button className={classes.button} disabled={true} onClick={()=> {buttonRedirect('BasicAdvanced')}}>
          <img className={classes.advanced} onMouseEnter={()=> setImageColor(true)} onMouseLeave={()=> setImageColor(false)} src={ImageColor ? '/assets/images/createjob/advancedNetworkBlue.svg':'/assets/images/createjob/advancedNetwork.svg'}></img>
          </Button>
        </Grid>
      </Grid>
    </Grid>
    {!isLicenseLoading && (licenseList.length > 0 ? <LicenseComponent licenseList={licenseList}></LicenseComponent>:<LicenseCreateComponent></LicenseCreateComponent>)}
    <ConfirmDialog open={openConfirmDialog} onClose={() => setopenConfirmDialog(false)}/>
  </Grid>
);
};

export default observer(BaseMandatoryComponent); 