import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import { DataMover } from '../../../app/models/BaseSettings.enum';
import { TestNames, TestType } from '../../../app/models/jobTest.model';
import { useContext} from 'react'
import { RootStoreContext } from '../../../app/stores/root.store';
import { NetworkOption } from '../../../app/models/jobProperties.model';

const initalValues= {
    power: true,
    network: true,
    service: true,
    custom: true,
    autofix: true,
    database: false,
    web: false,
    internet: true,
    branch: false,
    drResources: true,
    PreScriptRes:false,
    authentication:false,
    thirdDeviceNetwork:false
}

const initalValuesAzure= {
    power: false,
    network: false,
    service: false,
    custom: false,
    autofix: false,
    database: true,
    web: false,
    internet: false,
    branch: false,
    drResources: false,
    PreScriptRes:false,
    authentication:false, 
    thirdDeviceNetwork:false
}

const initalValuesVeeamSql={
    power: false,
    network: false,
    service: false,
    custom: true,
    autofix: true,
    database: false,
    web: false,
    internet: false,
    branch: false,
    drResources: false,
    PreScriptRes:false,
    authentication:false, 
    thirdDeviceNetwork:false
}

const OnlyPowerOn= {
    power: true,
    network: false,
    service: false,
    custom: false,
    autofix: false,
    database: false,
    web: false,
    internet: false,
    branch: false,
    drResources: false,
    PreScriptRes:false,
    authentication:false,
    thirdDeviceNetwork:false
}

const AllPowerOff= {
    power: false,
    network: false,
    service: false,
    custom: false,
    autofix: false,
    database: false,
    web: false,
    internet: false,
    branch: false,
    drResources: false,
    PreScriptRes:false,
    authentication:false,
    thirdDeviceNetwork:false
}

export type SelectedTest =  {
    [key in TestNames]: boolean
  }

export default function useDeviceTests(datamoverType: DataMover): [SelectedTest,  Dispatch<SetStateAction<SelectedTest>>] {
    const [deviceTests, setDeviceTests] = useState<SelectedTest>({...initalValues});
    const rootStore = useContext(RootStoreContext);

    useEffect(() => {
        if(window.location.href.includes('edit') || rootStore.createJobStore.selectedDevices.length > 0)
        {
            if(rootStore.createJobStore.selectedDevices.length == 0)
            {
                switch(datamoverType) {
                    case DataMover.PaaS: setDeviceTests({...initalValuesAzure}); break;
                    case DataMover.VeeamSQL: setDeviceTests({...initalValuesVeeamSql}); break;
                    default: 
                    {
                    if(rootStore.createJobStore.baseSettings.workflowSetting.serverTest === false)
                        setDeviceTests({...AllPowerOff});
                    if(rootStore.createJobStore.networkSetting.NetworkType === NetworkOption.None)
                        setDeviceTests({...OnlyPowerOn});
                    else
                        setDeviceTests({...initalValues});
                    break;
                    }
                }
            }
            else
            {
                let countPower = 0,countNetwork=0,countService=0,countCustom=0,countAutofix=0;
                rootStore.createJobStore.selectedDevices.map(device=>{
                    if(device.tests.find(test=>test.testType === TestType.power).selected)
                        countPower =countPower+1 ;
                    if(device.tests.find(test=>test.testType === TestType.network).selected)
                        countNetwork= countNetwork+1 ;
                    if(device.tests.find(test=>test.testType === TestType.service).selected)
                        countService= countService+1 ;
                    if(device.tests.find(test=>test.testType === TestType.custom).selected)
                        countCustom=countCustom+1 ;
                    if(device.tests.find(test=>test.testType === TestType.autofix).selected )
                        countAutofix =countAutofix+1 ;
                })
                const tests = {
                    power: countPower !== 0 ? true:false,
                    network: countNetwork  !== 0  ? true:false,
                    service: countService  !== 0  ? true:false,
                    custom: countCustom  !== 0  ? true:false,
                    autofix: countAutofix  !== 0  ? true:false,
                    database: false,
                    web: false,
                    internet: rootStore.createJobStore.ICTestActive,
                    branch: false,
                    drResources: rootStore.createJobStore.DrResTestActive,
                    PreScriptRes:false,
                    authentication:false,
                    thirdDeviceNetwork:false
                }
                setDeviceTests({...tests});
            }
        }
        else
        {
            switch(datamoverType) {
                case DataMover.PaaS: setDeviceTests({...initalValuesAzure}); break;
                case DataMover.VeeamSQL: setDeviceTests({...initalValuesVeeamSql}); break;
                default: 
                {
                if(rootStore.createJobStore.baseSettings.workflowSetting.serverTest === false)
                    setDeviceTests({...AllPowerOff});
                if(rootStore.createJobStore.networkSetting.NetworkType === NetworkOption.None)
                    setDeviceTests({...OnlyPowerOn});
                else
                    setDeviceTests({...initalValues});
                break;
                }
            }
        }
    }, [])

    return [deviceTests, setDeviceTests] 
}