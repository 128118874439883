import {Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { RootStoreContext } from "../../../app/stores/root.store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import Create from "@material-ui/icons/Create";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { DataMover } from "../../../app/models/BaseSettings.enum";
import { useFormik } from "formik";
import { useLocation } from 'react-router';
import { UpdateType } from "../../../app/stores/activityStore";

const WorkflowPrimaryComponent: React.FC = () => {

    const rootStore = useContext(RootStoreContext);
    const {createJobStore, activityStore,jobSettingsStore,displayStore} = rootStore;
    const {dataMover}=createJobStore.baseSettings;
    const credsOptions = jobSettingsStore.accessCredentials;
    const [t] = useTranslation("createJobBase");
    const location = useLocation();
    const isEditMode = () => {
      return location.pathname.includes("edit");
    }
    const useStyles = makeStyles(() => ({
        editIcon: {
            cursor: 'pointer'
        },
        button: {
          height: '100%',
          borderRadius: '4px',
          border: 'solid 1px #c7cfdb',
          backgroundColor: '#fafafc'
        },
        advanced: {
          width: '14px',
          height: '14px',
          objectFit: 'contain',
        },
        formControl: {
          width: '100%',
          '& label': {
            background: "white",
            width: "auto",
            padding: "0 4px 0 4px",
            fontSize: '17px'
          },
          '& .Mui-error': {
            color: 'red',
        },
         '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red'
        }
        }
    }));
    useEffect(() => {
      activityStore.on(UpdateType.ReValidate, Validate);
      return () => {
          activityStore.off(UpdateType.ReValidate,Validate);
      }
    }, [])
    const Validate =()=>{
    
          jobSettingsStore.ValidateCredentials();
    }
    const classes = useStyles();

    const form = useFormik({
        initialValues: 
        {
          PrimaryUser:'', //createJobStore.baseSettings.workflowTest.PrimaryUser,
          PrimaryPassword:'',//createJobStore.baseSettings.workflowTest.PrimaryPassword,
          accessCredentialsId:0,//createJobStore.baseSettings.workflowTest.accessCredentialsId,
        },
        validateOnChange: true,
        onSubmit: () => { },
      })
      
    const loadCircularProgress =()=>{
        return <div style={{width:'100%',display:'flex'}}>
        {t('PrimaryInProgress')}
        <CircularProgress id="primaryLoading" size={18} style={{color: '#2892D7', marginLeft: '10px',marginTop: '-3px'}}/>
        </div>
    }

    const buttonRedirect= (subComponent:any)=> {
        switch(subComponent)
        {
          case "PrimaryAdvanced":
            {
              if(displayStore.BaseDrawerSettings.PrimaryUserDrawerOpen || displayStore.BaseDrawerSettings.SecondaryUserDrawerOpen)
              {
                if(displayStore.BaseDrawerSettings.PrimaryUserDrawerOpen)
                {
                if(displayStore.BaseDrawerSettings.addPrimaryUser)
                {
                  displayStore.updateScreenSettings({displayBaseSettings: subComponent});
                  displayStore.updateBaseDrawerSettings({addPrimaryUser: false});
                  displayStore.updateBaseDrawerSettings({PrimaryUserDrawerOpen: true});
                  displayStore.closeAllOtherBaseDrawersExcept("PrimaryUserDrawer");
                }
                else
                {
                  displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
                  displayStore.updateBaseDrawerSettings({PrimaryUserDrawerOpen: false});
                }
              }
              else
              {
                displayStore.updateScreenSettings({displayBaseSettings: subComponent});
                displayStore.updateBaseDrawerSettings({addPrimaryUser: false});
                displayStore.updateBaseDrawerSettings({PrimaryUserDrawerOpen: true});
                displayStore.closeAllOtherBaseDrawersExcept("PrimaryUserDrawer");
              }
              }
              else
              { 
                displayStore.updateScreenSettings({displayBaseSettings: subComponent});
                displayStore.updateBaseDrawerSettings({addPrimaryUser: false});
                displayStore.updateBaseDrawerSettings({PrimaryUserDrawerOpen: true});
                displayStore.closeAllOtherBaseDrawersExcept("PrimaryUserDrawer");
              }  
          break;
            }
        }
    };

    const handleAddPrimary =()=>{
      displayStore.updateScreenSettings({displayBaseSettings: "PrimaryAdvanced"});
      displayStore.updateBaseDrawerSettings({addPrimaryUser: true});
      displayStore.updateBaseDrawerSettings({PrimaryUserDrawerOpen: true});
      displayStore.closeAllOtherBaseDrawersExcept("PrimaryUserDrawer");
    }
    
    useEffect(() => {
      let initialValues;
      initialValues =
      {
          PrimaryUser:jobSettingsStore.selectedPrimaryUser && jobSettingsStore.selectedPrimaryUser.userName,
          PrimaryPassword:jobSettingsStore.selectedPrimaryUser && jobSettingsStore.selectedPrimaryUser.password,
          accessCredentialsId:jobSettingsStore.selectedPrimaryUser && jobSettingsStore.selectedPrimaryUser.id,
      };
     
      form.setValues(initialValues);
    }, [createJobStore.baseSettings, jobSettingsStore.accessCredentials]);
    
    useEffect(() => {
      jobSettingsStore.getCredentials()
    }, [])
    
        
    useEffect(() => {
      if(credsOptions.length == 0)
        return;
      
      let cred = credsOptions.find(d => d.id == createJobStore.baseSettings.workflowTest.PrimaryUserCredsId);
      if(!cred && !isEditMode()) {
          setUser(credsOptions[0]);
          if(!jobSettingsStore.CredsValidationInProgress && !displayStore.nextStepValid.primaryValid)
          {
              jobSettingsStore.ValidateCredentials();
          }
      } 
      else {
          setUser(cred);
          if(!jobSettingsStore.CredsValidationInProgress && !displayStore.nextStepValid.primaryValid)
          {
              jobSettingsStore.ValidateCredentials();
          }
      }
    }, [jobSettingsStore.accessCredentials])

    const setUser=(cred: any) => {
      if(!cred)
        return;
        form.setFieldValue("PrimaryUser", cred.userName);
        form.setFieldValue("PrimaryPassword", cred.password);
        form.setFieldValue("accessCredentialsId", cred.id);
        jobSettingsStore.updateSelectedPrimaryUser(cred.userName);
        jobSettingsStore.updateSelectedPrimaryUserId(cred.id);
        jobSettingsStore.updateSelectedPrimaryPassword(cred.password);
        createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest, PrimaryUserCredsId: cred.id} })
    }

    const handlePrimaryUsernameChange= (event: any)=>{
      if(displayStore.BaseDrawerSettings.PrimaryUserDrawerOpen)
      {
        displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
        displayStore.updateBaseDrawerSettings({PrimaryUserDrawerOpen: false});
      }  
      let user = jobSettingsStore.accessCredentials.find(l => l.id === event.target.value);
      setUser(user);
      createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest, PrimaryUserCredsId: user.id}});
      jobSettingsStore.ValidateCredentials()
    }

return(
    <React.Fragment>
      <Grid container item direction='row' spacing={2} style={{marginTop:'1%'}}>
      <Grid item xs={1} style={{display:'flex',alignItems:'center'}}>
      <IconButton disabled={true} onClick={()=> {handleAddPrimary()}}>
        <AddCircleOutlineIcon />
      </IconButton>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={11}>
        <FormControl className={classes.formControl} variant='outlined' style={{backgroundColor:'#ffffff',paddingTop:'1%',width:'97%'}}>
          <InputLabel style={{transform:'translate(14px, 2px) scale(0.75)'}}>{t(dataMover=== DataMover.VeeamSQL? 'workflowUser': 'primaryUser')}</InputLabel>
		      <Select
                name="accessCredentialsId"
                required={true}
                labelId="accessCredentialsId"
                disabled={true}
                value={form.values.accessCredentialsId}
                onBlur={form.handleBlur}
                error={form.touched?.accessCredentialsId && Boolean(form.errors?.accessCredentialsId)}
                onChange={handlePrimaryUsernameChange}>
                {credsOptions.map((data) => (
                <MenuItem key={data.id} value={data.id}>
                {`${data.userName}`}
                </MenuItem>))}
            </Select>
          <em style={{fontSize: 12, fontStyle:"italic", color: "red"}}>{form.touched?.accessCredentialsId && form.errors.accessCredentialsId}</em>
        </FormControl>
        </Grid>

        <Grid item xs={1} style={{paddingTop:'5px'}}>
          <Button  disabled={true} className={classes.button} onClick={()=> {buttonRedirect('PrimaryAdvanced')}}>
          <Create className={classes.editIcon} ></Create>
          </Button>
        </Grid>

      </Grid>
    </Grid>
   </React.Fragment>
);

};

export default observer(WorkflowPrimaryComponent); 