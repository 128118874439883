import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import {CircularProgressbarWithChildren,buildStyles,CircularProgressbar} from "react-circular-progressbar";
import TrendingDownIcon from "../../../assets/dashboardAssets/downTrend.svg";
import { INotice } from "../../../app/models/dashboard/dashboard.model";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core";

const RealDownTimeComponent = (props: INotice) => {
  const [t] = useTranslation("dashboard");

  useEffect(() => {
    //callMatomo("NoticeSpinner","/dashboard");
  }, []);

  //the calculation convert all hours to minuts and then use real downtime as the whole 100%
  //    and devide by it and then by 2 for half of the circle
  //todo: dudu - refactor this calculations!!!
  const index = props.realDowntime
    ? props.realDowntime
        .toString()
        .split(":")
        .map(Number)
        .reduce((acc, time) => 60 * acc + time)
    : 0;
  let secondValue;
  let thirdValue;
  if (index > 0) {
    secondValue =
      (props.rpa
        .toString()
        .split(":")
        .map(Number)
        .reduce((acc, time) => 60 * acc + time) /
        index /
        2) *
      100;
    thirdValue = (props.rta / index / 2) * 100;
  }
  if (secondValue === 0 || thirdValue > 50 || secondValue === undefined || secondValue === null) {
    secondValue = 30;
    thirdValue = 20;
  }

  const Rta = Math.floor(props.rta / 3600)> 9 ? Math.floor(props.rta / 3600) : "0" + Math.floor(props.rta / 3600) + ":" + (Math.floor(props.rta / 60) > 9 ? Math.floor(props.rta / 60) : "0" + Math.floor(props.rta / 60));

  const RealDownTime = Math.floor(props.rta / 3600)> 9 ? Math.floor(props.rta / 3600) : "0" + Math.floor(props.rta / 3600) + ":" + (Math.floor(props.rta / 60) > 9 ? Math.floor(props.rta / 60) : "0" + Math.floor(props.rta / 60));
  

  const useStyles = makeStyles({
    noticeSpinner: {
      fontFamily: "Roboto"
    },
    text: {
      color: "#1f396d",
      fontSize: "0.74rem",
    },
    secondHeader:{
      margin: "5%",
      paddingTop:"12%",
      paddingBottom:"4%",
      fontSize: "2rem",
      fontWeight: 500,
      color: "#1f396d",
    },
    spinners:{
      paddingTop:"2em",
      paddingRight:"2em",
    },
    icon: { 
      width: "14px",
      height: "14px", 
      marginTop:"1em",
      marginRight:"0.5em", 
      objectFit: "contain"
    } 
  });

  const classes = useStyles();

  return (
    <Grid container direction="row" data-test="component-realdowntime" className={classes.noticeSpinner}>
      <Grid item style={{height:"112px"}} container direction="column" alignContent="space-between" xs={8}>
        <Grid item style={{ height: "40%",marginLeft: "24px"}}>
          <img src={TrendingDownIcon} className={classes.icon}/>
          <span className="dashboard_header_icon">{props.header}</span>
        </Grid>
        <Grid item style={{ height: "30%",marginLeft: "24px"}} className="boldText">
          <span className="firstPipe">|</span> {RealDownTime}{" "}
          {t("hr")}
        </Grid>
        <Grid item style={{ height: "30%",marginLeft: "24px"}} className={classes.text}>
          <span className="secondPipe">|</span> {t("rpa")}:{" "}
          {props.rpa} {t("hr")}{" "}
          <span className="thirdPipe">|</span> {t("rta")}:{" "}
          {Rta} {t("hr")}
        </Grid>
      </Grid>
      <Grid item xs={4} className={classes.spinners}>
      <div style={{ width: "70px" }}>
        <CircularProgressbarWithChildren
          value={100} //this value is always 50!
          strokeWidth={8}
          styles={buildStyles({
            pathColor: "#2892d7",
            trailColor: "#ebeef4",
            
          })}
          circleRatio={0.75}
        >
          {/*
                    Width here needs to be (100 - 2 * strokeWidth)% 
                    in order to fit exactly inside the outer progressbar.
                  */}
          <div style={{ width: "54px" }}>
            <CircularProgressbarWithChildren
              value={secondValue * 2}
              styles={buildStyles({
                pathColor: "#2ca58d",
                trailColor: "#ebeef4",
              })}
              circleRatio={0.75}
            >
              <div style={{ width: "40px" }}>
                <CircularProgressbar
                  value={thirdValue * 2}
                  styles={buildStyles({
                    pathColor: "#077187",
                    trailColor: "#ebeef4"
                  })}
                  circleRatio={0.75}
                />
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </CircularProgressbarWithChildren>
        </div>
      </Grid>
    </Grid>
  );
};

export default observer(RealDownTimeComponent);
