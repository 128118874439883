import React, { useState, useContext, useEffect } from 'react';
import DRResourceSectionComponent from '../report/drResourceSection.component';
import { RootStoreContext } from '../../app/stores/root.store';
import { observer } from 'mobx-react-lite';
import { Grid, Typography, makeStyles, withStyles, LinearProgress } from '@material-ui/core';
import { IopsIcon, CPUIcon, RamIcon } from '../../app/common/drResource/resourcesIcon.component';

import ResourceProgressBar from '../../app/common/drResource/dRProgressbar.component';
import { useTranslation } from 'react-i18next';

const EDRProgressbar = withStyles({
    root: {
        backgroundColor: "rgba(190,185,185, 0.45)",
        borderRadius: 20
    },
    bar: {
        backgroundColor: '#2892d7',
    },
    colorPrimary: {
        paddingRight: 10
    }
})(LinearProgress);

const useStyles = makeStyles({
    icon: {
        color: "#8092a9"
    },
    disabled: {
        background: "lightGray"
    },
    root: {
        minHeight:120,
        background: 'rgba(0,0,0,0.66)',
        padding: 15,
        '& p': {
            color: 'white'
        },
        '& svg': {
            color: 'white'
        },
 
    },
    arrowDown: {
     
    }
});

const renderProgressBar = (value) => <EDRProgressbar variant="determinate" style={{ color: "#2892d7" }} value={value} />;
const DrResourceOverlayComponent: React.FC = () => {
    const { dashboardStore, settingStore } = useContext(RootStoreContext);
    const { dashboardSettings } = settingStore;
    const maxIops = dashboardSettings.find(s => s.settingKey == 'SetIOPS');
    const [t] = useTranslation("dashboard");
    const classes = useStyles();
    const [resData, setresData] = useState({ iops: 0, iopsMax: 1, ram: 0, rammax: 1, cpu: 0, cpuMax: 1 });

    useEffect(() => {
        if (dashboardStore.jobResults.length > 0) {
            const resourcesSum = dashboardStore.jobResults.reduce((acc, jobRun) => {
                acc.cpu += jobRun.cpuActual;
                acc.cpuMax += jobRun.cpuCapacity;
                acc.ram += jobRun.ramActual;
                acc.rammax += jobRun.ramCapacity;
                acc.iops += jobRun.iopsActual;
                acc.iopsMax += jobRun.iopsCapacity;
                return acc;
            }, { iops: 0, iopsMax: 0, ram: 0, rammax: 0, cpu: 0, cpuMax: 0 })
            const jobCount = dashboardStore.jobResults.length;
            setresData({
                iops: resourcesSum.cpu / jobCount,
                iopsMax: resourcesSum.iopsMax / jobCount,
                ram: resourcesSum.ram / jobCount,
                rammax: resourcesSum.rammax / jobCount,
                cpu: resourcesSum.cpu / jobCount,
                cpuMax: resourcesSum.cpuMax / jobCount
            });
        } else {
            setresData({ iops: 0, iopsMax: 0, ram: 0, rammax: 0, cpu: 0, cpuMax: 0 });
        }
    }, [dashboardStore.jobResults])

    return <>
        <Grid className={classes.root} container direction="column" justify="space-between">
            {/* <div className={classes.arrowDow}></div> */}
            <Grid container direction="row">
                <IopsIcon className={classes.icon} />
                <Typography variant="body1">{t("DrResource.iops")}</Typography>
                <Grid item xs style={{ marginLeft: "10px" }}>
                    <ResourceProgressBar additionalTitle={null} value={resData.iops} maxValue={maxIops && parseInt(maxIops.settingValue)} unit={"IOPS"}
                        renderProgressBar={renderProgressBar} />
                </Grid>
            </Grid>
            <Grid container direction="row">
                <CPUIcon className={classes.icon} />
                <Typography variant="body1">{t("DrResource.cpu")}</Typography>
                <Grid item xs style={{ marginLeft: "10px" }}>
                    <ResourceProgressBar additionalTitle={null} value={resData.cpu} maxValue={resData.cpuMax} unit={"Ghz"} conversionFactor={1024} decimals={2}
                        renderProgressBar={renderProgressBar} />
                </Grid>
            </Grid>
            <Grid container direction="row">
                <RamIcon className={classes.icon} />
                <Typography variant="body1">{t("DrResource.ram")}</Typography>
                <Grid item xs style={{ marginLeft: "10px" }}>
                    <ResourceProgressBar additionalTitle={null} value={resData.ram} maxValue={resData.rammax} unit={"Gb"} conversionFactor={1024 * 1024} decimals={2}
                        renderProgressBar={renderProgressBar} />
                </Grid>
            </Grid>

        </Grid>
    </>
}

export default observer(DrResourceOverlayComponent);