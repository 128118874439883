
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, formatMs, FormControlLabel, Grid, IconButton, InputLabel, ListItemText, makeStyles, Switch, Tooltip, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EDRTextField } from "../../../../app/common/EDRTextField";
import { SaveButton } from "../../../../app/common/saveButton";
import { EDRDialogTitle } from "../../../../app/common/EDRDialogTitle";
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EDRAutocomplete } from '../../../../app/common/EDRAutocomplete';
import { JobDevice } from '../../../../app/models/jobDevice/jobDevice.model';
import { RootStoreContext } from '../../../../app/stores/root.store';
import { TestType } from '../../../../app/models/jobTest.model';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { DatabaseType, DevicePlatform, MachineType } from '../../../../app/models/jobProperties.model';
import HelpIcon from '@material-ui/icons/Help';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
    helpIcon:
    {
      color: '#1976d2',
      fontSize: "large"
    },
    SecondHeading:{
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        flexGrow: 1
    },
    label: {
        textTransform: 'capitalize',
    },
    root: {
        minWidth: '30vw'
    },
    checkbox: {
        marginLeft: theme.spacing(0.5)
    },
    heading:{
        fontFamily: 'Roboto',
        fontSize: '13px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontstyle: 'normal',
        lineHeight: 1.15,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#4d5f75',
    },
    divider: {
        backgroundColor: '#eaeef4',
        width: '155%',
        margin: '0',
        marginTop: '10px',
        marginLeft: '10px',
    },
    title:{
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontstyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        textAlign: 'left'
    },
    centerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between'
    },
    toggleItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiListItemText-root' : {
            marginLeft: 10
        },
        '& .MuiSvgIcon-root': {
            color: '#8092a9'
        }
    }
}));
export interface ServiceTestSettingProps {
    open: boolean;
    onSave : () => void;
    onClose: () => void;
    deviceName: string;
    isDefault :boolean;
}

const validationSchema = yup.object({
    serviceName: yup.string().required('Required'),
    isExt: yup.bool()
})
const ServiceTestConfigureDialog: React.FC<ServiceTestSettingProps> = (props) => {
    const { open,deviceName,isDefault, onClose,onSave } = props;
    const {createJobStore,jobSettingsStore} = useContext(RootStoreContext);
    const classes = useStyles();
   
    const loadServiceList =(type:string)=>{
        let serviceName=[];
        let platform = type ==="windows" ? DevicePlatform.Windows :DevicePlatform.UNIX;
        let list= jobSettingsStore.ServiceList.filter(item=>item.os == platform);
        list.map(item=>{
            serviceName.push(item.service);
        });
        return serviceName;
    }

    const getPreviousServiceNameWindows = loadServiceList("windows");
    const getPreviousServiceNameLinux = loadServiceList("linux");
    const [cancel,setCancel]=useState(false);
    const { t } = useTranslation('createJobBase');

    const form = useFormik({
        initialValues: {
            serviceNameWindows: jobSettingsStore.serviceToTest,
            serviceNameLinux: jobSettingsStore.serviceToTestLinux,
            isExt: jobSettingsStore.testFromEDRC,
        },
        onSubmit: () => { },
        validationSchema
    })

    useEffect(() => {
        let initialValues;
        initialValues =
        {
            serviceNameWindows: jobSettingsStore.serviceToTest,
            serviceNameLinux: jobSettingsStore.serviceToTestLinux,
            isExt: jobSettingsStore.testFromEDRC,
        };
        form.resetForm({values: initialValues});
      }, [open]);

    const handleClose = () => {
        if(form.dirty && form.touched)
            setCancel(!cancel);
        else
            onClose();
    }

    const handleSave=()=>{
        try
        {
            jobSettingsStore.updateServiceSettings(form.values.serviceNameWindows,form.values.isExt,form.values.serviceNameLinux);
            toast.success('Save successful');
        }
    catch(error){
        toast.success('Save failed'+error);
    }
        setCancel(false);
        onClose();
    }

    const handleTest=() =>{        
    }

    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel style={{marginLeft:'10px'}}>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const handleYes=()=>{
        let initialValues = {
            serviceNameWindows: jobSettingsStore.serviceToTest,
            serviceNameLinux: jobSettingsStore.serviceToTestLinux,
            isExt: jobSettingsStore.testFromEDRC
        };
        form.setValues(initialValues);
        setCancel(false);
        onClose();
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    const handleAutoCompleteChange = (event: React.ChangeEvent,value:any) => {
        if(event!=null)
        {
          let fieldName= event.target['id'].includes('-') ? event.target['id'].split('-')[0]: event.target['id'];
          form.setFieldValue(fieldName,value);
          switch(fieldName) {
            case 'serviceNameWindows': handleUrlChange(event,value,"windows"); break;
            case 'serviceNameLinux': handleUrlChange(event,value,"linux"); break;
          }
        }
      }
    
    const handleUrlChange=(event:object,value:any,type:string)=>{
        if(type === "windows")
            form.setFieldValue("serviceNameWindows",value,true);
        else
            form.setFieldValue("serviceNameLinux",value,true);
    }

    const handleExtChange =(event)=>{
        form.setFieldValue("isExt", event.target.checked);
    }


    return <Dialog disableBackdropClick
        open={open}
        classes={{ paper: classes.root }}
        onClose={handleClose}
        scroll="paper"
    >
        <EDRDialogTitle>
        <div style={{ display: 'flex', flexDirection: 'row'}}>
            <Typography variant="h6" className={classes.title} style={{display:'flex',alignItems:'center'}}>{'Default '+t('serviceSetting')}</Typography>
        </div>
        </EDRDialogTitle>
        <DialogContent dividers={true}>

        <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row',paddingBottom:'5%'}}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('subServiceDefaultWin')}</Typography>
                    <Tooltip title={t('serviceInfoDefaultWin')} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip> 
                    <Divider className={classes.divider} style={{width:'130%'}}/>
                </div>
            </Grid>

        <Grid container direction="column" spacing={1}>
                <Grid item container>
                    <Grid item xs={3} container alignItems="center">
                        <Typography variant="h6" className={classes.heading}>* {t('serviceName')}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                    <EDRAutocomplete id="serviceNameWindows"
                        freeSolo
                        disableClearable
                        value={form.values.serviceNameWindows}
                        options={getPreviousServiceNameWindows}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderInput={(params) => (<EDRTextField {...params} label={t('ServiceName')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="serviceNameWindows"
                        onBlur={form.handleBlur}
                        error={form.touched.serviceNameWindows && Boolean(form.errors.serviceNameWindows)}
                        helperText={form.errors.serviceNameWindows}
                    />)}
                    />
                    </Grid>
                </Grid>
            </Grid>
            
        <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row',paddingBottom:'5%',paddingTop:'5%'}}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('subServiceDefaultLinux')}</Typography>
                    <Tooltip title={t('serviceInfoDefaultLinux')} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip> 
                    <Divider className={classes.divider}/>
                </div>
            </Grid>
            
        <Grid container direction="column" spacing={1}>
                <Grid item container>
                    <Grid item xs={3} container alignItems="center">
                        <Typography variant="h6" className={classes.heading}>* {t('serviceName')}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                    <EDRAutocomplete id="serviceNameLinux"
                        freeSolo
                        disableClearable
                        value={form.values.serviceNameLinux}
                        options={getPreviousServiceNameLinux}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderInput={(params) => (<EDRTextField {...params} label={t('ServiceName')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="serviceNameLinux"
                        onBlur={form.handleBlur}
                        error={form.touched.serviceNameLinux && Boolean(form.errors.serviceNameLinux)}
                        helperText={form.errors.serviceNameLinux}
                    />)}
                    />
                    </Grid>
                </Grid>
                </Grid>

        <Grid item container xs={12} style={{paddingTop:'3%'}}>
                <Grid item xs={10}>
                <div className={classes.toggleItem}>
                    <Typography className={classes.heading} style={{marginLeft:'0px',marginTop:'3%'}}>{t("testRemote")}</Typography>
                </div>
                </Grid>
                <Grid item xs={2}>
                <div className={classes.centerVertical} style={{justifyContent:'flex-end'}}>
                <Tooltip title="Testing will be done by connecting to the remote device. you can alternatively toggle this button to test remotely from the EnsureDR controller" arrow>
                        <Switch checked={form.values.isExt} name="toggle2nd"  onChange={handleExtChange} onBlur={form.handleBlur} color='primary' />
                </Tooltip>
                </div>
                </Grid>  
            </Grid>

        </DialogContent>
        <DialogActions>
        <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end',marginRight:'2%'}}>
            <Button onClick={handleClose} color="primary" style={{textTransform:'none'}}>
                {t('cancel')}
            </Button>
            {/*<Button onClick={handleTest} disabled={form.values.serviceName==''} color="primary" style={{textTransform:'none'}}>
                {t('test')}
            </Button>
    */}
            <SaveButton onClick={handleSave} variant="contained" color="primary" style={{textTransform:'none'}}  disabled={form.values.serviceNameWindows === '' && form.values.serviceNameLinux === ''}>
                {t('save')}
            </SaveButton>
            </Grid>
        </DialogActions>
    </Dialog>
}

export default ServiceTestConfigureDialog;