import { FormControl, Grid, InputAdornment, InputLabel, makeStyles, OutlinedInput, Typography,IconButton, DialogContent, Dialog, Divider, Select, MenuItem, Button, Tooltip, Switch, ListItemText, CircularProgress } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Autocomplete } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { EDRAutocomplete } from "../../../app/common/EDRAutocomplete";
import { EDRTextField } from "../../../app/common/EDRTextField";
import { SaveButton } from "../../../app/common/saveButton";
import { DATABASE_TYPE_NAME, OS_TYPE_NAME } from "../../../app/models/BaseSettings.enum";
import { DeviceTest } from "../../../app/models/createjob/createJob.model";
import { JobDevice } from "../../../app/models/jobDevice/jobDevice.model";
import { DatabaseType, DevicePlatform } from "../../../app/models/jobProperties.model";
import { TestType } from "../../../app/models/jobTest.model";
import { RootStoreContext } from "../../../app/stores/root.store";
import { Props } from "./NetworkTestDialog.component";
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import HelpIcon from '@material-ui/icons/Help';
import jobAgent from "../../../app/api/jobAgent";

const ServiceTestDialog: React.FC<Props> = (props) => {
        const useStyles = makeStyles({
            formControl: {
                width: '100%',
                '& label': {
                    background: "white",
                    width: "auto",
                    padding:"0 4px 0 4px",
                    fontSize: '17px',
                  }
            },
            helpIcon:
            {
              color: '#1976d2',
              fontSize: "large"
            },
            divider: {
                backgroundColor: '#eaeef4',
                width: '157%',
                margin: '0',
                marginTop: '10px',
                marginLeft: '10px',
            },
            SecondHeading:{
                color:'#1f396d',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: 500,
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                textAlign: 'left',
                whiteSpace: 'nowrap',
                flexGrow: 1
            },
            centerVertical: {
                display: 'flex',
                alignItems: 'center',
                justifyContent:'space-between'
            },
            toggleItem: {
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
        
                '& .MuiListItemText-root' : {
                    marginLeft: 10
                },
                '& .MuiSvgIcon-root': {
                    color: '#8092a9'
                }
            }
            });   
        
        const loadServiceList =()=>{
            let serviceName=[];
            let list= jobSettingsStore.ServiceList.filter(item=>item.os == device.os);
            list.map(item=>{
                serviceName.push(item.service);
            });
            return serviceName;
        }
    
        const classes = useStyles();
        const rootStore = useContext(RootStoreContext); 
        const { onApply,discard,closeDrawer,reload,setParentValue } = props;
        const {createJobStore,jobSettingsStore} = useContext(RootStoreContext);
        const device= createJobStore.editedDevice;
        const ServiceTest = device.tests.find(test=> test.testType == TestType.service);
        const [processingTest,setProcessingTest] = useState(false);
        const [cancel,setCancel]=useState(false);
        const getPreviousServiceName = loadServiceList();
        const [t] = useTranslation("createJobDevices");
        const [Info] = useTranslation('createJobBase');
       
        const defaultSettings = {
            serviceName:ServiceTest.serviceToTest!="" ? ServiceTest.serviceToTest : (device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.serviceToTestLinux :jobSettingsStore.serviceToTest),
            isExt: ServiceTest.testFromEDRC!=false ? ServiceTest.testFromEDRC : jobSettingsStore.testFromEDRC
        };
    
        
    const validationSchema = yup.object({
        serviceName: yup.string().required('Required'),
        isExt: yup.bool()
    })
    
        const form = useFormik({
            initialValues: {
                serviceName: defaultSettings.serviceName,
                isExt: defaultSettings.isExt,
            },
            onSubmit: () => { },
            validationSchema
        })
    
          useEffect(() => {
            form.setFieldValue("serviceName",reload,true);
          }, [reload]); 
    
    
          const handleTest=() =>{  
            let serviceToTest= form.values.serviceName;
            var details ={ServiceName:serviceToTest,Hostname:device.sourceName,UserId:device.os === DevicePlatform.Windows ? jobSettingsStore.selectedPrimaryUser.id :jobSettingsStore.selectedSecondaryUser.id,devicePlatform:device.os};
            setProcessingTest(true);
            jobAgent.JobTestsActions.TestApplication(details).then(res => {
                if(res)
                    toast.success("Application Test succeeded");
                else
                    toast.error("Application Test failed");
                setProcessingTest(false);
            }).catch(err=>{
                if(err && err.data && err.data.errors)
                    toast.error(err.data.errors);
                setProcessingTest(false);
            });        
        }

        const handleCancle =()=>{
            if(form.dirty && form.touched)
                setCancel(!cancel);
            else
                closeDrawer();
        }
    
        const handleSave =()=> {
            try{
                let DeviceTest = {
                    testCategory:device.tests[0].testCategory, 
                    testType:TestType.service, 
                    selected: true,
                    customCommandToTest: '',
                    customCommandExpectedResult: '', 
                    customCommandToTestIsExternal: false,
                    databaseType:DatabaseType.SQL,
                    databaseNameToTest: '',
                    databaseQueryToTest: '',
                    databaseServerNameToTest: '',
                    databaseUsername:'',
                    databasePassword:'',
                    databasePort:'',
                    dbWindowsAuth:false,
                    serviceToTest:form.values.serviceName,
                    testFromEDRC: form.values.isExt,
                    script: '',
                    thirdDeviceNetworkToTest: '',
                    authenticationUserToTest:'',
                    authenticationPassToTest:'',
                    webPortalToTest: '',
                    webPortalResult: ''
                };
                createJobStore.updateTestDevice(TestType.service,DeviceTest,device.planName.includes("Custom Devices") ? device.sourceName : device.deviceName);
                onApply();
                discard();
                setParentValue(form.values.serviceName);
                toast.success(t('saveSuccessful'));
            }
            catch(error)
            {
                toast.success(t('savefailed')+error);
            }
        }
    
        const handleNo=()=>{
            setCancel(!cancel);
        }
    
        const handleYes=()=>{
            form.setFieldValue("serviceName",ServiceTest.serviceToTest!="" ? ServiceTest.serviceToTest :(device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.serviceToTestLinux :jobSettingsStore.serviceToTest),true);
            form.setFieldValue("isExt",ServiceTest.serviceToTest !="" ? ServiceTest.serviceToTest: (device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.serviceToTestLinux :jobSettingsStore.serviceToTest),true);
            discard();
            setCancel(false);
        }
    
        const loadConfirmation=()=>{
            if(cancel)
            {
                return (
                    <React.Fragment>
                       <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                            <div className={classes.centerVertical}>
                                <InputLabel>{t('disacrdChanges')}</InputLabel>
                                <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                                <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                            </div>
                        </div> 
                    </React.Fragment>
                );
            }
        }
    
        const handleAutoCompleteChange = (event: React.ChangeEvent,value:any) => {
            if(event!=null)
            {
              let fieldName= event.target['id'].includes('-') ? event.target['id'].split('-')[0]: event.target['id'];
              form.setFieldValue(fieldName,value);
              switch(fieldName) {
                case 'serviceName': handleServiceNameChange(event,value); break;
              }
            }
          }
    
          const handleServiceNameChange=(event:object,value:any)=>{
            if (value!=null && typeof value === 'object') 
            {
                form.setFieldValue("serviceName",value.query,true);
                setParentValue(value === null ? '' :value.query);
            }
            if (typeof value === 'string')
            {
              const Obj = getPreviousServiceName.find(e => e.toLocaleLowerCase() == value.toLocaleLowerCase());
              form.setFieldValue("serviceName", Obj!=undefined ? Obj:(value==""?'':value));
              setParentValue(value === null ? '' :value==""?'':value);
            }
        }
    
        const handleExtChange =(event)=>{
            form.setFieldValue("isExt", event.target.checked);
        }
    
    
    return(
           <Grid container spacing={2} style={{paddingRight: '5%',paddingLeft:'5%', alignContent:'flex-start'}}>

            <Grid item container direction="row" spacing={2} style={{marginTop:'0px'}}>
                <div style={{ display: 'flex', flexDirection: 'row',marginLeft:'1%'}}>
                <Typography variant='h6' className={classes.SecondHeading}>{t('servicesetting')}</Typography>
                    <Tooltip title={Info("serviceInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider}/>
                </div>
            </Grid>

            <Grid item style={{marginTop:'8px'}}>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
                <Typography>{t('operatingSystem')}{OS_TYPE_NAME[device.os]}</Typography>
                <img src={device.os === DevicePlatform.Windows ? "/assets/images/editDeviceIcons/windows.svg":"/assets/images/editDeviceIcons/linux.svg" } style={{marginLeft:'5px'}}></img>
            </div>
            </Grid>
           
            <Grid item container direction="row" spacing={2}>
                <Grid item xs={12} spacing ={2} style={{marginTop:'8px'}}>
                <EDRAutocomplete id="serviceName"
                        freeSolo
                        disableClearable
                        size='small'
                        value={form.values.serviceName}
                        options={getPreviousServiceName}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderInput={(params) => (<EDRTextField {...params} label={t('serviceLabel')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="serviceName"
                        onBlur={form.handleBlur}
                        error={form.touched.serviceName && Boolean(form.errors.serviceName)}
                        helperText={form.errors.serviceName}
                    />)}
                    />
                </Grid>

                <Grid item container xs={12}>
                <Grid item xs={10}>
                <div className={classes.toggleItem}>
                    <ListItemText style={{marginLeft:'0px'}}>{t("testRemote")}</ListItemText>
                </div>
                </Grid>
                <Grid item xs={2}>
                <div className={classes.centerVertical} style={{justifyContent:'flex-end'}}>
                <Tooltip title="Testing will be done by connecting to the remote device. you can alternatively toggle this button to test remotely from the EnsureDR controller" arrow>
                        <Switch checked={form.values.isExt} name="toggle2nd"  onChange={handleExtChange} onBlur={form.handleBlur} color='primary' />
                </Tooltip>
                </div>
                </Grid>  
            </Grid>

            <Grid item container direction="row">
            <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end'}}>
            <Button onClick={handleCancle} color="primary" style={{textTransform:'none'}}>
                {t('Cancel')}
            </Button>
            <Button onClick={handleTest} disabled={form.values.serviceName=='' ||processingTest || form.values.isExt} color="primary" style={{textTransform:'none'}}>
            {processingTest ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                {t('test')}
            </Button>
            <SaveButton onClick={handleSave} disabled={form.values.serviceName==''} variant="contained" color="primary" style={{textTransform:'none'}}>
                {t('apply')}
            </SaveButton>
            </Grid>
            </Grid>
            
            </Grid>
            </Grid>
    );
}

export default ServiceTestDialog;