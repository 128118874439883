import React, { useEffect, useState, useContext } from 'react';

import { TextField, InputAdornment, List, Checkbox, ListItemText, makeStyles, Grid, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import JobDeviceAgent from '../api/deviceAgent';
import { JobDeviceSearch } from '../models/jobDevice/jobDeviceSearch.model';
import { useDebounce } from './util/useDebounce.hook';
import { observer } from 'mobx-react-lite'
import { DeviceSearchContext } from './context.component';

interface DeviceSearchResultGroups {
    jobId: number;
    jobName: string;
    devices: Array<JobDeviceSearch>
}

const useStyle = makeStyles({
    subList: {
        paddingLeft: 16,
        paddingTop: 0
    },
    checked: {
        color: '#2892d7 !important'
    },
    badge: {
        backgroundColor: "#d6d6d9",
        color: "#4d5f75",
        minWidth: 30
    },
    listIcon: {
        minWidth: 0
    },
    zeroTopBottomPadding: {
        paddingBottom: 0,
        paddingTop: 0
    },
    list: {
        width:"100%",
        overflow: 'hidden'
    },
    checkboxstyle: {
        '&.MuiCheckbox-root': {
            color: '#c7cfdb !important'
        }
    },
})

type DeviceSearchProps = {
    selectedDeviceJobIds: Array<number>,
    onChange: (val: Array<number>) => void,
    searchDeviceText?: string
}

const DeviceSearch: React.FC<DeviceSearchProps> = (props) => {
    const [filter, setFilter] = useState('');
    const classes = useStyle();
    const [deviceResults, setDeviceResults] = useState<Array<DeviceSearchResultGroups>>([]);
    const debounceSearch = useDebounce(filter, 700);
    const s = useContext(DeviceSearchContext);
    useEffect(() => {
        if(s.searchResults) {
            
        let results: Array<DeviceSearchResultGroups> = [];
        s.searchResults.reduce((acc, jobDeviceSearchObject) => {
            var d = acc.find(d => d.jobId === jobDeviceSearchObject.jobId);
            if (d) {
                d.devices.push(jobDeviceSearchObject);
            } else {
                acc.push({
                    jobId: jobDeviceSearchObject.jobId,
                    jobName: jobDeviceSearchObject.jobName,
                    devices: [jobDeviceSearchObject],
                })
            }

            return acc;
        }, results);

        setDeviceResults(results);
    }
    }, [s.searchResults])

    useEffect(() => {
        let isCancelled = false;
        if (debounceSearch) {
            JobDeviceAgent.search(filter).then(res => {
                if (!isCancelled) {
                    s.setSearchResults(res);
                }
            })
        }
        return () => {
            isCancelled = true;
        }
    }, [debounceSearch])

    const handleCheckboxClick = (targetOption: DeviceSearchResultGroups, checked: boolean) => {
        if (checked) {
            props.onChange([...props.selectedDeviceJobIds, targetOption.jobId])
        } else {
            props.onChange(props.selectedDeviceJobIds.filter(d => d !== targetOption.jobId));
        }
    }
    return <React.Fragment>
        <TextField
            style={{ marginTop: "15px" }}
            placeholder={props.searchDeviceText && props.searchDeviceText!='' ? props.searchDeviceText: "Search"} variant="standard"
            onChange={event => setFilter(event.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
        <List dense={true} className={classes.list} >
            {deviceResults.map((d, index) => {
                return <Grid direction="column" container key={index}>
                    <Grid item container direction="row" alignItems="center">
                            <Checkbox
                                id={`jobs_device_search_${d.jobName}`}
                                style={{padding:'0 10px 0 10px'}}
                                edge="start"
                                tabIndex={-1}
                                onChange={(event) => { handleCheckboxClick(d, event.target.checked) }}
                                disableRipple
                                checked={props.selectedDeviceJobIds.indexOf(d.jobId) >= 0}
                                classes={{ checked: classes.checked }}
                                className={classes.checkboxstyle}
                                size="small"
                            />
                        <Typography noWrap={true} variant="body1" >{d.jobName} </Typography>
                    </Grid>
                    <Grid item>
                    <List dense={true} style={{padding: '0 0 0 30px'}}>
                            {d.devices.map(device => <ListItemText primary={device.deviceName} />)}
                        </List>
                    </Grid>
                  
                     
                </Grid>
            })}
        </List>
    </React.Fragment>


}

export default observer(DeviceSearch);
