import React from 'react'
import { Grid, Paper, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles({
    rect: {
        borderRadius: 20
    },
    root: {
        paddingTop: 20
    },
    circle: {
        marginRight: 10
    },
    subList: {
        padding: 5, 
        paddingTop: 15, 
        paddingLeft: "25px"
    }
})

const ListSkeleton = () => {
    const classes = useStyles();
    return <Grid container direction="column" className={classes.root}>
            <Grid item>
                <Skeleton className={classes.rect} height={12} variant="rect" width={45}></Skeleton>
            </Grid>
            <Grid item style={{padding: "5px", marginTop: 20}}>
                <Skeleton height={20} className={classes.rect} variant="rect"></Skeleton>
            </Grid>
            <Grid item container alignItems="center" style={{padding: "5px" ,marginTop: 20}}>
                <Skeleton className={classes.circle} variant="circle" width={15} height={15} ></Skeleton>
                <Skeleton className={classes.rect} height={12} width={60} variant="rect"></Skeleton>
            </Grid>
            <Grid item container alignItems="center" className={classes.subList}>
                <Skeleton className={classes.circle} variant="circle" width={15} height={15} ></Skeleton>
                <Skeleton className={classes.rect} height={12} width={140} variant="rect"></Skeleton>
            </Grid>
            <Grid item container alignItems="center" className={classes.subList}>
                <Skeleton className={classes.circle} variant="circle" width={15} height={15} ></Skeleton>
                <Skeleton className={classes.rect} height={12} width={140} variant="rect"></Skeleton>
            </Grid>
            <Grid item container alignItems="center" className={classes.subList}>
                <Skeleton className={classes.circle} variant="circle" width={15} height={15} ></Skeleton>
                <Skeleton className={classes.rect} height={12} width={140} variant="rect"></Skeleton>
            </Grid>
            <Grid item container alignItems="center" className={classes.subList}>
                <Skeleton className={classes.circle} variant="circle" width={15} height={15} ></Skeleton>
                <Skeleton className={classes.rect} height={12} width={140} variant="rect"></Skeleton>
            </Grid>
            <Grid item container alignItems="center" className={classes.subList}>
                <Skeleton className={classes.circle} variant="circle" width={15} height={15} ></Skeleton>
                <Skeleton className={classes.rect} height={12} width={140} variant="rect"></Skeleton>
            </Grid>
        
           
            
            
        </Grid>
}


export default ListSkeleton;