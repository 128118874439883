import React, { useEffect, useState, useContext } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../app/stores/root.store";
import {ComposableMap,Geographies,Geography,Marker,Line} from "react-simple-maps";
// import europe from "./europe-110m.json";
// import usa from "./states-10m.json";
// import israel from "./world-10m.json";
import Cog from "../Cog.component";
import { makeStyles, Grid, Typography, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { IMap } from "../../../app/models/dashboard/map.model";
import Dashboard from "../../../app/api/dashboardAgent";
import { ErrorBoundary } from "../../../app/common/errorHandling.component";

//const geoUrl = world;
const GEOGRAPHY_DATA_MAP = {
  'IL': 'world-10m.json',
  'US': 'states-10m.json',
  'EU': 'europe-110m.json',
  'ZU': 'states-10m.json',
  'ZE': 'europe-110m.json'
}
const Map: React.FC = () => {
  const rootStore = useContext(RootStoreContext); 
  const { currentCountryCode, getCurrentCountry } = rootStore.settingStore;
  const [t] = useTranslation("dashboard");
  const [mapData, setmapData] = useState<IMap>(null);
  const [geographyData, setGeographyData] = useState(null);
  const [mapConfig, setmapConfig] = useState<{scale: number, mapTransform: Array<number>, projection: string}>(null);
  useEffect(() => {
    Dashboard.getMapData().then(res=> setmapData(res)).catch(err => {});

  }, [])

  useEffect(() => {
    getCurrentCountry().then(countryCode => {
      const newMapConfig = {scale: returnMapScale(countryCode), mapTransform: returnMapRotate(countryCode), projection: returnProjection(countryCode)};
      setmapConfig({...newMapConfig});
      Dashboard.getGeographyData(GEOGRAPHY_DATA_MAP[countryCode] || 'state-10m.json').then(data => {
        setGeographyData(data)
       }).catch(err => {});
    }).catch(err => {});
  }, [])
  // useEffect(() => {
  //   const newMapConfig = {scale: returnMapScale(currentCountryCode), mapTransform: returnMapRotate(currentCountryCode), projection: returnProjection(currentCountryCode)};
  //   setmapConfig({...newMapConfig});
  // }, [currentCountryCode])

  // useEffect(() => {
  //  Dashboard.getGeographyData(GEOGRAPHY_DATA_MAP[currentCountryCode] || 'state-10m.json').then(data => {
  //   setGeographyData(data)
  //  }
  //   ).catch(err => {});
  // }, [currentCountryCode])
  const returnMapRotate = (currentCountryCode) => {
      let rotateVal = [96, -40.0, -5];
      if (currentCountryCode && currentCountryCode.length>0) {
          switch (currentCountryCode) {
            case "IL":
              rotateVal = [-35, -31.5, -5];
              break;
            case "US":
              rotateVal = [96, -40.0, -5];
              break;
            case "ZU":
              rotateVal = [96, -40.0, -5];
              break;
            case "EU":
              rotateVal = [-20.0, -52.0, 0];
              break;
            case "ZE":
              rotateVal = [-20.0, -52.0, 0];
              break;
            default:
              rotateVal = [96, -40.0, -5]
              break;
          }
        return rotateVal;
      } 
  }

  const returnMapScale = (currentCountryCode) => {
    let scaleVal = 9000;
    if (currentCountryCode && currentCountryCode.length>0) {
        switch (currentCountryCode) {
          case "IL":
            scaleVal = 9000;
            break;
          case "US":
            scaleVal = 900;
            break;
          case "ZU":
            scaleVal = 900;
            break;
          case "EU":
            scaleVal = 700;
            break;
          case "ZE":
            scaleVal = 700;
            break;
          default:
            scaleVal = 9000;
            break;
        }
      return scaleVal;
    } 
}

    const returnProjection = (currentCountryCode) => {
      let projectionVal = "geoAlbersUsa";
        if (currentCountryCode && currentCountryCode.length>0) {
            switch (currentCountryCode) {
              case "IL":
                projectionVal = "geoAzimuthalEqualArea";
                break;
              case "US":
                projectionVal = "geoAlbersUsa";
                break;
              case "ZU":
                projectionVal = "geoAlbersUsa";
                break;
              case "EU":
                projectionVal = "geoAzimuthalEqualArea";
                break;
              case "ZE":
                projectionVal = "geoAzimuthalEqualArea";
                break;
              default:
                projectionVal = "geoAlbersUsa";
                break;
            }
            return projectionVal;
          }
  }


  const useStyles = makeStyles({
    arrowReverse: {
      transform: "rotate(180deg)",
    },
    mapSvg: {
      width: '99%',
      height: '99%'
    }
  });


  const classes = useStyles();

  const Markers =
    mapData && mapData.dr && mapData.sites ? (
      <React.Fragment>
        {/* the <line> must be before of the <marker> for the arrows to render corectly!!! */}
        {mapData.sites.map((site, index) => (
          <svg key={index} >
            <Line
              from={[site.lng, site.lat]}
              to={[mapData.dr.lng, mapData.dr.lat]}
              stroke="#2892d7"
              strokeWidth={4}
              strokeLinecap="butt"
              markerEnd="url(#head)"
            />
          </svg>
        ))}
        <Marker coordinates={[mapData.dr.lng, mapData.dr.lat]} fill="#8bc53f">
          <circle r={8} fill="#8bc53f" />
          <circle r={18} fill="#8bc53f" opacity="0.25">
            <title>{mapData.dr.name}</title>
          </circle>
        </Marker>
        {mapData.sites.map((site, index) => (
          <Marker
            key={index}
            coordinates={[site.lng, site.lat]}
            fill="#2892d7"
          >
            <circle r={12} fill="#2892d7" />
            <circle r={22} fill="#2892d7" opacity="0.25">
              <title>{site.name}</title>
            </circle>
          </Marker>
        ))}
      </React.Fragment>
    ) : (
      ""
    );

  return (
    <Grid data-test="component-map"  style={{height: "100%"}}>
     {mapData == null || geographyData == null || mapConfig == null ? 
     <Grid direction='column' container style={{height: "100%"}} alignItems='center' justify='center'> <CircularProgress  size={50} color='primary' />
     </Grid>
     : <>
      <Grid item container>
        <Grid item style={{ width: "70%"}}>
          <Typography classes={{ root: "dashboard_header" }}>{t("mapHeader")}</Typography> 
        </Grid>
       
      </Grid>
      <Grid item>
        <ErrorBoundary fallback={<Grid container justifyContent="center" style={{padding: 20}}>
          <Typography variant="body1">Failed to render Map. Please check location information from <Link to={'/settings'}>settings</Link></Typography>
        </Grid>}>
        <ComposableMap
          projection={mapConfig.projection}
          projectionConfig={{rotate: mapConfig.mapTransform, scale: mapConfig.scale}}
        >
          <defs>
            <marker
              id="head"
              orient="auto"
              markerWidth="20"
              markerHeight="40"
              refX="4"
              refY="2"
            >
              <path d="M0,0 V4 L2,2 Z" fill="#2892d7" />
            </marker>
          </defs>
          <Geographies
            disableOptimization
            geography={geographyData}
            fill="rgba(163, 198, 229, 0.8)"
            stroke="#FFFFFF"
            strokeWidth={1}
          >
            {({ geographies }) =>
              geographies.map((geo) =>
              {
                if (currentCountryCode!=null && currentCountryCode.length>0 && currentCountryCode==="IL"){
                  const geoName = geo.properties.NAME.toLowerCase();

                  if (geoName !== "palestine" && geoName !== "israel") 
                    return null;
                }
              
              return (
                <Geography key={geo.rsmKey} geography={geo} />
              )
              })
            }
          </Geographies>
          {Markers}
        </ComposableMap>
        </ErrorBoundary>
      </Grid>

      </>}
         </Grid>
  );
};

export default observer(Map);
