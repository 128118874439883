
import { format } from 'date-fns';
import { decompressFromUint8Array as decompress, compressFromUint8Array as compress } from 'lz-string';
import moment from 'moment';
import { DeviceType } from '../../models/deviceType.enum';
import { TimeSpan } from '../../models/timespan.model';

export const combineDateAndTime = (date: Date, time: Date) => {
  var dateString, timeString;
  var dateToReturn = new Date();
  if (typeof (date) != 'undefined' && typeof (time) != 'undefined') {
    dateString = date.toISOString().split('T')[0];
    timeString = time.toISOString().split('T')[1];
    dateToReturn = new Date(dateString + 'T' + timeString);
  }

  return dateToReturn;
}


export const cleanObject = (obj: any) => {
  const newObj = {};

  Object.keys(obj).forEach(key => {
    if (obj[key]) {
      if(obj[key] instanceof Array) {
          if(obj[key].filter(v => v).length) {
            newObj[key]=obj[key].filter(v => v);
          }
      }else {
      newObj[key] = obj[key]; // copy value
      }
    }
  });

  return newObj;
}

export const addOrUpdateField = (
  field: any,
  fields: Array<any>
): Array<any> => {
  const index = fields.findIndex((f: any) => field.id === f.id);
  //Not found, add on end.
  if (-1 === index) {
    return [...fields, field];
  }
  //found, so return:
  //Clone of items before item being update.
  //updated item
  //Clone of items after item being updated.
  return [...fields.slice(0, index), field, ...fields.slice(index + 1)];
};

export const debounce = (func, delay) => {
  let inDebounce
  return function (...arg) {
    const context = this
    const args = arguments
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => func.apply(context, args), delay)
  }
}

function formatTo2Digit(n: number): string {
  return n>9? Math.floor(n).toFixed(0): `0${Math.floor(n).toFixed(0)}`
}

export function convertSecondsToTime(seconds: number, showSeconds: boolean = false, hourString: string = "Hr") {
  if (!seconds) {
    return "00:00";
  }
  let hrs = seconds / 3600;
  let minutes = (seconds % 3600)/60;
  let sec = seconds / 3600;
  if(showSeconds)
    return `${formatTo2Digit(hrs)}:${formatTo2Digit(minutes)}:${formatTo2Digit(sec)} ${hourString}`
  else   
    return `${formatTo2Digit(hrs)}:${formatTo2Digit(minutes)} ${hourString}`
}

export function convertMinToTime (minutes:number){
  let hrs = minutes/60;
  let min= minutes % 60;
  return `${formatTo2Digit(hrs)}:${formatTo2Digit(min)}`
}


export function decompressImage(imageStr: string): string {
  if (!imageStr)
    return null;
  try {
    var d = decompress(convertStringToBuffer(imageStr));
    return d;
  } catch (ex) {
    console.warn("Failed to decompress the image", ex);
    return null;
  }
}

export function compressFile(fileStr: string): string  {
  if (!fileStr)
    return null;
  try {
    var d = compress(convertStringToBuffer(fileStr));
    return d;
  } catch (ex) {
    console.warn("Failed to compress the file", ex);
    return null;
  }
}

export function convertTimespanToString(timespan: TimeSpan) {
  if(timespan.totalHours >= 1)
    return `${timespan.totalHours.toFixed(0)} Hr`
  else
    return `${timespan.totalMinutes.toFixed(0)} min`
}

export function ConvertStringToTimeSpan(timeSpan:string)
{
  let timeSpanSplit = timeSpan.split(':');
  let msSplit = [];
  if(timeSpanSplit[2].includes('.'))
  {
      msSplit = timeSpanSplit[2].split('.')
  }
  let timeSpanRet = {} as TimeSpan; 
  if(msSplit.length > 0)
  {
    timeSpanRet.milliseconds = Number(msSplit[1]);
  }
  timeSpanRet.totalHours = Number(timeSpanSplit[0]);
  timeSpanRet.totalMinutes = Number(timeSpanSplit[0]) * 60 + Number(timeSpanSplit[1]);
  timeSpanRet.totalSeconds = (Number(timeSpanSplit[0]) * 3600) + (Number(timeSpanSplit[1]) * 60)
  return timeSpanRet;
}

function convertStringToBuffer(str) {
  var binary_string = window.atob(str);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
}
export function parseSelectedDevices(selectedDevicesString: string): Array<DeviceType> {
  try {
    const parsed =JSON.parse(selectedDevicesString);
    if(Array.isArray(parsed)) {
      return parsed;
    } else {
      throw 'invalid selected devices setting'
    }
  } catch {
    console.warn('failed to parse selected devices settings. Returning default')
    return [0,1,2,3,4,10]
  }
}