import { withStyles, OutlinedInput } from "@material-ui/core";


export const EDROutlinedInput = withStyles({
    root: {
        '& .Mui-error': {
            color: 'red'
        },
        "& .Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: 'red !important'
        },
        "& .MuiInputBase-root.Mui-disabled": {
            background: 'rgb(233, 235, 238)'
        },
        "& .MuiFormHelperText-root": {
            position: 'absolute',
            bottom: '-16px'
         }
    }
})(OutlinedInput);
