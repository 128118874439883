import React, { useState } from 'react'
import { Typography, makeStyles, CardActions, Card, CardActionArea, CardMedia, Dialog, CardContent, Tooltip } from '@material-ui/core';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon from '@material-ui/icons/Settings';
import SpannerIcon from '@material-ui/icons/Build';
import WebIcon from '@material-ui/icons/Web';
import DatabaseIcon from '@material-ui/icons/Storage';
import clsx from 'clsx';
import GifPlayer from '../../app/common/GifPlayer.component';
import { JobDeviceResult } from '../../app/models/jobDevice/jobDeviceResult.model';
import TestStatusIcon from '../../app/common/testStatusIcon.component';
import { useTranslation } from 'react-i18next';
import { DeviceType, DEVICE_TYPE_NAME_SEPERATE_DC } from '../../app/models/deviceType.enum';
import DevicePreviewComponent from '../../app/common/devicePreview.component';
import { DevicePlatform } from '../../app/models/jobProperties.model';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AssignmentIcon from '@material-ui/icons/Assignment';
import RssFeedIcon from '@material-ui/icons/RssFeed';

const useStyles = makeStyles({
    root: {
        maxWidth: 250,
        height: '100%'
    },
    media: {
        maxHeight: 140,
        minHeight: 140,
        overflow: "hidden"
    },
    previewImage: {
        display: 'flex',
        flexDirection: 'column',
        height: 140
    },
    icon: {
        fontSize: 16,
        padding: 0
    },
    dialogRoot: {
        width: 600
    },
    dialogGif: {
        padding: 10
    },
    hidden: {
        visibility: 'hidden'
    }
});

const deviceTypeIcon = {
    [DeviceType.BranchOffice]: "/assets/images/device_type/branch.png",
    [DeviceType.DCPhysical]: "/assets/images/device_type/DC.png",
    [DeviceType.DCVirtual]: "/assets/images/device_type/DC.png",
    [DeviceType.Firewall]: "/assets/images/device_type/firewall.png",
    [DeviceType.InternetConnection]: "/assets/images/device_type/network.png",
    [DeviceType.NetworkDevice]: "/assets/images/device_type/network.png",
    [DeviceType.PhysicalServer]: "/assets/images/device_type/physical.png",
    [DeviceType.VirtualMachine]: "/assets/images/device_type/vm.png",
    [DeviceType.Other]: "/assets/images/device_type/other.png",
    [DeviceType.Database]: "/assets/images/device_type/database.png",
    [DeviceType.CloudService]: "/assets/images/device_type/cloud_service@2x.png",
    [DeviceType.CloudDatabase]: "/assets/images/device_type/cloud_database@2x.png"
}

const deviceTypeSmallIcon = {
    [DeviceType.BranchOffice]: "/assets/images/device_type/branch_small.png",
    [DeviceType.DCPhysical]: "/assets/images/device_type/DC_small.png",
    [DeviceType.DCVirtual]: "/assets/images/device_type/DC_small.png",
    [DeviceType.Firewall]: "/assets/images/device_type/firewall_small.png",
    [DeviceType.InternetConnection]: "/assets/images/device_type/network_small.png",
    [DeviceType.NetworkDevice]: "/assets/images/device_type/network_small.png",
    [DeviceType.PhysicalServer]: "/assets/images/device_type/physical_small.png",
    [DeviceType.VirtualMachine]: "/assets/images/device_type/vm_small.png",
    [DeviceType.Other]: "/assets/images/device_type/other_small.png",
    [DeviceType.Database]: "/assets/images/device_type/database_small.png",
    [DeviceType.CloudService]: "/assets/images/device_type/cloud_service.png",
    [DeviceType.CloudDatabase]: "/assets/images/device_type/cloud_database.png"
}

const ServerCard = (props: { playAnimation: boolean, device: JobDeviceResult, deviceOS: DevicePlatform, testGroupNames: {[key: number]: string} }) => {
    const [dialogOpen, setDialogState] = useState(false);
    const { device, deviceOS } = props;
    const classes = useStyles();
    const [t] = useTranslation("report");
    return <Card className={classes.root} data-test="component-serverCard" style={{paddingBottom:'1%'}}>
        <CardActionArea>
            <CardMedia data-test="component-gifPlayer"
                className={classes.media}
                title={props.device.name}>
                {/* {!props.device.smallGif && }      */}
                <GifPlayer gifUrl={`https://${window.location.hostname}/api/devices/animation/${device.id}?imageType=small`}
                    onClick={() => setDialogState(true)}
                    fallbackComponent={<div className={classes.previewImage}><DevicePreviewComponent icon={deviceTypeIcon[device.type]} isOk={device.isLive} /></div>} play={props.playAnimation} style={{ height: 140 }} showControls={false}></GifPlayer>
            </CardMedia>
            <CardContent>
                <Tooltip title={props.device.name}>
                    <span style={{ display: 'flex', alignItems: 'center', alignContent: 'center', marginLeft: '-5px' }}>
                        <img style={{ fontWeight: 800, marginTop: '-5px' }} src={deviceOS === DevicePlatform.UNIX ? "/assets/images/editDeviceIcons/linuxIcon.svg" : "/assets/images/editDeviceIcons/windowsIcon.svg"}></img>
                        <Typography align="left" gutterBottom color="primary" variant="body1" noWrap={true}>
                            {props.device.name}
                        </Typography>
                    </span>
                </Tooltip>
                <span style={{ display: 'flex', alignContent: 'center' }}>
                    <Typography align="left" gutterBottom variant="body1">
                        <img style={{ marginRight: 5, width: 12, height: 12, fontWeight: 800 }} src={deviceTypeSmallIcon[device.type]}></img>
                        {DEVICE_TYPE_NAME_SEPERATE_DC[device.type]}
                    </Typography>
                    {device.authenticationInfo != null  && device.authenticationInfo!="" ? <Tooltip title={'Custom Authentication: ' + device.authenticationInfo}><VpnKeyIcon style={{ width: 15, marginLeft: 5, color: 'rgb(139, 197, 63)' }} /></Tooltip> : null}
                </span>
                {/* <Typography className={clsx({[classes.hidden]: !device.hasAdvancedTest})} align="left" variant="body2" color="textSecondary" component="p">
                    {t("serverTile.advanceTest")}
                    </Typography> */}
                <Typography align="left" variant="body2" color="textSecondary" component="p">
                    {t('groupName')}{props.testGroupNames[props.device.testGroup]}
                </Typography>
            </CardContent>
        </CardActionArea>
        <CardActions style={{ textAlign: 'left', paddingLeft: '12px',marginTop:'-6%' }}>
            <TestStatusIcon test={device.tests.power} testInfo={''}> <PowerIcon className={classes.icon} /></TestStatusIcon>
            <TestStatusIcon test={device.tests.network} testInfo={device.tests.networkInfo}> <WebIcon className={classes.icon} /></TestStatusIcon>
            <TestStatusIcon test={device.tests.service} testInfo={device.tests.serviceInfo}> <SettingsIcon className={classes.icon} /></TestStatusIcon>
            <TestStatusIcon test={device.tests.custom} testInfo={device.tests.customInfo}> <SpannerIcon className={classes.icon} /></TestStatusIcon>
            <TestStatusIcon test={device.tests.database} testInfo={device.tests.databaseInfo}> <DatabaseIcon className={classes.icon} /></TestStatusIcon>
            <TestStatusIcon test={device.tests.web} testInfo={device.tests.webInfo}> <WebIcon className={classes.icon} /></TestStatusIcon>
            <TestStatusIcon test={device.tests.thirdDeviceNetwork} testInfo={device.tests.thirdDeviceNetworkInfo} className={classes.icon} />
            <TestStatusIcon test={device.tests.PreScriptRes} testInfo={device.tests.PreScriptResInfo} className={classes.icon} />
        </CardActions>
        <Dialog open={dialogOpen} onClose={() => setDialogState(false)} classes={{ paper: classes.dialogRoot }} >
            <GifPlayer className={classes.dialogGif} gifUrl={`https://${window.location.hostname}/api/devices/animation/${device.id}?imageType=large`} playOnLoad={true} onClose={() => setDialogState(false)} showControls={true}></GifPlayer>
        </Dialog>
    </Card>
}

export default ServerCard