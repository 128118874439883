import { DataMover } from "../../../../app/models/BaseSettings.enum"
import * as yup from 'yup';
import { debounce } from 'lodash';
import jobAgent from "../../../../app/api/jobAgent";
function validateHostname(value) {

    let IpRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    let hostnameRegex = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;
    let isValidIP = IpRegex.test(value);
    let isValidHostname = hostnameRegex.test(value);

    if (isValidIP || isValidHostname)
        return true;

    return false;

}

function validateGuid(value) {
    let GuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;

    let isValidGuid = GuidRegex.test(value);
    if (isValidGuid)
        return true;
    else
        return false;
}
const validateVC = async (value, resolve) => {
    try {
        if (!value.username || !value.password || !value.hostname) {
            resolve(false);
            return;
        }
        var res = await jobAgent.CreateJobActions.validateVcenter(value.hostname, value.username, value.password);
        resolve(Boolean(res));
    } catch (error) {
        resolve(false);
    }
};

const targetVCValidation = debounce(validateVC, 800, {
    'leading': false,
    'trailing': true
});
const sourceVCValidation = debounce(validateVC, 800, {
    'leading': false,
    'trailing': true
});

function _validateAzure (value, resolve) {
    resolve(Boolean(true));
}
const AzureValidator = debounce(_validateAzure, 800, { leading: false, trailing: true});


const _validateAWS = async (value, resolve) => {
    try {
        if (!value.username || !value.password || !value.hostname) {
            resolve(false);
            return;
        }
        var res = await jobAgent.CreateJobActions.validateAWS(value.hostname, value.username, value.password);
        resolve(Boolean(res));
    } catch (error) {
        resolve(false);
    }
}
const AWSValidator = debounce(_validateAWS, 800, { leading: false, trailing: true});

export const VcvalidationSchema = yup.object({
    ZertoOrSRM: yup.boolean(),
    source: yup.object({
        hostname: yup.string().when("datamover", {
            is: (v) => v != DataMover.PaaS && v != DataMover.ZertoAzure,
            then: yup.string()
                .nullable()
                .required('Required')
                .test('infraSourceIP',
                    'Invalid Source Name/IP', validateHostname),
            otherwise: yup.string()

        }),
        username: yup.string().when('datamover', {
            is: (v) => v !== DataMover.ZertoAzure && v != DataMover.ZertoVmWare && v != DataMover.SRM,
            then: yup.string()
                .required('Required')
                .nullable()
                .max(50, 'up to 50 characters')
        }),
        password: yup.string().when('datamover', {
            is: (v) => v !== DataMover.ZertoAzure && v != DataMover.ZertoVmWare && v != DataMover.SRM,
            then: yup.string()
                .test('empty', 'password must be not empty', infraTargetPassword => !infraTargetPassword || infraTargetPassword != '')
                .required('Required')
                .max(50, 'up to 50 characters')
        })
    }
    ).test('validateCredentials', "invalid VC details", function (value) {
        return new Promise(res => sourceVCValidation(value, res))
    }),
    target: yup.object({
        hostname: yup.string().when("datamover", {
            is: (v) => v != DataMover.PaaS && v != DataMover.ZertoAzure,
            then: yup.string()
                .nullable()
                .required('Required')
                .test('infraSourceIP',
                    'Invalid Source Name/IP',  validateHostname),
            otherwise: yup.string()

        }),
        username: yup.string().when('datamover', {
            is: (v) => v !== DataMover.ZertoAzure,
            then: yup.string()
                .required('Required')
                .nullable()
                .max(50, 'up to 50 characters')
        }),
        password: yup.string().when('datamover', {
            is: (v) => v !== DataMover.ZertoAzure,
            then: yup.string()
                .test('empty', 'password must be not empty', infraTargetPassword => !infraTargetPassword || infraTargetPassword != '')
                .required('Required')
                .max(50, 'up to 50 characters')
        })
    }).test('validateCredentials', "invalid VC details", function (value) {
        return new Promise(res => targetVCValidation(value, res))
    }),
});

export const AzureValidationSchema = yup.object({
    target: yup.object({
        hostname: yup.string().nullable()
        .required('Required')
        .test('tenantId',
            'Invalid TenantId', validateGuid),
        username: yup.string()
                .required('Required')
                .nullable()
                .max(50, 'up to 50 characters')
        ,
        password:  yup.string()
                .test('empty', 'Client Secret must be not empty', infraTargetPassword => !infraTargetPassword || infraTargetPassword != '')
                .required('Required')
                .max(50, 'up to 50 characters')
    }
    ).test('validateCredentials', "Invalid Azure credentials", function (value) {
        return new Promise(res => AzureValidator(value, res))
    })
})

export const AWSValidationSchema = yup.object({
    target: yup.object({
        hostname: yup.string().nullable()
        .required('Required')
        .test('region',
            'Invalid EnsureDR in AWS', validateHostname),
        username: yup.string()
                .required('Required')
                .nullable()
                .max(50, 'up to 50 characters')
        ,
        password:  yup.string()
                .test('empty', 'AWS Secret must be not empty', infraTargetPassword => !infraTargetPassword || infraTargetPassword != '')
                .required('Required')
                .max(50, 'up to 50 characters')
    }
    ).test('validateCredentials', "Invalid AWS credentials", function (value) {
        return new Promise(res => AWSValidator(value, res))
    })
})