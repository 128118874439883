import { Draft } from '../models/draft.model';
import agentbase from './agentBase';

const requests = agentbase.requests;
const axios = agentbase.axios;
const DraftsAgent = {
    Details: (id: number) => axios.get(`/drafts/${id}`),
    Create :(instance:Draft): Promise<any> => axios.post<{draft: Draft}>('/drafts', {...instance}),
    Delete :(id:number): Promise<any> => axios.delete<{draft: any}>(`/drafts/${id}`),
    Edit :(instance:any): Promise<any> => axios.put<{assets: any}>('/drafts', {...instance}),
    List: ():Promise<any> => axios.get('/drafts'),
    Any: ():Promise<any> => axios.get('/drafts/Any')

}

export default DraftsAgent;