import React, { ReactElement } from 'react'
import { List, ListItem, Typography, ListItemText, ListItemIcon, Badge, makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';

export interface SelectListProps<T> {
    options: T[];
    value: T;
    onChange: (value: T) => void,
    title?: string,
    getLabel: (option: T) => React.ReactNode,
    getIcon?: (option: T) => React.ReactElement,
    getCountBagde?: (option: T) => React.ReactElement,
    children?: any // workaround for typescript error
}

const useStyles = makeStyles({
    categorySelected: {
        borderRadius: 30,
        background: "#ecf6fc"
    },
    category_item: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer"
    },
    listItemRoot: {
        minWidth: 0
    }
})


function SelectList<T,>(props: SelectListProps<T>): ReactElement<SelectListProps<T>> {
    const classes = useStyles();
    const { title, onChange, getLabel, getCountBagde: getBagde, getIcon, value, options } = props;
    return <List>
        {title && <ListItem>
            <Typography variant="h6">{title}</Typography>
        </ListItem>}

        {options.map((option, key) => <ListItem
            key={key}
            onClick={() => onChange(option)} className={clsx({
                [classes.categorySelected]: value == option
            })}>

            <ListItemText >
                <div className={classes.category_item}>
                {getIcon && getIcon(option)}
                    <Tooltip title={getLabel(option)}>
                    <Typography variant="body2" style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{getLabel(option)}</Typography>
                    </Tooltip>
                </div>
            </ListItemText>

            {getBagde && <ListItemIcon classes={{root: classes.listItemRoot}} >
                {getBagde(option)}
            </ListItemIcon>
            }
        </ListItem>)}

    </List>
}

export default SelectList;
