import React from 'react';
import { LastJobRunStatus } from '../models/lastjobRun.model';

const iconStyles: {[key: string]: React.CSSProperties} = {
    small: {
        width: 10,
        height:11,
    },
    regular: {
        width: 21,
        height: 24
    },
    large: {
        width: 31,
        height: 36
    }
}
type JobCategoryIconProps = {
    status: LastJobRunStatus,
    noruns?:boolean,
    warning?:boolean,
    full?: boolean,
    className?: string,
    style?: React.CSSProperties,
    variant: "small" | "regular" | "large"
}
 const JobCategoryIcon: React.FC<JobCategoryIconProps> = (props) => {
    let icon = null;
    const {variant, status: score, full,style, className,noruns,warning} = props;
    if(noruns!=null && noruns)
        icon= `/assets/images/jobIcon/recoverable.svg`;
    else if( score >= 90 || score == LastJobRunStatus.resillient) 
    {
        if (full)
            icon= `/assets/images/jobIcon/resillient.svg`;
        else
            icon= `/assets/images/jobIcon/resillient_wire.svg`;    
    }
    else if((score >= 80 && score < 90) || score == LastJobRunStatus.recoverable) 
    {
        if (full)
            icon= `/assets/images/jobIcon/recoverable.svg`;
        else
            icon= `/assets/images/jobIcon/recoverable_wire.svg`;
    }
    else if(score<80 || score == LastJobRunStatus.atRisk) 
    {
        if (full)
            icon= `/assets/images/jobIcon/atrisk.svg`;
        else
            icon= `/assets/images/jobIcon/atrisk_wire.svg`;
    }

    if(score < 0) {
        if(warning!=undefined && warning!=null && warning === true)
            icon = `/assets/images/jobIcon/recoverable.svg`;
        else
            icon = `/assets/images/jobIcon/all.svg`;
    }

    return <img src={icon} style = {{ ...iconStyles[variant], ...style}} className={className}/>;

}

export default JobCategoryIcon;