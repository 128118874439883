import React, { Fragment, useState, useEffect } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import PhotoWidgetDropzone from './PhotoWidgetDropZone';
import PhotoWidgetCropper from './PhotoWidgetCropper';
import { useTranslation } from 'react-i18next';

interface IProps {
  loading: boolean;
  uploadPhoto: (file: Blob) => void;
}

export const PhotoUploadWidget: React.FC<IProps> = ({loading, uploadPhoto}) => {
    const [files, setFiles] = useState<any[]>([]);//we're setting this as any array to bypass type checking with our dropzone widget
    const [image, setImage] = useState<Blob | null>(null);
    const [t] = useTranslation("settings");

    useEffect(() => { 
        return () => {
            files.forEach(file => URL.revokeObjectURL(file.preview))
        }
    })

    return (
        <Fragment>
          <Grid>
            <Grid>
              <Typography>{t("step1")}</Typography>
              <PhotoWidgetDropzone setFiles={setFiles} />
            </Grid>
            <Grid>
              <Typography>{t("step2")}</Typography> 
              {files.length > 0 &&
              <PhotoWidgetCropper setImage={setImage} imagePreview={files[0].preview} />
              }
            </Grid>
            <Grid>
              <Typography>{t("step3")}</Typography> 
              {files.length > 0 &&
              <React.Fragment>
                  <div className='img-preview' style={{minHeight: '200px', overflow: 'hidden'}} />
                  <Button 
                      onClick={() => uploadPhoto(image!)} 
                  >{t("upload")}</Button>
                  <Button 
                      disabled={loading} 
                      onClick={() => setFiles([])} 
                  ></Button>
              </React.Fragment>
              
              }
            </Grid>
          </Grid>
        </Fragment>
      );      
}

export default observer(PhotoUploadWidget);
