import { FormControl, Grid, InputAdornment, InputLabel, makeStyles, OutlinedInput, Typography,IconButton, DialogContent, Dialog, Divider, Select, MenuItem, Button, Tooltip, Switch, ListItemText, CircularProgress } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Autocomplete } from "@material-ui/lab";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { EDRAutocomplete } from "../../../app/common/EDRAutocomplete";
import { EDRTextField } from "../../../app/common/EDRTextField";
import { SaveButton } from "../../../app/common/saveButton";
import { DATABASE_TYPE_NAME, OS_TYPE_NAME } from "../../../app/models/BaseSettings.enum";
import { DeviceTest } from "../../../app/models/createjob/createJob.model";
import { JobDevice } from "../../../app/models/jobDevice/jobDevice.model";
import { DatabaseType, DevicePlatform } from "../../../app/models/jobProperties.model";
import { TestType } from "../../../app/models/jobTest.model";
import { RootStoreContext } from "../../../app/stores/root.store";
import { Props } from "./NetworkTestDialog.component";
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import HelpIcon from '@material-ui/icons/Help';
import { compressFile } from '../../../app/common/util/util';
import jobAgent from "../../../app/api/jobAgent";

const CustomTestDialog: React.FC<Props> = (props) => {
    const useStyles = makeStyles({
        autoCompleteWithButton: {
            '& input': {
                flexGrow: 0,
                width: "calc(100% - 59px) !important"
            }
        },
        formControl: {
            width: '100%',
            '& label': {
                background: "white",
                width: "auto",
                padding:"0 4px 0 4px",
                fontSize: '17px',
              }
        },
        helpIcon:
        {
          color: '#1976d2',
          fontSize: "large"
        },
        divider: {
            backgroundColor: '#eaeef4',
            width: '290%',
            margin: '0',
            marginTop: '10px',
            marginLeft: '10px',
        },
        SecondHeading:{
            color:'#1f396d',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1
        },
        BrowseButton:{
            backgroundColor: '#fafafc',
            textTransform: 'none',
          },
        centerVertical: {
            display: 'flex',
            alignItems: 'center',
            justifyContent:'space-between'
        },
        toggleItem: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
    
            '& .MuiListItemText-root' : {
                marginLeft: 10
            },
            '& .MuiSvgIcon-root': {
                color: '#8092a9'
            }
        },
        autoWithButton:{
            '& .MuiOutlinedInput-adornedEnd' : {
                paddingRight:'0px'
            }
        }
        });   
        
    const loadCustomList =()=>{
        let customCommand=[];
        let list= jobSettingsStore.customList.filter(item=>item.os == device.os);
        list.map(item=>{
            if(item.command.includes('netdom'))
                customCommand.push(`${item.command} ${device.deviceName}`);
            else
                customCommand.push(item.command)
        });
        return customCommand;
    }

    const loadCustomeResultList =()=>{
        let customResult=[];
        let list= device !=undefined ? jobSettingsStore.customList.filter(item=>item.os == device.os) : jobSettingsStore.customList;
        list.map(item=>{
            customResult.push(item.result);
        });
        return customResult;
    }

    const classes = useStyles();
    const rootStore = useContext(RootStoreContext); 
    const { onApply,discard,closeDrawer,reload,setParentValue } = props;
    const {createJobStore,jobSettingsStore} = useContext(RootStoreContext);
    const device= createJobStore.editedDevice;
    const CustomTest = device.tests.find(test=> test.testType == TestType.custom);
    const [cancel,setCancel]=useState(false);
    const getPreviousCommand = loadCustomList();
    const getPreviousCustomResult = loadCustomeResultList();
    let inputFileCustom = useRef(null);
    const [DataStateCustom, setDataStateCustom] = useState<any>();
    const [ShowBrowse,setShowBrowse]= useState(device.customCommandData !=null ? false:true);
    const [processingTest,setProcessingTest] = useState(false);
    const [t] = useTranslation("createJobDevices");
    const [Info] = useTranslation('createJobBase');
   
    const defaultSettings = {
        customCommand:CustomTest.customCommandToTest!="" ? CustomTest.customCommandToTest :(device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.customCommandToTestLinux :jobSettingsStore.customCommandToTest),
        customResult : CustomTest.customCommandExpectedResult!="" ? CustomTest.customCommandExpectedResult :(device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.customCommandExpectedResultLinux :jobSettingsStore.customCommandExpectedResult),
        isExt: CustomTest.customCommandToTestIsExternal!=false ? CustomTest.customCommandToTestIsExternal : jobSettingsStore.customCommandToTestIsExternal
    };

    
const validationSchema = yup.object({
    customCommand: yup.string()
    .test('command','custom command must be not empty',customCommand => customCommand!='')
    .required('Custom Command is a required field'),

    isExt: yup.bool(),

    customResult: yup.string()
    .nullable()
})

    const form = useFormik({
        initialValues: {
            customCommand: defaultSettings.customCommand,
            customResult :  defaultSettings.customResult,
            isExt: jobSettingsStore.customCommandToTestIsExternal
        },
        onSubmit: () => { },
        validationSchema
    })

    useEffect(() => {
        const commandObj = reload.includes('netdom')?jobSettingsStore.customList.find(e => reload.toLocaleLowerCase().includes(e.command.toLocaleLowerCase())) : (jobSettingsStore.customList.find(e => e.command.toLocaleLowerCase() == reload.toLocaleLowerCase())!=undefined ? jobSettingsStore.customList.find(e => e.command.toLocaleLowerCase() == reload.toLocaleLowerCase()):null);
        let res = commandObj!== undefined &&  commandObj!== null ? (commandObj.result == null ? defaultSettings.customResult :commandObj.result):defaultSettings.customResult;
        form.setFieldValue("customCommand",reload,true);
        form.setFieldValue("customResult",res,true);
        form.setFieldValue("isExt",defaultSettings.isExt,true);
    }, [reload]); 

    const handleCancle =()=>{
        if(form.dirty && form.touched)
            setCancel(!cancel);
        else
            closeDrawer();
    }

    const handleTest =()=>{
        var details =
        {
            machineName:device.sourceName,
            customCommand:form.values.customCommand,
            customResult: form.values.customResult,
            commandData: device.customCommandData,
            devicePlatform: device.os,
            id: device.os == DevicePlatform.UNIX ? createJobStore.baseSettings.workflowTest.SecondaryUserCredsId : createJobStore.baseSettings.workflowTest.PrimaryUserCredsId
        };
        setProcessingTest(true);
        jobAgent.JobTestsActions.TestScript(details).then(res => {
            if(res)
                toast.success("Script Test succeeded");
            else
                toast.error("Script Test failed");
        setProcessingTest(false);
        }).catch(err=>{
        if(err && err.data && err.data.errors)
            toast.error(err.data.errors);
        setProcessingTest(false);
        });       
    }

    const handleSave =()=> {
        try{
            let DeviceTest = {
                testCategory:device.tests[0].testCategory, 
                testType:TestType.custom, 
                selected: true,
                customCommandToTest: form.values.customCommand,
                customCommandExpectedResult: form.values.customResult, 
                customCommandToTestIsExternal: form.values.isExt,
                databaseType:DatabaseType.SQL,
                databaseNameToTest: '',
                databaseQueryToTest: '',
                databaseServerNameToTest: '',
                databaseUsername:'',
                databasePassword:'',
                databasePort:'',
                dbWindowsAuth:false,
                serviceToTest:'',
                testFromEDRC:false,
                script: '',
                thirdDeviceNetworkToTest: '',
                authenticationUserToTest:'',
                authenticationPassToTest:'',
                webPortalToTest: '',
                webPortalResult: ''
            };
            createJobStore.updateTestDevice(TestType.custom,DeviceTest,device.planName.includes("Custom Devices") ? device.sourceName : device.deviceName);
            onApply();
            discard();
            setParentValue(form.values.customCommand);
            toast.success(t('saveSuccessful'));
        }
        catch(error)
        {
            toast.success(t('savefailed')+error);
        }
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    const handleYes=()=>{
        form.setFieldValue("customCommand",CustomTest.customCommandToTest!="" ? CustomTest.customCommandToTest :(device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.customCommandToTestLinux :jobSettingsStore.customCommandToTest),true);
        form.setFieldValue("customResult",CustomTest.customCommandExpectedResult!="" ?CustomTest.customCommandExpectedResult:(device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.customCommandExpectedResultLinux :jobSettingsStore.customCommandExpectedResult),true);
        form.setFieldValue("isExt",CustomTest.customCommandToTestIsExternal);
        discard();
        setCancel(false);
    }

    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const handleAutoCompleteChange = (event: React.ChangeEvent,value:any) => {
        if(event!=null)
        {
          let fieldName= event.target['id'].includes('-') ? event.target['id'].split('-')[0]: event.target['id'];
          form.setFieldValue(fieldName,value);
          switch(fieldName) {
            case 'customCommand': handleCommandChange(event,value); break;
          }
        }
      }

      const handleCommandChange=(event:any,value:any) => {
        if(event!=null){
            if (value!=null && typeof value === 'object') 
            {
              form.setFieldValue("customCommand",value=="" ? '':value.command,true);
              form.setFieldValue("customResult",value=="" ? '':value.result,true);
              setParentValue(value === null ? '' :value.command);
            }
            if (typeof value === 'string')
            {
              const commandObj = jobSettingsStore.customList.find(e => e.command.toLocaleLowerCase() == value.toLocaleLowerCase());
              form.setFieldValue("customCommand", commandObj!=undefined ? value:(value==""?'':value),true);
              setParentValue(value === null ? '' :value==""?'':value);
              let res = commandObj== undefined ? '' :commandObj.result;
              form.setFieldValue("customResult",res,true);
            }
            if(event.target.value===''){
            device.customCommandData=null;
            device.customCommandName='';
            form.setFieldValue('customCommand','',true);
            setShowBrowse(true);
            }
            if(event.target.value!==null && event.target.value!=='' && device!=undefined)
                device.customCommandData = event.target.files!=null ? compressFile(event.target.files[0]):null;
          }
    }

    const handleResultChange=(event:any,value:any)=>{
        if(event!=null)
        {
            if(event.type ==="click")
            {
                const Obj = jobSettingsStore.customList.find(e => e.result == value);
                let res = Obj== undefined ? '' :Obj.result;
                form.setFieldValue("customResult",res,true);
            }
            else if(event.type ==="change")
            {
                form.setFieldValue("customResult",value=="" ? '':value);
            }
        }
    }

    const handleExtChange =(event)=>{
        form.setFieldValue("isExt", event.target.checked);
    }

    const handleBrowseCustom =()=>{
        inputFileCustom.current.click();
    }

    const handleFileUpload = (e:any) => {
        const { files } = e.target;
        if (files && files.length) 
        {
          let fileSizebytes=files[0].size/1024;
          let invalid= fileSizebytes > 512 ;
          if(!invalid) 
          {
            form.setFieldValue("customCommand",files[0].name);
            const toBase64 = file => new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = error => reject(error);
            });
             
            const data = {
                file: toBase64(files[0]).then(function(res) {
                    setDataStateCustom(res);
                }),
            }  
            form.setFieldValue("customResult",'',true);
            setShowBrowse(false);
          }
          else
          {
            form.setFieldValue("customCommand",'');
            setDataStateCustom(null);
          }
        }
    };

    const removeFile =()=>{
        if(device!=undefined)
        {
            device.customCommandData=null;
            device.customCommandName='';
            form.setFieldValue('customCommand','',true);
            inputFileCustom = null;
            setShowBrowse(true);
        }
    }
    
    return(
           <Grid container spacing={2} style={{paddingRight: '5%',paddingLeft:'5%', alignContent:'flex-start'}}>
             <input style={{ display: "none" }} ref={inputFileCustom} type="file" onChange={(event)=> { 
               handleFileUpload(event) 
               event.target.value=null
            }}/>
            <Grid item container direction="row" spacing={2} style={{marginTop:'0px'}}>
                
                <div style={{ display: 'flex', flexDirection: 'row',marginLeft:'1%'}}>
                <Typography variant='h6' className={classes.SecondHeading}>{t('customsetting')}</Typography>
                    <Tooltip title={Info("customInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider} style={{width:'200%'}}/>
                </div>
            </Grid>

            <Grid item style={{marginTop:'8px'}}>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
                <Typography>{t('operatingSystem')}{OS_TYPE_NAME[device.os]}</Typography>
                <img src={device.os === DevicePlatform.Windows ? "/assets/images/editDeviceIcons/windows.svg":"/assets/images/editDeviceIcons/linux.svg" } style={{marginLeft:'5px'}}></img>
            </div>
            </Grid>
           
            <Grid item container direction="row" spacing={2}>
                <Grid item xs={12} spacing ={2} style={{marginTop:'8px'}}>
                <EDRAutocomplete id="customCommand" 
                        freeSolo
                        disableClearable
                        fullWidth
                        size='small'
                        className={classes.autoWithButton}
                        value={form.values.customCommand}
                        options={getPreviousCommand}
                        onBlur ={form.handleBlur}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderOption={(option: string|{command: string,result:string}) => (typeof option === 'string' ? option : `${option.command}`)}
	                    getOptionLabel={(option: string|{command: string,result:string}) => (typeof option === 'string' ? option : `${option.command}`)}
                        renderInput={(params) => (<div style={{position: 'relative', width: '100%'}}>
                                <EDRTextField classes={{root: classes.autoCompleteWithButton}}  fullWidth {...params} label={Info('customCommandLabel')} 
                        style={{borderColor:'#c7cfdb'}}
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="customCommand"
                        error={form.touched && form.touched.customCommand && form.errors && Boolean(form.errors.customCommand)}
                        helperText={form.touched.customCommand && form.errors.customCommand}
                    />
                    {device!=undefined && ShowBrowse === true ?  <Button className={classes.BrowseButton} onClick={handleBrowseCustom} style={{height: '36px', position: 'absolute', right: 1, top: 1, width: 70}}>{ t("browse")}</Button>
                    :<Button className={classes.BrowseButton} onClick={removeFile} style={{height: '36px', position: 'absolute', right: 1, top: 1, width: 70}}>{ t("remove")}</Button>}
                        </div>)}
                    />
                </Grid>

                <Grid item xs={12} style={{marginTop:'8px'}}>
                <EDRAutocomplete id="customResult"
                        freeSolo
                        disableClearable
                        size="small"
                        value={form.values.customResult}
                        options={[]}
                        onChange={handleResultChange}
                        onInputChange={handleResultChange}
                        style={{borderColor:'#c7cfdb'}}
                        renderOption={(option: string|{command: string,result:string}) => (typeof option === 'string' ? option : `${option.result}`)}
	                    getOptionLabel={(option: string|{command: string,result:string}) => (typeof option === 'string' ? option : `${option.result}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={Info('customResult')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="result"
                        onBlur={form.handleBlur}
                        error={form.touched.customResult && Boolean(form.errors.customResult)}
                        helperText={form.errors.customResult}
                    />)}
                    />  
                </Grid>

                <Grid item container xs={12}>
                <Grid item xs={10}>
                <div className={classes.toggleItem}>
                    <ListItemText style={{marginLeft:'0px'}}>{t("testRemote")}</ListItemText>
                </div>
                </Grid>
                <Grid item xs={2}>
                <div className={classes.centerVertical} style={{justifyContent:'flex-end'}}>
                <Tooltip title="Testing will be done by connecting to the remote device. you can alternatively toggle this button to test remotely from the EnsureDR controller" arrow>
                        <Switch checked={form.values.isExt} name="toggle2nd"  onChange={handleExtChange} onBlur={form.handleBlur} color='primary' />
                </Tooltip>
                </div>
                </Grid>  
            </Grid>

            <Grid item container direction="row">
            <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end'}}>
            <Button onClick={handleCancle} color="primary" style={{textTransform:'none'}}>
                {t('Cancel')}
            </Button>
            <Button onClick={handleTest} color="primary" disabled={form.values.customCommand =="" || processingTest || form.values.isExt} style={{textTransform:'none',marginLeft:'-11px'}}>
            {processingTest ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                {t('Test')}
            </Button>
            <SaveButton onClick={handleSave} disabled={form.values.customCommand ==""} variant="contained" color="primary" style={{textTransform:'none'}}>
                {t('apply')}
            </SaveButton>
            </Grid>
            </Grid>
            
            </Grid>
            </Grid>
    );
}

export default CustomTestDialog;