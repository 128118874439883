import { List, ListItem, ListItemText, Typography, Divider, ListItemIcon, makeStyles, Switch, Grid, Tooltip, IconButton } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react'

import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon from '@material-ui/icons/Settings';
import SpannerIcon from '@material-ui/icons/Build';
import WebIcon from '@material-ui/icons/Web';
import NetworkIcon from '@material-ui/icons/Language';
import DatabaseIcon from '@material-ui/icons/Storage';
import FlashOn from '@material-ui/icons/FlashOn';
import WifiIcon from '@material-ui/icons/Wifi';
import LayersIcon from '@material-ui/icons/Layers';
import MemoryIcon from '@material-ui/icons/Memory';
import { TestNames, TestType } from '../../../app/models/jobTest.model';
import EditIcon from '@material-ui/icons/Edit';
import WebTestSettingDialog from './TestsSettings/webTestSetting.component';
import DatabaseTestSettingDialog from './TestsSettings/databaseTestSetting.component';
import CustomTestSettingDialog from './TestsSettings/customTestSetting.component';
import ServiceTestSettingDialog from './TestsSettings/serviceTestSetting.component';
import WebPortalTestSettingDialog from './TestsSettings/webPortalTestSetting.component';
import { RootStore, RootStoreContext } from '../../../app/stores/root.store';
import { DataMover } from '../../../app/models/BaseSettings.enum';
import { useTranslation } from 'react-i18next';
import useDeviceTests, { SelectedTest } from './getInitalTests.hook';
import { JobDevice } from '../../../app/models/jobDevice/jobDevice.model';
import { DeviceType } from '../../../app/models/deviceType.enum';
import { observer } from 'mobx-react-lite';
import HelpIcon from '@material-ui/icons/Help';
import ServiceTestConfigureDialog from './TestsSettings/ServiceTestConfigure.component';
import CustomTestConfigureDialog from './TestsSettings/CustomTestConfigure.component';
import { LicenseType, NetworkOption, NetworkType } from '../../../app/models/jobProperties.model';
import { CustomIcon, InternetConnectionIcon, ServiceIcon } from '../../Icons';
import JobSettingsStore from '../../../app/stores/jobSettings.store';

const useStyles = makeStyles(theme =>({
    heading :{
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#1f396d',
    },
    centerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between'
    },
    listItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiListItemText-root' : {
            marginLeft: 10
        },
        '& .MuiSvgIcon-root': {
            color: '#8092a9'
        }
    },
    editIcon:{
        marginRight: theme.spacing(1),
        cursor: 'pointer'
    },
    helpIcon:
    {
      color: '#1976d2',
      fontSize: "large"
    }
}));

const DeviceTestSelectionComponent: React.FC<{onChange: (selectedTests: SelectedTest) => void}> = (props) => {
    const rootStore = useContext(RootStoreContext);
    const { createJobStore,jobSettingsStore } = rootStore;
    const isPaaS= rootStore.createJobStore.baseSettings.dataMover== DataMover.PaaS;
    const {dataMover}=  rootStore.createJobStore.baseSettings;
    const [openInternetSettingDialog, setOpenInternetSettingDialog] = useState(false);
    const [testState, setTestState] = useDeviceTests(rootStore.createJobStore.baseSettings.dataMover);
    const {selectedDevices} = rootStore.createJobStore;
    const [openDbSettingDialog, setopenDbSettingDialog] = useState(false);
    const [selectAll, setselectAll] = useState(rootStore.createJobStore.networkSetting.NetworkType === NetworkOption.None ? false:(rootStore.createJobStore.baseSettings.dataMover == DataMover.VeeamSQL ?false:true));
    const [openCustomTestSettingDialog, setOpenCustomTestSettingDialog] = useState(false);
    const [openServiceTestDialog, setOpenServiceTestDialog] = useState(false);
    const [openWebPortalTestDialog, setOpenWebPortalTestDialog] = useState(false)
    const [t] = useTranslation("createJobDevices");
    const {isFirstTrial: isTrial} = jobSettingsStore;
    const [disabledState, setdisabledState] = useState<{[key in TestNames]: boolean}>( {
        power: false,
        database: false,
        custom: false,
        network: false,
        service: false,
        web: false,
        autofix: false,
        internet: false,
        branch: false,
        drResources: false,
        authentication: false,
        thirdDeviceNetwork: false,
        PreScriptRes: false
    } );
    const classes = useStyles();

    const onTestSelectionChange = (testType: string) => {
        const newTestState = {...testState, [testType]: !testState[testType]};
        props.onChange(newTestState);
        setTestState(newTestState);
        switch(testType)
        {
            case "internet": createJobStore.updateICTestStatus(!testState[testType]); break;
            case "drResources":createJobStore.updateDRResTestStatus(!testState[testType]); break;
            case "web": if(testState[testType] ==false) setOpenWebPortalTestDialog(true); break;
        }
    }

    const handleSwitch=(testType: string,state:boolean)=>{
        const newTestState = {...testState, [testType]: state};
        props.onChange(newTestState);
        setTestState(newTestState);
    }

    useEffect(() => {
        let tests_ui;
        if(rootStore.createJobStore.baseSettings.dataMover== DataMover.VeeamSQL)
            tests_ui=[testState.custom];
        else
            tests_ui=[testState.power,testState.network,testState.service,testState.custom,testState.autofix,testState.internet,testState.drResources];
        const allValuesNotSeelcted = Object.values(tests_ui).filter(v => !v).length > 0;
        setselectAll(!allValuesNotSeelcted);
    }, [testState, setselectAll])

    useEffect(() => {
        createJobStore.updateDRResTestStatus(testState["internet"]); 
        createJobStore.updateICTestStatus(testState["drResources"]);
    }, [])


    const toggleAll =() => {
        let tests_ui=["power","network","service","custom","drResources","internet"];
        const newtoggleState = !selectAll;
        const newTestState = {...testState};
        !disablePowerOn() ?
        Object.keys(newTestState).forEach(key => tests_ui.includes(key)? newTestState[key]=newtoggleState:newTestState[key]) :
        Object.keys(newTestState).forEach(key  => {
            if(key!= 'power' && key !='autofix')
                if(tests_ui.includes(key))
                    newTestState[key]=newtoggleState;
        })
        setTestState(newTestState);
        setselectAll(newtoggleState);
        props.onChange(newTestState);
    }

    const disablePowerOn = ()=> {
        if(dataMover == DataMover.PaaS || dataMover == DataMover.VeeamSQL)
            return false;
        else
            return true;
    }

    const disableTest=(test:string)=> {
        return true;
        // if(isPaaS)
        // {
        //     if(selectedDevices.length > 0)
        //     {
        //         let custom = selectedDevices.filter(device => device.deviceGroup.includes("Custom"));
        //         return custom.length > 0 ? false: true;
        //     }
        //     return true;
        // }
        // else
        // {
        //     if(rootStore.createJobStore.baseSettings.dataMover == DataMover.VeeamSQL)
        //     {
        //         if(test === "custom")
        //             return false;
        //         else
        //             return true;
        //     }
        //     if(rootStore.createJobStore.networkSetting.NetworkType === NetworkOption.None)
        //         return true;
        //     if(test === "poweron")
        //         return true;
        //     if(rootStore.createJobStore.baseSettings.workflowSetting.serverTest === false)
        //         return true;
        // } 
    }

    useEffect(()=>{
        const _disabledState = {...disabledState};
        const datamover = createJobStore.baseSettings.dataMover
        Object.keys(_disabledState).forEach(key => {
            _disabledState[key]= false;
        })
        if(isPaaS) {
            if(!selectedDevices?.length || !selectedDevices.find (d => d.deviceGroup.includes("custom"))) {
                Object.keys(_disabledState).forEach(key => {
                    _disabledState[key]= true;
                })
            }
        }


        if(datamover === DataMover.VeeamSQL) {
            //disable all except custom
            Object.keys(_disabledState).forEach((key: TestNames) => {

                _disabledState[key]= key!= 'custom'? true: false ;
            })
        }

        if(!createJobStore.baseSettings.workflowSetting.serverTest) {
            //disable all
            Object.keys(_disabledState).forEach(key => {
                _disabledState[key]= true;
            })
        }

        if(isTrial) {
            //disable all except power
            Object.keys(_disabledState).forEach((key: TestNames)  => {
                _disabledState[key]= key!= 'power'? true: false ;
            })
        }

        setdisabledState({..._disabledState});
         
    },[createJobStore.baseSettings.dataMover, createJobStore.networkSetting.NetworkType, selectedDevices])

    const disableSelectAll =()=>{
        if(isPaaS)
            return true;
        if(rootStore.createJobStore.baseSettings.dataMover == DataMover.VeeamSQL)
            return true;
        else
        {
            if(rootStore.createJobStore.networkSetting.NetworkType === NetworkOption.None)
                return true;
            else
            {
                if(!rootStore.createJobStore.baseSettings.workflowSetting.serverTest)
                    return true;
                else
                    return false;
            }
        }
    }

    const loadListItem = (itemName:string) =>{
        switch(itemName)
        {
            case "poweron":
                return(
                    <ListItem disableGutters={true}>
            <div className={classes.listItem}>
                <div className={classes.centerVertical}>
                    <PowerIcon />
                <ListItemText >{t("poweron")}</ListItemText>
                </div>
                <span data-test={'healthSwitch_'+testState.power + '_false'}><Switch checked={testState.power} disabled={true} onChange={() => onTestSelectionChange('power')} color='primary' /></span>
            </div>
        </ListItem>
                );
            case "network":
                return(
                    <ListItem disableGutters={true}>
                         <Tooltip title={disableTest("network") && isTrial ? t('trialModeTestDisabled'): ''}> 
                        <div className={classes.listItem}>
                            <div className={classes.centerVertical}>
                                <NetworkIcon />
                               <ListItemText >{t("network")}</ListItemText>
                            </div>
                            <span data-test={'networkSwitch-'+testState.network}><Switch checked={testState.network} disabled={true} onChange={() => onTestSelectionChange('network')} color='primary' /></span>
                        </div>
                        </Tooltip>
                    </ListItem>
                );
            case "service":
                return(
                    <ListItem disableGutters={true}>
                         <Tooltip title={disableTest("service") && isTrial ? t('trialModeTestDisabled'): ''}> 
                    <div className={classes.listItem}>
                        <div className={classes.centerVertical}>
                            <ServiceIcon />
                            <ListItemText>{t("service")}</ListItemText>
                        </div>
                        <div className={classes.centerVertical}>
                           {!isPaaS && dataMover != DataMover.VeeamSQL && !isTrial ?<IconButton disabled={true}><EditIcon className={classes.editIcon} onClick={()=> setOpenServiceTestDialog(true)}/></IconButton>:null}
                           <span data-test={'serviceSwitch-'+testState.service}><Switch checked={testState.service} disabled={true} onChange={() => onTestSelectionChange('service')} color='primary' /></span>
                        </div>
                    </div>
                    </Tooltip>
                </ListItem>
                );
            case "custom":
                return (
                    <ListItem disableGutters={true}>
                         <Tooltip title={disableTest("custom") && isTrial ? t('trialModeTestDisabled'): ''}> 
                    <div className={classes.listItem}>
                        <div className={classes.centerVertical}>
                            <CustomIcon />
                            <ListItemText >{t("custom")}</ListItemText>
                        </div>
                        <div className={classes.centerVertical}>
                        {!isPaaS && !isTrial?<IconButton disabled={true}><EditIcon className={classes.editIcon}  onClick={()=> setOpenCustomTestSettingDialog(true)}/></IconButton>:null}
                        <span data-test={'customSwitch-'+testState.custom}><Switch color='primary' checked={testState.custom} disabled={true} onChange={() => onTestSelectionChange('custom')} /></span>
                        </div>
                    </div>
                    </Tooltip>
                </ListItem>
                );
            case "autofix":
                return(
                    <ListItem disableGutters={true}>
                         <Tooltip title={disableTest("autofix") && isTrial ? t('trialModeTestDisabled'): ''}> 
            <div className={classes.listItem}>
                <div className={classes.centerVertical}>
                    <FlashOn />
                    <ListItemText >{t("autofix")}</ListItemText>
                </div>
                <span data-test={'autofixSwitch-'+testState.autofix}><Switch checked={testState.autofix} disabled={true} onChange={() => onTestSelectionChange('autofix')} color='primary' /></span>
            </div>
            </Tooltip>
        </ListItem>
                );
            case "database":
                /*return(
                    <ListItem disableGutters={true}>
                    <div className={classes.listItem}>
                        <div className={classes.centerVertical}>
                            <img src='/assets/images/createjob/DevicesTestsIcons/database.svg' style={{marginLeft:'5px'}}></img>
                            <ListItemText >{t("database")}</ListItemText>
                        </div>
                        <div className={classes.centerVertical}>
                        <EditIcon className={classes.editIcon} onClick={()=> setopenDbSettingDialog(true)}/>
                        <span data-test={'databaseSwitch-'+testState.database}><Switch checked={testState.database}  onChange={() => onTestSelectionChange('database')} color='primary' /></span>
                        </div>
                    </div>
                </ListItem>
                );*/
            case "webportal":
                /*return(
                    <ListItem disableGutters={true}>
                    <div className={classes.listItem}>
                <div className={classes.centerVertical}>
                    <WebIcon />
                    <ListItemText>{t("webportal")}</ListItemText>
                </div>
                <div className={classes.centerVertical}>
                {!isPaaS ?<EditIcon className={classes.editIcon} onClick={()=> setOpenWebPortalTestDialog(true)}/>:null}
                <span data-test={'webportalSwitch-'+testState.web}><Switch checked={testState.web} disabled={disableTest()} onChange={() => onTestSelectionChange('web')} color='primary' /></span>
                </div>
            </div>
                    </ListItem>
                );*/
            case "internet":
                return (
                    <ListItem disableGutters={true}>
                         <Tooltip title={disableTest("internet") && isTrial ? t('trialModeTestDisabled'): ''}> 
            <div className={classes.listItem}>
                <div className={classes.centerVertical}>
                    <InternetConnectionIcon style={{marginTop:'7%'}}/>
                    <ListItemText >{t("internet")}</ListItemText>
                </div>
               <div className={classes.centerVertical}>
               <span data-test={'internetSwitch-'+testState.internet}><Switch checked={testState.internet} disabled={true} onChange={() => onTestSelectionChange('internet')} color='primary' /></span>
               </div>

            </div>
            </Tooltip>
        </ListItem>
                );
            case "drresources":
                return(
                    <ListItem disableGutters={true}>
                    <div className={classes.listItem}>
                        <div className={classes.centerVertical}>
                            <MemoryIcon />
                            <ListItemText >{t("drresources")}</ListItemText>
                        </div>
                        <div className={classes.centerVertical}>
                                {/* <EditIcon className={classes.editIcon} onClick={()=> setOpenDRSectionDialog(true)}/> */}
                                <span data-test={'resourcesSwitch-'+testState.drResources}><Switch checked={testState.drResources} disabled={true} onChange={() => onTestSelectionChange('drResources')} color='primary' /></span>
                        </div>
                    </div>
                    </ListItem>
                );
        }
       
    }


    return <List dense={true}>
        <Grid item container direction="row">
        <div className={classes.listItem}>
            <div className={classes.centerVertical}>
                <Typography variant='h6' style={{ color: '#1f396d'}}>{t("deviceTests")}</Typography>
            </div>
            <div className={classes.centerVertical}>
                <span data-test={'selectAllSwitch-'+selectAll}><Switch checked={selectAll} disabled={true} onChange={toggleAll} color='primary' /></span>
            </div>
        </div>
        </Grid>
        <Divider/>
        <ListItem disableGutters={true}>
            <ListItemText>
                <div style={{display:'flex',flexDirection:'row'}}>
                <Typography variant='h6' className={classes.heading}>{t("defaultTests")}</Typography>
                <Tooltip title={t('defaultTestsInfo')} arrow>
                    <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />    
                </Tooltip>
                </div>
            </ListItemText>
        </ListItem>
        {loadListItem("poweron")}
        {loadListItem("network")}
        {loadListItem("service")}
        {loadListItem("custom")}
        <Divider />
        
        <ListItem disableGutters={true}>
            <ListItemText>
            <div style={{display:'flex',flexDirection:'row'}}>
                <Typography variant='h6' className={classes.heading}>{t("globalTests")}</Typography>
                <Tooltip title={t('globalTestsInfo')} arrow>
                    <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />    
                </Tooltip>
            </div>
            </ListItemText>
        </ListItem>
        {loadListItem("internet")}
        {!isPaaS ? loadListItem("drresources") : null}
        {loadListItem("autofix")}
        <Divider />
        <WebTestSettingDialog open={openInternetSettingDialog} onClose={() =>setOpenInternetSettingDialog(false)} isDefault={true} deviceName={null}/>
        <DatabaseTestSettingDialog open={openDbSettingDialog} onClose={() => setopenDbSettingDialog(false)} deviceName={null} isDefault={true} onSave={null}/>
        <CustomTestConfigureDialog open={openCustomTestSettingDialog} onClose={() => setOpenCustomTestSettingDialog(false)} deviceName={null} isDefault={true} onSave={null}/>
        <ServiceTestConfigureDialog open={openServiceTestDialog} onClose={() => setOpenServiceTestDialog(false)} deviceName={null} isDefault={true} onSave={null}/>
        {openWebPortalTestDialog &&  <WebPortalTestSettingDialog open={openWebPortalTestDialog} onChange={(state)=>handleSwitch('web',state)} onClose={() => setOpenWebPortalTestDialog(false)} hostname={null} isDefault={true} onSave={null}/>
    }
    </List>
}

export default observer(DeviceTestSelectionComponent);