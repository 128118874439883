import { JobDeviceResult } from "../../models/jobDevice/jobDeviceResult.model";

class DeviceResultCache {
    private _cache: { [key: number]: JobDeviceResult } = {};


    reset() {
        this._cache = {}
    }

    addItem(deviceResult: JobDeviceResult) {
        this._cache[deviceResult.id + ''] = deviceResult;
    }

    get(id: number) {
        let key = id + '';
        if (this._cache[key]) {
            return this._cache[key];
        }
        return null;
    }
}
const cache = new DeviceResultCache;

export default cache;