import {Grid,makeStyles, Typography, Button, Divider, Switch, RadioGroup, Radio, FormControlLabel, Tooltip, FormControl, CircularProgress} from '@material-ui/core';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { JobType } from '../../../../app/models/jobProperties.model';
import { RootStoreContext } from '../../../../app/stores/root.store';
import HelpIcon from '@material-ui/icons/Help';
import { EDRTextField } from '../../../../app/common/EDRTextField';
import { SaveButton } from '../../../../app/common/saveButton';
import { DataMover } from '../../../../app/models/BaseSettings.enum';
import { CustomCheckbox } from '../../baseSettings/Advanced/BasicAdvanced.component';
import { EDRAutocomplete } from '../../../../app/common/EDRAutocomplete';
import { runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { NetworkTuppleDTO } from '../../../../app/models/createjob/createJob.model';
import ConfirmationDialog from '../../../../app/common/confirmationDialog.component';

const AdvancedVlanComponent: React.FC = (props) => {

    const useStyles = makeStyles({
        helpIcon:
        {
          color: '#1976d2',
          fontSize: "large"
        },
        MainHeading:
        {
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.19',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#1f396d'
        },
        divider:{
            backgroundColor: '#eaeef4',
            width:'100%',
            marginTop:'10px'
        },
        headerDivider:{
            backgroundColor: '#eaeef4',
            marginTop:'8px',
            marginLeft:'10px'
        },
        formControl: {
            width: '100%',
        },
        button: {
            borderRadius: 15,
            color: 'white',
            backgroundColor:'#2892d7',
            width: '23%',
            height: '32px',
            marginTop: '24px',
            marginLeft: '215px'
        }
        });
        
    const classes = useStyles();
    const [t] = useTranslation("createJobNetwork");
    const rootStore = useContext(RootStoreContext);
    const {displayStore,jobSettingsStore,createJobStore} = rootStore;
    const {liveDRNetwork,testDRNetwork} = rootStore.createJobStore.networkSetting;
    const [networkNameDR, setNetworkNameDR] = useState<string>('');
    const [networkNameBubble, setNetworkNameBubble] = useState<string>('');
    const [openConfirmationTupleDialog, setopenConfirmationTupleDialog] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const addMode = displayStore.screenSettings.AddModeNetworks;
      
    useEffect(() => {
        if(addMode == true)
        {
            setNetworkNameDR("");
            setNetworkNameBubble("");
        }
        else
        {
            if(jobSettingsStore.selectedNetworkTupple != null && jobSettingsStore.selectedNetworkTupple != undefined && jobSettingsStore.selectedNetworkTupple.vmNetworkId && jobSettingsStore.selectedNetworkTupple.drNetworkId)
            {
                setNetworkNameDR(jobSettingsStore.vCenterNetworks.find(item=>item.id === jobSettingsStore.selectedNetworkTupple.vmNetworkId).name);
                setNetworkNameBubble(jobSettingsStore.vCenterNetworks.find(item=>item.id === jobSettingsStore.selectedNetworkTupple.drNetworkId).name);
            }
            else if(jobSettingsStore.networkTuppleList.length > 0)
            {
                setNetworkNameDR(jobSettingsStore.vCenterNetworks.find(item=>item.id === jobSettingsStore.networkTuppleList[0].vmNetworkId).name);
                setNetworkNameBubble(jobSettingsStore.vCenterNetworks.find(item=>item.id === jobSettingsStore.networkTuppleList[0].drNetworkId).name);
            }
            else
            {
                setNetworkNameDR(jobSettingsStore.vCenterNetworks[0].name);
                setNetworkNameBubble(jobSettingsStore.vCenterNetworks[1].name);
            }
        }
      }, [displayStore.screenSettings.AddModeNetworks,displayStore.screenSettings.renderValue]); 

      useEffect(() => {
        if((networkNameDR == networkNameBubble) && (networkNameDR!="" &&networkNameBubble !=""))
        {
            toast.error("management and bubble can't be same");
        }
      },[networkNameDR,networkNameBubble]);
    
      const handleChangeNameBubble = (event:any,value:any,type:string) => 
    { 
        if(event!=null)
        {
            setNetworkNameBubble(value);
            let mapping= [{sourceNetwork:networkNameDR,targetNetwork: value}]
            rootStore.createJobStore.updateNetworkSettings({testDRNetwork: {...rootStore.createJobStore.networkSetting.testDRNetwork,networkMapping : mapping}});
            let dc= jobSettingsStore.selectedDC;
            dc.domainControllerTargetVLAN = value;
            jobSettingsStore.updateSelectedDC(dc);
        } 
    };

    const handleChangeNameProd = (event:any,value:any,type:string)  => 
    {  
        if(event!=null)
        {
            setNetworkNameDR(value);
            let mapping= [{sourceNetwork: event.target.value,targetNetwork:networkNameBubble}]
            rootStore.createJobStore.updateNetworkSettings({testDRNetwork: {...rootStore.createJobStore.networkSetting.testDRNetwork,networkMapping : mapping}});
        }
    };

    const handleAdd = async () => 
    {
        setButtonsDisabled(true);
        let networkTuple = new NetworkTuppleDTO();
        networkTuple.drNetworkId = jobSettingsStore.vCenterNetworks.find(n => n.name === networkNameBubble).id;
        networkTuple.vmNetworkId = jobSettingsStore.vCenterNetworks.find(n => n.name === networkNameDR).id;
        networkTuple.infrastructureId = createJobStore.baseSettings.infrastructureId;
        await jobSettingsStore.createNetworkTupple(networkTuple);
        setButtonsDisabled(false);
    }

    const handleUpdate = async () => {
        setButtonsDisabled(true);
        let networkTuple = new NetworkTuppleDTO();
        networkTuple.id= jobSettingsStore.selectedNetworkTupple.id;
        networkTuple.drNetworkId = jobSettingsStore.vCenterNetworks.find(n => n.name === networkNameBubble).id;
        networkTuple.vmNetworkId = jobSettingsStore.vCenterNetworks.find(n => n.name === networkNameDR).id;
        networkTuple.infrastructureId = createJobStore.baseSettings.infrastructureId;
        await jobSettingsStore.editNetworkTupple(networkTuple);
        setButtonsDisabled(false);
    }

    const handleConfirmationPopup =(confirm:boolean)=>{
        if(confirm)
        {
            handleDelete();
        }
       setopenConfirmationTupleDialog(false);
    }

    const handleDelete = async () => 
    {
        setButtonsDisabled(true);
        await jobSettingsStore.deleteNetworkTupple();
        setButtonsDisabled(false);
    }

    const disableAdd =()=>{
        if(networkNameDR =="" || networkNameBubble =="")
            return true;
        else if(networkNameDR == networkNameBubble)
            return true;
        return false;
    }

    return(
            <Grid container direction="column" style={{paddingRight: 16}}>
            <div style={{ display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
                <Typography variant='h6' className={classes.MainHeading}>{t('networkAdvanced')}</Typography>
                <div style={{ display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
                    {!addMode ?<SaveButton onClick={()=>setopenConfirmationTupleDialog(true)} disabled={buttonsDisabled} variant="contained" color="primary" style={{height:'25px',textTransform:'none',marginRight:'10px'}}>
                    {t('delete')}
                    {buttonsDisabled ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                    </SaveButton> :null} 
                    <SaveButton onClick={addMode ? handleAdd:handleUpdate} disabled={disableAdd()|| buttonsDisabled} variant="contained" color="primary" style={{height:'25px',textTransform:'none'}}>
                    {addMode ? t('add') : t('apply')}
                    {buttonsDisabled ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                    </SaveButton>
                </div>   
            </div>
            <Grid container direction="column" style={{flexWrap:'nowrap'}}>
                <Grid item direction="row">
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px' }}>
                        <Typography variant='h6' className={classes.MainHeading} style={{fontSize:'14px'}}>{t('configureNetworks')}</Typography>
                        <Tooltip title={t("configureNetworksInfo")} arrow>
                            <HelpIcon className={classes.helpIcon} style={{ marginLeft: '8px' }} />
                        </Tooltip>
                        <Divider className={classes.headerDivider} style={{ width: '60%' }} />
                    </div>
                </Grid>

                <Grid item container spacing={2} style={{display:'flex',marginTop:'25px',justifyContent:'center'}}>
                                    <Grid item xs={6} >
                                    <FormControl disabled={true} className={classes.formControl} variant='outlined'>    
                                        <EDRAutocomplete id="production-network-select-label"
                                        disableClearable
                                        disabled={true}
                                        options={jobSettingsStore.vCenterNetworks.map(n => n.name)}
                                        value={networkNameDR!="" ? networkNameDR :'Select'}
                                        onChange={(event, newValue)=> handleChangeNameProd(event,newValue,'test')}
                                        renderInput={(params) => (<EDRTextField {...params} disabled={true} label={t('managmentNetwork')} 
                                        InputLabelProps={{shrink: true}} 
                                        variant="outlined"
                                        placeholder="Select"
                                        name="production-network-select-label"
                                        />)}
                                        />
                                    </FormControl> 
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl disabled={true} className={classes.formControl} variant='outlined'>    
                                        <EDRAutocomplete id="bubble-network-select-label"
                                        disableClearable
                                        disabled={true}
                                        options={jobSettingsStore.vCenterNetworks.map(n => n.name)}
                                        value={networkNameBubble!="" ? networkNameBubble :'Select'}
                                        onChange={(event, newValue)=> handleChangeNameBubble(event,newValue,'test')}
                                        renderInput={(params) => (<EDRTextField {...params} disabled={true} label={t('bubbleNetwork')} 
                                        InputLabelProps={{shrink: true}} 
                                        variant="outlined"
                                        placeholder="Select"
                                        name="bubble-network-select-label"
                                        />)}
                                        />
                                    </FormControl> 
                                    </Grid>
                                  
                            </Grid>
            </Grid>
            <ConfirmationDialog type="deleteEDR" device={null} open={openConfirmationTupleDialog} onClose={(confrim)=>handleConfirmationPopup(confrim)} children={"You are about the delete "+ networkNameDR+ " --> "+networkNameBubble +" - are you sure?"}></ConfirmationDialog>
   </Grid>
    );
};

export default observer(AdvancedVlanComponent);