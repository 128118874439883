import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";
import { INoticePanel, INotice } from "../../../app/models/dashboard/dashboard.model";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { RootStoreContext } from "../../../app/stores/root.store";
import { settingObj } from "../../../app/models/setting.model";
import moment from "moment";
import RealDownTimeComponent from "./RealDownTime.component";
import AuditComponent from "./Audit.component";
import ResourcesComponent from "./Resources.component";
import clsx from 'clsx';
import { CircularProgress } from "@material-ui/core";
import { useShowResourceSetting } from "../../../app/common/util/useShowResourcesSetting.hook";

const NoticePanel = (props: INoticePanel) => {
  const rootStore = useContext(RootStoreContext);
  const [t] = useTranslation("dashboard");
  const { dashboardSettings } = rootStore.settingStore;
  const { score } = rootStore.dashboardStore;
  const auditLoaded = rootStore.settingStore.dashboardSettings && rootStore.settingStore.dashboardSettings.length > 0?true:false;
  const Loaded = rootStore.jobResultStore._jobResults && rootStore.jobResultStore._jobResults.length >0 ? true:false;
  const emptyDash=rootStore.dashboardStore.dashboardEmpty;
  const showAuditObj:settingObj = dashboardSettings.find((element:settingObj) => element.settingKey == "ShowNextAuditDate");
  const showAudit: boolean = showAuditObj!=undefined ? JSON.parse(showAuditObj.settingValue) : true;
  const auditDateObj:settingObj = dashboardSettings.find((element:settingObj) => element.settingKey == "NextAuditDate");
  

  useEffect(() => {
    //callMatomo("NoticePanel","/dashboard");
  }, []);

  const getDefaultDate = (dt,n) => {
     const retVal:string = new Date(dt.setFullYear(dt.getFullYear() + n)).toLocaleDateString();      
     return retVal;
  }

  const auditDate = auditDateObj!=undefined? moment(auditDateObj.settingValue).format('DD.MM.YYYY') : getDefaultDate(new Date(), 1);
  const isAuditReady: boolean = score>=85? true : false;
  const showResources = useShowResourceSetting();
  const config: INotice[] = [
    {
      header: t("auditHeader"),
      mainText: showAudit ? auditDate : "",
      secondaryText: showAudit ? t("nextAudit") : t("showNextAudit"),
      goodStateIcon: isAuditReady
    },
    {
      header: t("reasourceHeader"),
      mainText: t("reasourceText"),
      goodStateIcon: true
    },
    {
      header: t("realDownTime"),
      realDowntime: props.realDowntime,
      rpa: props.rpa,
      rta: props.rta
    }
  ];

  const useStyles = makeStyles({
    paper: {
      height: "100%",
    },
  });

  const classes = useStyles();
  
  return (
    <React.Fragment>
      <Grid item xs={showResources ? 4: 6} data-test="component-noticePanel">
        <Paper className={classes.paper} style={!auditLoaded?{ display: 'flex', flexDirection: 'row',justifyContent: 'center',alignItems: 'center'}:{}}>
        {!emptyDash && !auditLoaded
                        ? <CircularProgress  size={50} style={{color: '#2892d7'}}/>
                        :  <AuditComponent {...config[0]} />}
        </Paper>
      </Grid>
          {showResources && <Grid item xs={4}>
        <Paper className={clsx([classes.paper, 'cursor-pointer'])} style={!Loaded?{ display: 'flex', flexDirection: 'row',justifyContent: 'center',alignItems: 'center'}:{}}>
        {!emptyDash && !Loaded
                        ? <CircularProgress  size={50} style={{color: '#2892d7'}}/>
                        : <ResourcesComponent {...config[1]} />}
        </Paper>
      </Grid>}
      <Grid item xs={showResources ? 4: 6}>
        <Paper className={classes.paper} style={!Loaded?{ display: 'flex', flexDirection: 'row',justifyContent: 'center',alignItems: 'center'}:{}}>
        {!emptyDash && !Loaded
                        ? <CircularProgress  size={50} style={{color: '#2892d7'}}/>
                        : <RealDownTimeComponent {...config[2]} />}
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default observer(NoticePanel);
