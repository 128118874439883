import  SvgIcon  from "@material-ui/core/SvgIcon";
import Devices from "@material-ui/icons/Devices";
import React from "react"
import {ReactComponent as PreScriptSvg} from './svgs/prescript.svg';

const PreScriptIcon: React.FC<React.HTMLProps<SVGElement>> = (props) => {
    const {style, className} = props;
    return <SvgIcon viewBox={"-2,-2, 20, 20"}  className={className} style={style} component={PreScriptSvg}>
    </SvgIcon>
}


export default PreScriptIcon;