import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../app/stores/root.store";
import { makeStyles,Typography,Divider,Paper,TextField,InputAdornment,FormControl} from "@material-ui/core";
import { ReactComponent as PlusIcon } from "../../assets/settingsAssets/plusIcon.svg";
import { useTranslation } from "react-i18next";
import SettingLocationPart from "./SettingLocationPart.component";
import { countries } from "./data/countries";
//import { Autocomplete } from "@material-ui/lab";
import { ReactComponent as LocationIcon } from "../../assets/settingsAssets/locationIcon.svg";
import { countryObj, settingObj } from "../../app/models/setting.model";
import { EDRAutocomplete } from "../../app/common/EDRAutocomplete";
import { useFormik } from 'formik';
import { EDRTextField } from '../../app/common/EDRTextField';
import { LocationSettingSchema } from './validation';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { toast } from "react-toastify";
import { runInAction } from "mobx";

const SettingsLocationComponent: React.FC<{onChange: (val) => void}> = (props) => {
  const rootStore = useContext(RootStoreContext);
  const { setContentValid, settingsLoaded} = rootStore.settingStore;
  const [t] = useTranslation("settings");
  const [siteExtraLocationsState, setsiteExtraLocationsState] = useState([]);
  const [siteInititalLocationsState, setsiteInititalLocationsState] = useState([]);
  let counter = rootStore.settingStore.locationSettings.length>1 ? rootStore.settingStore.locationSettings.length-2: 0;
  const defaultCoords = { "name": "Choose City", "location": "", "country":"", "id": 0, "lng": 0, "lat":0};
  const countriesToDisplay = countries!=null? countries.filter(item => item.display) : countries.filter(item => item.code == "IL");
  let initialValuesList = {country:{"name": "", "code": "","display": true}}; 

  const loadSites = (code?: string, reset?:boolean) => {
    return (
      rootStore.settingStore.locationSettings != null && rootStore.settingStore.locationSettings.length>1 && reset!=true ?
        rootStore.settingStore.locationSettings.filter(obj => obj.settingKey!="Country").sort(compare).map(item => (
        <React.Fragment key={item.settingKey}> 
          <SettingLocationPart settingValue={(code&&code.length>0) ? code===rootStore.settingStore.currentCountryCode ? JSON.parse(item.settingValue): defaultCoords : JSON.parse(item.settingValue)} settingKey={item.settingKey} countryCode={(code&&code.length>0)? code :rootStore.settingStore.currentCountryCode}  />
          <Divider/>
        </React.Fragment>
        ))
        :(
        <React.Fragment key={Math.random()}>
          <SettingLocationPart settingValue={defaultCoords} settingKey='coords_prod' countryCode={code}  />
          <Divider/>
          <SettingLocationPart settingValue={defaultCoords} settingKey='coords_dr' countryCode={code}  />
          <Divider/>
        </React.Fragment>
        )
     )
  }


  const addSite = () => {
   const newSites = [...form.values.productionSites, {name: '',
    location: '',
    country: '',
    id:0,
    lat:0,
    lng: 0}]
    form.setFieldValue('productionSites',newSites,true);
  };

  const handleDeleteSite =(site:any,key:string)=>{
    if(form.values.productionSites.length == 1 )
    {
      toast.error("unable to delete city. at least one city need to be applied at production site");
    }
    else
    {
      runInAction(() => {
        rootStore.settingStore.deleteSettings(key).then(()=>
        {
          let result = form.values.productionSites.filter((item) => item.name != site.name);
          form.setFieldValue('productionSites',result,true);
        });
      })
    }
  }

  const loadCurrentCountry = () => {
    const countryObj:settingObj = rootStore.settingStore.locationSettings.filter(item => item.settingKey=="Country")[0];
    const countryValue:countryObj = countryObj? JSON.parse(countryObj.settingValue): countriesToDisplay.find(c => c.code == "IL");
    initialValuesList.country = countryValue;  
    form.setFieldValue("country", countryValue,true);
    rootStore.settingStore.updateCurrentCountry(countryValue.code);
    setsiteInititalLocationsState([...siteInititalLocationsState, loadSites(countryValue.code,false)]);
  }

  const form = useFormik({
    initialValues: 
    {country: {name: "", code: "", display: true}, 
    
    drSite: {
      name: '',
      location: '',
      country: '',
      id: 0,
      lat:0,
      lng: 0
    }, 
    
    productionSites: [{
      name: '',
      location: '',
      country: '',
      id: 0,
      lat:0,
      lng: 0
    }]

  },
    validationSchema:LocationSettingSchema,
    validateOnMount: false,
    onSubmit: () => { },
  })

  useEffect(() => {
    props.onChange && props.onChange(form.values);
},[form.values])


useEffect(() => {
  let dr =  rootStore.settingStore.locationSettings.find(item=>item.settingKey =="coords_dr")!=undefined ? JSON.parse(rootStore.settingStore.locationSettings.find(item=>item.settingKey =="coords_dr").settingValue):null;
  let drSite = {
    name: dr!=null ? dr.name :'',
    location: dr!=null ? dr.location:'',
    country: dr!=null ? dr.country:'',
    id:dr!=null ? dr.id:0,
    lat:dr!=null ? dr.lat:0,
    lng: dr!=null ?  dr.lng:0
  };
  form.setFieldValue("drSite",drSite,true);

  let prod =  rootStore.settingStore.locationSettings.filter(item=>item.settingKey.includes("coords_prod"));
  if(prod.length > 0)
  {
    let ProdArr =[];
    prod.map(item=>{
      let siteObj = JSON.parse(item.settingValue);
      let prodSite = {
        name: siteObj.name,
        location: siteObj.location,
        country: siteObj.country,
        id:siteObj.id,
        lat:siteObj.lat,
        lng: siteObj.lng
      }
      ProdArr.push(prodSite);
    });
    form.setFieldValue("productionSites",ProdArr,true);
  }
  else
    form.setFieldValue("productionSites",[{
      name: '',
      location: '',
      country: '',
      id:0,
      lat:0,
      lng: 0
    }],true)
    loadCurrentCountry();
},[rootStore.settingStore.locationSettings])

  useEffect(() => {
    if (settingsLoaded) 
        loadCurrentCountry()
}, [settingsLoaded]); 

  const useStyles = makeStyles((theme) => ({
    plusIcon: {
      marginLeft: '10px',
      cursor: "pointer",
    },
    locationDetails: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      color: "#4d5f75",
      "& > div": {
        padding: "5px",
        display: "flex",
        //justifyContent: "space-between",
        marginRight: 20,
        marginLeft: 10
      },
    },
    formControl: {
      width: '41%',
      '& label': {
          background: "white",
          width: "auto",
          padding: "0 4px 0 4px",
          fontSize: '17px'
      }
    }
  }));

  const classes = useStyles();

  const compare = (a, b) => {
    const keyA = a.settingKey.toUpperCase();
    const keyB = b.settingKey.toUpperCase();
  
    let comparison = 0;
    if (keyA < keyB) {
      comparison = 1;
    } else if (keyA > keyB) {
      comparison = -1;
    }
    return comparison;
  }

    const changeCountry = (value: countryObj) => {
      if (value)
      {
          setsiteInititalLocationsState([loadSites(value.code,true)]);
          setsiteExtraLocationsState([]);
          rootStore.settingStore.updateLocationObj(JSON.stringify(value),"Country","Location");
          rootStore.settingStore.updateCurrentCountry(value.code);
      
      }
      else 
      {
        setContentValid(false);
        rootStore.settingStore.updateLocationObj("none","Country","Location");
      }
  }


  return (
    <div data-test="settings-location-component">
      <Paper style={{paddingBottom:'2%',paddingTop:'2%'}}>
        <div style={{display: "flex",flexDirection: "row",justifyContent: "space-between",paddingTop: "1em",paddingRight:"5px",marginRight:"20px",marginLeft:"10px"}}>
        <Typography style={{paddingTop: "1em"}} classes={{ root: "settingsText" }}>{t("chooseCountry")}</Typography>
        <FormControl className={classes.formControl} variant='outlined'>
          <EDRAutocomplete id="country"
          onChange={(evt, val:countryObj) => changeCountry(val)} //when user selectes options from the list. val is of type option object
          onInputChange={(evt, val) => form.setFieldValue("country", countriesToDisplay.find(c => c.name.toLocaleLowerCase() == val.toLocaleLowerCase()))} // get user types in something val is of type string
          freeSolo
          disableClearable
          value={form.values.country}
          options={countriesToDisplay}
          getOptionLabel={(option : {name: string,code :string, display: boolean}) => option.name}
          renderOption={(option: {name: string,code :string, display: boolean}) => option.name}
          renderInput={(params) => 
            (<EDRTextField {...params} 
                label="Country"
                size= "small"
                InputLabelProps={{shrink: true}} 
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <LocationIcon />
                      </InputAdornment>
                      {params.InputProps.endAdornment}
                    </>
                  )}}
              variant="outlined"
              placeholder="Type Country Name" 
              name="country"
              onBlur={form.handleBlur}
              error={form.touched && form.touched.country && form.errors && Boolean(form.errors.country)}
              helperText={form.touched && form.touched.country && form.errors && Boolean(form.errors.country) && t(form.errors.country.name)}
          />)}
        />
        </FormControl>
        </div>
        <div className={classes.locationDetails}>
          
        
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <Typography classes={{ root: "settingsTextBold" }}>{t("productionSite")}</Typography>
            <PlusIcon className={classes.plusIcon} onClick={() =>  addSite()}/>
        </div>

          {/* {siteExtraLocationsState}       */}
          {form.values.productionSites.map((prodSite, index) => {
            return (<React.Fragment>
              <span style={{display: 'flex',flexDirection: 'row',alignItems: 'center',justifyContent:'center'}}>
                    <DeleteForeverIcon style={{cursor:'pointer',marginLeft:'3%'}} onClick={()=>{handleDeleteSite(prodSite,"coords_prod_"+index)}}/> 
                    <SettingLocationPart key={index} settingValue={prodSite} settingKey={"coords_prod_"+index} countryCode={rootStore.settingStore.currentCountryCode}  onChange={(locationObj) => form.setFieldValue(`productionSites[${index}]`, locationObj)}  /> 
                    </span>
                    {form.errors?.productionSites && form.errors?.productionSites[index] && <span style={{color: 'red',marginLeft:'4%'}}> {"site name is a required field"} </span>}
              </React.Fragment>)
          })}
           <Divider style={{marginTop:'2%'}}/>

          
           <span style={{display: 'flex',flexDirection: 'row',alignItems: 'center',marginLeft:'3%'}}>
           <SettingLocationPart key={"drSite"} settingValue={form.values.drSite} settingKey={"coords_dr"} countryCode={rootStore.settingStore.currentCountryCode}  onChange={(locationObj) => form.setFieldValue(`drSite`, locationObj)}  />
            </span>
            {form.errors?.drSite && <span style={{color: 'red',marginLeft:'4%'}}> {"site name is a required field"} </span>}
        </div>
      </Paper>
    </div>
  );
};

export default observer(SettingsLocationComponent);
