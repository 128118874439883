import { CircularProgress, Divider, FormControl, Grid, makeStyles, TextField, Tooltip, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useContext, useState }  from 'react';
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "../../../../app/stores/root.store";
import HelpIcon from '@material-ui/icons/Help';
import { SaveButton } from "../../../../app/common/saveButton";
import { AssetType } from "../../../../app/models/createjob/createJob.model";
import AssetAgent from '../../../../app/api/assetsAgent';
import { toast } from 'react-toastify';
import JobSettingsStore from "../../../../app/stores/jobSettings.store";
import ConfirmationDialog from "../../../../app/common/confirmationDialog.component";

const EDRServerSettingsComponent : React.FC = () => {

    const useStyles = makeStyles({
        helpIcon:
        {
          color: '#1976d2',
          fontSize: "large"
        },
        MainHeading:
        {
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.19',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#1f396d',
            marginLeft:'10px'
        },
        SecondHeading:{
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1
        },
        divider: {
                backgroundColor: '#eaeef4',
                width: '315%',
                margin: '0',
                marginTop: '10px',
                marginLeft: '10px',
        },
        formControl: {
            width: '100%',
            height:'70px',
            '& label': {
                background: "white",
                width: "auto",
                padding:"0 4px 0 4px",
                fontSize: '17px',
              }
        }
    });
    const classes = useStyles();
    const [t] = useTranslation("createJobNetwork");
    const rootStore = useContext(RootStoreContext); 
    const {displayStore,jobSettingsStore}= rootStore;
    const addMode = displayStore.screenSettings.AddModeEDR;
    const [hostnameIP, setHostnameIP] = useState("");
    const [inPortState, setInPortState] = useState<number>(rootStore.createJobStore.networkSetting.inPort);
    const [outPortState, setOutPortState] = useState<number>(rootStore.createJobStore.networkSetting.outPort);
    const [openConfirmationEDRDialog, setopenConfirmationEDRDialog] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    useEffect(()=>{
        if(addMode)
        {
            setHostnameIP("");
        }
        else
        {
            setHostnameIP(jobSettingsStore.selectedEDR.assetName);
        }
    },[addMode,displayStore.screenSettings.renderValue])

    useEffect(()=>{
        disableAddApply();
        loadCircular();
     },[displayStore.screenSettings.EDRApplyProcessing])
    
    useEffect(() => {
        setTimeout(() => populateFields(), 1000);
      }, []); 

    const populateFields = () => {
        if (rootStore.createJobStore.networkSetting.inPort  != null)
            setInPortState(rootStore.createJobStore.networkSetting.inPort)
        if (rootStore.createJobStore.networkSetting.outPort  != null)
            setOutPortState(rootStore.createJobStore.networkSetting.outPort)
    }

    const handlehostnameIPChange =(event) => {
        setHostnameIP(event.target.value);
    };

    const handleInPortChange = (event) => {
        setInPortState(event.target.value);
        rootStore.createJobStore.updateNetworkSettings({ inPort: parseInt(event.target.value) });
    };

    const handleOutPortChange = (event) => {
        setOutPortState(event.target.value);
        rootStore.createJobStore.updateNetworkSettings({ outPort: parseInt(event.target.value) });
    };
    
    const loadCircular =()=>{
        return false;
    }

    const disableAddApply =()=> {
        if(!addMode)
            return true;
        else
        {
            if(addMode && displayStore.screenSettings.EDRApplyProcessing)
                return true;
            else
                return false;
        }
    }

    const disableDelete =()=>{
        if(jobSettingsStore.EDRs.length == 0)
            return true;
        else
            return displayStore.screenSettings.EDRApplyProcessing;
    }

    const handleTest =  async() => {
        setButtonsDisabled(true);
        let result;
        displayStore.updateScreenSettings({EDRApplyProcessing:true});
        try
        {
            let res = await AssetAgent.TestAsset({assetName:jobSettingsStore.selectedEDR, assetType:AssetType.EDR});
            toast.success(rootStore.createJobStore.networkSetting.EDRServer.assetName + ': ' + t('successTestEDR'))
            displayStore.updateScreenSettings({EDRApplyProcessing:false});
            result= res;
            setButtonsDisabled(false);
        }
        catch
        {
            toast.error(rootStore.createJobStore.networkSetting.EDRServer.assetName + ': ' + t('failedTestEDR'))
            displayStore.updateScreenSettings({EDRApplyProcessing:false});
            result= false;
            setButtonsDisabled(false);
        }
        return result;
    }

    const handleAdd =  async () => {
        setButtonsDisabled(true);
        let edr = jobSettingsStore.selectedEDR;
        edr.assetName = hostnameIP;
        jobSettingsStore.updateTempEDR(edr);
        await jobSettingsStore.createAsset(jobSettingsStore.tempEDR,AssetType.EDR);
        setButtonsDisabled(false);
    }

    const handleConfirmationPopup =(confirm:boolean)=>{
        if(confirm)
        {
            handleDelete();
        }
        setopenConfirmationEDRDialog(false);
    }

    const handleDelete=  async () => {
        setButtonsDisabled(true);
        await jobSettingsStore.deleteAsset(AssetType.EDR);
        setButtonsDisabled(false);
    }


    return( 
    <Grid container direction="column">
    
        <div style={{ display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
        <Typography variant='h6' className={classes.MainHeading}>{t('EDRSettings')}</Typography>
        <div style={{ display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
        {!addMode ? <SaveButton onClick={()=>setopenConfirmationEDRDialog(true)} disabled={disableDelete() ||buttonsDisabled} variant="contained" color="primary" style={{height:'25px',textTransform:'none',marginRight:'10px'}}>
        {loadCircular() ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
        {t('delete')}
        </SaveButton> :null } 
        <SaveButton onClick={addMode ? handleAdd: handleTest} disabled={disableAddApply()||buttonsDisabled} variant="contained" color="primary" style={{height:'25px',textTransform:'none'}}>
        {loadCircular() ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
        {addMode ? t('add') : t('apply')}
        </SaveButton>
        </div>   
        </div>

        <Grid item container direction="row">
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px' ,marginLeft:'10px'}}>
                <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{AssetType[AssetType.EDR]+ t('configuration')}</Typography>
                <Tooltip title={t("InstanceConfigurationInfo")} arrow>
                    <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                </Tooltip> 
                <Divider className={classes.divider}/>
            </div>
        </Grid>
      
        <Grid container direction="column">
            <Grid container style={{marginTop:'10px'}} spacing={1}>
            <Grid item xs={12} spacing={1} > 
            <FormControl className={classes.formControl} variant='outlined'>   
            <TextField id="servername"
             label={t('hostnameIP')}
             placeholder=""
             value={hostnameIP}
             disabled={!addMode}
             style={{ margin: 8 }}
             onChange={handlehostnameIPChange}
             InputLabelProps={{
             shrink: true,
             }}
             variant="outlined">
         </TextField>
             </FormControl>
         </Grid>
         </Grid>

            <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px' ,marginLeft:'10px'}}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('portsettings')}</Typography>
                    <Tooltip title={t("EDRSettingsInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip> 
                    <Divider className={classes.divider}/>
                </div>
            </Grid>

            <Grid item container direction="row" spacing={2} style={{marginTop:'16px'}}>
            <Grid item xs={3}>
                <FormControl className={classes.formControl} variant='outlined'>
                <TextField id="in-port"
                    label={t('inport')}
                    placeholder=""
                    value={inPortState}
                    disabled={true}
                    style={{ margin: 8 }}
                    onChange={handleInPortChange}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    variant="outlined">
                </TextField>
            </FormControl>
            </Grid>

            <Grid item xs={3}>
            <FormControl className={classes.formControl} variant='outlined'>
                <TextField id="out-port"
                    label={t('outport')}
                    placeholder=""
                    value={outPortState}
                    disabled={true}
                    style={{ margin: 8 }}
                    onChange={handleOutPortChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined">
                </TextField>
            </FormControl>
            </Grid>
            </Grid>
            
        </Grid>

       <ConfirmationDialog type="deleteEDR" device={null} open={openConfirmationEDRDialog} onClose={(confrim)=>handleConfirmationPopup(confrim)} children={"You are about the delete "+ hostnameIP +" - are you sure?"}></ConfirmationDialog>
    </Grid>
    );

};

export default observer(EDRServerSettingsComponent);