import { Button, Dialog, DialogActions, DialogContent, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, makeStyles, Tooltip, Typography,CircularProgress } from "@material-ui/core";
import { TestSettingProps } from "./customTestSetting.component";
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as React from 'react'
import { useContext, useEffect, useState } from 'react';
import { RootStoreContext } from "../../../../app/stores/root.store";
import { useTranslation } from "react-i18next";
import { TestType } from "../../../../app/models/jobTest.model";
import { EDRDialogTitle } from "../../../../app/common/EDRDialogTitle";
import { SaveButton } from "../../../../app/common/saveButton";
import { EDRTextField } from "../../../../app/common/EDRTextField";
import { EDRAutocomplete } from "../../../../app/common/EDRAutocomplete";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ReactDOM from "react-dom";
import { EDROutlinedInput } from "../../../../app/common/EDROutlinedInput";
import Visibility from "@material-ui/icons/Visibility";
import { DatabaseType, DevicePlatform, MachineType } from "../../../../app/models/jobProperties.model";
import HelpIcon from '@material-ui/icons/Help';
import { toast } from 'react-toastify';
import { CONSTS } from "../../../../app/models/createjob/createJob.model";
import jobAgent from "../../../../app/api/jobAgent";

const useStyles = makeStyles({
    
    helpIcon:
    {
      color: '#1976d2',
      fontSize: "large"
    },
    SecondHeading:{
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        flexGrow: 1
    },
    divider: {
        backgroundColor: '#eaeef4',
        maxWidth: '53%',
        margin: '0',
        marginTop: '10px',
        marginLeft: '10px',
    },
    formControl: {
        width: '100%',
        '& label': {
            background: "white",
            width: "auto",
            padding:"0 4px 0 4px",
            fontSize: '17px'
          }
    },
    centerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between'
    },
    toggleItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiListItemText-root' : {
            marginLeft: 10
        },
        '& .MuiSvgIcon-root': {
            color: '#8092a9'
        }
    },
    root: {
        minWidth: '30vw'
    },
    title:{
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontstyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        textAlign: 'left'
    }
})

const validationSchema = yup.object({
    username: yup.string(),
    password: yup.string()
})

const AuthenticationTestSettingDialog: React.FC<TestSettingProps> = (props) => {
    let labelRef;
    const { open,deviceName,isDefault, onClose,onSave } = props;
    const classes = useStyles();
    const { t } = useTranslation('createJobBase');
    const {createJobStore,jobSettingsStore} = useContext(RootStoreContext);
    const [device, setDevice] = useState(createJobStore.selectedDevices.find(device=>device.deviceName == deviceName));
    const [authenticationTest,setauthenticationTest] =useState(device!= undefined ? device.tests.find(test=> test.testType == TestType.authentication):null);
    const getPreviousSettings = jobSettingsStore.accessCredentials.concat(jobSettingsStore.linuxCredentails);
    const [cancel,setCancel]=useState(false);
    const [processingTest,setProcessingTest] = useState(false);
    let username='';
    let password='';

    const loadParameters =()=>{
        if(device.os === DevicePlatform.Windows)
        {
            let cred = jobSettingsStore.accessCredentials.find(d => d.id == createJobStore.baseSettings.workflowTest.PrimaryUserCredsId);
            username = cred.userName;
            password = cred.password;
        }
        else
        {
            if(createJobStore.baseSettings.workflowTest.PrimaryUserCredsId === 0)
            {
                username = '';
                password = '';
            }
            else
            {
                let cred = jobSettingsStore.linuxCredentails.find(d => d.id == createJobStore.baseSettings.workflowTest.SecondaryUserCredsId);
                username = cred.userName;
                password = cred.password;
            }
        }
    }

    const form = useFormik({
        initialValues: {
            username: username,
            password:password
        },
        onSubmit: () => { },
        validationSchema
    })

    const [showEyePassword, setShowEyePassword]= useState(form.values.password !='' ? true:false);
    const [passwordVisibility,setPasswordVisibility] = useState(true);
    const [UserPassAutocomplete,setUserPassAutocomplete] = useState(false);
     

    const handleClose = () => {
        if(form.touched && form.dirty)
            setCancel(!cancel);
        else
            onClose();
    }

    const handleSave=()=>{
        try{
        let DeviceTest = {
                testCategory:authenticationTest.testCategory, 
                testType: TestType.authentication, 
                selected: true,
                customCommandToTest: '',
                customCommandExpectedResult: '', 
                customCommandToTestIsExternal: false,
                databaseType: DatabaseType.SQL,
                databaseNameToTest: '',
                databaseQueryToTest: '',
                databaseServerNameToTest: '',
                databaseUsername:'',
                databasePassword: '',
                databasePort:'',
                dbWindowsAuth:false,
                serviceToTest:'',
                testFromEDRC: false,
                script: '',
                thirdDeviceNetworkToTest: '',
                authenticationUserToTest:form.values.username,
                authenticationPassToTest:form.values.password,
                webPortalToTest:'' ,
                webPortalResult: ''
        };
        createJobStore.updateTestDevice(TestType.authentication,DeviceTest,device.planName.includes("Custom Device") ? device.sourceName : device.deviceName);
        toast.success('Save successful');
    }
    catch(error){
        toast.success('Save failed'+error);
    }
        setCancel(false);
        onSave();
        onClose();
    }

    const handleTest=() =>{    
        var details =
        {
            machineName:device.sourceName,
            username:form.values.username,
            password: form.values.password,
            devicePlatform: device.os,
            id:device.os === DevicePlatform.Windows ? jobSettingsStore.selectedPrimaryUser.id :jobSettingsStore.selectedSecondaryUser.id
        };
        setProcessingTest(true);
        jobAgent.JobTestsActions.TestAuthentication(details).then(res => {
            if(res)
                toast.success("Authentication Test succeeded");
            else
                toast.error("Authentication Test failed");
            setProcessingTest(false);
        }).catch(err=>{
        if(err && err.data && err.data.errors)
            toast.error(err.data.errors);
        setProcessingTest(false);
        });           
    }
    
    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel style={{marginLeft:'10px'}}>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const handleYes=()=>{
        let initialValues = {
            username: authenticationTest.authenticationUserToTest,
            password: authenticationTest.authenticationPassToTest
        };
        form.setValues(initialValues);
        setCancel(false);
        onClose();
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }


    const handleClickShowPassword  = () => {
        setPasswordVisibility(passwordVisibility? false: true);
    };
    
    const handleUsernameChange = (event:React.ChangeEvent,value:any) => {
        if(event!=null){
            let onInput= event.type=="click"? false:true;
            setUserPassAutocomplete(onInput);
            if (value!=null && typeof value === 'object') 
            {
                form.setFieldValue("username",value.userName,true);
            }
            if (typeof value === 'string')
            {
              const userObj = getPreviousSettings.find(e => e.userName.toLocaleLowerCase() == value.toLocaleLowerCase());
              let pass = userObj== undefined ? '' :userObj.password;
              form.setFieldValue("username",value,true);
              form.setFieldValue("password",pass,true);
            }
            setShowEyePassword(onInput);
          }

    };
    
    const handlePassChange = (event) => {
        setUserPassAutocomplete(true);
        setShowEyePassword(true);
        form.setFieldValue("password",event.target.value,true);
    };

    const handleMouseClick =()=>{
        if(form.values.password === CONSTS.MaskedPassword)
        {
            form.setFieldValue("password",'',true);
        }
    }

    const handleMouseOut =()=>{
        if(form.dirty && form.values.password == '')
        {
            if(form.values.username!='')
            {
                return;
            }
            else form.setFieldValue("password",CONSTS.MaskedPassword,true);
        }
    }

    useEffect(() => {
        const newDevice = createJobStore.selectedDevices.find(device=>device.deviceName == deviceName);
        setDevice(newDevice);
        const newAuthTest = newDevice!= undefined ? newDevice.tests.find(test=> test.testType == TestType.authentication):null;
        setauthenticationTest(newAuthTest);
        let initialValues;
        loadParameters();
        initialValues =
        {
            username: newAuthTest &&  newAuthTest.authenticationUserToTest!="" ? newAuthTest.authenticationUserToTest: username,
            password: newAuthTest &&  newAuthTest.authenticationPassToTest!="" ? newAuthTest.authenticationPassToTest: password
        };
        form.resetForm({values: initialValues});
    }, [deviceName]);

    return <Dialog disableBackdropClick
    open={open}
    classes={{ paper: classes.root }}
    onClose={handleClose}
    scroll="paper"
>
    <EDRDialogTitle>
    <div style={{ display: 'flex', flexDirection: 'row'}}>
        <Typography variant="h6" className={classes.title} style={{display:'flex',alignItems:'center'}}>{t('authenticationSetting')}</Typography>
        {device !== undefined ? <img style={{marginLeft:'5px'}} src={device.os=== DevicePlatform.UNIX ?"/assets/images/editDeviceIcons/linuxIcon.svg":"/assets/images/editDeviceIcons/windowsIcon.svg"}></img>:null}
    </div>
    </EDRDialogTitle>
    <DialogContent dividers={true}>

    <Grid item container direction="row">
        <div style={{ display: 'flex', flexDirection: 'row',paddingBottom:'5%'}}>
            <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('subAuthentication')+deviceName}</Typography>
            <Tooltip title={t('authenticationInfo')} arrow>
                <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
            </Tooltip> 
            <Divider className={classes.divider}/>
        </div>
    </Grid>

        <Grid container direction="column" spacing={1}>
        <Grid item container>
        <Grid item xs={6} spacing={1} > 
                   <FormControl className={classes.formControl} style={{width:'94%'}} variant='outlined'>   
                   <EDRAutocomplete id="username"
                        freeSolo
                        value={form.values.username}
                        options={getPreviousSettings}
                        renderOption={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
                        getOptionLabel={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
                        onChange={handleUsernameChange}
                        onInputChange={handleUsernameChange}
                        renderInput={(params) => (<EDRTextField {...params}
                        label= {t('username')}
                        InputLabelProps={{shrink: true}} 
                        style={{ margin: 8 ,backgroundColor:'#ffffff'}}
                        margin="normal"
                        variant="outlined"
                        placeholder="Select/Create New"
                        />)}
                        />
                    </FormControl>
                    </Grid>
                    <Grid item xs={6} spacing={1}>
                    <FormControl className={classes.formControl} variant='outlined'>
                                <InputLabel ref={ref => {labelRef = ReactDOM.findDOMNode(ref)}} style={{transform: 'translate(14px, 2px) scale(0.75)'}}>{ t('password')}</InputLabel>
                                <EDROutlinedInput id="password" 
                                value={form.values.password}
                                autoComplete="off"
                                aria-autocomplete="none"
                                type={passwordVisibility ? 'password':'text'}
                                placeholder="Type Here"
                                label={<InputLabel style={{marginTop:'8px'}}>{ t('password')}</InputLabel>}
                                style={{ margin: 8,backgroundColor:'#ffffff' }}
                                onChange={handlePassChange}
                                onClick={handleMouseClick}
                                onBlur={handleMouseOut}
                                endAdornment={ UserPassAutocomplete ?
                                <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}>
                                {passwordVisibility ?  <VisibilityOff />: <Visibility />}
                                </IconButton></InputAdornment>:null}/>  
                                </FormControl>
                    </Grid>
            </Grid>
        </Grid>
    </DialogContent>
    <DialogActions>
    <Grid item xs={6}>
        {loadConfirmation()}
        </Grid>
        <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end'}}>
        <Button onClick={handleClose} color="primary" style={{textTransform:'none'}}>
            {t('cancel')}
        </Button>
        <Button onClick={handleTest} color="primary" disabled={form.values.username =="" || form.values.password =="" || processingTest} style={{textTransform:'none',marginLeft:'-11px'}}>
        {processingTest ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                {t('Test')}
        </Button>
        <SaveButton onClick={handleSave} variant="contained" color="primary" style={{textTransform:'none'}} disabled={form.values.username === '' || form.values.password ===''}>
            {t('save')}
        </SaveButton>
        </Grid>
    </DialogActions>
</Dialog>
}

export default AuthenticationTestSettingDialog;