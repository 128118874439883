import { Typography, CircularProgress, Badge, TextField, InputAdornment, makeStyles,withStyles, Grid, Divider } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import JobCategoryIcon from '../../app/common/jobCategoryIcon'
import SelectList from '../../app/common/selectList.component'
import ListSkeleton from '../../app/common/util/listSkeleton.component'
import { JobSummaryGroupByJob } from '../../app/models/jobSummaryGroupByJob.model'
import { RootStoreContext } from '../../app/stores/root.store'
import SearchIcon from '@material-ui/icons/SearchOutlined';
import { observer } from 'mobx-react-lite'
import { useDebounce } from '../../app/common/util/useDebounce.hook'

const useStyles = makeStyles(theme => ({
    filter_container: {
      height: "100%",
      background: "white",
      padding: '15px',
      display: 'flex',
      flexDirection: 'column'
    }
}));

const FilterTextField = withStyles({
    root: {
        padding: 4,
        marginTop: "15px",
        maxWidth:'100%',
        display:'flex',
        '& .MuiInput-underline:hover': {
            border: 0,
        },
        '& .MuiInput-underline:hover:before': {
            border: 0,
        },
        '& .MuiInput-underline:after': {
            border: 0
        },
        '& .MuiInput-underline:before': {
            border: 0
        },
        background: "white",
        borderRadius: 28,
        border: '1px solid #eaeef4',
        overflow: "hidden"
    },
})(TextField);

const useFilteredJobList = function(jobList: Array<JobSummaryGroupByJob>, jobNameFilter: string) {
    const [filteredJobList, setfilteredJobList] = useState<Array<JobSummaryGroupByJob>>([]);
    useEffect(() => {
      if(!jobNameFilter) 
        setfilteredJobList(jobList);
      else{ 
      const _jobNameFilter = jobNameFilter.toLocaleLowerCase();
      setfilteredJobList(jobList.filter(j => j.jobName.toLocaleLowerCase().indexOf(_jobNameFilter.toLocaleLowerCase()) >= 0));
      }
    }, [jobList, jobNameFilter]);
    
    return filteredJobList;
  }
  

interface ReportJobFilterProps {
    selectedJobId: number;
    jobList: Array<JobSummaryGroupByJob>;
    section:string;
    onChange: (selection: JobSummaryGroupByJob) => void
}

const ReportJobFilter: React.FC<ReportJobFilterProps> = (props) => {
    const { jobList, onChange, selectedJobId,section } = props;
    const [filter, setFilter] = useState('');
    const debouncedFilter = useDebounce(filter, 400);
    const filteredJobList = useFilteredJobList(jobList, debouncedFilter);
    const [t] = useTranslation('report');
    const jobRunStore = useContext(RootStoreContext).jobRunStore;
    const classes = useStyles();

    return <div className={classes.filter_container} >
      <div style={{ display: 'flex', flexDirection: 'column', background: 'white'}}>
        <span data-test="filterTitle">
            <Typography classes={{ root: "jobFilter" }}>{section ==="regular"?t("filterJob"):t("deletedJobs")}</Typography>
        </span>  
        <span data-test="filterInput">
            <FilterTextField
            onChange={event => setFilter(event.target.value)} 
            placeholder={section ==="regular" ?"Search Job":"Search Deleted Job" } 
            variant="standard"
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
                ),
                }}
            />
         </span>
      </div>
        <div className="scrollbox scrollbox_delayed" style={{flexGrow: 1,overflowY: 'scroll'}}>
            <div className="scrollbox-content">

                {jobList ? <SelectList<JobSummaryGroupByJob>
                    value={jobList.find(j => j.jobId === selectedJobId)}
                    onChange={onChange}
                    options={filteredJobList || []}
                    getLabel={(option) => <span id={`report_job_filter_${option.jobName}`}>{((option.isDeleted || option.isDeletedPermanently) ? `* ${option.jobName}` : option.jobName)}</span>}
                    getIcon={option => jobRunStore.isJobRunning(option.jobId) ? <CircularProgress size={12} style={{ color: '#2892d7', marginRight: 2 }} /> : <JobCategoryIcon status={option.lastStatus || option.lastScore} variant="small" full={true} style={{ height: "12px", marginRight: "5px" }} />}
                    getCountBagde={option => <Badge color="default" badgeContent={option.jobResultCount}><React.Fragment></React.Fragment></Badge>}
                /> : <ListSkeleton />}
            </div>
        </div>
    </div>
    
}

export default observer(ReportJobFilter);