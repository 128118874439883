import agentbase from './agentBase';
import { JobResultSummaryResponse } from '../models/JobResult.reponse.model';
import {JobDeviceResultResponse} from '../models/jobDevice/jobDeviceResult.reponse.model';
import { DevicesEnvelope, PaginatedResponse } from '../models/paginatedResponse.model';
import { JobResultTimeline } from '../models/JobResultTimeline.model';
import { JobTest } from '../models/jobTest.model';
import { JobSummaryGroupByJob } from '../models/jobSummaryGroupByJob.model';
import { JobTrend } from '../models/jobTrend.model';
import { LastJobRun } from '../models/lastjobRun.model';
import { JobTestOverview } from '../models/JobTestOverview.model';
import { ReportData } from '../models/reportdata.model';
import { JobNamesGroupByJob } from '../models/jobNamesGroupByJob.model';
import { memoise } from '../common/util/memoise';
import { DeviceTest } from '../models/createjob/createJob.model';
import { CancelablePromise } from '../common/util/cancellablePromise';
import _axios from 'axios';
const requests = agentbase.requests;
const axios = agentbase.axios;

const JobResultsApi = {
    getLastJobResult: (jobId: string): Promise<JobResultSummaryResponse> => {
        return requests.get(`/JobSummaryResults/${jobId}/lastresult`);
    },
    devices: memoise( function (jobResultId: number, page: number= 0, limit: number = 0, fields?: string): CancelablePromise<PaginatedResponse<Array<JobDeviceResultResponse>>>
    {   const source = _axios.CancelToken.source();
        
        return axios.get(`JobSummaryResults/jobResults/${jobResultId}/devices`, {params: {page, limit, fields}, cancelToken: source.token}).then(res =>res.data)
        .asCancelable(
            () => source.cancel()
        );
    }),
    getJobResultById:(jobResultId): Promise<JobResultSummaryResponse> => {
        return requests.get(`/JobSummaryResults/${jobResultId}`);
    },
    getJobResults:(startDate: string, endDate: string): Promise<Array<JobResultSummaryResponse>> => {
        return axios.get<{dashboardData: Array<JobResultSummaryResponse>}>(`/JobSummaryResults/list`, {params: {startDate, endDate}})
        .then(res => res.data.dashboardData);
    },
    getJobResultTimeline:(jobId: number,before: string = null, page: number): Promise<PaginatedResponse<Array<JobResultTimeline>>> => {
        const params = {page, before};
        return axios.get<PaginatedResponse<Array<JobResultTimeline>>>(`/JobSummaryResults/${jobId}/timeline`, {params}).then(res => res.data);
    },
    getJobResultTests:(jobResultId): Promise<Array<JobTest>> => {
        return requests.get(`/JobSummaryResults/${jobResultId}/testresults`);
    },
    getJobTestOverview: (jobResultId): Promise<JobTestOverview> => {
        return requests.get(`/JobSummaryResults/${jobResultId}/testoverview`);
    },
    getGroupByJob:():CancelablePromise<Array<JobSummaryGroupByJob>> => {
        const source = _axios.CancelToken.source();
        return axios.get("JobSummaryResults/groupbyjob", {cancelToken: source.token}).then(r => r.data).asCancelable(() => source.cancel());
    },

    getNamesByJob:():Promise<Array<JobNamesGroupByJob>> => {
        return requests.get("JobSummaryResults/groupbyjob");
    },

    getTrend: (jobId: number, jobResultId: number):Promise<JobTrend> => {
        return requests.get(`/jobSummaryResults/${jobId}/result/${jobResultId}/trend`);
    },

    getlastJobRuns: (): Promise<PaginatedResponse<Array<LastJobRun>>> => {
        return requests.get('/jobs/lastrun');
    },
    getDevice: (id: number): Promise<{jobDeviceResult: JobDeviceResultResponse, deviceTests: Array<DeviceTest>,deviceUserName:string}> => {
        return requests.get(`jobDeviceResult/${id}`)
    },
    getReport: (jobResultId: number): Promise<ReportData> => {
    return requests.get(`report/${jobResultId}`);
    },
    sendMail: (jobResultId: number): Promise<any> => {
        return requests.get(`/jobSummaryResults/${jobResultId}/sendMail`);
    },
    getLogs: (jobResultId: number): Promise<string> => {
        return requests.get(`/jobSummaryResults/${jobResultId}/logs`);
    },
    getRRS: (jobResultId: number): Promise<string> => {
        return requests.get(`/jobSummaryResults/${jobResultId}/rrs`);
    },
    getRTA: (jobResultId: number): Promise<string> => {
        return requests.get(`/jobSummaryResults/${jobResultId}/rta`);
    },
    getJobResultByDate:(jobId: any, date: string| Date): Promise<JobResultSummaryResponse> => {
        return requests.get(`/JobSummaryResults/getByDate?jobId=${jobId}&date=${date}`, );
    },

}

export default JobResultsApi;