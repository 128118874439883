import Chart from 'chart.js';

// NOTE
// Due to typescript typeCheckin . We are not able pass custom properties to chart options.
// In this plugin we need image url to render
// we get image url from the title property

/**
 * @description : draws the icon in the center of the dougnut chart
 * 
 */
const DrawIconPlugin: Chart.PluginServiceRegistrationOptions={
afterDraw: function(chartInstance: Chart, options) {
       var  ctx = chartInstance.ctx;
        const canvas = ctx.canvas;
        const img = new Image();
        img.src = chartInstance.options.title.text as string;
        img.width = 20
        img.height = 20
        img.onload = () => {
            let posx = canvas.clientWidth / 2 - img.width / 2;
            let posy = canvas.clientHeight / 2 - img.height;
            ctx.drawImage(img, posx, posy- 2);

          
        }

        const targetValue = (chartInstance.data.datasets[0].data[0] as number) +  (chartInstance.data.datasets[0].data[1] as number);
        ctx.font= 'bold 1.5rem Roboto';
        ctx.textAlign = "center";
        ctx.textBaseline="hanging";
        ctx.fillStyle="#1f396d";
        ctx.fillText(targetValue +'',canvas.clientWidth/2, (canvas.clientHeight/2)+ 2);
    }
}

export default DrawIconPlugin;

