import Chart from 'chart.js';

type fontOptions = {
    font: string,
    color: string,
}

const ValueLabelPlugin = function (formatValue: (value) => string, valueFont?: fontOptions, title?: string, titleFont?: fontOptions): Chart.PluginServiceRegistrationOptions {
    return {
        afterDraw: function (chartInstance: Chart, easing, options) {
            var x = chartInstance.ctx.canvas.clientWidth / 2,
                y = (chartInstance.ctx.canvas.clientHeight / 2)+10,
                ctx = chartInstance.ctx;
            const targetValue = chartInstance.data.datasets[0].data[0] as number;
            const value = (Number.parseFloat(easing) * targetValue).toFixed(0).toString();
            ctx.textAlign = "center";
            if (valueFont) {
                ctx.font = valueFont.font;
                ctx.fillStyle = valueFont.color;
            }
            var offset = 10
            ctx.fillText(formatValue(value), x, y);
            const metric = ctx.measureText(value);
            const dx = metric.actualBoundingBoxRight;
            ctx.font= 'bold 1.2rem Roboto';
            ctx.textAlign = "left";
            ctx.fillText('%', x+dx, y);
            if (title) {
                if (titleFont) {
                    ctx.font = titleFont.font;
                    ctx.fillStyle = titleFont.color;
                }
                ctx.textAlign = "center";
                const metric = ctx.measureText(value);
                var dy = metric.actualBoundingBoxAscent + metric.actualBoundingBoxDescent;
                ctx.fillText(title, x, y + dy + offset);
            }
        }
    }
}

export default ValueLabelPlugin;

//example usage

/**
 * import React from 'react'

import { Doughnut } from 'react-chartjs-2';
import DoughnutBackgroundFillPlugin from '../../app/common/chartjsPlgins/doughnutBackgroundFill.plugin';
import ValueLabelPlugin from '../../app/common/chartjsPlgins/ValueLabel.plugin';

const RecoveryReadinessChart: React.FC<{ score: number }> = (props) => {

    const chartConfig = {
        labels: [
            '',
            '',
        ],
        datasets: [{
            data: [props.score, 100 - props.score],
            backgroundColor: [
                '#8bc53f',
                '#077187'
            ],
            hoverBackgroundColor: [
                '#FF6384',
                '#36A2EB'
            ]
        }]
    };

    return <Doughnut redraw={false} data={chartConfig} options={{
        title: { display: false },
        hover: { mode: null },
        events: [],
        legend: { display: false }, cutoutPercentage: 90, layout: { padding: 0 }, tooltips: { enabled: false }
    }}
        plugins={[ValueLabelPlugin((val) => val + "%", { font: "1.5em Roboto", color: "#1f396d" }, "Recoverable", { font: "1em Roboto", color: "#4d5f75" }),
            DoughnutBackgroundFillPlugin]} />


}

export default RecoveryReadinessChart;
 */