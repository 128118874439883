import { useEffect, useRef, useState } from "react";

 const useIntersect = ({ root = null, rootMargin, threshold = 0 }): [IntersectionObserverEntry, React.Dispatch<any>] => {
  const [entry, updateEntry] = useState<IntersectionObserverEntry>(null);
  const [node, setNode] = useState(null);

  const observer = useRef(
    new window.IntersectionObserver(([entry]) => updateEntry(entry), {
      root,
      rootMargin,
      threshold
    })
  );

  useEffect(
    () => {
      const { current: currentObserver } = observer;
      currentObserver.disconnect();

      if (node) currentObserver.observe(node);

      return () => currentObserver.disconnect();
    },
    [node]
  );

  return [entry, setNode];
};

export default useIntersect;