import { observable, action, computed, runInAction, reaction } from 'mobx'
//import { history} from '../../'
import { Asset } from '../models/asset.model';
import agentbase from '../api/agentBase';
import { ServerStatusManager } from './ServerStatusManager';

const axios = agentbase.axios;
export default class ServerStatusStore {
   @observable private _isAvailable: boolean = true;
   
   constructor() {
       (() => {
        const fetchStatus = () => {
            axios.get('/HealthCheck/ping').then(res => {
                if(res) {
                    ServerStatusManager.setServerStatus(true);
                runInAction(() => {
                    this._isAvailable = true;
                })
                } else {
                    ServerStatusManager.setServerStatus(false);
                    runInAction(() => {
                        this._isAvailable = false;
                    })
                }
            }).catch(err => {
                ServerStatusManager.setServerStatus(false);
                runInAction(() => {
                    this._isAvailable = false;
                })
            }).finally(() => {
                setTimeout(fetchStatus, 5000);
            })
             
        }
        fetchStatus();
       })()
    
   }

   @computed
   get isServerAvailable() {
       return this._isAvailable;
   }
}
