import agentbase from './agentBase';
import { JobDeviceSearch } from '../models/jobDevice/jobDeviceSearch.model';

const requests = agentbase.requests;

const JobDeviceAgent = {
    search: (query: string): Promise<Array<JobDeviceSearch>> => {
       return requests.get(`/jobDeviceResult/search?query=${query}`);
    }
}



export default JobDeviceAgent
