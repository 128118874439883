import { CreateJobProperties } from "./createjob/createJob.model";

export interface Draft {
    id: number
    jobProperties: CreateJobProperties;
    jobStep: number;
    draftState?: DraftState;
}
export enum DraftState {
    InProgress = 0,
    Finished = 1
}