import {Grid,makeStyles, Typography, Button, Divider, Switch, RadioGroup, Radio, FormControlLabel, Tooltip, FormControl} from '@material-ui/core';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { JobType } from '../../../../app/models/jobProperties.model';
import { RootStoreContext } from '../../../../app/stores/root.store';
import HelpIcon from '@material-ui/icons/Help';
import { EDRTextField } from '../../../../app/common/EDRTextField';
import { SaveButton } from '../../../../app/common/saveButton';
import { DataMover } from '../../../../app/models/BaseSettings.enum';
import { CustomCheckbox } from '../../baseSettings/Advanced/BasicAdvanced.component';

const NetworkControlSettingsComponent: React.FC = (props) => {

    const useStyles = makeStyles({
        helpIcon:
        {
          color: '#1976d2',
          fontSize: "large"
        },
        MainHeading:
        {
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.19',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#1f396d'
        },
        divider:{
            backgroundColor: '#eaeef4',
            width:'100%',
            marginTop:'10px'
        },
        headerDivider:{
            backgroundColor: '#eaeef4',
            marginTop:'8px',
            marginLeft:'10px'
        },
        formControl: {
            width: '100%',
        },
        button: {
            borderRadius: 15,
            color: 'white',
            backgroundColor:'#2892d7',
            width: '23%',
            height: '32px',
            marginTop: '24px',
            marginLeft: '215px'
        }
        });
        
    const classes = useStyles();
    const [t] = useTranslation("createJobNetwork");
    const rootStore = useContext(RootStoreContext);
    const {displayStore} = rootStore;
    const {liveDRNetwork,testDRNetwork} = rootStore.createJobStore.networkSetting;
    const {jobType}= rootStore.createJobStore.baseSettings;
    const [AllowInState, setAllowInState] = useState(false);
    const [AllowOutState, setAllowOutState] = useState(false);
    const [newNetwork,setNewNetwork]= useState();

      useEffect(() => {
        setTimeout(() => populateFields());
      }, []); 

    const populateFields = () => {
        if(jobType== JobType.Live)
        {
            if (liveDRNetwork.allowInbubble!=null)
                setAllowInState(liveDRNetwork.allowInbubble);
            if (liveDRNetwork.allowOutbubble!=null)
                setAllowOutState(liveDRNetwork.allowOutbubble);
        }
        else
        {
            if (testDRNetwork.allowInbubble!=null)
                setAllowInState(testDRNetwork.allowInbubble);
            if (testDRNetwork.allowOutbubble!=null)
                setAllowOutState(testDRNetwork.allowOutbubble);
        }
    }

    const handleAllowInChange = (event) =>{
        setAllowInState(event.target.checked);
        if(jobType== JobType.Live)
            rootStore.createJobStore.updateNetworkSettings({liveDRNetwork: {...liveDRNetwork, allowInbubble : event.target.checked}});
        else
            rootStore.createJobStore.updateNetworkSettings({testDRNetwork: {...testDRNetwork, allowInbubble : event.target.checked}});
    };

    const handleAllowOutChange = (event) =>{
        setAllowOutState(event.target.checked);
        if(jobType== JobType.Live)
            rootStore.createJobStore.updateNetworkSettings({liveDRNetwork: {...liveDRNetwork, allowOutbubble : event.target.checked}}); 
        else
            rootStore.createJobStore.updateNetworkSettings({testDRNetwork: {...testDRNetwork, allowOutbubble : event.target.checked}});  
    };

    const handleBlur = (event:any)=>{
        displayStore.updateScreenSettings({blurNetwork: newNetwork});
    }
    
    return(
            <Grid container direction="column" style={{paddingRight: 16}}>
            <div style={{ display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
                <Typography variant='h6' className={classes.MainHeading}>{t('networkAdvanced')}</Typography>
            </div>
            <Grid container direction="column" style={{flexWrap:'nowrap'}}>
                <Grid item direction="row">
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px' }}>
                        <Typography variant='h6' className={classes.MainHeading} style={{fontSize:'14px'}}>{t('networkControl')}</Typography>
                        <Tooltip title={t("networkControlInfo")} arrow>
                            <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                        </Tooltip>
                        <Divider className={classes.headerDivider} style={{ width: '60%' }} />
                    </div>
                </Grid>
            </Grid>

            <Grid container direction="row" style={{display: 'flex',justifyContent: 'space-between',alignItems: 'center', marginTop:'16px'}}>
                    <Grid item style={{display: 'flex',flexDirection:'column'}}>
                    <FormControlLabel 
                        value={t('moveInBubble')}
                        control={<CustomCheckbox icon={<CircleUnchecked/>} checkedIcon={<CircleCheckedFilled  />} checked={AllowInState} onChange={handleAllowInChange}/>}
                        label={<Typography>{t('moveInBubble')}</Typography>}
                       />

                    <FormControlLabel 
                        value={t('moveOutBubble')}
                        control={<CustomCheckbox icon={<CircleUnchecked/>} checkedIcon={<CircleCheckedFilled  />} checked={AllowOutState} onChange={handleAllowOutChange}/>}
                        label={<Typography>{t('moveOutBubble')}</Typography>}
                       />
                    </Grid>
            </Grid>
   </Grid>
    );
};

export default observer(NetworkControlSettingsComponent);