import { runInAction } from "mobx";
import moment from "moment";
import useDeviceTests from "../../features/createJob/deviceSection/getInitalTests.hook";
import jobAgent from "../api/jobAgent";
import { DataMover, getDataMover, VeeamSolution } from "../models/BaseSettings.enum";
import { AssetDTO, CreateJobProperties, CredentialUserType, DeviceTest, DomainControllerDTO, PrePostScriptType, TestCategory } from "../models/createjob/createJob.model";
import { JobDevice } from "../models/jobDevice/jobDevice.model";
import { JobBaseSettings, JobType, InfrastructureType, MachineType, JobNetworkSetting, NetworkOption, DnsResolveType, JobReportSetting, BootCaptureType, JobScheduleSetting, ScheduleRepeatType, getInfra, DatabaseType, NetworkType, CustomDeviceState } from "../models/jobProperties.model";
import { TestType } from "../models/jobTest.model";
import { getDNS } from "../models/Network.enum";
import { settingObj } from "../models/setting.model";

function parseTestGroups(testGroupJSON: string) {
    try {
        return JSON.parse(testGroupJSON)
    }catch{
        return [];
    }
}

function InitDefaultDate (){
    const currentDate= new Date();
    var todayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
    var momentVariable = moment(todayDate,'YYYY-MM-DDTHH:mm');  
    return momentVariable.format('YYYY-MM-DDTHH:mm');   
}

export class CreateJobHelper {  
    
    static getDefaultBaseSetting= (baseSettings: JobBaseSettings):JobBaseSettings => { 
        const initialBase:JobBaseSettings = 
        {
            id:0,
            jobName: '',
            jobId: null,
            jobType: JobType.Test,
            dataMover: baseSettings.dataMover == null ? DataMover.SRM : baseSettings.dataMover,
            infrastructureType: baseSettings.infrastructureType == null ? InfrastructureType.VMware : baseSettings.infrastructureType,
            licenseId:0,
            originalLicenseId:0,
            licenseName:'',
            infrastructureId:0,
            dataMoverId:0,
            veeamSolution:VeeamSolution.Backup,
            rto:60,
            powerOnTime:120,
            parallelTestExecution:30,
            maxTestTimeout:300,
            timeEngineFinish:3000,
            failoverTimeout:7200,
            testHeartbeat:true,
            fetchByDefault:baseSettings.dataMover == DataMover.VeeamSQL ? false: true,
            vra : '',
            veeamApiPort: 9392,
            planNameFilter: [],
            scriptsSettings: {
                failoverScriptSetting:{
                    preId: 0,
                    postId: 0,
                    prePath:'',
                    prePathData:null,
                    postPath:'',
                    postPathData:null,
                    preTTL:15,
                    postTTL:15,
                    type: PrePostScriptType.EDRServer
                },
                cleanupScriptSetting :{
                    preId: 0,
                    postId: 0,
                    prePath:'',
                    prePathData:null,
                    postPath:'',
                    postPathData:null,
                    preTTL:15,
                    postTTL:15,
                    type: PrePostScriptType.EDRServer 
                },
                TestScriptSetting:{
                    preId: 0,
                    postId: 0,
                    prePath:'',
                    prePathData:null,
                    postPath:'',
                    postPathData:null,
                    preTTL:15,
                    postTTL:15,
                    type: PrePostScriptType.ControllerServer
                }
            },
            workflowSetting: {
                preTest: true,
                failover: true,
                serverTest: true,
                snapshot: true,
                cleanup: true,
            },
            workflowTest: {
                Type: MachineType.Windows,
                PrimaryUser: '',
                PrimaryPassword: '',
                SecondaryUser: '',
                SecondaryPassword: '',
                PrimaryUserCredsId:0,
            },
            testGroups: {
                id: 0,
                groups:
                [
                    {groupOrder: 1,groupName:"Group1"},
                    {groupOrder: 2,groupName:"Group2"},
                    {groupOrder: 3,groupName:"Group3"},
                    {groupOrder: 4,groupName:"Group4"},
                    {groupOrder: 5,groupName:"Group5"},
                    {groupOrder: 6,groupName:"Group6"},
                    {groupOrder: 7,groupName:"Group7"}
                ]
            },
            networkTuppleId:0
        };
        return initialBase;
    };

   static getDefaultNetworkSetting= () :JobNetworkSetting =>{
        const initialNetwork :JobNetworkSetting= {
            EnsureDRController: {
                assetName:'',
                assetIP: '',
                assetVMName: '',
                credentials:{
                    id: 0,
                    userName:'',
                    password:'',
                    userType: CredentialUserType.GeneralUser 
                },
                id:0
            },
            domainController: {
                domainControllerName:'',
                dcHostServerSource:'',
                dcHostServerTarget:'',
                dcDataStoreTarget:'',
                domainControllerTargetVLAN:'',
                domainName:'',
                domainCredentialsId:0,
                domainCredentials:{
                    id: 0,
                    userName:'',
                    password:'',
                    userType: CredentialUserType.GeneralUser },
                domainControllerESXCredentialsId:0,
                domainControllerESXCredentials:{
                    id: 0,
                    userName:'',
                    password:'',
                    userType: CredentialUserType.ESXUser },
                id:0
            },
            EDRServer : {
                assetName:'',
                assetIP: '',
                assetVMName: '',
                credentials:{
                    id: 0,
                    userName:'',
                    password:'',
                    userType: CredentialUserType.GeneralUser 
                },
                id:0
            },
            liveDRNetwork: {
                type: NetworkOption.Single,
                networkMapping: [{sourceNetwork:'',targetNetwork:''}],
                allowInbubble:false,
                allowOutbubble:false
            },
            testDRNetwork: {
                type: NetworkOption.Single,
                networkMapping: [{sourceNetwork:'',targetNetwork:''}],
                allowInbubble:false,
                allowOutbubble:false
            },
            inPort: 5876,
            outPort: 5876,
            NetworkType:NetworkOption.Single,
            dnsResolve: DnsResolveType.None,
            additionalEdrcIp :['0.0.0.0'],
            additionalEdrcDns :['0.0.0.0'],
            additionalDefaultGateway :['0.0.0.0']
        };
        return initialNetwork;
    };

    static getDefaultSelectedDevices= ():Array<JobDevice> =>{
        return [];
    };

    static getDefaultJobReportSetting= (): JobReportSetting =>{
        const initalReport:JobReportSetting ={
            emailTo: null,
            phoneNumbersForSMS: null,
            sendEmail: false,
            sendSMS: true,
            bootCaptureType: BootCaptureType.Animation,
            bootTime: 40
        }
        return initalReport;
    };

    static getDefaultJobScheduleSetting= (): JobScheduleSetting =>{
        const initalSchedule:JobScheduleSetting= {
            nextRun: InitDefaultDate(),
            scheduleRepeatType: ScheduleRepeatType.Week,
            repeatInterval: 1,
            setSchedule: true,
            setRepeat:true
        }
        return initalSchedule;
    }

    static getBaseSettings(baseSettings: JobBaseSettings,jobProperties:CreateJobProperties): JobBaseSettings {
        baseSettings.id= jobProperties.id;
        baseSettings.infrastructureId = jobProperties.infrastructureId;
        baseSettings.dataMoverId = jobProperties.dataMoverId;
        baseSettings.dataMover = jobProperties.dataMoverType;
        baseSettings.veeamSolution = jobProperties.veeamSolution;
        baseSettings.infrastructureType = jobProperties.infrastructureType;
        baseSettings.jobName=jobProperties.jobName;
        baseSettings.licenseId=jobProperties.licenseId;
        baseSettings.originalLicenseId=jobProperties.licenseId;
        baseSettings.jobType =jobProperties.jobType;
        baseSettings.rto= jobProperties.rto;
        baseSettings.vra= jobProperties.vra;
        baseSettings.powerOnTime= jobProperties.powerOnTime;
        baseSettings.parallelTestExecution= jobProperties.parallelTestExecution;
        baseSettings.maxTestTimeout= jobProperties.maxTestTimeout;
        baseSettings.timeEngineFinish= jobProperties.timeEngineFinish;
        baseSettings.failoverTimeout = jobProperties.failoverTimeout;
        baseSettings.testHeartbeat= jobProperties.testHeartbeat;
        baseSettings.fetchByDefault = jobProperties.fetchByDefault;
        baseSettings.workflowTest.PrimaryUserCredsId=jobProperties.accessCredentialsId;
        baseSettings.workflowTest.SecondaryUserCredsId=jobProperties.linuxCredentialsId;
        baseSettings.vra = jobProperties.vra;
        baseSettings.veeamApiPort = jobProperties.veeamApiPort;
        baseSettings.testGroups.groups = parseTestGroups(jobProperties.testGroups.groups);
        baseSettings.testGroups.id = jobProperties.testGroups.id;
        baseSettings.networkTuppleId = jobProperties.networkTuppleId;
        baseSettings.jobId = jobProperties.jobId;
        CreateJobHelper.getWorkflowSetting(baseSettings,jobProperties);
        CreateJobHelper.getPrePostSetting(baseSettings,jobProperties);
        return baseSettings;
    }
    static getBaseSettingsNew(baseSettings: JobBaseSettings,jobProperties:CreateJobProperties): JobBaseSettings {
        baseSettings.id= 0;
        baseSettings.infrastructureId = jobProperties.infrastructureId;
        baseSettings.dataMoverId = jobProperties.dataMoverId;
        baseSettings.dataMover = jobProperties.dataMoverType;
        baseSettings.infrastructureType = jobProperties.infrastructureType;
        baseSettings.licenseId=jobProperties.licenseId;
        baseSettings.originalLicenseId=jobProperties.licenseId;
        baseSettings.jobType =jobProperties.jobType;
        baseSettings.rto= jobProperties.rto;
        baseSettings.vra= jobProperties.vra;
        baseSettings.powerOnTime= jobProperties.powerOnTime;
        baseSettings.parallelTestExecution= jobProperties.parallelTestExecution;
        baseSettings.maxTestTimeout= jobProperties.maxTestTimeout;
        baseSettings.timeEngineFinish= jobProperties.timeEngineFinish;
        baseSettings.failoverTimeout= jobProperties.failoverTimeout;
        baseSettings.testHeartbeat= jobProperties.testHeartbeat;
        baseSettings.fetchByDefault = jobProperties.fetchByDefault;
        baseSettings.workflowTest.PrimaryUserCredsId=jobProperties.accessCredentialsId;
        baseSettings.workflowTest.SecondaryUserCredsId=jobProperties.linuxCredentialsId;
        baseSettings.vra = jobProperties.vra;
        baseSettings.veeamApiPort = jobProperties.veeamApiPort;
        baseSettings.networkTuppleId = jobProperties.networkTuppleId;
        CreateJobHelper.getWorkflowSetting(baseSettings,jobProperties);
        return baseSettings;
    }
    static getWorkflowSetting(baseSettings: JobBaseSettings,jobProperties:CreateJobProperties) {
        baseSettings.workflowSetting.preTest = jobProperties.preTestEnabled;
        baseSettings.workflowSetting.snapshot = jobProperties.snapshotEnabled;
        baseSettings.workflowSetting.serverTest = jobProperties.serverTestEnabled;
        baseSettings.workflowSetting.failover = jobProperties.failOverEnabled;
        baseSettings.workflowSetting.cleanup = jobProperties.cleanUpEnabled;
    }

    static getPrePostSetting(baseSettings:JobBaseSettings,jobProperties:CreateJobProperties) {
        let prepostScripts= jobProperties.prePostScripts;
        prepostScripts.map((script) =>
        {
            switch(script.scriptName){
                case "prefailover":
                    {
                        baseSettings.scriptsSettings.failoverScriptSetting.preId = script.id;
                        baseSettings.scriptsSettings.failoverScriptSetting.prePath= script.name;
                        baseSettings.scriptsSettings.failoverScriptSetting.prePathData=script.data;
                        baseSettings.scriptsSettings.failoverScriptSetting.preTTL= script.ttl;
                        baseSettings.scriptsSettings.failoverScriptSetting.type = script.type
                        break;
                    }
                case "postfailover":
                    {
                        baseSettings.scriptsSettings.failoverScriptSetting.postId = script.id;
                        baseSettings.scriptsSettings.failoverScriptSetting.postPath=script.name;
                        baseSettings.scriptsSettings.failoverScriptSetting.postPathData= script.data;
                        baseSettings.scriptsSettings.failoverScriptSetting.postTTL=script.ttl;
                        baseSettings.scriptsSettings.failoverScriptSetting.type = script.type
                        break;
                    }
                case "pretest":
                    {
                        baseSettings.scriptsSettings.TestScriptSetting.preId = script.id;
                        baseSettings.scriptsSettings.TestScriptSetting.prePath=script.name;
                        baseSettings.scriptsSettings.TestScriptSetting.prePathData=script.data;
                        baseSettings.scriptsSettings.TestScriptSetting.preTTL=script.ttl;
                        baseSettings.scriptsSettings.TestScriptSetting.type = script.type;
                        break;
                    }
                case "posttest":
                    {
                        baseSettings.scriptsSettings.TestScriptSetting.postId = script.id;
                        baseSettings.scriptsSettings.TestScriptSetting.postPath=script.name;
                        baseSettings.scriptsSettings.TestScriptSetting.postPathData=script.data;
                        baseSettings.scriptsSettings.TestScriptSetting.postTTL=script.ttl;
                        baseSettings.scriptsSettings.TestScriptSetting.type = script.type;
                        break;
                    }
                case "precleanup":
                    {
                        baseSettings.scriptsSettings.cleanupScriptSetting.preId = script.id;
                        baseSettings.scriptsSettings.cleanupScriptSetting.prePath=script.name;
                        baseSettings.scriptsSettings.cleanupScriptSetting.prePathData=script.data;
                        baseSettings.scriptsSettings.cleanupScriptSetting.preTTL=script.ttl;
                        baseSettings.scriptsSettings.cleanupScriptSetting.type = script.type;
                        break;
                    }
                case "postcleanup":
                    {
                        baseSettings.scriptsSettings.cleanupScriptSetting.postId = script.id;
                        baseSettings.scriptsSettings.cleanupScriptSetting.postPath=script.name;
                        baseSettings.scriptsSettings.cleanupScriptSetting.postPathData=script.data;
                        baseSettings.scriptsSettings.cleanupScriptSetting.postTTL=script.ttl;
                        baseSettings.scriptsSettings.cleanupScriptSetting.type = script.type;
                        break;
                    }
            }
        })
    }

    static getDevices(jobProperties:CreateJobProperties) : Array<JobDevice> {
        let devices = [];
        let UI_TEST =[TestType.power,TestType.network ,TestType.service, TestType.custom,TestType.autofix, TestType.database, TestType.web,TestType.thirdDeviceNetwork,TestType.PreScriptRes,TestType.authentication];
        jobProperties.plans.forEach(p => {
        let allTests :Array<DeviceTest> = [];
        let checkedTests =[];
        p.devices.map(d=> {
        allTests = UI_TEST.map(test => ({
                testCategory: TestCategory.Default, 
                testType: test, 
                selected: false,
                customCommandToTest: '', 
                customCommandExpectedResult: '', 
                customCommandToTestIsExternal: false,
                databaseType:DatabaseType.SQL,
                databaseNameToTest: '',
                databaseQueryToTest: '',
                databaseServerNameToTest: '',
                databaseUsername: '',
                databasePassword: '',
                databasePort:'1433',
                dbWindowsAuth:false,
                serviceToTest: '',
                testFromEDRC: false,
                script: '',
                thirdDeviceNetworkToTest: '',
                authenticationUserToTest:'',
                authenticationPassToTest:'',
                webPortalToTest: '',
                webPortalResult: ''
            }));
            d.tests.map(test=>{
            allTests.map((t,index)=>{
                {
                    if(t.testType == test.testType)
                        allTests[index]= ({
                            id:test.id,
                            testCategory: test.testCategory, 
                            testType: test.testType, 
                            selected:test.selected,
                            customCommandToTest: test.customCommandToTest, 
                            customCommandExpectedResult: test.customCommandExpectedResult, 
                            customCommandToTestIsExternal: test.customCommandToTestIsExternal,
                            databaseType:test.databaseType,
                            databaseNameToTest: test.databaseNameToTest,
                            databaseQueryToTest: test.databaseQueryToTest,
                            databaseServerNameToTest: test.databaseServerNameToTest,
                            databaseUsername: test.databaseUsername,
                            databasePassword: test.databasePassword,
                            databasePort:test.databasePort,
                            dbWindowsAuth:test.dbWindowsAuth,
                            serviceToTest: test.serviceToTest,
                            testFromEDRC: test.testFromEDRC,
                            script: d.scriptName,
                            thirdDeviceNetworkToTest: test.thirdDeviceNetworkToTest,
                            authenticationUserToTest:d.deviceCredentials && d.deviceCredentials.userName,
                            authenticationPassToTest:d.deviceCredentials && d.deviceCredentials.password,
                            webPortalToTest: test.webPortalToTest?.includes('http://') ? test.webPortalToTest: 'http://' + test.webPortalToTest,
                            webPortalResult: test.webPortalResult
                        });
                }
            }); 
            d.planBootOrder=p.planBootOrder??0;
        })
        checkedTests.push(allTests); 
    });
        devices = [...devices, ...p.devices.map((d,idx) =>({...d,os: d.devicePlatform, planName: p.name,tests: checkedTests[idx], customDeviceState: p.name.includes("Custom Devices") ?(jobAgent.CreateJobActions.CheckCustomDeviceDeleted(d.deviceName) ?CustomDeviceState.Deleted:CustomDeviceState.Active ):CustomDeviceState.Active }))];
        })
        return devices;
     }

    static getScheduleSettings(setting: JobScheduleSetting,jobProperties:CreateJobProperties ): JobScheduleSetting {
        setting.repeatInterval = jobProperties.repeatInterval;
        setting.setRepeat = jobProperties.setSchedule == false ? false : (jobProperties.repeatInterval== 0 ? false:true);
        setting.setSchedule = jobProperties.setSchedule;
        setting.nextRun = jobProperties.nextRun!='01/01/0001 0:00:00' ? jobProperties.nextRun:null;
        setting.scheduleRepeatType = jobProperties.scheduleRepeatType;
        return setting;
    }

    static getReportSettings(setting: JobReportSetting,emailSetting:Array<settingObj>,jobProperties:CreateJobProperties ): JobReportSetting {
        let customEmailSettingItems= emailSetting.filter(s=> s.settingSection=="CustomEmail");
        setting.emailTo=jobProperties.emailTo;
        setting.sendEmail=jobProperties.sendMail;
        setting.sendSMS=jobProperties.sendSMS ;
        setting.phoneNumbersForSMS= jobProperties.phoneNumberForSMS;
        setting.bootCaptureType=jobProperties.bootCaptureType;
        setting.bootTime = (setting.bootCaptureType == 0 ? jobProperties.bootTime : 0);
        customEmailSettingItems["LocalSMTPAddress"] = jobProperties.EmailSetting!=null ? jobProperties.EmailSetting.address:'';
        customEmailSettingItems["LocalSMTPPort"] = jobProperties.EmailSetting!=null ? jobProperties.EmailSetting.port:0;
        customEmailSettingItems["EmailFrom"] = jobProperties.EmailSetting!=null ?jobProperties.EmailSetting.emailFrom:'';
        customEmailSettingItems["SMTPUser"] = jobProperties.EmailSetting!=null ?jobProperties.EmailSetting.username:'';
        customEmailSettingItems["SMTPPassword"] = jobProperties.EmailSetting!=null ?jobProperties.EmailSetting.password:'';
        return setting;
    }

    static getNetworkInfo(baseSettings:JobBaseSettings,setting: JobNetworkSetting,jobProperties:CreateJobProperties): JobNetworkSetting {
        let {EnsureDRController,domainController,inPort,outPort,liveDRNetwork,testDRNetwork,additionalDefaultGateway,additionalEdrcDns,additionalEdrcIp,EDRServer} = setting;
        let {jobType}= baseSettings;
        let mapping= [{sourceNetwork:jobProperties.liveDRNetworkName,targetNetwork: jobProperties.testDRNetworkName}];
        setting.testDRNetwork.networkMapping= mapping;
        testDRNetwork.allowInbubble = jobType==0 ? jobProperties.allowInbubble:false;
        testDRNetwork.allowOutbubble = jobType==0 ? jobProperties.allowOutbubble:false;
        liveDRNetwork.allowInbubble = jobType==1 ? jobProperties.allowInbubble:false;
        liveDRNetwork.allowOutbubble =  jobType==1 ? jobProperties.allowOutbubble:false;
        EDRServer.id = EDRServer && jobProperties.edrServerId;
        if(EnsureDRController == null || EnsureDRController == undefined)
        {   
            EnsureDRController = new AssetDTO();
        }
        EnsureDRController.id = jobProperties.edrControllerServerId != undefined && jobProperties.edrControllerServerId != null ? jobProperties.edrControllerServerId:0;
        EnsureDRController.assetName =EnsureDRController && jobProperties.edrcDispalyName!=null ? jobProperties.edrcDispalyName:'';
        domainController.id= domainController && jobProperties.domainControllerId;
        setting.dnsResolve = getDNS(jobProperties.dnsResolve);
        additionalEdrcIp = jobProperties.additionalIPs;
        additionalEdrcDns  =jobProperties.additionalDNSs;
        additionalDefaultGateway = jobProperties.additionalDGs;
        inPort = jobProperties.inPort;
        outPort = jobProperties.outPort;
        setting.NetworkType = jobProperties.networkType;
        return setting;
    }

}