import React, { useState, useEffect } from 'react'
import { TextField, InputAdornment, List, ListItem, ListItemIcon, Checkbox, ListItemText, makeStyles, Badge, Tooltip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import clsx from 'clsx';

export type CheckboxOption = {
    label: string | any,
    checked: boolean,
    count?: number,
    groupOrder?: string 
    type?: number 
}

export type CheckBoxFilterProps = {
    options: Array<CheckboxOption>,
    showAllOption?: boolean,
    showCount?: boolean,
    showSearchInput?: boolean,
    allOptionLabel?: React.ReactNode,
    allOptionIcon? : React.ReactElement,
    renderLabel?: (option: CheckboxOption) => React.ReactNode
    onChange: (value:  Array<CheckboxOption>) => void
}

const useStyle = makeStyles({
    subList: {
        paddingLeft: 16,
        paddingTop: 0
    },
    checked: {
    color: '#2892d7 !important'
    },
    checkboxstyle: {
        '&.MuiCheckbox-root': {
            color: '#c7cfdb'
        }
    },
    badge: {
        backgroundColor: "#d6d6d9",
        color: "#4d5f75",
        minWidth: 30
    },
    listIcon: {
        minWidth: 0
    },
    rightIcon: {
        display: 'flex',
        justifyContent: 'flex-end',
        minWidth: 0,
    },
    zeroTopBottomPadding: {
        paddingBottom: 0,
        paddingTop: 0
    }
})

const CheckBoxFilter: React.FC<CheckBoxFilterProps> = (props) => {
    const [filter, setFilter] = useState('');
    // const al = props.options.every(option => option.checked);
    const [allSelected, setAllSelecteed] = useState(props.options.every(option => option.checked));
    const [allCount, setAllCount] = useState(0);
    const classes = useStyle();

    useEffect(() => {
        setAllSelecteed(props.options.every(option => option.checked));
        setAllCount(props.options.reduce((acc, val) => {
            return acc+val.count;
        }, 0))
    }, [props.options])

    const handleCheckboxClick = (targetOption: CheckboxOption, checked: boolean) => {
        const newOption = {...targetOption, checked};
        props.onChange([...props.options.map(option => option === targetOption? newOption: option )]);
    }
    const handleAllSelectChange = () => {
        props.onChange([...props.options.map(option => ({...option, checked: !allSelected}))]);
    }
    return <React.Fragment>
        {props.showSearchInput && <TextField
                    style={{marginTop: "15px"}}
                    placeholder="Search" variant="standard"
                    onChange={event => setFilter(event.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />}
        {props.showAllOption && <ListItem classes={{root: classes.zeroTopBottomPadding}}>
            <ListItemIcon classes={{root:classes.listIcon}}>
                        <Checkbox
                            edge="start"
                            tabIndex={-1}
                            onChange={handleAllSelectChange}
                            disableRipple
                            checked={allSelected}
                            classes={{checked: classes.checked}}
                            className={classes.checkboxstyle}
                            size="small"
                        />
                    </ListItemIcon>
                    <ListItemText primary={props.allOptionLabel || <span>All</span>} />
                    {props.showCount && <ListItemIcon className={classes.rightIcon}>
                            {props.allOptionIcon || <Badge style={{ color: "black", background: "white" }} badgeContent={allCount} classes={{ badge: classes.badge }}><React.Fragment></React.Fragment></Badge>}
                        </ListItemIcon>}
                 
            </ListItem>}
          <List dense={true} className={clsx({ [classes.subList]: props.showAllOption})}>
              {props.options.filter(option => !filter || option.label.toLowerCase().indexOf(filter.toLowerCase())=== 0).map(option =>{
                  return <ListItem key={option.label} classes={{root: classes.zeroTopBottomPadding}}>
                       <ListItemIcon classes={{root:classes.listIcon}}>
                        <Checkbox
                            edge="start"
                            tabIndex={-1}
                            onChange={(event) => { handleCheckboxClick(option, event.target.checked)}}
                            disableRipple
                            checked={option.checked}
                            classes={{checked: classes.checked}}
                            className={classes.checkboxstyle}
                            size="small"
                        />
                    </ListItemIcon>
              <ListItemText primary={<Tooltip title={option.label}>{props.renderLabel && props.renderLabel(option) || option.label}</Tooltip>} />
                    {props.showCount && <ListItemIcon className={classes.rightIcon}>
                            <Badge style={{ color: "black", background: "white" }} badgeContent={option.count} classes={{ badge: classes.badge }}><React.Fragment></React.Fragment></Badge>
                        </ListItemIcon>}
                  </ListItem>
              })}
              </List>      
    </React.Fragment>
}
export default CheckBoxFilter;