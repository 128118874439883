import { RootStore } from "./root.store";
import { observable, action, reaction, runInAction } from "mobx";
import { toast } from "react-toastify";
import { settingObj } from "../models/setting.model";
import agent from "../api/settingAgent";

export default class CommonStore {
    @observable valueSelected = true;
    @observable contentValid = true;
    @observable token: string | null = window.localStorage.getItem('jwt');
    @observable appLoaded = false;
    
    constructor (private rootStore: RootStore) {
    
        reaction( //a reaction is another mobx feature that allows us to react when an item changes 
                  // in this case, the token.
            () => this.token,
            token => {
                if (token) {
                    window.localStorage.setItem('jwt', token);
                } else {
                    window.localStorage.removeItem('jwt');
                }
            }
        )
    }

    @action setToken = (token: string | null) => {
        this.token = token;
    }

    @action setAppLoaded = () => {
        this.appLoaded = true;
    }

    @action setValueSelected = () => {
        this.valueSelected = true;
    }

    
    
}