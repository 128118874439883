import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle';
import CrossIcon from '@material-ui/icons/CancelRounded';
import { TestFixStyles } from '../report/issuesAndAutofix.component';
import { useStatusColor } from '../../app/common/util/useStatusColor.hook';

const useStyle = makeStyles({
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr minmax(60px , 12fr) auto minmax(26px , 3fr)',
        width: '100%',
        gridGap: 5
    }
})
const TestOverViewTile = (props: {
    name: string;
    passed: number;
    failed: number;
    icon: any;
}) => {

    const classes = useStyle();
    const { icon, name, passed, failed } = props;
    const okColor = useStatusColor("passed", passed);
    const failedColor = useStatusColor("failed", failed)
    const total = passed + failed
    return <div className={classes.grid}>
            <div style={{paddingTop: 5}}>{icon}</div>
            <div>
                <Grid container direction="column">
                    <Grid item> <Typography variant="body1" style={{ fontWeight: 500, textAlign: 'left' }}>{name}</Typography></Grid>
                    <Grid item data-test="total-test">
                        <Typography variant="body2" color="textSecondary" style={{ textAlign: 'left' }}>{name === 'Cyber' ? 'Coming Soon' : total + ' Tested'}</Typography>
                    </Grid>
                </Grid>
            </div>

            <div>
                <Grid container direction="column">
                    <Grid item> <CheckIcon style={{ ...TestFixStyles.deviceCellIcon, color: okColor }} /></Grid>
                    <Grid item >
                        <CrossIcon style={{ ...TestFixStyles.deviceCellIcon, color: failedColor }} />
                    </Grid>
                </Grid>
            </div>

            <div style={{textAlign: 'right'}}>
                <Grid container direction="column">
                    <Grid item> {passed}</Grid>
                    <Grid item >
                        {failed}
                    </Grid>
                </Grid>
            </div>
        </div>
;
};


export default React.memo(TestOverViewTile);