import React, { useEffect, useState } from 'react';
import { Paper, List, ListItem, Typography, Divider, ListItemIcon, ListItemText, makeStyles, Button, Tooltip } from '@material-ui/core';
import moment from 'moment';
import JobTrendComponent from './jobTrend.component';
import { WorkflowStep } from '../../app/models/workflowStep.model';
import ReportInfoTreeComponent from './reportInfoTree.component';
import ListSkeleton from '../../app/common/util/listSkeleton.component';
import { JobDeviceResultShort } from '../../app/models/jobDevice/jobDeviceResult.reponse.model';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckIcon from '@material-ui/icons/Check';
import CrossIcon from '@material-ui/icons/CancelRounded';

import FlashIcon from '@material-ui/icons/FlashOn';
import ThumbUp from '@material-ui/icons/ThumbUp';
import BugIcon from '@material-ui/icons/BugReport';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { ScheduleStaus } from '../../app/models/jobProperties.model';

const useStyles = makeStyles(theme => ({
  
    report_info_container: {
      height: `calc(100vh - 60px)`,
      background: "white",
      position: "sticky",
      top: 60,
      padding: 15,
      overflowY: "scroll"
    },
    reportInfoContainer: {
      height: "100%",
      width: "100%",
      background: "white",
      position: "sticky",
      top: 0,
      padding: 15
    },
    categorySelected: {
      borderRadius: 30,
      background: "#cde3f0"
    },
   
    treepanelIcon: {
      marginRight: 10,
      color: "#8092a9"
    },
    treeInfo: {
      display: "flex",
      flexDirection: "column",
      color: "#4d5f75",
      '& > div': {
        padding: 5,
        display: "flex"
      }
    },
    checkIcon: {
      color: "#8bc53f",
      marginRight: 5
    },
    crossIcon: {
      color: "#077187",
      marginRight: 5
    }
  }));
const dateFormat = "M.D.YYYY, h:mm A";
const detailedDateFormat = "DD.MM.YY HH:MM:SS";
export type TrendSectionProps = {
  timelineRecords:number,isRunning:boolean,jobId: number, nextRun: string, knownIssues: Array<WorkflowStep>, devices: Array<JobDeviceResultShort>, isLoading: boolean, jobResultId: number, scheduleStaus: ScheduleStaus, scheduleInfo: string,scheduleNextRunInfo:string
}

const TrendSection: React.FC<TrendSectionProps> = ({nextRun,timelineRecords,isRunning, jobId, devices, jobResultId, knownIssues, isLoading, scheduleStaus,scheduleInfo,scheduleNextRunInfo }) => {
    const classes = useStyles();
    const [t] = useTranslation('report');
    const [translastion] = useTranslation("jobResult");
    const [AllDisplay, setAllDisplay] = useState(false);

    useEffect(() => {
      loadTrendSection();
    }, [timelineRecords])

    const ExpandToAll =()=>{
      setAllDisplay(true);
  }

  const ShrinkToMin =()=>{
    setAllDisplay(false);
  }

  const loadAllInfo =()=>{
    return <React.Fragment>
    {devices.filter((d) => d.autoTroubleshooting === "Yes").map((device, index) => 
    <div key={index}> 
    <CheckCircleIcon className={classes.checkIcon} /> {device.machineName}
    </div>)}
    </React.Fragment>
  }

  const loadPartialInfo =()=>{
    return <React.Fragment>
    {devices.slice(0,6).filter((d) => d.autoTroubleshooting === "Yes").map((device, index) => 
    <div key={index}> 
    <CheckCircleIcon className={classes.checkIcon} /> {device.machineName}
    </div>)}
    </React.Fragment>
  }

  const loadScheduleInfo =()=>{
    switch(scheduleStaus)
    {
      case ScheduleStaus.Suceess:
        return <Tooltip title={scheduleInfo +"."+"\n"+ "Next run will be at "+ scheduleNextRunInfo} arrow>
          <Typography>{t("nextRunTime")+scheduleNextRunInfo}</Typography>
        </Tooltip>;
      case ScheduleStaus.Failed:
        translastion('jobTable.schedInfoFailed');
      case ScheduleStaus.NA:
        return translastion('jobTable.schedInfoNA');
    }
  }

  const loadAutoFixed =()=>{
    return(devices.filter(d => d.autoTroubleshooting === "Yes").length > 0 &&<ReportInfoTreeComponent expanded={true} title={
      <span style={{ display: "flex" }}>
        <FlashIcon className={classes.treepanelIcon} />
        <Typography variant="h6">{t("devicesAutoFixed")}</Typography>
      </span>}>
      <div className={classes.treeInfo}>
      {AllDisplay ? loadAllInfo():loadPartialInfo()}
      {devices.length > 6  ?(!AllDisplay ? <Button size="small" color="primary"  onClick={ExpandToAll}>{t("showmore")}</Button>
      :<Button size="small" color="primary" style={{paddingBottom:'10%'}} onClick={ShrinkToMin}>{t("showless")}</Button>):null}
      </div>
    </ReportInfoTreeComponent>)
}

  const loadTrendSection =()=>{
    return(<React.Fragment>
      <JobTrendComponent jobId={jobId} jobResultId={jobResultId} timelineRecords={timelineRecords}/>
      <Divider />
    </React.Fragment>);
  }

    return  <Paper className={classes.report_info_container} style={{ top: "62px" }} >
    {!isLoading  ? <List>
      <ListItem>
        <Typography variant="h6">{t("reportInformation")}</Typography>
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemIcon style={{ padding: 0, justifyContent: "flex-end", minWidth: 40, paddingRight: "8px", position: "relative", top: "-8px" }}>
          <ScheduleIcon style={{ color: scheduleStaus === ScheduleStaus.Suceess ?'#8bc53f':(scheduleStaus === ScheduleStaus.Failed ?'#077187' :'#C0C0C0') }}/>
        </ListItemIcon>
        <ListItemText
          primary={t("scheduling")}
          secondary={loadScheduleInfo()}>
        </ListItemText>
      </ListItem>
      <Divider />
      {loadTrendSection()}

      {knownIssues.filter(workflowStep => workflowStep.workflowIssue).length > 0 && isRunning === false &&
        <React.Fragment> <ReportInfoTreeComponent expanded={true} title={
          <span style={{ display: "flex" }}>
            <BugIcon className={classes.treepanelIcon} />
            <Typography variant="h6">{t("mainJobIssues")}</Typography>
          </span>}>
          <div className={classes.treeInfo}>
            {knownIssues.filter(workflowStep => workflowStep.workflowIssue)
              .map(issue => <div> <CrossIcon className={classes.crossIcon} />{issue.workflowIssue}</div>
              )}

          </div>
        </ReportInfoTreeComponent>
          <Divider />
        </React.Fragment>}
      {false &&
        <React.Fragment>
          <ReportInfoTreeComponent expanded={true} title={
            <span style={{ display: "flex" }}>
              <ThumbUp className={classes.treepanelIcon} />
              <Typography variant="h6">{t("recommendation")}</Typography>
            </span>}>
            <div className={classes.treeInfo}>
              <div> <CheckIcon style={{ marginRight: "5px" }} />Lorem ipsum dolor sit amet consectetur adipisicing elit.</div>
              <div> <CheckIcon style={{ marginRight: "5px" }} />Enim, obcaecati. Fugiat, aliquam repellat harum amet odit </div>
              <div> <CheckIcon style={{ marginRight: "5px" }} />Excepturi magnam magni ea voluptates hic ab </div>
              <div> <CheckIcon style={{ marginRight: "5px" }} />Lorem ipsum dolor sit, amet consectetur </div>
              <div> <CheckIcon style={{ marginRight: "5px" }} />Adipisicing elit. Nulla iure quos</div>
            </div>
          </ReportInfoTreeComponent>
          <Divider />
        </React.Fragment>
      }
     {isRunning!=null && isRunning === false ? loadAutoFixed():null}
    </List> : <ListSkeleton />}
  </Paper>
}

export default TrendSection;