import {Grid,makeStyles, Typography, Divider, InputLabel, FormControl, IconButton, InputAdornment, Tooltip, TextField, CircularProgress} from '@material-ui/core';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { RootStoreContext } from '../../../../app/stores/root.store';
import { EDROutlinedInput } from "../../../../app/common/EDROutlinedInput";
import { EDRAutocomplete } from "../../../../app/common/EDRAutocomplete";
import { DataMover } from '../../../../app/models/BaseSettings.enum';
import HelpIcon from '@material-ui/icons/Help';
import { SaveButton } from '../../../../app/common/saveButton';
import { AssetType,CONSTS, CredentialUserType } from '../../../../app/models/createjob/createJob.model';
import AssetAgent from '../../../../app/api/assetsAgent';
import { toast } from 'react-toastify';
import JobSettingsStore from '../../../../app/stores/jobSettings.store';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EDRTextField } from '../../../../app/common/EDRTextField';
import ConfirmationDialog from '../../../../app/common/confirmationDialog.component';
import { runInAction } from 'mobx';
import { formatISO } from 'date-fns';
import CreateJobStore from '../../../../app/stores/createJob.store';
import { InfrastructureType } from '../../../../app/models/jobProperties.model';

const CloneSettingsComponent: React.FC = () => {
    let labelRef;
    let labelRefAuth;
    const rootStore = useContext(RootStoreContext); 
    const {jobSettingsStore,displayStore,createJobStore}=rootStore;
    const isPaaS= false;
    const [showEyePassword, setShowEyePassword]= useState(true);
    const [passwordVisibility,setPasswordVisibility] = useState(true);
    const [UserPassAutocomplete,setUserPassAutocomplete] = useState(false);
    const [AuthshowEyePassword, setAuthShowEyePassword]= useState(true);
    const [AuthpasswordVisibility,setAuthPasswordVisibility] = useState(true);
    const [AuthUserPassAutocomplete,setAuthUserPassAutocomplete] = useState(false);
    const getPreviousESX = jobSettingsStore.linuxCredentails;
    const getPreviousDC = jobSettingsStore.accessCredentials;
    const getTargetVlan = [{name:jobSettingsStore.vCenterNetworks[0]}]
    const [openConfirmationDCDialog, setopenConfirmationDCDialog] = useState(false);
    const [hostnameIP, setHostnameIP] = useState("");
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const addMode =displayStore.screenSettings.AddModeDC;

    const useStyles = makeStyles({
        helpIcon:
        {
          color: '#1976d2',
          fontSize: "large"
        },
        card: {
            padding: 16,
            width: '100%',
            height: '100%'
        },
        MainHeading:
        {
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.19',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#1f396d'
        },
        SecondHeading:{
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1
        },
        divider:{
            backgroundColor: '#eaeef4',
            width: '315%',
            marginTop: '10px',
            marginLeft: '10px'
        },
        formControl: {
            width: '100%',
            '& label': {
                background: "white",
                width: "auto",
                padding:"0 4px 0 4px",
                fontSize: '17px'
              }
        },
        button: {
            borderRadius: 15,
            color: 'white',
            backgroundColor:'#2892d7',
            width: '23%',
            height: '32px',
            marginTop: '24px',
            marginLeft: '410px'
        }
        });
        
    const classes = useStyles();
    const [t] = useTranslation("createJobNetwork");

    const validationSchema = yup.object({
        domainName: yup.string().required('Required'),
        dcUsername: yup.string().required('Required'),
        dcPassword: yup.string().required('Required'),
        serverSourceNameIP: yup.string().required().when('infrastructure', {
            is:(v) => v !== InfrastructureType.Azure,
            then: yup.string().required('Required')
          }),
        serverTargetNameIP: yup.string().required().when('infrastructure', {
            is:(v) => v !== InfrastructureType.Azure,
            then: yup.string().required('Required')
          }),
        targetServerUsername: yup.string().required().when('infrastructure', {
            is:(v) => v !== InfrastructureType.Azure,
            then: yup.string().required('Required')
          }),
        targetServerPassword: yup.string().required().when('infrastructure', {
            is:(v) => v !== InfrastructureType.Azure,
            then: yup.string().required('Required')
          }),
        datastore:yup.string().required().when('infrastructure', {
            is:(v) => v !== InfrastructureType.Azure,
            then: yup.string().required('Required')
          }),
        //vlan: yup.string().required('Required')
    })
    
    const form = useFormik({
        initialValues: {
            domainName: rootStore.createJobStore.networkSetting.domainController.domainName,
            dcUsername:rootStore.createJobStore.networkSetting.domainController.domainCredentials && rootStore.createJobStore.networkSetting.domainController.domainCredentials.userName ? rootStore.createJobStore.networkSetting.domainController.domainCredentials.userName : '',
            dcPassword:rootStore.createJobStore.networkSetting.domainController.domainCredentials && rootStore.createJobStore.networkSetting.domainController.domainCredentials.password ? rootStore.createJobStore.networkSetting.domainController.domainCredentials.password : '',
            serverSourceNameIP:rootStore.createJobStore.networkSetting.domainController.dcHostServerSource,
            serverTargetNameIP:rootStore.createJobStore.networkSetting.domainController.dcHostServerTarget,
            targetServerUsername:rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials !=undefined? rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials.userName:'',
            targetServerPassword:rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials !=undefined? rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials.password :'',
            datastore:rootStore.createJobStore.networkSetting.domainController.dcDataStoreTarget,
            vlan:rootStore.createJobStore.networkSetting.domainController.domainControllerTargetVLAN
        },
        onSubmit: () => { },
        validationSchema
    })

   useEffect(() => {
    checkAllFilled();
    },[form.values, form.errors])

    useEffect(() => {
        const  initialValues= {
            domainName: rootStore.createJobStore.networkSetting.domainController.domainName,
            dcUsername:rootStore.createJobStore.networkSetting.domainController.domainCredentials && rootStore.createJobStore.networkSetting.domainController.domainCredentials.userName ? rootStore.createJobStore.networkSetting.domainController.domainCredentials.userName : '',
            dcPassword:rootStore.createJobStore.networkSetting.domainController.domainCredentials && rootStore.createJobStore.networkSetting.domainController.domainCredentials.password ? rootStore.createJobStore.networkSetting.domainController.domainCredentials.password : '',
            serverSourceNameIP:rootStore.createJobStore.networkSetting.domainController.dcHostServerSource,
            serverTargetNameIP:rootStore.createJobStore.networkSetting.domainController.dcHostServerTarget,
            targetServerUsername:rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials !=undefined? rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials.userName:'',
            targetServerPassword:rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials !=undefined? rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials.password :'',
            datastore:rootStore.createJobStore.networkSetting.domainController.dcDataStoreTarget,
            vlan:rootStore.createJobStore.networkSetting.domainController.domainControllerTargetVLAN
        }
        setHostnameIP(jobSettingsStore.selectedDC.domainControllerName);
        form.setValues(initialValues);
        
    }, [rootStore.createJobStore.networkSetting.domainController])

    useEffect(() => {
        let initialValues;
        if(rootStore.createJobStore.networkSetting.domainController.domainControllerName.includes('I have a Domain Controller inside my replication') || rootStore.createJobStore.networkSetting.domainController.domainControllerName =='')
        {
            let userNoDomain= rootStore.createJobStore.baseSettings.workflowTest.PrimaryUser == "" ? '':(rootStore.createJobStore.baseSettings.workflowTest.PrimaryUser!=undefined ?rootStore.createJobStore.baseSettings.workflowTest.PrimaryUser.split('\\')[1]:'');
            initialValues ={
                domainName: '',
                dcUsername:userNoDomain,
                dcPassword:'',
                serverSourceNameIP:'',
                serverTargetNameIP:'',
                targetServerUsername:'',
                targetServerPassword:'',
                datastore:'',
                vlan:rootStore.createJobStore.networkSetting.domainController.domainControllerTargetVLAN == '' ? jobSettingsStore.vCenterNetworks[1]:rootStore.createJobStore.networkSetting.domainController.domainControllerTargetVLAN
            };
        }
        else
        {
            initialValues ={
                domainName: rootStore.createJobStore.networkSetting.domainController.domainName,
                dcUsername:rootStore.createJobStore.networkSetting.domainController.domainCredentials && rootStore.createJobStore.networkSetting.domainController.domainCredentials.userName ? rootStore.createJobStore.networkSetting.domainController.domainCredentials.userName : '', 
                dcPassword:rootStore.createJobStore.networkSetting.domainController.domainCredentials && rootStore.createJobStore.networkSetting.domainController.domainCredentials.password ? rootStore.createJobStore.networkSetting.domainController.domainCredentials.password : '',
                serverSourceNameIP:rootStore.createJobStore.networkSetting.domainController.dcHostServerSource,
                serverTargetNameIP:rootStore.createJobStore.networkSetting.domainController.dcHostServerTarget,
                targetServerUsername:rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials !=undefined? rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials.userName:'',
                targetServerPassword:rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials !=undefined? rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials.password :'',
                datastore:rootStore.createJobStore.networkSetting.domainController.dcDataStoreTarget,
                vlan:rootStore.createJobStore.networkSetting.domainController.domainControllerTargetVLAN =='' ? jobSettingsStore.vCenterNetworks[1]:rootStore.createJobStore.networkSetting.domainController.domainControllerTargetVLAN
            };
        }
        form.resetForm({values: initialValues});
        
      }, [displayStore.screenSettings.renderValue]); 

    const checkAllFilled =()=> {
        runInAction(()=>{
            if(createJobStore.baseSettings.infrastructureType! = InfrastructureType.Azure)
            {
                if(form.values.domainName !=='' && form.values.dcUsername !=='' &&  form.values.dcPassword !=='' && form.values.serverSourceNameIP !=='' && form.values.serverTargetNameIP !=='' && form.values.targetServerUsername !=='' && 
                form.values.targetServerPassword !=='' &&  form.values.datastore !=='' && form.values.vlan!='' && form.isValid)
                {
                    displayStore.updateNextStepValid({networkSettingValid:true,dcAdvancedValid:true});
                }
                else
                {
                    displayStore.updateNextStepValid({networkSettingValid:false,dcAdvancedValid:false});
                }
            }
            else
            {
                if(form.values.domainName !=='' && form.values.dcUsername !=='' &&  form.values.dcPassword !==''  && form.values.vlan!='' && form.isValid)
                {
                    displayStore.updateNextStepValid({networkSettingValid:true,dcAdvancedValid:true});
                }
                else
                {
                    displayStore.updateNextStepValid({networkSettingValid:false,dcAdvancedValid:false});
                }
            }
        })
    }

    const handleServerSourceChange=(event) => {
        form.setFieldValue("serverSourceNameIP",event.target.value,true);
        rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController, dcHostServerSource: event.target.value}});
    };

    const handleServerTargetChange=(event) => {
        form.setFieldValue("serverTargetNameIP",event.target.value,true);
        rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController, dcHostServerTarget: event.target.value}});
    };

    const handleDatastoreChange=(event) => {
        form.setFieldValue("datastore",event.target.value,true);
        rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController, dcDataStoreTarget: event.target.value}});
    };

    const handleDomainNameChange =(event) =>{
        form.setFieldValue("domainName",event.target.value,true);
        rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController, domainName: event.target.value=="" ? '':event.target.value}});
    }

    const handleTargetVlanChange =(event:React.ChangeEvent,value:any) =>{
        if(event!=null)
        {
            if (value!=null && typeof value === 'object') 
            {
                form.setFieldValue("vlan",value=="" ? '':value.name,true);
                rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController, domainControllerTargetVLAN: value=="" ? '':value.name }});
            }
            if (typeof value === 'string')
            {
              form.setFieldValue("vlan",value,true);
              rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController, domainControllerTargetVLAN: value=="" ? '':value }});
            }
        }
    }

    const handleClickShowPasswordAuth  = () => {
        setAuthPasswordVisibility(AuthpasswordVisibility? false: true);
    };

    const handleClickShowPasswordESX  = () => {
        setPasswordVisibility(passwordVisibility? false: true);
    };

    const handleAuthUsernameChange =(event:React.ChangeEvent,value:any)=>{
        if(event!=null){
            let onInput= event.type=="click"? false:true;
            setAuthUserPassAutocomplete(onInput);
            if (value!=null && typeof value === 'object') 
            {
                let id = rootStore.createJobStore.networkSetting.domainController && rootStore.createJobStore.networkSetting.domainController.domainCredentials && rootStore.createJobStore.networkSetting.domainController.domainCredentials.id != 0 ? rootStore.createJobStore.networkSetting.domainController.domainCredentials.id : 0
                let credentials= {id: id, userName: value=="" ? '':value.userName ,password:'',userType:CredentialUserType.GeneralUser};
                form.setFieldValue("dcUsername",credentials.userName,true);
                form.setFieldValue("dcPassword",'',true);
                rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController, domainCredentials : credentials}});
            }
            if (typeof value === 'string')
            {
              const userObj = getPreviousDC.find(e => e.userName.toLocaleLowerCase() == value.toLocaleLowerCase());
              let pass = userObj== undefined ? '' :userObj.password;
              let id = rootStore.createJobStore.networkSetting.domainController && rootStore.createJobStore.networkSetting.domainController.domainCredentials && rootStore.createJobStore.networkSetting.domainController.domainCredentials.id != 0 ? rootStore.createJobStore.networkSetting.domainController.domainCredentials.id : 0
              let credentials= {id: id,userName: value=="" ? '':value ,password:'',userType:CredentialUserType.GeneralUser};
              form.setFieldValue("dcUsername",credentials.userName,true);
              form.setFieldValue("dcPassword",credentials.password,true);
              rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController, domainCredentials : credentials}});
            }
            setShowEyePassword(onInput);
            if(!onInput)
            {
                setAuthPasswordVisibility(true);
            }
          }
    }

    const handleAuthPassChange=(event)=>{
        setAuthUserPassAutocomplete(true);
        setAuthShowEyePassword(true);
        let id = rootStore.createJobStore.networkSetting.domainController && rootStore.createJobStore.networkSetting.domainController.domainCredentials && rootStore.createJobStore.networkSetting.domainController.domainCredentials.id != 0 ? rootStore.createJobStore.networkSetting.domainController.domainCredentials.id : 0
        let credentials= {id: id,userName: rootStore.createJobStore.networkSetting.domainController.domainCredentials.userName,password:event.target.value,userType:CredentialUserType.GeneralUser};
        form.setFieldValue("dcPassword",credentials.password,true);
        rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController, domainCredentials:credentials }});
    }

    const handleUsernameChange = (event:React.ChangeEvent,value:any) => {
        if(event!=null){
        let onInput= event.type=="click"? false:true;
        setUserPassAutocomplete(onInput);
        if (value!=null && typeof value === 'object') 
        {
            let credsId = rootStore.createJobStore.networkSetting.domainController && rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials != null ? rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials.id : 0
            let credentials= {id: credsId,userName: rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials.userName ?? '' ,password:'',userType:CredentialUserType.ESXUser};
            form.setFieldValue("targetServerUsername",credentials.userName,true);
            form.setFieldValue("targetServerPassword",'',true);
            rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController, domainControllerESXCredentials : credentials}});
        }
        if (typeof value === 'string')
        {
          const userObj = getPreviousESX.find(e => e.userName.toLocaleLowerCase() == value.toLocaleLowerCase());
          let pass = userObj== undefined ? '' :userObj.password;
          let credsId = rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials != null ? rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials.id : 0
          let credentials= {id: credsId,userName: value=="" ? '':value ,password:'',userType:CredentialUserType.ESXUser};
          form.setFieldValue("targetServerUsername",credentials.userName,true);
          form.setFieldValue("targetServerPassword",'',true);
          rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController, domainControllerESXCredentials : credentials}});
        }
        setShowEyePassword(onInput);
        if(!onInput)
        {
            setPasswordVisibility(true);
        }
      }
    };
    
    const handlePassChange = (event) => {
        setUserPassAutocomplete(true);
        setShowEyePassword(true);
        let credsId = rootStore.createJobStore.networkSetting.domainController && rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials != null ? rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials.id : 0
        let credentials= {id: credsId,userName: rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials.userName,password:event.target.value,userType:CredentialUserType.ESXUser};
        form.setFieldValue("targetServerPassword",credentials.password,true);
        rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController, domainControllerESXCredentials:credentials }});
    };

    const loadDomainName= ()=>{
        if(isPaaS)
        {
            return(
                <React.Fragment>
                    <FormControl className={classes.formControl} style={{marginTop: '10px'}} variant='outlined'>
                           <EDRTextField id="domainName"
                            label={t('domainName')}
                            placeholder=""
                            value={rootStore.createJobStore.networkSetting.domainController.domainName}
                            onChange={handleDomainNameChange}
                            disabled={displayStore.screenSettings.DCApplyProcessing}
                            style={{margin: 8}}
                            margin="normal"
                            aria-autocomplete="none"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            variant="outlined">
                           </EDRTextField>
                        </FormControl>
                </React.Fragment>
            );
        }
    }

    useEffect(()=>{
        if(addMode)
        {
            setHostnameIP("");
        }
        else
        {
            setHostnameIP(jobSettingsStore.selectedDC.domainControllerName);
        }
    },[addMode,displayStore.screenSettings.renderValue])

    useEffect(()=>{
       disableAddApply();
       loadCircular();
    },[displayStore.screenSettings.DCApplyProcessing])

    
    const loadCircular =()=>{
        return false;
    }

    const handlehostnameIPChange =(event)=> 
    {
        setHostnameIP(event.target.value);
        let dc= jobSettingsStore.selectedDC;
        dc.domainControllerName = event.target.value;
        jobSettingsStore.updateSelectedDC(dc);
    }
    
    const disableAddApply =()=>{
        if(!displayStore.nextStepValid.dcAdvancedValid)
            return true;
        else
        {
            if(hostnameIP =="")
                return true;
            else
            {
                if(displayStore.screenSettings.DCApplyProcessing)
                    return true;
                else
                    return false;
            }
        }
    }

    const handleMouseClick =(type:string)=>{
        switch(type)
        {
          case "targetServerPassword":
              {
                  if(form.values.targetServerPassword === CONSTS.MaskedPassword)
                  {
                      form.setFieldValue("targetServerPassword",'',true);
                  }
                  break;
              }
          case "dcPassword":
              {
                  if(form.values.dcPassword === CONSTS.MaskedPassword)
                  {
                      form.setFieldValue("dcPassword",'',true);
                  }
                  break;
              }
        }
  }

  const handleMouseOut =(type:string)=>{
      switch(type)
      {
          case "targetServerPassword":
              {
                  if(form.dirty && form.values.targetServerPassword == '')
                  {
                    if(form.values.targetServerUsername!='')
                    {
                        return;
                    }
                    else form.setFieldValue("targetServerPassword",CONSTS.MaskedPassword,true);
                  }
                  break;
              }
          case "dcPassword":
              {
                  if(form.dirty && form.values.dcPassword == '')
                  {
                    if(form.values.dcUsername!='')
                    {
                        return;
                    }
                    else form.setFieldValue("dcPassword",CONSTS.MaskedPassword,true);
                  }
                  break;
              }
      }
  }


    const handleAdd = async () => {
        setButtonsDisabled(true);
        rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController, domainControllerName: hostnameIP}});
        jobSettingsStore.updateSelectedDC(rootStore.createJobStore.networkSetting.domainController);
        if(jobSettingsStore.DCs.find(item=>item.domainControllerName == hostnameIP) == undefined)
            await jobSettingsStore.createDomainController();
        else
            toast.error("DC already exist in your list. To use it, simply select it from the dropdown");
        setButtonsDisabled(false);
    }

    const handleUpdate = async () => {
        setButtonsDisabled(true);
        rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController, domainControllerName: hostnameIP}});   
        await jobSettingsStore.editAsset(AssetType.DC);
        setButtonsDisabled(false);
    }

    const handleDelete = async () => {
        setButtonsDisabled(true);
        await jobSettingsStore.deleteAsset(AssetType.DC);
        setButtonsDisabled(false);
    }

    const handleConfirmationPopup =(confirm:boolean)=>{
        if(confirm)
        {
            handleDelete();
        }
        setopenConfirmationDCDialog(false);
    }



    return(
        <Grid container direction="column">
            
            <div style={{ display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
            <Typography variant='h6' className={classes.MainHeading}>{t('cloneSetting')}</Typography>
            <div style={{ display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
            {!addMode ? <SaveButton onClick={()=>setopenConfirmationDCDialog(true)} disabled={displayStore.screenSettings.DCApplyProcessing || !displayStore.nextStepValid.dcAdvancedValid || buttonsDisabled} variant="contained" color="primary" style={{height:'25px',textTransform:'none',marginRight:'10px'}}>
            {loadCircular() ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
            {t('delete')}
            </SaveButton> :null } 
            <SaveButton onClick={addMode ? handleAdd:handleUpdate} disabled={disableAddApply() || buttonsDisabled} variant="contained" color="primary" style={{height:'25px',textTransform:'none'}}>
            {loadCircular() ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
            {addMode ? t('add') : t('apply')}
            </SaveButton>
            </div>   
            </div>

            <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px' ,marginLeft:'10px'}}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{AssetType[AssetType.DC] + t('configuration')}</Typography>
                    <Tooltip title={t("InstanceConfigurationInfoDC")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip> 
                    <Divider className={classes.divider}/>
                </div>
            </Grid>

            <Grid container style={{marginTop:'10px'}} spacing={1}>
            <Grid item xs={12} spacing={1} > 
            <FormControl className={classes.formControl} variant='outlined'>   
            <TextField id="servername"
             label={t('hostnameIP')}
             placeholder=""
             aria-autocomplete="none"
             value={hostnameIP}
             disabled={!addMode}
             style={{ margin: 8 }}
             onChange={handlehostnameIPChange}
             InputLabelProps={{
             shrink: true,
             }}
             variant="outlined">
         </TextField>
             </FormControl>
         </Grid>
         </Grid>
         
            <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row',marginLeft:'10px',marginTop:'16px'}}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('DCAuthentication')}</Typography> 
                    <Tooltip title={t("DCAuthenticationInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider} style={{width:'121%'}}/>
                </div>
            </Grid>

            <Grid container style={{marginTop:'16px'}} spacing={1}>
                <Grid item xs={6} spacing={1} style={{height:'40px'}}> 
                   <FormControl className={classes.formControl} variant='outlined'>
                        <EDRTextField id="domainName"
                        label= {t('domainName')}
                        style={{ margin: 8 }}
                        placeholder=""
                        value={form.values.domainName} 
                        disabled={displayStore.screenSettings.DCApplyProcessing}
                        onChange={handleDomainNameChange}
                        onBlur={form.handleBlur}
                        error={form.touched.domainName && Boolean(form.errors.domainName)}
                        helperText={form.touched.domainName && form.errors.domainName}
                        margin="normal"
                        aria-autocomplete="none"
                        InputLabelProps={{
                        shrink: true,
                        }}
                        variant="outlined">
                       </EDRTextField>
                    </FormControl>
                    </Grid>
                <Grid item xs={6}>
                </Grid>
            </Grid>

            <Grid container style={{marginTop:'8%'}} spacing={1}>
                <Grid item xs={6} spacing={1} > 
                   <FormControl className={classes.formControl} style={{width:'94%'}} variant='outlined'>   
                   <EDRAutocomplete id="dcUsername"
                        freeSolo
                        value={form.values.dcUsername}
                        options={getPreviousDC}
                        renderOption={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
                        getOptionLabel={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
                        onChange={handleAuthUsernameChange}
                        onInputChange={handleAuthUsernameChange}
                        renderInput={(params) => (<EDRTextField {...params}
                        label= {t('AuthUsername')}
                        disabled={displayStore.screenSettings.DCApplyProcessing}
                        InputLabelProps={{shrink: true}} 
                        style={{ margin: 8 ,backgroundColor:'#ffffff'}}
                        margin="normal"
                        aria-autocomplete="none"
                        variant="outlined"
                        placeholder="Type Here"
                        onBlur={form.handleBlur}
                        error={form.touched.dcUsername && Boolean(form.errors.dcUsername)}
                        helperText={form.touched.dcUsername && form.errors.dcUsername}
                        />)}
                        />
                    </FormControl>
                    </Grid>
                <Grid item xs={6} spacing={1} >
                        <FormControl className={classes.formControl} variant='outlined'>
                                <InputLabel ref={ref => {labelRefAuth = ReactDOM.findDOMNode(ref)}} style={{transform: 'translate(14px, 2px) scale(0.75)'}}>{ t('password')}</InputLabel>
                                <EDROutlinedInput id="dcPassword" 
                                value={form.values.dcPassword}
                                autoComplete="off"
                                type={AuthpasswordVisibility ? 'password':'text'}
                                placeholder="Type Here"
                                disabled={displayStore.screenSettings.DCApplyProcessing}
                                label={<InputLabel style={{marginTop:'8px'}}>{ t('password')}</InputLabel>}
                                style={{ margin: 8,backgroundColor:'#ffffff' }}
                                onChange={handleAuthPassChange}	
	                            onClick={event=> {handleMouseClick("dcPassword")}}
                                onBlur={event=> {handleMouseOut("dcPassword")}}
                                endAdornment={ AuthUserPassAutocomplete ?
                                <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                aria-autocomplete="none"
                                onClick={handleClickShowPasswordAuth}>
                                {AuthpasswordVisibility ?  <VisibilityOff />: <Visibility />}
                                </IconButton></InputAdornment>:null}/>  
                                </FormControl>
                    </Grid>
            </Grid>

            {jobSettingsStore.selectedInfra.infrastructureTypeId !=InfrastructureType.Azure  && jobSettingsStore.selectedInfra.infrastructureTypeId != InfrastructureType.VmwareAzure ?
            <Grid>
            <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row', marginTop:'16px',marginLeft:'2%'}}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('ESXSettings')}</Typography> 
                    <Tooltip title={t("ESXSettingsInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider} style={{width:'162%'}}/>
                </div>
            </Grid>
            <Grid container style={{marginTop:'16px'}} spacing={1}>
                <Grid item xs={6} spacing={1} style={{height:'40px'}}> 
                   <FormControl className={classes.formControl} variant='outlined'>
                        <EDRTextField id="serverSourceNameIP"
                        name="serverSourceNameIP"
                        label= {t('serverSource')}
                        style={{ margin: 8 }}
                        placeholder=""
                        value={form.values.serverSourceNameIP} 
                        disabled={displayStore.screenSettings.DCApplyProcessing}
                        onBlur={form.handleBlur}
                        error={form.touched.serverSourceNameIP && Boolean(form.errors.serverSourceNameIP)}
                        helperText={form.touched.serverSourceNameIP && form.errors.serverSourceNameIP}
                        onChange={handleServerSourceChange}
                        margin="normal"
                        aria-autocomplete="none"
                        InputLabelProps={{
                        shrink: true,
                        }}
                        variant="outlined">
                       </EDRTextField>
                    </FormControl>
                    </Grid>
                <Grid item xs={6} spacing={1}>
                        <FormControl className={classes.formControl} variant='outlined'>
                            <EDRTextField id="serverTargetNameIP"
                        name="serverTargetNameIP"
                        label= {t('serverTarget')}
                        style={{ margin: 8 }}
                        placeholder=""
                        value={form.values.serverTargetNameIP} 
                        disabled={displayStore.screenSettings.DCApplyProcessing}
                        onBlur={form.handleBlur}
                        error={form.touched.serverTargetNameIP && Boolean(form.errors.serverTargetNameIP)}
                        helperText={form.touched.serverTargetNameIP && form.errors.serverTargetNameIP}
                        onChange={handleServerTargetChange}
                        margin="normal"
                        aria-autocomplete="none"
                        InputLabelProps={{
                        shrink: true,
                        }}
                        variant="outlined">
                       </EDRTextField>
                    </FormControl>    
                   </Grid>
            </Grid>
            <Grid container style={{marginTop:'10px'}} spacing={1}>
                <Grid item xs={6} spacing={1} > 
                   <FormControl className={classes.formControl} style={{width:'94%'}} variant='outlined'>   
                   <EDRAutocomplete id="targetServerUsername"
                       // name="targetServerUsername"
                        freeSolo
                        value={form.values.targetServerUsername}
                        options={getPreviousESX}
                        renderOption={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
                        getOptionLabel={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
                        onChange={handleUsernameChange}
                        onInputChange={handleUsernameChange}
                        renderInput={(params) => (<EDRTextField {...params}
                        label= {t('targetServerUsername')}
                        disabled={displayStore.screenSettings.DCApplyProcessing}
                        InputLabelProps={{shrink: true}} 
                        style={{ margin: 8 ,backgroundColor:'#ffffff'}}
                        margin="normal"
                        variant="outlined"
                        placeholder="Type Here"
                        aria-autocomplete="none"
                        onBlur={form.handleBlur}
                        error={form.touched.targetServerUsername && Boolean(form.errors.targetServerUsername)}
                        helperText={form.touched.targetServerUsername && form.errors.targetServerUsername}
                        />)}
                        />
                    </FormControl>
                    </Grid>
                <Grid item xs={6} spacing={1} >
                        <FormControl className={classes.formControl} variant='outlined'>
                                <InputLabel ref={ref => {labelRef = ReactDOM.findDOMNode(ref)}} style={{transform: 'translate(14px, 2px) scale(0.75)'}}>{ t('targetServerPassword')}</InputLabel>
                                <EDROutlinedInput id="targetServerPassword" 
                                name="targetServerPassword" 
                                value={form.values.targetServerPassword}
                                disabled={displayStore.screenSettings.DCApplyProcessing}
                                autoComplete="off"
                                type={passwordVisibility ? 'password':'text'}
                                placeholder="Type Here"
                                label={<InputLabel style={{marginTop:'8px'}}>{ t('targetServerPassword')}</InputLabel>}
                                style={{ margin: 8,backgroundColor:'#ffffff' }}
                                onChange={handlePassChange}
	                            onClick={event=> {handleMouseClick("targetServerPassword")}}
                                onBlur={event=> {handleMouseOut("targetServerPassword")}}
                                endAdornment={ UserPassAutocomplete ?
                                <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                aria-autocomplete="none"
                                onClick={handleClickShowPasswordESX}>
                                {passwordVisibility ?  <VisibilityOff />: <Visibility />}
                                </IconButton></InputAdornment>:null}/>  
                                </FormControl>
                    </Grid>
                                </Grid></Grid>:null}

            <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row',marginLeft:'10px',marginTop:'16px'}}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('additionalDCSettings')}</Typography> 
                    <Tooltip title={t("additionalDCSettingsInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider} style={{width:'100%'}}/>
                </div>
            </Grid>

            <Grid container style={{marginTop:'16px'}} spacing={1}>
            {jobSettingsStore.selectedInfra.infrastructureTypeId !=InfrastructureType.Azure  && jobSettingsStore.selectedInfra.infrastructureTypeId != InfrastructureType.VmwareAzure? 
                <Grid item xs={6} spacing={1}> 
                <FormControl className={classes.formControl} variant='outlined'>
                       <EDRTextField id="datastore"
                        label={t('datastoreTarget')}
                        style={{ margin: 8 }}
                        placeholder=""
                        value={form.values.datastore} 
                        disabled={displayStore.screenSettings.DCApplyProcessing}
                        onChange={handleDatastoreChange}
                        onBlur={form.handleBlur}
                        error={form.touched.datastore && Boolean(form.errors.datastore)}
                        helperText={form.touched.datastore && form.errors.datastore}
                        margin="normal"
                        aria-autocomplete="none"
                        InputLabelProps={{
                        shrink: true,
                        }}
                        variant="outlined">
                       </EDRTextField>
                    </FormControl>
                    </Grid>:null}
            <Grid item xs={6} spacing={1}> 
            <FormControl className={classes.formControl} style={{width:'94%'}} variant='outlined'>   
                   <EDRAutocomplete id="vlan"
                        freeSolo
                        value={form.values.vlan}
                        options={jobSettingsStore.vCenterNetworks}
                        renderOption={(option: string|{name: string}) => (typeof option === 'string' ? option : `${option.name}`)}
                        getOptionLabel={(option: string|{name: string}) => (typeof option === 'string' ? option : `${option.name}`)}
                        onChange={handleTargetVlanChange}
                        onInputChange={handleTargetVlanChange}
                        disabled={displayStore.screenSettings.DCApplyProcessing}
                        renderInput={(params) => (<EDRTextField {...params}
                        label= {t('targetVlan')}
                        InputLabelProps={{shrink: true}} 
                        margin="normal" 
                        variant="outlined"
                        placeholder="Type Here"
                        aria-autocomplete="none"
                        onBlur={form.handleBlur}
                        error={form.touched.vlan && Boolean(form.errors.vlan)}
                        helperText={form.touched.vlan && form.errors.vlan}
                        style={{ margin: 8 ,backgroundColor:'#ffffff'}}
                        />)}
                        />
                    </FormControl>
            </Grid>
            </Grid>
            <ConfirmationDialog type="deleteDC" device={null} open={openConfirmationDCDialog} onClose={(confrim)=>handleConfirmationPopup(confrim)} children={"You are about the delete "+ hostnameIP +" - are you sure?"}></ConfirmationDialog>
        </Grid>
    );
};

export default observer(CloneSettingsComponent);