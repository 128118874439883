import { FormControl, Grid, InputAdornment, InputLabel, makeStyles, OutlinedInput, Typography,IconButton, DialogContent, Dialog, Divider, Select, MenuItem, Button, Tooltip, Switch } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Autocomplete } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { EDRAutocomplete } from "../../../app/common/EDRAutocomplete";
import { EDRTextField } from "../../../app/common/EDRTextField";
import { SaveButton } from "../../../app/common/saveButton";
import { DATABASE_TYPE_NAME } from "../../../app/models/BaseSettings.enum";
import { CONSTS, DeviceTest } from "../../../app/models/createjob/createJob.model";
import { JobDevice } from "../../../app/models/jobDevice/jobDevice.model";
import { DatabaseType, DevicePlatform } from "../../../app/models/jobProperties.model";
import { TestType } from "../../../app/models/jobTest.model";
import { RootStoreContext } from "../../../app/stores/root.store";
import { Props } from "./NetworkTestDialog.component";
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import HelpIcon from '@material-ui/icons/Help';
import jobAgent from "../../../app/api/jobAgent";

const DatabaseTestDialog: React.FC<Props> = (props) => {
    const useStyles = makeStyles({
        formControl: {
            width: '100%',
            '& label': {
                background: "white",
                width: "auto",
                padding:"0 4px 0 4px",
                fontSize: '17px',
              }
        },
        helpIcon:
        {
          color: '#1976d2',
          fontSize: "large"
        },
        divider: {
            backgroundColor: '#eaeef4',
            width: '290%',
            margin: '0',
            marginTop: '10px',
            marginLeft: '10px',
    },
        SecondHeading:{
            color:'#1f396d',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1
        },
        centerVertical: {
            display: 'flex',
            alignItems: 'center',
            justifyContent:'space-between'
        },
        toggleItem: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
    
            '& .MuiListItemText-root' : {
                marginLeft: 10
            },
            '& .MuiSvgIcon-root': {
                color: '#8092a9'
            }
        }
        });    

    const classes = useStyles();
    const rootStore = useContext(RootStoreContext); 
    const { onApply,discard,closeDrawer,reload,setParentValue } = props;
    const {createJobStore,jobSettingsStore}= rootStore;
    const device= createJobStore.editedDevice;
    const DatabaseTest = device.tests.find(test=> test.testType == TestType.database);
    const DatabaseTypeOptions: Array<DatabaseType> = [0];
    const [passwordVisibility,setPasswordVisibility] = useState(true);
    const [showEyePassword, setShowEyePassword]= useState(true);
    const [UserPassAutocomplete,setUserPassAutocomplete] = useState(false);
    const [cancel,setCancel]=useState(false);
    const [t] = useTranslation("createJobDevices");
    const [Info] = useTranslation('createJobBase');
    const [processingTest,setProcessingTest] = useState(false);
    let labelRef;
    const PreviousDatabase = jobSettingsStore.databaseList; 
    
    const loadOptions =()=>{
        let options =[];
        if(device && device.os === DevicePlatform.Windows)
            return jobSettingsStore.accessCredentials;
        else if(device && device.os === DevicePlatform.UNIX)
        {
            jobSettingsStore.accessCredentials.map(item=>{
                let userNoDomain = item.userName.split('\\')[1];
                options.push({userName:userNoDomain==undefined ? item.userName: userNoDomain,password:item.password});
            });
           return options;
        }
    }

    const loadServerName =()=>{
        let res="";
        if(device!=undefined)
        {
            if(device.planName.includes("Custom"))
            {
               res= device.sourceName;
            }
            else
            {
                if(device.sourceName!="")
                {
                    res= device.sourceName.split('.')[0];
                }
                else  res= device.deviceName;
            }
        }
        else  
            res="";
        return res;
    }

    const usernameOptions = loadOptions();
    const serverName = loadServerName();

    const validationSchema = yup.object({
        server:yup.string().nullable().required("Required"),
        database:yup.string().nullable().required("Required"),
        query:yup.string().nullable().required("Required"),
        username:yup.string().nullable().required("Required"),
        password:yup.string().nullable().required("Required"),
        port:yup.string().nullable().required("Required")
    })

    const defaultSettings = {
        databaseType:DatabaseTest.databaseType,
        server: DatabaseTest.databaseServerNameToTest!="" ? DatabaseTest.databaseServerNameToTest:(device.planName.includes("Custom") ? device.sourceName: (device.sourceName!=null ?device.sourceName.split('.')[0]:device.deviceName)),
        database:DatabaseTest.databaseNameToTest!="" ? DatabaseTest.databaseNameToTest :jobSettingsStore.databaseNameToTest,
        query:DatabaseTest!= undefined ?(DatabaseTest.databaseQueryToTest !="" ? DatabaseTest.databaseQueryToTest: jobSettingsStore.databaseQueryToTest): jobSettingsStore.databaseQueryToTest,
        username:DatabaseTest.databaseUsername!="" ? DatabaseTest.databaseUsername : jobSettingsStore.accessCredentials.find(d => d.id == createJobStore.baseSettings.workflowTest.PrimaryUserCredsId).userName,
        password:DatabaseTest.databasePassword !="" ? DatabaseTest.databasePassword : jobSettingsStore.accessCredentials.find(d => d.id == createJobStore.baseSettings.workflowTest.PrimaryUserCredsId).password,
        port:DatabaseTest.databasePort!="" ? DatabaseTest.databasePort :jobSettingsStore.databasePort,
        dbWindowsAuth:DatabaseTest!=null ? DatabaseTest.dbWindowsAuth :jobSettingsStore.dbWindowsAuth
    };

    const form = useFormik({
        initialValues: {
            databaseType:defaultSettings.databaseType,
            server: defaultSettings.server,
            database:defaultSettings.database,
            query:defaultSettings.query,
            username:device && device.os == DevicePlatform.Windows ? defaultSettings.username: defaultSettings.username.split('\\')[1],
            password:defaultSettings.password,
            port:defaultSettings.port,
            dbWindowsAuth:defaultSettings.dbWindowsAuth
        },
        onSubmit: () => { },
        validationSchema
    })

      useEffect(() => {
        //form.setFieldValue("query",reload,true);
      }, [reload]); 


    const handleDatabaseTypeChange =(event)=>{
        form.setFieldValue("databaseType",event.target.value,true);
    }

    const handleServerChange =(event:object,value:any)=>{
        form.setFieldValue("server",value,true);
    }

    const handleDatabaseChange =(event:object,value:any)=>{
        form.setFieldValue("database",value,true);
    }

    const handleBlurQueryChange =(event:any) => {
        const Obj = PreviousDatabase.find(e => e.query.toLocaleLowerCase() ==  form.values.query.toLocaleLowerCase());
        setParentValue(form.values.query);
      }

    const handleQueryChange =(event:object,value:any)=>{
        if (value!=null && typeof value === 'object') 
        {
            form.setFieldValue("query",value.query,true);
        }
        if (typeof value === 'string')
        {
          form.setFieldValue("query",value);
        }
    }

    const handleUsernameChange =(event:React.ChangeEvent,value:any)=>{
        if(event!=null){
            let onInput= event.type=="click"? false:true;
            setUserPassAutocomplete(onInput);
            if (value!=null && typeof value === 'object') 
            {
                form.setFieldValue("username",value=="" ? '':value.userName,true);
            }
            if (typeof value === 'string')
            {
                const userObj = usernameOptions.find(e => e.userName.toLocaleLowerCase() == value.toLocaleLowerCase());
                form.setFieldValue("username",value,true);
                let pass = userObj== undefined ? '' :userObj.password;
                form.setFieldValue("password",pass,true);
            }
            setShowEyePassword(onInput);
          }
    }

    const handlePasswordChange =(event)=>{
        setUserPassAutocomplete(true);
        setShowEyePassword(true);
        form.setFieldValue("password",event.target.value,true);
    }

    const handleMouseClick =()=>{
        if(form.values.password === CONSTS.MaskedPassword)
        {
            form.setFieldValue("password",'',true);
        }
    }

    const handleMouseOut =()=>{
        if(form.dirty && form.values.password == '')
        {
            if(form.values.username!='')
            {
                return;
            }
            else form.setFieldValue("password",CONSTS.MaskedPassword,true);
        }
    }

    const handleClickShowPassword  = () => {
        setPasswordVisibility(passwordVisibility? false: true); 
    };

    const handleTest=() =>{       
        var details =
        {
            databaseType:form.values.databaseType,
            databaseServer:form.values.server,
            database:form.values.database,
            query:form.values.query,
            dbUsername:form.values.username,
            dbPassword:form.values.password,
            dbWindowsAuth:form.values.dbWindowsAuth,
            id: createJobStore.baseSettings.workflowTest.PrimaryUserCredsId
        };
        jobAgent.JobTestsActions.TestDatabase(details).then(res => {
            if(res)
                toast.success("Database Test succeeded");
            else
                toast.error("Database Test failed");
        }).catch(err=>{
        if(err && err.data && err.data.errors)
            toast.error(err.data.errors);
        });          
    }

    const handleCancle =()=>{
        if(form.dirty && form.touched)
            setCancel(!cancel);
        else
            closeDrawer();
    }

    const handleSave =()=> {
        try{
            let DeviceTest = {
                testCategory:device.tests[0].testCategory, 
                testType:TestType.database, 
                selected: true,
                customCommandToTest: '',
                customCommandExpectedResult: '', 
                customCommandToTestIsExternal: false,
                databaseType:form.values.databaseType,
                databaseNameToTest: form.values.database,
                databaseQueryToTest: form.values.query,
                databaseServerNameToTest: form.values.server,
                databaseUsername:form.values.username,
                databasePassword: form.values.password,
                databasePort:form.values.port,
                dbWindowsAuth:form.values.dbWindowsAuth,
                serviceToTest:'',
                testFromEDRC: false,
                script: '',
                thirdDeviceNetworkToTest: '',
                authenticationUserToTest:'',
                authenticationPassToTest:'',
                webPortalToTest: '',
                webPortalResult: ''
            };
            createJobStore.updateTestDevice(TestType.database,DeviceTest,device.planName.includes("Custom Devices") ? device.sourceName : device.deviceName);
            onApply();
            discard();
            setParentValue(form.values.query);
            toast.success(t('saveSuccessful'));
        }
        catch(error)
        {
            toast.success(t('savefailed')+error);
        }
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    const handleYes=()=>{
        form.setFieldValue("databaseType",DatabaseTest.databaseType);
        form.setFieldValue("server",DatabaseTest !=undefined ?(device.planName.includes("Custom") ? device.sourceName: device.sourceName.split('.')[0]):'',true);
        form.setFieldValue("database",DatabaseTest.databaseNameToTest!="" ? DatabaseTest.databaseNameToTest :jobSettingsStore.databaseNameToTest,true);
        form.setFieldValue("query",DatabaseTest.databaseQueryToTest !="" ? DatabaseTest.databaseQueryToTest: jobSettingsStore.databaseQueryToTest,true);
        form.setFieldValue("username",DatabaseTest.databaseUsername!="" ? DatabaseTest.databaseUsername : createJobStore.baseSettings.workflowTest.PrimaryUser,true);
        form.setFieldValue("password",DatabaseTest.databasePassword !="" ? DatabaseTest.databasePassword :  createJobStore.baseSettings.workflowTest.PrimaryPassword,true);
        discard();
        setCancel(false);
    }

    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const handlePortChange = (event: any) => {
        form.setFieldValue("port", parseInt(event.target.value));
    };

    const handleChangeWindowsAuth =(event)=>{
        form.setFieldValue("dbWindowsAuth", event.target.checked);
    }
    
    return(
           <Grid container spacing={2} style={{paddingRight: '5%',paddingLeft:'5%', alignContent:'flex-start'}}>
              <Grid item container direction="row" spacing={2} style={{marginTop:'0px'}}>
                <div style={{ display: 'flex', flexDirection: 'row',marginLeft:'1%'}}>
                <Typography variant='h6' className={classes.SecondHeading}>{t('databasesetting')}</Typography>
                    <Tooltip title={Info('databaseInfo')} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider} style={{width:'177%'}}/>
                </div>
            </Grid>
            
            <Grid item container spacing={1}>
                <Grid item xs={6}>
                <FormControl className={classes.formControl} variant='outlined' style={{marginTop:'10px',marginLeft:'1%'}}>
                <InputLabel>{t('databaseType')}</InputLabel>
                <Select
                required={true}
                labelId="databaseType"
                value={form.values.databaseType}
                displayEmpty={true}
                style={{ backgroundColor:'#ffffff',height:'40px',marginLeft:'1%'}}
                renderValue={name => name<0? 'None': DATABASE_TYPE_NAME[name as string]}
                onChange={handleDatabaseTypeChange}>
                {DatabaseTypeOptions.map((name) => (
                <MenuItem key={name} value={name}>
                {DATABASE_TYPE_NAME[name]}
                </MenuItem>))}
                </Select>
                </FormControl>
                </Grid>
                <Grid item xs={6}>
                <FormControl className={classes.formControl} variant='outlined'>
                <EDRTextField id="port"
                    name="port"
                    label={t('databasePort')}
                    placeholder=""
                    value={form.values.port}
                    onChange={handlePortChange}
                    onBlur={form.handleBlur}
                    error={Boolean(form.errors.port)}
                    helperText={t(form.errors.port)}
                    type="number"
                    aria-autocomplete="none"
                    InputLabelProps={{
                       shrink: true,
                    }}
                    variant="outlined" />
                    </FormControl>
                </Grid>
            </Grid>

            <Grid item container spacing={2} >
                <Grid item xs={6}>
                <FormControl className={classes.formControl} variant='outlined'>   
                   <EDRAutocomplete id="server"
                        freeSolo
                        fullWidth
                        size="small"
                        value={form.values.server}
                        options={PreviousDatabase.map(a => a.databaseServer)}
                        renderOption={(option: string|{databaseServer: string,database:string,query:string,username:string,password:string}) => (typeof option === 'string' ? option : `${device.planName.includes("Custom") ? device.sourceName: device.sourceName.split('.')[0]}`)}
	                    getOptionLabel={(option: string|{databaseServer: string,database:string,query:string,username:string,password:string}) => (typeof option === 'string' ? option : `${device.planName.includes("Custom") ? device.sourceName: device.sourceName.split('.')[0]}`)}
                        onChange={handleServerChange}
                        onInputChange={(event:object,value:any) => {handleServerChange(event,value)}}
                        renderInput={(params) => (<EDRTextField {...params}
                        label= {t('dbserver')}
                        InputLabelProps={{shrink: true}} 
                        margin="normal" 
                        variant="outlined"
                        aria-autocomplete="none"
                        onBlur={form.handleBlur}
                        error={form.touched.server && Boolean(form.errors.server)}
                        helperText={t(form.errors.server)}
                        style={{ margin: 8 ,backgroundColor:'#ffffff'}}
                        />)}
                        />
                        </FormControl>
            </Grid>
                <Grid item xs={6}>
                <FormControl className={classes.formControl} variant='outlined'>   
                   <EDRAutocomplete id="database"
                        freeSolo
                        fullWidth
                        size="small"
                        value={form.values.database}
                        options={PreviousDatabase.map(a => a.database)}
                        onChange={handleDatabaseChange}
                        onInputChange={(event:object,value:any) => {handleDatabaseChange(event,value)}}
                        renderOption={(option: string|{databaseServer: string,database:string,query:string,username:string,password:string}) => (typeof option === 'string' ? option : `${option.database}`)}
	                    getOptionLabel={(option: string|{databaseServer: string,database:string,query:string,username:string,password:string}) => (typeof option === 'string' ? option : `${option.database}`)}
                        renderInput={(params) => (<EDRTextField {...params}
                        label= {t('database')}
                        InputLabelProps={{shrink: true}} 
                        margin="normal" 
                        variant="outlined"
                        aria-autocomplete="none"
                        onBlur={form.handleBlur}
                        error={form.touched.database && Boolean(form.errors.database)}
                        helperText={t(form.errors.database)}
                        style={{ margin: 8 ,backgroundColor:'#ffffff'}}
                        />)}
                        />
                        </FormControl>
            </Grid>
            </Grid>
           
            <Grid item xs={12}>
            <FormControl className={classes.formControl} variant='outlined'>   
                   <EDRAutocomplete id="query"
                        freeSolo
                        fullWidth
                        size="small"
                        value={form.values.query}
                        options={PreviousDatabase.map(a => a.query)}
                        onChange={handleQueryChange}
                        onInputChange={(event:object,value:any) => {handleQueryChange(event,value)}}
                        renderOption={(option: string|{databaseServer: string,database:string,query:string,username:string,password:string}) => (typeof option === 'string' ? option : `${option.query}`)}
	                    getOptionLabel={(option: string|{databaseServer: string,database:string,query:string,username:string,password:string}) => (typeof option === 'string' ? option : `${option.query}`)}
                        renderInput={(params) => (<EDRTextField {...params}
                        label= {t('query')}
                        InputLabelProps={{shrink: true}} 
                        margin="normal" 
                        variant="outlined"
                        aria-autocomplete="none"
                        onBlur={handleBlurQueryChange}
                        error={form.touched.query && Boolean(form.errors.query)}
                        helperText={t(form.errors.query)}
                        style={{ margin: 8 ,backgroundColor:'#ffffff'}}
                        />)}
                        />
                        </FormControl>
            </Grid>

            <Grid item container direction="row" spacing={2}>
            <Grid item xs={6} spacing={2}>
            <FormControl className={classes.formControl} variant='outlined'>   
                   <EDRAutocomplete id="username"
                        freeSolo
                        fullWidth
                        size="small"
                        value={form.values.username}
                        disabled={form.values.dbWindowsAuth}
                        options={usernameOptions}
                        renderOption={(option: string|{userName:string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
                        onChange={handleUsernameChange}
                        onInputChange={(event:React.ChangeEvent,value:any) => {handleUsernameChange(event,value)}}
                        getOptionLabel={(option: string|{userName:string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
                        renderInput={(params) => (<EDRTextField {...params}
                        label= {t('username')}
                        InputLabelProps={{shrink: true}} 
                        onBlur={form.handleBlur}
                        error={form.touched && form.touched.username && form.errors && Boolean(form.errors.username)}
                        helperText={form.touched.username && form.errors.username}
                        margin="normal" 
                        aria-autocomplete="none"
                        variant="outlined"
                        style={{ margin: 8 }}
                        />)}
                        />
                        </FormControl>
            </Grid>

            <Grid item xs={6} spacing={2}>
            <FormControl className={classes.formControl} variant='outlined' style={{marginLeft:'8px'}}>   
                    <InputLabel ref={ref => {labelRef = ReactDOM.findDOMNode(ref)}} style={{transform: 'translate(16px, 2px) scale(0.75)'}}>{t('password')}</InputLabel>
                    <OutlinedInput id="password" 
                    name="password"
                    value={form.values.password}
                    disabled={form.values.dbWindowsAuth}
                    labelWidth={labelRef ? labelRef.offsetWidth:0}
                    autoComplete="off"
                    aria-autocomplete="none"
                    type={passwordVisibility ? 'password':'text'}
                    onChange={handlePasswordChange}
                    onClick={handleMouseClick}
                    onBlur={handleMouseOut}
                    style={{ marginTop: '8px',height:'37px'}}
                    endAdornment={ showEyePassword && UserPassAutocomplete ?
                    <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={()=> handleClickShowPassword()}
                    >
                    {passwordVisibility ?  <VisibilityOff />: <Visibility />}
                </IconButton>
                </InputAdornment>
                    :null}
                    />  
                    </FormControl>
            </Grid>

            <Grid item container justify="center" direction="column" style={{ marginTop: "15px", marginBottom: "15px", display: 'flex', flexDirection: 'row' }}>
                    <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                        <Typography style={{ marginLeft: '4%' }}>{t('useWindowsAuth')}</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                        <Switch checked={form.values.dbWindowsAuth} onChange={handleChangeWindowsAuth} color='primary' />
                    </Grid>
                </Grid>
                
            </Grid>

            <Grid item container direction="row" style={{paddingRight:'0px'}}>
            <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end'}}>
            <Button onClick={handleCancle} color="primary" style={{textTransform:'none'}}>
                {t('Cancel')}
            </Button>
            <Button onClick={handleTest} color="primary" disabled={form.values.server=='' || form.values.database=='' || form.values.query=='' || form.values.username=='' || form.values.password==''} style={{textTransform:'none',marginLeft:'-11px'}}>
                {t('Test')}
            </Button>
            <SaveButton onClick={handleSave} disabled={form.values.server=='' || form.values.server==null  || form.values.database=='' || form.values.database==null||  form.values.query=='' || form.values.query==null || form.values.username=='' ||form.values.username==null|| form.values.password==''||form.values.password==null} variant="contained" color="primary" style={{textTransform:'none'}}>
                {t('apply')}
            </SaveButton>
            </Grid>
            </Grid>
            
        </Grid>
    );
}

export default DatabaseTestDialog;