import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/root.store';
import { Dialog, DialogContent, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';


const UserGuideDialogComponent: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { helpDialogOpen, setHelpOpen } = rootStore.userStore;
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

    return <Dialog
                style={{ zIndex: 1302, minHeight: 600}}
                maxWidth="md"
                
                fullWidth={true}
                open={helpDialogOpen}
                fullScreen={matchesSM}
                onClose={() => setHelpOpen(false)}
            >
                <DialogContent >
                <object style={{height: '90vh', width: '100%'}} data="/assets/documents/EnsureDR_Management_Guide_Release.pdf" type="application/pdf">
                        <iframe src="/assets/documents/EnsureDR_Management_Guide_Release.pdf"></iframe>
                    </object>
                </DialogContent>
            </Dialog>
}

export default (observer(UserGuideDialogComponent));