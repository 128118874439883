import { Button, Card, CircularProgress, Divider, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { toast } from 'react-toastify';
import { runInAction } from 'mobx';
import Create from '@material-ui/icons/Create';
import { RootStoreContext } from '../../../../app/stores/root.store';
import AssetAgent from '../../../../app/api/assetsAgent';
import { Credentials, LicenseType, NetworkOption } from '../../../../app/models/jobProperties.model';
import { DomainControllerDTO } from '../../../../app/models/createjob/createJob.model';
import ConfirmationDialog from '../../../../app/common/confirmationDialog.component';
import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles({
    editIcon: {
        cursor: 'pointer'
    },
    button: {
        width: '50%',
        height: '100%',
        borderRadius: '4px',
        border: 'solid 1px #c7cfdb',
        backgroundColor: '#fafafc'
    },
    advanced: {
        width: '14px',
        height: '14px',
        objectFit: 'contain',
    },
    MainHeading: {
        fontFamily: 'Roboto',
        color: '#1f396d',
        fontSize: '16px'
    },
    SecondHeading: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '100%',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.14',
        letterSpacing: 'normal',
        textAlign: 'left'
    },
    card: {
        padding: 25,
        height: '100%',
    },
    divider: {
        backgroundColor: '#eaeef4',
        width: '75%',
        marginTop: '8px',
        marginLeft: '7px',
        marginRight: '-107px'
    },
    helpIcon:
    {
        color: '#1976d2',
        fontSize: "large"
    },
    formControl: {
        width: '100%',
        '& label': {
            background: "white",
            width: "auto",
            padding: "0 4px 0 4px",
            fontSize: '17px'
        }
    }
});

const DCComponent: React.FC<{onValidationChange:(isValid: boolean) => void}> = (props) => {

    const classes = useStyles();
    const [t] = useTranslation("createJobNetwork");
    const rootStore = useContext(RootStoreContext);
    const {jobSettingsStore,displayStore,createJobStore} = rootStore;
    const {cloneDrawerOpen}=displayStore.NetworkDrawerSettings;
    const [ImageCloneColor, setImageCloneColor] = useState(false);
    const [openMissingDetailsDialog,setopenMissingDetailsDialog]= useState(false);
    
    let isEdit = window.location.href.includes('editJob');
    let isDraft = createJobStore.isDraft;
    const {isFirstTrial} = jobSettingsStore;

    /* useEffect(() => {
        const DC = jobSettingsStore.DCs.find(dc => dc.id == createJobStore.networkSetting.domainController?.id);
        if(DC) {
            jobSettingsStore.updateSelectedDC(DC);
        }
    }, [createJobStore.networkSetting.domainController]); */

    useEffect(() => {
        let isDCValid = Boolean(jobSettingsStore.selectedDC?.id) ||
         jobSettingsStore.selectedDC?.domainControllerName == "I have a Domain Controller inside my replication" || createJobStore.networkSetting.NetworkType === NetworkOption.None;

        props.onValidationChange(isDCValid);
     }, [jobSettingsStore.selectedDC])

    const CheckSectionAndDrawerStatus= (section:string) =>{
        if(displayStore.screenSettings.displayNetwork=='ControllerSettings' || displayStore.screenSettings.displayNetwork=='CloneSettings' || displayStore.screenSettings.displayNetwork=='AdvancedVlan' || 
            displayStore.screenSettings.displayNetwork=='NetworkControlSettings'|| displayStore.screenSettings.displayNetwork== 'EDRServerSettings' || displayStore.screenSettings.displayNetwork=='EmptyVlan')
        {
            if(displayStore.screenSettings.displayNetwork!='EDRServerSettings')
                displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
            displayStore.updateNetworkDrawerSettings({controllerDrawerOpen:false});
            displayStore.updateNetworkDrawerSettings({cloneDrawerOpen:false});
            displayStore.updateNetworkDrawerSettings({liveDrawerOpen:false});
            displayStore.updateNetworkDrawerSettings({testDrawerOpen:false});
        }
    }

    const handleAddDC =()=>{
        if((jobSettingsStore.selectedDC==null) || (jobSettingsStore.selectedDC!= null && !jobSettingsStore.selectedDC.domainControllerName.includes("Add")))
        {
            jobSettingsStore.updatePreviousDC(jobSettingsStore.selectedDC);
            let dcObject = new DomainControllerDTO();
            dcObject.id=0;
            dcObject.domainControllerName ='';
            displayStore.updateScreenSettings({ AddModeDC: true,AddModeEDR:false,AddModeEDRC:false,DCTestValid:false,DCValuesValid:false});
            displayStore.updateScreenSettings({ renderValue: "" });
            displayStore.updateScreenSettings({ displayInfo: "InfoClone" });
            jobSettingsStore.updateSelectedDC(dcObject);
            rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController, domainControllerName:''}});
            CheckSectionAndDrawerStatus("dc");
            displayStore.updateScreenSettings({ displayNetwork: "CloneSettings" });
            displayStore.closeAllOtherNetworkDrawersExcept("cloneDrawer");
            if(displayStore.networkSectionSettings.DisableButtonDC)
                displayStore.updateNetworkSectionSettings({DisableButtonDC:false});
        }
    };

    const testDomainController =(dc:DomainControllerDTO)=>{
        AssetAgent.TestDomainController({domainCredsId:dc.domainCredentials.id,
            domainName:dc.domainName,
            username:dc.domainCredentials.userName,
            password:dc.domainCredentials.password,
            domainControllerName:dc.domainControllerName}).then(res => {
            if(res)
            {
               toast.success(dc.domainControllerName + ': ' + "Domain Controller Tested and Applied successfully.")
               displayStore.updateNextStepValid({dcAdvancedValid:true})
            }
            else
            {
                toast.error(dc.domainControllerName + ': ' + "Domain Controller Validation Failed - check access to the domain controller")
                displayStore.updateScreenSettings({DCApplyProcessing:false});
                displayStore.updateNextStepValid({dcAdvancedValid:true})
            }
            }).catch(err => {
                toast.error(dc.domainControllerName + ': ' + "Domain Controller Validation Failed - check access to the domain controller")
                displayStore.updateScreenSettings({DCApplyProcessing:false});
                displayStore.updateNextStepValid({dcAdvancedValid:true})
            });
    }
                
    const handleChangeDC = (event: object, value:any) => {
        if(event != null)
        {
            var dcObject;
            if(typeof value == "object" &&  value !== "I have a Domain Controller inside my replication") 
            {
                jobSettingsStore.updateSelectedDC(value);
                createJobStore.updateNetworkSettings({ domainController: value });
            }
            if(typeof value == "string")
                dcObject= jobSettingsStore.DCs.find(dc => dc.domainControllerName.toLocaleLowerCase() == value.toLocaleLowerCase());
            if(dcObject != null &&  dcObject.domainControllerName !== "I have a Domain Controller inside my replication")
            {
                    displayStore.updateScreenSettings({ AddModeDC: false,AddModeEDR:false,AddModeEDRC:false,DCTestValid:false,DCValuesValid:true });
                    displayStore.updateScreenSettings({ renderValue: dcObject.name });
                    jobSettingsStore.updateSelectedDC(dcObject);
                    createJobStore.updateNetworkSettings({ domainController: dcObject });
            }
            //displayStore.updateScreenSettings({ displayInfo: "InfoClone" });
            if(dcObject)
            {
                if(dcObject.domainControllerName !== "I have a Domain Controller inside my replication")
                {
                    jobSettingsStore.updateSelectedDC(dcObject);
                    createJobStore.updateNetworkSettings({ domainController: dcObject });
                    CheckSectionAndDrawerStatus("dc");
                    let dc = dcObject as DomainControllerDTO
                    if(!dc.dcHostServerSource || !dc.dcHostServerTarget || !dc.domainName || !dc.domainControllerName || !dc.dcDataStoreTarget || !dc.domainControllerESXCredentials || !dc.domainCredentials || !dc.domainControllerTargetVLAN)
                    {
                        displayStore.updateScreenSettings({ displayNetwork: "CloneSettings" });
                        displayStore.closeAllOtherNetworkDrawersExcept("cloneDrawer");
                        displayStore.updateNextStepValid({dcAdvancedValid:false});
                        setopenMissingDetailsDialog(true);
                    }
                    else
                        testDomainController(dc);
                    //displayStore.updateScreenSettings({ displayNetwork: "CloneSettings" });
                    //displayStore.closeAllOtherNetworkDrawersExcept("controllerDrawer");
                    if(displayStore.networkSectionSettings.DisableButtonDC)
                        displayStore.updateNetworkSectionSettings({DisableButtonDC:false});

                }
                else
                {
                    displayStore.updateNextStepValid({dcAdvancedValid:true});
                    displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
                    createJobStore.updateNetworkSettings({ domainController: dcObject });
                }
            }
        }
    };

    const buttonRedirect= (subComponent:any)=> {
        if(cloneDrawerOpen)
        {
          displayStore.updateScreenSettings({ displayNetwork: 'EmptyVlan' });
          displayStore.updateNetworkDrawerSettings({cloneDrawerOpen:false});
        }
        else
        {
            displayStore.updateScreenSettings({AddModeDC:false});
            displayStore.updateScreenSettings({ displayNetwork: subComponent });
            displayStore.updateNetworkDrawerSettings({cloneDrawerOpen:true});
            displayStore.updateScreenSettings({ displayInfo: "InfoClone" });
            displayStore.closeAllOtherNetworkDrawersExcept("cloneDrawer");
        }
    };

    const loadCircularProgress =(item:string)=>{
        return <div style={{width:'100%',display:'flex'}}>
        {t('validateInProgress')}
        <CircularProgress id="dcValidation" size={18} style={{color: '#2892D7', marginLeft: '10px',marginTop: '-3px'}}/>
        </div>
    }
    
    return (
        <Grid container spacing={2} style={{ display: 'flex',justifyContent:'center' }}>
      
            <Grid item xs={1} style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
            {isFirstTrial  ? 
            <Tooltip title={t("trialLock")} arrow>
                <LockIcon />
            </Tooltip>
            :<IconButton onClick={()=> handleAddDC()} disabled={true}>
                <AddCircleOutlineIcon/>
            </IconButton>}
            </Grid>
            <Grid item container xs={11} >
            <Grid item xs={11}>
            <Autocomplete id="dc"
                disableClearable
                value={jobSettingsStore.selectedDC}
                disabled={true}
                style={{backgroundColor:(displayStore.networkSectionSettings.DisableButtonDC ? 'rgba(235, 238, 244, 0.75)': '#ffffff'), width: '97%'}} 
                renderOption={option => ( option.domainControllerName.includes("I have a Domain Controller") ?<span>{`${option.domainControllerName}`}</span>:<span>{`${option.domainControllerName} (${option.dcDataStoreTarget} - ${option.dcHostServerTarget})`}</span>)}
                getOptionLabel={(option) => (`${option.domainControllerName}`)}
                options={jobSettingsStore.DCs}
                onFocus={()=>displayStore.updateScreenSettings({ displayInfo: "InfoClone" })}
                onChange={(event: object, value: any) => { handleChangeDC(event, value) }}
                onInputChange={handleChangeDC}
                renderInput={(params) => (<TextField {...params} disabled={true} label={t('clone')} InputLabelProps={{ shrink: true, }} variant="outlined" />)}
            />
            </Grid>
       
        <Grid item xs={1}>
            <Button className={classes.button} disabled={true} onClick={() => buttonRedirect('CloneSettings')}>
                <Create className={classes.editIcon} onMouseEnter={() => setImageCloneColor(true)} onMouseLeave={() => setImageCloneColor(false)}></Create>
            </Button>
        </Grid>
        </Grid>
        <ConfirmationDialog type="missingDetailsDC" device={null} open={openMissingDetailsDialog} modal={true} onClose={(confirmed)=>{

            if(!confirmed)
                return;
            setopenMissingDetailsDialog(false);

        }} children={"You have selected to use the Domain Controller Clone feature of EnsureDR. Please fill all the details of your Domain Controller of choice in the form on the right side, then click 'Apply'. Once you get a Green toast confirming the Domain Controller is valid- you can click Next and move on to the next step"}></ConfirmationDialog>
    </Grid>
    );
};

export default observer(DCComponent);