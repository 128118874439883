import { IUsedLicences } from '../models/licenseUsage.model';
import { IUser, IUserFormValues } from '../models/user.model';
import agentbase from './agentBase';
import _axios from 'axios';
import { ILicenseDevicesDto } from '../models/licenseDevicesDto.model';

const axios = agentbase.axios;

const requests = agentbase.requests;

const User = {
    current: (): Promise<IUser> => requests.get('/user'),
    login: (user: IUserFormValues): Promise<IUser> => requests.post(`/user/login`, user),
    autoLogin: (): Promise<IUser> => requests.post(`/user/autologin`, {}),
    register: (user: IUserFormValues): Promise<IUser> => requests.post(`/user/register`, user),
    get: (username: string): Promise<IUserFormValues> => requests.get(`/user/${username}`),
    //get: (username: string) => requests.get(`/user/${username}`),
    updateProfile: (userProfile: IUserFormValues) => requests.put(`/user`, userProfile),
    getUsedLicences: (): Promise<IUsedLicences> => requests.get('/licenses/getlicencesusage'),
    getLicenseUsageById: (LicenseId: number): Promise<ILicenseDevicesDto> => requests.get('/licenses/getavailabledevicesbylicenseid/' + LicenseId),
}

export default {
    User,
}