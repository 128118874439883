import React, { useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/root.store';
import { Dialog, useMediaQuery, useTheme, DialogContent, Grid, Typography, CircularProgress, Button, makeStyles, InputLabel, FormControl, OutlinedInput, InputAdornment, IconButton, FormHelperText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { toast } from 'react-toastify';



const RegisterFormComponent: React.FC = () => {
    const rootStore = useContext(RootStoreContext); 
    const { register, loadingProfile, profileUpdateError, registerDialogOpen, setOpenRegister,userProfile} = rootStore.userStore;
    const [username, setUsername] = useState("");
    const [displayname, setDisplayname] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [repassword, setRePassword] = useState("");
    const [displaynameHelper, setDisplayNameHelper] = useState("");
    const [emailHelper, setEmailHelper] = useState("");
    const [passwordHelper, setPasswordHelper] = useState("");
    const [repasswordHelper, setRePasswordHelper] = useState("");
    const [t] = useTranslation("user");
    const theme = useTheme();    
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const [passwordVisibility,setPasswordVisibility] = useState(false);
    const [confirmPasswordVisibility,setconfirmPasswordVisibility] = useState(false);
    
    const keyPress = event => {
        if(event.keyCode == 13){
          registerHandler();
        }
    }
    
    const onChange = event => {
      let valid: boolean;
      switch (event.target.id) {
         case "email":
            setEmail(event.target.value);
            break;
         case "username":
            setUsername(event.target.value);
            break;
         case "displayname":
            setDisplayname(event.target.value);
            valid = event.target.value.length > 2 && event.target.value.length < 20
            if (!valid) {
              setDisplayNameHelper(t("profiledisplayshouldbe"));
            } else {
              setDisplayNameHelper("");
            }
           break;
          case "password":
            setPassword(event.target.value);
            valid = event.target.value.length > 5;
            if (!valid) {
              setPasswordHelper(t("profilepasswordshouldbe"));
            } else {
              setPasswordHelper("");
            }
            break;
            case "repassword":
            setRePassword(event.target.value);
            valid = event.target.value.length > 5;
            if (!valid) {
              setRePasswordHelper(t("profilepasswordshouldbe"));
            } else {
              valid = event.target.value === password;
                if (!valid){
                  setRePasswordHelper(t("profilepasswordmustmatch"));
                }
                else {
                  setRePasswordHelper("");
                }
            }
            break;     
         default:
           break;
       }
    }
    const registerHandler= () => {
      let emailVal = '';
      let usernameVal = '';
      let displaynameVal = '';
      if (email.length > 0)
        emailVal = email;
      if (displayname.length > 0)
        displaynameVal = displayname;
      if (username.length > 0)
        usernameVal = username;
      let values = {
        email: emailVal,
        username: usernameVal,
        password: password,
        displayName: displaynameVal
      }
      register(values).finally(
        () => 
        {
          rootStore.userStore.registerError ?
          toast.error(t("registerFailed"))
          :
          rootStore.userStore.registerOK ?
            toast.success(t("registerOK"))
            : toast.success(t("registerOK"))
        }
      )
      setOpenRegister(false);
    }  

    const handleClickShowPassword = () => {
      setPasswordVisibility(passwordVisibility? false: true);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowConfirmPassword = () => {
      setconfirmPasswordVisibility(confirmPasswordVisibility? false: true);
    };

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };

    
    const useStyles = makeStyles({
      label: {
        textTransform: 'capitalize',
      },
    });

    const classes = useStyles();

      const dialogContents = (
            <Dialog
              style={{ zIndex: 1305 }}
              open={registerDialogOpen}
              fullScreen={matchesSM}
              onClose={() => setOpenRegister(false)}
            >
              <DialogContent style={{ overflow: "hidden"}}>
                <Grid container direction="column" spacing={3} style={{ overflow: "hidden"}}>
                  <Grid item>
                    <Typography align="left" classes={{ root: "profile_form_title"}}>
                    {t("registertitle")}
                    </Typography>
                  </Grid>
                  <Grid item >
                    <FormControl variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-username">{t("profileemail")}</InputLabel>
                          <OutlinedInput 
                                      id="email"
                                      error={emailHelper.length !== 0}
                                      classes={{ root: "profile_text_box" }}
                                      label={t("profileemail")}
                                      onChange={onChange}
                          />  
                    </FormControl>
                  </Grid>
                  <Grid item >
                    <FormControl variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-username">{t("profileusername")}</InputLabel>
                          <OutlinedInput 
                                      id="username"
                                      error={displaynameHelper.length !== 0}
                                      classes={{ root: "profile_text_box" }}
                                      label={t("profileusername")}
                                      onChange={onChange}
                          />  
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-displayname">{t("profiledisplayname")}</InputLabel>
                              <OutlinedInput 
                                          id="displayname"
                                          error={displaynameHelper.length !== 0}
                                          classes={{ root: "profile_text_box" }}
                                          label={t("profiledisplayname")}
                                          onChange={onChange}
                                          onKeyDown={keyPress}
                              />  
                              <FormHelperText>{displaynameHelper}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item >
                    <FormControl variant="outlined">
                          <InputLabel margin="dense" htmlFor="outlined-adornment-password">{ t("profileenterpassword")}</InputLabel>
                          <OutlinedInput id="password" 
                                      error={passwordHelper.length !== 0}
                                      type={passwordVisibility ? 'text' : 'password'}
                                      classes={{ root: "profile_text_box" }}
                                      label={ t("profileenterpassword")}
                                      onChange={onChange}
                                      onKeyDown={keyPress}
                                      endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPassword}
                                              onMouseDown={handleMouseDownPassword}
                                            >
                                              {passwordVisibility ?  <VisibilityOff />: <Visibility />}
                                            </IconButton>
                                          </InputAdornment>
                            }
                          />  
                      <FormHelperText>{passwordHelper}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined">
                          <InputLabel margin="dense" htmlFor="outlined-adornment-repassword">{ t("profilereenterpassword")}</InputLabel>
                          <OutlinedInput id="repassword" 
                                      error={repasswordHelper.length !== 0}
                                      type={confirmPasswordVisibility ? 'text' : 'password'}
                                      classes={{ root: "profile_text_box" }}
                                      label={ t("profilereenterpassword")}
                                      onChange={onChange}
                                      onKeyDown={keyPress}
                                      endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle repassword visibility"
                                              onClick={handleClickShowConfirmPassword}
                                              onMouseDown={handleMouseDownConfirmPassword}
                                            >
                                              {confirmPasswordVisibility ?  <VisibilityOff />: <Visibility />}
                                            </IconButton>
                                          </InputAdornment>
                            }
                          />  
                          <FormHelperText>{repasswordHelper}</FormHelperText>
                    </FormControl>
                  </Grid>
                  { profileUpdateError ?
                      <Grid item>
                        <Typography 
                          classes={{ root: "somethingwrong_text"}}>
                          { t("profilewrong")}
                        </Typography>
                      </Grid> : ""
                  }
                  <div style={{width:"372px", height: "40px",marginBottom: "2em", marginTop: "1em", display:"flex",justifyContent:"flex-end"}}>
                    <Grid item>
                      <Button
                        classes={{ root: "Cancel", label: classes.label}}
                        style={{ marginRight: "2em", marginTop: "0.5em" }}
                        onClick={() => setOpenRegister(false)}
                      >
                        { t("profilecancel")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        disabled={
                          password.length == 0 ||
                          repassword.length == 0 ||
                          displaynameHelper.length !== 0 ||
                          passwordHelper.length !== 0 ||
                          repasswordHelper.length !== 0
                        }
                        variant="contained"
                        classes={{ root: "profile_send_button" , label: classes.label}}
                        onClick={() => {
                            registerHandler()
                        }}
                      >
                        {loadingProfile ? <CircularProgress size={30} /> : t("profilesave")}
                      </Button>
                    </Grid>
                  </div>
                </Grid>
        </DialogContent>
    </Dialog>
      );
    
    return (
        <div data-test='component-set-name'>
          {loadingProfile ? <CircularProgress size={30} /> : dialogContents}        
        </div>
    );
}

export default (observer(RegisterFormComponent));