import { ListItemText } from "@material-ui/core";
import { action, computed, observable, reaction, runInAction, when } from "mobx";
import { Credentials, DevicePlatform, InfrastructureType, LicenseType, NetworkOption } from "../models/jobProperties.model";
import { RootStore } from "./root.store";
import JobAgent from '../api/jobAgent';
import jobAgent from "../api/jobAgent";
import { toast } from "react-toastify";
import { DataMover } from "../models/BaseSettings.enum";
import { AccessCredentials, AssetDTO, AssetType, CONSTS, CredentialUserType, DataMoverDTO, DomainControllerDTO, DomainControllerSetting, EDRCSetting, EDRSetting, InfraNetworkDTO, InfrastructureDTO, License, LicenseDTO, NetworkTuppleDTO } from "../models/createjob/createJob.model";
import CreateJobStore from "./createJob.store";
import { useContext } from "react";
import DisplayScreenStore from "./screenStore";
import AssetAgent from "../api/assetsAgent";
import EDRServerSettingsComponent from "../../features/createJob/NetworkInfo/Vlan/EDRServerSettings.component";
import { useTranslation } from "react-i18next";

function setInitialPort(infrastructureType) {
    if (infrastructureType == InfrastructureType.VMware)
        return 902;
    else
        return 0;
}

export function setDatamoverDefaultPort (dataMover:DataMover,type?:"source"|"target") {
  
        if(dataMover == DataMover.ZertoVmWare || dataMover == DataMover.ZertoAzure || dataMover == DataMover.ZertoVmwareAzure)
          return 9669;
        else
        if(dataMover == DataMover.SRM)
          return 443;
        if(dataMover == DataMover.VeeamSQL)
        {
            if(type == "source")
                return 9392;
            if( type =="target")
                return 1433;
        }
        if(dataMover == DataMover.VeeamVmware)
          return type=="source" ? 9398: 9419;
        else  
          return 443;
      
}
export default class JobSettingsStore {
    rootStore: RootStore;
    displayStore: DisplayScreenStore;
    private _dcValidation: {dcSelected: boolean, dcValid};
    
    @observable defaultSettings = { 
        serviceToTest:'Spooler',
        serviceToTestLinux:'sshd',
        testFromEDRC: false,
        customCommandToTest:"wmic logicaldisk get FreeSpace /format:value",
        customCommandToTestLinux: "cat /etc/hosts",
        customCommandOS : DevicePlatform.Windows,
        customCommandExpectedResult:'FreeSpace=',
        customCommandExpectedResultLinux:'127.0.0.1',
        customCommandToTestIsExternal: false,
        webPortalToTest: '',
        webPortalResult: '',
        databaseType: 0,
        databaseServerNameToTest: '',
        databaseNameToTest: 'master',
        databaseQueryToTest: "SELECT @@VERSION AS 'SQL Server Version'",
        databaseUsername: '',
        databasePassword: '',
        databasePort:'1433',
        dbWindowsAuth:false
    }

    @observable serviceToTest = '';
    @observable serviceToTestLinux = '';
    @observable testFromEDRC = false;
    @observable customCommandToTest = "wmic logicaldisk get FreeSpace /format:value";
    @observable customCommandToTestLinux = 'cat /etc/hosts';
    @observable customCommandOS = DevicePlatform.Windows;
    @observable customCommandExpectedResult = 'FreeSpace=';
    @observable customCommandExpectedResultLinux = '127.0.0.1';
    @observable customCommandToTestIsExternal = false;
    @observable webPortalToTest = '';
    @observable webPortalResult = '';
    @observable databaseType = 0;
    @observable databaseServerNameToTest = '';
    @observable databaseNameToTest = '';
    @observable databaseQueryToTest = '';
    @observable databaseUsername = '';
    @observable databasePassword = '';
    @observable databasePort = '1443';
    @observable dbWindowsAuth = false;
    
    @observable emailList=[];
    
    @observable ServiceList = [{service:'EventLog',os:DevicePlatform.Windows},{service:'NlaSvc',os:DevicePlatform.Windows},{service:'Netlogon',os:DevicePlatform.Windows},{service:'Spooler',os:DevicePlatform.Windows},{service:'sshd',os:DevicePlatform.UNIX},{service:'Termservice',os:DevicePlatform.Windows},{service:'syslogd',os:DevicePlatform.UNIX},{service:'dhcpd',os:DevicePlatform.UNIX},{service:'crond',os:DevicePlatform.UNIX}];
    @observable customList = [{command:"wmic logicaldisk get FreeSpace /format:value",result:'FreeSpace=',os:DevicePlatform.Windows},{command:'cat /etc/hosts',result:'127.0.0.1',os:DevicePlatform.UNIX},{command:'ls /',result:'bin',os:DevicePlatform.UNIX},{command:'netdom verify',result:'has been verified',os:DevicePlatform.Windows}];
    @observable webPortalList = [];
    @observable databaseList = [{databaseType: 0 ,databaseServer: '',database: 'master',query: "SELECT @@VERSION AS 'SQL Server Version'",dbUsername: '',dbPassword: '',databasePort:'1433',dbWindowsAuth:false}];
    @observable rdDeviceNetworkList = [];
    @observable scriptList = [];
    @observable authenticationList = [];

    @observable PreviousTenantID =[];
    @observable PreviousTargetIPInfra =[];
    @observable PreviousSourceIPInfra=[];
    @observable PreviousSourceIPDataMover=[];
    @observable PreviousTargetIPDataMover=[];
    @observable targetUserNamesAndPass=[];
    @observable sourceUserNamesAndPass=[];
    @observable targetDataMoverUserNamesAndPass=[];
    @observable sourceDataMoverUserNamesAndPass=[];
    @observable accessCredentials=[];
    @observable linuxCredentails=[];
    @observable vCenterNetworks:Array<InfraNetworkDTO>=[];
    @observable vmwareApiSessionId='';
    @observable vmwareApiSessionIdSource='';
    
  
    @observable selectedLicense = new License();
    @observable selectedInfra = new InfrastructureDTO();
    @observable selectedDataMover = new DataMoverDTO();
    @observable selectedPrimaryUser = new AccessCredentials();
    @observable selectedSecondaryUser = new AccessCredentials();
    @observable selectedNetworkTupple = new NetworkTuppleDTO();
    @observable selectedEDR = new AssetDTO();
    @observable selectedEDRC = new AssetDTO();
    @observable selectedDC = new DomainControllerDTO();

    @observable previousEDR = new AssetDTO();
    @observable previousEDRC = new AssetDTO();
    @observable previousDC = new DomainControllerDTO();

    @observable addedNetworks=[];
    @observable isAuthenticated = false;
    @observable isAuthenticatedSource = false;
    @observable isDMAuthenticated = false;
    @observable isDMAuthenticatedSource = false;

    @observable LicenseKeys=[];
    @observable licensesList: Array<License>=[];
    @observable infrastructureList:Array<InfrastructureDTO> = [];
    @observable dataMoverList:Array<DataMoverDTO> = [];
    @observable networkTuppleList:Array<NetworkTuppleDTO> = [];
    @observable EDRs:Array<AssetDTO> = [];
    @observable EDRCs:Array<AssetDTO> = [];
    @observable DCs:Array<DomainControllerDTO> = [];

    @observable tempDataMover = new DataMoverDTO();
    @observable tempInfra = new InfrastructureDTO();
    @observable tempEDR = new AssetDTO();
    @observable tempEDRC = new AssetDTO();
    
    @observable anyInfra = false;
    @observable anyDM = true;
    @observable anyLic = true;
    @observable anyPrimaryUser = false;
    @observable anySecondaryUser = false;
    @observable anyNetworkTupples = false;
    @observable anyEDR = false;
    @observable anyEDRC = false;
    @observable anyDC = false;
    @observable CredsValidationInProgress = false;

    constructor(rootStore:RootStore,displayStore:DisplayScreenStore) {
        this.rootStore = rootStore;
        this.displayStore=displayStore;
        this.initializeJobSettings();
        // this.getLicenese();
    }

    get isFirstTrial(): boolean {
        return this.selectedLicense?.licenseType == LicenseType.FirstTrial;
    }

    get dcValidation() {
        return this._dcValidation;
    }

    @action
    updateDCValidation(dcValidationUpdate: Partial<{dcSelected: boolean, dcValid: boolean}>) {
        this._dcValidation = {...this.dcValidation, ...dcValidationUpdate};
    }
    @action updateInfraListAfterCreate(infras: Array<any>){
        this.infrastructureList = [];
        infras.map(infra => this.infrastructureList.push(infra as InfrastructureDTO))
        if(this.selectedInfra && this.selectedInfra != null)
            this.updateSelectedInfra(infras.find(i => i.id === this.selectedInfra.id))
    }

    @action initializeJobBaseData =()=>{
        JobAgent.CreateJobActions.GetBaseSettingsData(this.rootStore.createJobStore.baseSettings.dataMover,this.rootStore.createJobStore.baseSettings.infrastructureType).then(
            data => 
            {
                
               runInAction(() => {
                this.PreviousTenantID=[];
                this.PreviousTargetIPInfra=[];
                this.PreviousSourceIPInfra=[];
                this.PreviousSourceIPDataMover=[];
                this.PreviousTargetIPDataMover=[];
                this.sourceUserNamesAndPass=[];
                this.targetUserNamesAndPass=[];
                this.sourceDataMoverUserNamesAndPass=[];
                this.targetDataMoverUserNamesAndPass=[];
                
                this.PreviousTargetIPInfra = data.targetInfraNameIP;
                this.PreviousSourceIPInfra = data.sourceInfraNameIP;
                this.PreviousSourceIPDataMover = data.sourceDatamoverNameIP;
                this.PreviousTargetIPDataMover = data.targetDatamoverNameIP;
                data.targetInfraUserAndPass.map(item => this.targetUserNamesAndPass.push({username: item.userName,password: item.password}));
                data.sourceInfraUserAndPass.map(item => this.sourceUserNamesAndPass.push({username: item.userName,password: item.password}));
                data.sourceDataMoverUserAndPass.map(item => this.sourceDataMoverUserNamesAndPass.push({username: item.userName,password: item.password}));
                data.targetDataMoverUserAndPass.map(item => this.targetDataMoverUserNamesAndPass.push({username: item.userName,password: item.password}));
                switch(this.rootStore.createJobStore.baseSettings.dataMover)
                {
                    case DataMover.ZertoAzure :
                        {
                            this.PreviousTenantID = data.tenantID;
                            break;
                        }
                    case DataMover.PaaS:
                        {
                            this.PreviousTenantID = data.tenantID;
                            break;
                        }
                }
               })
            }
          );
    }
   
    @action updateDataMoverListAfterCreate(dataMovers: Array<any>)
    {
        this.dataMoverList = [];
        dataMovers.map(dm => this.dataMoverList.push(dm as DataMoverDTO))
        if(this.selectedDataMover && this.selectedDataMover != null)
            this.updateSelectedDataMover(dataMovers.find(i => i.id === this.selectedDataMover.id))
    }

    @action updateEditedInfra(infra: InfrastructureDTO)
    {
        var infraIndex = this.infrastructureList.findIndex(i => i.id == infra.id)
        if(infraIndex !== -1)
        {
            this.infrastructureList[infraIndex] = infra;
            this.selectedInfra = infra;
        }
    }

    @action updateTempInfra(infra: Partial<InfrastructureDTO>)
    {
        this.tempInfra = {...this.tempInfra,...infra};        
    }

    @action updateEditedDataMover(datamover: DataMoverDTO)
    {
        var dmIndex = this.dataMoverList.findIndex(i => i.id == datamover.id)
        if(dmIndex !== -1)
        {
            this.dataMoverList[dmIndex] = datamover;
            this.selectedDataMover = datamover;
        }
    }

    @action updateTempDataMover(dataMover: Partial<DataMoverDTO>)
    {
        this.tempDataMover = {...this.tempDataMover, ...dataMover};        
    }

    @action updateEditedEDRC(edrc: AssetDTO)
    {
        var edrcIndex = this.EDRCs.findIndex(i => i.id == edrc.id)
        if(edrcIndex !== -1)
        {
            this.EDRCs[edrcIndex] = edrc;
            this.selectedEDRC = edrc;
            this.tempEDRC = edrc;
        }
    }

    @action updateTempEDR(EDR: Partial<AssetDTO>)
    {
        this.tempEDR = {...this.tempEDR, ...EDR};  
    }

    @action updateTempEDRC(EDRC: Partial<AssetDTO>)
    {
        this.tempEDRC = {...this.tempEDRC, ...EDRC};  
    }

    @action updateSelectedLicense(license: License)
    {
        this.selectedLicense = license;        
    }

    @action updateSelectedInfra(infra: InfrastructureDTO)
    {
        this.selectedInfra = infra;        
    }

    @action updateSelectedNetworkTupple(networkTupple: NetworkTuppleDTO)
    {
        this.selectedNetworkTupple = networkTupple;        
    }

    @action updateSelectedEDR(edr: AssetDTO)
    {
        this.selectedEDR = edr;        
    }
    @action updatePreviousEDR(edr: AssetDTO)
    {
        this.previousEDR = edr;        
    }

    @action updateAnyEDR(res:boolean)
    {
        this.anyEDR =res;
    }
    @action updateAnyNetworkTupple(res:boolean)
    {
        this.anyNetworkTupples =res;
    }
    @action updateAnyEDRC(res:boolean)
    {
        this.anyEDRC =res;
    }
    @action updateSelectedEDRC(edrc: Partial<AssetDTO>)
    {
        this.selectedEDRC = {...this.selectedEDRC, ...edrc};  
    }

    
    @action updatePreviousEDRC(edrc: AssetDTO)
    {
        this.previousEDRC = edrc;        
    }

    @action updateSelectedDC(dc: DomainControllerDTO)
    {
        this.selectedDC= dc;        
    }

    @action updatePreviousDC(dc: DomainControllerDTO)
    {
        this.previousDC = dc;        
    }

    @action updateSelectedDataMover(dataMover: DataMoverDTO)
    {
        this.selectedDataMover = dataMover;        
    }

    @action updateSelectedPrimaryUser(username: string)
    {
        if(this.selectedPrimaryUser != null)
            this.selectedPrimaryUser.userName = username;        
    }

    @action updateSelectedPrimaryPassword(password: string)
    {
        if(this.selectedPrimaryUser != null)
            this.selectedPrimaryUser.password = password;        
    }

    @action updateSelectedPrimaryUserId(id: number)
    {
        if(this.selectedPrimaryUser != null)
            this.selectedPrimaryUser.id = id;        
    }

    @action updateSelectedSecondaryUser(username: string)
    {
        if(this.selectedSecondaryUser != null)
            this.selectedSecondaryUser.userName = username;        
    }

    @action updateSelectedSecondaryPassword(password: string)
    {
        if(this.selectedSecondaryUser != null)
            this.selectedSecondaryUser.password = password;        
    }

    @action updateSelectedSecondaryUserId(id: number)
    {
        if(this.selectedSecondaryUser != null)
            this.selectedSecondaryUser.id = id;        
    }

    @action setIsAutheticated(val:boolean)
    {
        this.isAuthenticated = val;
    }

    @action setIsAutheticatedSource(val:boolean)
    {
        this.isAuthenticatedSource = val;
    }
    
    @action setIsAutheticatedToFalse()
    {
        this.isAuthenticated = false;
        this.isAuthenticatedSource = false;
    }
    @action setIsDMAutheticated(val:boolean)
    {
        this.isDMAuthenticated = val;
    }

    @action setIsDMAutheticatedSource(val:boolean)
    {
        this.isDMAuthenticatedSource = val;
    }
    
    @action setIsDMAutheticatedToFalse()
    {
        this.isDMAuthenticated = false;
        this.isDMAuthenticatedSource = false;
    }

    @action initializeEmailsList =()=>{
        JobAgent.CreateJobActions.ListAllEmails().then(res => {
            runInAction(() => {
                if(res && res != null && res.length > 0)
                    res.map(email => this.emailList.push(email))
            })
            
        });
    }

    @action initializeJobSettings = () => {
        this.serviceToTest = this.defaultSettings.serviceToTest;
        this.serviceToTestLinux = this.defaultSettings.serviceToTestLinux;
        this.testFromEDRC = this.defaultSettings.testFromEDRC;
        this.customCommandToTest = this.defaultSettings.customCommandToTest;
        this.customCommandExpectedResult = this.defaultSettings.customCommandExpectedResult;
        this.customCommandToTestIsExternal = this.defaultSettings.customCommandToTestIsExternal;
        this.webPortalToTest = this.defaultSettings.webPortalToTest;
        this.webPortalResult = this.defaultSettings.webPortalResult;
        this.databaseType = this.defaultSettings.databaseType;
        this.databaseServerNameToTest = this.defaultSettings.databaseServerNameToTest;
        this.databaseNameToTest = this.defaultSettings.databaseNameToTest;
        this.databaseQueryToTest = this.defaultSettings.databaseQueryToTest;
        this.databaseUsername = this.defaultSettings.databaseUsername;
        this.databasePassword =  this.defaultSettings.databasePassword;
        this.databasePort = this.defaultSettings.databasePort;
        this.dbWindowsAuth = this.defaultSettings.dbWindowsAuth;
    }

    @action updateServiceSettings= (serviceToTest:string,testFromEDRC:boolean,serviceToTestLinux?:string)=>{
        this.serviceToTest = serviceToTest!== this.defaultSettings.serviceToTest ? serviceToTest: this.defaultSettings.serviceToTest ;
        this.testFromEDRC = testFromEDRC!== this.defaultSettings.testFromEDRC ? testFromEDRC: this.defaultSettings.testFromEDRC;
        if(serviceToTestLinux!=null)
            this.serviceToTestLinux = serviceToTestLinux!== this.defaultSettings.serviceToTestLinux ? serviceToTestLinux: this.defaultSettings.serviceToTestLinux ;
    }

    @action updateCustomSettings= (customCommandToTest:string,customCommandExpectedResult:string,customCommandToTestIsExternal:boolean,customCommandToTestLinux?:string,customCommandExpectedResultLinux?:string,os?:DevicePlatform)=>{
        this.customCommandToTest = customCommandToTest!== this.defaultSettings.customCommandToTest ? customCommandToTest: this.defaultSettings.customCommandToTest ;
        this.customCommandExpectedResult = customCommandExpectedResult!== this.defaultSettings.customCommandExpectedResult ? customCommandExpectedResult: this.defaultSettings.customCommandExpectedResult;
        if(customCommandToTestLinux!=null && customCommandExpectedResultLinux!=null){
        this.customCommandToTestLinux = customCommandToTestLinux!== this.defaultSettings.customCommandToTestLinux ? customCommandToTestLinux: this.defaultSettings.customCommandToTestLinux ;
        this.customCommandExpectedResultLinux = customCommandExpectedResultLinux!== this.defaultSettings.customCommandExpectedResultLinux ? customCommandExpectedResultLinux: this.defaultSettings.customCommandExpectedResultLinux;
        }
        this.customCommandToTestIsExternal= customCommandToTestIsExternal!== this.defaultSettings.customCommandToTestIsExternal ? customCommandToTestIsExternal: this.defaultSettings.customCommandToTestIsExternal;
        this.customCommandOS= os!== this.defaultSettings.customCommandOS ? os: this.defaultSettings.customCommandOS;
    }

    @action updateWebPortalSettings= (webPortalToTest:string,webPortalResult:string)=>{
        this.webPortalToTest = webPortalToTest!== this.defaultSettings.webPortalToTest ? webPortalToTest: this.defaultSettings.webPortalToTest ;
        this.webPortalResult = webPortalResult!== this.defaultSettings.webPortalResult ? webPortalResult: this.defaultSettings.webPortalResult ;
    }

    @action updateDatabaseSettings= (databaseType:number,databaseNameToTest:string,databaseQueryToTest:string,databaseServerNameToTest:string,databaseUsername:string,databasePassword:string,databasePort:string,dbWindowsAuth:boolean)=>{
        this.databaseType = databaseType!== this.defaultSettings.databaseType ? databaseType: this.defaultSettings.databaseType ;
        this.databaseServerNameToTest = databaseServerNameToTest!== this.defaultSettings.databaseServerNameToTest ? databaseServerNameToTest: this.defaultSettings.databaseServerNameToTest ;
        this.databaseNameToTest =databaseNameToTest!== this.defaultSettings.databaseNameToTest ? databaseNameToTest: this.defaultSettings.databaseNameToTest ;
        this.databaseQueryToTest =databaseQueryToTest!== this.defaultSettings.databaseQueryToTest ? databaseQueryToTest: this.defaultSettings.databaseQueryToTest ;
        this.databaseUsername =databaseUsername!== this.defaultSettings.databaseUsername ? databaseUsername: this.defaultSettings.databaseUsername ;
        this.databasePassword =databasePassword!== this.defaultSettings.databasePassword ? databasePassword: this.defaultSettings.databasePassword ;
        this.databasePort = databasePort!== this.defaultSettings.databasePort ? databasePort: this.defaultSettings.databasePort ;
        this.dbWindowsAuth = dbWindowsAuth!== this.defaultSettings.dbWindowsAuth ? dbWindowsAuth: this.defaultSettings.dbWindowsAuth ;
    }

    @action getVcenterNetworks=async (id:number)=>{
        this.vCenterNetworks = [];
        /* await runInAction(() => {
            jobAgent.CreateJobActions.ListInfraNetworks(id).then(res =>{
                runInAction(() => {
                    
                    res.map(item => this.vCenterNetworks.push(item as InfraNetworkDTO));
                })
            }).catch(
                err => {
                    toast.error(`Failed to list ${this.selectedInfra.infrastructureTarget} networks`);
                }
            )
        }) */
        
    }

    @action authenticateVCenter= async ()=>{
      
                this.displayStore.updateNextStepValid({infraValid:false})
                this.displayStore.updateScreenSettings({InfraApplyProcessing:true})
             return jobAgent.CreateJobActions.InfraAuthenticate(this.selectedInfra.id,false).then(res =>{
                        this.vmwareApiSessionId = res;
                        this.setIsAutheticated(true);
                        // toast.success(`Successfully autheticated to ${this.selectedInfra.infrastructureTarget}`);
                        this.displayStore.updateScreenSettings({InfraApplyProcessing:false})
                        this.displayStore.updateNextStepValid({infraValid:true});
                        return res;
                    }).catch(
                        err => {
                            this.setIsAutheticated(false);
                             // toast.error(`Failed to autheticate to ${this.selectedInfra.infrastructureTarget}`);
                            this.displayStore.updateScreenSettings({InfraApplyProcessing:false})
                            this.displayStore.updateNextStepValid({infraValid:false});
                            throw err;
                        }
                    )
            
            
        
       
    }

    @action authenticateVCenterSource= async ()=> {
      
                this.displayStore.updateNextStepValid({infraValid:false})
                this.displayStore.updateScreenSettings({InfraApplyProcessing:true})  
             return jobAgent.CreateJobActions.InfraAuthenticate(this.selectedInfra.id,true).then(res =>{
                    this.vmwareApiSessionIdSource = res;
                    this.setIsAutheticatedSource(true);
                    // toast.success(`Successfully autheticated to ${this.selectedInfra.infrastructureSource}`);
                    this.displayStore.updateScreenSettings({InfraApplyProcessing:false})  
                    this.displayStore.updateNextStepValid({infraValid:true});
                    return res;
                }).catch(
                    err => {
                        this.setIsAutheticatedSource(false);
                        // toast.error(`Failed to autheticate to ${this.selectedInfra.infrastructureSource}`);
                        this.displayStore.updateScreenSettings({InfraApplyProcessing:false})
                        this.displayStore.updateNextStepValid({infraValid:false})  
                        throw err;
                    }
                )
       
    }

    @action authenticateDataMover= async (dataMover:DataMoverDTO,isSource:boolean)=>{
     
              
               return jobAgent.CreateJobActions.DataMoverAuthenticate(this.rootStore.createJobStore.baseSettings.dataMover == DataMover.VeeamSQL ? 10 : this.selectedInfra.id,dataMover.id, isSource).then(res =>{
                        return res;
                    }).catch(
                        err => {
                            return false;
                        }
                    )
              
       
       
    }

    @action authenticateVCenterAfterAddOrUpdate= async (id: number)=>{
        this.vCenterNetworks = [];
        await jobAgent.CreateJobActions.InfraAuthenticate(id,false).then(res =>{
                toast.success(`Successfully autheticated to ${this.selectedInfra.infrastructureTarget}`);
            }).catch(
                err => {
                    toast.error(`Failed to autheticate to ${this.selectedInfra.infrastructureTarget}`);
                }
            )
        
    }

    @action authenticateVCenterSourceAfterAddOrUpdate= async(id: number)=>{
        await runInAction(() => {
            jobAgent.CreateJobActions.InfraAuthenticate(id,true).then(res =>{
                toast.success(`Successfully autheticated to ${this.selectedInfra.infrastructureSource}`);
            }).catch(
                err => {
                    toast.error(`Failed to autheticate to ${this.selectedInfra.infrastructureSource}`);
                }
            )
        })
    }

    @action reset = ()=>{
        this.serviceToTest = '';
        this.testFromEDRC = false;
        this.customCommandToTest = '';
        this.customCommandOS = DevicePlatform.Windows;
        this.customCommandExpectedResult = '';
        this.customCommandToTestIsExternal = false;
        this.webPortalToTest = '';
        this.webPortalResult = '';
        this.databaseType = 0;
        this.databaseServerNameToTest = '';
        this.databaseNameToTest = '';
        this.databaseQueryToTest = '';
        this.databaseUsername = '';
        this.databasePassword = '';
        this.databasePort = '';
        this.dbWindowsAuth =false;
        this.vCenterNetworks=[];
        this.vmwareApiSessionId='';
        this.emailList=[];
        this.isAuthenticatedSource = false;
        this.isAuthenticated = false;
        this.initializeJobSettings();
        this.tempInfra=new InfrastructureDTO();
        this.tempDataMover=new DataMoverDTO();
        this.resetAssets();
        this.setIsDMAutheticatedToFalse();
        this.setIsAutheticatedToFalse();
        this.displayStore.updateNextStepValid({dmValid:false,infraValid:false,primaryValid:false})
    }

    //Asset section
    @action
    resetAssets = async() => {
        this.DCs = [];
        this.EDRCs = [];
        this.EDRs = [];
    }
    
    @action
    testDomainController =(dc:DomainControllerDTO)=>{
        AssetAgent.TestDomainController({domainCredsId:dc.domainCredentials.id,
            domainName:dc.domainName,
            username:dc.domainCredentials.userName,
            password:dc.domainCredentials.password,
            domainControllerName:dc.domainControllerName}).then(res => {
            if(res)
            {
               toast.success(dc.domainControllerName + ': ' + "Domain Controller Tested and Applied successfully.")
               this.displayStore.updateNextStepValid({dcAdvancedValid:true})
            }
            else
            {
                toast.error(dc.domainControllerName + ': ' + "Domain Controller Validation Failed - check access to the domain controller")
                this.displayStore.updateScreenSettings({DCApplyProcessing:false});
                this.displayStore.updateNextStepValid({dcAdvancedValid:true})
            }
            }).catch(err => {
                toast.error(dc.domainControllerName + ': ' + "Domain Controller Validation Failed - check access to the domain controller")
                this.displayStore.updateScreenSettings({DCApplyProcessing:false});
                this.displayStore.updateNextStepValid({dcAdvancedValid:true})
            });
    }

    @action
    getAssets = async() => {
        try {
        var assets = await AssetAgent.ListJobAssets(this.rootStore.createJobStore.baseSettings.workflowTest.PrimaryUserCredsId)
        runInAction(
            () => {
                this.EDRCs = [];
                this.EDRs = [];
                this.DCs = [];
                let IHaveDC = {id: 0, domainControllerName: 'I have a Domain Controller inside my replication',dcHostServerSource: '',dcHostServerTarget: '',dcDataStoreTarget:'',domainName: '',domainControllerTargetVLAN: '',domainControllerESXCredentials:null,domainCredentials:null, domainCredentialsId:0,domainControllerESXCredentialsId:0}
                if(!this.DCs.find(item=>item.domainControllerName == 'I have a Domain Controller inside my replication'))
                    this.DCs.push(IHaveDC);
                assets.edRs.map(a => this.EDRs.push(a as AssetDTO));
                assets.edrCs.map(a => this.EDRCs.push(a as AssetDTO));
                assets.dCs.map(a => this.DCs.push(a as DomainControllerDTO));
                this.anyEDR =assets.edRs.length >0;
                this.anyEDRC =assets.edrCs.length >0;
                this.anyDC =assets.dCs.length >0;
                
                if(this.rootStore.createJobStore.networkSetting.domainController?.id)
                {
                    if(this.rootStore.createJobStore.networkSetting.domainController.id != 0)
                    {
                        let valueDC = assets.dCs.find(a => a.id == this.rootStore.createJobStore.networkSetting.domainController.id)
                        this.updateSelectedDC(valueDC ? valueDC:null);
                        this.rootStore.createJobStore.updateNetworkSettings({ domainController: valueDC ? valueDC : null });
                        this.testDomainController(valueDC);
                    }
                    else
                    {
                        this.updateSelectedDC(IHaveDC);
                        this.rootStore.createJobStore.updateNetworkSettings({ domainController: IHaveDC });
                        this.rootStore.displayStore.updateNextStepValid({dcAdvancedValid:true});
                    }
                }
                else
                {
                    this.updateSelectedDC(IHaveDC);
                    this.rootStore.createJobStore.updateNetworkSettings({ domainController: IHaveDC });
                    this.rootStore.displayStore.updateNextStepValid({dcAdvancedValid:true});
                }
            }
        )
         } catch {

         }finally {
            this.displayStore.updateScreenSettings({EDRApplyProcessing:false,EDRCApplyProcessing:false,DCApplyProcessing:false});
            

            if(this.rootStore.createJobStore.networkSetting.NetworkType === NetworkOption.None)
            {
                 this.displayStore.updateNetworkSectionSettings({DisableSection: true});
                 this.displayStore.updateNetworkSectionSettings({DisableButtonDC:true});
                 this.displayStore.updateNetworkSectionSettings({DisableButtonEDRC:true});
            } 
         }
    }
    @action
    editAsset = async(instance:AssetType,asset?:AssetDTO)=>{
        switch(instance)
        {
            case AssetType.DC :
            {
                 this.displayStore.updateScreenSettings({DCApplyProcessing:true});
                    AssetAgent.TestDomainController({domainCredsId: this.rootStore.createJobStore.networkSetting.domainController.domainCredentials.id,
                    domainName:this.rootStore.createJobStore.networkSetting.domainController.domainName,
                    username:this.rootStore.createJobStore.networkSetting.domainController.domainCredentials.userName,
                    password:this.rootStore.createJobStore.networkSetting.domainController.domainCredentials.password,
                    domainControllerName:this.rootStore.createJobStore.networkSetting.domainController.domainControllerName}).then(res => {
                    if(res === true)
                    {
                        AssetAgent.UpdateDC(this.rootStore.createJobStore.networkSetting.domainController).then(
                            assets =>
                            {
                                toast.success(this.rootStore.createJobStore.networkSetting.domainController.domainControllerName + ': ' + "Domain Controller Tested and Applied successfully.")
                                runInAction(() => {
                                    this.DCs = [];
                                    let IHaveDC = {id: 0, domainControllerName: 'I have a Domain Controller inside my replication',dcHostServerSource: '',dcHostServerTarget: '',dcDataStoreTarget:'',domainName: '',domainControllerTargetVLAN: '',domainControllerESXCredentials:null,domainCredentials:null, domainCredentialsId:0,domainControllerESXCredentialsId:0}
                                    if(!this.DCs.find(item=>item.domainControllerName == 'I have a Domain Controller inside my replication'))
                                        this.DCs.push(IHaveDC);
                                    assets.data.dCs.map(a => this.DCs.push(a as DomainControllerDTO));
                                    this.anyDC =assets.data.dCs.length >0;
                                    if(this.rootStore.createJobStore.networkSetting.domainController != null)
                                    {
                                        if(this.rootStore.createJobStore.networkSetting.domainController.id != 0)
                                        {
                                            let valueDC = assets.data.dCs.find(a => a.id == this.rootStore.createJobStore.networkSetting.domainController.id)
                                            this.updateSelectedDC(valueDC ? valueDC:null);
                                            this.rootStore.createJobStore.updateNetworkSettings({ domainController: valueDC ? valueDC : null });
                                            this.displayStore.updateNextStepValid({dcAdvancedValid:true})
                                        }
                                        else
                                        {
                                            this.updateSelectedDC(this.selectedDC);
                                            this.rootStore.createJobStore.updateNetworkSettings({ domainController:this.selectedDC});
                                            this.displayStore.updateNextStepValid({dcAdvancedValid:true})
                                        }
                                    }
                                    else
                                    {
                                        if(assets.data.dCs.length > 0)
                                        {
                                            this.updateSelectedDC(assets.data.dCs[0]);
                                            this.rootStore.createJobStore.updateNetworkSettings({ domainController: assets.data.dCs[0] });
                                            this.displayStore.updateNextStepValid({dcAdvancedValid:true})
                                        }
                                        else
                                        {
                                            this.updateSelectedDC(IHaveDC);
                                            this.rootStore.createJobStore.updateNetworkSettings({ domainController: this.DCs[0] });
                                            this.displayStore.updateNextStepValid({dcAdvancedValid:true})
                                        }
                                    
                                    }
                                })
                                if(this.displayStore.NetworkDrawerSettings.cloneDrawerOpen)
                                {
                                    this.displayStore.updateScreenSettings({ displayNetwork: 'EmptyVlan' });
                                    this.displayStore.updateNetworkDrawerSettings({cloneDrawerOpen:false});
                                }
                                this.displayStore.updateScreenSettings({DCApplyProcessing:false});
                            }
                            ).catch(
                            err => 
                            {
                                toast.error(this.rootStore.createJobStore.networkSetting.domainController.domainControllerName + ': ' + "Domain Controller Validation Failed - check access to the domain controller")
                                this.displayStore.updateScreenSettings({DCApplyProcessing:false});
                                this.displayStore.updateNextStepValid({dcAdvancedValid:false})
                            })
                    }
                    else
                    {
                        toast.error(this.rootStore.createJobStore.networkSetting.domainController.domainControllerName + ': ' + "Domain Controller Validation Failed - check access to the domain controller")
                        this.displayStore.updateScreenSettings({DCApplyProcessing:false});
                        this.displayStore.updateNextStepValid({dcAdvancedValid:false})
                    }
                    }).catch(err => {
                        toast.error(this.rootStore.createJobStore.networkSetting.domainController.domainControllerName + ': ' + "Domain Controller Validation Failed - check access to the domain controller")
                        this.displayStore.updateScreenSettings({DCApplyProcessing:false});
                        this.displayStore.updateNextStepValid({dcAdvancedValid:false})
                    });
                   
                    break;
            }
            case AssetType.EDRC :
            {
                this.displayStore.updateScreenSettings({EDRCApplyProcessing:true});
                await AssetAgent.UpdateEDRC({id:asset.id,VMName:asset.assetVMName,username:asset.credentials.userName,password:asset.credentials.password}).then(res => {
                    this.updateEditedEDRC(res.data);
                    this.rootStore.createJobStore.updateNetworkSettings({ EnsureDRController: res.data });
                    toast.success(this.selectedEDRC.assetName + ' ' + "was updated successfully");
                    this.displayStore.updateScreenSettings({EDRCApplyProcessing:false});
                    this.displayStore.updateNextStepValid({edrcValid:true})
                    if(this.displayStore.NetworkDrawerSettings.controllerDrawerOpen)
                    {
                        this.displayStore.updateScreenSettings({ displayNetwork: 'EmptyVlan' });
                        this.displayStore.updateNetworkDrawerSettings({controllerDrawerOpen:false});
                    }
                }).catch(err => {
                    toast.error("Failed to update EDRC for" + ' ' + asset.assetVMName + " : "+err.data.errors);
                    this.displayStore.updateScreenSettings({EDRCApplyProcessing:false});
                    this.displayStore.updateNextStepValid({edrcValid:false})
                })
                if(this.displayStore.NetworkDrawerSettings.controllerDrawerOpen)
                {
                    this.displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
                    this.displayStore.updateNetworkDrawerSettings({controllerDrawerOpen: false});
                }
                break;                
            }
        }
    }
    @action
    createDomainController = async()=>{
        await AssetAgent.TestDomainController({domainCredsId: this.rootStore.createJobStore.networkSetting.domainController.domainCredentials.id, domainName:this.rootStore.createJobStore.networkSetting.domainController.domainName,username:this.rootStore.createJobStore.networkSetting.domainController.domainCredentials.userName,password:this.rootStore.createJobStore.networkSetting.domainController.domainCredentials.password,domainControllerName:this.rootStore.createJobStore.networkSetting.domainController.domainControllerName}).then(res => {
                if(res === true)
                {
                    AssetAgent.AddNewDC(this.rootStore.createJobStore.networkSetting.domainController).then(
                        assets =>
                        {
                            runInAction(()=> {
                                toast.success("Domain Controller was added successfully");
                                this.DCs = [];
                                let IHaveDC = {id: 0, domainControllerName: 'I have a Domain Controller inside my replication',dcHostServerSource: '',dcHostServerTarget: '',dcDataStoreTarget:'',domainName: '',domainControllerTargetVLAN: '',domainControllerESXCredentials:null,domainCredentials:null, domainCredentialsId:0,domainControllerESXCredentialsId:0}
                                if(!this.DCs.find(item=>item.domainControllerName == 'I have a Domain Controller inside my replication'))
                                    this.DCs.push(IHaveDC);
                                assets.data.dCs.map(a => this.DCs.push(a as DomainControllerDTO));
                                this.anyDC =assets.data.dCs.length > 0;
                                let valueDC = assets.data.dCs.find(a => a.id == assets.data.addedId)
                                this.updateSelectedDC(valueDC);
                                this.rootStore.createJobStore.updateNetworkSettings({ domainController: valueDC });
                                this.displayStore.updateNextStepValid({dcAdvancedValid:true})
                            })
                            if(this.displayStore.NetworkDrawerSettings.cloneDrawerOpen)
                            {
                                this.displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
                                this.displayStore.updateNetworkDrawerSettings({cloneDrawerOpen: false});
                            } 
                        }
                ).catch(
                        err => 
                        {
                            if(err && err.data && err.data.errors)
                            {
                                this.updateSelectedDC(this.previousDC); 
                                this.displayStore.updateNextStepValid({dcAdvancedValid:this.previousDC.domainControllerName.includes("I have a Domain Controller inside my replication")? true:false})
                                toast.error(this.rootStore.createJobStore.networkSetting.domainController.domainControllerName + ': ' + "Failed to add new Domain Controller: " + err.data.errors)
                            }
                        }
                    )
                }
                else
                {
                    toast.error(this.rootStore.createJobStore.networkSetting.domainController.domainControllerName + ': ' + "Domain Controller test failed, please verify it is available and credentials are correct.")
                    this.updateSelectedDC(this.previousDC); 
                    this.displayStore.updateNextStepValid({dcAdvancedValid:this.previousDC.domainControllerName.includes("I have a Domain Controller inside my replication")? true:false})
                }
            }).catch(err => {
                toast.error(this.rootStore.createJobStore.networkSetting.domainController.domainControllerName + ': ' + "Domain Controller test failed, please verify it is available and credentials are correct." + err)
                this.updateSelectedDC(this.previousDC); 
                this.displayStore.updateNextStepValid({dcAdvancedValid:this.previousDC.domainControllerName.includes("I have a Domain Controller inside my replication")? true:false})
            });
            if(this.displayStore.NetworkDrawerSettings.cloneDrawerOpen)
            {
                this.displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
                this.displayStore.updateNetworkDrawerSettings({cloneDrawerOpen: false});
            }
    }
    @action
    validateEDRC = (asset:AssetDTO) => {
        this.displayStore.updateScreenSettings({EDRCApplyProcessing:true});
        this.displayStore.updateNextStepValid({edrcValid:false})
        AssetAgent.ValidateEDRC({id:asset.id,assetName:asset.assetName,assetVMName:asset.assetVMName,infraId:this.selectedInfra.id}).then(res => {
            if(res.data.isValid)
            {
                toast.success(`${asset.assetName} Validation passed`)
                this.displayStore.updateScreenSettings({EDRCApplyProcessing:false});
                this.displayStore.updateNextStepValid({edrcValid:true});
            }
            else
            {
                let errors = []
                res.data.failedValidations.map(err => {errors.push(this.getErrorText(err))})
                toast.error(`EDRC Validation Failed - ${errors.join(", ")}`)
                this.displayStore.updateScreenSettings({EDRCApplyProcessing:false});
                this.displayStore.updateNextStepValid({edrcValid:false});
            }
        }).catch(err => {
            if(err && err.data && err.data.errors)
            {
                toast.error(`${asset.assetName} Validation failed - ${err.data.errors}`)
            }
            this.displayStore.updateScreenSettings({EDRCApplyProcessing:false});
            this.displayStore.updateNextStepValid({edrcValid:false});
                
        })
    }
    @action
    validateEDR = (asset:AssetDTO,showToast:boolean) => {
        if(!asset)
            return;
        this.displayStore.updateScreenSettings({EDRValidationInProgress:true});
        this.displayStore.updateNextStepValid({edrValid:false})
        AssetAgent.ValidateEDR({id:asset.id,assetName:asset.assetName}).then(res => {
            if(res.data == true)
            {
                if(showToast)
                {
                    toast.success(`${asset.assetName} Validation passed`);
                }
                this.displayStore.updateScreenSettings({EDRValidationInProgress:false});
                this.displayStore.updateNextStepValid({edrValid:true});
            }
            else
            {
                toast.error(`EDR Validation Failed - cannot access EDR service on ${asset.assetName}`)
                this.displayStore.updateScreenSettings({EDRValidationInProgress:false});
                this.displayStore.updateNextStepValid({edrValid:false});
            }
        }).catch(err => {
            if(err && err.data && err.data.errors)
            {
                toast.error(`${asset.assetName} Validation failed - ${err.data.errors}`)
            }
            this.displayStore.updateScreenSettings({EDRValidationInProgress:false});
            this.displayStore.updateNextStepValid({edrValid:false});
                
        })
    }
    getErrorText = (err:string) => {
        if(err === "NoNetworkConnection")
            return "No Network Connection to EDRC";
        if(err === "EDRCVMNameMissing")
            return "EDRC VM Name is missing";
        if(err === "FailedToConnectInfra")
            return "Failed to connect to EDRC Infra";
        if(err === "EDRCNotFoundOnTargetInfra")
            return "EDRC VM not found on traget infra";
    }
    @action
    createAsset = (asset:AssetDTO,assetType:AssetType)=>{
        if(this.rootStore.createJobStore.networkSetting.NetworkType == NetworkOption.None && (assetType == AssetType.EDRC || assetType == AssetType.DC))
        {
            toast.error('Cannot add this asset while in "None" network mode');
            return;
        }
        if(assetType == AssetType.EDR)
        {
            this.displayStore.updateScreenSettings({EDRApplyProcessing:true});
            AssetAgent.ValidateEDR({assetName:asset.assetName, id:0}).then(res => {
                if(res.data === true)
                {
                    AssetAgent.AddNewAsset({assetName:asset.assetName, assetType:assetType, assetVMName:asset.assetVMName})
                    .then(
                        assets =>
                        {
                            runInAction (()=>{
                                this.EDRs = assets.data.edRs;
                                let value = assets.data.edRs.find(a => {
                                    return a.assetName === asset.assetName;
                                });
                                this.updateSelectedEDR(value);  
                                this.rootStore.createJobStore.updateNetworkSettings({ EDRServer: value });
                                this.displayStore.updateNetworkSectionSettings({DisableButtonEDR :false})
                                toast.success(asset.assetName + ': ' +"EnsureDR Server validation passed and added successfully to job. ");
                                this.displayStore.updateScreenSettings({changeEDR:false});
                                this.displayStore.updateScreenSettings({EDRApplyProcessing:false});
                                this.displayStore.updateNextStepValid({edrValid:true});
                            })
                        }
                    ).catch(err => 
                        {
                            toast.error("failed to create EDR Server :" +asset.assetName + err.data);
                            this.displayStore.updateScreenSettings({EDRApplyProcessing:false});
                            this.updateSelectedEDR(this.previousEDR); 
                            this.displayStore.updateNextStepValid({edrValid:this.previousEDR!=null ? true:false});
                            if(this.displayStore.NetworkDrawerSettings.edrDrawerOpen)
                            {
                                this.displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
                                this.displayStore.updateNetworkDrawerSettings({edrDrawerOpen: false});
                            }
                            
                        }
                    )
                    .finally(() => {
                        this.displayStore.updateScreenSettings({AddModeEDR: false});
                        this.displayStore.updateScreenSettings({EDRApplyProcessing:false});
                        if(this.displayStore.NetworkDrawerSettings.edrDrawerOpen)
                        {
                            this.displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
                            this.displayStore.updateNetworkDrawerSettings({edrDrawerOpen: false});
                        }
                    })
                }
                else
                {
                    toast.error(asset.assetName + ': ' + "EnsureDR Server test failed, please verify it is available and all services are running. ");
                    this.displayStore.updateScreenSettings({EDRApplyProcessing:false});
                    this.updateSelectedEDR(this.previousEDR); 
                    this.displayStore.updateNextStepValid({edrValid:this.previousEDR!=null ?true:false});
                    if(this.displayStore.NetworkDrawerSettings.edrDrawerOpen)
                    {
                        this.displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
                        this.displayStore.updateNetworkDrawerSettings({edrDrawerOpen: false});
                    }
                }
                }).catch(err => {
                    toast.error(asset.assetName + ': ' + "EnsureDR Server test failed, please verify it is available and all services are running. ");
                    this.displayStore.updateScreenSettings({EDRApplyProcessing:false});
                    this.updateSelectedEDR(this.previousEDR); 
                    this.displayStore.updateNextStepValid({edrcValid:this.previousEDR!=null ?true:false});
                    if(this.displayStore.NetworkDrawerSettings.edrDrawerOpen)
                    {
                        this.displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
                        this.displayStore.updateNetworkDrawerSettings({edrDrawerOpen: false});
                    }
                });
            }
        else
        {
            this.displayStore.updateScreenSettings({EDRCApplyProcessing:true});
            AssetAgent.ValidateEDRC({id:0,assetName:asset.assetName,assetVMName:asset.assetVMName,infraId:this.selectedInfra.id}).then(res => {
                if(res.data.isValid === true)
                {
                    AssetAgent.AddNewAsset({assetName:asset.assetName, assetType:assetType, assetVMName:asset.assetVMName})
                    .then(
                        assets =>
                        {
                            runInAction (()=>{
                                this.EDRCs = assets.data.edrCs;
                                let val = assets.data.edrCs.find(a=>{ 
                                    return a.assetName === asset.assetName;
                                });
                                this.updateSelectedEDRC(val); 
                                this.rootStore.createJobStore.updateNetworkSettings({ EnsureDRController: val });
                                this.displayStore.updateNetworkSectionSettings({DisableButtonEDRC :false})
                                toast.success(asset.assetName + ': ' + "EnsureDR Controller added successfully to job." )
                                this.displayStore.updateScreenSettings({EDRCApplyProcessing:false});
                                this.displayStore.updateScreenSettings({AddModeEDRC: false});
                                this.displayStore.updateNextStepValid({edrcValid:true});
                            })
                        }
                    ).catch(err => 
                        {
                            toast.error(asset.assetName + ': ' + "EnsureDR controller test failed, please verify it is available, all services are running and credentials are correct. ")
                            this.displayStore.updateScreenSettings({EDRCApplyProcessing:false});
                            this.updateSelectedEDRC(this.previousEDRC); 
                            this.displayStore.updateNextStepValid({edrcValid:this.previousEDRC!=null ? true:false});
                            if(this.displayStore.NetworkDrawerSettings.controllerDrawerOpen)
                            {
                                this.displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
                                this.displayStore.updateNetworkDrawerSettings({controllerDrawerOpen: false});
                            }
                            
                        }
                    )
                    .finally(() => {
                        this.displayStore.updateScreenSettings({EDRCApplyProcessing:false});
                        if(this.displayStore.NetworkDrawerSettings.controllerDrawerOpen)
                        {
                            this.displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
                            this.displayStore.updateNetworkDrawerSettings({controllerDrawerOpen: false});
                        }
                    })
                }
                else
                {
                    let errors = []
                    res.data.failedValidations.map(err => {errors.push(this.getErrorText(err))})
                    toast.error(`EDRC Validation Failed - ${errors.join(", ")}`)
                    this.displayStore.updateScreenSettings({EDRCApplyProcessing:false});
                    this.displayStore.updateNextStepValid({edrcValid:this.previousEDRC!=null ?true:false});
                    this.updateSelectedEDRC(this.previousEDRC); 
                    if(this.displayStore.NetworkDrawerSettings.controllerDrawerOpen)
                    {
                        this.displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
                        this.displayStore.updateNetworkDrawerSettings({controllerDrawerOpen: false});
                    }
                }
                }).catch(err => {
                    toast.error(asset.assetName + ': ' + "EnsureDR controller test failed, please verify it is available, all services are running and credentials are correct. ")
                    this.displayStore.updateScreenSettings({EDRCApplyProcessing:false});
                    this.displayStore.updateScreenSettings({AddModeEDRC: true});
                    this.updateSelectedEDRC(this.previousEDRC); 
                    this.displayStore.updateNextStepValid({edrcValid:this.previousEDRC!=null ?true:false});
                });
            }
        }

    @action 
    deleteAsset = async(asset:AssetType)=>{
            switch(asset)
            {
                case AssetType.EDR:
                    {
                            this.displayStore.updateScreenSettings({EDRApplyProcessing:true});
                            await AssetAgent.DeleteAsset(this.selectedEDR.id).then(res=>{
                                runInAction(() => {
                                toast.success("EDR was deleted successfully");
                                this.EDRs = [];
                                res.data.edRs.map(a => this.EDRs.push(a as AssetDTO));
                                this.anyEDR =res.data.edRs.length >0;
                                if(res.data.edRs.length >0)
                                {
                                    this.updateSelectedEDR(res.data.edRs[0]);
                                    this.rootStore.createJobStore.updateNetworkSettings({ EDRServer: res.data.edRs[0] });
                                    this.displayStore.updateNextStepValid({edrValid:true});
                                }
                                else
                                {
                                    this.updateSelectedEDR(null);
                                    this.rootStore.createJobStore.updateNetworkSettings({ EDRServer: null });
                                    this.displayStore.updateNextStepValid({edrValid:false});
                                }
                                if(this.displayStore.NetworkDrawerSettings.edrDrawerOpen)
                                {
                                    this.displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
                                    this.displayStore.updateNetworkDrawerSettings({edrDrawerOpen: false});
                                }
                            })
                            })
                            .catch(err=>{
                                toast.error(err.data.errors);
                                this.displayStore.updateNextStepValid({edrValid:false});
                            })
                            this.displayStore.updateScreenSettings({EDRApplyProcessing:false});
                        break;
                    }
                case AssetType.EDRC:
                    {
                            this.displayStore.updateScreenSettings({EDRCApplyProcessing:true});
                            await AssetAgent.DeleteAsset(this.selectedEDRC.id).then(res=>{
                                runInAction(() => {
                                toast.success("EDRC was deleted successfully");
                                this.EDRCs = [];
                                res.data.edrCs.map(a => this.EDRCs.push(a as AssetDTO));
                                this.anyEDRC =res.data.edrCs.length >0;
                                if(res.data.edrCs.length >0)
                                {
                                    this.updateSelectedEDRC(res.data.edrCs[0]);
                                    this.rootStore.createJobStore.updateNetworkSettings({ EnsureDRController: res.data.edrCs[0] });
                                    this.displayStore.updateNextStepValid({edrcValid:true});
                                }
                                else
                                {
                                    this.displayStore.updateNextStepValid({edrcValid:false});
                                    this.updateSelectedEDRC(null);
                                    this.rootStore.createJobStore.updateNetworkSettings({ EnsureDRController: null });
                                }
                                if(this.displayStore.NetworkDrawerSettings.controllerDrawerOpen)
                                {
                                    this.displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
                                    this.displayStore.updateNetworkDrawerSettings({controllerDrawerOpen: false});
                                }
                            })
                        })
                            .catch(err=>{
                                if(err && err.data && err.data.errors)
                                    toast.error(err.data.errors);
                                    this.displayStore.updateNextStepValid({edrcValid:false});
                            })
                            this.displayStore.updateScreenSettings({EDRCApplyProcessing:false});
                        break;
                    }
                case AssetType.DC:
                    {
                            this.displayStore.updateScreenSettings({DCApplyProcessing:true});
                            await AssetAgent.DeleteDC(this.rootStore.createJobStore.networkSetting.domainController.id).then(res=>{
                            runInAction(() => {
                                toast.success("DC was deleted successfully");
                                this.DCs = [];
                                let IHaveDC = {id: 0, domainControllerName: 'I have a Domain Controller inside my replication',dcHostServerSource: '',dcHostServerTarget: '',dcDataStoreTarget:'',domainName: '',domainControllerTargetVLAN: '',domainControllerESXCredentials:null,domainCredentials:null, domainCredentialsId:0,domainControllerESXCredentialsId:0}
                                if(!this.DCs.find(item=>item.domainControllerName == 'I have a Domain Controller inside my replication'))
                                    this.DCs.push(IHaveDC);
                                res.data.dCs.map(a => this.DCs.push(a as DomainControllerDTO));
                                this.anyDC =res.data.dCs.length >0;
                                if(res.data.dCs.length > 0)
                                {
                                    this.updateSelectedDC(res.data.dCs[0]);
                                    this.rootStore.createJobStore.updateNetworkSettings({ domainController: res.data.dCs[0] });
                                    this.displayStore.updateNextStepValid({dcAdvancedValid:true});
                                }
                                else
                                {
                                    this.updateSelectedDC(IHaveDC);
                                    this.rootStore.createJobStore.updateNetworkSettings({ domainController: IHaveDC });
                                    this.displayStore.updateNextStepValid({dcAdvancedValid:true});
                                }
                                })
                                if(this.displayStore.NetworkDrawerSettings.cloneDrawerOpen)
                                {
                                    this.displayStore.updateScreenSettings({ displayNetwork: 'EmptyVlan' });
                                    this.displayStore.updateNetworkDrawerSettings({cloneDrawerOpen:false});
                                }

                            }).catch(err=>{
                                if(err && err.data && err.data.errors)
                                    toast.error(err.data.errors);
                                    this.displayStore.updateNextStepValid({dcAdvancedValid:false});
                            })
                            this.displayStore.updateScreenSettings({DCApplyProcessing:false});
                        break;
                    }
                }
    }

    //license section
    @action 
    getLicenese = async()=>{
        this.displayStore.updateScreenSettings({LicenseApplyProcessing:true});
        await JobAgent.JobActions.getLicenses().then(res => 
        {
            runInAction(() => {

                this.licensesList = [...res.licenses];
                this.LicenseKeys= res.licenses.map(license => license.name)
                if(this.licensesList.length > 0 && this.rootStore.createJobStore.baseSettings.licenseId === 0)
                {
                    if(res.licenseLastSelectedId != 0)
                    {
                        let lic = res.licenses.find(l => l.id == res.licenseLastSelectedId)
                        if(lic!=undefined)
                        {
                        this.rootStore.createJobStore.updateBaseSettings({licenseName: lic.name, licenseId: lic.id});
                        this.updateSelectedLicense(lic);
                        }
                    }

                }
                else if(this.licensesList.length === 0)
                {
                    this.updateSelectedLicense(null);
                    this.displayStore.updateNextStepValid({licenseValid:false});  
                }
                else
                {
                    let lic = this.licensesList.find(l => l.id == this.rootStore.createJobStore.baseSettings.licenseId);
                    if(lic)
                    {
                        this.updateSelectedLicense(lic);
                        this.rootStore.createJobStore.updateBaseSettings({licenseName: lic.name, licenseId: lic.id});   
                        this.displayStore.updateNextStepValid({licenseValid:true}); 
                    }
                    
                }
                
            })
            this.displayStore.updateScreenSettings({LicenseApplyProcessing:false});
        });
        this.displayStore.updateScreenSettings({LicenseApplyProcessing:false});
        return this.licensesList;
    }
    @action
    editLicense = async(licenseDetails:LicenseDTO)=>{

        try {
           const res = await  jobAgent.JobActions.changeLicName(licenseDetails);
                this.getLicenese();
                this.updateSelectedLicense(res);
                this.rootStore.createJobStore.updateBaseSettings({licenseId: res.id,licenseName:res.name});
                this.displayStore.updateNextStepValid({licenseValid:true});
                toast.success("License name was updated successfully");
                return res;

        } catch(err) {
            toast.error("failed to update license name"+ err);
            this.displayStore.updateNextStepValid({licenseValid:false});
            throw err;

        }
    }
    @action 
    deleteLicense = async()=>{
        this.displayStore.updateScreenSettings({LicenseApplyProcessing:true});
       return await jobAgent.JobActions.deleteLicense(this.selectedLicense.id).then(res=>{
            toast.success("License was deleted successfully");
           
                    runInAction(() => {
                        this.licensesList = [];
                        this.LicenseKeys = [];
                        res.licenses.map(license => this.licensesList.push(license as License))
                        res.licenses.map(license => this.LicenseKeys.push(license.name))
                        if(this.licensesList.length > 0 && this.rootStore.createJobStore.baseSettings.licenseId === 0)
                        {
                            this.rootStore.createJobStore.updateBaseSettings({licenseName: this.licensesList[0].name, licenseId: this.licensesList[0].id});
                            this.selectedLicense = this.licensesList[0];   
                            this.displayStore.updateNextStepValid({licenseValid:true});
                        }
                        else if(this.licensesList.length === 0)
                        {
                            this.selectedLicense = null;
                            this.displayStore.updateNextStepValid({licenseValid:false});
                        }
                        else
                        {
                            this.selectedLicense = this.licensesList.find(l => l.id == this.rootStore.createJobStore.baseSettings.licenseId);
                            this.displayStore.updateNextStepValid({licenseValid:true});
                        }
                        this.anyLic=this.licensesList.length>0;
                    })
                    this.displayStore.updateScreenSettings({LicenseApplyProcessing:false});

                if(this.displayStore.BaseDrawerSettings.LicenseDrawerOpen)
                {
                  this.displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
                  this.displayStore.updateBaseDrawerSettings({LicenseDrawerOpen: false});
                }  
        })
        .catch(err=>{
            toast.error(err.data.errors);
            this.displayStore.updateNextStepValid({licenseValid:false});
            this.displayStore.updateScreenSettings({LicenseApplyProcessing:false});
            throw err;
        })
        // this.displayStore.updateScreenSettings({LicenseApplyProcessing:false});
    }
    @action
    createLicense = async(licenseDetails:LicenseDTO)=>{
        try {
           const res = await jobAgent.JobActions.addLicense(licenseDetails);
           this.getLicenese();
           this.updateSelectedLicense(res);
           this.rootStore.createJobStore.updateBaseSettings({licenseId: res.id,licenseName:res.name});
           this.displayStore.updateNextStepValid({licenseValid:true});
           this.displayStore.updateScreenSettings({addedLicense:true});
           toast.success("License was added successfully");
           return res;
        } catch(err) {
            toast.error("Wrong License. Please use a valid license from EnsureDR and try again");
            this.displayStore.updateNextStepValid({licenseValid:false});
            throw err;
        }
    }

    //Credentials section
    @action 
    getCredentials = async()=>{
        this.displayStore.updateScreenSettings({PrimaryUserApplyProcessing:true,SecondaryUserApplyProcessing:true});
        await JobAgent.CreateJobActions.GetCredentials().then(
            data => 
            {
               runInAction(() => {
                    this.accessCredentials = [];
                    this.linuxCredentails = [];
                    data.accessCredentials.map(item => this.accessCredentials.push(item as AccessCredentials));
                    data.linuxCredentails.map(item => this.linuxCredentails.push(item as AccessCredentials));
               })
            }
          );
        this.displayStore.updateScreenSettings({PrimaryUserApplyProcessing:false,SecondaryUserApplyProcessing:false});
    }
    @action 
    editCredentials= async(credentials:AccessCredentials)=>{
        await jobAgent.JobActions.EditCredentials(credentials).then(res=>{
            runInAction(() => {
                if(credentials.userType == CredentialUserType.GeneralUser)
                {
                    this.accessCredentials = [];
                    res.credentials.map(item => this.accessCredentials.push(item as AccessCredentials));
                    toast.success("Windows Credentials settings was updated successfully");
                    this.updateSelectedPrimaryUser(credentials.userName);
                    this.updateSelectedPrimaryPassword(credentials.password);
                    this.updateSelectedPrimaryUserId(credentials.id);
                    this.displayStore.updateNextStepValid({primaryValid:true});
                    this.displayStore.updateBaseDrawerSettings({PrimaryUserDrawerOpen:false});
                    this.ValidateCredentials()
                }
                else
                {
                    this.linuxCredentails = [];
                    res.credentials.map(item => this.linuxCredentails.push(item as AccessCredentials));
                    toast.success("Linux Credentials settings was updated successfully");
                    this.updateSelectedSecondaryUser(credentials.userName);
                    this.updateSelectedSecondaryPassword(credentials.password);
                    this.updateSelectedSecondaryUserId(credentials.id);
                    this.displayStore.updateNextStepValid({secondaryValid:true});
                    this.displayStore.updateBaseDrawerSettings({SecondaryUserDrawerOpen:false});
                }
                this.displayStore.updateScreenSettings({ displayBaseSettings: 'EmptyVlan' });
            })
        })
        .catch(err=>{
            if(err.data && err.data.errors)
            {
                if(credentials.userType == CredentialUserType.GeneralUser)
                {
                    toast.error("failed to update Windows Credentials settings " + err.data.errors);
                    this.displayStore.updateBaseDrawerSettings({PrimaryUserDrawerOpen:false});
                    this.displayStore.updateNextStepValid({primaryValid:false});
                }
                else
                {
                    toast.error("failed to update Linux Credentials settings "+ err.data.errors);
                    this.displayStore.updateBaseDrawerSettings({SecondaryUserDrawerOpen:false});
                    this.displayStore.updateNextStepValid({secondaryValid:false});
                }
                this.displayStore.updateScreenSettings({ displayBaseSettings: 'EmptyVlan' });
            }
            
        })
    
    }
    @action 
    deleteCredentials = async(type: CredentialUserType)=>{
        await runInAction(()=> {
            jobAgent.JobActions.DeleteCredentials(type == CredentialUserType.GeneralUser ? this.selectedPrimaryUser.id : this.selectedSecondaryUser.id).then(
                data => {
                    runInAction(() => {
                        toast.success("Successfuly deleted user credentials");
                        if(type == CredentialUserType.GeneralUser)
                        {
                            this.accessCredentials = [];
                            data.map(item => this.accessCredentials.push(item as AccessCredentials));
                            this.anyPrimaryUser = data.length > 0;
                            if(data.length > 0)
                            {
                                this.rootStore.createJobStore.updateBaseSettings({ workflowTest: { ...this.rootStore.createJobStore.baseSettings.workflowTest, PrimaryUserCredsId:data[0].id} });
                                this.rootStore.createJobStore.updateBaseSettings({ workflowTest: { ...this.rootStore.createJobStore.baseSettings.workflowTest, PrimaryUser:data[0].userName} });
                                this.rootStore.createJobStore.updateBaseSettings({ workflowTest: { ...this.rootStore.createJobStore.baseSettings.workflowTest, PrimaryPassword:data[0].password} });
                                this.selectedPrimaryUser = data[0];
                                this.displayStore.updateNextStepValid({primaryValid:true});
                                this.ValidateCredentials()
                            }
                            else
                            {
                                this.rootStore.createJobStore.updateBaseSettings({ workflowTest: { ...this.rootStore.createJobStore.baseSettings.workflowTest, PrimaryUserCredsId:0} });
                                this.rootStore.createJobStore.updateBaseSettings({ workflowTest: { ...this.rootStore.createJobStore.baseSettings.workflowTest, PrimaryUser:''} });
                                this.rootStore.createJobStore.updateBaseSettings({ workflowTest: { ...this.rootStore.createJobStore.baseSettings.workflowTest, PrimaryPassword:''} });
                                this.selectedPrimaryUser = null;
                                this.displayStore.updateNextStepValid({primaryValid:false});
                            }
                                
                        }
                        else
                        {
                            this.linuxCredentails = [];
                            data.map(item => this.linuxCredentails.push(item as AccessCredentials));
                            this.anySecondaryUser = data.length > 0;
                            if(data.length > 0)
                            {
                                this.rootStore.createJobStore.updateBaseSettings({ workflowTest: { ...this.rootStore.createJobStore.baseSettings.workflowTest, SecondaryUserCredsId:data[0].id} });
                                this.rootStore.createJobStore.updateBaseSettings({ workflowTest: { ...this.rootStore.createJobStore.baseSettings.workflowTest, SecondaryUser:data[0].userName} });
                                this.rootStore.createJobStore.updateBaseSettings({ workflowTest: { ...this.rootStore.createJobStore.baseSettings.workflowTest, SecondaryPassword:data[0].password} });
                                this.selectedSecondaryUser = data[0];
                                this.displayStore.updateNextStepValid({secondaryValid:true});
                            }
                            else
                            {
                                this.rootStore.createJobStore.updateBaseSettings({ workflowTest: { ...this.rootStore.createJobStore.baseSettings.workflowTest, SecondaryUserCredsId:0}});
                                this.rootStore.createJobStore.updateBaseSettings({ workflowTest: { ...this.rootStore.createJobStore.baseSettings.workflowTest, SecondaryUser:''}});
                                this.rootStore.createJobStore.updateBaseSettings({ workflowTest: { ...this.rootStore.createJobStore.baseSettings.workflowTest, SecondaryPassword:''}});
                                this.selectedSecondaryUser = null;
                                this.displayStore.updateNextStepValid({secondaryValid:false});
                            }
                                
                        }
                        
                    })
                      
            }).catch(err => {
                if(err && err.data && err.data.errors)
                    toast.error(err.data.errors)
            }).finally(() => {
                this.displayStore.updateScreenSettings({ displayBaseSettings: 'EmptyVlan' });
                if(type == CredentialUserType.GeneralUser)
                    this.displayStore.updateBaseDrawerSettings({PrimaryUserDrawerOpen:false});
                else
                    this.displayStore.updateBaseDrawerSettings({SecondaryUserDrawerOpen:false});
            })

        });
    }
    @action 
    createCredentials = async(type:CredentialUserType)=>{
        let credentials = new AccessCredentials();
        if(type == CredentialUserType.GeneralUser)
        {
            if(!this.rootStore.createJobStore.baseSettings.workflowTest.PrimaryUser || this.rootStore.createJobStore.baseSettings.workflowTest.PrimaryUser == '')
            {
                toast.error("Failed to add new Credentials, username is empty");
                this.displayStore.updateNextStepValid({primaryValid:false});
                return;
            }
            credentials = { id: 0, userName: this.rootStore.createJobStore.baseSettings.workflowTest.PrimaryUser, password: this.rootStore.createJobStore.baseSettings.workflowTest.PrimaryPassword, userType: type };
        }
        else
        {
            if(!this.rootStore.createJobStore.baseSettings.workflowTest.SecondaryUser || this.rootStore.createJobStore.baseSettings.workflowTest.SecondaryUser == '')
            {
                toast.error("Failed to add new Credentials, username is empty");
                this.displayStore.updateNextStepValid({secondaryValid:false});
                return;
            }
            credentials = { id: 0, userName: this.rootStore.createJobStore.baseSettings.workflowTest.SecondaryUser, password: this.rootStore.createJobStore.baseSettings.workflowTest.SecondaryPassword, userType: type };
        }
        return await jobAgent.JobActions.AddCredentials(credentials).then(res => {
            runInAction(() => {
                let cred = res.credentials.find(item=>item.id == res.addedCredId);
                if(type == CredentialUserType.GeneralUser)
                {
                    this.selectedPrimaryUser =cred;
                    this.rootStore.createJobStore.updateBaseSettings({ workflowTest: { ...this.rootStore.createJobStore.baseSettings.workflowTest, PrimaryUserCredsId:cred.id, PrimaryUser: cred.userName, PrimaryPassword:cred.password } });
                    toast.success("Windows Credentials were added successfully");
                    this.anyPrimaryUser = true;
                    this.accessCredentials = res.credentials;
                    this.displayStore.updateNextStepValid({primaryValid:true});
                    this.displayStore.updateBaseDrawerSettings({PrimaryUserDrawerOpen:false});
                    this.ValidateCredentials()
                }
                else
                {
                    this.selectedSecondaryUser =cred;
                    this.rootStore.createJobStore.updateBaseSettings({ workflowTest: { ...this.rootStore.createJobStore.baseSettings.workflowTest, SecondaryUser: cred.userName, SecondaryPassword:cred.password, SecondaryUserCredsId:cred.id } });
                    toast.success("Linux Credentials were added successfully");
                    this.anySecondaryUser = true;
                    this.linuxCredentails = res.credentials;
                    this.displayStore.updateNextStepValid({secondaryValid:true});
                    this.displayStore.updateBaseDrawerSettings({SecondaryUserDrawerOpen:false});
                } 
                this.displayStore.updateScreenSettings({ displayBaseSettings: 'EmptyVlan' });
            })
        }).catch(err => {
            if(err.data && err.data.errors)
            {
                toast.error("Failed to add new Credentials" + err.data.errors);
            }
        })
    }
    @action
    ValidateCredentials = async() =>{
        this.CredsValidationInProgress = true;
        this.rootStore.displayStore.updateNextStepValid({primaryValid:false})
        this.displayStore.updateScreenSettings({PrimaryUserApplyProcessing:true});
        await JobAgent.JobActions.ValidateDomainCredentials({credsId:this.selectedPrimaryUser.id,username:this.selectedPrimaryUser.userName,password:this.selectedPrimaryUser.password}).then(res =>{
            if(res)
            {
                toast.success(`${this.selectedPrimaryUser.userName} - domain validation passed`)
                this.rootStore.displayStore.updateNextStepValid({primaryValid:true})
            }
            else
            {
                toast.error(`${this.selectedPrimaryUser.userName} - domain validation failed - please verify username and password`)
                this.rootStore.displayStore.updateNextStepValid({primaryValid:false})
            }
        }).catch(err => {
            if(err && err.data && err.data.errors)
            {
                if(err.data.errors.includes("Not a domain user"))
                {
                    toast.warn(`${this.selectedPrimaryUser.userName} is not a domain user`)
                    this.rootStore.displayStore.updateNextStepValid({primaryValid:true})
                }
                else if(err.data.errors.includes('The LDAP server is unavailable'))
                {
                    toast.warn(`${this.selectedPrimaryUser.userName} - domain validation waning, ${err.data.errors}`)
                    this.rootStore.displayStore.updateNextStepValid({primaryValid:true})
                }
                else
                {
                    toast.error(`${this.selectedPrimaryUser.userName} - domain validation failed, ${err.data.errors}`)
                    this.rootStore.displayStore.updateNextStepValid({primaryValid:false})
                }
                    
            }
        }).finally(() => {
            this.displayStore.updateScreenSettings({PrimaryUserApplyProcessing:false});
            this.CredsValidationInProgress = false;
        });

    }
    //data mover section
    @action 
    getDataMovers = async() =>{
        
        return JobAgent.JobActions.ListDataMovers(this.rootStore.createJobStore.baseSettings.dataMover).then(res => 
        {
            runInAction(() => {
                this.dataMoverList = [];
                res.map(dm => this.dataMoverList.push(dm as DataMoverDTO));
            })
            
        });
        
    }
    @action
    editDataMover = async (DMDetails:DataMoverDTO)=>{
        await runInAction(() => {
            jobAgent.JobActions.EditDataMover(DMDetails).then(res=>{
                this.updateEditedDataMover(res);
                toast.success("Data Mover settings was updated successfully");
                this.displayStore.updateNextStepValid({dmValid:true});
            })
            .catch(err=>{
                toast.error("failed to update data mover settings"+ err);
                this.displayStore.updateNextStepValid({dmValid:false});
            })
        })
    }
    @action 
    deleteDataMover = async()=> {
        await runInAction(() => {
            this.anyDM = true;
            this.displayStore.updateScreenSettings({DMApplyProcessing:true});
            jobAgent.JobActions.DeleteDataMover(this.selectedDataMover.id).then(res=>{
            toast.success("Data Mover was deleted successfully");
            
            JobAgent.JobActions.ListDataMovers(this.rootStore.createJobStore.baseSettings.dataMover).then(res => 
                {
                    runInAction(() => {
                        this.dataMoverList = [];
                        res.map(dm => this.dataMoverList.push(dm as DataMoverDTO));
                        this.anyDM=this.dataMoverList.length>0;
                        this.tempDataMover = new DataMoverDTO();
                        this.rootStore.createJobStore.updateBaseSettings({dataMoverId:this.dataMoverList[0].id});
                        this.displayStore.updateNextStepValid({dmValid:true});
                    })
                    
                });
                if(this.displayStore.BaseDrawerSettings.DataMoverDrawerOpen)
                {
                    this.displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
                    this.displayStore.updateBaseDrawerSettings({DataMoverDrawerOpen: false});
                }  
        })
        .catch(err=>{
            if(err.data && err.data.errors)
                toast.error(err.data.errors);
        })
        this.displayStore.updateScreenSettings({DMApplyProcessing:false});
        })
        
    }
    @action
    createDataMover= async() =>{
       let username = this.tempDataMover.sourceDataMoverCredentials.userName === '' ? (this.tempInfra.sourceInfraCredentials.userName === '' ? '' : this.tempInfra.sourceInfraCredentials.userName) : this.tempDataMover.sourceDataMoverCredentials.userName
       let password = this.tempDataMover.sourceDataMoverCredentials.password === '' ? (this.tempInfra.sourceInfraCredentials.password === '' ? '' : this.tempInfra.sourceInfraCredentials.password) : this.tempDataMover.sourceDataMoverCredentials.password
       let targetUsername = this.tempDataMover.targetDataMoverCredentials.userName === '' ? (this.tempInfra.targetInfraCredentials.userName === '' ? '' : this.tempInfra.targetInfraCredentials.userName) : this.tempDataMover.targetDataMoverCredentials.userName
       let targetPassword = this.tempDataMover.targetDataMoverCredentials.password === '' ? (this.tempInfra.targetInfraCredentials.password === '' ? '' : this.tempInfra.targetInfraCredentials.password) : this.tempDataMover.targetDataMoverCredentials.password
      
       let sourceCredentials = new AccessCredentials();
       sourceCredentials = { id: 0, userName: username, password: password, userType: CredentialUserType.InfraUser };
       let targetCredentials = new AccessCredentials();
       targetCredentials = { id: 0, userName: targetUsername, password: targetPassword, userType: CredentialUserType.InfraUser };
       const dataMoverType = this.rootStore.createJobStore.baseSettings.dataMover;
       let DMDetails = {
           datamoverTypeId: dataMoverType,
           sourceDataMoverCredentials: sourceCredentials,
           targetDataMoverCredentials: targetCredentials,
           dataMoverSource: this.tempDataMover.dataMoverSource,
           dataMoverTarget: this.tempDataMover.dataMoverTarget,
           SourcePort: this.tempDataMover.sourcePort == 0 ? setDatamoverDefaultPort(dataMoverType,"source") :this.tempDataMover.sourcePort,
           TargetPort: this.tempDataMover.targetPort == 0 ? setDatamoverDefaultPort(dataMoverType,"target") : this.tempDataMover.targetPort
       };
        return await jobAgent.JobActions.CreateDataMover(DMDetails).then(res => {
               this.updateDataMoverListAfterCreate(res.dataMovers);
               let dm= res.dataMovers.find(item=>item.id == res.addedDataMoverId);
               this.updateSelectedDataMover(dm);
               this.rootStore.createJobStore.updateBaseSettings({dataMoverId:  res.addedDataMoverId});
               toast.success("Data Mover was added successfully");
               this.displayStore.updateNextStepValid({dmValid:true});
              return res;
           }).catch(err => {
                   toast.error(('Failed to add Datamover'));
                   this.displayStore.updateNextStepValid({dmValid:false});
                 throw err;
               })
    }

    //infrastructure section 
    @action 
    getInfras = async() =>{
        this.displayStore.updateScreenSettings({InfraApplyProcessing:true});
        await JobAgent.JobActions.ListInfrastructures(this.rootStore.createJobStore.baseSettings.infrastructureType).then(res => 
        {
            runInAction(() => {
                this.infrastructureList = [];
                res.map(infra => this.infrastructureList.push(infra as InfrastructureDTO));

            })
        });
        this.displayStore.updateScreenSettings({InfraApplyProcessing:false});
    }
    @action
    createInfra = async() => {
        let tempInfra = this.tempInfra;
        let sourceCredentials = new AccessCredentials();
        sourceCredentials = { id: 0, userName: tempInfra.sourceInfraCredentials.userName, password: tempInfra.sourceInfraCredentials.password, userType: CredentialUserType.InfraUser };
        let targetCredentials = new AccessCredentials();
        targetCredentials = { id: 0, userName: tempInfra.targetInfraCredentials.userName, password: tempInfra.targetInfraCredentials.password, userType: CredentialUserType.InfraUser };
        const infraType =  !tempInfra.infrastructureTypeId || tempInfra.infrastructureTypeId === 0 ? this.rootStore.createJobStore.baseSettings.infrastructureType : tempInfra.infrastructureTypeId
        let InfraDetails = {
            infrastructureTypeId: infraType,
            sourceInfraCredentials: sourceCredentials,
            targetInfraCredentials: targetCredentials,
            infrastructureSource: tempInfra.infrastructureSource,
            infrastructureTarget: tempInfra.infrastructureTarget,
            infrastructureSourcePort: tempInfra.infrastructureSourcePort == 0 ? 902 : tempInfra.infrastructureSourcePort,
            infrastructureTargetPort: tempInfra.infrastructureTargetPort == 0 ? 902 : tempInfra.infrastructureTargetPort
        };
        
        return jobAgent.JobActions.CreateInfrastructure(InfraDetails).then(res => {
            this.displayStore.updateNextStepValid({infraValid:false});
            let infra= res.infrastructures.find(item=>item.id == res.addedInfraId);
            this.updateSelectedInfra(infra);
            this.rootStore.createJobStore.updateBaseSettings({infrastructureId:  res.addedInfraId});
            this.updateInfraListAfterCreate(res.infrastructures);
            this.displayStore.updateNextStepValid({infraValid:true});
            toast.success("Infrastructure was added successfully");
            return res;

        }).catch(err => {
            if(err && err.data && err.data.errors)
                    {   if( !('includes' in err.data.errors)) {
                            toast.error("failed to create infrastructure");
                            console.log(err);
                            return err;
                        }
                        if(err.data.errors.includes('bothfailed'))
                        {
                            toast.error(`Cannot add Infrastructure - Failed to autheticate to ${InfraDetails.infrastructureSource}`);
                            toast.error(`Cannot add Infrastructure - Failed to autheticate to ${InfraDetails.infrastructureTarget}`);
                        }
                        else if(err.data.errors.includes('sourcefailed'))
                        {
                            toast.error(`Cannot add Infrastructure - Failed to autheticate to ${InfraDetails.infrastructureSource}`);
                        }
                        else if(err.data.errors.includes('targetfailed'))
                        {
                            toast.error(`Cannot add Infrastructure - Failed to autheticate to ${InfraDetails.infrastructureTarget}`);
                        }
                        else
                        {
                            toast.error(`Cannot add Infrastructure: ${err.data.errors}`);
                        }
                    }
            this.displayStore.updateNextStepValid({infraValid:true});
            return err;
        })
    }
    @action
    editInfra = async(InfraDetails:InfrastructureDTO)=>{
    await runInAction(() => {
        return jobAgent.JobActions.EditInfrastructure(InfraDetails).then(res=>{
            this.updateEditedInfra(res);
            this.displayStore.updateNextStepValid({infraValid:true});
            toast.success("Infrastructure was updated successfully");
            return res;
        })
        .catch(err=>{
            if(err && err.data && err.data.errors)
                    {
                        if(err.data.errors.includes('bothfailed'))
                        {
                            toast.error(`Cannot update Infrastructure - Failed to autheticate to ${InfraDetails.infrastructureSource}`);
                            toast.error(`Cannot update Infrastructure - Failed to autheticate to ${InfraDetails.infrastructureTarget}`);
                        }
                        else if(err.data.errors.includes('sourcefailed'))
                        {
                            toast.error(`Cannot update Infrastructure - Failed to autheticate to ${InfraDetails.infrastructureSource}`);
                        }
                        else if(err.data.errors.includes('targetfailed'))
                        {
                            toast.error(`Cannot update Infrastructure - Failed to autheticate to ${InfraDetails.infrastructureTarget}`);
                        }
                        else
                        {
                            toast.error(`Cannot update Infrastructure: ${err.data.errors}`);
                        }
                    }
            this.displayStore.updateNextStepValid({infraValid:false});
            return err;
        })
    })
    }
    @action 
    deleteInfra= async() =>{
        await runInAction(() => {
            this.anyInfra=true;
            this.displayStore.updateScreenSettings({DMApplyProcessing:true});
            jobAgent.JobActions.DeleteInfrastructure(this.selectedInfra.id).then(res=>{
                toast.success("Infrastructure was deleted successfully");
                JobAgent.JobActions.ListInfrastructures(this.rootStore.createJobStore.baseSettings.infrastructureType).then(res => 
                {
                    runInAction(() => {
                        this.infrastructureList = [];
                        res.map(infra => this.infrastructureList.push(infra as InfrastructureDTO))
                        this.anyInfra=this.infrastructureList.length>0;
                        this.displayStore.updateNextStepValid({infraValid:this.infrastructureList.length>0 ? true:false});
                        this.tempInfra = new InfrastructureDTO();
                        
                    })
                });
                
                if(this.displayStore.BaseDrawerSettings.InfraDrawerOpen)
                {
                    this.displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
                    this.displayStore.updateBaseDrawerSettings({InfraDrawerOpen: false});
                }  
            })
            .catch(err=>{
                if(err.data && err.data.errors)
                    toast.error(err.data.errors);
                    // this.displayStore.updateNextStepValid({infraValid:false});
            })
            this.displayStore.updateScreenSettings({DMApplyProcessing:false});
        })
    }
    

    //network tuples section
    @action
    listNetworkTupples = async() => {
         this.displayStore.updateScreenSettings({NetworkProcessing:true});
         await runInAction(()=> {
         jobAgent.CreateJobActions.listNetworkTupples(this.selectedInfra.id).then(res => {
            if(res)
            {
                runInAction(()=> {
                this.networkTuppleList = [];
                res.map(nt => this.networkTuppleList.push(nt as NetworkTuppleDTO));
                this.anyNetworkTupples = this.networkTuppleList.length > 0;
                if(this.anyNetworkTupples)
                {
                    this.updateSelectedNetworkTupple(this.networkTuppleList[0]);
                    this.rootStore.displayStore.updateNextStepValid({networkMappingValid:true});
                }
            });
            }
        }).catch(err => {
            if(err.data && err.data.errors)
            {
                toast.error('Failed to list network tupples' + err.data.errors);
                this.rootStore.displayStore.updateNextStepValid({networkMappingValid:false});
            }
        }).finally(() => {
            this.displayStore.updateScreenSettings({NetworkProcessing:false});
        });
    });
    }
    @action
    editNetworkTupple = async(networkTupple: NetworkTuppleDTO) => {
        await jobAgent.CreateJobActions.editNetworkTupple(networkTupple).then(res => {
            runInAction(()=> {
                if(res)
                {
                    this.networkTuppleList = [];
                    res.map(nt => this.networkTuppleList.push(nt as NetworkTuppleDTO));
                    this.anyNetworkTupples = this.networkTuppleList.length > 0;
                    let networkTuple = this.networkTuppleList.find(item=>item.id == networkTupple.id);
                    this.rootStore.jobSettingsStore.updateSelectedNetworkTupple(networkTuple);
                    toast.success('Tuple was updated successfully');
                    this.rootStore.displayStore.updateNextStepValid({networkMappingValid:true});
                    this.rootStore.displayStore.updateScreenSettings({ displayNetwork: 'EmptyVlan' });
                    this.rootStore.displayStore.updateNetworkDrawerSettings({testDrawerOpen:false});
                }
            });
        }).catch(err => {
            if(err.data && err.data.errors)
            {
                toast.error('Failed to edit network tupple' + err.data.errors);
                this.rootStore.displayStore.updateNextStepValid({networkMappingValid:false});
                this.rootStore.displayStore.updateScreenSettings({ displayNetwork: 'EmptyVlan' });
                this.rootStore.displayStore.updateNetworkDrawerSettings({testDrawerOpen:false});
                throw err
            }
        });
    }
    @action
    deleteNetworkTupple = async() => {
        await jobAgent.CreateJobActions.deleteNetworkTupple(this.selectedNetworkTupple.id).then(res => {
            runInAction(()=> {
                if(res)
                {
                    this.networkTuppleList = [];
                    res.map(nt => this.networkTuppleList.push(nt as NetworkTuppleDTO));
                    this.anyNetworkTupples = res.length > 0;
                    toast.success('Tuple was deleted successfully');
                    if(res.length > 0)
                        this.rootStore.jobSettingsStore.updateSelectedNetworkTupple(res[0]);
                        this.rootStore.displayStore.updateNextStepValid({networkMappingValid:true});
                    this.rootStore.displayStore.updateScreenSettings({ displayNetwork: 'EmptyVlan' });
                    this.rootStore.displayStore.updateNetworkDrawerSettings({testDrawerOpen:false});
                    this.rootStore.displayStore.updateScreenSettings({AddModeNetworks:false});
                }
            });
        }).catch(err => {
            if(err.data && err.data.errors)
            {
                toast.error('Failed to delete network tupple' + err.data.errors);
                this.rootStore.displayStore.updateNextStepValid({networkMappingValid:false});
                this.rootStore.displayStore.updateScreenSettings({ displayNetwork: 'EmptyVlan' });
                this.rootStore.displayStore.updateNetworkDrawerSettings({testDrawerOpen:false});
                this.rootStore.displayStore.updateScreenSettings({AddModeNetworks:false});
                throw err
            }
        });
    }
    @action
    createNetworkTupple = async(networkTupple: NetworkTuppleDTO) => {
        return await jobAgent.CreateJobActions.createNetworkTupple(networkTupple).then(res => {
            runInAction(()=> {
                if(res)
                {
                    this.networkTuppleList = [];
                    res.networkTupples.map(nt => this.networkTuppleList.push(nt as NetworkTuppleDTO));
                    this.anyNetworkTupples = res.networkTupples.length > 0;
                    this.rootStore.jobSettingsStore.updateSelectedNetworkTupple(res.networkTupples.find(item=>item.id == res.addedId));
                    this.rootStore.createJobStore.updateBaseSettings({networkTuppleId:res.addedId});
                    toast.success('Tuple was created successfully');
                    this.rootStore.displayStore.updateNextStepValid({networkMappingValid:true});
                    this.rootStore.displayStore.updateScreenSettings({ displayNetwork: 'EmptyVlan' });
                    this.rootStore.displayStore.updateNetworkDrawerSettings({testDrawerOpen:false});
                    this.rootStore.displayStore.updateScreenSettings({AddModeNetworks:false});
                    return res;
                }

            });
        }).catch(err => {
            if(err.data && err.data.errors)
            {
                toast.error('Failed to create network tupple' + err.data.errors);
                this.rootStore.displayStore.updateNextStepValid({networkMappingValid:false});
                this.rootStore.displayStore.updateScreenSettings({ displayNetwork: 'EmptyVlan' });
                this.rootStore.displayStore.updateNetworkDrawerSettings({testDrawerOpen:false});
                this.rootStore.displayStore.updateScreenSettings({AddModeNetworks:false});
                throw err
            }
        });
    }

}