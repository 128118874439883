import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { CircularProgress, Divider, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, makeStyles, Switch, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RootStoreContext } from '../../../../app/stores/root.store';
import { EDRTextField } from '../../../../app/common/EDRTextField';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import HelpIcon from '@material-ui/icons/Help';
import { SaveButton } from '../../../../app/common/saveButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import jobAgent from '../../../../app/api/jobAgent';
import CreateJobStore from '../../../../app/stores/createJob.store';
import { toast } from 'react-toastify';
//import { exception } from 'console';
import { runInAction } from 'mobx';
import ConfirmationDialog from '../../../../app/common/confirmationDialog.component';
import { EDRAutocomplete } from '../../../../app/common/EDRAutocomplete';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import ReactDOM from 'react-dom';
import { EDROutlinedInput } from '../../../../app/common/EDROutlinedInput';
import { AccessCredentials, CONSTS, CredentialUserType } from '../../../../app/models/createjob/createJob.model';
import { DevicePlatform } from '../../../../app/models/jobProperties.model';

const useStyles = makeStyles({
    formControl: {
        width: '100%',
        height:'70px',
        '& label': {
            background: "white",
            width: "auto",
            padding:"0 4px 0 4px",
            fontSize: '17px',
          }
    },
    helpIcon:
    {
      color: '#1976d2',
      fontSize: "large"
    },
    MainHeading:
    {
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.19',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#1f396d',
        marginLeft:'10px'
    },
    SecondHeading:{
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        flexGrow: 1
    },
    divider: {
            backgroundColor: '#eaeef4',
            margin: '0',
            marginTop: '10px',
            marginLeft: '10px',
    },
    });   

const SecondaryAdvancedComponent : React.FC = () => {
    let labelRef;
    const classes = useStyles();
    const [t] = useTranslation("createJobBase");
    const rootStore = useContext(RootStoreContext); 
    const {displayStore} = rootStore;
    const {BaseDrawerSettings} =displayStore;
    const {jobSettingsStore,createJobStore} =rootStore;
    const [openConfirmationDialog, setopenConfirmationDialog] = useState(false);
    const [linuxPasswordVisibility, setLinuxPasswordVisibility] = useState(true);
    const [showEyeLinuxPassword, setShowEyeLinuxPassword]= useState(true);
    const previousSecondaryUsername = jobSettingsStore.linuxCredentails;
    const [secondaryAutocomplete,setSecondaryAutocomplete] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

      const validation = yup.object({
        SecondaryUser: yup.string(),
        SecondaryPassword: yup.string(),
    })

      const form = useFormik({
        initialValues:
        {
            SecondaryUser:'',
            SecondaryPassword:'',
        },
        validationSchema: validation,
        onSubmit: () => { },
    })

    useEffect(() => {
        let user = jobSettingsStore.linuxCredentails.find(l => l.userName === jobSettingsStore.selectedSecondaryUser.userName);
        const initialValues =
        {
            SecondaryUser: BaseDrawerSettings.addSecondaryUser ?'' :user!=undefined ? user.userName:'',
            SecondaryPassword: BaseDrawerSettings.addSecondaryUser ?'': user!=undefined ? user.password:'',
        };
        form.resetForm({ values: initialValues });
    }, [BaseDrawerSettings.addSecondaryUser]);

    const handleAdd = async() =>{
        setButtonsDisabled(true);
        await jobSettingsStore.createCredentials(CredentialUserType.LinuxUser);
        setButtonsDisabled(false);
    }

    const handleDelete= async() =>{
        setButtonsDisabled(true);
        await jobSettingsStore.deleteCredentials(CredentialUserType.LinuxUser);
        setButtonsDisabled(false);
    }

    const handleUpdate = async() =>{
        setButtonsDisabled(true);
        let Credentials = new AccessCredentials();
        Credentials = {id : jobSettingsStore.selectedSecondaryUser.id, userName:form.values.SecondaryUser, password:form.values.SecondaryPassword, userType:CredentialUserType.LinuxUser};
        await jobSettingsStore.editCredentials(Credentials);
        setButtonsDisabled(false);
    }

    const handleConfirmationPopup =(confirm:boolean)=>{
        if(confirm)
        {
            handleDelete();
        }
        setopenConfirmationDialog(false);
    }


    const handleSecondaryUserChange = (event:React.ChangeEvent,value:any) => {
        if(event!=null){
          let onInput= event.type=="click"? false:true;
          setSecondaryAutocomplete(onInput);
          if (value!=null && typeof value === 'object') 
          {
            form.setFieldValue("SecondaryUser",value=="" ? '':value.userName,true);
            form.setFieldValue("SecondaryPassword",'',true);
            createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest, SecondaryUser: value=="" ? '':value.userName,SecondaryPassword:''}});
            if(createJobStore.networkSetting.domainController != null && createJobStore.networkSetting.domainController != undefined && createJobStore.networkSetting.domainController.domainControllerESXCredentials != null)
            {
                let credentials= {id: createJobStore.networkSetting.domainController.domainControllerESXCredentials.id,userName: value=="" ? '':value.userName ,password: '',userType:CredentialUserType.ESXUser};
                rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController,  domainControllerESXCredentials: credentials}});
            }
          }
          if (typeof value === 'string')
          {
            const userObj = previousSecondaryUsername.find(e => e.userName.toLocaleLowerCase() == value.toLocaleLowerCase());
            form.setFieldValue("SecondaryUser", userObj!=undefined ? value:(value==""?'':value),true);
            let pass ='';
            form.setFieldValue("SecondaryPassword",pass,true);
            createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest, SecondaryUser: value=="" ? '':value,SecondaryPassword:pass}});
            if(createJobStore.networkSetting.domainController != null && createJobStore.networkSetting.domainController != undefined && createJobStore.networkSetting.domainController.domainControllerESXCredentials != null)
            {
                let credentials= {id: createJobStore.networkSetting.domainController.domainControllerESXCredentials.id,userName: value=="" ? '':value ,password: pass,userType:CredentialUserType.ESXUser};
                rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController,  domainControllerESXCredentials: credentials}});
            }
          }
          setShowEyeLinuxPassword(onInput);
          if(!onInput)
          {
              setLinuxPasswordVisibility(true);
          }
        }
      };
    
      const handleSecondaryPasswordChange = (event) => {
        form.setFieldValue("SecondaryPassword",event.target.value,true);
        if(event.target.value != '')
        {
            setSecondaryAutocomplete(true);
            setShowEyeLinuxPassword(true);
            createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest,SecondaryPassword : event.target.value}});
            if(createJobStore.networkSetting.domainController != null && createJobStore.networkSetting.domainController != undefined && createJobStore.networkSetting.domainController.domainControllerESXCredentials != null)
            {
                let credentials= {id: createJobStore.networkSetting.domainController.domainControllerESXCredentials.id,userName: rootStore.createJobStore.networkSetting.domainController.domainControllerESXCredentials.userName,password:event.target.value,userType:CredentialUserType.ESXUser};
                rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController,  domainControllerESXCredentials: credentials}});
            }
        }
        
      };

      const handleMouseClick =()=>{
        if(form.values.SecondaryPassword === CONSTS.MaskedPassword)
        {
            form.setFieldValue("SecondaryPassword",'',true);
        }
    }

    const handleMouseOut =()=>{
        if(form.dirty && form.values.SecondaryPassword == '')
        {
            if(form.values.SecondaryUser!='')
            {
                return;
            }
            else form.setFieldValue("SecondaryPassword",CONSTS.MaskedPassword,true);
        }
    }
  
      const handleClickShowPassword  = () => {
          setLinuxPasswordVisibility (linuxPasswordVisibility ?false:true);
      };
  

    const loadSecondaryUsername= ()=>{
        return (<Tooltip title={t('linuxUserPassInfo')} placement="top">
        <FormControl className={classes.formControl} variant='outlined'> 
        <EDRAutocomplete id="SecondaryUser"
          freeSolo
          disableClearable
          value={form.values.SecondaryUser}
          options={previousSecondaryUsername}
          renderOption={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
          getOptionLabel={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
          onChange={handleSecondaryUserChange}
          onInputChange={handleSecondaryUserChange}
          renderInput={(params) => (<EDRTextField {...params}
          label= {t('linuxUsername')}
          InputLabelProps={{shrink: true}} 
          variant="outlined"
          placeholder="Select/ Create New" 
          aria-autocomplete="none"
          onBlur={form.handleBlur}
          error={form.touched && form.touched.SecondaryUser && form.errors && Boolean(form.errors.SecondaryUser)}
          helperText={form.touched && form.touched.SecondaryUser && form.errors && Boolean(form.errors.SecondaryUser) && t(form.errors.SecondaryUser)}
          />)}
        />
        </FormControl>
        </Tooltip>
  );
      }
  
      const loadSecondaryPassword=()=>{
      return(<Tooltip title={t('linuxUserPassInfo')} placement="top">  
      <FormControl className={classes.formControl} variant='outlined'>
          <InputLabel ref={ref => {labelRef = ReactDOM.findDOMNode(ref)}}  style={{transform: 'translate(14px, -8px) scale(0.75)'}}>{t('linuxPass')}</InputLabel>
          <EDROutlinedInput id="SecondaryPassword" className="MuiFormControl"
              value={form.values.SecondaryPassword}
              labelWidth={labelRef ? labelRef.offsetWidth:0}
              autoComplete="off"
              type={linuxPasswordVisibility ? 'password':'text'}
              placeholder="Type Here"
              onChange={handleSecondaryPasswordChange}
              onClick={handleMouseClick}
              onBlur={handleMouseOut}
              endAdornment={form.values.SecondaryPassword && secondaryAutocomplete ?
              <InputAdornment position="end">
              <IconButton
              aria-label="toggle password visibility"
              aria-autocomplete="none"
              onClick={()=>handleClickShowPassword()}
              >
              {linuxPasswordVisibility ?  <VisibilityOff />: <Visibility />}
          </IconButton>
  </InputAdornment>
   :null }
  />
  <FormHelperText id="helperTextSecondaryPassword" style={{color:'red',marginTop:'0px'}}>{form.touched && form.touched.SecondaryPassword && form.errors &&  Boolean(form.errors.SecondaryPassword) &&t(form.errors.SecondaryPassword)}</FormHelperText>  
  </FormControl>
  </Tooltip>
      )
      }
    
    
    return(
    <Grid container direction="column" style={{paddingRight: 16}}>

        <div style={{ display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
            <Typography variant='h6' className={classes.MainHeading}>{BaseDrawerSettings.addSecondaryUser ? t("addSecondaryUser"):t("editSecondaryUser")}</Typography>
            <div style={{ display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
                {!BaseDrawerSettings.addSecondaryUser ? <SaveButton onClick={()=>setopenConfirmationDialog(true)} disabled ={buttonsDisabled} variant="contained" color="primary" style={{height:'25px',textTransform:'none',marginRight:'10px'}}>
                    {t('delete')}
                    {buttonsDisabled ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                </SaveButton>:null}
                <SaveButton onClick={BaseDrawerSettings.addSecondaryUser ? handleAdd :handleUpdate} disabled={form.values.SecondaryUser === '' || form.values.SecondaryPassword === ''||buttonsDisabled} variant="contained" color="primary" style={{height:'25px',textTransform:'none'}}>
                    {BaseDrawerSettings.addSecondaryUser ? t('add'): t('apply')}
                    {buttonsDisabled ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                </SaveButton>   
            </div>
        </div>

        <Grid item container direction="row">
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px' ,marginLeft:'10px'}}>
            <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('LinuxCredentials')}</Typography>
            <Tooltip title={t('editSecondaryInfo')} arrow>
                <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
            </Tooltip> 
            <Divider className={classes.divider} style={{width:BaseDrawerSettings.addSecondaryUser? '245%':'200%'}}/>
        </div>
    </Grid>

    <Grid item direction="row" container xs={12} style={{marginTop:'8px',marginLeft:'1%'}} spacing={2}>
        <Grid item xs={6}>
            {loadSecondaryUsername()}
        </Grid>
        <Grid item xs={6}>
            {loadSecondaryPassword()}
        </Grid> 
    </Grid>
<ConfirmationDialog type="deleteUser" device={null} open={openConfirmationDialog} onClose={(confrim)=>handleConfirmationPopup(confrim)} children={"You are about to delete existing linux user, are you sure ?"}></ConfirmationDialog>
    </Grid>);
};

export default observer(SecondaryAdvancedComponent);