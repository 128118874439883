import { CancelablePromise } from "./cancellablePromise";

/**
 * @description converts the given function to memoised. 
 * @param func 
 */
export function memoise<T, Targs extends unknown[]>(func: (...args: Targs) => Promise<T>|CancelablePromise<T>): (...args: Targs) => Promise<T>|CancelablePromise<T> {
    var cache: {
        [key: string]: Promise<T>;
    } = {};
    return function (...args: Targs) {
        var argArray = Array.from(arguments);
        let key = JSON.stringify(argArray);
        if(argArray.some(arg => arg === "resetCache")) {
            cache = {}
        }
        const cachedValue = cache[key];
        if (cachedValue) {
            return cachedValue;
        }
        const value = func(...args);
        value.then(val => {
            cache[key] = value;
        });
        return value;
    };
}
