import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import  CheckCircleIcon  from "../../../assets/dashboardAssets/v.svg";
import CancelIcon from "../../../assets/dashboardAssets/false.svg";
import MemoryIcon  from "../../../assets/dashboardAssets/chip.svg";
import { INotice } from "../../../app/models/dashboard/dashboard.model";
import { makeStyles, Popover } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { NavLink } from "react-router-dom";
import Cog from "../Cog.component";
import DrResourceOverlayComponent from "../DrResourceOverlay.component";


const ResourcesComponent = (props: INotice) => {
    const [openPopover, setopenPopover] = useState<HTMLSpanElement| null>(null);
    const ref= React.createRef<HTMLSpanElement>();

  const stateicon =
    props.goodStateIcon === true ? CheckCircleIcon : CancelIcon;

  const useStyles = makeStyles({
    icon: { 
      width: "14px",
      height: "14px", 
      marginTop:"1em",
      marginRight:"0.5em", 
      objectFit: "contain"
    } ,
    popover: {
      boxShadow: 'none',
      overflowY: 'hidden',
      background: 'transparent',
      minWidth: 220, 
      "&:after": {
        width: 0,
        height: 0, 
        borderLeft: "10px solid transparent",
        borderRight: "10px solid transparent",
        borderTop: "10px solid rgba(0,0,0,0.66)",
        position: "relative",
        bottom: -9,
        left: "calc(50% - 5px)",
        right: 0,
        zIndex: 100,
        content: "''"
    }
    }

  });

  const handleClick = () => {
    setopenPopover(ref.current);
  }
  const classes = useStyles();

  return (<>
    <Grid item container data-test="component-resources" style={{position: 'relative'}} onClick={() => handleClick()}>
      <Grid item container >
          <Grid item style={{ width: "60%",paddingLeft: "24px"}} >
            <img src={MemoryIcon} className={classes.icon} />
            <span className="dashboard_header_icon" ref={ref}>{props.header}</span>
          </Grid>
              
      </Grid>
      <Grid item container>
          <Grid item style={{ width: "65%", paddingLeft: "24px"}}>
            <span className="dashboard_noticepanel_text">{props.mainText}</span>
          </Grid>
          <Grid item style={{ width: "35%"}}>
            <span style={{float: "right",paddingRight:"1em"}}>
              {<img src={stateicon}/>}
            </span>
          </Grid>
      </Grid>
     </Grid>
     <Popover anchorOrigin={{vertical: 'top', horizontal: 'left'}}
     anchorReference="anchorEl"
      transformOrigin= {{vertical: 122, horizontal: 40}}
     anchorPosition={{ top: -500, left: 400 }}
     open={!!openPopover}  anchorEl={openPopover} classes={{paper: classes.popover}} onClose={()=> setopenPopover(null)}><DrResourceOverlayComponent></DrResourceOverlayComponent> </Popover>
     
     </>
  );
};

export default observer(ResourcesComponent);
