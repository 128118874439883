import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { Chip, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, makeStyles, Switch, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { EDRTextField } from '../../../app/common/EDRTextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import jobAgent from '../../../app/api/jobAgent';
import { RootStoreContext } from '../../../app/stores/root.store';
import { DataMover } from '../../../app/models/BaseSettings.enum';

import { EDRAutocomplete } from '../../../app/common/EDRAutocomplete';

function useVRAs(datamover: DataMover) {
    const [vras, setvras] = useState([]);

    useEffect(() => {
        jobAgent.JobActions.listVRAs(datamover).then(vras => {
            setvras([...vras]);
        }).catch(err => console.log);
    }, [datamover])

    return vras;
}

const useStyles = makeStyles({
    formControl: {
        width: '100%',
        height:'70px',
        '& label': {
            background: "white",
            width: "auto",
            padding:"0 4px 0 4px",
            fontSize: '17px',
          }
    },
    validateIcon: {
        position: 'absolute',
        top: 15,
        right:8,
        color: '#8bc53f'
      }
    });   

const VeeamHostsComponent : React.FC = () => {
    const classes = useStyles();
    const [t] = useTranslation("createJobBase");
    const rootStore = useContext(RootStoreContext); 
    const {jobSettingsStore,createJobStore,displayStore} =rootStore;
    const [licenseVisibility,setLicenseVisibility] = useState(true);
    const [PassAutocomplete,setPassAutocomplete] = useState(false);
    const {dataMover} = createJobStore.baseSettings;
    const vraList = useVRAs(dataMover);
    let labelRef;

    const validation = yup.object({
        vmHosts: yup.string()
    })

    const form = useFormik({
    initialValues:
    {
        vmHosts: ''
    },
    validationSchema: validation,
    onSubmit: () => { },
    })
    
    useEffect(() => {
        let initialValues;
        initialValues =
        {
            vmHosts: createJobStore.baseSettings.vra || '',
        };
        form.setValues(initialValues, true);
    }, [])

    const handleVmHostChange = (event, value: Array<string> | string) => {
        if(dataMover != DataMover.CohesityRunbook && dataMover != DataMover.VeeamVmware)
            return;
        const vmHosts =  value instanceof Array? value.join(','): value;
        form.setFieldValue("vmHosts", vmHosts, true);
        createJobStore.updateBaseSettings({ vra: vmHosts });
    }
    const handleVmHostInputChange = (event, value:string) => {
        if(dataMover != DataMover.CohesityRunbook && dataMover != DataMover.VeeamVmware)
            return;
        form.setFieldValue("vmHosts", value, true);
        createJobStore.updateBaseSettings({ vra: value });
    }

    const handleVmHostBlur = (event) => {
        if(dataMover == DataMover.CohesityRunbook)
            return;
        const value = event.target.value;
        if (value) {
            const vmHosts = form.values.vmHosts.split(',') || [];
            if (vmHosts.indexOf(value) < 0) {
                vmHosts.push(value);
                form.setFieldValue('vmHosts', vmHosts.filter(v => v).join(','), true);
                createJobStore.updateBaseSettings({ vra: vmHosts.filter(v => v).join(',') });
            }

        }
        form.handleBlur(event);
    }
    return(
    <Grid container item direction="row" style={{marginTop:'3%',marginLeft:54,paddingRight:'1.8%'}} spacing={2}>
        <Grid item xs={10}>
            <FormControl className={classes.formControl} style={{ marginLeft: '7px' }} variant='outlined'>
                <EDRAutocomplete id="vmHosts"
                    freeSolo
                    disableClearable
                    multiple={dataMover != DataMover.CohesityRunbook ? true: false}
                    value={form.values.vmHosts? dataMover == DataMover.CohesityRunbook? form.values.vmHosts: form.values.vmHosts.split(','): []}
                    options={vraList.filter(i => form.values.vmHosts.split(',').indexOf(i) === -1 && i != '')}
                    onChange={handleVmHostChange}
                    disabled={true}
                    onInputChange={handleVmHostInputChange}
                    renderTags={(value: string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => (<EDRTextField {...params} label={dataMover == DataMover.CohesityRunbook ? "Target Profile": "Vmware Host ESXi Name/IP"}
                        InputLabelProps={{ shrink: true }}
                        disabled={true}
                        variant="outlined"
                        placeholder="Type Here"
                        aria-autocomplete="none"
                        onBlur={handleVmHostBlur}
                        error={form.touched && form.touched.vmHosts && form.errors && Boolean(form.errors.vmHosts)}
                        helperText={form.touched && form.touched.vmHosts && form.errors && Boolean(form.errors.vmHosts) && t(form.errors.vmHosts)}
                    />)}
                />
            </FormControl>
        </Grid>
</Grid>);
};

export default observer(VeeamHostsComponent);