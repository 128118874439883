import React, { useState, useEffect } from 'react';
import { Grid, Typography, makeStyles, useTheme, useMediaQuery, Tooltip } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/CheckCircle';
import CrossIcon from '@material-ui/icons/CancelRounded';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import { WorkflowStep, WorkFlowStatus, WorkflowStepType } from '../../app/models/workflowStep.model';
import { useTranslation } from 'react-i18next';
import { JobRun, JobRunSubState } from '../../app/models/jobRun.model';
import moment from 'moment';
import { PrePostScriptType } from '../../app/models/createjob/createJob.model';
import { ConvertStringToTimeSpan } from '../../app/common/util/util';

function formatTime(seconds) {
    
    if (!seconds) {
        return `0 m`;
    }
    if (seconds < 60) {
        return moment().startOf('day').seconds(seconds).format('s[s]');
    }

    if (seconds / 60 < 60) {
        return moment().startOf('day').seconds(seconds).format('m[m] s[s]');
    }

    return moment().startOf('day').seconds(seconds).format('H[h] m[m] s[s]');
}
interface JobWorkflowStep {
    type: WorkflowStepType;
    durationInSeconds: number;
    status: WorkFlowStatus
}
interface PrePostScriptsDTO {
    ttl: number;
    type: PrePostScriptType;
    scriptName:string;
    name: string;
    data: string;
}

const useStyle = makeStyles(theme => ({
    root: {
        borderRight: 0,
        "& > div:nth-child(3), div:nth-child(5)": {
            [theme.breakpoints.down("sm")]: {
                borderLeft: "1px solid lightgray"
            },
        },
        "& > div:nth-child(4)": {
            [theme.breakpoints.down("md")]: {
                borderLeft: "1px solid lightgray"
            },
        }
    },
    tile: {
        display: "flex",
        // backgroundColor:'#8bc53f',
        borderLeftColor:'white',
        justifyContent: "flex-start",
        paddingLeft: "40px",
        alignItems: "center",
        height: 48,
        marginTop: 5,
        color: 'inherit',
        '& .MuiTypography-body1': {
            fontWeight: 500
        },
        [theme.breakpoints.down("xs")]: {
            borderLeft: "1px solid lightgray"
        },
        "&:nth-child(3)": {
            background: "yellow",
        },
        "& p": {
            lineHeight: 1,
            marginLeft: 10,
            textAlign: 'left'
        }
    },
    firstArrowBox: {
        // backgroundColor:'#8bc53f',
         borderLeft: "1px solid #c7cfdb",
        paddingLeft: "20px"
    },
    
}));
function getJobWorkflowStep(workflowStep: WorkflowStep, startDate: string): JobWorkflowStep {
    if (!workflowStep)
        return null;
    const duration = moment.duration(moment(workflowStep.timeStamp).diff(moment(startDate))).asSeconds();
    if(workflowStep.workflowStatus === WorkFlowStatus.disabled)
        return null;
    if(workflowStep.workflowStatus !== WorkFlowStatus.oldValue) {
        return {
            status: workflowStep.workflowStatus,
            type: workflowStep.workflowStep,
            durationInSeconds: duration
        }
    }
    // old data without workflow status, we will determine the disabled status of test from duration
    if (duration > 1)
        return {
            status: workflowStep.workflowStatus,
            type: workflowStep.workflowStep,
            durationInSeconds: duration
        }
    else
        return null;
}
const WorkFlowIcon: React.FC<{ workflowStep: JobWorkflowStep, isRunning?: boolean, isWaiting?: boolean }> = ({ workflowStep, isRunning, isWaiting }) => {
    if (isRunning) {
        return <img src="/assets/images/running_job_loader_large.gif" style={{ width: 20, height: 20 }}></img>
    }
    if(isWaiting) {
        return <img src="/assets/images/timer-sand.png" ></img>
    }
    if (!workflowStep) {
        return <BlockIcon style={{ color: "#8092a9" }} />
        
    }
    if (workflowStep.status == WorkFlowStatus.success) {
        return <CheckIcon style={{ color: "#ffffff" }} />
    }
    if (workflowStep.status == WorkFlowStatus.failed) {
       return <ErrorIcon style={{ color: "#ffffff" }} />;
    }
    return <CheckIcon style={{ color: "#8bc53f" }} />;
}
const TestWorkFlowComponent: React.FC<{ variant:"small"| "regular" | "smallRun" | "regularRun",workflowScripts: Array<PrePostScriptsDTO> ,workflowSteps: Array<WorkflowStep>, jobRunState: JobRun, runDate: string }> = ({ variant: variant, workflowScripts, workflowSteps, jobRunState, runDate }) => {
    const [workflow, setworkflow] = useState<{ preTask: JobWorkflowStep, failover: JobWorkflowStep, serverTest: JobWorkflowStep, cleanUp: JobWorkflowStep }>({ preTask: null, failover: null, serverTest: null,cleanUp: null });
    const prefailover = workflowScripts==null || workflowScripts.length === 0 ? null : workflowScripts.find(script=> script.scriptName === "prefailover");
    const postfailover =workflowScripts==null || workflowScripts.length === 0 ? null : workflowScripts.find(script=> script.scriptName === "postfailover");
    const pretest = workflowScripts==null || workflowScripts.length === 0 ? null : workflowScripts.find(script=> script.scriptName === "pretest");
    const postest = workflowScripts==null || workflowScripts.length === 0 ? null : workflowScripts.find(script=> script.scriptName === "posttest");
    const precleanup = workflowScripts==null || workflowScripts.length === 0 ? null : workflowScripts.find(script=> script.scriptName === "precleanup"); 
    const postcleanup = workflowScripts==null || workflowScripts.length === 0 ? null : workflowScripts.find(script=> script.scriptName === "postcleanup"); 

    const [t] = useTranslation("report");
    useEffect(() => {
        if (!jobRunState) {
            let preTask = workflowSteps.find(w => w.workflowStep == WorkflowStepType.preTask);
            let failover = workflowSteps.find(w => w.workflowStep == WorkflowStepType.failover);
            let serverTest = workflowSteps.find(w => w.workflowStep == WorkflowStepType.serverTest);
            let cleanUp = workflowSteps.find(w => w.workflowStep == WorkflowStepType.cleanUp);
            setworkflow({
                preTask: getJobWorkflowStep(preTask, runDate),
                failover: getJobWorkflowStep(failover, preTask && preTask.timeStamp || runDate),
                serverTest: getJobWorkflowStep(serverTest, failover && failover.timeStamp || preTask && preTask.timeStamp || runDate),
                cleanUp: getJobWorkflowStep(cleanUp, serverTest && serverTest.timeStamp || failover && failover.timeStamp || preTask && preTask.timeStamp || runDate)
            })
        } else {
            setworkflow({
                
                        preTask: jobRunState.workflowStatusPreTest === WorkFlowStatus.disabled || ConvertStringToTimeSpan(jobRunState.preTestTime).totalSeconds <= 0 ? 
                            null: { status: jobRunState.workflowStatusPreTest, durationInSeconds: ConvertStringToTimeSpan(jobRunState.preTestTime).totalSeconds, type: WorkflowStepType.preTask },
                
                        failover: jobRunState.workflowStatusFailover === WorkFlowStatus.disabled || Math.floor(ConvertStringToTimeSpan(jobRunState.failoverTime).totalSeconds) <= 0 ? 
                            null: { status:jobRunState.workflowStatusFailover, durationInSeconds: ConvertStringToTimeSpan(jobRunState.failoverTime).totalSeconds, type: WorkflowStepType.failover },
                
                        serverTest: jobRunState.workflowStatusServerTest === WorkFlowStatus.disabled || Math.floor(ConvertStringToTimeSpan(jobRunState.serverTestTime).totalSeconds - ConvertStringToTimeSpan(jobRunState.failoverTime).totalSeconds) <= 0 ? 
                            null: { status: jobRunState.workflowStatusServerTest, durationInSeconds: ConvertStringToTimeSpan(jobRunState.serverTestTime).totalSeconds, type: WorkflowStepType.serverTest } ,
                
                        cleanUp: jobRunState.workflowStatusCleanUp === WorkFlowStatus.disabled ||Math.floor(ConvertStringToTimeSpan(jobRunState.cleanUpTime).totalSeconds - ConvertStringToTimeSpan(jobRunState.snapshotTime).totalSeconds) <= 0 ? 
                            null: { status: jobRunState.workflowStatusCleanUp, durationInSeconds: ConvertStringToTimeSpan(jobRunState.cleanUpTime).totalSeconds, type: WorkflowStepType.cleanUp }
            });
        }
    }, [workflowSteps])
    const theme = useTheme();
    const classes = useStyle();
    return <Grid container direction="row" className={classes.root} >
        <Grid className="p-max-20" item xs={12} sm={6} md={4} lg>
            <div className={clsx(classes.firstArrowBox, classes.tile, "arrow_box", {["arrow_box_failed"]: workflow.preTask && workflow.preTask.status === WorkFlowStatus.failed, ["arrow_box_passed"]: workflow.preTask && workflow.preTask.status === WorkFlowStatus.success})}>
                <WorkFlowIcon workflowStep={workflow.preTask} isRunning={jobRunState && jobRunState.currentRunSubState == JobRunSubState.PreTestTask} isWaiting={jobRunState && jobRunState.currentRunSubState < JobRunSubState.PreTestTask} />
                <span data-test="dataCollectionLabel">
                    <Typography variant="body1" >    
                        {t("testWorkflow.dataCollection")}
                    </Typography>
                    <Typography variant="body2" >
                        {formatTime(workflow.preTask && workflow.preTask.durationInSeconds)}
                    </Typography>
                </span>
            </div>
        </Grid>
        <Grid className="p-max-20" item xs={12} sm={6} md={4} lg>
            <div className={clsx(classes.tile, "arrow_box", {["arrow_box_failed"]: workflow.failover && workflow.failover.status === WorkFlowStatus.failed, ["arrow_box_passed"]: workflow.failover && workflow.failover.status === WorkFlowStatus.success})}>
                <WorkFlowIcon workflowStep={workflow.failover} isRunning={jobRunState && jobRunState.currentRunSubState == JobRunSubState.Failover} isWaiting={jobRunState && jobRunState.currentRunSubState < JobRunSubState.Failover}/>
                {prefailover && prefailover != null ? <Tooltip title={t('prefailover') + prefailover.name}><img src="/assets/images/reportIcons/workflowPrescriptWhite.svg" style={{marginLeft:'5px'}}></img></Tooltip> : null}
                <span data-test="failoverLabel">
                    <Typography variant="body1" >{t("testWorkflow.failover")}</Typography>
                    <Typography variant="body2" >{formatTime(workflow.failover && workflow.failover.durationInSeconds)}</Typography>
                </span>
                {<span style={{display:'flex',paddingLeft: variant.includes("small") ? '14%':'30%'}}>
                    {postfailover && postfailover != null ? <Tooltip title={t('postfailover') + postfailover.name}><img src="/assets/images/reportIcons/workflowPostscriptWhite.svg"></img></Tooltip> : null}
                </span>}
            </div>
        </Grid>
        <Grid className="p-max-20" item xs={12} sm={6} md={4} lg>
            <div className={clsx(classes.tile, "arrow_box", {["arrow_box_failed"]: workflow.serverTest && workflow.serverTest.status === WorkFlowStatus.failed,["arrow_box_passed"]: workflow.serverTest && workflow.serverTest.status === WorkFlowStatus.success})}>
                <WorkFlowIcon workflowStep={workflow.serverTest} isRunning={jobRunState && jobRunState.currentRunSubState == JobRunSubState.ServerTest} isWaiting={jobRunState && jobRunState.currentRunSubState < JobRunSubState.ServerTest} />
                {pretest && pretest !== null ? <Tooltip title={t('pretest') + pretest.name}><img src="/assets/images/reportIcons/workflowPrescriptWhite.svg" style={{marginLeft:'5px'}}></img></Tooltip> : null}
                <span data-test="serverTestLabel">
                    <Typography variant="body1">{t("testWorkflow.serverTest")}</Typography>
                    <Typography variant="body2">{formatTime(workflow.serverTest && workflow.serverTest.durationInSeconds)}</Typography>
                </span>
                {<span style={{display:'flex',paddingLeft: variant.includes("small") ?'8px':'0%'}}>
                    {postest && postest !== null ? <Tooltip title={t('posttest') +postest.name}><img src="/assets/images/reportIcons/workflowPostscriptWhite.svg" ></img></Tooltip> : null}
                </span>}
            </div></Grid>

        <Grid className="p-max-20"item xs={12} sm={6} md={4} lg>
            <div className={clsx(classes.tile, "arrow_box", {["arrow_box_failed"]: workflow.cleanUp && workflow.cleanUp.status === WorkFlowStatus.failed, ["arrow_box_passed"]: workflow.cleanUp && workflow.cleanUp.status === WorkFlowStatus.success})}>
                <WorkFlowIcon workflowStep={workflow.cleanUp} isRunning={jobRunState && jobRunState.currentRunSubState == JobRunSubState.CleanUp} isWaiting={jobRunState && jobRunState.currentRunSubState < JobRunSubState.CleanUp} />
                {precleanup && precleanup !== null ? <Tooltip title={t('precleanup') + precleanup.name}><img src="/assets/images/reportIcons/workflowPrescriptWhite.svg" style={{marginLeft:'5px'}}></img></Tooltip>: null}
                <span data-test="cleanupLabel">
                    <Typography variant="body1">{t("testWorkflow.cleanup")}</Typography>
                    <Typography variant="body2">{formatTime(workflow.cleanUp && workflow.cleanUp.durationInSeconds)}</Typography>
                </span>
                {<span style={{display:'flex',paddingLeft:variant.includes("small") ?'14%':'30%'}}>
                    {postcleanup && postcleanup !== null ? <Tooltip title={t('postcleanup') + postcleanup.name}><img src="/assets/images/reportIcons/workflowPostscriptWhite.svg"></img></Tooltip>: null}
                </span>}
            </div>
        </Grid>
        <Grid item style={{ width: "24px" }}></Grid>

    </Grid>
}
export default TestWorkFlowComponent;