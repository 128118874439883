import  SvgIcon  from "@material-ui/core/SvgIcon";
import React from "react"
import {ReactComponent as InternetConnectionSvg} from './svgs/internetconnection.svg';

const InternetConnectionIcon:React.FC<React.HTMLProps<SVGElement>> = (props) => {
    const {style, className} = props;
    return <SvgIcon viewBox={"0,0, 20, 20"}  className={className} style={style} component={InternetConnectionSvg}>
    </SvgIcon>
}


export default InternetConnectionIcon;