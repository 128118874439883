import { createMuiTheme } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';

export default createMuiTheme({
  palette: {
    primary: {
      dark: '#2892d7',
      light: '#2892d7',
      main: '#2892d7'
    },
    secondary: green,
    text: {
      
    },
    error: {
      main: '#077187'
    }
  },
  typography: {
    h3: {
      width: "45.8%",
      height: "3.7%",
      objectFit: "contain",
      fontFamily: "Roboto",
      fontSize: "30px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.67",
      letterSpacing: "normal",
      textAlign: "left",
      color: "#1f396d"
    },
    body1: {
      color: '#4d5f75'
    },
    body2 :{
      color: '#8092a9'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1700,
    }
  }

});