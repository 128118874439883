import { Button, Grid, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { RouteComponentProps, RouteProps, RouterProps } from 'react-router'
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RootStoreContext } from '../../app/stores/root.store'
import CreateJobComponent from '../createJob/createJob.component';

function JobStateReducer(state: { jobLoaded: boolean, hasError: boolean }, action: { type: 'jobLoaded' | 'error', arg?: any }) {
    switch (action.type) {
        case 'jobLoaded': return { jobLoaded: true, hasError: false };
        case 'error': return { jobLoaded: false, hasError: true };
        default: return { ...state }
    }
}

const EditJobComponent: React.FC<RouteComponentProps> = (props) => {
    const { createJobStore } = useContext(RootStoreContext);
    const {history} = props;
    const [state, dispatch] = useReducer(JobStateReducer, { jobLoaded: false, hasError: false })

    useEffect(() => {
        const jobId = props.match.params['jobId'];
        if (!jobId)
            return;
        createJobStore.getJob(jobId, false).then(res => {
            dispatch({ type: 'jobLoaded' })
        }).catch(err => {
            dispatch({ type: 'error' })
            toast.error(`failed to load job with job id ${jobId}`);
            history.push("/jobs");
        })
    }, [props.match.params['jobId']])
    const { jobLoaded, hasError } = state;
    const jobId = props.match.params['jobId'];
    return (
        <React.Fragment>
            {jobLoaded && <CreateJobComponent {...props} />}
            {hasError && <Grid spacing={2} container direction="column" justifyContent='center' alignItems='center' style={{ height: '100vh' }}>
                <Grid item>
                    <Typography variant='h4'>Failed to load Job with Id {jobId}.</Typography>
                </Grid>
                <Grid item container justifyContent='center' spacing={2}>
                    <Grid item>
                        <NavLink to="/createJob">
                            <Button variant='contained' color='primary'>Create Job</Button>
                        </NavLink>
                    </Grid>
                    <Grid item>
                        <NavLink to="/jobs">
                            <Button variant='contained' color='primary'>View Jobs</Button>
                        </NavLink>
                    </Grid>
                </Grid>
            </Grid>}
        </React.Fragment>

    )
}

export default EditJobComponent;