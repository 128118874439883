import React, {useState} from 'react';
import { JobDeviceSearch } from '../models/jobDevice/jobDeviceSearch.model';

/**
 * Component to preserve DeviceSearch results on toggle button change.
 * On Toggle Button change in filter section , device search results were not retained on component unmount.
 * This component helps to preserver those search results
 */

export const DeviceSearchContext = React.createContext<{searchResults: Array<JobDeviceSearch>; setSearchResults:(value: any) => void}>({ searchResults: [], setSearchResults: val => {}});


const JobDeviceSearchProvider: React.FC = (props) => {
    const [value, setValue] = useState<Array<JobDeviceSearch>>(null);
    return <DeviceSearchContext.Provider value={{searchResults: value, setSearchResults: setValue}}>
            {props.children}
    </DeviceSearchContext.Provider>
}

export default JobDeviceSearchProvider;
