import agent from "../api/settingAgent";
import { observable, action, runInAction, reaction } from "mobx";
import { settingObj, countryObj } from "../models/setting.model";
import { RootStore, RootStoreContext } from './root.store';
import { toast } from "react-toastify";
import moment, { now } from "moment";
import { useContext } from "react";

export default class SettingStore {

    rootStore: RootStore;
    constructor(rootStore:RootStore) {
        this.rootStore = rootStore;
        reaction(() => rootStore.userStore.isLoggedIn, () => {
            if(rootStore.userStore.isLoggedIn){
                this.getSettings();
            }
        } )
    }

    @observable reportSettings: Array<settingObj> = [];
    @observable locationSettings: Array<settingObj> = [];
    @observable dashboardSettings: Array<settingObj> = [];
    @observable updatedSetting: Array<settingObj> = [];
    @observable administrationSettings: Array<settingObj> = [];
    @observable backupSettings: Array<settingObj> = [];
    @observable monitoringSettings: Array<settingObj> = [];
    @observable emailSettings: Array<settingObj> = [];
    @observable createJobSettings: Array<settingObj> = [];
    @observable settingsUpdateError = false;
    @observable settingUpdateSuccess = false;
    @observable loadingSettings = false;
    @observable settingsLoaded = false;
    @observable currentCountryCode : string = "IL";
    @observable contentValid = true;
    @observable pointCounter = 0;
    @observable uploadingPhoto = false;
    @observable currentLogo : string = "";
    @observable dialogOpen = false;
    @observable emailListValid = false;
    
    @action setPointCounter = (val: number) => {
        this.pointCounter = val;
    }

    @action setContentValid = (val:boolean) => {
        this.contentValid = val;
    }
    @action setEmailListValid = (val:boolean) => {
        this.emailListValid = val;
    }
    @action getCurrentCountry = async() => {
        try {
            var country:settingObj = await agent.Setting.getSingle('Country');
            let countryCode = country && JSON.parse(country.settingValue).code || 'US';
            runInAction(()=> {
                this.currentCountryCode = countryCode;
            })
            return countryCode;
            
        }
        catch (error) {
            runInAction(() => {
                this.currentCountryCode = "US";
            })
            console.log(error);
        }
    }

    @action updateCurrentCountry(countryCode: string) {
        this.currentCountryCode = countryCode;
    }

    @action updateSettingObj = (value,key,section) => {
        if (this.updatedSetting!=null){      
               let pos = this.updatedSetting.map(function(e) { return e.settingKey; }).indexOf(key);
               if (pos > -1) {
                   this.updatedSetting.splice(pos, 1);
               }
            //    if (value === "") {
            //         toast.warn("Please make sure all Dashboard section fields are filled")
            //         this.contentValid = false;
            //    }
            //     else
            //         this.contentValid = true;
                const setting = this.updatedSetting.find(s => s.settingKey == key);
                if(setting) {
                    this.updatedSetting= this.updatedSetting.map(s => {
                            if(s == setting)
                             return {settingKey:key, settingValue:value, settingSection:section}
                            else
                             return s;
                        })
                } else {
                     this.updatedSetting.push({settingKey:key, settingValue:value, settingSection:section});
                    }

       }
    }

    @action updateReportObj = (value,key,section) => {
               if ((value === "")) 
                    this.contentValid = false;
                else if((value.length< 6) ||(value.length > 6))
                    this.contentValid = false;
                else
                    this.contentValid = true;
               const setting = this.updatedSetting.find(s => s.settingKey == key);
               if(setting) {
                this.updatedSetting=   this.updatedSetting.map(s => {
                       if(s == setting)
                        return {settingKey:key, settingValue:JSON.stringify(value), settingSection:section}
                       else
                        return s;
                   })
               } else {
                this.updatedSetting.push({settingKey:key, settingValue:JSON.stringify(value), settingSection:section});
               }     
       }

    @action updateBackupObj = (value,key,section) => {
        if (this.updatedSetting!=null){      
            let pos = this.backupSettings.map(function(e) { return e.settingKey; }).indexOf(key);
            if (pos > -1) {
                this.backupSettings.splice(pos, 1);
            }
                 
            this.backupSettings.push({settingKey:key, settingValue:value, settingSection:section});
        }
    }

    @action updateMonitoringObj = (value,key,section) => {
        if (this.updatedSetting!=null){      
               let pos = this.monitoringSettings.map(function(e) { return e.settingKey; }).indexOf(key);
               if (pos > -1) {
                   this.monitoringSettings.splice(pos, 1);
               }
            //    if (value === "") {
            //         toast.warn("Please make sure all Monitoring section fields are filled")
            //         this.contentValid = false;
            //    }
                    
               this.monitoringSettings.push({settingKey:key, settingValue:value, settingSection:section});
       }
    }

    @action updateLocationObj = (value,key,section) => {
        if (this.locationSettings!=null){ 
                if (key==="Country"){
                    this.locationSettings = [];
                }
                else{
                    let pos = this.locationSettings.map(function(e) { return e.settingKey; }).indexOf(key);
                    if (pos > -1) {
                        this.locationSettings.splice(pos, 1);
                    }
                }
               
               if (value!="remove")
                    this.locationSettings.push({settingKey:key, settingValue:value, settingSection:section});
            //   let countryObj = this.locationSettings.filter(e => e.settingKey==="Country");
            //   let emptyVal = [];
            //   if (countryObj.length>0){
            //        emptyVal = countryObj.filter(j => j.settingValue === "none");
            //   }
            //    if (this.locationSettings.length<3 || countryObj.length===0 || emptyVal.length>0){
            //         toast.warn("Please make sure all Location section fields are filled")
            //         this.contentValid = false; 
            //    }
                        
       }
    }

    @action getSettings = async () => {
        this.loadingSettings = true;
        this.settingsUpdateError = false;
        try {
            const settingsList:Array<settingObj> = await agent.Setting.get();
            runInAction(() => {
                this.reportSettings = [];
                this.dashboardSettings = [] ;
                this.locationSettings = [];
                this.emailSettings = [];
                this.backupSettings = [];
                this.monitoringSettings = [];
                Object.keys(settingsList).forEach(key => {
                     switch(settingsList[key].settingSection.toLowerCase()){
                        case "report": 
                            this.reportSettings.push(settingsList[key]);
                            break;             
                        case "dashboard": 
                            this.dashboardSettings.push(settingsList[key]);
                            break;  
                        case "location": 
                            this.locationSettings.push(settingsList[key]);
                            break; 
                        case "backup": 
                            this.backupSettings.push(settingsList[key]);
                            break; 
                        case "monitoring":
                            this.monitoringSettings.push(settingsList[key]);
                            break;
                        case "createjob":
                            this.createJobSettings.push(settingsList[key]);
                            break;
                        case "email":
                            if (settingsList[key].settingKey.toLowerCase()!="companylogo"){
                                this.emailSettings.push(settingsList[key]);
                                break;
                            }
                        default: 
                            break;
                       }
                })
                //set default values in case keys were not created
                console.log(this.emailSettings)
                if (this.emailSettings.map(function(e) { return e.settingKey; }).indexOf("SMTPType") === -1){
                    this.emailSettings.push({settingKey:"SMTPType", settingValue:"system",settingSection:"Email"});
                }
                if (this.reportSettings.map(function(e) { return e.settingKey; }).indexOf("AutoPlayAnimation") === -1){
                    this.reportSettings.push({settingKey:"AutoPlayAnimation", settingValue:"true",settingSection:"Report"});
                }
                if (this.reportSettings.map(function(e) { return e.settingKey; }).indexOf("AddAttachments") === -1){
                    this.reportSettings.push({settingKey:"AddAttachments", settingValue:"true",settingSection:"Email"});
                }
                if (this.dashboardSettings.map(function(e) { return e.settingKey; }).indexOf("Graph") === -1){
                    this.dashboardSettings.push({settingKey:"Graph", settingValue:"AVG",settingSection:"Dashboard"});
                }
                if (this.dashboardSettings.map(function(e) { return e.settingKey; }).indexOf("RTO") === -1){
                    this.dashboardSettings.push({settingKey:"RTO", settingValue:"AVG",settingSection:"Dashboard"});
                }
                if (this.dashboardSettings.map(function(e) { return e.settingKey; }).indexOf("ShowNextAuditDate") === -1){
                    this.dashboardSettings.push({settingKey:"ShowNextAuditDate", settingValue:"true",settingSection:"Dashboard"});
                }
                if (this.dashboardSettings.map(function(e) { return e.settingKey; }).indexOf("NextAuditDate") === -1){
                    this.dashboardSettings.push({settingKey:"NextAuditDate", settingValue: moment(Date.now()).format('MMM DD, YYYY'),settingSection:"Dashboard"});
                }
                if (this.backupSettings.map(function(e) { return e.settingKey; }).indexOf("BackupCount") === -1){
                    this.backupSettings.push({settingKey:"BackupCount", settingValue:"10",settingSection:"Backup"});
                }
                if (this.backupSettings.map(function(e) { return e.settingKey; }).indexOf("BackupCount") === -1){
                    this.backupSettings.push({settingKey:"BackupPath", settingValue:"C:\Data",settingSection:"Backup"});
                }
                if (this.dashboardSettings.map(function(e) { return e.settingKey; }).indexOf("BackupInterval") === -1){
                    this.dashboardSettings.push({settingKey:"BackupInterval", settingValue:"24",settingSection:"Backup"});
                }
                if (this.monitoringSettings.map(function(e) { return e.settingKey; }).indexOf("MonitoringInterval") === -1){
                    this.monitoringSettings.push({settingKey:"MonitoringInterval", settingValue:"10",settingSection:"Monitoring"});
                }
                if (this.monitoringSettings.map(function(e) { return e.settingKey; }).indexOf("BypassMonitoring") === -1){
                    this.monitoringSettings.push({settingKey:"BypassMonitoring", settingValue:"false",settingSection:"Monitoring"});
                }
                if (this.monitoringSettings.map(function(e) { return e.settingKey; }).indexOf("DisableNotifications") === -1){
                    this.monitoringSettings.push({settingKey:"DisableNotifications", settingValue:"false",settingSection:"Monitoring"});
                }
                if (this.monitoringSettings.map(function(e) { return e.settingKey; }).indexOf("CPUPeakPercentageHighThreshold") === -1){
                    this.monitoringSettings.push({settingKey:"CPUPeakPercentageHighThreshold", settingValue:"90",settingSection:"Monitoring"});
                }
                if (this.monitoringSettings.map(function(e) { return e.settingKey; }).indexOf("CPUPeakPercentageThreshold") === -1){
                    this.monitoringSettings.push({settingKey:"CPUPeakPercentageThreshold", settingValue:"50",settingSection:"Monitoring"});
                }
                if (this.monitoringSettings.map(function(e) { return e.settingKey; }).indexOf("LowMemoryThresholdInMB") === -1){
                    this.monitoringSettings.push({settingKey:"LowMemoryThresholdInMB", settingValue:"500",settingSection:"Monitoring"});
                }
                if (this.monitoringSettings.map(function(e) { return e.settingKey; }).indexOf("LowDiskSpaceThresholdInGB") === -1){
                    this.monitoringSettings.push({settingKey:"LowDiskSpaceThresholdInGB", settingValue:"5",settingSection:"Monitoring"});
                }
                this.loadingSettings = false;
                this.settingsLoaded = true;
            })
        } catch (error) {
            runInAction(() => {
                this.loadingSettings = false;
            })
            console.log(error);    
        }
    }

    @action deleteSettings = async (settings: any) => {
        this.loadingSettings = true;
        console.log(settings)
        try{
            await agent.Setting.delete(settings).then(res=>{
                this.getSettings();
            });
            runInAction(() => 
            {
                this.settingsUpdateError = false;
                this.loadingSettings = false;
                this.settingUpdateSuccess = true;
            })
        } catch (error) 
        {
            runInAction(() => {
                this.settingsUpdateError = true;
                this.settingUpdateSuccess = false;
                this.loadingSettings = false;
            })
            console.error('Problem updating settings');
        }
    }
    @action updateSettings = async (settings: any) => {
        this.loadingSettings = true;
        console.log(settings)
        try{
            await agent.Setting.update(settings);
            //await this.getSettings();
            runInAction(() => {
                this.settingsUpdateError = false;
                this.loadingSettings = false;
                this.settingUpdateSuccess = true;
            })
        } catch (error) {
            runInAction(() => {
                this.settingsUpdateError = true;
                this.settingUpdateSuccess = false;
                this.loadingSettings = false;
            })
            console.error('Problem updating settings');
        }
    }

    @action getLogo = async () => {
        try {
            var photo = await agent.Setting.getPhoto();
            runInAction(()=> {
                if (photo.content!=undefined)
                    this.currentLogo = `data:image/jpeg;base64,${photo.content}`
            })
        }
        catch (error) {
            console.log(error);
        }
    }

    @action uploadLogo = async (file: Blob) => {
        this.uploadingPhoto = true;
        try {
            const photo = await agent.Setting.uploadPhoto(file);
            runInAction(() => {
                if (photo.content!=undefined)
                    this.currentLogo = `data:image/jpeg;base64,${photo.content}`
                this.uploadingPhoto = false;
                this.dialogOpen = false;
            })
        } catch (error) {
            console.log(error)
            toast.error("Problem uploading photo");
            runInAction(() => {
                this.uploadingPhoto = false;
                this.dialogOpen = false;
            })
        }
    }

    @action setOpen = (state: boolean) => {
        this.dialogOpen = state;
    }

    @action updateEmailObj = (value,key,section) => {
        if (this.emailSettings!=null && key!="CompanyLogo"){
            // if( section!= "CustomEmail" && value === "") 
            // {
            //     toast.warn("Please make sure all Email section fields are filled")
            //     this.contentValid = false;    
            // }
            // else
            //     this.contentValid = true;
            
              let smtpTypeObj = this.emailSettings.filter(e=> e.settingKey==="SMTPType") && this.emailSettings.filter(e=> e.settingKey==="SMTPType").length>0 ? this.emailSettings.filter(e=> e.settingKey==="SMTPType")[0] : {settingKey:"SMTPType", settingValue:"system",settingSection:"Email"};
              if ((key==="SMTPType" && value==="organization") || (smtpTypeObj.settingKey==="SMTPType" && smtpTypeObj.settingValue==="organization")){
                    let emailToObj = key==="EmailTo"? value: this.emailSettings.filter(e=> e.settingKey==="EmailTo").length>0? this.emailSettings.filter(e=> e.settingKey==="EmailTo")[0].settingValue : "";
                    let emailFromObj = key==="EmailFrom"? value: this.emailSettings.filter(e=> e.settingKey==="EmailFrom").length>0? this.emailSettings.filter(e=> e.settingKey==="EmailFrom")[0].settingValue: "";
                    let smtpAddressObj = key==="LocalSMTPAddress"? value: this.emailSettings.filter(e=> e.settingKey==="LocalSMTPAddress").length>0? this.emailSettings.filter(e=> e.settingKey==="LocalSMTPAddress")[0].settingValue: "";
                    let smtpPortObj = key==="LocalSMTPPort"? value: this.emailSettings.filter(e=> e.settingKey==="LocalSMTPPort").length>0? this.emailSettings.filter(e=> e.settingKey==="LocalSMTPPort")[0].settingValue: "";
                    if (emailToObj==="" || emailFromObj==="" || smtpAddressObj==="" || smtpPortObj==="")
                        this.contentValid = false; 
                    else
                        this.contentValid = true;
                }
                else {
                    let emailToObj = key==="EmailTo"? value: this.emailSettings.filter(e=> e.settingKey==="EmailTo").length>0? this.emailSettings.filter(e=> e.settingKey==="EmailTo")[0].settingValue: "";
                    if (emailToObj==="")
                        this.contentValid = false;
                    else
                        this.contentValid = true;
                } 
                let pos = this.emailSettings.map(function(e) { return e.settingKey; }).indexOf(key);
                if (pos > -1) {
                    this.emailSettings.splice(pos, 1);
                }               
                this.emailSettings.push({settingKey:key, settingValue:value, settingSection:section});
            }
    }

    @action sendTestEmail = async (smtpkey,defaultEmail:boolean) => {
        let emailObj ;
        if(defaultEmail==true)
        emailObj ={
            "smtpType": "",
            "smtpServer": "smtp.sendgrid.net",
            "smtpPort": "587",
            "toAddress": smtpkey as string,
            "username": "apikey",
            "password": "uGmsOFSu3kDqZzWTHxe7UuNuRMygnrA/BPCeg1G7+7QoOhh5vSZkvR/raxWI2fVLqXTO+QBx/J4CEUdXqPkhvjmFSr4S3UPkRI+LqbywDs5HswnEW9RhbT4zyw5CcVS7",
            "fromAddress": "support@ensuredr.com",
            "allowanonymous": "",
            "useOrgCreds": "false"
        };
        else
        emailObj = {
            "smtpType": "",
            "smtpServer": "",
            "smtpPort": "",
            "toAddress": "",
            "username": "",
            "password": "",
            "fromAddress": "",
            "allowanonymous": "",
            "useOrgCreds": "false"
        };
        try {
            //const valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            let sendIt = true;
            if(defaultEmail==false)
            {
                runInAction(() => {
                    Object.keys(this.emailSettings).forEach(key => {
                        switch(this.emailSettings[key].settingKey.toLowerCase()){
                            case "smtptype":
                                emailObj.smtpType = this.emailSettings[key].settingValue;
                                break;
                            case "allowanonymous":
                                emailObj.allowanonymous = this.emailSettings[key].settingValue;
                                break;
                            case "localsmtpaddress":
                                emailObj.smtpServer = this.emailSettings[key].settingValue;
                                break;
                            case "localsmtpport":
                                emailObj.smtpPort = this.emailSettings[key].settingValue;
                                break;
                             case "emailto":
                                emailObj.toAddress = this.emailSettings[key].settingValue;
                                 break;
                            case "smtpuser":
                                emailObj.username = this.emailSettings[key].settingValue;
                                break;
                            case "smtppassword":
                                emailObj.password = this.emailSettings[key].settingValue;
                                break;
                            case "emailfrom":                                
                                emailObj.fromAddress = this.emailSettings[key].settingValue;
                                break;
                            case "useorgcreds":
                                emailObj.useOrgCreds = this.emailSettings[key].settingValue;
                                break;
                            default:
                                break;
        
                        }
                    })
                })
            }
        let res: string = "";
        if (sendIt){
            if(emailObj.toAddress == '')
            {
                toast.success("Email To addresses list is empty, please add email seperated by semicolumn")
            }
            res = await agent.Setting.sendTestEmail(emailObj);
            if (res === "Success")    
                toast.success("Test Email Was Sent Successfully")
            else if(res === "SuccessFallback")
                toast.success("Test Email Request Was Sent Successfully")
            else
                toast.warn("Test Email Failed. Please Adjust SMTP Settings");
        }
        }
        catch(err){
            console.log(err);
            toast.warn("Test Email Failed. Please Adjust SMTP Settings");
        }
    }




    
}