import { Button, Card, CircularProgress, Divider, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField, Tooltip, Typography} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import HelpIcon from '@material-ui/icons/Help';
import { useTranslation } from 'react-i18next';
import { RootStoreContext } from '../../../app/stores/root.store';
import { DnsResolveType, JobType, LicenseType, NetworkOption, NetworkType } from '../../../app/models/jobProperties.model';
import jobAgent from '../../../app/api/jobAgent';
import { toast } from 'react-toastify';
import { runInAction } from 'mobx';
import { Autocomplete } from '@material-ui/lab';
import { EDRAutocomplete } from '../../../app/common/EDRAutocomplete';
import { EDRTextField } from '../../../app/common/EDRTextField';
import CreateJobStore from '../../../app/stores/createJob.store';
import ConfirmationDialog from '../../../app/common/confirmationDialog.component';
import Create from '@material-ui/icons/Create';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { NetworkTuppleDTO } from '../../../app/models/createjob/createJob.model';
import LockIcon from '@material-ui/icons/Lock';

const TupleComponent: React.FC = (props) => {
    const [t] = useTranslation("createJobNetwork");
    const rootStore = useContext(RootStoreContext);
    const {jobSettingsStore,createJobStore,displayStore} = rootStore;
    
    const isEdit =window.location.href.includes('edit');
    const networkType = createJobStore.networkSetting.NetworkType;
    
    //edit mode variabled
    const [tupleValue, setTupleValue] = useState<NetworkTuppleDTO>(null);
    const [DisableOption, setDisableOption] = useState(networkType == NetworkOption.Single ? false: true);
    const {isFirstTrial} = jobSettingsStore;
    const useStyles = makeStyles((theme) => ({
        editIcon: {
            cursor: 'pointer'
        },
        button:{
            width: '50%',
            height: '100%',
            borderRadius: '4px',
            border: 'solid 1px #c7cfdb',
            backgroundColor: '#fafafc'
        },
        advanced:{
            width: '14px',
            height: '14px',
            objectFit: 'contain',
        },
        MainHeading:{
            fontFamily: 'Roboto',
            color: '#1f396d',
            fontSize: '16px'
        },
        SecondHeading:{
            fontFamily:'Roboto',
            fontWeight:500,
            fontSize:'100%',
            fontStretch:'normal',
            fontStyle: 'normal',
            lineHeight:'1.14',
            letterSpacing:'normal',
            textAlign:'left'
        },
        card: {
            padding:25,
            height: '100%',
            overflowY: "auto"
        },
        helpIcon:
        {
            color: '#1976d2',
            fontSize: "large"
        },
        formControl: {
            width: '100%',
            '& label': {
                background: "white",
                width: "auto",
                padding:"0 4px 0 4px",
                fontSize: '17px'
              }
        },
        divider:{
            backgroundColor: '#eaeef4',
            width:'85%',
            margin:'0',
            marginTop: '8px',
            marginLeft: '10px'
        }
    }));    
    const classes = useStyles();

    useEffect(() => {
        
            if(createJobStore.baseSettings.networkTuppleId != 0 && createJobStore.baseSettings.networkTuppleId != null)
            {
                if(jobSettingsStore.networkTuppleList.some(i => i.id == createJobStore.baseSettings.networkTuppleId))
                {
                    let networkTuple= jobSettingsStore.networkTuppleList.find(item=>item.id== createJobStore.baseSettings.networkTuppleId);
                    jobSettingsStore.updateSelectedNetworkTupple(networkTuple);
                    let mapping= [{sourceNetwork:networkTuple.vmNetworkName,targetNetwork: networkTuple.drNetworkName}];
                    createJobStore.updateNetworkSettings({testDRNetwork: {...rootStore.createJobStore.networkSetting.testDRNetwork,networkMapping: mapping }});
                    setTupleValue(networkTuple);
                }
                else
                {
                    if(jobSettingsStore.anyNetworkTupples)
                    {
                        let networkTuple= jobSettingsStore.networkTuppleList[0];
                        jobSettingsStore.updateSelectedNetworkTupple(networkTuple);
                        createJobStore.updateBaseSettings({networkTuppleId:networkTuple.id})
                        let mapping= [{sourceNetwork:networkTuple.vmNetworkName,targetNetwork: networkTuple.drNetworkName}];
                        createJobStore.updateNetworkSettings({testDRNetwork: {...rootStore.createJobStore.networkSetting.testDRNetwork,networkMapping: mapping }});
                        setTupleValue(networkTuple);
                    }
                    else
                    {
                        setTupleValue(null);
                    }
                }
            }
            else
            {
                runInAction(() => {
                    if(jobSettingsStore.anyNetworkTupples)
                    {
                        let networkTuple= jobSettingsStore.networkTuppleList[0];
                        jobSettingsStore.updateSelectedNetworkTupple(networkTuple);
                        createJobStore.updateBaseSettings({networkTuppleId:networkTuple.id})
                        let mapping= [{sourceNetwork:networkTuple.vmNetworkName,targetNetwork: networkTuple.drNetworkName}];
                        createJobStore.updateNetworkSettings({testDRNetwork: {...rootStore.createJobStore.networkSetting.testDRNetwork,networkMapping: mapping }});
                        setTupleValue(networkTuple);
                    }
                    else
                    {
                        setTupleValue(null);
                    }
                });
                    
            }
            displayStore.updateNextStepValid({networkMappingValid: true,networkSettingValid:true});
      }, [jobSettingsStore.networkTuppleList])

    const loadCircularProgress =()=>{
        return <div style={{width:'100%',display:'flex'}}>
        {t('InProgress')}
        <CircularProgress id="NetworkValidation" size={18} style={{color: '#2892D7', marginLeft: '10px',marginTop: '-3px'}}/>
        </div>
    }

    const handleNetworkPairChange =(event: any, value: any) => {
        runInAction(() => {
            let networkTuple= jobSettingsStore.networkTuppleList.find(item=>item.id==event.target.value);
            jobSettingsStore.updateSelectedNetworkTupple(networkTuple);
            setTupleValue(networkTuple);
            createJobStore.updateBaseSettings({networkTuppleId:event.target.value})
            let drNetwork = jobSettingsStore.vCenterNetworks.find(item=>item.id === networkTuple.vmNetworkId);
            let bubbleNetwork = jobSettingsStore.vCenterNetworks.find(item=>item.id === networkTuple.drNetworkId);
            //setValueDr(drNetwork.name);
            //setValueBubble(bubbleNetwork.name);
            let mapping= [{sourceNetwork:drNetwork.name,targetNetwork: bubbleNetwork.name}];
            rootStore.createJobStore.updateNetworkSettings({testDRNetwork: {...rootStore.createJobStore.networkSetting.testDRNetwork,networkMapping: mapping }});
            displayStore.updateNextStepValid({networkMappingValid: true,networkSettingValid:true});
            if(displayStore.NetworkDrawerSettings.testDrawerOpen)
            {
                displayStore.updateScreenSettings({ displayNetwork: 'EmptyVlan' });
                displayStore.updateNetworkDrawerSettings({testDrawerOpen:false});
            }
        })
        
    }

    const handleAddNetworkPair =()=>{
        displayStore.updateScreenSettings({displayNetwork: "AdvancedVlan"});
        displayStore.updateScreenSettings({AddModeNetworks: true});
        displayStore.updateNetworkDrawerSettings({testDrawerOpen: true});
        displayStore.updateScreenSettings({displayInfo: "InfoDR"}); 
        displayStore.closeAllOtherBaseDrawersExcept("testDrawer");
    }

    const buttonRedirect =(subComponent:any)=>{
        if(displayStore.NetworkDrawerSettings.testDrawerOpen)
        {
          if(displayStore.screenSettings.AddModeNetworks)
          {
            displayStore.updateScreenSettings({displayNetwork: subComponent});
            displayStore.updateScreenSettings({AddModeNetworks: false});
            displayStore.updateNetworkDrawerSettings({testDrawerOpen: true});
            displayStore.updateScreenSettings({displayInfo: "InfoDR"}); 
            displayStore.closeAllOtherBaseDrawersExcept("testDrawer");
          }
          else
          {
            displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
            displayStore.updateNetworkDrawerSettings({testDrawerOpen: false});
          }
        }
        else
        { 
          displayStore.updateScreenSettings({displayNetwork: subComponent});
          displayStore.updateScreenSettings({AddModeNetworks: false});
          displayStore.updateNetworkDrawerSettings({testDrawerOpen: true});
          displayStore.updateScreenSettings({displayInfo: "InfoDR"}); 
          displayStore.closeAllOtherBaseDrawersExcept("testDrawer");
        }  
    }
    const loadDREditSection = () => {
        switch(rootStore.createJobStore.baseSettings.jobType)
        {
            case JobType.Test:
                {
                    return (<Grid container spacing={2} style={{ display: 'flex',justifyContent:'center',marginTop:'3%' }}>
                    
                        <Grid item xs={1} style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                        {isFirstTrial  ? 
                        <Tooltip title={t("trialLock")} arrow>
                            <LockIcon />
                        </Tooltip>
                        :<IconButton onClick={()=> handleAddNetworkPair()} disabled={true}>
                            <AddCircleOutlineIcon/>
                        </IconButton>}
                        </Grid>
                        <Grid item container xs={11}>
                        <Grid item xs={11}>
                        <FormControl className={classes.formControl} variant='outlined' style={{backgroundColor:'#ffffff'}}>
                        <InputLabel style={{transform:'translate(14px, -7px) scale(0.75)'}}>{t('networks')}</InputLabel>
		                <Select
                        name="tupple"
                        required={true}
                        labelId="tupple"
                        style={{backgroundColor:(displayStore.networkSectionSettings.DisableSection ? 'rgba(235, 238, 244, 0.75)': '#ffffff'), width:'97%'}} 
                        disabled={true}
                        value={jobSettingsStore.selectedNetworkTupple && jobSettingsStore.selectedNetworkTupple.id}
                        onChange={(event: object, value: any) => {handleNetworkPairChange(event, value) }}>
                        {jobSettingsStore.networkTuppleList.map((tuple) => (
                            <MenuItem key={tuple.id} value={tuple.id}>
                            {tuple.vmNetworkName + ' -> ' + tuple.drNetworkName}
                            </MenuItem>
                        ))}
                        </Select>
                        </FormControl>
                        </Grid>
                   
                    
                    <Grid item xs={1}>
                        <Button className={classes.button} disabled={true} onClick={()=>buttonRedirect("AdvancedVlan")}>
                            <Create className={classes.editIcon}></Create>
                        </Button>
                    </Grid>
                    </Grid>
                </Grid>);
                break;
                }
        }
    }

    return(
        <Grid container>
        {loadDREditSection()}
        </Grid>
    );
};

export default observer(TupleComponent);