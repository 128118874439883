import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useEffect, useContext, Suspense } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, withRouter, RouteComponentProps, Switch } from 'react-router-dom';
import HomePage from '../../features/navigation/homePage.component';
import { ToastContainer } from 'react-toastify'
import { RootStoreContext } from '../stores/root.store';
import PrivateRoute from './PrivateRoute';
import { Grid, CircularProgress } from '@material-ui/core';
import { ThemeProvider } from "@material-ui/styles";
import 'typeface-roboto';
import theme from "./Theme";
import LoginFormComponent from '../../features/user/login/LoginForm.component';
import '../common/util/i18n';
import MaintainanceView from './Maintainance';
import EmptyDasboard from '../common/emptyDashboard.component';
import { history } from '../common/util/history';
import { MatomoInstance } from '../common/util/matomo';
import { MatomoProvider } from '@datapunt/matomo-tracker-react';

function getMatomoAnalyticElement(element: HTMLElement) : HTMLElement {
  if(!element)
    return null;
  if(element["dataset"]?.analyticCategory)
    return element;
  else
    return getMatomoAnalyticElement(element.parentElement);
}

const App: React.FC<RouteComponentProps> = ({ location }) => {
  //we'd like to be logged in automatically if we already have a token.
  const rootStore = useContext(RootStoreContext);
  const { setAppLoaded, token, appLoaded } = rootStore.commonStore;
  const { getUser, isLoggedIn, user } = rootStore.userStore;
  const {isServerAvailable} = rootStore.serverStatusStore;
  useEffect(() => {
    if (token) {
      getUser().finally(() => setAppLoaded());
    } else {
      setAppLoaded();
    }
    rootStore.settingStore.getSettings();
  }, [getUser, setAppLoaded, token])

  useEffect(() => {
    if(user)
     {
        //MatomoInstance.pushInstruction('setUserId', user.username);
        //MatomoInstance.pushInstruction('setCustomVariable', 1, "TenantId", user.tenantId, "visit");
     }
  }, [user])

  useEffect(() => {
    // MatomoInstance.pushInstruction('setUserid', )
    window.addEventListener('unhandledrejection', event => {
      console.log('unhandled exceptions',event);
    })

    window.addEventListener('click', event => {
      var target = getMatomoAnalyticElement(event.target as HTMLElement);
      if(!target)
        return
      // console.log(target)
      if(target["dataset"]?.analyticCategory)
       {
        
         //@ts-ignore
         MatomoInstance.trackEvent({
           //@ts-ignore
           category: target.dataset.analyticCategory,
           //@ts-ignore
           action: target.dataset.analyticAction,
           //@ts-ignore
           name: target.dataset.analyticName
         })
       }
    })

    window.addEventListener('keyup', (event) => {
      if(event.key !== 'Tab')
       return;
     var target = getMatomoAnalyticElement(document.activeElement as HTMLElement);
     if(!target)
       return
     // console.log(target)
     if(target["dataset"]?.analyticCategory && target["dataset"]?.analyticAction == 'focus')
      {
       
        //@ts-ignore
        MatomoInstance.trackEvent({
          //@ts-ignore
          category: target.dataset.analyticCategory,
          //@ts-ignore
          action: target.dataset.analyticAction,
          //@ts-ignore
          name: target.dataset.analyticName
        })
      }
    });
  }, [])

  useEffect(() => {
   history.replace('/jobs');
  }, [])

  if (!appLoaded) return <CircularProgress size={50} />



  return (
    <div data-test="component-app">
      <Suspense fallback={null}>
      <MatomoProvider value={MatomoInstance}>
      <ThemeProvider theme={theme}>
        <ToastContainer position='bottom-right' />
          {isServerAvailable && <Grid container direction="column" className="h100">
        <Switch>
          <PrivateRoute exact={true} path='/welcome' component={EmptyDasboard} />
          <Route exact={true} path="/user/login"  component={LoginFormComponent} />
          <PrivateRoute path={`/`}  component={HomePage} />
        </Switch>
        </Grid>}
        {!isServerAvailable && <MaintainanceView/>}
    </ThemeProvider>
    </MatomoProvider>
      </Suspense>
    </div>
  );

}

export default withRouter(observer(App));//the withRouter is a higher level component that allows access here to all the route objects (like location) 
