import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
//import { callMatomo } from '../../../app/stores/root.store';
import { Grid, useMediaQuery } from '@material-ui/core';

import LoginImageComponent from './LoginImage.component';
import LoginBoxComponent from './LoginBox.component';

const LoginFormComponent: React.FC = () => {
    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
    
    useEffect(() => {
        //callMatomo("Login","/user/login");
    });

  
    return (
        <div data-test="component-login-form">
          <Grid container direction="row" classes={{ root: "overall_box"}}>
            { matchesXS || matchesSM || matchesMD ?
                <LoginBoxComponent />            
              : 
              <React.Fragment>
                <LoginImageComponent />
                <LoginBoxComponent />
              </React.Fragment>
            }
            
            
          </Grid>
        
        </div>
    )
}

export default (observer(LoginFormComponent));