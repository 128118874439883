import { Radio,FormControlLabel,Grid, Typography, Card, TableCell as MuiTableCell, TableContainer, Table, TableRow, TableHead, TableBody, Checkbox, withStyles, IconButton, makeStyles, TextField, InputAdornment, Select, MenuItem, Box, InputLabel, FormControl, Menu, RadioGroup, Divider, ListItemText, NativeSelect, TableCellProps, Paper, CircularProgress, Tooltip, Button } from '@material-ui/core';
import React, { CSSProperties, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Column, useBlockLayout, useExpanded, useFilters, useGroupBy, useRowSelect, useTable,useGlobalFilter, CellProps, useSortBy, HeaderProps, Row } from 'react-table';
import { JobDevice } from '../../../app/models/jobDevice/jobDevice.model';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowRight from '@material-ui/icons/ArrowRight';
import RefreshIcon from '@material-ui/icons/Refresh';
import FilterIcon from '@material-ui/icons/FilterList';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import SettingsIcon from '@material-ui/icons/Settings';
import DeviceTestSelectionComponent from './deviceTestSelection.component';
import SelectedDeviceTable from './selectedDevicetable.component';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import { RootStore, RootStoreContext } from '../../../app/stores/root.store';
import AddNewDeviceDialog from './addNewDevice.component';
import { TestType } from '../../../app/models/jobTest.model';
import { DeviceTest, SRMRPState, TestCategory } from '../../../app/models/createjob/createJob.model';
import useDevices from './hooks/getDevice.hook';
import { CustomDeviceStatus, DeviceState, DeviceStatus, DeviceType, DEVICE_TYPE_SMALL_ICON } from '../../../app/models/deviceType.enum';
import { toast } from 'react-toastify';
import { DataMover } from '../../../app/models/BaseSettings.enum';
import { useTranslation } from 'react-i18next';
import useDeviceTests,  { SelectedTest } from './getInitalTests.hook';
import ManageTestGroupsDialog from './ManageTestGroups.component';
import ArrowUp from '@material-ui/icons/ArrowDropUp';
import HelpIcon from '@material-ui/icons/Help';
import JobSettingsStore from '../../../app/stores/jobSettings.store';
import { CustomDeviceState, DatabaseType, DevicePlatform, MachineType, NetworkOption } from '../../../app/models/jobProperties.model';
import Create from '@material-ui/icons/Create';
import ListSkeleton from '../../../app/common/util/listSkeleton.component';
import { truncate } from 'fs';
import { isUsingStaticRendering, observer } from 'mobx-react-lite';
import jobAgent from '../../../app/api/jobAgent';
import ConfirmationDialog from '../../../app/common/confirmationDialog.component';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EmptyDevicesComponent from './EmptyDevices.component';
import MessageDialog from '../../../app/common/MessageDialog.component';
import FilterByJobNameComponent from './filterByJobName.component';
import StopIcon from '@material-ui/icons/Stop';

const TableCell = withStyles({
    root: {
        borderBottom: "none"
    }
})(MuiTableCell);

const useStyles = makeStyles({
    tooltip: {
        maxWidth: 'none',
        whiteSpace:'pre-wrap'
    },
    helpIcon:
    {
      color: '#1976d2',
      fontSize: "large"
    },
    root:{
        '& .MuiList-root MuiMenu-list' : {
            width:'252px'
        }
    },
    centerVertical: {
        display: 'flex',
        alignItems: 'center'
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    listItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft:'13px',

        '& .MuiListItemText-root' : {
            marginLeft: 10
        },
        '& .MuiSvgIcon-root': {
            color: '#8092a9'
        }
    },
    formControl: {
        width: '100%',
        '& label': {
          background: "white",
          width: "auto",
          padding: "0 4px 0 4px",
          fontSize: '17px'
        }
      },
    arrowLeftButton: {
        padding: "5px",
        border: "1px solid #2892d7",
        top: "calc(50% + 20px)",
        left: "-25px",
        position: "absolute",
        background: "#2892d7",
        borderRight: "0px",
        zIndex: 1101,
        color: "white",
        cursor: "pointer",
        borderRadius: '50%',
        width: 35,
        height: 35,
    },
    arrowRightButton: {
        padding: "5px",
        border: "1px solid #2892d7",
        top: "calc(50% - 20px)",
        left: "-25px",
        position: "absolute",
        background: "#2892d7",
        borderRight: "0px",
        zIndex: 1101,
        color: "white",
        cursor: "pointer",
        borderRadius: '50%',
        width: 35,
        height: 35,
    },
    table: {
        '& tr td:first-child': {
            width: '40%'
        },
        '& tr th:first-child': {
            width: '40%'
        }
    },
    'groupedCell': {
        display: 'flex',
        alignItems: 'center',
        borderLeft: '1px solid #c7cfdb',
        marginLeft: 40,
        position: 'relative', left: -29,
        paddingLeft: 29
    },
    headerCell: {
        borderBottom: '1px solid #eaeef4'
    },
    searchField: {
        "& input" : {
            padding: "10px 8px"
        }
    },
    nativeFormControl:{
        '& .MuiInput-underline:before': {
            borderBottom:'1px solid rgba(0, 0, 0, 0.12)'
        },
        '& .MuiNativeSelect-outlined.MuiNativeSelect-outlined': {
            paddingLeft:'8px'
        }
    },
    editIcon: {
        cursor: 'pointer'
    },
    nativeSelect:{
        borderTop:'1px solid rgba(0, 0, 0, 0.12)',
        borderRight:'1px solid rgba(0, 0, 0, 0.12)',
        borderLeft:'1px solid rgba(0, 0, 0, 0.12)',
        borderRadius:'4px'
    },
    button:{
        '& .MuiIconButton-root':{
            padding:'5px'
        }
    }
})

const SortIconStyles: { [key: string]: CSSProperties } = {
    arrowAup: {
        fontSize: "1.2em",
        position: "relative",
        top: "2px"
    },
    arrowDown: {
        fontSize: "1.2em",
        position: "relative",
        bottom: "5px"
    }
}

export const SortIcon: React.FC<{ sortActive: boolean, sortDesc: boolean }> = ({ sortActive, sortDesc, children }) => {
    return <span style={{ display: "flex" }}>
        {children}
        <span style={{
            display: "flex",
            flex: "column",
            color: "lightgray",
            paddingLeft: "2px",
            flexDirection: "column"
        }}>
            <ArrowUp style={{ ...(sortActive && !sortDesc ? { color: "gray" } : {}), ...SortIconStyles.arrowAup }} />
            <ArrowDown style={{ ...(sortActive && sortDesc ? { color: "gray" } : {}), ...SortIconStyles.arrowDown }} />
        </span>
    </span>
}


const DeviceSelectionComponent: React.FC = () => {
    
    const { createJobStore,jobSettingsStore,displayStore, userStore } = useContext(RootStoreContext);
    const isPaaS= createJobStore.baseSettings.dataMover== DataMover.PaaS;
    const [devices,devicesAll,CustomDevicesAll,plans, setDevices, addCustomDevice, removeCustomDevice,updateCustomDevice,refreshDevices,stopFetch,loadingState] = useDevices();
    const [groupBy, setGroupBy] = useState('planName');
    const isEdit= window.location.href.includes('editJob');
    const [openAddNewDeviceDialog, setopenAddNewDeviceDialog] = useState(false);
    const [openConfirmationDialog, setopenConfirmationDialog] = useState(false);
    const [openConfirmDeleteDialog, setopenConfirmDeleteDialog] = useState(false);
    const [openWarnStatusDialog,setopenWarnStatusDialog]= useState(false);
    const [openManageGroupsDialog, setopenManageGroupsDialog] = useState(false);
    const [openFilterByJobDialog, setopenFilterByJobDialog] = useState(false);
    const [DevicesBadStatus, setDevicesBadStatus] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [TestGroup, setTestGroup] = React.useState(null);
    const [isEditMode, setIsEditMode] = React.useState(false);
    const [editDevice, setEditDevice] = useState<JobDevice>(undefined);
    const [showMaxDeviceWarning, setshowMaxDeviceWarning] = useState(false);
    const SRMPlanStateMap = {1: "recovery plan has errors",3: "recovery plan needs cleanup before you can select it",10: "recovery plan is currently running", 8: "Ready",11:"Some VMs are powered off"}
    const classes = useStyles();
    const [t] = useTranslation("createJobDevices");
    const selectedDevices = createJobStore.selectedDevices;
    const selectedDevicesRef = useRef(selectedDevices);
    const deviceGroupState = useRef<{[key: string]: DeviceStatus}>({});
    const [disableSelectAll, setdisableSelectAll] = useState(false);
    const [toggleAll, settoggleAll] = useState(false);
    const initalCustomNoDefault= {
            power: false,
            network: true,
            service: false,
            custom: false,
            autofix: false,
            database: false,
            web: false,
            internet: true,
            branch: false,
            drResources: true,
            PreScriptRes:false,
           authentication:false,
            thirdDeviceNetwork:false
    }

    const initalCustomDefault= {
        power: false,
        network: true,
        service: true,
        custom: true,
        autofix: true,
        database: false,
        web: false,
        internet: true,
        branch: false,
        drResources: true,
        PreScriptRes:false,
       authentication:false,
        thirdDeviceNetwork:false
    }

    const handleDeviceGroupChange =(event:any,cellProps:PropsWithChildren<CellProps<{}, any>>)=> {
        const updateDevice =  cellProps.data.map((d,deviceIndex) => {
            if(deviceIndex == parseInt(cellProps.row.id)) {
                d.deviceGroup = event.target.value;
                return d;
            }
            return d; 
        });
         setDevices([...updateDevice]);
    }

    
    const handleCustomRemove = useCallback( (row:any) => {
        setDevices(_devices => {
                let custom = _devices.find(item=>item.deviceName === row.values.deviceName && item.planName == 'Custom Devices');
                if(custom !=undefined && custom!=null)
                {
                    setEditDevice(custom);
                    setopenConfirmDeleteDialog(true);;
                    setIsEditMode(false);
                    return _devices;
                }
                else
                    return _devices;
        })
        
    }, [devices])

    const loadOptionsGroups =()=> {
        let groups =
        [
            {groupOrder: 1,groupName:"Group1"},
            {groupOrder: 2,groupName:"Group2"},
            {groupOrder: 3,groupName:"Group3"},
            {groupOrder: 4,groupName:"Group4"},
            {groupOrder: 5,groupName:"Group5"},
            {groupOrder: 6,groupName:"Group6"},
            {groupOrder: 7,groupName:"Group7"}
        ]
        if(createJobStore.TestGroups && createJobStore.TestGroups.groups !=null)
        {
            return createJobStore.TestGroups.groups.map((test) => (
                <option value={test.groupOrder}>{test.groupName}</option>
            ));
        }
        else
        {
            return groups.map((test) => (
                <option value={test.groupOrder}>{test.groupName}</option>
            ));
        }
    }
    
    const columns: Array<Column<JobDevice>> = React.useMemo(() => {
        return [
            {
                Header: (props) => { return <span onClick={() =>{toggleSortBy("deviceName")}}><SortIcon sortActive={props.column.isSorted} sortDesc={props.column.isSortedDesc}>{t("Name")} </SortIcon></span>},
                accessor: 'deviceName',
            },
            {
                Header: <span></span>,
                accessor: 'sourceName',
            },
            {
                Header: <span></span>,
                accessor: 'planState'
            },
            {
                Header: (props) => { return <span onClick={() =>{toggleSortBy("deviceStatus")}}><SortIcon sortActive={props.column.isSorted} sortDesc={props.column.isSortedDesc}>{t("Status")} </SortIcon></span>},
                accessor: 'deviceStatus',
                Cell: (cellProps) => {
                    if(cellProps.row.isGrouped)
                    {
                        if(cellProps.row.values?.planName?.includes("Custom Devices"))
                            return <React.Fragment><img style={{height: "15px"}} src={"/assets/images/jobIcon/resillient.svg"}></img></React.Fragment>
                        else
                        {
                            const groupByVal = cellProps.row.values?.planName;

                            if( deviceGroupState.current[groupByVal] === DeviceStatus.Ready)
                                return <React.Fragment>{cellProps.row.leafRows.length > 0 && cellProps.row.leafRows[0].values.planState != null ? <Tooltip title={SRMPlanStateMap[cellProps.row.leafRows[0].values.planState]}><img src={"/assets/images/editDeviceIcons/v.svg"}></img></Tooltip> : <img src={"/assets/images/editDeviceIcons/v.svg"}></img>}</React.Fragment>
                            else if(deviceGroupState.current[groupByVal] === DeviceStatus.Unknown)
                                return <React.Fragment>{cellProps.row.leafRows.length > 0 && cellProps.row.leafRows[0].values.planState != null ? <Tooltip title={SRMPlanStateMap[cellProps.row.leafRows[0].values.planState]}><img src={"/assets/images/editDeviceIcons/warning.svg"}></img></Tooltip> : <img src={"/assets/images/editDeviceIcons/warning.svg"}></img>}</React.Fragment>
                            else if(deviceGroupState.current[groupByVal] === DeviceStatus.NotReady)
                                return <React.Fragment>{cellProps.row.leafRows.length > 0 && cellProps.row.leafRows[0].values.planState != null ? <Tooltip title={SRMPlanStateMap[cellProps.row.leafRows[0].values.planState]}><img src={"/assets/images/editDeviceIcons/x.svg"}></img></Tooltip> : <img src={"/assets/images/editDeviceIcons/x.svg"}></img>}</React.Fragment>
                            else 
                                return <React.Fragment><img src={"/assets/images/editDeviceIcons/warning.svg"}></img></React.Fragment>
                        }
                    }
                    else
                    {
                        if(cellProps.row.values?.planName?.includes("Custom Devices"))
                        {
                            //switch(cellProps.row.values.CustomDeviceStatus)
                            //{
                                //case CustomDeviceStatus.Free:
                                    //{
                            return <React.Fragment><img style={{height: "15px"}} src={"/assets/images/jobIcon/resillient.svg"}></img></React.Fragment>
                                    //}
                                //case CustomDeviceStatus.Occupied:
                                //{
                                //     return <React.Fragment><img style={{height: "15px"}} src={"/assets/images/jobIcon/atrisk.svg"}></img></React.Fragment>
                                //}
                            //} 
                        }
                        else
                        {
                            switch(cellProps.row.values.deviceStatus)
                            {
                                case DeviceStatus.Ready:
                                    {
                                        return <React.Fragment><img src={"/assets/images/editDeviceIcons/v.svg"}></img></React.Fragment>
                                    }
                                case DeviceStatus.Initializing:
                                case DeviceStatus.Deleting:
                                case DeviceStatus.FailingOver:
                                case DeviceStatus.NotReady:
                                case DeviceStatus.Unknown:   
                                    {
                                        return <React.Fragment><img src={"/assets/images/editDeviceIcons/x.svg"}></img></React.Fragment>
                                    }
                                default:
                                    {
                                        return <React.Fragment><img src={"/assets/images/editDeviceIcons/warning.svg"}></img></React.Fragment>
                                    }
                            }
                        }
                    }
                }
            },
            {
                Header: (props) => { return <span onClick={() =>{toggleSortBy("os")}}><SortIcon sortActive={props.column.isSorted} sortDesc={props.column.isSortedDesc}>{createJobStore.baseSettings.dataMover == DataMover.VeeamSQL ? t("Instance"):t("OS")} </SortIcon></span>},
                accessor: 'os',
                Cell: (cellProps) => {
                    if(createJobStore.baseSettings.dataMover == DataMover.VeeamSQL)
                    {
                        return <React.Fragment></React.Fragment>
                    }
                    else
                    {
                    switch(cellProps.row.values.os)
                    {
                        case DevicePlatform.Windows:
                            {
                                return <React.Fragment><img style={{marginLeft:"5px"}} src={"/assets/images/editDeviceIcons/windowsIcon.svg"}></img></React.Fragment>
                            }
                        case DevicePlatform.UNIX:
                            {
                                return <React.Fragment><img style={{marginLeft:"5px"}} src={"/assets/images/editDeviceIcons/linuxIcon.svg"}></img></React.Fragment>
                            }
                        default:
                            {
                                return <React.Fragment><span></span></React.Fragment>
                            }
                    }
                }
                }
            },
            {
                Header: <span></span>,
                accessor: 'deviceType'
            },
            ,
            {
                Header: <span></span>,
                accessor: 'deviceState'
            },
            {
                Header: (props) => {
                    if (props.column.isGrouped) {
                        return (
                                <span style={{ minWidth: 350 }} onClick={() =>{toggleSortBy("Name")}}>
                                    <SortIcon sortActive={props.column.isSorted} sortDesc={props.column.isSortedDesc}>{t("Name")} </SortIcon>
                                </span>
                        )
                    } else {
                        return <span onClick={() =>{toggleSortBy("deviceGroup")}}><SortIcon sortActive={props.column.isSorted} sortDesc={props.column.isSortedDesc}>{t("Group")} </SortIcon></span>
                    }
                },
                accessor: 'deviceGroup',
                Cell: (cellProps) =>{
                    if (cellProps.row.leafRows == undefined) 
                    return <div style={{width:'166px',display:'flex',justifyContent:'flex-end'}}>
                        <FormControl className={classes.formControl} classes={{root: classes.nativeFormControl}} variant='outlined'>
                        <NativeSelect
                        disabled={true}
                        classes={{root: classes.nativeSelect}}
                        value={cellProps.value}
                        onChange={(event)=> handleDeviceGroupChange(event,cellProps)}
                        name="group"
                        inputProps={{ 'aria-label': 'group' }}
                        >
                        {loadOptionsGroups()}
                        </NativeSelect>
                        </FormControl>
                        {cellProps.row.values?.planName?.includes("Custom Devices") && !cellProps.row.isGrouped ?<Tooltip title={t("editcustom")}><Create className={classes.editIcon} style={{marginTop:'3%',marginLeft:'6%'}} onClick={()=>handleCustomEdit(cellProps.row)}/></Tooltip>:null}
                    </div>
                    else return null;
                }
            },
            {
                accessor: "planName",
                Header: (props) => {
                    if (props.column.isGrouped) {
                        return (
                        <span style={{ minWidth: 350 }} onClick={() =>{toggleSortBy("planName")}}>
                            <SortIcon sortActive={props.column.isSorted} sortDesc={props.column.isSortedDesc}>{t("Name")}</SortIcon>
                        </span>
                        )
                    } 
                    else return <span onClick={() =>{toggleSortBy("planName")}}><SortIcon sortActive={props.column.isSorted} sortDesc={props.column.isSortedDesc}>{t("Plan Name")} </SortIcon></span>
                    }
            },
            {
                accessor: 'showDeviceAsDC',

            }

        ] as Array<Column<JobDevice>>;
    }, [])


    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            maxWidth: 400,
        }),
        []
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        toggleGroupBy,
        toggleSortBy,
        getToggleAllRowsSelectedProps,
        rows,
        prepareRow,
        setGlobalFilter,
        selectedFlatRows,
        state,
        toggleRowSelected,
        toggleAllRowsSelected,
        toggleRowExpanded,
        expandedRows
    } = useTable(
        {
            data: React.useMemo(() => {return devices}, [devices]),
            columns,
            defaultColumn,
            initialState: {
                groupBy: React.useMemo(() => {
                    return [groupBy];
                }, [groupBy]), hiddenColumns: React.useMemo(() => ['deviceName', 'deviceType','sourceName','deviceState','planState', 'showDeviceAsDC'], [])
            },
            autoResetExpanded: false,
            autoResetGroupBy: false,
            autoResetSelectedRows: false,
            autoResetGlobalFilter:false,
            expandSubRows: true
        },
        useGlobalFilter,
        useGroupBy,
        useSortBy,
        useExpanded,
        useRowSelect
    );

    const {globalFilter}=state;

    const disableDeviceSelection = useMemo(() => {

        if(devices.length == 0 || loadingState === 'loading' || Object.keys(state.selectedRowIds).map(rowId => parseInt(rowId)).length == 0)
            return true;
        const {usedDevicesById} =userStore;
        let removeUsed = jobSettingsStore && jobSettingsStore.selectedLicense && (jobSettingsStore.selectedLicense.id != 0) && (jobSettingsStore.selectedLicense.id == createJobStore.baseSettings.originalLicenseId);
        if(usedDevicesById && usedDevicesById.totalDevices - usedDevicesById.usedDevices - selectedDevices.length  + (removeUsed ? createJobStore.jobDevicesCounter : 0) < Object.keys(state.selectedRowIds).map(rowId => parseInt(rowId)).length)
        {
            toast.error(t("quotaExeededWarning"), {autoClose: 30000});
            return true;
        }

        return false;
    }, [devices.length,state.selectedRowIds, userStore.usedDevicesById ])
 
    useEffect(() => {
        state.groupBy.forEach(g => {
            toggleGroupBy(g, false);
        })

        toggleGroupBy(groupBy)
    }, [groupBy, toggleGroupBy])

    useEffect(() => {
        if(selectedFlatRows.length === 0)
            settoggleAll(false);
    }, [selectedFlatRows])

    const handleToggleAll = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
        const checked = !toggleAll;
        settoggleAll(checked);
        rows.forEach(row => {
            if(row.isGrouped) {
                if(deviceGroupState.current[row.groupByVal] === DeviceStatus.Ready)
                    toggleRowSelected(row.id, checked);
            } else {
                   if(row.values.deviceState === DeviceState.PoweredOn)
                        toggleRowSelected(row.id, checked);
                
            }
            
        });
    }

    useEffect(() => {


        /**
         *   if(cellProps.row.leafRows.every(child=>child.values.deviceStatus === DeviceStatus.Ready))
                            return <React.Fragment><img src={"/assets/images/editDeviceIcons/v.svg"}></img></React.Fragment>
                        else if(cellProps.row.leafRows.find(child=>child.values.deviceStatus === undefined))
                            return <React.Fragment><img src={"/assets/images/editDeviceIcons/warning.svg"}></img></React.Fragment>
                        else if(cellProps.row.leafRows.every(child=>child.values.deviceStatus === DeviceStatus.NotReady))
                            return <React.Fragment><img src={"/assets/images/editDeviceIcons/x.svg"}></img></React.Fragment>
                        else if(cellProps.row.leafRows.find(child=>child.values.deviceStatus === DeviceStatus.Initializing || DeviceStatus.Deleting  || DeviceStatus.FailingOver || DeviceStatus.NotReady || DeviceStatus.Unknown))
                            return <React.Fragment><img src={"/assets/images/editDeviceIcons/warning.svg"}></img></React.Fragment>
                        else
                            return <React.Fragment><img src={"/assets/images/editDeviceIcons/warning.svg"}></img></React.Fragment>
                
         */
        const _deviceGroupState: {[key: string]: DeviceStatus} = {};
        rows.forEach(row => {
             if(row.isGrouped) {
                 if(row.leafRows.some(d => d.values?.planState === SRMRPState.NeedsCleanup)){
                    _deviceGroupState[row.groupByVal] = DeviceStatus.NotReady;
                 }
                else if(row.leafRows.every(d => d.values.deviceState === DeviceState.PoweredOn)) {
                    _deviceGroupState[row.groupByVal] = DeviceStatus.Ready;
                } else if(row.leafRows.find(d => d.values.deviceState === undefined || d.values.deviceState === DeviceState.Unknown )) {
                    _deviceGroupState[row.groupByVal] = DeviceStatus.Unknown;
                } else if(row.leafRows.every(d => d.values.deviceState === DeviceState.PoweredOff)) {
                    _deviceGroupState[row.groupByVal] = DeviceStatus.NotReady;
                } else {
                    _deviceGroupState[row.groupByVal] = DeviceStatus.Unknown;
                }
             }
         })
         deviceGroupState.current=_deviceGroupState;
         setdisableSelectAll(Object.values(_deviceGroupState).some(s => s != DeviceStatus.Ready));
    }, [rows])
  
    useEffect(() => {
        selectedDevicesRef.current = selectedDevices;
    }, [selectedDevices])


    // useEffect(() => {
    //     if(displayStore._stopLoadingPlans)
    //     {
    //         setloadVPG(false);
    //         if(devices.length == 0)
    //             setFailedFetched(true);
    //     }
           
    // }, [displayStore._stopLoadingPlans])

    

    const [devicesToBeRemoved, setdevicesToBeRemoved] = useState<Array<JobDevice>>([]);
    const [selectedTests, setSelectedTests] = useDeviceTests(createJobStore.baseSettings.dataMover);
    
    const handleMoveToLeft = () => {
        if(devicesToBeRemoved.length > 0 )
            setopenConfirmationDialog(true);
    }

    const intializeDefaults=(device:any)=>{
      let serviceTest = device.tests.find(item=> item.testType === TestType.service);
      let customTest = device.tests.find(item=> item.testType === TestType.custom);
      if(device.os === DevicePlatform.UNIX)
      {
        serviceTest.serviceToTest =jobSettingsStore.serviceToTestLinux;
        customTest.customCommandToTest =jobSettingsStore.customCommandToTestLinux;
        customTest.customCommandExpectedResult=jobSettingsStore.customCommandExpectedResultLinux;
      }
    }

    const InitializeTests =(tests:DeviceTest[])=>{
       tests.map(test=>{
           switch(test.testType)
           {
               case TestType.service:
                {
                    if(test.selected)
                    {
                        test.serviceToTest =jobSettingsStore.serviceToTest;
                        test.testFromEDRC = jobSettingsStore.testFromEDRC;
                    }
                    break;
                }
                case TestType.custom:
                {
                    if(test.selected){
                    test.customCommandToTest = jobSettingsStore.customCommandToTest;
                    test.customCommandExpectedResult= jobSettingsStore.customCommandExpectedResult;
                    test.customCommandToTestIsExternal=jobSettingsStore.customCommandToTestIsExternal;
                    }
                    break;
                }
                case TestType.web:
                {
                    if(test.selected){
                    test.webPortalToTest =jobSettingsStore.webPortalToTest;
                    test.webPortalResult= jobSettingsStore.webPortalResult;
                    }
                    break;
                }
                case TestType.database:
                {
                    if(test.selected){
                    test.databaseType=jobSettingsStore.databaseType;
                    test.databaseNameToTest=jobSettingsStore.databaseNameToTest;
                    test.databaseServerNameToTest=jobSettingsStore.databaseServerNameToTest;
                    test.databaseQueryToTest=jobSettingsStore.databaseQueryToTest;
                    test.databaseUsername=createJobStore.baseSettings.workflowTest.PrimaryUser;
                    test.databasePassword=createJobStore.baseSettings.workflowTest.PrimaryPassword;
                    test.databasePort = jobSettingsStore.databasePort;
                    test.dbWindowsAuth =  jobSettingsStore.dbWindowsAuth
                    }
                    break;
                }
           }
       })
        return tests;
    }

    const handleWarnStatus =(confirm:boolean)=>{
        setopenWarnStatusDialog(false);
        if(confirm)
            MoveDevicesRight();
    }

    const sortByCustomFirst =(devices:any)=>{
        let resCustom=[];
        let resOther=[];
        devices.map(item=>{
            if(item.planName && item.planName.includes('Custom'))
                resCustom.push(item);
            else
                resOther.push(item);
        });
        return resCustom.concat(resOther);
    }


    const handleMoveToRight = () => {
        // let devicesToMoveRight = selectedFlatRows.reduce((acc, row, index) => {
        //     if(row.isGrouped && row.isExpanded) {
        //         return acc;
        //     }
        //     if(row.isGrouped) {
        //         row.subRows.forEach(subRow => acc.push(subRow.original));
        //     } else {
        //         acc.push(row.original);
        //     }
        //     return acc;
        // }, []);
        let devicesToMoveRight = Object.keys(state.selectedRowIds).map(id => devices[id]);
        let badStatusDevices = devicesToMoveRight.filter(device=> !device.planName.includes('Custom') && device.deviceStatus !== DeviceStatus.Ready);
        if(badStatusDevices.length > 0)
            setopenWarnStatusDialog(true);
        else
            MoveDevicesRight();
    }

    const MoveDevicesRight =()=>{
        let devicesToMoveRight = Object.keys(state.selectedRowIds).map(id => devices[id]);
        let customDevices = devicesToMoveRight.filter(device=>device.planName.includes('Custom'));
        let otherDevices = devicesToMoveRight.filter(device=> !device.planName.includes('Custom'));
        if(DevicesBadStatus)
            otherDevices = otherDevices.filter(device=>device.deviceStatus!== DeviceStatus.NotReady);
        let customDefault;
        let updatedCustomDefault;
        let customNoDefault;
        let updatedCustomNoDefault;
        if(isPaaS)
        {
            customDefault = Object.keys(TestType).filter(k => k in initalCustomDefault).map(testName => ({
                testCategory: TestCategory.Default, 
                testType: TestType[testName], 
                selected: initalCustomDefault[testName],
                serviceToTest: '',
                testFromEDRC: false,
                customCommandToTest: '',
                customCommandExpectedResult: '',
                customCommandToTestIsExternal: false,
                databaseType: DatabaseType.SQL,
                databaseNameToTest: '',
                databasePassword: '',
                databaseQueryToTest: '',
                databaseServerNameToTest: '',
                databaseUsername: '',
                databasePort:'',
                dbWindowsAuth:false,
                script:'',
                thirdDeviceNetworkToTest: '',
                authenticationUserToTest:'',
                authenticationPassToTest:'',
                webPortalResult: '',
                webPortalToTest:''
             }));
            updatedCustomDefault = InitializeTests(customDefault);     
        }
        else
        {
            customNoDefault = Object.keys(TestType).filter(k => k in initalCustomNoDefault).map(testName => ({
                testCategory: TestCategory.Default, 
                testType: TestType[testName], 
                selected: initalCustomNoDefault[testName],
                serviceToTest: '',
                testFromEDRC: false,
                customCommandToTest: '',
                customCommandExpectedResult: '',
                customCommandToTestIsExternal: false,
                databaseType: DatabaseType.SQL,
                databaseNameToTest: '',
                databasePassword: '',
                databaseQueryToTest: '',
                databaseServerNameToTest: '',
                databaseUsername: '',
                databasePort:'',
                dbWindowsAuth:false,
                script:'',
                thirdDeviceNetworkToTest: '',
                authenticationUserToTest:'',
                authenticationPassToTest:'',
                webPortalResult: '',
                webPortalToTest:''
             }));
            updatedCustomNoDefault = InitializeTests(customNoDefault);        
        }
        const tests : Array<DeviceTest> = Object.keys(TestType).filter(k => k in selectedTests).map(testName => ({
            testCategory: TestCategory.Default, 
            testType: TestType[testName], 
            selected: selectedTests[testName],
            serviceToTest: '',
            testFromEDRC: false,
            customCommandToTest: '',
            customCommandExpectedResult: '',
            customCommandToTestIsExternal: false,
            databaseType: DatabaseType.SQL,
            databaseNameToTest: '',
            databasePassword: '',
            databaseQueryToTest: '',
            databaseServerNameToTest: '',
            databaseUsername: '',
            databasePort:'',
            dbWindowsAuth:false,
            script:'',
            thirdDeviceNetworkToTest: '',
            authenticationUserToTest:'',
            authenticationPassToTest:'',
            webPortalResult: '',
            webPortalToTest:''
        }));
        const updatedTests = InitializeTests(tests);
        otherDevices.forEach(device => device.tests = [ ...updatedTests.map(t => ({...t}))]);
        if(isPaaS)
            customDevices.forEach(device => device.tests = [ ...updatedCustomDefault.map(t => ({...t}))]);
        else
            customDevices.forEach(device => device.inheritDefaultTests ? device.tests = [ ...updatedTests.map(t => ({...t}))] : device.tests = [ ...updatedCustomNoDefault.map(t => ({...t}))]);
        devicesToMoveRight = otherDevices.concat(customDevices);
        devicesToMoveRight.map(device => {
            let webTest= device.tests.find(test=> test.testType === TestType.web);
            let hostname = device.sourceName?.split('.')[0];
            webTest.webPortalToTest = !device.planName.includes('Custom Devices') ? (hostname ? 'http://'+hostname :'http://'+ device.sourceName):'http://'+ device.sourceName;
            webTest.webPortalResult = '';
            
            let scriptTest= device.tests.find(test=> test.testType === TestType.PreScriptRes);
            scriptTest.script = device.os == DevicePlatform.Windows ? 'WindowsPowerup.ps1':'LinuxPowerup.sh';
            device.scriptName = device.os == DevicePlatform.Windows ? 'WindowsPowerup.ps1':'LinuxPowerup.sh';

            let serviceTest= device.tests.find(test=> test.testType === TestType.service);
            serviceTest.serviceToTest = device.os === DevicePlatform.UNIX ? jobSettingsStore.serviceToTestLinux : jobSettingsStore.serviceToTest;

            let customTest = device.tests.find(test => test.testType === TestType.custom)
            customTest.customCommandToTest = device.os === DevicePlatform.UNIX ? jobSettingsStore.customCommandToTestLinux : jobSettingsStore.customCommandToTest;
            customTest.customCommandExpectedResult = device.os === DevicePlatform.UNIX ? jobSettingsStore.customCommandExpectedResultLinux : jobSettingsStore.customCommandExpectedResult;
            if(customTest && customTest.customCommandToTest.includes('netdom'))
                customTest.customCommandToTest = `${customTest.customCommandToTest} ${device.deviceName}`
            
            device.customCommandData =  device.os === DevicePlatform.Windows ? createJobStore.customDefaultScript && createJobStore.customDefaultScript : createJobStore.customDefaultScriptLinux && createJobStore.customDefaultScriptLinux;
            intializeDefaults(device);
        });
        let allSelected = [...selectedDevices, ...devicesToMoveRight];
        if(jobSettingsStore.isFirstTrial && allSelected.length > 10) {
            setshowMaxDeviceWarning(true);
            return;
        }

        allSelected = sortByCustomFirst(allSelected);
        createJobStore.updateSelectedDevices(allSelected);
        // remove moved devices from the list on the left
        setDevices([...devices.filter((device, deviceIndex) => !state.selectedRowIds[deviceIndex + ''])]);
        if(allSelected.length == (devices.length + selectedDevices.length))
        {
            // setloadVPG(false);
        }

        toggleAllRowsSelected(false);
    }

    const handleDeviceUpdate = useCallback((updates: Array<JobDevice>) => {
        const updatedDeviceList = selectedDevicesRef.current.map(d => updates.find(updatedDevice => updatedDevice == d) || d);
        createJobStore.updateSelectedDevices([...updatedDeviceList]);
    },[selectedDevices, devices]);

    const IsCustom = (datamover:DataMover) =>
    {
        if(datamover == DataMover.CustomVmware ||datamover == DataMover.CustomAWS ||datamover == DataMover.CustomAzure ||datamover == DataMover.CustomGCP)
            return true;
        else
            return false;
    }

    useEffect(()=>{
        let selectedNotCustomExist = selectedDevices.length !== 0 && selectedDevices.find(device=> !device.planName.includes("Custom Devices"));
        let selectedCustomExist = selectedDevices.length !== 0 && selectedDevices.find(device=> device.planName.includes("Custom Devices"));
        if(IsCustom(createJobStore.baseSettings.dataMover))
        {
            displayStore.updateNextStepValid({selectedDevicesValid: selectedDevices.length == 0? false: selectedCustomExist?true:false});
        }
        else
        {
            if(selectedDevices.length === 0)
            {
                displayStore.updateNextStepValid({selectedDevicesValid:false});
            }  
            else
            {
                if(selectedNotCustomExist)
                {
                    displayStore.updateNextStepValid({selectedDevicesValid:true});
                }
                else
                {
                    displayStore.updateNextStepValid({selectedDevicesValid:false});
                }
            }   
        }
    },[selectedDevices.length])


    const DeleteCustomDevice =(confirm:boolean)=>{
        setopenConfirmDeleteDialog(false);
        if(confirm)
        {
            jobAgent.CreateJobActions.DeleteCustomDevice(editDevice.id,createJobStore.baseSettings.id).then(res => {
                    if (res == true) {
                        fetchVPG();
                        toast.success('custom devices removed successfully');
                    }
                    else {
                        toast.error('Failed to remove custom devices');
                    }
                })
            .catch(err => {
                toast.error(err.data.errors)
              })
        }
    }

    const handleGroupClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };

      const handleTestGroupChange = (event) => {
        setTestGroup(event.target.value);
        const markedDevices = Object.keys(state.selectedRowIds).map(rowId => parseInt(rowId));
        const updatedDevices = devices.map((d,deviceIndex) => {
            if(markedDevices.indexOf(deviceIndex)>= 0) {
                return {...d, deviceGroup: createJobStore.TestGroups.groups.find(item=>item.groupName === event.target.value).groupOrder};
            } else {
                return {...d};
            }
        });
        setDevices([...updatedDevices]);
        handleClose();
      };
    
      const loadRadioLabelOptions =()=> {
        return (createJobStore.TestGroups && createJobStore.TestGroups.groups && createJobStore.TestGroups.groups.map((test,idx) => (
            <FormControlLabel value={test.groupName} disabled={Object.keys(state.selectedRowIds).length == 0 ?true:false} control={<Radio color="primary" />} label={test.groupName} />
        )));
    };

    const fetchVPG=()=>{
        setIsEditMode(false);
        setopenFilterByJobDialog(false)
      
        refreshDevices().then(res => 
          {}
        );
    }

    const handleRefreshDevices = () => {
        if(loadingState === 'loading') {
            toast.warn(t("requestInProgress"));
            return;
        }
        createJobStore.updateBaseSettings({planNameFilter:[]})
        setIsEditMode(false);
      
        refreshDevices("resetCache").then(res => {
         
        });
    }

    const disableCustom=()=>{
        let markedDevices = selectedFlatRows.reduce((acc, row, index) => {
            if(row.isGrouped && row.isExpanded) {
                    return acc;
            }
            if(row.isGrouped) {
                row.subRows.forEach(subRow => acc.push(subRow.original));
            } else {
                acc.push(row.original);
            }
            return acc;
        }, []);
        if(markedDevices.length === 0)
            return true;
        else
        {
            let CustomMarkedDevices = markedDevices.filter(device=>device.planName.includes('Custom'));
            return CustomMarkedDevices.length > 0 ? false: true;
        }
    }

    const handleCustomEdit = useCallback( (row:any) => {
        
        setIsEditMode(true);
        setDevices(_devices => {
            let custom = _devices.find(item=>item.deviceName === row.values['deviceName'] && item.planName == 'Custom Devices');
            if(custom !=undefined && custom!=null)
            {
                setEditDevice(custom);
                setopenAddNewDeviceDialog(true);
                return _devices;
            }
            else
                return _devices;
        })
    }, [devices])

    const handleCustomAdd = () => {
        setIsEditMode(false);
        setEditDevice(null);
        setopenAddNewDeviceDialog(true);
    }

    const handleConfirmationPopup =(confirm:boolean)=>{
        setopenConfirmationDialog(false);
        if(confirm)
        {
            createJobStore.updateSelectedDevices([...selectedDevices.filter(device => !devicesToBeRemoved.find(d => d == device))]);
            let allToBeRemoved = [...devices, ...devicesToBeRemoved];
            allToBeRemoved = sortByCustomFirst(allToBeRemoved);
            allToBeRemoved.map(item=> {
                if(item.planName.includes("Custom Devices"))
                {
                    let customDeviceFound = CustomDevicesAll.find(d=>d.deviceName === item.deviceName);
                    item.customDeviceState = customDeviceFound !=undefined ? customDeviceFound.customDeviceState: CustomDeviceState.Active;
                    item.inheritDefaultTests = customDeviceFound !=undefined ? customDeviceFound.inheritDefaultTests: false;
                    item.id = customDeviceFound != undefined ? customDeviceFound.id : 0;
                }
                else
                {
                    let deviceFound = devicesAll.find(d=>d.deviceName === item.deviceName);
                    item.deviceStatus = deviceFound !=undefined ? deviceFound.deviceStatus : DeviceStatus.Unknown;
                }
                });
            setDevices(allToBeRemoved);
            setdevicesToBeRemoved([]);
            // setFailedFetched(false);
        }
    }

    const loadName= (row:Row<object>)=>{
        if(row!=undefined && row.values!=undefined && row.values['deviceName']!=undefined)
            return row.values['deviceName'];
        else
         return "";
    }


    const handleNewDeviceDialogClose =(isEditMode:boolean)=>{
        if(!isEditMode)
        {
            rows.forEach(cell => {
                if(cell.isGrouped && cell.values.planName.includes("Custom")) {
                    cell.toggleRowExpanded(true);
                }
            });
        }
        setopenAddNewDeviceDialog(false);
    }

    // add new custom device callback from dialog
    const handleSubmitUpdate =(jobDevice: Array<JobDevice>)=>{
        addCustomDevice(jobDevice);
        setTimeout(() => { toggleRowExpanded(['planName:Custom Devices'], true);}, 10)
        // refreshCustomDevices();
        // updateCustomDevice(editDevice);
    }

    const handleCloseCustom =()=>{
      handleNewDeviceDialogClose(isEditMode);
    }

    const disableAdd =()=>{
        if(IsCustom(createJobStore.baseSettings.dataMover))
            return false;
        if(createJobStore.baseSettings.dataMover== DataMover.VeeamSQL)
            return true;
        if(createJobStore.networkSetting.NetworkType == NetworkOption.None)
            return true;
        return false;
    }


    const loadTooltip=(row:Record<string,any>)=>{
        if(row!=undefined)
        {
            if( createJobStore.baseSettings.dataMover == DataMover.VeeamSQL)
            {
                return "DB Name:"+row.values['deviceName'];
            }
            else
            {   
                if(row.values.deviceType === DeviceType.DCVirtual || row.values.deviceType === DeviceType.DCPhysical) 
                    return `Vmname: ${row.values['deviceName']} \nHostname: ${row.values['sourceName']} \nThis VM is a Domain Controller - Use it in your job to run advanced tests`
                if(row.values.deviceState == DeviceState.PoweredOff || row.values.deviceState == DeviceState.Unknown)
                    return "vmware: N/A - VM Powered Off"+'\n' +"Hostname:  N/A - VM Powered Off";
                else
                    return "vmname: "+row.values['deviceName']+'\n'+ "Hostname: "+row.values['sourceName'];
            }
        }
        return "";
    }

    const handleStop = ()=>{
        stopFetch();
    }

    return <Grid direction="row" container style={{height: '100%', overflow: 'hidden'}}>
        <Grid item xs={2} className='hide-scrollbar' style={{ padding: 16, height: '100%', overflowY: 'scroll',overflowX:'scroll' }}>
            <DeviceTestSelectionComponent onChange={(selection) => setSelectedTests(selection)} />
        </Grid>
        <Grid className='hide-scrollbar' style={{ background: 'rgb(233, 235, 238)' , height: '100%', overflowY: 'scroll',overflowX:'scroll'}} container direction="column" item xs={5}>
            <Box p={2} minHeight='100%'>
            <Card elevation={0} style={{ minHeight: '100%'}}>
                 <Box p={2} height="100%">
                <Grid item container direction='row' justify='space-between'>
                    <Grid item style={{display:'flex',alignItems: 'center'}} direction='row'>   
                    <Typography variant='h6' style={{ color: '#1f396d', margin: 5 }}>{isPaaS ? t("resourcegroups"):t("devices")} ({devices && devices.length})</Typography>
                    <Tooltip title={t("devicesInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '10px' }} />    
                    </Tooltip>
                    </Grid>
                    
                    <Grid item direction='row' style={{ display: 'flex', alignItems: 'center',marginLeft:'-18px' }}>
                    {!IsCustom(createJobStore.baseSettings.dataMover) ? <Tooltip title={t('filterIconTooltip')} arrow>
                        <IconButton disabled={true} component='span' classes={{root: classes.button}}>
                            <FilterIcon onClick={() => setopenFilterByJobDialog(true)}/>
                        </IconButton>
                    </Tooltip>:null}

                    <Tooltip title={t('StopLoadingIconTooltip')} arrow>
                    <IconButton disabled={true} component='span' onClick={() => handleStop()} classes={{root: classes.button}} style={{padding: isPaaS?'3px':'5px' }}>
                        <StopIcon />
                    </IconButton>
                    </Tooltip>
                    </Grid>

                    <Grid item direction='row' style={{ display: 'flex', alignItems: 'center',marginLeft:'-18px' }}>
                    <Tooltip title={t('refreshIconTooltip')} arrow>
                        <IconButton disabled={true} component='span' classes={{root: classes.button}} style={{padding: isPaaS?'3px':'5px' }}>
                            <RefreshIcon onClick={handleRefreshDevices}/>
                        </IconButton>
                    </Tooltip>
                        
                    
                    <Tooltip title={createJobStore.networkSetting.NetworkType == NetworkOption.None ?  t('disabledAddCustom'):t('addIconTooltip')} arrow>
                    <span>
                    <IconButton component='span' disabled={true} onClick={() => handleCustomAdd()} classes={{root: classes.button}} style={{padding: isPaaS?'3px':'5px' }}>
                        <AddCircleOutlineIcon />
                    </IconButton>
                    </span>
                    </Tooltip>

                    {/*<Tooltip title={t('removeIconTooltip')} arrow>
                        <IconButton component='span' disabled={disableCustom()} onClick={()=>{setopenConfirmDeleteDialog(true);setIsEditMode(false);}} classes={{root: classes.button}} style={{padding: isPaaS?'3px':'5px' }}>
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    </Tooltip>*/}

                    <Tooltip title={t('manageIconTooltip')} arrow>
                        <IconButton disabled={true} component='span' classes={{root: classes.button}} style={{padding: isPaaS?'3px':'5px' }}>
                        <GroupWorkIcon onClick={handleGroupClick}/>
                            <Menu
                            id="testgroup-menu"
                            className={classes.root}
                            anchorEl={anchorEl}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            transformOrigin={{ vertical: "top", horizontal: "center" }} 
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            >
                            <RadioGroup aria-label="groups" name="groups1" style={{paddingLeft:'16px',width:'120px'}} value={TestGroup} onChange={handleTestGroupChange}>
                            {loadRadioLabelOptions()}
                            </RadioGroup>
                            <Divider/>
                            <div className={classes.listItem} style={{paddingTop:'8px'}}>
                                <div className={classes.centerVertical}>
                                    <SettingsIcon />
                                    <InputLabel style={{paddingLeft:'8px',cursor:'pointer'}} onClick={()=> setopenManageGroupsDialog(true)}>{t("manage")}</InputLabel>
                                </div>
                            </div>
                            </Menu>
                        </IconButton>
                    </Tooltip>

                    <div style={{ display: 'flex', alignItems: 'center'}}>
                            <Select displayEmpty value={groupBy} onChange={event => setGroupBy(event.target.value as string)} disableUnderline={true} style={{height:37,width: '190px',border:'1px solid rgba(0, 0, 0, 0.12)',borderRadius:'4px',paddingLeft:'5%'}}>
                                <MenuItem value='planName'>{isPaaS ?t("rg"):t("vpg")}</MenuItem>
                                <MenuItem value='deviceGroup'>{t("testgroups")}</MenuItem>
                            </Select>
                        </div>
                        <TextField
                        placeholder={t("Search")} 
                        variant="outlined"
                        style={{ width: '190px', marginLeft: 15 }}
                        value={globalFilter || ''} 
                        onChange={event => setGlobalFilter(event.target.value)}
                        classes={{ root: classes.searchField }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    </Grid>
                        

                </Grid>
                 <TableContainer>
                    {loadingState === 'loading' && <Paper classes={{ root: 'paper' }} style={{display: 'flex', flexDirection: 'row',justifyContent: 'center' , alignItems: 'center',marginTop:'30%'}} >
                            <CircularProgress  size={50} style={{color: '#2892d7'}}/>
                        </Paper>
                        
                    }


                  {   loadingState === 'done' && <Table aria-label="Device Selection Table" style={{ tableLayout: 'fixed',marginTop: '1%'}} {...getTableProps()} className={classes.table} >
                        <TableHead >
                            {headerGroups.map((headerGroup, headerGroupIndex) => (
                                <TableRow key={headerGroupIndex}  {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column, columnIndex) => (
                                        <TableCell className={classes.headerCell} {...column.getHeaderProps()} align='left' padding='none'>
                                        {columnIndex === 0 ? 
                                        <React.Fragment>
                                            <div className={classes.centerVertical}>
                                            <Checkbox {...getToggleAllRowsSelectedProps()} onChange={handleToggleAll}
                                            color='primary' style={{ marginRight: 5,marginTop:'-5px' }}></Checkbox>
                            
                                            {column.render('Header')} 
                                            </div>
                                        </React.Fragment>
                                        : 
                                        <React.Fragment>
                                            <div style={{marginLeft: columnIndex === 2 ?'-16%':(columnIndex ==3 ? '-30%': columnIndex ==1 ?"22%":'0%')}}>
                                            {column.render('Header')}
                                            </div>
                                        </React.Fragment>}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody {...getTableBodyProps()}>
                            {rows.map((row, rowIndex) => {
                                prepareRow(row)
                                let checkboxProps = row.getToggleRowSelectedProps();
                                if(row.values?.deviceState == DeviceState.PoweredOff || row.values?.deviceState == DeviceState.Unknown) {
                                    if(checkboxProps.checked) {
                                        toggleRowSelected(row.id, false);
                                    }
                                }
                                return (
                                    <TableRow key={rowIndex} >
                                        {row.cells.map((cell, cellIndex) => {
                                            return <TableCell key={cellIndex} padding='none' {...cell.getCellProps()}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {cell.isGrouped ? <><span style={{ display: 'flex', alignItems: 'center' }} {...row.getToggleRowExpandedProps()}>
                                                        {(row.isExpanded ? <ArrowDown />
                                                            : <ArrowRight />)
                                                        }
                                                    </span>
                                                        {cell.value && <span style={{ display: 'flex', alignItems: 'center',whiteSpace:'nowrap' ,overflow:'hidden',textOverflow: 'ellipsis',width:'100%'}}>
                                                            <Checkbox  {...row.getToggleRowSelectedProps()} disabled={deviceGroupState.current[cell.value] !== DeviceStatus.Ready} color='primary' style={{ marginRight: 5 }}></Checkbox>
                                                            <img style={{marginRight: 5, width: 12, height: 12, fontWeight: 800}} src={createJobStore.baseSettings.dataMover == DataMover.VeeamSQL ?DEVICE_TYPE_SMALL_ICON[DeviceType.Database]:DEVICE_TYPE_SMALL_ICON[DeviceType.PhysicalServer]}></img>
                                                            <div style={{marginLeft: cellIndex === 2 ?'-16%':(cellIndex == 3 ? '-30%':cellIndex ==1 ?"22%":'0%')}}>
                                                                {cell.row.groupByID === 'deviceGroup' ? createJobStore.TestGroups.groups.find(item=>item.groupOrder.toString() === cell.value.toString()).groupName:cell.value}  {`(${row.subRows.length})`}
                                                            </div>
                                                        </span>
                                                        }
                                                    </> :
                                                        <>
                                                            {
                                                            //change this logic! this does not work if some cell value is same as grouped cell name
                                                            }
                                                            {cell.value === row.values[groupBy] && <span className={classes.groupedCell} style={{overflow:'hidden', flexGrow: 1}}>
                                                               
                                                                <Checkbox disabled={row.values.deviceState == DeviceState.PoweredOff || row.values.deviceState == DeviceState.Unknown || row.values.deviceStatus == DeviceStatus.NotReady} {...checkboxProps} color='primary' style={{ marginRight: 5 }}></Checkbox>
                                                                <img style={{marginRight: 5, width: 12, height: 12, fontWeight: 800}} src={createJobStore.baseSettings.dataMover == DataMover.VeeamSQL? DEVICE_TYPE_SMALL_ICON[DeviceType.Database]:DEVICE_TYPE_SMALL_ICON[row.values.showDeviceAsDC ? DeviceType.DCVirtual : row.values.deviceType]}></img>
                                                                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'flex', justifyContent: 'space-between', flexGrow: 1}}>
                                                                    {<Tooltip classes={{tooltip:classes.tooltip}} title={<span>{loadTooltip(row)}</span>}>
                                                                        <span style={{width:'80%',textOverflow: 'ellipsis',overflowX: 'hidden', fontWeight: row.values['showDeviceAsDC'] && row.values['deviceType'] == DeviceType.DCVirtual || row.values['deviceType'] == DeviceType.DCPhysical? 'bold': 'normal'}}>{row.values['deviceName']}</span></Tooltip>}
                                                                    {row.values?.planName?.includes('Custom')?
                                                                     <div style={{display:'flex',justifyContent:'flex-end', width: '20%'}}>
                                                                    <Tooltip title={t("delcustom")+ row.values['deviceName']}>
                                                                        <DeleteForeverIcon className={classes.editIcon} onClick={()=>handleCustomRemove(row)}/> 
                                                                    </Tooltip>
                                                                    </div>
                                                                    :null}
                                                                </span> 
                                                            </span>}
                                                            <div style={{marginLeft: cellIndex === 2 ?'-16%':(cellIndex == 3 ? '-30%':cellIndex ==1 ?"22%":'0%')}}>
                                                                {cell.value !== row.values[groupBy]  && <span style={{overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{cell.render('Cell')}</span>}
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </TableCell>
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>}
                </TableContainer>
                {
                   
                   loadingState !== 'loading' ? (loadingState === 'failed'|| (selectedDevices.length != 0 && devices.length == 0)) && <EmptyDevicesComponent title={selectedDevices.length != 0 && devices.length == 0 ? t('allIncludedPart1'):t('emptyDevices')} type={selectedDevices.length != 0 && devices.length == 0 ?"allIncluded": "devices"}/>:null
                }
                </Box>
            </Card>
            </Box>

        </Grid>
        <Grid item xs={5} container direction='column' className='hide-scrollbar' style={{ background: 'rgb(233, 235, 238)', padding: '16px 16px 16px 0', position: 'relative', height: '100%' }}>
        <Tooltip title={t('markDevice')}>
            <span id="buttonMoveRight" className={classes.arrowRightButton} style={{border:'1px solid rgb(233, 235, 238)',cursor:'pointer',background:(devices.length == 0 || loadingState === 'loading' || isEdit || Object.keys(state.selectedRowIds).map(rowId => parseInt(rowId)).length == 0)?'rgb(233, 235, 238)':'#2892d7'}} onClick={!isEdit ?(loadingState !== 'loading' && (Object.keys(state.selectedRowIds).map(rowId => parseInt(rowId)).length > 0) ? handleMoveToRight:null):null}> <ArrowRightIcon /></span>
         </Tooltip>
         <Tooltip title={t('markDevice')}>
            <span id="buttonMoveLeft" className={classes.arrowLeftButton} style={{border:'1px solid rgb(233, 235, 238)',cursor:'pointer',background: (selectedDevices.length == 0  ||loadingState === 'loading' || isEdit || devicesToBeRemoved.length == 0) ? 'rgb(233, 235, 238)':'#2892d7'}} onClick={!isEdit ?(loadingState !== 'loading' && devicesToBeRemoved.length != 0 ? handleMoveToLeft:null):null}><ArrowLeftIcon /></span>
         </Tooltip>
            <Card style={{ color: 'white', height: '100%'}}>
               <Box p={2} height='100%'style={{paddingBottom:'0px'}}>
                <SelectedDeviceTable devices={selectedDevices} plans={plans} onSelectionChange={(devices) => setdevicesToBeRemoved(devices)} onDeviceUpdate={handleDeviceUpdate} />
               </Box>
            </Card>
        </Grid>
        <AddNewDeviceDialog isEditMode={isEditMode} open={openAddNewDeviceDialog} onClose={handleCloseCustom} onSubmit={handleSubmitUpdate} onUpdate={(updatedDevice => updateCustomDevice(updatedDevice))} editDevice={editDevice}/>
        <ManageTestGroupsDialog open={openManageGroupsDialog} onClose={() => setopenManageGroupsDialog(false)}/>
        <ConfirmationDialog type="discard" device={editDevice} open={openConfirmationDialog} onClose={(confrim)=>handleConfirmationPopup(confrim)} children={"You are about to discard the tests you have added - are you sure?"}></ConfirmationDialog>
        <ConfirmationDialog type="delete" device={editDevice} open={openConfirmDeleteDialog} onClose={(confrim)=>DeleteCustomDevice(confrim)} children={"You have selected to delete the custom device "}></ConfirmationDialog>
        <ConfirmationDialog type="warn" device={editDevice} open={openWarnStatusDialog} onClose={(confrim)=>handleWarnStatus(confrim)} children={"You are attempting to test a device which is currently in bad state. Are you sure?"}></ConfirmationDialog>
       <MessageDialog open={showMaxDeviceWarning} onClose={res => setshowMaxDeviceWarning(false)}> 
                {t('maxDeviceWarning', {maxDevice: 10})}
       </MessageDialog>
       <FilterByJobNameComponent onClose={fetchVPG} onCancel={() => setopenFilterByJobDialog(false)} open={openFilterByJobDialog}></FilterByJobNameComponent>                  
    </Grid>
}

export default observer(DeviceSelectionComponent);