import {Grid,makeStyles, Typography, Divider, FormControl, InputLabel, Select, MenuItem, Tooltip, IconButton, InputAdornment, CircularProgress, TextField} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IPSectionFormComponent from '../../../../app/common/IPSectionForm.component';
import { DnsResolveType } from '../../../../app/models/jobProperties.model';
import { DNS_RESOLVE_NAME } from '../../../../app/models/Network.enum';
import { RootStoreContext } from '../../../../app/stores/root.store';
import HelpIcon from '@material-ui/icons/Help';
import { EDRAutocomplete } from '../../../../app/common/EDRAutocomplete';
import { EDRTextField } from '../../../../app/common/EDRTextField';
import { EDROutlinedInput } from '../../../../app/common/EDROutlinedInput';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import ReactDOM from 'react-dom';
import { AccessCredentials, AssetDTO, AssetType,CONSTS, CredentialUserType } from '../../../../app/models/createjob/createJob.model';
import JobSettingsStore from '../../../../app/stores/jobSettings.store';
import { SaveButton } from '../../../../app/common/saveButton';
import { runInAction } from 'mobx';
import { toast } from 'react-toastify';
import ConfirmationDialog from '../../../../app/common/confirmationDialog.component';
import es from 'date-fns/esm/locale/es/index.js';
import { useFormik } from 'formik';
import * as yup from 'yup';


const ControllerSettingsComponent: React.FC = (open) => {
    let labelRef;
    const DNSOptionTypes: Array<DnsResolveType> = [0, 1, 2];
    const rootStore = useContext(RootStoreContext);
    const {createJobStore,jobSettingsStore,displayStore} = rootStore;
    const [showEyePassword, setShowEyePassword]= useState(true);
    const [passwordVisibility,setPasswordVisibility] = useState(true);
    const [UserPassAutocomplete,setUserPassAutocomplete] = useState(false);
    const [openConfirmationEDRCDialog, setopenConfirmationEDRCDialog] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [optionDns, setOptionDns] = useState<DnsResolveType>(rootStore.createJobStore.networkSetting.dnsResolve);
    const {additionalEdrcIp, additionalDefaultGateway, additionalEdrcDns} = createJobStore.networkSetting;
    const getCustomAuthentication=jobSettingsStore.accessCredentials;
    const addMode = displayStore.screenSettings.AddModeEDRC;

    const useStyles = makeStyles({
        helpIcon:
        {
          color: '#1976d2',
          fontSize: "large"
        },
        card: {
            padding: 16,
            width: '100%',
            height: '100%'
        },
        formControl: {
            width: '100%',
            '& label': {
                background: "white",
                width: "auto",
                padding: "0 4px 0 4px",
                fontSize: '17px'
            }
        },
        MainHeading:
        {
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.19',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#1f396d'
        },
        text:{
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.71',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#4d5f75'
        },
        divider:{
            backgroundColor: '#eaeef4',
            width:'100%',
            marginTop:'30px'
        },
        headerDivider :{
            backgroundColor: '#eaeef4',
            margin: '0',
            marginTop: '10px',
            marginLeft: '10px',
        },
        button: {
            borderRadius: 15,
            color: 'white',
            backgroundColor:'#2892d7',
            width: '23%',
            height: '32px',
            marginTop: '24px',
            marginLeft: '410px'
        },
        SecondHeading:{
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1
        }
        });
        
    const classes = useStyles();
    const [t] = useTranslation("createJobNetwork");
    
    const validation = yup.object({
        displayName: yup.string()
        .max(30, 'up to 30 characters')
        .required('Required')
        .test('empty','VM Name must be not empty',displayName => displayName !='' && displayName !== undefined)
      })

    const form = useFormik({
        initialValues: {
            hostnameIP:'',
            displayName: '',
            username:'',
            password:''
        },
        validationSchema:validation,
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: () => { },
      })

    useEffect(() => {
        setOptionDns(rootStore.createJobStore.networkSetting.dnsResolve)
    },[optionDns])

    useEffect(()=>{
        disableAddApply();
        loadCircular();
     },[displayStore.screenSettings.EDRCApplyProcessing])

     useEffect(()=>{
        if(addMode)
        {
            form.setFieldValue("hostnameIP","",true);
            form.setFieldValue("displayName","",true);
            form.setFieldValue("username","",true);
            form.setFieldValue("password","",true);
        }
        else
        {
            form.setFieldValue("hostnameIP",jobSettingsStore.selectedEDRC.assetName,true);
            form.setFieldValue("displayName",jobSettingsStore.selectedEDRC != null && jobSettingsStore.selectedEDRC.assetVMName != '' ? jobSettingsStore.selectedEDRC.assetVMName : '',true);
            form.setFieldValue("username",jobSettingsStore.selectedEDRC !=null && jobSettingsStore.selectedEDRC.credentials!=null && jobSettingsStore.selectedEDRC.credentials.userName!=''? jobSettingsStore.selectedEDRC.credentials.userName:'',true);
            form.setFieldValue("password",jobSettingsStore.selectedEDRC != null && jobSettingsStore.selectedEDRC.credentials!=null && jobSettingsStore.selectedEDRC.credentials.password != '' ? jobSettingsStore.selectedEDRC.credentials.password : '',true);
        }
    },[addMode,displayStore.screenSettings.renderValue])
    
    useEffect(() => {
        setTimeout(() => populateFields(), 1000);
    }, []);

    const populateFields = () => {
        if (rootStore.createJobStore.networkSetting.dnsResolve != null)
            setOptionDns(rootStore.createJobStore.networkSetting.dnsResolve);
    }

    const handleChangeDNS = (event) => {
        setOptionDns(event.target.value);
        rootStore.createJobStore.updateNetworkSettings({ dnsResolve: event.target.value });
    };

    const handleClickShowPassword  = () => {
        setPasswordVisibility(passwordVisibility? false: true);
    };
    
    const handleUsernameChange = (event:React.ChangeEvent,value:any) => {
        if(event!=null){
        let onInput= event.type=="click"? false:true;
        setUserPassAutocomplete(onInput);
        if (value!=null && typeof value === 'object') 
        {
            form.setFieldValue("username", value == "" ? '' : value.username, true);
            form.setFieldValue("password", '', true);
            rootStore.createJobStore.updateBaseSettings({workflowTest: {...rootStore.createJobStore.baseSettings.workflowTest, PrimaryUser: value=="" ? '':value.username,PrimaryPassword:''}});
        }
        if (typeof value === 'string')
        {
            jobSettingsStore.updateTempEDRC({ credentials: { ...rootStore.jobSettingsStore.selectedEDRC.credentials,userName:value=="" ? '':value} });
            form.setFieldValue('username',value);
            form.setFieldValue("password", '', true);
        }
        setShowEyePassword(onInput);
        if(!onInput)
        {
            setPasswordVisibility(true);
        }
      }
    };
    
    const handlePassChange = (event) => {
        setUserPassAutocomplete(true);
        setShowEyePassword(true);
        jobSettingsStore.updateTempEDRC({ credentials: { ...rootStore.jobSettingsStore.selectedEDRC.credentials,password:event.target.value=="" ? '':event.target.value} });
        form.setFieldValue('password',event.target.value);
    };

    const handlehostnameIPChange =(event)=> 
    {
        jobSettingsStore.updateTempEDRC({assetName: event.target.value})
        form.setFieldValue("hostnameIP",event.target.value,true);
    }

    const handleDisplayNameChange =(event)=>
    {
        jobSettingsStore.updateTempEDRC({assetVMName: event.target.value})
        form.setFieldValue("displayName",event.target.value,true);
    }

    const loadCircular =()=>{
        return false;
    }

    const handleAdd =  async() => {
        setButtonsDisabled(true);
        if(jobSettingsStore.EDRCs.find(item=>item.assetName == form.values.hostnameIP) == undefined)
        {
            runInAction(async ()=>{
            await jobSettingsStore.createAsset(jobSettingsStore.tempEDRC,AssetType.EDRC);
            setButtonsDisabled(false);
        });
        }  
        else
        {
            toast.error("EDRC already exist in your list. To use it, simply select it from the dropdown");
            setButtonsDisabled(false);
        } 
    }

    const handleUpdate = async() => {
        runInAction(async ()=>{
            setButtonsDisabled(true);
            let edrc =jobSettingsStore.EDRCs.find(item=>item.assetName == form.values.hostnameIP);
            var assetToEdit = new AssetDTO();
            assetToEdit.id = edrc.id;
            assetToEdit.assetName = form.values.hostnameIP;
            assetToEdit.assetVMName =form.values.displayName;
            assetToEdit.assetIP = form.values.hostnameIP
            var cred = new AccessCredentials();
            cred.userName = form.values.username;
            cred.password = form.values.password;
            assetToEdit.credentials = cred;
            await jobSettingsStore.editAsset(AssetType.EDRC,assetToEdit);
            setButtonsDisabled(true);
        });
    }

    const handleDelete = async() => {
        setButtonsDisabled(true);
        await jobSettingsStore.deleteAsset(AssetType.EDRC);
        setButtonsDisabled(true);
    }

    const disableAddApply =()=>{
        return form.values.hostnameIP =="" || form.values.displayName =="" ;
    }

    const disableDelete =()=>{
        return displayStore.screenSettings.EDRCApplyProcessing;
    }

    const handleConfirmationPopup =(confirm:boolean)=>{
        if(confirm)
        {
            handleDelete();
        }
        setopenConfirmationEDRCDialog(false);
    }

    const handleMouseClick =()=>{
        if(form.values.password === CONSTS.MaskedPassword)
        {
            form.setFieldValue("password",'',true);
        }
    }
  
    const handleMouseOut =()=>{
        if(form.dirty && form.values.password == '')
        {
          if(form.values.username!='')
          {
            form.setFieldValue("password",CONSTS.MaskedPassword,true);
          }
          else form.setFieldValue("password",'',true);
        }
    }

    return(
    <Grid container direction="column">

            <div style={{ display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
            <Typography variant='h6' className={classes.MainHeading}>{t('controllerSetting')}</Typography>
            <div style={{ display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
            {!addMode ? <SaveButton onClick={()=>setopenConfirmationEDRCDialog(true)} disabled={disableDelete()|| buttonsDisabled} variant="contained" color="primary" style={{height:'25px',textTransform:'none',marginRight:'10px'}}>
            {loadCircular() ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
            {t('delete')}
            </SaveButton> :null } 
            <SaveButton onClick={addMode ? handleAdd : handleUpdate} disabled={disableAddApply() || buttonsDisabled} variant="contained" color="primary" style={{height:'25px',textTransform:'none'}}>
            {loadCircular() ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
            {addMode ? t('add') : t('apply')}
            </SaveButton>
            </div>   
            </div>

            <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px' ,marginLeft:'10px'}}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{AssetType[AssetType.EDRC] + t('configuration')}</Typography>
                    <Tooltip title={t("InstanceConfigurationInfoEDRC")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip> 
                    <Divider className={classes.divider}/>
                </div>
            </Grid>

            <Grid container style={{marginTop:'10px'}}>
            <Grid item xs={6}> 
            <FormControl className={classes.formControl} variant='outlined'>   
            <EDRTextField id="servername"
             label={"* "+t('hostnameIP')}
             placeholder=""
             aria-autocomplete="none"
             value={form.values.hostnameIP}
             disabled={!addMode}
             style={{ margin: 8 }}
             onChange={handlehostnameIPChange}
             InputLabelProps={{
             shrink: true,
             }}
             variant="outlined">
         </EDRTextField>
             </FormControl>
         </Grid> 
          {<Grid item xs={6}> 
             <FormControl className={classes.formControl} variant='outlined'>
             <EDRTextField id="displayName"
             label={"* "+t('displayName')}
             placeholder=""
             aria-autocomplete="none"
             value={form.values.displayName}
             style={{ margin: 8 }}
             onChange={handleDisplayNameChange}
             onBlur={form.handleBlur}
             error={form.touched && form.touched.displayName && form.errors && Boolean(form.errors.displayName)}
             helperText={form.touched && form.touched.displayName && form.errors && Boolean(form.errors.displayName) && t(form.errors.displayName)}
             InputLabelProps={{
             shrink: true,
             }}
             variant="outlined">
             </EDRTextField>   
              </FormControl>
                  </Grid>}
              </Grid>
        
        <Grid item container direction="row" style={{marginLeft:'1%'}}>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px',marginBottom:'4px'}}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('customAuthentication')}</Typography> 
                    <Tooltip title={t("customAuthenticationInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.headerDivider} style={{width:'338%'}}/>
                </div>
        </Grid>

            <Grid container style={{marginTop:'10px',marginLeft:'-3%'}} spacing={2}>
                <Grid item xs={6}> 
                   <FormControl className={classes.formControl} variant='outlined'>   
                   <EDRAutocomplete id="custom-username"
                        freeSolo
                        value={form.values.username}
                        options={getCustomAuthentication}
                        renderOption={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
                        getOptionLabel={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
                        onChange={handleUsernameChange}
                        onInputChange={handleUsernameChange}
                        renderInput={(params) => (<EDRTextField {...params}
                        label= {t('customUsername')}
                        InputLabelProps={{shrink: true}} 
                        style={{backgroundColor:'#ffffff',margin:8,marginLeft:'6%',paddingRight:'10px'}}
                        margin="normal"
                        aria-autocomplete="none"
                        variant="outlined"
                        placeholder="Type Here"
                        />)}
                        />
                    </FormControl>
                    </Grid>
                <Grid item xs={6}>
                        <FormControl className={classes.formControl} variant='outlined'>
                                <InputLabel ref={ref => {labelRef = ReactDOM.findDOMNode(ref)}} style={{transform: 'translate(14px, 2px) scale(0.75)'}}>{ t('customPassword')}</InputLabel>
                                <EDROutlinedInput id="custom-password" 
                                value={form.values.password}
                                autoComplete="off"
                                aria-autocomplete="none"
                                type={passwordVisibility ? 'password':'text'}
                                placeholder="Type Here"
                                label={<InputLabel style={{marginTop:'8px'}}>{ t('customPassword')}</InputLabel>}
                                style={{ margin: 8,marginRight:'-16px',backgroundColor:'#ffffff' }}
                                onChange={handlePassChange}
                                onClick={handleMouseClick}
                                onBlur={handleMouseOut}
                                endAdornment={form.values.password && UserPassAutocomplete ?
                                    <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={()=>handleClickShowPassword()}
                                    >
                                    {passwordVisibility ?  <VisibilityOff />: <Visibility />}
                                </IconButton>
                                </InputAdornment>
                                :null }/>  
                                </FormControl>
                    </Grid>
        </Grid>
        
        {/*
        <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '25px' }}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('additionalSettings')}</Typography> 
                    <Tooltip title={t("additionalSettingsInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.headerDivider} style={{width:'155%'}}/>
                </div>
        </Grid>

        <Grid container direction="row">
            <Grid item xs={6} direction="row" style={{display: 'flex',flexDirection:'row', marginTop:'35px'}}>
                <Typography className={classes.text} style={{color: '#1f396d'}}>{t('additionalIP')}</Typography>
            </Grid>
            <Grid item xs={6}>
                <IPSectionFormComponent ipAddresses={additionalEdrcIp} onChange={values =>createJobStore.updateNetworkSettings({additionalEdrcIp: values})} section={"additionalIP"}/>
            </Grid> 
            <Divider className={classes.divider}/>  
        </Grid>
        
        <Grid container direction="row">
            <Grid item xs={6} direction="row" style={{display: 'flex',flexDirection:'row', marginTop:'35px'}}>
                <Typography className={classes.text} style={{color: '#1f396d'}}>{t('additionalDNS')}</Typography>
            </Grid>
            <Grid item xs={6}>
                <IPSectionFormComponent ipAddresses={additionalEdrcDns} onChange={values =>createJobStore.updateNetworkSettings({additionalEdrcDns: values})} section={"additionalDNS"}/>
            </Grid> 
            <Divider className={classes.divider}/>  
        </Grid>
        
        <Grid container direction="row">
            <Grid item xs={6} direction="row" style={{display: 'flex',flexDirection:'row', marginTop:'35px'}}>
                <Typography className={classes.text} style={{color: '#1f396d'}}>{t('additionalGatway')}</Typography>
            </Grid>
            <Grid item xs={6} style={{marginTop:'10px'}}>   
                <IPSectionFormComponent ipAddresses={additionalDefaultGateway} onChange={values =>createJobStore.updateNetworkSettings({additionalDefaultGateway: values})}  section={"additionalGatway"}/>
            </Grid> 
        </Grid>
 */}
  <ConfirmationDialog type="deleteEDRC" device={null} open={openConfirmationEDRCDialog} onClose={(confrim)=>handleConfirmationPopup(confrim)} children={"You are about the delete "+ form.values.hostnameIP +" - are you sure?"}></ConfirmationDialog>
    </Grid>
    );
};

export default observer(ControllerSettingsComponent);