import React from 'react';

const iconStyles: {[key: string]: React.CSSProperties} = {
    small: {
        width: 10,
        height:11,
    },
    regular: {
        width: 21,
        height: 24
    },
    large: {
        width: 31,
        height: 36
    }
}

const ICON_MAP = {
    'Carbonite Replication VMware': 'carbonite',
    'Carbonite VMware': 'carbonite',
    'Recover point for VMware': 'recoverpoint',
    'RecoverPoint': 'recoverpoint',
    'VMware VC': 'vcenter',
    'Cohesity VMware': 'cohesity',
    'Cohesity Runbook': 'cohesityRunbook',
    'Zerto VMware': 'zerto',
    'Zerto Azure': 'zerto',
    'Zerto Vmware Azure':'zerto',
    'Veeam SQL': 'veeam',
    'Veeam VMware': 'veeam',
    'Netapp VMware': 'netapp',
    'VMware SRM': 'srm',
    'Custom VMware': 'custom',
    'Custom AWS': 'custom',
    'Custom Azure': 'custom',
    'Custom GCP': 'custom',
    'ASR Azure': 'asr',
    'Azure IAAS': 'azure',
    'Azure PAAS': 'azure',
    'Rubrik':'rubrik',
    'AWSDRS':'awsdrs',
    'AIX':'aix',
    'Netapp NAS':'netappnas',
    'SAP HANA':'sap',
    'Oracle':'oracle',
    'MS SQL Server Vmware':'mssql',
    'MS SQL Server Azure':'mssql',
    'Pure Storage':'pureStorage'
}
type DataMoverIconProps = {
    name: string,
    className?: string,
    style?: React.CSSProperties,
    variant: "small" | "regular" | "large"
}
 const DataMoverIcon: React.FC<DataMoverIconProps> = (props) => {
    let icon = null;
    const {variant, name,style, className} = props;

    if(variant === 'regular') {
        icon = `/assets/images/datamoverIcons/${ICON_MAP[name.trim()]}.png`;
    } else {
        icon = `/assets/images/datamoverIcons/${ICON_MAP[name.trim()]}-${variant}.png`;
    }

    return <img src={icon} style = {{...iconStyles[variant], ...style}} className={className}/>;

}

export default DataMoverIcon;