import React, { useContext, useEffect, useState } from "react";
import { RootStoreContext } from "../../app/stores/root.store";
import { observer } from "mobx-react-lite";
import { Grid, makeStyles, Paper, Typography, Divider, Badge, Hidden, CircularProgress } from "@material-ui/core";
import Graph from "./Graph.component";
import Map from "./map/Map.component";
import DevicesPanel from "./devicesPanel/DevicesPanel.component";
import ActivityPanel from "./activityPanel/ActivityPanel.component";
import Score from "./score/Score.component";
import NoticePanel from "./noticePanel/NoticePanel.component";
import { useTranslation } from 'react-i18next';
import { IActivityPanelConfig } from "../../app/models/dashboard/activitiesPanel.model";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { convertSecondsToTime, cleanObject } from "../../app/common/util/util";
import GaugeChart from "../../app/common/gaugeChart.component";
import moment from "moment";
import CheckBoxFilter from "../../app/common/checkboxFilter";
import JobCategoryIcon from "../../app/common/jobCategoryIcon";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import DeviceSearch from '../../app/common/deviceSearch.component';
import { JobResultQuery } from "../../app/models/jobResultQuery.model";
import queryString from 'querystring';
import AppBar, { AppBarTitle } from "../navigation/appbar/appBar.component";
import GenericCheckBoxFilter from "../../app/common/genericCheckboxFilter.component";
import { LastJobRun, LastJobRunStatus } from "../../app/models/lastjobRun.model";
import JobDeviceSearchProvider from "../../app/common/context.component";
import Cog from "./Cog.component";
import EmptyDasboard from "../../app/common/emptyDashboard.component";
import DataMoverIcon from "../../app/common/dataMoverIcon.component";
import TimerIcon from '@material-ui/icons/Timer'
import { JobDeviceResultAdapter } from "../../app/common/util/jobDevice.adapter";
import { IDevicesPanel } from "../../app/models/dashboard/devicePanel.model";
import EmptyGraph from "../../assets/dashboardAssets/empty_graph.svg"
import { settingObj } from "../../app/models/setting.model";

const dateFormat = "DD.MM.YY";
const jobCategoryOptions: Array<string> = ["all", "atRisk", "recoverable", "resilient"]

const JOB_STATUS_ICON = {
  "all": <JobCategoryIcon status={-1} variant="small" style={{ height: "12px", marginRight: "5px" }} />,
  "atRisk": <JobCategoryIcon status={LastJobRunStatus.atRisk} variant="small" style={{ height: "12px", marginRight: "5px" }} />,
  "recoverable": <JobCategoryIcon status={LastJobRunStatus.recoverable} variant="small" style={{ height: "12px", marginRight: "5px" }} />,
  "resilient": <JobCategoryIcon status={LastJobRunStatus.resillient} variant="small" style={{ height: "12px", marginRight: "5px" }} />


}

function getQueryParams(searchParams: string): JobResultQuery {
  if (!searchParams) {
    return {
      category: "all",
      startDate: null,
      endDate: null,
      dataMover: [],
      jobNames: [],
      deviceJobIds: []
    };
  }
  const parsed = queryString.parse(searchParams.slice(1));


  return {
    category: parsed.category as string || 'all',
    servers: parsed.servers,
    startDate: parsed.startDate as string,
    endDate: parsed.endDate as string,
    dataMover: parsed.dataMover || [],
    jobNames: parsed.jobNames || [],
    deviceJobIds: parsed.deviceJobIds? parsed.deviceJobIds instanceof Array? parsed.deviceJobIds : [parsed.deviceJobIds as string] : [],
    jobId: parsed.jobId ? parseInt(parsed.jobId as string) : null
  };
}

const Dashboard: React.FC<RouteComponentProps> = (props) => {
  const rootStore = useContext(RootStoreContext);
  const dashboardStore = rootStore.dashboardStore;
  const jobRunStore = rootStore.jobRunStore;
  const jobResultStore = rootStore.jobResultStore;
  const { dashboardSettings, getSettings } = rootStore.settingStore;
  const [toggleFilter, setToggleFilter] = useState("dataMover");
  const query = dashboardStore.query;
  const [selectedJobDeviceIds, setselectedJobDeviceIds] = useState<Array<number>>([]);
  const [scoreLoaded, setScoreLoaded] = useState(false);
  const [graphData, setgraphData] = useState<{ values: Array<number>, labels: Array<Date> }>({ values: [], labels: [] });
  const graphSettingObj: settingObj = dashboardSettings.find((element: settingObj) => element.settingKey == "Graph");
  const graphSettings: string = graphSettingObj != undefined ? graphSettingObj.settingValue : "Single";

  const onToggleFilterChange = (event, value) => {
    setToggleFilter(event.currentTarget.value);
  };

  const onDeviceSelection = (selectedDeviceJobIds: Array<number>) => {
    const query = { deviceJobIds: selectedDeviceJobIds };
    props.history.push({ search: queryString.stringify(query) });
    jobResultStore.setloadedJobResults(true);
  }

  const onJobFilterSelection = (selectedJobs: Array<LastJobRun>) => {
    const query = { deviceJobIds: selectedJobs.map(jr => jr.jobId) }
    props.history.push({ search: queryString.stringify(query) });
  }
  const handleDataMoverFilterChange = (filter: Array<{ label: string, checked: boolean }>) => {
    if (filter.every(option => option.checked)) {
      props.history.push({ search: queryString.stringify({ dataMover: filter.map(option => option.label) }) })
    } else {
      const newQuery = { ...query, dataMover: filter.filter(f => f.checked).map(f => f.label) }
      props.history.push({ search: queryString.stringify(cleanObject(newQuery)) });
    }
    jobResultStore.setloadedJobResults(true);
  }

  const handleJobListFilterChange = (filter: Array<{ label: string, checked: boolean }>) => {
    if (filter.every(option => option.checked)) {
      props.history.push({ search: queryString.stringify({ jobFilter: filter.map(option => option.label) }) })
    } else {
      const jobfilterQuery = { ...query, jobFilter: filter.filter(f => f.checked).map(f => f.label) }
      
      props.history.push({ search: queryString.stringify(jobfilterQuery) });
    }
    jobResultStore.setloadedJobResults(true);
  }

  useEffect(() => {
    const query = getQueryParams(props.location.search);
    dashboardStore.setQueryParams({ ...query, jobId: null });
    dashboardStore.setJobSelectionChanged();
    if (query.deviceJobIds && query.deviceJobIds instanceof Array) {
      setselectedJobDeviceIds(query.deviceJobIds.map(d => parseInt(d)));

    } else {
      setselectedJobDeviceIds(query.deviceJobIds ? [parseInt(query.deviceJobIds as string)] : [])
    }
  }, [props.location.search,getSettings]);

  const {
    scheduledJobs,
    rta,
    rto,
    score,
    nextScheduledJob,
    lastRunJob,
    filteredJobResults: jobResults,
    DevicesData,
    jobsResultLoaded
  } = rootStore.dashboardStore;

  const [t] = useTranslation("dashboard");
  const graphHeader = t("graphHeader");


  const graphSetting = {
    graphHeader: graphHeader,
    dataPoints: [],
    dataPointsDates: []
  };

  const lastRuns = jobResults.filter(j => j.runDate);
  const scoreSettings = {
    score: score,
    startDate: lastRuns.length == 0 || !lastRuns[0].runDate ? null : moment(lastRuns[0].runDate).format(dateFormat),
    endDate: lastRuns.length < 1 || !lastRuns.slice(-1)[0].runDate ? null : moment(lastRuns.slice(-1)[0].runDate).format(dateFormat)
  };

  const noticePanelConfig = {
    rpa: 0,
    rta: rta,
    realDowntime: rta
  };
  const nextRun =
    nextScheduledJob && nextScheduledJob.nextRun;
  const lastRun =
    lastRunJob && lastRunJob.runDate;
  const schedule = scheduledJobs ? scheduledJobs.length : 0;
  const items = jobResults;
  const ActivityPanelConfigForItems: IActivityPanelConfig = {
    items: items,
    nextRun: nextRun && moment(nextRun).format(dateFormat),
    lastRun: lastRun && moment(lastRun).format(dateFormat),
    scheduledJobsCount: schedule,
  };
  useEffect(() => {
    setScoreLoaded(false)
    drawGraph()
  }, [dashboardStore.jobSelectionChanged])
  const devicesConfig: IDevicesPanel = 
  {
    devicesData: dashboardStore.DevicesData,
    ok: DevicesData ? DevicesData.filter(x => x.status == 'ok').length : 0,
    failed: DevicesData ? DevicesData.filter(x => x.status == 'failed').length : 0,
  };
  const drawGraph = () => {
    let isCancelled = false;
      dashboardStore.getScoreData(graphSettings).then(scoreData => {
        if (isCancelled)
        {
          setScoreLoaded(true);
          return;

        }
        let data : {[key: string]: Array<number>} = {};
        Array.from(scoreData).forEach(scoreDataPoint => {
            var date = moment(scoreDataPoint.runDate).format('YYYY/MM/DD');
            
            if (data[date]) {
              data[date].push(scoreDataPoint.score);
            } else {
              data[date] = [scoreDataPoint.score];
            }
          });
        rootStore.settingStore.setPointCounter(scoreData.length);
        let graphData = Object.entries(data).sort(([a],[b]) => a.localeCompare(b) ).reduce((acc, [label, value]) => {
          acc.labels.push(moment(label).toDate());
          acc.values.push(value.reduce((sum, value) => sum + value, 0) / value.length);
          return acc;
        }, { values: [], labels: [] });
        setgraphData(graphData);
        setScoreLoaded(true);
      });
    return () => {
      isCancelled = true;
      setScoreLoaded(true);
    }
  }
  const useStyles = makeStyles(theme => {
    return {
      root: {
        //flexGrow: 1,#f4f5f9
      },
      jobCategory: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer"
      },
      badge: {
        backgroundColor: "#d6d6d9",
        color: "#4d5f75",
        minWidth: 30
      },
      dashboardContainer: {
        padding: '15px 5px 15px 15px',
        background: '#E9EBEE',
        minHeight: '100%',
        // overflowY: 'scroll',
        overflowX: 'hidden'
      },
      label: {
        textTransform: 'capitalize',
      },
      container: {
        height: `100vh`,
        minHeight: "-webkit-fill-available",
        //  overflowY : "scroll",
        "&::-webkit-scrollbar":  {
          display: "none"
        },
        [theme.breakpoints.down('xl')]: {
           overflowY: "scroll"
        },
        
        [theme.breakpoints.up('xl')]: {
          overflowY: "hidden"
        },
        "@media (max-height:940px)": {
            overflowY: 'scroll'
          
        },
      }
    }
  });

  const classes = useStyles();
  const scoretest=false;
  const maptest=false;
  return (
    <Grid  style={{backgroundColor:"#E9EBEE" }} container data-test="component-dashboard" direction="row" className="h100">
      <Grid item xs={3} lg={2} xl={2} style={{paddingRight: 15, paddingLeft: 15,maxWidth: '400px', height:'100vh' }} classes={{ root: "filter_container" }} >
        <div style={{paddingTop:20}}>
        <span data-test="filterByJobNameTitle"><Typography classes={{root: "jobFilter"}}>{t("filterJob")}</Typography></span>
          <GenericCheckBoxFilter<LastJobRun>
            options={dashboardStore.jobResults}
            value={dashboardStore.jobResults.filter(jr => !query.deviceJobIds || query.deviceJobIds.indexOf(jr.jobId.toString()) >= 0)}
            filterOptions={(option, filter) => option.jobName.toLowerCase().indexOf(filter.toLowerCase()) === 0}
            onChange={onJobFilterSelection}
            renderLabel={(option) => <div style={{display: 'flex', flexDirection: 'column'}}>
              <span id={`dashboard_job_filter_${option.jobName}`} style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} >{option.isRunning ? <CircularProgress  size={12} style={{color: '#2892d7', marginRight: 2}}/>: <JobCategoryIcon style={{marginRight:5}} variant="small" status={option.status || option.score} full={true} /> }{option.jobName.length>25? option.jobName.substring(0,25).concat('...'):option.jobName}</span>
            </div>}
            renderEDRServer={(option) => <div style={{color: 'gray', paddingLeft: '40px',fontSize:'10px'}}>{option.edrServer}</div>}
            showAllOption={true}
            showSearchInput={true}
            searchText={t("searchText")}
            allText={t("allText")}
          />
        </div>
        <Divider style={{ marginLeft: "1.5em" }} />
        <Grid container justify="center" direction="column" style={{ paddingTop: "15px", paddingBottom: "15px", boxSizing: 'border-box' }}>
          <ToggleButtonGroup
            classes={{ root: "toggle_button_group" }}
            value={toggleFilter}
            onChange={onToggleFilterChange}
          >
            <ToggleButton
              classes={{ root: "toggle_button", selected: "toggle_button_selected", label: classes.label }}
              value="dataMover">
              {t("toggleButton.dataMover")}
            </ToggleButton>
            <ToggleButton
              classes={{ root: "toggle_button", selected: "toggle_button_selected", label: classes.label }}
              value="server">
              {t("toggleButton.servers")}
            </ToggleButton>
          </ToggleButtonGroup>

          {toggleFilter === "dataMover" &&
            <CheckBoxFilter
              options={dashboardStore.dataMovers}
              onChange={handleDataMoverFilterChange}
              showCount={true}
              allOptionLabel={<span>{t("allDataMover")}</span>}
              renderLabel={(option) => <div id={`dashboard_datamover_filter_${option.label}`} style={{display: 'flex', alignItems: 'center'}}>
            <DataMoverIcon name={option.label} variant="small" style={{marginRight: 5}}></DataMoverIcon>
            <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '80%'}}>{option.label}</span> 
        </div>}
         showAllOption={true} 
         allOptionIcon={<TimerIcon style={{position: 'relative', left: 10}}/>}
              showSearchInput={false}>
            </CheckBoxFilter>}
          <JobDeviceSearchProvider>
            {toggleFilter === "server" &&
              <DeviceSearch
                selectedDeviceJobIds={selectedJobDeviceIds}
                onChange={onDeviceSelection}
                searchDeviceText={t("searchDeviceText")}
              />
            }
          </JobDeviceSearchProvider>
        </Grid>
      </Grid>
      <Grid item xs={9} lg={10} xl={10} className={classes.container} >
        <AppBar showDevices={true}>
                    <Grid container>
                        <Grid xs={9}>
                        <AppBarTitle>
                            <Typography variant="h6">{"Dashboard"}</Typography>
                        </AppBarTitle>
                    
                        </Grid>
                        <Grid xs={3}  alignItems='center'>
                        </Grid>
                    </Grid>
                </AppBar>

        <Grid container item spacing={2} direction="column" classes={{ root: classes.dashboardContainer }} style={{height: (!dashboardStore.dashboardEmpty && !jobsResultLoaded ? '100%': 'auto') }}>
          <Grid item style={{ height: (!dashboardStore.dashboardEmpty && !jobsResultLoaded ? "calc(50% - 8px)": 'auto')}} container>
            <Grid container direction="row" spacing={2} >
              <Grid item container md={12} xl={7} >
                <Grid container direction="column" spacing={2} >
                  <Grid item container spacing={2}  style={{ flexGrow: 1, minHeight: '70%' }}>
                    <Grid item xs={6}>
                      <Paper classes={{ root: "paper" }} style={{height:"100%",display: 'flex',justifyContent: 'center', flexDirection: 'row'}} >
                      {(!dashboardStore.dashboardEmpty && !jobsResultLoaded)
                        ? <Grid direction='column' container style={{height: "100%"}} alignItems='center' justify='center'> <CircularProgress  size={50} color='primary' />
                        </Grid>
                        : <Score {...scoreSettings}/>
                        }
                      </Paper>
                    </Grid>
                    <Grid item  xs={6} >
                      <Paper classes={{ root: 'paper' }} style={{ display: 'flex', flexDirection: 'row',justifyContent: 'center',alignItems: 'center'}}>
                      {(!dashboardStore.dashboardEmpty && !dashboardStore.recoveryLoaded && !dashboardStore.jobsResultLoaded)
                      ? <CircularProgress  size={50} style={{color: '#2892d7'}} /> :
                      <Paper classes={{ root: 'paper' }} style={(!dashboardStore.dashboardEmpty && !dashboardStore.recoveryLoaded&& !dashboardStore.jobsResultLoaded)?{ display: 'flex', flexDirection: 'row',justifyContent: 'center',alignItems: 'center'}:{}}>
                        <Grid item container>
                          <Grid item style={{ width: "60%"}} >
                         <Typography classes={{ root: "dashboard_header" }}>{t("recoveryTime")}</Typography>
                          </Grid>
  
                        </Grid>              
                        <Grid container direction="column" justify="space-around" style={{ height: "100%" }}>
                          <div style={{ position: "relative", height: "53%", maxWidth: '99%' }}>
                            <GaugeChart width={200} height={200} value={rta} max={rto} drawTicks={true} cutoutPercentage={90} />
                            <Typography style={{ fontSize: "2rem", color: "#1f396d", fontFamily: "Roboto", fontWeight: "bold", textAlign: "center", width: "100%" }} variant="h4">{convertSecondsToTime(rta , false, t("hr"))}</Typography>
                          </div>
                          <Typography style={{ width: "100%", marginBottom: "2.5em", color: "#1f396d", textAlign: "center" }} variant="body1">{t("rtoSet")} {convertSecondsToTime(rto)}</Typography>
                        </Grid>
                        </Paper>
                        }
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2} >
                    <NoticePanel {...noticePanelConfig} />
                  </Grid>
                </Grid>
              </Grid>
              <Hidden lgDown>
                <Grid item xl={5}>
                  <Paper style={{height: '100%',display: 'flex', flexDirection: 'row',justifyContent: 'center' , alignItems: 'center'}}> 
                  {(!dashboardStore.devicesLoaded)
                        ? <CircularProgress  size={50} style={{color: '#2892d7'}}/>
                        : devicesConfig.devicesData && devicesConfig.devicesData.length > 0 ? <DevicesPanel {...devicesConfig} /> :
                        <Grid container alignItems='center' style={{ height: '100%' }}>
                        <table style={{ width: "100%", height: "100%" }}>
                          <tbody>
                            <tr><td align="center" style={{ height: "40%" }}><img src={EmptyGraph} style={{ objectFit: "contain" }} /><Typography style={{ fontWeight: 500, fontSize: "2rm" }}>{t("emptyDevices")}</Typography></td></tr>
                          </tbody>
                        </table>
                        </Grid>}
                  </Paper>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>

          <Hidden xlUp>
            <Grid item container>
              <Grid container spacing={2} direction="row">
                <Grid item xs={6}>
                  <Paper classes={{ root: "paper" }} style={{height: "100%",display: 'flex', flexDirection: 'row',justifyContent: 'center' , alignItems: 'center'}} >
                  {(!dashboardStore.dashboardEmpty && !dashboardStore.jobsResultLoaded)
                        ? <CircularProgress  size={50} style={{color: '#2892d7'}}/>
                        : devicesConfig.devicesData && devicesConfig.devicesData.length > 0 ? <DevicesPanel {...devicesConfig} /> : 
                        <Grid container alignItems='center' style={{ height: '100%' }}>
                        <table style={{ width: "100%", height: "100%" }}>
                          <tbody>
                            <tr><td align="center" style={{ height: "40%" }}><img src={EmptyGraph} style={{ objectFit: "contain" }} /><Typography style={{ fontWeight: 500, fontSize: "2rm" }}>{t("emptyDevices")}</Typography></td></tr>
                          </tbody>
                        </table>
                        </Grid>}
                  </Paper>
                </Grid>

                <Grid item xs={6}>
                  <Paper classes={{ root: 'paper' }} style={{display: 'flex', flexDirection: 'row',justifyContent: 'center' , alignItems: 'center'}}>
                  {!dashboardStore.dashboardEmpty && !dashboardStore.jobsResultLoaded
                        ? <CircularProgress  size={50} style={{color: '#2892d7'}}/>
                        :  <ActivityPanel {...ActivityPanelConfigForItems} />}
                  </Paper>
                </Grid>

              </Grid>
            </Grid>
          </Hidden>

          <Grid item style={{ height:(!dashboardStore.dashboardEmpty && !jobsResultLoaded ? "calc(50% - 8px)": 'auto')}} container>
            <Grid container spacing={2} direction="row">
              <Grid item md={12} xl={7}container>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Paper style={{height: '100%',display: 'flex', flexDirection: 'row',justifyContent: 'center' , alignItems: 'center'}}>
                     {(!scoreLoaded)
                        ? <CircularProgress  size={50} style={{color: '#2892d7'}}/>
                        : graphData.values.length > 0 ? <Graph graphData={graphData} Graph={graphSetting}/> :
                        <Grid container alignItems='center' style={{ height: '100%' }}>
                        <table style={{ width: "100%", height: "100%" }}>
                        <tbody>
                          <tr><td align="center" style={{ height: "40%" }}><img src={EmptyGraph} style={{ objectFit: "contain" }} /><Typography style={{ fontWeight: 500, fontSize: "2rm" }}>{t("emptyGraph")}</Typography></td></tr>
                        </tbody>
                      </table>
                      </Grid>}
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper classes={{ root: "paper"}}>
                    <Map/>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <Hidden lgDown>
                <Grid item xl={5}>
                  <Paper classes={{ root: 'paper' }} style={{display: 'flex', flexDirection: 'row',justifyContent: 'center' , alignItems: 'center'}} >
                  {(!dashboardStore.dashboardEmpty && !dashboardStore.jobsResultLoaded)
                        ? <CircularProgress  size={50} style={{color: '#2892d7'}}/>
                        : <ActivityPanel {...ActivityPanelConfigForItems} />}
                  </Paper>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default observer(Dashboard);
