import * as yup from 'yup';
export const LocationSettingSchema = yup.object({
  country: yup.object({   
        name: yup.string().required("requiredCountry")
  }),
  drSite: yup.object({name: yup.string().required()}).required(),
  productionSites: yup.array( yup.object({name: yup.string().required()})).min(1)
  //locationName: yup.object({
  //      name: yup.string().required("requiredCity")
  //})
})