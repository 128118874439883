import React, { useEffect, useState, useRef, useContext } from "react";
import { observer } from "mobx-react-lite";
import AppBar, { AppBarTitle } from "../navigation/appbar/appBar.component";
import { Grid, makeStyles, Button, Typography, CircularProgress, useMediaQuery, Hidden, Drawer, Fab } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import SettingsReportComponent from "./SettingsReport.component";
import SettingsLicenseComponent from "./SettingsLicense.component";
import SettingsDashboardComponent from "./SettingsDashboard.component";
import SettingsLocationComponent from "./SettingsLocation.component";
import EmailSettingsComponent from "./SettingEmail.component";
import SettingsBackupComponent from "./SettingsBackup.component";
import SettingsMonitoringComponent from "./SettingsMonitoring.component";
import PolicyIcon from '@material-ui/icons/Policy';
import SelectList from "../../app/common/selectList.component";
import { ReactComponent as DashboardIcon } from "../../assets/settingsAssets/dashboardIcon.svg";
import { ReactComponent as ReportIcon } from "../../assets/settingsAssets/reportIcon.svg";
import { ReactComponent as LocationIcon } from "../../assets/settingsAssets/locationIcon.svg";
import { ReactComponent as EmailIcon } from "../../assets/settingsAssets/emailIcon.svg";
import { ReactComponent as BackupIcon } from "../../assets/settingsAssets/backupIcon.svg";
import { ReactComponent as MonitoringIcon } from "../../assets/settingsAssets/monitoringIcon.svg";
import FilterIcon from '@material-ui/icons/FilterList';
import { RootStoreContext } from "../../app/stores/root.store";
import { toast } from "react-toastify";
import * as yup from 'yup';
import { useFormik } from "formik";
import { BackupSettingSchema, MonitoringSettingSchema, DashboardSettingSchema, EmailSettingSchema, LocationSettingSchema } from "./validation";
import { settingObj } from "../../app/models/setting.model";

const SettingsComponent: React.FC = () => {
  const rootStore = useContext(RootStoreContext); 
  const { loadingSettings, updateSettings, updatedSetting,backupSettings,monitoringSettings, emailSettings, locationSettings,contentValid, reportSettings} = rootStore.settingStore;  
  const [t] = useTranslation("settings");
  const [selectedSettingOption, setselectedSettingOption] = useState("dashboard")
  const locationRef = useRef(null);
  const reportRef = useRef(null);
  //const licenseRef = useRef(null);
  const dashboardRef = useRef(null);
  const emailRef = useRef(null);
  const backupRef = useRef(null);
  const monitoringRef = useRef(null);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const [openMenuDrawer, toggleMenuDrawer] = useState(false);

  // const validation = yup.object({
  //   monitoringSettings: MonitoringSettingSchema,
  //   backupSettings: BackupSettingSchema,
  //   dashboardSettings: DashboardSettingSchema,
  //   emailSettings: yup.object({
  //     emailtype: yup.string(), 
  //     emailSettings: EmailSettingSchema
  //   }),
  //   locationSettings: LocationSettingSchema,
  // });

  const validation = yup.object({
    monitoringSettings: MonitoringSettingSchema,
    backupSettings: BackupSettingSchema,
    dashboardSettings: DashboardSettingSchema,
    emailSettings: yup.object({
      emailtype: yup.string(), 
      emailSettings: yup.object().when("emailtype",{ is: 'system', then: yup.object(), otherwise: EmailSettingSchema})
    }),
    locationSettings: LocationSettingSchema,
  });
  
  const form = useFormik({
    initialValues: { monitoringSettings: {}, backupSettings: {},emailSettings: {}, dashboardSettings: {}, locationSettings: {}},
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: validation,
    onSubmit:(val) => {}
  });
  useEffect(() => {
   const timer = setTimeout(() => form.validateForm(), 100)
   return () => {
     clearTimeout(timer);
   }
  }, [form.values])
  const navigateToSettingOption = (option: string) => {
      setselectedSettingOption(option);
      switch (option) {
        case 'dashboard':
          scrollToRef(dashboardRef);
          break;
        case 'report':
          scrollToRef(reportRef);
          break;
        // case 'license':
        //   scrollToRef(licenseRef);
        //  break;
        case 'email':
          scrollToRef(emailRef);
          break;
        case 'location':
          scrollToRef(locationRef);
          break;
        case 'database':
            scrollToRef(backupRef);
          break;
        case 'monitoring':
            scrollToRef(monitoringRef);
          break;
        default:
          scrollToRef(dashboardRef);
          break;
      }
    }
  const settingsHandler= () => {

    //Uupdate location object from form

    const locationSettingFromForm = form.values.locationSettings as {country: any, drSite: any, productionSites: Array<any>};

    const locationSettingUpdate: Array<settingObj> = [];
    locationSettingUpdate.push({ settingKey: 'Country', settingSection: 'Location', settingValue: JSON.stringify(locationSettingFromForm.country)});
    locationSettingUpdate.push({ settingKey: 'coords_dr', settingSection: 'Location', settingValue: JSON.stringify(locationSettingFromForm.drSite)});
    locationSettingFromForm.productionSites.forEach((prod_site, index) => {
      locationSettingUpdate.push({ settingKey: `coords_prod_${index}`, settingSection: 'Location', settingValue: JSON.stringify(prod_site)});
    })
    

    //if (rootStore.settingStore.contentValid){
      if (form.isValid){
        let values = { Settings: [] };
        updatedSetting.forEach(key=>{
            values.Settings.push(key);
        })
        backupSettings.forEach(key=>{
          values.Settings.push(key);
        })
        monitoringSettings.forEach(key=>{
          values.Settings.push(key);
        })
        locationSettingUpdate.forEach(key=>{
          values.Settings.push(key);
        })
        emailSettings.forEach(key=>{
          values.Settings.push(key);
        })
        if (JSON.stringify(values) !='{"Settings":[]}'){
            updateSettings(values).finally(
              () => {
                rootStore.settingStore.settingsUpdateError ?
                toast.error(t("updateFailed"))
                :
                rootStore.settingStore.settingUpdateSuccess ?
                  toast.success(t("updateOK"))
                  : toast.error(t("updateFailed"))
              }
            )
          }
      }
      else {
        toast.info(t("validateFirst"))
      }
    
    } 


  const useStyles = makeStyles({
    settingsContainer: {
      backgroundColor: "#E9EBEE",
    },
    label: {
      textTransform: 'capitalize',
    },
    body: {
      width: "50%",
      align: "center",
      marginLeft: "25%",
      marginRight: "25%",
      marginTop: "2%",
    },
    menu_container: {
      height: "100vh",
      width: "200px",
      background: "white",
      position: "sticky",
      top: 0,
      zIndex: 1101,
      boxShadow:  "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
    },
    innerSettingsContainer: {
      position: "sticky",
    },
    disabledButton: {
      width: "4em",
      height: "3em",
      background: "#bacedb !important",
      color: "white !important",
      borderRadius: "28px !important",
      marginBottom: "2em !important"
   }
  });

  const classes = useStyles();

  //const settingsOption: Array<string> = [ "dashboard", "report","license", "email", "backup","monitoring","location"]
  const settingsOption: Array<string> = [ "dashboard", "report","email", "database","monitoring","location"]
  const SETTING_OPTION_ICON = {
    "dashboard": <DashboardIcon style={{marginRight: 10}} />,
    "report": <ReportIcon style={{marginRight: 10}} />,
    //"license": <PolicyIcon style={{marginRight: 10,fontSize: "medium",color:"rgb(128, 146, 169)",width:'5%'}} />,
    "email": <EmailIcon style={{marginRight: 10}} />,
    "database": <BackupIcon style={{marginRight: 10}} />,
    "monitoring": <MonitoringIcon style={{marginRight: 10}} />,
    "location": <LocationIcon style={{marginRight: 10}} />
  }

  const buttonContents = (
    <React.Fragment>
       {t("save")}
    </React.Fragment>
  );

  const checkDisabled = () => {
    if (locationSettings.length===0 || emailSettings.length===0 || contentValid===false)
        return true
    else
        return false
  }   
  
 const showFilter = useMediaQuery('(min-width: 960px)')
 const menuPanel=<Grid item xs={showFilter? 2: 9} className={classes.menu_container}>
 <SelectList<string> 
       options={settingsOption} 
       onChange={option => navigateToSettingOption(option)} 
       value={selectedSettingOption} 
       title="" 
       getLabel={option => t(option)} 
       getIcon={option => SETTING_OPTION_ICON[option]}
 />
</Grid>

  return (
    <div data-test="component-Settings" className={classes.settingsContainer}>
      <Grid container direction="row">
        {showFilter && menuPanel}
          <Hidden mdUp={true}>
            <Drawer variant="temporary" anchor={'left'} open={openMenuDrawer} ModalProps={{  BackdropProps: {invisible: true} } } onClose={() => toggleMenuDrawer(false)}>
                  {menuPanel}
              </Drawer>
        </Hidden>
        <Hidden mdUp>
        <Fab color="primary" style={{position: 'fixed', left: '80px', bottom: '10px', zIndex: 1}} onClick={() => toggleMenuDrawer(true)}>
              <FilterIcon/>
          </Fab>  
        </Hidden>
        <Grid item xs={10} style={{ display: "flex", flexDirection: "column" }}>
        <AppBar showDevices={true}>
                    <Grid container>
                        <Grid xs={9}>
                        <AppBarTitle>
                        <Typography style={{ textAlign: "left" }} variant="h6">
                            {t("settings")}
                        </Typography>
                        </AppBarTitle>
                    
                        </Grid>
                        <Grid xs={3}  alignItems='center'>
                        </Grid>
                    </Grid>
                </AppBar>
          {/* <div>{JSON.stringify(form.values)}</div>
          <div>{JSON.stringify(form.errors)}</div> */}
          <div style={{ position: "sticky", top: "65px", zIndex: 9, background: 'white' }}>
            <span style={{position: "relative",float:"right", width:"29%", marginTop: "20px"}}>
            <Button
                    disabled={
                      //checkDisabled() || !form.isValid
                      !form.isValid
                    }
                    variant="contained"
                    classes={{ root: "save_button", disabled: classes.disabledButton , label: classes.label}}
                    onClick={() => {
                        settingsHandler()
                    }}
                    >{loadingSettings ? <CircularProgress size={30} style={{color: "white"}} /> : buttonContents}
            </Button>
            </span>
          </div>
          <Grid container direction="column" className={classes.body}>
            <div className={classes.innerSettingsContainer}>
              <Grid item classes={{ root: "spaceSettings"}}>
                <span ref={dashboardRef}></span>
                <Typography classes={{ root: "settingHeader"}} variant="h6" >{t("dashboard")}</Typography>
              </Grid>
              <Grid item>
                <SettingsDashboardComponent onChange={val => form.setFieldValue('dashboardSettings', val)} />
              </Grid>
              <Grid item classes={{ root: "spaceSettings"}}>
                <span ref={reportRef}></span>
                <Typography classes={{ root: "settingHeader"}} variant="h6" >{t("report")}</Typography>
              </Grid>
              <Grid item>
               <SettingsReportComponent />
              </Grid>

              {/* <Grid item classes={{ root: "spaceSettings"}}>
                <span ref={licenseRef}></span>
                <Typography classes={{ root: "settingHeader"}} variant="h6" >{t("license")}</Typography>
              </Grid>
              <Grid item>
               <SettingsLicenseComponent/>
              </Grid> */}
              <Grid item classes={{ root: "spaceSettings"}}>
                <span ref={emailRef}></span>
                <Typography classes={{ root: "settingHeader"}} variant="h6" >{t("email")}</Typography>
              </Grid>
              <Grid item>
                  <EmailSettingsComponent onChange={val => form.setFieldValue('emailSettings', val)} />
              </Grid> 
              <Grid item classes={{ root: "spaceSettings"}}>
                <span ref={backupRef}></span>
                <Typography classes={{ root: "settingHeader"}} variant="h6" >{t("database")}</Typography>
              </Grid>
              <Grid item>
                <SettingsBackupComponent onChange={val => form.setFieldValue('backupSettings', val)} />
              </Grid>
              <Grid item classes={{ root: "spaceSettings"}}>
                <span ref={monitoringRef}></span>
                <Typography classes={{ root: "settingHeader"}} variant="h6" >{t("monitoring")}</Typography>
              </Grid>
              <Grid item>
              <SettingsMonitoringComponent onChange={val => form.setFieldValue('monitoringSettings', val)} />
              </Grid>
              <Grid item classes={{ root: "spaceSettings"}}>
                <span ref={locationRef}></span>
                <Typography classes={{ root: "settingHeader"}} variant="h6" >{t("location")}</Typography>
              </Grid>       
              <Grid item style={{marginBottom: "10px"}}>
                <SettingsLocationComponent onChange={val => form.setFieldValue('locationSettings', val)} />
              </Grid>
            </div>
          </Grid>
       </Grid>
      </Grid>
    </div>
  );
};

export default observer(SettingsComponent);
