import { useContext, useEffect, useState } from 'react';
import jobAgent from '../../../app/api/jobAgent';
import { UpdateType } from '../../../app/stores/activityStore';
import { RootStoreContext } from '../../../app/stores/root.store';

export function useAnyRuns(subscribeToJobUpdate = false) {
    const [anyRuns, setAnyRun] = useState(false);
    const [isRunLoading, setIsLoading] = useState(true);
    const { activityStore } = useContext(RootStoreContext);
    const checkAnyRuns = () => {
        setIsLoading(true);
        jobAgent.CreateJobActions.AnyRuns().then(res => {
            setAnyRun(res);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    useEffect(() =>{
        const update = () => {
            checkAnyRuns();
        }
        if(subscribeToJobUpdate) 
            activityStore.on(UpdateType.JobRunUpdate, update);

        return () => {
            activityStore.off(UpdateType.JobRunUpdate, update);
        }
    }, [])

    useEffect(() => {
        checkAnyRuns();
    }, []);
    return [anyRuns, isRunLoading];
}

export default useAnyRuns;