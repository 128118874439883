import { Card, Divider, Grid, InputLabel, makeStyles, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import clsx from 'clsx';
import { RootStoreContext } from "../../../app/stores/root.store";
import { useTranslation } from "react-i18next";
import { TestType } from "../../../app/models/jobTest.model";
import { DeviceType } from "../../../app/models/deviceType.enum";
import { AutofixIcon, CustomIcon, DatabaseIcon, InternetConnectionIcon, NetworkIcon, PowerIcon, PreScriptIcon, ServiceIcon, ThirdDeviceIcon, WebportalIcon } from "../../Icons";
import AuthenticationIcon from "../../Icons/AuthenticationIcon";

const DevicesPreviewComponent: React.FC = () => {

    const rootStore = useContext(RootStoreContext);
    const { createJobStore } = rootStore;
    const {selectedDevices,ICTestActive,DrResTestActive }= createJobStore;
    const [t] = useTranslation("CreateJobPreview");
    const [testagg, setTestAggr] = useState<{ [key: number]: number }>({})
    const [deviceAggregate, setDeviceAggregate] = useState<{ [key: number]: number }>({})

    useEffect(() => {
        const _deviceAggregate = { ...deviceAggregate };

        const _testagg = { ...testagg }
        createJobStore.selectedDevices.forEach(device => {
            if (_deviceAggregate[device.deviceType]) {
                _deviceAggregate[device.deviceType] += 1
            } else {
                _deviceAggregate[device.deviceType] = 1
            }
            device.tests.filter(t => t.selected).forEach(test => {
                if (_testagg[test.testType]) {
                        _testagg[test.testType] += 1;
                } else {
                    _testagg[test.testType] = 1;
                }
            });
        })
        setDeviceAggregate(_deviceAggregate);
        _testagg[TestType.network]= _testagg[TestType.network] - _testagg[TestType.thirdDeviceNetwork];
        setTestAggr(_testagg);
    }, [])

    const useStyles = makeStyles(({
        heading: {
            color: '#1f396d',
            fontWeight: 500,
        },
        pb10: {
            paddingBottom: 10
        },
        rightBorder: {
            position: 'relative',
            "&:after": {
                content: "' '",
                position: 'absolute',
                width: 1,
                height: '100%',
                right: 10,
                background: 'rgba(0, 0, 0, 0.12)'
            }
        },
        textHeading: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.71,
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#8092a9',
        },
        textItem: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 450,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.14,
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#4d5f75',
            marginLeft: '10px'
        },
        card: {
            padding: 16,
            width: '100%',
            boxSizing: 'border-box',
            borderRadius: '4px',
            border: 'solid 1px #eaeef4'
        },
        divider: {
            backgroundColor: '#eaeef4',
            width: '100%',
            margin: '0',
        },
    }))
    const classes = useStyles();

    return (
        <Card elevation={1} classes={{ root: clsx([classes.card]) }}>
            <Grid direction="column" style={{ height: '100%' }}>
                <Typography variant='h6' className={classes.heading}>{t('DevicesPreview')}</Typography>
                <Grid container item direction="column" style={{flexGrow: 1, paddingTop: 10, paddingBottom: 10}} >
                    <Grid item container direction="row" className={classes.pb10} >
                        <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{t('selectedDevices')}</Typography>
                        <InputLabel className={classes.textItem} style={{ color: '#2892d7' }}>{selectedDevices.length.toString()}</InputLabel>
                    </Grid>

                    <Grid item container direction="row" style={{flexGrow: 1}} spacing={1}>
                        <Grid item container xs={4} className={classes.rightBorder} direction="column" >
                            <Grid container direction="row" className={classes.pb10} >
                                <PowerIcon style={{color:testagg[TestType.power] ? '#8bc53f':'#c7cfdb'}}></PowerIcon>
                                <Typography className={classes.textItem}>{t('poweron')}</Typography>
                                <InputLabel className={classes.textItem}>{testagg[TestType.power] || '0'}</InputLabel>
                            </Grid>
                            <Grid container direction="row" className={classes.pb10}>
                                <NetworkIcon style={{color:testagg[TestType.network] ? '#8bc53f':'#c7cfdb'}}></NetworkIcon>
                                <Typography className={classes.textItem}>{t('network')}</Typography>
                                <InputLabel className={classes.textItem}>{testagg[TestType.network] || '0'}</InputLabel>
                            </Grid>
                            <Grid container direction="row" className={classes.pb10} >
                                <ServiceIcon style={{color:testagg[TestType.service] ? '#8bc53f':'#c7cfdb'}}></ServiceIcon>
                                <Typography className={classes.textItem}>{t('service')}</Typography>
                                <InputLabel className={classes.textItem}>{testagg[TestType.service] || '0'}</InputLabel>
                            </Grid>
                            <Grid container direction="row">
                                <ThirdDeviceIcon style={{color:testagg[TestType.thirdDeviceNetwork] ? '#8bc53f':'#c7cfdb'}}></ThirdDeviceIcon>
                                <Typography className={classes.textItem}>{t('thirdDevice')}</Typography>
                                <InputLabel className={classes.textItem}>{testagg[TestType.thirdDeviceNetwork] || '0'}</InputLabel>
                            </Grid>

                        </Grid>

                       
                        <Grid item container xs={4} className={classes.rightBorder} direction="column" >
                            <Grid container direction="row" className={classes.pb10}>
                                <CustomIcon style={{color:testagg[TestType.custom] ? '#8bc53f':'#c7cfdb'}}></CustomIcon>
                                <Typography className={classes.textItem}>{t('custom')}</Typography>
                                <InputLabel className={classes.textItem}>{testagg[TestType.custom] || '0'}</InputLabel>
                            </Grid>
                            <Grid container direction="row" className={classes.pb10}>
                                <DatabaseIcon style={{color:testagg[TestType.database] ? '#8bc53f':'#c7cfdb'}}></DatabaseIcon>
                                <Typography className={classes.textItem}>{t('database')}</Typography>
                                <InputLabel className={classes.textItem}>{testagg[TestType.database] || '0'}</InputLabel>
                            </Grid>
                            <Grid container direction="row" className={classes.pb10} >
                                <WebportalIcon style={{color:testagg[TestType.web] ? '#8bc53f':'#c7cfdb'}}></WebportalIcon>
                                <Typography className={classes.textItem}>{t('portal')}</Typography>
                                <InputLabel className={classes.textItem}>{testagg[TestType.web] || '0'}</InputLabel>
                            </Grid>
                            <Grid container direction="row">
                                <PreScriptIcon style={{color:testagg[TestType.PreScriptRes] ? '#8bc53f':'#c7cfdb'}}></PreScriptIcon>
                                <Typography className={classes.textItem}>{t('script')}</Typography>
                                <InputLabel className={classes.textItem}>{testagg[TestType.PreScriptRes] || '0'}</InputLabel>
                            </Grid>
                        </Grid>
                        
                       
                        <Grid item container xs={4} direction="column" justify="space-between" >
                            <Grid container direction="row" >
                                <AutofixIcon style={{color:testagg[TestType.autofix] ? '#8bc53f':'#c7cfdb'}}></AutofixIcon>
                                <Typography className={classes.textItem}>{t('autofix')}</Typography>
                                <InputLabel className={classes.textItem}>{testagg[TestType.autofix] || '0'}</InputLabel>
                            </Grid>
                            <Grid container direction="row">
                            <InternetConnectionIcon style={{color: ICTestActive ? '#8bc53f':'#c7cfdb'}}></InternetConnectionIcon>
                                <Typography className={classes.textItem}>{t('internet')}</Typography>
                            </Grid>
                            <Grid container direction="row" >
                                <img src={DrResTestActive ? '/assets/images/createjob/DevicesTestsIcons/resourcesGreen.svg':'/assets/images/createjob/DevicesTestsIcons/resourcesGrey.svg'}></img>
                                <Typography className={classes.textItem}>{t('resources')}</Typography>
                            </Grid>
                            <Grid container direction="row" >
                                <AuthenticationIcon style={{color:testagg[TestType.authentication] ? '#8bc53f':'#c7cfdb'}}></AuthenticationIcon>
                                <Typography className={classes.textItem}>{t('authentication')}</Typography>
                                <InputLabel className={classes.textItem}>{testagg[TestType.authentication] || '0'}</InputLabel>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Card>
    );
}

export default observer(DevicesPreviewComponent);