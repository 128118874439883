
import React from 'react';

import  { Doughnut } from 'react-chartjs-2';
import GaugePlugin from './chartjsPlgins/gauge.plugin';

type GaugeChartProps = {
    value: number;
    max: number;
    width?: number;
    height?: number;
    drawTicks?: boolean,
    cutoutPercentage?: number
}

//TODO refactor this after the demo
function convertToPercent(value: number, max: number) {
    if(!value || !max)
        return 0;
    if(value > max) {
        return 100;
    }
    return  Math.ceil((value*100)/max); 
}


const GaugeChart: React.FC<GaugeChartProps> = (props) => {
    const {width, height} = props;
    const percentValue = convertToPercent(props.value, props.max);
    const chartConfig = {
        labels: [
            'Successful',
            'Failed',
        ],
        datasets: [{
            data: [percentValue, 100-percentValue],
            backgroundColor: [
                "rgba(0,0,0,0)",
                'rgba(0,0,0,0)'
            ]
        }]
    };

    let dimensions = {}
    if(width || height) {
        dimensions = {width, height};
    }
   

    return <Doughnut data={chartConfig} 
        {...dimensions}
        options={
            { title: { display: false }, 
            elements: {
                arc: {
                    borderWidth: 0
                }
            },
         hover: {mode: null},
         events: [],
         responsive: true,
         maintainAspectRatio: false,
        legend: { display: false }, cutoutPercentage: props.cutoutPercentage || 82, layout: { padding: 0 },
         tooltips: { enabled: false },
            circumference: Math.PI * 1.30,
            rotation: Math.PI * 0.85
        }} 
        plugins={[GaugePlugin(props.drawTicks)]} />
}

export default GaugeChart;