import { Asset } from '../models/asset.model';
import { AssetType } from '../models/createjob/createJob.model';
import agentbase from './agentBase';

const requests = agentbase.requests;
const axios = agentbase.axios;
const AssetAgent = {
    ListJobAssets: (credsID: number = 0): Promise<any> => axios.get<{assets: any}>(`/assets/ListJobAssets?CredsId=${credsID}`).then(res => res.data),
    //getEDRCs: (filterEDR: boolean = false): Promise<Array<Asset>> => axios.get<{assets: Array<Asset>}>('/assets/ListEDRC').then(res => res.data.assets),

    AddNewAsset :(instance:any): Promise<any> => axios.post<{assets: any}>('/assets', {...instance}),
    AddNewDC :(instance:any): Promise<any> => axios.post<{assets: any}>('/assets/CreateDC', {...instance}),
    UpdateDC :(instance:any): Promise<any> => axios.put<{assets: any}>('/assets/UpdateDC', {...instance}),
    TestAsset: (asset:any): Promise<any> => axios.get(`/assets/TestAsset?assetName=${asset.assetName}&assetType=${asset.assetType}`,),
    ValidateEDRC: (asset:any):Promise<any> => axios.post('/assets/ValidateEDRC', {...asset}),
    ValidateEDR: (asset:any):Promise<any> => axios.post('/assets/ValidateEDR', {...asset}),
    TestDomainController: (dc:{domainName: string, username?: string, domainControllerName: string, domainCredsId?: number, password?: string}): Promise<any> => axios.get(`/assets/TestDomainController?domainName=${dc.domainName}&username=${dc.username}&domainControllerName=${dc.domainControllerName}&domainCredsId=${dc.domainCredsId}&password=${dc.password}`,).then(res => res.data),
    CheckAsset: (id: number): Promise<any> => axios.get(`/assets/CheckAssetAvailable?id=${id}`),
    UpdateEDRC: (instance:any):Promise<any> => axios.put<{assets: any}>('assets/UpdateEDRC', {...instance}),
    DeleteAsset: (id:number):Promise<any> => axios.delete(`assets/DeleteAsset/${id}`),
    DeleteDC: (id:number):Promise<any> => axios.delete(`assets/DeleteDC/${id}`),
    AnyAsset:(assetType:AssetType):Promise<any> =>axios.get(`/assets/AnyAsset?assetType=${assetType}`)
}

export default AssetAgent;