import React, { useReducer } from 'react';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import JobResultsApi from '../api/jobResult.api';
import { useTranslation } from 'react-i18next';
import { RTATimeStamps } from '../models/reportdata.model';
import moment from 'moment';

 type LogViewProps = {
    jobResultId: number;
    jobName: string;
    open: boolean;
    rTATimeStamps: RTATimeStamps;
    onClose: () => void;
}

const useStyles = makeStyles({
    root: {
        minWidth: '25vw',
        minHeight: '60vh'
    },
    close_button: {
      width: 60,
      height: 32,
      background: '#2892d7 !important',
      color: 'white !important',
      borderRadius: '28px !important',
      fontSize:14,
      fontWeight:500
    },
    wrapper: {
        counterReset: 'line',
        color: 'black'
    },
    line: {
        '&::before' :{
            content: '""counter(line)""',
            counterIncrement: "line"
        }
    },
    TimeBreakdown: {
      fontSize: '16px',
      fontWeight: 500,
      textAlign: 'left',
      color: '#1f396d'
    },
    card: {
      padding: 16,
      // width: '100%',
      height: '100%',
      marginTop:'-14px',
      marginLeft:'-15px'
    },
    button:{
        backgroundColor: 'transparent',
        backgroundRepeat:'no-repeat',
        cursor:'pointer',
        overflow: 'hidden',
        outline:'none',
    },
    advanced:{
        width: '14px',
        height: '14px',
        objectFit: 'contain'
    },
    textHeading:{
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.14',
        letterSpacing: 'normal',
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        marginLeft: '20px',
        color: '#4d5f75'
    },
    verticalLine:{
        width: '19px',
        height: '0',
        marginLeft: '3px',
        border: '0.5px solid #2892d7'
    },
    divWithoutCircle:{
        height: '7px',
        width: 'auto',
        position: 'relative',
        alignContent: 'center',
        alignItems: 'center',
        "&:before": {
            width: '0',
            height: '100%',
            position: 'absolute',
            content: "' '",
            display: 'inline-block',
            borderLeft: '1px dashed #2892d7',
            left:'3px',
            bottom:'30px'
        },
        "&:after": {
            width: '10px',
            height: '10px',
            display: 'block',
            content:  "' '",
            left: '-1px',
            top: 'calc(50% - 10px)',
            position: 'absolute'
        }
    },
    div :{
        height: '40px',
        width: '40px',
        position: 'relative',
        alignContent: 'center',
        alignItems: 'center',
        "&:before": {
            width: '0',
            height: '100%',
            position: 'absolute',
            content: "' '",
            display: 'inline-block',
            borderLeft: '1px dashed #2892d7',
            left:'16px',
            bottom:'30px'
        },
        "&:after": {
            width: '8px',
            height: '8px',
            display: 'block',
            content:  "' '",
            background: '#2892d7',
            borderRadius: '10px',
            left: '13px',
            top: 'calc(50% - 5px)',
            position: 'absolute'
        },
        "&:first-child:before ": {
            bottom: '-16px'
          },
        "&:last:after": {
            top: 'calc(100% - 5px)'
          }
      },
    divLast :{
        height: '40px',
        width: '40px',
        position: 'relative',
        alignContent: 'center',
        alignItems: 'center',
        "&:before": {
            width: '0',
            height: '40px',
            position: 'absolute',
            content: "' '",
            display: 'inline-block',
            left:'3px',
            bottom:'6px'
        },
        "&:after": {
            width: '8px',
            height: '8px',
            display: 'block',
            content:  "' '",
            background: '#2892d7',
            borderRadius: '10px',
            left: '13px',
            top: 'calc(50% - 5px)',
            position: 'absolute'
        },
        "&:first-child:before ": {
            bottom: '-16px'
          }
      },
      errorText:{
        color:'red',
        marginTop:'10px',
        fontSize: '0.75rem',
        textAlign: 'left',
        fontFamily: "Roboto",
        fontWeight: 400,
        lineHeight: 1.66,
        letterSpacing: '0.03333em'
      },
      helpIcon:
      {
        color: '#1976d2',
        fontSize: "large",
        marginTop:'8px'
      },
      ScriptButton:{
            borderRadius: '4px',
            border: 'solid 1px #c7cfdb',
            backgroundColor: '#fafafc',
            minWidth: '40px',
            minHeight: '35px',
            marginRight: '8px',
      },
      timeHeader:
      {
        fontWeight:500,
        color:'#4d5f75',
        width:'33%', 
        marginTop:10
      },
      timeData:
      {
        fontWeight:'normal',
        color:'#4d5f75',
        width:'33%', 
        marginTop:10
      }
})

const RTADialog: React.FC<LogViewProps> =  ({jobResultId, open, onClose, jobName, rTATimeStamps}) => {
function reducer (state, action: {type: string, value?: any}): {isLoading: boolean, dataLoaded: boolean, data: string, error: string} {
    switch(action.type) {
        case 'loadingData': return {...state, isLoading: true, error: null};
        case 'dataFetched': return {...state, isLoading: false, dataLoaded: true, data: action.value};
        case 'fetchError': return {...state, isLoading: false, dataLoaded: true, error: action.value}
    }
    return state;
}
const [state, dispatch] = useReducer(reducer, {
    dataLoaded: false,
    data: null,
    isLoading: false,
    error: null
} )

const classes = useStyles();
const [t] = useTranslation('reportDialog');
const descriptionElementRef = React.useRef<HTMLElement>(null);
  
React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
      dispatch({type: 'dataFetched', value: "need to fill data"});
      /*dispatch({type: 'loadingData'});
      JobResultsApi.getRTA(jobResultId).then((data) => {
        let RTAData;
        dispatch({type: 'dataFetched', value: RTAData});
      }).catch(err => {
        dispatch({type: 'fetchError', value: t("fetchErrorRTA")});
      });
      */
    }
  }, [open, jobResultId]);
  const loadRTATime = () => {
      let days = 0;
      let hours = 0;
      let minutes = 0;
      let seconds = 0;
      if(rTATimeStamps)
      {
          if(rTATimeStamps.failoverTimeStamp.includes('D'))
          {
              let split = rTATimeStamps.failoverTimeStamp.split(':')
              days += Number(split[0]);
              hours += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.failoverTimeStamp.includes('Hr'))
          {
              let split = rTATimeStamps.failoverTimeStamp.split(':');
              hours += Number(split[0]);
              minutes += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.failoverTimeStamp.includes('Min'))
          {
              let split = rTATimeStamps.failoverTimeStamp.split(':')
              minutes += Number(split[0]);
              seconds += Number(split[1].split(' ')[0]);
          }
          else
          {
              seconds += Number(rTATimeStamps.failoverTimeStamp.split(' ')[0])
          }

          if(rTATimeStamps.prepareTestTimeStamp.includes('D'))
          {
              let split = rTATimeStamps.prepareTestTimeStamp.split(':')
              days += Number(split[0]);
              hours += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.prepareTestTimeStamp.includes('Hr'))
          {
              let split = rTATimeStamps.prepareTestTimeStamp.split(':');
              hours += Number(split[0]);
              minutes += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.prepareTestTimeStamp.includes('Min'))
          {
              let split = rTATimeStamps.prepareTestTimeStamp.split(':')
              minutes += Number(split[0]);
              seconds += Number(split[1].split(' ')[0]);
          }
          else
          {
              seconds += Number(rTATimeStamps.prepareTestTimeStamp.split(' ')[0])
          }

          if(rTATimeStamps.testPowerOnTimeStamp.includes('D'))
          {
              let split = rTATimeStamps.testPowerOnTimeStamp.split(':')
              days += Number(split[0]);
              hours += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.testPowerOnTimeStamp.includes('Hr'))
          {
              let split = rTATimeStamps.testPowerOnTimeStamp.split(':');
              hours += Number(split[0]);
              minutes += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.testPowerOnTimeStamp.includes('Min'))
          {
              let split = rTATimeStamps.testPowerOnTimeStamp.split(':')
              minutes += Number(split[0]);
              seconds += Number(split[1].split(' ')[0]);
          }
          else
          {
              seconds += Number(rTATimeStamps.testPowerOnTimeStamp.split(' ')[0])
          }

          if(rTATimeStamps.testTimeStamp.includes('D'))
          {
              let split = rTATimeStamps.testTimeStamp.split(':')
              days += Number(split[0]);
              hours += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.testTimeStamp.includes('Hr'))
          {
              let split = rTATimeStamps.testTimeStamp.split(':');
              hours += Number(split[0]);
              minutes += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.testTimeStamp.includes('Min'))
          {
              let split = rTATimeStamps.testTimeStamp.split(':')
              minutes += Number(split[0]);
              seconds += Number(split[1].split(' ')[0]);
          }
          else
          {
              seconds += Number(rTATimeStamps.testTimeStamp.split(' ')[0])
          }
          
          let seconds2 = (days * 86400) + (hours * 3600) + (minutes * 60) + seconds;
          if(days > 0)
              return `${days} D, ${moment().startOf('day').seconds(seconds2).format('H [Hr] m [Min] s [Sec]')}`;  
          if(hours > 0)
              return moment().startOf('day').seconds(seconds2).format('H [Hr] m [Min] s [Sec]');
          if(minutes > 0)
              return moment().startOf('day').seconds(seconds2).format('m [Min] s [Sec]');
          return moment().startOf('day').seconds(seconds2).format('s [Sec]');
      }
      else
      {
        return '0 minutes'
      }
      
  }
  const loadTotalTime = () => {
      let days = 0;
      let hours = 0;
      let minutes = 0;
      let seconds = 0;
      if(rTATimeStamps)
      {
          if(rTATimeStamps.preparingWorkflowTimeStamp.includes('D'))
          {
              let split = rTATimeStamps.preparingWorkflowTimeStamp.split(':')
              days += Number(split[0]);
              hours += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.preparingWorkflowTimeStamp.includes('Hr'))
          {
              let split = rTATimeStamps.preparingWorkflowTimeStamp.split(':');
              hours += Number(split[0]);
              minutes += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.preparingWorkflowTimeStamp.includes('Min'))
          {
              let split = rTATimeStamps.preparingWorkflowTimeStamp.split(':')
              minutes += Number(split[0]);
              seconds += Number(split[1].split(' ')[0]);
          }
          else
          {
              seconds += Number(rTATimeStamps.preparingWorkflowTimeStamp.split(' ')[0])
          }
          
          if(rTATimeStamps.failoverTimeStamp.includes('D'))
          {
              let split = rTATimeStamps.failoverTimeStamp.split(':')
              days += Number(split[0]);
              hours += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.failoverTimeStamp.includes('Hr'))
          {
              let split = rTATimeStamps.failoverTimeStamp.split(':');
              hours += Number(split[0]);
              minutes += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.failoverTimeStamp.includes('Min'))
          {
              let split = rTATimeStamps.failoverTimeStamp.split(':')
              minutes += Number(split[0]);
              seconds += Number(split[1].split(' ')[0]);
          }
          else
          {
              seconds += Number(rTATimeStamps.failoverTimeStamp.split(' ')[0])
          }

          if(rTATimeStamps.prepareTestTimeStamp.includes('D'))
          {
              let split = rTATimeStamps.prepareTestTimeStamp.split(':')
              days += Number(split[0]);
              hours += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.prepareTestTimeStamp.includes('Hr'))
          {
              let split = rTATimeStamps.prepareTestTimeStamp.split(':');
              hours += Number(split[0]);
              minutes += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.prepareTestTimeStamp.includes('Min'))
          {
              let split = rTATimeStamps.prepareTestTimeStamp.split(':')
              minutes += Number(split[0]);
              seconds += Number(split[1].split(' ')[0]);
          }
          else
          {
              seconds += Number(rTATimeStamps.prepareTestTimeStamp.split(' ')[0])
          }

          if(rTATimeStamps.testPowerOnTimeStamp.includes('D'))
          {
              let split = rTATimeStamps.testPowerOnTimeStamp.split(':')
              days += Number(split[0]);
              hours += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.testPowerOnTimeStamp.includes('Hr'))
          {
              let split = rTATimeStamps.testPowerOnTimeStamp.split(':');
              hours += Number(split[0]);
              minutes += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.testPowerOnTimeStamp.includes('Min'))
          {
              let split = rTATimeStamps.testPowerOnTimeStamp.split(':')
              minutes += Number(split[0]);
              seconds += Number(split[1].split(' ')[0]);
          }
          else
          {
              seconds += Number(rTATimeStamps.testPowerOnTimeStamp.split(' ')[0])
          }

          if(rTATimeStamps.testTimeStamp.includes('D'))
          {
              let split = rTATimeStamps.testTimeStamp.split(':')
              days += Number(split[0]);
              hours += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.testTimeStamp.includes('Hr'))
          {
              let split = rTATimeStamps.testTimeStamp.split(':');
              hours += Number(split[0]);
              minutes += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.testTimeStamp.includes('Min'))
          {
              let split = rTATimeStamps.testTimeStamp.split(':')
              minutes += Number(split[0]);
              seconds += Number(split[1].split(' ')[0]);
          }
          else
          {
              seconds += Number(rTATimeStamps.testTimeStamp.split(' ')[0])
          }

          if(rTATimeStamps.prepareCleanUpTimeStamp.includes('D'))
          {
              let split = rTATimeStamps.prepareCleanUpTimeStamp.split(':')
              days += Number(split[0]);
              hours += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.prepareCleanUpTimeStamp.includes('Hr'))
          {
              let split = rTATimeStamps.prepareCleanUpTimeStamp.split(':');
              hours += Number(split[0]);
              minutes += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.prepareCleanUpTimeStamp.includes('Min'))
          {
              let split = rTATimeStamps.prepareCleanUpTimeStamp.split(':')
              minutes += Number(split[0]);
              seconds += Number(split[1].split(' ')[0]);
          }
          else
          {
              seconds += Number(rTATimeStamps.prepareCleanUpTimeStamp.split(' ')[0])
          }

          if(rTATimeStamps.cleanUpTimeStamp.includes('D'))
          {
              let split = rTATimeStamps.cleanUpTimeStamp.split(':')
              days += Number(split[0]);
              hours += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.cleanUpTimeStamp.includes('Hr'))
          {
              let split = rTATimeStamps.cleanUpTimeStamp.split(':');
              hours += Number(split[0]);
              minutes += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.cleanUpTimeStamp.includes('Min'))
          {
              let split = rTATimeStamps.cleanUpTimeStamp.split(':')
              minutes += Number(split[0]);
              seconds += Number(split[1].split(' ')[0]);
          }
          else
          {
              seconds += Number(rTATimeStamps.cleanUpTimeStamp.split(' ')[0])
          }

          if(rTATimeStamps.finishAndReportTimeStamp.includes('D'))
          {
              let split = rTATimeStamps.finishAndReportTimeStamp.split(':')
              days += Number(split[0]);
              hours += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.finishAndReportTimeStamp.includes('Hr'))
          {
              let split = rTATimeStamps.finishAndReportTimeStamp.split(':');
              hours += Number(split[0]);
              minutes += Number(split[1].split(' ')[0]);
          }
          else if(rTATimeStamps.finishAndReportTimeStamp.includes('Min'))
          {
              let split = rTATimeStamps.finishAndReportTimeStamp.split(':')
              minutes += Number(split[0]);
              seconds += Number(split[1].split(' ')[0]);
          }
          else
          {
              seconds += Number(rTATimeStamps.finishAndReportTimeStamp.split(' ')[0])
          }
          let seconds2 = (days * 86400) + (hours * 3600) + (minutes * 60) + seconds;
          if(days > 0)
              return `${days} D, ${moment().startOf('day').seconds(seconds2).format('H [Hr] m [Min] s [Sec]')}`;  
          if(hours > 0)
              return moment().startOf('day').seconds(seconds2).format('H [Hr] m [Min] s [Sec]');
          if(minutes > 0)
              return moment().startOf('day').seconds(seconds2).format('m [Min] s [Sec]');
          return moment().startOf('day').seconds(seconds2).format('s [Sec]');
      }
      else
      {
        return '0 minutes'
      }
  }
  
  const handleClose = () => {
      onClose && onClose();
  }

  return (
    <div>
      <Dialog
        open={open}
        classes={{paper: classes.root}}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" style={{padding:'15px 24px 0 24px'}}>
          <Typography variant="body1" style={{fontWeight:500,fontSize:'16px',color:'#1f396d'}}>{t('rta')}</Typography>
          <Typography variant="body1" style={{fontWeight:500,fontSize:'14px',color:'#8092a9'}}>{jobName} {t('rtasub')}</Typography>
        </DialogTitle>
        
        <DialogContent dividers={true} style={{border:'none', padding:'0 24px 0 24px'}}>
          <DialogContentText
            style={{whiteSpace: 'pre-wrap'}}
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
          {state.isLoading && <LinearProgress />}
          <div className={classes.wrapper}>
          {rTATimeStamps != null ?
            <div>
            <Grid item style={{display:'flex',height:40}}>
              <div style={{width:40}}></div>
              <Typography className={classes.timeHeader}></Typography>
              <Typography className={classes.timeHeader}>RTA</Typography>
              <Typography className={classes.timeHeader}>Total Time</Typography>
            </Grid>
            <Grid item style={{backgroundColor:'#f4f6f9',display:'flex',height:40, borderRadius:4}}>
              <div className={classes.div}>
              </div>
              <div style={{width:'100%',display:'flex'}}>
                <Typography className={classes.timeHeader}>{t('workflowTime')}</Typography>
                <Typography className={classes.timeData}></Typography>
                <Typography className={classes.timeData}>{rTATimeStamps.preparingWorkflowTimeStamp}</Typography>
                
              </div>
            </Grid>
            <Grid item style={{display:'flex',height:40}}>
              <div className={classes.div}>
              </div>
              <Typography className={classes.timeHeader}>{t('failoverTime')} </Typography>
              <Typography className={classes.timeData}>{rTATimeStamps.failoverTimeStamp}</Typography>
              <Typography className={classes.timeData}>{rTATimeStamps.failoverTimeStamp}</Typography>
            </Grid>
            <Grid item style={{backgroundColor:'#f4f6f9',display:'flex',height:40, borderRadius:4}}>
              <div className={classes.div}>
              </div>
              <Typography className={classes.timeHeader}>{t('testTime')} </Typography>
              <Typography className={classes.timeData}>{rTATimeStamps.prepareTestTimeStamp}</Typography>
              <Typography className={classes.timeData}>{rTATimeStamps.prepareTestTimeStamp}</Typography>
              
            </Grid>
            <Grid item style={{display:'flex',height:40}}>
            <div className={classes.div}>
              </div>
              <Typography className={classes.timeHeader}>{t('poweronTime')} </Typography>
              <Typography className={classes.timeData}>{rTATimeStamps.testPowerOnTimeStamp}</Typography>
              <Typography className={classes.timeData}>{rTATimeStamps.testPowerOnTimeStamp}</Typography>
            </Grid>
            <Grid item style={{backgroundColor:'#f4f6f9',display:'flex',height:40, borderRadius:4}}>
            <div className={classes.div}>
              </div>
              <Typography className={classes.timeHeader}>{t('serversTime')} </Typography>
              <Typography className={classes.timeData}>{rTATimeStamps.testTimeStamp}</Typography>
              <Typography className={classes.timeData}>{rTATimeStamps.testTimeStamp}</Typography>
              
            </Grid>
            <Grid item style={{display:'flex',height:40}}>
            <div className={classes.div}>
              </div>
              <Typography className={classes.timeHeader}>{t('prepareCleanupTime')}</Typography>
              <Typography className={classes.timeData}></Typography>
              <Typography className={classes.timeData}>{rTATimeStamps.prepareCleanUpTimeStamp}</Typography>
              
            </Grid>
            <Grid item style={{backgroundColor:'#f4f6f9',display:'flex',height:40, borderRadius:4}}>
            <div className={classes.div}>
              </div>
              <Typography className={classes.timeHeader}>{t('cleanupTime')}</Typography>
              <Typography className={classes.timeData}></Typography>
              <Typography className={classes.timeData}>{rTATimeStamps.cleanUpTimeStamp}</Typography>
              
            </Grid>
            <Grid item style={{display:'flex',height:40}}>
            <div className={classes.divLast}>
              </div>
              <Typography className={classes.timeHeader}>{t('reportTime')}</Typography>
              <Typography className={classes.timeData}></Typography>
              <Typography className={classes.timeData}>{rTATimeStamps.finishAndReportTimeStamp}</Typography>
            </Grid>
            <Grid item style={{display:'flex',height:40,boxShadow: '0 8px 12px 0 rgba(31, 57, 109, 0.05)',backgroundColor:'rgba(40,146,215,0.15)', borderRadius:4}}>
              <div style={{width:40}}></div>
              <Typography className={classes.timeHeader}>{t('total')}</Typography>
              <Typography className={classes.timeData} style={{color:'#2892d7', fontWeight:500}}>{loadRTATime()}</Typography>
              <Typography className={classes.timeData} style={{color:'#2892d7', fontWeight:500}}>{loadTotalTime()}</Typography>
            </Grid>
            </div>
      : <Typography variant="body1" style={{fontWeight:500}}>{t('noRTAdataAvailable')}</Typography>}
          
          </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{padding:16}}>
            <Button onClick={handleClose} color="primary" style={{textTransform:'none'}} className={classes.close_button}>
                {t('close')}
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RTADialog;
