import { ca } from "date-fns/locale";
import { DatabaseType, DevicePlatform, InfrastructureType, JobType, MachineType } from "./jobProperties.model";

export enum DataMover {
    
    SRM = 1,
    ZertoVmWare = 2,
    Recoverpoint = 7,
    ASR = 4,
    Netapp = 5,
    VeeamVmware = 9,
    AWSDRS = 17,
    CohesityRunbook = 16,
    ZertoAzure = 3,
    Carbonite = 6,
    VmwareVc = 8,
    VeeamSQL = 10,
    CustomVmware = 11,
    Cohesity = 12,
    IaaS = 13,
    PaaS = 14,
    Rubrik = 15,
    VmwareAWS = 18,
    ASRVmwareAzure = 19,
    ZertoVmwareAzure = 20,
    AIX = 21,
    NetappNAS = 22,
    MSSqlServer = 23,
    MSSqlServerAzure = 24,
    Oracle = 25,
    SapHana = 26,
    PureStorage = 27,
    Commvault =28,
    CustomAWS = 29,
    CustomAzure = 30,
    CustomGCP = 31
}

export enum VeeamSolution {
    Replica =0,
    Backup = 1,
    SQL = 2
}

export enum JobRunType {
    All = 0,
    FailoverOnly = 1,
    CleanUpOnly = 2,
    TestOnly = 3,
    FailoverAndCleanUpOnly = 4,
    Live = 5
}

export function getDataMover(datamoverId:number) {
    switch(datamoverId)
    {
        case 1:
            return DataMover.SRM;
        case 2:
            return DataMover.ZertoVmWare;
        case 3:
            return DataMover.ZertoAzure;
        case 4:
            return DataMover.ASR;
        case 5:
            return DataMover.Netapp;
        case 6:
            return DataMover.Carbonite;
        case 7:
            return DataMover.Recoverpoint;
        case 8:
            return DataMover.VmwareVc;
        case 9:
            return DataMover.VeeamVmware;
        case 10:
            return DataMover.VeeamSQL;
        case 11:
            return DataMover.CustomVmware
        case 12:
            return DataMover.Cohesity;
        case 13:
            return DataMover.IaaS;
        case 14:
            return DataMover.PaaS;
        case 15:
            return DataMover.Rubrik;
        case 16:
            return DataMover.CohesityRunbook;
        case 17:
            return DataMover.AWSDRS;
        case 18:
            return DataMover.VmwareAWS;
        case 19: 
            return DataMover.ASRVmwareAzure
        case 20:
            return DataMover.ZertoVmwareAzure
        case 21:
            return DataMover.AIX
        case 22:
            return DataMover.NetappNAS
        case 23:
            return DataMover.MSSqlServer
        case 24:
            return DataMover.MSSqlServerAzure
        case 25: 
            return DataMover.Oracle
        case 26:
            return DataMover.SapHana
        case 27:
            return DataMover.PureStorage
    }
}


export const  JOB_TYPE_NAME = {
    [JobType.LiveTest]: "Live & Test",
    [JobType.Test]: "Test Only",
    [JobType.Live]: "Live Only",
}

export const DATABASE_TYPE_NAME = {
    [DatabaseType.SQL]: "SQL",
    [DatabaseType.Oracle]: "Oracle",
}

export const OS_TYPE_NAME = {
    [DevicePlatform.Windows]: "Windows",
    [DevicePlatform.UNIX]: "Linux",
    [DevicePlatform.Other]: "Other"
}

export const  MACHINE_TYPE_NAME = {
    [MachineType.Windows]: "Windows",
    [MachineType.Linux]: "Linux",
}


export const  DATA_MOVER_NAME = {
    [DataMover.SRM]: "SRM",
    [DataMover.ZertoVmWare]: "Zerto Vmware",
    [DataMover.ZertoAzure]: "Zerto Azure",
    [DataMover.ASR]: "ASR",
    [DataMover.Netapp]:"NetApp",
    [DataMover.Carbonite]:"Carbonite",
    [DataMover.Recoverpoint]:"RecoverPoint",
    [DataMover.VmwareVc]:"VCenter",
    [DataMover.VeeamVmware]:"Veeam VMware",
    [DataMover.VeeamSQL]:"Veeam SQL",
    [DataMover.CustomVmware]: "Custom",
    [DataMover.CustomAWS]: "Custom",
    [DataMover.CustomAzure]: "Custom",
    [DataMover.CustomGCP]: "Custom",
    [DataMover.Cohesity]:"Cohesity",
    [DataMover.IaaS]:"Azure IaaS",
    [DataMover.PaaS]:"Azure PaaS",
    [DataMover.Rubrik]:"Rubrik",
    [DataMover.ASRVmwareAzure]:"ASR Vmware>Azure",
    [DataMover.ZertoVmwareAzure]:"Zerto Vmware>Azure",
    [DataMover.CohesityRunbook]:"Cohesity"
}

export const PlatformOptions: Array<{infra: InfrastructureType, datamover: DataMover, name: string}> = [
    /*{infra: InfrastructureType.Azure, datamover: DataMover.IaaS, name: "Azure Iaas"},
    {infra: InfrastructureType.Azure, datamover: DataMover.PaaS, name: "Azure Paas"},
    {infra: InfrastructureType.Azure, datamover: DataMover.ZertoAzure, name: "Azure Zerto"},*/
    {infra: InfrastructureType.Azure, datamover: DataMover.ASR, name: "Azure ASR"},
    /*{infra: InfrastructureType.Vmware, datamover: DataMover.Rubrik, name: "VMware Rubrik"},
    {infra: InfrastructureType.VmwareAzure, datamover: DataMover.ZertoVmwareAzure, name: "VMware Azure Zerto"}*/
    {infra: InfrastructureType.VmwareAzure, datamover: DataMover.ASRVmwareAzure, name: "VMware Azure ASR"},
    {infra: InfrastructureType.VMware, datamover: DataMover.SRM, name: "VMware SRM"},
    {infra: InfrastructureType.VMware, datamover: DataMover.ZertoVmWare, name: "VMware Zerto"},
    /*{infra: InfrastructureType.Vmware, datamover: DataMover.Netapp, name: "VMware NetApp"},
    {infra: InfrastructureType.Vmware, datamover: DataMover.Carbonite, name: "VMware Carbonite"},
    {infra: InfrastructureType.Vmware, datamover: DataMover.Recoverpoint, name: "VMware RecoverPoint"},*/
    {infra: InfrastructureType.VMware, datamover: DataMover.VeeamVmware, name: "VMware Veeam"},
    /*{infra: InfrastructureType.Vmware, datamover: DataMover.VmwareVc, name: "VMware VCenter"},*/
    {infra: InfrastructureType.VMware, datamover: DataMover.VeeamSQL, name: "VMware VeeamSQL"},
    /*{infra: InfrastructureType.Vmware, datamover: DataMover.Custom, name: "VMware Custom"}, */
    // {infra: InfrastructureType.Vmware, datamover: DataMover.Cohesity, name: "VMware Cohesity"},
    {infra: InfrastructureType.VMware, datamover: DataMover.CohesityRunbook, name: "Cohesity_RB"}
]

export const DATA_MOVER_CONFIGURATION_NAME = {
    [DataMover.SRM]:"SRM",
    [DataMover.ZertoVmWare]: "Zerto",
    [DataMover.ZertoAzure]: "Zerto",
    [DataMover.ASR]:"ASR",
    [DataMover.Netapp]:"NetApp",
    [DataMover.Carbonite]:"Carbonite",
    [DataMover.Recoverpoint]:"RecoverPoint",
    [DataMover.VmwareVc]:"VCenter",
    [DataMover.VeeamVmware]:"Veeam",
    [DataMover.VeeamSQL]:"VeeamSQL",
    [DataMover.Cohesity]:"Cohesity",
    [DataMover.CustomVmware]:"Custom",
    [DataMover.CustomAWS]:"Custom",
    [DataMover.CustomAzure]:"Custom",
    [DataMover.CustomGCP]:"Custom",
    [DataMover.IaaS]:"IaaS",
    [DataMover.PaaS]:"PaaS",
    [DataMover.Rubrik]:"Rubrik",
    [DataMover.ASRVmwareAzure]:"ASR",
    [DataMover.ZertoVmwareAzure]:"Zerto",
    [DataMover.CohesityRunbook]:"Cohesity_RB",
    [DataMover.AWSDRS]:"DRS",
    [DataMover.VmwareAWS]:"Vmware",
    [DataMover.SapHana]:"SAP HANA",
    [DataMover.NetappNAS]:"NetApp NAS",
    [DataMover.MSSqlServer]:"MS SQL Server",
    [DataMover.MSSqlServerAzure]:"MS SQL Server",
    [DataMover.Oracle]:"Oracle",
    [DataMover.PureStorage]:"Pure Storage",
    [DataMover.AIX]:"IBM AIX",
    [DataMover.Commvault]:"Commvault"
}

export const  INFRA_NAME = {
    [InfrastructureType.VMware]:"VMware",
    [InfrastructureType.Azure]:"Azure",
    [InfrastructureType.HyperV]:"Hyper-V",
    [InfrastructureType.VmwareAzure]:"VMware > Azure",
    [InfrastructureType.AWS]:"AWS",
    [InfrastructureType.Physical]:"Physical",
    [InfrastructureType.GCP]:"GCP"
}

export type WorkflowStepNames = 'preTestTask' | 'failover' | 'test' | 'cleanup' ;


