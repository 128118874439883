import { observer } from 'mobx-react-lite';
import { Grid, Typography } from '@material-ui/core';
import readiness_image from "../../../assets/user/towers.png";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import login_bg_image from "../../../assets/user/login_background.svg";
import React from 'react';
import { useTranslation } from 'react-i18next';

const LoginImageComponent: React.FC = () => {
    
    const [t] = useTranslation("user");
    const useStyles = makeStyles(() =>
    createStyles({
        visual_box: {
        width: "1328px",
        height: "936px",
        backgroundImage: `url(${login_bg_image})`,
        backgroundColor: '#dfeff9'
        },
        readiness_image: {
        width: "1012.8px",
        height: "760px",
        objectFit: "contain",
        },
    }));
  
    const classes = useStyles();

    return (
            <Grid container direction="row" className={classes.visual_box} item xs={8}>
               <Grid item container direction="column" style={{marginLeft: "5em"}} justify="center" xs={4}>
                   <Typography classes={{ root: "hundredpercent_text"}}>
                       {t("perfectscore")}
                   </Typography>
                   <Typography classes={{ root: "readiness_text"}}>
                       {t("recoveryready")}
                   </Typography>
               </Grid>  
               <Grid item xs={5}>
                 <img className={classes.readiness_image} 
                   src={readiness_image}
                 />  
               </Grid>
              </Grid> 
    );
}

export default (observer(LoginImageComponent));