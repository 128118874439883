import React , {useState, useEffect} from 'react'
import { Grid, Typography, Button } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import { JobDeviceResult } from '../../app/models/jobDevice/jobDeviceResult.model';
import JobResultsApi from '../../app/api/jobResult.api';
import { JobDeviceResultAdapter } from '../../app/common/util/jobDevice.adapter';
import { useTranslation } from 'react-i18next';
import ServerCard from './serverCard.component';
import { Skeleton } from '@material-ui/lab';
import { PlayPauseButton } from '../../app/common/playPauseButton.component';
import useTestGroups from '../../app/common/util/useTestGroups';




const DeviceListComponent: React.FC<{jobResultId: number, jobId: number, defaultAnimationPlayState?: boolean,type:string}> = ({jobResultId,jobId, defaultAnimationPlayState,type}) => {
    const fetchNextPage=() => {
        JobResultsApi.devices(jobResultId,pagination.currentPage+1, 4).then(res => {
            setDevices(res.records.map(d => JobDeviceResultAdapter.getObject(d,null,null)));
            setPagination({
                currentPage: res.page,
                totalPages: res.totalPages
            });
       })
    };
    const fetchPrevPage=() => {
        JobResultsApi.devices(jobResultId,pagination.currentPage-1, 4).then(res => {
            setDevices(res.records.map(d => JobDeviceResultAdapter.getObject(d,null,null)));
            setPagination({
                currentPage: res.page,
                totalPages: res.totalPages
            });
       })
    };
    
   
    const [isAnimationPlaying, setAnimationPlay] = useState(defaultAnimationPlayState);
    const  [devices, setDevices] = useState<Array<JobDeviceResult>>(null);
    const [pagination, setPagination] = useState({ currentPage: 0, totalPages: 0});
    const [t] = useTranslation("report");

    useEffect(() => {
        JobResultsApi.devices(jobResultId,1, 4).then(res => {
             setDevices(res.records.map(d => JobDeviceResultAdapter.getObject(d,null,null)));
             setPagination({
                 currentPage: res.page,
                 totalPages: res.totalPages
             });
        })
    }, [jobResultId])

    const [testGroups, testGroupNames] = useTestGroups(jobId);

    return  <Grid item style={{ padding: "8px 15px" }} data-test="component-deviceList">
    <Grid container>
        <Typography variant="h6" align="left" style={{flexGrow: 1}}> {t("serverBootPreview")}</Typography>
        <PlayPauseButton isPlaying={isAnimationPlaying} onChange={setAnimationPlay}/>
    <span>
    {pagination.currentPage > 1 && <Button data-test="button-previous" onClick={fetchPrevPage}>
                <ArrowLeftIcon/>
            </Button>}
            {pagination.currentPage < pagination.totalPages && <Button data-test="button-next" onClick={fetchNextPage}>
                <ArrowRightIcon/>
            </Button>}
    </span>
    </Grid>
    <Grid container justify="flex-start" style={{ marginTop: "7px" }} >
        {!devices && <React.Fragment>
            <Skeleton  variant="rect" style={{width: "23%", marginRight: "10px" , height: "250px"}} /> 
            <Skeleton  variant="rect" style={{width: "23%", marginRight: "10px" , height: "250px"}} />
            <Skeleton  variant="rect" style={{width: "23%", marginRight: "10px" , height: "250px"}} />
            <Skeleton  variant="rect" style={{width: "23%", marginRight: "10px" , height: "250px"}} />
            </React.Fragment>}
        {devices && devices.map((d, index) =>  <div key={d.name+index} style={{width: "23%", marginRight: "10px"}}> <ServerCard testGroupNames={testGroupNames} playAnimation={isAnimationPlaying} device={d} deviceOS={d.devicePlatform}></ServerCard> </div>)}
    </Grid>
</Grid>
}

export default DeviceListComponent;