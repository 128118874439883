import { PrePostScriptsDTO } from "./createjob/createJob.model";
import { ScheduleStaus } from "./jobProperties.model";
import { WorkFlowStatus } from "./workflowStep.model";

export enum LastJobRunStatus {
    atRisk = 0,
    recoverable =1,
    resillient =2
}
export interface LastJobRun {
    id: number,
    jobId: number,
    createDate:Date;
    jobName: string,
    dataMover: string
    jobStatus: string,
    serversInStatusOK: number,
    serversInStatusBAD: number,
    totalServers:string,
    runDate: string,
    nextRun: string,
    rta: number,
    rto: number,
    score: number,
    edrServer: string,
    rpa: number,
    rpo: number,
    rtaChange: number;
    rtoChange: number;
    scoreChange: number;
    workflowStatus: WorkFlowStatus;
    isRunning: boolean;
    iopsActual: number;
    iopsCapacity: number;
    ramActual: number;
    ramCapacity: number;
    cpuActual: number;
    cpuCapacity: number;
    status : LastJobRunStatus;
    isDeleted : boolean;
    isDeletedPermanently : boolean;
    jobNameFromJobs: string;
    attachmentSize: number;
    PrePostScripts:Array<PrePostScriptsDTO>;
    scheduleStaus: ScheduleStaus;
}