import { observable, computed, runInAction, action } from "mobx";
import { JobResultQuery } from "../models/jobResultQuery.model";
import JobResultsApi from "../api/jobResult.api";
import { LastJobRun, LastJobRunStatus } from "../models/lastjobRun.model";
import { JobFilter } from "../common/util/job.filter";
import { RootStore } from "./root.store";
import { JobSortComparator } from "../common/util/SortComparator";
import { UpdateType } from "./activityStore";


export class JobResultStore {
    @observable _jobResults: Array<LastJobRun> = [];
    @observable query: JobResultQuery = { category: "all" };
    @observable slectedJobId: number = null;
    @observable private _noJobsAvailable = false;
    @observable loadedJobResults = false;
    @observable private _jobsFetched = false;
    @observable sortInfo: { sortBy: string, sortDesc: boolean } = { sortBy: "lastRun", sortDesc: true };
    @observable dialogOpen = false;
    @observable dialogOpenConfirm = false;
    @observable dialogOpenLicense = false;
    @observable newJobName = '';
    @observable loadingRename = false;
    @observable loadingConfirm = false;
    @observable private _isJobsLoading =false;

    constructor(rootStore: RootStore) {
        rootStore.activityStore.on(UpdateType.ForceRefresh, () => {
            this.fetch();
        })
        rootStore.activityStore.on(UpdateType.JobRunUpdate, () => {
            this.fetch();
        })
        rootStore.activityStore.on(UpdateType.JobAdded, () => {
            this.fetch();
        })
    }

    @computed get isJobsLoading(): boolean {
        return this._isJobsLoading;
    }
    @computed get noJobsAvailable(): boolean {
        return this._noJobsAvailable;
    }
    @computed get jobsFetched(): boolean {
        return this._jobsFetched
    }
    @computed get selectedJob(): LastJobRun {
        return this._jobResults.find(j => j.jobId === this.slectedJobId);
    }
    @computed
    get jobResults(): Array<LastJobRun> {
        const results = JobFilter.filterJobResults(this.query, this._jobResults);
        const sorted = results.slice().sort(JobSortComparator[this.sortInfo.sortBy]);
        return this.sortInfo.sortDesc ? [...sorted.reverse().sort(JobSortComparator.runningStatus)] : [...sorted.sort(JobSortComparator.runningStatus)];
    }

    @action
    setloadedJobResults(loaded: boolean) {
        this.loadedJobResults = loaded;
    }

    @action
    setQueryParams(query: JobResultQuery) {
        this.query = { ...query };
    }

    @action
    selectJobSummaryResult(id: number) {
        this.slectedJobId = id;
    }
    @action setNewJobName = (newName: string) => {
        this.newJobName = newName;
    }

    @computed get jobCountByCategories(): { all: number, resilient: number, recoverable: number, atRisk: number } {
        let jobCount = {
            all: this._jobResults.length,
            resilient: 0,
            recoverable: 0,
            atRisk: 0,
            running: 0
        };
        this._jobResults.forEach(jobResult => {

            if (jobResult.status === LastJobRunStatus.atRisk) {
                jobCount.atRisk += 1;
            }
            if (jobResult.status === LastJobRunStatus.recoverable) {
                jobCount.recoverable += 1;
            }
            if (jobResult.status === LastJobRunStatus.resillient) {
                jobCount.resilient += 1;
            }
            if (jobResult.isRunning) {
                jobCount.running += 1;
            }

        })
        return jobCount;
    }

    @computed
    get dataMovers(): Array<{ label: string, checked: boolean, count: number }> {
        let _dataMovers: Array<string> = [];
        this._jobResults.map(j => j.dataMover).filter(dataMover => dataMover).forEach(d => {
            if (_dataMovers.indexOf(d) < 0) {
                _dataMovers.push(d);
            }
        });
        return _dataMovers.sort().map(d => ({
            label: d, checked: this.query.dataMover && this.query.dataMover.indexOf(d) >= 0 ? true : false,
            count: this._jobResults.filter(j => j.dataMover === d).length

        }));
    }
    @action
    fetch() {
        if(this._isJobsLoading)
            return;
        this._isJobsLoading = true;
        JobResultsApi.getlastJobRuns().then(res => {
            runInAction(() => {
                this._jobResults = res.records.map(this.fillStatusProperty);
                this._jobsFetched = true;
                this.loadedJobResults =true;
                if (res.records.length === 0)
                    this._noJobsAvailable = true;
            });
        }).finally(() => {
            runInAction(() => {
                this._isJobsLoading=false;
            })
        })

    }

    fillStatusProperty(jobRun: LastJobRun): LastJobRun {
        if (jobRun.status !== null)
            return jobRun; // if it already has status property do nothing

        if (jobRun.score >= 90) {
            jobRun.status = LastJobRunStatus.resillient;
        } else if (jobRun.score >= 80) {
            jobRun.status = LastJobRunStatus.recoverable;
        } else {
            jobRun.status = LastJobRunStatus.atRisk;
        };

        return jobRun;
    }
    @action
    sortBy(field: string, sortDesc: boolean) {
        this.sortInfo = { sortBy: field, sortDesc }
    }

    @action updateJobRunState(jobId: number, running: boolean) {
        this._jobResults.filter(j => j.jobId === jobId).forEach(job => {
            job.isRunning = running;
        })
    }

}