import Chart from 'chart.js';

const DoughnutBackgroundFillPlugin: Chart.PluginServiceRegistrationOptions = {
    beforeDraw: function (chartInstance: Chart, easing, options) {
        var x = chartInstance.ctx.canvas.clientWidth / 2,
            y = chartInstance.ctx.canvas.clientHeight / 2,
            ctx = chartInstance.ctx;
        ctx.beginPath();
        const outerRadius = chartInstance['outerRadius'];
        const radiusLength = chartInstance['radiusLength'];
        ctx.arc(x, y, outerRadius - (radiusLength / 2), 0, 2 * Math.PI);
        ctx.lineWidth = radiusLength;
        ctx.strokeStyle = chartInstance.options['radiusBackground.color'] || '#e6e7ec'; //bebfc5
        ctx.stroke();
    }
}

export default DoughnutBackgroundFillPlugin;