import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/root.store';
import { Card, CircularProgress, Divider, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import HelpIcon from '@material-ui/icons/Help';
import clsx from 'clsx';
import BaseMandatoryComponent from './BaseMandatory.component';
import { DataMover } from '../../../app/models/BaseSettings.enum';
import { runInAction } from 'mobx';
import jobAgent from '../../../app/api/jobAgent';
import InfrastructureComponent from './Infrastructure.component';
import InfraCreateComponent from './InfraCreate.component';
import DataMoverComponent from './DataMover.component';
import DataMoverCreateComponent from './DataMoverCreate.component';
import WorkflowPrimaryCreateComponent from './WorkflowPrimaryCreate.component';
import WorkflowSecondaryCreateComponent from './WorkflowSecondaryCreate.component';
import WorkflowPrimaryComponent from './WorkflowPrimary.component';
import WorkflowSecondaryComponent from './WorkflowSecondary.component';
import { CredentialUserType } from '../../../app/models/createjob/createJob.model';
import EDRComponent from '../NetworkInfo/Vlan/EDR.component';
import VeeamHostsComponent from './VeeamHosts.component';
import { UpdateType } from '../../../app/stores/activityStore';


const BaseSettingMainComponent: React.FC = (props) => {
  const rootStore = useContext(RootStoreContext);
  const {jobSettingsStore,displayStore} = rootStore;
  const isPaaS = rootStore.createJobStore.baseSettings.dataMover== DataMover.PaaS;
  const isZertoSrm = rootStore.createJobStore.baseSettings.dataMover== DataMover.ZertoVmWare || rootStore.createJobStore.baseSettings.dataMover== DataMover.SRM;
  const [t] = useTranslation("createJobBase");
  const useStyles = makeStyles((theme) => ({
    SecondHeading: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '100%',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.14',
      letterSpacing: 'normal',
      textAlign: 'left',
      whiteSpace: 'nowrap',
      flexGrow: 1
    },
    card: {
      padding: 25,
      paddingBottom: isPaaS ? 112:221,
      minHeight: '100%'
    },
    helpIcon:
    {
      color: '#1976d2',
      fontSize: "large"
    },
    divider: {
      backgroundColor: '#eaeef4',
      margin: '0',
      marginTop: '8px',
      marginLeft: '26px'
    }
  }));
  const classes = useStyles();
  const {baseSettings:{dataMover, infrastructureType}} = useContext(RootStoreContext).createJobStore

  const {createJobStore, activityStore} = useContext(RootStoreContext)
  const [anyDataMover, setAnyDataMover] = useState(true);
  const [anyInfra, setAnyInfra] = useState(true);
  const [anyPrimaryUser, setAnyPrimaryUser] = useState(true);
  const [anySecondaryUser, setAnySecondaryUser] = useState(true);
  const [finishedLoading, setFinishLoading] = useState(true);
  const [validation, setValidation] = useState({EDRValid: true, DCValid: true, EDRCValid: true});

  useEffect(() => {
    runInAction(async () => {
      await jobAgent.JobActions.CheckAnyUser(CredentialUserType.GeneralUser).then(res => {
          setAnyPrimaryUser(res);
        });
      await jobAgent.JobActions.CheckAnyUser(CredentialUserType.LinuxUser).then(res => {
          setAnySecondaryUser(res);
        });   
        
    });
  }, [jobSettingsStore.linuxCredentails,jobSettingsStore.accessCredentials])
  useEffect(() => {
  const refresh = () => {
     jobAgent.JobActions.CheckAnyUser(CredentialUserType.GeneralUser).then(res => {
      setAnyPrimaryUser(res);
    });
   jobAgent.JobActions.CheckAnyUser(CredentialUserType.LinuxUser).then(res => {
      setAnySecondaryUser(res);
    }); 
  }
  activityStore.on(UpdateType.FirstRunDefaultLoaded, refresh);

  return () => activityStore.off(UpdateType.FirstRunDefaultLoaded, refresh)
 

  })
  useEffect(() => {
    runInAction(async () => {
      setFinishLoading(false);
      await  jobAgent.JobActions.CheckAnyDataMover(createJobStore.baseSettings.dataMover).then(res => {
          runInAction(() => {
            setAnyDataMover(res);
            if (res!=jobSettingsStore.anyDM) jobSettingsStore.anyDM = res;
          });
        });
      await  jobAgent.JobActions.CheckAnyInfrastructure(createJobStore.baseSettings.infrastructureType).then(res => {
          runInAction(() => {
            setAnyInfra(res);
            if (res!=jobSettingsStore.anyInfra) jobSettingsStore.anyInfra = res;
          })
        });
    }).finally(() => {
      setFinishLoading(true);
    });
  }, [dataMover, infrastructureType, jobSettingsStore.anyDM, jobSettingsStore.anyInfra])

  useEffect(() => {
    displayStore.updateNextStepValid({edrValid: validation.EDRValid});
  }, [validation])

  const loadCircularProgress =()=>{
    return <div style={{width:'100%',display:'flex'}}>
    <CircularProgress id="anyData" size={18} style={{color: '#2892D7', marginLeft: '10px',marginTop: '-3px'}}/>
    </div>
  }

  const notCustom=()=>
  {
    if(dataMover != DataMover.CustomVmware && dataMover != DataMover.CustomAWS && dataMover != DataMover.CustomAzure && dataMover != DataMover.CustomGCP)
      return true;
    return false;
  }

  return (
    <Grid container direction="column" style={{ paddingRight: 16, paddingLeft: 16 }}>
      <Card elevation={1} classes={{ root: clsx([classes.card]) }}>

        <BaseMandatoryComponent/>

        <Grid container item direction="row" style={{paddingTop:'1%'}}>
          <div style={{ display: 'flex', flexDirection: 'row', padding: '10px 0 10px 0' }}>
            <Typography className={classes.SecondHeading} style={{ color: '#1f396d' }}>{t('platform')}</Typography>
            <Tooltip title={createJobStore.baseSettings.dataMover == DataMover.ASR ? t("ASRInfo"):(createJobStore.baseSettings.dataMover == DataMover.AWSDRS ? t("AWSInfo"): t("platformInfo"))} arrow>
              <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
            </Tooltip>
            <Divider className={classes.divider} style={{width:'623%'}}/>
          </div>
        </Grid>
        {!finishedLoading ? loadCircularProgress() : 
        <span>
          {dataMover != DataMover.VeeamSQL ?
             <React.Fragment>
                {anyInfra ? <InfrastructureComponent></InfrastructureComponent>:<InfraCreateComponent></InfraCreateComponent>}
             </React.Fragment>
           :null}
           {dataMover != DataMover.AWSDRS  && notCustom() ?
           <React.Fragment>
              {anyDataMover ? <DataMoverComponent></DataMoverComponent>:<DataMoverCreateComponent></DataMoverCreateComponent>}
            </React.Fragment>:null}
        </span>}
        { anyDataMover && (dataMover == DataMover.VeeamVmware || dataMover == DataMover.Netapp || dataMover == DataMover.CohesityRunbook) ? <VeeamHostsComponent></VeeamHostsComponent> : null}
        <Grid container item direction="row">
          <div style={{ display: 'flex', flexDirection: 'row',marginTop:isPaaS ?'3%':(isZertoSrm ? '16px':'2%')}}>
            <Typography className={classes.SecondHeading} style={{ color: '#1f396d' }}>{isPaaS ?t('EnsuredrServer'):t('workflow')}</Typography>
            <Tooltip title={t("workflowInfo")} arrow>
              <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
            </Tooltip>
            <Divider className={classes.divider} style={{width:isPaaS?'400%': '330%'}} />
          </div>
        </Grid>
        
        {anyPrimaryUser ? <WorkflowPrimaryComponent></WorkflowPrimaryComponent> : <WorkflowPrimaryCreateComponent></WorkflowPrimaryCreateComponent> }
        {dataMover !== DataMover.VeeamSQL && (anySecondaryUser ? <WorkflowSecondaryComponent></WorkflowSecondaryComponent> : <WorkflowSecondaryCreateComponent></WorkflowSecondaryCreateComponent>) }
        {
          dataMover == DataMover.VeeamSQL && <div style={{width:'100%', marginTop: 16}}>
            <EDRComponent onValidationChange={(EDRValid) => setValidation({...validation, EDRValid})}></EDRComponent>
            </div>
            
        }
      </Card>
    </Grid>
  );
};

export default observer(BaseSettingMainComponent); 