import { Card, Divider, Grid, InputLabel, makeStyles, Typography, useTheme } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import clsx from 'clsx';
import { RootStoreContext } from "../../../app/stores/root.store";
import { useTranslation } from "react-i18next";
import { DataMover, DATA_MOVER_NAME, INFRA_NAME } from "../../../app/models/BaseSettings.enum";
import { InfrastructureType } from "../../../app/models/jobProperties.model";
import JobSettingsStore from "../../../app/stores/jobSettings.store";

export const loadDataMoverIcon = (dataMover: DataMover) => {
    switch (dataMover) {
        case DataMover.SRM:
            {
                return '/assets/images/createjob/DataMoverIcons/srm.svg';
            }
        case DataMover.ZertoVmWare:
        case DataMover.ZertoAzure:
            {
                return '/assets/images/createjob/DataMoverIcons/zerto.svg';
            }
        case DataMover.PaaS:
        case DataMover.IaaS:
            {
                return '/assets/images/createjob/DataMoverIcons/azure.svg';
            }
        case DataMover.ASR:
            {
                return '/assets/images/createjob/DataMoverIcons/asr.svg';
            }
        case DataMover.Netapp:
            {
                return '/assets/images/createjob/DataMoverIcons/netapp.svg';
            }
        case DataMover.Carbonite:
            {
                return '/assets/images/createjob/DataMoverIcons/carbonite.svg';
            }
        case DataMover.Recoverpoint:
            {
                return '/assets/images/createjob/DataMoverIcons/recoverpoint.svg';
            }
        case DataMover.VmwareVc:
            {
                return '/assets/images/createjob/DataMoverIcons/vcenter.svg';
            }
        case DataMover.VeeamVmware:
        case DataMover.VeeamSQL:
            {
                return '/assets/images/createjob/DataMoverIcons/veeam.svg';
            }
        case DataMover.CustomAWS:
        case DataMover.CustomVmware:
        case DataMover.CustomAzure:
        case DataMover.CustomGCP:
            {
                return '/assets/images/createjob/DataMoverIcons/custom.svg';
            }
        case DataMover.Cohesity:
            {
                return '/assets/images/createjob/DataMoverIcons/cohecity.svg';
            }
    }
}

const BaseSettingPreviewComponent: React.FC = () => {
    
    const rootStore = useContext(RootStoreContext);
    const { createJobStore, jobSettingsStore } = rootStore;
    const { dataMover, infrastructureType, workflowSetting, jobName } = createJobStore.baseSettings;
    const { EDRServer } = rootStore.createJobStore.networkSetting;
    const { failoverScriptSetting, cleanupScriptSetting, TestScriptSetting } = rootStore.createJobStore.baseSettings.scriptsSettings;
    const isPaaS = rootStore.createJobStore.baseSettings.dataMover == DataMover.PaaS;
    const [t] = useTranslation("CreateJobPreview");
    const theme = useTheme();

    const useStyles = makeStyles(({
        pb10: {
            paddingBottom: 10
        },
        heading: {
            color: '#1f396d',
            fontWeight: 500,
        },
        card: {
            padding: 16,
            width: '100%',
            boxSizing: 'border-box',
            borderRadius: '4px',
            border: 'solid 1px #eaeef4'
        },
        textItem: {
            fontFamily: 'Roboto',
            fontSize: isPaaS ? '13px':'14px',
            fontWeight: 450,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.14,
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#4d5f75',
            marginLeft: '10px'
        },
        miniHeader: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.14,
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#1f396d'
        },
        divider: {
            backgroundColor: '#eaeef4',
            width: '100%',
            margin: '0',
        },
        div: {
            // width: '420px',
            // height: '130px',
            //  margin: '13px 24px 0 1px',
            // padding: '11px 206px 21px 15px',
            padding: 16,
            borderRadius: '4px',
            border: 'solid 1px #eaeef4',
            backgroundColor: '#eaeef4'
        }
    }))

    const classes = useStyles();

    const loadInfrastructureIcon = () => {
        switch (infrastructureType) {
            case InfrastructureType.Azure:
                {
                    return '/assets/images/createjob/DataMoverIcons/azure.svg';
                }
            case InfrastructureType.HyperV:
                {
                    return '/assets/images/createjob/DataMoverIcons/hyperv.svg';
                }
            case InfrastructureType.VMware:
                {
                    return '/assets/images/createjob/DataMoverIcons/vmware.svg';
                }
        }
    }
    const loadTestWorkflowStateIcon = (stepName: string) => {
        let valueFromStore;
        switch (stepName) {
            case 'Collection':
                {
                    valueFromStore = workflowSetting.preTest;
                    break;
                }
            case 'Fail Over':
                {
                    valueFromStore = workflowSetting.failover;
                    break;
                }
            case 'Test':
                {
                    valueFromStore = workflowSetting.serverTest;
                    break;
                }
            case 'Clean Up':
                {
                    valueFromStore = workflowSetting.cleanup;
                    break;
                }
        }
        if (valueFromStore)
            return '/assets/images/createjob/checked.svg';
        else
            return '/assets/images/createjob/unchecked.svg';
    }

    const loadScript = (name: string) => {
        let valueFromStore;
        switch (name) {
            case 'prefailover':
                {
                    valueFromStore = failoverScriptSetting.prePath;
                    break;
                }
            case 'postfailover':
                {
                    valueFromStore = failoverScriptSetting.postPath;
                    break;
                }
            case 'pretest':
                {
                    valueFromStore = TestScriptSetting.prePath;
                    break;
                }
            case 'posttest':
                {
                    valueFromStore = TestScriptSetting.postPath;
                    break;
                }
            case 'precleanup':
                {
                    valueFromStore = cleanupScriptSetting.prePath;
                    break;
                }
            case 'postcleanup':
                {
                    valueFromStore = cleanupScriptSetting.postPath;
                    break;
                }
        }
        if (valueFromStore != null && valueFromStore != '')
            return true;
        else
            return false;
    }

    const loadPrePostPhase = (prepostName: string) => {
        return (
            <React.Fragment>
                <Grid container direction="row" style={{ display: 'flex', marginTop: '10px', marginLeft: '30px' }}>
                    <Typography className={classes.textItem} style={{ color: (prepostName.toLowerCase().includes("pre") ? '#2892d7' : '#f08700'), fontSize: '12px' }}>{prepostName}</Typography>
                </Grid>
            </React.Fragment>
        );
    }

    const countNumberScripts = () => {
        let count = 0;
        if (failoverScriptSetting.prePath != '')
            count++;
        if (failoverScriptSetting.postPath != '')
            count++;
        if (TestScriptSetting.prePath != '')
            count++;
        if (TestScriptSetting.postPath != '')
            count++;
        if (cleanupScriptSetting.prePath != '')
            count++;
        if (cleanupScriptSetting.postPath != '')
            count++;
        return count;
    }

    const loadWorkflowPhase = (workflowName: string) => {
        return (
            <React.Fragment>
                <Grid container direction="row" style={{ display: 'flex', marginTop: '10px', marginLeft: '16px' }}>
                    <img src={loadTestWorkflowStateIcon(workflowName)}></img>
                    <Typography className={classes.textItem}>{workflowName}</Typography>
                </Grid>
            </React.Fragment>
        );
    }

    const loadSection = () => {
        if (countNumberScripts() <= 2) {
            return (
                <Grid container  direction="column" justify="space-between" style={{borderLeft: '1px solid rgba(0,0,0,0.12)'}} >
                    {loadWorkflowPhase(t('collection'))}
                    {loadScript('prefailover') ? loadPrePostPhase(t('prefailover')) : null}
                    {loadWorkflowPhase(t('failover'))}
                    {loadScript('postfailover') ? loadPrePostPhase(t('postfailover')) : null}
                    {loadScript('pretest') ? loadPrePostPhase(t('pretest')) : null}
                    {loadWorkflowPhase(t('test'))}
                    {loadScript('posttest') ? loadPrePostPhase(t('posttest')) : null}
                    {loadScript('precleanup') ? loadPrePostPhase(t('precleanup')) : null}
                    {loadWorkflowPhase(t('cleanup'))}
                    {loadScript('postcleanup') ? loadPrePostPhase(t('postcleanup')) : null}
                </Grid>
            );
        }
        else {
            return (
                <Grid  container direction="row" style={{borderLeft: '1px solid rgba(0,0,0,0.12)'}}>
                    <Grid item container xs={6} direction="column" justify="space-between" >
                        {loadWorkflowPhase(t('collection'))}
                        {loadScript('prefailover') ? loadPrePostPhase(t('prefailover')) : null}
                        {loadWorkflowPhase(t('failover'))}
                        {loadScript('postfailover') ? loadPrePostPhase(t('postfailover')) : null}
                        {loadScript('pretest') ? loadPrePostPhase(t('pretest')) : null}
                        {loadWorkflowPhase(t('test'))}
                        {loadScript('posttest') ? loadPrePostPhase(t('posttest')) : null}
                    </Grid>

                    <Grid item container xs={6} direction="column"  >
                        {loadScript('precleanup') ? loadPrePostPhase(t('precleanup')) : null}
                        {loadWorkflowPhase(t('cleanup'))}
                        {loadScript('postcleanup') ? loadPrePostPhase(t('postcleanup')) : null}
                    </Grid>
                </Grid>

            );
        }
    }

    const loadInfraContent = () => {
        return (
            <React.Fragment>
                <Grid item container direction="row" className={classes.pb10} style={{ whiteSpace: 'nowrap', flexWrap: 'nowrap' }} >
                    <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{!isPaaS ? t('sourceServer') : t('ensureServer')}</Typography>
                    <InputLabel className={classes.textItem}>{!isPaaS ? jobSettingsStore.selectedInfra.infrastructureSource : EDRServer.assetName}</InputLabel>
                </Grid>
                {!isPaaS ?
                    <Grid item container direction="row"   >
                        <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{t('targetServer')}</Typography>
                        <InputLabel className={classes.textItem}>{jobSettingsStore.selectedInfra.infrastructureTarget}</InputLabel>
                    </Grid> : null}
            </React.Fragment>
        )
    }

    const loadDataMoverContent = () => {
        return (
            <React.Fragment>
                <Grid item container direction="row" className={classes.pb10} style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }} >
                    <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{!isPaaS ? t('sourceServer') : t('TenantID')}</Typography>
                    <InputLabel className={classes.textItem}>{!isPaaS ? jobSettingsStore.selectedDataMover.dataMoverSource : jobSettingsStore.selectedInfra.infrastructureSource}</InputLabel>
                </Grid>
                <Grid item container direction="row" style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }} >
                    <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{!isPaaS ? t('targetServer') : t('clientId')}</Typography>
                    <InputLabel className={classes.textItem}>{!isPaaS ? jobSettingsStore.selectedDataMover.dataMoverSource : jobSettingsStore.selectedInfra.infrastructureSource}</InputLabel>
                </Grid>
            </React.Fragment>
        );
    }

    return (
        <Card elevation={1} classes={{ root: clsx([classes.card]) }}>
            <Typography variant='h6' className={classes.heading}>{t('BasePreview')}</Typography>
            <Grid container direction="row">
                <Grid item xs={8}>
                    <Grid container direction="row" style={{ paddingTop: 10, paddingBottom: 10 }} >
                        <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{t('jobName')}</Typography>
                        <InputLabel className={classes.textItem}>{jobName}</InputLabel>
                    </Grid>
                    <Grid container direction="row" spacing={1} >
                        <Grid item container xs={6} direction="column" >
                            <div className={classes.div}>
                                <Grid item container direction="row" className={classes.pb10} style={{ marginTop: '8px' }} >
                                    <img src={'/assets/images/createjob/datamoverIcon.svg'}></img>
                                    <Typography className={clsx([classes.miniHeader])} style={{ marginLeft: '8px' }}>{t('datemover')}</Typography>
                                </Grid>
                                <Grid item container direction="row" className={classes.pb10}>
                                    <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{t('type')}</Typography>
                                    <img src={loadDataMoverIcon(dataMover)} style={{ marginLeft: '8px' }}></img>
                                    <InputLabel className={classes.textItem}>{DATA_MOVER_NAME[dataMover]}</InputLabel>
                                </Grid>
                                {loadDataMoverContent()}

                            </div>
                        </Grid>
                        <Grid item container xs={6} direction="column">
                            <div className={classes.div} style={{marginLeft:isPaaS?'8%':null,paddingBottom:isPaaS? '9%':null}}>
                                <Grid item container direction="row" className={classes.pb10} style={{ marginTop: '8px' }} >
                                    <img src={'/assets/images/createjob/infrastructureIcon.svg'}></img>
                                    <Typography className={classes.miniHeader} style={{ marginLeft: '8px' }}>{t('infrastucture')}</Typography>
                                </Grid>
                                <Grid item container direction="row" className={classes.pb10}>
                                    <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{t('type')}</Typography>
                                    <img src={loadInfrastructureIcon()} style={{ marginLeft: '8px' }}></img>
                                    <InputLabel className={classes.textItem}>{INFRA_NAME[infrastructureType]}</InputLabel>
                                </Grid>
                                {loadInfraContent()}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} container style={{paddingLeft: isPaaS ?'9%':16}}>

                    {/* <Divider orientation="vertical" flexItem style={{ marginLeft: 16 }} /> */}
                    {loadSection()}

                </Grid>
            </Grid>

        </Card>
    );
}

export default observer(BaseSettingPreviewComponent);