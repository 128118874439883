import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, makeStyles, Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { history } from '../common/util/history';
import DraftsMenuComponent from '../../features/navigation/appbar/draftsMenu.component';
import DraftsAgent from '../api/draftsAgent';
import { PowerIcon } from '../../features/Icons';
import GuideIcon from '../../features/Icons/guideIcon';
import StartIcon from '../../features/Icons/startIcon';
import DemoIcon from '../../features/Icons/demoIcon';
import DraftIcon from '../../features/Icons/draftIcon';
import draftsMenuComponent from '../../features/navigation/appbar/draftsMenu.component';
import jobAgent from '../api/jobAgent';
import { DataMover } from '../models/BaseSettings.enum';

const useClass = makeStyles({
    root: {
        width: 552,
        height: 695,
        display: 'flex',
        backgroundColor: "#ffffff",
        flexDirection: 'column',
        position: 'relative',
        boxShadow: '0px 1px 2px 0px grey'
    },
    header: {
        width: 552,
        height: 120,
        backgroundImage: 'url("/assets/images/blueHeader.svg")'
    },
    img: {
        width: 150,
        height: 170,
        position: 'absolute',
        top: 10,
        margin: 'auto',
        left: 0,
        right: 0,
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        fontFamily: "Roboto",
        fontSize: "28px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#1f396d",
    },
    info1: {
        margin: '7px 40px 16px',
        fontFamily: "Roboto",
        fontSize: "20px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.4",
        letterSpacing: "normal",
        textAlign: "center",
        color: '#077187'
    },
    info2:{
        fontFamily: "Roboto",
        fontSize: "16px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "center",
        color: "#4d5f75"
    },
    container : {
        height: '100vh',
        width: '100%',
        zIndex: 20,
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'

    },
    button :{
        width: 140,
        height:48,
        borderRadius: '26px',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.1)',
        backgroundColor: "#8bc53f"
    },
    textDesc:{
        fontFamily: "Roboto",
        fontSize: "15px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#8092a9"
    }
});

export interface EmptyProps{
    open: boolean;
    onClose: () => void;
}

const EmptyDasboard: React.FC =  () => {
    
    const [t] = useTranslation("dashboard");
    const classes = useClass();
    const [anyDrafts, setAnyDrafts] = useState(false);
    useEffect(() => {
        DraftsAgent.Any().then(res => {
            setAnyDrafts(res.data);
        }).catch(err => {
            setAnyDrafts(false);
        })
    }, [])

    const handleStartupGuide =()=>{
        let link="";
        jobAgent.JobActions.getDefaulTDataMover().then(res=>{
            switch(res)
            {
                case DataMover.ZertoVmWare:
                    {
                        link="https://support.ensuredr.com/298104-EnsureDR-Quick-Startup-Guide-for-Zerto";
                        break;
                    }
                case DataMover.SRM:
                    {
                        link="https://support.ensuredr.com/458907-EnsureDR-Quick-Startup-Guide-for-VMware-SRM";
                        break;
                    }
                case DataMover.VeeamVmware:
                case DataMover.VeeamSQL:
                    {
                        link="https://support.ensuredr.com/636321-EnsureDR-Quick-Startup-Guide-for-Veeam-Backup-and-Replication";
                        break;
                    }
                case DataMover.CohesityRunbook:
                    {
                        link="https://support.ensuredr.com/667604-EnsureDR-Quick-Startup-Guide-for-Cohesity";
                        break;
                    }
                case DataMover.AWSDRS:
                    {
                        link ="https://support.ensuredr.com/288677-EnsureDR-Quick-Startup-Guide-for-AWS-Elastic-Disaster-Recovery";
                        break;
                    }
                case DataMover.ASR:
                    {
                        link ="https://support.ensuredr.com/008952-EnsureDR-Quick-Startup-Guide-for-Microsoft-Azure-Site-Recovery";
                        break;
                    }
                case DataMover.Netapp:
                    {
                        link = "https://support.ensuredr.com/950468-EnsureDR-Quick-Startup-Guide-for-NetApp";
                        break;
                    }
                case DataMover.Recoverpoint :
                    {
                        link="https://support.ensuredr.com/782780-EnsureDR-Quick-Startup-Guide-for-RecoverPoint-for-Virtual-Machines";
                        break;
                    }
            }
            window.open(link);
        });
    }
    
    return(
    <div>
    <div className={clsx([classes.container, "blur"])}>
    </div>
    <div className={classes.container}>
    <div className={classes.root}>
            <div className={classes.header}> 
            </div>
                <img className={classes.img} src="/assets/images/EnsureDR.svg">
            </img>
            
            <div className={classes.content}>
                <span className={classes.title}>{t("emptyDashHeader")}</span>
                <div style={{paddingTop:'1%',whiteSpace:'nowrap'}}>
                    <Typography className={classes.info1} style={{fontWeight:"bold"}}>{t("onlineDemo")}</Typography>
                    <Typography className={classes.info2} style={{margin: "32px 149px 16px 79px",fontWeight:"bold"}}>{t("demoExperience")}</Typography>
                </div>
                <div>
                    
                    <div style={{textAlign:'center',alignItems:'center',justifyContent:'center'}}>
                    <div style={{textAlign:'center',display:'flex',alignItems:'center',justifyContent:'start',cursor:'pointer'}}>
                        <img style={{marginTop:'4%'}} src={"/assets/images/demoIcons/demoIcon1.svg"}></img>
                        <Typography className={classes.textDesc} style={{whiteSpace:'nowrap',marginLeft: '2%',marginTop:'3%'}}>{t("icon1Desc")}</Typography>
                    </div>
                    <div style={{textAlign:'center',display:'flex',alignItems:'center',justifyContent:'start',cursor:'pointer'}}>
                        <img style={{marginTop:'4%'}} src={"/assets/images/demoIcons/demoIcon2.svg"}></img>
                        <Typography className={classes.textDesc} style={{whiteSpace:'nowrap',marginLeft: '2%',marginTop:'3%'}}>{t("icon2Desc")}</Typography>
                    </div>
                    <div style={{textAlign:'center',display:'flex',alignItems:'center',justifyContent:'start',cursor:'pointer',width:'100%'}}>
                        <img style={{marginTop:'4%'}} src={"/assets/images/demoIcons/demoIcon3.svg"}></img>
                        <Typography className={classes.textDesc} style={{whiteSpace:'nowrap',marginLeft: '2%',marginTop:'3%'}}>{t("icon3Desc")}</Typography>
                    </div>
                    <div style={{textAlign:'center',display:'flex',alignItems:'center',justifyContent:'start',cursor:'pointer'}}>
                        <img style={{marginTop:'4%'}} src={"/assets/images/demoIcons/demoIcon4.svg"}></img>
                        <Typography className={classes.textDesc} style={{whiteSpace:'nowrap',marginLeft: '2%',marginTop:'3%'}}>{t("icon4Desc")}</Typography>
                    </div>
                    </div>
                  
                    <Button className={classes.button} 
                        variant="contained" 
                        color="primary" 
                        onClick={()=>history.push('/jobs')}
                        style={{textTransform:'none',marginLeft:'31%',marginTop:'10%',fontSize:'15px',fontFamily:'Roboto'}}>
                            <div style={{display:'flex'}}>
                            <img src={'/assets/images/demoIcons/powerIcon.svg'}></img>{t("startNow")}
                            </div>
                    </Button>

                    <Typography className={classes.textDesc} style={{whiteSpace:'nowrap',fontSize: '14px',fontWeight: 'normal',lineHeight: 1.43,marginTop:'10%',textAlign: 'center',color:'#8092a9'}}>{t("note")}</Typography>
                </div>
                
            </div>
        </div>
    </div>
    </div>
     );
}

export default EmptyDasboard;