import { Button, CircularProgress, FormControl, Grid, InputAdornment, makeStyles, OutlinedInput, TextField, Typography } from "@material-ui/core";
import { useContext, useState, useImperativeHandle, useRef, forwardRef, useEffect} from "react";
import { RootStoreContext } from "../../../app/stores/root.store";
import { useTranslation } from 'react-i18next';
import React from "react";
import { compressFile } from '../../../app/common/util/util';
import { EDROutlinedInput } from "../../../app/common/EDROutlinedInput";
import { EDRTextField } from "../../../app/common/EDRTextField";
import { useFormik } from 'formik';
import * as yup from 'yup';
import jobAgent from "../../../app/api/jobAgent";
import { toast } from "react-toastify";

type PrePostSectionProps = {
    section:string;
    type:string;
    name: string;
  }

const PrePostSectionComponent : React.FC<PrePostSectionProps> = (props, ref) => {
    const rootStore = useContext(RootStoreContext); 
    const inputFile = useRef(null);
    const [scriptInvalid,setScriptInvalid] = useState(false);
    const [scriptInvalidType,setScriptTypeInvalid] = useState(false);
    const [processingTest,setProcessingTest] = useState(false);
    let isEdit = window.location.href.includes('editJob');

  const validationSchema = yup.object({
  })

  const form = useFormik({
    initialValues: {
      PathState: '',
      DataState: null,
      TTLState: 15,
      scriptId: 0
    },
    onSubmit: () => { },
    validationSchema
})

const [ShowBrowse,setShowBrowse]= useState(form.values.DataState!=null?false:true);

    const useStyles = makeStyles({
        card: {
            padding: 16,
            paddingRight:35,
            width: '600px',
            height: '650px'
          },
          MainHeading: {
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '100%',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.19',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1
          },
          SecondHeading: {
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.19',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1,
            color: '#4d5f75',
            fontsize:'13px',
            marginTop:'16px'
          },
          helpIcon:
          {
            color: '#1976d2',
            fontSize: "large",
            marginLeft: '16px'
          },
          formControl: {
            width: '100%',
            '& label': {
              background: "white",
              width: "auto",
              padding: "0 4px 0 4px",
              fontSize: '17px'
            }
          },
          divider: {
            backgroundColor: '#eaeef4',
            width: '100%',
            margin: '0',
            marginTop: '8px',
            marginLeft: '26px'
          },
          blueDiv:{
            width: '600px',
            height: '60px',
            backgroundColor: '#1f396d',
            marginLeft: '-40px',
            marginTop: '-36px'
          },
          label: {
            textTransform: 'capitalize',
          },
          button:{
            borderRadius: 15,
            color: 'white',
            backgroundColor:'#2892d7',
            textTransform: 'capitalize'
          },
          BrowseButton:{
            backgroundColor: '#fafafc',
            textTransform: 'none',
          },
          errorText:{
            color:'red',
            fontSize: '0.75rem',
            textAlign: 'left',
            fontFamily: "Roboto",
            fontWeight: 400,
            lineHeight: 1.66,
            letterSpacing: '0.03333em'
          }
    });   
    const classes = useStyles(); 
    const [t] = useTranslation("createJobBase");

    const populateSectionFields = () => {
      let initialValues;
      if(props.section === "failover")
      {
          if (rootStore.createJobStore.baseSettings.scriptsSettings.failoverScriptSetting!=null)
              {
                  if( props.type==="pre")
                  {
                      form.setFieldValue("PathState",rootStore.createJobStore.baseSettings.scriptsSettings.failoverScriptSetting.prePath);
                      form.setFieldValue("TTLState",rootStore.createJobStore.baseSettings.scriptsSettings.failoverScriptSetting.preTTL);
                      initialValues ={
                      PathState: rootStore.createJobStore.baseSettings.scriptsSettings.failoverScriptSetting.prePath,
                      DataState: null ,
                      TTLState: rootStore.createJobStore.baseSettings.scriptsSettings.failoverScriptSetting.preTTL,
                      scriptId:rootStore.createJobStore.baseSettings.scriptsSettings.failoverScriptSetting.preId
                      };
                  }
                  else
                  {
                    form.setFieldValue("PathState",rootStore.createJobStore.baseSettings.scriptsSettings.failoverScriptSetting.postPath);
                    form.setFieldValue("TTLState",rootStore.createJobStore.baseSettings.scriptsSettings.failoverScriptSetting.postTTL);
                    initialValues ={
                      PathState: rootStore.createJobStore.baseSettings.scriptsSettings.failoverScriptSetting.postPath,
                      DataState: null ,
                      TTLState: rootStore.createJobStore.baseSettings.scriptsSettings.failoverScriptSetting.postTTL,
                      scriptId:rootStore.createJobStore.baseSettings.scriptsSettings.failoverScriptSetting.postId
                      };
                  }  
              }
       }
      if(props.section === "cleanup")
      {
          if (rootStore.createJobStore.baseSettings.scriptsSettings.cleanupScriptSetting!=null)
              {
                  if( props.type==="pre")
                  {
                    form.setFieldValue("PathState",rootStore.createJobStore.baseSettings.scriptsSettings.cleanupScriptSetting.prePath);
                    form.setFieldValue("TTLState",rootStore.createJobStore.baseSettings.scriptsSettings.cleanupScriptSetting.preTTL);
                    initialValues ={
                      PathState: rootStore.createJobStore.baseSettings.scriptsSettings.cleanupScriptSetting.prePath,
                      DataState: null ,
                      TTLState: rootStore.createJobStore.baseSettings.scriptsSettings.cleanupScriptSetting.preTTL,
                      scriptId:rootStore.createJobStore.baseSettings.scriptsSettings.cleanupScriptSetting.preId
                      };
                  }
              else
                  {
                    form.setFieldValue("PathState",rootStore.createJobStore.baseSettings.scriptsSettings.cleanupScriptSetting.postPath);
                    form.setFieldValue("TTLState",rootStore.createJobStore.baseSettings.scriptsSettings.cleanupScriptSetting.postTTL);
                    initialValues ={
                      PathState: rootStore.createJobStore.baseSettings.scriptsSettings.cleanupScriptSetting.postPath,
                      DataState: null ,
                      TTLState:rootStore.createJobStore.baseSettings.scriptsSettings.cleanupScriptSetting.postTTL,
                      scriptId:rootStore.createJobStore.baseSettings.scriptsSettings.cleanupScriptSetting.postId
                      };
                  }
              }
      }
      if(props.section === "test")
      {
          if (rootStore.createJobStore.baseSettings.scriptsSettings.TestScriptSetting!=null)
          {
              if( props.type==="pre")
              {
                form.setFieldValue("PathState",rootStore.createJobStore.baseSettings.scriptsSettings.TestScriptSetting.prePath);
                form.setFieldValue("TTLState",rootStore.createJobStore.baseSettings.scriptsSettings.TestScriptSetting.preTTL);
                initialValues ={
                  PathState: rootStore.createJobStore.baseSettings.scriptsSettings.TestScriptSetting.prePath,
                  DataState: null ,
                  TTLState:rootStore.createJobStore.baseSettings.scriptsSettings.TestScriptSetting.preTTL,
                  scriptId:rootStore.createJobStore.baseSettings.scriptsSettings.TestScriptSetting.preId
                  };
              }
              else
              {
                form.setFieldValue("PathState",rootStore.createJobStore.baseSettings.scriptsSettings.TestScriptSetting.postPath);
                form.setFieldValue("TTLState",rootStore.createJobStore.baseSettings.scriptsSettings.TestScriptSetting.postTTL);
                initialValues ={
                  PathState: rootStore.createJobStore.baseSettings.scriptsSettings.TestScriptSetting.postPath,
                  DataState: null ,
                  TTLState:rootStore.createJobStore.baseSettings.scriptsSettings.TestScriptSetting.postTTL,
                  scriptId:rootStore.createJobStore.baseSettings.scriptsSettings.TestScriptSetting.postId
                  };
              }
          }
      }
      form.resetForm({values: initialValues}); 
      if(initialValues.PathState!='')
      {
        setShowBrowse(false);
      }
  }

  useEffect(() => {
    populateSectionFields();
}, [props.name]);

    useImperativeHandle(ref, () => ({
        SaveClick () {
            const scriptsetting = rootStore.createJobStore.baseSettings.scriptsSettings;
                if(props.section ==="failover")
                    {
                        if(props.type ==="pre")
                            rootStore.createJobStore.updateBaseSettings({scriptsSettings: {...scriptsetting, failoverScriptSetting: {...scriptsetting.failoverScriptSetting, prePath: form.values.PathState, prePathData: form.values.DataState, preTTL: form.values.TTLState}}});
                        else
                            rootStore.createJobStore.updateBaseSettings({scriptsSettings: {...scriptsetting, failoverScriptSetting: {...scriptsetting.failoverScriptSetting, postPath: form.values.PathState, postPathData: form.values.DataState ,postTTL: form.values.TTLState}}});
                    }
                if(props.section ==="cleanup")
                    {
                        if(props.type ==="pre")
                            rootStore.createJobStore.updateBaseSettings({scriptsSettings: {...scriptsetting, cleanupScriptSetting: {...scriptsetting.cleanupScriptSetting, prePath: form.values.PathState, prePathData: form.values.DataState ,preTTL: form.values.TTLState }}});
                        else
                            rootStore.createJobStore.updateBaseSettings({scriptsSettings: {...scriptsetting, cleanupScriptSetting: {...scriptsetting.cleanupScriptSetting, postPath: form.values.PathState, postPathData: form.values.DataState ,postTTL: form.values.TTLState}}});
                    }
                    if(props.section ==="test")
                    {
                        if(props.type ==="pre")
                            rootStore.createJobStore.updateBaseSettings({scriptsSettings: {...scriptsetting, TestScriptSetting: {...scriptsetting.TestScriptSetting, prePath: form.values.PathState,prePathData: form.values.DataState, preTTL: form.values.TTLState}}});
                        else
                            rootStore.createJobStore.updateBaseSettings({scriptsSettings: {...scriptsetting, TestScriptSetting: {...scriptsetting.TestScriptSetting, postPath: form.values.PathState, postPathData: form.values.DataState, postTTL: form.values.TTLState}}});
                    }
        },
        CancelClick() {
            const scriptsetting = rootStore.createJobStore.baseSettings.scriptsSettings;
            let defaultPath="";
            let defaultData=null;
            let defaultTTL=15;
            form.setFieldValue("PathState",defaultPath);
            form.setFieldValue("TTLState",defaultTTL);
            if(props.section ==="failover")
                {
                    if(props.type ==="pre")
                        rootStore.createJobStore.updateBaseSettings({scriptsSettings: {...scriptsetting, failoverScriptSetting: {...scriptsetting.failoverScriptSetting, prePath:scriptsetting.failoverScriptSetting.prePath!="" ?scriptsetting.failoverScriptSetting.prePath :defaultPath, prePathData:scriptsetting.failoverScriptSetting.prePathData!="" ?scriptsetting.failoverScriptSetting.prePathData :defaultData , preTTL:defaultTTL }}});
                    else
                        rootStore.createJobStore.updateBaseSettings({scriptsSettings: {...scriptsetting, failoverScriptSetting: {...scriptsetting.failoverScriptSetting, postPath:scriptsetting.failoverScriptSetting.postPath!="" ?scriptsetting.failoverScriptSetting.postPath :defaultPath, postPathData:scriptsetting.failoverScriptSetting.postPathData!="" ?scriptsetting.failoverScriptSetting.postPathData :defaultData , postTTL: defaultTTL}}});
                }
            if(props.section ==="cleanup")
                {
                    if(props.type==="pre") 
                        rootStore.createJobStore.updateBaseSettings({scriptsSettings: {...scriptsetting, cleanupScriptSetting: {...scriptsetting.cleanupScriptSetting, prePath:scriptsetting.cleanupScriptSetting.prePath!="" ?scriptsetting.cleanupScriptSetting.prePath :defaultPath, prePathData:scriptsetting.cleanupScriptSetting.prePathData!="" ?scriptsetting.cleanupScriptSetting.prePathData :defaultData , preTTL:defaultTTL }}});
                    else
                        rootStore.createJobStore.updateBaseSettings({scriptsSettings: {...scriptsetting, cleanupScriptSetting: {...scriptsetting.cleanupScriptSetting, postPath:scriptsetting.cleanupScriptSetting.postPath!="" ?scriptsetting.cleanupScriptSetting.postPath :defaultPath, postPathData:scriptsetting.cleanupScriptSetting.postPathData!="" ?scriptsetting.cleanupScriptSetting.postPathData :defaultData , postTTL: defaultTTL}}});
                }
            if(props.section ==="test")
                {
                    if(props.type==="pre")
                        rootStore.createJobStore.updateBaseSettings({scriptsSettings: {...scriptsetting, TestScriptSetting: {...scriptsetting.TestScriptSetting,prePath:scriptsetting.TestScriptSetting.prePath!="" ?scriptsetting.TestScriptSetting.prePath :defaultPath, prePathData:scriptsetting.TestScriptSetting.prePathData!="" ?scriptsetting.TestScriptSetting.prePathData :defaultData , preTTL:defaultTTL }}});
                    else
                        rootStore.createJobStore.updateBaseSettings({scriptsSettings: {...scriptsetting, TestScriptSetting: {...scriptsetting.TestScriptSetting, postPath:scriptsetting.TestScriptSetting.postPath!="" ?scriptsetting.TestScriptSetting.postPath :defaultPath, postPathData:scriptsetting.TestScriptSetting.postPathData!="" ?scriptsetting.TestScriptSetting.postPathData :defaultData , postTTL: defaultTTL}}});
                }
        },
        IsDirty () {
          return (form.dirty && form.touched) ? true: false;
        }
      }));

    const handleFileUpload = e => {
        const { files } = e.target;
        if (files && files.length) 
        {
          let fileSizebytes=files[0].size/1024;
          let invalid = fileSizebytes > 512;
          invalid ? setScriptInvalid (true):setScriptInvalid (false);

          var parts = files[0].name.split(".");
          const fileType = parts[parts.length - 1];
          let invalidType = fileType !== 'bat' && fileType !=='ps1'; 
          invalidType ? setScriptTypeInvalid(true):setScriptTypeInvalid (false);

          if(!invalid && !invalidType) 
          {
            form.setFieldValue("PathState",files[0].name);
            const toBase64 = file => new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = error => reject(error);
            });
             
            const data = {
                file: toBase64(files[0]).then(function(res) {
                  form.setFieldValue("DataState",res);
                }),
            }
            setShowBrowse(false);   
          }
          else
          {
            form.setFieldValue("PathState",'');
            form.setFieldValue("DataState",null);
          }
        }
    };

    const removeFile =()=> {
        form.setFieldValue("PathState",'');
        form.setFieldValue("DataState",null);
        setShowBrowse(true);
    }
    
    const onButtonClick = () => {
    inputFile.current.click();
    };
  
    const handlePathChange = (event) => {   
      form.setFieldValue("PathState",event.target.value);
      form.setFieldValue("DataState",event.target.value!=''?compressFile(event.target.files[0]):null);
      if(event.target.value!='')
        setShowBrowse(true);
    };

    const handleTTLChange = (event) => {   
      form.setFieldValue("TTLState",event.target.value);
    };

    const handleTest=() =>{   
      var details =
      {
          prepostType:props.type+" "+props.section,
          scriptName:form.values.PathState,
          scriptData: form.values.DataState,
          scriptId:isEdit ? form.values.scriptId : 0
      };
      setProcessingTest(true);
      jobAgent.JobTestsActions.TestPrePostScript(details).then(res => {
          if(res)
              toast.success(props.type+" "+props.section + " Script Test succeeded");
          else
              toast.error(props.type+" "+props.section + " Script Test failed");
      setProcessingTest(false);
      }).catch(err=>{
      if(err && err.data && err.data.errors)
          toast.error(err.data.errors);
      setProcessingTest(false);
      });           
  }

            return (
              <Grid container direction="row" style={{ marginTop: '10px' }} spacing={1}>
                <input style={{ display: "none" }} ref={inputFile} type="file" onChange={(event)=> { 
               handleFileUpload(event) 
               event.target.value=null
            }}/>
                <Grid item xs={3}>
                  <Typography className={classes.SecondHeading} style={{marginTop:'16px'}}>{t(props.name)}</Typography>
                </Grid>
                <Grid item container xs={6} direction="column">
                <Grid item>
                  <FormControl className={classes.formControl} style ={{marginTop:'-16px',marginLeft: '-10px',paddingBottom:'5%'}} variant='outlined'>
                    <EDROutlinedInput className="MuiFormControl-marginNormal" 
                        value={form.values.PathState}
                        style={{paddingRight:'0px'}}
                        type='text'
                        placeholder={t('script')}
                        onChange={handlePathChange}
                        endAdornment={
                          <InputAdornment position="end">
                              {ShowBrowse === true ?<Button className={classes.BrowseButton} size="small" onClick={onButtonClick} style={{width: '75px',height: '52px',fontSize:'13px',marginLeft:'-12px'}}>{ t("browse")}</Button>
                               :<Button size="small" className={classes.BrowseButton} onClick={removeFile} style={{width: '75px',height: '52px',fontSize:'13px',marginLeft:'-12px'}}>{t("remove")}</Button>}
                          </InputAdornment>
                          }
                    />  
                    </FormControl>
                  </Grid>
                <Grid item style={{position:'absolute',marginTop:'9%'}}>
                  <label className={classes.errorText}>{scriptInvalid ? t('scriptValidationSizeText'): (scriptInvalidType ? t('scriptValidationTypeText') : null)}</label>
                </Grid>
                </Grid>
                <Grid item xs={2}>
                <FormControl className={classes.formControl} variant='outlined'>
                <TextField id="ttl"
                label={t('ttl')}
                placeholder=""
                value={form.values.TTLState}
                onChange={handleTTLChange}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined">
              </TextField>
                </FormControl>
                </Grid> 
                <Grid item xs={1}>
                <Button onClick={handleTest} disabled={form.values.PathState == '' ||processingTest} color="primary" style={{textTransform:'none',marginTop:'10px'}}>
                  {processingTest ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                {t('test')}
                </Button>
                </Grid>
              </Grid>
            );
}

export default forwardRef(PrePostSectionComponent);