import { Button, Card, CircularProgress, Divider, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { toast } from 'react-toastify';
import { runInAction } from 'mobx';
import Create from '@material-ui/icons/Create';
import { RootStoreContext } from '../../../../app/stores/root.store';
import AssetAgent from '../../../../app/api/assetsAgent';
import { LicenseType, NetworkOption } from '../../../../app/models/jobProperties.model';
import { AccessCredentials, AssetDTO } from '../../../../app/models/createjob/createJob.model';
import LockIcon from '@material-ui/icons/Lock';
import { UpdateType } from '../../../../app/stores/activityStore';

const useStyles = makeStyles({
    editIcon: {
        cursor: 'pointer'
    },
    button: {
        width: '50%',
        height: '100%',
        borderRadius: '4px',
        border: 'solid 1px #c7cfdb',
        backgroundColor: '#fafafc'
    },
    advanced: {
        width: '14px',
        height: '14px',
        objectFit: 'contain',
    },
    MainHeading: {
        fontFamily: 'Roboto',
        color: '#1f396d',
        fontSize: '16px'
    },
    SecondHeading: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '100%',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.14',
        letterSpacing: 'normal',
        textAlign: 'left'
    },
    card: {
        padding: 25,
        height: '100%',
    },
    divider: {
        backgroundColor: '#eaeef4',
        width: '75%',
        marginTop: '8px',
        marginLeft: '7px',
        marginRight: '-107px'
    },
    helpIcon:
    {
        color: '#1976d2',
        fontSize: "large"
    },
    formControl: {
        width: '100%',
        '& label': {
            background: "white",
            width: "auto",
            padding: "0 4px 0 4px",
            fontSize: '17px'
        }
    }
});

const EDRomponent: React.FC<{ onValidationChange: (isValid: boolean) => void }> = (props) => {

    const classes = useStyles();
    const [t] = useTranslation("createJobNetwork");
    const rootStore = useContext(RootStoreContext);
    const { jobSettingsStore, displayStore, createJobStore,activityStore } = rootStore;
    const { edrDrawerOpen } = displayStore.NetworkDrawerSettings;
    const [ImageEDRColor, setImageEDRColor] = useState(false);
    const isFirstTrial = jobSettingsStore.isFirstTrial;
    const Validate =()=>{
        if(!displayStore.nextStepValid.edrValid)
        {
         jobSettingsStore.selectedEDR && jobSettingsStore.selectedEDR.id!=0 && jobSettingsStore.validateEDR(jobSettingsStore.selectedEDR,true)
        }
    }
    useEffect(() => {
        if(createJobStore.networkSetting.EDRServer?.id)
        {
            let valueEDR = jobSettingsStore.EDRs.find(a => a.id == createJobStore.networkSetting.EDRServer.id)
            jobSettingsStore.updateSelectedEDR(valueEDR);
            createJobStore.updateNetworkSettings({ EDRServer: valueEDR ? valueEDR : null });
            displayStore.updateNextStepValid({ edrValid: true });
            
        }
        else
        {
            if(jobSettingsStore.EDRs.length >0)
            {
                jobSettingsStore.updateSelectedEDR(jobSettingsStore.EDRs[0]);
                createJobStore.updateNetworkSettings({ EDRServer: jobSettingsStore.EDRs[0] });
                displayStore.updateNextStepValid({ edrValid: true });
                
            }
            else
            {
                jobSettingsStore.updateSelectedEDR(null);
                createJobStore.updateNetworkSettings({ EDRServer: null });
                displayStore.updateNextStepValid({ edrValid: false });
            }
            
        }
        activityStore.on(UpdateType.ReValidate, Validate);
        return () => {
            activityStore.off(UpdateType.ReValidate,Validate);
        }
    }, [jobSettingsStore.EDRs])
    useEffect(() => {
        props.onValidationChange(Boolean(jobSettingsStore.selectedEDR?.id));
        if(!displayStore.screenSettings.EDRApplyProcessing)
          jobSettingsStore.selectedEDR && jobSettingsStore.selectedEDR.id!=0 &&  jobSettingsStore.validateEDR(jobSettingsStore.selectedEDR,displayStore.screenSettings.changeEDR ? true:false);
    }, [jobSettingsStore.selectedEDR])

    const CheckSectionAndDrawerStatus = () => {
        if (displayStore.screenSettings.displayNetwork == 'ControllerSettings' || displayStore.screenSettings.displayNetwork == 'CloneSettings' || displayStore.screenSettings.displayNetwork == 'AdvancedVlan' ||
            displayStore.screenSettings.displayNetwork == 'NetworkControlSettings' || displayStore.screenSettings.displayNetwork == 'EDRServerSettings' || displayStore.screenSettings.displayNetwork == 'EmptyVlan') {
            if (displayStore.screenSettings.displayNetwork != 'EDRServerSettings')
                displayStore.updateScreenSettings({ displayNetwork: 'EmptyVlan' });
            displayStore.updateNetworkDrawerSettings({ controllerDrawerOpen: false });
            displayStore.updateNetworkDrawerSettings({ cloneDrawerOpen: false });
            displayStore.updateNetworkDrawerSettings({ liveDrawerOpen: false });
            displayStore.updateNetworkDrawerSettings({ testDrawerOpen: false });
        }
    }

    const handleAddEDR = () => {
        jobSettingsStore.updatePreviousEDR(jobSettingsStore.selectedEDR);
        let cred = new AccessCredentials();
        cred.id = 0; cred.userName = ''; cred.password = '';
        let edrObject = new AssetDTO();
        edrObject.id = 0;
        edrObject.assetName = "";
        edrObject.assetVMName = "";
        edrObject.credentials = cred;
        jobSettingsStore.updateSelectedEDR(edrObject);
        if ((jobSettingsStore.selectedEDR == null) || (jobSettingsStore.selectedEDR != null && !jobSettingsStore.selectedEDR.assetName.includes("Add"))) 
        {
            displayStore.updateScreenSettings({ AddModeEDR: true, AddModeEDRC: false, AddModeDC: false });
            displayStore.updateScreenSettings({ renderValue: "" });
            displayStore.updateScreenSettings({ displayInfo: "InfoEDR" });
            CheckSectionAndDrawerStatus();
            displayStore.updateScreenSettings({ displayNetwork: 'EDRServerSettings' });
            displayStore.updateScreenSettings({ displayBaseSettings: 'EDRServerSettings' });
            displayStore.closeAllOtherNetworkDrawersExcept("edrDrawer");
        }
    }

    const handleChangeEDR = (event: object, value: any) => {
        if (event != null) {
            if (typeof value === 'object') {
                jobSettingsStore.updateSelectedEDR(value);
                createJobStore.updateNetworkSettings({ EDRServer: value });
                displayStore.updateNextStepValid({ edrValid: true });
            }
            if (typeof value === 'string') {
                const edrObj = jobSettingsStore.EDRs.find(e => e.assetName.toLocaleLowerCase() == value.toLocaleLowerCase());
                if (edrObj) {
                    displayStore.updateNetworkSectionSettings({ DisableButtonEDR: false });
                    displayStore.updateScreenSettings({ AddModeEDR: false, AddModeEDRC: false, AddModeDC: false });
                    displayStore.updateScreenSettings({ renderValue: edrObj.assetName });
                    createJobStore.updateNetworkSettings({ EDRServer: edrObj });
                    jobSettingsStore.updateSelectedEDR(edrObj);
                    displayStore.updateNextStepValid({ edrValid: true });
                }
            }
            CheckSectionAndDrawerStatus();
            displayStore.updateNetworkDrawerSettings({ edrDrawerOpen: false });
            displayStore.updateScreenSettings({changeEDR:true});
            if (event != null) {
                displayStore.updateScreenSettings({ displayInfo: "InfoEDR" });
            }
        }
    };

    const buttonRedirect = (subComponent: any) => {
        if (edrDrawerOpen) {
            if (displayStore.screenSettings.AddModeEDR) {
                displayStore.updateScreenSettings({ displayBaseSettings: subComponent });
                displayStore.updateScreenSettings({ AddModeEDR: false });
                displayStore.updateBaseDrawerSettings({ EDRDrawerOpen: true });
                displayStore.updateScreenSettings({ displayBaseSettings: '' });
                displayStore.closeAllOtherBaseDrawersExcept("edrDrawer");
            }
            else {
                displayStore.updateScreenSettings({ displayNetwork: 'EmptyVlan' });
                displayStore.updateNetworkDrawerSettings({ edrDrawerOpen: false });
                displayStore.updateScreenSettings({ displayBaseSettings: '' });
            }
        }
        else {
            displayStore.updateScreenSettings({ AddModeEDR: false });
            displayStore.updateScreenSettings({ displayNetwork: subComponent });
            displayStore.updateScreenSettings({ displayInfo: "InfoEDR" });
            displayStore.updateScreenSettings({ displayBaseSettings: 'EDRServerSettings' });
            displayStore.closeAllOtherNetworkDrawersExcept("edrDrawer");
        }
    };
    const loadCircularProgress =()=>{
        return <div style={{width:'100%',display:'flex'}}>
        {(t('EDRInProgress'))}
        <CircularProgress id="dcValidation" size={18} style={{color: '#2892D7', marginLeft: '10px',marginTop: '-3px'}}/>
        </div>
    }

    return (<Grid container spacing={2} style={{ display: 'flex', justifyContent: 'space-between' }}>

        <Grid item xs={1} style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
            {isFirstTrial  ? 
            <Tooltip title={t("trialLock")} arrow>
                <LockIcon />
            </Tooltip>
            : <IconButton onClick={() => handleAddEDR()} disabled={true}>
                <AddCircleOutlineIcon />
            </IconButton>}
        </Grid>
        <Grid container item xs={11} >
            <Grid item xs={11}>
                <Autocomplete id="ensuredr-server"
                    style={{width:'97%', backgroundColor:'rgba(235, 238, 244, 0.75)'}}
                    disableClearable
                    disabled={true}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.assetName}`)}
                    getOptionSelected={(option, value) => option.assetName == value}
                    value={jobSettingsStore.selectedEDR}
                    options={jobSettingsStore.EDRs}
                    onInputChange={handleChangeEDR}
                    renderOption={option => <span>{`${option.assetName} (${option.assetIP})`}</span>}
                    onChange={(event: object, value: any) => { handleChangeEDR(event, value) }}
                    onFocus={() => displayStore.updateScreenSettings({ displayInfo: "InfoEDR" })}
                    renderInput={(params) => (<TextField {...params} disabled={true} label={t('edr')} InputLabelProps={{ shrink: true, }} variant="outlined" />)}
                />
            </Grid>

            <Grid item xs={1}>
                <Button className={classes.button} disabled={true} onClick={() => buttonRedirect('EDRServerSettings')}>
                    <Create className={classes.editIcon} onMouseEnter={() => setImageEDRColor(true)} onMouseLeave={() => setImageEDRColor(false)}></Create>
                </Button>
            </Grid>
        </Grid>
    </Grid>
    );
};

export default observer(EDRomponent);