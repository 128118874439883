import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/root.store';
import { Paper, makeStyles, withStyles, Switch, Typography, Divider, TextField, InputAdornment, IconButton, OutlinedInput, Button, FormControl } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import SettingsLogoComponent from './SettingsLogo.component';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useFormik } from 'formik';
import { EmailSettingSchema } from './validation';
import SettingEmailSMTPComponent from './SettingEmailSMTP.component';
import * as yup from 'yup';

const EmailSettingsComponent: React.FC<{onChange: (val) => void}> = (props) => {
    const rootStore = useContext(RootStoreContext); 
    const { setOpen, getLogo , currentLogo, updateEmailObj, emailSettings, sendTestEmail, settingsLoaded,emailListValid } = rootStore.settingStore;
    const [t] = useTranslation("settings");
    const [orgSMTPstate, setorgSMTPstate] = useState("system");
    const [allowanonymousState,setallowanonymousState] = useState(true);
    const [sendAttachmentsState,setSendAttachmentsState] = useState(true);
    const [reportURLstate, setReportURLState] = useState("");
    const [companyLogostate, setcompanyLogostate] = useState(""); 
    const form = useFormik({
        initialValues: {
            emailtype: "",
            emailSettings: {}
        },
        validationSchema:yup.object({
                emailtype: yup.string(), 
                emailSettings: yup.object().when("emailtype",{ is: 'system', then: yup.object(), otherwise: EmailSettingSchema})
        }),
        validateOnMount: false,
        onSubmit: () => { },
    })

    useEffect(() => {
        const timer = setTimeout(() => form.validateForm(), 100)
        return () => {
          clearTimeout(timer);
      }
    }, [form.values])
    
    useEffect(() => {
        getLogo();
    }, [getLogo]); 

    const populateFields = () => {
        if (emailSettings!=null){ 
            const initialValuesList = {
                emailtype: "",
                emailSettings: {...form.values.emailSettings}
            };
            const emailSettingItems= emailSettings.filter(s=> s.settingSection=="Email");
            Object.keys(emailSettingItems).forEach(key => {
                switch(emailSettings[key].settingKey.toLowerCase()){
                    case "companylogo":
                        let companyLogo = (emailSettings[key].settingValue);  
                        setcompanyLogostate(companyLogo);                        
                        break;
                    case "smtptype":
                        initialValuesList.emailtype = emailSettings[key].settingValue; 
                        if (initialValuesList.emailtype==="system")
                           setallowanonymousState(false);                       
                        break;
                    case "edrmurlforemail":
                        let edrmurlforemail = (emailSettings[key].settingValue);  
                        setReportURLState(edrmurlforemail);              
                        break;
                    case "addattachments":
                        let AddAttachments = (emailSettings[key].settingValue === 'true');  
                        setSendAttachmentsState(AddAttachments);              
                        break;
                    default:
                        break;
                }
            })  
            form.setFieldValue('emailtype',initialValuesList.emailtype , true);  
         }
        
     }

     
    useEffect(() => {
        if (settingsLoaded) 
            populateFields()
    }, [settingsLoaded]); 

      useEffect(() => {
        if(form.dirty) {
            updateEmailObj(form.values.emailtype,"SMTPType","Email");
        }
        props.onChange && props.onChange(form.values);
    },[form.values])


    const useStyles = makeStyles({
        emailDetails: {
            display: "flex",
            flexDirection: "column",
            color: "#4d5f75",
            '& > div': {
              padding: 5,
              display: "flex",
              justifyContent: "space-between",
              marginRight: "20px",
              marginLeft:"10px"
            }
        },
        dropdown: {
            '& .MuiOutlinedInput-input': {
                paddingTop: '7px !important',
                paddingBottom: '7px !important',
                paddingLeft: '15px !important'
            }
        },
        label: {
            textTransform: 'capitalize',
          },
        disabledButton: {
            width: "150px",
            height: "32px",
            backgroundColor: "#c7cfdb !important",
            color: "white !important",
            borderRadius: "28px !important",
        },
        formControl: {
            width: '41%',
            '& label': {
                background: "white",
                width: "auto",
                padding: "0 4px 0 4px",
                fontSize: '17px'
            }
        }
    })
    const BlueSwitch = withStyles({
        switchBase: {
          color: blue[300],
          "&$checked": {
            color: blue[500],
          },
          "&$checked + $track": {
            backgroundColor: blue[500],
          },
        },
        checked: {},
        track: {},
      })(Switch);
     
    const classes = useStyles();

    const handleLogoClick = (event) => {
        setOpen(true);
    };

    const handleTestEmailClick = (event,key) => {
        sendTestEmail(key,false);
    }


    const handleAddAttachmentClick = (event) => {
        setSendAttachmentsState(event.target.checked);
        let send:boolean = true;
        if (!event.target.checked)
            send = false;
        updateEmailObj(JSON.stringify(send),"AddAttachments","Email");
    }

   
    return (
        <div data-test='email-settings-component'>
            <form>
            <input type='hidden' value='something'/>
            <SettingsLogoComponent />
            <Paper>
                <div className={classes.emailDetails}>
                <div style={{marginTop: "10px", marginBottom: "10px"}}>
                        <Typography classes={{ root: "settingsText"}}>{t("sendOption")}</Typography>
                        <ToggleButtonGroup 
                            id="emailtype"
                            classes={{ root: "settings_toggle_button_group" }} 
                            style={{width:"160px",height:"50px"}}
                            value={form.values.emailtype}
                            exclusive={true}
                            onChange={(evt, emailtype) => form.setFieldValue("emailtype", emailtype, true)}
                            >
                                <ToggleButton 
                                    classes={{ root: "settings_toggle_button", selected: "settings_toggle_button_selected", label: classes.label }} 
                                    style={{width:"80px"}}
                                    value="system">
                                        {t("defaultSMTP")}
                                    </ToggleButton>
                                <ToggleButton 
                                    name="emailtype"
                                    classes={{ root: "settings_toggle_button", selected: "settings_toggle_button_selected", label: classes.label }} 
                                    style={{width:"80px"}}
                                    value="organization">
                                        {t("orgSMTP")}
                                </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <Divider />
                    <SettingEmailSMTPComponent emailType={form.values.emailtype} onChange={val => form.setFieldValue('emailSettings', val,true)} />
                    <Divider />
                    <div style={{marginBottom: "10px",marginTop:"10px"}}>
                        <Typography classes={{ root: "settingsText"}}>{t("companyLogo")}</Typography>
                        <img style={{width: "110px"}} src={currentLogo} />
                        <Button
                            variant="contained"
                            classes={{ root: "upload_button", label: classes.label }}
                            onClick={(event) => {handleLogoClick(event)}}
                        >{t("upload")}
                        </Button>
                    </div>
                    <div>                
                        <Typography style={{paddingTop:"1em"}} classes={{ root: "settingsText"}}>{t("edrmReportURL")}</Typography>
                        <TextField id="ReportURL"
                                style={{width:'302px', height: '32px' , borderRadius: '4px', backgroundColor: '#ffffff' , marginTop:'1em', marginBottom: '1em'}}
                                value={reportURLstate} 
                                variant="outlined"
                                size="small"
                                label={t("reportURL")}
                                onChange={(event) => {setReportURLState(event.target.value);updateEmailObj(event.target.value,"EDRMUrlForEmail","Email")}}
                        /> 
                    </div>
                    <div>                
                        <Typography style={{paddingTop:"1em"}} classes={{ root: "settingsText"}}>{t("sendAttachments")}</Typography>
                        <BlueSwitch
                                    checked={sendAttachmentsState}
                                    onChange={(event) => {handleAddAttachmentClick(event)}}
                                    color="default"
                                    name="sendAttach"
                                /> 
                    </div>
                    <Divider />
                    <div style={{paddingTop:"1em",paddingBottom:"1em"}}>
                       <Typography classes={{ root: "settingsText"}}>{t("testEmail")}</Typography>
                        <Button
                            disabled={ !form.isValid || !emailListValid}
                            variant="contained"
                            classes={{ root: "testemail_button", disabled: classes.disabledButton, label: classes.label}}
                            onClick={(event) => {handleTestEmailClick(event,orgSMTPstate)}}
                        >{t("sendEmail")}
                        </Button>
                    </div>
                        
                </div>
            </Paper>
            </form>
        </div>
    );
}

export default (observer(EmailSettingsComponent));