import React, { useEffect, useLayoutEffect, useReducer, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, InputAdornment, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import JobResultsApi from '../api/jobResult.api';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import { debounce } from '../../app/common/util/util';
import { SearchTextField } from '../../features/report/reportDeviceSection.component';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const Highlighted = ({ text = '', highlight = '' }) => {
  if (!highlight.trim()) {
    return <span>{text}</span>
  }
  const regex = new RegExp(`(${highlight})`, 'gi')
  const parts = text.split(regex)
  return (
    <span>
      {parts.filter(part => part).map((part, i) => (
        regex.test(part) ? <mark className="_h" key={i}>{part}</mark> : <span key={i}>{part}</span>
      ))}
    </span>
  )
}

type LogViewProps = {
  jobResultId: number;
  jobName: string;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  root: {
    minWidth: '75vw',
    minHeight: '50vh',
    "& ::-webkit-scrollbar": {
      width: '12px'
    },
    "& ::-webkit-scrollbar-track": {
      background: '#f1f1f1 !important'
    },
    "& ::-webkit-scrollbar-thumb": {
      background: '#c0c0c0 !important',
      borderRadius: 6,
      border: '3px solid #f1f1f1'
    }
  },
  wrapper: {
    counterReset: 'line',
    color: 'black',
    height: '90%',
    width: 'calc(100% - 50px)'

  },
  line: {
    '&::before': {
      content: '""counter(line)""',
      counterIncrement: "line"
    }
  },
  currentMarker: {
    background: 'gray !important'
  }
})

const LogViewer: React.FC<LogViewProps> = ({ jobResultId, open, onClose, jobName }) => {
  function reducer(state, action: { type: string, value?: any }): { isLoading: boolean, logsLoaded: boolean, logs: Array<string>, filteredLogs: Array<string>, error: string } {
    switch (action.type) {
      case 'loadingLogs': return { ...state, isLoading: true, error: null };
      case 'logsFetched': return { ...state, isLoading: false, logsLoaded: true, logs: action.value, filteredLogs: action.value };
      case 'filterLogs': return { ...state, filteredLogs: state.logs.filter(l => !action.value || l.indexOf(action.value) >= 0) };
      case 'fetchError': return { ...state, isLoading: false, logsLoaded: true, error: action.value }
    }
    return state;
  }
  const [state, dispatch] = useReducer(reducer, {
    logsLoaded: false,
    logs: [],
    filteredLogs: [],
    isLoading: false,
    error: null
  })

  const classes = useStyles();
  const { t } = useTranslation('logViewer');
  const [filter, setFilter] = useState();
  const contentRef = React.useRef<HTMLElement>(null);
  const [scrollMarkers, setScrollMarkers] = useState<Array<HTMLElement>>([]);
  const [selected, setSelected] = useState(0);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = contentRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
      dispatch({ type: 'loadingLogs' });
      JobResultsApi.getLogs(jobResultId).then((logs) => {
        dispatch({ type: 'logsFetched', value: logs.split('\n') });
      }).catch(err => {
        dispatch({ type: 'fetchError', value: t("logFetchError") });
      });
    }
  }, [open, jobResultId]);

  const handleClose = () => {
    onClose && onClose();
  }

  const downloadLogs = () => {
    const element = document.createElement("a");
    const file = new Blob([state.logs.join('\n')], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `${jobName}-logs.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element);
  }
  useLayoutEffect(() => {
    const marker = scrollMarkers[selected];
    if (!marker)
      return;
    Array.from(document.getElementsByClassName(classes.currentMarker)).forEach(m => m.classList.remove(classes.currentMarker));
    marker.classList.add(classes.currentMarker);
    contentRef.current?.scrollTo(0, marker.offsetTop);


  }, [selected])
  useLayoutEffect(() => {
    var markArr = Array.from(document.getElementsByClassName("_h")) as Array<HTMLElement>;
    markArr.sort((m1, m2) => m1.offsetTop - m2.offsetTop);
    setScrollMarkers(markArr);

    const firstMark = markArr.find((m, index) => {
      if (m.offsetTop > contentRef.current?.scrollTop) {
        setSelected(index);
        return true;
      }
    });

  }, [filter])

  const moveToNext=(direction: 'forward'|'backward' = 'forward')=> {
    if(direction === 'forward')
      setSelected((selected + 1) % scrollMarkers.length);
    else
      setSelected(((selected - 1)  + scrollMarkers.length)%scrollMarkers.length);
  }
  const handleLogFilterChange = debounce((val) => setFilter(val), 600);

  return (
    <div>
      <Dialog
        open={open}
        classes={{ paper: classes.root }}
        onClose={handleClose}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant="h6">{jobName}-{t('title')}</Typography>
            <SearchTextField
              style={{ marginLeft: '2%', maxWidth: 200 }}
              data-test="log-filter-input"
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  moveToNext();
                }
              }}
              onChange={event => handleLogFilterChange(event.target.value)}
              placeholder="Search the Log"
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <IconButton color="primary"  component="span" onClick={e => moveToNext()}>
              <KeyboardArrowDownIcon />
            </IconButton>
            <IconButton color="primary" component="span" onClick={e => moveToNext('backward')}>
              <KeyboardArrowUpIcon />
            </IconButton>
            <Typography>Result {scrollMarkers.length ? selected + 1 : 0} of {scrollMarkers.length}</Typography>
          </div>
        </DialogTitle>
        <DialogContent ref={contentRef} style={{ position: 'relative' }} dividers={true}>

          {state.isLoading && <LinearProgress />}
          <div className={classes.wrapper}>
            {state.logsLoaded && state.error || state.filteredLogs.map((l, i) => <p key={i} style={{ margin: '0px' }}>
              <Highlighted text={l} highlight={filter}></Highlighted>
            </p>)}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('cancel')}
          </Button>
          <Button disabled={!state.logsLoaded || !!state.error} onClick={downloadLogs} color="primary">
            {t('download')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LogViewer;
