import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";
// import { TableRow, TableCell } from "semantic-ui-react";
import { makeStyles, CircularProgress, Divider, TableRow, TableCell } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { LastJobRun } from "../../../app/models/lastjobRun.model";
import { convertSecondsToTime } from "../../../app/common/util/util";
import JobCategoryIcon from "../../../app/common/jobCategoryIcon";
import { RootStoreContext } from "../../../app/stores/root.store";
import CheckIcon from '@material-ui/icons/CheckCircle';
import CrossIcon from '@material-ui/icons/CancelRounded';

import moment from "moment";
import { useStatusColor } from "../../../app/common/util/useStatusColor.hook";



const ActivityItem = (props: LastJobRun) => {
  const [t] = useTranslation("dashboard");
  const {jobRunStore} = useContext(RootStoreContext);


  useEffect(() => {
    //callMatomo("ActivityItem","/dashboard");
  }, []);


  const useStyles = makeStyles({
    text: {
      paddingLeft: "2%",
      paddingRight: "2%"
    },
    blueText: {
      paddingLeft: "2%",
      width:"40%"
    },
    tableRow: {
      color: "#4d5f75",
      '& > td': {
         paddingTop: 10,
         paddingBottom: 10,
      },
    },
    tabledivider: {
      backgroundColor: "#eaeef4"
    },
    deviceCellIcon: {
      marginRight: "5px",
      fontSize: "13px",
  }
  });
  const classes = useStyles();

  const link = "/jobs?jobId=" + props.jobId + "&showReport=true";

  const shortname = (
    <TableCell  align="left" className={classes.blueText}>
      <Link id={`dashboard_job_table_${props.jobName}`} to={link}>{jobRunStore.isJobRunning(props.jobId) ? <CircularProgress  size={12} style={{color: '#2892d7', marginRight: 2}}/>:  <JobCategoryIcon status={props.status|| props.score} variant="small" full={true} style={{ height: "12px", marginRight: "5px" }} />} {props.jobName}</Link>
    </TableCell>
  );

  const longName = (
      <TableCell align="left" className={classes.blueText} title={props.jobName}>
        <Link id={`dashboard_job_table_${props.jobName}`} to={link}>{jobRunStore.isJobRunning(props.jobId) ? <CircularProgress  size={12} style={{color: '#2892d7', marginRight: 2}}/>:  <JobCategoryIcon status={props.status || props.score} variant="small" full={true} style={{ height: "12px", marginRight: "5px" }}/> } {props.jobName.substring(0, 18) + ".."}</Link>
      </TableCell>
  );

  const okIconColor = useStatusColor("passed", props.serversInStatusOK);
  const failedIconColor = useStatusColor("failed", props.serversInStatusBAD);


  return (
    <React.Fragment>
      <TableRow data-test="component-ActivityItem" classes={{root: classes.tableRow}}  >
        {props.jobName.length > 18 ? longName : shortname}
        <TableCell align="left" style={{paddingLeft: "2%"}}>
          {props.score}%
        </TableCell>
        <TableCell align="left" style={{width: "15%"}}>
          {convertSecondsToTime(props.rta)}
        </TableCell>
        <TableCell align="left" style={{paddingRight: "2%"}}>
          <CheckIcon className={classes.deviceCellIcon} style={{ color: okIconColor }} />

          <span>{props.serversInStatusOK}</span> 
          <span>{" "}</span>
          <CrossIcon className={classes.deviceCellIcon} style={{ color: failedIconColor, marginLeft: "8px" }} /> 
          <span>{props.serversInStatusBAD}</span>
        </TableCell>
        <TableCell align="left" style={{ paddingRight: "2%"}}>
          {props.runDate
            ? moment(props.runDate).format("DD.MM.YY")
            : " "}
        </TableCell>
      </TableRow>
      {/* <TableRow>
        <TableCell colSpan={5}><Divider className={classes.tabledivider} variant="middle" /></TableCell>
      </TableRow> */}
    </React.Fragment>
  );
};

export default observer(ActivityItem);
