import { observer } from 'mobx-react-lite';
import { Button, createStyles, makeStyles, Menu, MenuItem, Typography, Link } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import InfoIcon from '@material-ui/icons/Info';
import HelpIcon from '@material-ui/icons/Help';
import VideoIcon from '@material-ui/icons/OndemandVideo';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';
import { RootStoreContext } from '../../../app/stores/root.store';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import version_image_tiny from "../../../assets/help/version.svg";
import version_image_small from "../../../assets/help/version.png";
import version_image_medium from "../../../assets/help/version@2x.png";
import version_image_large from "../../../assets/help/version@3x.png";
import guide_image_tiny from "../../../assets/help/guide.svg";
import guide_image_small from "../../../assets/help/guide.png";
import guide_image_medium from "../../../assets/help/guide@2x.png";
import guide_image_large from "../../../assets/help/guide@3x.png";
import GuideIcon from '../../Icons/guideIcon';
import DemoIcon from '../../Icons/demoIcon';
import StartIcon from '../../Icons/startIcon';
import FormSupportComponent from '../../help/FormSupport.component';

const HelpMenuComponent: React.FC = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [t] = useTranslation("help");
    const versionNum = t("versionNum") + " 4.11.0";
    const rootStore = useContext(RootStoreContext); 
    const { setHelpOpen} = rootStore.userStore;
    const [openSupportForm, setOpenSupportForm] = useState(false);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const useStyles = makeStyles(() =>
    createStyles({
       Version_Menu_Image: {
         width: "12px",
         height: "12px",
         objectFit: "contain",
         marginRight: "1em"
       },
       offsetMenu:{
        top:"50px !important"
        },
       Guide_Menu_Button: {
         width: "12.6px",
         height: "14px",
         objectFit: "contain",
         marginRight: "1em"
       },
 }));

 const classes = useStyles();


return (
       <div>
           <Button
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={handleClick}
                >
                    <HelpOutlineIcon fontSize="large" />
                </Button>
                <Menu
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    keepMounted
                    classes={{paper:classes.offsetMenu}}
                >
                    <MenuItem>
                        <InfoIcon style={{paddingRight:'5px',fill:'rgb(167, 167, 167)'}}></InfoIcon>
                        <Typography variant="subtitle2">{ versionNum}</Typography>
                    </MenuItem> 
                    <MenuItem onClick={()=>window.open("https://support.ensuredr.com/733565-First-health-only-job")}>
                        <StartIcon style={{paddingRight:'5px',fill:'rgb(167, 167, 167)'}}></StartIcon>
                        <Typography variant="subtitle2">{ t("gettingStarted")}</Typography>
                    </MenuItem>
                    <MenuItem onClick={()=>window.open("https://support.ensuredr.com/284479-Quick-startup-guides")}>
                        <GuideIcon style={{paddingRight:'5px',fill:'rgb(167, 167, 167)'}}></GuideIcon>
                        <Typography variant="subtitle2">{ t("startupGuide")}</Typography>
                    </MenuItem>
                    <MenuItem onClick={()=> window.open("https://support.ensuredr.com/673730-Technical-Videos","_blank")} >
                        <VideoIcon style={{paddingRight:'5px',fill:'rgb(167, 167, 167)'}}></VideoIcon>
                        <Typography variant="subtitle2">{t("technicalvideos")}</Typography>
                    </MenuItem>
                    <MenuItem onClick={()=> window.open("https://support.ensuredr.com/458729-Technical-articles","_blank")} >
                        <DescriptionIcon style={{paddingRight:'5px',fill:'rgb(167, 167, 167)'}}></DescriptionIcon>
                        <Typography variant="subtitle2">{t("technicalarticals")}</Typography>
                    </MenuItem>
                    <MenuItem onClick={()=> window.open("https://support.ensuredr.com/099546-Full-user-guide","_blank")} >
                        <GuideIcon style={{paddingRight:'5px',fill:'rgb(167, 167, 167)'}}></GuideIcon>
                        <Typography variant="subtitle2">{t("userguide")}</Typography>
                    </MenuItem>
                    <MenuItem onClick={()=>setOpenSupportForm(true)}>
                         <HelpIcon style={{paddingRight:'5px',fill:'rgb(167, 167, 167)'}}></HelpIcon>
                        <Typography variant="subtitle2">{t("openSupportForm")}</Typography>
                     </MenuItem>
                </Menu>
                <FormSupportComponent open={openSupportForm} onClose={()=>setOpenSupportForm(false)}/>
       </div>
);
}

export default (observer(HelpMenuComponent));