import React, { ReactNode } from 'react';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, makeStyles } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/KeyboardArrowRight';

const useStyle= makeStyles({
    root: {
        boxShadow: "none",
        padding: 0
    },
    expansionPanelRoot: {
        display: "flex",
        flexDirection: "column",
        padding: 0,
        paddingLeft: 24
    }
})

type ReportInfoProps = {
    title: ReactNode;
    expanded?:boolean;
}

const ReportInfoTreeComponent: React.FC<ReportInfoProps> = (props) => {
    const classes = useStyle();
    return <ExpansionPanel classes={{root:classes.root}} defaultExpanded={props.expanded ? props.expanded:false}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
            {props.title}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{root: classes.expansionPanelRoot}}>
            {props.children}
        </ExpansionPanelDetails>
    </ExpansionPanel>
}

export default ReportInfoTreeComponent;