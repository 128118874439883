import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext} from '../../app/stores/root.store';
import { makeStyles, withStyles, Switch, Typography, Paper, Divider, FormControl } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { EDRTextField } from '../../app/common/EDRTextField';
import { MonitoringSettingSchema } from './validation';

const SettingsAdminComponent: React.FC<{onChange: (val) => void}> = (props) => {
    const rootStore = useContext(RootStoreContext); 
    const { updateMonitoringObj, monitoringSettings,settingsLoaded} = rootStore.settingStore;
    const [t] = useTranslation("settings");
    const [disableNotificationsstate, setdisableNotificationsstate] = useState(false);
    const [bypassMonitoringstate, setbypassMonitoringstate] = useState(false);
    let initialValuesList = {monitoringinterval:0,cpuPeakPercentageHighThreshold:0,cpuPeakPercentageThreshold:0,lowDiskSpaceThresholdInGB:0,lowMemoryThresholdInMB:0};
    
      const form = useFormik({
        initialValues: initialValuesList,
        validationSchema:MonitoringSettingSchema,
        validateOnMount: false,
        onSubmit: () => { },
      })

    const populateFields = () => {
        if (monitoringSettings!=null){ 
            Object.keys(monitoringSettings).forEach(key => {
                switch(monitoringSettings[key].settingKey.toLowerCase()){
                    case "lowdiskspacethresholdingb":
                        initialValuesList.lowDiskSpaceThresholdInGB = isNaN(monitoringSettings[key].settingValue)? 5 :parseInt(monitoringSettings[key].settingValue);
                        break;
                    case "lowmemorythresholdinmb":
                        initialValuesList.lowMemoryThresholdInMB = isNaN(monitoringSettings[key].settingValue)? 500 :parseInt(monitoringSettings[key].settingValue);
                        break;
                    case "monitoringinterval":
                        initialValuesList.monitoringinterval = isNaN(monitoringSettings[key].settingValue)? 5 :parseInt(monitoringSettings[key].settingValue);
                        break;
                    case "bypassmonitoring":
                        let bypassMode = (monitoringSettings[key].settingValue=== 'true'); 
                        setbypassMonitoringstate(bypassMode);                        
                        break;
                    case "cpupeakpercentagehighthreshold":
                        initialValuesList.cpuPeakPercentageHighThreshold = isNaN(monitoringSettings[key].settingValue)? 90 :parseInt(monitoringSettings[key].settingValue);            
                        break;
                    case "cpupeakpercentagethreshold":
                        initialValuesList.cpuPeakPercentageThreshold = isNaN(monitoringSettings[key].settingValue)? 50 :parseInt(monitoringSettings[key].settingValue);            
                        break;
                    case "disablenotifications":
                        let notificationsMode = (monitoringSettings[key].settingValue=== 'true');  
                        setdisableNotificationsstate(notificationsMode);              
                        break;
                    default:
                        break;
                }
            })    
         }
         form.setValues(initialValuesList, true);
     }

    
    useEffect(() => {
        if(form.dirty) {
            updateMonitoringObj(JSON.stringify(form.values.monitoringinterval), "MonitoringInterval", "Monitoring")
            updateMonitoringObj(JSON.stringify(form.values.cpuPeakPercentageHighThreshold), "CPUPeakPercentageHighThreshold", "Monitoring")
            updateMonitoringObj(JSON.stringify(form.values.cpuPeakPercentageThreshold), "CPUPeakPercentageThreshold", "Monitoring")
            updateMonitoringObj(JSON.stringify(form.values.lowDiskSpaceThresholdInGB), "LowDiskSpaceThresholdInGB", "Monitoring")
            updateMonitoringObj(JSON.stringify(form.values.lowMemoryThresholdInMB), "LowMemoryThresholdInMB", "Monitoring")
        }
        props.onChange && props.onChange(form.values);
    },[form.values])

   
    useEffect(() => {
        if (settingsLoaded) 
            populateFields()
    }, [settingsLoaded]); 

    const useStyles = makeStyles({
        dashboardDetails: {
            display: "flex",
            flexDirection: "column",
            color: "#4d5f75",
            '& > div': {
              padding: 5,
              display: "flex",
              justifyContent: "space-between",
              marginRight: 20,
              marginLeft: 10
            }
        },
        label: {
            textTransform: 'capitalize',
        },
        formControl: {
            width: '41%',
            '& label': {
                background: "white",
                width: "auto",
                padding: "0 4px 0 4px",
                fontSize: '17px'
            }
        }
    })

    const BlueSwitch = withStyles({
        switchBase: {
          color: blue[300],
          "&$checked": {
            color: blue[500],
          },
          "&$checked + $track": {
            backgroundColor: blue[500],
          },
        },
        checked: {},
        track: {},
      })(Switch);
     
    const classes = useStyles();

    return (
        <div data-test='settings-dashboard-component'>
            <Paper>
                <div className={classes.dashboardDetails}>
                    <div style={{marginBottom: "1em", marginTop: "1em"}}>
                        <Typography style={{paddingTop:"10px"}} classes={{ root: "settingsText"}}>{t("disableNotifications")}</Typography>
                        <BlueSwitch
                            checked={disableNotificationsstate}
                            onChange={(event) => {setdisableNotificationsstate(event.target.checked);updateMonitoringObj(JSON.stringify(event.target.checked),"DisableNotifications","Adminisration")}}
                            color="default"
                            name="autoPlayCheck"
                        />
                    </div>
                    <div style={{marginBottom: "1em", marginTop: "1em"}}>
                        <Typography style={{paddingTop:"10px"}} classes={{ root: "settingsText"}}>{t("bypassMonitoring")}</Typography>
                        <BlueSwitch
                            checked={bypassMonitoringstate}
                            onChange={(event) => {setbypassMonitoringstate(event.target.checked);updateMonitoringObj(JSON.stringify(event.target.checked),"BypassMonitoring","Adminisration")}}
                            color="default"
                            name="autoPlayCheck"
                        />
                    </div>
                    <Divider />
                    <div>                
                        <Typography style={{paddingTop:"20px"}} classes={{ root: "settingsText"}}>{t("monitoringInterval")}</Typography>
                        

                        <FormControl className={classes.formControl} variant='outlined'>
                        <EDRTextField
                            name="monitoringinterval"
                            label={t("monitoringIntervalLabel")}
                            placeholder="Type Here"
                            type="number"
                            value={form.values.monitoringinterval}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={Boolean( form.errors.monitoringinterval)}
                            helperText={ t(form.errors.monitoringinterval)}
                            margin="normal"
                            size= "small"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            fullWidth
                            variant="outlined">
                        </EDRTextField>
                        </FormControl> 
                    </div>
                    <div>                
                        <Typography style={{paddingTop:"20px"}} classes={{ root: "settingsText"}}>{t("CPUPeakPercentageThreshold")}</Typography>
                        <FormControl className={classes.formControl} variant='outlined'>
                        <EDRTextField
                            name="cpuPeakPercentageThreshold"
                            label={t("CPUPeakPercentageThresholdLabel")}
                            placeholder="Type Here"
                            type="number"
                            value={form.values.cpuPeakPercentageThreshold}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={Boolean( form.errors.cpuPeakPercentageThreshold)}
                            helperText={ t(form.errors.cpuPeakPercentageThreshold)}
                            margin="normal"
                            size= "small"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            fullWidth
                            variant="outlined">
                        </EDRTextField>
                        </FormControl> 
                    </div>
                    <div>                
                        <Typography style={{paddingTop:"20px"}} classes={{ root: "settingsText"}}>{t("CPUPeakPercentageHighThreshold")}</Typography>
                        <FormControl className={classes.formControl} variant='outlined'>
                        <EDRTextField
                            name="cpuPeakPercentageHighThreshold"
                            label={t("CPUPeakPercentageHighThresholdLabel")}
                            placeholder="Type Here"
                            type="number"
                            value={form.values.cpuPeakPercentageHighThreshold}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={Boolean( form.errors.cpuPeakPercentageHighThreshold)}
                            helperText={ t(form.errors.cpuPeakPercentageHighThreshold)}
                            margin="normal"
                            size= "small"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            fullWidth
                            variant="outlined">
                        </EDRTextField>
                        </FormControl> 
                    </div>
                    <div>                
                        <Typography style={{paddingTop:"20px"}} classes={{ root: "settingsText"}}>{t("lowDiskSpaceThresholdInGB")}</Typography>
                        <FormControl className={classes.formControl} variant='outlined'>
                        <EDRTextField
                            name="lowDiskSpaceThresholdInGB"
                            label={t("lowDiskSpaceThresholdInGBLabel")}
                            placeholder="Type Here"
                            type="number"
                            value={form.values.lowDiskSpaceThresholdInGB}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={Boolean( form.errors.lowDiskSpaceThresholdInGB)}
                            helperText={ t(form.errors.lowDiskSpaceThresholdInGB)}
                            margin="normal"
                            size= "small"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            fullWidth
                            variant="outlined">
                        </EDRTextField>
                        </FormControl> 
                    </div>
                    <div>                
                        <Typography style={{paddingTop:"20px"}} classes={{ root: "settingsText"}}>{t("lowMemoryThresholdInMB")}</Typography>
                        <FormControl className={classes.formControl} variant='outlined'>
                        <EDRTextField
                            name="lowMemoryThresholdInMB"
                            label={t("lowMemoryThresholdInMBLabel")}
                            placeholder="Type Here"
                            type="number"
                            value={form.values.lowMemoryThresholdInMB}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={Boolean( form.errors.lowMemoryThresholdInMB)}
                            helperText={ t(form.errors.lowMemoryThresholdInMB)}
                            margin="normal"
                            size= "small"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            fullWidth
                            variant="outlined">
                        </EDRTextField>
                        </FormControl> 
                    </div>
                </div>
            </Paper>
        </div>
    );
}

export default (observer(SettingsAdminComponent));