import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/root.store';
import { makeStyles, withStyles, Switch, Typography, TextField, InputAdornment, IconButton, OutlinedInput, Button, FormControl } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useFormik } from 'formik';
import { EDRTextField } from '../../app/common/EDRTextField';
import { EmailSettingSchema } from './validation';

interface ComponentProps {
    onChange: (val) => void;
    emailType: string;
    }

const EmailSMTPSettingsComponent: React.FC<ComponentProps> = (props) => {
    const emailType = props.emailType;
    const rootStore = useContext(RootStoreContext); 
    const { updateEmailObj, emailSettings, settingsLoaded } = rootStore.settingStore;
    const [t] = useTranslation("settings");
    const [allowanonymousState,setallowanonymousState] = useState(true);
    const [smtpUserstate, setsmtpUserstate] = useState(""); 
    const [smtpPasswordstate, setsmtpPasswordstate] = useState(""); 
    const [initialPasswordstate, setInitialPasswordstate] = useState(""); 
    const [passwordVisibility,setPasswordVisibility] = useState(false);
    
    const populateFields = () => {
      
        if (emailSettings!=null){ 
            let initialValuesList = {emailto:"",emailfrom: "",smtpaddress: "",smtpport: 0,emailtype: props.emailType};
            const emailSettingItems= emailSettings.filter(s=> s.settingSection=="Email");
            Object.keys(emailSettingItems).forEach(key => {
                switch(emailSettings[key].settingKey.toLowerCase()){
                    case "emailto": 
                        initialValuesList.emailto = emailSettings[key].settingValue;
                        break;
                    case "emailfrom": 
                        initialValuesList.emailfrom = emailSettings[key].settingValue;
                        break;
                    case "smtptype":
                        initialValuesList.emailtype = emailSettings[key].settingValue; 
                        if (initialValuesList.emailtype==="system")
                           setallowanonymousState(false);                       
                        break;
                    case "localsmtpaddress":
                        initialValuesList.smtpaddress = (emailSettings[key].settingValue);  
                        break;
                    case "localsmtpport":
                        initialValuesList.smtpport = isNaN((emailSettings[key].settingValue))? 25:parseInt(emailSettings[key].settingValue) ;  
                        break;
                    case "smtpuser":
                        let smtpUser = (emailSettings[key].settingValue);  
                        setsmtpUserstate(smtpUser);              
                        break;
                    case "smtppassword":
                        let smtpPassword = (emailSettings[key].settingValue);  
                        setsmtpPasswordstate(smtpPassword);  
                        setInitialPasswordstate(smtpPassword);            
                        break;
                    case "allowanonymous":
                        let displayauth = (emailSettings[key].settingValue==='true');  
                        setallowanonymousState(displayauth);              
                        break;
                    default:
                        break;
                }
            })    
            form.setValues(initialValuesList, true);
         }
        
     }

     
    useEffect(() => {
        if (settingsLoaded) 
            populateFields()
    }, [settingsLoaded]); 

     const form = useFormik({
        initialValues: {emailto:"",emailfrom: "",smtpaddress: "",smtpport: 0,emailtype: props.emailType},
        validationSchema:EmailSettingSchema,
        validateOnMount: false,
        onSubmit: () => { },
      })

      useEffect(()=>{
          form.setFieldValue("emailtype", props.emailType, true);
      },[props.emailType])

      useEffect(() => {
        if(form.dirty) {
            updateEmailObj(form.values.emailto, "EmailTo", "Email")
            updateEmailObj(form.values.emailfrom, "EmailFrom", "Email")
            updateEmailObj(form.values.smtpaddress, "LocalSMTPAddress", "Email")
            updateEmailObj(JSON.stringify(form.values.smtpport), "LocalSMTPPort", "Email")
        }
        props.onChange && props.onChange(form.values);
        console.log(form.errors)
        rootStore.settingStore.setEmailListValid(form.values.emailto != "" && form.isValid);
    },[form.values, form.isValid])


    const useStyles = makeStyles({
        emailDetails: {
            display: "flex",
            flexDirection: "column",
            color: "#4d5f75",
            '& > div': {
              padding: 5,
              display: "flex",
              justifyContent: "space-between",
              marginLeft:"10px"
            }
        },
        dropdown: {
            '& .MuiOutlinedInput-input': {
                paddingTop: '7px !important',
                paddingBottom: '7px !important',
                paddingLeft: '15px !important'
            }
        },
        label: {
            textTransform: 'capitalize',
          },
        disabledButton: {
            width: "150px",
            height: "32px",
            backgroundColor: "#c7cfdb !important",
            color: "white !important",
            borderRadius: "28px !important",
        },
        formControl: {
            '& label': {
                background: "white",
                width: "auto",
                padding: "0 4px 0 4px",
                fontSize: '17px'
            }
        }
    })
    const BlueSwitch = withStyles({
        switchBase: {
          color: blue[300],
          "&$checked": {
            color: blue[500],
          },
          "&$checked + $track": {
            backgroundColor: blue[500],
          },
        },
        checked: {},
        track: {},
      })(Switch);
     
    const classes = useStyles();

    const handleClickShowPassword = () => {
        setPasswordVisibility(passwordVisibility? false: true);
      };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSMTPClick = (event) => {
        setallowanonymousState(event.target.checked);
        let allow:boolean = true;
        if (event.target.checked)
            allow = false;
        updateEmailObj(JSON.stringify(allow),"AllowAnonymous","Email");
    }

    
    const smtpAuthContent = (
        <React.Fragment>
                <div>
                        <span style={{paddingTop:"0.5em"}}><Typography classes={{ root: "settingsText"}}>*{t("smtpCredentials")}</Typography></span>
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <span><TextField id="SMTPUser" 
                                autoComplete="new-password"
                                value={smtpUserstate} 
                                style={{width:'144px', height: '32px' , borderRadius: '4px', backgroundColor: '#ffffff' , marginTop:'1em', marginBottom: '1em'}}
                                variant="outlined"
                                size="small"
                                label={t("username")}
                                onChange={(event) => {setsmtpUserstate(event.target.value);updateEmailObj(event.target.value,"SMTPUser","Email")}} 
                                /> 
                            </span>
                            <span  style={{marginLeft: '1em'}}>
                                <OutlinedInput id="SMTPPassword" 
                                autoComplete="new-password"
                                type={passwordVisibility ? 'text' : 'password'}
                                value={smtpPasswordstate}
                                style={{width:'144px', height: '36px' , borderRadius: '4px', backgroundColor: '#ffffff' , marginTop:'1em', marginBottom: '1em'}}
                                label="Password"
                                onChange={(event) => {setsmtpPasswordstate(event.target.value);setInitialPasswordstate("");updateEmailObj(event.target.value,"SMTPPassword","Email")}} 
                                endAdornment={ initialPasswordstate!=""? "" : 
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        >
                                        {passwordVisibility ?  <VisibilityOff />: <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                />
                            </span> 
                        </div>  
                    </div> 
            </React.Fragment> 
        )

    const smptContent  = (
            <React.Fragment>
                        <div>                
                            <Typography style={{paddingTop:"1em"}} classes={{ root: "settingsText"}}>*{t("emailFrom")}</Typography>
                            <FormControl className={classes.formControl} variant='outlined'>
                            <EDRTextField
                                name="emailfrom"
                                label={t("emailAddress")}
                                style={{width:'302px', height: '32px' , borderRadius: '4px', backgroundColor: '#ffffff' , marginTop:'1em', marginBottom: '1em'}}
                                placeholder="Type Here"
                                type="string"
                                value={form.values.emailfrom}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                error={form.touched.emailfrom && Boolean( form.errors.emailfrom)}
                                helperText={form.touched.emailfrom && t(form.errors.emailfrom)}
                                margin="normal"
                                size= "small"
                                InputLabelProps={{
                                shrink: true,
                                }}
                                fullWidth
                                variant="outlined">
                            </EDRTextField>
                            </FormControl>
                        </div>
                        <div>
                            <span><Typography classes={{ root: "settingsText"}}>*{t("smtpAddress")}</Typography></span>
                            <div>
                                <span >
                                <FormControl className={classes.formControl} variant='outlined'>
                                <EDRTextField
                                name="smtpaddress"
                                label={t("address")}
                                style={{width:'204px', height: '32px' , borderRadius: '4px', backgroundColor: '#ffffff' , marginTop:'1em', marginBottom: '1em'}}
                                placeholder="Type Here"
                                type="string"
                                value={form.values.smtpaddress}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                error={form.touched.smtpaddress && Boolean( form.errors.smtpaddress)}
                                helperText={form.touched.smtpaddress && t(form.errors.smtpaddress)}
                                margin="normal"
                                size= "small"
                                InputLabelProps={{
                                shrink: true,
                                }}
                                fullWidth
                                variant="outlined">
                            </EDRTextField>
                            </FormControl>
                                </span>
                                <span  style={{marginLeft: '1em'}}>
                                <FormControl className={classes.formControl} variant='outlined'>
                                <EDRTextField
                                    name="smtpport"
                                    label={t("port")}
                                    style={{width:'84px', height: '32px' , borderRadius: '4px', backgroundColor: '#ffffff' , marginTop:'1em', marginBottom: '1em'}}
                                    placeholder="Type Here"
                                    type="number"
                                    value={form.values.smtpport}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    error={form.touched.smtpport && Boolean( form.errors.smtpport)}
                                    helperText={form.touched.smtpport && t(form.errors.smtpport)}
                                    margin="normal"
                                    size= "small"
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    fullWidth
                                    variant="outlined">
                                </EDRTextField>
                                </FormControl>
                                </span> 
                            </div>
                        </div>
                        {
                            emailType==="organization"  ?
                            <React.Fragment>
                            <div>
                                <Typography classes={{ root: "settingsText"}}>{t("displayAuthSMTP")}</Typography>
                                <BlueSwitch
                                    checked={allowanonymousState}
                                    onChange={(event) => {handleSMTPClick(event)}}
                                    color="default"
                                    name="displayAuth"
                                />
                            </div>
                            </React.Fragment>
                            : ""
                        }
                        
                        {
                            emailType==="organization" && allowanonymousState ?
                            smtpAuthContent
                            : ""
                        }
                </React.Fragment>
    )
   
    return (
        <div className={classes.emailDetails} data-test='email-settings-component'>
                    <div>                
                        <Typography style={{paddingTop:"1em"}} classes={{ root: "settingsText"}}>*{t("emailTo")}</Typography>
                        <FormControl className={classes.formControl} variant='outlined'>
                        <EDRTextField
                            name="emailto"
                            style={{width:'302px', height: '32px' , borderRadius: '4px', backgroundColor: '#ffffff' , marginTop:'1em', marginBottom: '1em'}}
                            label={t("emailAddress")}
                            placeholder="Type Here"
                            type="string"
                            value={form.values.emailto}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={form.touched.emailto && Boolean( form.errors.emailto)}
                            helperText={form.touched.emailto &&  t(form.errors.emailto)}
                            margin="normal"
                            size= "small"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            fullWidth
                            variant="outlined">
                        </EDRTextField>
                        </FormControl>
                    </div>
                    {  emailType==="organization"  ? 
                          smptContent
                        : ""
                    }
                </div>
    );
}

export default (observer(EmailSMTPSettingsComponent));