
import { Button, Dialog, DialogActions, DialogContent, makeStyles } from '@material-ui/core';
import { type } from 'os';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { JobDevice } from '../models/jobDevice/jobDevice.model';
import { EDRDialogTitle } from './EDRDialogTitle';
import { SaveButton } from './saveButton';
const useStyles = makeStyles(theme => ({
    root: {
        minWidth: '30vw'
    },
    saveButton: {
        borderRadius: 20
    }
}))
export interface MessageDialogProps {
    open: boolean;
    onClose: (confirm, event?) => void;
    title?: string
}


const MessageDialog: React.FC<MessageDialogProps> = (props) => {
    const { open, onClose } = props;
    const classes = useStyles();
    const { t } = useTranslation('utils');
    const handleClose = (confirm: boolean = false, event?: any) => {
        onClose(confirm, event);
    }

    return <Dialog
        open={open}
        classes={{ paper: classes.root }}
        onClose={handleClose}
        scroll="paper"
    >
        <EDRDialogTitle >{props.title || t('Warning')}</EDRDialogTitle>
        <DialogContent dividers={true}>
            {props.children}
        </DialogContent>
        <DialogActions>
            <SaveButton onClick={() => handleClose(true)} variant="contained" color="primary" style={{ textTransform: 'none' }}>
                {t('ok')}
            </SaveButton>
        </DialogActions>
    </Dialog>
}

export default MessageDialog;