import { Grid, Typography,Select,TextField,InputAdornment ,TableCell as MuiTableCell, TableContainer, Table, TableRow, TableHead, TableBody, Checkbox, withStyles, SvgIcon, makeStyles, MenuItem, Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import { Column, Row, useExpanded, useGlobalFilter, useGroupBy, useRowSelect, useSortBy, useTable } from 'react-table';
import { JobDevice } from '../../../app/models/jobDevice/jobDevice.model';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowRight from '@material-ui/icons/ArrowRight';
import Create from '@material-ui/icons/Create';
import { TestType } from '../../../app/models/jobTest.model';
import ReorderIcon from '@material-ui/icons/Reorder';
import clsx from 'clsx';
import { ToggleButton } from '@material-ui/lab';
import EditDeviceDialog from './editDevice.component';
import { DeviceTest } from '../../../app/models/createjob/createJob.model';
import { DeviceState, DeviceType, DEVICE_TYPE_SMALL_ICON } from '../../../app/models/deviceType.enum';
import WebTestSettingDialog from './TestsSettings/webTestSetting.component';
import DatabaseTestSettingDialog from './TestsSettings/databaseTestSetting.component';
import CustomTestSettingDialog from './TestsSettings/customTestSetting.component';
import ServiceTestSettingDialog from './TestsSettings/serviceTestSetting.component';
import WebPortalTestSettingDialog from './TestsSettings/webPortalTestSetting.component';
import useClickPreventionOnDoubleClick from './useClickPreventionOnDoubleClick.hook';
import {RootStoreContext } from '../../../app/stores/root.store';
import { DataMover } from '../../../app/models/BaseSettings.enum';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import { useTranslation } from 'react-i18next';
import ArrowUp from '@material-ui/icons/ArrowDropUp';
import HelpIcon from '@material-ui/icons/Help';
import EmptySelectedDevicesComponent from './EmptyDevices.component';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AuthenticationTestSettingDialog from './TestsSettings/authenticationTestSetting.component';
import ScriptTestSettingDialog from './TestsSettings/scriptTestSetting.component';
import NetworkTestSettingDialog from './TestsSettings/NetworkTestSetting.component';
import { CustomDeviceState, DatabaseType,DevicePlatform, LicenseType } from '../../../app/models/jobProperties.model';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import OrderPlansDialog from './OrderPlans.component';
import { CustomIcon, PreScriptIcon, ServiceIcon,DatabaseIcon,NetworkIcon,WebportalIcon,PowerIcon, AutofixIcon } from '../../Icons';
import AuthenticationIcon from '../../Icons/AuthenticationIcon';
import useDCValidation from './hooks/dcValidation.hook';
import CheckCircle from '@material-ui/icons/CheckCircle';
function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

const TestIconFromType:React.FC<{type: string, className?: string}> = ({type, className}) =>{
    switch (type) {
        case 'custom': return <CustomIcon className={className} />
        case 'web': return <WebportalIcon className={className} />
        case 'power': return <PowerIcon className={className} />
        case 'service': return <ServiceIcon className={className} />
        case 'network': return <NetworkIcon className={className} />
        case 'database': return <DatabaseIcon className={className} />
        case 'autofix': return <AutofixIcon className={className}/>
        case 'authentication': return <AuthenticationIcon className={className}/>
        case 'thirdDeviceNetwork': return <RssFeedIcon className={className}/>
        case 'PreScriptRes': return <PreScriptIcon className={className}/>
        default: return <> </>
    }
}

export const CurrencyIcon = (props) => {
    return <SvgIcon {...props}>
       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12.5" viewBox="0 0 20 12.5">
    <defs>
        <clipPath id="clip-path">
            <path id="Rectangle_2255" d="M0 0H20V12.5H0z" style={{fill:'#8bc53f'}} className="cls-1"/>
        </clipPath>
    </defs>
    <g id="Group_6814" transform="translate(0 -.333)">
        <g id="Group_6810" clip-path="url(#clip-path)" transform="translate(0 .333)">
            <path id="Path_11142" d="M18.333 2.5H2.5v9.167h-.833v-10h16.666zM16.667.833H.833V10H0V0h16.667zm-5 5a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0-2.5-2.5m.125 3.737v.263h-.25v-.25a1.716 1.716 0 0 1-.748-.18l.112-.412a1.552 1.552 0 0 0 .807.136c.287-.066.346-.362.028-.5-.233-.107-.944-.2-.944-.811a.758.758 0 0 1 .746-.712v-.27h.251v.254a2.3 2.3 0 0 1 .61.105l-.09.411a1.614 1.614 0 0 0-.611-.116c-.372.022-.405.344-.145.479.428.2.985.35.985.886 0 .43-.337.659-.751.717m-8.459 3.763H20v-10H3.333zm15-3.318a2.747 2.747 0 0 0-1.512 1.652H6.512A2.75 2.75 0 0 0 5 10.015V6.652A2.747 2.747 0 0 0 6.512 5h10.309a2.752 2.752 0 0 0 1.512 1.652v3.363z" style={{fill:'#8bc53f'}} transform="translate(0 -.417)"/>
        </g>
    </g>
</svg>

    </SvgIcon>
}

const TableCell = withStyles({
    root: {
      borderBottom: "none"
    }
  })(MuiTableCell);

  const useStyles = makeStyles({
    tooltip: {
        maxWidth: 'none',
        whiteSpace:'pre-wrap'
    },
    headerCell: {
        borderBottom: '1px solid #eaeef4'
    },
    button:{
        '& .MuiIconButton-root':{
            padding:'5px'
        }
    },
    helpIcon:
    {
      color: '#1976d2',
      fontSize: "large"
    },
    centerVertical: {
        display: 'flex',
        alignItems: 'center'
    },
    searchField: {
        "& input" : {
            padding: "10px 8px"
        }
    },  
    testButton: {
          color: '#D7D7D7',
          background: 'rgba(242, 242, 242, 1)',
          padding: 1,
          height: 'auto',
          '& .MuiSvgIcon-root': {
              fontSize: '1rem'
          },
          marginRight: 2
      },
      active: {
        background: 'rgba(165, 214, 167, 1)',
        color: 'white',
        '&:hover': {
            background: 'rgba(165, 214, 167, 0.9)',
            boxShadow: '0 0 1px 1px rgba(165, 214, 167, 1)'
        }
    },
    table: {
        '& tr td:first-child': {
            width: '35%'
        },
        '& tr th:first-child': {
            width: '35%'
        },
        
        '& tr th:nth-child(2)': {
            width: '230px',
            justifyContent:'center'
        }
    },
    'groupedCell': {
        display: 'flex',
        alignItems: 'center',
        borderLeft: '1px solid #c7cfdb',
        position: 'relative',
        paddingLeft: 29
    },
    editIcon: {
        cursor: 'pointer'
    },
    tableRoot: {
        overflowY: 'scroll',
        overflowX:'scroll',
        height: '90%',
        position: 'relative',
        '& ::-webkit-scrollbar-thumb': {
            width: '0px !important',
            background: 'transparent !important'
        }
    }
  })

  const SortIconStyles: { [key: string]: CSSProperties } = {
    arrowAup: {
        fontSize: "1.2em",
        position: "relative",
        top: "2px"
    },
    arrowDown: {
        fontSize: "1.2em",
        position: "relative",
        bottom: "5px"
    }
}

export const SortIcon: React.FC<{ sortActive: boolean, sortDesc: boolean }> = ({ sortActive, sortDesc, children }) => {
    return <span style={{ display: "flex" }}>
        {children}
        <span style={{
            display: "flex",
            flex: "column",
            color: "lightgray",
            paddingLeft: "2px",
            flexDirection: "column"
        }}>
            <ArrowUp style={{ ...(sortActive && !sortDesc ? { color: "gray" } : {}), ...SortIconStyles.arrowAup }} />
            <ArrowDown style={{ ...(sortActive && sortDesc ? { color: "gray" } : {}), ...SortIconStyles.arrowDown }} />
        </span>
    </span>
}



const ClickableToggleButton = ({ onClick, onDoubleClick,device, index, testType,classesStyle, disabled=false }) => {
    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(onClick, onDoubleClick);
    const [t] = useTranslation("createJobDevices");
    const { createJobStore,jobSettingsStore } = useContext(RootStoreContext);
    const isPaaS= createJobStore.baseSettings.dataMover==  DataMover.PaaS;
    const {isFirstTrial: isFirstTrial} = jobSettingsStore;
    const TestTooltip =(testType:any)=>{
        if(isFirstTrial && createJobStore.baseSettings.dataMover != DataMover.VeeamSQL) {
            switch(testType) {
                case 'power': return isPaaS ? t("cantEnable")+"Health Test"+t("forPlatofrm") :(device.planName.includes('Custom Devices') ? t("poweronCustomTooltip"):t("poweronTooltip"));
                default: return t('trialModeTestDisabled');
                
            }
        }
        switch(testType)
        {
            case 'power': return isPaaS ? t("cantEnable")+"Health Test"+t("forPlatofrm") :(device.planName.includes('Custom Devices') ? t("poweronCustomTooltip"):t("poweronTooltip"));
            case 'network': return  isPaaS ? t("cantEnable")+"Network/Segmentation Test"+t("forPlatofrm"): "Network/Segmentation Test"+ t("NetworkTooltip");
            case 'autofix': return  isPaaS ? t("cantEnable")+"Autofix"+t("forPlatofrm"): "Autofix"+ t("testWithoutPopupTooltip");
            case 'service': return  isPaaS ? t("cantEnable")+"Application Test"+t("forPlatofrm"): "Application Test" + t("testWithPopupTooltip");
            case 'custom': return  isPaaS ? t("cantEnable")+"Script Test"+t("forPlatofrm"): "Script Test" + t("testWithPopupTooltip");
            case 'database': return  "Database Test" + t("testWithPopupTooltip");
            case 'web': return  isPaaS ? t("cantEnable")+"Web Test"+t("forPlatofrm"): "Web Test" + t("testWithPopupTooltip");
            case 'thirdDeviceNetwork':  return isPaaS ? t("cantEnable")+"Segmentation Test"+t("forPlatofrm"): "Segmentation Test" + t("testWithPopupTooltip")+ t("3rdDeviceTooltip");
            case 'PreScriptRes':  return isPaaS ? t("cantEnable")+"Device Powerup Script to run"+t("forPlatofrm"): "Device Powerup Script to run" + t("testWithPopupTooltip");
            case 'authentication':  return isPaaS ? t("cantEnable")+"Device Specific Authentication"+t("forPlatofrm"): "Device Specific Authentication" + t("testWithPopupTooltip");
        }
    }

        return(<Tooltip title={TestTooltip(testType)} arrow>
            <span>
            <ToggleButton disabled={disabled} key={index} size='small' classes={classesStyle}>
                <TestIconFromType type={testType} />
            </ToggleButton>
            </span>
        </Tooltip>);
  
   };

const SelectedDeviceTable: React.FC<{devices: Array<JobDevice>,plans:string[]
    
    onSelectionChange: (devices: Array<JobDevice>) => void, 
    onDeviceUpdate:(devices: Array<JobDevice>) =>  void}> = (props) => {
    const classes = useStyles();
    const { createJobStore,jobSettingsStore,displayStore } = useContext(RootStoreContext);
    const {dataMover}=createJobStore.baseSettings;
    const isPaaS= dataMover== DataMover.PaaS;
    const [editDevice, setEditDevice] = useState<string>(null);
    const [currentDevice, setCurrentDevice] = useState<string>(null);
    const isEdit= window.location.href.includes('editJob');
    const [groupBy, setGroupBy] = useState('planName');
    const [openCustomTestDialog, setOpenCustomTestDialog] = useState(false);
    const [openOrderPlansDialog, setopenOrderPlansDialog] = useState(false);
    const [openServiceTestDialog, setOpenServiceTestDialog] = useState(false);
    const [openNetworkTestDialog, setOpenNetworkTestDialog] = useState(false);
    const [openWebPortalTestDialog, setOpenWebPortalTestDialog] = useState(false)
    const [openDbTestDialog, setOpenDbTestDialog]= useState<boolean>(false);
    const [openInternetTestDialog, setOpenInternetTestDialog]= useState(false);
    const [openThirdDeviceTestDialog,setOpenThirdDeviceDialog]= useState(false);
    const [openPreScriptDialog,setOpenPreScriptDialog]= useState(false);
    const [openAuthenticationDialog,setAuthenticationDialog]= useState(false);
    const [t] = useTranslation("createJobDevices");
    const previousDevices = usePrevious(props.devices || []);
    const {isFirstTrial} = jobSettingsStore;
    const {dcValidations, areDCsValid} = useDCValidation();
        // Need to remove this functionality after discussion with shahar
    const openUncheckedTestspopups =(device: JobDevice,test:DeviceTest,testType: string)=>{
        if(test.selected==true)
        switch (testType) 
        {
            case 'thirdDeviceNetwork':
                {
                    if(device.NumberClick3rd== 0){
                        setOpenThirdDeviceDialog(true);
                        const update: JobDevice = {...device,NumberClick3rd:++device.NumberClick3rd} ;
                        props.onDeviceUpdate([update]);
                    }
                    break;
                } 
        }
    }

    const checkWorkflowTest =()=>{
        return (!isPaaS  && createJobStore.baseSettings.workflowSetting.serverTest) ? true:false;
    }

    const toggleTest = (rows: Row<JobDevice>[],device: JobDevice,test: DeviceTest) => {
        if(isFirstTrial) {
            return;
        }
        switch(dataMover)
        {
            case DataMover.PaaS:
                {
                    if(test.testType == 4)
                        handleToggleClick(rows,device,test);
                    break;
                }
            case DataMover.VeeamSQL:
                {
                    if(test.testType == 8 || test.testType == 3)
                        handleToggleClick(rows,device,test);
                    break;
                }
            default:
                {
                    if(checkWorkflowTest){
                        if(device.planName.includes('Custom Devices'))
                        {
                            handleToggleClick(rows,device,test);
                        }
                        else
                        {
                            if(test.testType != 0)
                                handleToggleClick(rows,device,test);
                        }
                    }
                    break;
                }
        }
    }

    const updateTest =(device: JobDevice,type:TestType)=>{
        let DeviceTest = {
            testCategory:device.tests[0].testCategory, 
            testType: type, 
            selected: true,
            customCommandToTest: type === TestType.custom ? (device.tests.find(test=>test.testType === TestType.custom).customCommandToTest!="" ? device.tests.find(test=>test.testType === TestType.custom).customCommandToTest:(device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.customCommandToTestLinux :jobSettingsStore.customCommandToTest)):'',
            customCommandExpectedResult: type === TestType.custom ? (device.tests.find(test=>test.testType === TestType.custom).customCommandExpectedResult!="" ? device.tests.find(test=>test.testType === TestType.custom).customCommandExpectedResult:(device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.customCommandExpectedResultLinux :jobSettingsStore.customCommandExpectedResult)):'', 
            customCommandToTestIsExternal: type === TestType.custom ? (device.tests.find(test=>test.testType === TestType.custom).customCommandToTestIsExternal!=false ? device.tests.find(test=>test.testType === TestType.custom).customCommandToTestIsExternal:(jobSettingsStore.defaultSettings.customCommandToTestIsExternal)):false, 
            databaseType: type === TestType.database ? (device.tests.find(test=>test.testType === TestType.database).databaseType!=DatabaseType.SQL ? device.tests.find(test=>test.testType === TestType.database).databaseType:(jobSettingsStore.defaultSettings.databaseType)):DatabaseType.SQL,
            databaseNameToTest: type === TestType.database ? (device.tests.find(test=>test.testType === TestType.database).databaseNameToTest!="" ? device.tests.find(test=>test.testType === TestType.database).databaseNameToTest:(jobSettingsStore.defaultSettings.databaseNameToTest)):'',
            databaseQueryToTest: type === TestType.database ? (device.tests.find(test=>test.testType === TestType.database).databaseQueryToTest!="" ? device.tests.find(test=>test.testType === TestType.database).databaseQueryToTest:(jobSettingsStore.defaultSettings.databaseQueryToTest)):'',
            databaseServerNameToTest: type === TestType.database ? (device.tests.find(test=>test.testType === TestType.database).databaseServerNameToTest!="" ? device.tests.find(test=>test.testType === TestType.database).databaseServerNameToTest:(jobSettingsStore.defaultSettings.databaseServerNameToTest)):'',
            databaseUsername: type === TestType.database ? (device.tests.find(test=>test.testType === TestType.database).databaseUsername!="" ? device.tests.find(test=>test.testType === TestType.database).databaseUsername:(jobSettingsStore.defaultSettings.databaseUsername)):'',
            databasePassword: type === TestType.database ? (device.tests.find(test=>test.testType === TestType.database).databasePassword!="" ? device.tests.find(test=>test.testType === TestType.database).databasePassword:(jobSettingsStore.defaultSettings.databasePassword)):'',
            databasePort: type === TestType.database ? (device.tests.find(test=>test.testType === TestType.database).databasePort!="" ? device.tests.find(test=>test.testType === TestType.database).databasePort:(jobSettingsStore.defaultSettings.databasePort)):'',
            dbWindowsAuth :  type === TestType.database ? (device.tests.find(test=>test.testType === TestType.database).dbWindowsAuth!=false ? device.tests.find(test=>test.testType === TestType.database).dbWindowsAuth:(jobSettingsStore.defaultSettings.dbWindowsAuth)):false,
            serviceToTest: type === TestType.service ? (device.tests.find(test=>test.testType === TestType.service).serviceToTest!="" ? device.tests.find(test=>test.testType === TestType.service).serviceToTest:(device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.serviceToTestLinux :jobSettingsStore.serviceToTest)):'',
            testFromEDRC: type === TestType.service ? (device.tests.find(test=>test.testType === TestType.service).testFromEDRC!=false ? device.tests.find(test=>test.testType === TestType.service).testFromEDRC:(jobSettingsStore.defaultSettings.testFromEDRC )):false,
            script: type ===  TestType.PreScriptRes ? (device.tests.find(test=>test.testType === TestType.PreScriptRes).script!="" ? device.tests.find(test=>test.testType === TestType.PreScriptRes).script:''):'',
            thirdDeviceNetworkToTest: '',
            authenticationUserToTest:type === TestType.authentication ? (device.tests.find(test=>test.testType === TestType.authentication).authenticationUserToTest!="" ? device.tests.find(test=>test.testType === TestType.authentication).authenticationUserToTest:''):'',
            authenticationPassToTest:type === TestType.authentication ? (device.tests.find(test=>test.testType === TestType.authentication).authenticationPassToTest!="" ? device.tests.find(test=>test.testType === TestType.authentication).authenticationPassToTest:''):'',
            webPortalToTest: type === TestType.web ? (device.tests.find(test=>test.testType === TestType.web).webPortalToTest!="" ? device.tests.find(test=>test.testType === TestType.web).webPortalToTest:'https://'+device.planName.includes("Custom") ? device.sourceName: device.sourceName.split('.')[0]):'',
            webPortalResult:type === TestType.web ? (device.tests.find(test=>test.testType === TestType.web).webPortalResult!="" ? device.tests.find(test=>test.testType === TestType.web).webPortalResult:device.deviceName):'',
        };
        createJobStore.updateTestDevice(type,DeviceTest,device.deviceName);
    }

    const handleToggleClick= (rows: Row<JobDevice>[],device: JobDevice,test: DeviceTest) =>{
        let emptyTest= false;
        /**
         * update the current test state
         * update device tests
         * 
         * if test is empty open dialog
         */
      
        setCurrentDevice(test.testType === TestType.web ||TestType.database ? device.sourceName:device.deviceName);
        test.selected= !test.selected;
        const update: JobDevice = {...device, tests: device.tests.map(t => t.testType === test.testType ? test: t )} ;
        props.onDeviceUpdate([update]);
        let totalSelectedTests = 0;
        createJobStore.selectedDevices.map(selectedDev=>{
            selectedDev.tests.map(test=>{
                if(test.selected === true)
                    totalSelectedTests= totalSelectedTests + 1 ;
                })
             });
        if(emptyTest)
        {
            handleDoubleToggleClick(device,TestType[test.testType]);
        }
        else if(emptyTest  === false && test.selected)
        {
            updateTest(device,test.testType);
        }
        displayStore.updateNextStepValid({selectedDevicesValid: totalSelectedTests == 0 ? false: true});
        openUncheckedTestspopups(device,test,TestType[test.testType]);
    }

    const DoubleClickEditTest=(device: JobDevice,testType: string)=>{
        if(checkWorkflowTest)
        switch(dataMover)
            {
                case DataMover.PaaS:
                    {
                    if (testType == 'database')
                            handleDoubleToggleClick(device,testType);
                        break;
                    }
                case DataMover.VeeamSQL:
                    {
                    if (testType == 'database' || testType == 'custom')
                            handleDoubleToggleClick(device,testType);
                        break;
                    }
                default:
                    {
                        handleDoubleToggleClick(device,testType);
                        break;
                    }
            }
        }

        const handleDoubleToggleClick=(device: JobDevice,testType: string)=> {
            switch (testType) {
                case 'power': return;
                case 'thirdDeviceNetwork':
                case 'network': 
                {
                    setCurrentDevice(device.deviceName);setOpenNetworkTestDialog(true);break;
                };
                case 'service': {setCurrentDevice(device.deviceName);setOpenServiceTestDialog(true);break};
                case 'custom': {setCurrentDevice(device.deviceName);setOpenCustomTestDialog(true);break};
                case 'web': {setCurrentDevice(device.sourceName);setOpenWebPortalTestDialog(true);break};
                case 'autofix': return;
                case 'database': {setCurrentDevice(device.deviceName);setOpenDbTestDialog(true);break;};
                case 'internetConnection': {setCurrentDevice(device.deviceName);setOpenInternetTestDialog(true);break};
                case 'PreScriptRes': { setCurrentDevice(device.deviceName); setOpenPreScriptDialog(true);break;}
                case 'authentication': { setCurrentDevice(device.deviceName);setAuthenticationDialog(true);break;}
                default: return;
            }
        }

    const handleEditClick =(row:any)=>{
        if(isPaaS)
        {
            let custom = createJobStore.selectedDevices.filter(device => device.deviceGroup.includes("Custom"));
            custom.length > 0 ? setEditDevice(row.values['deviceName']) : setOpenDbTestDialog(row.values['deviceName']);
        }   
        else
            setEditDevice(row.values['deviceName']);
    }

    const handleTogglePower=(deviceName:string, testType: TestType,poweron:boolean)=>{
        let device= props.devices.find(item=>item.deviceName.toLocaleLowerCase() === deviceName.toLocaleLowerCase());
        if(device && device.tests!=undefined)
        {
            let test =  device.tests.find(test=> test.testType === testType);
            if(test.testType == TestType.thirdDeviceNetwork || test.testType == TestType.network)
            {
                if(test.testType == TestType.thirdDeviceNetwork)
                {
                    test.selected= poweron;
                    let testNetwork =  device.tests.find(test=> test.testType === TestType.network);
                    testNetwork.selected =testNetwork.selected ? !poweron: false;
                    device.tests= device.tests.map(t=>t.testType !== TestType.thirdDeviceNetwork ? (t.testType === TestType.network ? testNetwork: t):test);
                }
                else
                {
                    test.selected= poweron;
                    let testThirdDevice =  device.tests.find(test=> test.testType === TestType.thirdDeviceNetwork);
                    testThirdDevice.selected = testThirdDevice.selected ? !poweron: false;
                    device.tests= device.tests.map(t=>t.testType !== TestType.network ? (t.testType === TestType.thirdDeviceNetwork ? testThirdDevice: t):test);
                }
            }
            else
            {
                test.selected= poweron;
                device.tests= device.tests.map(t=>t.testType === test.testType ? test: t);
            }
            props.onDeviceUpdate([device]);
        }
    }

    const columns: Array<Column<JobDevice>> = React.useMemo((): Array<Column<JobDevice>> => {
        const c: Array<Column<JobDevice>> = [
           
            {
                Header:  (props) => { return <span style={{display:'flex'}} >
                  {t("DeviceName")} </span>},
                accessor: 'deviceName',
                minWidth: 350
            },
            {
                Header: <span></span>,
                accessor: 'sourceName',
            },
            {
                Header: <span style={{display:'flex',justifyContent:'flex-start',width: '100%',marginLeft:'15%'}}>{t("test")}</span>,
                accessor: 'tests',
                width: 250,
                Cell: (cellProps) => {
                    if(cellProps.value) {
                        let Tests;
                        if(isPaaS)
                        Tests= cellProps.value.filter(test=>test.testType != TestType.power);
                        else
                            Tests= cellProps.value;
                        return <div style={{display:'flex',justifyContent:'flex-start',width: '100%'}}>
                            { 
                                Tests.map((test: DeviceTest, index) => {
                                if(test.testType !== TestType.autofix){
                                if(test.testType !== TestType.thirdDeviceNetwork && test.testType !== TestType.network)
                                {
                                    const active = test.selected;
                                    const className=clsx({[classes.active]: active, [classes.testButton]: true});
                                    return(<ClickableToggleButton onClick={() => toggleTest(cellProps.rows,cellProps.row.original, test)} 
                                        disabled={isFirstTrial && createJobStore.baseSettings.dataMover != DataMover.VeeamSQL && test.testType !== TestType.power}
                                        onDoubleClick={()=>DoubleClickEditTest(cellProps.row.original,TestType[test.testType])} 
                                        device={cellProps.row.original}
                                        index={index} 
                                        testType={TestType[test.testType]} 
                                        classesStyle= {{root: className}}/>)
                                }
                                else
                                {
                                    if(test.testType == TestType.network)
                                    {
                                        let otherTest =Tests.find(t=>t.testType == TestType.thirdDeviceNetwork);
                                        const active = test.selected || otherTest.selected;
                                        const className=clsx({[classes.active]: active, [classes.testButton]: true});
                                        return(<ClickableToggleButton onClick={() => toggleTest(cellProps.rows,cellProps.row.original, test.selected ? test:otherTest)} 
                                            disabled={isFirstTrial && createJobStore.baseSettings.dataMover != DataMover.VeeamSQL} 
                                            onDoubleClick={()=>DoubleClickEditTest(cellProps.row.original,TestType[test.selected ? test.testType:otherTest.testType])} 
                                            device={cellProps.row.original}
                                            index={index} 
                                            testType={TestType[TestType.network]} 
                                            classesStyle= {{root: className}}/>)
                                    }
                                }
                                }
                            })
                            }
                        </div>
                    } else {
                        return <></>
                    }
                }
            
            },
            {
                Header: (props) => { return <span style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '12%' }} >{t("os")}</span>},
                accessor: 'os',
                Cell: (cellProps) => {
                    switch(cellProps.row.values.os)
                    {
                        case DevicePlatform.Windows:
                            {
                                return <React.Fragment><img src={"/assets/images/editDeviceIcons/windowsIcon.svg"}></img></React.Fragment>
                            }
                        case DevicePlatform.UNIX:
                            {
                                return <React.Fragment><img src={"/assets/images/editDeviceIcons/linuxIcon.svg"}></img></React.Fragment>
                            }
                        default:
                            {
                                return <React.Fragment><span></span></React.Fragment>
                            }
                    }
                }
            },
            {
                Header: <span style={{display:'flex',marginLeft:'-45%'}}>{t("devicegroup")}</span>,
                accessor: 'deviceGroup',
                Cell: (cellProps) => {
                    if(cellProps.row)
                    {
                        let val = cellProps.row.values.deviceGroup!=null && createJobStore.TestGroups && createJobStore.TestGroups.groups ? createJobStore.TestGroups.groups.find(item=>item.groupOrder.toString() === cellProps.row.values.deviceGroup.toString()):{groupOrder:1,groupName:"Group1"};
                        return cellProps.row.canExpand ? "": (val ? val.groupName:("Group1")); 
                    }
                   else
                    return null;
                }
            },
            {
                Header:  (props) => { return <span style={{display:'flex',justifyContent:'center'}} >{t('Name')}</span>},
                accessor: "planName", 
                minWidth: 100
            },
            {
                Header: <span> </span>,
                accessor: 'deviceType',
            },
            {
                Header: <span> </span>,
                accessor: 'customDeviceState',
            },
            {
                Header: <span></span>,
                accessor: 'deviceState'
            },
            {
                Header: <span style={{display:'flex',justifyContent:'flex-end'}}></span>,
                
                Cell: (props) => {
                    if(!props.row.canExpand)
                    return (<div style={{display:'flex',justifyContent:'flex-end'}}>
                            {/* <CurrencyIcon style={{marginRight: 20}}></CurrencyIcon> */}
                            {dataMover != DataMover.VeeamSQL && !isFirstTrial  ?<IconButton disabled={true}><Create className={classes.editIcon} onClick={()=>handleEditClick(props.row)}/></IconButton>:null}
                        </div>);
                    else 
                        return null;
                },
                accessor: 'deviceIP',
                minWidth: 100
            },
            {
                accessor: 'planBootOrder'
            },
            {
                accessor: 'showDeviceAsDC'
            }

        ];
        return c;
    }, [])

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            maxWidth: 400,
        }),
        []
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        getToggleAllRowsSelectedProps,
        toggleGroupBy,
        toggleRowSelected,
        toggleSortBy,
        toggleAllRowsSelected,
        toggleAllRowsExpanded,
        rows,
        prepareRow,
        setGlobalFilter,
        selectedFlatRows,
        state,
    } = useTable<JobDevice>(
        {
            data:React.useMemo(() => props.devices, [props.devices]),
            columns,
            defaultColumn,
            initialState: {
                groupBy: React.useMemo(() => {
                    return [groupBy];
                }, [groupBy]),
                hiddenColumns: React.useMemo(() => createJobStore.baseSettings.dataMover == DataMover.VeeamSQL ? ['deviceName','deviceType','customDeviceState',"deviceState", 'planBootOrder','sourceName','os', 'showDeviceAsDC']:['deviceName','deviceType','customDeviceState',"deviceState", 'planBootOrder','sourceName', 'showDeviceAsDC'], []),
                expanded: props.devices.reduce((acc,d) => ({...acc, [d.id+'']: true}), {})  
            },
            autoResetExpanded: false,
            autoResetGroupBy: false,
            autoResetSelectedRows: true, 
            expandSubRows: true,
            selectSubRows: true,
            autoResetRowState: false,
            autoResetGlobalFilter:false
        },
        useGlobalFilter,
        useGroupBy,
        useSortBy,
        useExpanded,
        useRowSelect
    )
    useEffect(() => {
        if(isEdit)
        {
            toggleAllRowsExpanded(true);
            toggleAllRowsSelected(true);
        }
    },[]);

    useEffect(() => {

        if(previousDevices && props.devices) {
            const newAddedDevices = props.devices.filter(d => !previousDevices.find(p => p.id === d.id));
            if(newAddedDevices) {
                toggleAllRowsSelected(false);
            }
            rows.forEach(row => {
                if(row.original && newAddedDevices.find(d => d.id === row.original.id)) {
                  //  row.toggleRowSelected(true);
                    row.toggleRowExpanded(true);
                }
                if(row.isGrouped && newAddedDevices.find(d => d[row.groupByID] === row.groupByVal)) {
                    row.toggleRowExpanded(true);
                  //  row.toggleRowSelected(true);
                }
            })
        }

    }, [props.devices.length])

    const {globalFilter}= state;

    const loadArrowDirection=(row:any)=>{
        if(isEdit)
        return (<React.Fragment>
                    <ArrowDown/>
                </React.Fragment>);
        else
        {
            if(row.isExpanded)
            return (
                <React.Fragment>
                    <ArrowDown />
                </React.Fragment>
            ) 
            else return(
                <ArrowRight />
            )
        }
        ;
    }

    useEffect(() => {
      //  props.onSelectionChange(Object.keys(state.selectedRowIds).map(rowId => props.devices[parseInt(rowId)]))
        // let selectedDevices = selectedFlatRows.reduce((acc, row, index) => {
        //     if(row.isGrouped && row.isExpanded) {
        //         return acc;
        //     }
        //     if(row.isGrouped) {
        //         row.subRows.forEach(subRow => acc.push(subRow.original));
        //     } else {
        //         acc.push(row.original);
        //     }
        //     return acc;
            
        // }, []);
       
            
        let selectedDevices = Object.keys(state.selectedRowIds).map(id =>props.devices[id]);
        props.onSelectionChange(selectedDevices)
        
     }, [state.selectedRowIds])

     useEffect(() => {
        state.groupBy.forEach(g => {
            toggleGroupBy(g, false);
        })

        toggleGroupBy(groupBy)
    }, [groupBy, toggleGroupBy])
    

    const loadName= (row:any)=>{
        if(row!=undefined && row.values!=undefined && row.values['deviceName']!=undefined)
            return row.values['deviceName'];
        else
         return "";
    }

    const onCloseScript =(deviceName:string,changed:boolean)=>{
        setOpenPreScriptDialog(false);
        let device= props.devices.find(item=>item.deviceName.toLocaleLowerCase() === deviceName.toLocaleLowerCase());
        if(device && device.tests!=undefined)
        {
            let powerupTest = device.tests.find(test=> test.testType === TestType.PreScriptRes);
            powerupTest.selected= changed ? (powerupTest.script !="" ? true:false):false;
            props.onDeviceUpdate([device]);
        }
    }

    const closeNetworkpopup =()=>{
        setOpenNetworkTestDialog(false);
    }

    const loadTooltip=(row:Record<string,any>)=>{
            if(row!=undefined)
            {
                if( createJobStore.baseSettings.dataMover == DataMover.VeeamSQL)
                {
                    return "DB Name:"+row.values['deviceName'];
                }
                else
                {   
                    if(row.values.deviceType === DeviceType.DCVirtual || row.values.deviceType === DeviceType.DCPhysical) 
                        return `Vmname: ${row.values['deviceName']} \nHostname: ${row.values['sourceName']} \nThis VM is a Domain Controller - Use it in your job to run advanced tests`
                    if(row.values == null || row.values.deviceState == null)
                        return "VM name: Failed to fetch"+ '\n'+" Hostname:  Failed to fetch";
                    if(row.values.deviceState == DeviceState.PoweredOff)
                        return "VM name: N/A - VM Powered Off"+ '\n'+" Hostname:  N/A - VM Powered Off";
                    else if(row.values.deviceState == DeviceState.Unknown)
                        return "VM name: Failed to fetch"+ '\n'+" Hostname:  Failed to fetch";
                    else
                        return "VM name: "+row.values['deviceName']+'\n'+ "Hostname: " +row.values['sourceName'];
                }
            }
            return "VM name: Failed to fetch"+ '\n'+" Hostname:  Failed to fetch";
        }

        const handleSearch =(event:any)=>{
            if(event!=null)
            {
                setGlobalFilter(event.target.value);
            }
        }

    return( 
    <React.Fragment>
            <Grid item container direction='row' justify='space-between'>

                <Grid item style={{display:'flex',alignItems: 'center'}} direction='row'>
                    <Typography variant='h6' style={{ color: '#1f396d' }}>{t("selected")} ({props.devices.length})</Typography>
                    <Tooltip title={t('selectedInfo')} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px'}} />
                    </Tooltip>
                </Grid>

                <Tooltip title={t('orderplanIconTooltip')} arrow>
                    <IconButton component='span' disabled={true} classes={{root: classes.button}} style={{padding: isPaaS?'3px':'5px',marginLeft:'20%' }}>
                    <ReorderIcon onClick={()=>setopenOrderPlansDialog(true)}/>      
                    </IconButton>
                </Tooltip>

                <Grid item direction='row' style={{ display: 'flex', alignItems: 'center'}}>
                    <div style={{ display: 'flex', alignItems: 'center',paddingRight:'15px'}}>
                    <Select value={groupBy} disabled={props.devices.length == 0} onChange={event => setGroupBy(event.target.value as string)} disableUnderline={true} style={{height:37,width:'190px',border:'1px solid rgba(0, 0, 0, 0.12)',borderRadius:'4px',paddingLeft:'5%'}}>
                        <MenuItem value='planName'>{isPaaS ?t("rg"):t("vpg")}</MenuItem>
                        <MenuItem value='deviceGroup'>{t("testgroups")}</MenuItem>
                    </Select>
                    </div>
                <TextField
                    placeholder={t("Search")}
                    fullWidth
                    disabled={props.devices.length == 0}
                    variant="outlined"
                    style={{ width: '200px' }}
                    value={globalFilter || ''}
                    className= {classes.searchField}
                    onChange={handleSearch}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
                </Grid>
                {props.devices.length > 0 ?<>
                <TableContainer style={{paddingBottom:'5%'}} classes={{root: clsx([classes.tableRoot, 'hide-scrollbar'])}}>
        <Table aria-label="Device Selection Table" className={classes.table} style={{ tableLayout: 'fixed',marginTop:'1%' }} {...getTableProps()} >
                        <TableHead >
                            {headerGroups.map((headerGroup, headerGroupIndex) => (
                                <TableRow key={headerGroupIndex}  {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column, columnIndex) => (
                                       <TableCell className={classes.headerCell} {...column.getHeaderProps()} align='left' padding='none'>
                                             {columnIndex === 0 ? 
                                        <React.Fragment>
                                            <div className={classes.centerVertical}>
                                            <Checkbox {...getToggleAllRowsSelectedProps()} color='primary' style={{ marginRight: 5,marginTop:'-5px' }}></Checkbox>
                                            {column.render('Header')} 
                                            </div>
                                        </React.Fragment>
                                        : 
                                        <React.Fragment>
                                            {column.render('Header')}
                                        </React.Fragment>}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody {...getTableBodyProps()}>
                            {rows.map((row, rowIndex) => {
                                prepareRow(row)
                                return (
                                    <TableRow key={rowIndex}>
                                        {row.cells.map((cell, cellIndex) => {
                                            return <TableCell key={cellIndex} padding='none' {...cell.getCellProps()} style={{...(cellIndex === 0 ? {minWidth: 250}: (cellIndex ===2 || cellIndex ===3 ? {minWidth: 20}:{minWidth: 150,paddingLeft:'5%'}))}}>
                                               
                                                   <div style={{ display: 'flex', alignItems: 'center',justifyContent:cellIndex ===2 ?'flex-start':'flex-start',marginLeft:cellIndex ===2 ? '7%':cellIndex === 3 ?'-45%':null }}>
                                                    {cell.isGrouped ? <>
                                                    <span style={{ display: 'flex', alignItems: 'center',overflow:'hidden'}} {...row.getToggleRowExpandedProps()}>
                                                      {loadArrowDirection(row)}
                                                    </span> 
                                                        {cell.value && <span style={{ display: 'flex', alignItems: 'center'}}>
                                                            <Checkbox {...row.getToggleRowSelectedProps()} color='primary' style={{ marginRight: 5 }}></Checkbox>
                                                            <img style={{marginRight: 5, width: 12, height: 12, fontWeight: 800}} src={createJobStore.baseSettings.dataMover == DataMover.VeeamSQL ?DEVICE_TYPE_SMALL_ICON[DeviceType.Database]:DEVICE_TYPE_SMALL_ICON[DeviceType.PhysicalServer]}></img>
                                                            {cell.row.groupByID === 'deviceGroup' ? createJobStore.TestGroups.groups.find(item=>item.groupOrder.toString() === cell.value.toString()).groupName:cell.value}  {`(${row.subRows.length})`}
                                                        </span>
                                                        }
                                                    </> :
                                                        <>
                                                            {cell.value === row.values[groupBy] && <span className={classes.groupedCell} style={{overflow:'hidden'}}>
                                                                <Checkbox {...row.getToggleRowSelectedProps()} color='primary' style={{ marginRight: 5 }}></Checkbox>
                                                                <img style={{marginRight: 5, width: 12, height: 12, fontWeight: 800}} src={createJobStore.baseSettings.dataMover == DataMover.VeeamSQL ?DEVICE_TYPE_SMALL_ICON[DeviceType.Database]:DEVICE_TYPE_SMALL_ICON[row.values['showDeviceAsDC']? DeviceType.DCVirtual : row.values.deviceType]}></img>
                                                                <span style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow: 'ellipsis' , fontWeight:row.values['showDeviceAsDC'] || row.values['deviceType'] == DeviceType.DCVirtual || row.values['deviceType'] == DeviceType.DCPhysical? 'bold': 'normal'}}>
                                                                {
                                                                    <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                                                                      <Tooltip classes={{tooltip:classes.tooltip}} title={<span>{loadTooltip(row)}</span>}>
                                                                       <span style={{marginRight: 5}}> {row.values['deviceName']}</span>
                                                                       </Tooltip>
                                                                        {(row.values['deviceType'] === DeviceType.DCVirtual || row.values['showDeviceAsDC']) && dcValidations[row.values['sourceName']]?.isValidating && 
                                                                        <CircularProgress size={10} color='primary' variant='indeterminate'  />
                                                                        }
                                                                        {(row.values['deviceType'] === DeviceType.DCVirtual || row.values['showDeviceAsDC']) && dcValidations[row.values['sourceName']]?.isValid && 
                                                                           <Tooltip title={t("DCValidationOk")}>
                                                                                <CheckCircle style={{fontSize: '1rem', color: '#8bc53f'}} />
                                                                            </Tooltip>
                                                                        }

                                                                        {(row.values['deviceType'] === DeviceType.DCVirtual || row.values['showDeviceAsDC']) && !dcValidations[row.values['sourceName']]?.isValidating && !dcValidations[row.values['sourceName']]?.isValid && 
                                                                          <Tooltip title={t("DCValidationFailed")}>
                                                                               <img src='/assets/images/jobIcon/recoverable.svg' style={{width: 10, height:11}}  />
                                                                          </Tooltip>
                                                                        }
                                                                        </Grid>}
                                                                </span>
                                                            </span>}
                                                            { cell.value !== row.values[groupBy] && <span style={{overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{cell.render('Cell')}</span>}
                                                        </>
                                                    }
                                                </div>
                                                </TableCell>
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
        </Table>
        <OrderPlansDialog open={openOrderPlansDialog} onClose={() => setopenOrderPlansDialog(false)} planNames={props.plans}/>
        {editDevice && <EditDeviceDialog open={Boolean(editDevice)} onClose={() => setEditDevice(null)} onSave={(test,state)=>handleTogglePower(editDevice,test,state)} deviceName={editDevice}/>}
        {openInternetTestDialog && <WebTestSettingDialog open={openInternetTestDialog} onClose={() =>setOpenInternetTestDialog(false)} isDefault={false} deviceName={currentDevice}/>}
        {openDbTestDialog && <DatabaseTestSettingDialog open={openDbTestDialog} onClose={() => setOpenDbTestDialog(false)} deviceName={currentDevice} isDefault={false} onSave={()=>handleTogglePower(currentDevice,TestType.database,true)}/>}
        {openCustomTestDialog && <CustomTestSettingDialog open={openCustomTestDialog} onClose={() => setOpenCustomTestDialog(false)} deviceName={currentDevice} isDefault={false}  onSave={()=>handleTogglePower(currentDevice,TestType.custom,true)}/>}
        {openNetworkTestDialog && <NetworkTestSettingDialog  onClose={()=>closeNetworkpopup()} deviceName={currentDevice} isDefault={false}  onSave={(useThirdDevice)=>handleTogglePower(currentDevice, useThirdDevice ? TestType.thirdDeviceNetwork :TestType.network,true)}/>}
        {openServiceTestDialog && <ServiceTestSettingDialog open={openServiceTestDialog} onClose={() => setOpenServiceTestDialog(false)} deviceName={currentDevice} isDefault={false}  onSave={()=>handleTogglePower(currentDevice,TestType.service,true)}/>}
        {openWebPortalTestDialog && <WebPortalTestSettingDialog open={openWebPortalTestDialog} onChange={null} onClose={() => setOpenWebPortalTestDialog(false)} hostname={currentDevice} isDefault={false}  onSave={()=>handleTogglePower(currentDevice,TestType.web,true)}/>} 
        {openAuthenticationDialog && <AuthenticationTestSettingDialog open={openAuthenticationDialog} onClose={() =>setAuthenticationDialog(false)} deviceName={currentDevice} isDefault={false}  onSave={()=>handleTogglePower(currentDevice,TestType.authentication,true)}/>}
        {openPreScriptDialog && <ScriptTestSettingDialog open={openPreScriptDialog} onClose={(changed)=>onCloseScript(currentDevice,changed)} deviceName={currentDevice} isDefault={false}  onSave={()=>handleTogglePower(currentDevice,TestType.PreScriptRes,true)}/>}
    </TableContainer>
                </>:<EmptySelectedDevicesComponent title={t('selectDevice')} type="selected"/>}
    </React.Fragment>
    )
                
}

export default SelectedDeviceTable;
