import {Grid,makeStyles, Typography, Button, Divider, Switch, Radio, FormControlLabel, RadioGroup, Tooltip} from '@material-ui/core';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NetworkMapping } from '../../../../app/models/jobProperties.model';
import { RootStoreContext } from '../../../../app/stores/root.store';
import DRVlanPartComponent from './DRVlanPart.component';
import HelpIcon from '@material-ui/icons/Help';
import jobAgent from '../../../../app/api/jobAgent';
import { toast } from 'react-toastify';
import { CustomCheckbox } from '../../baseSettings/Advanced/BasicAdvanced.component';

export interface FragmentObj {
    item: NetworkMapping,
    index: number
}

const TestDRVlanComponent: React.FC = (open) => {

    const useStyles = makeStyles({
        helpIcon:
        {
          color: '#1976d2',
          fontSize: "large"
        },
        MainHeading:
        {
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.19',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#1f396d'
        },
        SecondHeading:{
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
        },
        divider:{
            backgroundColor: '#eaeef4',
            width:'100%',
            marginTop:'10px'
        },
        headerDivider:{
            backgroundColor: '#eaeef4',
            marginTop:'8px',
            marginLeft:'10px'
        },
        Image:{
            objectFit: 'contain',
            marginRight:'8px',
            marginBottom: '10px'
        },
        formControl: {
            width: '100%',
        },
        button: {
            borderRadius: 15,
            color: 'white',
            backgroundColor:'#2892d7',
            width: '23%',
            height: '32px',
            marginTop: '24px',
            marginLeft: '215px'
        }
        });
        
    const classes = useStyles();
    const [t] = useTranslation("createJobNetwork");
    const rootStore = useContext(RootStoreContext);
    const {jobSettingsStore} = rootStore;
    const {testDRNetwork} = rootStore.createJobStore.networkSetting;
    const [AllowInState, setAllowInState] = useState(false);
    const [AllowOutState, setAllowOutState] = useState(false);

    const[networkNames, setNetworkNames] = useState([]);
    
    const handleMappingChange= (sourceNetwork: string, targetNetwork: string, index: number) => {
        const mapping: NetworkMapping = {sourceNetwork, targetNetwork} ;
        const mappingArray = testDRNetwork.networkMapping;
        rootStore.createJobStore.updateNetworkSettings({testDRNetwork: {...testDRNetwork, networkMapping :mappingArray.map((m, i) => i=== index? mapping: m) }});
      }

    useEffect(() => {
        if(testDRNetwork.networkMapping.length === 0) {
            const mappingArray: Array<NetworkMapping> = new Array(networkNames.length).fill(null).map(v => ({sourceNetwork: null, targetNetwork: null}));
            rootStore.createJobStore.updateNetworkSettings({testDRNetwork: {...testDRNetwork, networkMapping :mappingArray }});
        }
      }, [networkNames])

    const loadDRVlanPart = () => {
        return (
            (testDRNetwork.networkMapping).map(function(item,index){
                return(
                <React.Fragment key={index}> 
                <DRVlanPartComponent settingValue={item.targetNetwork} settingKey={networkNames[index]} type={"test"} allValues={networkNames} onChange={(s, t) =>handleMappingChange(s,t,index)} />
              </React.Fragment>
                );
            })
        );
      }

      useEffect(() => {
        setNetworkNames(jobSettingsStore.vCenterNetworks);
        setTimeout(() => populateFields());
      }, []); 

    const populateFields = () => {
        if (testDRNetwork.allowInbubble!=null)
            setAllowInState(testDRNetwork.allowInbubble);
        if (testDRNetwork.allowOutbubble!=null)
            setAllowOutState(testDRNetwork.allowOutbubble);
    }

    const handleAllowInChange = (event) =>{
        setAllowInState(event.target.checked);
        rootStore.createJobStore.updateNetworkSettings({testDRNetwork: {...testDRNetwork, allowInbubble : event.target.checked}});
    };

    const handleAllowOutChange = (event) =>{
        setAllowOutState(event.target.checked);
        rootStore.createJobStore.updateNetworkSettings({testDRNetwork: {...testDRNetwork, allowOutbubble : event.target.checked}});  
    };
        
      
    return(
            <Grid container direction="column" style={{paddingRight: 16}}>
            
            <Typography variant='h6' className={classes.MainHeading}>{t('networkAdvanced')}</Typography>
            
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px' }}>
                <Typography variant='h6' className={classes.MainHeading} style={{fontSize:'14px'}}>{t('mappingNetwork')}</Typography>
                    <Tooltip title={t("mappingNetworkInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '9px' }} />
                    </Tooltip>
                <Divider className={classes.headerDivider} style={{ width: '37%' }} />
            </div>

            <Grid item direction="column">
                <div style={{display: 'flex',flexDirection:'row',marginTop:'16px',marginLeft:'25px'}}>
                    <Typography className={classes.SecondHeading} style={{color: '#1f396d'}}>{t('ManagmentNetwork')}</Typography>
                    <Typography className={classes.SecondHeading} style={{color: '#1f396d',marginLeft:'100px'}}>{t('BubbleNetwork')}</Typography>
                </div>
                <div>
                    <Divider className={classes.divider}/>
                </div>
            </Grid> 
            {loadDRVlanPart()}
            <Grid container direction="column">

                <Grid container direction="row">
                    <Grid item xs={6} direction="row" style={{display: 'flex',flexDirection:'row', marginTop:'35px'}}>
                        <img src='/assets/images/createjob/copy.svg' className={classes.Image}></img>
                        <Typography className={classes.SecondHeading} style={{color: '#2892d7'}}>{t('copyMapping')}</Typography>
                    </Grid>
                </Grid>

                <Grid container direction="column" style={{flexWrap:'nowrap'}}>
                <Grid item direction="row">
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px' }}>
                            <Typography variant='h6' className={classes.MainHeading} style={{fontSize:'14px'}}>{t('networkControl')}</Typography>
                            <Tooltip title={t("networkControlInfo")} arrow>
                                <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                            </Tooltip>
                            <Divider className={classes.headerDivider} style={{ width: '61%' }} />
                    </div>
                </Grid>
                </Grid>

                <Grid container direction="row" style={{display: 'flex',justifyContent: 'space-between',alignItems: 'center', marginTop:'16px'}}>
                    <Grid item style={{display: 'flex',flexDirection:'column'}}>
                    <FormControlLabel 
                        value={t('moveInBubble')}
                        control={<CustomCheckbox icon={<CircleUnchecked/>} checkedIcon={<CircleCheckedFilled />} checked={AllowInState} onChange={handleAllowInChange}/>}
                        label={<Typography>{t('moveInBubble')}</Typography>}
                       />

                    <FormControlLabel 
                        value={t('moveOutBubble')}
                        control={<CustomCheckbox icon={<CircleUnchecked/>} checkedIcon={<CircleCheckedFilled />} checked={AllowOutState} onChange={handleAllowOutChange}/>}
                        label={<Typography>{t('moveOutBubble')}</Typography>}
                       />
                    </Grid>
                </Grid>
            </Grid>
   </Grid>
    );
};

export default observer(TestDRVlanComponent);