import React, { useState, useEffect, useRef } from 'react';

import { Grid, makeStyles, Popover, Button, Typography, InputAdornment, withStyles, TextField } from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewThumbnailIcon from '@material-ui/icons/ViewModule';
import FilterIcon from '@material-ui/icons/FilterList';
import clsx from 'clsx';

import ServerInfoPanel from './serverInfoPanel.component';
import { useTranslation } from 'react-i18next';
import { JobDeviceResultShort, DeviceStatus } from '../../app/models/jobDevice/jobDeviceResult.reponse.model';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import CheckBoxFilter, { CheckboxOption } from '../../app/common/checkboxFilter';
import { DeviceType, DEVICE_TYPE_NAME_SEPERATE_DC } from '../../app/models/deviceType.enum';
import { PlayPauseButton } from '../../app/common/playPauseButton.component';
import { debounce } from '../../app/common/util/util';
import DeviceResultCache from '../../app/common/util/deviceResult.cache';
import JobCategoryIcon from '../../app/common/jobCategoryIcon';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import { TestStatus, TestType } from '../../app/models/jobTest.model';

const useTabStyles = makeStyles({
    root: {
        borderBottom: "1px solid #c7cfdb",
    },
    tabsRoot: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        background: "transparent",

    },

    item: {
        display: "flex",
        flexDirection: "column",
        fontSize: "16px",
        alignItems: "center",
        padding: "10px 20px 10px 20px",
        boxSizing: "border-box",
        border: "1px solid white",
        color: "#8092a9",
        cursor: "pointer"
    },
    selected: {
        position: "relative",
        color: "#1f396d",
        "&:after": {
            position: "absolute",
            content: '""',
            width: "100%",
            bottom: "-2px",
            left: 0,
            borderBottom: "2px solid #ffffff",
            zIndex: 1
        },
        borderLeft: "1px solid #c7cfdb",
        borderRight: "1px solid #c7cfdb",
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        borderTop: "1px solid #c7cfdb",
        background: "white",
    },
    toggleButton: {
        borderRadius: "50%",
        width: "40px",
        height: "40px"
    },

    filterButton: {
        borderRadius: "50%",
        width: "35px",
        height: "35px",
        minWidth: 0,
        marginLeft: "10px",
        color: "lightgray"
    },
    filterPopover: {
        padding: "10px 0px 0px 10px"
    },
    input: {
        border: '0px',
        borderBottom: '1px solid gray',
        '&:active , &:focus': {
            outline: "none"
        }
    }

});

const DEVICE_TYPE_OPTIONS: Array<CheckboxOption> = [
    { label: DeviceType.VirtualMachine, checked: false },
    { label: DeviceType.PhysicalServer, checked: false },
    { label: DeviceType.BranchOffice, checked: false },
    { label: DeviceType.DCPhysical, checked: false },
    { label: DeviceType.DCVirtual, checked: false },
    { label: DeviceType.NetworkDevice, checked: false },
    { label: DeviceType.Firewall, checked: false },
    { label: DeviceType.InternetConnection, checked: false },
    { label: DeviceType.Database, checked: false },
    { label: DeviceType.Other, checked: false }]

const DEVICE_TYPE_MAP: { [key: number]: string } = {
    [DeviceType.BranchOffice]: "branchOffice",
    [DeviceType.DCPhysical]: "dc",
    [DeviceType.DCVirtual]: "virtualDC",
    [DeviceType.PhysicalServer]: "physical",
    [DeviceType.NetworkDevice]: "network",
    [DeviceType.Firewall]: "firewall",
    [DeviceType.VirtualMachine]: "vms",
    [DeviceType.InternetConnection]: "internet",
    [DeviceType.Database]:"Database",
    [DeviceType.Other]: "other",

}
export const SearchTextField = withStyles({
    root: {
        marginRight: 10,
        padding: 4,
        maxWidth: 150,
        '& .MuiInput-underline:hover': {
            border: 0,
        },
        '& .MuiInput-underline:hover:before': {
            border: 0,
        },
        '& .MuiInput-underline:after': {
            border: 0
        },
        '& .MuiInput-underline:before': {
            border: 0
        },
        background: "white",
        borderRadius: 28,
        border: '1px solid #eaeef4',
        overflow: "hidden"
    },
})(TextField);

function filterDevicesByStatus(devices: Array<JobDeviceResultShort>, status: string): Array<JobDeviceResultShort> {
    return devices.filter(d => {
        if (status === "all")
            return true;
        if(status === "warning")
            return d.status === DeviceStatus.warning; 
        if (status === DeviceStatus.ok)
            return d.status === DeviceStatus.ok;
        if (status === DeviceStatus.failed)
            return d.status === DeviceStatus.failed;
        return false;
    })
}


const ReportDeviceSection: React.FC<{ devices: Array<JobDeviceResultShort>,testGroups: Array<any>, defaultAnimationPlayState?: boolean, testGroupNames: {[key: number]: string} }> = ({ devices,testGroups, testGroupNames,defaultAnimationPlayState }) => {
    const tabClasses = useTabStyles();
    const [t] = useTranslation("report");
    const [viewType, setviewType] = useState<"list" | "thumbnail">("thumbnail");
    const [isAnimationPlaying, setAnimationPlayState] = useState(defaultAnimationPlayState);
    const [filterAnchor, setfilterAnchor] = useState(null);
    const filterButtonRef = useRef(null);
    const [deviceTypeOptions, setDeviceTypeOptions] = useState<Array<CheckboxOption>>([]);
    const [testGroupOptions, setTestGroupOptions] = useState<Array<CheckboxOption>>([]);
    const [filteredDevices, setFilteredDevices] = useState<Array<JobDeviceResultShort>>([]);
    const [deviceNameFilter, setdeviceNameFilter] = useState<string>(null);
    
    const [selectedTab, setSelectedTab] = useState("all");

    const handleViewTypeChange = (event, value) => {
        setviewType(event.currentTarget.value);
    }

    
    const loadTab=()=>{
        if(devices.filter(d => d.status === "failed").length === devices.length)
            return "failed";
        else if(devices.filter(d => d.status === "ok").length === devices.length)
            return "ok";
        else if(devices.filter(d => d.status === "warning").length === devices.length)
            return "warning";
        else
            return "all";
    }


    useEffect(() => {
        return () => {
            DeviceResultCache.reset();
        }
    }, [])

    useEffect(() => {
        setSelectedTab(loadTab())
        setFilteredDevices(filterDevicesByStatus(devices, selectedTab));
         
        setDeviceTypeOptions(devices.reduce((acc, d) => {
            let deviceType = DEVICE_TYPE_OPTIONS.find(g => g.label === d.type);
            if (deviceType && acc.find(o => o.label === DEVICE_TYPE_NAME_SEPERATE_DC[deviceType.label]) == null) {
                acc.push({ checked: false, label:DEVICE_TYPE_NAME_SEPERATE_DC[deviceType.label],type: deviceType.label})
            }    
            return acc;
        }, []));

        setTestGroupOptions(devices.reduce((acc, d) => {
            if(testGroups.length !== 0)
            {
                let group = testGroups.find(g => g.groupOrder === d.testGroup.toString());
                if (group && acc.find(o => o.label === group.groupName) == null) {
                    acc.push({ checked: false, label: group.groupName, groupOrder: group.groupOrder})
                }
            }
            else
            {
                if (acc.find(o => o.label === d.testGroup.toString()) == null) {
                    acc.push({ checked: false, label: d.testGroup.toString(), groupOrder: d.testGroup.toString()})
                }
            }            
            return acc;
        }, []));
    }, [devices, testGroups])

    useEffect( () => {   
        setFilteredDevices([]); 
        setdeviceNameFilter(null);
        if(testGroupOptions.length > 0)
            setTestGroupOptions(testGroupOptions.map(t => { t.checked=false; return t;}));
        if(deviceTypeOptions.length > 0)
            setDeviceTypeOptions(deviceTypeOptions.map(d=> {d.checked=false; return d;}));
     },[selectedTab])

    useEffect(() => {
        const slectedTestGroups = testGroupOptions.filter(t => t.checked).map(t => t.groupOrder);
        const slectedDeviceType = deviceTypeOptions.filter(t => t.checked).map(t => t.type);
        
        let _filteredDevices = filterDevicesByStatus(devices, selectedTab);
        
          if(slectedTestGroups.length >0 ) {
            _filteredDevices = _filteredDevices.filter(d => {
                
                if (slectedTestGroups.indexOf(d.testGroup.toString()) >= 0) {
                    return true;
                } else {
                    return false;
                }
        })}

        if(slectedDeviceType.length >0 ) {
            _filteredDevices = _filteredDevices.filter(d => {
                if (slectedDeviceType.indexOf(d.type) >= 0) {
                    return true;
                } else {
                    return false;
                }
        })}
       _filteredDevices = _filteredDevices.filter(d => !deviceNameFilter || d.machineName.toLowerCase().indexOf(deviceNameFilter.toLowerCase())=== 0);
        
      
        setFilteredDevices(_filteredDevices);
      
    }, [testGroupOptions, deviceTypeOptions, selectedTab, deviceNameFilter])


    const handleDeviceNameFilterChange = debounce((val) => setdeviceNameFilter(val), 600 );
    
    const loadTabs =()=>{
        let isAnyDeviceWithOk = devices.some(d => d.status === "ok");
        let isAnyDeviceWithFailed = devices.some(d =>d.status === "failed");
        let isAnyDeviceWithWarning = devices.some(d =>d.status === "warning");
        return(<React.Fragment>
                {isAnyDeviceWithFailed ? <div data-test="tab-failed" onClick={() => setSelectedTab("failed")}
                    className={clsx(tabClasses.item, {
                        [tabClasses.selected]: selectedTab === "failed"
                    })}>    
                    <span><JobCategoryIcon style={{marginRight: 5}} status={0} variant="small" full={true} />{t("devicePanel.failed")}({devices.filter(d =>d.status === "failed").length})</span>
                </div>:null}
                 
                {isAnyDeviceWithWarning ? <div data-test="tab-warning" onClick={() => setSelectedTab("warning")}
                className={clsx(tabClasses.item, {
                    [tabClasses.selected]: selectedTab === "warning"
                })}> 
                <span><JobCategoryIcon style={{marginRight: 5}} status={-3} variant="small" full={true} warning={true}/>{t("devicePanel.warning")}({devices.filter(d =>d.status === "warning").length})</span>
                </div>:null}

                {isAnyDeviceWithOk ? <div data-test="tab-ok" onClick={() => setSelectedTab("ok")}
                className={clsx(tabClasses.item, {
                    [tabClasses.selected]: selectedTab === "ok"
                })}>     
                <span><JobCategoryIcon style={{marginRight: 5}} status={2} variant="small" full={true} /> {t("devicePanel.ok")}({devices.filter(d => d.status === "ok").length})</span>
                </div>:null}

                {(isAnyDeviceWithOk && isAnyDeviceWithFailed) || (isAnyDeviceWithOk && isAnyDeviceWithWarning) || (isAnyDeviceWithFailed && isAnyDeviceWithWarning)?<div data-test="tab-all" onClick={() => setSelectedTab("all")}
                className={clsx(tabClasses.item, {
                    [tabClasses.selected]: selectedTab === "all"
                })}>   
                <span><JobCategoryIcon style={{marginRight: 5}} status={-2} variant="small" full={true}/> {t("devicePanel.allDevices")}({devices.length})</span>
                </div>:null}
        </React.Fragment>);
    }

    return <Grid container direction="column" data-test="component-reportDevice">
        <Grid container direction="row" className={tabClasses.root}>
            <Grid item className={tabClasses.tabsRoot}>
              {loadTabs()}
            </Grid>
            <Grid item style={{ display: "flex", paddingBottom: "5px" }}>
            <SearchTextField
                        data-test="device-filter-input"
                        onChange={event => handleDeviceNameFilterChange(event.target.value)} 
                        placeholder={t("searchDevice")} 
                        variant="standard"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                {/* <input type="text"  className={tabClasses.input} ></input> */}
                <PlayPauseButton color="#8092a9" isPlaying={isAnimationPlaying} onChange={setAnimationPlayState}/>
                <ToggleButtonGroup classes={{ root: "toggle_button_group" }} value={viewType} onChange={handleViewTypeChange}>
                    <ToggleButton data-test="button-list" classes={{ root: clsx(["toggle_button", tabClasses.toggleButton]), selected: "toggle_button_selected" }} value="list"><ViewListIcon /></ToggleButton>
                    <ToggleButton data-test="button-thumbnail" classes={{ root: clsx(["toggle_button", tabClasses.toggleButton]), selected: "toggle_button_selected" }} value="thumbnail"><ViewThumbnailIcon /> </ToggleButton>
                </ToggleButtonGroup>

                <Button
                    ref={filterButtonRef}
                    variant="outlined"
                    className={tabClasses.filterButton}
                    onClick={() => setfilterAnchor(filterButtonRef)} > <FilterIcon />  </Button>
                <Popover
                    open={!!filterAnchor}
                    anchorEl={filterButtonRef.current}
                    onClose={() => setfilterAnchor(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div className={tabClasses.filterPopover}>
                        <Typography variant="h6" >{t('DeviceType')}</Typography>
                        <CheckBoxFilter options={deviceTypeOptions} onChange={options => setDeviceTypeOptions(options)} showAllOption={true} renderLabel={option => <span>{option.label}</span>} />
                        <Typography variant="h6">{t('TestGroup')}</Typography>
                        <CheckBoxFilter options={testGroupOptions} onChange={options => setTestGroupOptions(options)} showAllOption={true} renderLabel={option => <span>{option.label}</span>} />
                    </div>
                </Popover>
            </Grid>
        </Grid>
        <Grid container >

        </Grid>
        <Grid container direction={viewType === "thumbnail" ? "row" : "column"} style={{ marginTop: "7px" }} >
            {filteredDevices.map((d, index) => <ServerInfoPanel data-test="component-serverInfoPanel" key={d.id} playAnimation={isAnimationPlaying} deviceInfo={d} testGroups={testGroups} testGroupNames={testGroupNames} view={viewType}></ServerInfoPanel>)}
        </Grid>
    </Grid>
}

export default ReportDeviceSection;