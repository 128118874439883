import React, { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";
import DeviceBar from "./DeviceBar.component";
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Divider, Typography } from "@material-ui/core";
import DeviceTestChart from "../../../app/common/testChart.component"; //the new testSpinner
import { DeviceType, DEVICE_TYPE_NAME_SEPERATE_DC, DEVICE_TYPE_SMALL_ICON } from "../../../app/models/deviceType.enum";
import { RootStoreContext } from '../../../app/stores/root.store';
import { IDevicesPanel } from "../../../app/models/dashboard/devicePanel.model";
import { parseSelectedDevices } from "../../../app/common/util/util";
import { NavLink } from "react-router-dom";
import Cog from "../Cog.component";
import EmptyGraph from "../../../assets/dashboardAssets/empty_graph.svg"

const DevicesPanel = (props: IDevicesPanel) => {
  const [t] = useTranslation("dashboard");

  const totalDevices = props.ok + props.failed;

  const useStyles = makeStyles({
    verticalBorder: {
      width: "0.1%",
      height: "14em",
      color: "#eaeef4"
    },
  });

  const classes = useStyles();
  const { settingStore } = useContext(RootStoreContext);
  const { reportSettings } = settingStore;
  const selectedDevices = reportSettings.find(obj => obj.settingKey.match('SelectedDevices'));
  const [devices, setDevices] = useState<Array<{ type: DeviceType, ok: number, failed: number }>>([]);

  useEffect(() => {
    selectedDevices && props.devicesData && setDevices(parseSelectedDevices(selectedDevices.settingValue).map(deviceType => {
      return {
        type: deviceType,
        ok: props.devicesData.filter(d => d.type === deviceType).filter(d => d.status === "ok").length,
        failed: props.devicesData.filter(d => d.type === deviceType).filter(d => d.status !== "ok").length
      }

    }))
  }, [selectedDevices, props.devicesData]);

  return (
      <Grid container direction="column" data-test="component-testsPanel" style={{ height: "100%" }}>
        <Grid item container>
          <Grid item style={{ width: "70%" }}>
            <Typography classes={{ root: "dashboard_header" }}>{t("testedDevices") + " (" + totalDevices + ") "}</Typography>
          </Grid>

        </Grid>
        <Grid item style={{ marginTop: "1em" }}>
          <Grid justify="space-around" container direction="row">
            {devices.slice(0, 3).map((device, index) => {
              return <> <Grid key={index} item xs={3}>
                <DeviceTestChart passed={device.ok} failed={device.failed} width={100} height={80} icon={DEVICE_TYPE_SMALL_ICON[device.type]} title={t(DEVICE_TYPE_NAME_SEPERATE_DC[device.type])} />
              </Grid>
                {index < 2 && <Grid item><hr className={classes.verticalBorder} /></Grid>}
              </>
            })}
          </Grid>
        </Grid>
        <Grid item ><Divider /></Grid>
        <Grid item style={{ backgroundColor: "#fafafb" }}>

          {devices.slice(3).map((device, index) => <DeviceBar {...device} />)}
        </Grid>
      </Grid>
  );
}

export default observer(DevicesPanel);



