import React, { useContext, useEffect, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/root.store';
import { Grid, Typography, TextField, Button, CircularProgress, Checkbox, makeStyles, createStyles, useTheme, FormControlLabel, OutlinedInput, InputAdornment, IconButton, FormControl, InputLabel, Tooltip  } from '@material-ui/core';
import login_image_tiny from "../../../assets/user/login_logo.svg";
import login_image_small from "../../../assets/user/login_logo.png";
import login_image_medium from "../../../assets/user/login_logo@2x.png";
import login_image_large from "../../../assets/user/login_logo@3x.png";
import { useTranslation } from 'react-i18next';
import Visibility from '@material-ui/icons/Visibility';
import HelpIcon from '@material-ui/icons/Help';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const LoginBoxComponent: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { login, loading , loginError , isChecked, autologin } = rootStore.userStore; 
    const [t] = useTranslation("user");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [usernameHelper, setUsernameHelper] = useState("");
    const [passwordHelper, setPasswordHelper] = useState("");
    const [checked, setChecked] = useState(true);
    const theme = useTheme();  
    const [passwordVisibility,setPasswordVisibility] = useState(false);

    useEffect(() => {
      login({username: 'edradmin', password: 'Pa$$w0rd' , remember: 'No'});
    }, [])
    
    const keyPress = event => {
        if(event.keyCode == 13){
          loginHandler();
        }
      }

      const handleClickShowPassword = () => {
        setPasswordVisibility(passwordVisibility? false: true);
      };

      const handleMouseDownPassword = (event) => {
          event.preventDefault();
      };

      const onChange = event => {
        let valid: boolean;
    
         switch (event.target.id) {
           case "username":
              setUsername(event.target.value);
              // valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
              //   event.target.value
              // );
              valid = event.target.value.length > 6;
            if (!valid) {
              //setUsernameHelper(t("loginusevalidemail"));
              setUsernameHelper("");
            } else {
              setUsernameHelper("");
            }
             break;
            case "password":
              setPassword(event.target.value);
              valid = event.target.value.length > 5;
              if (!valid) {
                setPasswordHelper(t("loginpasswordshouldbe"));
              } else {
                setPasswordHelper("");
              }
              break;
              case "remember":
                setChecked(event.target.checked);
                break;
     
           default:
             break;
         }
      }
      const loginHandler= () => {
        let values = {
          email: username,
          password: password,
          username: username,
          remember: checked? "yes":"",
        }
        login(values);  
      }

      const useStyles = makeStyles({
        login_logo: {
          width: "298.1px",
          height: "48px",
          objectFit: "contain",
          marginTop: "2em",
        },
        helpIcon:
        {
          color: '#1976d2',
          fontSize: "large",
          marginTop:'8px'
        },
        label: {
          textTransform: 'capitalize',
        },
      });
  
      const classes = useStyles();
 
    return (
        <Grid item container direction="column" xs={4}  alignContent="center" classes={{ root: "login_box"}}>
              <Grid item xs={4} >
              <img className={classes.login_logo} 
                      src={login_image_tiny}
                      srcSet={`${login_image_medium}, ${login_image_small}, ${login_image_large}`}
                    />  
              </Grid>
              <Grid item xs={8} container style={{marginLeft: "4em",width:"400px"}} direction="column">
                <Grid item style={{marginBottom: "1em"}}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography classes={{ root: "form_title"}}>
                    {t("signin")}
                  </Typography>
                  <Tooltip title={t("enterdetails")} arrow>
                    <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' ,marginTop:'16px'}} />
                  </Tooltip>
                  </div>
                </Grid>
                <Grid item style={{marginBottom: "1em"}}>
                </Grid>
                    <Grid item>
                      <TextField  
                        label={ t("username")}
                        autoComplete="new-username"
                        id="username"
                        variant="outlined"
                        value={username}
                        error={usernameHelper.length !== 0}
                        helperText={usernameHelper}
                        classes={{ root: "login_text_box" }}
                        onChange={onChange}
                        onKeyDown={keyPress}
                        >{ t("username")}
                      </TextField>
                    </Grid>
                    <Grid item>
                    <FormControl variant="outlined">
                      <InputLabel style={{marginTop:"2em"}} htmlFor="outlined-adornment-password">{ t("password")}</InputLabel>
                      <OutlinedInput id="password" 
                                  value={password}
                                  autoComplete="new-password"
                                  type={passwordVisibility ? 'text' : 'password'}
                                  classes={{ root: "login_text_box" }}
                                  label={ t("password")}
                                  onChange={onChange}
                                  onKeyDown={keyPress}
                                  endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                        >
                                          {passwordVisibility ?  <VisibilityOff />: <Visibility />}
                                        </IconButton>
                                      </InputAdornment>
                        }
                      />  
                      </FormControl>
                    </Grid>
                    { loginError ?
                      <Grid item>
                        <Typography 
                          classes={{ root: "somethingwrong_text"}}>
                          { t("somethingwrong")}
                        </Typography>
                      </Grid> : ""
                    }
                    <div  style={{width:"400px", height: "48px",marginTop: "1em", display:"flex",justifyContent:"space-between"}}>
                      <span data-test="rememberMeCheckbox">
                        <FormControlLabel
                            control={<Checkbox value={isChecked} defaultChecked={isChecked} onChange={onChange} classes={{root: "rememberme_text"}} id="remember"/>}
                            label= { t("rememberme")}
                        />
                      </span>
                      <div>
                        <span data-test="signInButton">
                          <Button
                          disabled={username.length === 0 || password.length == 0 || passwordHelper.length !== 0 || usernameHelper.length !== 0}
                          classes={{ root: "send_button" , label: classes.label }}
                          onClick={() => {
                            loginHandler()
                          }}
                          >{loading ? <CircularProgress size={30} style={{color: "white"}} /> : t("signinbutton")}
                          </Button>
                        </span>
                      </div>
                    </div>

                    <div style={{display:'flex',paddingTop:'50%'}}>
                    <Typography>
                    { t("supportText1")}
                  </Typography>
                  <Typography style={{cursor:'pointer',marginLeft:'4px',marginRight:'4px',fontWeight:500}} onClick={()=>window.open("https://support.ensuredr.com/169318-KB50008---First-time-login-into-EnsureDR-Management-EDRM")}>
                    { t("support")}
                  </Typography>
                  <Typography>
                    { t("supportText2")}
                  </Typography>
                    </div>
                  </Grid>
                </Grid>

    );
}

export default (observer(LoginBoxComponent));