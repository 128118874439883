import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, ListItem, ListItemText, makeStyles, Paper, SvgIcon, Switch, Tooltip, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { SaveButton } from "../../../app/common/saveButton";
import { EDRDialogTitle } from "../../../app/common/EDRDialogTitle";
import FlashOn from '@material-ui/icons/FlashOn';
import TuneIcon from '@material-ui/icons/Tune';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import DatabaseTestDialog from './DatabaseTestDialog.component';
import { EDRTextField } from '../../../app/common/EDRTextField';
import { EDROutlinedInput } from '../../../app/common/EDROutlinedInput';
import NetworkTestDialog from './NetworkTestDialog.component';
import { TestType } from '../../../app/models/jobTest.model';
import { RootStore, RootStoreContext } from '../../../app/stores/root.store';
import { CONSTS, DeviceTest } from '../../../app/models/createjob/createJob.model';
import { JobDevice } from '../../../app/models/jobDevice/jobDevice.model';
import { DatabaseType, DevicePlatform, MachineType } from '../../../app/models/jobProperties.model';
import JobSettingsStore from '../../../app/stores/jobSettings.store';
import { compressFile } from '../../../app/common/util/util';
import { create } from 'yup/lib/array';
import { EDRAutocomplete } from '../../../app/common/EDRAutocomplete';
import EmptyVlanComponent from '../NetworkInfo/Vlan/EmptyVlan.component';
import { toast } from 'react-toastify';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ReactDOM from 'react-dom';
import HelpIcon from '@material-ui/icons/Help';
import ServiceTestDialog from './ServiceTestDialog.component';
import WebTestDialog from './WebTestDialog.component';
import ToggleAllTestDialog from './ToggleAllTestDialog.component';
import CustomTestDialog from './CustomTestDialog.component';
import { DEVICE_TYPE_NAME_SEPERATE_DC } from '../../../app/models/deviceType.enum';
import { CustomIcon, DatabaseIcon, PreScriptIcon, ServiceIcon, WebportalIcon} from '../../Icons';
import AuthenticationIcon from '../../Icons/AuthenticationIcon';
import { DataMover } from '../../../app/models/BaseSettings.enum';
import AuthTestDialog from './AuthTestDialog.component';
import ScriptTestDialog from './ScriptTestDialog.component';

function getWebTest(device: JobDevice) {
    const webTest = device.tests.find(test=>test.testType === TestType.web);
    if(webTest.webPortalToTest?.includes("http")) {
        return webTest.webPortalToTest;
    }
    if(device.planName.includes("custom")) {
        if(device.sourceName) {
            return `https://${device.sourceName}`
        }
    } else {
        if(!webTest.webPortalToTest) {
            if(device.sourceName) 
            {
                let hostname =device.sourceName.split('.')[0];
                return `https://${hostname}`;
            }
            else return `https://${device.deviceName}`;
        }
    }
    return '';
   
}
const useStyles = makeStyles({
    root: {
        minWidth: '40vw'
    },
    helpIcon:
        {
          color: '#1976d2',
          fontSize: "large"
        },
        divider: {
            backgroundColor: '#eaeef4',
            width: '290%',
            margin: '0',
            marginTop: '10px',
            marginLeft: '10px',
    },
    advanced:{
        width: '70px',
        height: '40px',
        objectFit: 'contain',
        marginTop: '15px'
    },
    circle:{
        width: '120px',
        height: '120px',
        padding: '24px',
        marginTop: '90%',
        marginLeft: '115%',
        borderRadius: '50%',
        backgroundColor: '#fafafc'
    },
    SecondHeading:{
        color:'#1f396d',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        flexGrow: 1
    },
    formControl: {
        width: '100%',
        '& label': {
            background: "white",
            width: "auto",
            padding:"0 4px 0 4px",
            fontSize: '17px',
          }
    },
    iconButton: {
        borderRadius: '4px',
        height:'36px',
        minWidth:'36px',
        width:'36px'
    },
    centerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between'
    },
    rightBorder: {
        position: 'relative',
        "&:after": {
            content: "' '",
            position: 'absolute',
            width: 1,
            height: '100%',
            right: -4,
            background: 'rgba(0, 0, 0, 0.12)'
        }
    },
    toggleItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiListItemText-root' : {
            marginLeft: 10
        },
        '& .MuiSvgIcon-root': {
            color: '#8092a9'
        }
    },
heading :{
    fontSize:'15px'
},
BrowseButton:{
    backgroundColor: '#fafafc',
    textTransform: 'none',
  },
})
export interface EditDeviceProps {
    open: boolean;
    onClose: () => void;
    onSave :(test:TestType,state:boolean)=> void;
    deviceName: string;
}

const EditDeviceDialog: React.FC<EditDeviceProps> = (props) => {
    let labelRef;
    let labelRefScript;
    const { open, onClose, deviceName,onSave } = props;
    const classes = useStyles();
    const {createJobStore,jobSettingsStore,displayStore} = useContext(RootStoreContext);
   
    const [togglenetwork,setToggleNetwork]=useState(true);
    const [toggleAll,setToggleAll]=useState(false);
    
    const [ToggleAllButtonClick,setToggleAllButtonClick]=useState(false);
    const [NetworkButtonClick,setNetworkButtonClick]=useState(false);
    const [DBButtonClick,setDBButtonClick]=useState(false);
    const [ServiceButtonClick,setServiceButtonClick]=useState(false);
    const [CustomButtonClick,setCustomButtonClick]=useState(false);
    const [WebButtonClick,setWebButtonClick]=useState(false);
    const [ScriptButtonClick,setScriptButtonClick]=useState(false);
    const [AuthButtonClick,setAuthButtonClick]=useState(false);
    

   
    const [openToggleAllTestDialog, setopenToggleAllTestDialog] = useState(false);
    const [openDatabaseTestDialog, setopenDatabaseTestDialog] = useState(false);
    const [openServiceTestDialog, setopenServiceTestDialog] = useState(false);
    const [openCustomTestDialog, setopenCustomTestDialog] = useState(false);
    const [openWebTestDialog, setopenWebTestDialog] = useState(false);
    const [openNetworkTestDialog, setopenNetworkTestDialog] = useState(false);
    const [openScriptTestDialog, setopenScriptTestDialog] = useState(false);
    const [openAuthTestDialog, setopenAuthTestDialog] = useState(false);
    const [DrawersOpen, setDrawersOpen] = useState(false);
    const [showEmpty,setShowEmpty]= useState(true);
    const [cancel,setCancel]=useState(false);
    const { t } = useTranslation('createJobBase');
    const device= createJobStore.selectedDevices.find(device=>device.deviceName == deviceName);
    const inputFileScript = useRef(null);
    const [DataStateScript, setDataStateScript] = useState<any>();

    const [passwordVisibility,setPasswordVisibility] = useState(true);
    const [UserPassAutocomplete,setUserPassAutocomplete] = useState(false);
    const [showEyePassword, setShowEyePassword]= useState(true);
    const [TestAllTests, setTestAllTests]= useState(false);
    const [drawer, setDrawer]= useState("empty");

    const [ShowBrowse,setShowBrowse]= useState(device.scriptData!=null && device.scriptName!="" ?false:(device.scriptName!=""?false:true));

    const loadServiceList =()=>{
        let serviceName=[];
        let list= device !=undefined ? jobSettingsStore.ServiceList.filter(item=>item.os == device.os) : jobSettingsStore.ServiceList;
        list.map(item=>{
            serviceName.push(item.service);
        });
        return serviceName;
    }

    const loadCustomList= ()=>{
        let customCommand=[];
        let list= device !=undefined ? jobSettingsStore.customList.filter(item=>item.os == device.os) : jobSettingsStore.customList;
        list.map(item=>{
            if(device !=undefined && item.command.includes('netdom'))
                customCommand.push(`${item.command} ${device.deviceName}`);
            else
                customCommand.push(item.command)
        });
        return customCommand;
    }
    const getUrl = (device:JobDevice,secure:boolean) => {
        if(device.planName.includes("Custom Devices") && device.sourceName != null && device.sourceName != undefined && device.sourceName != '')
            return secure ? 'https://'+device.sourceName : 'http://'+ device.sourceName
        else if(device.sourceName != null && device.sourceName != undefined && device.sourceName != '')
        {
            let name = device.sourceName;
            if(device.sourceName.includes('.'))
            {
                name = device.sourceName.split('.')[0]
            }
            return secure ? 'https://'+name : 'http://'+ name
        }
        else if(device.deviceName != null && device.deviceName != undefined && device.deviceName != '')
        {
            let name = device.deviceName;
            if(device.deviceName.includes('.'))
            {
                name = device.deviceName.split('.')[0]
            }
            return secure ? 'https://'+name : 'http://'+ name
        }
        return '';
    }
    const getAuthUsername = (platform:DevicePlatform) => {
        if(platform === DevicePlatform.Windows)
        {
            if(jobSettingsStore.selectedPrimaryUser != null && jobSettingsStore.selectedPrimaryUser != undefined && jobSettingsStore.selectedPrimaryUser.userName != '')
                return jobSettingsStore.selectedPrimaryUser.userName;
            else if(createJobStore.baseSettings.workflowTest.PrimaryUser != '')
                return createJobStore.baseSettings.workflowTest.PrimaryUser;
            return '';
        }
        else if(platform === DevicePlatform.UNIX)
        {
            if(jobSettingsStore.selectedSecondaryUser != null && jobSettingsStore.selectedSecondaryUser != undefined && jobSettingsStore.selectedSecondaryUser.userName != '')
                return jobSettingsStore.selectedSecondaryUser.userName;
            else if(createJobStore.baseSettings.workflowTest.SecondaryUser != '')
                return createJobStore.baseSettings.workflowTest.SecondaryUser;
            return '';
            
        }
    }
    const defaultSettings = {
        PreviousServiceName : loadServiceList(),
        PreviousCustomData : loadCustomList(),
        PreviousWebPortal: [
        {url:getUrl(device,false),result:''},
        {url:getUrl(device,true),result:''}],
        PreviousDatabaseData: jobSettingsStore.databaseList,
        PreviousCredentials: jobSettingsStore.accessCredentials.concat(jobSettingsStore.linuxCredentails),
        AuthUsername: getAuthUsername(device.os) ,
        AuthPassword: CONSTS.MaskedPassword,
        script: device.os == DevicePlatform.Windows ? 'WindowsPowerup.ps1':'LinuxPowerup.sh'
    };
    
    const validation = yup.object({
    serviceName: yup.string().when("serviceNameButton", {
            is: true,
            then: yup.string().required("Service Name is a required field")
    }),

    customCommand: yup.string().when("customCommandButton", {
            is: true,
            then: yup.string().required("Custom Command is a required field")
    }),

    customResult: yup.string().when("customCommandButton", {
            is: true,
            then: yup.string().required("Custom Result is a required field")
    }),

    websiteUrl: yup.string().when("webButton", {
            is: true,
            then: yup.string().required("Website URL is a required field")
    }),

    query: yup.string().when("queryButton", {
        is: true,
        then: yup.string().required("Database Query is a required field")
    }),

    script: yup.string().when("scriptButton", {
        is: true,
        then: yup.string().required("Script Path is a required field")
    }),

    username: yup.string().when("credentialsButton", {
        is: true,
        then:yup.string().nullable().required("Username is a required field")
    }),

    password: yup.string().when("credentialsButton", {
        is: true,
        then: yup.string().required("Password is a required field")
    })
    })
    
    useEffect(() => {
        populateFields();
        setopenDatabaseTestDialog(false);
        setopenNetworkTestDialog(false);
        setopenServiceTestDialog(false);
        setopenWebTestDialog(false);
        if(!openToggleAllTestDialog)
        { 
            setShowEmpty(true);
            setDrawer("empty");
        }
      },[device]); 

    
      useEffect(() => {
        if(openServiceTestDialog || openCustomTestDialog || openDatabaseTestDialog || openWebTestDialog || openNetworkTestDialog || openToggleAllTestDialog || openScriptTestDialog || openAuthTestDialog)
            setDrawersOpen(true);
        else
            setDrawersOpen(false);
    },[openServiceTestDialog,openCustomTestDialog,openDatabaseTestDialog,openWebTestDialog,openNetworkTestDialog,openToggleAllTestDialog,openScriptTestDialog,openAuthTestDialog]); 



    const ToggleAllTests= ()=> {
        setToggleAll(!toggleAll);
        if(form.values.serviceName!="") 
        {
            form.setFieldValue("serviceNameButton",!toggleAll ? true:false);
            device.tests.find(test=>test.testType === TestType.service).selected =!toggleAll;   
        }   
        if(form.values.networkName!="") 
        {
            setToggleNetwork(!toggleAll ? true:false);
            form.setFieldValue("networkButton",!toggleAll ? true:false);
            device.tests.find(test=>test.testType === TestType.network).selected =!toggleAll;  
        }  
        if(form.values.customCommand!="")
        {
            form.setFieldValue("customCommandButton",!toggleAll ? true:false);
            device.tests.find(test=>test.testType === TestType.custom).selected =!toggleAll;  
        } 
        if(form.values.query!="")
        {
            form.setFieldValue("queryButton",!toggleAll ? true:false);
            device.tests.find(test=>test.testType === TestType.custom).selected =!toggleAll;  
        }   
        if(form.values.websiteUrl!="")
        {
            form.setFieldValue("webButton",!toggleAll ? true:false);
            device.tests.find(test=>test.testType === TestType.web).selected =!toggleAll; 
        }    
        if(form.values.script!="")
        {
            form.setFieldValue("scriptButton",!toggleAll ? true:false);
            device.tests.find(test=>test.testType === TestType.PreScriptRes).selected =!toggleAll; 
        }
        //if(form.values.username!="" && form.values.password!="")
            form.setFieldValue("credentialsButton",!toggleAll ? true:false); 
    }

    const populateFields = () => {
        setCancel(false);
        setToggleAll(false);
        if(device && device.tests)
        {
            let initialValues= {
                networkName:device.tests.find(test=>test.testType === TestType.thirdDeviceNetwork).selected ? device.tests.find(test=>test.testType === TestType.thirdDeviceNetwork).thirdDeviceNetworkToTest: (device.planName.includes("Custom Devices") ?device.sourceName:device.deviceName),
                serviceName: device.tests.find(test=>test.testType === TestType.service).serviceToTest !="" ? device.tests.find(test=>test.testType === TestType.service).serviceToTest :(device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.serviceToTestLinux :jobSettingsStore.serviceToTest),
                customCommand: device.tests.find(test=>test.testType === TestType.custom).customCommandToTest !="" ?  device.tests.find(test=>test.testType === TestType.custom).customCommandToTest:(device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.customCommandToTestLinux :jobSettingsStore.customCommandToTest),
                customResult: device.tests.find(test=>test.testType === TestType.custom).customCommandExpectedResult !="" ? device.tests.find(test=>test.testType === TestType.custom).customCommandExpectedResult :(device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.customCommandExpectedResultLinux :jobSettingsStore.customCommandExpectedResult),
                query: device.tests.find(test=>test.testType === TestType.database).databaseQueryToTest !="" ? device.tests.find(test=>test.testType === TestType.database).databaseQueryToTest: jobSettingsStore.databaseQueryToTest ,
                websiteUrl: getWebTest(device),
                websiteResult: device.tests.find(test=>test.testType === TestType.web).webPortalResult !="" ? device.tests.find(test=>test.testType === TestType.web).webPortalResult : '',
                script: device.tests.find(test=>test.testType === TestType.PreScriptRes).script !="" ? device.tests.find(test=>test.testType === TestType.PreScriptRes).script :defaultSettings.script,
                username:device.tests.find(test=>test.testType === TestType.authentication).authenticationUserToTest && device.tests.find(test=>test.testType === TestType.authentication).authenticationUserToTest!="" ? device.tests.find(test=>test.testType === TestType.authentication).authenticationUserToTest: defaultSettings.AuthUsername,
                password:device.tests.find(test=>test.testType === TestType.authentication).authenticationUserToTest && device.tests.find(test=>test.testType === TestType.authentication).authenticationPassToTest!="" ? CONSTS.MaskedPassword : defaultSettings.AuthUsername =='' ? '': defaultSettings.AuthPassword,
                networkButton:device.tests.find(test=>test.testType === TestType.thirdDeviceNetwork).selected || device.tests.find(test=>test.testType === TestType.network).selected,
                serviceNameButton: device.tests.find(test=>test.testType === TestType.service).selected,
                customCommandButton: device.tests.find(test=>test.testType === TestType.custom).selected,
                queryButton: device.tests.find(test=>test.testType === TestType.database).selected,
                webButton: device.tests.find(test=>test.testType === TestType.web).selected,
                scriptButton: device.tests.find(test=>test.testType === TestType.PreScriptRes).selected,
                credentialsButton: device.tests.find(test=>test.testType === TestType.authentication).selected,
                toggledc: device.showDeviceAsDC,
                toggle2nd: device.performSecondRestart,
                toggleAllButton:false
            }
            form.resetForm({values: initialValues});
        }
    }
    
    const handleClose = () => {
        onClose();
    }

    const form = useFormik({
        initialValues: {
            networkName:'',
            serviceName: '',
            customCommand: '',
            query: '',
            websiteUrl: '',
            script:'',
            username:'',
            password:'',
            toggleAllButton:false,
            networkButton:false,
            serviceNameButton:true,
            customCommandButton:true,
            queryButton:false,
            webButton:false,
            scriptButton:false,
            credentialsButton:false,
            toggledc:false,
            toggle2nd:false
        },
        validationSchema:validation,
        onSubmit: () => { }
    })


    useEffect(() => {
    if(form.values.customCommandButton && form.values.serviceNameButton && togglenetwork && form.values.queryButton && form.values.webButton && form.values.scriptButton && form.values.credentialsButton)
        setToggleAll(true);
    else
        setToggleAll(false);
        
    },[form.values.customCommandButton,form.values.serviceNameButton,togglenetwork,form.values.queryButton,form.values.webButton,form.values.scriptButton,form.values.credentialsButton]); 

    const handleBrowseScript =()=>{
        if(form.values.scriptButton)
        {
            inputFileScript.current.click();
        }
    }

    const handleFileUpload = (e:any) => {
        const { files } = e.target;
        if (files && files.length) 
        {
          let fileSizebytes=files[0].size/1024;
          let invalid= fileSizebytes > 512 ;
          if(!invalid) 
          {
            form.setFieldValue("script",files[0].name);
            device.scriptName = files[0].name;
            const toBase64 = file => new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = error => reject(error);
            });
            toBase64(files[0]).then(function(res) {
                device.scriptData = res;
            });
            setShowBrowse(false);
          }
          else
          {
            form.setFieldValue("script",'');
            setDataStateScript(null);
            device.scriptData =null;
            device.scriptName ='';
            createJobStore.updateDevice(device);
          }
        }
    };

    const removeFile =()=>{
        if(device!=undefined)
        {
            device.scriptData=null;
            device.scriptName='';
            form.setFieldValue('script','',true);
            setShowBrowse(true);
        }
    }

    const handleScriptChange =(event:any)=>{
        form.setFieldValue("script",event.target.value,true);
        device.scriptName = event.target.value;
        if(event.target.value==='')
        {
            device.scriptData=null;
            device.scriptName='';
            form.setFieldValue('script','',true);
            setShowBrowse(true);
        }
        if(event.target.value!==null && event.target.value!=='')
        {
            device.scriptName = event.target.value;
            device.scriptData = event.target.files!=null ? compressFile(event.target.files[0]):null;
        }  
        createJobStore.updateDevice(device);
    }
    
    const handleCancle =()=>{
        if(form.touched && form.dirty)
            setCancel(!cancel);
        else
            onClose();
    }

    const initializeAllDeviceTests=(tests:DeviceTest[],device:JobDevice)=>{
        tests.map(test=>{
            switch(test.testType)
            {
                case TestType.thirdDeviceNetwork:
                case TestType.network:
                {
                    if(form.values.networkName !== device.deviceName)
                        test.thirdDeviceNetworkToTest = form.values.networkName;
                    break;
                }
                case TestType.service:
                 {
                     test.serviceToTest = form.values.serviceName;
                     test.testFromEDRC =  device.tests.find(test=>test.testType === TestType.service).testFromEDRC;
                     break;
                 }
                 case TestType.custom:
                 {
                     test.customCommandToTest =form.values.customCommand;
                     test.customCommandToTestIsExternal=device.tests.find(test=>test.testType === TestType.custom).customCommandToTestIsExternal;
                     break;
                 }
                 case TestType.web:
                 {
                     test.webPortalToTest = form.values.websiteUrl;
                     break;
                 }
                 case TestType.database:
                 {
                     test.databaseQueryToTest=form.values.query;
                     break;
                 }
                case TestType.authentication: 
                {
                    test.authenticationUserToTest = form.values.username;
                    test.authenticationPassToTest = form.values.password;
                    break;
                }
                case TestType.PreScriptRes :
                {
                    test.script = form.values.script;
                    device.scriptName = form.values.script;
                }
            }
        })
         return tests;
    }

    const handleSave =()=>{  
        try{
            let allDeviceTests : Array<DeviceTest> = initializeAllDeviceTests(device.tests,device);
            let networkSelected =  allDeviceTests.find(test=>test.testType == TestType.network).selected;
            let thirdSelected = allDeviceTests.find(test=>test.testType == TestType.thirdDeviceNetwork).selected;
            const update: JobDevice = {...device,tests:allDeviceTests,deviceType:createJobStore.TempDataDevice!=undefined ? createJobStore.TempDataDevice.deviceType:device.deviceType ,performSecondRestart:createJobStore.TempDataDevice!=undefined ? createJobStore.TempDataDevice.performSecondRestart:device.performSecondRestart,showDeviceAsDC:createJobStore.TempDataDevice!=undefined ? createJobStore.TempDataDevice.showDeviceAsDC:device.showDeviceAsDC,deviceGroup:createJobStore.TempDataDevice!=undefined ? createJobStore.TestGroups.groups.find(item=>item.groupOrder.toString() == createJobStore.TempDataDevice.deviceGroup).groupOrder.toString(): device.deviceGroup} ;
            createJobStore.updateDevice(update);
            //onSave(allDeviceTests.find(test=>test.testType == TestType.network).selected ?TestType.network: TestType.thirdDeviceNetwork,togglenetwork);
            if(networkSelected || thirdSelected)
            {
                onSave(allDeviceTests.find(test=>test.testType == TestType.network).selected ?TestType.network: TestType.thirdDeviceNetwork,form.values.networkButton);
                onSave(networkSelected ? TestType.thirdDeviceNetwork : TestType.network,false);
            }
            else
            {
                onSave(TestType.network,form.values.networkButton);
                onSave(TestType.thirdDeviceNetwork,form.values.networkButton);
            }
            onSave(TestType.service,form.values.serviceNameButton);
            onSave(TestType.custom,form.values.customCommandButton);
            onSave(TestType.database,form.values.queryButton);
            onSave(TestType.web,form.values.webButton);
            onSave(TestType.PreScriptRes,form.values.scriptButton);
            onSave(TestType.authentication,form.values.credentialsButton);
            toast.success(t('saveSuccessful'));
            createJobStore.updateTempDataDevice (null);
        }
        catch(error)
        {
            toast.success(t('savefailed')+error);
        }
        onClose();
    }

    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel style={{marginLeft:'10px'}}>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary"  style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }
    
    const handleYes=()=>{
        let initialValues= {
            networkName:'',
            serviceName: '',
            customCommand: '',
            query: '',
            websiteUrl: '',
            script:'',
            username:'',
            password:'',
            toggleAllButton:false,
            networkButton:false,
            serviceNameButton:true,
            customCommandButton:true,
            queryButton:false,
            webButton:false,
            scriptButton:false,
            credentialsButton:false,
            toggledc:false,
            toggle2nd:false
        };
        form.setValues(initialValues);
        onClose();
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    const handleNetworkButtonClick= ()=>{
        createJobStore.updateEditedDevice(device);
        closeAllOtherDrawers();
        if(NetworkButtonClick === false)
        {
            setopenNetworkTestDialog(true);
            setNetworkButtonClick(true);
            setDrawer("network");
            checkEmptyValues();
        }
        else
        { 
            setopenNetworkTestDialog(false);
            setNetworkButtonClick(false);
            setDrawer("empty");
            if(form.values.networkName == "")
            {
                form.setFieldValue("networkName",device.tests.find(test=>test.testType === TestType.thirdDeviceNetwork).selected ? device.tests.find(test=>test.testType === TestType.thirdDeviceNetwork).thirdDeviceNetworkToTest: (device.planName.includes("Custom Devices") ?device.sourceName:device.deviceName));
            }
        }   
    }

    const handleDBButtonClick =()=>{
        createJobStore.updateEditedDevice(device);
        closeAllOtherDrawers();
        if(DBButtonClick===false)
        {
            setopenDatabaseTestDialog(true); 
            setDBButtonClick(true);
            setDrawer("database");
            checkEmptyValues();
        }
        else
        {
            setopenDatabaseTestDialog(false); 
            setDBButtonClick(false);
            setDrawer("empty");
            if(form.values.query =="")
            {
                form.setFieldValue("query",device.tests.find(test=>test.testType === TestType.database).databaseQueryToTest !="" ? device.tests.find(test=>test.testType === TestType.database).databaseQueryToTest: jobSettingsStore.databaseQueryToTest);
            }
        }  
    }

    const closeAllOtherDrawers =()=>{
        if(drawer!=="empty"){
        if(openNetworkTestDialog)
        {
            setNetworkButtonClick(false);
            setopenNetworkTestDialog(false);
        }
        if(openDatabaseTestDialog)
        {
            setDBButtonClick(false);
            setopenDatabaseTestDialog(false);
        }
        if(openServiceTestDialog)
        {
            setServiceButtonClick(false);
            setopenServiceTestDialog(false);
        }
        if(openWebTestDialog)
        {
            setWebButtonClick(false);
            setopenWebTestDialog(false);
        }
        if(openToggleAllTestDialog)
        {
            setToggleAllButtonClick(false);
            setopenToggleAllTestDialog(false);
        }
        if(openCustomTestDialog)
        {
            setCustomButtonClick(false);
            setopenCustomTestDialog(false);
        }
        if(openScriptTestDialog)
        {
            setScriptButtonClick(false);
            setopenScriptTestDialog(false);
        }
        if(openAuthTestDialog)
        {
            setAuthButtonClick(false);
            setopenAuthTestDialog(false);
        }
        setShowEmpty(true);
        setDrawer("empty");
    }
    }

    const handleServiceButtonClick =()=>{
        createJobStore.updateEditedDevice(device);
        closeAllOtherDrawers();
        if(ServiceButtonClick===false)
        {
            setopenServiceTestDialog(true); 
            setServiceButtonClick(true);
            setDrawer("service");
            checkEmptyValues();
        }
        else
        {
            setopenServiceTestDialog(false); 
            setServiceButtonClick(false);
            setDrawer("empty");
            if(form.values.serviceName == "")
            {
                form.setFieldValue("serviceName",device.tests.find(test=>test.testType === TestType.service).serviceToTest !="" ? device.tests.find(test=>test.testType === TestType.service).serviceToTest :(device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.serviceToTestLinux :jobSettingsStore.serviceToTest))
            }
        }  
    }

    const handleCustomButtonClick =()=>{
        createJobStore.updateEditedDevice(device);
        closeAllOtherDrawers();
        if(CustomButtonClick===false)
        {
            setopenCustomTestDialog(true); 
            setCustomButtonClick(true);
            setDrawer("custom");
            checkEmptyValues();
        }
        else
        {
            setopenCustomTestDialog(false); 
            setCustomButtonClick(false);
            setDrawer("empty");
            if(form.values.customCommand == "")
            {
                form.setFieldValue("customCommand",device.tests.find(test=>test.testType === TestType.custom).customCommandToTest !="" ?  device.tests.find(test=>test.testType === TestType.custom).customCommandToTest:(device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.customCommandToTestLinux :jobSettingsStore.customCommandToTest))
            }
        }  
    }

    const handleToggleAllButtonClick =()=>{
        createJobStore.updateEditedDevice(device);
        closeAllOtherDrawers();
        if(ToggleAllButtonClick===false)
        {
            setopenToggleAllTestDialog(true); 
            setToggleAllButtonClick(true);
            setDrawer("toggleAll");
        }
        else
        {
            setopenToggleAllTestDialog(false); 
            setToggleAllButtonClick(false);
            setDrawer("empty");
        }  
    }

    const handleWebButtonClick =()=>{
        createJobStore.updateEditedDevice(device);
        closeAllOtherDrawers();
        if(WebButtonClick===false)
        {
            setopenWebTestDialog(true); 
            setWebButtonClick(true);
            setDrawer("web");
            checkEmptyValues();
        }
        else
        {
            setopenWebTestDialog(false); 
            setWebButtonClick(false);
            setDrawer("empty");
            if(form.values.websiteUrl =="")
            {
                form.setFieldValue("websiteUrl",getWebTest(device));
            }
        }  
    }

    const handleAuthButtonClick =()=>{
        createJobStore.updateEditedDevice(device);
        closeAllOtherDrawers();
        if(AuthButtonClick===false)
        {
            setopenAuthTestDialog(true); 
            setAuthButtonClick(true);
            setDrawer("authentication");
            checkEmptyValues();
        }
        else
        {
            setopenAuthTestDialog(false); 
            setAuthButtonClick(false);
            setDrawer("empty");
            if(form.values.username=="" || form.values.password=="")
            {
                form.setFieldValue("username",device.tests.find(test=>test.testType === TestType.authentication).authenticationUserToTest!="" ? device.tests.find(test=>test.testType === TestType.authentication).authenticationUserToTest: defaultSettings.AuthUsername);
                form.setFieldValue("password",device.tests.find(test=>test.testType === TestType.authentication).authenticationPassToTest!="" ? CONSTS.MaskedPassword : defaultSettings.AuthUsername =='' ? '': defaultSettings.AuthPassword)
            }
        }  
    }

    const handleScriptButtonClick =()=>{
        createJobStore.updateEditedDevice(device);
        closeAllOtherDrawers();
        if(ScriptButtonClick===false)
        {
            setopenScriptTestDialog(true); 
            setScriptButtonClick(true);
            setDrawer("script");
            checkEmptyValues();
        }
        else
        {
            setopenScriptTestDialog(false); 
            setScriptButtonClick(false);
            setDrawer("empty");
            if(form.values.script=="")
            {
                let test = device.tests.find(test=>test.testType === TestType.PreScriptRes).script;
                form.setFieldValue("script",test!="" && test !=undefined ? test :defaultSettings.script);
            }
        }  
    }

    const checkEmptyValues =()=>{
        if(form.values.serviceName == "") 
        {
            form.setFieldValue("serviceName",device.tests.find(test=>test.testType === TestType.service).serviceToTest !="" ? device.tests.find(test=>test.testType === TestType.service).serviceToTest :(device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.serviceToTestLinux :jobSettingsStore.serviceToTest));
        }   
        if(form.values.networkName == "") 
        {
            form.setFieldValue("networkName",device.tests.find(test=>test.testType === TestType.thirdDeviceNetwork).selected ? device.tests.find(test=>test.testType === TestType.thirdDeviceNetwork).thirdDeviceNetworkToTest: (device.planName.includes("Custom Devices") ?device.sourceName:device.deviceName));
        }  
        if(form.values.customCommand == "")
        {
            form.setFieldValue("customCommand",device.tests.find(test=>test.testType === TestType.custom).customCommandToTest !="" ?  device.tests.find(test=>test.testType === TestType.custom).customCommandToTest:(device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.customCommandToTestLinux :jobSettingsStore.customCommandToTest)); 
        } 
        if(form.values.query == "")
        {
            form.setFieldValue("query",device.tests.find(test=>test.testType === TestType.database).databaseQueryToTest !="" ? device.tests.find(test=>test.testType === TestType.database).databaseQueryToTest: jobSettingsStore.databaseQueryToTest);
        }   
        if(form.values.websiteUrl == "")
        {
            form.setFieldValue("websiteUrl",getWebTest(device));
        }    
        if(form.values.script == "")
        {
            form.setFieldValue("script",device.tests.find(test=>test.testType === TestType.PreScriptRes).script !="" ? device.tests.find(test=>test.testType === TestType.PreScriptRes).script :defaultSettings.script);
        }
        if(form.values.username == "")
        {
            form.setFieldValue("username",device.tests.find(test=>test.testType === TestType.authentication).authenticationUserToTest && device.tests.find(test=>test.testType === TestType.authentication).authenticationUserToTest!="" ? device.tests.find(test=>test.testType === TestType.authentication).authenticationUserToTest: defaultSettings.AuthUsername);
            form.setFieldValue("password",device.tests.find(test=>test.testType === TestType.authentication).authenticationUserToTest && device.tests.find(test=>test.testType === TestType.authentication).authenticationPassToTest!="" ? CONSTS.MaskedPassword : defaultSettings.AuthUsername =='' ? '': defaultSettings.AuthPassword)
        }
    }

    const handleAutoCompleteChange = (event: React.ChangeEvent,value:any) => {
        if(event!=null)
        {
          let fieldName= event.target['id'].includes('-') ? event.target['id'].split('-')[0]: event.target['id'];
          form.setFieldValue(fieldName,value);
          switch(fieldName) {
            case 'serviceName': handleServiceNameChange(event,value); break;
            case 'websiteUrl': handleUrlChange(event,value); break;
            case 'query': handleQueryChange(event,value);break;
            case 'username': handleAuthUserChange(event,value);break;
          }
        }
      }

      const handlePasswordChange =(event)=>{
        setUserPassAutocomplete(true);
        setShowEyePassword(true);
        form.setFieldValue("password",event.target.value,true);
        closeAllOtherDrawers();
    }
    
    const handleServiceNameChange =(event:object,value:any)=>{
        form.setFieldValue("serviceName",value,true);
        closeAllOtherDrawers();
    }

    const handleCommandChange=(event:any,value:any) => {
        if(event!=null){
            if (value!=null && typeof value === 'object') 
            {
              form.setFieldValue("customCommand",value=="" ? '':value.command,true);
            }
            if (typeof value === 'string')
            {
              const commandObj = defaultSettings.PreviousCustomData.find(e => e.toLocaleLowerCase() == value.toLocaleLowerCase());
              form.setFieldValue("customCommand", commandObj!=undefined ? value:(value==""?'':value),true);
            }
            closeAllOtherDrawers();
          }
    }

    const handleUrlChange =(event:object,value:any)=>{
        if (value!=null && typeof value === 'object') 
        {
            form.setFieldValue("websiteUrl",value=="" ? '':value.url);
        }
        if (typeof value === 'string')
        {
          const Obj = defaultSettings.PreviousWebPortal.find(e => e.url.toLocaleLowerCase() == value.toLocaleLowerCase());
          form.setFieldValue("websiteUrl", Obj!=undefined ? value :(value==""?'':value));
        }
        closeAllOtherDrawers();
    }

    const handleQueryChange =(event:object,value:any)=>{
        if (value!=null && typeof value === 'object') 
            {
              form.setFieldValue("query",value=="" ? '':value.query);
        }
        if (typeof value === 'string')
            {
              const Obj = defaultSettings.PreviousDatabaseData.find(e => e.query.toLocaleLowerCase() == value.toLocaleLowerCase());
              form.setFieldValue("query", Obj!=undefined ? value:(value==""?'':value));
        }
        closeAllOtherDrawers();
    }

    const handleAuthUserChange =(event:React.ChangeEvent,value:any)=>{
        let onInput= event.type=="click"? false:true;
        setUserPassAutocomplete(onInput);
        if (value!=null && typeof value === 'object') 
        {   

            // form.setFieldValue("username",value=="" ? '':value.userName);
            // form.setFieldValue("password",value=="" ? '':value.password,true);
            form.setValues({...form.values, username: value?.userName || '', password: value?.password || ''}, true);
        }
        if (typeof value === 'string')
        {
         const userObj = defaultSettings.PreviousCredentials.find(e => e.userName.toLocaleLowerCase() == value.toLocaleLowerCase());
        //   form.setFieldValue("username", userObj!=undefined ? value.userName:(value==""?'':value),true);
          let pass = userObj== undefined ? '' :userObj.password;
        //  form.setFieldValue("password",pass,true);
          form.setValues({...form.values, username: value, password: pass}, true);
        
        }
        setShowEyePassword(onInput);
        closeAllOtherDrawers();
    }

    const handleButton =(buttonType:string)=>{
        switch(buttonType)
        {
            case "network":
                {
                    setToggleNetwork(!togglenetwork);
                    form.setFieldValue("networkButton",!form.values.networkButton,true);
                    break;
                }
            case "service":
                {
                    form.setFieldValue("serviceNameButton",!form.values.serviceNameButton,true);
                    break;
                }
            case "custom":
                {
                    form.setFieldValue("customCommandButton",!form.values.customCommandButton,true);
                    break;
                }
            case "database":
                {
                    form.setFieldValue("queryButton",!form.values.queryButton,true);
                    break;
                }
            case "web":
                {
                    form.setFieldValue("webButton",!form.values.webButton,true);
                    break;
                }
            case "script":
                {
                    form.setFieldValue("scriptButton",!form.values.scriptButton,true);
                    break;
                }
            case "credentials":
                {
                    form.setFieldValue("credentialsButton",!form.values.credentialsButton,true);
                    break;
                }
        }
    }
    
    const handleUpdate =(type:TestType)=>{
        /*if(type === TestType.network)
        {
            let test = device.tests.find(item=>item.testType ==TestType.thirdDeviceNetwork);
            form.setFieldValue("networkName",test.thirdDeviceNetworkToTest);
        }  
        if(type === TestType.database)
        {
            let test = device.tests.find(item=>item.testType ==TestType.database);
            form.setFieldValue("query",test.databaseQueryToTest);
        } */
    }

    const handleDiscard =(display:string)=>{
        switch(display)
        {
            case "database":
                {
                    setDBButtonClick(false);
                    if(form.values.query =="")
                    {
                        form.setFieldValue("query",device.tests.find(test=>test.testType === TestType.database).databaseQueryToTest !="" ? device.tests.find(test=>test.testType === TestType.database).databaseQueryToTest: jobSettingsStore.databaseQueryToTest);
                    }
                    break;
                }
            case "network":
                {
                    setNetworkButtonClick(false);
                    if(form.values.networkName == "")
                    {
                        form.setFieldValue("networkName",device.tests.find(test=>test.testType === TestType.thirdDeviceNetwork).selected ? device.tests.find(test=>test.testType === TestType.thirdDeviceNetwork).thirdDeviceNetworkToTest: (device.planName.includes("Custom Devices") ?device.sourceName:device.deviceName));
                    }
                    break;
                }
            case "service":
                {
                    setServiceButtonClick(false);
                    if(form.values.serviceName == "")
                    {
                        form.setFieldValue("serviceName",device.tests.find(test=>test.testType === TestType.service).serviceToTest !="" ? device.tests.find(test=>test.testType === TestType.service).serviceToTest :(device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.serviceToTestLinux :jobSettingsStore.serviceToTest))
                    }
                    break;
                }
            case "web":
                {
                    setWebButtonClick(false);
                    if(form.values.websiteUrl =="")
                    {
                        form.setFieldValue("websiteUrl",getWebTest(device));
                    }
                    break;
                }
            case "script":
                {
                    setScriptButtonClick(false);
                    if(form.values.script=="")
                    {
                        let test = device.tests.find(test=>test.testType === TestType.PreScriptRes).script;
                        form.setFieldValue("script", test!= "" && test!=undefined ? test :defaultSettings.script);
                    }
                    break;
                }
            case "authentication":
                {
                    setAuthButtonClick(false);
                    if(form.values.username=="" || form.values.password=="")
                    {
                        form.setFieldValue("username",device.tests.find(test=>test.testType === TestType.authentication).authenticationUserToTest!="" ? device.tests.find(test=>test.testType === TestType.authentication).authenticationUserToTest: defaultSettings.AuthUsername);
                        form.setFieldValue("password",device.tests.find(test=>test.testType === TestType.authentication).authenticationPassToTest!="" ? CONSTS.MaskedPassword : defaultSettings.AuthUsername =='' ? '': defaultSettings.AuthPassword)
                    }
                    break;
                }
            case "custom":
                {
                    setCustomButtonClick(false);
                    if(form.values.customCommand == "")
                    {
                        form.setFieldValue("customCommand",device.tests.find(test=>test.testType === TestType.custom).customCommandToTest !="" ?  device.tests.find(test=>test.testType === TestType.custom).customCommandToTest:(device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.customCommandToTestLinux :jobSettingsStore.customCommandToTest))
                    }
                    break;
                }
        }
        setShowEmpty(true);
        setDrawer("empty");
    }

    const handleClickShowPassword  = () => {
        setPasswordVisibility(passwordVisibility? false: true);
    };

    const loadRightSideDrawer =()=>{
        switch(drawer)
        {
            case "empty":
                {
                    return(
                        <Grid item>
                        <div className={classes.circle}><img className={classes.advanced} src='/assets/images/createjob/advancedNetworkGrey.svg'></img></div>
                        <Typography variant='h6' className={classes.SecondHeading} style={{marginTop:'15px',marginLeft: '100%'}}>{t('selectOption')}</Typography>
                    </Grid>
                    );
                }
            case "database":
                {
                    return(
                    <DatabaseTestDialog onApply={()=>{handleUpdate(TestType.database);setDBButtonClick(false);setopenDatabaseTestDialog(false);}} setParentValue={(val)=>form.setFieldValue("query",val,true)} reload={form.values.query} discard={()=>handleDiscard("database")} closeDrawer={()=>{setDBButtonClick(false);setopenDatabaseTestDialog(false);setShowEmpty(true); setDrawer("empty");}}/>
                    );
                }
            case "network":
                {
                    return(
                        <NetworkTestDialog onApply={()=>{handleUpdate(TestType.network);setNetworkButtonClick(false);setopenNetworkTestDialog(false);}} setParentValue={(val)=>form.setFieldValue("networkName",val,true)} discard={()=>handleDiscard("network")} closeDrawer={()=>{setNetworkButtonClick(false);setopenNetworkTestDialog(false);setShowEmpty(true);setDrawer("empty");}}/>
                        );
                }
            case "service":
                {
                    return(
                        <ServiceTestDialog onApply={()=>{handleUpdate(TestType.service);setServiceButtonClick(false);setopenServiceTestDialog(false);}} setParentValue={(val)=>form.setFieldValue("serviceName",val,true)} reload={form.values.serviceName} discard={()=>handleDiscard("service")} closeDrawer={()=>{setServiceButtonClick(false);setopenServiceTestDialog(false);setShowEmpty(true);setDrawer("empty");}}/>
                        );
                }
            case "web":
                {
                    return(
                        <WebTestDialog onApply={()=>{handleUpdate(TestType.web);setWebButtonClick(false);setopenWebTestDialog(false);}} setParentValue={(val)=>form.setFieldValue("websiteUrl",val,true)} reload={form.values.websiteUrl} discard={()=>handleDiscard("web")} closeDrawer={()=>{setWebButtonClick(false);setopenWebTestDialog(false);setShowEmpty(true);setDrawer("empty");}}/>
                        );
                }
            case "toggleAll":
                {
                    return(
                        <ToggleAllTestDialog deviceName={deviceName} closeDrawer={()=>{setToggleAllButtonClick(false);setopenToggleAllTestDialog(false);setShowEmpty(true);setDrawer("empty");}}/>
                        );
                }
            case "custom":
                {
                    return(<CustomTestDialog onApply={()=>{handleUpdate(TestType.custom);setCustomButtonClick(false);setopenCustomTestDialog(false);}} setParentValue={(val)=>form.setFieldValue("customCommand",val,true)} reload={form.values.customCommand} discard={()=>handleDiscard("custom")} closeDrawer={()=>{setCustomButtonClick(false);setopenCustomTestDialog(false);setShowEmpty(true);setDrawer("empty");}}/>
                    );
                }
            case "script":
                {
                    return(<ScriptTestDialog onApply={()=>{handleUpdate(TestType.PreScriptRes);setScriptButtonClick(false);setopenScriptTestDialog(false);}} setParentValue={(val)=>form.setFieldValue("script",val,true)} reload={form.values.script} discard={()=>handleDiscard("script")} closeDrawer={()=>{setScriptButtonClick(false);setopenScriptTestDialog(false);setShowEmpty(true);setDrawer("empty");}}/>
                    );
                }
            case "authentication":
                {
                    return(
                        <AuthTestDialog onApply={()=>{handleUpdate(TestType.authentication);setAuthButtonClick(false);setopenAuthTestDialog(false);}} setParentUsernameValue={(val)=>form.setFieldValue("username",val,true)} setParentPasswordValue={(val)=>form.setFieldValue("password",val,true)} reloadUser={form.values.username} reloadPass={form.values.password} discard={()=>handleDiscard("authentication")} closeDrawer={()=>{setAuthButtonClick(false);setopenAuthTestDialog(false);setShowEmpty(true);setDrawer("empty");}}/>
                        );
                }
        }
    };

    const disableSave =()=> {
        if(!form.isValid)
            return true;
        else
        {
            if(DrawersOpen)
                return true;
            else
                return false;
        }
    }

    const VeeamSqlDisable=()=>{
        if(createJobStore.baseSettings.dataMover == DataMover.VeeamSQL)
            return true;
        else
            return false;
    }

    
    const handleMouseClick =()=>{
        if(form.values.password === CONSTS.MaskedPassword)
        {
            form.setFieldValue("password",'',true);
        }
    }

    const handleMouseOut =()=>{
        if(form.dirty  && form.values.password == '')
        {
            if(form.values.username!='')
            {
                return;
            }
            else form.setFieldValue("password",CONSTS.MaskedPassword,true);
        }
    }

    return <Dialog disableBackdropClick
        maxWidth={'lg'}
        open={open}
        classes={{ paper: classes.root }}
        onClose={handleClose}
        scroll="paper"
    >
        <EDRDialogTitle>
        
        {device.planName.includes("Custom") ?
        <React.Fragment>
        <div style={{ display: 'flex', flexDirection: 'row'}}>
            <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{"type: "+DEVICE_TYPE_NAME_SEPERATE_DC[device.deviceType]+ "\n"+"os: " + (device.os ===  DevicePlatform.UNIX ? "Linux" : DevicePlatform[device.os]) +"\n" +"fqdn: " + device.deviceIP +"\n" +"in/out bubble: "+(device.newtworkLocation =="inBubble"?"in bubble":"out bubble")}</span>} arrow>
                <div style={{ display: 'flex', flexDirection: 'row'}}>
                {t("EditDevice")} - {deviceName}<img style={{marginLeft:'5px'}} src={device.os=== DevicePlatform.UNIX ?"/assets/images/editDeviceIcons/linuxIcon.svg":"/assets/images/editDeviceIcons/windowsIcon.svg"}></img>
                </div>
            </Tooltip>
        </div>
        </React.Fragment>
        :
        <div style={{ display: 'flex', flexDirection: 'row'}}>
            {t("EditDevice")} - {deviceName}<img style={{marginLeft:'5px'}} src={device.os=== DevicePlatform.UNIX ?"/assets/images/editDeviceIcons/linuxIcon.svg":"/assets/images/editDeviceIcons/windowsIcon.svg"}></img>
        </div>}
        </EDRDialogTitle>
        <DialogContent dividers={true}>
        <input style={{ display: "none" }} ref={inputFileScript} type="file" onChange={(event)=> { 
               handleFileUpload(event) 
               event.target.value=null
        }}/>
        {TestAllTests ?
        <Grid container style={{display: 'flex', flexDirection: 'row',justifyContent: 'center' , alignItems: 'center',marginTop:'30%',height:'100%'}} >
            <CircularProgress  size={50} style={{color: '#2892d7'}}/>
        </Grid>
    :  <Grid container>
        <Grid item xs container direction="row" spacing={3}>

        <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row'}}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('editDeviceTests')}</Typography> 
                    <Tooltip title={t("editDeviceTestsInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider}/>
                </div>
            </Grid>

            <Grid item container xs={12} spacing={2}>
            <Grid item xs={1}>
                    <Tooltip title={t("ToogleAllTooltip")} arrow  placement="top">
                    <Button variant='outlined' className={classes.iconButton} onClick={ToggleAllTests} style={{border:'solid 1px',borderColor:!toggleAll ?'#c7cfdb':'#96b403', backgroundColor :!toggleAll?'#fafafc':'#edf6e1'}}>
                        <img src={toggleAll ? '/assets/images/editDeviceIcons/toggleAll.svg':'/assets/images/editDeviceIcons/toggleAllGray.svg'}/>
                    </Button>
                    </Tooltip>
                </Grid>
            <Grid item container xs={10}>
            <ListItem disableGutters={true}>
                <div className={classes.centerVertical} style={{marginTop:'-1%'}}>
                  <ListItemText >{t("toggleall")}</ListItemText>
                </div>
            </ListItem>
            </Grid>
            <Grid item xs={1}>
            <Button variant='outlined' className={classes.iconButton} onClick={handleToggleAllButtonClick} style={{border:'solid 1px',borderColor:!ToggleAllButtonClick ?'#c7cfdb':'#2892d7', backgroundColor :!ToggleAllButtonClick?'#fafafc':'#e5f2fa'}}><TuneIcon style={{color:!ToggleAllButtonClick?'#8092a9':'#2892d7'}}/></Button>
            </Grid>
            </Grid>

            <Grid item container xs={12} spacing={2}>
            <Grid item xs={1}>
                    <Tooltip title={t("networkInfo")} arrow  placement="top">
                    <Button variant='outlined' className={classes.iconButton} disabled={VeeamSqlDisable()} onClick={()=>handleButton("network")} style={{border:'solid 1px',borderColor:!form.values.networkButton ?'#c7cfdb':'#96b403', backgroundColor :!form.values.networkButton?'#fafafc':'#edf6e1'}}>
                        <img src={form.values.networkButton ? '/assets/images/editDeviceIcons/ToggleNetwork.svg':'/assets/images/editDeviceIcons/ToggleNetworkGray.svg'}/>
                    </Button>
                    </Tooltip>
                </Grid>
            <Grid item xs={10}>
            <FormControl className={classes.formControl} variant='outlined'>
                <EDRTextField id="network"
                name="network"
                size="small"
                label={t('networkLabel')}
                value={form.values.networkName}
                disabled={true}
                type="text"
                InputLabelProps={{shrink: true}} 
                fullWidth
                variant="outlined">
                </EDRTextField>
                </FormControl>
            </Grid>
            <Grid item xs={1}>
                <Button variant='outlined' className={classes.iconButton} disabled={!form.values.networkButton} onClick={handleNetworkButtonClick} style={{border:'solid 1px',borderColor:!NetworkButtonClick ?'#c7cfdb':'#2892d7', backgroundColor :!NetworkButtonClick?'#fafafc':'#e5f2fa'}}><TuneIcon style={{color:!NetworkButtonClick?'#8092a9':'#2892d7'}}/></Button>
            </Grid>
            </Grid>

            <Grid item container xs={12} spacing={2}>
                <Grid item xs={1}>
                <Tooltip title={t("serviceInfo")} arrow placement="top">
                    <Button variant='outlined' className={classes.iconButton} disabled={VeeamSqlDisable()} onClick={()=>handleButton("service")} style={{border:'solid 1px',borderColor:!form.values.serviceNameButton ?'#c7cfdb':'#96b403', backgroundColor :!form.values.serviceNameButton?'#fafafc':'#edf6e1'}}>
                        <ServiceIcon style={{color:form.values.serviceNameButton? '#8bc53f':'#8092a9'}}></ServiceIcon>
                    </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={10}>
                <EDRAutocomplete id="serviceName" 
                        freeSolo
                        disableClearable
                        fullWidth
                        size='small'
                        value={form.values.serviceName}
                        options={defaultSettings.PreviousServiceName}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderInput={(params) => (<EDRTextField {...params} label={t('serviceLabel')} 
                        disabled={!form.values.serviceNameButton}
                        style={{backgroundColor:!form.values.serviceNameButton ?'#f0f2f7':'',borderColor:'#c7cfdb'}}
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="serviceName"
                        onBlur={form.handleBlur}
                        error={form.touched && form.touched.serviceName && form.errors && Boolean(form.errors.serviceName)}
                        helperText={form.touched.serviceName && form.errors.serviceName}
                    />)}
                    />
                </Grid>
                <Grid item xs={1}>
                <Button variant='outlined' className={classes.iconButton} disabled={!form.values.serviceNameButton} onClick={handleServiceButtonClick} style={{border:'solid 1px',borderColor:!ServiceButtonClick ?'#c7cfdb':'#2892d7', backgroundColor :!ServiceButtonClick?'#fafafc':'#e5f2fa'}}><TuneIcon style={{color:!ServiceButtonClick?'#8092a9':'#2892d7'}}/></Button>
                </Grid>
            </Grid>

            <Grid item container xs={12} spacing={2}>
                <Grid item xs={1} style={{display:'flex',flexDirection:"row"}}>
                <Tooltip title={t("customInfo")} arrow  placement="top">
                    <Button variant='outlined' className={classes.iconButton} disabled={VeeamSqlDisable()} onClick={()=>handleButton("custom")} style={{border:'solid 1px',borderColor:!form.values.customCommandButton ?'#c7cfdb':'#96b403', backgroundColor :!form.values.customCommandButton?'#fafafc':'#edf6e1'}}>
                        <CustomIcon style={{color:form.values.customCommandButton? '#8bc53f':'#8092a9'}}></CustomIcon>
                    </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={10}>
                <EDRAutocomplete id="customCommand" 
                        freeSolo
                        disableClearable
                        fullWidth
                        size='small'
                        value={form.values.customCommand}
                        options={defaultSettings.PreviousCustomData}
                        onChange={handleCommandChange}
                        onInputChange={handleCommandChange}
                        disabled={!form.values.customCommandButton}
                        renderOption={(option: string|{command: string,result:string}) => (typeof option === 'string' ? option : `${option.command}`)}
	                    getOptionLabel={(option: string|{command: string,result:string}) => (typeof option === 'string' ? option : `${option.command}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={t('customCommandLabel')} 
                        style={{backgroundColor:!form.values.customCommandButton ?'#f0f2f7':'',borderColor:'#c7cfdb'}}
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="customCommand"
                        onBlur={form.handleBlur}
                        error={form.touched && form.touched.customCommand && form.errors && Boolean(form.errors.customCommand)}
                        helperText={form.touched.customCommand && form.errors.customCommand}
                    />)}
                    />
                </Grid>
                <Grid item container xs={1}>
                    <Button variant='outlined' className={classes.iconButton} disabled={!form.values.customCommandButton} onClick={handleCustomButtonClick} style={{border:'solid 1px',borderColor:!CustomButtonClick ?'#c7cfdb':'#2892d7', backgroundColor :!CustomButtonClick?'#fafafc':'#e5f2fa'}}><TuneIcon style={{color:!CustomButtonClick?'#8092a9':'#2892d7'}}/></Button>
                </Grid>
            </Grid>

            <Grid item container xs={12} spacing={2}>
                <Grid item xs={1}>
                <Tooltip title={t("databaseInfo")} arrow placement="top">
                    <Button variant='outlined' className={classes.iconButton} onClick={()=>handleButton("database")} style={{border:'solid 1px',borderColor:!form.values.queryButton ?'#c7cfdb':'#96b403', backgroundColor :!form.values.queryButton?'#fafafc':'#edf6e1'}}>
                        <DatabaseIcon style={{color:form.values.queryButton? '#8bc53f':'#8092a9'}}></DatabaseIcon>
                    </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={10}>
                <EDRAutocomplete id="query"
                        freeSolo
                        disableClearable
                        fullWidth
                        size="small"
                        value={form.values.query}
                        options={defaultSettings.PreviousDatabaseData}
                        disabled={true}
                        //disabled={!form.values.queryButton}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderOption={(option: string|{databaseType:number,databaseServer: string,database:string,query:string,username:string,password:string}) => (typeof option === 'string' ? option : `${option.query}`)}
	                    getOptionLabel={(option: string|{databaseType:number,databaseServer: string,database:string,query:string,username:string,password:string}) => (typeof option === 'string' ? option : `${option.query}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={t('dbQuery')} 
                        style={{backgroundColor:!form.values.queryButton ?'#f0f2f7':'',borderColor:'#c7cfdb'}}
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="query"
                        onBlur={form.handleBlur}
                        error={form.touched && form.touched.query && form.errors && Boolean(form.errors.query)}
                        helperText={form.touched.query && form.errors.query}
                    />)}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' className={classes.iconButton} disabled={!form.values.queryButton} onClick={handleDBButtonClick} style={{border:'solid 1px',borderColor:!DBButtonClick ?'#c7cfdb':'#2892d7', backgroundColor :!DBButtonClick?'#fafafc':'#e5f2fa'}}><TuneIcon style={{color:!DBButtonClick?'#8092a9':'#2892d7'}}/></Button>
                </Grid>
            </Grid>

            <Grid item container xs={12} spacing={2}>
                <Grid item xs={1}>
                <Tooltip title={t("webInfo")} arrow placement="top">
                    <Button variant='outlined' className={classes.iconButton} disabled={VeeamSqlDisable()} onClick={()=>handleButton("web")} style={{border:'solid 1px',borderColor:!form.values.webButton ?'#c7cfdb':'#96b403', backgroundColor :!form.values.webButton?'#fafafc':'#edf6e1'}}>
                        <WebportalIcon style={{color:form.values.webButton? '#8bc53f':'#8092a9'}}></WebportalIcon>
                    </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={10}>
                    <EDRAutocomplete id="websiteUrl"
                        freeSolo
                        disableClearable
                        fullWidth
                        size="small"
                        value={form.values.websiteUrl}
                        options={defaultSettings.PreviousWebPortal}
                        disabled={!form.values.webButton}
                        style={{backgroundColor:!form.values.webButton ?'#f0f2f7':'',borderColor:'#c7cfdb'}}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderOption={(option: string|{url: string,result:string}) => (typeof option === 'string' ? option : `${option.url}`)}
	                    getOptionLabel={(option: string|{url: string,result:string}) => (typeof option === 'string' ? option : `${option.url}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={t('url')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="websiteUrl"
                        onBlur={form.handleBlur}
                        error={form.touched && form.touched.websiteUrl && form.errors && Boolean(form.errors.websiteUrl)}
                        helperText={form.touched.websiteUrl && form.errors.websiteUrl}
                    />)}
                    />  
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' className={classes.iconButton} disabled={!form.values.webButton} onClick={handleWebButtonClick} style={{border:'solid 1px',borderColor:!WebButtonClick ?'#c7cfdb':'#2892d7', backgroundColor :!WebButtonClick?'#fafafc':'#e5f2fa'}}><TuneIcon style={{color:!WebButtonClick?'#8092a9':'#2892d7'}}/></Button>
                </Grid>
            </Grid>

            <Grid item container xs={12} spacing={2}>
                <Grid item xs={1} style={{display:'flex',flexDirection:"row"}}>
                <Tooltip title={t("scriptInfo")} arrow placement="top">
                    <Button variant='outlined' className={classes.iconButton} onClick={()=>handleButton("script")} style={{border:'solid 1px',borderColor:!form.values.scriptButton ?'#c7cfdb':'#96b403', backgroundColor :!form.values.scriptButton?'#fafafc':'#edf6e1'}}>
                        <PreScriptIcon style={{color:form.values.scriptButton? '#8bc53f':'#8092a9'}}></PreScriptIcon>
                    </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={10}>
                <FormControl className={classes.formControl} variant='outlined'>
                <InputLabel  disabled={!form.values.scriptButton} ref={ref => {labelRefScript = ReactDOM.findDOMNode(ref)}} style={{transform: 'translate(14px, -6px) scale(0.65)'}}>{t('uploadedScript')}</InputLabel>
                <EDROutlinedInput 
                        value={form.values.script}
                        labelWidth={labelRefScript ? labelRefScript.offsetWidth:0}
                        name="script"
                        type='text'
                        disabled={!form.values.scriptButton}
                        style={{paddingRight:'0px',backgroundColor:!form.values.scriptButton ?'#f0f2f7':'',borderColor:'#c7cfdb',height:'39px'}}
                        placeholder=""
                        onChange={handleScriptChange}
                        onBlur={form.handleBlur}
                        error={form.touched && form.touched.script && form.errors && Boolean(form.errors.script)}
                        endAdornment={
                        <InputAdornment position="end">
                            {device!=undefined && ShowBrowse === true ?<Button className={classes.BrowseButton} size="small" onClick={handleBrowseScript} style={{height: '38px'}}>{ t("browse")}</Button>
                             :<Button size="small" className={classes.BrowseButton} onClick={removeFile} style={{height: '38px'}}>{t("remove")}</Button>}
                        </InputAdornment>
                        }
                    /> 
                    </FormControl> 
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' className={classes.iconButton} disabled={!form.values.scriptButton} onClick={handleScriptButtonClick} style={{border:'solid 1px',borderColor:!ScriptButtonClick ?'#c7cfdb':'#2892d7', backgroundColor :!ScriptButtonClick?'#fafafc':'#e5f2fa'}}><TuneIcon style={{color:!ScriptButtonClick?'#8092a9':'#2892d7'}}/></Button>
                </Grid>
            </Grid>

            <Grid item container xs={12} spacing={2}>
                <Grid item xs={1}>
                <Tooltip title={t("authenticationInfo")} arrow placement="top">
                    <Button variant='outlined' className={classes.iconButton} disabled={VeeamSqlDisable()} onClick={()=>handleButton("credentials")} style={{border:'solid 1px',borderColor:!form.values.credentialsButton ?'#c7cfdb':'#96b403', backgroundColor :!form.values.credentialsButton?'#fafafc':'#edf6e1'}}>
                        <AuthenticationIcon style={{color:form.values.credentialsButton ?'#8bc53f':'#8092a9'}}></AuthenticationIcon>
                    </Button>
                    </Tooltip>
                </Grid>
                <Grid item container xs={10}>
                <Grid item container spacing={2}>
                    <Grid item xs={6}>
                    <EDRAutocomplete id="username"
                        freeSolo
                        disableClearable
                        fullWidth
                        size='small'
                        value={form.values.username}
                        options={defaultSettings.PreviousCredentials}
                        disabled={!form.values.credentialsButton}
                        style={{backgroundColor:!form.values.credentialsButton ?'#f0f2f7':'',borderColor:'#c7cfdb'}}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderOption={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
	                    getOptionLabel={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={t('usernameLabel')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="username"
                        aria-autocomplete="none"
                        onBlur={form.handleBlur}
                        error={form.touched && form.touched.username && form.errors && Boolean(form.errors.username)}
                        helperText={form.touched.username && form.errors.username}
                    />)}
                    /> 
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl className={classes.formControl} variant='outlined'>
                    <InputLabel  disabled={!form.values.credentialsButton} ref={ref => {labelRef = ReactDOM.findDOMNode(ref)}} style={{transform: 'translate(14px, -6px) scale(0.65)'}}>{ t("passwordLabel")}</InputLabel>
                    <EDROutlinedInput id="password" 
                        name="password"
                        value={form.values.password}
                        labelWidth={labelRef ? labelRef.offsetWidth:0}
                        disabled={!form.values.credentialsButton}
                        autoComplete="off"
                        aria-autocomplete="none"
                        style={{backgroundColor:!form.values.credentialsButton ?'#f0f2f7':'',borderColor:'#c7cfdb',height:'39px'}}
                        type={passwordVisibility ? 'password':'text'}
                        placeholder="Type Here"
                        error={form.touched.password && Boolean(form.errors.password)}
                        onChange={handlePasswordChange}
                        onClick={handleMouseClick}
                        onBlur={handleMouseOut}
                        endAdornment={form.values.password && UserPassAutocomplete ?
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            aria-autocomplete="none"
                            onClick={()=>handleClickShowPassword()}
                            >
                            {passwordVisibility ?  <VisibilityOff />: <Visibility />}
                            </IconButton>
                    </InputAdornment>:null}
            />  
          </FormControl>
                    </Grid>
                </Grid>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' className={classes.iconButton} disabled={!form.values.credentialsButton} onClick={handleAuthButtonClick} style={{border:'solid 1px',borderColor:!AuthButtonClick ?'#c7cfdb':'#2892d7', backgroundColor :!AuthButtonClick?'#fafafc':'#e5f2fa'}}><TuneIcon style={{color:!AuthButtonClick?'#8092a9':'#2892d7'}}/></Button>
                </Grid>
            </Grid>
          
        </Grid>
        <Grid item xs style={{display:'flex'}}>
            <Divider orientation="vertical" style={{marginLeft:'10%'}}/>
            {loadRightSideDrawer()}
        </Grid>
        </Grid>
        }
        </DialogContent>
        
        <DialogActions>
            <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item container xs={6} style={{display:'flex',justifyContent:'flex-end',marginRight:'46px'}}>
            <Button onClick={handleCancle} color="primary" style={{textTransform:'none'}}>
            {t('cancel')}
            </Button>
            <SaveButton color="primary" variant="contained" disabled={disableSave()} onClick={handleSave} style={{textTransform:'none'}}>
            {t('save')}
            </SaveButton>
       </Grid>
        </DialogActions>
    </Dialog>
}

export default EditDeviceDialog;

