import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
//import { callMatomo } from "../../../app/stores/root.store";
import DateRangeIcon from "../../../assets/dashboardAssets/3dotCalendar.svg";
import CheckCircleIcon from "../../../assets/dashboardAssets/v.svg";
import  CancelIcon  from "../../../assets/dashboardAssets/false.svg";
import { INotice } from "../../../app/models/dashboard/dashboard.model";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { NavLink } from "react-router-dom";
import Cog from "../Cog.component";

const AuditComponent = (props: INotice) => {
  
  useEffect(() => {
    //callMatomo("Notice","/dashboard");
  }, []);

  const stateicon = props.goodStateIcon === true ? CheckCircleIcon : CancelIcon;

  const useStyles = makeStyles({
    icon: { 
      width: "14px",
      height: "14px", 
      marginTop:"1em",
      marginRight:"0.5em", 
      objectFit: "contain"
    }, 
  });

  const classes = useStyles();

  return (
    <Grid data-test="component-audit">
      <Grid item container>
        <Grid item style={{ width: "70%",paddingLeft: "24px"}}>
          <img src={DateRangeIcon} className={classes.icon} />
          <span className="dashboard_header_icon">{props.header}</span>
        </Grid>
  
      </Grid>
      <Grid item container>
          <Grid item style={{ width: "70%",paddingLeft: "24px"}}>
            {props.mainText.length > 0 ? 
              <span className="boldText">{props.mainText}</span> : 
            ""}          
          </Grid>
          <Grid item style={{ width: "30%"}}>
            <span style={{float: "right",paddingRight:"1em"}}>{<img src={stateicon}/>}</span>
          </Grid>
      </Grid>
      <Grid item container>
          <Grid item style={{paddingLeft: "24px"}}>
          {props.secondaryText.length > 0 ? 
            <span className="dashboard_noticepanel_text">{props.secondaryText}</span>: 
          ""}
          </Grid>
      </Grid>
    </Grid>
  );
};

export default observer(AuditComponent);
