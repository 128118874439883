import { useEffect, useContext, useState, useRef } from 'react'
import AssetAgent from '../../../../app/api/assetsAgent';

import { DeviceType } from '../../../../app/models/deviceType.enum';
import { NetworkOption } from '../../../../app/models/jobProperties.model';
import { RootStoreContext } from '../../../../app/stores/root.store'
type DcValidation = { [key: string]: { isValid: boolean, isValidating: boolean }};
function useDCValidation() {
    const [dcValidations, setdcValidations] = useState<DcValidation>({});
    const dcValidationRef = useRef<DcValidation>({});
    const [areDCsValid, setDCsValid] = useState(false);

    const { createJobStore, jobSettingsStore } = useContext(RootStoreContext);
    const { selectedDevices } = createJobStore;
    const { selectedDC, isFirstTrial: isTrial } = jobSettingsStore;
    const isDCValidationRequired = () => {
        if (isTrial)
            return false;
        if(createJobStore.networkSetting.NetworkType === NetworkOption.None) 
            return false;
        if (selectedDC?.domainControllerName.includes('I have a Domain Controller inside my replication'))
            return true;

        return false;
    }


    useEffect(() => {
        let cancelled = false;

        if (!isDCValidationRequired()) {
            setDCsValid(true);
            setdcValidations({});

            return;
        }
        const dcs = selectedDevices.filter(d => d.deviceType === DeviceType.DCVirtual || d.deviceType === DeviceType.DCPhysical || d.showDeviceAsDC);
        if (!dcs.length) {
            setDCsValid(false);
            setdcValidations({});
        }
        if (dcs.length) {
            const _dcValidations: { [key: string]: { isValid: boolean, isValidating: boolean } } = {}
            dcs.reduce((acc, dc) => {
                acc[dc.sourceName] = { isValid: false, isValidating: true };
                return acc;
            }, _dcValidations);
            dcValidationRef.current = _dcValidations;
            const username = createJobStore.baseSettings.workflowTest.PrimaryUser;
            const password = createJobStore.baseSettings.workflowTest.PrimaryPassword;
            const domainCredsId = createJobStore.baseSettings.workflowTest.PrimaryUserCredsId;
            const _validationRequests = dcs.map(dc => {
                return new Promise(async (res, rej) => {
                    try {
                        const domainName = dc.sourceName.split('.').slice(1).join('.');
                        const isDCValid = await AssetAgent.TestDomainController({ domainName, domainControllerName: dc.sourceName, username, password, domainCredsId })
                        dcValidationRef.current[dc.sourceName] =  { isValid: isDCValid, isValidating: false };
                        res(isDCValid);
                    }
                    catch {
                        dcValidationRef.current[dc.sourceName] =  { isValid: true, isValidating: false };

                        rej(`DC: ${dc.sourceName} validation failed`);
                    }
                })
            })
            Promise.allSettled(_validationRequests).then(res => {
              setdcValidations(dcValidationRef.current);
                if (cancelled)
                    return;
                // if atleast one DC is validated then we are OK    
                if (res.map(r => r.status === 'fulfilled' ? r.value: false).find(isValid => isValid)) {
                    setDCsValid(true);
                } else {
                    setDCsValid(false);
                }
            }).catch(err => {
                if (cancelled)
                    return;
                setDCsValid(false);
            })
        }
        return () => {
            cancelled = true;
        }
    }, [selectedDevices])

    useEffect(() => {
        const dcs = selectedDevices.filter(d => d.deviceType === DeviceType.DCVirtual || d.deviceType === DeviceType.DCPhysical);
        jobSettingsStore.updateDCValidation({ dcSelected: dcs.length > 0, dcValid: areDCsValid });

    }, [areDCsValid, selectedDevices])

    return { dcValidations, areDCsValid };

}

export default useDCValidation;