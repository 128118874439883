import { settingObj, IPhoto } from '../models/setting.model';
import agentbase from './agentBase';

const requests = agentbase.requests;

const Setting = {
    get: (): Promise<Array<settingObj>> => requests.get(`/setting`),
    update: (settings: Array<settingObj>): Promise<string> => requests.put(`/setting`, settings),
    getSingle: ( keyname: string): Promise<settingObj> => requests.get(`/setting/${keyname}`),
    uploadPhoto: (photo: Blob): Promise<IPhoto> => requests.postForm(`/photos`, photo) ,
    getPhoto: (): Promise<IPhoto> => requests.getPhoto(`/photos`),
    sendTestEmail: (settings: any): Promise<any> => requests.post(`/setting/sendtestmail`,settings),
    sendSystemMail:(): Promise<any> => requests.post(`/setting/sendsystemmail`,null),
    sendSupportMail:(supportDetails:any):Promise<any> => {return requests.post('/setting/sendsupportmail',{...supportDetails})},
    delete:(key:string): Promise<any> => requests.del(`/setting/${key}`,null),
    deleteOldAnimations: (day: number, month: number, year: number): Promise<any> => requests.del(`/setting/deleteOldAnimations/${day}/${month}/${year}`, null)
}

export default {
    Setting,
}