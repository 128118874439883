import { Button, Card, CircularProgress, Divider, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { toast } from 'react-toastify';
import { runInAction } from 'mobx';
import Create from '@material-ui/icons/Create';
import { RootStoreContext } from '../../../../app/stores/root.store';
import JobSettingsStore from '../../../../app/stores/jobSettings.store';
import { AssetDTO } from '../../../../app/models/createjob/createJob.model';
import { EDRTextField } from "../../../../app/common/EDRTextField";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EDRAutocomplete } from '../../../../app/common/EDRAutocomplete';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { debounce } from 'lodash';
import assetsAgent from '../../../../app/api/assetsAgent';
import { LicenseType } from '../../../../app/models/jobProperties.model';
import LockIcon from '@material-ui/icons/Lock';
import { DataMover } from '../../../../app/models/BaseSettings.enum';

const useStyles = makeStyles({
    editIcon: {
        cursor: 'pointer'
    },
    button: {
        width: '50%',
        height: '100%',
        borderRadius: '4px',
        border: 'solid 1px #c7cfdb',
        backgroundColor: '#fafafc'
    },
    advanced: {
        width: '14px',
        height: '14px',
        objectFit: 'contain',
    },
    MainHeading: {
        fontFamily: 'Roboto',
        color: '#1f396d',
        fontSize: '16px'
    },
    SecondHeading: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '100%',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.14',
        letterSpacing: 'normal',
        textAlign: 'left'
    },
    card: {
        padding: 25,
        height: '100%',
    },
    divider: {
        backgroundColor: '#eaeef4',
        width: '75%',
        marginTop: '8px',
        marginLeft: '7px',
        marginRight: '-107px'
    },
    helpIcon:
    {
        color: '#1976d2',
        fontSize: "large"
    },
    formControl: {
        width: '100%',
        '& label': {
            background: "white",
            width: "auto",
            padding: "0 4px 0 4px",
            fontSize: '17px'
        }
    },
    formControlEDRCName: {
    width: '100%',
    '& label': {
        background: "white",
        width: "auto",
        padding: "0 4px 0 2px",
        fontSize: '17px'
    },
    '& .Mui-error': {
        color: 'red',
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: 'red'
    }
    },
    validateIcon: {
      position: 'absolute',
      top: 33,
      right:8,
      color: '#8bc53f'
    },
    validationStatus: {
        color: 'red'
      }
});

const EDRCCreateComponent: React.FC = () => {

    const classes = useStyles();
    const [t] = useTranslation("createJobNetwork");
    const rootStore = useContext(RootStoreContext);
    const {jobSettingsStore,displayStore,createJobStore} = rootStore;
    const {isFirstTrial} = jobSettingsStore;
const getErrorText = (err:string) => {
    if(err === "NoNetworkConnection")
        return "No Network Connection to EDRC";
    if(err === "EDRCVMNameMissing")
        return "EDRC VM Name is missing";
    if(err === "FailedToConnectInfra")
        return "Failed to connect to EDRC Infra";
    if(err === "EDRCNotFoundOnTargetInfra")
        return "EDRC VM not found on traget infra";
}
    const validateEDRC = async (value, resolve,context) => {
        if(createJobStore.baseSettings.dataMover === DataMover.AWSDRS || createJobStore.baseSettings.dataMover === DataMover.VmwareAWS)
        {
            displayStore.updateNextStepValid({edrcValid:true})
            resolve(true);
            return;
        }
        let edrcVmName = context.parent.EDRCVMName
        try {
          if (!value || !edrcVmName) {
            return;
          }
          await assetsAgent.ValidateEDRC({id:0,assetName:value,assetVMName:edrcVmName,infraId:createJobStore.baseSettings.infrastructureId}).then(res => {
            if(res.data.isValid === true)
            {
                displayStore.updateNextStepValid({edrcValid:true})
                resolve(true);
            }
            else
            {
                displayStore.updateNextStepValid({edrcValid:false})
                resolve(false);
            }
          }).catch(err=> {
            if(err && err.data && err.data.errors)
            {
                toast.error(`${value} Validation failed - ${err.data.errors}`)
            }
            displayStore.updateNextStepValid({edrcValid:false});
          });
          
        } catch (error) {
          resolve(false);
        }
        };
     const edrcValidation = debounce(validateEDRC, 800, {
         'leading': false,
         'trailing': true
         });

const validation = yup.object({
    EDRCName:yup.string().required('Required').nullable()
    .test('empty','EDRC Name must be not empty',EDRCName => EDRCName!='')
    .max(50,'up to 50 characters').when("EDRCVMName",{
        is: p => p,
        then: yup.string().test("validateEDRC","EDRC Validation Failed",function(value,context) {
            return new Promise(res => edrcValidation(value, res,context))
        })
    }),
    EDRCVMName:yup.string().required('Required').nullable()
    .test('empty','EDRC VM name must be not empty',EDRCVMName => EDRCVMName!='')
    .max(50,'up to 50 characters')
    })

    useEffect(() => {
        let initialValues ={
                Id:jobSettingsStore.tempEDRC && jobSettingsStore.tempEDRC.id,
                EDRCName:jobSettingsStore.tempEDRC && jobSettingsStore.tempEDRC.assetName,
                EDRCVMName:jobSettingsStore.tempEDRC && jobSettingsStore.tempEDRC.assetVMName
        };
        form.setValues(initialValues);
    },[])
    
    const form = useFormik({
        initialValues: 
        {
            EDRCName:'',
            EDRCVMName:'',
        },
        validationSchema:validation,
        validateOnChange: true,
        onSubmit: () => { },
    })

    const handlehostnameIPChange =(event:React.ChangeEvent,value:any)=> 
    {
        if(event!=null)
        {
            form.setFieldValue("EDRCName",value=="" ? '':value,true);
            jobSettingsStore.updateTempEDRC({ assetName:value});
        }
    }

    const handleDisplayNameChange =(event:React.ChangeEvent,value:any)=>{
        if(event!=null)
        {
            form.setFieldValue("EDRCVMName",value=="" ? '':value,true);
            jobSettingsStore.updateTempEDRC({ assetVMName:value});
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{padding: '0 0 0 80px'}}>
                    {form.isValidating && <Typography variant="body2" className={classes.validationStatus}>Validating EDRC...</Typography>}
                </Grid>
            </Grid>
            <Grid container spacing={2}>
            
            <Grid item xs={1} style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                                    {isFirstTrial  ? 
                                    <Tooltip title={t("trialLock")} arrow>
                                        <LockIcon style={{marginLeft:'1%'}}/>
                                    </Tooltip>
                                    : null}
                                    </Grid>
                                    <Grid item container xs={10} spacing={2}>
            <Grid item xs={6}>
            <Tooltip title={"EDRC"} placement="top">
            <FormControl className={classes.formControlEDRCName} variant='outlined'> 
            <EDRAutocomplete id="servername"
                freeSolo
                disableClearable
                value={form.values && form.values.EDRCName}
                options={[]}
                renderOption={(option: string|{EDRCName: string}) => (typeof option === 'string' ? option : `${option.EDRCName}`)}
                getOptionLabel={(option: string|{EDRCName: string}) => (typeof option === 'string' ? option : `${option.EDRCName}`)}
                onChange={handlehostnameIPChange}
                onInputChange={handlehostnameIPChange}
                onBlur={()=>{
                form.setFieldTouched("EDRCName", true)
                }}
                disabled={true}
                renderInput={(params) => (<EDRTextField {...params}
                label= {t('hostnameIP')}
                InputLabelProps={{shrink: true}} 
                margin="normal" 
                variant="outlined"
                placeholder="EDRC Name" 
                aria-autocomplete="none"
                error={form.touched && form.touched.EDRCName && form.errors && Boolean(form.errors.EDRCName)}
                helperText={form.touched && form.touched.EDRCName && form.errors && Boolean(form.errors.EDRCName) && t(form.errors.EDRCName)}
                />)}
            />
            <React.Fragment>{!form.isValidating && displayStore.nextStepValid.edrcValid && <CheckCircle className={classes.validateIcon} />}</React.Fragment>
            </FormControl>
            </Tooltip>
            </Grid> 

            <Grid item xs={6}> 
            <Tooltip title={"EDRC Name"} placement="top">
            <FormControl className={classes.formControl} variant='outlined' style={{width:'97%'}}> 
            <EDRAutocomplete id="servername"
                freeSolo
                disableClearable
                value={form.values && form.values.EDRCVMName}
                options={[]}
                renderOption={(option: string|{EDRCVMName: string}) => (typeof option === 'string' ? option : `${option.EDRCVMName}`)}
                getOptionLabel={(option: string|{EDRCVMName: string}) => (typeof option === 'string' ? option : `${option.EDRCVMName}`)}
                onChange={handleDisplayNameChange}
                onInputChange={handleDisplayNameChange}
                onBlur={()=>{
                form.setFieldTouched("EDRCVMName", true)
                }}
                disabled={true}
                renderInput={(params) => (<EDRTextField {...params}
                label={t('displayName')}
                // style={{backgroundColor:(displayStore.sectionSettings.WorkflowSection ? 'rgb(233, 235, 238)': '#ffffff')}}
                InputLabelProps={{shrink: true}} 
                margin="normal" 
                variant="outlined"
                placeholder="EDRC VM Name" 
                aria-autocomplete="none"
                error={form.touched && form.touched.EDRCVMName && form.errors && Boolean(form.errors.EDRCVMName)}
                helperText={form.touched && form.touched.EDRCVMName && form.errors && Boolean(form.errors.EDRCVMName) && t(form.errors.EDRCVMName)}
                />)}
            />
            <React.Fragment>{!form.isValidating && displayStore.nextStepValid.edrcValid && <CheckCircle className={classes.validateIcon} />}</React.Fragment>
            </FormControl>
            </Tooltip>
            </Grid>
           </Grid>
            
          </Grid>        
        </Grid>
    
    );
};

export default observer(EDRCCreateComponent);