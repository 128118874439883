import { Card, Grid, makeStyles, Typography} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const EmptyVlanComponent: React.FC = (open) => {

    const useStyles = makeStyles({
        SecondHeading:{
            fontFamily:'Roboto',
            fontWeight:500,
            fontSize:'100%',
            fontStretch:'normal',
            fontStyle: 'normal',
            lineHeight:'1.14',
            letterSpacing:'normal',
            textAlign:'left'
        },
        card: {
            padding: 16,
            width: '100%',
            height: '100%'
        },
        advanced:{
            width: '70px',
            height: '40px',
            objectFit: 'contain',
            marginTop: '15px'
        },
        circle:{
            width: '120px',
            height: '120px',
            padding: '24px',
            marginTop: '150%',
            marginLeft: '50px',
            borderRadius: '50%',
            backgroundColor: '#fafafc'
        }
        });
        
    const classes = useStyles();
    const [t] = useTranslation("createJobNetwork");
    
    return(
             <Grid container style={{display: 'flex', flexDirection: 'row',justifyContent: 'center' , alignItems: 'center'}}>
                <Grid item>
                    <div className={classes.circle}><img className={classes.advanced} src='/assets/images/createjob/advancedNetworkGrey.svg'></img></div>
                    <Typography variant='h6' className={classes.SecondHeading} style={{marginTop:'15px',marginLeft: '18px'}}>{t('selectOption')}</Typography>
                </Grid>
             </Grid> 
    );
};

export default observer(EmptyVlanComponent);