import React from 'react'
import { Grid, Paper, Typography, makeStyles, LinearProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ResourceProgressBar from '../../app/common/drResource/dRProgressbar.component';
import { CPUIcon, IopsIcon, RamIcon } from '../../app/common/drResource/resourcesIcon.component';
import { InternetConnectionIcon} from '../Icons';
import clsx from 'clsx';
import { LastJobRunStatus } from '../../app/models/lastjobRun.model';
import { cpus } from 'os';

const useStyles = makeStyles({
    icon: {
        color: "#8092a9"
    },
    disabled: {
        color: "lightGray"
    },
    h50: {
        flexGrow: 1
    },
    testIcon: {
        fontSize: 18
    },
    passed: {
        color: '#8bc53f'
    },
    failed: {
        color: '#8bc53f'
    },
    iconDiv:{
        paddingTop: '10px'
    },
    text: {
        color: "#4d5f75",
      },
      alignCenter: {
        alignSelf: "center",
        paddingRight:'5%'
      },
      colorPrimary: {
        backgroundColor: "#8bc53f",
        "& ::after": {
          content: '""',
          right: 0,
          width: 2,
          backgroundColor: "white",
        },
      },
      colorPrimaryDisabled: {
        backgroundColor: "lightGray",
        "& ::after": {
          content: '""',
          right: 0,
          width: 2,
          backgroundColor: "white",
        },
      },
      barColorPrimary: {
        backgroundColor:"#8bc53f",
      },
      barColorPrimaryDisabled: {
        backgroundColor: 'lightGray',
      },
});


type DRSectionProps = {
    iops: number,
    iopsMax: number,
    cpu: number,
    cpuMax: number,
    ram: number,
    rammax: number,
    jobStatus: LastJobRunStatus
}

const DRResourceSectionMultipleComponent: React.FC<DRSectionProps> = (props) => {
    const { iops, iopsMax, cpu, cpuMax, ram, rammax, jobStatus } = props;
    const [t] = useTranslation("report");
    const classes = useStyles();
    const { testIcon, disabled, passed, failed } = classes;


    
    return (
        <Grid container direction="row">
        
        <Grid container direction="row">
        <Grid item xs={3}>
            <IopsIcon className={clsx([testIcon, { [passed]:iops>0 && iops > iopsMax / 2, [failed]:iops>0 && iops <= iopsMax / 2, [disabled]: iops == 0 }])} style={{marginTop:'5px',paddingTop:'5px'}}/> 
            <span className={classes.text}>{"IOPS"}</span>
        </Grid>
        <Grid item xs={9} className={classes.alignCenter}>
          <LinearProgress
            variant="determinate"
            value={iops/iopsMax}
            style={{ borderRadius:'3px'}}
            classes={{
              colorPrimary: jobStatus == null ? classes.colorPrimaryDisabled : classes.colorPrimary,
              barColorPrimary:jobStatus == null ? classes.barColorPrimaryDisabled:classes.barColorPrimary,
            }}
          ></LinearProgress>
        </Grid>
            </Grid>

        <Grid container direction="row">
        <Grid item xs={3}>
            <CPUIcon className={clsx([testIcon, { [passed]:cpu>0 && cpu > cpuMax / 2, [failed]:cpu>0 && cpu <= cpuMax / 2, [disabled]: cpu == 0 }])} style={{marginRight:'5px'}} /> 
            <span className={classes.text}>{"CPU"}</span>
        </Grid>
        <Grid item xs={9} className={classes.alignCenter}>
          <LinearProgress
            variant="determinate"
            value={cpu/cpuMax}
            style={{ borderRadius:'3px'}}
            classes={{
              colorPrimary: jobStatus == null ? classes.colorPrimaryDisabled : classes.colorPrimary,
              barColorPrimary: jobStatus == null ? classes.barColorPrimaryDisabled:classes.barColorPrimary,
            }}
          ></LinearProgress>
        </Grid>
        </Grid>

        <Grid container direction="row">
        <Grid item xs={3}>
            <RamIcon className={clsx([testIcon, { [passed]:ram>0 && ram > rammax / 2, [failed]:ram>0 && ram <= rammax / 2, [disabled]: ram == 0 }])} style={{marginRight:'5px'}}/> 
            <span className={classes.text}>{"RAM"}</span>
        </Grid>
        <Grid item xs={9} className={classes.alignCenter}>
          <LinearProgress
            variant="determinate"
            value={ram/rammax}
            style={{ borderRadius:'3px'}}
            classes={{
              colorPrimary: jobStatus == null ? classes.colorPrimaryDisabled : classes.colorPrimary,
              barColorPrimary:jobStatus == null ? classes.barColorPrimaryDisabled:classes.barColorPrimary,
            }}
          ></LinearProgress>
        </Grid>
        </Grid>

        </Grid>
    )

};

export default DRResourceSectionMultipleComponent;