import { useMemo } from 'react';
import { DataMover } from '../../../../app/models/BaseSettings.enum';
const DEFAULT  = {
  target: "dataMover.common.target",
  source: "dataMover.common.source",
  sourceUsername: "dataMover.common.sourceUsername",
  sourcePassword: "dataMover.common.sourcePassword",
  targetUsername: "dataMover.common.targetUsername",
  targetPassword: "dataMover.common.targetPassword",
  targetPort: "dataMover.common.targetPort",
  sourcePort: "dataMover.common.sourcePort"
}
export function useLabels(dataMover: DataMover) {

  const translations = useMemo(() => {
    switch (dataMover) {
      case DataMover.VeeamSQL: return {
        ...DEFAULT,
        target: "dataMover.veeamSql.target",
        source: "dataMover.veeamSql.source",
        sourceUsername: "dataMover.veeamSql.sourceUsername",
        sourcePassword: "dataMover.veeamSql.sourcePassword",
        targetUsername: "dataMover.veeamSql.targetUsername",
        targetPassword: "dataMover.veeamSql.targetPassword"
      };
      case DataMover.VeeamVmware: return {
        ...DEFAULT,
        target: "dataMover.veeam.target",
        source: "dataMover.veeam.source",
        sourcePort: "dataMover.veeam.sourcePort",
      };
      case DataMover.ASR: return {
        ...DEFAULT,
        target: "dataMover.azure.target",
        source: "dataMover.azure.source",
        sourceUsername: "dataMover.azure.sourceUsername",
        sourcePassword: "dataMover.azure.sourcePassword",
        targetUsername: "dataMover.azure.targetUsername",
        targetPassword: "dataMover.azure.targetPassword"
      };      
      case DataMover.CohesityRunbook: return {
        ...DEFAULT,
        sourceUsername: "dataMover.cohesityRunbook.sourceUsername",
        sourcePassword: "dataMover.cohesityRunbook.sourcePassword",
        targetUsername: "dataMover.cohesityRunbook.targetUsername",
        targetPassword: "dataMover.cohesityRunbook.targetPassword"
      };
      default: return {
        ...DEFAULT
      };
    }
  }, [dataMover]);

  return translations;
}
