import { Box, Button, Card, Checkbox, FormControlLabel, Grid, makeStyles, MenuItem, Select, TextField, Typography, withStyles } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import clsx from 'clsx';
import { RootStoreContext } from "../../../app/stores/root.store";
import { useTranslation } from "react-i18next";
import { ScheduleRepeatType } from "../../../app/models/jobProperties.model";
import moment from 'moment';
import { DataMover } from "../../../app/models/BaseSettings.enum";
import { runInAction } from "mobx";
//import { Props } from "./ReportPreview.component";
import { truncate } from "fs";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { EDRTextField } from "../../../app/common/EDRTextField";

interface Props {
    onChange: (val) => void;
}

const ScheduleJobPreviewComponent: React.FC<Props> = (props) => {
    const { onChange } = props;
    const repeatType = ['Day','Week','Month',"One Time"];
    const rootStore = useContext(RootStoreContext);
    const {displayStore} = rootStore;
    const isPaaS= rootStore.createJobStore.baseSettings.dataMover== DataMover.PaaS;
    const [scheduleState, setScheduleState] = useState(rootStore.createJobStore.jobScheduleSetting.setSchedule);
    const [scheduleTimeState, setScheduleTimeState] = useState(rootStore.createJobStore.jobScheduleSetting.nextRun);
    const [repeatState, setRepeatState] = useState(true);
    const [scheduleIntervalState, setScheduleIntervalState] = useState(rootStore.createJobStore.jobScheduleSetting.repeatInterval);
    const [scheduleTypeState, setScheduleTypeState] = useState(ScheduleRepeatType[rootStore.createJobStore.jobScheduleSetting.scheduleRepeatType]);
    
    const CustomCheckbox = withStyles({
        root: {
          color: '#4d5f75',
          '&$checked': {
            color: displayStore._disableSectionSave || displayStore._disablePreview ? 'rgb(233, 235, 238)':'#2892d7',
          },
        },
        checked: {},
      })(Checkbox);
    
    const useStyles = makeStyles((theme) => ({
        select:{
            padding: '11px 14px'
        },
        h33: {
            height: '33.33%'
        },
        h66: {
            height: '66.66%'
        },
        scheduleJobCard: {
            height: '100%',
           // marginTop: isPaaS ? '4px':'0px',
            padding: 16,
            width: '100%',
            background: theme.palette.primary.main,
            '& > *': {
                color: 'white'
            },
            boxSizing: 'border-box'
        },
        text: {
            fontFamily:'Roboto',
            fontWeight:500,
            fontSize:'13px',
            fontStretch:'normal',
            fontStyle: 'normal',
            lineHeight:'1.14',
            letterSpacing: '0.13px',
            textAlign:'left',
            color: '#4d5f75'
        },
        errorText:{
            color:'red',
            marginTop:'10px',
            fontSize: '0.75rem',
            textAlign: 'left',
            fontFamily: "Roboto",
            fontWeight: 400,
            lineHeight: 1.66,
            letterSpacing: '0.03333em'
          },
        heading: {
            color: '#ffffff',
            fontWeight: 500,
        },
        input: {
            color: "white"
        },
        formControl: {
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            '& label': {
              background: "white",
              width: "auto",
              padding: "0 4px 0 4px",
              fontSize: '17px',
              color: '#ffffff'
            }
        },
        textfield:{
            '& .MuiOutlinedInput-input': {
                padding: '11.5px 14px',
            }
        }
        }));
    const classes = useStyles();
    const [t] = useTranslation("CreateJobPreview");
    
    useEffect(() => {
        setTimeout(() => populateFields(), 1000);
        return(() => {
            clearTimeout();
        })
    }, []);

    useEffect (()=>{
        if(repeatState === true && isNaN(rootStore.createJobStore.jobScheduleSetting.repeatInterval)) 
            onChange(false);
        else
            onChange(true);
    },[repeatState,scheduleIntervalState])

    const populateFields = () => {
        if(rootStore.createJobStore.jobScheduleSetting.setSchedule != null)
        {
            setScheduleState(rootStore.createJobStore.jobScheduleSetting.setSchedule);
            if(rootStore.createJobStore.jobScheduleSetting.setSchedule)
            {
                if(rootStore.createJobStore.jobScheduleSetting.nextRun != null)
                {
                    var momentVariable = moment(rootStore.createJobStore.jobScheduleSetting.nextRun).format('MM/DD/YYYY HH:mm');
                    setScheduleTimeState(momentVariable);
                    rootStore.createJobStore.updateJobScheduleSetting({nextRun : momentVariable});
                }
                else
                {
                    var momentVariable = moment(Date.now()).format('MM/DD/YYYY HH:mm');
                    setScheduleTimeState(momentVariable);
                    rootStore.createJobStore.updateJobScheduleSetting({nextRun : momentVariable});
                }
            }
            else
            {
                var momentVariable = moment(Date.now()).format('MM/DD/YYYY HH:mm');
                setScheduleTimeState(momentVariable);
                rootStore.createJobStore.updateJobScheduleSetting({nextRun : ""});
            }
        }
        if(rootStore.createJobStore.jobScheduleSetting.setRepeat!=null)
            setRepeatState(rootStore.createJobStore.jobScheduleSetting.setRepeat);
        if (rootStore.createJobStore.jobScheduleSetting.repeatInterval != null)
            setScheduleIntervalState(rootStore.createJobStore.jobScheduleSetting.repeatInterval);
        if(rootStore.createJobStore.jobScheduleSetting.scheduleRepeatType != null)
            setScheduleTypeState(ScheduleRepeatType[rootStore.createJobStore.jobScheduleSetting.scheduleRepeatType]);
      
    }
    
    const handleScheduleChange = (event) =>{
        if(rootStore.createJobStore.jobScheduleSetting.nextRun == null)
        {
            var momentVariable =moment(Date.now());  
            var stringvalue = momentVariable.format('dd/MM/yyyy hh:mm a');
            setScheduleTimeState(momentVariable.format('YYYY-MM-DDTHH:mm'));
            rootStore.createJobStore.updateJobScheduleSetting({nextRun : stringvalue});      
        }
        setScheduleState(event.target.checked);
        let val= event.target.checked;
        if(event.target.checked==false && repeatState==true)
        {
            setRepeatState(false);
            val=false;
        }
        rootStore.createJobStore.updateJobScheduleSetting({setSchedule : val});      
    }

    const handleRepeatChange =(event)=> {
        setRepeatState(event.target.checked);
        let val= event.target.checked==true ? event.target.checked: false;
        rootStore.createJobStore.updateJobScheduleSetting({setRepeat : val});  
    }

    const handleScheduleTimeChange = (date) =>{
        setScheduleTimeState(date);
        rootStore.createJobStore.updateJobScheduleSetting({nextRun : moment(date.toString()).format('MM/DD/YYYY HH:mm')});   
    }

    const handleScheduleIntervalChange = (event) =>{
        setScheduleIntervalState(event.target.value);
        rootStore.createJobStore.updateJobScheduleSetting({ repeatInterval: parseInt(event.target.value) });   
    }

    const handleScheduleTypeChange = (event) =>{
        setScheduleTypeState(event.target.value);
        let val;
        switch(event.target.value){
            case 'Day':
                val=ScheduleRepeatType.Day;
                break;
            case 'Week':
                val=ScheduleRepeatType.Week;
                break;
            case 'Month':
                val=ScheduleRepeatType.Month;
                break;
            case 'One Time':
                val=ScheduleRepeatType.OneTime;
                break;
        }
        rootStore.createJobStore.updateJobScheduleSetting({ scheduleRepeatType: val});   
    }

    return (
        <Grid container direction="row" style={{marginTop:8}}>
        
        <Grid item xs={4} direction="row" style={{ display: 'flex' }}>
        <FormControlLabel 
        value="Set Schedule"
                            control={<CustomCheckbox checked={scheduleState} disabled={true} onChange={handleScheduleChange}/>}
                            label={<Typography className={classes.text}>{t('setSchedule')}</Typography>}
                            labelPlacement="end"/>
        </Grid>
        <Grid item xs={8} direction="row">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
            id="datetime-schedule"
            size="small"
            fullWidth
            format="MM/dd/yyyy hh:mm a"
            value={scheduleTimeState}
            disabled={true}
            onChange={handleScheduleTimeChange}
            style={{ width:'82%',backgroundColor:'rgb(233, 235, 238)'}}
            InputLabelProps={{
                shrink: true,
                className: classes.input,
                }}
                inputVariant="outlined"
        
            />
            </MuiPickersUtilsProvider>
            {/* <TextField
                        id="datetime-schedule"
                        type="datetime-local"
                        size="small"
                        fullWidth
                        value={scheduleTimeState}
                        onChange={handleScheduleTimeChange}
                        style={{ width:'82%',backgroundColor:!scheduleState ||displayStore._disableSectionSave ?'rgb(233, 235, 238)':'white'}}
                        disabled={!scheduleState || displayStore._disableSectionSave}
                        InputLabelProps={{
                        shrink: true,
                        className: classes.input,
                        }}
                        variant='outlined'
                        /> */}
        </Grid>

        <Grid container spacing={2} style={{marginTop:16}}>
        <Grid item xs={4} direction="row" style={{ display: 'flex' }}>
            <FormControlLabel 
            value="Set Schedule"
            control={<CustomCheckbox checked={repeatState} disabled={true} onChange={handleRepeatChange}/>}
            label={<Typography className={classes.text}>{t('repeat')}</Typography>}
            labelPlacement="end"/>
        </Grid>
        
         <Grid container xs={8} direction="row" spacing={2}>
         <Grid item xs={2}>
            <TextField id="interval"
            className={classes.textfield}
            name="interval"
            value={scheduleIntervalState}
            type="number"
            InputProps={{ inputProps: { min: 1} }}
            onChange={handleScheduleIntervalChange}
            style={{backgroundColor:'rgb(233, 235, 238)'}}
            disabled={true}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            variant="outlined">
            </TextField>   
        </Grid>     
          
        <Grid item xs={8} style={{paddingLeft:'2%',paddingRight:'1%'}}>
            <Select
             id="select-type"
             value={scheduleTypeState}
             fullWidth={true}
             classes={{root: classes.select}}
             onChange={handleScheduleTypeChange}
             renderValue={name => name< 0? 'None': (scheduleTypeState === 'OneTime' ? 'One Time' : scheduleTypeState.toString())}
             style={{backgroundColor:'rgb(233, 235, 238)'}}
             disabled={true}
             variant="outlined">
             {repeatType.map((name) => (
             <MenuItem key={name} value={name}>
             {name}
              </MenuItem>
              ))}   
            </Select>
            </Grid>
        </Grid>
        
        <Grid item container direction="row">
        <Grid item xs={4} direction="row" style={{ display: 'flex' }}>
        </Grid>
        <Grid item xs={8} container direction="row">
            <label className={classes.errorText}>{(repeatState && isNaN(rootStore.createJobStore.jobScheduleSetting.repeatInterval)) ?t('ValidationText'):null}</label>
        </Grid>
        </Grid>


        </Grid>
        </Grid>
    );
}

export default observer(ScheduleJobPreviewComponent);