import { Card, Grid, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import InfoCloneComponent from "./Info/InfoClone.component";
import InfoDRComponent from "./Info/InfoDR.component";
import { observer } from "mobx-react-lite";
import InfoEDRCComponent from "./Info/InfoEDRC.component";
import InfoEDRComponent from "./Info/InfoEDR.component";
import { RootStoreContext } from "../../../app/stores/root.store";



const InfoComponent : React.FC = () => {
    const {displayStore }= useContext(RootStoreContext); 
     
    return(
        <Grid container direction="column" style={{height:'100%'}}>
            {displayStore.screenSettings.displayInfo== 'InfoDR' ? <InfoDRComponent /> : null }
            {displayStore.screenSettings.displayInfo== 'InfoClone' ? <InfoCloneComponent /> : null }
            {displayStore.screenSettings.displayInfo== 'InfoEDRC' ? <InfoEDRCComponent /> : null }
            {displayStore.screenSettings.displayInfo== 'InfoEDR' ? <InfoEDRComponent /> : null }
        </Grid>
    );
};

export default observer(InfoComponent);