import { Grid, Typography, Card, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import NetworkVlanComponent from './NetworkInfo/NetworkVlan.component';
import NetworkSettingComponent from './NetworkInfo/NetworkSetting.component';
import MultiVlanComponent from './NetworkInfo/Vlan/MultiVlan.component';
import InfoComponent from './NetworkInfo/Info.component';
import clsx from 'clsx';
const NetworkInfoComponent: React.FC = () => {

    const useStyles = makeStyles({
       
        });
    
    const classes = useStyles();

    return (

        <Grid style={{background: 'rgb(233, 235, 238)', padding: '16px 16px 16px 16px', height: '100%', overflow: 'hidden'}}  direction="row" container>
        <Grid item xs={2} style={{marginBottom:'-30px'}}>
            <InfoComponent/>
        </Grid>
        <Grid container item xs={6} style={{overflowY: 'scroll',height: '100%', overflowX:'hidden'}} className="hide-scrollbar">
            <NetworkVlanComponent/> 
            <NetworkSettingComponent/>  
        </Grid>
        <Grid item xs={4} style={{overflowY: 'scroll',height: '100%', overflowX:'hidden'}} className="hide-scrollbar">
            <MultiVlanComponent />
        </Grid>
        </Grid>
    );
};

export default observer(NetworkInfoComponent);