import { action, computed, observable } from "mobx";
import { useContext } from "react";
import { boolean } from "yup/lib/locale";
import { DataMover } from "../models/BaseSettings.enum";
import { DisplayScreenSetting, NetworkSectionSetting, SectionSetting, NetworkDrawerSetting, BaseDrawerSetting, NextSetting } from "../models/jobProperties.model";
import { RootStore, RootStoreContext } from "./root.store";

export default class DisplayScreenStore {
    rootStore: RootStore;
    
    constructor(rootStore:RootStore) {
        this.rootStore = rootStore;
    }

    @action
    closeAllOtherNetworkDrawersExcept = (currentOpen:string) =>
    {
        currentOpen != "controllerDrawer" ? this.updateNetworkDrawerSettings({controllerDrawerOpen:false}):this.updateNetworkDrawerSettings({controllerDrawerOpen:true});
        currentOpen != "cloneDrawer" ? this.updateNetworkDrawerSettings({cloneDrawerOpen:false}):this.updateNetworkDrawerSettings({cloneDrawerOpen:true});
        currentOpen != "edrDrawer" ? this.updateNetworkDrawerSettings({edrDrawerOpen:false}):this.updateNetworkDrawerSettings({edrDrawerOpen:true});
        currentOpen != "testDrawer" ? this.updateNetworkDrawerSettings({testDrawerOpen:false}):this.updateNetworkDrawerSettings({testDrawerOpen:true});
        currentOpen != "liveDrawer" ? this.updateNetworkDrawerSettings({liveDrawerOpen:false}):this.updateNetworkDrawerSettings({liveDrawerOpen:true});
    }

    @action
    closeAllOtherBaseDrawersExcept = (currentOpen:string) =>
    {
        currentOpen != "BasicDrawer" ? this.updateBaseDrawerSettings({BasicDrawerOpen:false}):this.updateBaseDrawerSettings({BasicDrawerOpen:true});
        currentOpen != "LicenseDrawer" ? this.updateBaseDrawerSettings({LicenseDrawerOpen:false}):this.updateBaseDrawerSettings({LicenseDrawerOpen:true});
        currentOpen != "InfraDrawer" ? this.updateBaseDrawerSettings({InfraDrawerOpen:false}):this.updateBaseDrawerSettings({InfraDrawerOpen:true});
        currentOpen != "DataMoverDrawer" ? this.updateBaseDrawerSettings({DataMoverDrawerOpen:false}):this.updateBaseDrawerSettings({DataMoverDrawerOpen:true});
        currentOpen != "PrimaryUserDrawer" ? this.updateBaseDrawerSettings({PrimaryUserDrawerOpen:false}):this.updateBaseDrawerSettings({PrimaryUserDrawerOpen:true});
        currentOpen != "SecondaryUserDrawer" ? this.updateBaseDrawerSettings({SecondaryUserDrawerOpen:false}):this.updateBaseDrawerSettings({SecondaryUserDrawerOpen:true});
        currentOpen != "EDRDrawer" ? this.updateBaseDrawerSettings({EDRDrawerOpen:false}):this.updateBaseDrawerSettings({EDRDrawerOpen:true});
    }
    
    @observable private _screenSettings: DisplayScreenSetting = {
        displayBaseSettings:'EmptyVlan',
        displayNetwork:'EmptyVlan',
        displayInfo:'InfoDR',
        addNetwork:"none",
        blurNetwork:"",
        AddModeNetworks:false,
        AddModeEDR:false,
        AddModeEDRC:false,
        AddModeDC:false,
        DCTestValid:window.location.href.includes('edit')?true:false,
        LicenseApplyProcessing:false,
        DMApplyProcessing:false,
        PrimaryUserApplyProcessing:false,
        SecondaryUserApplyProcessing:false,
        InfraApplyProcessing:false,
        NetworkProcessing:true,
        DCApplyProcessing:true,
        EDRApplyProcessing:true,
        EDRCApplyProcessing:true,
        firstTimeTestDC:true,
        DCValuesValid:window.location.href.includes('edit')?true:false,
        renderValue:"",
        addedLicense:false,
        EDRValidationInProgress:false,
        changeEDR:false,
    }

    @observable private _sectionSettings: SectionSetting = {
        platformSection:true,
        WorkflowSection:true,
        serverDRSection:true
    }

    @observable private _networksectionSettings: NetworkSectionSetting = {
        DisableSection:false,
        DisableButtonEDR:false,
        DisableButtonEDRC:false,
        DisableButtonDC:false,
    }

    @observable private _baseDrawersSettings: BaseDrawerSetting = {
        BasicDrawerOpen:false,
        LicenseDrawerOpen:false,
        addLicense:false,
        AzureDrawerOpen:false,
        addInfrastructure:false,
        InfraDrawerOpen:false,
        PrimaryUserDrawerOpen:false,
        SecondaryUserDrawerOpen:false,
        addPrimaryUser:false,
        addSecondaryUser:false,
        addDataMover:false,
        DataMoverDrawerOpen:false,
        WorkflowDrawerOpen:false,
        EDRDrawerOpen:false
    }

    @observable private _networkDrawersSettings: NetworkDrawerSetting = {
        edrDrawerOpen:false,
        controllerDrawerOpen:false,
        cloneDrawerOpen:false,
        testDrawerOpen:false,
        liveDrawerOpen:false
    }

    @observable private _nextStepValid: NextSetting = {
        networkMappingValid:false,
        networkSettingValid:false,
        testsValid:false,
        jobNameValid:false,
        basicAdvancedValid:true,
        licenseValid:false,
        dmValid:false,
        infraValid:false,
        primaryValid:false,
        secondaryValid:false,
        edrcValid:false,
        dcAdvancedValid:false,
        selectedDevicesValid:false,
        edrValid: false
    }
    @observable public _stopLoadingPlans: boolean;

    @observable public _stepToSet: number = -1
    @observable public _afterCreate: boolean = false
    @observable public _RunJobProcessing: boolean = false
    @observable public _disableSectionSave : boolean=false
    @observable public _disablePreview : boolean=false
    @observable public _saverun :boolean =false

    @observable public licenseEditMode: boolean = false

    @computed
    get screenSettings(): DisplayScreenSetting {
        return this._screenSettings;
    }

    @action
    updateScreenSettings(obj: Partial<DisplayScreenSetting>) {
        this._screenSettings = {...this._screenSettings, ...obj};
    }

    @computed
    get sectionSettings(): SectionSetting {
        return this._sectionSettings;
    }

    @action
    updateSectionSettings(obj: Partial<SectionSetting>) {
        this._sectionSettings = {...this._sectionSettings, ...obj};
    }

    @computed
    get networkSectionSettings(): NetworkSectionSetting {
        return this._networksectionSettings;
    }

    @action
    updateNetworkSectionSettings(obj: Partial<NetworkSectionSetting>) {
        this._networksectionSettings = {...this._networksectionSettings, ...obj};
    }

    @computed
    get NetworkDrawerSettings(): NetworkDrawerSetting {
        return this._networkDrawersSettings;
    }
    
    @computed
    get BaseDrawerSettings(): BaseDrawerSetting {
        return this._baseDrawersSettings;
    }

    @computed
    get nextStepValid(): NextSetting {
        return this._nextStepValid;
    }

    @action
    updateNetworkDrawerSettings(obj: Partial<NetworkDrawerSetting>) {
        this._networkDrawersSettings = {...this._networkDrawersSettings, ...obj};
    }

    @action
    updateBaseDrawerSettings(obj: Partial<BaseDrawerSetting>) {
        this._baseDrawersSettings = {...this._baseDrawersSettings, ...obj};
    }

    @action
    updateNextStepValid(obj: Partial<NextSetting>) {
        this._nextStepValid = {...this._nextStepValid, ...obj};
    }

    @action
    resetStep(stepNumber: Partial<number>) {
        this._stepToSet = stepNumber;
    }

    @action 
    updateStopLoading(stoploading:boolean)
    {
        this._stopLoadingPlans = stoploading;
    }

    @action
    setAfterCreate(afterCreate: Partial<boolean>) {
        this._afterCreate = afterCreate;
    }

    @action
    setDisableSectionSave (disable:boolean){
        this._disableSectionSave= disable;
    }

    @action
    setDisablePreview (disable:boolean){
        this._disablePreview= disable;
    }

    @action
    setLicenseEditMode (val:boolean){
        this.licenseEditMode= val;
    }

    @action
    setSaveRunClicked (val:boolean){
        this._saverun= val;
    }

    @action
    setRunJobProcessing(runJobProcessing: Partial<boolean>) {
        this._RunJobProcessing = runJobProcessing;
    }

    @action
    resetDrawers()
    {
        this.screenSettings.displayBaseSettings ="EmptyVlan";
        this.BaseDrawerSettings.InfraDrawerOpen =false;
        this.BaseDrawerSettings.DataMoverDrawerOpen =false;
        this.BaseDrawerSettings.BasicDrawerOpen =false;
        this.BaseDrawerSettings.AzureDrawerOpen =false;
        this.BaseDrawerSettings.EDRDrawerOpen =false;
        this.BaseDrawerSettings.WorkflowDrawerOpen =false;
        this.BaseDrawerSettings.LicenseDrawerOpen =false;
    }

    @action reset = ()=>{
       this.screenSettings.displayBaseSettings ="EmptyVlan";
       this.licenseEditMode =false;
       this.BaseDrawerSettings.InfraDrawerOpen =false;
       this.BaseDrawerSettings.DataMoverDrawerOpen =false;
       this.BaseDrawerSettings.BasicDrawerOpen =false;
       this.BaseDrawerSettings.AzureDrawerOpen =false;
       this.BaseDrawerSettings.EDRDrawerOpen =false;
       this.BaseDrawerSettings.WorkflowDrawerOpen =false;
       this.BaseDrawerSettings.LicenseDrawerOpen =false;
       this.networkSectionSettings.DisableSection=false;
       this.networkSectionSettings.DisableButtonEDR=false;
       this.networkSectionSettings.DisableButtonEDRC=false;
       this.networkSectionSettings.DisableButtonDC=false;
       this.screenSettings.EDRApplyProcessing =false;
       this.screenSettings.EDRCApplyProcessing =false;
       this.screenSettings.DCApplyProcessing =false;
       this.screenSettings.AddModeEDR =false;
       this.screenSettings.AddModeEDRC =false;
       this.screenSettings.AddModeDC =false;
       this.screenSettings.LicenseApplyProcessing =false;
       this.screenSettings.InfraApplyProcessing =false;
       this.screenSettings.DMApplyProcessing =false;
       this.screenSettings.PrimaryUserApplyProcessing =false;
       this.screenSettings.SecondaryUserApplyProcessing =false;
       this._disableSectionSave= false;
       this._disablePreview= false;
        this._afterCreate = false
        this._RunJobProcessing = false;
    }
}
