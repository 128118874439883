import { FormControl, Grid, InputAdornment, InputLabel, makeStyles, OutlinedInput, Typography,IconButton, DialogContent, Dialog, Divider, Select, MenuItem, Button, Tooltip, Switch, CircularProgress } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Autocomplete } from "@material-ui/lab";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { EDRAutocomplete } from "../../../app/common/EDRAutocomplete";
import { EDRTextField } from "../../../app/common/EDRTextField";
import { SaveButton } from "../../../app/common/saveButton";
import { DATABASE_TYPE_NAME, OS_TYPE_NAME } from "../../../app/models/BaseSettings.enum";
import { AccessCredentials, CONSTS, DeviceTest } from "../../../app/models/createjob/createJob.model";
import { JobDevice } from "../../../app/models/jobDevice/jobDevice.model";
import { DatabaseType, DevicePlatform } from "../../../app/models/jobProperties.model";
import { TestType } from "../../../app/models/jobTest.model";
import { RootStoreContext } from "../../../app/stores/root.store";
import { Props } from "./NetworkTestDialog.component";
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import HelpIcon from '@material-ui/icons/Help';
import jobAgent from "../../../app/api/jobAgent";
import { EDROutlinedInput } from "../../../app/common/EDROutlinedInput";
import { compressFile } from "../../../app/common/util/util";

const ScriptTestDialog: React.FC<Props> = (props) => {
    const useStyles = makeStyles({
        formControl: {
            width: '100%',
            '& label': {
                background: "white",
                width: "auto",
                padding:"0 4px 0 4px",
                fontSize: '17px',
              }
        },
        helpIcon:
        {
          color: '#1976d2',
          fontSize: "large"
        },
        divider: {
            backgroundColor: '#eaeef4',
            width: '290%',
            margin: '0',
            marginTop: '10px',
            marginLeft: '10px',
    },
        SecondHeading:{
            color:'#1f396d',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1
        },
        centerVertical: {
            display: 'flex',
            alignItems: 'center',
            justifyContent:'space-between'
        },
        BrowseButton:{
            backgroundColor: '#fafafc',
            textTransform: 'none',
          },
        toggleItem: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
    
            '& .MuiListItemText-root' : {
                marginLeft: 10
            },
            '& .MuiSvgIcon-root': {
                color: '#8092a9'
            }
        }
        });   
    let labelRefScript;
    const classes = useStyles();
    const rootStore = useContext(RootStoreContext); 
    const { onApply,discard,closeDrawer,reload,setParentValue} = props;
    const {createJobStore,jobSettingsStore} = useContext(RootStoreContext);
    const device= createJobStore.editedDevice;
    const ScriptTest = device.tests.find(test=> test.testType == TestType.PreScriptRes);
    const [cancel,setCancel]=useState(false);
    const [ShowBrowse,setShowBrowse]= useState(device.scriptData!=null && device.scriptName!="" ?false:(device.scriptName!=""?false:true));
    const inputFileScript = useRef(null);
    const [DataStateScript, setDataStateScript] = useState<any>();
    const [processingTest,setProcessingTest] = useState(false);
    const [t] = useTranslation("createJobDevices");
    const [Info] = useTranslation('createJobBase');
    
const validationSchema = yup.object({
    script: yup.string().required('username is  a required field'),
})

const defaultSettings = {
    script: device.os == DevicePlatform.Windows ? 'WindowsPowerup.ps1':'LinuxPowerup.sh'
};

    const form = useFormik({
        initialValues: {
            script: ScriptTest!=undefined ? (ScriptTest.script!="" ?  ScriptTest.script : ''):'',
        },
        onSubmit: () => { },
        validationSchema
    })

      useEffect(() => {
        form.setFieldValue("script",reload,true);
    }, [reload]); 


    const handleTest=() =>{   
        var details =
        {
            machineName:device.sourceName,
            scriptName:form.values.script,
            scriptData: device.scriptData,
            devicePlatform: device.os,
            id: device.os == DevicePlatform.UNIX ? createJobStore.baseSettings.workflowTest.SecondaryUserCredsId : createJobStore.baseSettings.workflowTest.PrimaryUserCredsId

        };
        setProcessingTest(true);
        jobAgent.JobTestsActions.TestPowerupScript(details).then(res => {
            if(res)
                toast.success("Script Test succeeded");
            else
                toast.error("Script Test failed");
        setProcessingTest(false);
        }).catch(err=>{
        if(err && err.data && err.data.errors)
            toast.error(err.data.errors);
        setProcessingTest(false);
        });           
    }

    const handleCancle =()=>{
        if(form.dirty && form.touched)
            setCancel(!cancel);
        else
            closeDrawer();
    }

    const handleSave =()=> {
        try{
            let DeviceTest = {
                testCategory:device.tests[0].testCategory, 
                testType:TestType.PreScriptRes, 
                selected: true,
                customCommandToTest: '',
                customCommandExpectedResult: '', 
                customCommandToTestIsExternal: false,
                databaseType:DatabaseType.SQL,
                databaseNameToTest: '',
                databaseQueryToTest: '',
                databaseServerNameToTest: '',
                databaseUsername:'',
                databasePassword:'',
                databasePort:'',
                dbWindowsAuth:false,
                serviceToTest:'',
                testFromEDRC:false,
                script: form.values.script,
                thirdDeviceNetworkToTest: '',
                authenticationUserToTest:'',
                authenticationPassToTest:'',
                webPortalToTest: '',
                webPortalResult:''
            };
            createJobStore.updateTestDevice(TestType.PreScriptRes,DeviceTest,device.planName.includes("Custom Devices") ? device.sourceName : device.deviceName);
            onApply();
            discard();
            setParentValue(form.values.script);
            toast.success(t('saveSuccessful'));
        }
        catch(error)
        {
            toast.success(t('savefailed')+error);
        }
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    const handleYes=()=>{
        form.setFieldValue("script",ScriptTest.script!="" ? ScriptTest.script :'',true);
        discard();
        setCancel(false);
    }

    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const handleScriptChange =(event:any)=>{
        form.setFieldValue("script",event.target.value,true);
        device.scriptName = event.target.value;
        if(event.target.value==='')
        {
            device.scriptData=null;
            device.scriptName='';
            form.setFieldValue('script','',true);
            setShowBrowse(true);
        }
        if(event.target.value!==null && event.target.value!=='')
        {
            device.scriptName = event.target.value;
            device.scriptData = event.target.files!=null ? compressFile(event.target.files[0]):null;
        }  
        createJobStore.updateDevice(device);
        setParentValue(event.target.value === '' ? '' :event.target.value);
    }

    const handleBrowseScript =()=>{
        inputFileScript.current.click();
    }

    const removeFile =()=>{
        if(device!=undefined)
        {
            device.scriptData=null;
            device.scriptName='';
            form.setFieldValue('script','',true);
            setShowBrowse(true);
        }
    }

    const handleFileUpload = (e:any) => {
        const { files } = e.target;
        if (files && files.length) 
        {
          let fileSizebytes=files[0].size/1024;
          let invalid= fileSizebytes > 512 ;
          if(!invalid) 
          {
            form.setFieldValue("script",files[0].name);
            device.scriptName = files[0].name;
            const toBase64 = file => new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = error => reject(error);
            });
            toBase64(files[0]).then(function(res) {
                device.scriptData = res;
            });
            setShowBrowse(false);
          }
          else
          {
            form.setFieldValue("script",'');
            setDataStateScript(null);
            device.scriptData =null;
            device.scriptName ='';
            createJobStore.updateDevice(device);
          }
        }
    };

    
    return(
           <Grid container spacing={2} style={{paddingRight: '5%',paddingLeft:'5%', alignContent:'flex-start'}}>
            <input style={{ display: "none" }} ref={inputFileScript} type="file" onChange={(event)=> { 
               handleFileUpload(event) 
               event.target.value=null
            }}/>
            <Grid item container direction="row" spacing={2} style={{marginTop:'0px'}}>
                <div style={{ display: 'flex', flexDirection: 'row',marginLeft:'1%'}}>
                <Typography variant='h6' className={classes.SecondHeading}>{t('scriptsetting')}</Typography>
                    <Tooltip title={Info("webInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider} style={{width:'150%'}}/>
                </div>
            </Grid>
           
            <Grid item container direction="row" spacing={2} style={{marginTop:'1%'}}>
                <Grid item xs={12}>
                <FormControl className={classes.formControl} variant='outlined'>
                <InputLabel ref={ref => {labelRefScript = ReactDOM.findDOMNode(ref)}} style={{transform: 'translate(14px, -6px) scale(0.65)'}}>{t('uploadedScript')}</InputLabel>
                <EDROutlinedInput 
                        value={form.values.script}
                        labelWidth={labelRefScript ? labelRefScript.offsetWidth:0}
                        name="script"
                        type='text'
                        style={{paddingRight:'0px',borderColor:'#c7cfdb',height:'39px'}}
                        placeholder=""
                        onChange={handleScriptChange}
                        onBlur={form.handleBlur}
                        error={form.touched && form.touched.script && form.errors && Boolean(form.errors.script)}
                        endAdornment={
                        <InputAdornment position="end">
                            {device!=undefined && ShowBrowse === true ?<Button className={classes.BrowseButton} size="small" onClick={handleBrowseScript} style={{height: '38px'}}>{ t("browse")}</Button>
                             :<Button size="small" className={classes.BrowseButton} onClick={removeFile} style={{height: '38px'}}>{t("remove")}</Button>}
                        </InputAdornment>
                        }
                    /> 
                    </FormControl> 
                </Grid>
            </Grid>

            <Grid item container direction="row">
            <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end'}}>
            <Button onClick={handleCancle} color="primary" style={{textTransform:'none'}}>
                {t('Cancel')}
            </Button>
            <Button onClick={handleTest} disabled={form.values.script =='' ||processingTest } color="primary" style={{textTransform:'none'}}>
            {processingTest ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                {t('test')}
            </Button>
            <SaveButton onClick={handleSave} disabled={form.values.script ==''} variant="contained" color="primary" style={{textTransform:'none',marginRight:'-8px'}}>
                {t('apply')}
            </SaveButton>
            </Grid>
            </Grid>
            
        </Grid>
    );
}

export default ScriptTestDialog;