import React, { useState, useEffect } from 'react'
import { JobResultTimeline } from '../models/JobResultTimeline.model'
import { Grid, makeStyles, Button, Popover, Paper, InputLabel, FormControl, Select, MenuItem, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const YearOptions = [2020, 2021, 2022];


const useStyles = makeStyles({
    root: {
        background: "#fafafc",
        minHeight: 60,
    },
    timeline: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        background: "transparent",
        marginLeft: 10
    },

    item: {
        display: "flex",
        flexDirection: "column",
        fontSize: "16px",
        alignItems: "center",
        padding: "2px 20px 2px 20px",
        boxSizing: "border-box",
        cursor: "pointer",
        borderTop: "4px solid transparent",
        '& .month': {
            fontSize: 14
        }
    },
    selected: {
        background: "white",
        borderLeft: '1px solid #f4f5f9',
        borderRight: '1px solid #f4f5f9',
        borderTop: "4px solid #2892d7",
        position: 'relative',
        '&:after': {
            position: "absolute",
            content: '""',
            width: "100%",
            bottom: "-2px",
            left: 0,
            borderBottom: "3px solid #ffffff",
            zIndex: 1
        }
    },
    popoverItems: {
        color: "#2892d7",
        padding: '5px 15px 5px 15px',
        cursor: 'pointer',
        "&:hover": {
            textDecoration: "underline"
        }
    },
    popoverItemSelected: {
        background: '#c8e3f3'
    },

    atRisk: {
        color: "#077187",
        fontSize: "0.8em"
    },

    resillient: {
        color: "#8bc53f",
        fontSize: "0.8em"
    },

    recoverable: {
        color: "#2892d7",
        fontSize: "0.8em"
    },
    hide: {
        visibility: 'hidden'
    },
    displayNone: {
        width: '0 !important',
        height: '0 !important'
    },
    dot: {
        fontSize: 8
    }

});
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

type TimelineSelectorProps = {
    selectedDate: string,
    selectedJobResultId: number;
    records: Array<JobResultTimeline>,
    selectedClassName: string,
    hasNextPage: boolean,
    hasPreviousPage: boolean,
    onSelect: (selection: string, jobResultId: number) => void,
    onNextPage: (after: string) => void,
    onPrevPage: (before: string) => void,
    onYearSelected: (date: Date) => void
}

const TimeLineSelectorComponent: React.FC<TimelineSelectorProps> = (props) => {
    const classes = useStyles();
    const [popoverAnchor, setpopoverAnchor] = useState(null);
    const [selectedItem, setSelectedItem] = useState<JobResultTimeline>(null);
    const [buttonState, setButtonState] = useState<{ showNext: Boolean, showPrevious: Boolean }>({ showNext: true, showPrevious: true });
    const [calendarOpen, setcalendarOpen] = useState(false);
    const [selectedIdx,setSelectedIdx] =useState(0);

    useEffect(() => {
        const index = props.records.findIndex(r => r.date === props.selectedDate);
        setButtonState({ showPrevious: index > 0 || props.hasPreviousPage, showNext: index < props.records.length - 1 || props.hasNextPage })

    }, [props.selectedDate, props.records])
    const selectNext = () => {
        const index = props.records.findIndex(r => r.date === props.selectedDate);
        if (index === props.records.length - 1) {
            props.onNextPage(props.records[index].date);
        } else {
            const record = props.records[index + 1];
            props.onSelect(record.date, record.results[0].id);
        }
    }

    const selectPrev = () => {
        const index = props.records.findIndex(r => r.date === props.selectedDate);
        const item = props.records[index];
        if (index === 0) {
            props.onPrevPage(item.date);

        } else {
            const record = props.records[index - 1];
            props.onSelect(record.date, record.results[0].id);
        }
    }

    const onItemClick = (event, item: JobResultTimeline) => {

        if (props.selectedDate !== item.date) {
            props.onSelect(item.date, item.results[0].id);
            return;
        }
        // show popover if there are more than 1 results
        if (item.results.length > 1) {
            setSelectedItem(item);
            setpopoverAnchor(event.target);
        }
    }

    const handleYearChange = (event: React.ChangeEvent<{value: number}>) => {
        const year = event.target.value;
        props.onYearSelected(moment(`${year}/12/31`).toDate());
    }
    return <Grid container direction="row" className={classes.root}>
        <Grid item style={{ display: 'flex' }} >
            <Grid item container direction="column" justify="center" alignContent="center">
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                disableToolbar
                autoOk
                variant="inline"
                format="dd/MM/yyyy"
                placeholder="Start Date"
                maxDate={new Date()}
                value={(props.selectedDate && new Date(props.selectedDate) )|| new Date()}
                onChange={handleDatePickerChange}
                open={calendarOpen}
                onClose={() => setcalendarOpen(false)}
                className={classes.displayNone}
            />
            </MuiPickersUtilsProvider>
            <Button style={{marginLeft: 25}} variant="outlined" onClick={() => setcalendarOpen(true)}> {new Date().getFullYear()} <ArrowDropDownIcon style={{marginLeft: 10, position: 'relative', left: 5}}></ArrowDropDownIcon></Button>
            */}

               <FormControl variant="outlined">
                    <Select
                    style={{marginLeft: 25, height: 30}} 
                        value={props.selectedDate && new Date(props.selectedDate).getFullYear()}
                        onChange={handleYearChange}>
                        {YearOptions.map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}

                    </Select>
                    </FormControl>
            </Grid>

        </Grid>
        <Grid item className={classes.timeline}>
            {props.records.map(r =>  r.results.length == 1 ? 
            <div key={r.date} onClick={event => onItemClick(event, r)}
            className={clsx(classes.item, {
                [classes.selected]: r.date === props.selectedDate,
                [props.selectedClassName]: r.date === props.selectedDate
            })}>
            <span>{new Date(r.date).getDate()}</span>
            <span><CircleIcon className={clsx(classes.dot, {
                [classes.atRisk]: r.results[0].score < 80,
                [classes.recoverable]: r.results[0].score >= 80 && r.results[0].score <= 90,
                [classes.resillient]: r.results[0].score > 90
            })} /> </span>
            <span className="month">{monthNames[new Date(r.date).getMonth()]}</span>
        </div>:
            <Tooltip title={r.results[selectedIdx] && r.results[selectedIdx].runDate!=undefined ? moment(r.results[selectedIdx].runDate).format('HH:mm'):""} arrow>
            <div key={r.date} onClick={event => onItemClick(event, r)}
                className={clsx(classes.item, {
                    [classes.selected]: r.date === props.selectedDate,
                    [props.selectedClassName]: r.date === props.selectedDate
                })}>
                <span>{new Date(r.date).getDate()}</span>
                <span><CircleIcon className={clsx(classes.dot, {
                    [classes.atRisk]: r.results[0].score < 80,
                    [classes.recoverable]: r.results[0].score >= 80 && r.results[0].score <= 90,
                    [classes.resillient]: r.results[0].score > 90
                })} /> </span>
                <span className="month">{monthNames[new Date(r.date).getMonth()]}</span>
            </div>
            </Tooltip>
            )}
        </Grid>
        <Grid item style={{ alignSelf: "center" }}>
            {buttonState.showPrevious && <Button disabled={props.records.length === 0} onClick={selectPrev}>
                <ArrowLeftIcon />
            </Button>}
            <Button className={clsx([{ [classes.hide]: !buttonState.showNext }])} disabled={props.records.length === 0} onClick={selectNext}>
                <ArrowRightIcon />
            </Button>
        </Grid>
        <Popover anchorEl={popoverAnchor}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={!!popoverAnchor}
            onClose={() => setpopoverAnchor(null)}>
            <Paper style={{ padding: '10px 0px 10px 0px' }} onClick={() => setpopoverAnchor(null)}>
                {selectedItem && selectedItem.results.map((i, k) => {
                    return (
                        <React.Fragment>
                            <div className={clsx([classes.popoverItems, { [classes.popoverItemSelected]: i.id === props.selectedJobResultId }])} key={k} onClick={() => {props.onSelect(selectedItem.date, i.id);setSelectedIdx(k)}}>{moment(i.runDate).format('HH:mm')} 
                            <CircleIcon className={clsx(classes.dot, {
                            [classes.atRisk]: i.score < 80,
                            [classes.recoverable]: i.score >= 80 && i.score <= 90,
                            [classes.resillient]:i.score > 90
                            })} />
                            </div>
                        </React.Fragment>
                    );
                })}
            </Paper>
        </Popover>
    </Grid>
}

export default TimeLineSelectorComponent;