import React, { useState, useEffect, createContext, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PrintIcon from '@material-ui/icons/Print';
import MoreVert from '@material-ui/icons/MoreVert';
import queryString from 'querystring';
import { Paper, Grid, Typography, Button, makeStyles, useMediaQuery, Drawer, Fab,Tooltip, Divider, SvgIcon, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import AppBar, { AppBarTitle } from '../navigation/appbar/appBar.component';
import TimeLineSelectorComponent from '../../app/common/timelineSelector.component';
import { JobSummaryGroupByJob } from '../../app/models/jobSummaryGroupByJob.model';
import JobResultsApi from '../../app/api/jobResult.api';
import { convertSecondsToTime, ConvertStringToTimeSpan, convertTimespanToString, parseSelectedDevices } from '../../app/common/util/util';
import TestWorkFlowComponent from '../miniReport/testWorkflow.component';
import { observer } from 'mobx-react-lite';
import JobCategoryIcon from '../../app/common/jobCategoryIcon';
import FullLogIcon from '@material-ui/icons/Assignment';
import CrossIcon from '@material-ui/icons/CancelRounded';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import DeviceTestChart from '../../app/common/testChart.component';
import ReportDeviceSection from './reportDeviceSection.component';
import JobActionMenu, { JobMenuAction } from '../../app/common/jobActionMenu.component';
import DRResourceSectionComponent from './drResourceSection.component';
import ReportSkeleton from './reportSkeleton.component';
import ListSkeleton from '../../app/common/util/listSkeleton.component';
import RecoveryReadinessChart from './recoveryReadiness.component';
import GaugeChart from '../../app/common/gaugeChart.component';
import { DeviceStatus, JobDeviceResultResponse, JobDeviceResultShort } from '../../app/models/jobDevice/jobDeviceResult.reponse.model';
import {DeviceType, DEVICE_TYPE_NAME_SHORTCUT, DEVICE_TYPE_SMALL_ICON} from "../../app/models/deviceType.enum";
import { useTranslation } from 'react-i18next';
import { TrendIndicator } from '../../app/common/trendIndicator.component';
import SelectList from '../../app/common/selectList.component';
import { RootStore, RootStoreContext } from '../../app/stores/root.store';

import jobAgent from '../../app/api/jobAgent';
import TrendSection from './trendSection.component';
import MainReportJobRunView from './mainReportJobRun.component';
import LogViewer from '../../app/common/logViewer.component';
import { WorkflowStep, WorkflowStepType } from '../../app/models/workflowStep.model';
import { toast } from 'react-toastify';
import ReportJobFilter from './reportJobFilter.component';
import { UpdateType } from '../../app/stores/activityStore';
import { useShowResourceSetting } from '../../app/common/util/useShowResourcesSetting.hook';
import resourcesAndICComponent from './resourcesAndIC.component';
import DashboardStore from '../../app/stores/dashboard.store';
import { LastJobRun, LastJobRunStatus } from '../../app/models/lastjobRun.model';
import ResourcesAndICComponent from './resourcesAndIC.component';
import IssueAndAutoFixComponent, { TestFixStyles } from './issuesAndAutofix.component';
import RRSDialog from '../../app/common/RRSDialog.component';
import RTADialog from '../../app/common/RTADialog.component';
import {  IssuesIcon, AutofixIcon, InternetConnectionIcon} from '../Icons';

import TestOverviewDevicesComponent from '../miniReport/TestOverviewDevices.component';
import ReportTestsComponent from './reportTests.component';
import { TestStatus, TestType } from '../../app/models/jobTest.model';
import useTestGroups from '../../app/common/util/useTestGroups';
import ResillientIcon from '../Icons/resillientIcon';
import RecoverableIcon from '../Icons/recoverableIcon';
import AtRiskIcon from '../Icons/atRiskIcon';
import DRResourceSectionMultipleComponent from './drResourceSectionMulitple';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { JobRunType } from '../../app/models/BaseSettings.enum';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const useStyles = makeStyles(theme => ({
  filter_container: {
    background: "white",
    position: "sticky",
    top: 0,
    padding: 15,
    zIndex: theme.zIndex.appBar+1,
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow:'hidden'
},
  categorySelected: {
    borderRadius: 30,
    background: "#cde3f0"
  },
  root: {
    width: "80%",
    margin: "auto",
    textAlign: "center",
    marginTop: 48,
    marginBottom: 100,
    padding: 20
  },
  job_category: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer"
  },

  verticalBorder: {
    background: "lightGray",
    width: 1
  },

  checkIcon: {
    color: "#8bc53f",
    marginRight: 5
  },
  crossIcon: {
    color: "#077187",
    marginRight: 5
  },
  timelineSelectedItem: {
    borderTop: "0px",
    background: "white",
    borderBottom: "4px solid #2892d7",
    '&:after': {
      display: 'none'
    }
  },
  menuButton: {
    width: "35px",
    height: "35px",
    borderRadius: "18px",
    border: "1px solid #c7cfdb",
    minWidth: "0"
  },
  label: {
    textTransform: 'capitalize',
  },
  jobName: {
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('lg')]: {
          maxWidth: '100%'
      },
      
  },
  hover: {
     "&:hover": {
        backgroundColor: '#bfdff3',
        cursor:'pointer'
    }
},
  sideBySide: {
      display: 'flex',
      flexDirection: 'row'
  },
  testIcon: {
    color: '#8092a9',
    fontSize: 16
  }
}));

const ExcludedTests = [TestType.LinuxOSADV, TestType.advanceSvc, TestType.NetConnectStat, TestType.SecondPowerOn, TestType.testAdvNet]

const useDeviceTypeAggregate = (devices: Array<Pick<JobDeviceResultResponse, 'status' | 'type'>>, deviceTypeStrings) => {
  const [deviceTypeAggregate, setdeviceTypeAggregate] = useState<Array<{type: DeviceType, ok: number, failed: number}>>([]);

  useEffect(() => {
   setdeviceTypeAggregate( parseSelectedDevices(deviceTypeStrings).slice(0,5).map(deviceType => {
    return {
        type: deviceType,
        ok: devices.filter(d => d.type === deviceType).filter(d => d.status === "ok").length,
        failed: devices.filter(d => d.type === deviceType).filter(d => d.status !== "ok").length
        }

}))
    }, [devices, deviceTypeStrings]);

  return deviceTypeAggregate;
}

function getDeviceStatus(device: JobDeviceResultShort) : DeviceStatus {
  if(device.status === DeviceStatus.ok)
      {
          return DeviceStatus.ok;
      }
  const powerOnTest = device.jobTestResults.find(t => t.testType === TestType.power)
  if(powerOnTest && powerOnTest.testStatus !== TestStatus.failed && device.jobTestResults.some(t => t.testType !== TestType.power && ExcludedTests.indexOf(t.testType) <0 && t.testStatus === TestStatus.passed))
      {
          return DeviceStatus.warning;
      }
  
  return DeviceStatus.failed;
}

function getDevicesWithDeviceStatus (devices: Array<JobDeviceResultShort>): Array<JobDeviceResultShort> {
  return devices.map(d => {
      return {...d, status: getDeviceStatus(d)}
  })
}


export const loadOneKindDevice =(reportScore:number,type:string,FirstMostPopular:DeviceType,devices?:JobDeviceResultShort[],status?:LastJobRunStatus)=>{
  const failedDeviceCount = devices ? devices.filter(d => d.type === FirstMostPopular && d.status === "failed").length: 0;
  const warningDeviceCount =  devices ? devices.filter(d => d.type === FirstMostPopular && d.status === "warning").length: 0;
  const okDeviceCount =  devices ? devices.filter(d => d.type === FirstMostPopular && d.status === "ok").length: 0;
  return(          
    <Grid item container style={{backgroundColor:type == 'main' ?  'rgba(40, 146, 215, 0.05)':'white',border:type == 'main' ? '1px solid rgba(40, 146, 215, 0.25)' : '1px solid #eaeef4',borderRadius: '4px', height:"calc(50% - 8px)"}}>
    <Grid item xs={4} style={{height: "100%", padding: 10,display:'flex',alignItems:'center' }}>
      <Grid container direction="column" justify="space-between">
           <Grid item style={{ flexGrow: 1 }}>
            <Grid container direction="column">
                <Grid item> 
                    <img src={DEVICE_TYPE_SMALL_ICON[FirstMostPopular]} style={{ fill: "#8092a9",display:'flex',height:"30%"}}/>
                </Grid>
                <Grid item style={{display:'flex'}}> 
                    <Typography variant="body1" noWrap={true} style={{fontWeight: 500, fontSize: 10, textAlign:'left'}}>{DEVICE_TYPE_NAME_SHORTCUT[FirstMostPopular]}</Typography>
                </Grid>
                <Grid item data-test="total-test" style={{display:'flex'}}>  
                    <Typography variant="body2" color="textSecondary">{type =="main" ? devices.filter(item=>item.type === FirstMostPopular).length : 0 + ' tested'}</Typography>
                </Grid>
            </Grid>
        </Grid>
       </Grid>
     </Grid>
     <Grid item container xs={8}>
      <Grid item xs={4} style={{height: "100%", padding: 10,display:'flex',alignItems:'center' }}>
      <Grid container direction="column" justify="space-between">
      <Grid item style={{ flexGrow: 1 }}>
            <Grid container direction="column">
                <Grid item> 
                <ResillientIcon style={{ ...TestFixStyles.deviceCellIcon, color: type == "mainRun" ? 'lightgray':(status == null ? 'lightgray' :(okDeviceCount == 0? 'lightgray':'#8bc53f')) }}/>
                </Grid>
                <Grid item> 
                    <Typography variant="body1" style={{fontWeight: 500}}>{type =="main" ? (reportScore === 0 ? 0: okDeviceCount): 0}</Typography>
                </Grid>
                <Grid item data-test="total-test">  
                    <Typography variant="body2" color="textSecondary">{'OK'}</Typography>
                </Grid>
            </Grid>
        </Grid>
       </Grid>
     </Grid>
      <Grid item xs={4} style={{height: "100%", padding: 10,display:'flex',alignItems:'center' }}>
      <Grid container direction="column" justify="space-between">
      <Grid item style={{ flexGrow: 1 }}>
            <Grid container direction="column">
                <Grid item> 
                  <RecoverableIcon style={{ ...TestFixStyles.deviceCellIcon, color: type == "mainRun" ? 'lightgray':(status == null ? 'lightgray' :(warningDeviceCount == 0 ? 'lightgray':'#2892d7'))}}/>
                </Grid>
                <Grid item> 
                    <Typography variant="body1" style={{fontWeight: 500}}>{type =="main" ? (reportScore === 0 ? 0:warningDeviceCount): 0}</Typography>
                </Grid>
                <Grid item data-test="total-test">  
                    <Typography variant="body2" color="textSecondary" >{'Warning'}</Typography>
                </Grid>
            </Grid>
        </Grid>
       </Grid>
     </Grid>
      <Grid item xs={4} style={{height: "100%", padding: 10,display:'flex',alignItems:'center' }}>
      <Grid container direction="column" justify="space-between">
      <Grid item style={{ flexGrow: 1 }}>
            <Grid container direction="column">
                <Grid item> 
                <AtRiskIcon style={{ ...TestFixStyles.deviceCellIcon, color: type == "mainRun" ? 'lightgray':(status == null ? 'lightgray' :(failedDeviceCount == 0 ? 'lightgray':'#077187'))}}/>
                </Grid>
                <Grid item> 
                    <Typography variant="body1" style={{fontWeight: 500}}>{type =="main" ? (reportScore === 0 ? 0: failedDeviceCount ): 0}</Typography>
                </Grid>
                <Grid item data-test="total-test">  
                    <Typography variant="body2" color="textSecondary">{'Falied'}</Typography>
                </Grid>
            </Grid>
        </Grid>
       </Grid>
     </Grid>
     </Grid>
     </Grid>)
}

const ReportComponent: React.FC<RouteComponentProps<any>> = ({ location, history }) => {
  const classes = useStyles();
  const { jobRunStore, activityStore, settingStore, jobResultStore,reportStore, userStore} = useContext(RootStoreContext);
  const { selectedJobResult: jobReport, jobTestOverview: jobTest, isLoading,timeline } = reportStore;
  const [jobList, setjobList] = useState<Array<JobSummaryGroupByJob>>(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [devices, setDevices] = useState<Array<JobDeviceResultShort>>([]);
  const [t] = useTranslation("report");
  const [openTrendDrawer, toggleTrendDrawer] = useState(false);
  const [openLogsDialog, setopenLogsDialog] = useState(false);
  const [openRRSDialog, setopenRRSDialog] = useState(false);
  const [openRTADialog, setopenRTADialog] = useState(false);
  const { dashboardSettings, reportSettings } = settingStore;
  const maxIops = dashboardSettings.find(s => s.settingKey == 'SetIOPS');
  const selectedDevices= settingStore.reportSettings.find(obj => obj.settingKey.match('SelectedDevices'));
  const animationPlaySettings = reportSettings.find(s => s.settingKey == 'AutoPlayAnimation');
  const deviceTypeAggregate = useDeviceTypeAggregate(devices, selectedDevices && selectedDevices.settingValue || '');
  const jobRunState = jobRunStore.getJobRun(reportStore.JobId);
  const showResource = useShowResourceSetting();
  const [issueCount, setIssuesCount] = useState(0);
  const [lastJobResult, setLastJobResult] = useState<LastJobRun>(null);
  const [isSecondRunning, setIsSecondRunning]= useState(false);
  const [JobChanged, setJobChanged]= useState<number>(0);
  const [devicesKinds, setDevicesKinds] =useState<number>(0); 
  const [numberDateRecords, setNumberDateRecords]= useState<number>(0);
  const [FirstMostPopular, setFirstMostPopular] =useState<DeviceType>(DeviceType.VirtualMachine); 
  const [SecondMostPopular, setSecondMostPopular] =useState<DeviceType>(DeviceType.PhysicalServer); 
  const [ThirdMostPopular, setThirdMostPopular] =useState<DeviceType>(DeviceType.PhysicalServer); 
  const [FourthMostPopular, setFourthMostPopular] =useState<DeviceType>(DeviceType.PhysicalServer); 
  const [FifthMostPopular, setFifthMostPopular] =useState<DeviceType>(DeviceType.PhysicalServer); 

  const [testGroups, testGroupNames] = useTestGroups(reportStore.JobId);
  const {trackEvent} = useMatomo();
  
  
  // update component state upon url change
  useEffect(() => {
    const params = queryString.parse(location.search.slice(1));
    const jobId = params.jobId;
    const jobResultId = params.jobResultId
    const refresh = params.refreshTimeline as string;
    if (jobId && jobResultId) {
      const oldJobId = reportStore.JobId;
      reportStore.setSelectedJobId(Number.parseInt(jobId.toString()));
      reportStore.fetchJobReport(Number.parseInt(jobResultId + '')).then((res) => {
        if (!res) return;
        if (oldJobId !== reportStore.JobId || refresh === "1")
          reportStore.fetchTimeline(null, res.runDate + '');

        reportStore.fetchJobTestOverview(res.id);
      });

    }
  }, [location.search])
  
  useEffect(() => {
    setLastJobResult(jobResultStore._jobResults.find(j => j.jobId === reportStore.JobId));
  }, [reportStore.JobId, jobResultStore._jobResults])

  useEffect(() => {
    if(reportStore.timeline!=undefined && reportStore.timeline.records.length > 0)
      reportStore.timeline.records[0].date ==  selectedDate ? setNumberDateRecords(1) :setNumberDateRecords(reportStore.timeline.records.length);
  }, [reportStore&& reportStore.timeline && reportStore.timeline.records])

  const sortByRunDate = (j1: JobSummaryGroupByJob, j2: JobSummaryGroupByJob): number => {
    if(jobRunStore.isJobRunning(j1.jobId) && !jobRunStore.isJobRunning(j2.jobId)) 
      return -1;
    if(jobRunStore.isJobRunning(j2.jobId) && !jobRunStore.isJobRunning(j1.jobId))
      return 1;
    return moment(j2.runDate).diff(moment(j1.runDate));
  }

  useEffect(() => {
    const req= reportStore.fetchJobList();
    req.then(res => {
      setjobList(res.sort(sortByRunDate));
      if (!location.search) {
        if (res.length > 0)
          selectJob(res.filter(item=>item.isDeleted == false)[0]);
      }
    }).catch(err => {
      if(!err)
      console.log("groupbyjob Request cancelled",err);
    });
    return () => {
      req.cancel();
    }

  }, []);

  useEffect(() => {
    if(!reportStore.jobList)
      return;
   setjobList(reportStore.jobList.sort(sortByRunDate));
  }, [reportStore.jobList, jobRunStore.jobRuns])

  // for job action updates
  useEffect(() => {
    const updateHandler = (update: {jobId: number}) => {
      reportStore.fetchJobList().then(res => {
        res = res.sort(sortByRunDate);
        if(update.jobId === reportStore.JobId) {
          let jobId = reportStore.JobId;
            selectJob(res.find(j => j.jobId === Number(jobId)));
        }
      })
    };

    activityStore.on(UpdateType.JobRemoved, updateHandler);
    activityStore.on(UpdateType.JobRenamed, updateHandler);
    activityStore.on(UpdateType.JobRestored, updateHandler);

    return () => {
      activityStore.off(UpdateType.JobRemoved, updateHandler);
      activityStore.off(UpdateType.JobRenamed, updateHandler);
      activityStore.off(UpdateType.JobRestored, updateHandler);
    }
  }, []);

  // for job run updates and job Duplicate action
  useEffect(() => {
    const updateHandler = (update: {jobId: number}) => {
      reportStore.fetchJobList().then(res => {
        res = res.sort(sortByRunDate);
          let jobId = (update && update.jobId) ||  reportStore.JobId;
          selectJob(res.find(j => j.jobId === Number(jobId)), '1');
      });
    };

    activityStore.on(UpdateType.JobRunUpdate, updateHandler);
    activityStore.on(UpdateType.JobAdded, updateHandler);
    
    return () => {
      activityStore.off(UpdateType.JobRunUpdate, updateHandler);
      activityStore.off(UpdateType.JobAdded, updateHandler);
    }
  }, []);

  useEffect(() => {
    if (jobReport) {
      let date = moment(jobReport.runDate).format("YYYY-MM-DD") + "T00:00:00";
      setSelectedDate(date);
    }
  }, [setSelectedDate, jobReport])


  // fetch devices on job result selection  
  useEffect(() => {
    var isCancelled = false;
    if (jobReport) {
        JobResultsApi.devices(jobReport.id, 0, 0, 'id,type,status,machineName,testGroup,testGroupName,autoTroubleshooting,jobTestResults,devicePlatform,deviceUserName').then(res => {
        if (isCancelled)
          return;
        setDevices(getDevicesWithDeviceStatus(res.records.sort((d1,d2) => d1.testGroup - d2.testGroup)));
        setJobChanged(jobReport.id);
        // setTestGropus(res.testGroups);
      });
    }
    return () => {
      isCancelled = true
    }

  }, [jobReport])

  useEffect(() => {
    if(devices.length > 0)
    {
      let dict=[];
      let DeviceTypeArray=[0,1,2,3,4,5,6,7,8,9,10,11];
      DeviceTypeArray.map(type =>{
        dict.push({key:type,val:devices.filter(item=>item.type == type).length});
      });
      dict.sort((a, b) => (a.val > b.val) ? 1 : -1).reverse();
      setFirstMostPopular(dict[0].key);
      setSecondMostPopular(dict[1].key);
      setThirdMostPopular(dict[2].key);
      setFourthMostPopular(dict[3].key);
      setFifthMostPopular(dict[4].key);
      let kinds=0;
      dict.map(item=>{
        if(item.val!=0)
        kinds++;
      })
      setDevicesKinds(kinds);
    }
  }, [JobChanged ,devices.length > 0])

  const selectJob =  (jobGroup: JobSummaryGroupByJob, refreshTimeline: '0' | '1' = '0') => {
    trackEvent({
      category: 'Report',
      action : 'Select Job',
      name: jobGroup.jobName
  })
    setJobChanged(jobGroup.jobId);
    setIsSecondRunning(jobGroup.jobResultCount >= 2 ? true:false);
    const query = { jobId: jobGroup.jobId, jobResultId: jobGroup.lastJobResultId, u: Math.random(), refreshTimeline }
    history.push({ search: queryString.stringify(query) });
  }

  useEffect(() => {
    let issueCount = 0;
    Object.keys(jobTest).forEach(test => {
        if(typeof jobTest[test] == 'object')
        issueCount +=jobTest[test].failed
    });
    issueCount += (jobReport && jobReport.knownIssues.filter(k => k.workflowIssue).length) || 0
    setIssuesCount(issueCount);
 }, [jobTest, jobReport])

  const onDateSelected = (date: string, jobresultId: number, refreshTimeline: '0' | '1' = '0') => {
    setSelectedDate(date);
    reportStore.timeline.records[0].date ==  date ? setNumberDateRecords(1) :setNumberDateRecords(reportStore.timeline.records.length);
    const query = { jobId: jobReport.jobId, jobResultId: jobresultId , refreshTimeline}
    history.push({ search: queryString.stringify(query) });
  }
  const fetchNextPage = () => {
    reportStore.fetchTimeline(reportStore.timeline.page - 1).then(({records: timeline}) => {
      if(timeline.length > 0) {
          const last = timeline[0];
          onDateSelected(last.date, last.results[0].id);
      }
  });;
  }

  const fetchPrevPage = () => {
    reportStore.fetchTimeline(reportStore.timeline.page + 1).then(({records: timeline}) => {
      if(timeline.length > 0) {
          const last = timeline.slice(-1)[0];
          onDateSelected(last.date, last.results[0].id);
      }
  });
  }
  const showError = (steps: WorkflowStep[]) => {
    let Errors=[];
    steps.map(s => {
        if(s.workflowIssue && s.workflowIssue != undefined && s.workflowIssue != "")
        Errors.push( 
            <React.Fragment>
                <CrossIcon style={{marginRight: 5, fontSize: 14}} ></CrossIcon>
                <Typography color="error" variant="body1">{s.workflowIssue}</Typography>
                <br></br>
            </React.Fragment> )
    })
    if(Errors.length > 0)
        return Errors
    return (
        <React.Fragment>
                <CrossIcon style={{marginRight: 5, fontSize: 14}} ></CrossIcon>
                <Typography color="error" variant="body1">{t('failoverIssue')}</Typography>
        </React.Fragment>
    )
  }
  
  const handleRandomDateSelection = (date: Date) => {
    JobResultsApi.getJobResultByDate(jobReport.jobId, date.toISOString()).then(res => {
      if(res) {
          onDateSelected(res.runDate as string, res.id, '1');
      } else {
          toast.warn(t('noJobRunForYear', {date: date.getFullYear()}))
      }
  })
}

const checkJobRunning =()=>{
if(jobReport && jobReport.jobId)
  return jobRunStore.isJobRunning(jobReport.jobId);
else
  return false;
}

const Noloadpopup =()=>{
  if(jobRunStore.isJobRunning(jobReport.jobId))
    return false;
  if(jobReport.score == 0)
    return false;
  return true;
}


const loadMultipleDevices =()=>{
   if(devicesKinds == 3 || devicesKinds == 4)
   return(          
  <Grid item container style={{ backgroundColor:'rgba(40, 146, 215, 0.05)', border: '1px solid rgba(40, 146, 215, 0.25)',borderRadius: '4px', height:"calc(50% - 8px)"}}>
   <Grid item xs={devicesKinds == 3 ? 4:3} style={{ borderRight: '1px solid #eaeef4', height: "100%", padding:"20px 10px 0px"}}>
   <Grid container direction="column" justify="space-between">
        <TestOverviewDevicesComponent jobReportStatus={jobReport.status} deviceType={DEVICE_TYPE_NAME_SHORTCUT[DeviceType.VirtualMachine]} passed={devices.filter(d =>d.status === "ok" && d.type === DeviceType.VirtualMachine).length} warning={devices.filter(d =>d.status === "warning" && d.type === DeviceType.VirtualMachine).length} failed={devices.filter(d =>d.status === "failed" && d.type === DeviceType.VirtualMachine).length} icon={DEVICE_TYPE_SMALL_ICON[DeviceType.VirtualMachine]}></TestOverviewDevicesComponent>
    </Grid>
   </Grid>

    <Grid item xs={devicesKinds == 3 ? 4:3} style={{ borderRight: '1px solid #eaeef4', height: "100%", padding:"20px 10px 0px"}}>
    <Grid container direction="column" justify="space-between">
      <TestOverviewDevicesComponent jobReportStatus={jobReport.status} deviceType={DEVICE_TYPE_NAME_SHORTCUT[SecondMostPopular]} passed={devices.filter(d =>d.status === "ok" && d.type === SecondMostPopular).length} warning={devices.filter(d =>d.status === "warning" && d.type === SecondMostPopular).length} failed={devices.filter(d =>d.status === "failed" && d.type === SecondMostPopular).length} icon={DEVICE_TYPE_SMALL_ICON[SecondMostPopular]}></TestOverviewDevicesComponent>
    </Grid>
    </Grid>
     <Grid item xs={devicesKinds == 3 ? 4:3} style={{ borderRight: '1px solid #eaeef4', height: "100%", padding:"20px 10px 0px" }}>
     <Grid container direction="column" justify="space-between">
      <TestOverviewDevicesComponent jobReportStatus={jobReport.status} deviceType={DEVICE_TYPE_NAME_SHORTCUT[ThirdMostPopular]} passed={devices.filter(d =>d.status === "ok" && d.type === ThirdMostPopular).length} warning={devices.filter(d =>d.status === "warning" && d.type === ThirdMostPopular).length} failed={devices.filter(d =>d.status === "failed" && d.type === ThirdMostPopular).length} icon={DEVICE_TYPE_SMALL_ICON[ThirdMostPopular]}></TestOverviewDevicesComponent>
    </Grid>
     </Grid>
     {devicesKinds == 4 ?<Grid item xs={3} style={{ borderRight: '1px solid #eaeef4', height: "100%", padding:"20px 10px 0px" }}>
     <Grid container direction="column" justify="space-between">
      <TestOverviewDevicesComponent jobReportStatus={jobReport.status} deviceType={DEVICE_TYPE_NAME_SHORTCUT[FourthMostPopular]} passed={devices.filter(d =>d.status === "ok" && d.type === FourthMostPopular).length} warning={devices.filter(d =>d.status === "warning" && d.type === FourthMostPopular).length} failed={devices.filter(d =>d.status === "failed" && d.type === FourthMostPopular).length} icon={DEVICE_TYPE_SMALL_ICON[FourthMostPopular]}></TestOverviewDevicesComponent>
    </Grid>
     </Grid>:null}
   </Grid>)
   else if(devicesKinds >= 5)
   return(
    <Grid item container style={{ backgroundColor:'rgba(40, 146, 215, 0.05)', border: '1px solid rgba(40, 146, 215, 0.25)',borderRadius: '4px', height:"calc(50% - 8px)"}}>
    <Grid item xs={2} style={{ borderRight: '1px solid #eaeef4', height: "100%", padding:"20px 10px 0px",marginLeft:'5%' }}>
    <Grid container direction="column" justify="space-between" style={{marginLeft:'-15px'}}>
        <TestOverviewDevicesComponent jobReportStatus={jobReport.status} deviceType={DEVICE_TYPE_NAME_SHORTCUT[DeviceType.VirtualMachine]} passed={devices.filter(d =>d.status === "ok" && d.type === DeviceType.VirtualMachine).length} warning={devices.filter(d =>d.status === "warning" && d.type === DeviceType.VirtualMachine).length} failed={devices.filter(d =>d.status === "failed" && d.type === DeviceType.VirtualMachine).length} icon={DEVICE_TYPE_SMALL_ICON[DeviceType.VirtualMachine]}></TestOverviewDevicesComponent>
    </Grid>
    </Grid>
     <Grid item xs={2} style={{ borderRight: '1px solid #eaeef4', height: "100%", padding:"20px 10px 0px",marginLeft:'2%' }}>
     <Grid container direction="column" justify="space-between">
      <TestOverviewDevicesComponent jobReportStatus={jobReport.status} deviceType={DEVICE_TYPE_NAME_SHORTCUT[SecondMostPopular]} passed={devices.filter(d =>d.status === "ok" && d.type === SecondMostPopular).length} warning={devices.filter(d =>d.status === "warning" && d.type === SecondMostPopular).length} failed={devices.filter(d =>d.status === "failed" && d.type === SecondMostPopular).length} icon={DEVICE_TYPE_SMALL_ICON[SecondMostPopular]}></TestOverviewDevicesComponent>
    </Grid>
     </Grid>
      <Grid item xs={2} style={{ borderRight: '1px solid #eaeef4', height: "100%", padding:"20px 10px 0px" ,marginLeft:'2%'}}>
      <Grid container direction="column" justify="space-between">
      <TestOverviewDevicesComponent jobReportStatus={jobReport.status} deviceType={DEVICE_TYPE_NAME_SHORTCUT[ThirdMostPopular]} passed={devices.filter(d =>d.status === "ok" && d.type === ThirdMostPopular).length} warning={devices.filter(d =>d.status === "warning" && d.type === ThirdMostPopular).length} failed={devices.filter(d =>d.status === "failed" && d.type === ThirdMostPopular).length} icon={DEVICE_TYPE_SMALL_ICON[ThirdMostPopular]}></TestOverviewDevicesComponent>
    </Grid>
      </Grid>
      <Grid item xs={2} style={{ borderRight: '1px solid #eaeef4', height: "100%", padding:"20px 10px 0px",marginLeft:'2%'}}>
      <Grid container direction="column" justify="space-between">
      <TestOverviewDevicesComponent jobReportStatus={jobReport.status} deviceType={DEVICE_TYPE_NAME_SHORTCUT[FourthMostPopular]} passed={devices.filter(d =>d.status === "ok" && d.type === FourthMostPopular).length} warning={devices.filter(d =>d.status === "warning" && d.type === FourthMostPopular).length} failed={devices.filter(d =>d.status === "failed" && d.type === FourthMostPopular).length} icon={DEVICE_TYPE_SMALL_ICON[FourthMostPopular]}></TestOverviewDevicesComponent>
    </Grid>
     </Grid>
     <Grid item xs={2} style={{height: "100%", padding:"20px 10px 0px",marginLeft:'2%' }}>
     <Grid container direction="column" justify="space-between">
      <TestOverviewDevicesComponent jobReportStatus={jobReport.status} deviceType={DEVICE_TYPE_NAME_SHORTCUT[FifthMostPopular]} passed={devices.filter(d =>d.status === "ok" && d.type === FifthMostPopular).length} warning={devices.filter(d =>d.status === "warning" && d.type === FifthMostPopular).length} failed={devices.filter(d =>d.status === "failed" && d.type === FifthMostPopular).length} icon={DEVICE_TYPE_SMALL_ICON[FifthMostPopular]}></TestOverviewDevicesComponent>
    </Grid>
     </Grid>
    </Grid>)
}

const loadDeviesKindSection =()=>{
  if(devicesKinds != 1 && devicesKinds!=2)
  {
    return(<React.Fragment>
      <Grid item container xs={12} direction="column" style={{paddingTop:'2%'}}>
        {loadMultipleDevices()}
       </Grid>
       <Grid container spacing={2} style={{paddingTop:'2%'}}>
       <Grid item container xs={4} direction="row">
         <Grid item container style={{border: '1px solid #eaeef4',borderRadius: '4px', flexGrow:1, height:"calc(50% - 8px)", padding: "10px 0"}}>
         <Grid item container xs={6} style={{ borderRight: '1px solid #eaeef4', height: "100%" }}>
           <Grid container direction="column" justify="space-between" style={{paddingRight: 10}}>
              <IssueAndAutoFixComponent name={t("tests.issues")} count={jobReport?.status ==null ? 0 :issueCount} icon={<IssuesIcon className={classes.testIcon}/>} type="issues" reportType="main"/>
              <IssueAndAutoFixComponent name={t("tests.autoFix")} count={jobReport?.score ==0 ? 0: jobTest.autoFix} icon={<AutofixIcon className={classes.testIcon}/>} type="autoFix" reportType="main"/>
            </Grid>
          </Grid>
          <Grid item xs={6} container style={{height: "100%", padding:"0 10px"}}>
            <Grid data-test="component-drResource" container style={{height: '100%'}} direction="column" justifyContent="space-between" >
            <Grid style={{flexGrow: 1}} item container justifyContent="space-between">
            <Grid item xs={2}>
              <InternetConnectionIcon style={{fontSize:18,color: !jobReport?.status ?'lightGray':'#8bc53f'}}/>
            </Grid>
            <Grid item xs={8}>
                <Typography variant="body1" style={{fontWeight: 500, fontSize: 12, textAlign:'center'}}> {t("internetconnection")}</Typography>
            </Grid>
            <Grid item xs={2} style={{textAlign: 'right'}}>
                <CheckCircle style={{fontSize:18,color: !jobReport?.status ?'lightGray':'#8bc53f'}}/>
            </Grid>
        </Grid>
            </Grid>
          </Grid>
          </Grid>

          <Grid item container style={{border: '1px solid #eaeef4',borderRadius: '4px', marginTop: 10,marginBottom:'14%', flexGrow:1, height:"calc(50% - 5px)"}}>
              <DRResourceSectionMultipleComponent 
             cpu={jobReport?.cpuActual} cpuMax={jobReport?.cpuCapacity} 
             ram={jobReport?.ramActual} rammax={jobReport?.ramCapacity}
             iops={jobReport?.iopsActual} iopsMax={parseInt(maxIops?.settingValue)}
             jobStatus={jobReport?.status}
           />
           </Grid>
    </Grid>
  
     <Grid item xs={8}>
      <ReportTestsComponent type={"main"} jobTest={jobTest}/>
     </Grid>
       </Grid>
     </React.Fragment>)
  }
  else
  {
    return(<React.Fragment>
     <Grid container spacing={2}>
     <Grid item container xs={4} direction="column" >
         {devicesKinds  === 1 ? loadOneKindDevice(jobReport.score,"main",FirstMostPopular,devices,jobReport.status):loadOneOrTwo()}
        <Grid item container style={{border: '1px solid #eaeef4',borderRadius: '4px', marginTop: 16, flexGrow:1, height:"calc(50% - 8px)", padding: "10px 0"}}>
        <Grid item container xs={6} style={{ borderRight: '1px solid #eaeef4', height: "100%" }}>
          <Grid container direction="column" justify="space-between" style={{paddingRight: 10}}>
             <IssueAndAutoFixComponent name={t("tests.issues")} count={jobReport?.status ==null ? 0 :issueCount} icon={<IssuesIcon className={classes.testIcon}/>} type="issues" reportType="main"/>
             <IssueAndAutoFixComponent name={t("tests.autoFix")} count={jobReport?.score ==0 ? 0: jobTest.autoFix} icon={<AutofixIcon className={classes.testIcon}/>} type="autoFix" reportType="main"/>
           </Grid>
         </Grid>
         <Grid item xs={6} container style={{height: "100%", padding:"0 10px"}}>
           <DRResourceSectionComponent 
             cpu={jobReport?.cpuActual} cpuMax={jobReport?.cpuCapacity} 
             ram={jobReport?.ramActual} rammax={jobReport?.ramCapacity}
             iops={jobReport?.iopsActual} iopsMax={parseInt(maxIops?.settingValue)}
             jobStatus={jobReport?.status}
           />
         </Grid>
       </Grid>
   </Grid>
     <Grid item xs={8}>
      <ReportTestsComponent type={"main"} jobTest={jobTest}/>
     </Grid>
       </Grid>
      </React.Fragment>)
  }
  
}

const loadOneOrTwo =()=>{
  if(devicesKinds == 2)
  {
    return(<Grid item container style={{ backgroundColor:'rgba(40, 146, 215, 0.05)', border: '1px solid rgba(40, 146, 215, 0.25)',borderRadius: '4px', height:"calc(50% - 8px)"}}>
    <Grid item xs={6} style={{ borderRight: '1px solid #eaeef4', height: "100%", paddingTop: 10}}>
      <Grid container direction="column" justify="space-between" style={{paddingLeft:'10px',paddingRight:'10px'}}>
           <TestOverviewDevicesComponent jobReportStatus={jobReport.status} deviceType={DEVICE_TYPE_NAME_SHORTCUT[DeviceType.VirtualMachine]} passed={devices.filter(d =>d.status === "ok" && d.type === DeviceType.VirtualMachine).length} warning={devices.filter(d =>d.status === "warning" && d.type === DeviceType.VirtualMachine).length} failed={devices.filter(d =>d.status === "failed" && d.type === DeviceType.VirtualMachine).length} icon={DEVICE_TYPE_SMALL_ICON[DeviceType.VirtualMachine]}></TestOverviewDevicesComponent>
       </Grid>
     </Grid>
     <Grid item xs={6} style={{height: "100%", padding: 10 }}>
      <Grid container direction="column" justify="space-between">
         <TestOverviewDevicesComponent jobReportStatus={jobReport.status} deviceType={DEVICE_TYPE_NAME_SHORTCUT[SecondMostPopular]} passed={devices.filter(d =>d.status === "ok" && d.type === SecondMostPopular).length} warning={devices.filter(d =>d.status === "warning" && d.type === SecondMostPopular).length} failed={devices.filter(d =>d.status === "failed" && d.type === SecondMostPopular).length} icon={DEVICE_TYPE_SMALL_ICON[SecondMostPopular]}></TestOverviewDevicesComponent>
       </Grid>
     </Grid>
     </Grid>)
  }
}

const getTitle =(type: any)=>{
  let title = t("testWorkflow.title");
  let res="";
  if(!type)
  {
      return title + t("testWorkflow.all");
  }
  if(type == JobRunType.All)
    res = t("testWorkflow.all");
  else if(type == JobRunType.CleanUpOnly)
    res =t("testWorkflow.cleanupOnly");
  else if(type === JobRunType.FailoverOnly)
    res = t("testWorkflow.failoverOnly");
  else
    res = t("testWorkflow.liveFailoverOnly");
  return title + res;
}
  
  const rootStore = useContext(RootStoreContext);
  const showTrends = useMediaQuery('(min-width: 1700px)');
  const reportJSX = <Grid item xs={showTrends ? 9 : 12}>
    
    <div style={{ position: "sticky", top: "65px", zIndex: 9, background: 'white' }}>
      <TimeLineSelectorComponent
        records={reportStore.timeline && reportStore.timeline.records || []}
        hasPreviousPage={reportStore.timeline && reportStore.timeline.page < reportStore.timeline.totalPages - 1}
        hasNextPage={reportStore.timeline && reportStore.timeline.page > 0 }
        selectedDate={selectedDate}
        onSelect={onDateSelected}
        onNextPage={(after) => fetchNextPage()}
        onPrevPage={(before) => fetchPrevPage()}
        selectedClassName={classes.timelineSelectedItem}
        selectedJobResultId={jobReport && jobReport.id}
        onYearSelected={handleRandomDateSelection}
      />
       </div>
     
    {jobRunState && lastJobResult? <>
      <Paper className={classes.root + ' print'}>
       <MainReportJobRunView  jobReport={lastJobResult} jobRunState={jobRunState} /> 
        </Paper>
      </>: !isLoading && jobReport ? <Paper className={classes.root + ' print'}>
   
   <Grid container direction="row" style={{ padding: "0 15px 0 15px"}}>
     <RRSDialog jobName={jobReport.jobName} jobResultId={jobReport.id} rRSTotals={jobReport.rrsTotals} open={openRRSDialog} onClose={() => setopenRRSDialog(false)} />
     <RTADialog jobName={jobReport.jobName} jobResultId={jobReport.id} rTATimeStamps={jobReport.rtaTimeStamps} open={openRTADialog} onClose={() => setopenRTADialog(false)} />
     <LogViewer jobName={jobReport.jobName} jobResultId={jobReport.id} open={openLogsDialog} onClose={() => setopenLogsDialog(false)} />
     <div style={{ display: "flex", marginRight: 10, marginLeft: 10, alignItems: 'center' }} >
       <JobCategoryIcon variant="large" status={jobReport.status || jobReport.score} style={{ height: '100%', width: 32 }} />
     </div>

     <Grid item xs={7}>
       <Grid container direction="column">
         <Grid item data-test='component-testTitle'> 
         <div className={classes.sideBySide}>
                <Typography variant="h6" style={{color: "#1f396d",textOverflow: "ellipsis", width: '95%'}} noWrap align="left">
                {(jobReport.isRenamed || jobReport.isDeleted || jobReport.isDeletedPermanently) && <Tooltip title={"Current Name: " + jobReport.jobNameFromJobs}>
                    <span>
                    {jobReport.isDeleted || jobReport.isDeletedPermanently ? t("deleted") : (jobReport.isRenamed ? t("renamed") : "")}{" - "}
                    </span>
                  </Tooltip>}
                  <Tooltip title={jobReport.jobName}>
                    <span>{t("summaryReport")} - {jobReport.jobName}</span>
                  </Tooltip>
               </Typography>
       
       </div>
         </Grid>
         
         <Grid item data-test='component-dataMover'><Typography variant="body1" align="left">{jobReport.dataMover}</Typography></Grid>
       </Grid>
     </Grid>
     <Grid item xs>
       <div style={{ textAlign: "right" }}>
         <span data-test="fullLogButton">
          <Button onClick={() => setopenLogsDialog(true)} variant="outlined" classes={{ label: classes.label }} style={{ marginRight: "10px", borderRadius: "20px" }}><FullLogIcon /> {t("button.fullLog")}</Button>
         </span>
         <span data-test="printButton">
          <Button onClick={() => { window.print() }} variant="outlined" classes={{ label: classes.label }} style={{ marginRight: "10px", borderRadius: "20px" }}><PrintIcon /> {t("button.print")}</Button>
         </span>
         <span data-test="jobActionsMenuButton">
          { userStore.user.username != "readonly"?
                <Button variant="text" className={classes.menuButton} onClick={(event) => setMenuAnchor(event.target)}><MoreVert /></Button>
                : null
          }
          
         </span>
                          <JobActionMenu jobId={jobReport.jobId} firstJobEver={jobReport && jobReport.jobId == 1} attachmentSize={jobReport.attachmentSize} edrServer={jobReport.edrServer} jobResultId={jobReport.id} jobName={jobReport.jobName} createDate={jobReport.createDate} jobNameFromJobs={jobReport.jobNameFromJobs} isDeleted={jobReport.isDeleted} isDeletedPermanently={jobReport.isDeletedPermanently} anchorEl={menuAnchor} onClose={() => setMenuAnchor(null)} />
       </div>
     </Grid>
     <Grid container direction="row" style={{ padding: "15px 0 0 0" }}>
       <Grid item xs onClick={()=>Noloadpopup() ? setopenRRSDialog(true):null}>
         <Paper style={{ margin: "5px", padding: "15px", height: '90%', boxSizing: 'border-box' }} className={!Noloadpopup() ? null : classes.hover} variant="outlined">
           <Grid container>
             <Grid item xs={8}>
               <Grid container direction="column" alignItems="flex-start">
                 <Grid item>
                   <Typography variant="body1" > {t("recoveryReadinessScore")}</Typography>
                 </Grid>
                 <Grid item data-test={`rrs-score-${reportStore.selectedJobResult.score}`}>
                   <Typography variant="h3">
                     {reportStore.selectedJobResult.score}%</Typography>
                 </Grid>
                 <Grid item>
                   <TrendIndicator value={jobReport.scoreChange} showText={true} trendUpLabel={t("morethanLastJob")} trendDownLabel={t("lessthanLastJob")} />
                 </Grid>
               </Grid>
             </Grid>
             <Grid item xs={4}>
               <RecoveryReadinessChart width={110} height={110} score={jobReport.score} />
             </Grid>
           </Grid>
         </Paper>
       </Grid>
       <Grid item xs onClick={()=>Noloadpopup() ? setopenRTADialog(true):null}>
         <Paper style={{ margin: "5px", padding: "15px", height: '90%', boxSizing: 'border-box' }} className={!Noloadpopup() ? null: classes.hover} variant="outlined">
           <Grid container>
             <Grid item xs={8}>
               <Grid container direction="column" alignItems="flex-start">
                 <Grid item>
                   <Typography variant="body1"> {t("recoveryTime")}</Typography>
                 </Grid>
                 <Grid item data-test={`rt-time-${ConvertStringToTimeSpan(reportStore.selectedJobResult.rta).totalSeconds}`}>
                   <span>
                     <Typography variant="h3" style={{ width: "100%" }}> {convertSecondsToTime(ConvertStringToTimeSpan(reportStore.selectedJobResult.rta).totalSeconds)}</Typography>
                   </span>
                 </Grid>
                 <Grid item>
                   <Typography variant="body2"> {t("RTOwasSetTo")} {convertSecondsToTime(ConvertStringToTimeSpan(reportStore.selectedJobResult.rto).totalSeconds)}</Typography>
                 </Grid>
               </Grid>
             </Grid>
             <Grid item xs={4}>
               <GaugeChart width={100} height={110} value={ConvertStringToTimeSpan(jobReport.rta).totalSeconds} max={ConvertStringToTimeSpan(jobReport.rto).totalSeconds} />
             </Grid>
           </Grid>
         </Paper>
       </Grid>
     </Grid>
     {/*  chart tiles ends */}
   </Grid>
   <Grid container direction="column" style={{ padding: "15px" }}>
     <Grid item container justify="space-between" >
       <Grid item> 
       <Typography variant="h6">{getTitle(jobReport.jobRunType)}</Typography></Grid>
       <Grid item><Typography variant="body1" align="left">{t("runFor")} {convertTimespanToString(ConvertStringToTimeSpan(jobReport.totalRunTime))}, {t("finishedAt")}:{moment(reportStore.selectedJobResult.runDate).format("M.D.YYYY, h:mm A")}</Typography></Grid>
     </Grid>
     <Grid item style={{ marginTop: "15px" }}>
       <TestWorkFlowComponent variant="regular" workflowScripts={jobReport.prePostScripts} workflowSteps={jobReport.knownIssues || []} jobRunState={jobRunState} runDate={jobReport.runDate}></TestWorkFlowComponent>

     </Grid>

   </Grid>
   {jobReport.score === 0 &&  <Grid container direction="column" style={{ padding: "15px" }}>
     <Grid item container justify="space-between" >
       <Grid item> <Typography variant="h6" color="error">{t("failedReport")}</Typography></Grid>
     </Grid>
     <Grid item style={{ marginTop: "15px" }}>
       <div style={{height: "40px",
                 borderRadius: "4px",
                 color: '#077187',
                 display: 'flex',
                 alignItems: 'center',
                 paddingLeft: 10,
                 border: "solid 1px #83b8c3",
                 backgroundColor: "#f2f8f9"}}>
          
          {jobReport.knownIssues && jobReport.knownIssues.length > 0 ? showError(jobReport.knownIssues) : t('failoverIssue')}  
          {jobReport.EndMessages && jobReport.EndMessages !== "" && jobReport.EndMessages !== undefined ? <Typography color="error" variant="body1">
          {jobReport.EndMessages} </Typography> : null}
       </div>
     </Grid>

   </Grid>}
   
   <Grid container direction="row">
     <Grid item xs={12} md={12} xl={12} className="p-max-70">
       <Grid item style={{ padding: "0px 15px 0px 15px" }}>
         <Grid item style={{textAlign: "left"}}>
           <Typography variant="h6" display="inline" noWrap={true} align="left" >{t("testOverview")}</Typography>
         </Grid>
         <Paper style={{ marginTop: "7px", borderColor:'white' }} variant="outlined" >
          {loadDeviesKindSection()}
         </Paper>
       </Grid>
     </Grid>
     
   </Grid>
   <Grid container style={{ padding: "15px" }}>
     {jobReport.score > 0 && <ReportDeviceSection testGroups={testGroups} testGroupNames={testGroupNames} devices={devices} defaultAnimationPlayState={animationPlaySettings && animationPlaySettings.settingValue === 'true'}/>}
   </Grid>
</Paper>
 : <div className={classes.root} style={{ padding: 0 }}> <ReportSkeleton /></div>}
  </Grid>


  return <Grid container direction="row">
    <Grid item xs={3} xl={2} className={classes.filter_container}>
      <div style={{height: '90%', overflow:'hidden'}}>
        <span data-test="activeJobsFilter">
          <ReportJobFilter selectedJobId={reportStore.JobId} section={"regular"} jobList={jobList && jobList.filter(item=>item.isDeleted !== true && item.isDeletedPermanently !== true)} onChange={selectJob} />
        </span>   
      </div>
      <Divider></Divider>
      <div style={{height: '49%', overflow: 'hidden'}}>
        <span data-test="deletedJobsFilter">
          <ReportJobFilter selectedJobId={reportStore.JobId} section={"deleted"} jobList={jobList && jobList.filter(item=>item.isDeleted === true || item.isDeletedPermanently === true)} onChange={selectJob} />
        </span>
      </div>
    </Grid>
    <Grid item xs={9} xl={10} >
    <AppBar showDevices={true}>
                    <Grid container>
                        <Grid xs={9}>
                        <AppBarTitle>
                        <Typography style={{ textAlign: "left" }} variant="h6">
                            {`Report for Job ${(jobReport && jobReport.jobNameFromJobs) || ""}`}
                        </Typography>
                        {checkJobRunning() &&  <Typography variant="h6" style={{color: '#2892D7',cursor:'pointer'}} onClick={()=>history.push('/jobs')}>{t('jobRunning')}</Typography>}
                        {checkJobRunning() && <CircularProgress id="job_running" size={18} style={{color: '#2892D7', marginLeft: '1%'}}/>}

                        </AppBarTitle>
                    
                        </Grid>
                        <Grid xs={3}  alignItems='center'>
                        </Grid>
                    </Grid>
                </AppBar>

      <Grid container direction="row" style={{ background: "#E9EBEE" }}>
        {reportJSX}
        {showTrends && 
          <Grid item xs={3}>
            {jobReport && <TrendSection timelineRecords={numberDateRecords} isRunning={checkJobRunning()} jobId={jobReport.jobId} jobResultId={jobReport.id} knownIssues={jobReport.knownIssues} nextRun={jobReport.nextRun} devices={devices} isLoading={!jobReport || isLoading} scheduleStaus={jobReport.scheduleStaus} scheduleInfo={jobReport.scheduleInfo} scheduleNextRunInfo={jobReport.scheduleNextRunInfo}/>}
          </Grid>}
        {!showTrends &&
          <Drawer variant="temporary" anchor={'right'} open={openTrendDrawer} ModalProps={{ BackdropProps: { invisible: true } }} onClose={() => toggleTrendDrawer(false)}>
            {jobReport && 
              <TrendSection timelineRecords={numberDateRecords} isRunning={checkJobRunning()} jobId={jobReport.jobId} jobResultId={jobReport.id} knownIssues={jobReport.knownIssues} nextRun={jobReport.nextRun} devices={devices} isLoading={!jobReport || isLoading} scheduleStaus={jobReport.scheduleStaus} scheduleInfo={jobReport.scheduleInfo} scheduleNextRunInfo={jobReport.scheduleNextRunInfo}/>}
          </Drawer>}
        {!showTrends && <Fab color="primary" style={{ position: 'fixed', right: '10px', bottom: '10px' }} onClick={() => toggleTrendDrawer(true)}>
          <TrendingUpIcon />
        </Fab>}
      </Grid>
    </Grid>
  </Grid>
}

export default observer(ReportComponent);
function clsx(arg0: any[]): string {
  throw new Error('Function not implemented.');
}

