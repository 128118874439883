import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { CircularProgress, Divider, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, makeStyles, Switch, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RootStoreContext } from '../../../../app/stores/root.store';
import { EDRTextField } from '../../../../app/common/EDRTextField';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import HelpIcon from '@material-ui/icons/Help';
import { SaveButton } from '../../../../app/common/saveButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { runInAction } from 'mobx';
import ConfirmationDialog from '../../../../app/common/confirmationDialog.component';
import { EDROutlinedInput } from '../../../../app/common/EDROutlinedInput';
import ReactDOM from 'react-dom';
import { EDRAutocomplete } from '../../../../app/common/EDRAutocomplete';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import { DataMover } from '../../../../app/models/BaseSettings.enum';
import { AccessCredentials, CONSTS, CredentialUserType } from '../../../../app/models/createjob/createJob.model';
import { DevicePlatform } from '../../../../app/models/jobProperties.model';

const useStyles = makeStyles({
    formControl: {
        width: '100%',
        height:'70px',
        '& label': {
            background: "white",
            width: "auto",
            padding:"0 4px 0 4px",
            fontSize: '17px',
          }
    },
    helpIcon:
    {
      color: '#1976d2',
      fontSize: "large"
    },
    MainHeading:
    {
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.19',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#1f396d',
        marginLeft:'10px'
    },
    SecondHeading:{
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        flexGrow: 1
    },
    divider: {
            backgroundColor: '#eaeef4',
            margin: '0',
            marginTop: '10px',
            marginLeft: '10px',
    },
    });   

const PrimaryAdvancedComponent : React.FC = () => {
    let labelRef;
    const classes = useStyles();
    const [t] = useTranslation("createJobBase");
    const rootStore = useContext(RootStoreContext); 
    const {displayStore,jobSettingsStore,createJobStore} = rootStore;
    const {dataMover} =createJobStore.baseSettings;
    const [openConfirmationDialog, setopenConfirmationDialog] = useState(false);
    const [windowsPasswordVisibility, setWindowsPasswordVisibility] = useState(true);
    const [showEyeWindowsPassword, setShowEyeWindowsPassword]= useState(true);
    const previousPrimaryUsername = jobSettingsStore.accessCredentials;
    const [primaryAutocomplete,setPrimaryAutocomplete] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    useEffect(() => {
        let user = jobSettingsStore.accessCredentials.find(l => l.userName === jobSettingsStore.selectedPrimaryUser.userName);
        const initialValues =
        {
            PrimaryUser: displayStore.BaseDrawerSettings.addPrimaryUser ?'' :user!=undefined ? user.userName:'',
            PrimaryPassword: displayStore.BaseDrawerSettings.addPrimaryUser ?'': user!=undefined ? user.password:'',
        };
        form.resetForm({values: initialValues});
    }, [displayStore.BaseDrawerSettings.addPrimaryUser]);

    const validation = yup.object({
        PrimaryUser:yup.string().required('Required')
        .test('empty','username must be not empty',PrimaryUser => PrimaryUser!='')
        .max(50,'up to 50 characters'),
  
        PrimaryPassword:yup.string().required('Required')
        .test('empty','password must be not empty',PrimaryPassword => PrimaryPassword!='')
        .max(50,'up to 50 characters'),
        })
      
      const form = useFormik({
          initialValues: 
          {
            PrimaryUser:'',
            PrimaryPassword:'',
          },
          validationSchema:validation,
          validateOnChange: true,
          onSubmit: () => { },
        })

    const handleAdd  = async() =>{
        setButtonsDisabled(true);
        await jobSettingsStore.createCredentials(CredentialUserType.GeneralUser);
        setButtonsDisabled(false);
    }

    const handleDelete  = async() =>{
        setButtonsDisabled(true);
        await jobSettingsStore.deleteCredentials(CredentialUserType.GeneralUser);;
        setButtonsDisabled(false);
    }

    const handleUpdate  = async() =>{
        setButtonsDisabled(true);
        //let user = jobSettingsStore.accessCredentials.find(l => l.userName === createJobStore.baseSettings.workflowTest.PrimaryUser);
        let Credentials = new AccessCredentials();
        Credentials = {id : jobSettingsStore.selectedPrimaryUser.id, userName:form.values.PrimaryUser, password:form.values.PrimaryPassword, userType:CredentialUserType.GeneralUser};
        await jobSettingsStore.editCredentials(Credentials);
        setButtonsDisabled(false);
    }

    const handleConfirmationPopup =(confirm:boolean)=>{
        if(confirm)
        {
            handleDelete();
        }
        setopenConfirmationDialog(false);
    }

    const handlePrimaryPasswordChange = (event) => {
        form.setFieldValue("PrimaryPassword",event.target.value);
        if(event.target.value != '')
        {
            setPrimaryAutocomplete(true);
            setShowEyeWindowsPassword(true);
            createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest,PrimaryPassword : event.target.value}});
            if(createJobStore.networkSetting.domainController != null && createJobStore.networkSetting.domainController != undefined && createJobStore.networkSetting.domainController.domainCredentials != null && !createJobStore.networkSetting.domainController.domainControllerName.includes('I have a Domain Controller inside my replication'))
            {
                let credentials= {id: createJobStore.networkSetting.domainController.domainCredentials.id,userName: rootStore.createJobStore.networkSetting.domainController.domainCredentials.userName,password:event.target.value,userType:CredentialUserType.GeneralUser};
                rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController,  domainCredentials: credentials}});
            }
        }
    };

    const handleMouseClick =()=>{
        if(form.values.PrimaryPassword === CONSTS.MaskedPassword)
        {
            form.setFieldValue("PrimaryPassword",'',true);
        }
    }

    const handleMouseOut =()=>{
        if(form.dirty && form.values.PrimaryPassword == '')
        {
            if(form.values.PrimaryUser!='')
            {
                return;
            }
            else form.setFieldValue("PrimaryPassword",CONSTS.MaskedPassword,true);
        }
    }

    const handleClickShowPassword  = () => {
        setWindowsPasswordVisibility(windowsPasswordVisibility ? false : true); 
    };

    const handlePrimaryUserChange = (event:React.ChangeEvent,value:any) => {
        if(event!=null){
        let credentials;
        let onInput= event.type=="click"? false:true;
         setPrimaryAutocomplete(onInput);
        if (value!=null && typeof value === 'object') 
        {
          form.setFieldValue("PrimaryUser",value=="" ? '':value.userName,true);
          form.setFieldValue("PrimaryPassword",'',true);
          createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest, PrimaryUser: value=="" ? '':value.userName,PrimaryPassword:''}});
          let userNoDomain= value == "" ? '':value.userName.split('\\')[0];
          credentials= {userName:userNoDomain ,password:value=="" ? '':value.password,userType:CredentialUserType.GeneralUser};
          rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController,  domainCredentials: credentials}});
        }
        if (typeof value === 'string')
        {
          const userObj = previousPrimaryUsername.find(e => e.userName.toLocaleLowerCase() == value.toLocaleLowerCase());
          form.setFieldValue("PrimaryUser", userObj!=undefined ? value:(value==""?'':value),true);
          let pass = '';
          form.setFieldValue("PrimaryPassword",pass,true);
          createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest, PrimaryUser: value=="" ? '':value,PrimaryPassword:pass}});
          let userNoDomain= value == "" ? '':value.split('\\')[0];
          credentials= {userName:userNoDomain,password:pass,userType:CredentialUserType.GeneralUser};
          rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController,  domainCredentials: credentials}});
        }
        setShowEyeWindowsPassword(onInput);
        if(!onInput)
        {
            setWindowsPasswordVisibility(true);
        }
      }
    };

    const loadPrimaryUsername= ()=>{
        return (<Tooltip title={t('domainUserPassInfo')} placement="top">  
        <FormControl className={classes.formControl} variant='outlined'> 
        <EDRAutocomplete id="PrimaryUser"
          freeSolo
          disableClearable
          value={form.values.PrimaryUser}
          options={previousPrimaryUsername}
          renderOption={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
          getOptionLabel={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
          onChange={handlePrimaryUserChange}
          onInputChange={handlePrimaryUserChange}
          renderInput={(params) => (<EDRTextField {...params}
          label= {t(dataMover=== DataMover.VeeamSQL? 'workflowUser': 'Windows Username')}
          InputLabelProps={{shrink: true}} 
          margin="normal" 
          variant="outlined"
          placeholder="Select/ Create New"
          aria-autocomplete="none" 
          onBlur={form.handleBlur}
          error={form.touched && form.touched.PrimaryUser && form.errors && Boolean(form.errors.PrimaryUser)}
          helperText={form.touched && form.touched.PrimaryUser && form.errors && Boolean(form.errors.PrimaryUser) && t(form.errors.PrimaryUser)}
          />)}
        />
        </FormControl>
        </Tooltip>
  );
      }
  
    const loadPrimaryPassword=()=>{
      return(<Tooltip title={t('domainUserPassInfo')} placement="top">  
      <FormControl className={classes.formControl} variant='outlined'>
          <InputLabel ref={ref => {labelRef = ReactDOM.findDOMNode(ref)}}  style={{transform: 'translate(14px, 10px) scale(0.75)',color:(form.touched && form.touched.PrimaryPassword && form.errors &&  Boolean(form.errors.PrimaryPassword)) ?'red':''}}>{t(dataMover=== DataMover.VeeamSQL?'WorkflowPassword':'windowsPass')}</InputLabel>
          <EDROutlinedInput id="PrimaryPassword" className="MuiFormControl-marginNormal"
              value={form.values.PrimaryPassword}
              labelWidth={labelRef ? labelRef.offsetWidth:0}
              autoComplete="off"
              type={windowsPasswordVisibility ? 'password':'text'}
              placeholder="Type Here"
              onClick={handleMouseClick}
              onBlur={handleMouseOut}
              onChange={handlePrimaryPasswordChange}
              endAdornment={form.values.PrimaryPassword && primaryAutocomplete ?
              <InputAdornment position="end">
              <IconButton
              aria-label="toggle password visibility"
              aria-autocomplete="none"
              onClick={()=>handleClickShowPassword()}
              >
              {windowsPasswordVisibility ?  <VisibilityOff />: <Visibility />}
          </IconButton>
  </InputAdornment>
   :null }
  />  
  <FormHelperText id="helperTextPrimaryPassword" style={{color:'red',marginTop:'0px'}}>{form.touched && form.touched.PrimaryPassword && form.errors &&  Boolean(form.errors.PrimaryPassword) &&t(form.errors.PrimaryPassword)}</FormHelperText>
  </FormControl>
  </Tooltip>
      )
    }
    
    
    return(
    <Grid container direction="column" style={{paddingRight: 16}}>

        <div style={{ display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
            <Typography variant='h6' className={classes.MainHeading}>{displayStore.BaseDrawerSettings.addPrimaryUser ? t("addPrimaryUser"):t("editPrimaryUser")}</Typography>
            <div style={{ display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
            {!displayStore.BaseDrawerSettings.addPrimaryUser ? <SaveButton onClick={()=>setopenConfirmationDialog(true)} disabled={buttonsDisabled} variant="contained" color="primary" style={{height:'25px',textTransform:'none',marginRight:'10px'}}>
                    {t('delete')}
                    {buttonsDisabled ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                </SaveButton>:null}
                <SaveButton onClick={displayStore.BaseDrawerSettings.addPrimaryUser ? handleAdd :handleUpdate} disabled={form.values.PrimaryUser ==='' || form.values.PrimaryPassword ==='' || buttonsDisabled} variant="contained" color="primary" style={{height:'25px',textTransform:'none'}}>
                    {displayStore.BaseDrawerSettings.addPrimaryUser ? t('add'): t('apply')}
                    {buttonsDisabled ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                </SaveButton>   
            </div>
        </div>

        <Grid item container direction="row">
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px' ,marginLeft:'10px'}}>
            <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('WindowsCredentials')}</Typography>
            <Tooltip title={displayStore.BaseDrawerSettings.addPrimaryUser ? t("AddPrimaryInfo"): t('editPrimaryInfo')} arrow>
                <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
            </Tooltip> 
            <Divider className={classes.divider} style={{width:displayStore.BaseDrawerSettings.addPrimaryUser? '245%':'200%'}}/>
        </div>
    </Grid>

    <Grid item direction="row" container xs={12} style={{marginTop:'8px',marginLeft:'1%'}} spacing={2}>
        <Grid item xs={6}>
            {loadPrimaryUsername()}
        </Grid>
        <Grid item xs={6}>
            {loadPrimaryPassword()}
        </Grid> 
    </Grid>
<ConfirmationDialog type="deleteUser" device={null} open={openConfirmationDialog} onClose={(confrim)=>handleConfirmationPopup(confrim)} children={"You are about to delete existing windows user, are you sure ?"}></ConfirmationDialog>
    </Grid>);
};

export default observer(PrimaryAdvancedComponent);