
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputLabel, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EDRTextField } from "../../../../app/common/EDRTextField";
import { SaveButton } from "../../../../app/common/saveButton";
import { EDRDialogTitle } from "../../../../app/common/EDRDialogTitle";
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EDRAutocomplete } from '../../../../app/common/EDRAutocomplete';
import { RootStoreContext } from '../../../../app/stores/root.store';
import { JobDevice } from '../../../../app/models/jobDevice/jobDevice.model';
import { DeviceType } from '../../../../app/models/deviceType.enum';
import { DeviceTest, TestCategory } from '../../../../app/models/createjob/createJob.model';
import DeviceTestChart from '../../../../app/common/testChart.component';
import { TestType } from '../../../../app/models/jobTest.model';
import { DatabaseType, MachineType } from '../../../../app/models/jobProperties.model';
import jobAgent from '../../../../app/api/jobAgent';
import { toast } from 'react-toastify';
const useStyles = makeStyles({
    root: {
        minWidth: '30vw'
    },
    heading:{
        fontFamily: 'Roboto',
        fontSize: '13px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontstyle: 'normal',
        lineHeight: 1.15,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#4d5f75',
    },
    title:{
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontstyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        textAlign: 'left'
    },
    centerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between'
    },
    toggleItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiListItemText-root' : {
            marginLeft: 10
        },
        '& .MuiSvgIcon-root': {
            color: '#8092a9'
        }
    }
})
export interface WebTestSettingProps {
    open: boolean;
    onClose: () => void;
    isDefault:boolean;
    deviceName: string;
}

const validationSchema = yup.object({
    websiteUrl: yup.string().required('Required'),
    networkToPing: yup.string().required('Required')
})
const WebTestSettingDialog: React.FC<WebTestSettingProps> = (props) => {
    const { open,isDefault,deviceName, onClose } = props;
    const classes = useStyles();
    const { createJobStore } = useContext(RootStoreContext);
    const getPreviousInternetSettings = [{networkURL:'',websiteURL:''}]
    const [device, setDevice] = useState(createJobStore.selectedDevices.find(device=>device.deviceName == deviceName));
    const { t } = useTranslation('createJobBase');
    const [cancel,setCancel]=useState(false);

    const form = useFormik({
        initialValues: {
            networkToPing: 'https://google.com',
            textResult: 'Google'
        },
        onSubmit: () => { },
        validationSchema
    })

    const handleClose = () => {
        if(form.dirty)
            setCancel(!cancel);
        else
            onClose();
    }

    const handleSave=()=>{
        let tests: Array<DeviceTest> =[{
            testCategory: TestCategory.Default, 
            testType: TestType.web, 
            selected: true,
            webPortalToTest: form.values.networkToPing,
            webPortalResult: form.values.textResult,
            customCommandToTest: '', 
            customCommandExpectedResult: '', 
            customCommandToTestIsExternal: false,
            databaseType: DatabaseType.SQL,
            databaseNameToTest: '',
            databaseQueryToTest: '',
            databaseServerNameToTest: '',
            databaseUsername: '',
            databasePassword: '',
            databasePort:'',
            dbWindowsAuth:false,
            serviceToTest: '',
            script:'',
            testFromEDRC: false,
            thirdDeviceNetworkToTest: '',
            authenticationUserToTest:'',
            authenticationPassToTest:'',
        }];
        setCancel(false);
        onClose();
    }

    const handleTest=() =>{        
        var details ={webURL:form.values.networkToPing,webResult:form.values.textResult,Hostname:device.sourceName};
        jobAgent.JobTestsActions.TestWeb(details).then(res => {
            if(res)
                toast.success("Web Test succeeded");
            else
                toast.error("Web Test failed");
        }).catch(err=>{
            if(err && err.data && err.data.errors)
                toast.error(err.data.errors);
        });        
    }

    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel style={{marginLeft:'10px'}}>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const handleYes=()=>{
        let initialValues = {
            networkToPing: 'https://google.com',
            textResult: 'Google'
        }
        form.setValues(initialValues);
        setCancel(false);
        onClose();
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    const handleAutoCompleteChange = (event: React.ChangeEvent,value:any) => {
        if(event!=null)
        {
          let fieldName= event.target['id'].includes('-') ? event.target['id'].split('-')[0]: event.target['id'];
          form.setFieldValue(fieldName,value);
          switch(fieldName) {
            case 'networkToPing': handleNetworkPingChange(event,value); break;
            case 'textResult': handleTextResultChange(event,value); break;
          }
        }
      }
    
      const handleNetworkPingChange=(event:object,value:any)=>{
        form.setFieldValue("networkToPing",value,true);
      }

      const handleTextResultChange=(event:object,value:any)=>{
        form.setFieldValue("textResult",value,true);
      }

    return <Dialog disableBackdropClick
        open={open}
        classes={{ paper: classes.root }}
        onClose={handleClose}
        scroll="paper"
    >
         <EDRDialogTitle className={classes.title}>
            <Typography variant="h6" className={classes.title}>{t('defaultInternetTestSetting')}</Typography>
        </EDRDialogTitle>
        <DialogContent dividers={true}>
            <Grid container direction="column" spacing={2}>
                <Grid item container>
                    <Grid item xs={3} container alignItems="center">
                        <Typography variant="h6" className={classes.heading}>* {t('networkToPing')}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                    <EDRAutocomplete id="networkToPing"
                        freeSolo
                        disableClearable
                        value={form.values.networkToPing}
                        options={getPreviousInternetSettings}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderOption={(option: string|{networkURL: string,websiteURL:string}) => (typeof option === 'string' ? option : `${option.networkURL}`)}
	                    getOptionLabel={(option: string|{networkURL: string,websiteURL:string}) => (typeof option === 'string' ? option : `${option.networkURL}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={t('url')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="networkToPing"
                        onBlur={form.handleBlur}
                        error={form.touched.networkToPing && Boolean(form.errors.networkToPing)}
                        helperText={form.errors.networkToPing}
                    />)}
                    />  
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>

                <Grid item container>
                    <Grid item container spacing={2}>
                    <Grid item xs={3} container alignItems="center">
                        <Typography variant="h6" className={classes.heading}>* {t('textResult')}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                    <EDRAutocomplete id="textResult"
                        freeSolo
                        disableClearable
                        value={form.values.textResult}
                        options={getPreviousInternetSettings}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderOption={(option: string|{networkURL: string,textResult:string}) => (typeof option === 'string' ? option : `${option.textResult}`)}
	                    getOptionLabel={(option: string|{networkURL: string,textResult:string}) => (typeof option === 'string' ? option : `${option.textResult}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={t('textResult')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="textResult"
                        onBlur={form.handleBlur}
                        error={form.touched.textResult && Boolean(form.errors.textResult)}
                        helperText={form.errors.textResult}
                    />)}
                    />  
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
        <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end'}}>
            <Button onClick={handleClose} color="primary" style={{textTransform:'none'}}>
                {t('cancel')}
            </Button>
            <Button onClick={handleTest} disabled={form.values.networkToPing ==''} color="primary" style={{textTransform:'none'}}>
                {t('test')}
            </Button>
            <SaveButton onClick={handleSave} variant="contained" color="primary" style={{textTransform:'none'}}>
                {t('save')}
            </SaveButton>
            </Grid>
        </DialogActions>
    </Dialog>
}

export default WebTestSettingDialog;