import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
const useStyle = makeStyles({
    background: {
        backgroundColor: '#f4f5f9',
        height: '100vh'
    },
    container: {
        width: 'auto',
        padding: '0 10px',
        height: 536,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    img: {
        width: '299px',
        height: '283px'
    },
    h1: {
        color: '#1f396d',
        fontSize: '28px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.18',
        letterSpacing: 'normal',
        marginTop: 22
    },
    h2: {
        color: '#4d5f75',
        fontSize: '16px',
        marginTop: '20px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.38',
        letterSpacing: 'normal'
    },
    logoImg: {
        marginBottom: '35px',
        width: '299px',
        height: '48px'
    }
});
const MaintainanceView = () => {
    const classes = useStyle();
    return (
        <Grid container className={classes.background} direction="column" justify='center' alignItems='center'>
            {/* <img className={classes.logoImg} src="/assets/images/login_logo.png" /> */}
            <div className={classes.container}>
                {/* <img src="/assets/images/maintainance.png" className={classes.img} /> */}
                <Typography variant='body1' className={classes.h1}>
                The EnsureDR Management Server is Down for Maintenance
                 </Typography>
                <Typography variant='body1' className={classes.h2}>
                Check soon to use the system again.
                 </Typography>
            </div>
        </Grid>
    );
};

export default MaintainanceView;