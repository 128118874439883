import Chart from 'chart.js';


//TODO Refactor the afterDraw method
//TODO add configuration options for the gauge like needle, scale ticks. Add Higher Order function

function drawNeedle(cx, cy, radius, radianAngle, ctx) {
    ctx.translate(cx, cy);

    ctx.rotate(radianAngle);
    ctx.lineCap="round"
    ctx.beginPath();
    ctx.moveTo(0, -5);
    ctx.lineTo(radius-5, 0);
    ctx.lineTo(0, 5);
    ctx.fillStyle = '#2892d7';
    ctx.fill();
    ctx.rotate(-radianAngle);
    ctx.translate(-cx, -cy);
    ctx.beginPath();
    ctx.arc(cx, cy, 5, 0, Math.PI * 2);
    ctx.fill();

    ctx.beginPath();
    ctx.fillStyle = 'white';
    ctx.arc(cx, cy, 2, 0, Math.PI * 2);
    ctx.fill();
}
/**
 * @description : draws the icon in the center of the dougnut chart
 * 
 */
const GaugePlugin = function(drawTicks): Chart.PluginServiceRegistrationOptions {
    return {
    beforeDraw: function (chartInstance: Chart) {
        var ctx = chartInstance.ctx;
        const outerRadius = chartInstance['outerRadius'];
        const radiusLength = chartInstance['radiusLength'];

        const arc = chartInstance.getDatasetMeta(0).data[0]
        var vm = arc._view;
        var endAngle = chartInstance.config.options.circumference;

        // draw arc

        const xc = vm.x;
        const yc = vm.y;

        const start = chartInstance.config.options.rotation;
        const r = vm.outerRadius;

        ctx.beginPath();
        ctx.lineCap = "round";
        ctx.arc(xc, yc, outerRadius - (radiusLength/2), start, start+endAngle);
        ctx.lineWidth = radiusLength;
        ctx.strokeStyle = "#e6e7ec";
        //ctx.strokeStyle = "#f4f5f9";
        ctx.stroke();
        ctx.beginPath();
        ctx.strokeStyle='#bebfc5'
        ctx.lineWidth=1;
        if(drawTicks) {
        const innerRadius = outerRadius - radiusLength- 2;
        let  j =1;
        for(let i =0 ; i<endAngle+start; i+=endAngle/15,j++) {
            const theta = start + i;
            let drawMajor = j%3==0;
            var tickLength = drawMajor?12:7;
            var x1 = xc+ (innerRadius )* Math.sin(theta);
            var y1 = yc- (innerRadius)* Math.cos(theta);
           
           
            var x2 = xc+ (innerRadius-tickLength )* Math.sin(theta);
            var y2 = yc- (innerRadius-tickLength)* Math.cos(theta);
           
           ctx.moveTo(x1,y1);
           ctx.lineTo(x2,y2);
        }
        ctx.stroke();
    }
    },
    afterDraw: function (chartInstance: Chart, options) {
        var ctx = chartInstance.ctx;
        const innerRadius = chartInstance['innerRadius'];
        const radiusLength = chartInstance['radiusLength'];

        const arc = chartInstance.getDatasetMeta(0).data[0]
        var vm = arc._view;
        var angle = vm.endAngle;

        // draw arc

        const xc = vm.x;
        const yc = vm.y;

        const start = vm.startAngle;
        const r = vm.outerRadius;

        ctx.beginPath();
        var xStart = xc + Math.cos(start) * r;
        var xEnd = xc + Math.cos(angle) * r;
        var yStart = yc + Math.sin(start) * r;
        var yEnd = yc + Math.sin(angle) * r;

        ctx.lineCap = "round";
        var gradient = ctx.createLinearGradient(xStart, yStart, xEnd, yEnd);
        gradient.addColorStop(0, "#2892d7");
        gradient.addColorStop(1.0, "#2ca58d");
        ctx.arc(xc, yc, vm.outerRadius - (radiusLength / 2), start, angle);
        ctx.lineWidth = radiusLength;
        ctx.strokeStyle = gradient;
        ctx.stroke();
        //paint needle circle
        ctx.beginPath();
        ctx.fillStyle = 'lightgray';
        ctx.arc(xc, yc, 10, 0, Math.PI * 2);
        ctx.fill();
        drawNeedle(vm.x, vm.y, innerRadius, angle, ctx);
    }
    }
}

export default GaugePlugin;