import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const ReportSkeleton = () => {
    return <Paper style={{ padding: "15px", }}>
        <Grid container direction="column">
            <Grid item>
                <Grid container direction="row" justify="space-between">
                    <Grid item>
                        <Skeleton variant="circle" width={50} height={50}></Skeleton>
                    </Grid>
                    <Grid item style={{ flexGrow: 1, marginLeft: "10px" }}>
                        <Skeleton variant="text"></Skeleton>
                        <Skeleton variant="text"></Skeleton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{ marginTop: "20px" }}>
                <Skeleton variant="text" width={200}></Skeleton>
                <Grid container direction="row" justify="space-around">
                    <Grid item xs style={{ margin: "5px" }}>
                        <Skeleton variant="rect" height={130}></Skeleton>
                    </Grid>
                    <Grid item xs style={{ margin: "5px" }}>
                        <Skeleton variant="rect" height={130} ></Skeleton>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item style={{ marginTop: "20px" }} >
            <Skeleton variant="text" width={200}></Skeleton>
                <Skeleton variant="rect" height={60}></Skeleton>
            </Grid>

            
            <Grid item style={{ marginTop: "20px" }} >
            <Skeleton variant="text" width={200}></Skeleton>
                <Skeleton variant="rect" height={200}></Skeleton>
            </Grid>
            <Grid item style={{ marginTop: "20px" }} >
                <Skeleton variant="rect" height={120}></Skeleton>
            </Grid>
            
            <Grid item style={{ marginTop: "20px" }} >
                <Paper>
                <Skeleton variant="rect" height={280}></Skeleton>
                </Paper>
            </Grid>
        </Grid>
    </Paper>
}


export default ReportSkeleton;