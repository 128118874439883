import { observable, action, computed, runInAction, reaction } from 'mobx'
//import { history} from '../../'
import { toast } from 'react-toastify';
import { RootStore } from '../../app/stores/root.store';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

export enum UpdateType {
    JobRemoved = 'JobRemoved',
    JobRenamed = 'JobRenamed',
    JobRestored = 'JobRestored',
    JobRunUpdate = 'JobRunUpdate',
    ForceRefresh = 'ForceRefresh',
    JobAdded = 'JobAdded',
    CopyInfraCredentials = 'CopyInfraCredentials',
    ReValidate = 'ReValidate',
    LicenseAdded = 'LicenseAdded',
    LicenseDeleted = 'LicenseDeleted',
    LicenseUpdated = 'LicenseUpdated',
    FirstRunDefaultLoaded = 'FirstRunDefaultLoaded'
}
export default class ActivityStore {
    private subscribers: {[key: string]: Array<(val: any) => void>} = {};

    @observable.ref hubConnection: HubConnection | null = null;

    sendMessage(message: UpdateType, value?: any): void {
        const mSubscribers = this.subscribers[message];
        if(mSubscribers) {
            mSubscribers.forEach(subscriber => {
                subscriber(value);
            })
        }
    }
    on<T>(message: UpdateType, callback: (val: T) => void) {
        const mSubscribers = this.subscribers[message] || [];
        mSubscribers.push(callback);
        this.subscribers[message] = mSubscribers;
    }

    off(message: UpdateType, callback: (val) => void) {
        let mSubscribers = this.subscribers[message] || [];
        mSubscribers = mSubscribers.filter(subscriber => subscriber != callback);
        this.subscribers[message] = mSubscribers;
    }
}
