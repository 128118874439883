import * as yup from 'yup';

export const MonitoringSettingSchema = yup.object({
    monitoringinterval: yup.number()
        .max(300, "maxMonitorInterval")
        .required("requiredMonitorInterval")
        .min(20, "minMonitorInterval"),
    cpuPeakPercentageThreshold: yup.number()
        .max(100, "maxCPUPeakPercentageThreshold")
        .required("requiredCPUPeakPercentageThreshold")
        .min(1, "minCPUPeakPercentageThreshold"),
    cpuPeakPercentageHighThreshold: yup.number()
        .max(100, "maxCPUPeakPercentageHighThreshold")
        .required("requiredCPUPeakPercentageHighThreshold")
        .min(1, "minCPUPeakPercentageHighThreshold").when(['cpuPeakPercentageThreshold'], {
            is: cpuPeakPercentageThreshold => cpuPeakPercentageThreshold > 0,
            then: yup
              .number()
              .test('conditionalMax', "cpuHighBiggerThanWarning", function(v, ctx) {
                const formValue = ctx.parent;
                if (v > formValue.cpuPeakPercentageThreshold) return true;
                else return false;
              })
        }),
    lowDiskSpaceThresholdInGB: yup.number()
        .max(10000, "maxLowDiskSpaceThresholdInGB")
        .required("requiredLowDiskSpaceThresholdInGB")
        .min(5, "minLowDiskSpaceThresholdInGB"),
    lowMemoryThresholdInMB: yup.number()
        .max(100000, "maxLowMemoryThresholdInMB")
        .required("requiredLowMemoryThresholdInMB")
        .min(500, "minLowMemoryThresholdInMB"),




  })