import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, makeStyles, Tooltip, Typography } from '@material-ui/core';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useTranslation } from 'react-i18next';
import { RootStoreContext } from '../../../../app/stores/root.store';
import ReactDOM from 'react-dom';
import { InfrastructureType } from '../../../../app/models/jobProperties.model';
import { EDROutlinedInput } from "../../../../app/common/EDROutlinedInput";
import { EDRAutocomplete } from "../../../../app/common/EDRAutocomplete";
import { EDRTextField } from "../../../../app/common/EDRTextField";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { INFRA_NAME } from '../../../../app/models/BaseSettings.enum';
import HelpIcon from '@material-ui/icons/Help';

const AzureAdvancedComponent : React.FC = () => {
    
    let targetlabelRef;
    const useStyles = makeStyles({
        helpIcon:
        {
          color: '#1976d2',
          fontSize: "large"
        },
        formControl: {
            width: '100%',
            '& label': {
                background: "white",
                width: "auto",
                padding:"0 4px 0 4px",
                fontSize: '17px',
              }
        },
        MainHeading:
        {
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.19',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#1f396d',
            marginLeft:'10px'
        },
        SecondHeading:{
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1
        },
        divider: {
                backgroundColor: '#eaeef4',
                width: '190%',
                margin: '0',
                marginTop: '10px',
                marginLeft: '10px',
        },
        button: {
            borderRadius: 15,
            color: 'white',
            backgroundColor:'#2892d7',
            width: '23%',
            height: '32px',
            marginTop: '24px',
            marginLeft: '485px'
        }
        });    

    const classes = useStyles();
    const [t] = useTranslation("createJobBase");
    const rootStore = useContext(RootStoreContext); 
    
   

    const validation = yup.object({
       
      })
      
      const form = useFormik({
        initialValues: {
            infraSourceUsername:'',
            infraSourcePassword:'',
            infraSourcePort: '',
            infraTargetPort: '',
        },
        validationSchema:
        validation,
        onSubmit: () => { },
      })

    useEffect(() => {
        /*const initialValues=
        {
            infraSourceUsername:,
            infraSourcePassword:rootStore.createJobStore.baseSettings.sourceInfrastructure.password,
            infraSourcePort:rootStore.createJobStore.baseSettings.sourceInfrastructure.port== 0 ? setInitialPort(): rootStore.createJobStore.baseSettings.sourceInfrastructure.port,
            infraTargetPort:rootStore.createJobStore.baseSettings.targetInfarstructure.port== 0 ?setInitialPort(): rootStore.createJobStore.baseSettings.targetInfarstructure.port,
        };
        form.setValues(initialValues);)*/
      }, []); 

    return(
        <Grid container direction="column" style={{paddingRight: 16}}>
            
            <Typography variant='h6' className={classes.MainHeading}>{t('infrastrucutreSettings')}</Typography>
            
            <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '30px',marginLeft:'10px' }}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('azureSettings')}</Typography> 
                    <Tooltip title={t("azureSettingsInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider}/>
                </div>
            </Grid>
             {/*loadSourceSettings()*/}
        </Grid>
    );
};


export default observer(AzureAdvancedComponent);