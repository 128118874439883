import {Grid, Card,makeStyles} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import EmptyVlanComponent from './EmptyVlan.component';
import LiveDRVlanComponent from './LiveDRVlan.component';
import TestDRVlanComponent from './TestDRVlan.component';
import ControllerSettingsComponent from './ControllerSettings.component';
import CloneSettingsComponent from './CloneSettings.component';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from '../../../../app/layout/PrivateRoute';
import { RootStoreContext } from '../../../../app/stores/root.store';
import EDRServerSettingsComponent from './EDRServerSettings.component';
import NetworkControlSettingsComponent from './NetworkControlSettings.component';
import AdvancedVlanComponent from './AdvancedVlan.component';


const MultiVlanComponent: React.FC = () => {
    const {displayStore} = useContext(RootStoreContext); 
    const useStyles = makeStyles({
        card: {
            padding: 16,
            width: '100%',
            height: '99.8%',
            overflowY: "scroll"
        }
        });
        
    const classes = useStyles();
     
    return(
        <Card elevation={1} classes={{root: clsx([classes.card,"hide-scrollbar"])}}>
         {displayStore.screenSettings.displayNetwork== 'EmptyVlan' ? <EmptyVlanComponent /> : null }
         {displayStore.screenSettings.displayNetwork== 'NetworkControlSettings' ? <NetworkControlSettingsComponent /> : null }
         {displayStore.screenSettings.displayNetwork== 'AdvancedVlan' ? <AdvancedVlanComponent /> : null }
         {displayStore.screenSettings.displayNetwork== 'LiveDRVlan' ? <LiveDRVlanComponent /> : null }
         {displayStore.screenSettings.displayNetwork== 'TestDRVlan' ? <TestDRVlanComponent /> : null }
         {displayStore.screenSettings.displayNetwork== 'ControllerSettings' ? <ControllerSettingsComponent /> : null }
         {displayStore.screenSettings.displayNetwork== 'CloneSettings' ? <CloneSettingsComponent /> : null }
         {displayStore.screenSettings.displayNetwork== 'EDRServerSettings' ? <EDRServerSettingsComponent /> : null }
        </Card>    
    );
};

export default observer(MultiVlanComponent);