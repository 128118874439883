import * as yup from 'yup';

export const BackupSettingSchema = yup.object({
  backupinterval: yup.number()
  .max(240, "maxBackupInterval")
  .required("requiredBackupInterval")
  .min(1, "minBackupInterval"),
  backupcount: yup.number()
  .max(30, "maxBackupCount")
  .required("requiredBackupCount")
  .min(1, "minBackupCount"),
  backuppath: yup.string()
  .nullable()
  .required("requiredBackupPath")
  .min(4, "minBackupPath")
  .test('backuppath',"backupPathIsInvalid",
            function(value){
                let folderRegex= /^[a-zA-Z]:\\(?:[^\\\/:*?"<>|\r\n]+\\)*[^\\\/:*?"<>|\r\n]*$/;
                let isValidFolder = folderRegex.test(value);
                if(isValidFolder) 
                      return true;
                else
                    return false;
            }),
  })