import { DeviceTest, PrePostScriptType } from "../../models/createjob/createJob.model";
import { JobDeviceResult } from "../../models/jobDevice/jobDeviceResult.model";
import { JobDeviceResultResponse } from "../../models/jobDevice/jobDeviceResult.reponse.model";
import { TestType, TestStatus } from "../../models/jobTest.model";
import { decompressImage } from "./util";

const ADVANCED_TEST = [
    TestType.power, TestType.service, TestType.database, TestType.web, TestType.network, TestType.custom, TestType.advanceSvc,TestType.testAdvNet
]
const ALLOWED_TEST = [
    TestType.power, TestType.service, TestType.database, TestType.web, TestType.network, TestType.custom, TestType.thirdDeviceNetwork
]

/**
 * @description Adapter to convert perform conversion between the datamodels
 */
export class JobDeviceResultAdapter {
    static getObject(jobDeviceResult: JobDeviceResultResponse, deviceTests: Array<DeviceTest>,deviceUserName:string,testGroups?:Array<any>): JobDeviceResult {
        const powerTest =  jobDeviceResult.jobTestResults.find(t => t.testType === TestType.power);
        let hasAdvancedTest = jobDeviceResult.jobTestResults.filter(t => t.testStatus !== TestStatus.NA && ADVANCED_TEST.indexOf(t.testType) >= 0).length > 0;
        let webTest = deviceTests != null ? deviceTests.find(t => t.testType === TestType.web) : null;
        let serviceTest = deviceTests != null ? deviceTests.find(t => t.testType === TestType.service) : null;
        let customTest = deviceTests != null ? deviceTests.find(t => t.testType === TestType.custom) : null;
        let databaseTest = deviceTests != null ? deviceTests.find(t => t.testType === TestType.database) : null;
        let thirdNetworkTest = deviceTests != null ? deviceTests.find(t => t.testType === TestType.thirdDeviceNetwork) : null;
        let preScriptTest = deviceTests != null ? deviceTests.find(t => t.testType === TestType.PreScriptRes) : null;
        let authenticationTest = deviceTests != null ? deviceTests.find(t => t.testType === TestType.authentication) : null;
        return {
            id: jobDeviceResult.id,
            name: jobDeviceResult.machineName,
            smallGif: decompressImage(jobDeviceResult.smallGif),
            largeGif: decompressImage(jobDeviceResult.largeGif),
            status: jobDeviceResult.status,
            type: jobDeviceResult.type,
            testGroup: jobDeviceResult.testGroup,
            testGroupName: testGroups && jobDeviceResult.testGroup ? testGroups?.find(item=>item.groupOrder == jobDeviceResult.testGroup.toString())?.groupName || jobDeviceResult.testGroup : jobDeviceResult.testGroup,
            hostServer: jobDeviceResult.hostServer,
            hostServerType: jobDeviceResult.hostServerType,
            targetIp: '',
            sourceIP: '',
            sourceSubnet: '',
            targetSubnet: '',
            targetMachineName: jobDeviceResult.targetServer,
            testCollection: jobDeviceResult.jobTestResults.filter(t => ALLOWED_TEST.indexOf(t.testType)>=0).filter(t => t.testStatus !== TestStatus.NA && t.testType !== TestType.web),
            autoFix: jobDeviceResult.autoTroubleshooting === "Yes",
            autoFixResult: jobDeviceResult.autoTroubleshootingResult,
            tests: {
                power: jobDeviceResult.jobTestResults.find(t=>t.testType === TestType.power),
                web: jobDeviceResult.jobTestResults.find(t=> t.testType === TestType.web && t.testStatus !== TestStatus.NA),
                webInfo: webTest != null ? webTest.webPortalToTest + (webTest.webPortalResult != "" ? '\n\n Expected Result: ' + webTest.webPortalResult:''): '',
                service:jobDeviceResult.jobTestResults.find(t=> t.testType === TestType.service),
                serviceInfo: serviceTest != null ? serviceTest.serviceToTest : '',
                network: jobDeviceResult.jobTestResults.find(t=> t.testType === TestType.network),
                networkInfo: 'Ping To: ' + jobDeviceResult.machineName,
                custom:jobDeviceResult.jobTestResults.find(t=> t.testType === TestType.custom),
                customInfo: customTest != null ? customTest.customCommandToTest + (customTest.customCommandExpectedResult != "" ? '\n\n Expected Result: ' + customTest.customCommandExpectedResult:''): '',
                database:jobDeviceResult.jobTestResults.find(t=> t.testType === TestType.database),
                databaseInfo: databaseTest != null ? databaseTest.databaseNameToTest + ' Query: ' + databaseTest.databaseQueryToTest : '',
                thirdDeviceNetwork: jobDeviceResult.jobTestResults.find(t => t.testType === TestType.thirdDeviceNetwork),
                thirdDeviceNetworkInfo: thirdNetworkTest != null ? 'Ping To: ' + thirdNetworkTest.thirdDeviceNetworkToTest : '',
                PreScriptRes:jobDeviceResult.jobTestResults.find(t=> t.testType === TestType.PreScriptRes),
                PreScriptResInfo: jobDeviceResult.scriptName != null && jobDeviceResult.scriptName != '' ? jobDeviceResult.scriptName : ''
            },
            authenticationTest: authenticationTest,
            authenticationInfo :deviceUserName !=null ? deviceUserName :jobDeviceResult.deviceUserName,
            hasAdvancedTest: hasAdvancedTest,
            isLive: jobDeviceResult.jobTestResults.filter(t => ALLOWED_TEST.indexOf(t.testType)>=0).filter(t => t.testStatus === TestStatus.passed).length > 0,
            webPortalScreenShot : decompressImage(jobDeviceResult.webPortalScreenShot),
            devicePlatform: jobDeviceResult.devicePlatform
        }
    }
}