import React, { useContext, useState, CSSProperties, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, makeStyles, Typography, Tooltip, TableCellProps } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle';
import CrossIcon from '@material-ui/icons/CancelRounded';
import ArrowUp from '@material-ui/icons/ArrowDropUp';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import MoreVert from '@material-ui/icons/MoreVert';
import clsx from 'clsx';
import { RootStoreContext } from '../../app/stores/root.store';
import JobActionMenu from '../../app/common/jobActionMenu.component';
import JobCategoryIcon from '../../app/common/jobCategoryIcon';
import { convertSecondsToTime } from '../../app/common/util/util';
import { LastJobRun } from '../../app/models/lastjobRun.model';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import moment from 'moment';
import { TrendIndicator } from '../../app/common/trendIndicator.component';
import { useTranslation } from 'react-i18next';
import { WorkFlowStatus } from '../../app/models/workflowStep.model';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import {useTable, Column, useBlockLayout, useResizeColumns, useAbsoluteLayout, useFlexLayout} from 'react-table';
import { useStatusColor } from '../../app/common/util/useStatusColor.hook';
import useClickPreventionOnDoubleClick from '../createJob/deviceSection/useClickPreventionOnDoubleClick.hook';
import { ScheduleStaus } from '../../app/models/jobProperties.model';
import ScheduleIcon from '@material-ui/icons/Schedule';
const dateFormat = "DD.MM.YY";
const detailedDateFormat = "DD.MM.YY HH:MM:SS";

const useStyles = makeStyles(theme => ({
    iconCell: {
        display: "flex",
    },

    deviceCellIcon: {
        marginRight: "5px",
        fontSize: "20px",
    },

    rowSelected: {
        background: "#bfdff3"
    },
    selectable: {
        cursor: "pointer"
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    tableCell: {
        display: "flex",
        cursor: "pointer"
    },
    tableRoot: {
        overflowX: "hidden",
        padding: '0 10px 0 10px',
        overflowY: 'scroll',
        height: '100%',
        position: 'relative',
        '& ::-webkit-scrollbar-thumb': {
            width: '0px !important',
            background: 'transparent !important'
        }
    },
    jobName: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '100%'
         },
         "&:hover": {
            color: '#2892d7'
        }
    },
    div:{
        overflow:'hidden',
        "&:hover": {
            backgroundColor: '#bfdff3'
        }
    },
    jobNameNotHover: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '100%'
         }
    },
    workflowSpan: {
        display: 'flex',
        alignItems: 'center'
    },
    overflowEllipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    width100: {
        maxWidth: '100%',
        display: 'block'
    },
    stickyHeader: {
        background: 'white',
        position: "sticky",
        top: 0,
        zIndex: 1100,
        borderBottom: '1px solid gray',
        '& th': {
            background: 'white'
        }
    },
    resizer: {
        display: "inline-block",
        background: "transparent",
        width: "20px",
        height: "60%",
        position: "absolute",
        right: 0,
        top: "15%",
        transform: "translateX(50%)",
        zIndex: 1,
        touchAction:"none",
        "&.isResizing" : {
          borderLeft: "5px solid gray",

        },
        "&:hover": {
            borderLeft: '5px solid gray'
        }
      }
}));

const SortIconStyles: { [key: string]: CSSProperties } = {
    arrowAup: {
        fontSize: "1.2em",
        position: "relative",
        top: "2px"
    },
    arrowDown: {
        fontSize: "1.2em",
        position: "relative",
        bottom: "5px"
    }
}


const SortIcon: React.FC<{ sortActive: boolean, sortDesc: boolean }> = ({ sortActive, sortDesc, children }) => {
    return <span style={{ display: "flex" }}>
        {children}
        <span style={{
            display: "flex",
            flex: "column",
            color: "lightgray",
            paddingLeft: "2px",
            flexDirection: "column"
        }}>
            <ArrowUp style={{ ...(sortActive && !sortDesc ? { color: "gray" } : {}), ...SortIconStyles.arrowAup }} />
            <ArrowDown style={{ ...(sortActive && sortDesc ? { color: "gray" } : {}), ...SortIconStyles.arrowDown }} />
        </span>
    </span>
}


type JobTableProps = {
    jobResults: Array<LastJobRun>,
    expanded: boolean,
    onTableRowSelected?: (selection: LastJobRun) => void
}
const JobTable: React.FC<JobTableProps> = (props) => {
    const { jobResults, expanded } = props;
    const { jobResultStore,createJobStore,dashboardStore,displayStore,userStore } = useContext(RootStoreContext);
    const [menuSelection, setMenuSelection] = useState<{ anchorEl: any, job: LastJobRun }>({ anchorEl: null, job: null });
    const classes = useStyles();
    const { sortInfo } = jobResultStore;
    const { slectedJobId } = jobResultStore;
    const [expandTable, setexpandTable] = useState(expanded);
    const { trackEvent } = useMatomo();

    useEffect(() => {
      
       
            setexpandTable(expanded);
      
     
    }, [expanded])

    const [t] = useTranslation("jobResult");

    const sortByColumn = (column: string) => {
        if (sortInfo.sortBy !== column) {
            jobResultStore.sortBy(column, false);
        } else {
            jobResultStore.sortBy(column, !sortInfo.sortDesc);
        }
    }

    const selectJobSummary = (lastJobRun: LastJobRun) => {
        props.onTableRowSelected && props.onTableRowSelected(lastJobRun);
        //jobResultStore.selectJobSummaryResult(lastJobRun.jobId);

    }

    const handleMenuAction = (target: any, job: LastJobRun) => {
        setMenuSelection({ anchorEl: target, job });
    }

    const handleMenuClose = () => {
        setMenuSelection({ anchorEl: null, job: menuSelection.job });
    }

    const handleEditJob =(jobId:number) =>{
        try
        {
            displayStore.resetDrawers();
            createJobStore.getJob(jobId, false);
        }
        catch(error){};
    }
const handleContextMenu = (event, job:LastJobRun) => {
    trackEvent({
        category: 'jobs',
        action: 'open',
        name: 'ContextMenu',
        value: 1
    })
    event.preventDefault();
    userStore.user.username!="readonly"?  setMenuSelection({ anchorEl: event.target, job }) : setMenuSelection({ anchorEl: null, job: menuSelection.job });
}
    const createDateNotExist= (job:any)=> {
        return job.createDate && job.createDate =="0001-01-01T00:00:00" ? true:false;
    }

    const ClickableTypography = ({  job,index}) => {
      //  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(onClick, onDoubleClick);
        
        if(dashboardStore.createEnabled && createDateNotExist(job))
        return(
            <Typography key={index} variant="body1"  className={dashboardStore.createEnabled && createDateNotExist(job)?classes.jobNameNotHover:classes.jobName} >{job.jobName}</Typography>);
       else return(
        /*<Tooltip title={"Select Edit in the Actions Menu (By right clicking your mouse, or clicking the 3 dots icon) to Edit "+job.jobName}>*/
            <div className={classes.div}>
                <Typography key={index} variant="body1"  className={dashboardStore.createEnabled && createDateNotExist(job)?classes.jobNameNotHover:classes.jobName} >{job.jobName}</Typography>
            </div>
        /*</Tooltip>*/);
       };

    const columns: Array<Column> = React.useMemo(() => {
       return [
            {
                Header: <span onClick={() => sortByColumn("jobName")}><SortIcon sortActive={sortInfo.sortBy === "jobName"} sortDesc={sortInfo.sortDesc}>{t("jobTable.jobName")} </SortIcon></span>,
                accessor: "jobName",
                Cell:  (props) => {
                       const job = props.data[props.row.index];
                    return <div style={{ display: "flex", alignItems: "center" }}>
                    {job.isRunning && <CircularProgress id="job_running" size={12} style={{color: '#2892d7', marginRight: 2}} />}
                    {!job.isRunning && <JobCategoryIcon status={job.status} noruns={job.id == 0 || job.id == null ? true:false} variant="small" full={true} style={{ height: "12px", marginRight: "5px" }} />}
                    <ClickableTypography 
                    index={props.row.index}
                    job={job}
                    />
                </div>
                },
                minWidth: 250
            },
            
            {
                Header: <span className={clsx([classes.noWrap])} onClick={() => sortByColumn("lastRun")}><SortIcon sortActive={sortInfo.sortBy === "lastRun"} sortDesc={sortInfo.sortDesc}>{t("jobTable.lastRun")} </SortIcon></span>,
                accessor: "runDate",
                width: 100,
                Cell: (props) => {
                    const job = props.data[props.row.index];
                    return <span className={classes.noWrap}>{job.runDate && moment(job.runDate).format(dateFormat)}</span>;
                }
            },
            { 
                Header: <span onClick={() => sortByColumn("rrs")}><SortIcon sortActive={sortInfo.sortBy === "rrs"} sortDesc={sortInfo.sortDesc}>{t("jobTable.RRS")} </SortIcon></span>,
                accessor: "score",
                width: 80,
                Cell: (props) => {
                    const job = props.data[props.row.index];
                    return <div className={classes.iconCell}>
                    <TrendIndicator value={job.scoreChange} />
                    <span id="job_score" className={classes.noWrap}>{job.score} %</span>
                    </div>
                }
            },
            {
                Header: <span onClick={() => sortByColumn("rta")}><SortIcon sortActive={sortInfo.sortBy === "rta"} sortDesc={sortInfo.sortDesc}>{t("jobTable.RTA")} </SortIcon></span>,
                accessor: 'rta',
                width: 90,
                Cell: (props) => {
                    const job = props.data[props.row.index];
                    return <div className={classes.iconCell}>
                    <TrendIndicator value={job.rtaChange} />
                    <span className={classes.noWrap}>{convertSecondsToTime(job.rta)}
                    </span>
                </div>
                }
                
            },
            {
                Header:<span onClick={() => sortByColumn("devices")}><SortIcon sortActive={sortInfo.sortBy === "devices"} sortDesc={sortInfo.sortDesc}>{t("jobTable.devices")} </SortIcon></span>,
                accessor: function(row : any, index) {
                    return row.serversInStatusOK + row.serversInStatusBAD
                },
                id: 'devices',
                width: 110,
                Cell: (props) => {
                    const job = props.data[props.row.index];
                    const okIconColor = useStatusColor("passed", job.serversInStatusOK);
                    const failedIconColor = useStatusColor("failed", job.serversInStatusBAD);
                    const norunsState = job.serversInStatusOK +job.serversInStatusBAD;
                    return <span className={classes.iconCell}>
                    <CheckIcon className={classes.deviceCellIcon} style={{ color: okIconColor }} /> {job.serversInStatusOK}
                    <CrossIcon className={classes.deviceCellIcon} style={{ color: failedIconColor, marginLeft: "8px" }} /> {norunsState ==0 ? job.totalServers:job.serversInStatusBAD}
                </span>
                }
            },
            ...(expandTable? 
                [{
                    Header: <span  className={classes.noWrap} onClick={() => sortByColumn("scheduleStaus")}><SortIcon sortActive={sortInfo.sortBy === "scheduleStaus"} sortDesc={sortInfo.sortDesc}>{t("jobTable.scheduleStaus")} </SortIcon></span>,
                    accessor: 'scheduleStaus',
                    Cell: (props) => {
                        const job = props.data[props.row.index];
                        switch(job.scheduleStaus)
                        {
                            case ScheduleStaus.Suceess:
                                return <React.Fragment><Tooltip title={t('jobTable.schedInfoSuccess') + ' ' + job.scheduleInfo}><ScheduleIcon style={{ color: '#8bc53f' }} /></Tooltip></React.Fragment>
                            case ScheduleStaus.Failed:
                                return <React.Fragment><Tooltip title={t('jobTable.schedInfoFailed')}><ScheduleIcon style={{ color: '#077187' }} /></Tooltip></React.Fragment>
                            case ScheduleStaus.NA:
                                return <React.Fragment><Tooltip title={t('jobTable.schedInfoNA')}><ScheduleIcon style={{ color: '#C0C0C0' }} /></Tooltip></React.Fragment>
                        }
                    },
                    maxWidth: 80,
                },
                {
                    Header: <span  className={classes.noWrap} onClick={() => sortByColumn("edrServer")}><SortIcon sortActive={sortInfo.sortBy === "edrServer"} sortDesc={sortInfo.sortDesc}>{t("jobTable.EDRServer")} </SortIcon></span>,
                    accessor: 'edrServer',
                    Cell: (props) => {
                        const job = props.data[props.row.index];
                        return <Tooltip title={job.edrServer || ''}>
                        <span className={clsx([classes.width100, classes.overflowEllipsis, classes.noWrap])}>{job.edrServer}</span>
                        </Tooltip>;
                    },
                    minWidth: 120
                },
                {
                    Header: <span  className={classes.noWrap}  onClick={() => sortByColumn("dataMover")}><SortIcon sortActive={sortInfo.sortBy === "dataMover"} sortDesc={sortInfo.sortDesc}>{t("jobTable.dataMover")} </SortIcon></span>,
                    accessor: 'dataMover',
                    Cell: (props) => {
                        const job = props.data[props.row.index];
                        return <Tooltip title={job.dataMover}>
                        <span className={clsx([classes.width100, classes.overflowEllipsis, classes.noWrap])}>{job.dataMover}</span>
                        </Tooltip>;
                    },
                    minWIdth: 120
                },
                {
                    Header: <span onClick={() => sortByColumn("rto")}><SortIcon sortActive={sortInfo.sortBy === "rto"} sortDesc={sortInfo.sortDesc}>{t("jobTable.RTO")} </SortIcon></span>,
                    accessor: 'rto',
                    Cell: (props) => {
                        const job = props.data[props.row.index];
                        return <div className={classes.iconCell}>
                        <TrendIndicator value={job.rtoChange} />
                        <span className={classes.noWrap}>{convertSecondsToTime(job.rto)}
                        </span>
                    </div>
                    },
                    maxWidth: 90
                },
                /*{
                    Header: <span onClick={() => sortByColumn("rpa")}><SortIcon sortActive={sortInfo.sortBy === "rpa"} sortDesc={sortInfo.sortDesc}>{t("jobTable.RPA")} </SortIcon></span>,
                    accessor: 'rpa',
                    Cell: (props) => {
                        const job = props.data[props.row.index];
                        return <span >{convertSecondsToTime(job.rpa)}
                        </span>
            
                    },
                    maxWidth: 90
                },
                {
                    Header: <span onClick={() => sortByColumn("rpo")}><SortIcon sortActive={sortInfo.sortBy === "rpo"} sortDesc={sortInfo.sortDesc}>{t("jobTable.RPO")} </SortIcon></span>,
                    accessor: 'rpo',
                    Cell: (props) => {
                        const job = props.data[props.row.index];
                        return <span >{convertSecondsToTime(job.rpo)}
                        </span>
            
                    },
                    maxWidth: 80
                },*/
                {
                    Header: <span onClick={() => sortByColumn("createDate")}><SortIcon sortActive={sortInfo.sortBy === "createDate"} sortDesc={sortInfo.sortDesc}>{t("jobTable.createDate")} </SortIcon></span>,
                    accessor: 'createDate',
                    Cell: (props) => {
                        const job = props.data[props.row.index];
                        return <React.Fragment>
                            <Tooltip title={createDateNotExist(job) ? "Not Avilable":moment(job.createDate).format(detailedDateFormat)}>
                            <span>
                                {createDateNotExist(job) ? "Not Avilable":moment(job.createDate).format(dateFormat)}
                            </span>
                            </Tooltip>
                        </React.Fragment>
                    },
                    maxWidth: 130
                },
                {
                    Header:<span  className={classes.noWrap}  onClick={() => sortByColumn("workflowState")}><SortIcon sortActive={sortInfo.sortBy === "workflowSTate"} sortDesc={sortInfo.sortDesc}>{t("jobTable.workflowState")} </SortIcon></span>,
                    accessor: 'workflowStatus',
                    minWidth: 130,
                    Cell: (props) => {
                        const job = props.data[props.row.index];
                        return job.workflowStatus === WorkFlowStatus.success ? 
                        <span className={classes.workflowSpan} ><CheckCircleIcon style={{color: '#8bc53f', fontSize: 12, marginRight: 5}} /> {t("ok")} </span> : 
                        job.id? <span  className={classes.workflowSpan} ><ErrorIcon color="primary" style={{ fontSize: 12, marginRight: 5}} /> {t("gotIssues")}</span>: ''
            
                    }
                }] : []),
            {
                Header: '',
                accessor: (row, index) => '',
                id: 'jobActions',
                Cell: (props) => {
                    const job = props.data[props.row.index];
                    if (userStore.user.username!="readonly"){
                       return <Button id="job_menu_button" style={{marginTop: 10}} variant="text" onClick={event => { handleMenuAction(event.target, job); event.stopPropagation() }}><MoreVert /></Button>
                    }
                    else
                        return null
                }
            }
        ];
    }, [sortInfo, expandTable])
    const defaultColumn = React.useMemo(
        () => ({
          minWidth: 30,
          maxWidth: 400,
        }),
        []
      )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
      } = useTable(
        { data: jobResults,
            columns,
            defaultColumn
        },
        useBlockLayout,
        useResizeColumns
      )
    return <TableContainer component={Paper} classes={{ root: classes.tableRoot }} className="hide-scrollbar" data-test="component-jobTable">
        <Table aria-label="Job Summary table" style={{tableLayout: 'fixed'}} {...getTableProps()} >
            <TableHead className={classes.stickyHeader}>
            {headerGroups.map(headerGroup => (
                    <TableRow className={clsx([classes.stickyHeader, classes.selectable]) } {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <TableCell {...column.getHeaderProps()}>{column.render("Header")}
              <div {...column.getResizerProps()}
              className={clsx([classes.resizer, { ['isResizing']: column.isResizing}])}>

              </div>
              </TableCell>
            ))}
            </TableRow>
            ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
                prepareRow(row)
                return (
                    <TableRow id={`jobs_table_${row.cells[0].value}`} onContextMenu={event => handleContextMenu(event, row.original as LastJobRun)} onClick={event => selectJobSummary(row.original as LastJobRun)} className={clsx([classes.selectable],{[classes.rowSelected]: jobResultStore.slectedJobId === jobResults[row.index].jobId})} key={row.index} {...row.getRowProps()}>
                        {row.cells.map((cell, cellIndex) => {
                            const cellProps: TableCellProps = cellIndex ==row.cells.length-1? {padding: 'none', size:'small'}: {};
                        return <TableCell {...cellProps}  key={cellIndex} {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>
                    })}
                    </TableRow>
                    )
                })}
            </TableBody>
        </Table>
        <JobActionMenu
            firstJobEver={menuSelection.job && menuSelection.job.jobId == 1}
            jobId={menuSelection.job && menuSelection.job.jobId}
            jobResultId={menuSelection.job && menuSelection.job.id}
            jobName={menuSelection.job && menuSelection.job.jobName}
            createDate={menuSelection.job && menuSelection.job.createDate}
            jobNameFromJobs={menuSelection.job && menuSelection.job.jobNameFromJobs}
            isDeleted={menuSelection.job && menuSelection.job.isDeleted}
            isDeletedPermanently={menuSelection.job && menuSelection.job.isDeletedPermanently}
            attachmentSize={menuSelection.job && menuSelection.job.attachmentSize}
            anchorEl={menuSelection.anchorEl} onClose={handleMenuClose}
            edrServer={menuSelection.job && menuSelection.job.edrServer} />
    </TableContainer>

}


export default observer(JobTable);
