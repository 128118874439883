
import { Button, Dialog, DialogContent, Grid, InputLabel, makeStyles, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EDRDialogTitle } from "../../../app/common/EDRDialogTitle";


const useStyles = makeStyles(theme => ({
    text: {
        fontFamily:'Roboto',
        fontWeight:500,
        fontSize:'13px',
        fontStretch:'normal',
        fontStyle: 'normal',
        lineHeight:'1.14',
        letterSpacing: '0.13px',
        textAlign:'left',
        color: ' #1f396d'
    }
}))
export interface props {
    open: boolean;
    onClose: () => void;
}

const ConfirmDialog: React.FC<props> = ({onClose, open}) => {
    
    const { t } = useTranslation('createJobBase');
    const classes = useStyles();
   
    const handleClose =()=>{
        onClose();
    }

    const handleSwitchAdapter=()=>{
        
    }

    return <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
    >
        <DialogContent style={{backgroundColor: '#f4f5f9'}}>
            <Grid container justify="center" direction="column" style={{ padding:'25px 25px', boxSizing: 'border-box' }}>
                <Typography className={classes.text}>{t('switchAdapter')}</Typography>
            </Grid>
            <Grid container justify="center" direction="row" style={{ padding:'25px 25px', boxSizing: 'border-box' }}>
                <Button size="small" color="primary" onClick={handleSwitchAdapter}>{t("ok")}</Button>
                <Button size="small" color="primary" onClick={handleClose}>{t("cancel")}</Button>
            </Grid>

        </DialogContent>
        
    </Dialog>
}

export default ConfirmDialog;