export interface IJobSync {
    id: number;
    runId:number;
}
export class JobSync implements IJobSync {
    id: number;
    runId:number;
    constructor(init?: IJobSync) {
       Object.assign(this, init); //this will initialize our object of class JobActions
                                 // with the above values
   }
}