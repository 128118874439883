import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext} from '../../app/stores/root.store';
import { TextField, makeStyles, withStyles, Typography, Paper, FormControl, Button, InputAdornment, Icon, Grid, styled } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { EDRTextField } from '../../app/common/EDRTextField';
import { BackupSettingSchema } from './validation';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ConfirmationDialog from '../../app/common/confirmationDialog.component';
import { BooleanSchema } from 'yup';
import { toast } from 'react-toastify';
import settingAgent from '../../app/api/settingAgent';


const EDRButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',});


const SettingsBackupComponent: React.FC<{onChange: (val) => void}> = (props) => {

    var endDate = new Date();
    endDate.setDate(endDate.getDate() - 30);

    const [openConfirmReduceDialog, setopenConfirmReduceDialog] = useState(false);
    const [DateState, setDateState] = useState(null);
    const [DeleteButtonDisabled, setDeleteButtonDisabled] = useState(true);
    const [openConfirmationDeleteDialog, setopenConfirmationDeleteDialog] = useState(false);
    const [DateObject, setDateObject] = useState({varOne : new Date()})
    const [LastSuccessfullBackup, setLastBackup] = useState(null);

    const rootStore = useContext(RootStoreContext); 
    const { updateBackupObj, backupSettings, settingsLoaded} = rootStore.settingStore;
    const [t] = useTranslation("settings");

    let initialValuesList = {backupinterval:0,backupcount:0,backuppath:"", deleteAnimationsDate:"",lastBackup:null};

    const populateFields = () => {
        if (backupSettings!=null){ 
            Object.keys(backupSettings).forEach(key => {
                switch(backupSettings[key].settingKey.toLowerCase()){
                    case "backupcount": 
                        initialValuesList.backupcount = isNaN(backupSettings[key].settingValue)? 1 :parseInt(backupSettings[key].settingValue);
                        break;
                    case "backupinterval":
                        initialValuesList.backupinterval = isNaN(backupSettings[key].settingValue)? 1 :parseInt(backupSettings[key].settingValue);
                        break;
                    case "backuppath":
                        initialValuesList.backuppath = backupSettings[key].settingValue;                    
                        break;
                    case "DeleteAnimationsDate":
                        initialValuesList.deleteAnimationsDate = backupSettings[key].settingValue;
                        break;
                    case "lastbackup":
                        setLastBackup(backupSettings[key].settingValue);
                    default:
                        break;
                }
            })    
         }
         form.setValues(initialValuesList, true);
     }


     const form = useFormik({
        initialValues: initialValuesList,
        validationSchema:BackupSettingSchema,
        validateOnMount: false,
        onSubmit: () => { },
      })

      useEffect(() => {
        if(form.dirty) {
            updateBackupObj(JSON.stringify(form.values.backupcount), "BackupCount", "Backup")
            updateBackupObj(JSON.stringify(form.values.backupinterval), "BackupInterval", "Backup")
            updateBackupObj(form.values.backuppath, "BackupPath", "Backup")
        }
        props.onChange && props.onChange(form.values);
    },[form.values])

    useEffect(() => {
        if (settingsLoaded) 
            populateFields()
    }, [settingsLoaded]); 

    const useStyles = makeStyles({
        dashboardDetails: {
            // display: "flex",
            // flexDirection: "column",
            color: "#4d5f75",
            // '& > div': {
            //   padding: 5,
            //   display: "flex",
            //   justifyContent: "space-between",
            //   marginRight: 20,
            //   marginLeft: 10
            // }
        },
        label: {
            textTransform: 'capitalize',
        },
        disabledButton: {
            width: "150px",
            height: "32px",
            backgroundColor: "#c7cfdb !important",
            color: "white !important",
            borderRadius: "28px !important",
        },
        formControl: {
            // width: '41%',
            '& label': {
                background: "white",
                width: "auto",
                // padding: "0 4px 0 4px",
                fontSize: '17px'
            }
        },
        reduceButton: {
            width: '150px',
            height: '32px',
            backgroundColor: '#1f396d !important',
            color: 'white !important',
            borderRadius: '28px !important'
        },
        reduceButtonDisabled: {
            width: '150px',
            height: '32px',
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            color: 'white !important',
            borderRadius: '28px !important'
        },
        settingsText: {
            fontFamily: 'Roboto !important',
            fontSize: '0.9rem !important',
            fontWeight: 'normal',
            fontStretch: 'normal !important',
            fontStyle: 'normal !important',
            letterSpacing: 'normal !important',
            textAlign: 'left',
            color: '#4d5f75 !important',
            marginTop: '0.3em !important',
            marginBottom: '0.3em !important',
            marginLeft: '1em !important'
        },
        row: {
            paddingRight: "2em "
        },
        bold: {
            fontWeight: 'bold',
            fontFamily: 'Roboto !important',
            fontSize: '0.9rem !important',
            fontStretch: 'normal !important',
            fontStyle: 'normal !important',
            letterSpacing: 'normal !important',
            textAlign: 'left',
            color: '#4d5f75 !important',
            marginTop: '0.3em !important',
            marginBottom: '0.3em !important',
            marginLeft: '1em !important'
        }
    })
     
    const classes = useStyles();

    const handleReduceClick = (event) => {
        setopenConfirmReduceDialog(!openConfirmReduceDialog);
    };

    const handleDeleteNow =(event) => {
        setopenConfirmationDeleteDialog(true)
    }

    function DateFormatter (date: Date, invalidLabel: string) {
        if(!date)
            return invalidLabel;
        return moment(date).format('MMM DD, YYYY')
    }

    const onDateChange = (date: Date) => {
        setDateObject({varOne : date});
        setDateState(date.toLocaleDateString());
        updateBackupObj(moment(date).format('MMM DD, YYYY'),"DeleteAnimationsDate","Backup")
        setDeleteButtonDisabled(false)
    };

    const DateTextField = withStyles({
        root: {
            '& .MuiInput-underline:hover': {
                borderWidth: "1"
            },
            '& .MuiInput-underline:hover:before': {
                borderWidth: "1"
            },
            '& .MuiInput-underline:after': {
                borderWidth: "1"
            },
            '& .MuiInput-underline:before': {
                borderWidth: "1"
            },
            background: "white",
            borderRadius: 28,
            overflow: "hidden"
        },
    })(TextField);

    const handleConfirmationPopup =(confirm:BooleanSchema,type:string)=>{
        if(confirm)
        {
                handleDelete(DateState)        
                setopenConfirmationDeleteDialog(false) 
        }
        else
        {
            setopenConfirmationDeleteDialog(false)
        }
     
    }

    function handleDelete(DateState: any) {
        if (DateObject==null) {
            handleDeleteAnimationResult(false, "Illegal date or no date selected");
            return;
        }
        settingAgent.Setting.deleteOldAnimations(DateObject.varOne.getDate(), DateObject.varOne.getMonth()+1, DateObject.varOne.getFullYear())
        .then(res=>handleDeleteAnimationResult(res, null))
        .catch(exception=>handleDeleteAnimationResult(false, null))
    }

    function handleDeleteAnimationResult(status: boolean, errorMessage: string){
        switch (status) {
            case true:
                toast.success(t('deleteOldAnimationsSuccess'));
                break;
            case false:
                toast.error(t('deleteOldAnimationsFail'));
                break;
            default:
                toast.error(t('deleteOldAnimationsFail'))
                break;
        }
    }

    var lastBackupText = LastSuccessfullBackup==null ? "Unknown" : LastSuccessfullBackup; 

    return (
        <div data-test='settings-dashboard-component'>
            <Paper>
                <Grid container direction='column'>
                    <Grid item container justifyContent="space-between" className={classes.row} style={{paddingBottom: "15px", paddingTop: "15px"}}>     
                        <div>
                            <span>
                                <Typography className={classes.bold}>{lastBackupText}</Typography>
                            </span>
                        </div>
                    </Grid>
                    <Grid item container justifyContent="space-between" className={classes.row}>                
                       <Grid item>
                       <Typography style={{paddingTop:"20px"}} classes={{ root: "settingsText"}}>{t("backupCount")}</Typography>
                       </Grid>
                       <Grid item>
                       <FormControl className={classes.formControl} variant='outlined'>
                        <EDRTextField
                            style={{width: "302px"}}
                            name="backupcount"
                            label={t("backupCountLabel")}
                            placeholder="Type Here"
                            type="number"
                            value={form.values.backupcount}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={Boolean( form.errors.backupcount)}
                            helperText={ t(form.errors.backupcount)}
                            margin="normal"
                            size= "small"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            fullWidth
                            variant="outlined">
                        </EDRTextField>
                        </FormControl>
                       </Grid>
                    </Grid>
                    <Grid  item container justifyContent="space-between" className={classes.row}>                
                        <Typography style={{paddingTop:"20px"}} classes={{ root: "settingsText"}}>{t("backupInterval")}</Typography>
                        <FormControl className={classes.formControl} variant='outlined'>
                        <EDRTextField
                            style={{width: "302px"}}
                            name="backupinterval"
                            label={t("backupIntervalLabel")}
                            placeholder="Type Here"
                            type="number"
                            value={form.values.backupinterval}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={Boolean( form.errors.backupinterval)}
                            helperText={ t(form.errors.backupinterval)}
                            margin="normal"
                            size= "small"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            fullWidth
                            variant="outlined">
                        </EDRTextField>
                        </FormControl>
                    </Grid>
                    <Grid  item container justifyContent="space-between"  className={classes.row}>                
                        <Typography style={{paddingTop:"20px"}} classes={{ root: "settingsText"}}>{t("backuppath")}</Typography>
                         <FormControl className={classes.formControl} variant='outlined'>
                        <EDRTextField
                            style={{width: "302px"}}
                            name="backuppath"
                            label={t("backupPathLabel")}
                            placeholder="Type Here"
                            type="string"
                            value={form.values.backuppath}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={Boolean( form.errors.backuppath)}
                            helperText={ t(form.errors.backuppath)}
                            margin="normal"
                            size= "small"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            fullWidth
                            variant="outlined">
                        </EDRTextField>
                        </FormControl> 
                    </Grid>
                    <Grid item container  className={classes.row} justifyContent="space-between" style={{paddingBottom: "10px",paddingTop:"10px"}}>
                        <Typography classes={{ root: "settingsText"}}>{t("reduceDbText")}</Typography>
                        <EDRButton
                            className={classes.reduceButton}
                            onClick={(event) => {handleReduceClick(event)}}
                        >{t("reduceDbButton")}
                        </EDRButton>
                    </Grid>
                    {
                        openConfirmReduceDialog ?
                        <React.Fragment >
                        <div>
                            <span>
                                <Typography className={classes.bold}>{t("deleteUptoDate")}</Typography>
                            </span>
                        </div>
                        <Grid container justifyContent='flex-end' className={classes.row}>
                           
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    disableToolbar
                                    autoOk
                                    variant="dialog"
                                    inputVariant="outlined"
                                    size="small"
                                    style={{borderRadius: "4px",backgroundColor: "#ffffff",paddingBottom: "1em", width: "302px"}}
                                    format="MMM DD, YYYY"
                                    placeholder="End date"
                                    maxDate={endDate}
                                    value={DateState}
                                    defaultValue={null}
                                    labelFunc={DateFormatter}
                                    onChange={onDateChange}
                                    TextFieldComponent={DateTextField}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment position={'end'}>
                                        <Icon>
                                            <DateRangeIcon />
                                        </Icon>
                                        </InputAdornment>)
                                    }}
                                />
                                </MuiPickersUtilsProvider>
                        
                        </Grid>
                        <Grid container justifyContent='flex-end' className={classes.row} style={{paddingBottom: "10px",paddingTop:"10px"}}>
                            <EDRButton
                                    disabled={DeleteButtonDisabled}
                                    className={DeleteButtonDisabled? classes.reduceButtonDisabled : classes.reduceButton}
                                    onClick={(event) => {handleDeleteNow(event)}}
                                >{t("reduceAction")}
                                </EDRButton>
                        </Grid>
                        </React.Fragment>
 
                        : "" 
                    }

                </Grid>
            </Paper>
            <ConfirmationDialog type="deleteBootAnimations" device={null} open={openConfirmationDeleteDialog} onClose={(confrim)=>handleConfirmationPopup(confrim,"deleteAnimations")} children={"Are you sure you want to delete now?"}></ConfirmationDialog>
        </div>
    );
}

export default (observer(SettingsBackupComponent));
