export enum DeviceType {
    VirtualMachine = 0,
    PhysicalServer = 1,
    NetworkDevice = 2,
    Firewall = 3,
    BranchOffice = 4,
    InternetConnection = 5,
    Other = 6,
    DCPhysical = 7,
    DCVirtual = 8,
    Database= 9,
    CloudService=10,
    CloudDatabase=11
}
export enum DeviceStatus {
    Initializing = 0,
    Ready = 1,
    NotReady = 2,
    FailingOver = 3,
    Deleting = 4,
    Unknown = 5
}

export enum DeviceState {
    PoweredOn = 0,
    PoweredOff = 1,
    Unknown = 2
}
export enum CustomDeviceStatus {
    Free = 0,
    Occupied = 1,
}


export const  DEVICE_TYPE_NAME = {
    [DeviceType.BranchOffice]: "Branch Office",
    [DeviceType.DCPhysical]: "DC",
    [DeviceType.DCVirtual]: "DC",
    [DeviceType.Firewall]: "Firewall",
    [DeviceType.InternetConnection]: "Internet Connection",
    [DeviceType.NetworkDevice]: "Network",
    [DeviceType.PhysicalServer]: "Physical",
    [DeviceType.VirtualMachine]: "Virtual Machine" ,
    [DeviceType.Other]: "Other",
    [DeviceType.Database]: "Database",
    [DeviceType.CloudService]: "Cloud Service",
    [DeviceType.CloudDatabase]: "Cloud Database"
}

export const  DEVICE_TYPE_NAME_SEPERATE_DC = {
    [DeviceType.BranchOffice]: "Branch Office",
    [DeviceType.DCPhysical]: "Physical DC",
    [DeviceType.DCVirtual]: "Virtual DC",
    [DeviceType.Firewall]: "Firewall",
    [DeviceType.InternetConnection]: "Internet Connection",
    [DeviceType.NetworkDevice]: "Network",
    [DeviceType.PhysicalServer]: "Physical",
    [DeviceType.VirtualMachine]: "Virtual Machine" ,
    [DeviceType.Other]: "Other",
    [DeviceType.Database]: "Database",
    [DeviceType.CloudService]: "Cloud Service",
    [DeviceType.CloudDatabase]: "Cloud Database"
}

export const  DEVICE_TYPE_NAME_SHORTCUT = {
    [DeviceType.BranchOffice]: "Branch Office",
    [DeviceType.DCPhysical]: "Physical DC",
    [DeviceType.DCVirtual]: "Virtual DC",
    [DeviceType.Firewall]: "Firewall",
    [DeviceType.InternetConnection]: "Internet Connection",
    [DeviceType.NetworkDevice]: "Network",
    [DeviceType.PhysicalServer]: "Physical",
    [DeviceType.VirtualMachine]: "VM" ,
    [DeviceType.Other]: "Other",
    [DeviceType.Database]: "Database",
    [DeviceType.CloudService]: "Cloud Service",
    [DeviceType.CloudDatabase]: "Cloud Database"
}

export const DEVICE_TYPE_SMALL_ICON = {
    [DeviceType.BranchOffice]: "/assets/images/device_type/branch_device.svg",
    [DeviceType.DCPhysical]: "/assets/images/device_type/dc_device.svg",
    [DeviceType.DCVirtual]: "/assets/images/device_type/dc_device.svg",
    [DeviceType.Firewall]: "/assets/images/device_type/firewall_device.svg",
    [DeviceType.InternetConnection]: "/assets/images/device_type/network_device.svg",
    [DeviceType.NetworkDevice]: "/assets/images/device_type/network_device.svg",
    [DeviceType.PhysicalServer]: "/assets/images/device_type/physical_device.svg",
    [DeviceType.VirtualMachine]: "/assets/images/device_type/vm_device.svg" ,
    [DeviceType.Other]: "/assets/images/device_type/other_device.svg",
    [DeviceType.Database]: "/assets/images/device_type/database_device.svg",
    [DeviceType.CloudService]: "/assets/images/device_type/cloud_service_device.svg",
    [DeviceType.CloudDatabase]: "/assets/images/device_type/cloud_database_device.svg"
}
