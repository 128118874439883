
import { Button, Dialog, DialogActions, DialogContent, Divider, Grid, InputLabel, List, ListItem,ListItemText, makeStyles, RootRef, Tooltip, Typography } from '@material-ui/core';
import React, { useContext, useState,Component, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SaveButton } from "../../../app/common/saveButton";
import { EDRDialogTitle } from "../../../app/common/EDRDialogTitle";
import { RootStoreContext } from '../../../app/stores/root.store';
import HelpIcon from '@material-ui/icons/Help';
import clsx from 'clsx';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
    helpIcon:
    {
      color: '#1976d2',
      fontSize: "large",
      marginLeft: '16px'
    },
    centerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between'
    },
    toggleItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiListItemText-root' : {
            marginLeft: 10
        },
        '& .MuiSvgIcon-root': {
            color: '#8092a9'
        }
    },
    MainHeading: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '100%',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.19',
        letterSpacing: 'normal',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        flexGrow: 1
      },
      divider: {
        backgroundColor: '#eaeef4',
        width: '33%',
        margin: '0',
        marginTop: '8px',
        marginLeft: '10px'
      },
      SecondHeading: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.19',
        letterSpacing: 'normal',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        flexGrow: 1,
        color: '#4d5f75',
        fontsize:'13px',
        marginTop:'16px'
      },
    root: {
        minWidth: '30vw',
        maxWidth:'500px'
    },
    card: {
        width: '568px',
      },
    formControl: {
        width: '100%',
        '& label': {
          background: "white",
          width: "auto",
          padding: "0 4px 0 4px",
          fontSize: '17px'
        }
      },
      Header:
      {
        fontWeight:500,
        color:'#4d5f75',
        marginTop:15
      }
}))
export interface props {
    open: boolean;
    planNames:string[];
    onClose: () => void;
}

const OrderPlansDialog: React.FC<props> = ({onClose, open,planNames}) => {
    
    const {createJobStore } = useContext(RootStoreContext);
    const classes = useStyles();
    const { t } = useTranslation('createJobDevices');
    const [cancel,setCancel]=useState(false);
    const [plans, setPlans] = React.useState<Array<{name: string, bootOrder: number}>>([]);
    const [startState, setstartState] = useState<string[]>([])

    useEffect(() => {
      if(open) {
        const plans:Array<{name: string, bootOrder: number}> = [];
        createJobStore.selectedDevices.forEach(d => {
          if(!plans.find(p => p.name === d.planName)) {
            if(!d.planName.includes("Custom Devices"))
            {
              plans.push({name: d.planName, bootOrder: d.planBootOrder||0});
            }
          }
        });
        plans.sort((p1,p2) => p1.bootOrder - p2.bootOrder);
        setPlans(plans);
        setstartState(plans.map(item=>item.name));
      }
      
     
    }, [open])

    const handleSaveChange =()=>{
       const selectedDevices =  createJobStore.selectedDevices.map(d => {
        d.planBootOrder = plans.findIndex(p => p.name === d.planName)+1;
        return d;
       });
       selectedDevices.sort((d1,d2) => d1.planBootOrder - d2.planBootOrder );
       try
       {
        createJobStore.updateSelectedDevices(selectedDevices);
        toast.success('Plans order saved successfully');
       }
       catch(error)
       {
        toast.error('Plans order not saved. error: '+error);
       }
        setCancel(false);
        onClose();
    }

    const handleClose =()=>{
        if(JSON.stringify(startState) !== JSON.stringify(plans.map(item=>item.name)))
        {
          setCancel(!cancel);
        }
        else
            onClose();
    }

    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel style={{marginLeft:'10px'}}>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const handleYes=()=>{
        
        setCancel(false);
        onClose();
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    const getListStyle = isDraggingOver => ({
        //background: isDraggingOver ? 'lightblue' : 'lightgrey',
      });

    const getItemStyle = (isDragging, draggableStyle) => ({
        // styles we need to apply on draggables
        ...draggableStyle,
        ...(isDragging && {
          background: "rgb(235,235,235)"
        })
    });

    const reorder = (list:any [], startIndex:number, endIndex:number) => 
    {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result:any) =>
    {
        if (!result.destination){
            return;
        }
        setPlans(reorder(plans,result.source.index,result.destination.index));
    }

    return <Dialog disableBackdropClick
        open={open}
        classes={{ paper: classes.root }}
        onClose={handleClose}
        scroll="paper"
    >
        <EDRDialogTitle >{t('orderPlans')}</EDRDialogTitle>
        <DialogContent dividers={true}>
        <Grid container direction="column" classes={{ root: clsx([classes.card]) }}>
                
                <Grid container item direction="row">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography className={classes.MainHeading} style={{ color: '#1f396d' }}>{t('setOrderPlans')}</Typography>
                  <Tooltip title={t("orderPlansInfo")} arrow>
                    <HelpIcon className={classes.helpIcon} />
                  </Tooltip>
                  <Divider className={classes.divider}/>
                </div>
                </Grid>
        </Grid>
        <Grid item>

           <Grid item container style={{height:50}}>
            <Grid item xs={4} style={{display:'flex'}}>
              <Typography className={classes.Header}>{t("PlanOrder")}</Typography>
            </Grid>
            <Grid item xs={4} style={{display:'flex'}}>
              <Typography className={classes.Header}>{t("PlanName")}</Typography>
            </Grid> 
            </Grid>
          
        <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <RootRef rootRef={provided.innerRef}>
              <Grid container direction="column" style={getListStyle(snapshot.isDraggingOver)}>
                {plans.map((item, index) => (
                  <Draggable key={item.name} draggableId={item.name} index={index}>
                    {(provided, snapshot) => (
                      <Grid item container
                        ContainerComponent="li"
                        innerRef={provided.innerRef}
                        // ContainerProps={{ ref: provided.ref }}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        
                        style={{...getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        ), height: 50}}
                      >
                        <Grid item xs={4} style={{display:'flex'}}>
                          {index+1}
                        </Grid>       
                        <Grid item xs={4} style={{display:'flex'}}>
                          {item.name}
                        </Grid>             
                      </Grid>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Grid>
            </RootRef>
          )}
        </Droppable>
      </DragDropContext>
        </Grid>
        </DialogContent>
        <DialogActions>
        <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end'}}>
            <Button color="primary" onClick={handleClose} style={{textTransform:'none'}}>
                {t('cancel')}
            </Button>
            <SaveButton variant="contained" color="primary" onClick={handleSaveChange} style={{textTransform:'none'}}>
                {t('save')}
            </SaveButton>
            </Grid>
        </DialogActions>
    </Dialog>
}

export default OrderPlansDialog;

