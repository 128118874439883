

import React, { useState, useEffect } from 'react';
import ReportInfoTreeComponent from './reportInfoTree.component';
import { Typography, makeStyles } from '@material-ui/core';
import TimeLineIcon from '@material-ui/icons/Timeline';
import { TrendIndicator } from '../../app/common/trendIndicator.component';
import { JobTrend } from '../../app/models/jobTrend.model';
import JobResultsApi from '../../app/api/jobResult.api';
import { useTranslation } from 'react-i18next';

type JobTrendProps = {
    jobId: number,
    jobResultId: number,
    timelineRecords:number
}

const useStyles = makeStyles({
    treepanelIcon: {
        marginRight: 10,
        color: "#8092a9"
      },
      treeInfo: {
        display: "flex",
        flexDirection: "column",
        color: "#4d5f75",
        '& > div': {
          padding: 5,
          display: "flex",
          justifyContent: "space-between"
        }
      },
});
 const JobTrendComponent:React.FC<JobTrendProps> = (props) => {
     const {timelineRecords}=props;
     const classes = useStyles();
     const [trend, setTrend] = useState<JobTrend>(null);
     const [t] = useTranslation("report");

     useEffect(() => {
        let cancelRequest = false;
         JobResultsApi.getTrend(props.jobId, props.jobResultId).then(trend => {
            !cancelRequest && setTrend(trend)
         })
         return () => {
           cancelRequest = true;
         }
     }, [props.jobResultId, setTrend])

     const loadTrends =()=>{
       return(
        <ReportInfoTreeComponent expanded={true} data-test="component-jobTrend" title={
          <span style={{ display: "flex" }}>
            <TimeLineIcon className={classes.treepanelIcon} />
      <Typography variant="h6">{t("trends.title")}</Typography>
          </span>}>
          <div className={classes.treeInfo}>
            <div> {t("trends.recoveryReadinessScore")} <TrendIndicator value={trend && trend.score} showText={true} /></div>
            <div>{t("trends.recoveryTime")} <TrendIndicator value={trend && trend.rta} showText={true} /></div>
            <div>{t("trends.testDevices")} <TrendIndicator value={trend && trend.deviceTested} showText={true} /></div>
            <div>{t("trends.testOverview")} <TrendIndicator value={trend && trend.testOverview} showText={true} /></div>
            <div>{t("trends.drResources")} <TrendIndicator value={trend && trend.resource} showText={true} /></div>
          </div>
        </ReportInfoTreeComponent>
       )
     }
    
    return(<React.Fragment>
      {props.timelineRecords > 1 ? loadTrends() :(trend && trend.hasPrevious ?loadTrends():null)}
    </React.Fragment>)
    
}

export default JobTrendComponent;


