import { FormControl, Grid, InputAdornment, InputLabel, makeStyles, OutlinedInput, Typography,IconButton, DialogContent, Dialog, Divider, Select, MenuItem, Button, Tooltip, Switch,CircularProgress } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Autocomplete } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { EDRAutocomplete } from "../../../app/common/EDRAutocomplete";
import { EDRTextField } from "../../../app/common/EDRTextField";
import { SaveButton } from "../../../app/common/saveButton";
import { DATABASE_TYPE_NAME, OS_TYPE_NAME } from "../../../app/models/BaseSettings.enum";
import { DeviceTest } from "../../../app/models/createjob/createJob.model";
import { JobDevice } from "../../../app/models/jobDevice/jobDevice.model";
import { DatabaseType, DevicePlatform } from "../../../app/models/jobProperties.model";
import { TestType } from "../../../app/models/jobTest.model";
import { RootStoreContext } from "../../../app/stores/root.store";
import { Props } from "./NetworkTestDialog.component";
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import HelpIcon from '@material-ui/icons/Help';
import jobAgent from "../../../app/api/jobAgent";

const WebTestDialog: React.FC<Props> = (props) => {
    const useStyles = makeStyles({
        formControl: {
            width: '100%',
            '& label': {
                background: "white",
                width: "auto",
                padding:"0 4px 0 4px",
                fontSize: '17px',
              }
        },
        helpIcon:
        {
          color: '#1976d2',
          fontSize: "large"
        },
        divider: {
            backgroundColor: '#eaeef4',
            width: '290%',
            margin: '0',
            marginTop: '10px',
            marginLeft: '10px',
    },
        SecondHeading:{
            color:'#1f396d',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1
        },
        centerVertical: {
            display: 'flex',
            alignItems: 'center',
            justifyContent:'space-between'
        },
        toggleItem: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
    
            '& .MuiListItemText-root' : {
                marginLeft: 10
            },
            '& .MuiSvgIcon-root': {
                color: '#8092a9'
            }
        }
        });   

    const classes = useStyles();
    const rootStore = useContext(RootStoreContext); 
    const { onApply,discard,closeDrawer,reload,setParentValue } = props;
    const {createJobStore,jobSettingsStore} = useContext(RootStoreContext);
    const device= createJobStore.editedDevice;
    const WebTest = device.tests.find(test=> test.testType == TestType.web);
    const [cancel,setCancel]=useState(false);
    const PreviousWebPortal = [{url:'https://'+device.deviceName,result:''},{url:'http://'+device.deviceName,result:''}];
    const [processingTest,setProcessingTest] = useState(false);
    const [t] = useTranslation("createJobDevices");
    const [Info] = useTranslation('createJobBase');
    
const validationSchema = yup.object({
    url: yup.string().required('Service Name is  a required field'),
    result: yup.string().nullable()
})
const getUrl = (device:JobDevice,secure:boolean) => {
    if(device.planName.includes("Custom Devices") && device.sourceName != null && device.sourceName != undefined && device.sourceName != '')
        return secure ? 'https://'+device.sourceName : 'http://'+ device.sourceName
    else if(device.sourceName != null && device.sourceName != undefined && device.sourceName != '')
    {
        let name = device.sourceName;
        if(device.sourceName.includes('.'))
        {
            name = device.sourceName.split('.')[0]
        }
        return secure ? 'https://'+name : 'http://'+ name
    }
    else if(device.deviceName != null && device.deviceName != undefined && device.deviceName != '')
    {
        let name = device.deviceName;
        if(device.deviceName.includes('.'))
        {
            name = device.deviceName.split('.')[0]
        }
        return secure ? 'https://'+name : 'http://'+ name
    }
    return '';
}
    const form = useFormik({
        initialValues: {
            url: WebTest.webPortalToTest!="" ? WebTest.webPortalToTest : getUrl(device,false),
            result:WebTest.webPortalResult!="" ? WebTest.webPortalResult :''
        },
        onSubmit: () => { },
        validationSchema
    })

      useEffect(() => {
        form.setFieldValue("url",reload,true);
      }, [reload]); 

      const handleTest=() =>{        
        var details ={webURL:form.values.url,webResult:form.values.result,Hostname:device.sourceName};
        setProcessingTest(true);
        jobAgent.JobTestsActions.TestWeb(details).then(res => {
            if(res)
                toast.success("Web Test succeeded");
            else
                toast.error("Web Test failed");
        setProcessingTest(false);
        }).catch(err=>{
            if(err && err.data && err.data.errors)
                toast.error(err.data.errors);
            setProcessingTest(false);
        });        
    }

    const handleCancle =()=>{
        if(form.dirty && form.touched)
            setCancel(!cancel);
        else
            closeDrawer();
    }

    const handleSave =()=> {
        try{
            let DeviceTest = {
                testCategory:device.tests[0].testCategory, 
                testType:TestType.web, 
                selected: true,
                customCommandToTest: '',
                customCommandExpectedResult: '', 
                customCommandToTestIsExternal: false,
                databaseType:DatabaseType.SQL,
                databaseNameToTest: '',
                databaseQueryToTest: '',
                databaseServerNameToTest: '',
                databaseUsername:'',
                databasePassword:'',
                databasePort:'',
                dbWindowsAuth:false,
                serviceToTest:'',
                testFromEDRC:false,
                script: '',
                thirdDeviceNetworkToTest: '',
                authenticationUserToTest:'',
                authenticationPassToTest:'',
                webPortalToTest: form.values.url,
                webPortalResult:form.values.result
            };
            createJobStore.updateTestDevice(TestType.web,DeviceTest,device.planName.includes("Custom Devices") ? device.sourceName : device.deviceName);
            onApply();
            discard();
            setParentValue(form.values.url);
            toast.success(t('saveSuccessful'));
        }
        catch(error)
        {
            toast.success(t('savefailed')+error);
        }
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    const handleYes=()=>{
        form.setFieldValue("url",WebTest.webPortalToTest!="" ? WebTest.webPortalToTest :getUrl(device,false),true);
        form.setFieldValue("result",WebTest.webPortalResult!="" ? WebTest.webPortalResult :'',true);
        discard();
        setCancel(false);
    }

    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const handleAutoCompleteChange = (event: React.ChangeEvent,value:any) => {
        if(event!=null)
        {
          let fieldName= event.target['id'].includes('-') ? event.target['id'].split('-')[0]: event.target['id'];
          form.setFieldValue(fieldName,value);
          switch(fieldName) {
            case 'url': handleUrlChange(event,value); break;
            case 'result': handleResultChange(event,value); break;
          }
        }
      }
    
      const handleUrlChange =(event:React.ChangeEvent,value:any)=>{
        if (value!=null && typeof value === 'object') 
        {
            form.setFieldValue("url",value=="" ? '':value.url);
            form.setFieldValue("result",value=="" ? '':value.result);
            setParentValue(value === null ? '' :value.url);
        }
        if (typeof value === 'string')
        {
          const Obj = PreviousWebPortal.find(e => e.url.toLocaleLowerCase() == value.toLocaleLowerCase());
          form.setFieldValue("url", Obj!=undefined ? value:(value==""?'':value));
          let result = Obj == undefined ? '' :Obj.result;
          form.setFieldValue("result",result);
          setParentValue(value === null ? '' :value==""?'':value);
        }
    }

      const handleResultChange=(event:React.ChangeEvent,value:any)=>{
        if(event!=null){
            if(event.type=="click")
            {
                const Obj = PreviousWebPortal.find(e => e.result == value);
                let res = Obj== undefined ? '' :Obj.result;
                form.setFieldValue("result",res,true);
            }
            else
            {
                form.setFieldValue("result",value=="" ? '':value,true);
            }
        }
      }
    
    return(
           <Grid container spacing={2} style={{paddingRight: '5%',paddingLeft:'5%', alignContent:'flex-start'}}>
            
            <Grid item container direction="row" spacing={2} style={{marginTop:'0px'}}>
                <div style={{ display: 'flex', flexDirection: 'row',marginLeft:'1%'}}>
                <Typography variant='h6' className={classes.SecondHeading}>{t('websetting')}</Typography>
                    <Tooltip title={Info("webInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider} style={{width:'235%'}}/>
                </div>
            </Grid>
           
            <Grid item container direction="row" spacing={2} style={{marginTop:'1%'}}>
                <Grid item xs={12}>
                <EDRAutocomplete id="url"
                        freeSolo
                        disableClearable
                        size='small'
                        value={form.values.url}
                        options={PreviousWebPortal.map(a => a.url)}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderOption={(option: string|{url: string,result:string}) => (typeof option === 'string' ? option : `${option.url}`)}
	                    getOptionLabel={(option: string|{url: string,result:string}) => (typeof option === 'string' ? option : `${option.url}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={t('webTestURL')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="url"
                        onBlur={form.handleBlur}
                        error={form.touched.url && Boolean(form.errors.url)}
                        helperText={form.touched.url && form.errors.url}
                    />)}
                    />
                </Grid>
            </Grid>

            <Grid item container direction="row" spacing={2} style={{marginTop:'1%'}}>
            <Grid item xs={12}>
                <EDRAutocomplete id="result"
                        freeSolo
                        disableClearable
                        size='small'
                        value={form.values.result}
                        options={PreviousWebPortal.map(a => a.result)}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderOption={(option: string|{url: string,result:string}) => (typeof option === 'string' ? option : `${option.result}`)}
	                    getOptionLabel={(option: string|{url: string,result:string}) => (typeof option === 'string' ? option : `${option.result}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={t('webTestResult')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="result"
                        onBlur={form.handleBlur}
                        error={form.touched.result && Boolean(form.errors.result)}
                        helperText={form.errors.result && form.errors.result}
                    />)}
                    />  
                </Grid>
            </Grid>
             

            <Grid item container direction="row">
            <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end'}}>
            <Button onClick={handleCancle} color="primary" style={{textTransform:'none'}}>
                {t('Cancel')}
            </Button>
            <Button onClick={handleTest} disabled={form.values.url =='' || processingTest} color="primary" style={{textTransform:'none'}}>
            {processingTest ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                {t('test')}
            </Button>
            <SaveButton onClick={handleSave} disabled={form.values.url ==''} variant="contained" color="primary" style={{textTransform:'none',marginRight:'-8px'}}>
                {t('apply')}
            </SaveButton>
            </Grid>
            </Grid>
            
        </Grid>
    );
}

export default WebTestDialog;