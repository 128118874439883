import React, { useContext, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RunJobIcon from '@material-ui/icons/PlayArrow';
import NoteIcon from '@material-ui/icons/Note';

import LastJobReportIcon from '@material-ui/icons/Poll';
import SendMailIcon from '@material-ui/icons/Mail';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import LastJobLogIcon from '@material-ui/icons/AssignmentLate';
import CopyJobIcon from '@material-ui/icons/FileCopy';
import DeleteJobIcon from '@material-ui/icons/Delete';
import EditJobIcon from '@material-ui/icons/Edit';
import AutoRenewIcon from '@material-ui/icons/Autorenew';
import { Divider, Typography } from '@material-ui/core';
import JobResultsApi from '../api/jobResult.api';
import { RootStoreContext } from '../stores/root.store';
import jobAgent from '../../app/api/jobAgent';
import { toast } from 'react-toastify';
import { JobAction } from '../models/jobactions/jobaction.model';
import LogViewer from './logViewer.component';
import { useTranslation } from 'react-i18next';
import {history} from '../common/util/history';
import queryString from 'querystring';
import { AssetState } from '../models/jobRunWithAssetInfo.model';
import { JobDuplicate } from '../models/jobactions/jobDuplicate.model';
import { JobRestore } from '../models/jobactions/jobRestore.model';
import RenameJobComponent  from "../../features/jobResult/renameJob.component";
import ConfirmDeleteComponent from '../../features/jobResult/confirmDelete.component';
import UpdateJobLicenseComponent from '../../features/jobResult/updateJobLicense.component';
import { JobSummaryGroupByJob } from '../../app/models/jobSummaryGroupByJob.model';
import { UpdateType } from '../stores/activityStore';
import { JobSync } from '../models/jobactions/jobSync.model';
import { JobEdit } from '../models/jobactions/jobEdit.model';
import DashboardStore from '../stores/dashboard.store';
import JobSettingsStore from '../stores/jobSettings.store';
import ConfirmationDialog from './confirmationDialog.component';
import GetAppIcon from '@material-ui/icons/GetApp';
import { BooleanSchema } from 'yup';
import CleanupIcon from '../../features/Icons/CleanupIcon';
import FailoverIcon from '../../features/Icons/FailoverIcon';
import { JobRunType } from '../models/BaseSettings.enum';
import FailoverOnlyIcon from '../../features/Icons/FailoverOnlyIcon';
const { JobActions } = jobAgent;


export enum JobMenuAction {
  run = "startjob",
  runCleanup ="runCleanup",
  runFailover ="runFailover",
  runLiveFailover = "runLiveFailover",
  editJob = "editJob",
  lastReport = "lastReport",
  sendEmail = "sendEmail",
  getLogs = "getLogs",
  deleteJob = "deleteJob",
  duplicateJob = "duplicateJob",
  renameJob = "renameJob",
  restoreJob = "restoreJob",
  updateJobLicense = "updateJobLicense",
  syncJob = "syncJob",
}
type JobActionMenuProps = {
  anchorEl: any;
  firstJobEver:boolean;
  jobId: number;
  jobResultId: number;
  jobName: string;
  createDate:Date;
  jobNameFromJobs: string;
  isDeleted: boolean;
  isDeletedPermanently: boolean;
  attachmentSize: number;
  edrServer: string;
  onSelect?: (action: JobMenuAction) => void;
  onClose: () => void,
}



const JobActionMenu: React.FC<JobActionMenuProps> = (props) => {
  const { anchorEl, onClose, jobId, jobResultId, jobName,isDeleted,isDeletedPermanently,jobNameFromJobs,createDate,attachmentSize,edrServer,firstJobEver } = props;
  const { displayStore,userStore, jobRunStore, jobResultStore, activityStore, reportStore,settingStore,createJobStore,dashboardStore, jobSettingsStore} = useContext(RootStoreContext);
  const [openLogsDialog, setopenLogsDialog] = useState(false);
  const [openRenameDialog, setopenRenameDialog] = useState(false);
  const [openConfirmDeleteDialog, setopenConfirmDeleteDialog] = useState(false);
  const [openConfirmationRunDialog, setopenConfirmationRunialog] = useState(false);
  const [openFailoverRunDialog, setopenFailoverRunialog] = useState(false);
  const [openCleanupRunDialog, setopenCleanupRunialog] = useState(false);
  const [openLiveFailoverRunDialog, setopenLiveFailoverRunDialog] = useState(false);
  
  const [openLicDialog, setopenLicDialog] = useState(false);
  const {t: errorTranslations} = useTranslation('errors');
  const {t} =  useTranslation('jobmenu');
  const [isLive, setIsLive] = useState(false);
  const [logs,setLogs]= useState<Array<string>>([]);

  const handleAction = (action: JobMenuAction) => {

    switch (action) {
      case JobMenuAction.run: 
      jobRunStore.addJobRun(jobId);
      jobResultStore.updateJobRunState(jobId, true);
      history.push(`/jobs?${queryString.stringify({jobId: props.jobId, showReport: true})}`);
      jobRunStore.setJobRunType(JobRunType.All);
      JobActions.run(new JobAction({
        jobId: jobId,
        jobName: jobName,
        action: "startjob",
        actionDate: new Date(),
        remoteIP: "",
        jobRunType:JobRunType.All,
        userId: userStore.user.username
      })).then(res => {
        if(res.assetState === AssetState.OperationalWarning)
        {
          toast.warn(<React.Fragment>
            <h3>{errorTranslations('warning')}</h3>
            {res.nonOperationalReasons.map(err => <div style={{marginLeft: 10}}><i>- {errorTranslations(err)}</i></div>)}
          </React.Fragment>, {autoClose: 30000 })          
        }
        jobRunStore.updateJobRunState(res);
      }).catch(err => { 
        jobRunStore.removeJobRun(jobId);
       jobResultStore.updateJobRunState(jobId, false);
        if(err.status == 409) {
          toast.error(<React.Fragment>
            <h3>{errorTranslations('Error')}</h3>
            {<div style={{marginLeft: 10}}><i>- {errorTranslations(err.data.errors)}</i></div>}
          </React.Fragment>, {autoClose: 30000 })
        } else {
          toast.error(err.data.errors, {autoClose: 30000});
        }
      });
        break;
      case JobMenuAction.runFailover:
        jobRunStore.addJobRun(jobId);
      jobResultStore.updateJobRunState(jobId, true);
      history.push(`/jobs?${queryString.stringify({jobId: props.jobId, showReport: true})}`);
      jobRunStore.setJobRunType(JobRunType.FailoverOnly);
      JobActions.run(new JobAction({
        jobId: jobId,
        jobName: jobName,
        action: "startjob",
        jobRunType : JobRunType.FailoverOnly,
        actionDate: new Date(),
        remoteIP: "",
        userId: userStore.user.username
      })).then(res => {
        if(res.assetState === AssetState.OperationalWarning)
        {
          toast.warn(<React.Fragment>
            <h3>{errorTranslations('warning')}</h3>
            {res.nonOperationalReasons.map(err => <div style={{marginLeft: 10}}><i>- {errorTranslations(err)}</i></div>)}
          </React.Fragment>, {autoClose: 30000 })          
        }
        jobRunStore.updateJobRunState(res);
      }).catch(err => { 
        jobRunStore.removeJobRun(jobId);
       jobResultStore.updateJobRunState(jobId, false);
        if(err.status == 409) {
          toast.error(<React.Fragment>
            <h3>{errorTranslations('Error')}</h3>
            {<div style={{marginLeft: 10}}><i>- {errorTranslations(err.data.errors)}</i></div>}
          </React.Fragment>, {autoClose: 30000 })
        } else {
          toast.error(err.data.errors, {autoClose: 30000});
        }
      });
       setopenFailoverRunialog(false);
        break;
      case JobMenuAction.runLiveFailover:
        jobRunStore.addJobRun(jobId);
        jobResultStore.updateJobRunState(jobId, true);
        history.push(`/jobs?${queryString.stringify({jobId: props.jobId, showReport: true})}`);
        jobRunStore.setJobRunType(JobRunType.Live);
        JobActions.run(new JobAction({
          jobId: jobId,
          jobName: jobName,
          action: "startjob",
          jobRunType : JobRunType.Live,
          actionDate: new Date(),
          remoteIP: "",
          userId: userStore.user.username
        })).then(res => {
          if(res.assetState === AssetState.OperationalWarning)
          {
            toast.warn(<React.Fragment>
              <h3>{errorTranslations('warning')}</h3>
              {res.nonOperationalReasons.map(err => <div style={{marginLeft: 10}}><i>- {errorTranslations(err)}</i></div>)}
            </React.Fragment>, {autoClose: 30000 })          
          }
          jobRunStore.updateJobRunState(res);
        }).catch(err => { 
          jobRunStore.removeJobRun(jobId);
         jobResultStore.updateJobRunState(jobId, false);
          if(err.status == 409) {
            toast.error(<React.Fragment>
              <h3>{errorTranslations('Error')}</h3>
              {<div style={{marginLeft: 10}}><i>- {errorTranslations(err.data.errors)}</i></div>}
            </React.Fragment>, {autoClose: 30000 })
          } else {
            toast.error(err.data.errors, {autoClose: 30000});
          }
        });
        setopenLiveFailoverRunDialog(false);
        break;
      case JobMenuAction.runCleanup:
        jobRunStore.addJobRun(jobId);
      jobResultStore.updateJobRunState(jobId, true);
      history.push(`/jobs?${queryString.stringify({jobId: props.jobId, showReport: true})}`);
      jobRunStore.setJobRunType(JobRunType.CleanUpOnly);
      JobActions.run(new JobAction({
        jobId: jobId,
        jobName: jobName,
        action: "startjob",
        jobRunType : JobRunType.CleanUpOnly,
        actionDate: new Date(),
        remoteIP: "",
        userId: userStore.user.username
      })).then(res => {
        if(res.assetState === AssetState.OperationalWarning)
        {
          toast.warn(<React.Fragment>
            <h3>{errorTranslations('warning')}</h3>
            {res.nonOperationalReasons.map(err => <div style={{marginLeft: 10}}><i>- {errorTranslations(err)}</i></div>)}
          </React.Fragment>, {autoClose: 30000 })          
        }
        jobRunStore.updateJobRunState(res);
      }).catch(err => { 
        jobRunStore.removeJobRun(jobId);
       jobResultStore.updateJobRunState(jobId, false);
        if(err.status == 409) {
          toast.error(<React.Fragment>
            <h3>{errorTranslations('Error')}</h3>
            {<div style={{marginLeft: 10}}><i>- {errorTranslations(err.data.errors)}</i></div>}
          </React.Fragment>, {autoClose: 30000 })
        } else {
          toast.error(err.data.errors, {autoClose: 30000});
        }
      });
        setopenCleanupRunialog(false);
        break;
      case JobMenuAction.sendEmail: JobResultsApi.sendMail(jobResultId)
        .then(res => {
          if(res == "Success")
            toast.success(t('mailSent'));
          else if(res == "SuccessFallback")
            toast.success(t('mailRequestSent'));
          else
            toast.warn(errorTranslations("sendMail"))
        })
        .catch(err => {
            toast.warn(errorTranslations("sendMail"));
        });
        break;
      case JobMenuAction.getLogs: setopenLogsDialog(true); break;
      case JobMenuAction.lastReport: history.push("/report?" + queryString.stringify({ jobId: jobId, jobResultId: jobResultId })); return;
      case JobMenuAction.deleteJob: setopenConfirmDeleteDialog(true); break;
      case JobMenuAction.syncJob: JobActions.syncJob(new JobSync({ id:jobId, runId:jobRunStore.getJobRun(jobId).id}))
      .then(res=>{
        if(res.includes("success"))
        {
          toast.success(t('syncSuccess'))
        }
        else
        {
          if(res === "AssetNotAvailable")
            {
              toast.error(<React.Fragment>
                <h3>{errorTranslations('Error')}</h3>
                {<div style={{marginLeft: 10}}><i>{errorTranslations('RunnerServiceNotAvailableJobNotChagnedSync')}</i></div>}
              </React.Fragment>, {autoClose: 30000 })                 
            }
          else if(res === "EnsureDRRunning")
          {
            toast.warn(<React.Fragment>
            <h3>{errorTranslations('warning')}</h3>
                {<div style={{marginLeft: 10}}><i>{errorTranslations('SyncJobAlreadyRunning')}</i></div>}
              </React.Fragment>, {autoClose: 30000 })
          }
          else
          {
            toast.error(t('syncFail')) 
          }
        }
      })
      .catch(err => {
        toast.error(err)
      })
      break;
      case JobMenuAction.editJob: 
      try
      {
        jobSettingsStore.setIsAutheticatedToFalse();
        jobSettingsStore.setIsDMAutheticatedToFalse();
        displayStore.resetDrawers();
        history.push(`editJob/${jobId}`)
        // createJobStore.getJob(jobId);
        
        break;
      }
      catch(error){ break;};
      case JobMenuAction.renameJob: setopenRenameDialog(true); break;
      case JobMenuAction.updateJobLicense: setopenLicDialog(true); break;
      case JobMenuAction.duplicateJob: JobActions.duplicate(new JobDuplicate({ id:jobId }))
        .then(res => {
          if(res.includes("success"))
          {
             // activityStore.sendNewUpdatesSignal();
              let addedId = Number(res.slice(res.indexOf('-') + 1));
              activityStore.sendMessage(UpdateType.JobAdded, {jobId: addedId});
              toast.success(t('duplicateSuccess'))
          }
          else
          {
            if(res === "AssetNotAvailable")
            {
              toast.error(<React.Fragment>
                <h3>{errorTranslations('Error')}</h3>
                {<div style={{marginLeft: 10}}><i>{errorTranslations('RunnerServiceNotAvailableJobNotChagnedDuplicate')}</i></div>}
              </React.Fragment>, {autoClose: 30000 })                 
            }
            else if(res === "JobFileNotExist")
            {
              toast.error(<React.Fragment>
                <h3>{errorTranslations('Error')}</h3>
                {<div style={{marginLeft: 10}}><i>{errorTranslations('JobFileNotExistOnRemoteServer')}</i></div>}
              </React.Fragment>, {autoClose: 30000 })             
            }
            else
            {
              toast.error(t('duplicateFail')) 
            }
                         
          }
        })
        .catch(err => {
          toast.error(err)
        })
        break;
      case JobMenuAction.restoreJob: JobActions.restoreJob(new JobRestore({id: jobId}))
      .then(res => {
        if(res === "success")
        {
            activityStore.sendMessage(UpdateType.JobRestored, {jobId: jobId});
            toast.success(t('restoreSuccess'))
        }
        else
        {
          if(res === "AssetNotAvailable")
            {
              toast.error(<React.Fragment>
                <h3>{errorTranslations('Error')}</h3>
                {<div style={{marginLeft: 10}}><i>{errorTranslations('RunnerServiceNotAvailableJobNotChagnedRestore')}</i></div>}
              </React.Fragment>, {autoClose: 30000 })                 
            }
            else
            {
              toast.error(t('restoreFail')) 
            }
            
        }
      })
      .catch(err => {
        toast.error(err)
      })
      break;
      
    }
  }
  const handleMenuSlection = (action: JobMenuAction) => {
    // onSelect(action);
    handleAction(action);
    onClose();
  }
  const selectJob = (jobGroup: JobSummaryGroupByJob) => {
    const query = { jobId: jobGroup.jobId, jobResultId: jobGroup.lastJobResultId }
    history.push({ search: queryString.stringify(query) });
  }

  const createDateNotExist= (createDate:Date)=> {
    return createDate && createDate.toString() =="0001-01-01T00:00:00" ? true:false;
  }

  const handleConfirmationPopup =(confirm:BooleanSchema,type:string)=>{
    if(confirm)
    {
      switch(type)
      {
        case "runJob":
          {
            setIsLive(false);
            handleMenuSlection(JobMenuAction.run);
            break;
          }
        case "runFailover":
          {
            handleMenuSlection(JobMenuAction.runFailover);
            break;
          }
        case "runCleanup":
          {
            handleMenuSlection(JobMenuAction.runCleanup);
            break;
          }
        case "runLiveFailover":
          {
            handleMenuSlection(JobMenuAction.runLiveFailover);
            break;
          }
      }
    }
    setopenConfirmationRunialog(false);
    setopenFailoverRunialog(false);
    setopenCleanupRunialog(false);
    setopenLiveFailoverRunDialog(false);
}

const downloadFile =()=>{     
 
  };

  const enableLiveFailover =()=>{
    if(jobName.toLocaleLowerCase().includes("netapp") || jobName.toLocaleLowerCase().includes("srm") || jobName.toLocaleLowerCase().includes("zerto_vmware") )
      return true;
    else
      return false;
  }

  /*const element = document.createElement("a");
  const file = new Blob([logs.join('\n')], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = `EnsureDR.log..txt`;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
  document.body.removeChild(element);*/


  return (
    <Menu
      data-test="component-jobActionMenu"
      anchorEl={anchorEl}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(anchorEl)}

      onClose={onClose}>
      {/* <MenuItem disabled={!!jobRunStore.getJobRun(jobId) || (isDeleted || isDeletedPermanently)} onClick={() => setopenConfirmationRunialog(true)}>
        <span data-test="runJobButton">
          <ListItemIcon>
            <RunJobIcon fontSize="small" />
          </ListItemIcon>
        </span>
        <ListItemText primary={t("runTest")} />
      </MenuItem>
      <MenuItem disabled={!!jobRunStore.getJobRun(jobId) || (isDeleted || isDeletedPermanently)} onClick={() => setopenCleanupRunialog(true)}>
        <span data-test="runJobButton">
          <ListItemIcon>
            <CleanupIcon style={{color:"rgba(0, 0, 0, 0.54)"}}/>
          </ListItemIcon>
        </span>
        <ListItemText primary={t("runCleanup")} />
      </MenuItem>
      <MenuItem disabled={!!jobRunStore.getJobRun(jobId) || (isDeleted || isDeletedPermanently)} onClick={() => setopenFailoverRunialog(true)}>
        <span data-test="runJobButton">
          <ListItemIcon>
            <FailoverIcon style={{color:"rgba(0, 0, 0, 0.54)"}}/>
          </ListItemIcon>
        </span>
        <ListItemText primary={t("runFailover")} />
      </MenuItem>
      <MenuItem disabled={jobName!=null ? (enableLiveFailover() ? (jobRunStore.getJobRun(jobId) || (isDeleted || isDeletedPermanently) ? true:false):true):false} onClick={() => setopenLiveFailoverRunDialog(true)}>
        <span data-test="runJobButton">
          <ListItemIcon>
            <FailoverOnlyIcon style={{color:"rgba(0, 0, 0, 0.54)"}}/>
          </ListItemIcon>
        </span>
        <ListItemText primary={t("runLiveFailover")} />
      </MenuItem>
      {/* <MenuItem disabled={!!jobRunStore.getJobRun(jobId) || (isDeleted || isDeletedPermanently)} onClick={() => {setIsLive(true);handleMenuSlection(JobMenuAction.run)}}>
        <ListItemIcon>
          <RunJobIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t("runLive")} />
      </MenuItem> */}
      {/* <Divider /> */}
      <MenuItem disabled={!jobResultId} onClick={() => handleMenuSlection(JobMenuAction.lastReport)}>
        <span data-test="lastReportButton">
          <ListItemIcon  >
            <LastJobReportIcon fontSize="small" />
          </ListItemIcon>
        </span>
        <ListItemText primary={t("lastJobReport")} />
      </MenuItem>
      {/* <MenuItem disabled={!jobResultId} onClick={() => handleMenuSlection(JobMenuAction.sendEmail)}>
        <span data-test="sendReportViaMailButton">
          <ListItemIcon>
            <SendMailIcon fontSize="small" />
          </ListItemIcon>
        </span>
        <ListItemText primary={t("sendReportMail")} secondary = { + attachmentSize && attachmentSize > 0 ? '(' + Math.ceil(attachmentSize / 1024 / 1024) + ' Mb)' : ""} />
      </MenuItem> */}
      <MenuItem disabled={!jobResultId} onClick={() => handleMenuSlection(JobMenuAction.getLogs)} >
        <span data-test="lastJobLogButton">
          <ListItemIcon>
            <LastJobLogIcon fontSize="small" />
          </ListItemIcon>
        </span>
        <ListItemText primary={t("lastJobLog")} />
      </MenuItem>
      {/* <MenuItem disabled={jobRunStore.isJobRunning(jobId) || isDeleted || isDeletedPermanently} onClick={() => handleMenuSlection(JobMenuAction.duplicateJob)}>
        <span data-test="duplicateJobButton">
          <ListItemIcon>
            <img src='/assets/images/menuIcons/duplicate.svg'/>
          </ListItemIcon>
        </span>
        <ListItemText primary={t("duplicateJob")} />      
      </MenuItem> */}
      <Divider />
      {/* <MenuItem disabled={jobRunStore.isJobRunning(jobId) || isDeleted || isDeletedPermanently} onClick={() => handleMenuSlection(JobMenuAction.deleteJob)}>
        <span data-test="deleteJobButton">
          <ListItemIcon>
            <DeleteJobIcon fontSize="small" />
          </ListItemIcon>
        </span>
        <ListItemText primary={t("deleteJob")} />
      </MenuItem> */}
      {/* <MenuItem disabled={jobRunStore.isJobRunning(jobId) || isDeleted || isDeletedPermanently} onClick={() => handleMenuSlection(JobMenuAction.renameJob)}>
        <span data-test="renameJobButton">
          <ListItemIcon>
            <EditJobIcon fontSize="small" />
          </ListItemIcon>
        </span>
        <ListItemText primary={t("renameJob")}/>
    </MenuItem>*/}
      {dashboardStore.createEnabled ?
      <MenuItem disabled={isDeleted || isDeletedPermanently || createDateNotExist(createDate)} onClick={() => handleMenuSlection(JobMenuAction.editJob)}>
        <span data-test="editJobButton">
          <ListItemIcon>
            <img src='/assets/images/menuIcons/edit.svg'/>
          </ListItemIcon>
        </span>
        <ListItemText primary={t("editJob")}/>
      </MenuItem>:null}
      {/* <MenuItem disabled={!isDeleted || isDeletedPermanently} onClick={() => handleMenuSlection(JobMenuAction.restoreJob)}>
        <span data-test="restoreJobButton">
          <ListItemIcon>
            <img src='/assets/images/menuIcons/restore.svg'/>
          </ListItemIcon>
        </span>
        <ListItemText primary={t("restoreJob")}/>
      </MenuItem>
      <MenuItem disabled={true} onClick={downloadFile}>
      <span data-test="downloadLogJobButton">
          <ListItemIcon>
            <GetAppIcon></GetAppIcon>
          </ListItemIcon>
        </span>
        <ListItemText primary={t("downloadLog")}/>
      </MenuItem>
     {/*<MenuItem disabled={jobRunStore.isJobRunning(jobId)|| isDeleted || isDeletedPermanently} onClick={() => handleMenuSlection(JobMenuAction.updateJobLicense)}>
        <span data-test="setLicenseButton">
          <ListItemIcon>
            <NoteIcon fontSize="small" />
          </ListItemIcon>
        </span>
        <ListItemText primary={t("licenseChange")}/>
      </MenuItem> */}
      {/*<MenuItem disabled={jobRunStore.isJobRunning(jobId)?isDeleted:!isDeleted} onClick={() => handleMenuSlection(JobMenuAction.syncJob)}>
        <span data-test="syncButton">
          <ListItemIcon>
            <SyncAltIcon fontSize="small" />
          </ListItemIcon>
        </span>
        <ListItemText primary={t("syncJob")} />
      </MenuItem>*/}
      <LogViewer jobName={jobName} jobResultId={jobResultId} open={openLogsDialog} onClose={() => setopenLogsDialog(false)} />
      <RenameJobComponent jobId={jobId} open={openRenameDialog} jobName={jobNameFromJobs} jobNameFromJob={jobNameFromJobs} onClose={() => setopenRenameDialog(false)}></RenameJobComponent>
      <ConfirmDeleteComponent jobId={jobId} JobNameFromJobs={jobNameFromJobs} edrServer={edrServer} open={openConfirmDeleteDialog} onClose={() => setopenConfirmDeleteDialog(false)} ></ConfirmDeleteComponent>
      <UpdateJobLicenseComponent jobId={jobId} jobNameFromJobs={jobNameFromJobs} open={openLicDialog} onClose={() => setopenLicDialog(false)}></UpdateJobLicenseComponent>
      <ConfirmationDialog type="runJob" device={null} open={openConfirmationRunDialog} onClose={(confrim)=>handleConfirmationPopup(confrim,"runJob")} children={"You are about to Run a Job that will failover to your DR and test it - are you sure?"}></ConfirmationDialog>
      <ConfirmationDialog type="runFailover" device={null} open={openFailoverRunDialog} onClose={(confrim)=>handleConfirmationPopup(confrim,"runFailover")} children={"You are about to run a failover process of "+ jobName +" - This means the devices will failover but WILL NOT clean up, which means, you need to take care of that yourself - either by running Cleanup only mode from EnsureDR, or doing this manually in the Data Mover. Do you want to continue?"}></ConfirmationDialog>
      <ConfirmationDialog type="runCleanup" device={null} open={openCleanupRunDialog} onClose={(confrim)=>handleConfirmationPopup(confrim,"runCleanup")} children={"You are about to clean up the devices for Job "+ jobName +". This means, EnsureDR will look for devices which are failed over without cleanup. If no such devices exist- this process will fail. please make sure those devices exist before you continue. Do you want to Continue?"}></ConfirmationDialog>
      <ConfirmationDialog type="runLiveFailover" device={null} open={openLiveFailoverRunDialog} onClose={(confrim)=>handleConfirmationPopup(confrim,"runLiveFailover")} children={"You are about to run a live failover of the job "+ jobName +". This will failover all of the devices configured in this job, without cleanup. Are you sure you want to procceed?"}></ConfirmationDialog>
    </Menu>
  );
}


export default JobActionMenu;