import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";
import { CircularProgressbarWithChildren, buildStyles  } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import EventIcon from "@material-ui/icons/Event";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { IScore } from "./IScore";
import { RootStoreContext } from "../../../app/stores/root.store";
import RecoveryReadinessChart from "../../report/recoveryReadiness.component";
import DashboardChartPlugin from "../../../app/common/chartjsPlgins/dashboardScoreChart.lugin";
import ValueLabelPlugin from "../../../app/common/chartjsPlgins/ValueLabel.plugin";

const Score = (props: IScore) => {
  const [t] = useTranslation("dashboard");
  const rootStore = useContext(RootStoreContext);
  const { dashboard } = rootStore.dashboardStore;
  const date = dashboard && dashboard.runDate ? dashboard.runDate.toString().substring(0, 10) : "01.01.2020 - 31.12.2020"; //todo: dudu: make it as range of first and last job run, and if only one job its date and ajust size!

  const useStyles = makeStyles({
    score: {
      width: "100%",
      borderRadius: 4,
      height: '60%',
      maxHeight: 250,
      minHeight: '150px',
      textAlign: "center",
      overflow: "hidden",
      fontFamily:"Roboto",
      paddingTop:"5%",
      flexGrow:1 
    },
    text:{
      color:"#4d5f75",
      textAlign: 'center',
      marginBottom:"2%",
      fontSize:"1rem"
    }
  });

  const classes = useStyles();

  useEffect(() => {
    //callMatomo("Score","/dashboard");
  }, []);

  return (
    <div data-test="component-score" style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
      <Typography classes={{ root: "dashboard_header" }}>{t("recoveryScoreHeader")}</Typography>
      <div className={classes.score}>

      <RecoveryReadinessChart width={210} height={210} score={props.score} cutoutPercentage={75} color={"rgba(0,0,0,0)"} 
      plugins={[DashboardChartPlugin, ValueLabelPlugin(val => `${val}`, {color: '#1f396d', font: 'bold 4rem Roboto'}, 'Recoverable' , {color: '#1f396d', font: 'bold 1.2rem Roboto'} )]} />
      </div>
      <div className={classes.text}><EventIcon fontSize={'inherit'} style={{fill:"#8092a9", paddingRight:"1%"}}/>{props.endDate ? `${props.startDate} - ${props.endDate}`: props.startDate}</div>
    </div>
  );
};

export default observer(Score);
