import { Button, Card, CircularProgress, Divider, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import HelpIcon from '@material-ui/icons/Help';
import { DNS_RESOLVE_NAME } from '../../../app/models/Network.enum';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DnsResolveType, JobType, NetworkOption } from '../../../app/models/jobProperties.model';
import { RootStoreContext } from '../../../app/stores/root.store';
import { Autocomplete } from '@material-ui/lab';
import AssetAgent from '../../../app/api/assetsAgent';
import JobAgent from '../../../app/api/jobAgent';
import { Credentials } from '../../../app/models/asset.model';
import CreateJobStore from '../../../app/stores/createJob.store';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { toast } from 'react-toastify';
import { runInAction } from 'mobx';
import Create from '@material-ui/icons/Create';
import EDRComponent from './Vlan/EDR.component';
import EDRCreateComponent from './Vlan/EDRCreate.component';
import { AssetType } from '../../../app/models/createjob/createJob.model';
import EDRCCreateComponent from './Vlan/EDRCCreate.component';
import EDRCComponent from './Vlan/EDRC.component';
import DCComponent from './Vlan/DC.component';

const useStyles = makeStyles({
    editIcon: {
        cursor: 'pointer'
    },
    button: {
        width: '50%',
        height: '100%',
        borderRadius: '4px',
        border: 'solid 1px #c7cfdb',
        backgroundColor: '#fafafc'
    },
    advanced: {
        width: '14px',
        height: '14px',
        objectFit: 'contain',
    },
    MainHeading: {
        fontFamily: 'Roboto',
        color: '#1f396d',
        fontSize: '16px'
    },
    SecondHeading: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '100%',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.14',
        letterSpacing: 'normal',
        textAlign: 'left'
    },
    card: {
        padding: 25,
        height: '100%',
    },
    divider: {
        backgroundColor: '#eaeef4',
        width: '75%',
        marginTop: '8px',
        marginLeft: '7px',
        marginRight: '-107px'
    },
    helpIcon:
    {
        color: '#1976d2',
        fontSize: "large"
    },
    formControl: {
        width: '100%',
        '& label': {
            background: "white",
            width: "auto",
            padding: "0 4px 0 4px",
            fontSize: '17px'
        }
    }
});
const NetworkSettingComponent: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const {jobSettingsStore,createJobStore,displayStore} = rootStore;
    const classes = useStyles();
    const [t] = useTranslation("createJobNetwork");
    const [validation, setValidation] = useState({EDRValid: true, DCValid: true, EDRCValid: true})
    useEffect(() => {
       const isInvalid = Object.values(validation).some(v => !v);
       displayStore.updateNextStepValid({networkSettingValid: !isInvalid});
    }, [validation])

    useEffect (()=>{
          displayStore.updateScreenSettings({EDRApplyProcessing:true,EDRCApplyProcessing:true,DCApplyProcessing:true});
          jobSettingsStore.getAssets().finally(()=>{
            displayStore.updateScreenSettings({EDRApplyProcessing:false,EDRCApplyProcessing:false,DCApplyProcessing:false});
            displayStore.updateNextStepValid({networkSettingValid: true});
          });
    },[])

        return (
        <Grid container item style={{ flexGrow: 6 }}>

            <Grid item xs style={{ paddingBottom: 1, paddingRight: 16, marginLeft: '16px' }}>
                <Card elevation={1} classes={{ root: clsx([classes.card]) }} style={{ height: '100%' }}>
                    <Typography variant='h6' className={classes.MainHeading} style={{marginLeft:'4%'}}>{t('additionalSettings')}</Typography>

                    <Grid container direction="column">
                        
                        <Grid item direction="row" style={{marginLeft:'4%'}}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px',marginBottom:'8px' }}>
                                <Typography className={classes.SecondHeading} style={{ color: '#1f396d' }}>{t('EnsuredrServer')}</Typography>
                                <Tooltip title={t("EnsuredrServerInfo")} arrow>
                                    <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                                </Tooltip>
                                <Divider className={classes.divider} style={{ width: '83%' }} />
                            </div>
                        </Grid>

                        {jobSettingsStore.anyEDR  ? <EDRComponent onValidationChange={(EDRValid) => setValidation({...validation, EDRValid})}></EDRComponent>:<EDRCreateComponent></EDRCreateComponent>}

                        <Grid item direction="row" style={{marginLeft:'4%'}}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px' }}>
                                <Typography className={classes.SecondHeading} style={{ color: '#1f396d' }}>{t('edrc')}</Typography>
                                <Tooltip title={t("edrcInfo")} arrow>
                                    <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                                </Tooltip>
                                <Divider className={classes.divider} />
                            </div>
                        </Grid>

                        {jobSettingsStore.anyEDRC ? <EDRCComponent onValidationChange={(EDRCValid) => setValidation({...validation, EDRCValid})}></EDRCComponent>:<EDRCCreateComponent></EDRCCreateComponent>}

                        <Grid item direction="row" style={{marginLeft:'4%'}}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px' }}>
                                <Typography className={classes.SecondHeading} style={{ color: '#1f396d' }}>{t('clone')}</Typography>
                                <Tooltip title={t("cloneInfo")} arrow>
                                    <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                                </Tooltip>
                                <Divider className={classes.divider} style={{ width: '77%', }} />
                            </div>
                        </Grid>

                        <DCComponent onValidationChange={(DCValid) => setValidation({...validation, DCValid})}></DCComponent>
                    </Grid>
                </Card>
            </Grid>

        </Grid>
    );
}; 

export default observer(NetworkSettingComponent);