
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, formatMs, FormControlLabel, Grid, IconButton, InputLabel, ListItemText, makeStyles, Switch, Tooltip, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EDRTextField } from "../../../../app/common/EDRTextField";
import { SaveButton } from "../../../../app/common/saveButton";
import { EDRDialogTitle } from "../../../../app/common/EDRDialogTitle";
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EDRAutocomplete } from '../../../../app/common/EDRAutocomplete';
import { JobDevice } from '../../../../app/models/jobDevice/jobDevice.model';
import { RootStoreContext } from '../../../../app/stores/root.store';
import { TestType } from '../../../../app/models/jobTest.model';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { DatabaseType, DevicePlatform, MachineType } from '../../../../app/models/jobProperties.model';
import HelpIcon from '@material-ui/icons/Help';
import { toast } from 'react-toastify';
import jobAgent from '../../../../app/api/jobAgent';

const useStyles = makeStyles(theme => ({
    helpIcon:
    {
      color: '#1976d2',
      fontSize: "large"
    },
    SecondHeading:{
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        flexGrow: 1
    },
    label: {
        textTransform: 'capitalize',
    },
    root: {
        minWidth: '30vw'
    },
    checkbox: {
        marginLeft: theme.spacing(0.5)
    },
    heading:{
        fontFamily: 'Roboto',
        fontSize: '13px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontstyle: 'normal',
        lineHeight: 1.15,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#4d5f75',
    },
    divider: {
        backgroundColor: '#eaeef4',
        maxWidth: '210%',
        margin: '0',
        marginTop: '10px',
        marginLeft: '10px',
    },
    title:{
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontstyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        textAlign: 'left'
    },
    centerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between'
    },
    text:{
        color: '#4d5f75',
        fontSize: '13px',
        fontstyle: "normal",
        textAlign: "left",
        fontFamily: "Roboto",
        fontWeight: 500,
        lineHeight: 1.15,
        fontStretch: "normal",
        letterSpacing: "normal"
    },
    toggleItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiListItemText-root' : {
            marginLeft: 10
        },
        '& .MuiSvgIcon-root': {
            color: '#8092a9'
        }
    }
}));
export interface ServiceTestSettingProps {
    open: boolean;
    onSave : () => void;
    onClose: () => void;
    deviceName: string;
    isDefault :boolean;
}

const validationSchema = yup.object({
    serviceName: yup.string().required('Required'),
    isExt: yup.bool()
})
const ServiceTestSettingDialog: React.FC<ServiceTestSettingProps> = (props) => {
    const { open,deviceName,isDefault, onClose,onSave } = props;
    const {createJobStore,jobSettingsStore} = useContext(RootStoreContext);
    const [device, setDevice] = useState(createJobStore.selectedDevices.find(device=>device.deviceName == deviceName));
    const [ServiceTest,setServiceTest] =useState(device!= undefined ? device.tests.find(test=> test.testType == TestType.service):null)
    const [processingTest,setProcessingTest] = useState(false);
    const classes = useStyles();
   
    const loadServiceList =()=>{
        let serviceName=[];
        let list= device !=undefined ? jobSettingsStore.ServiceList.filter(item=>item.os == device.os) : jobSettingsStore.ServiceList;
        list.map(item=>{
            serviceName.push(item.service);
        });
        return serviceName;
    }

    const loadOS =()=>{
        if(device!=undefined)
            return device.os;
         else
            return DevicePlatform.Windows;
    }

    const getPreviousServiceName= loadServiceList();
    const [cancel,setCancel]=useState(false);
    const [OS, setOS] = useState(loadOS());
    const { t } = useTranslation('createJobBase');

    const defaultSettings = {
        serviceName: device!=undefined ? (device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.serviceToTestLinux :jobSettingsStore.serviceToTest):(jobSettingsStore.serviceToTest),
        isExt: jobSettingsStore.testFromEDRC
    };

    const form = useFormik({

        initialValues: {
            serviceName: defaultSettings.serviceName,
            isExt: defaultSettings.isExt,
        },
        onSubmit: () => { },
        validationSchema
    })

    useEffect(() => {
        const newDevice = createJobStore.selectedDevices.find(device=>device.deviceName == deviceName)
        setDevice(newDevice);
        const newServiceTest = newDevice!= undefined ? newDevice.tests.find(test=> test.testType == TestType.service):null
        setServiceTest(newServiceTest);
        let initialValues;
        initialValues =
        {
            serviceName: newServiceTest &&  newServiceTest.serviceToTest!="" ? newServiceTest.serviceToTest :defaultSettings.serviceName,
            isExt: newServiceTest &&  newServiceTest.testFromEDRC != false  ?newServiceTest.testFromEDRC :defaultSettings.isExt
        };
        form.resetForm({values: initialValues});
    }, [deviceName]);

    const handleClose = () => {
        if(form.dirty && form.touched)
            setCancel(!cancel);
        else
            onClose();
    }

    const handleSave=()=>{
        try{
        if(device!=undefined)
        {
            let DeviceTest = {
                testCategory:device.tests[0].testCategory, 
                testType: TestType.service, 
                selected: true,
                customCommandToTest: '', 
                customCommandExpectedResult: '', 
                customCommandToTestIsExternal: false,
                databaseType: DatabaseType.SQL,
                databaseNameToTest: '',
                databaseQueryToTest: '',
                databaseServerNameToTest: '',
                databaseUsername:'',
                databasePassword: '',
                databasePort:'',
                dbWindowsAuth:false,
                serviceToTest: form.values.serviceName,
                testFromEDRC: form.values.isExt,
                script: '',
                thirdDeviceNetworkToTest: '',
                authenticationUserToTest:'',
                authenticationPassToTest:'',
                webPortalToTest: '',
                webPortalResult: ''
            };
            createJobStore.updateTestDevice(TestType.service,DeviceTest,device.planName.includes("Custom Device") ? device.sourceName : device.deviceName);
            toast.success('Save successful');
        }
        else
        {
            jobSettingsStore.updateServiceSettings(form.values.serviceName,form.values.isExt,null);
            toast.success('Save successful');
        }
    }
    catch(error){
        toast.success('Save failed'+error);
    }
        setCancel(false);
        if(device!=undefined)
            onSave();
        onClose();
    }

    const handleTest=() =>{  
        let serviceToTest= form.values.serviceName;
        var details ={ServiceName:serviceToTest,Hostname:device.sourceName,UserId:device.os === DevicePlatform.Windows ? jobSettingsStore.selectedPrimaryUser.id :jobSettingsStore.selectedSecondaryUser.id ,devicePlatform:device.os};
        setProcessingTest(true);        
        jobAgent.JobTestsActions.TestApplication(details).then(res => {
            if(res)
                toast.success("Application Test succeeded");
            else
                toast.error("Application Test failed");
            setProcessingTest(false);
        }).catch(err=>{
            if(err && err.data && err.data.errors)
                toast.error(err.data.errors);
            setProcessingTest(false);
        });        
    }

    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel style={{marginLeft:'10px'}}>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const handleYes=()=>{
        let initialValues = {
            serviceName: ServiceTest!=null ? ServiceTest.serviceToTest: defaultSettings.serviceName,
            isExt: ServiceTest!=null ? ServiceTest.testFromEDRC: defaultSettings.isExt
        };
        device!=undefined ? setOS(device.os):setOS(DevicePlatform.Windows);
        form.setValues(initialValues);
        setCancel(false);
        onClose();
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    const handleAutoCompleteChange = (event: React.ChangeEvent,value:any) => {
        if(event!=null)
        {
          let fieldName= event.target['id'].includes('-') ? event.target['id'].split('-')[0]: event.target['id'];
          form.setFieldValue(fieldName,value);
          switch(fieldName) {
            case 'serviceName': handleUrlChange(event,value); break;
          }
        }
      }
    
    const handleUrlChange=(event:object,value:any)=>{
        form.setFieldValue("serviceName",value,true);
        if(device!=undefined)
            setOS(device.os);
        else
        {
            let osInList= jobSettingsStore.ServiceList.find(item=> item.service === value);
            if(osInList!=undefined)
                setOS(osInList.os);
        }
    }

    const handleExtChange =(event)=>{
        form.setFieldValue("isExt", event.target.checked);
    }

    const onOSChange = (event, value) => {
        if(!getPreviousServiceName.includes(form.values.serviceName))
            setOS(event.currentTarget.value);
      };

    const loadOSSection =()=>{
        if(device === undefined)
        return( 
                <Grid item container>
                <Grid item xs={3} container alignItems="center">
                    <Typography variant="h6" className={classes.heading}>* {t('operatingSystem')}</Typography>
                </Grid>
                <Grid item xs={9} alignItems="center" style={{marginTop:'12px',display:'flex',justifyContent:'flex-end'}}>
                <ToggleButtonGroup
                    classes={{ root: "toggle_button_group" }}
                    value={OS}
                    onChange={onOSChange}
                    >
                    <ToggleButton
                    disabled={jobSettingsStore.ServiceList.some(e => e.service === form.values.serviceName)}
                    classes={{ root: "toggle_button", selected: "toggle_button_selected", label: classes.label }}
                    value={DevicePlatform.UNIX}>
                    {t("toggleButton.linux")}
                    </ToggleButton>
                    <ToggleButton
                    disabled={jobSettingsStore.ServiceList.some(e => e.service === form.values.serviceName)}
                    classes={{ root: "toggle_button", selected: "toggle_button_selected", label: classes.label }}
                    value={DevicePlatform.Windows}>
                    {t("toggleButton.windows")}
                    </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                </Grid>
        )
    }
    

    return <Dialog disableBackdropClick
        open={open}
        classes={{ paper: classes.root }}
        onClose={handleClose}
        scroll="paper"
    >
        <EDRDialogTitle>
        <div style={{ display: 'flex', flexDirection: 'row'}}>
            <Typography variant="h6" className={classes.title} style={{display:'flex',alignItems:'center'}}>{t('serviceSetting')}</Typography>
            {device !== undefined ? <img style={{marginLeft:'5px'}} src={device.os=== DevicePlatform.UNIX ?"/assets/images/editDeviceIcons/linuxIcon.svg":"/assets/images/editDeviceIcons/windowsIcon.svg"}></img>:null}
        </div>
        </EDRDialogTitle>
        <DialogContent dividers={true}>

        <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row',paddingBottom:'5%'}}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('subService')+deviceName}</Typography>
                    <Tooltip title={device.os=== DevicePlatform.UNIX ? t('serviceInfoLinux'):t('serviceInfoWin')} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip> 
                    <Divider className={classes.divider}/>
                </div>
            </Grid>

            <Grid container direction="column" spacing={1}>
                <Grid item container>
                    <Grid item xs={3} container alignItems="center">
                        <Typography variant="h6" className={classes.heading}>* {t('serviceName')}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                    <EDRAutocomplete id="serviceName"
                        freeSolo
                        disableClearable
                        value={form.values.serviceName}
                        options={getPreviousServiceName}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderInput={(params) => (<EDRTextField {...params} label={t('serviceName')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="serviceName"
                        onBlur={form.handleBlur}
                        error={form.touched.serviceName && Boolean(form.errors.serviceName)}
                        helperText={form.errors.serviceName}
                    />)}
                    />
                    </Grid>
                </Grid>
                {loadOSSection()}
                <Grid item container xs={12}>
                <Grid item xs={10}>
                <div className={classes.toggleItem}>
                    <Typography className={classes.heading} style={{marginLeft:'0px',marginTop:'3%'}}>{t("testRemote")}</Typography>
                </div>
                </Grid>
                <Grid item xs={2}>
                <div className={classes.centerVertical} style={{justifyContent:'flex-end'}}>
                <Tooltip title="Testing will be done by connecting to the remote device. you can alternatively toggle this button to test remotely from the EnsureDR controller" arrow>
                        <Switch checked={form.values.isExt} name="toggle2nd"  onChange={handleExtChange} onBlur={form.handleBlur} color='primary' />
                </Tooltip>
                </div>
                </Grid>  
            </Grid>

            </Grid>
        </DialogContent>
        <DialogActions>
        <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end',marginRight:'2%'}}>
            <Button onClick={handleClose} color="primary" style={{textTransform:'none'}}>
                {t('cancel')}
            </Button>
            {device === undefined ? null : null}
            <Button onClick={handleTest} disabled={form.values.serviceName=='' || processingTest || form.values.isExt} color="primary" style={{textTransform:'none'}}>
            {processingTest ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                {t('test')}
            </Button>
            <SaveButton onClick={handleSave} variant="contained" color="primary" style={{textTransform:'none'}}  disabled={form.values.serviceName === ''}>
                {t('save')}
            </SaveButton>
            </Grid>
        </DialogActions>
    </Dialog>
}

export default ServiceTestSettingDialog;