import React, { useState, useEffect } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import RemoveIcon from '@material-ui/icons/Remove'
import { Grid, withStyles, TextField } from '@material-ui/core';
import moment from 'moment';


const DateTextField = withStyles({
    root: {
        width: 90,
        '& .MuiInput-underline:hover': {
            border: 0,
        },
        '& .MuiInput-underline:hover:before': {
            border: 0,
        },
        '& .MuiInput-underline:after': {
            border: 0
        },
        '& .MuiInput-underline:before': {
            border: 0
        },
        background: "white",
        borderRadius: 4,
        overflow: "hidden",
        '& input':{
            color: '#1f396d'
        }
    },
})(TextField);

function DateFormatter (date: Date, invalidLabel: string) {
    if(!date)
        return invalidLabel;
    return moment(date).format('MMM DD, YYYY')
}

type DatePickerProps = {
    startDate: string,
    endDate: string,
    minDate?: string,
    maxDate?: string
    onChange: (startDate, endDate) => void
}

export const DateRangePickerComponent: React.FC<DatePickerProps> = ({startDate, endDate, onChange, minDate, maxDate}) => {

    const [_startDate, set_startDate] = useState(null);
    const [_endDate, set_endDate] = useState<Date>(null);

    useEffect(() => {
       if(startDate && Date.parse(startDate) !== NaN) {
           set_startDate(new Date(startDate))
       } else {
          minDate && set_startDate(new Date(minDate));
       }
       if(endDate && Date.parse(endDate) !== NaN) {
           set_endDate(new Date(endDate))
       } else {
          maxDate && set_endDate(new Date(maxDate));
       }
    }, [startDate, endDate, minDate, maxDate])

    const handleStartDateChange = (date: Date) => {
        if(date > _endDate)
            onChange(date, null);
        else
            onChange(date, _endDate);
    }
 
    const handleEndDateChange = (date) => {
        onChange(_startDate, date);
    }
    
    return (
        <div>
            
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid className="dateRangePicker_container" container justify="space-around" alignItems="center" style={{background:"white", border: '1px solid #eaeef4', padding:"5px 8px 2px 8px", borderRadius: "4px"}}>
                            <DateRangeIcon style={{marginRight: "5px"}}/>
                            <DatePicker
                                disableToolbar
                                autoOk
                                variant="inline"
                                format="dd/MM/yyyy"
                                placeholder="Start Date"
                                minDate={minDate}
                                maxDate={maxDate}
                                value={_startDate}
                                labelFunc={DateFormatter}
                                onChange={handleStartDateChange}
                                TextFieldComponent={DateTextField}
                            />
                            <RemoveIcon style={{marginRight: "5px"}} />
                            <DatePicker
                                autoOk
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                placeholder="End Date"
                                minDate={minDate}
                                maxDate={maxDate}
                                value={_endDate}
                                labelFunc={DateFormatter}
                                onChange={handleEndDateChange}
                                TextFieldComponent={DateTextField}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
        </div>
    )
}

export default DateRangePickerComponent;
