import { observable, action, runInAction, computed, autorun, reaction } from "mobx";
import DashboardAgent from "../api/dashboardAgent";
import { RootStore } from "./root.store";
import { JobDeviceResultShort } from "../models/jobDevice/jobDeviceResult.reponse.model";
import { JobResultSummaryResponse } from "../models/JobResult.reponse.model";
import { JobResultQuery } from "../models/jobResultQuery.model";
import { LastJobRun } from "../models/lastjobRun.model";
import JobResultsApi from "../api/jobResult.api";
import { JobFilter } from "../common/util/job.filter";
import { JobScoreDataPoint } from "../models/JobScoreData";
import { JobSortComparator } from "../common/util/SortComparator";
import { JobScore } from "../models/dashboard/dashboard.model";
import jobAgent from "../api/jobAgent";

function settleAllPromise<T>(promises: Array<Promise<T>>): Promise<Array<{ status: 'fulfilled' | 'rejected', value?: T, err?: any }>> {
    let results = [];
    let completedRequests = 0;
    return new Promise((resolve) => {
        promises.forEach((promise, index) => {
            promise.then(val => {
                results[index] = {
                    status: 'fulfilled',
                    value: val
                }
            }).catch(err => {
                results[index] = {
                    status: 'rejected',
                    err: err
                }
            }).finally(() => {
                completedRequests++;
                if (completedRequests == promises.length)
                    resolve(results);
            })
        });
    })

}


export default class DashboardStore {


    @observable query: JobResultQuery = { category: "all", jobNames: "" };
    @observable dashboard: JobResultSummaryResponse;
    @observable dashboardDTO;
    @observable DashboardMapData;
    @observable DevicesData: Array<JobDeviceResultShort>;
    @observable scoreData: Array<Array<JobScoreDataPoint>>;
    @observable recoveryLoaded: boolean = false;
    @observable devicesLoaded: boolean = false
    @observable mapLoaded: boolean = false
    @observable jobsResultLoaded: boolean = false
    @observable emptyDash: boolean = false
    @observable jobSelectionChanged: boolean = false
    @observable createEnabled: boolean = false

    constructor(private rootStore: RootStore) {
        reaction(() => rootStore.userStore.isLoggedIn, () => {
            if (rootStore.userStore.isLoggedIn) {
                this.loadDashboard();
                this.isCreateEnabled();
            }
        })

        reaction(() => this.filteredJobResults,
            async () => {
                this.jobsResultLoaded = this.rootStore.jobResultStore._jobResults && this.rootStore.jobResultStore._jobResults.length > 0 ? true : false;
                const deviceResponse = await settleAllPromise(this.filteredJobResults.filter(j => j.id).map(j => JobResultsApi.devices(j.id, 0, 0, 'id,type,status')))

                var devices = deviceResponse.filter(d => d.status == 'fulfilled')
                    .map(r => r.value.records)
                    .reduce((acc, d) => {
                        return acc.concat(d)
                    }, [])

                runInAction(() => {
                    this.DevicesData = devices
                    this.SetWidgets();
                    this.devicesLoaded = true;
                })
            });
    }

    @action
    async isCreateEnabled(): Promise<any> {
        jobAgent.JobActions.createEnabled().then(res => {
            runInAction(() => {
                if (res)
                    this.createEnabled = true;
                else
                    this.createEnabled = false;
            })
        });
        //return this._supportedAdapters.length > 0 ? true: false;
    }


    SetWidgets() {
        this.recoveryLoaded = this.rta != null && this.rto != null;
        this.devicesLoaded = this.DevicesData && this.DevicesData.length > 0 ? true : false;
        this.jobsResultLoaded = this.rootStore.jobResultStore._jobResults && this.rootStore.jobResultStore._jobResults.length > 0 ? true : false;
        this.emptyDash = this.dashboardEmpty;
    }

    loadDashboard() {
        if (this.rootStore.jobResultStore._jobResults.length == 0) {
            this.rootStore.jobResultStore.fetch();
        }

    }

    @computed
    private get rtoSetting(): string {
        var setting = this.rootStore.settingStore.dashboardSettings.find(s => s.settingKey === 'RTO');
        return setting ? setting.settingValue : 'AVG';
    }

    @computed
    get filteredJobResults(): Array<LastJobRun> {
        const results = JobFilter.filterJobResults(this.query, this.jobResults);
        return results;
    }

    @computed
    get dashboardEmpty(): boolean {
        return this.rootStore.jobResultStore.jobsFetched && this.jobResults.length === 0;
    }

    @computed
    get jobResults(): Array<LastJobRun> {
        return this.rootStore.jobResultStore._jobResults.filter(j => j.runDate).sort(JobSortComparator.runningStatus);
    }

    @computed
    get score(): number {
        if (this.filteredJobResults.length == 0)
            return 0;
        return Math.round(this.filteredJobResults.map(x => x.score).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / this.filteredJobResults.length);
    }

    @computed
    get rta(): number {
        if (this.filteredJobResults.length == 0)
            return 0;
        if (this.rtoSetting == 'AVG')
            return Math.round(this.filteredJobResults.map(x => x.rta).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / this.filteredJobResults.length);
        else {
            var max = 0;
            this.filteredJobResults.forEach(j => {
                if (j.rta > max)
                    max = j.rta;
            })
            return max;
        }
    }


    @computed
    get rto(): number {
        if (this.filteredJobResults.length == 0)
            return 0;
        if (this.rtoSetting == 'AVG')
            return Math.round(this.filteredJobResults.map(x => x.rto).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / this.filteredJobResults.length);
        else {
            var max = 0;
            this.filteredJobResults.forEach(j => {
                if (j.rto > max)
                    max = j.rto;
            })
            return max;
        }
    }

    @computed
    get lastRunJob(): LastJobRun {
        if (this.rootStore.jobResultStore._jobResults.length == 0)
            return null;
        else
            return this.rootStore.jobResultStore._jobResults[0];  // job runs are sorted by run date
    }

    @computed
    get scheduledJobs(): Array<LastJobRun> {
        return this.rootStore.jobResultStore._jobResults.filter(j => j.nextRun);
    }
    @computed
    get nextScheduledJob(): LastJobRun {

        if (this.scheduledJobs.length == 0)
            return null;
        else
            return this.scheduledJobs.sort((j1, j2) => new Date(j1.nextRun).getTime() - new Date(j2.nextRun).getTime())[0];
    }


    @action
    setQueryParams(query: JobResultQuery) {
        this.query = this.query ? { ...this.query, ...query } : query;
    }
    @action
    setJobSelectionChanged() {
        this.jobSelectionChanged = !this.jobSelectionChanged;
    }


    @computed get jobCountByCategories(): { all: number, resilient: number, recoverable: number, atRisk: number } {
        return this.rootStore.jobResultStore.jobCountByCategories;
    }

    @computed
    get dataMovers(): Array<{ label: string, checked: boolean, count: number }> {
        let _dataMovers: Array<string> = [];
        const jobResults = this.rootStore.jobResultStore._jobResults;
        jobResults.map(j => j.dataMover).forEach(d => {
            if (_dataMovers.indexOf(d) < 0) {
                _dataMovers.push(d);
            }
        });
        return _dataMovers.sort().map(d => ({
            label: d, checked: this.query.dataMover && this.query.dataMover.indexOf(d) >= 0 ? true : false,
            count: jobResults.filter(j => j.dataMover == d).length

        }));
    }

    async getScoreData(graphSetting: string) {
        let ids = [];
        if (this.filteredJobResults) {
            this.filteredJobResults && this.filteredJobResults.map(jobResult => ids.push(jobResult.jobId));
        }
        let score = new JobScore({ limit: 5, jobIds: ids, type: graphSetting });
        return DashboardAgent.getJobScoreData(score);
        //.then(res => res.filter(r => r.status == 'fulfilled'))
        //.then(res => res.map(r => r.value));
    }

}