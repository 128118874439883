import React,{useContext, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {Chip,TextField} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { DeviceType, DEVICE_TYPE_NAME_SEPERATE_DC } from "../../app/models/deviceType.enum";
import { toast } from 'react-toastify';
import { RootStoreContext } from '../../app/stores/root.store';
import { parseSelectedDevices } from '../../app/common/util/util';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    maxWidth: 300,
    '& label': {
      background: "white",
      width: "auto",
      marginTop: "1px",
      padding:"0 4px 0 4px"
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const DevicesTransferListComponent: React.FC = () => {
  const classes = useStyles();
  const {settingStore} = useContext(RootStoreContext); 
  const devices: Array<DeviceType>= [0,1,2,3,4,5,6,7,8,9,10,11];
  const [selectedDeviceTypes, setSelectedDeviceTypes] = React.useState<Array<DeviceType>>([]);
  const {updateReportObj, reportSettings}=settingStore;

  useEffect(() => {
    const selectedDeviceSetting = reportSettings.find(s => s.settingKey == 'SelectedDevices');
    if(selectedDeviceSetting) {
     setSelectedDeviceTypes(parseSelectedDevices(selectedDeviceSetting.settingValue));
    }
  }, [reportSettings])
  const handleChange = (event) => {
      const selectedValues = event.target.value;
      if(!selectedValues)
        return;
      if(selectedValues.length <= 6) {
          setSelectedDeviceTypes(selectedValues);
          updateReportObj(selectedValues,"SelectedDevices","Report");  
      }
      else
        toast.warn("You reached the max limit for selection. please remove one of your choices in order to change.")
  };

  return (
    <div>
      <FormControl className={classes.formControl} variant='outlined'>
        <InputLabel>Select Device Types</InputLabel>
        <Select
          labelId="mutiple-device-types-label"
          multiple
          placeholder='Select Device Types'
          value={selectedDeviceTypes}
          onClick={handleChange}
          renderValue={(selectedVal) => (
              <>
                {selectedDeviceTypes.map(d => DEVICE_TYPE_NAME_SEPERATE_DC[d]).join(',')}
              </>
          )}
          MenuProps={MenuProps}
        >
          {devices.map((name) => (
            <MenuItem key={name} value={name}>
              {DEVICE_TYPE_NAME_SEPERATE_DC[name]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}


export default (observer(DevicesTransferListComponent));