export enum NoticeType{
    spinner,
    resource,
    audit
}

export enum ActivityIconType{
    nextRun,
    lastRun,
    schedule
}

export enum ActivityResultType{
    ok,
    failed,
    warning,
}