
import React, { useState, useEffect, useRef } from 'react'

import {
    Grid, Typography, makeStyles, Table, TableHead, TableBody, TableCell, TableRow,
    IconButton, Dialog, ExpansionPanelDetails, ExpansionPanelSummary, ExpansionPanel, Box, Paper, TableContainer, Tooltip
} from '@material-ui/core';
import CrossIcon from '@material-ui/icons/CancelRounded';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import CheckIcon from '@material-ui/icons/CheckCircle';
import ExpandMoreIcon from '@material-ui/icons/KeyboardArrowRight';
import clsx from 'clsx';
import { JobDeviceResult } from '../../app/models/jobDevice/jobDeviceResult.model';
import { TestStatus, TestType } from '../../app/models/jobTest.model';
import GifPlayer from '../../app/common/GifPlayer.component';
import TestStatusIcon from '../../app/common/testStatusIcon.component';
import { useTranslation } from 'react-i18next';
import { JobDeviceResultShort } from '../../app/models/jobDevice/jobDeviceResult.reponse.model';
import jobAgent from '../../app/api/jobResult.api';
import { JobDeviceResultAdapter } from '../../app/common/util/jobDevice.adapter';
import { Skeleton } from '@material-ui/lab';
import useIntersect from '../../app/common/util/useIntersect.hook';
import ServerCard from '../miniReport/serverCard.component';
import DeviceResultCache from '../../app/common/util/deviceResult.cache';
import { DeviceType, DEVICE_TYPE_NAME_SEPERATE_DC } from '../../app/models/deviceType.enum';
import JobCategoryIcon from '../../app/common/jobCategoryIcon';
import DevicePreviewComponent from '../../app/common/devicePreview.component';
import { DevicePlatform } from '../../app/models/jobProperties.model';
import { displayName } from 'react-widgets/lib/SelectList';
import { DeviceTest } from '../../app/models/createjob/createJob.model';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const useStyles = makeStyles({
    root: {
        maxWidth: 250,
        marginRight: 14,
        width: "23%"
    },
    media: {
        height: 115,
    },
    dialogRoot: {
        width: 600
    },
    dialogGif: {
        padding: 10
    },
    verticalBorder: {
        background: "lightGray",
        width: 1
    },
    vmInfoDiv: {
        background: "#ebeef4",
        padding: 5,
        margin: 10,
        height: "100%",
        textAlign: "left",
        "& > span": {
            marginRight: 10
        }
    },
    flashIcon: {
        borderRadius: "50%",
        background: "#077187",
        color: "white",
        fontSize: "1em",
        padding: "2px",
        marginRight: 5
    },
    overAllStatusItem: {
        display: "flex",
        alignItems: "center",

    },
    overAllStatuIcon: {
        marginRight: 5,
        fontSize: "1em"
    },
    failed: {
        color: "#077187",
    },
    passed: {
        color: "#8bc53f"
    },
    textOverflow: {
        maxWidth: "300px",
        display: "inline-block",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    testStatusIcon: {
        marginRight: 5
    },
    thumbnail: {
        width: "23%",
        marginBottom: "10px",
        marginLeft: "5px",
        marginRight: "5px"
    },
    expansionPanelRoot: {
        maxWidth: "100%"
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    webPortalImage: {
        display: 'block',
        width: 120,
        marginTop: 5,
        boxShadow: '1px 1px 2px 1px gray'
    },
    full: {
        justifyContent: "center"
    },
    notfull: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: '21%',
        marginTop: '4%'
    }
});

function getTestName(type: TestType): string {
    switch (type) {
        case TestType.custom: return "Script"
        case TestType.web: return "Web"
        case TestType.power: return "Health"
        case TestType.service: return "Application"
        case TestType.network: return "Network"
        case TestType.database: return "Database"
        case TestType.thirdDeviceNetwork: return "Segmentation"
    }
}

const deviceTypeIcon = {
    [DeviceType.BranchOffice]: "/assets/images/device_type/branch.png",
    [DeviceType.DCPhysical]: "/assets/images/device_type/DC.png",
    [DeviceType.DCVirtual]: "/assets/images/device_type/DC.png",
    [DeviceType.Firewall]: "/assets/images/device_type/firewall.png",
    [DeviceType.InternetConnection]: "/assets/images/device_type/network.png",
    [DeviceType.NetworkDevice]: "/assets/images/device_type/network.png",
    [DeviceType.PhysicalServer]: "/assets/images/device_type/physical.png",
    [DeviceType.VirtualMachine]: "/assets/images/device_type/vm.png",
    [DeviceType.Other]: "/assets/images/device_type/other.png",
    [DeviceType.Database]: "/assets/images/device_type/database.png",
}

const deviceTypeSmallIcon = {
    [DeviceType.BranchOffice]: "/assets/images/device_type/branch_small.png",
    [DeviceType.DCPhysical]: "/assets/images/device_type/DC_small.png",
    [DeviceType.DCVirtual]: "/assets/images/device_type/DC_small.png",
    [DeviceType.Firewall]: "/assets/images/device_type/firewall_small.png",
    [DeviceType.InternetConnection]: "/assets/images/device_type/network_small.png",
    [DeviceType.NetworkDevice]: "/assets/images/device_type/network_small.png",
    [DeviceType.PhysicalServer]: "/assets/images/device_type/physical_small.png",
    [DeviceType.VirtualMachine]: "/assets/images/device_type/vm_small.png",
    [DeviceType.Other]: "/assets/images/device_type/other_small.png",
    [DeviceType.Database]: "/assets/images/device_type/database_small.png",
}




const ServerInfoPanel = (props: { playAnimation: boolean, deviceInfo: JobDeviceResultShort,testGroups: Array<any>, view?: "list" | "thumbnail", testGroupNames:{[key: number]: string} }) => {
    const { deviceInfo } = props;
    const { testGroups } = props;
    const [dialogOpen, setDialogState] = useState(false);

    const [webPortalImageOpen, setwebPortalImageOpen] = useState(false);
    const [overallStatus, setoverallStatus] = useState<string[]>([])
    const [device, setDevice] = useState<JobDeviceResult>(DeviceResultCache.get(deviceInfo.id));
    const [deviceUserName, setdeviceUserName] = useState<string>("");
    const [devicesTestsInfo, setDevicesTestsInfo] = useState<Array<DeviceTest>>([])
    const classes = useStyles();
    const [t] = useTranslation("report");
    const [entry, setNode] = useIntersect({ rootMargin: "5px", threshold: 0.1 });

    const rootRef = useRef(null);
    const {trackEvent} = useMatomo();


    useEffect(() => {
        let isCancelled = false;
        if (entry && (entry as IntersectionObserverEntry).isIntersecting && device === null) {
            jobAgent.getDevice(deviceInfo.id).then(res => {
                const jobDeviceResult = JobDeviceResultAdapter.getObject(res.jobDeviceResult, res.deviceTests, res.deviceUserName,testGroups);
                setDevicesTestsInfo(res.deviceTests);
                setDevice(jobDeviceResult);
                DeviceResultCache.addItem(jobDeviceResult);

            })
        }

        return () => {
            isCancelled = false;
        }
    }, [deviceInfo, entry])
    useEffect(() => {
        setNode(rootRef.current);

    }, [rootRef, setNode])

    useEffect(() => {
        if (device) {
            const overallStatus = device.testCollection.sort((a, b) => a.testType - b.testType)
                .filter(t => t.testStatus === TestStatus.failed)
                .map(test => `${t("testGotIssues", { testName: getTestName(test.testType) })}`);
            if (device.autoFix) {
                setoverallStatus(overallStatus.slice(0, 2));
            } else {
                setoverallStatus(overallStatus.slice(0, 3))
            }
        }
    }, [device])

    const handleGifImageClick = (event) => {
        setDialogState(true);
        event.stopPropagation();
        trackEvent({
            category: 'Report',
            action : 'Open Boot Animation',
            name: device.name
        })
    }

    const handlePanelExpand = (event, expanded) => {
        if(expanded) {
            trackEvent({
                category: 'Report',
                action : 'Show Device details',
                name: device.name
            })
        }
    }


    const skeleton = <div ref={rootRef} style={{ width: props.view === "list" ? "100%" : "23%", margin: "5px" }}><Skeleton variant="rect" height={props.view === "list" ? 175 : 255} style={{ marginBottom: "15px" }} /></div>;


    return device == null ? skeleton : props.view == "thumbnail" ?
        <div className={classes.thumbnail}><ServerCard device={device} playAnimation={props.playAnimation} testGroupNames={props.testGroupNames} deviceOS={device.devicePlatform} /> </div> :
        <ExpansionPanel
            className='avoid-page-break'
            classes={{ root: classes.expansionPanelRoot }}
            onChange={handlePanelExpand}
            style={{ marginBottom: "15px" }}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}>
                <Grid container direction="row">
                    <Grid item xs={12} md={8} xl={5} style={{ display: "flex", justifyContent: 'space-between' }}>
                        <div style={{ display: "flex", minWidth: '99%' }}>
                            <div data-test='component-gifPlayer' style={{ width: "160px", minHeight: 125 }}>
                                <GifPlayer className="white-background"
                                    onClick={handleGifImageClick}
                                    fallbackComponent={<DevicePreviewComponent icon={deviceTypeIcon[device.type]} isOk={device.isLive} style={{ width: "160px", minHeight: 125 }} />}
                                    gifUrl={`https://${window.location.hostname}/api/devices/animation/${device.id}?imageType=small`} play={props.playAnimation} showControls={false}></GifPlayer>
                            </div>
                            <div style={{ textAlign: "left", marginLeft: "10px", overflow: "hidden", width: 'calc(100% - 170px)' }}>
                                <Tooltip title={device.name}>
                                    <span style={{ display: 'flex', alignItems: 'center', alignContent: 'center', marginLeft: '-5px' }}>
                                        <img style={{ fontWeight: 800, marginTop: '-5px' }} src={device.devicePlatform === DevicePlatform.UNIX ? "/assets/images/editDeviceIcons/linuxIcon.svg" : "/assets/images/editDeviceIcons/windowsIcon.svg"}></img>
                                        <Typography color="primary" gutterBottom variant="h6" style={{ textOverflow: "ellipsis", whiteSpace: 'nowrap', overflow: 'hidden', fontSize: '13px' }} >
                                            {device.name}
                                        </Typography>
                                    </span>
                                </Tooltip>
                                <span style={{ display: 'flex', alignContent: 'center' }}>
                                    <Typography gutterBottom variant="body1">
                                        <img style={{ marginRight: 5, width: 12, height: 12, fontWeight: 800 }} src={deviceTypeSmallIcon[device.type]}></img>
                                        {DEVICE_TYPE_NAME_SEPERATE_DC[device.type]}
                                    </Typography>
                                    {device.authenticationTest != null ? <Tooltip title={'Custom Authentication: ' + device.authenticationInfo}><VpnKeyIcon style={{ width: 15, marginLeft: 5, color: 'rgb(139, 197, 63)' }} /></Tooltip> : null}
                                </span>
                                {/* {device.hasAdvancedTest && <Typography variant="body2" color="textSecondary" component="p">
                                {t("serverTile.advanceTest")}
                            </Typography>} */}
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {t('groupName')}{`${device.testGroupName}`}
                                </Typography>
                            </div>
                        </div>
                        <Box className={classes.verticalBorder} display={{ xs: "none", md: "block" }}></Box>
                    </Grid>
                    <Grid item xs={3} md={3} xl={2} style={{ display: "flex", justifyContent: 'space-between' }}>
                        <div style={{ flexGrow: 1, textAlign: 'center' }}>
                            <Typography variant="h6">{t("devicePanel.testResults")}</Typography>
                            <div style={{ justifyContent: "center", display: 'flex' }}>
                                <div style={{ maxWidth: 82, textAlign:'left' }}>
                                    {device.tests.power && <TestStatusIcon test={device.tests.power} testInfo={''} className={classes.testStatusIcon} />}
                                    {device.tests.network && <TestStatusIcon test={device.tests.network} testInfo={device.tests.networkInfo} className={classes.testStatusIcon} />}
                                    {device.tests.service && <TestStatusIcon test={device.tests.service} testInfo={device.tests.serviceInfo} className={classes.testStatusIcon} />}
                                    {device.tests.custom && <TestStatusIcon test={device.tests.custom} testInfo={device.tests.customInfo} className={classes.testStatusIcon} />}
                                    {device.tests.database && <TestStatusIcon test={device.tests.database} testInfo={device.tests.databaseInfo} className={classes.testStatusIcon} />}
                                    {device.tests.web && <TestStatusIcon test={device.tests.web} testInfo={device.tests.webInfo} className={classes.testStatusIcon} />}
                                    {device.tests.thirdDeviceNetwork && <TestStatusIcon test={device.tests.thirdDeviceNetwork} testInfo={device.tests.thirdDeviceNetworkInfo} className={classes.testStatusIcon} />}
                                    {device.tests.PreScriptRes && <TestStatusIcon test={device.tests.PreScriptRes} testInfo={device.tests.PreScriptResInfo} className={classes.testStatusIcon} />}
                                </div>
                            </div>


                        </div>
                        <Box className={classes.verticalBorder} display={{ md: 'none', lg: 'none', xl: 'block' }}></Box>
                    </Grid>
                    <Grid item xs={8} md={8} xl={5} style={{ textAlign: "left", paddingLeft: "10px" }}>
                        <Typography variant="h6">{t("devicePanel.overallStat")}</Typography>
                        {device.autoFix && <Typography variant="body1">
                            <span className={clsx([classes.overAllStatusItem, classes.textOverflow])}>
                                <FlashOnIcon className={classes.flashIcon} /> {device.autoFixResult}
                            </span>
                        </Typography>}
                        {overallStatus.length === 0 && !device.autoFix && <Typography variant="body1">
                            <span className={clsx([classes.overAllStatusItem, classes.textOverflow])}>
                                <CheckIcon className={clsx([classes.overAllStatuIcon, classes.passed])} /> {t("allTestsPassed")}
                            </span></Typography>}
                        {overallStatus.map((status, index) =>
                            <Typography variant="body1" key={index}>
                                <span className={clsx([classes.overAllStatusItem, classes.textOverflow])}>
                                    <CrossIcon className={clsx([classes.overAllStatuIcon, classes.failed])} /> {status}
                                </span></Typography>
                        )}
                    </Grid>
                </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container direction="column">
                    <Grid container direction="row">
                        <Grid item sm={12} md={6}>
                            <Paper variant="outlined" className={classes.vmInfoDiv}>
                                <Typography variant="h6">{t("devicePanel.source")}</Typography>
                                <span>{t("devicePanel.vmName")}: {device.name}</span>
                                {/* <span>{t("devicePanel.ip")}: {"10.0.0.120"}</span> */}
                                {/* <span>{t("devicePanel.subnet")}: {"255.255.255.0"}</span> */}
                            </Paper>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <Paper variant="outlined" className={classes.vmInfoDiv}>
                                <Typography variant="h6">{t("Target")}</Typography>
                                <span>{t("devicePanel.vmName")}: {device.targetMachineName}</span>
                                {/* <span>{t("devicePanel.ip")}: {"10.0.0.120"}</span> */}
                                {/* <span>{t("devicePanel.subnet")}: {"255.255.255.0"}</span> */}
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {(device.testCollection.filter(t => t.testStatus === TestStatus.failed).length > 0 || device.autoFix || device.tests.web || device.tests.thirdDeviceNetwork) && <TableContainer>
                            <Table aria-label="Job Summary table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.noWrap}>{t("devicePanel.testTable.testName")}</TableCell>
                                        <TableCell className={classes.noWrap}>{t("devicePanel.testTable.testResults")}</TableCell>
                                        {/* <TableCell className={classes.noWrap}>{t("devicePanel.testTable.autoFix")}</TableCell> */}
                                        {/* <TableCell className={classes.noWrap}>{t("devicePanel.testTable.recommendation")}</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {device.testCollection.filter(t => t.testStatus === TestStatus.failed).map((t, index) => <TableRow key={index}>
                                        <TableCell>
                                            <span style={{ whiteSpace: "nowrap" }}>
                                                <TestStatusIcon test={t} testInfo={''} className={classes.testStatusIcon} />
                                                {getTestName(t.testType)}
                                            </span>
                                        </TableCell>
                                        <TableCell>{t.testResult}</TableCell>
                                        {/* <TableCell></TableCell> */}
                                        {/* <TableCell></TableCell> */}
                                    </TableRow>)}
                                    {device.autoFix && <TableRow>
                                        <TableCell>
                                            <span style={{ whiteSpace: "nowrap" }}>
                                                <JobCategoryIcon status={-2} variant="small" style={{ width: 15, height: 15, marginRight: 4 }} />
                                                {t("tests.autoFix")}
                                            </span>
                                        </TableCell>
                                        <TableCell>{device.autoFixResult}</TableCell>
                                        {/* <TableCell></TableCell> */}
                                        {/* <TableCell></TableCell> */}
                                    </TableRow>}
                                    {device.tests.web && <TableRow>
                                        <TableCell><span style={{ whiteSpace: "nowrap" }}>
                                            <TestStatusIcon test={device.tests.web} testInfo={device.tests.webInfo} className={classes.testStatusIcon} />
                                            {getTestName(device.tests.web.testType)}
                                        </span>
                                        </TableCell>
                                        <TableCell>{device.tests.web.testResult}
                                            {device.webPortalScreenShot && <img className={classes.webPortalImage} onClick={() => setwebPortalImageOpen(true)} src={device.webPortalScreenShot}></img>}
                                        </TableCell>
                                    </TableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
            <Dialog open={dialogOpen} onClose={() => setDialogState(false)} classes={{ paper: classes.dialogRoot }} >
                <GifPlayer className={classes.dialogGif} gifUrl={`https://${window.location.hostname}/api/devices/animation/${device.id}?imageType=large`} playOnLoad={true} onClose={() => setDialogState(false)} showControls={true}></GifPlayer>
            </Dialog>
            <Dialog open={webPortalImageOpen} onClose={() => setwebPortalImageOpen(false)} classes={{ paper: classes.dialogRoot }} >
                <img style={{ width: '100%' }} src={device.webPortalScreenShot}></img>
            </Dialog>
        </ExpansionPanel>

}

export default ServerInfoPanel;