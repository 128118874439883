import { List } from "@material-ui/core";
import { number, string } from "yup/lib/locale";
import { RootStore } from "../../stores/root.store";
import { DataMover, VeeamSolution } from "../BaseSettings.enum";
import { DeviceState, DeviceStatus, DeviceType } from "../deviceType.enum";
import { JobDevice } from "../jobDevice/jobDevice.model";
import { BootCaptureType, Credentials, DatabaseType, DevicePlatform, DnsResolveType, InfrastructureType, JobBaseSettings, JobNetworkSetting, JobReportSetting, JobScheduleSetting, JobType, LicenseType, MachineType, NetworkOption, NetworkSetting, ScheduleRepeatType, TestGroups } from "../jobProperties.model";
import { TestType } from "../jobTest.model";
import { settingObj } from "../setting.model";
import base64 from 'react-native-base64';
import { SelectedTest } from "../../../features/createJob/deviceSection/getInitalTests.hook";
import { runInThisContext } from "vm";
export interface newJobObj {
    createJobKey: string,
    createJobValue: string,
}
export const CONSTS = {
    MaskedPassword: "ThisIsAMaskedPassword!@"
}

export enum CredentialUserType {
    InfraUser = 0,
    ReplicationUser = 1,
    GeneralUser = 2,
    AppUser = 3,
    TestUser = 4,
    LinuxUser = 5,
    ESXUser = 6,
    DomainUser = 7,
    EDRCUser = 8
}

export enum PrePostScriptType {
    ControllerServer = 0,
    EDRServer = 1
}

export enum AssetType
{
    EDR = 0,
    EDRC = 1,
    EDRM = 2,
    DC = 3
}

export enum TestCategory {
    Default = 0,
    Custom =1,
    Global =2
}
export enum SRMRPState {
    Cancelling = 0, //recovery plan is in the process of cancelling
    Error = 1, //recovery plan has errors
    FailedOver = 2, //recovery plan has failed over
    NeedsCleanup = 3, //need to cleanup a test run
    NeedsFailover = 4, //need to re‐run recovery (failover)
    NeedsReprotect = 5, //need to re‐run reprotect
    NeedsRollback = 6,  //need to re‐run rollback
    Prompting = 7, //recovery plan is running, but requires user‐interaction before it may continue
    Protecting = 8, //recovery plan is protecting to remote site, run peer recovery plan on remote site
    Ready = 9, //recovery plan is not in a running state and may be run
    Running = 10 //recovery plan is currently running
}
export class InfrastructureDTO {
    id: number;
    infrastructureTypeId: number;
    sourceInfraCredentials: AccessCredentials = new AccessCredentials();
    targetInfraCredentials : AccessCredentials = new AccessCredentials();
    infrastructureSource: string;
    infrastructureTarget: string;
    infrastructureSourcePort:number = 0 ;
    infrastructureTargetPort:number = 0 ;
}
export class LicenseDTO {
    id: number;
    key?:string;
    name:string;
}
export class DataMoverDTO {
    id: number ;
    dataMoverTypeId: number;
    sourceDataMoverCredentials: AccessCredentials = new AccessCredentials();
    targetDataMoverCredentials: AccessCredentials = new AccessCredentials();
    dataMoverTarget: string;
    dataMoverSource: string;
    sourcePort: number = 0;
    targetPort: number = 0;
}
export class InfraNetworkDTO {
    id: number;
    name: string;
    type: string;
    network: string;
    infraId: number;
}
export class AssetDTO {
    id: number;
    assetName: string;
    assetVMName: string;
    assetIP:string;
    credentials: AccessCredentials = new AccessCredentials();
}
export class EDRSetting {
    name: string;
    id: number;
    IPValue:string;  
}

export class EDRCSetting {
    name: string;
    displayName:string;
    EDRCcredentials: Credentials;
    id: number;
    IPValue:string;
}

export class DomainControllerSetting {
    name: string;
    source:string; 
    datastore: string;
    target: string;
    DCCredentials:{userName:string,password:string};
    ESXcredentials:{userName:string,password:string};
    domainName:string;
    targetVlan:string;
    id:number;
}

export class AccessCredentials {
    id: number;
    userName: string ='';
    password: string = '';
    userType: CredentialUserType = CredentialUserType.AppUser;
}
class EmailSettingDTO {
    address:string;
    port: number;
    emailFrom: string;
    username: string ='';
    password: string = '';
}
export class PrePostScriptsDTO {
    id?: number;
    ttl: number;
    type: PrePostScriptType;
    scriptName:string='';
    name: string ='';
    data: string = null;
}
class ScriptDTO 
{
    name: string ='';
    data: string = null;
}
export class DomainControllerDTO {
    id: number;
    domainControllerName: string;
    domainControllerESXCredentialsId: number
    domainControllerESXCredentials: AccessCredentials = new AccessCredentials()
    domainCredentialsId: number
    domainCredentials: AccessCredentials = new AccessCredentials()
    domainControllerTargetVLAN:string;
    dcHostServerSource:string;
    dcHostServerTarget:string;
    dcDataStoreTarget:string;
    domainName:string;
}
class TestGroupsDTO {
    id: number;
    groups: string;
}

export interface GlobalTest {
    testType:string;
    enabled:boolean;
}
export interface DeviceTest {
    id?: string; 
    testCategory: TestCategory;
    testType: TestType,
    selected?: boolean
    serviceToTest: string;
    testFromEDRC: boolean;
    customCommandToTest: string;
    customCommandExpectedResult: string; 
    customCommandToTestIsExternal: boolean; 
    webPortalToTest: string;
    webPortalResult: string;
    databaseType:DatabaseType;
    databaseServerNameToTest:string;
    databaseNameToTest:string;
    databaseQueryToTest:string;
    databaseUsername:string;
    databasePassword:string;
    databasePort:string;
    dbWindowsAuth:boolean;
    script:string;
    thirdDeviceNetworkToTest:string;
    authenticationUserToTest:string;
    authenticationPassToTest:string;
}
interface Device {
    id?: number;
    deviceName : string;
    deviceType: DeviceType;
    deviceIP: string;
    targetIP: string;
    deviceGroup: string;
    tests:Array<DeviceTest> ;
    scriptData:string;
    scriptName:string;
    customCommandData:string;
    customCommandName:string;
    deviceUser?: string;
    devicePassword?: string;
    sourceName?: string;
    targetName?: string;
    sourceMask?: string;
    targetMask?: string;
    targetHostName?: string;
    targetNetworkName?: string;
    devicePlatform: DevicePlatform;
    guid: string;
    deviceCredentials?: AccessCredentials;
    inheritDefaultTests:boolean;
    performSecondRestart: boolean;
    showDeviceAsDC:boolean;
    newtworkLocation:string;
    deviceStatus?: DeviceStatus;
    deviceState?: DeviceState;
    fetchFromInfra?: boolean;
    planBootOrder?: number;
}
export interface Plan {
    id?: number;
    name: string;
    srmrpState: SRMRPState;
    planBootOrder: number;
    devices: Array<Device>
}
export class License {
    id:number;
    name: string;
    startDate: Date;
    experationDate: Date;
    licenseType: LicenseType;
    version:number;
    companyName:string;
    numberOfServer:number;

}
export class Infrastructure {
    id:number;
    name:string;
}

export class WebPortalTestProperties {
    url:string;
    result: string;
}

export class DatabaseTestProperties {
    type :string;
    server :string;
    database:string;
    query :string;
    username:string;
    password:string;
}
export class NetworkTuppleDTO {
    id: number;
    vmNetworkId: number;
    vmNetworkName: string;
    drNetworkId: number;
    drNetworkName: string;
    infrastructureId: number;
}
export class CreateJobProperties {
    id: number;
    runJob:boolean;
    jobId: number;
    jobName: string;
    dataMoverType:DataMover;
    veeamSolution:VeeamSolution;
    infrastructureType:InfrastructureType;
    rto:number;
    powerOnTime:number;
    parallelTestExecution:number;
    maxTestTimeout:number;
    timeEngineFinish:number;
    failoverTimeout:number;
    testHeartbeat:boolean;
    fetchByDefault:boolean;
    jobType: JobType;
    jobDescription:string;
    licenseId: number;
    preTestEnabled: boolean = true;
    failOverEnabled: boolean = true;
    serverTestEnabled: boolean = true;
    snapshotEnabled: boolean = false;
    cleanUpEnabled: boolean = true;
    prePostScripts: Array<PrePostScriptsDTO>= [];
    accessCredentialsId: number;
    linuxCredentialsId: number;
    infrastructureId: number;
    dataMoverId: number;
    edrServerId: number = 0;
    edrControllerServerId: number = 0;
    domainControllerId: number = 0;
    allowInbubble:boolean;
    allowOutbubble:boolean;
    dnsResolve: DnsResolveType= DnsResolveType.None;
    additionalIPs: Array<string>=[];
    additionalDNSs: Array<string>=[];
    additionalDGs: Array<string>=[];
    inPort: number;
    outPort: number;
    liveDRNetworkName: string;
    liveDRNetworkSegment: string;
    liveNetworkName: string;
    liveNetworkSegment: string;
    testDRNetworkName: string;
    testDRNetworkSegment: string;
    plans: Array<Plan> = [];
    hiddenDevice :Array<Plan> =[];
    emailTo: string = '';
    sendMail: boolean = true;
    EmailSetting: EmailSettingDTO = new EmailSettingDTO();
    phoneNumberForSMS: string = '';
    sendSMS: boolean = false;
    bootCaptureType: BootCaptureType = BootCaptureType.Animation;
    bootTime: number;
    nextRun: string;
    setSchedule:boolean;
    repeatInterval: number;
    scheduleRepeatType: ScheduleRepeatType = ScheduleRepeatType.Week;
    testGroups: TestGroupsDTO = new TestGroupsDTO();
    //eDRCCredentials: AccessCredentials = new AccessCredentials();
    //ESXCredentials: AccessCredentials = new AccessCredentials();
    //DCCredentials : AccessCredentials = new AccessCredentials();
    DCDomainName :string;
    edrcDispalyName:string;
    IsCollectDRResourcesActive: boolean;
    IsICTestActive: boolean;
    networkType:NetworkOption = NetworkOption.Single;
    vra: string;
    veeamApiPort: number;
    networkTuppleId:number;
    
    
    addRunJob(runJob:boolean)
    {
        this.runJob=runJob;
        return this;
    }

    addBaseSettings(baseSettings: JobBaseSettings, networkSettings:JobNetworkSetting): CreateJobProperties {
        this.jobName= baseSettings.jobName;
        this.jobDescription = "";
        this.licenseId =  baseSettings.licenseId;
        this.infrastructureId = baseSettings.infrastructureId;
        this.dataMoverId = baseSettings.dataMoverId;
        this.infrastructureType = baseSettings.infrastructureType;
        this.dataMoverType = baseSettings.dataMover;
        this.jobType = baseSettings.jobType;
        this.rto = baseSettings.rto;
        this.powerOnTime = baseSettings.powerOnTime;
        this.parallelTestExecution = baseSettings.parallelTestExecution;
        this.maxTestTimeout = baseSettings.maxTestTimeout;
        this.timeEngineFinish = baseSettings.timeEngineFinish;
        this.failoverTimeout = baseSettings.failoverTimeout;
        this.testHeartbeat = baseSettings.testHeartbeat;
        this.fetchByDefault = baseSettings.fetchByDefault;
        this.rto = baseSettings.rto;
        this.edrServerId =  networkSettings.EDRServer != null && networkSettings.EDRServer.id != null ? networkSettings.EDRServer.id : 0;
        this.accessCredentialsId = baseSettings.workflowTest.PrimaryUserCredsId;
        this.linuxCredentialsId = baseSettings.workflowTest.SecondaryUserCredsId;
        this.addWorkflowSetting(baseSettings);
        this.addPrePostSetting(baseSettings);
        this.vra = baseSettings.vra;
        this.networkTuppleId = baseSettings.networkTuppleId;
        this.veeamApiPort = baseSettings.veeamApiPort;
        this.veeamSolution = baseSettings.veeamSolution;
        return this;
    }
    addGeneralSettings(testGroups: TestGroups,ICActive:boolean,DRActive:boolean): CreateJobProperties {
        this.testGroups.groups = JSON.stringify(testGroups.groups);
        this.testGroups.id = testGroups.id;
        this.IsCollectDRResourcesActive = DRActive;
        this.IsICTestActive = ICActive;
        return this;
    }

    private addWorkflowSetting(baseSettings: JobBaseSettings) {
        this.preTestEnabled = baseSettings.workflowSetting.preTest;
        this.snapshotEnabled = baseSettings.workflowSetting.snapshot;
        this.serverTestEnabled = baseSettings.workflowSetting.serverTest;
        this.failOverEnabled = baseSettings.workflowSetting.failover;
        this.cleanUpEnabled = baseSettings.workflowSetting.cleanup;
    }

    private addPrePostSetting(baseSettings:JobBaseSettings) {
        const preFailover= new PrePostScriptsDTO();
        const postFailover  = new PrePostScriptsDTO();
        const preTest= new PrePostScriptsDTO();
        const postTest =new PrePostScriptsDTO();
        const preCleanup = new PrePostScriptsDTO();
        const postCleanup = new PrePostScriptsDTO();
        if(baseSettings.scriptsSettings.failoverScriptSetting.prePath!='')
        {
            preFailover.scriptName="prefailover";
            preFailover.id = baseSettings.scriptsSettings.failoverScriptSetting.preId;
            preFailover.name=baseSettings.scriptsSettings.failoverScriptSetting.prePath;
            preFailover.data=baseSettings.scriptsSettings.failoverScriptSetting.prePathData;
            preFailover.ttl=Number(baseSettings.scriptsSettings.failoverScriptSetting.preTTL);
            preFailover.type=PrePostScriptType.EDRServer;
        }
        if(baseSettings.scriptsSettings.failoverScriptSetting.postPath!='')
        {
            postFailover.scriptName="postfailover";
            postFailover.id = baseSettings.scriptsSettings.failoverScriptSetting.postId;
            postFailover.name=baseSettings.scriptsSettings.failoverScriptSetting.postPath;
            postFailover.data=baseSettings.scriptsSettings.failoverScriptSetting.postPathData;
            postFailover.ttl=Number(baseSettings.scriptsSettings.failoverScriptSetting.postTTL);
            postFailover.type=PrePostScriptType.EDRServer;
        }
        if(baseSettings.scriptsSettings.TestScriptSetting.prePath!='')
        {
            preTest.scriptName="pretest";
            preTest.id = baseSettings.scriptsSettings.TestScriptSetting.preId;
            preTest.name=baseSettings.scriptsSettings.TestScriptSetting.prePath;
            preTest.data=baseSettings.scriptsSettings.TestScriptSetting.prePathData;
            preTest.ttl=Number(baseSettings.scriptsSettings.TestScriptSetting.preTTL);
            preTest.type=PrePostScriptType.ControllerServer;
        }
        if(baseSettings.scriptsSettings.TestScriptSetting.postPath!='')
        {
            postTest.scriptName="posttest";
            postTest.id = baseSettings.scriptsSettings.TestScriptSetting.postId;
            postTest.name=baseSettings.scriptsSettings.TestScriptSetting.postPath;
            postTest.data=baseSettings.scriptsSettings.TestScriptSetting.postPathData;
            postTest.ttl=Number(baseSettings.scriptsSettings.TestScriptSetting.postTTL);
            postTest.type=PrePostScriptType.ControllerServer;
        }
        if(baseSettings.scriptsSettings.cleanupScriptSetting.prePath!='')
        {
            preCleanup.scriptName="precleanup";
            preCleanup.id = baseSettings.scriptsSettings.cleanupScriptSetting.preId;
            preCleanup.name=baseSettings.scriptsSettings.cleanupScriptSetting.prePath;
            preCleanup.data=baseSettings.scriptsSettings.cleanupScriptSetting.prePathData;
            preCleanup.ttl=Number(baseSettings.scriptsSettings.cleanupScriptSetting.preTTL);
            preCleanup.type=PrePostScriptType.EDRServer;
        }
        if(baseSettings.scriptsSettings.cleanupScriptSetting.postPath!='')
        {
            postCleanup.scriptName="postcleanup";
            postCleanup.id = baseSettings.scriptsSettings.cleanupScriptSetting.postId;
            postCleanup.name=baseSettings.scriptsSettings.cleanupScriptSetting.postPath;
            postCleanup.data=baseSettings.scriptsSettings.cleanupScriptSetting.postPathData;
            postCleanup.ttl=Number(baseSettings.scriptsSettings.cleanupScriptSetting.postTTL);
            postCleanup.type=PrePostScriptType.EDRServer;
        }
        const prepostObjects=[preFailover,postFailover,preTest,postTest,preCleanup,postCleanup];
        const list=[];
        prepostObjects.map((script) => script.name !='' ? list.push(script):null);
        this.prePostScripts= list;
    }

    addDevices(devices: Array<JobDevice>,plans:Array<Plan>,isEdit:boolean) : CreateJobProperties{
        const obj : {[key: string]: Array<Device>} = {};
        const planOrder: {[key: string]: number} = {};

        devices.forEach(device => {
            if(!obj[device.planName]) 
                obj[device.planName] = [];
        planOrder[device.planName] = device.planBootOrder;
        let selectedTests= device.tests.filter(t => t.selected);
        if(isEdit)
        {
            obj[device.planName].push({
                id:device.id,
                deviceName: device.deviceName,
                sourceName: device.sourceName,
                deviceType: device.deviceType,
                deviceIP: device.deviceIP,
                deviceGroup: device.deviceGroup,
                targetIP: device.targetIP,
                performSecondRestart: device.performSecondRestart,
                showDeviceAsDC: device.showDeviceAsDC,
                devicePlatform: device.os,
                guid: device.guid,
                // need to remove selected property from test objects
                tests: selectedTests.map(t => ({
                    id:t.id,
                    testCategory: t.testCategory, 
                    testType: t.testType, 
                    selected:t.selected,
                    customCommandToTest: t.customCommandToTest, 
                    customCommandExpectedResult: t.customCommandExpectedResult, 
                    customCommandToTestIsExternal: t.customCommandToTestIsExternal,
                    databaseType: t.databaseType,
                    databaseNameToTest: t.databaseNameToTest,
                    databaseQueryToTest: t.databaseQueryToTest,
                    databaseServerNameToTest: t.databaseServerNameToTest,
                    databaseUsername: t.databaseUsername,
                    databasePassword: t.databasePassword,
                    databasePort:t.databasePort,
                    dbWindowsAuth:t.dbWindowsAuth,
                    serviceToTest: t.serviceToTest,
                    testFromEDRC: t.testFromEDRC,
                    thirdDeviceNetworkToTest: t.thirdDeviceNetworkToTest,
                    authenticationUserToTest:device.deviceUser,
                    authenticationPassToTest:device.devicePassword,
                    script:device.scriptName,
                    webPortalToTest: t.webPortalToTest,
                    webPortalResult: t.webPortalResult,
                })),
                deviceCredentials: {id: 0,userName: device.tests.filter(t => t.testType == TestType.authentication)[0] ? device.tests.filter(t => t.testType == TestType.authentication)[0].authenticationUserToTest : '',password:device.tests.filter(t => t.testType == TestType.authentication)[0] ? device.tests.filter(t => t.testType == TestType.authentication)[0].authenticationPassToTest : '', userType: CredentialUserType.TestUser},
                //deviceUser: device.tests.filter(t => t.testType == TestType.authentication)[0] ? device.tests.filter(t => t.testType == TestType.authentication)[0].authenticationUserToTest : '',
                //devicePassword: device.tests.filter(t => t.testType == TestType.authentication)[0] ? device.tests.filter(t => t.testType == TestType.authentication)[0].authenticationPassToTest : '',
                inheritDefaultTests:device.inheritDefaultTests,
                newtworkLocation:device.newtworkLocation,
                customCommandData : device.customCommandData,
                customCommandName :device.customCommandName,
                scriptData : device.scriptData,
                scriptName: selectedTests.find(t => t.testType === TestType.PreScriptRes) ? device.scriptName: ''
        })
        }
        else
        {
            obj[device.planName].push({
                deviceName: device.deviceName,
                sourceName: device.sourceName,
                deviceType: device.deviceType,
                deviceIP: device.deviceIP,
                deviceGroup: device.deviceGroup,
                targetIP: device.targetIP,
                performSecondRestart: device.performSecondRestart,
                showDeviceAsDC: device.showDeviceAsDC,
                devicePlatform: device.os,
                guid: device.guid,
                // need to remove selected property from test objects
                tests: selectedTests.map(t => ({
                    testCategory: t.testCategory, 
                    testType: t.testType, 
                    selected:t.selected,
                    customCommandToTest: t.customCommandToTest, 
                    customCommandExpectedResult: t.customCommandExpectedResult, 
                    customCommandToTestIsExternal: t.customCommandToTestIsExternal,
                    databaseType: t.databaseType,
                    databaseNameToTest: t.databaseNameToTest,
                    databaseQueryToTest: t.databaseQueryToTest,
                    databaseServerNameToTest: t.databaseServerNameToTest,
                    databaseUsername: t.databaseUsername,
                    databasePassword: t.databasePassword,
                    databasePort:t.databasePort,
                    dbWindowsAuth:t.dbWindowsAuth,
                    serviceToTest: t.serviceToTest,
                    testFromEDRC: t.testFromEDRC,
                    thirdDeviceNetworkToTest: t.thirdDeviceNetworkToTest,
                    script:device.scriptName,
                    authenticationUserToTest:device.deviceUser,
                    authenticationPassToTest:device.devicePassword,
                    webPortalToTest: t.webPortalToTest,
                    webPortalResult: t.webPortalResult,
                })),
                deviceCredentials: {id: 0,userName: device.tests.filter(t => t.testType == TestType.authentication)[0] ? device.tests.filter(t => t.testType == TestType.authentication)[0].authenticationUserToTest : '',password:device.tests.filter(t => t.testType == TestType.authentication)[0] ? device.tests.filter(t => t.testType == TestType.authentication)[0].authenticationPassToTest : '', userType: CredentialUserType.TestUser},
                //deviceUser: device.tests.filter(t => t.testType == TestType.authentication)[0] ? device.tests.filter(t => t.testType == TestType.authentication)[0].authenticationUserToTest : '',
                //devicePassword: device.tests.filter(t => t.testType == TestType.authentication)[0] ? device.tests.filter(t => t.testType == TestType.authentication)[0].authenticationPassToTest : '',
                inheritDefaultTests:device.inheritDefaultTests,
                newtworkLocation:device.newtworkLocation,
                customCommandData : device.customCommandData,
                customCommandName :device.customCommandName,
                scriptData : device.scriptData,
                scriptName: selectedTests.find(t => t.testType === TestType.PreScriptRes) ? device.scriptName: '' 
        })
        }
        });
        if(isEdit)
        this.plans =  Object.entries(obj).map(([planName, devices]) => {
            const plan = plans.find(plan=>plan.name=== planName)
            return {
                    id: plan ? plan.id: 0,
                    name:planName,
                    srmrpState: plan && plan.srmrpState ? plan.srmrpState : SRMRPState.Ready,
                    planBootOrder: planOrder[planName]??0,
                    devices
                }
            });
        else
        {
            this.plans =  Object.entries(obj).map(([planName, devices],idx) => {
                return {
                        name:planName,
                        srmrpState: null,
                        planBootOrder:planOrder[planName]??0,
                        devices
                    }
                });
        }
        return this;
    }

    addScheduleSettings(setting: JobScheduleSetting ): CreateJobProperties {
        const {repeatInterval,scheduleRepeatType,nextRun,setSchedule,setRepeat}=setting;
        setRepeat ? this.repeatInterval=repeatInterval: this.repeatInterval= 0;
        setSchedule ? this.nextRun=nextRun : this.nextRun="";
        this.setSchedule = setSchedule;
        this.scheduleRepeatType=scheduleRepeatType;
        return this;
    }

    addReportSettings(setting: JobReportSetting,emailSetting:Array<settingObj>): CreateJobProperties {
        const {emailTo, sendEmail, sendSMS, phoneNumbersForSMS,bootCaptureType,bootTime} = setting;
        const customEmailSettingItems= emailSetting.filter(s=> s.settingSection=="CustomEmail");
        this.emailTo = emailTo!='undefined' && emailTo != null && emailTo ? emailTo : '';
        this.sendMail = sendEmail;
        this.sendSMS = sendSMS;
        this.phoneNumberForSMS= phoneNumbersForSMS;
        this.bootCaptureType= bootCaptureType;
        this.bootTime= bootCaptureType == 0 ? bootTime: 0;
        this.EmailSetting.address= customEmailSettingItems["LocalSMTPAddress"];
        this.EmailSetting.port= customEmailSettingItems["LocalSMTPPort"];
        this.EmailSetting.emailFrom= customEmailSettingItems["EmailFrom"];
        this.EmailSetting.username= customEmailSettingItems["SMTPUser"];
        this.EmailSetting.password= customEmailSettingItems["SMTPPassword"];
        return this;
    }

    addNetworkInfo(baseSettings:JobBaseSettings,setting: JobNetworkSetting): CreateJobProperties {
        const {EnsureDRController,NetworkType,dnsResolve,domainController,inPort,outPort,liveDRNetwork,testDRNetwork,additionalDefaultGateway,additionalEdrcDns,additionalEdrcIp,EDRServer} = setting;
        const {jobType}= baseSettings;
        const [mapping] = setting.testDRNetwork.networkMapping;
        if(mapping) {
            this.testDRNetworkName = mapping.targetNetwork;
            this.liveDRNetworkName = mapping.sourceNetwork;
        }
        this.allowInbubble= jobType==JobType.Test ? testDRNetwork.allowInbubble : liveDRNetwork.allowInbubble;
        this.allowOutbubble=jobType==JobType.Test ? testDRNetwork.allowOutbubble : liveDRNetwork.allowOutbubble;
        this.edrControllerServerId=setting.NetworkType === NetworkOption.None ? 0 : (EnsureDRController != null && EnsureDRController.id != 0 ? Number(EnsureDRController.id) : 0);
        this.edrServerId=EDRServer != null && EDRServer.id != 0 && EDRServer.id != null ? Number(EDRServer.id) : 0;
        this.edrcDispalyName = EnsureDRController != null ? EnsureDRController.assetName : '';
        //this.eDRCCredentials.userName = EnsureDRController.credentials && EnsureDRController.credentials.userName;
        //this.eDRCCredentials.password = EnsureDRController.credentials && EnsureDRController.credentials.password;
        this.dnsResolve=dnsResolve;
        this.additionalIPs=additionalEdrcIp; 
        this.additionalDNSs=additionalEdrcDns;
        this.additionalDGs=additionalDefaultGateway;
        this.inPort=inPort;
        this.outPort=outPort;
        this.domainControllerId = setting.NetworkType === NetworkOption.None ? 0 : (domainController!=null && domainController.id != null ? domainController.id: 0);
        this.DCDomainName= domainController!=null && domainController.domainName!=null ? domainController.domainName:'';
        this.networkType = NetworkType;
        return this;
    }
} 