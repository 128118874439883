import React from 'react'
import { Button } from '@material-ui/core';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { useTranslation } from 'react-i18next';

export const PlayPauseButton: React.FC<{ isPlaying: boolean, onChange: (isPlaying: boolean) => void, color?: string}> = (props) => {
    const [t] = useTranslation("report");
    return (
        <div style={{minWidth: 88}}>
            {props.isPlaying ? <Button style={{ color: props.color || "#2892d7" }}
                onClick={() => props.onChange(false)}
                startIcon={<PauseIcon />}>
                {t("button.pause")}
            </Button> : <Button
                style={{ color: props.color || "#2892d7"}}
                onClick={() => props.onChange(true)}
                startIcon={<PlayIcon />}>
                    {t("button.play")}
                </Button>}
        </div>
    )
}

