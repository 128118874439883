import { JobRun, JobRunState } from "../models/jobRun.model";
import { observable, action, runInAction, reaction, computed } from "mobx";
import JobRunAgent from "../api/jobRunAgent";
import { RootStore } from "./root.store";
import { TimeSpan } from "../models/timespan.model";
import { jobRunWithAssetInfo } from "../models/jobRunWithAssetInfo.model";
import { UpdateType } from "./activityStore";
import moment from "moment";
import { JobRunType } from "../models/BaseSettings.enum";


export class JobRunStore {
    @observable
    jobRuns: Array<JobRun> = [];
    private firstFetch = true;
    private timeoutPeriod: number = 5 * 1000;
    private timer: any;
    
    @observable private _jobRunType : JobRunType;
  

    constructor(private rootStore: RootStore) {
        const { userStore } = rootStore;
        reaction(() => userStore.isLoggedIn, () => {
            if (userStore.isLoggedIn) {
                (() => {
                    const fetchData = async () => {
                        await this.fetchRunningJobs();
                        this.timer = setTimeout(fetchData, this.timeoutPeriod);
                    }
                    fetchData();
                })();
            }
            else
                clearInterval(this.timer);
        });
       
        // this.timer= setInterval(() => { this.fetchRunningJobs() }, this.timeoutPeriod);
        // reaction(() => rootStore.activityStore.newUpdates,
        //     () => {
        //         if (rootStore.activityStore.newUpdates)
        //             this.fetchRunningJobs();
        //     }
        // )
    }

    @action setJobRunType = (type: JobRunType) => {
        this._jobRunType = type;
    }
    
    @computed
    get jobRunType(): JobRunType {
        return this._jobRunType;
    }

    private hasJobRunDataChanged(prev: Array<JobRun>, current: Array<JobRun>): boolean {
        if (prev.length != current.length)
            return true;
        const prevRunningJobIds = prev.map(j => j.jobId);
        const currentRunningJobIds = current.map(j => j.jobId);
        prevRunningJobIds.sort();
        currentRunningJobIds.sort();
        if (prevRunningJobIds.join('').localeCompare(currentRunningJobIds.join('')) != 0)
            return true;
        else
            return false;

    }

    @action
    fetchRunningJobs() {
        Promise.all([JobRunAgent.getJobRun(JobRunState.Running), JobRunAgent.getJobRun(JobRunState.WriteInProcess)]).then(([runningJobs, writePendingJobs]) => {
            runInAction(() => {
                const fetchedJobRuns = [...runningJobs.records, ...writePendingJobs.records];
                if (!this.firstFetch) {
                    if (this.hasJobRunDataChanged(this.jobRuns, fetchedJobRuns))
                        this.rootStore.activityStore.sendMessage(UpdateType.JobRunUpdate);
                } 
                this.jobRuns = fetchedJobRuns;
                this.firstFetch = false;
            })
        }).catch(err => { })
    }

    getJobRun(jobId: number): JobRun {
        return computed(() => this.jobRuns.find(j => j.jobId == jobId)).get();
    }

    isJobRunning(jobId: number): boolean {
        return computed(() => {
            const jobRun = this.getJobRun(jobId);
            if (jobRun == null) {
                return false;
            }
            return true;
        }).get();
    }

    @action updateJobRunState(jobRun: jobRunWithAssetInfo) {
        this.jobRuns = this.jobRuns.map(j => j.jobId == jobRun.jobId ? jobRun : j);
    }

    @action getStartTime(jobId: number) {
        return computed(() => {
            const jobRun = this.getJobRun(jobId);
            if (jobRun == null) {
                return null;
            }
            return moment(jobRun.runDate).format('MM/DD/YYYY HH:mm:ss');
        }).get();
    }

    @action addJobRun(jobId: number) {
        const zeroTimeSpan: TimeSpan = {
            days: 0,
            hours: 0,
            milliseconds: 0,
            minutes: 0,
            seconds: 0,
            ticks: 0,
            totalDays: 0,
            totalHours: 0,
            totalMilliseconds: 0,
            totalMinutes: 0,
            totalSeconds: 0,
        }
        const jobRun: JobRun = {
            id: 0,
            jobId: jobId,
            preTestTime: '00:00:00.00000',
            serverTestTime: '00:00:00.00000',
            failoverTime: '00:00:00.00000',
            cleanUpTime: '00:00:00.00000',
            snapshotTime: '00:00:00.00000',
            jobName: '',
            currentRunState: 0,
            currentRunSubState: 0,
            jobRunProgressInfos: [{ id: 0, progressInfo: 'starting' }],
            jobRunType: JobRunType.All
        };
        this.jobRuns.push(jobRun);
    }

    @action removeJobRun(jobId: number) {
        this.jobRuns = this.jobRuns.filter(j => j.jobId !== jobId);
    }
}