import React, {CSSProperties} from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface DevicePreviewProps {
    icon: string;
    style?: CSSProperties;
    className?: string;
    isOk?: boolean
}

const useStyles= makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#e0e0e0'
    },
    status: {
        position: 'absolute',
        right: 0,
        bottom: 0
    },
    statusSpan: {
        background: '#1f396d',
        color: 'white',
        fontSize: 12
    },
    dot: {
        
        borderRadius: '50%',
        width: 6,
        height: 6,
        display: 'inline-block',
        marginRight: 5,
        marginLeft: 5
    },
    failed: {
        background: 'red'
    
    },
    passed: {
        background: '#8bc53f'
    }
})

const DevicePreviewComponent : React.FC<DevicePreviewProps> = ({icon, style, className, isOk }) => {
    const classes = useStyles();
    return <div className={clsx([classes.root, className])} style={style}>
        <img src={icon}></img>
        <div className={classes.status}>
            {isOk && <span className={classes.statusSpan}><i className={clsx(classes.dot, classes.passed)}/>Live</span>}
            {!isOk && <span className={classes.statusSpan}> <i className={clsx(classes.dot, classes.failed)} />Off</span>}
        </div>
    </div>
}

export default DevicePreviewComponent;