import React, { useRef, useState, useEffect, ReactElement } from 'react'
import clsx from 'clsx';
import { Slider } from '@material-ui/core';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { decompressFrames, parseGIF } from './util/gifParser';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

type GifPlayerProps = {
    gifUrl: string,
    showControls?: boolean,
    onClose?: () => void,
    play?: boolean,
    playOnLoad?: boolean
    className?: string,
    style?: CSSProperties,
    fallbackComponent?: ReactElement,
    onClick?: (event?) => void
}

const GifPlayer = ({ gifUrl, onClose, showControls, play, className, style, fallbackComponent, playOnLoad, onClick }: GifPlayerProps) => {
    const ref = useRef(null);
    const reqRef = useRef(null);
    const gifplayerRef = useRef<HTMLDivElement>(null);
    const [isPlaying, setPlayState] = useState(playOnLoad);
    const [isLoading, setIsLoading] = useState(false);
    const [slidervalue, setSlidervalue] = useState(0);
    const [sliderMaxValue, setSliderMaxValue] = useState(100);
    const [imageExist, setImageExist] = useState(false);
    // gif patch canvas
    var tempCanvas = document.createElement('canvas')
    var tempCtx = tempCanvas.getContext('2d')
    const [loadedFrames, setloadedFrames] = useState<Array<any>>(null);
    const frameIndex = useRef(0);
    const [timeOutId, settimeOutId] = useState(null);

    useEffect(() => {

        if (gifUrl) {
            setIsLoading(true);
            loadGIF(gifUrl)
                .then(frames => {
                    if (frames.length) {
                        setImageExist(true);
                        setloadedFrames(frames)
                    }
                }).catch(err => {
                    setImageExist(false);
                }).finally(() => setIsLoading(false));
        }

    }, [gifUrl, ref])

    useEffect(() => {
       return ()=>{
            clearTimeout(timeOutId);
            reqRef.current && cancelAnimationFrame(reqRef.current);
        }
    }, [])
    useEffect(() => {

        loadedFrames && renderGIF(loadedFrames);
    }, [loadedFrames])

    useEffect(() => {
        if (loadedFrames && loadedFrames.length && ref.current) {
            renderGIF(loadedFrames);
        }
    }, [ref])

    useEffect(() => {
        clearTimeout(timeOutId);
        reqRef.current && cancelAnimationFrame(reqRef.current);
        if (isPlaying || play) {
            loadedFrames && renderGIF(loadedFrames);
        } 
    }, [isPlaying, play])


    function loadGIF(url): Promise<Array<any>> {
        return new Promise((res, rej) => {
            var oReq = new XMLHttpRequest()
            oReq.open('GET', url, true)
            oReq.responseType = 'arraybuffer'

            oReq.onload = function (oEvent) {
                var arrayBuffer = oReq.response // Note: not oReq.responseText
                if (arrayBuffer) {
                    var gif = parseGIF(arrayBuffer)
                    var frames = decompressFrames(gif, true)
                    res(frames)
                }
            }
            oReq.onerror = function (event) {
                rej(event);
            }

            oReq.send(null)
        });
    }
    var frameImageData
    function drawPatch(frame) {
        var dims = frame.dims

        if (
            !frameImageData ||
            dims.width != frameImageData.width ||
            dims.height != frameImageData.height
        ) {
            tempCanvas.width = dims.width
            tempCanvas.height = dims.height
            frameImageData = tempCtx.createImageData(dims.width, dims.height)
        }

        // set the patch data as an override
        frameImageData.data.set(frame.patch)

        // draw the patch back over the canvas
        tempCtx.putImageData(frameImageData, 0, 0)
        var canvas = ref.current as HTMLCanvasElement;
        var gifCtx = canvas.getContext("2d");
        gifCtx.drawImage(tempCanvas, dims.left, dims.top)
    }

    const renderGIF = (frames: Array<any>) => {
        if (frames.length == 0) {
            return;
        }
        setSliderMaxValue(frames.length);
        frameIndex.current = 0
        var canvas = ref.current as HTMLCanvasElement;
        var gifCtx = canvas.getContext("2d");
        canvas.width = frames[0].dims.width
        canvas.height = frames[0].dims.height

        tempCanvas.width = canvas.width
        tempCanvas.height = canvas.height

        renderFrame();
    }


    const renderFrame = () => {
        // get the frame
        var frame = loadedFrames[frameIndex.current]

        var start = new Date().getTime();

        var canvas = ref.current as HTMLCanvasElement;
        if (canvas) {

            var gifCtx = canvas.getContext("2d");
            gifCtx.clearRect(0, 0, canvas.width, canvas.height)

            // draw the patch
            drawPatch(frame)
            // update the frame index
            frameIndex.current++
            if (frameIndex.current >= loadedFrames.length) {
                frameIndex.current = 0
            }

            var end = new Date().getTime()
            var diff = end - start

        }

        // delay the next gif frame
        if(isPlaying || play) {
            settimeOutId(setTimeout(() => {
                if (isPlaying || play) {
                    reqRef.current = requestAnimationFrame(renderFrame)
                } else {
                    reqRef.current && cancelAnimationFrame(reqRef.current);
                }
                //renderFrame();
            }, Math.max(0, Math.floor(frame.delay - diff))))
        }

    }

    var seekGifPlayer = (frame: number) => {
        frameIndex.current = frame;
        setSlidervalue(frame);
    }

    var togglePlayState = () => {
        setPlayState(!isPlaying);
    }
    const showFullScreen = () => {
        gifplayerRef.current.requestFullscreen();
    }

    const handleClick = (event) => {
        if(onClick && imageExist)
            onClick(event);
    }

    return <div className={clsx("gif_player", className)} ref={gifplayerRef} style={{ ...style }}>
        {isLoading && <img></img>}
        {!isLoading && !imageExist && fallbackComponent}
        {imageExist && <canvas style={{ flexGrow: 1 }} ref={ref} onClick={handleClick}></canvas>}
        {showControls && <div className="controller" >
            <button onClick={() => togglePlayState()} className={isPlaying ? "button pause" : "button"}></button>
            <Slider value={frameIndex.current} style={{ marginRight: 10 }} max={sliderMaxValue} onChange={(event, newValue) => seekGifPlayer(newValue as number)} aria-labelledby="continuous-slider" />
            <FullscreenExitIcon style={{ color: 'white' }} onClick={showFullScreen} />
        </div>}
    </div>
}

export default GifPlayer;

