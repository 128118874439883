import React, { useEffect, useContext }  from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/root.store';
import { MenuItem, Button, Typography, Menu, makeStyles, createStyles } from '@material-ui/core';
import profile_image_tiny from "../../../assets/user/profile.svg";
import register_image_tiny from "../../../assets/user/Register.svg";
import profile_image_small from "../../../assets/user/profile.png";
import profile_image_medium from "../../../assets/user/profile@2x.png";
import profile_image_large from "../../../assets/user/profile@3x.png";
import user_icon_tiny from "../../../assets/user/usericon.svg";
import user_icon_small from "../../../assets/user/usericon.png";
import user_icon_medium from "../../../assets/user/usericon@2x.png";
import user_icon_large from "../../../assets/user/usericon@3x.png";
import logout_image_tiny from "../../../assets/user/logout.svg";
import logout_image_small from "../../../assets/user/logout.png";
import logout_image_medium from "../../../assets/user/logout@2x.png";
import logout_image_large from "../../../assets/user/logout@3x.png";
import { useTranslation } from 'react-i18next';

const UserMenuComponent: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { user, logout, setOpen, setOpenRegister } = rootStore.userStore;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [t] = useTranslation("user");
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const useStyles = makeStyles(() =>
       createStyles({
        Profile_Menu_Button: {
            width: "12px",
            height: "12px",
            objectFit: "contain",
            marginRight: "1em"
          },
          Logout_Menu_Button: {
            width: "12.6px",
            height: "14px",
            objectFit: "contain",
            marginRight: "1em"
          },
          UserIcon: {
            width: "24px",
            height: "24px",
            objectFit: "contain",
            marginRight: "1em"
          },
          UserIconSmall: {
            width: "12.6px",
            height: "14px",
            objectFit: "contain",
            marginRight: "1em"
          },
          label: {
            textTransform: 'capitalize',
          },
    }));

    const classes = useStyles();

    return (
        <div data-test='userAccountButton'>
                <Button
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={handleClick}
                    classes={{ label: classes.label}}
                >
                    <img className={classes.UserIcon}
                            src={user_icon_tiny}
                            srcSet={`${user_icon_large}, ${user_icon_medium}, ${user_icon_small} `}
                    />  
                    <Typography variant="subtitle2" style={{flexWrap:'nowrap',whiteSpace:'nowrap'}}>{user.displayName}</Typography>
                </Button>
                {/* <Menu
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    keepMounted
                >
                    <MenuItem onClick={() => setOpen(true)}>
                        <span data-test="profileMenuButton">
                            <img className={classes.Profile_Menu_Button}
                                src={profile_image_tiny}
                                srcSet={`${profile_image_large}, ${profile_image_medium}, ${profile_image_small} `}
                            />  
                        </span>
                        <Typography>{ t("usermenuprofile")}</Typography>
                    </MenuItem> 
                    <MenuItem onClick={() => setOpenRegister(true)}>
                        <span data-test="registerMenuButton">
                            <img className={classes.UserIconSmall}
                                src={register_image_tiny}
                            />  
                        </span>
                        <Typography>{ t("usermenuregister")}</Typography>
                    </MenuItem> 
                    <MenuItem onClick={logout}>
                        <span data-test="logoutMenuButton">
                            <img className={classes.Logout_Menu_Button} 
                            src={logout_image_tiny}
                            srcSet={`${logout_image_large}, ${logout_image_medium}, ${logout_image_small} `}
                            />
                        </span>  
                    <Typography>
                        { t("usermenulogout")}
                    </Typography>
                    </MenuItem>
                </Menu> */}
            </div>
    );
}

export default (observer(UserMenuComponent));