import React, { useContext, useEffect } from 'react'
import { Route, Redirect, Switch, useHistory, useLocation } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import Navigation from './sideNav.component'
import JobResultComponent from '../jobResult/jobResult.component';
import Dashboard from '../dashboard/Dashboard.component';
import ReportComponent from '../report/reportMain.component';
import SettingsComponent from '../settings/Settings.component';
import PrivateRoute from '../../app/layout/PrivateRoute';
import { observer } from 'mobx-react-lite';
import { useAnyRuns } from './hooks/useAnyRuns';
import { useAnyJobs } from './hooks/useAnyJobs';
import EmptyDasboard from '../../app/common/emptyDashboard.component';
import EditJobComponent from '../editJob/editJob.component';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { RootStoreContext } from '../../app/stores/root.store';

function NoJobGaurd(Component: React.FC) {
    function WithNoJobgaurd(props) {
        const h = useHistory();
        const [hasAnyJob, isLoading] = useAnyJobs();
        React.useEffect(() => {
            if (!isLoading && !hasAnyJob) {
                h.push("/jobs");
            }
        }, [isLoading, hasAnyJob])
        return <React.Fragment>
            {!isLoading && hasAnyJob && <Component {...props} />}
        </React.Fragment>
    }

    return WithNoJobgaurd;
}


function NoJobRunGaurd(Component: React.FC) {
    function WithNoJobRungaurd(props) {
        const h = useHistory();
        const [hasAnyJobRun, isLoading] = useAnyRuns();
        React.useEffect(() => {
            if (!isLoading && !hasAnyJobRun) {
                h.push("/jobs");
            }
        }, [isLoading, hasAnyJobRun])
        return <React.Fragment>
            {!isLoading && hasAnyJobRun && <Component {...props} />}
        </React.Fragment>
    }

    return WithNoJobRungaurd;
}

function getTitle(path: string) {
    if(path.includes('setting'))
        return 'EnsureDR Management Settings'
    if(path.includes('dashboard')) 
        return 'EnsureDR Management Dashboard'
    if(path.includes('jobs')) 
        return 'EnsureDR Management Jobs'
    if(path.includes('report')) 
        return 'EnsureDR Management Report'
    if(path.toLocaleLowerCase().includes('createjob')) 
        return 'EnsureDR Management Create Job'
    if(path.toLocaleLowerCase().includes('editjob')) 
        return 'EnsureDR Management Edit Job'
    if(path.toLocaleLowerCase().includes('login')) 
        return 'EnsureDR Management Login'
    
    return 'EnsurDR Management';   
}

const JobsComponentWithGaurd = NoJobGaurd(JobResultComponent);
const ReportComponentWithGaurd = NoJobRunGaurd(ReportComponent);
const DashboardComponentWithGaurd = NoJobRunGaurd(Dashboard);
const HomePage = () => {
    const { userStore } = useContext(RootStoreContext);
    const _location = useLocation();
    const {trackPageView } =  useMatomo();
     
     useEffect(() =>{
     
         let timer = setTimeout(() => {
             document.title = getTitle(_location.pathname)
             trackPageView({})
         }, 200)
 
         return () =>{
             clearTimeout(timer);
         }
     }, [_location])
     
    return (
        <React.Fragment>
            <Grid container className="h100">
                <Grid item>
                    <Navigation />
                </Grid>
                <Grid item xs style={{ marginLeft: "60px" }} className="h100">
                        <Switch>
                            <PrivateRoute exact path='/settings' component={SettingsComponent} />
                            <PrivateRoute exact path='/jobs' component={JobsComponentWithGaurd} />
                            <PrivateRoute exact path='/dashboard' component={DashboardComponentWithGaurd} />
                            {/* <PrivateRoute path='/live' component={LiveFailoverComponent} /> */}
                            <PrivateRoute path='/report' component={ReportComponentWithGaurd} />
                            {<PrivateRoute path='/editjob/:jobId' component={EditJobComponent} />}
                            {/*<PrivateRoute path='/createjob' component={CreateJobComponent} /> */}
                            <Redirect to="/jobs"></Redirect>
                        </Switch>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default observer(HomePage)
