import  SvgIcon  from "@material-ui/core/SvgIcon";
import React from "react"
import {ReactComponent as ThirdDeviceSvg} from './svgs/3rddevice.svg';

const ThirdDeviceIcon: React.FC<React.HTMLProps<SVGElement>> = (props) => {
    const {style, className} = props;
    return <SvgIcon viewBox={"-2,-2, 20, 20"}  className={className} style={style} component={ThirdDeviceSvg}>
    </SvgIcon>
}


export default ThirdDeviceIcon;