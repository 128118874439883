export interface IJobLicense {
    id: number;
    license: string;
}
export class JobLicense implements IJobLicense {
    id: number;
    license: string;
    constructor(init?: IJobLicense) {
       Object.assign(this, init); //this will initialize our object of class JobActions
                                 // with the above values
   }
}