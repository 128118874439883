import React, { useState, useEffect, useContext } from 'react'
import { ReportData } from '../../app/models/reportdata.model'
import { Grid, Typography, Button, Paper, makeStyles } from '@material-ui/core';
import TimeLineSelectorComponent from '../../app/common/timelineSelector.component';
import JobCategoryIcon from '../../app/common/jobCategoryIcon';
import { convertTimespanToString, convertSecondsToTime } from '../../app/common/util/util';
import moment from 'moment';
import { TrendIndicator } from '../../app/common/trendIndicator.component';
import RecoveryReadinessChart from '../report/recoveryReadiness.component';
import GaugeChart from '../../app/common/gaugeChart.component';
import TestWorkFlowComponent from './testWorkflow.component';
import TestOverViewTile from './TestOverView.component';
import { WorkFlowStatus } from '../../app/models/workflowStep.model';
import DeviceListComponent from './deviceList.component';
import JobActionMenu from '../../app/common/jobActionMenu.component';
import ReportSkeleton from '../report/reportSkeleton.component';
import NoteIcon from '@material-ui/icons/NoteAdd';
import MoreVert from '@material-ui/icons/MoreVert';
import CheckIcon from '@material-ui/icons/CheckCircle';
import CrossIcon from '@material-ui/icons/CancelRounded';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon from '@material-ui/icons/Settings';
import NetworkIcon from '@material-ui/icons/Language';
import SpannerIcon from '@material-ui/icons/Build';
import WebIcon from '@material-ui/icons/Web';
import BugIcon from '@material-ui/icons/BugReport';
import FlashIcon from '@material-ui/icons/FlashOn';
import DatabaseIcon from '@material-ui/icons/Storage';
import DescriptionIcon from '@material-ui/icons/Description';
import { useTranslation } from 'react-i18next';

import queryString from 'querystring';
import { history } from '../../app/common/util/history';
import { JobRun } from '../../app/models/jobRun.model';
import { Skeleton } from '@material-ui/lab';
import { RootStoreContext } from '../../app/stores/root.store';
import { PrePostScriptsDTO } from '../../app/models/createjob/createJob.model';
import RRSDialog from '../../app/common/RRSDialog.component';
import RTADialog from '../../app/common/RTADialog.component';
import ReportTestsComponent from '../report/reportTests.component';
import { AutofixIcon, InternetConnectionIcon, IssuesIcon } from '../Icons';
import IssueAndAutoFixComponent from '../report/issuesAndAutofix.component';
import ResourcesAndICComponent from '../report/resourcesAndIC.component';
import DRResourceSectionComponent from '../report/drResourceSection.component';
import { JobRunType } from '../../app/models/BaseSettings.enum';

export const styles = {
    iconCell: {
        display: "flex"
    },
    deviceCellIcon: {
        marginRight: "5px",
        marginLeft: "8px",
        fontSize: "20px"
    },
    daySelector: {
        borderBottom: "1px solid #f5f6fa",
        height: '60px'
    },
    playPauseButton: {
        color: "#2892d7"
    },
    fullReportButton: {
        borderRadius: "23px",
        color: "white",
        background: "#2994cf",
        marginRight: "10px"
    },
    menuButton: {
        width: "35px",
        height: "35px",
        borderRadius: "18px",
        border : "1px solid #c7cfdb",
        minWidth: "0"
    }
}

const TestFix = (props: { name: string, count: number, icon: any, type: "autoFix" | "issues" }) => {
    const { icon, name, count, type } = props;
    return <Grid container>
        <Grid item>
            {icon}
        </Grid>
        <Grid item>
            <Grid container direction="column">
                <Grid item> <Typography variant="body2">{name}</Typography></Grid>
            </Grid>
        </Grid>


        {type === "issues" ? <Grid item><span style={styles.iconCell}><CrossIcon style={{ ...styles.deviceCellIcon, color: "lightgray" }} /> {count} </span></Grid> : ''}
        {type === "autoFix" ? <Grid item>
            <span style={styles.iconCell}>
                <CheckIcon style={{ ...styles.deviceCellIcon, color: 'lightgray' }} /> {count}
            </span>
        </Grid> : ''}


    </Grid>
}

const useStyles = makeStyles(theme => ({
    hover: {
        "&:hover": {
           backgroundColor: '#bfdff3',
           cursor:'pointer'
       }
   },
   testIcon: {
    color: '#8092a9'
}
}))

const MiniReportJobRunView: React.FC<{jobReport: {id: number, jobId: number, jobName: string, createDate:Date, dataMover: string,PrePostScripts:Array<PrePostScriptsDTO>}, jobRunState: JobRun}> = ({jobReport, jobRunState}) => {
    const classes = useStyles();
    const {t} = useTranslation('report');
    const [progressInfo, setprogressInfo] = useState("");
    const [openRRSDialog, setopenRRSDialog] = useState(false);
    const [openRTADialog, setopenRTADialog] = useState(false);
    const {jobRunStore,reportStore} = useContext(RootStoreContext);
    const startTime= jobRunStore.getStartTime(jobReport.jobId);

    useEffect(() => {
       if(jobRunState.jobRunProgressInfos) {
        const lastProgressInfo = jobRunState.jobRunProgressInfos.sort((a,b) => b.id-a.id)[0];
        setprogressInfo(lastProgressInfo && lastProgressInfo.progressInfo);
       }
        
    }, [jobRunState])
    
    const [menuAnchor, setMenuAnchor] = useState(null);
    const showFullReport = () => {
        history.push("/report?" + queryString.stringify({ jobId: jobReport.jobId, jobResultId: jobReport.id }));
    }
    const handleMenuClose = () => {
        setMenuAnchor(null);
    }

    const getTitle =(type: any)=>{
        let title = t("testWorkflow.title");
        let res="";
        if(!type)
        {
            return title + t("testWorkflow.all");
        }
        if(type == JobRunType.All)
          res = t("testWorkflow.all");
        else if(type == JobRunType.CleanUpOnly)
          res =t("testWorkflow.cleanupOnly");
        else if(type === JobRunType.FailoverOnly)
          res = t("testWorkflow.failoverOnly");
        else
          res = t("testWorkflow.liveFailoverOnly");
        return title + res;
      }
      
    return <>
     <Grid container direction="column" data-test="component-miniReport-running-state">

           <React.Fragment> <Grid container direction="row" style={{ padding: "8px 15px 0 15px" }}>
                <Grid item style={{ display: "flex", justifyContent: "center", alignItems: "center" }} xs={1}>
                    <img src="/assets/images/running_job_loader_large.gif" style={{width:50, height: 50}}></img>
                </Grid>
                <RRSDialog jobName={jobReport.jobName} jobResultId={jobReport.id} rRSTotals={null} open={openRRSDialog} onClose={() => setopenRRSDialog(false)} />
                <RTADialog jobName={jobReport.jobName} jobResultId={jobReport.id} rTATimeStamps={null} open={openRTADialog} onClose={() => setopenRTADialog(false)} />
                {/* {openLogsDialog && <LogViewer jobName={jobReport.jobName} jobResultId={jobReport.id} open={openLogsDialog} onClose={() => setopenLogsDialog(false)} />} */}
                <Grid item xs={8}>
                    <Grid container direction="column">
                        <Grid item xs={12} style={{whiteSpace: "pre-line"}}> <Typography noWrap={true} variant="h6"  align="left">{t("summaryReport")} - {jobRunState.jobName}</Typography></Grid>
                        <Grid item xs={12}><Typography variant="body1" align="left">{jobReport.dataMover}
                        <span style={{margin: "0 5px 0 5px"}}>|</span>
                        {startTime !=null ? 'Job Started at '+ startTime +' - '+t("running"):t("running")}
                        </Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <div style={{textAlign: "right"}}>
                        <Button data-test="button-fullReport" onClick={showFullReport} variant="contained" style={styles.fullReportButton}><DescriptionIcon />{t("button.fullReport")}</Button>
                        <Button data-test="button-menu" variant="text"  style={styles.menuButton} onClick={(event) => setMenuAnchor(event.target)}><MoreVert /></Button>
                    </div>
                </Grid>
                <Grid container direction="row" style={{ padding: "15px 0 0 0" }}>
                    <Grid item xs onClick={()=> !jobRunStore.isJobRunning(jobReport.jobId) ? setopenRRSDialog(true):null}>
                        <Paper style={{ margin: "5px", padding: "15px" }} className={jobRunStore.isJobRunning(jobReport.jobId) ? null : classes.hover} variant="outlined">
                            <Grid container>
                                <Grid item xs={8}>
                                    <Grid container direction="column" alignItems="flex-start">
                                        <Grid item>
                                            <Typography variant="body1" > {t("recoveryReadinessScore")}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h3" style={{width:"100%"}}> {0}%</Typography>
                                        </Grid>
                                        <Grid item>
                                            <TrendIndicator showText={true} value={0} trendUpLabel={t("morethanLastJob")} trendDownLabel={t("lessthanLastJob")}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <RecoveryReadinessChart width={90} height={90} score={0} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs onClick={()=> !jobRunStore.isJobRunning(jobReport.jobId) ? setopenRTADialog(true):null}>
                        <Paper style={{ margin: "5px", padding: "15px" }} className={jobRunStore.isJobRunning(jobReport.jobId) ? null : classes.hover} variant="outlined">
                            <Grid container>
                                <Grid item xs={8}>
                                    <Grid container direction="column" alignItems="flex-start">
                                        <Grid item>
                                            <Typography variant="body1">{t("recoveryTime")}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <span>
                                                <Typography variant="h3"> {convertSecondsToTime(0)}</Typography>
                                            </span>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2"> {t("RTOwasSetTo")} {convertSecondsToTime(0)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <GaugeChart width={110} height={90} value={0} max={0} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                {/*  chart tiles ends */}
            </Grid>
                <Grid container direction="column" style={{ padding: "15px" }}>
                    <Grid item >
                        <Typography variant="h6">{getTitle(jobRunState.jobRunType)}</Typography>
                    </Grid>
                    <Grid item data-test='component-current-step'>
                        <Typography variant="body1">{progressInfo}</Typography>
                    </Grid>
                    <Grid item style={{ marginTop: "15px" }}>
                        <TestWorkFlowComponent variant="smallRun" workflowScripts={jobReport.PrePostScripts} workflowSteps={[]} jobRunState={jobRunState} runDate={jobRunState.runDate}></TestWorkFlowComponent>

                    </Grid>

                </Grid>
                {/* workflow ends */}
                <Grid item container style={{padding: "0px 15px 0px 5px" }}>
                    <Grid item xs={12}>
                      <Typography variant="h6" align="left" style={{paddingLeft:'10px'}}> Tests Overview</Typography>
                    </Grid>
                   <Grid xs={12}>
                   <Paper style={{padding: "15px",border:'1px solid white' }} variant="outlined">
                        <Grid container spacing={1}>
                        
                        <Grid item container xs={3}>
                        <Grid container item justifyContent="center" alignItems="center" style={{border: '1px solid #eaeef4',borderRadius: '4px'}}>
                            <Grid item container style={{width: '100%', padding:'10px 10px'}}>
                                <Grid item container direction="column" justify="space-between">
                                    <IssueAndAutoFixComponent name={t("tests.issues")} count={0} icon={<IssuesIcon className={classes.testIcon}/>} type="issues" reportType="mini"/>
                                    <IssueAndAutoFixComponent name={t("tests.autoFix")} count={0} icon={<AutofixIcon className={classes.testIcon}/>} type="autoFix" reportType="mini"/>
                                    <Grid item>
                                   <DRResourceSectionComponent 
                                        cpu={0} cpuMax={10} 
                                        ram={0} rammax={10}
                                        iops={0} iopsMax={10}
                                        jobStatus={null}
                                        />
                                    </Grid>
								</Grid>
                            </Grid>
                        </Grid>
                        </Grid>

                       <Grid item xs={9}>
                       <ReportTestsComponent type={"miniRun"} jobTest={null}/>
                       </Grid>
                    </Grid>
                    </Paper>
                   </Grid>
                </Grid>

                {/* Device List */}
                {/* <DeviceListComponent jobResultId={jobReport && jobReport.id} /> */}
    
            <Grid item container spacing={1} style={{ padding: 20 }} >
               <Grid item xs={3}><Paper style={{width: '100%'}} >
                <Skeleton variant="rect" height={280}></Skeleton>
                </Paper></Grid>

                <Grid xs={3} item><Paper style={{width: '100%'}} >
                <Skeleton variant="rect" height={280}></Skeleton>
                </Paper></Grid>

                <Grid xs={3} item ><Paper style={{width: '100%'}} > 
                <Skeleton variant="rect" height={280}></Skeleton>
                </Paper></Grid>

                <Grid xs={3} item><Paper style={{width: '100%'}} >
                <Skeleton variant="rect" height={280}></Skeleton>
                </Paper></Grid>
            </Grid>
                <JobActionMenu jobId={jobReport.jobId} firstJobEver={jobReport && jobReport.jobId == 1} jobResultId={jobReport.id} edrServer={''} createDate={jobReport.createDate} jobName={jobReport.jobName} jobNameFromJobs={jobReport.jobName} isDeleted={false} isDeletedPermanently={false} attachmentSize={0} onClose={handleMenuClose} anchorEl={menuAnchor} />
            </React.Fragment> 
        </Grid>
    </>;
}

export default MiniReportJobRunView;