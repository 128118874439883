
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, makeStyles, MenuItem, Select, Switch, Tooltip, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EDRTextField } from "../../../../app/common/EDRTextField";
import { SaveButton } from "../../../../app/common/saveButton";
import { EDRDialogTitle } from "../../../../app/common/EDRDialogTitle";
import { DatabaseType, DevicePlatform, MachineType } from '../../../../app/models/jobProperties.model';
import { DATABASE_TYPE_NAME, DataMover } from '../../../../app/models/BaseSettings.enum';
import  VisibilityOff  from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import ReactDOM from 'react-dom';
import { EDROutlinedInput } from '../../../../app/common/EDROutlinedInput';
import { EDRAutocomplete } from '../../../../app/common/EDRAutocomplete';
import { RootStoreContext } from '../../../../app/stores/root.store';
import { CONSTS, DatabaseTestProperties } from '../../../../app/models/createjob/createJob.model';
import jobAgent from '../../../../app/api/jobAgent';
import { TestType } from '../../../../app/models/jobTest.model';
import { TestSettingProps } from './customTestSetting.component';
import HelpIcon from '@material-ui/icons/Help';
import { toast } from 'react-toastify';
import * as yup from 'yup';

const useStyles = makeStyles({
    root: {
        minWidth: '40vw',
        minHeight: '50vh'
    }
})

const DatabaseTestSettingDialog: React.FC<TestSettingProps> = (props) => {
    const { open, onClose,isDefault,deviceName,onSave } = props;
    const useStyles = makeStyles(() => ({
        helpIcon:
    {
      color: '#1976d2',
      fontSize: "large"
    },
    SecondHeading:{
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        flexGrow: 1
    },
    title:{
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontstyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        textAlign: 'left'
    },
    divider: {
        backgroundColor: '#eaeef4',
        maxWidth: '92%',
        margin: '0',
        marginTop: '10px',
        marginLeft: '10px',
    },
        centerVertical: {
            display: 'flex',
            alignItems: 'center',
            justifyContent:'space-between'
        },
        toggleItem: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
    
            '& .MuiListItemText-root' : {
                marginLeft: 10
            },
            '& .MuiSvgIcon-root': {
                color: '#8092a9'
            }
        },
        formControl: {
          width: '100%',
          '& label': {
            background: "white",
            width: "auto",
            padding: "2px 4px 0 4px",
            fontSize: '15px'
          }
        },
        root:{}
      }));
    const classes = useStyles();
    const { t } = useTranslation('createJobBase');
    const { createJobStore,jobSettingsStore } = useContext(RootStoreContext);
    const {dataMover}=createJobStore.baseSettings;
    const isPaaS= dataMover == DataMover.PaaS;
    const DatabaseTypeOptions: Array<DatabaseType> = [0];
    const getPreviousDatabase= jobSettingsStore.databaseList;
    let labelRef;
    const [cancel,setCancel]=useState(false);
    const [device, setDevice] = useState(createJobStore.selectedDevices.find(device=>device.deviceName == deviceName));
    const [DatabaseTest,setDatabaseTest] =useState(device!= undefined ? device.tests.find(test=> test.testType == TestType.database):null)
    const [processingTest,setProcessingTest] = useState(false);

    const loadOptions =()=>{
        let options =[];
        if(device && device.os === DevicePlatform.Windows)
            return jobSettingsStore.accessCredentials;
        else if(device && device.os === DevicePlatform.UNIX)
        {
            jobSettingsStore.accessCredentials.map(item=>{
                let userNoDomain = item.userName.split('\\')[1];
                options.push({userName:userNoDomain==undefined ? item.userName: userNoDomain,password:item.password});
            });
           return options;
        }
    }

    const usernameOptions = loadOptions();

    const validation = yup.object({
        databaseServer:yup.string(),
        database:yup.string(),
        query:yup.string(),
        dbUsername:yup.string(),
        dbPassword:yup.string()
    })

    const form = useFormik({
        initialValues: {
            databaseType: jobSettingsStore.databaseType,
            databaseServer: device === undefined ? '': device.planName.includes("Custom") ? device.sourceName: (device.sourceName!="" ? device.sourceName.split('.')[0]:device.deviceName),
            database: jobSettingsStore.databaseNameToTest,
            query: jobSettingsStore.databaseQueryToTest,
            dbUsername: jobSettingsStore.selectedPrimaryUser != null && jobSettingsStore.selectedPrimaryUser != undefined ? (device && device.os == DevicePlatform.Windows ? jobSettingsStore.selectedPrimaryUser.userName: jobSettingsStore.selectedPrimaryUser.userName.split('\\')[1]) : '',
            dbPassword: jobSettingsStore.selectedPrimaryUser != null && jobSettingsStore.selectedPrimaryUser != undefined ? jobSettingsStore.selectedPrimaryUser.password : '',
            port: jobSettingsStore.databasePort,
            dbWindowsAuth: jobSettingsStore.dbWindowsAuth
        },
        validationSchema:validation,
        validateOnMount: false,
        onSubmit: () => { },
       
    })
    const [showEyePassword, setShowEyePassword]= useState(true);
    const [passwordVisibility,setPasswordVisibility] = useState(true);
    const [UserPassAutocomplete,setUserPassAutocomplete] = useState(false);

     useEffect(() => {
        const newDevice = createJobStore.selectedDevices.find(device=>device.deviceName == deviceName)
        setDevice(newDevice);
        const newDatabaseTest = newDevice!= undefined ? newDevice.tests.find(test=> test.testType == TestType.database):null
        setDatabaseTest(newDatabaseTest);
        let serverName = '';
        if(device!=undefined)
        {
            if(device.planName.includes("Custom"))
            {
                serverName = device.sourceName;
            }
            else
            {
                if(device.sourceName === "")
                {
                    serverName = device.deviceName;
                  
                }
                else
                {
                    serverName = device.sourceName.split('.')[0];
                }
            }
        }
        else
        {
            serverName = "";
        } 
        let initialValues;
         initialValues =
         {
             databaseType: newDatabaseTest!=null ? newDatabaseTest.databaseType: jobSettingsStore.databaseType,
             databaseServer: newDatabaseTest && newDatabaseTest.databaseServerNameToTest!== "" ? newDatabaseTest.databaseServerNameToTest:serverName,
             database: newDatabaseTest && newDatabaseTest.databaseNameToTest!="" ? newDatabaseTest.databaseNameToTest :jobSettingsStore.databaseNameToTest,
             query: newDatabaseTest && newDatabaseTest.databaseQueryToTest!="" ? newDatabaseTest.databaseQueryToTest :jobSettingsStore.databaseQueryToTest,
             dbUsername: newDatabaseTest && newDatabaseTest.databaseUsername!="" ? newDatabaseTest.databaseUsername :(jobSettingsStore.selectedPrimaryUser != null && jobSettingsStore.selectedPrimaryUser != undefined ? (device && device.os == DevicePlatform.Windows ? jobSettingsStore.selectedPrimaryUser.userName: jobSettingsStore.selectedPrimaryUser.userName.split('\\')[1]) : ''),
             dbPassword: newDatabaseTest &&  newDatabaseTest.databasePassword!="" ? newDatabaseTest.databasePassword :(jobSettingsStore.selectedPrimaryUser != null && jobSettingsStore.selectedPrimaryUser != undefined ? jobSettingsStore.selectedPrimaryUser.password : ''),
             port: newDatabaseTest && newDatabaseTest.databasePort!="" ? newDatabaseTest.databasePort :jobSettingsStore.databasePort,
             dbWindowsAuth: newDatabaseTest && newDatabaseTest.dbWindowsAuth!=false ? newDatabaseTest.dbWindowsAuth :jobSettingsStore.dbWindowsAuth
         };
        form.resetForm({values: initialValues});
    }, [deviceName]);

     
    const handleClose = () => {
        if(form.touched && form.dirty)
            setCancel(!cancel);
        else
            onClose();
    }

    const handleSave=()=>{
        try{
        if(device!=undefined){
            let DeviceTest = {
                testCategory:device.tests[0].testCategory, 
                testType:TestType.database, 
                selected: true,
                customCommandToTest: '',
                customCommandExpectedResult: '', 
                customCommandToTestIsExternal: false,
                databaseType: form.values.databaseType,
                databaseNameToTest: form.values.database,
                databaseQueryToTest: form.values.query,
                databaseServerNameToTest: form.values.databaseServer,
                databaseUsername:form.values.dbUsername,
                databasePassword: form.values.dbPassword,
                databasePort:form.values.port,
                dbWindowsAuth:form.values.dbWindowsAuth,
                serviceToTest:'',
                testFromEDRC: false,
                script: '',
                thirdDeviceNetworkToTest: '',
                authenticationUserToTest:'',
                authenticationPassToTest:'',
                webPortalToTest: '',
                webPortalResult: ''
            };
            createJobStore.updateTestDevice(TestType.database,DeviceTest,device.planName.includes("Custom Device") ? device.sourceName : device.deviceName);
            toast.success('Save successful');
        }
        else
        {
            jobSettingsStore.updateDatabaseSettings(form.values.databaseType,form.values.database,form.values.query,form.values.databaseServer,form.values.dbUsername,form.values.dbPassword,form.values.port,form.values.dbWindowsAuth);
            toast.success('Save successful');
        }
    }
    catch(error){
        toast.success('Save failed'+error);
    }
        setCancel(false);
        onSave();
        onClose();
    }

    const handleTest=() =>{       
        var details =
        {
            databaseType:form.values.databaseType,
            databaseServer:form.values.databaseServer,
            database:form.values.database,
            query:form.values.query,
            dbUsername:form.values.dbUsername,
            dbPassword:form.values.dbPassword,
            port:form.values.port,
            dbWindowsAuth:form.values.dbWindowsAuth,
            id: createJobStore.baseSettings.workflowTest.PrimaryUserCredsId
        };
        jobAgent.JobTestsActions.TestDatabase(details).then(res => {
            if(res)
                toast.success("Database Test succeeded");
            else
                toast.error("Database Test failed");
        }).catch(err=>{
        if(err && err.data && err.data.errors)
            toast.error(err.data.errors);
        });          
    }

    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel style={{marginLeft:'10px'}}>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const handleYes=()=>{
        if(device!=undefined)
        {
            let initialValues = {
                databaseType: DatabaseTest.databaseType,
                databaseServer: DatabaseTest.databaseServerNameToTest,
                database: DatabaseTest.databaseNameToTest,
                query: DatabaseTest.databaseQueryToTest,
                dbUsername: DatabaseTest.databaseUsername,
                dbPassword: DatabaseTest.databasePassword,
                port: DatabaseTest.databasePort,
                dbWindowsAuth:DatabaseTest.dbWindowsAuth
            };
            form.setValues(initialValues);
        }
        setCancel(false);
        onClose();
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    const handleAutoCompleteChange = (event: React.ChangeEvent,value:any) => {
        if(event!=null)
        {
          let fieldName= event.target['id'].includes('-') ? event.target['id'].split('-')[0]: event.target['id'];
          form.setFieldValue(fieldName,value);
          switch(fieldName) {
            case 'databaseServer': handleDatabaseServerChange(event,value); break;
            case 'database': handleDatabaseChange(event,value); break;
            case 'query': handleQueryChange(event,value);break;
            case 'dbUsername':handleUsernameChange(event,value);break;
          }
        }
      }

    const handleClickShowPassword  = () => {
        setPasswordVisibility(passwordVisibility? false: true);
    };

    const handleDatabaseTypeChange = (event) => {
        form.setFieldValue("databaseType",event.target.value);
      };

    const handleDatabaseServerChange=(event:object,value:any)=>{
        form.setFieldValue("databaseServer",value,true);   
    }

    const handleDatabaseChange=(event:object,value:any)=>{
        form.setFieldValue("database",value,true);
    }

    const handleQueryChange=(event:object,value:any)=>{
        form.setFieldValue("query",value,true);
    }

    const handleUsernameChange=(event:React.ChangeEvent,value:any)=>{
        if(event!=null){
            let onInput= event.type=="click"? false:true;
            setUserPassAutocomplete(onInput);
            if (value!=null && typeof value === 'object') 
            {
                form.setFieldValue("dbUsername",value=="" ? '':value.userName,true);
            }
            if (typeof value === 'string')
            {
                const userObj = jobSettingsStore.accessCredentials.find(e => e.userName.toLocaleLowerCase() == value.toLocaleLowerCase());
                form.setFieldValue("dbUsername",value,true);
                let pass = userObj== undefined ? '' :userObj.password;
                form.setFieldValue("dbPassword",pass,true);
            }
            setShowEyePassword(onInput);
          }
    }

    const handledbPassChange= (event)=>{
        setUserPassAutocomplete(true);
        setShowEyePassword(true);
        form.setFieldValue("dbPassword",event.target.value,true);
    }

    const handleMouseClick =()=>{
        if(form.values.dbPassword === CONSTS.MaskedPassword)
        {
            form.setFieldValue("dbPassword",'',true);
        }
    }

    const handleMouseOut =()=>{
        if(form.dirty && form.values.dbPassword == '')
        {
            if(form.values.dbUsername!='')
            {
                return;
            }
            else form.setFieldValue("dbPassword",CONSTS.MaskedPassword,true);
        }
    }

    const handlePortChange = (event: any) => {
        form.setFieldValue("port", parseInt(event.target.value));
    };

    const handleChangeWindowsAuth =(event)=>{
    form.setFieldValue("dbWindowsAuth", event.target.checked);
    }

    return <Dialog disableBackdropClick
        open={open}
        classes={{ paper: classes.root }}
        onClose={handleClose}
        scroll="paper"
    >
        <EDRDialogTitle>
        <div style={{ display: 'flex', flexDirection: 'row'}}>
            <Typography variant="h6" className={classes.title} style={{display:'flex',alignItems:'center'}}>{isPaaS ? t('DatabaseSetting') + deviceName : t('DatabaseSetting')}</Typography>
            {device !== undefined ? <img style={{marginLeft:'5px'}} src={device.os=== DevicePlatform.UNIX ?"/assets/images/editDeviceIcons/linuxIcon.svg":"/assets/images/editDeviceIcons/windowsIcon.svg"}></img>:null}
        </div>
        </EDRDialogTitle>
        <DialogContent dividers={true}>

        <Grid item container direction="row">
        <div style={{ display: 'flex', flexDirection: 'row',paddingBottom:'5%'}}>
            <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('subDatabase')+deviceName}</Typography>
            <Tooltip title={t('databaseInfo')} arrow>
                <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
            </Tooltip> 
            <Divider className={classes.divider}/>
        </div>
        </Grid>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                <FormControl className={classes.formControl} variant='outlined'>
                <InputLabel>{t('databaseType')}</InputLabel>
                <Select
                required={true}
                labelId="databaseType"
                value={form.values.databaseType}
                displayEmpty={true}
                renderValue={name => name<0? 'None': DATABASE_TYPE_NAME[name as string]}
                onChange={handleDatabaseTypeChange}>
                {DatabaseTypeOptions.map((name) => (
                <MenuItem key={name} value={name}>
                {DATABASE_TYPE_NAME[name]}
                </MenuItem>))}
                </Select>
                </FormControl>
                </Grid>
                
                <Grid item xs={6}>
                <FormControl className={classes.formControl} variant='outlined'>
                <EDRTextField id="port"
                    name="port"
                    label={t('databasePort')}
                    placeholder=""
                    value={form.values.port}
                    onChange={handlePortChange}
                    onBlur={form.handleBlur}
                    error={Boolean(form.errors.port)}
                    helperText={t(form.errors.port)}
                    type="number"
                    aria-autocomplete="none"
                    InputLabelProps={{
                       shrink: true,
                    }}
                    variant="outlined" />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                <EDRAutocomplete id="databaseServer"
                        freeSolo
                        disableClearable
                        value={form.values.databaseServer}
                        options={getPreviousDatabase}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderOption={(option: string|{databaseServer: string,database:string,query:string,username:string,password:string}) => (typeof option === 'string' ? option : `${option.databaseServer}`)}
	                    getOptionLabel={(option: string|{databaseServer: string,database:string,query:string,username:string,password:string}) => (typeof option === 'string' ? option : `${option.databaseServer}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={t('databaseServer')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="databaseServer"
                        aria-autocomplete="none"
                        onBlur={form.handleBlur}
                        error={form.touched.databaseServer && Boolean(form.errors.databaseServer)}
                        helperText={t(form.errors.databaseServer)}
                    />)}
                    /> 
                </Grid>
                <Grid item xs={6}>
                <EDRAutocomplete id="database"
                        freeSolo
                        disableClearable
                        value={form.values.database}
                        options={getPreviousDatabase}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderOption={(option: string|{databaseServer: string,database:string,query:string,username:string,password:string}) => (typeof option === 'string' ? option : `${option.database}`)}
	                    getOptionLabel={(option: string|{databaseServer: string,database:string,query:string,username:string,password:string}) => (typeof option === 'string' ? option : `${option.database}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={t('database')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="database"
                        aria-autocomplete="none"
                        onBlur={form.handleBlur}
                        error={form.touched.database && Boolean(form.errors.database)}
                        helperText={t(form.errors.database)}
                    />)}
                    /> 
                </Grid>
                
                <Grid item xs={12}>
                <EDRAutocomplete id="query"
                        freeSolo
                        disableClearable
                        value={form.values.query}
                        options={getPreviousDatabase}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderOption={(option: string|{databaseServer: string,database:string,query:string,username:string,password:string}) => (typeof option === 'string' ? option : `${option.query}`)}
	                    getOptionLabel={(option: string|{databaseServer: string,database:string,query:string,username:string,password:string}) => (typeof option === 'string' ? option : `${option.query}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={t('query')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        aria-autocomplete="none"
                        name="query"
                        onBlur={form.handleBlur}
                        error={form.touched.query && Boolean(form.errors.query)}
                        helperText={t(form.errors.query)}
                    />)}
                    /> 
                </Grid>

                <Grid item xs={6}>
                <EDRAutocomplete id="dbUsername"
                        freeSolo
                        disableClearable
                        value={form.values.dbUsername}
                        options={usernameOptions}
                        disabled={form.values.dbWindowsAuth}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderOption={(option: string|{userName:string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
	                    getOptionLabel={(option: string|{userName:string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={t('dbUsername')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        aria-autocomplete="none"
                        name="dbUsername"
                        onBlur={form.handleBlur}
                        error={form.touched && form.touched.dbUsername && form.errors && Boolean(form.errors.dbUsername)}
                        helperText={form.touched.dbUsername && form.errors.dbUsername}
                    />)}
                    /> 
                </Grid>
                <Grid item xs={6}>
                <FormControl className={classes.formControl} variant='outlined'>
                <InputLabel ref={ref => {labelRef = ReactDOM.findDOMNode(ref)}} style={{transform: 'translate(14px,-8px) scale(0.75)'}}>{t('dbPassword')}</InputLabel>
                <EDROutlinedInput id="dbPassword" className="MuiFormControl"
                required={true}
                value={form.values.dbPassword}
                disabled={form.values.dbWindowsAuth}
                labelWidth={labelRef ? labelRef.offsetWidth:0}
                type={passwordVisibility ? 'password':'text'}
                aria-autocomplete="none"
                autoComplete="off"
                onChange={handledbPassChange}
                onClick={handleMouseClick}
                onBlur={handleMouseOut}
                error={form.touched && form.touched.dbPassword && Boolean(form.errors.dbPassword)}
                endAdornment={showEyePassword && UserPassAutocomplete ? 
                <InputAdornment position="end">
                <IconButton
                aria-label="toggle password visibility"
                onClick={()=>handleClickShowPassword()}
                >
                {passwordVisibility ?  <VisibilityOff />: <Visibility />}
                </IconButton>
                </InputAdornment>:null}
                />  
                </FormControl>
                </Grid>

                <Grid item container justify="center" direction="column" style={{ marginTop: "15px", marginBottom: "15px", display: 'flex', flexDirection: 'row' }}>
                    <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                        <Typography style={{ marginLeft: '4%' }}>{t('useWindowsAuth')}</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                        <Switch checked={form.values.dbWindowsAuth} onChange={handleChangeWindowsAuth} color='primary' />
                    </Grid>
                </Grid>
              
            </Grid>
        </DialogContent>
        <DialogActions>
        <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end'}}>
            <Button onClick={handleClose} color="primary" style={{textTransform:'none'}}>
                {t('cancel')}
            </Button>
            <Button onClick={handleTest} color="primary" disabled={form.values.databaseServer=='' || form.values.database=='' || form.values.query=='' || form.values.dbUsername=='' || form.values.dbPassword==''} style={{textTransform:'none',marginLeft:'-11px'}}>
                {t('Test')}
            </Button>
            <SaveButton onClick={handleSave} color="primary" variant="contained" style={{textTransform:'none'}} disabled={form.values.database ==='' || form.values.databaseServer ==='' || form.values.query ==='' || form.values.dbUsername ==='' || form.values.dbPassword ===''}>
                {t('save')}
            </SaveButton>
            </Grid>
        </DialogActions>
        </Dialog>
} 

export default DatabaseTestSettingDialog;