import { LastJobRun } from "../../models/lastjobRun.model";

export const JobSortComparator: { [key: string]: (j1: LastJobRun, j2: LastJobRun) => number; } = {
    jobName: (j1: LastJobRun, j2: LastJobRun) => {
        return j1.jobName.localeCompare(j2.jobName);
    },
    edrServer: (j1: LastJobRun, j2: LastJobRun) => {
        return j1.edrServer.localeCompare(j2.edrServer);
    },
    rrs: (j1: LastJobRun, j2: LastJobRun) => {
        return j1.score - j2.score;
    },
    rta: (j1: LastJobRun, j2: LastJobRun) => {
        return j1.rta - j2.rta;
    },
    nextRun: (j1: LastJobRun, j2: LastJobRun) => {
        return new Date(j1.nextRun).getTime() - new Date(j2.nextRun).getTime();
    },
    lastRun: (j1: LastJobRun, j2: LastJobRun) => {
        if (j1.isRunning && !j2.isRunning)
            return -1;
        if (j2.isRunning && !j1.isRunning)
            return 1;
        if (j2.isRunning && j1.isRunning)
            return 0;
        return new Date(j1.runDate).getTime() - new Date(j2.runDate).getTime();
    },
    dataMover: (j1: LastJobRun, j2: LastJobRun) => {
        return j1.dataMover.localeCompare(j2.dataMover);
    },
    rto: (j1: LastJobRun, j2: LastJobRun) => {
        return j1.rto - j2.rto;
    },
    rpa: (j1: LastJobRun, j2: LastJobRun) => {
        return j1.rpa - j2.rpa;
    },
    rpo: (j1: LastJobRun, j2: LastJobRun) => {
        return j1.rpo - j2.rpo;
    },
    workflowState: (j1: LastJobRun, j2: LastJobRun) => {
        return 0;
    },
    devices: (j1: LastJobRun, j2: LastJobRun) => {
        return j1.serversInStatusBAD + j1.serversInStatusOK - j2.serversInStatusOK - j2.serversInStatusBAD;
    },
    runningStatus: (j1: LastJobRun, j2: LastJobRun) => {
        if (j1.isRunning && !j2.isRunning)
            return -1;
        if (j2.isRunning && !j1.isRunning)
            return 1;
        return 0;

    }
};
