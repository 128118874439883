import React, { useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, CircularProgress, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, makeStyles, Tooltip, Typography } from '@material-ui/core';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useTranslation } from 'react-i18next';
import { RootStoreContext } from '../../../../app/stores/root.store';
import ReactDOM from 'react-dom';
import { InfrastructureType } from '../../../../app/models/jobProperties.model';
import { EDROutlinedInput } from "../../../../app/common/EDROutlinedInput";
import { EDRAutocomplete } from "../../../../app/common/EDRAutocomplete";
import { EDRTextField } from "../../../../app/common/EDRTextField";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { INFRA_NAME } from '../../../../app/models/BaseSettings.enum';
import HelpIcon from '@material-ui/icons/Help';
import { DataMover } from '../../../../app/models/BaseSettings.enum';
import JobSettingsStore from '../../../../app/stores/jobSettings.store';
import { SaveButton } from '../../../../app/common/saveButton';
import { runInAction } from 'mobx';
import jobAgent from '../../../../app/api/jobAgent';
import { toast } from 'react-toastify';
import { AccessCredentials, CONSTS, CredentialUserType, InfrastructureDTO } from '../../../../app/models/createjob/createJob.model';
import ConfirmationDialog from '../../../../app/common/confirmationDialog.component';
import { regions } from '../../../../app/regions';
import CheckCircle from '@material-ui/icons/CheckCircle';

const VmwareValidationSchema = yup.object({
        
    infraSourceUsername: yup.string().when('datamover', {
            is: DataMover.SRM || DataMover.ZertoVmWare ||  DataMover.VeeamVmware || DataMover.ASR,
            then: yup.string()
            .test('empty','username must be not empty',infraSourceUsername => infraSourceUsername!='')
            .required('Required'),
            otherwise: yup.string()
            .nullable()
            .required('Required')
            .max(50, 'up to 50 characters')
        }),

    infraSourcePassword: yup.string().when('datamover', {
        is: DataMover.SRM || DataMover.ZertoVmWare ||  DataMover.VeeamVmware,
        then: yup.string()
        .test('empty','password must be not empty',infraSourcePassword => infraSourcePassword!='')
        .required('Required'),
        otherwise: yup.string()
        .required('Required')
        .max(50, 'up to 50 characters')
    }),

    infraSourcePort: yup.number()
        .required('Required')
        .transform(value => (isNaN(value) ? 0 : value))
        .max(65535, 'must be a number between 0 - 65535')
        .min(0, 'must be a number between 0 - 65535'),
       
    infraTargetPort: yup.number()
        .required('Required')
        .transform(value => (isNaN(value) ? 0 : value))
        .max(65535, 'must be a number between 0 - 65535')
        .min(0, 'must be a number between 0 - 65535'),
       
    infraTargetIP: yup.string().required().when('datamover', {
            is: (v) => v!= DataMover.PaaS && v!= DataMover.ZertoAzure,
            then:yup.string().required()
            .nullable()
            .required('Required')
            .test('infraTargetIP',
            'Invalid Target Name/IP',
            function(value){
              let IpRegex=/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
              let hostnameRegex=/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;
              let isValidIP = IpRegex.test(value);
              let isValidHostname = hostnameRegex.test(value);
              if(isValidIP || isValidHostname) 
              {
                return true;
              }
              return false;
            })
    }),

    infraSourceIP: yup.string().required().when("datamover",{
        is: (v) => v!= DataMover.PaaS && v!= DataMover.ZertoAzure,
        then: yup.string().required()
        .nullable()
        .required('Required')
        .test('infraSourceIP',
        'Invalid Source Name/IP',
        function(value){
          let IpRegex=/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
          let hostnameRegex=/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;
          let GuidRegex= /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
          let isValidIP = IpRegex.test(value);
          let isValidHostname = hostnameRegex.test(value);
          let isValidGuid= GuidRegex.test(value);
       
            if(isValidIP || isValidHostname) 
              return true;
          
          return false;
        }),
        otherwise: yup.string()
        
      }),
    
    infraTargetUsername:yup.string().required().when('datamover', {
        is:(v) => v !== DataMover.ZertoAzure,
        then: yup.string().required()
        .test('empty','username must be not empty',infraTargetUsername => infraTargetUsername!='')
        .required('Required')
        .nullable()
        .max(50,'up to 50 characters')
    }),
    
    infraTargetPassword:yup.string().required().when('datamover', {
      is:(v) => v !== DataMover.ZertoAzure,
      then: yup.string().required()
      .test('empty','password must be not empty',infraTargetPassword => !infraTargetPassword|| infraTargetPassword!='')
      .required('Required')
      .max(50,'up to 50 characters')
    })
});


var AzurevalidationSchema = yup.object({
    infraTargetIP: yup.string().required('TenantId is required'),
    infraTargetUsername:yup.string().required('Client Id is required'),
    infraTargetPassword: yup.string().required('Client Secret is required'),
    infraSourceIP: yup.string().required('Subscriptoin Id is required')
})

var AWSvalidationSchema = yup.object({
    infraTargetIP: yup.string().required('EnsureDR is AWS is required'),
    infraTargetUsername:yup.string().required('AWS Key is required'),
    infraTargetPassword: yup.string().required('AWS Secret is required'),
    infraSourceIP: yup.string().required('Region is required')
})

const InfrastructAdvancedComponent: React.FC = () => {

    let targetlabelRef;
    const useStyles = makeStyles({
        helpIcon:
        {
            color: '#1976d2',
            fontSize: "large"
        },
        formControl: {
            width: '100%',
            '& label': {
                background: "white",
                width: "auto",
                //padding: "0 4px 0 4px",
                fontSize: '15px',
            }
        },
        validateIcon: {
            position: 'absolute',
            top: 15,
            right:8,
            color: '#8bc53f'
          },
        MainHeading:
        {
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.19',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#1f396d',
            marginLeft: '10px'
        },
        SecondHeading: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1
        },
        divider: {
            backgroundColor: '#eaeef4',
            width: '290%',
            margin: '0',
            marginTop: '10px',
            marginLeft: '10px',
        },
        button: {
            borderRadius: 15,
            color: 'white',
            backgroundColor: '#2892d7',
            width: '23%',
            height: '32px',
            marginTop: '24px',
            marginLeft: '485px'
        }
    });

    const classes = useStyles();
    const [t] = useTranslation("createJobBase");
    const rootStore = useContext(RootStoreContext);
    const {jobSettingsStore,createJobStore,displayStore} = rootStore;
    const {BaseDrawerSettings} =displayStore;
    const isPaaS= createJobStore.baseSettings.dataMover==  DataMover.PaaS;
    const isAzure = createJobStore.isAzure;
    const isAWS = createJobStore.isAWS;
    const { dataMover,infrastructureType } = rootStore.createJobStore.baseSettings;
    const [sourcepasswordVisibility, setSourcePasswordVisibility] = useState(true);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [UserPassAutocomplete, setUserPassAutocomplete] = useState(false);
    const [showEyeSourcePassword, setShowEyeSourcePassword] = useState(true);
    const [InfraUserPassAutocomplete,setInfraUserPassAutocomplete] = useState(false);
    const [targetpasswordVisibility,setTargetPasswordVisibility] = useState(true);
    const [showEyeInfraPassword, setShowEyeInfraPassword]= useState(true);
    const [openConfirmationDialog, setopenConfirmationDialog] = useState(false);
    const portRegex = /^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/;
    
    const previousSourceUsername = jobSettingsStore.sourceUserNamesAndPass;
    const previousTargetIPInfra = jobSettingsStore.PreviousTargetIPInfra;
    const previousSourceIPInfra = jobSettingsStore.PreviousSourceIPInfra;
    const previousTargetUsername = jobSettingsStore.targetUserNamesAndPass;

    function setInitialPort() {
        if (infrastructureType == InfrastructureType.VMware)
            return 902;
        else
            return 0;
    }

    const form = useFormik({
        initialValues: {
            infraTargetIP:'',
            infraSourceIP:'',
            infraSourceUsername: '',
            infraSourcePassword: '',
            infraTargetUsername:'',
            infraTargetPassword:'',
            infraSourcePort: setInitialPort(),
            infraTargetPort: setInitialPort(),
            dataMover: null
        },
        validationSchema:isAzure ? AzurevalidationSchema: (isAWS ? AWSvalidationSchema :VmwareValidationSchema),
        validateOnChange:true,
        onSubmit: () => { },
    })

    useEffect(() => {
        //let infra = jobSettingsStore.infrastructureList.find(inf => inf.id === createJobStore.baseSettings.infrastructureId);
        const initialValues =
        {
            infraTargetIP: BaseDrawerSettings.addInfrastructure ? '' :jobSettingsStore.selectedInfra !=undefined ?jobSettingsStore.selectedInfra.infrastructureTarget:'',
            infraSourceIP: BaseDrawerSettings.addInfrastructure ? '' : jobSettingsStore.selectedInfra!=undefined ? jobSettingsStore.selectedInfra.infrastructureSource:'',
            infraSourceUsername: BaseDrawerSettings.addInfrastructure ? '' :jobSettingsStore.selectedInfra!=undefined  && jobSettingsStore.selectedInfra.sourceInfraCredentials ? jobSettingsStore.selectedInfra.sourceInfraCredentials.userName:'',
            infraSourcePassword: BaseDrawerSettings.addInfrastructure ? '' :jobSettingsStore.selectedInfra!=undefined && jobSettingsStore.selectedInfra.sourceInfraCredentials ? jobSettingsStore.selectedInfra.sourceInfraCredentials.password:'',
            infraTargetUsername:BaseDrawerSettings.addInfrastructure ? '' :jobSettingsStore.selectedInfra!=undefined && jobSettingsStore.selectedInfra.targetInfraCredentials ? jobSettingsStore.selectedInfra.targetInfraCredentials.userName:'',
            infraTargetPassword:BaseDrawerSettings.addInfrastructure ? '' :jobSettingsStore.selectedInfra!=undefined  && jobSettingsStore.selectedInfra.targetInfraCredentials ? jobSettingsStore.selectedInfra.targetInfraCredentials.password:'',
            infraSourcePort: BaseDrawerSettings.addInfrastructure ? setInitialPort() : jobSettingsStore.selectedInfra!=undefined ? jobSettingsStore.selectedInfra.infrastructureSourcePort :setInitialPort(),
            infraTargetPort: BaseDrawerSettings.addInfrastructure ? setInitialPort() : jobSettingsStore.selectedInfra!=undefined  ? jobSettingsStore.selectedInfra.infrastructureTargetPort :setInitialPort(),
            dataMover: rootStore.createJobStore.baseSettings.dataMover
        }; 
        form.resetForm({values: initialValues});
    }, [BaseDrawerSettings.addInfrastructure]);

    const handleClickShowPassword = (type:string) => {
        switch(type)
        {
            case "infraSource":  setSourcePasswordVisibility(sourcepasswordVisibility ? false : true);break;
            case "infraTarget":  setTargetPasswordVisibility(targetpasswordVisibility? false: true); break;
        }
    };

    const handleSourceUsernameChange = (event:React.ChangeEvent,value:any,onInput:boolean) => {
        setUserPassAutocomplete(onInput);
        if (value!=null && typeof value === 'object') 
        {
          form.setFieldValue("infraSourceUsername",value=="" ? '':value.username,true);
          form.setFieldValue("infraSourcePassword",'',true);
        }
        if (typeof value === 'string')
        {
          const userObj = previousSourceUsername.find(e => e.username.toLocaleLowerCase() == value.toLocaleLowerCase());
          let user = userObj ? userObj.username : value;
          form.setFieldValue("infraSourceUsername", user,true);
          let pass ='';
          form.setFieldValue("infraSourcePassword",pass,true);
        }
        if(value === null)
        {
          form.setFieldValue("infraSourceUsername", '',true);
          form.setFieldValue("infraSourcePassword",'',true);
        }
        form.setFieldTouched('infraSourceUsername', true);
        setShowEyeSourcePassword(onInput);
        if(!onInput)
        {
            setSourcePasswordVisibility(true);
        }
        //event.stopPropagation &&  event.stopPropagation();
        //event.preventDefault();
    };

    const handleSourcePassChange = (event) => {
        setUserPassAutocomplete(true);
        setShowEyeSourcePassword(true);
    };

    const handleSourcePortChange = (event) => {
        form.setFieldValue("infraSourcePort",parseInt(event.target.value),true);
    };

    const handleTargetPortChange = (event) => {
        form.setFieldValue("infraTargetPort",parseInt(event.target.value),true);
    };

    const handleInfraTargetIPChange = (event:object,value:any)=>{
        form.setFieldValue("infraTargetIP",value,true);
        //createJobStore.updateBaseSettings({targetInfarstructure: {...createJobStore.baseSettings.targetInfarstructure, server: value}});
    };

    const handleInfraSourceIPChange = (event:object,value:any)=>{
        form.setFieldValue("infraSourceIP",value,true);
        //createJobStore.updateBaseSettings({sourceInfrastructure: {...createJobStore.baseSettings.sourceInfrastructure, server: value}});
        //CheckSectionAndDrawerStatus("infrastructure");
     };

    const handleTargetPassChange = (event) => {
        setInfraUserPassAutocomplete(true);
        setShowEyeInfraPassword(true);
    };

    const handleTargetUsernameChange = (event:React.ChangeEvent,value:any,onInput:boolean) => {
        setInfraUserPassAutocomplete(onInput);
        if (value!=null && typeof value === 'object') 
        {
          form.setFieldValue("infraTargetUsername",value.username,true);
          form.setFieldValue("infraTargetPassword",'',true);
          let credentials= {id: value=="" ? 0:value.id, userName: value=="" ? '':value.username ,password: value=="" ? '':value.password, userType:CredentialUserType.GeneralUser};
          rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController, domainCredentials : credentials}});
        }
        if (typeof value === 'string')
        {
          const userObj = previousTargetUsername.find(e => e.username.toLocaleLowerCase() == value.toLocaleLowerCase());
          let user = userObj ? userObj.username : value
          form.setFieldValue("infraTargetUsername", user,true);
          let pass = '';
          let id = userObj== undefined ? 0 :userObj.id;
          form.setFieldValue("infraTargetPassword",pass,true);
          rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController, domainCredentials : {id: id,userName: user, password: pass, userType:CredentialUserType.GeneralUser}}});
        }
        if(value === null)
        {
          form.setFieldValue("infraTargetUsername", '',true);
          form.setFieldValue("infraTargetPassword",'',true);
        }
        setShowEyeInfraPassword(onInput);
        if(!onInput)
        {
            setTargetPasswordVisibility(true);
        }
        // event.stopPropagation &&  event.stopPropagation();
        // event.preventDefault();
      };
      

    const handleValueChange = (event: React.ChangeEvent) => {
        form.handleChange(event);
        switch (event.target['name']) {
            case 'infraTargetPassword': handleTargetPassChange(event); break;
            case 'infraSourcePassword': handleSourcePassChange(event); break;
            case 'infraSourcePort': handleSourcePortChange(event); break;
            case 'infraTargetPort': handleTargetPortChange(event); break;
        }
    }

    const handleAutoCompleteChange = (event: React.ChangeEvent, value: any) => {
        if (event != null) {
            let fieldName = event.target['id'].includes('-') ? event.target['id'].split('-')[0] : event.target['id'];
            // form.setFieldValue(fieldName, value);
            switch (fieldName) {
                case 'infraSourceUsername': handleSourceUsernameChange(event,value,event.type=="click"?false:true); break;
                case 'infraTargetUsername': handleTargetUsernameChange(event,value,event.type=="click"?false:true); break;
                case 'infraTargetIP': handleInfraTargetIPChange(event,value); break;
                case 'infraSourceIP': handleInfraSourceIPChange(event,value); break;
                case 'tenantId': handleInfraSourceIPChange(event,value); break;
            }
        }
    }
    
    const showInfraSource= ()=>{
        if(isPaaS) 
          return false;
        else
          return true;
      }
      
      const showInfraTarget= ()=>{
        if(isPaaS || isAzure || isAWS) 
          return false;
        else
          return true;
      }

    const loadInfraTarget=() =>{
        return(<Tooltip title={isAzure ? t('TenantID'): (isAWS ? t('ensuredrAWS'):t('infraTargetInfo'))} placement="top">
        <FormControl className={classes.formControl} variant='outlined'>
                  <EDRAutocomplete id="infraTargetIP"
                  freeSolo
                  disableClearable
                  value={form.values.infraTargetIP}
                  options={previousTargetIPInfra}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteChange}
                  renderInput={(params) => (<EDRTextField {...params} label={isAzure ? t('TenantID'):(isAWS ? t('ensuredrAWS'):"* "+INFRA_NAME[infrastructureType]+" "+t("vcenter")+t('targetIP'))} 
                  InputLabelProps={{shrink: true}} 
                  variant="outlined"
                  placeholder="Type Here" 
                  onBlur={form.handleBlur}
                  error={form.touched && form.touched.infraTargetIP && form.errors && Boolean(form.errors.infraTargetIP)}
                  helperText={form.touched && form.touched.infraTargetIP && form.errors && Boolean(form.errors.infraTargetIP) && t(form.errors.infraTargetIP)}
                  />)}
                />
                  </FormControl>
                  </Tooltip>
      );
    }
  
    const loadInfraSource =() =>{
        return(<Tooltip title={isAzure ? t('SubscriptionId'):(isAWS ? t('region'): t('infraSourceInfo'))} placement="top">
        <FormControl className={classes.formControl} variant='outlined'>
                <EDRAutocomplete id="infraSourceIP"
                  freeSolo
                  disableClearable
                  value={form.values.infraSourceIP}
                  options={previousSourceIPInfra}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteChange}
                  renderInput={(params) => (<EDRTextField {...params}
                  label={isAzure ? t("SubscriptionId"):(isAWS ? t('region'): `* ${INFRA_NAME[infrastructureType]} ${t("vcenter")} ${t('sourceIP')}`)} 
                  InputLabelProps={{shrink: true}} 
                  variant="outlined"
                  placeholder="Type Here"
                  onBlur={form.handleBlur}
                  error={form.touched && form.touched.infraSourceIP && form.errors && Boolean(form.errors.infraSourceIP)}
                  helperText={form.touched &&  form.touched.infraSourceIP && form.errors && Boolean(form.errors.infraSourceIP) && t(form.errors.infraSourceIP)}
                  />)}
                />
                </FormControl>
                </Tooltip>
        );
    }

    const handleTargetUsernameChangeManual = (event:any) => {
        if(form.values.infraTargetPassword!="")
        {
          form.setFieldValue("infraTargetPassword","",true);
        }
        form.setFieldValue("infraTargetUsername",event=="" ? '':event,true);
        let credentials= {id: createJobStore.networkSetting.domainController.domainCredentials.id, userName: event=="" ? '':event ,password: '',userType:CredentialUserType.GeneralUser};
        rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController, domainCredentials : credentials}});
      }      

    const handleAutoCompleteTargetChangeInput = (event: any,value:any) => {
        if(event != null)
          handleTargetUsernameChangeManual(event.target.value);
      }

      const handleTargetPasswordChangeManual = (event:any) => {
        setInfraUserPassAutocomplete(true);
        form.setFieldValue("infraTargetPassword",event.target.value=="" ? '':event.target.value,true);
      }

      const handleSourcePasswordChangeManual = (event:any) => {
        setUserPassAutocomplete(true);
        form.setFieldValue("infraSourcePassword",event.target.value=="" ? '':event.target.value,true);
      }

      const handleMouseClick =(type:string)=>{
          switch(type)
          {
            case "infraSourcePassword":
                {
                    if(form.values.infraSourcePassword === CONSTS.MaskedPassword)
                    {
                        form.setFieldValue("infraSourcePassword",'',true);
                    }
                    break;
                }
            case "infraTargetPassword":
                {
                    if(form.values.infraTargetPassword === CONSTS.MaskedPassword)
                    {
                        form.setFieldValue("infraTargetPassword",'',true);
                    }
                    break;
                }
          }
    }

    const handleMouseOut =(type:string)=>{
        switch(type)
        {
            case "infraSourcePassword":
                {
                    if(form.dirty && form.values.infraSourcePassword == '')
                    {
                        if(form.values.infraSourceUsername!='')
                        {
                            return;
                        }
                        else form.setFieldValue("infraSourcePassword",CONSTS.MaskedPassword,true);
                    }
                    break;
                }
            case "infraTargetPassword":
                {
                    if(form.dirty && form.values.infraTargetPassword == '')
                    {
                        if(form.values.infraTargetUsername!='')
                        {
                            return;
                        }
                        else form.setFieldValue("infraTargetPassword",CONSTS.MaskedPassword,true);
                    }
                    break;
                }
        }
    }
      
      const handleSourceUsernameChangeManual = (event:any) => {
        if(form.values.infraSourceUsername!="")
        {
          form.setFieldValue("infraSourcePassword","",true);
        }
        form.setFieldValue("infraSourceUsername",event=="" ? '':event,true);
      }   

 

    const loadInfraTargetUsername= ()=> {
        return (<Tooltip title={isAzure ? t('ClientID'):(isAWS ? t('awsKey'):t('infraUserPassInfo'))} placement="top">
                <FormControl className={classes.formControl} variant='outlined'>
                            <EDRAutocomplete id="infraTargetUsername"
                            freeSolo
                            disableClearable
                            value={form.values.infraTargetUsername}
                            options={previousTargetUsername}
                            renderOption={(option: string|{username: string,password:string}) => (typeof option === 'object' ? option.username : option)}
                            onChange={handleAutoCompleteChange}
                            onInputChange={handleAutoCompleteChange}
                            getOptionLabel={(option: string|{username: string,password:string}) => (typeof option === 'object' ? option.username : option)}
                            renderInput={(params) => (<EDRTextField {...params}
                            label= {isAzure ? t('ClientID'):(isAWS ? t('awsKey'):"* "+INFRA_NAME[infrastructureType]+" "+t("vcenter")+(isPaaS ?t('ClientID'):t('targetUsername')))}
                            InputLabelProps={{shrink: true}} 
                            variant="outlined"
                            placeholder="Type Here"
                            aria-autocomplete="none"
                            onBlur={form.handleBlur}
                            error={form.touched && form.touched.infraTargetUsername && form.errors &&  Boolean(form.errors.infraTargetUsername)}
                            helperText={form.touched && form.touched.infraTargetUsername && form.errors &&  Boolean(form.errors.infraTargetUsername) && t(form.errors.infraTargetUsername)}
                            />)}
                            />
                        </FormControl>
                        </Tooltip>
        );
    }
    
      const loadInfraTargetPassword= ()=> {
        return(
        <Tooltip title={isAzure ? t('ClientSecret'):(isAWS ? t('awsSecret'):t('infraUserPassInfo'))} placement="top">
        <FormControl className={classes.formControl} variant='outlined'>
        <InputLabel ref={ref => {targetlabelRef = ReactDOM.findDOMNode(ref)}}  style={{transform: 'translate(14px, -7px) scale(0.75)'}}>{isAzure ? t('ClientSecret'):(isAWS ? t('awsSecret'):"* "+INFRA_NAME[infrastructureType]+" "+t("vcenter")+(isPaaS ?t('secret'):t('targetPassword')))}</InputLabel>
        <EDROutlinedInput id="infraTargetPassword" 
            name="infraTargetPassword"
            value={form.values.infraTargetPassword}
            labelWidth={targetlabelRef ? targetlabelRef.offsetWidth:0}
            autoComplete="off"
            type={targetpasswordVisibility ? 'password':'text'}
            placeholder="Type Here"
            error={form.touched && form.touched.infraTargetPassword && form.errors && Boolean(form.errors.infraTargetPassword)}
            onChange={handleTargetPasswordChangeManual}
            onClick={event=> {handleMouseClick("infraTargetPassword")}}
            onBlur={event=> {handleMouseOut("infraTargetPassword")}}
            endAdornment={ form.values.infraTargetPassword && InfraUserPassAutocomplete?
            <InputAdornment position="end">
            <IconButton
            aria-label="toggle password visibility"
            aria-autocomplete="none"
            onClick={()=> handleClickShowPassword("infraTarget")}
            >
            {targetpasswordVisibility ?  <VisibilityOff />: <Visibility />}
        </IconButton>
    </InputAdornment>
    :null}
    />  
    </FormControl>
    </Tooltip>
        );
    }      

      
    const loadSourceSettings = () => {
        switch (rootStore.createJobStore.baseSettings.dataMover) {
            default:
                {
                    return (
                        <React.Fragment>
                            <Grid container style={{ marginTop: '16px' }} spacing={2}>
                            <Grid item xs={6}>
                            {showInfraSource()?loadInfraSource():null}
                            </Grid>
                            <Grid item xs={6}>
                                {/*<FormControl className={classes.formControl} variant='outlined'>
                                    <EDRTextField id="infraSourcePort"
                                        name="infraSourcePort"
                                        label={t('port')}
                                        type="number"
                                        placeholder=""
                                        style={{ margin:'0px 8px 8px 8px'}}
                                        value={form.values.infraSourcePort}
                                        onChange={handleValueChange}
                                        onBlur={form.handleBlur}
                                        error={Boolean(form.errors.infraSourcePort)}
                                        helperText={t(form.errors.infraSourcePort)}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined">
                                    </EDRTextField>
                                    </FormControl>*/}
                            </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{ marginTop: 8 }} >
                                <Grid item xs={6}>
                                <Tooltip title={t('infraUserPassInfo')} placement="top">
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <EDRAutocomplete id="infraSourceUsername"
                                            freeSolo
                                            value={form.values.infraSourceUsername}
                                            options={previousSourceUsername}
                                            renderOption={(option: string | { username: string, password: string }) => (typeof option === 'string' ? option : `${option.username}`)}
                                            getOptionLabel={(option: string | { username: string, password: string }) => (typeof option === 'string' ? option : `${option.username}`)}
                                            onChange={handleAutoCompleteChange}
                                            onInputChange={handleAutoCompleteChange}
                                            renderInput={(params) => (<EDRTextField {...params}
                                                label={"* "+INFRA_NAME[infrastructureType]+" "+t("vcenter")+t('sourceUsername')}
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                placeholder="Type Here"
                                                aria-autocomplete="none"
                                                style={{ marginTop: 8, backgroundColor: '#ffffff' }}
                                                onBlur={form.handleBlur}
                                                error={form.touched && form.touched.infraSourceUsername && form.errors &&  Boolean(form.errors.infraSourceUsername)}
                                                helperText={form.touched && form.touched.infraSourceUsername && form.errors &&  Boolean(form.errors.infraSourceUsername) && t(form.errors.infraSourceUsername)}
                                            />)}
                                        />
                                    </FormControl>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6}>
                                    <Tooltip title={t('infraUserPassInfo')} placement="top">
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <InputLabel ref={ref => { targetlabelRef = ReactDOM.findDOMNode(ref) }} style={{ transform: 'translate(14px, 2px) scale(0.75)' }}>{"* "+INFRA_NAME[infrastructureType]+" "+t("vcenter")+t('sourcePassword')}</InputLabel>
                                        <EDROutlinedInput id="infraSourcePassword"
                                            name="infraSourcePassword"
                                            value={form.values.infraSourcePassword}
                                            autoComplete="off"
                                            aria-autocomplete="none"
                                            type={sourcepasswordVisibility ? 'password' : 'text'}
                                            placeholder="Type Here"
                                            label={<InputLabel style={{ marginTop: '8px' }}>{t('password')}</InputLabel>}
                                            style={{ margin: 8, backgroundColor: '#ffffff' }}
                                            error={form.touched && form.touched.infraSourcePassword && form.errors && Boolean(form.errors.infraSourcePassword)}
                                            onChange={handleSourcePasswordChangeManual}
                                            onClick={event=> {handleMouseClick("infraSourcePassword")}}
                                            onBlur={event=> {handleMouseOut("infraSourcePassword")}}
                                            endAdornment={form.values.infraSourcePassword && UserPassAutocomplete ?
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={()=> handleClickShowPassword("infraSource")}>
                                                        {sourcepasswordVisibility ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton></InputAdornment> : null} />
                                    </FormControl>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )
                }
        }
    };

    const loadInfraTargetAWS = () => {
        return (
        <Tooltip title={t('region')} placement="top">
        <FormControl className={classes.formControl} variant='outlined'>
          <EDRAutocomplete id="infraTargetIP"
            freeSolo
            disableClearable
            value={form.values && form.values.infraTargetIP != null ? form.values.infraTargetIP : ''}
            options={regions.map((data,key) => {return data.RegionName})}
            onChange={handleAutoCompleteChange}
            onInputChange={handleAutoCompleteChange}
            onBlur={() => {
              form.setTouched({
                ...form.touched,
                infraTargetIP: true
              })
            }}
            renderInput={(params) => (<EDRTextField {...params} label={t('region')}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              placeholder="Type Here"
              aria-autocomplete="none"
              error={!form.isValidating && form.touched?.infraTargetIP && Boolean(typeof form.errors.infraTargetIP == "string"? form.errors.infraTargetIP: form.errors.infraTargetIP)}
              helperText={!form.isValidating && form.touched?.infraTargetIP && (t(typeof form.errors?.infraTargetIP == "string" ? form.errors.infraTargetIP : form.errors.infraTargetIP)) }
            />)}
          />
          <React.Fragment>{form.touched?.infraTargetIP && !form.isValidating && !form.errors.infraTargetIP && <CheckCircle className={classes.validateIcon} />}</React.Fragment>
        </FormControl>
        </Tooltip>
        );
      }

    const loadTargetSettings = () => {
        switch (rootStore.createJobStore.baseSettings.dataMover) {
            default:
                {
                    return (
                        <React.Fragment>
                            <Grid container style={{ marginTop: '16px' }} spacing={2}>
                            {isAWS ?
                            <Grid item xs={12}>
                                {loadInfraTargetAWS()}
                            </Grid>:
                            <React.Fragment>
                            <Grid item xs={6}>
                            {loadInfraTarget()}
                            </Grid>
                            <Grid item xs={6}>
                            {isAzure || isAWS ? loadInfraSource():null}
                            </Grid>
                            </React.Fragment>
                            }
                            </Grid>
                            
                            <Grid container style={{ marginTop: '16px' }} spacing={2}>
                            <Grid item xs={6}>
                                {loadInfraTargetUsername()}
                            </Grid>
                            <Grid item xs={6}>
                                {loadInfraTargetPassword()}
                            </Grid>
                            </Grid>
                        </React.Fragment>
                    )
                }
        }
    };

    const handleAdd =  async () => {
        setButtonsDisabled(true);
        let sourceCredentials = new AccessCredentials();
        sourceCredentials = {id :0 , userName:form.values.infraSourceUsername, password:form.values.infraSourcePassword, userType:CredentialUserType.InfraUser};
        let targetCredentials = new AccessCredentials();
        targetCredentials = {id : 0 , userName:form.values.infraTargetUsername, password:form.values.infraTargetPassword, userType:CredentialUserType.InfraUser};
        let InfraDetails = new InfrastructureDTO();

        InfraDetails.infrastructureTypeId = createJobStore.baseSettings.infrastructureType
        InfraDetails.sourceInfraCredentials = isAzure || isAWS ? targetCredentials: sourceCredentials
        InfraDetails.targetInfraCredentials = targetCredentials
        InfraDetails.infrastructureSource = form.values.infraSourceIP
        InfraDetails.infrastructureTarget = form.values.infraTargetIP
        InfraDetails.infrastructureSourcePort = form.values.infraSourcePort
        InfraDetails.infrastructureTargetPort = form.values.infraTargetPort

        jobSettingsStore.updateTempInfra(InfraDetails);
        await jobSettingsStore.createInfra();
        displayStore.updateBaseDrawerSettings({InfraDrawerOpen:false});
        displayStore.updateScreenSettings({ displayBaseSettings: 'EmptyVlan' });
        displayStore.updateBaseDrawerSettings({addInfrastructure:false});
        setButtonsDisabled(false);
    }

    const handleUpdate = async() =>{
        setButtonsDisabled(true);
        let sourceCredentials = new AccessCredentials();
        sourceCredentials = {id : jobSettingsStore.selectedInfra.sourceInfraCredentials.id , userName:form.values.infraSourceUsername, password:form.values.infraSourcePassword, userType:CredentialUserType.InfraUser};
        let targetCredentials = new AccessCredentials();
        targetCredentials = {id : jobSettingsStore.selectedInfra.targetInfraCredentials.id , userName:form.values.infraTargetUsername, password:form.values.infraTargetPassword, userType:CredentialUserType.InfraUser};
        let InfraDetails = {
            id: createJobStore.baseSettings.infrastructureId,
            infrastructureTypeId: createJobStore.baseSettings.infrastructureType,
            sourceInfraCredentials: isAzure || isAWS ? targetCredentials: sourceCredentials,
            targetInfraCredentials : targetCredentials,
            infrastructureSource:  form.values.infraSourceIP,
            infrastructureTarget: form.values.infraTargetIP,
            infrastructureSourcePort:form.values.infraSourcePort,
            infrastructureTargetPort:form.values.infraTargetPort
        };
        await jobSettingsStore.editInfra(InfraDetails);
        displayStore.updateBaseDrawerSettings({InfraDrawerOpen:false});
        displayStore.updateScreenSettings({ displayBaseSettings: 'EmptyVlan' });
        displayStore.updateBaseDrawerSettings({addInfrastructure:false});
        setButtonsDisabled(false);
    }

    const handleDelete  = async() =>{
        setButtonsDisabled(true);
        await jobSettingsStore.deleteInfra();
        setButtonsDisabled(false);
    }

    
    const handleConfirmationPopup =(confirm:boolean)=>{
        if(confirm)
        {
            handleDelete();
        }
        setopenConfirmationDialog(false);
    }
    return (
        <Grid container direction="column" style={{ paddingRight: 16 }}>
            
            <div style={{ display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
                <Typography variant='h6' className={classes.MainHeading}>{BaseDrawerSettings.addInfrastructure ? t("addInfrastrucutre"):t("editInfrastrucutre")}</Typography>
                <div style={{ display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
                {!BaseDrawerSettings.addInfrastructure ? <SaveButton onClick={()=>setopenConfirmationDialog(true)} disabled={buttonsDisabled} variant="contained" color="primary" style={{height:'25px',textTransform:'none',marginRight:'10px'}}>
                    {t('delete')}
                    {buttonsDisabled ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                </SaveButton>:null}
                <SaveButton onClick={BaseDrawerSettings.addInfrastructure ? handleAdd :handleUpdate} disabled={!form.isValid || buttonsDisabled} variant="contained" color="primary" style={{height:'25px',textTransform:'none'}}>
                    {BaseDrawerSettings.addInfrastructure ? t('add'): t('apply')}
                    {buttonsDisabled ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                </SaveButton>  
                </div> 
            </div>
                    
            <Grid container item direction="row">
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '30px', marginLeft: '10px' }}>
                    <Typography className={classes.SecondHeading} style={{ color: '#4d5f75' }}>{isAWS ? t('awsSettings'):t('targetSettings')}</Typography>
                    <Tooltip title={isAWS ? t('AWStargetSettingsInfo'):(createJobStore.baseSettings.dataMover == DataMover.ASR ? t("ASRtargetSettingsInfo"):t("targetSettingsInfo"))} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider} />
                </div>
            </Grid>
            {loadTargetSettings()}
            {!isAzure && !isAWS &&
            <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '30px', marginLeft: '10px' }}>
                    <Typography className={classes.SecondHeading} style={{ color: '#4d5f75' }}>{t('sourceSettings')}</Typography>
                    <Tooltip title={t("sourceSettingsInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider} />
                </div>
            </Grid>}
            {!isAzure && !isAWS && loadSourceSettings()}
            <ConfirmationDialog type="deleteInfra" device={null} open={openConfirmationDialog} onClose={(confrim)=>handleConfirmationPopup(confrim)} children={"You are about to delete existing infrastructure, are you sure ?"}></ConfirmationDialog>
        </Grid>
    );
};


export default observer(InfrastructAdvancedComponent);