import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox, Divider, FormControlLabel, Grid, makeStyles, Switch, Tooltip, Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RootStoreContext } from '../../../../app/stores/root.store';
import { EDRTextField } from '../../../../app/common/EDRTextField';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import HelpIcon from '@material-ui/icons/Help';
import { useFormik } from 'formik';
import * as yup from 'yup';

export const CustomCheckbox = withStyles({
    root: {
      color: '#4d5f75',
      '&$checked': {
        color:'#2892d7',
      },
    },
    checked: {},
})(Checkbox);

const BasicAdvancedComponent : React.FC = () => {
    const useStyles = makeStyles({
        helpIcon:
        {
          color: '#1976d2',
          fontSize: "large"
        },
        MainHeading:
        {
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.19',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#1f396d',
            marginLeft:'10px'
        },
        SecondHeading:{
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1
        },
        divider: {
                backgroundColor: '#eaeef4',
                width: '260%',
                margin: '0',
                marginTop: '10px',
                marginLeft: '10px',
        },
        });   

    const classes = useStyles();
    const [t] = useTranslation("createJobBase");
    const rootStore = useContext(RootStoreContext); 
    const {settingStore}= rootStore;

    const validation = yup.object({
        rto:yup.string()
        .required('Required'),
        powerOnTime:yup.string()
        .required('Required'),
        parallelTestExecution:yup.string()
        .required('Required'),
        maxTestTimeout:yup.string()
        .required('Required'),
        timeEngineFinish:yup.string()
        .required('Required'),
        failoverTimeout:yup.string()
        .required('Required'),
        testHeartbeat:yup.boolean()
      })
    
      const form = useFormik({
        initialValues: {
            rto:'0',
            powerOnTime:'0',
            parallelTestExecution:'0',
            maxTestTimeout: '0',
            timeEngineFinish:'0',
            failoverTimeout:'0',
            testHeartbeat: true
        },
        validationSchema:validation,
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: () => { },
      })


    useEffect(() => {
        const initialValues = 
        {
              rto:rootStore.createJobStore.baseSettings.rto != null ? rootStore.createJobStore.baseSettings.rto.toString() : settingStore.createJobSettings.find(item=>item.settingKey == 'ExpectedRTO')?.settingValue ?? '60',
              powerOnTime:rootStore.createJobStore.baseSettings.powerOnTime != null ? rootStore.createJobStore.baseSettings.powerOnTime.toString(): settingStore.createJobSettings.find(item=>item.settingKey == 'PowerOnWaitTime')?.settingValue ?? '120',
              parallelTestExecution:rootStore.createJobStore.baseSettings.parallelTestExecution != null ? rootStore.createJobStore.baseSettings.parallelTestExecution.toString() : settingStore.createJobSettings.find(item=>item.settingKey == 'ParaellelTestExecutionLimit').settingValue ?? '30',
              maxTestTimeout: rootStore.createJobStore.baseSettings.maxTestTimeout != null ? rootStore.createJobStore.baseSettings.maxTestTimeout.toString() : settingStore.createJobSettings.find(item=>item.settingKey == 'MaximumTestTimeout')?.settingValue ?? '300',
              timeEngineFinish:rootStore.createJobStore.baseSettings.timeEngineFinish != null ? rootStore.createJobStore.baseSettings.timeEngineFinish.toString() : settingStore.createJobSettings.find(item=>item.settingKey == 'EngineTTL')?.settingValue ?? '3000',
              failoverTimeout:rootStore.createJobStore.baseSettings.failoverTimeout != null ? rootStore.createJobStore.baseSettings.failoverTimeout.toString() : settingStore.createJobSettings.find(item=>item.settingKey == 'FOTTL')?.settingValue ?? '7200',
              testHeartbeat:rootStore.createJobStore.baseSettings.testHeartbeat !=null ? rootStore.createJobStore.baseSettings.testHeartbeat :settingStore.createJobSettings.find(item=>item.settingKey == 'TestHB')?.settingValue === 'True' ?? false
            }
        form.setValues(initialValues);
      }, []); 

    const handleRTOChange =(event)=> {
        form.setFieldValue('rto',event.target.value,true);
        rootStore.createJobStore.updateBaseSettings({rto: parseInt(event.target.value)});
    }

    const handleWaitTimeChange =(event)=> {
        form.setFieldValue('powerOnTime',event.target.value,true);
        rootStore.createJobStore.updateBaseSettings({powerOnTime: parseInt(event.target.value)});
    }

    const handleParallelTestExecutionChange =(event)=>{
        form.setFieldValue('parallelTestExecution',event.target.value,true);
        rootStore.createJobStore.updateBaseSettings({ parallelTestExecution: parseInt(event.target.value)});
    }

    const handleMaxTestTimeoutChange =(event)=>{
        form.setFieldValue('maxTestTimeout',event.target.value,true);
        rootStore.createJobStore.updateBaseSettings({maxTestTimeout : parseInt(event.target.value)});
    }

    const handleTimeEngineFinishChange =(event)=>{
        form.setFieldValue('timeEngineFinish',event.target.value,true);
        rootStore.createJobStore.updateBaseSettings({timeEngineFinish: parseInt(event.target.value)});
    }

    const handleFaileOverTimeOutChange =(event)=>{
      form.setFieldValue('failoverTimeout',event.target.value,true);
      rootStore.createJobStore.updateBaseSettings({failoverTimeout: parseInt(event.target.value)});
  }

    const handleTestHeartbeatChange = (event) =>{
        form.setFieldValue('testHeartbeat',event.target.checked,true);
        rootStore.createJobStore.updateBaseSettings({testHeartbeat: event.target.checked});
    };

    useEffect(() => {
        rootStore.displayStore.updateNextStepValid({basicAdvancedValid:form.isValid});
      },[form.isValid,form.isValidating,form.values])

    
    return(
        <Grid container direction="column" style={{paddingRight: 16}}>
             <Typography variant='h6' className={classes.MainHeading}>{t('jobSettings')}</Typography>
            
            <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px',marginLeft:'10px' }}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('recoveryTimeout')}</Typography> 
                    <Tooltip title={t("recoveryTimeoutInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider}/>
                </div>
            </Grid>
            
            <Grid item xs={6}>
            <EDRTextField id="rto"
            name="rto"
            label={t('rto')}
            value={form.values.rto}
            type="number"
            InputProps={{ inputProps: { min: 1} }}
            onChange={handleRTOChange}
            margin="normal"
            InputLabelProps={{
              shrink: true,

            }}
            fullWidth
            error={Boolean(form.errors.rto)}
            helperText={t(form.errors.rto)}
            variant="outlined">
          </EDRTextField>
      </Grid>

      <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '30px',marginLeft:'10px' }}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('parallelTestExecution')}</Typography> 
                    <Tooltip title={t("parallelTestExecutionInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider}/>
                </div>
        </Grid>

      <Grid item xs={6}>
            <EDRTextField id="parallelTestExecution"
            name="parallelTestExecution"
            label={t('parallelTestExecution')}
            value={form.values.parallelTestExecution}
            onChange={handleParallelTestExecutionChange}
            margin="normal"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            error={Boolean(form.errors.parallelTestExecution)}
            helperText={t(form.errors.parallelTestExecution)}
            variant="outlined">
          </EDRTextField>
      </Grid>

      <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '30px',marginLeft:'10px' }}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('maxTestTimeout')}</Typography> 
                    <Tooltip title={t("maxTestTimeoutInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider}/>
                </div>
        </Grid>

      <Grid item xs={6}>
            <EDRTextField id="maxTestTimeout"
            name="maxTestTimeout"
            label={t('maxTestTimeout')}
            value={form.values.maxTestTimeout}
            onChange={handleMaxTestTimeoutChange}
            margin="normal"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            error={Boolean(form.errors.maxTestTimeout)}
            helperText={t(form.errors.maxTestTimeout)}
            variant="outlined">
          </EDRTextField>
      </Grid>

      <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '30px',marginLeft:'10px' }}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('timeEngineFinish')}</Typography> 
                    <Tooltip title={t("timeEngineFinishInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider}/>
                </div>
        </Grid>

      <Grid item xs={6}>
            <EDRTextField id="timeEngineFinish"
            name="timeEngineFinish"
            label={t('timeEngineFinish')}
            value={form.values.timeEngineFinish}
            onChange={handleTimeEngineFinishChange}
            margin="normal"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            error={Boolean(form.errors.timeEngineFinish)}
            helperText={t(form.errors.timeEngineFinish)}
            variant="outlined">
          </EDRTextField>
      </Grid>

      <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '30px',marginLeft:'10px' }}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('poweronTimeout')}</Typography> 
                    <Tooltip title={t("poweronTimeoutInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider}/>
                </div>
        </Grid>

      <Grid item xs={6}>
            <EDRTextField id="waitTimePowerOn"
            name="waitTimePowerOn"
            label={t('waitTimePowerOn')}
            value={form.values.powerOnTime}
            onChange={handleWaitTimeChange}
            margin="normal"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            error={Boolean(form.errors.powerOnTime)}
            helperText={t(form.errors.powerOnTime)}
            variant="outlined">
          </EDRTextField>
      </Grid>

      <Grid item xs={8} style={{marginTop: '10px'}}>
      <FormControlLabel 
        value={t('testHeartbeat')}
        control={<CustomCheckbox icon={<CircleUnchecked/>} checkedIcon={<CircleCheckedFilled />} checked={form.values.testHeartbeat} onChange={handleTestHeartbeatChange}/>}
        label={<Typography>{t('testHeartbeat')}</Typography>}
      />
      </Grid>

      <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px',marginLeft:'10px' }}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('failoverTimeout')}</Typography> 
                    <Tooltip title={t("failoverTimeoutInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider}/>
                </div>
        </Grid>

      <Grid item xs={6}>
            <EDRTextField id="failoverTimeout"
            name="failoverTimeout"
            label={t('failoverTimeout')}
            value={form.values.failoverTimeout}
            onChange={handleFaileOverTimeOutChange}
            margin="normal"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            error={Boolean(form.errors.failoverTimeout)}
            helperText={t(form.errors.failoverTimeout)}
            variant="outlined">
          </EDRTextField>
      </Grid>

    </Grid>
    );
};

export default observer(BasicAdvancedComponent);