import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import ActivityIcon from "./ActivityIcon.component";
import ActivityItem from "./ActivityItem.component";
import {
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Grid,
  makeStyles,
  Typography,
  Divider,
  TableRow,
} from "@material-ui/core";
import { ActivityIconType } from "../../../app/models/dashboard/dashboard.enum";
import { useTranslation } from 'react-i18next';
import { IActivityPanelConfig } from "../../../app/models/dashboard/activitiesPanel.model";
import { LastJobRun } from "../../../app/models/lastjobRun.model";
import moment from "moment";


const sortByRunDate = (j1: LastJobRun, j2: LastJobRun): number => {
  if(j1.isRunning && !j2.isRunning) 
    return -1;
  if(j2.isRunning && !j1.isRunning)
    return 1;
  return moment(j2.runDate).diff(moment(j1.runDate));
}

const ActivityPanel = (props: IActivityPanelConfig) => {
  const [t] = useTranslation("dashboard");
  
  const [jobs, setjobs] = useState<Array<LastJobRun>>([]);
  useEffect(() => {
    if(!props.items)
      return;
    setjobs(props.items.sort(sortByRunDate).slice(0,7));
  }, [props.items])

  const configForIcons = [
    {
      activityIconType: ActivityIconType.nextRun,
      header: t("nextRun"),
      text: props.nextRun,
    },
    {
      activityIconType: ActivityIconType.lastRun,
      header: t("lastRun"),
      text: props.lastRun,
    },
    {
      activityIconType: ActivityIconType.schedule,
      header: t("schedule"),
      text: props.scheduledJobsCount + '',
    },
  ];

  const useStyles = makeStyles({
    header: {
      color: "#8092a9",
      fontSize: "0.9rem",
      paddingLeft: "2%",
      border: "none",
    },
    headernopadding: {
      color: "#8092a9",
      fontSize: "0.9rem",
      border: "none",
      padding: 0,
      margin: 0,
    },
    divider: {
      marginLeft: 10,
      marginRight: 10
    }
  });

  const classes = useStyles();

  return (
    <Grid container data-test="component-activityPanel" direction="row" style={{ height: "100%" }}>
          <Grid item xs={10}>
            <Typography classes={{ root: "dashboard_header" }}>{t("activities")}</Typography>
            <TableContainer  style={{ overflowX: "hidden", maxHeight: '100%',paddingLeft:'0.5em' }} >
              <Table size="small" padding="none" >
                <TableHead className={classes.header}>
                  <TableRow>
                  <TableCell align="left" className={classes.header}>
                    {t("jobName")}
                  </TableCell>
                  <TableCell align="left" className={classes.header}>
                    {t("rrs")}
                  </TableCell>
                  <TableCell align="left" className={classes.headernopadding}>
                    {t("rta")}
                  </TableCell>
                  <TableCell align="left" className={classes.headernopadding}>
                    {t("devices")}
                  </TableCell>
                  <TableCell align="left" className={classes.headernopadding}>
                    {t("date")}
                  </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobs.map((item, index) => (
                    <React.Fragment key={index}>
                      <ActivityItem {...item} />
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={2} style={{ backgroundColor: "#fafafc", height: "100%" }}>
            <Grid style={{ height: "33%" }} container alignContent="center" justify="center" >
              <ActivityIcon {...configForIcons[0]} />
             
            </Grid>
            <Divider className={classes.divider}/>
            <Grid style={{ height: "33%" }}  container alignContent="center"justify="center" >
              <ActivityIcon {...configForIcons[1]} />
             
            </Grid>
            <Divider className={classes.divider} />
            <Grid style={{ height: "34%" }}  container alignContent="center" justify="center" >
              <ActivityIcon {...configForIcons[2]} />
            </Grid>
      </Grid>
    </Grid>
  );
};

export default observer(ActivityPanel);
