import { useContext, useEffect, useState } from "react";
import { RootStore, RootStoreContext } from "../../stores/root.store";

export function useShowResourceSetting() {
    const rootStore = useContext(RootStoreContext); 
    const { reportSettings} = rootStore.settingStore;  
    const [showResources, setShowResources] = useState(false);

    useEffect(() => {
        let showResourceSetting = reportSettings.find(s => s.settingKey === 'ShowResources');
        if(showResourceSetting) {
            setShowResources(showResourceSetting.settingValue === 'true');
        }
    }, [reportSettings])
   

    return showResources;
}