import { Card, Divider, Grid, InputLabel, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import clsx from 'clsx';
import { RootStoreContext } from "../../../app/stores/root.store";
import { useTranslation } from "react-i18next";
import { DNS_RESOLVE_NAME } from "../../../app/models/Network.enum";
import { JobType, NetworkOption } from "../../../app/models/jobProperties.model";

const NetworkPreviewComponent: React.FC = () => {

    const rootStore = useContext(RootStoreContext);
    const { createJobStore } = rootStore;
    const { EDRServer, additionalEdrcIp, additionalDefaultGateway, additionalEdrcDns, liveDRNetwork, testDRNetwork, EnsureDRController, dnsResolve, domainController, inPort, outPort } = createJobStore.networkSetting;
    const [t] = useTranslation("CreateJobPreview");
    const useStyles = makeStyles(({
        heading: {
            color: '#1f396d',
            fontWeight: 500,
        },
        pb10: {
            paddingBottom: 10
        },
        card: {
            padding: 16,
            width: '100%',
            boxSizing: 'border-box',
            borderRadius: '4px',
            border: 'solid 1px #eaeef4'
        },
        textItem: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 450,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.14,
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#4d5f75',
            marginLeft: '6px'
        },
        divider: {
            backgroundColor: '#eaeef4',
            width: '100%',
            margin: '0',
        },
        orangeDiv: {
            padding: 16,
            borderRadius: '4px',
            border: 'solid 1px rgba(229, 57, 53, 0.25)',
            backgroundColor: 'rgba(229, 57, 53, 0.05)'
        },
        blueDiv: {
            padding: 16,
            borderRadius: '4px',
            border: 'solid 1px rgba(40, 146, 215, 0.25)',
            backgroundColor: 'rgba(40, 146, 215, 0.05)'
        },
        emptyDiv: {

            borderRadius: '4px'
        }
    }))
    const classes = useStyles();

    const loadNetworkDataFirst = () => {
        let jobtype = rootStore.createJobStore.baseSettings.jobType;
        if (jobtype == JobType.Test || jobtype == JobType.Live) {
            return (
                <React.Fragment>
                    <Grid item container direction="row" className={classes.pb10} spacing={1}>
                        <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{jobtype == JobType.Live ? t('livedr') : t('testdr')}</Typography>
                        <InputLabel className={classes.textItem}>
                            {jobtype == JobType.Live ?
                                NetworkOption[liveDRNetwork.type] : NetworkOption[testDRNetwork.type]}</InputLabel>
                    </Grid>

                    <Grid item container direction="row" spacing={1}>
                        <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{jobtype == JobType.Live ? t('livenetwork') : t('bubblenetwork')}</Typography>
                        <InputLabel className={classes.textItem}>
                            {jobtype == JobType.Live ?
                                (rootStore.createJobStore.networkSetting.liveDRNetwork.type == NetworkOption.None ? '' :
                                    rootStore.createJobStore.networkSetting.liveDRNetwork.type == NetworkOption.Single ? liveDRNetwork.networkMapping[0].targetNetwork : t('multiNetwork'))
                                : (rootStore.createJobStore.networkSetting.testDRNetwork.type == NetworkOption.None ? '' :
                                    rootStore.createJobStore.networkSetting.testDRNetwork.type == NetworkOption.Single ? testDRNetwork.networkMapping[0].targetNetwork : t('multiNetwork'))
                            }</InputLabel>
                    </Grid>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <Grid item container direction="row" className={classes.pb10} spacing={1}>
                        <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{t('livedr')}</Typography>
                        <InputLabel className={classes.textItem}>{NetworkOption[liveDRNetwork.type]}</InputLabel>
                    </Grid>
                    <Grid item container direction="row" style={{ display: 'flex' }} spacing={1}>
                        <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{t('livenetwork')}</Typography>
                        <InputLabel className={classes.textItem}>
                            {rootStore.createJobStore.networkSetting.liveDRNetwork.type == NetworkOption.None ?
                                '' : (rootStore.createJobStore.networkSetting.liveDRNetwork.type == NetworkOption.Single) ? liveDRNetwork.networkMapping[0].targetNetwork : t('multiNetwork')}</InputLabel>
                    </Grid>
                </React.Fragment>
            );
        }
    }

    const loadNetworkDataSecond = () => {
        if (rootStore.createJobStore.baseSettings.jobType == JobType.LiveTest) {
            return (
                <React.Fragment>
                    <Grid item container direction="row" style={{ display: 'flex' }} spacing={1}>
                        <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{t('testdr')}</Typography>
                        <InputLabel className={classes.textItem}>{NetworkOption[testDRNetwork.type]}</InputLabel>
                    </Grid>
                    <Grid item container direction="row" style={{ display: 'flex' }} spacing={1}>
                        <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{t('bubblenetwork')}</Typography>
                        <InputLabel className={classes.textItem}>
                            {rootStore.createJobStore.networkSetting.testDRNetwork.type == NetworkOption.None ?
                                '' : (rootStore.createJobStore.networkSetting.testDRNetwork.type == NetworkOption.Single) ? testDRNetwork.networkMapping[0].targetNetwork : t('multiNetwork')}</InputLabel>
                    </Grid>
                </React.Fragment>
            );
        }
        else return (
            <Grid item container direction="row" style={{ display: 'flex' }} spacing={1}>
                <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{rootStore.createJobStore.baseSettings.jobType == JobType.Test ? t('livedr') : t('testdr')}</Typography>
                <InputLabel className={classes.textItem}>{t('notconfigured')}</InputLabel>
            </Grid>
        );
    }

    return (
        <Card elevation={1} classes={{ root: clsx([classes.card]) }}>
            <Grid container direction="column" style={{ height: '100%' }}>
                <Grid item>
                    <Typography variant='h6' className={classes.heading}>{t('NetworkPreview')}</Typography>
                </Grid>
                <Grid item container spacing={1} style={{ flexGrow: 1,paddingTop: 10, paddingBottom: 10 }}>

                    <Grid item container xs={4} direction="column" >
                        <Grid item container direction="row" style={{ display: 'flex' }} className={classes.pb10} >
                            <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{t('ensureServer')}</Typography>
                            <InputLabel className={classes.textItem}>{EDRServer.assetName}</InputLabel>
                        </Grid>

                        <Grid item container direction="row" className={classes.pb10}>
                            <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{t('inport')}</Typography>
                            <InputLabel className={classes.textItem}>{inPort}</InputLabel>
                            <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{t('outport')}</Typography>
                            <InputLabel className={classes.textItem}>{outPort}</InputLabel>
                        </Grid>

                        <div className={classes.orangeDiv} style={{ position: 'relative', flexGrow: 1 }}>
                            {loadNetworkDataFirst()}
                        </div>
                    </Grid>

                    <Grid item container xs={4} direction="column" >
                        <Grid item container direction="row" className={classes.pb10}>
                            <Typography className={classes.textItem}>{t('dcclone')}</Typography>
                            <InputLabel className={classes.textItem} style={{ color: '#8092a9' }}>{domainController.domainControllerName.includes("I have a Domain Controller")?t("dcExistInRep"): domainController.domainControllerName + " (" + domainController.dcHostServerSource + ")"}</InputLabel>
                        </Grid>
                        <Grid item container direction="row" className={classes.pb10} style={{height:domainController.domainControllerName.includes("I have a Domain Controller") ?'25px':null}}>
                            <Typography className={classes.textItem}>{domainController.domainControllerName.includes("I have a Domain Controller") ? " ":t('datastore')}</Typography>
                            <InputLabel className={classes.textItem} style={{ color: '#8092a9' }}>{domainController.domainControllerName.includes("I have a Domain Controller") ? " ":domainController.dcDataStoreTarget}</InputLabel>
                        </Grid>

                        <div className={classes.blueDiv} style={{ position: 'relative', flexGrow: 1 }}>
                            {loadNetworkDataSecond()}
                        </div>

                    </Grid>

                    <Divider orientation="vertical" flexItem style={{marginLeft: 15}} />
                    <Grid item container xs={3} direction="column" justify="space-between">
                        <Grid item container direction="row" style={{flexWrap:'nowrap'}}>
                            <Grid item xs={6}>
                                <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{t('ensurecontroller')}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                            <Tooltip title={EnsureDRController!=null ? EnsureDRController.assetName + " (" + EnsureDRController.assetIP + ")":''}>
                                <InputLabel className={classes.textItem} style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',width:'100%'}} >{EnsureDRController!=null ? EnsureDRController.assetName + " (" + EnsureDRController.assetIP + ")":''}</InputLabel>
                            </Tooltip> 
                            </Grid>
                        </Grid>
                        
                        <Grid container direction="row" >
                            <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{t('VMdispalyName')}</Typography>
                            <InputLabel className={classes.textItem}>{EnsureDRController!=null ? EnsureDRController.assetVMName:''}</InputLabel>
                        </Grid>
                        <Grid container direction="row">
                            <Typography className={classes.textItem}>{t('customUsername')}</Typography>
                            <InputLabel className={classes.textItem} style={{ color: '#8092a9' }}>{EnsureDRController!=null ? (EnsureDRController.credentials && EnsureDRController.credentials.userName!="" ? EnsureDRController.credentials.userName:"None"):''}</InputLabel>
                        </Grid>
                        
                        {/*<Grid container direction="row" >
                            <Typography className={classes.textItem} style={{ color: '#8092a9' }}>{t('dnsresolve')}</Typography>
                            <InputLabel className={classes.textItem}>{DNS_RESOLVE_NAME[dnsResolve]}</InputLabel>
                        </Grid>
                        <Grid container direction="row">
                            <Typography className={classes.textItem}>{t('additionalIP')}</Typography>
                            <InputLabel className={classes.textItem} style={{ color: '#8092a9' }}>{additionalEdrcIp.length >= 1 ? additionalEdrcIp[0] : ""}</InputLabel>
                            <Tooltip title={additionalEdrcIp.slice(1, additionalEdrcIp.length - 1).map((item) => item + ",") + additionalEdrcIp.slice(-1)[0]} arrow>
                                <InputLabel className={classes.textItem} style={{ color: '#2892d7' }}>{additionalEdrcIp.length > 1 ? (" +" + (additionalEdrcIp.length - 1).toString()) : ""}</InputLabel>
                            </Tooltip>
                        </Grid>
                        <Grid container direction="row" >
                            <Typography className={classes.textItem}>{t('additionalDNS')}</Typography>
                            <InputLabel className={classes.textItem} style={{ color: '#8092a9' }}>{additionalEdrcDns.length >= 1 ? additionalEdrcDns[0] : ""}</InputLabel>
                            <Tooltip title={additionalEdrcDns.slice(1, additionalEdrcDns.length - 1).map((item) => item + ",") + additionalEdrcDns.slice(-1)[0]} arrow>
                                <InputLabel className={classes.textItem} style={{ color: '#2892d7' }}>{additionalEdrcDns.length > 1 ? (" +" + (additionalEdrcDns.length - 1).toString()) : ""}</InputLabel>
                            </Tooltip>
                        </Grid>
                        <Grid container direction="row">
                            <Typography className={classes.textItem}>{t('gatway')}</Typography>
                            <InputLabel className={classes.textItem} style={{ color: '#8092a9' }}>{additionalDefaultGateway.length >= 1 ? additionalDefaultGateway[0] : ""}</InputLabel>
                            <Tooltip title={additionalDefaultGateway.slice(1, additionalDefaultGateway.length - 1).map((item) => item + ",") + additionalDefaultGateway.slice(-1)[0]} arrow>
                                <InputLabel className={classes.textItem} style={{ color: '#2892d7' }}>{additionalDefaultGateway.length > 1 ? (" +" + (additionalDefaultGateway.length - 1).toString()) : ""}</InputLabel>
                            </Tooltip>
                        </Grid>
                        */}
                    </Grid>

                </Grid>

            </Grid>
        </Card>
    );
}

export default observer(NetworkPreviewComponent);