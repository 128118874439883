
import React from 'react';
import CheckIcon from '@material-ui/icons/CheckCircle';
import CrossIcon from '@material-ui/icons/CancelRounded';

import  { Doughnut } from 'react-chartjs-2';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import DrawIconPlugin from './chartjsPlgins/drawIcon.plugin';
import DoughnutBackgroundFillPlugin from './chartjsPlgins/doughnutBackgroundFill.plugin';
import { useStatusColor } from './util/useStatusColor.hook';



const useStyles = makeStyles({
    iconCell: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        fontSize: 20,
        marginRight: 2
    }
});
type DeviceTestChartProps = {
    passed: number;
    failed: number;
    icon: string;
    width?: number;
    height?: number,
    title: string;
}



const DeviceTestChart: React.FC<DeviceTestChartProps> = (props) => {
    const classes = useStyles();
    const chartConfig = {
        labels: [
            'Successful',
            'Failed',
        ],
        datasets: [{
            data: [props.passed, props.failed],
            backgroundColor: [
                '#8bc53f',
                '#077187'
            ],
            hoverBackgroundColor: [
                '#FF6384',
                '#36A2EB'
            ]
        }]
    };

    const okIconColor = useStatusColor("passed", props.passed);
    const failedIconColor = useStatusColor("failed", props.failed);
  
   

    return <Grid container direction="column" alignContent="center">
        <Doughnut redraw={false} data={chartConfig} height={props.height} width={props.width}  options={{ title: { display: false, text: props.icon }, 
         hover: {mode: null},
         events: [],
        legend: { display: false }, cutoutPercentage: 90, layout: { padding: 0 }, tooltips: { enabled: false } }} 
        plugins={[DrawIconPlugin, DoughnutBackgroundFillPlugin]} />
        <Typography align="center" variant="h6">{props.title}</Typography>
        <Grid container justify="space-around">
            <Grid item>
                <span className={classes.iconCell}>
                    <CheckIcon className={classes.icon} style={{ color: okIconColor }} />
                    <Typography variant="body2">OK</Typography>
                </span>

                <Typography variant="h6" align="left"> {props.passed}</Typography>

            </Grid>
            <Grid item >
                <span className={classes.iconCell}>
                    <CrossIcon className={classes.icon} style={{ color: failedIconColor }} />
                    <Typography variant="body2">Failed</Typography>
                </span>
                <Typography variant="h6" align="left"> {props.failed}</Typography>
            </Grid>
        </Grid>
    </Grid>
}

export default DeviceTestChart;