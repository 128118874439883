import React, { useState, useEffect } from 'react'
import { TextField, InputAdornment, List, ListItem, ListItemIcon, Checkbox, ListItemText, makeStyles, Badge } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import clsx from 'clsx';
export type CheckboxOption = {
    label: string | any,
    checked: boolean,
    count?: number
}
export interface CheckBoxFilterProps<T>  {
    options: Array<T>;
    showAllOption?: boolean;
    showCount?: boolean;
    showSearchInput?: boolean;
    searchText? : string;
    allText? : string;
    allOptionLabel?: React.ReactNode;
    value: Array<T>;
    filterOptions: (option: T, filterString: string) => boolean;
    renderLabel?: (option: T) => React.ReactNode;
    renderEDRServer?: (option: T) => React.ReactNode;
    onChange: (value:  Array<T>) => void;
}
const useStyle = makeStyles({
    subList: {
        paddingLeft: 16,
        paddingTop: 0,     
    },
    checked: {
        color: '#2892d7 !important',
    },
    badge: {
        backgroundColor: "#d6d6d9",
        color: "#4d5f75",
        minWidth: 30
    },
    listIcon: {
        minWidth: 0,
    },
    checkboxstyle: {
        '&.MuiCheckbox-root': {
            color: '#c7cfdb'
        }
    },
    zeroTopBottomPadding: {
        paddingBottom: 0,
        paddingTop: 0
    },
    wrapper: {
        height: '40vh',
        width: '100%'   
    }
})

const GenericCheckBoxFilter = <R,>(props : CheckBoxFilterProps<R>): React.ReactElement<CheckBoxFilterProps<R>> => {
    const [filter, setFilter] = useState('');
    const [allSelected, setAllSelecteed] = useState(!!(props.value.length && props.value.length === props.options.length));
    const classes = useStyle();
    useEffect(() => {
        setAllSelecteed(!!(props.value.length && props.value.length === props.options.length));
     
    }, [props.options, props.value])
    const handleCheckboxClick = (targetOption: R) => {
        if(!props.value.find(option => option === targetOption))
        props.onChange([...props.value, targetOption]);
        else 
        props.onChange([...props.value.filter(option => option !== targetOption)]);
    }
    const handleAllSelectChange = () => {
        props.onChange(!allSelected ? props.options : []);
    }
    return <React.Fragment>
    {props.showSearchInput && <TextField
                    style={{marginTop: "15px"}}
                    placeholder={props.searchText!='' ? props.searchText : "Search"} variant="standard"
                    onChange={event => setFilter(event.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />}
        {props.showAllOption && <ListItem classes={{root: classes.zeroTopBottomPadding}}>
            <ListItemIcon classes={{root:classes.listIcon}}>
                        <Checkbox
                            edge="start"
                            tabIndex={-1}
                            onChange={handleAllSelectChange}
                            disableRipple
                            checked={allSelected}
                            classes={{checked: classes.checked}}
                            className={classes.checkboxstyle}
                            size='small'
                        />
                    </ListItemIcon>
                    <ListItemText primary={props.allOptionLabel || <span>{props.allText !='' ?  props.allText : 'All'}</span>} />
                 
            </ListItem>}
            <div className={clsx([classes.wrapper, "scrollbox scrollbox_delayed"])}>
                <div className={clsx(['scrollbox-content'])}>    
          <List dense={true} className={clsx({ [classes.subList]: props.showAllOption})}>
              {props.options.filter(option => !filter || props.filterOptions(option, filter)).map((option, index) =>{
                  return (
                    <div style={{paddingTop:0,paddingBottom:0}} key={index}>
                    <ListItem  style={{lineHeight:0}} classes={{root: classes.zeroTopBottomPadding}} 
                    onClick={() => { handleCheckboxClick(option)}}>
                        <ListItemIcon classes={{root:classes.listIcon}}>
                        <Checkbox
                         edge="start"
                         tabIndex={-1}
                         onChange={(event) => { handleCheckboxClick(option); event.stopPropagation();}}
                         disableRipple
                         checked={!!props.value.find(o => o=== option)}
                         classes={{checked: classes.checked}}
                         className={classes.checkboxstyle}
                         style={{paddingBottom:0,paddingTop:0}}
                         size='small'
                        />
                        </ListItemIcon>
                        <ListItemText primary={props.renderLabel && props.renderLabel(option) || option} />
                    </ListItem>
                    {props.renderEDRServer && props.renderEDRServer(option)}
                    </div>
                  )
              })}
              </List>
              </div>
              </div>
    </React.Fragment>
}
export default GenericCheckBoxFilter;
