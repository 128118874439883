import { Card, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, {useContext } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { RootStoreContext } from '../../../../app/stores/root.store';
import { JobType } from '../../../../app/models/jobProperties.model';

const InfoDRComponent: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const {jobType}=rootStore.createJobStore.baseSettings;
    const useStyles = makeStyles({
        Image:{
            objectFit: 'contain',
            marginTop:'16px',
            marginBottom:'16px'
        },
        card: {
            padding: 16,
            height:'100%',
            marginTop: '-14px',
            marginLeft: '-15px'
        },
        HeaderDR:
        {
            fontFamily:'Roboto',
            fontWeight:500,
            fontSize:'14px',
            fontStretch:'normal',
            fontStyle: 'normal',
            lineHeight:'1.14',
            letterSpacing:'normal',
            textAlign:'left'
        },
        TextDR:{
            marginTop:'8px'
        },
        backgroundDiv:{
            marginLeft:'10px',
            marginTop: '16px',
            width: '112px',
            height: '144px',
            borderRadius: '4px',
            border: 'solid 1px rgba(40, 146, 215, 0.25)',
            backgroundColor: 'rgba(40, 146, 215, 0.05)'
        }
        });
        
    const classes = useStyles();
    const [t] = useTranslation("createJobNetwork");
    
    const loadLiveDRInfo= ()=> {
        if(jobType== JobType.Live || jobType== JobType.LiveTest)
        return(
            <React.Fragment>
                <Typography className={classes.HeaderDR} style={{color: '#e53935'}}>{t('livedr')}</Typography>
                <Typography variant='body1' className={classes.TextDR} style={{top:'18%'}}>
                {t('liveDRPart1')}
                </Typography>
                <Typography variant='body1' className={classes.TextDR} style={{top:'18%'}}>{t('liveDRPart2')}</Typography>
                <Typography variant='body1' className={classes.TextDR} style={{top:'18%'}}>{t('liveDRPart3')}</Typography>
                <img src='/assets/images/createjob/livedr.svg' className={classes.Image} style={{top:'39%',left:'35px'}}></img>
            </React.Fragment>
        );
    }

    const loadTestDRInfo= ()=> {
        if(jobType== JobType.Test || jobType== JobType.LiveTest)
        return(
            <React.Fragment>
                <Typography color='primary' className={classes.HeaderDR}  style={{color: '#2892d7', marginTop:'16px'}}>{t('testdr')}</Typography>
                <Typography variant='body1' className={classes.TextDR} style={{top:'58%'}}>
                {t('testDRPart1')}
                </Typography>
                <Typography variant='body1' className={classes.TextDR} style={{top:'58%'}}>{t('testDRPart2')}</Typography>
                <Typography variant='body1' className={classes.TextDR} style={{top:'58%'}}>{t('testDRPart3')}</Typography>
                <div className={classes.backgroundDiv} style={{position:'relative',marginLeft: '120px'}}>
                <img src='/assets/images/createjob/livedr.svg' className={classes.Image} style={{right:'10%',position:'absolute'}}></img>
                </div>
            </React.Fragment>
        );
    }

    return(
            <Card elevation={1} classes={{root: clsx([classes.card])}}>
                <Grid item xs style={{paddingBottom: 16}}>
                {loadLiveDRInfo()}
                {jobType==JobType.LiveTest ?<Divider style={{backgroundColor: '#eaeef4'}}/>: null}
                {loadTestDRInfo()}
                </Grid>
            </Card>
    );
};

export default observer(InfoDRComponent);