import React, { useState } from 'react';
import jobAgent from '../../../app/api/jobAgent';

export function useAnyJobs() {
    const [anyJobs, setAnyJob] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const checkAnyJobs = () => {
        setIsLoading(true);
        jobAgent.CreateJobActions.AnyJobs().then(res => {
            setAnyJob(res);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    React.useEffect(() => {
        checkAnyJobs();
    }, []);
    return [anyJobs, isLoading];
}
