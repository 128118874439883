import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { PowerIcon, NetworkIcon, ServiceIcon, CyberIcon, DatabaseIcon, CustomIcon, InternetConnectionIcon, IssuesIcon, ThirdDeviceIcon, WebportalIcon, AutofixIcon, PreScriptIcon, DrResourceIcon} from '../Icons';
import TestOverViewTile from '../miniReport/TestOverView.component';
import { useTranslation } from 'react-i18next';
import { JobTestOverview } from '../../app/models/JobTestOverview.model';

type ReportTestsProps = {
    type: string;
    jobTest?: JobTestOverview
}

const ReportTestsComponent: React.FC<ReportTestsProps> = (props) => {
    const {type,jobTest}= props;
    const useStyle= makeStyles({
        testIcon: {
            color: '#8092a9',
            fontSize: 16
          }
    })

    const classes = useStyle();
    const [t] = useTranslation("report");
    
    return(
        <Grid container  style={{border: '1px solid #eaeef4',borderRadius: '4px', height: '100%', padding: '12px 0px'}}>
        <Grid item xs={4} container>
          <Grid container direction="column" justify="space-between" style={{ borderRight: '1px solid #eaeef4',  padding: type== "main" ? "0px 16px" :"0px 9px"}}>
            <TestOverViewTile name={t("tests.power")} passed={jobTest== null ? 0 : jobTest.powerOn.passed} failed={jobTest== null ? 0 :jobTest.powerOn.failed} icon={<PowerIcon className={classes.testIcon}/>}></TestOverViewTile>
            <TestOverViewTile name={t("tests.network")} passed={jobTest== null ? 0 :jobTest.network.passed} failed={jobTest== null ? 0 :jobTest.network.failed} icon={<NetworkIcon className={classes.testIcon}/>}></TestOverViewTile>
            <TestOverViewTile name={t("tests.service")} passed={jobTest== null ? 0 :jobTest.service.passed} failed={jobTest== null ? 0 :jobTest.service.failed} icon={<ServiceIcon className={classes.testIcon}/>}></TestOverViewTile>
          </Grid>
        </Grid>
        <Grid item xs={4} container style={{ borderRight: '1px solid #eaeef4',  padding: type== "main" ? "0px 18px" : "0px 10px 0px 10px" }}>
          <Grid container direction="column" justify="space-between">
            <TestOverViewTile name={t("tests.custom")} passed={jobTest== null ? 0 :jobTest.custom.passed} failed={jobTest== null ? 0 :jobTest.custom.failed} icon={<CustomIcon className={classes.testIcon} />}></TestOverViewTile>
            <TestOverViewTile name={t("tests.database")} passed={jobTest== null ? 0 :jobTest.database.passed} failed={jobTest== null ? 0 :jobTest.database.failed} icon={<DatabaseIcon className={classes.testIcon}/>}></TestOverViewTile>
            <TestOverViewTile name={t("tests.web")} passed={jobTest== null ? 0 :jobTest.web.passed} failed={jobTest== null ? 0 :jobTest.web.failed} icon={<WebportalIcon className={classes.testIcon}/>}></TestOverViewTile>
          </Grid>
        </Grid>
        <Grid item xs={4} container style={{ padding: type== "main" ? "0px 14px":"0px 10px 0px 10px" }}>
          <Grid container direction="column" justify="space-between">
          <TestOverViewTile name={t("tests.prescript")} passed={jobTest== null ? 0 :jobTest.prescript.passed} failed={jobTest== null ? 0 :jobTest.prescript.failed} icon={<PreScriptIcon className={classes.testIcon}/>}></TestOverViewTile>
          <TestOverViewTile name={t("tests.thirdDevice")} passed={jobTest== null ? 0 :jobTest.thirdDevice.passed} failed={jobTest== null ? 0 :jobTest.thirdDevice.failed} icon={<ThirdDeviceIcon className={classes.testIcon}/>}></TestOverViewTile>
          <TestOverViewTile name={t("tests.cybertest")} passed={0} failed={0} icon={<CyberIcon className={classes.testIcon}/>}></TestOverViewTile>
          </Grid>
        </Grid>
       </Grid>
    )
}


export default ReportTestsComponent;