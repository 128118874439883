import  SvgIcon  from "@material-ui/core/SvgIcon";
import React from "react"
import {ReactComponent as CyberSvg} from './svgs/cyber.svg';

const CyberIcon: React.FC<React.HTMLProps<SVGElement>> = (props) => {
    const {style, className} = props;
    return <SvgIcon viewBox={"-3,-3, 20, 20"}  className={className} style={style} component={CyberSvg}>
    </SvgIcon>
}


export default CyberIcon;