import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/root.store';
import { makeStyles, Switch, withStyles, Typography, Paper,Divider, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { blue } from "@material-ui/core/colors";
import { DeviceType } from '../../app/models/deviceType.enum';
import { parseSelectedDevices } from '../../app/common/util/util';
import DevicesTransferListComponent from './DevicesTransferList.component';

const SettingsReportComponent: React.FC = () => {
    const rootStore = useContext(RootStoreContext); 
    const { updateSettingObj, reportSettings} = rootStore.settingStore;  
    const [t] = useTranslation("settings");
    const [autoPlay, setAutoPlay] = useState(true);
    const [showResources, setShowResources] = useState(false);
    const [selectedDeviceTypes, setSelectedDeviceTypes] = React.useState<Array<DeviceType>>([]);
    
     const populateFields = () => {
        if (rootStore.settingStore.reportSettings!=null){ 
            Object.keys(rootStore.settingStore.reportSettings).forEach(key => {
                switch(rootStore.settingStore.reportSettings[key].settingKey.toLowerCase()){
                    case "showresources":
                        let showresources = (rootStore.settingStore.reportSettings[key].settingValue === 'true');  
                        setShowResources(showresources);              
                        break;
                    case "autoplayanimation":
                        let autoplay = (rootStore.settingStore.reportSettings[key].settingValue === 'true');  
                        setAutoPlay(autoplay);              
                        break;
                    default:
                            break;
                }
            })
        }
     } 

    useEffect(() => {
        setTimeout(() => populateFields(), 1000)
    }, []);
    
    const useStyles = makeStyles((theme) => ({
        reportDetails: {
            display: "flex",
            flexDirection: "column",
            color: "#4d5f75",
            '& > div': {
              padding: 5,
              display: "flex",
              justifyContent: "space-between",
              marginRight: "20px",
              marginLeft:"10px"
            }
        },
         formControl: {
            margin: theme.spacing(1),
            maxWidth: 300,
            '& label': {
              background: "white",
              width: "auto",
              marginTop: "1px",
              padding:"0 4px 0 4px"
            }
          },
          chips: {
            display: 'flex',
            flexWrap: 'wrap',
          },
          chip: {
            margin: 2,
          },
          noLabel: {
            marginTop: theme.spacing(3),
          },
    }));

    const BlueSwitch = withStyles({
        switchBase: {
          color: blue[300],
          "&$checked": {
            color: blue[500],
          },
          "&$checked + $track": {
            backgroundColor: blue[500],
          },
        },
        checked: {},
        track: {},
      })(Switch);

    const classes = useStyles();

    return (
        <div data-test='settings-report-component'>
            <Paper>
                <div className={classes.reportDetails}>
                    <div>
                        <Typography classes={{ root: "settingsText"}}>
                            {t("autoPlay")}
                        </Typography>
                        <BlueSwitch
                            checked={autoPlay}
                            onChange={(event) => {setAutoPlay(event.target.checked); updateSettingObj(JSON.stringify(event.target.checked),"AutoPlayAnimation","Report")}}
                            color="default"
                            name="autoPlayCheck"
                        />
                    </div>
                    <div>
                    <Typography classes={{ root: "settingsText"}}>
                            {t("showDrResources")}
                        </Typography>
                        <BlueSwitch
                            checked={showResources}
                            onChange={(event) => {setShowResources(event.target.checked); updateSettingObj(JSON.stringify(event.target.checked),"ShowResources","Report")}}
                            color="default"
                            name="showResources"
                        />
                    </div>
                    <Divider/>
                    <div>
                        <Typography classes={{ root: "settingsText"}}>
                            {t("SelectedDevices")}
                        </Typography>
                        <DevicesTransferListComponent/>
                        </div>
                </div>  
            </Paper>                                                    
        </div>
    );
}

export default (observer(SettingsReportComponent));