import { FormControl, Grid, InputLabel, makeStyles, Typography, DialogContent, Dialog, Tooltip, Button, Divider, ListItemText, Switch, CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EDRTextField } from "../../../app/common/EDRTextField";
import { SaveButton } from "../../../app/common/saveButton";
import { RootStoreContext } from "../../../app/stores/root.store";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TestType } from "../../../app/models/jobTest.model";
import { JobDevice } from "../../../app/models/jobDevice/jobDevice.model";
import { DatabaseType, DevicePlatform, MachineType } from "../../../app/models/jobProperties.model";
import { toast } from 'react-toastify';
import HelpIcon from '@material-ui/icons/Help';
import jobAgent from "../../../app/api/jobAgent";
export interface Props {
    onApply:()=>void;
    reload:string;
    discard: ()=>void;
    closeDrawer:()=>void;
    setParentValue:(value:string)=>void;
}

export interface AuthProps {
    onApply:()=>void;
    reloadUser:string;
    reloadPass:string;
    discard: ()=>void;
    closeDrawer:()=>void;
    setParentUsernameValue:(value:string)=>void;
    setParentPasswordValue:(value:string)=>void;
}

export interface PropsNetwork {
    onApply:()=>void;
    discard: ()=>void;
    closeDrawer:()=>void;
    setParentValue:(value:string)=>void;
}

const NetworkTestDialog: React.FC<PropsNetwork> = (props) => {
    const useStyles = makeStyles({
        centerVertical: {
            display: 'flex',
            alignItems: 'center',
            justifyContent:'space-between'
        },
        toggleItem: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            '& .MuiListItemText-root' : {
                marginLeft: 10
            }
        },
        helpIcon:
        {
          color: '#1976d2',
          fontSize: "large"
        },
        divider: {
            backgroundColor: '#eaeef4',
            width: '290%',
            margin: '0',
            marginTop: '10px',
            marginLeft: '10px',
    },
        formControl: {
            width: '100%',
            '& label': {
                background: "white",
                width: "auto",
                padding:"0 4px 0 4px",
                fontSize: '17px',
              }
        },
        textLabel: {
            textTransform:'uppercase',
            paddingTop:'5%'
        },
        SecondHeading:{
            color:'#1f396d',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap'
        },
        blueDiv: {
            width: '100%',
            height: '165px',
            borderRadius: '4px',
            border: 'solid 1px rgba(40, 146, 215, 0.25)',
            backgroundColor: 'rgba(40, 146, 215, 0.05)'
        },
        div: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            display: 'block',
            overflow: 'hidden'
        }
        });    

    const classes = useStyles();
    const { onApply,discard,closeDrawer,setParentValue } = props;
    const {createJobStore} = useContext(RootStoreContext);
    const device = createJobStore.editedDevice;
    const [NetworkthirdDeviceTest,setNetworkThirdDeviceTest] =useState(device!= undefined ? device.tests.find(test=> test.testType == TestType.thirdDeviceNetwork).selected ? device.tests.find(test=> test.testType == TestType.thirdDeviceNetwork) : device.tests.find(test=> test.testType == TestType.network):null);
    const [thirdDeviceLabel,setThirdDevice]= useState("3rd Device");
    const [cancel,setCancel]=useState(false);
    const [useThirdDevice,setUseThirdDevice] =useState(device!= undefined ? (device.tests.find(test=> test.testType == TestType.thirdDeviceNetwork).selected ? true:false):false);
    const [t] = useTranslation("createJobDevices");
    const [processingTest,setProcessingTest] = useState(false);

    const validation = yup.object({
        thirdDevice: yup.string()
        .nullable()
        .test('empty','third device must be not empty',thirdDevice => thirdDevice!='')
        .test('thirdDevice','Invalid third device hostname/IP',
        function(value){
            if(value!=undefined)
            {
                let IpRegex=/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
                let hostnameRegex=/^(([a-zA-Z0-9\-\(\)]|[a-zA-Z0-9\-\(\)][a-zA-Z0-9\-\(\)]*[a-zA-Z0-9\-\(\)])\.)*([A-Za-z0-9\-\(\)]|[A-Za-z0-9\-\(\)][A-Za-z0-9\-\(\)]*[A-Za-z0-9\-\(\)])$/;
                let isValidIP = IpRegex.test(value);
                let isValidHostname = hostnameRegex.test(value);
                if(isValidIP || isValidHostname ) 
                    return true;
                else
                    return false;
            }
            else return true;
        })
    })

    const form = useFormik({
        initialValues: {
            thirdDevice: ''
        },
        validationSchema:validation,
        validateOnMount: true,
        onSubmit: () => { },
    })

    useEffect(() => {
        let initialValues = {
            thirdDevice:NetworkthirdDeviceTest.thirdDeviceNetworkToTest != ""  ? 
            (device.planName.includes("Custom Devices") ? (NetworkthirdDeviceTest.thirdDeviceNetworkToTest != device.sourceName ? NetworkthirdDeviceTest.thirdDeviceNetworkToTest:device.sourceName): (NetworkthirdDeviceTest.thirdDeviceNetworkToTest != device.deviceName ? NetworkthirdDeviceTest.thirdDeviceNetworkToTest:device.deviceName)):
            (device.planName.includes("Custom Devices") ?device.sourceName:device.deviceName)
        }
        form.setValues(initialValues);
        if(device.planName.includes("Custom Devices"))
            NetworkthirdDeviceTest.thirdDeviceNetworkToTest != "" &&  NetworkthirdDeviceTest.thirdDeviceNetworkToTest != device.sourceName  ? setUseThirdDevice(true):setUseThirdDevice(false);
        else
            NetworkthirdDeviceTest.thirdDeviceNetworkToTest != "" &&  NetworkthirdDeviceTest.thirdDeviceNetworkToTest != device.deviceName  ? setUseThirdDevice(true):setUseThirdDevice(false);
        }, []); 

    const handleChange3rdDevice= (event)=>{
        form.setFieldValue("thirdDevice", event.target.value);
        setThirdDevice(event.target.value);
    }

    
    const handleTest=() =>{
        if(device.sourceName && device.sourceName != '')
        {
            let network= useThirdDevice ? form.values.thirdDevice :device.sourceName;
            var details =
            {
                NetworkIP:network,
                UseThirdDevice:useThirdDevice,
                machineName:device.sourceName,
                devicePlatform:device.os,
                id: device.os == DevicePlatform.UNIX ? createJobStore.baseSettings.workflowTest.SecondaryUserCredsId : createJobStore.baseSettings.workflowTest.PrimaryUserCredsId
            };
            setProcessingTest(true);
            jobAgent.JobTestsActions.TestNetwork(details).then(res => {
                if(res)
                {
                    if(useThirdDevice)
                        toast.success("Segmentation Test succeeded");
                    else
                        toast.success("Network Test succeeded");
                }
                
                else
                {
                    if(useThirdDevice)
                        toast.success("Segmentation Test failed");
                    else
                        toast.error("Network Test failed");
                }
                    
                setProcessingTest(false);
            }).catch(err=>{
                if(err && err.data && err.data.errors)
                    toast.error(err.data.errors);
                setProcessingTest(false);
            }); 
        }
        else
        {
            toast.error("Network Test failed, device hostname is empty");
        }
               
    }

    const handleCancle =()=>{
        if(NetworkthirdDeviceTest.thirdDeviceNetworkToTest != "" ? form.values.thirdDevice !=NetworkthirdDeviceTest.thirdDeviceNetworkToTest :form.values.thirdDevice !='')
            setCancel(!cancel);
        else
            closeDrawer();
    }

    const handleSave= ()=>{
        try{
            setThirdDevice(form.values.thirdDevice);
            let test =  useThirdDevice ? device.tests.find(test=> test.testType === TestType.thirdDeviceNetwork) : device.tests.find(test=> test.testType === TestType.network) ;
            if(useThirdDevice)
            {
                test.selected= true;
                test.thirdDeviceNetworkToTest =form.values.thirdDevice;
                let testNetwork =  device.tests.find(test=> test.testType === TestType.network);
                testNetwork.selected =false;
                device.tests= device.tests.map(t=>t.testType !== TestType.thirdDeviceNetwork ? (t.testType === TestType.network ? testNetwork: t):test);
            }
            else
            {
                test.selected= true;
                test.thirdDeviceNetworkToTest = form.values.thirdDevice;
                let testThirdDevice =  device.tests.find(test=> test.testType === TestType.thirdDeviceNetwork);
                testThirdDevice.selected =false;
                device.tests= device.tests.map(t=>t.testType !== TestType.network ? (t.testType === TestType.thirdDeviceNetwork ? testThirdDevice: t):test);
            }
            createJobStore.updateDevice(device);
            /*let DeviceTest = {
                testCategory:device.tests[0].testCategory, 
                testType: useThirdDevice ? TestType.thirdDeviceNetwork :TestType.network , 
                selected: true,
                customCommandToTest: '', 
                customCommandExpectedResult: '', 
                customCommandToTestIsExternal: false,
                databaseType: DatabaseType.SQL,
                databaseNameToTest: '',
                databaseQueryToTest: '',
                databaseServerNameToTest: '',
                databaseUsername:'',
                databasePassword: '',
                serviceToTest: '',
                testFromEDRC: false,
                script: '',
                thirdDeviceNetworkToTest: form.values.thirdDevice,
                authenticationUserToTest:'',
                authenticationPassToTest:'',
                webPortalToTest: '',
                webPortalResult: ''
            };*/
            //createJobStore.updateTestDevice(useThirdDevice ? TestType.thirdDeviceNetwork :TestType.network,DeviceTest,device.planName.includes("Custom Devices") ? device.sourceName : device.deviceName);
            onApply();
            discard();
            setParentValue(form.values.thirdDevice);
            toast.success(t('saveSuccessful'));
        }
        catch(error)
        {
            toast.success(t('savefailed')+error);
        }
        
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    const handleYes=()=>{
        form.setFieldValue("thirdDevice", device.planName.includes("Custom Devices") ?device.sourceName:device.deviceName);
        setUseThirdDevice(false);
        discard();
        setCancel(false);
    }

    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel style={{marginLeft:'10px'}}>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const handleSwitchNetwork =(event:any)=>{
        setUseThirdDevice(event.target.checked);
        form.setFieldValue("thirdDevice",event.target.checked == false ? device.deviceName:'');
    }

    const loadImageText =() =>{
        if(useThirdDevice)
        return(<React.Fragment>
         <Grid item style={{ display:'flex',justifyContent:'center'}}>
             <div>
                <img src={'/assets/images/editDeviceIcons/testdrImage.svg'}></img>
             </div>
        </Grid>
        <Grid item container direction="row" alignContent="center">
        <Grid item xs={4} alignContent="center" style={{display:'flex',paddingLeft: '20%',alignItems: 'flex-end'}}>
        <div className={classes.div}>
            <Tooltip title={device.deviceName} arrow>
            <InputLabel className={classes.textLabel}>{t("edrc")}</InputLabel>
            </Tooltip>
            </div>
        </Grid>
        <Grid item xs={4} alignContent="center" style={{display:'flex',justifyContent:'center'}}>
            <div className={classes.div}>
            <Tooltip title={device.deviceName} arrow>
            <InputLabel className={classes.textLabel}>{device.deviceName}</InputLabel>
            </Tooltip>
            </div>
        </Grid>
        <Grid item xs={4} alignContent="center" style={{display:'flex'}}>
            <div className={classes.div}>
            <Tooltip title={thirdDeviceLabel} arrow>
            <InputLabel className={classes.textLabel}>{thirdDeviceLabel}</InputLabel>
            </Tooltip>
            </div>
        </Grid>
        </Grid>
        </React.Fragment>
    )
    else
    return (<React.Fragment>
        <Grid item style={{ display:'flex',justifyContent:'center'}}>
        <div>
            <img src={'/assets/images/editDeviceIcons/networkImage.svg'}></img>
        </div>
        </Grid>
        <Grid item container direction="row" alignContent="center">
            <Grid item xs={6} alignContent="center" style={{display:'flex',paddingLeft: '34%',alignItems: 'flex-end'}}>
        <div className={classes.div}>
            <Tooltip title={device.deviceName} arrow>
            <InputLabel className={classes.textLabel}>{t("edrc")}</InputLabel>
            </Tooltip>
        </div>
        </Grid>
            <Grid item xs={6} alignContent="center" style={{display:'flex'}}>
            <div className={classes.div}>
            <Tooltip title={device.deviceName} arrow>
            <InputLabel className={classes.textLabel}>{device.deviceName}</InputLabel>
            </Tooltip>
            </div>
        </Grid>
    </Grid>
    </React.Fragment>
    );
    }
    
    return(
        <Grid container spacing={2} style={{paddingRight: '5%',paddingLeft:'5%', alignContent:'flex-start',overflow:'hidden'}}>
        
       <Grid item container direction="row" spacing={2} style={{marginTop:'0px'}}>
       <div style={{ display: 'flex', flexDirection: 'row',marginLeft:'1%'}}>
                <Typography variant='h6' className={classes.SecondHeading}>{useThirdDevice ? t('thirdDevicesetting'): t('networksetting')}</Typography>
                    <Tooltip title={useThirdDevice ? t("thirdDeviceInfo"): t("networkInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider} style={{width:'177%'}}/>
        </div>
       </Grid>
      
      <Grid item container>
      <Grid item xs={12}>
            <div className={classes.blueDiv}>
                    <Grid item direction="row" alignContent="center">
                        <InputLabel style={{marginLeft:'2%' ,lineHeight:1.8 }} >{useThirdDevice ? t('thirddivInfo'): t('netowrkdivInfo')}</InputLabel>
                    </Grid>
                    <Grid item container direction="column">
                        {loadImageText()}
                    </Grid>
            </div>
        </Grid>

        <Grid item container xs={12} style={{marginTop:'2%'}}>
                <Grid item xs={10}>
                <div style={{width: '100%',display: 'flex',justifyContent: 'space-between'}}>
                    <ListItemText >{t("switchNetworkThird")}</ListItemText>
                </div>
                </Grid>
                <Grid item xs={2}>
                <div className={classes.centerVertical} style={{justifyContent:'flex-end'}}>
                    <Switch checked={useThirdDevice} name="switch"  onChange={handleSwitchNetwork} onBlur={form.handleBlur} color='primary' />
                </div>
                </Grid>  
        </Grid>

        <Grid item xs={12} style={{marginTop:'2%'}}>
					<FormControl className={classes.formControl} variant='outlined'>
					<EDRTextField id="3rddevice"
					name="3rddevice"
					size="small"
					label={useThirdDevice ? t("thirdDevice"):t("networkLabel")}
					disabled={!useThirdDevice}
					value={form.values.thirdDevice}
					onChange={handleChange3rdDevice}
					onBlur={form.handleBlur}
					error={Boolean(form.errors.thirdDevice)}
					helperText={t(form.errors.thirdDevice)}
					InputLabelProps={{
					shrink: true,
					}}
					variant="outlined">
					</EDRTextField>
					</FormControl>
		</Grid>
      </Grid>
       
        <Grid item container direction="row">
            <Grid item xs={6}>
                {loadConfirmation()}
            </Grid>
                <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end'}}>
            <Button onClick={handleCancle} color="primary" disabled={!useThirdDevice} style={{textTransform:'none'}}>
                {t('Cancel')}
            </Button>
            <Button onClick={handleTest} disabled={useThirdDevice ?(form.values.thirdDevice ===""? true:false):false || processingTest} color="primary" style={{textTransform:'none',marginLeft:'-11px'}}>
            {processingTest ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                {t('Test')}
            </Button>
            <SaveButton color="primary" variant="contained" disabled={!useThirdDevice ? false: (!form.isValid || form.values.thirdDevice ==="" ? true:false)} onClick={handleSave} style={{textTransform:'none'}}>
            {t("Apply")}
            </SaveButton> 
            </Grid>
        </Grid>
       
       </Grid>
    );
}

export default NetworkTestDialog;