import agentbase from './agentBase';
import { JobScoreDataPoint } from '../models/JobScoreData';
import {memoise} from '../common/util/memoise';
import { IMap } from '../models/dashboard/map.model';
import { JobScore } from '../models/dashboard/dashboard.model';

const axios = agentbase.axios;
const responseBody = agentbase.responseBody;

const EndDate = new Date().toDateString();
const Dashboard = {
    list: (): Promise<any> => axios.get('/JobSummaryResults/List', {params: {startDate:"Wed Jan 1 2020", endDate: EndDate, full: true}})
        .then(responseBody),   
    getJobScoreData: (jobscore: JobScore):Promise<Array<JobScoreDataPoint>> => {
            return axios.post('/dashboard/scoredata', {...jobscore}).then(res=>res.data);
    },
    getMapData: (): Promise<IMap> => {
        return axios.get(`/dashboard/mapdata`).then(res => res.data);
    },
    getGeographyData: (resourceName: string): Promise<any> => {
        return fetch( `/assets/map/${resourceName}`).then(r => r.json());
    }
}

export default Dashboard
