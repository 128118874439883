
import { Button,Dialog, DialogActions, DialogContent, makeStyles, Typography } from '@material-ui/core';
import { type } from 'os';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormSupportComponent from '../../features/help/FormSupport.component';
import { JobDevice } from '../models/jobDevice/jobDevice.model';
import { EDRDialogTitle } from './EDRDialogTitle';
import { SaveButton } from './saveButton';
const useStyles = makeStyles(theme => ({
    root: {
        minWidth: '30vw'
    },
    saveButton: {
        borderRadius: 20
    }
}))
export interface ConfirmationDialogProps {
    open: boolean;
    onClose: (confirm,event?) => void;
    type:string;
    device?:JobDevice;
    event?:any;
    title? : string,
    modal?: boolean
}


const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
    const { open, onClose,type,device,event } = props;
    const classes = useStyles();
    const { t } = useTranslation('utils');
    const handleClose = (confirm: boolean = false,event?:any) => {
        onClose(confirm,event);
    }

    const handleDialogClose = (event, reason) => {
        if(props.modal)
            return;
        else
            props.onClose(false);
    }

 

    const loadTitle =()=>{
        if(type == "delete")
            return t("confirmDeleteAction");
        if(type =="interestedIn")
            return t("InterstedInAction");
        else if(type == "exitWizard")
            return t("exitAction");
        else if(type == "discard")
            return t('confirmAction');
        else if(type == "changeNetworkType")
            return t('changeNetworkTypeAction');
        else if(type == "deleteDraft")
            return 'Delete Draft Confirmation';
        else if(type == "deleteLicense")
            return t("deleteLicenseAction");
        else if(type == "deleteInfra")
            return t("deleteInfraAction");
        else if(type == "deleteDM")
            return t("deleteDMAction");
        else if(type == "deleteEDR")
            return t("deleteEDRAction");
        else if(type == "deleteEDRC")
            return t("deleteEDRCAction");
        else if(type == "deleteDC")
            return t("deleteDCAction");
        else if(type=="runJob")
            return t("runAction");
        else if(type=="runCleanup")
            return t("runCleanupAction");
        else if(type=="runFailover")
            return t("runFailoverAction");
        else if(type == "runLiveFailover")
            return t("runLiveFailoverAction");
        else if(type=="missingDetailsDC")
            return t("missingDetails");
        else if (type=="deleteBootAnimations")
            return t("deleteBootAnimations");
        else if(type =="interstedIn")
            return t("InterstedIn");
        else
            return t('warnAction');
    }

    return <Dialog
        open={open}
        classes={{ paper: classes.root }}
        onClose={handleDialogClose}
       
        scroll="paper"
    >
        <EDRDialogTitle >{props.title || loadTitle()}</EDRDialogTitle>
        <DialogContent dividers={true}>
            <span style={{whiteSpace:'pre-wrap', fontWeight:type == "changeNetworkType" ?"bold":"normal"}}>
            {type =="delete" && device!=undefined ? (props.children + device.deviceName +" - are you sure?"):props.children}
            {type =="changeNetworkType" ? <span style={{whiteSpace:'pre-wrap'}}>{'\n\n'+"Are you sure you want to procceed?"}
            </span>:null}
            </span>
        </DialogContent>
        <DialogActions>
            {type !="missingDetailsDC"  && type!="interestedIn" ? 
            <Button onClick={() => handleClose(false)} color="primary" style={{textTransform:'none'}}>
                {type == "exitWizard" ? t('cancel'):t('no')}
            </Button>:null}
            {type!="interestedIn" ? <SaveButton onClick={() => handleClose(true)} variant="contained" color="primary" style={{textTransform:'none'}}>
                {type == "exitWizard" || type =="missingDetailsDC" ? t('ok'):t('yes')}
            </SaveButton>:null}
        </DialogActions>
    </Dialog>
}

export default ConfirmationDialog;