import { JobRunType } from "./BaseSettings.enum";
import { TimeSpan } from "./timespan.model";
import { WorkFlowStatus } from "./workflowStep.model";

export enum JobRunState {
    Running = 0,
    Failed = 1,
    Stale = 2,
    WriteSuccess = 3,
    WriteFailed = 4,
    WriteInProcess = 5,
    DataReady = 6,
}

export enum JobRunSubState {
    PreTestTask = 0,
    Failover = 1,
    ServerTest = 2,
    Snapshot = 3,
    CleanUp = 4,
    Done = 5
}

export interface JobRunProgress {
    id: number;
    progressInfo: string;
}
export interface JobRun {
    id: number;
    jobId: number;
    jobName?: string;
    runDate?: string;
    rta?: string;
    currentRunState: JobRunState;
    currentRunSubState: JobRunSubState;
    preTestTime: string;
    failoverTime: string;
    serverTestTime: string;
    snapshotTime: string;
    cleanUpTime: string;
    failed?: boolean;
    failedReason?: boolean;
    jobSummaryResultId?: boolean;
    workflowStatusCleanUp?: WorkFlowStatus;
    workflowStatusPreTest?: WorkFlowStatus;
    workflowStatusFailover?: WorkFlowStatus;
    workflowStatusServerTest?: WorkFlowStatus;
    workflowStatusSnapshot?: WorkFlowStatus
    jobRunProgressInfos: Array<JobRunProgress>
    jobRunType: JobRunType;
}