import  SvgIcon  from "@material-ui/core/SvgIcon";
import React from "react"
import {ReactComponent as WebportalSvg} from './svgs/webportal.svg';

const WebportalIcon: React.FC<React.HTMLProps<SVGElement>> = (props) => {
    const {style, className} = props;
    return <SvgIcon viewBox={"-2.5,-3, 20, 20"}  className={className} style={style} component={WebportalSvg}>
    </SvgIcon>
}


export default WebportalIcon;