import { FormControl, Grid, InputAdornment, InputLabel, makeStyles, OutlinedInput, Typography,IconButton, DialogContent, Dialog, Divider, Select, MenuItem, Button, Tooltip, Switch, ListItem, ListItemText, NativeSelect } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Autocomplete } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { EDRAutocomplete } from "../../../app/common/EDRAutocomplete";
import { EDRTextField } from "../../../app/common/EDRTextField";
import { SaveButton } from "../../../app/common/saveButton";
import { DATABASE_TYPE_NAME, OS_TYPE_NAME } from "../../../app/models/BaseSettings.enum";
import { DeviceTest } from "../../../app/models/createjob/createJob.model";
import { JobDevice } from "../../../app/models/jobDevice/jobDevice.model";
import { DatabaseType, DevicePlatform } from "../../../app/models/jobProperties.model";
import { TestType } from "../../../app/models/jobTest.model";
import { RootStoreContext } from "../../../app/stores/root.store";
import { Props } from "./NetworkTestDialog.component";
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import HelpIcon from '@material-ui/icons/Help';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import { DeviceType } from "../../../app/models/deviceType.enum";
import JobSettingsStore from "../../../app/stores/jobSettings.store";

export interface ToggleProps {
    deviceName:string;
    closeDrawer:()=>void;
}

const ToggleAllTestDialog: React.FC<ToggleProps> = (props) => {
    const useStyles = makeStyles({
        formControl: {
            width: '100%',
            '& label': {
                background: "white",
                width: "auto",
                padding:"0 4px 0 4px",
                fontSize: '17px',
              }
        },
        helpIcon:
        {
          color: '#1976d2',
          fontSize: "large"
        },
        divider: {
            backgroundColor: '#eaeef4',
            width: '110%',
            margin: '0',
            marginTop: '10px',
            marginLeft: '10px',
    },
        SecondHeading:{
            color:'#1f396d',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1
        },
        centerVertical: {
            display: 'flex',
            alignItems: 'center',
            justifyContent:'space-between'
        },
        nativeSelect:{
            borderTop:'1px solid rgba(0, 0, 0, 0.12)',
            borderRight:'1px solid rgba(0, 0, 0, 0.12)',
            borderLeft:'1px solid rgba(0, 0, 0, 0.12)',
            borderRadius:'4px'
        },
         nativeFormControl:{
        '& .MuiInput-underline:before': {
            borderBottom:'1px solid rgba(0, 0, 0, 0.12)'
        },
        '& .MuiNativeSelect-outlined.MuiNativeSelect-outlined': {
            paddingLeft:'8px'
        }
    },
        toggleItem: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
    
            '& .MuiListItemText-root' : {
                marginLeft: 10
            },
            '& .MuiSvgIcon-root': {
                color: '#8092a9'
            }
        },
        });   

    const classes = useStyles();
    const rootStore = useContext(RootStoreContext); 
    const {createJobStore} = useContext(RootStoreContext);
    const {TestGroups} =createJobStore;
    const [cancel,setCancel]=useState(false)
    const device= createJobStore.selectedDevices.find(device=>device.deviceName == props.deviceName);
    const [t] = useTranslation("createJobDevices");
    
const validationSchema = yup.object({
})

    const form = useFormik({
        initialValues: {
            toggledc:  createJobStore.TempDataDevice!=null ? createJobStore.TempDataDevice.showDeviceAsDC: device.showDeviceAsDC,
            toggle2nd: createJobStore.TempDataDevice!=null ? createJobStore.TempDataDevice.performSecondRestart: device.performSecondRestart,
            deviceGroup:TestGroups && TestGroups != null && TestGroups.groups && TestGroups.groups != null ? TestGroups.groups.find(g => g.groupOrder === parseInt(createJobStore.TempDataDevice!=null ? createJobStore.TempDataDevice.deviceGroup:device.deviceGroup)).groupName:''
        },
        onSubmit: () => { },
        validationSchema
    })

      useEffect(() => {
        if(createJobStore.TempDataDevice && createJobStore.TempDataDevice.deviceName != props.deviceName)
            createJobStore.updateTempDataDevice(null);
        const initialValues =
        {
            toggledc:  createJobStore.TempDataDevice!=null ? createJobStore.TempDataDevice.showDeviceAsDC: device.showDeviceAsDC,
            toggle2nd: createJobStore.TempDataDevice!=null ? createJobStore.TempDataDevice.performSecondRestart: device.performSecondRestart,
            deviceGroup:TestGroups && TestGroups != null && TestGroups.groups && TestGroups.groups != null ? TestGroups.groups.find(g => g.groupOrder === parseInt(createJobStore.TempDataDevice!=null ? createJobStore.TempDataDevice.deviceGroup:device.deviceGroup)).groupOrder.toString():'1'
        };
        form.setValues(initialValues);
      }, []); 
    
    const handleToggle2nd =(event:any)=>{
        form.setFieldValue("toggle2nd",event.target.checked);
    }

    const handleToggleDC =(event:any)=>{
        form.setFieldValue("toggledc",event.target.checked);
    }

    const handleDeviceGroupChange =(event:any)=>{
        form.setFieldValue("deviceGroup",event.target.value);
    }

    const handleCancle =()=>{
        if(form.dirty && form.touched)
            setCancel(!cancel);
        else
            props.closeDrawer();
    }

    const handleSave =()=>{
        try{
        const update: JobDevice = {...device,performSecondRestart:form.values.toggle2nd,showDeviceAsDC:form.values.toggledc,deviceGroup:createJobStore.TestGroups.groups.find(item=>item.groupOrder.toString() === form.values.deviceGroup.toString()).groupOrder.toString()} ;
        createJobStore.updateTempDataDevice(update);
        toast.success(t('saveSuccessful'));
        props.closeDrawer();
        }
        catch(error)
        {
            toast.success(t('savefailed')+error);
        }
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    const handleYes=()=>{
        form.setFieldValue("toggle2nd", device.performSecondRestart);
        form.setFieldValue("toggledc", device.showDeviceAsDC);
        form.setFieldValue("deviceGroup",device.deviceGroup);
        const update: JobDevice = {...device,performSecondRestart:device.performSecondRestart,showDeviceAsDC:device.showDeviceAsDC,deviceGroup:device.deviceGroup} ;
        createJobStore.updateTempDataDevice(update);
        setCancel(false);
        props.closeDrawer();
    }

    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const loadOptionsGroups =()=> {
        let groups =
        [
            {groupOrder: 1,groupName:"Group1"},
            {groupOrder: 2,groupName:"Group2"},
            {groupOrder: 3,groupName:"Group3"},
            {groupOrder: 4,groupName:"Group4"},
            {groupOrder: 5,groupName:"Group5"},
            {groupOrder: 6,groupName:"Group6"},
            {groupOrder: 7,groupName:"Group7"}
        ]
        if(createJobStore.TestGroups && createJobStore.TestGroups.groups !=null)
        {
            return createJobStore.TestGroups.groups.map((test) => (
                <option value={test.groupOrder}>{test.groupName}</option>
            ));
        }
        else
        {
            return groups.map((test) => (
                <option value={test.groupOrder}>{test.groupName}</option>
            ));
        }
    }



    return(
           <Grid container spacing={2} style={{paddingRight: '5%',paddingLeft:'5%', alignContent:'flex-start'}}>
              
              <Grid item container direction="row" spacing={2} style={{marginTop:'0px'}}>
                <div style={{ display: 'flex', flexDirection: 'row',marginLeft:'1%'}}>
                <Typography variant='h6' className={classes.SecondHeading}>{t('moreOptions')}</Typography>
                    <Tooltip title={t("moreOptionsInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider}/>
                </div>
            </Grid>

            <Grid item container direction="row">
            <Grid item container xs={12}>
                <Grid item xs={6}>
                <div className={classes.toggleItem}>
                        <div className={classes.centerVertical}>
                        <PowerIcon style={{marginLeft:'-4px'}}/>
                            <ListItemText >{t("toggle2nd")}</ListItemText>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6}>
                <div className={classes.centerVertical} style={{justifyContent:'flex-end'}}>
                        <Switch checked={form.values.toggle2nd} name="toggle2nd"  onChange={handleToggle2nd} onBlur={form.handleBlur} color='primary' />
                </div>
                </Grid>  
            </Grid>

            <Grid item container xs={12}>
                <Grid item xs={6}>
                <div className={classes.toggleItem}>
                        <div className={classes.centerVertical}>
                            <img src={'/assets/images/device_type/dc_device.svg'}/>
                            <ListItemText >{t("toggledc")}</ListItemText>
                        </div>
                </div>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.centerVertical} style={{justifyContent:'flex-end'}}>
                        <Switch checked={form.values.toggledc} name="toggledc"  onChange={handleToggleDC} onBlur={form.handleBlur} color='primary' />   
                    </div>
                </Grid>
            </Grid>

            <Grid item container xs={12} style={{paddingTop:'2%'}}>
                <Grid item xs={6}>
                <div style={{width: '100%',display: 'flex',justifyContent: 'space-between'}}>
                    <div className={classes.centerVertical}>
                        <ListItemText >{t("testGroup")}</ListItemText>
                    </div>
                </div>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                    <div className={classes.centerVertical} style={{justifyContent:'flex-end'}}>
                    <FormControl className={classes.formControl} classes={{root: classes.nativeFormControl}} style={{paddingRight:'5%'}} variant='outlined'>
                        <NativeSelect
                        classes={{root: classes.nativeSelect}}
                        value={form.values.deviceGroup}
                        onChange={handleDeviceGroupChange}
                        name="group"
                        inputProps={{ 'aria-label': 'group' }}
                        >
                        {loadOptionsGroups()}
                        </NativeSelect>
                        </FormControl>
                    </div>
                </Grid>
            </Grid>

            </Grid>
           
            <Grid item container direction="row">
            <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end'}}>
            <Button onClick={handleCancle} color="primary" style={{textTransform:'none'}}>
                {t('Cancel')}
            </Button>
            <SaveButton onClick={handleSave} variant="contained" color="primary" style={{textTransform:'none'}}>
                {t('apply')}
            </SaveButton>
            </Grid>
            </Grid>
        </Grid>
    );
}

export default ToggleAllTestDialog;