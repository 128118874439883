
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, ListItemText, makeStyles, Switch, Tooltip, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EDRTextField } from "../../../../app/common/EDRTextField";
import { SaveButton } from "../../../../app/common/saveButton";
import { EDRDialogTitle } from "../../../../app/common/EDRDialogTitle";
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EDRAutocomplete } from '../../../../app/common/EDRAutocomplete';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import { TestType } from '../../../../app/models/jobTest.model';
import { RootStoreContext } from '../../../../app/stores/root.store';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { DatabaseType, DevicePlatform, MachineType } from '../../../../app/models/jobProperties.model';
import HelpIcon from '@material-ui/icons/Help';
import { toast } from 'react-toastify';
import { compressFile } from '../../../../app/common/util/util';
import { flow, runInAction } from 'mobx';
import { AnyCnameRecord } from 'dns';
import jobAgent from '../../../../app/api/jobAgent';

const useStyles = makeStyles(theme => ({
    helpIcon:
    {
      color: '#1976d2',
      fontSize: "large"
    },
    autoCompleteWithButton: {
        '& input': {
            flexGrow: 0,
            width: "calc(100% - 59px) !important"
        }
    },
    BrowseButton:{
        backgroundColor: '#fafafc',
        textTransform: 'none',
      },
    SecondHeading:{
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        flexGrow: 1
    },
    divider: {
        backgroundColor: '#eaeef4',
        width:'210%',
        margin: '0',
        marginTop: '10px',
        marginLeft: '10px',
    },
    label: {
        textTransform: 'capitalize',
    },
    root: {
        minWidth: '30vw'
    },
    checkbox: {
        marginLeft: theme.spacing(0.5)
    },
    heading:{
        fontFamily: 'Roboto',
        fontSize: '13px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontstyle: 'normal',
        lineHeight: 1.15,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#4d5f75',
    },
    title:{
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontstyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        textAlign: 'left'
    },
    button: {
        height: '100%',
        borderRadius: '4px',
        border: 'solid 1px #c7cfdb',
        backgroundColor: '#fafafc'
    },
    centerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between'
    },
    text:{
        '& .MuiTypography-body2':{
            color:'#4d5f75',
            fontWeight:500,
        }
    },
    toggleItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiListItemText-root' : {
            marginLeft: 10
        },
        '& .MuiSvgIcon-root': {
            color: '#8092a9'
        }
    },
    autoWithButton:{
        '& .MuiOutlinedInput-adornedEnd' : {
            paddingRight:'0px'
        }
    }
}))
export interface TestSettingProps {
    open: boolean;
    onSave :()=> void;
    onClose: () => void;
    deviceName:string;
    isDefault:boolean;
}

const validationSchema = yup.object({
    command: yup.string()
    .test('command','custom command must be not empty',command => command!='')
    .required('Custom Command is a required field'),

    isExt: yup.bool(),

    result: yup.string()
    .nullable()
})
const CustomTestConfigureDialog: React.FC<TestSettingProps> = (props) => {
    
    const { open,deviceName,isDefault, onClose,onSave } = props;
    const {createJobStore, jobSettingsStore} = useContext(RootStoreContext);

    const form = useFormik({
        initialValues: {
            command: jobSettingsStore.customCommandToTest,
            result: jobSettingsStore.customCommandExpectedResult,
            commandLinux:jobSettingsStore.customCommandToTestLinux,
            resultLinux:jobSettingsStore.customCommandExpectedResultLinux,
            isExt:jobSettingsStore.customCommandToTestIsExternal
        },
        onSubmit: () => { },
        validationSchema
    })

    useEffect(() => {
        let initialValues;
        initialValues =
        {
            command: jobSettingsStore.customCommandToTest,
            result: jobSettingsStore.customCommandExpectedResult,
            commandLinux:jobSettingsStore.customCommandToTestLinux,
            resultLinux:jobSettingsStore.customCommandExpectedResultLinux,
            isExt:jobSettingsStore.customCommandToTestIsExternal
        };
        form.resetForm({values: initialValues});
      }, [open]);

    const loadCustomeList =(type:any)=>{
        let customCommand=[];
        let platform = type ==="windows" ? DevicePlatform.Windows :DevicePlatform.UNIX;
        let list=jobSettingsStore.customList.filter(item=>item.os == platform);
        list.map(item=>{
            customCommand.push(item.command)
        });
        return customCommand;
    }

    const loadCustomeResultList =(type:any)=>{
        let customResult=[];
        let platform = type ==="windows" ? DevicePlatform.Windows :DevicePlatform.UNIX;
        let list= jobSettingsStore.customList.filter(item=>item.os == platform);
        list.map(item=>{
            customResult.push(item.result);
        });
        return customResult;
    }

    let getPreviousCustomWindows = loadCustomeList("windows");
    let getPreviousCustomLinux = loadCustomeList("linux");
    let getPreviousCustomResWindows = loadCustomeResultList("windows");
    let getPreviousCustomResLinux = loadCustomeResultList("linux");

    const classes = useStyles();
    const inputFile = useRef(null);
    const inputFileLinux = useRef(null);
    const [cancel,setCancel]=useState(false);
    const [ShowBrowse,setShowBrowse]= useState(true);
    const [ShowBrowseLinux,setShowBrowseLinux]= useState(true);

    const { t } = useTranslation('createJobBase');

    const handleClose = () => {
        if(form.touched && form.dirty)
            setCancel(!cancel);
        else
            onClose();
    }

    const handleSave=()=>{
        try
        {
            jobSettingsStore.updateCustomSettings(form.values.command,form.values.result,form.values.isExt,form.values.commandLinux,form.values.resultLinux);
            toast.success('Save successful');
            let jobSettingItems =
            [
                {JobSettingKey:'Custom',JobSettingValue:form.values.command,JobSettingResult:form.values.result,Platform:DevicePlatform.Windows},
                {JobSettingKey:'Custom',JobSettingValue:form.values.commandLinux,JobSettingResult:form.values.resultLinux,Platform:DevicePlatform.UNIX}
            ];
            jobAgent.CreateJobActions.CreateJobSetting(jobSettingItems).then(res =>{
                runInAction(() => {
                    getPreviousCustomWindows = res;
                    getPreviousCustomLinux = res;
                    getPreviousCustomResWindows = res;
                    getPreviousCustomResLinux = res;
                 })
                toast.success(`Added setting to default options successfully`);
               }).catch(
                   err => {
                       toast.error(`Failed to add setting to default options`);
                   }
               )

           
        }
    catch(error){
        toast.success('Save failed'+error);
    }
        setCancel(false);
        onClose();
    }

    const handleTest=() =>{        
    }

    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel style={{marginLeft:'10px'}}>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const handleYes=()=>{
        let initialValues = {
            command: jobSettingsStore.customCommandToTest,
            result: jobSettingsStore.customCommandExpectedResult,
            commandLinux: jobSettingsStore.customCommandToTestLinux,
            resultLinux: jobSettingsStore.customCommandExpectedResultLinux,
            isExt: jobSettingsStore.customCommandToTestIsExternal
        };
        form.setValues(initialValues);
        setCancel(false);
        onClose();
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    const handleExtChange =(event)=>{
        form.setFieldValue("isExt", event.target.checked);
      }

    const handleCommandChange=(event:any,value:any) => {
        if(event!=null){
            if (value!=null && typeof value === 'object') 
            {
              form.setFieldValue("command",value=="" ? '':value.command,true);
              form.setFieldValue("result",value=="" ? '':value.result,true);
            }
            if (typeof value === 'string')
            {
              let commandObj ;
              if(value.includes("netdom"))  
                commandObj = jobSettingsStore.customList.find(e => e.command.toLocaleLowerCase().includes("netdom"));
              else
                commandObj = jobSettingsStore.customList.find(e => e.command.toLocaleLowerCase() == value.toLocaleLowerCase());
              form.setFieldValue("command", commandObj!=undefined ? value:(value==""?'':value),true);
              let res = commandObj== undefined ? '' :commandObj.result;
              form.setFieldValue("result",res,true);
            }
            /*if(event.target.value===''){
            customCommandData=null;
            customCommandName='';
            form.setFieldValue('command','',true);
            setShowBrowse(true);
            }
            if(event.target.value!==null && event.target.value!=='')
                customCommandData = event.target.files!=null ? compressFile(event.target.files[0]):null;
                */
          }
    }

    const handleCommandLinuxChange=(event:any,value:any) => {
        if(event!=null){
            if (value!=null && typeof value === 'object') 
            {
              form.setFieldValue("commandLinux",value=="" ? '':value.command,true);
              form.setFieldValue("resultLinux",value=="" ? '':value.result,true);
            }
            if (typeof value === 'string')
            {
              let commandObj ;
              if(value.includes("netdom"))  
                commandObj = jobSettingsStore.customList.find(e => e.command.toLocaleLowerCase().includes("netdom"));
              else
                commandObj = jobSettingsStore.customList.find(e => e.command.toLocaleLowerCase() == value.toLocaleLowerCase());
              form.setFieldValue("commandLinux", commandObj!=undefined ? value:(value==""?'':value),true);
              let res = commandObj== undefined ? '' :commandObj.result;
              form.setFieldValue("resultLinux",res,true);
            }
            /*if(event.target.value===''){
            customCommandDataLinux=null;
            customCommandNameLinux='';
            form.setFieldValue('commandLinux','',true);
            setShowBrowseLinux(true);
            }
            if(event.target.value!==null && event.target.value!=='')
                customCommandDataLinux = event.target.files!=null ? compressFile(event.target.files[0]):null;*/
          }
    }

    const handleResultChange=(event:any,value:any)=>{
        if(event!=null)
        {
            if(event.type ==="click")
            {
                const Obj = jobSettingsStore.customList.find(e => e.result == value);
              let res = Obj== undefined ? '' :Obj.result;
              form.setFieldValue("result",res,true);
            }
            else if(event.type ==="change")
            {
                form.setFieldValue("result",value=="" ? '':value);
            }
        }
    }

    const handleResultLinuxChange=(event:any,value:any)=>{
        if(event!=null)
        {
            if(event.type ==="click")
            {
                const Obj = jobSettingsStore.customList.find(e => e.result == value);
              let res = Obj== undefined ? '' :Obj.result;
              form.setFieldValue("resultLinux",res,true);
            }
            else if(event.type ==="change")
            {
                form.setFieldValue("resultLinux",value=="" ? '':value);
            }
        }
    }

      const handleFileUpload = (e:any,type:any) => {
        const { files } = e.target;
        if (files && files.length) 
        {
        let fileSizebytes=files[0].size/1024;
        const filename = files[0].name;
        var parts = filename.split(".");
        const fileType = parts[parts.length - 1];
        let fileTypePerOSInvalid = type ==="windows" ? DevicePlatform.Windows : DevicePlatform.UNIX;
        let invalidFileSize = fileSizebytes > 512;
        if(!invalidFileSize && !fileTypePerOSInvalid)
        {
            form.setFieldValue(type ==="windows"? "command":"commandLinux",files[0].name);
            const toBase64 = file => new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = () => resolve(reader.result);
                  reader.onerror = error => reject(error);
                });
            toBase64(files[0]).then(function(res) {
                type ==="windows" ? createJobStore.updateCustomDefaultScript(res): createJobStore.updateCustomDefaultScriptLinux(res);
                });
                type ==="windows"? setShowBrowse(false):setShowBrowseLinux(false);
            form.setFieldValue(type ==="windows"? "result":"resultLinux",'',true);
        }
        else
        {
            if(invalidFileSize)
            form.setErrors({ command: 'custom command script can not exceed 512KB' });
        else
            form.setErrors({ command: 'file type must be '});
        }
    }
    };


    const removeFile =(type:any)=>{
        form.setFieldValue(type === "windows"?'command':'commandLinux','',true);
        type === "windows"? setShowBrowse(true):  setShowBrowseLinux(true);
    }

    return <Dialog disableBackdropClick
        open={open}
        classes={{ paper: classes.root }}
        onClose={handleClose}
        scroll="paper"
    >
        <EDRDialogTitle>
        <div style={{ display: 'flex', flexDirection: 'row'}}>
            <Typography variant="h6" className={classes.title} style={{display:'flex',alignItems:'center'}}>{t('customSetting')}</Typography>
        </div>
        </EDRDialogTitle>
        <DialogContent dividers={true}>
        <input style={{ display: "none" }} ref={inputFile} type="file" onChange={(event)=> { 
               handleFileUpload(event,"windows") 
               event.target.value=null
        }}/>
          <input style={{ display: "none" }} ref={inputFileLinux} type="file" onChange={(event)=> { 
               handleFileUpload(event,"Linux") 
               event.target.value=null
        }}/>
        <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row',paddingBottom:'5%'}}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('subCustomDefaultWin')}</Typography>
                    <Tooltip title={t('customInfoDefaultWin')} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip> 
                    <Divider className={classes.divider}/>
                </div>
            </Grid>

            <Grid container direction="column" spacing={2}>
                <Grid item container spacing={2}>
                    <Grid item xs={3} container alignItems="center">
                        <Typography variant="h6" className={classes.heading}>* {t('customCommand')}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                    <EDRAutocomplete id="command"
                        freeSolo
                        disableClearable
                        value={form.values.command}
                        options={getPreviousCustomWindows}
                        className={classes.autoWithButton}
                        onChange={handleCommandChange}
                        onInputChange={handleCommandChange}
                        renderOption={(option: string|{command: string,result:string}) => (typeof option === 'string' ? option : `${option.command}`)}
	                    getOptionLabel={(option: string|{command: string,result:string}) => (typeof option === 'string' ? option : `${option.command}`)}
                        renderInput=
                        {(params) => (<div style={{position: 'relative', width: '100%'}}>
                                <EDRTextField classes={{root: classes.autoCompleteWithButton}}fullWidth {...params} label={t('customCommand')} 
                        style={{borderColor:'#c7cfdb'}}
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="command"
                        onBlur={form.handleBlur}
                        error={form.touched && form.touched.command && form.errors && Boolean(form.errors.command)}
                        helperText={form.touched && form.touched.command && form.errors && Boolean(form.errors.command) && t(form.errors.command)}
                    />
                     {ShowBrowse === true ? <Button className={classes.BrowseButton} onClick={()=>inputFile.current.click()} style={{height: '51px', position: 'absolute', right: 1, top: 1, width: 70}}>{ t("browse")}</Button>
                    :<Button size="small" className={classes.BrowseButton} onClick={()=>removeFile("windows")} style={{height: '51px', position: 'absolute', right: 1, top: 1, width: 70}}>{t("remove")}</Button>}
                        </div>)}
                    />  
                    </Grid>
                </Grid>

                <Grid item container style={{paddingTop:'4%'}}>
                    <Grid item xs={3} container alignItems="center">
                        <Typography variant="h6" className={classes.heading}>{t('commandResult')}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                    <EDRAutocomplete id="result"
                        freeSolo
                        disableClearable
                        value={form.values.result}
                        options={[]}
                        onChange={handleResultChange}
                        onInputChange={handleResultChange}
                        renderOption={(option: string|{command: string,result:string}) => (typeof option === 'string' ? option : `${option.result}`)}
	                    getOptionLabel={(option: string|{command: string,result:string}) => (typeof option === 'string' ? option : `${option.result}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={t('commandResult')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="result"
                        onBlur={form.handleBlur}
                        error={form.touched.result && Boolean(form.errors.result)}
                        helperText={form.errors.result}
                    />)}
                    />  
                    </Grid>
                </Grid>
            </Grid>

            <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row',paddingBottom:'5%',paddingTop:'2%'}}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{t('subCustomDefaultLinux')}</Typography>
                    <Tooltip title={t('customInfoDefaultLinux')} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip> 
                    <Divider className={classes.divider}/>
                </div>
            </Grid>

            <Grid container direction="column" spacing={2}>
                <Grid item container spacing={2}>
                    <Grid item xs={3} container alignItems="center">
                        <Typography variant="h6" className={classes.heading}>* {t('customCommand')}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                    <EDRAutocomplete id="commandLinux"
                        freeSolo
                        disableClearable
                        value={form.values.commandLinux}
                        options={getPreviousCustomLinux}
                        className={classes.autoWithButton}
                        onChange={handleCommandLinuxChange}
                        onInputChange={handleCommandLinuxChange}
                        renderOption={(option: string|{command: string,result:string}) => (typeof option === 'string' ? option : `${option.command}`)}
	                    getOptionLabel={(option: string|{command: string,result:string}) => (typeof option === 'string' ? option : `${option.command}`)}
                        renderInput=
                        {(params) => (<div style={{position: 'relative', width: '100%'}}>
                                <EDRTextField classes={{root: classes.autoCompleteWithButton}}fullWidth {...params} label={t('customCommand')} 
                        style={{borderColor:'#c7cfdb'}}
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="commandLinux"
                        onBlur={form.handleBlur}
                        error={form.touched && form.touched.command && form.errors && Boolean(form.errors.command)}
                        helperText={form.touched && form.touched.command && form.errors && Boolean(form.errors.command) && t(form.errors.command)}
                    />
                     {ShowBrowseLinux === true ? <Button className={classes.BrowseButton} onClick={()=>inputFileLinux.current.click()} style={{height: '51px', position: 'absolute', right: 1, top: 1, width: 70}}>{ t("browse")}</Button>
                    :<Button size="small" className={classes.BrowseButton} onClick={()=>removeFile("Linux")} style={{height: '51px', position: 'absolute', right: 1, top: 1, width: 70}}>{t("remove")}</Button>}
                        </div>)}
                    />  
                    </Grid>
                </Grid>

                <Grid item container style={{paddingTop:'4%'}}>
                    <Grid item xs={3} container alignItems="center">
                        <Typography variant="h6" className={classes.heading}>{t('commandResult')}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                    <EDRAutocomplete id="resultLinux"
                        freeSolo
                        disableClearable
                        value={form.values.resultLinux}
                        options={[]}
                        onChange={handleResultLinuxChange}
                        onInputChange={handleResultLinuxChange}
                        renderOption={(option: string|{command: string,result:string}) => (typeof option === 'string' ? option : `${option.result}`)}
	                    getOptionLabel={(option: string|{command: string,result:string}) => (typeof option === 'string' ? option : `${option.result}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={t('commandResult')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="resultLinux"
                        onBlur={form.handleBlur}
                        error={form.touched.result && Boolean(form.errors.result)}
                        helperText={form.errors.result}
                    />)}
                    />  
                    </Grid>
                </Grid>
            </Grid>



            <Grid item container xs={12} style={{marginTop:'4%'}}>
                <Grid item xs={10}>
                <div className={classes.toggleItem}>
                    <ListItemText className={classes.text} style={{marginLeft:'0px',marginTop:'2%'}}>{t("testRemote")}</ListItemText>
                </div>
                </Grid>
                <Grid item xs={2}>
                <div className={classes.centerVertical} style={{justifyContent:'flex-end'}}>
                <Tooltip title="Testing will be done by connecting to the remote device. you can alternatively toggle this button to test remotely from the EnsureDR controller" arrow>
                        <Switch checked={form.values.isExt} name="toggle2nd"  onChange={handleExtChange} onBlur={form.handleBlur} color='primary' />
                </Tooltip>
                </div>
                </Grid>  
            </Grid>

        </DialogContent>
        <DialogActions>
        <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end',marginRight:'2%'}}>
            <Button onClick={handleClose} color="primary" style={{textTransform:'none'}}>
                {t('cancel')}
            </Button>
            {/*device === undefined ? null :<Button onClick={handleTest} disabled={form.values.command==''} color="primary" style={{textTransform:'none'}}> 
                {t('test')}
</Button>}*/}
            <SaveButton onClick={handleSave} variant="contained" color="primary" style={{textTransform:'none'}} disabled={form.values.command === ''}>
                {t('save')}
            </SaveButton>
            </Grid>
        </DialogActions>
    </Dialog>
}

export default CustomTestConfigureDialog;