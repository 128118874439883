
import { Dialog, DialogContent, Grid, makeStyles, useTheme } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EDRDialogTitle } from "../../../app/common/EDRDialogTitle";
import { DataMover } from '../../../app/models/BaseSettings.enum';
import { RootStoreContext } from '../../../app/stores/root.store';
import BaseSettingPreviewComponent from './BaseSettingPreview.component';
import NetworkPreview from './NetworkPreview.component';
import DevicesPreview from './DevicesPreview.component';

const useStyles = makeStyles(theme => ({
    root: {
        width: '58%',
        maxWidth:'58%'
    },
    h33: {
        height: '33.33%',
        overflow: 'hidden'
    },
    h66: {
        height: '66.66%'
    },
    h50: {
        height: '50%'
    }
}))
export interface props {
    open: boolean;
    onClose: () => void;
}

const FullSummaryDialog: React.FC<props> = ({onClose, open}) => {
    
    const { createJobStore } = useContext(RootStoreContext);
    const isPaaS= createJobStore.baseSettings.dataMover== DataMover.PaaS;
    const { t } = useTranslation('createJobPreview');
    const theme = useTheme();
    const classes = useStyles();
   
    const handleClose =()=>{
        onClose();
    }

    const loadBasePreview =()=>{
        return (
            <Grid item className={isPaaS? classes.h50 : classes.h33} style={{flexGrow: 1,display:'flex',paddingTop:'2%'}}>
                <BaseSettingPreviewComponent/>
            </Grid>
        )
    }

    const loadNetworkPreview=()=> {
        if(!isPaaS)
        return(
            <Grid item className={classes.h33} style={{flexGrow: 1,display:'flex'}}>
               <NetworkPreview/>
            </Grid>
        )
        else return null;
    }

    const loadDevicesPreview=()=> {
        return(
            <Grid item className={isPaaS? classes.h50 : classes.h33} style={{flexGrow: 1,display:'flex',paddingBottom:'2%'}}>
                <DevicesPreview/>
            </Grid>
        )
    }

    return <Dialog
        open={open}
        classes={{ paper: classes.root }}
        onClose={handleClose}
        scroll="paper"
    >
        <EDRDialogTitle style={{fontSize:'16px',fontWeight: 500}}>{createJobStore.baseSettings.jobName+": " +t('summary')}</EDRDialogTitle>
        <DialogContent style={{backgroundColor: '#f4f5f9'}}>
        <Grid container direction="column" spacing={2} justify='space-between'>
            {loadBasePreview()}
            {loadNetworkPreview()}
            {loadDevicesPreview()}
        </Grid>
        </DialogContent>
    </Dialog>
}

export default FullSummaryDialog;