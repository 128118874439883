import { Button, Card, Checkbox, Chip, Divider, FormControl, FormControlLabel, Grid, InputLabel, makeStyles, Radio, RadioGroup, TextField, Typography, withStyles } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import clsx from 'clsx';
import { RootStoreContext } from "../../../app/stores/root.store";
import { useTranslation } from "react-i18next";
import CustomEmailSettingsComponent from "./CustomEmailSettings.component";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EDRTextField } from "../../../app/common/EDRTextField";
import { DataMover } from "../../../app/models/BaseSettings.enum";
import { EDRAutocomplete } from "../../../app/common/EDRAutocomplete";
import jobAgent from "../../../app/api/jobAgent";


interface Props {
    onChange: (val: {sendEmail:boolean, emailTo:Array<string>}) => void;
    noChips: boolean;
}

const ReportPreview: React.FC<Props> = (props) => {
    const rootStore = useContext(RootStoreContext);
    const {displayStore} = rootStore;
    const {updateEmailObj} = rootStore.settingStore;
    const [ImageColor, setImageColor] = useState(false);
    const [openEmailSettingsDialog, setopenEmailSettingsDialog] = useState(false);
    const [emailList, setEmailList] = useState<string[]>([]);
    const [emailError, setemailError] = useState<string>(null);

    const CustomCheckbox = withStyles({
        root: {
            color: '#4d5f75',
          '&$checked': {
            color: displayStore._disableSectionSave || displayStore._disablePreview ? 'rgb(233, 235, 238)':'#2892d7',
            },
        },
        checked: {},
    })(Checkbox);

    const useStyles = makeStyles((theme) => ({
        autocomplete:{
        "& .MuiFormHelperText-root": {
            position: 'absolute',
            bottom: '-22px',
            left:'-14px'
         }
        },
        divider: {
            backgroundColor: '#eaeef4',
            width: '100%',
            margin: '0',
            marginTop: '30px'
        },
        errorText:{
            color:'red',
            marginTop:'10px',
            fontSize: '0.75rem',
            textAlign: 'left',
            fontFamily: "Roboto",
            fontWeight: 400,
            lineHeight: 1.66,
            letterSpacing: '0.03333em'
          },
        button: {
            minWidth:'45px',
            height: '36px',
            borderRadius: '4px',
            border: 'solid 1px #c7cfdb',
            backgroundColor: '#fafafc'
        },
        advanced: {
            width: '14px',
            height: '14px',
            objectFit: 'contain',
        },
        text: {
            fontFamily:'Roboto',
            fontWeight:500,
            fontSize:'13px',
            fontStretch:'normal',
            fontStyle: 'normal',
            lineHeight:'1.14',
            letterSpacing: '0.13px',
            textAlign:'left',
            color: '#4d5f75'
        },
        inputLabel: {
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '12px',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.14',
            letterSpacing: '0.13px',
            textAlign: 'left',
            color: '#2892d7',
            marginLeft: '10px'
        },
        heading: {
            color: '#1f396d',
            fontWeight: 500,
        },
        card: {
            padding: 16,
            width: '100%',
            boxSizing: 'border-box',
            height: '100%'
        },
        formControl: {
            width: '100%',
            minHeight: '40px',
            '& label': {
                background: "white",
                width: "auto",
                padding: "0 4px 0 4px",
                fontSize: '17px'
            }
        }
    }));
    const classes = useStyles();
    const [t] = useTranslation("CreateJobPreview");

    const validation = yup.object({
        
        emailTo: yup.array().nullable().when(["sendEmail", "settingsEmail"], {
            is: (sendEmail, settingsEmail) => {  
                if(sendEmail && settingsEmail.length ==0)
                    return true;
                if(!sendEmail || settingsEmail.length)
                    return false;
                return false;
            },
            then: yup.array(yup.string().required().email("invalid email")).min(1, "At least one email is required"),
            otherwise: yup.array(yup.string().required().email())
            
            
            // .string().nullable().test('emailTo','Invalid Email Address',
            // function(value){
            //         if(value!=null && value !='')
            //         {
            //             let valueSplit = value.split(';');
            //             let allGood = true;
            //             valueSplit.map(val => {
            //                 let isValidEmail = emailRegex.test(val);
            //                 if(!isValidEmail)
            //                 {
            //                     allGood = false;
            //                 }
            //             })
            //             return allGood;
            //         }
            //         else return true;
            //     }).test('requiredIf','Email Address is a required field',function(val){
            //        if(emailList && emailList.length > 0)
            //             return true
            //         if(val == '' || !val)
            //             return false;
            //         return true;
            //    })
           })
    })

    const form = useFormik({
        initialValues: {
            sendEmail: rootStore.createJobStore.jobReportSetting.sendEmail,
            emailTo: [],
            settingsEmail: []
        },
        validationSchema:
            validation,
        onSubmit: () => { },
    })

    useEffect(() => {
        jobAgent.CreateJobActions.ListAllEmails().then(res => {
            var settingsEmail = res ? [...res]:[]
                setEmailList([...settingsEmail]);
                // form.setFieldValue('settingsEmail', [...res]);

                const initialValues =
                {
                    sendEmail:rootStore.createJobStore.jobReportSetting.sendEmail,
                    emailTo:["admin@ensuredr.com"],
                    settingsEmail: [...settingsEmail]
                };
                form.setValues(initialValues);
        });
    }, []);


    useEffect(() => {
        props.onChange && props.onChange(form.values);
      
            const emailWithoutDup = Array.from(new Set(form.values.emailTo));
            // if(form.dirty) {
                // updateEmailObj(emailList.length === 0 ? form.values.emailTo : emailWithoutDup.join(';'), "EmailTo", "Email")
                rootStore.createJobStore.updateJobReportSetting({emailTo: emailWithoutDup.join(';'), sendEmail: form.values.sendEmail})
            // }
       
    },[form.values])
   
    useEffect(() => {
        if(form.values.emailTo?.length >0) {
            form.setFieldValue('sendEmail', true);
        }
    }, [form.values.emailTo])

    const handleSendEmailChange = (event) => {
        form.setFieldValue("sendEmail", event.target.checked);
        rootStore.createJobStore.updateJobReportSetting({ sendEmail: event.target.checked });
    }

    const handleSendTestEmail = (event, disabled, key) => {
        if (!disabled)
            rootStore.settingStore.sendTestEmail(key, true);
    }

    const checkDisabled = () => {
        return !form.isValid
            
    }

    const handleOpenpopup = () => {
        //if (form.values.emailTo !== "" && form.values.sendEmail)
            setopenEmailSettingsDialog(true);
    };

    const handleChangeImgColor = (mouseEvent: string) => {
        if (form.values.emailTo.length > 0 && form.values.sendEmail) {
            if (mouseEvent == "enter")
                setImageColor(true);
            else
                setImageColor(false);
        }
    }

    const handleEmailChange = (event, value) => {
        const emailValidator = yup.array(yup.string().email());
        emailValidator.isValid(value).then(isValid => {
           if(!isValid)
            return; 
           form.setFieldValue('emailTo', value);
           form.setFieldTouched('emailTo', true);
           const emails = Array.from(new Set([...emailList, ...value]));
           updateEmailObj(emails.join(';'), 'emailTo', 'Email')
        }).catch(err => {
            
        }).finally(() => {
            setemailError(null);
        })
        event.stopPropagation();
    }

    const handleBlurEmail =(event)=>{
        const emailValidator = yup.array(yup.string().required().email());
        let newArray = [...form.values.emailTo,event.target.value];
        emailValidator.isValid(newArray).then(isValid => {
            if(isValid)  
            form.setFieldValue('emailTo', newArray);
            const emails = Array.from(new Set([...emailList, ...newArray]));
            updateEmailObj(emails.filter(Boolean).join(';'), 'emailTo', 'Email');
         }).catch(err => {}).finally(() => {
            setemailError(null);
        })
    }

    return (
            <Grid container direction="column" style={{marginTop:'16px', paddingBottom: 10}}>

                <Grid container direction="row">
                    <Grid item xs={4} direction="row">  
                        <FormControlLabel
                            value="Email To:"
                            control={<CustomCheckbox checked={form.values.sendEmail} disabled={true} onChange={handleSendEmailChange} />}
                            label={<Typography className={classes.text}>{t('emailTo')}</Typography>}
                        />
                    </Grid>
                    <Grid item container xs={8} direction="row" spacing={1}>
                        <Grid item xs={10} style={{position: 'relative'}}>
                            <FormControl className={classes.formControl} variant='outlined'>
                            <EDRAutocomplete id="emailTo"
                            size="small"
                            freeSolo
                            disableClearable
                            multiple = {true}
                            disabled={true}
                            value={form.values.emailTo}
                            options={[]}
                            limitTags={4}
                            style={{maxHeight: '130px',overflowY: 'scroll'}}
                            onChange={handleEmailChange}
                            onBlur={() => {
                                form.setFieldTouched('emailTo', true);
                            }}
                            renderTags={(value: string[], getTagProps) =>
                              {
                                  var _values = typeof value === 'string' ? [value]: value;
                                  return   _values.map((option: string, index: number) => (
                                    <Chip variant="outlined" style={{color:form.errors?.emailTo && form.errors.emailTo[index] ? 'red': 'inherit', height:"100%"}} label={<Typography style={{whiteSpace: 'normal'}}>{option}</Typography>} {...getTagProps({ index })} />
                            ))
                              }
                            }
                            renderInput={(params) => (<EDRTextField {...params} 
                            InputLabelProps={{shrink: true}} 
                            name="emailTo"
                            variant="outlined"
                            placeholder="Add Additional Email Address"
                            onBlur={handleBlurEmail}
                            onInput={event => {
                                const value = event.target['value'];
                                if(!value){
                                    setemailError(null);
                                    return;
                                }
                                var isEmailValid = yup.string().email().isValidSync(value);
                                setemailError(isEmailValid? null: 'Invalid email');
                            }}
                            onKeyDown={event => {
                                if(Boolean(emailError) && event.key === 'Enter') {
                                    event.stopPropagation();
                                }
                            }}
                            error={Boolean(emailError) || (form.touched.emailTo && Boolean(form.errors.emailTo))}
                            // helperText={ form.touched.emailTo && t(form.errors.emailTo as string)}
                            />)}
                            /> 
                            </FormControl>
                            { (form.errors?.emailTo || emailError) && <em style={{color: 'red', position: 'absolute', bottom: -12, left: 5}}> {emailError ||t(form.errors.emailTo as string)}</em>}
             
                        </Grid>
                        <Grid item xs={2} style={{display:'flex',paddingLeft:'6%'}}>
                            {/* <Button classes={{root: classes.button}} disabled={true} onClick={handleOpenpopup} size="small"> */}
                            <Button classes={{root: classes.button}} disabled={true} onClick={handleOpenpopup} size="small">
                                <img style={{ cursor: 'pointer' }} className={classes.advanced} onMouseEnter={() => handleChangeImgColor("enter")} onMouseLeave={() => handleChangeImgColor("leave")} src={displayStore._disableSectionSave? '/assets/images/createjob/advancedNetworkDisabled.svg':(ImageColor ? '/assets/images/createjob/advancedNetworkBlue.svg' : '/assets/images/createjob/advancedNetwork.svg')}></img>
                            </Button>
                        </Grid>
                        <Grid container xs={10} direction="row">
                        <Grid item xs={6}>
                         </Grid>
                        <Grid xs={6} item container justify="flex-end" style={{padding: '0px 5px 10px 0',cursor: 'pointer'}} >
                            {/* <img className={classes.advanced} src={'/assets/images/createjob/envelope.svg'}></img> */}
                            {/* <InputLabel className={classes.inputLabel} disabled={checkDisabled()} style={{cursor: 'pointer'}} onClick={(event) => handleSendTestEmail(event, checkDisabled(), emailTo)}>{t("testEmail")}</InputLabel> */}
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>


                <CustomEmailSettingsComponent open={openEmailSettingsDialog} onClose={() => setopenEmailSettingsDialog(false)} />
            </Grid>
    );
}

export default observer(ReportPreview);