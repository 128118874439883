import { withStyles, DialogTitle } from "@material-ui/core";


export const EDRDialogTitle = withStyles({
    root: {
        background: "#1f396d",
        color: 'white',
        padding: '10px 24px'
    }
})(DialogTitle);
