import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LinearProgress, makeStyles, Grid } from "@material-ui/core";
import { IDevicesPanelConfig } from "../../../app/models/dashboard/devicePanel.model";
import CheckCircleIcon  from "@material-ui/icons/CheckCircle";
import CrossIcon from '@material-ui/icons/CancelRounded';
import { ReactComponent as SuitcaseIcon } from "../../../assets/dashboardAssets/suitcase.svg";
import { ReactComponent as LanguageIcon } from "../../../assets/dashboardAssets/web.svg";
import { ReactComponent as SecurityIcon } from "../../../assets/dashboardAssets/shield.svg";
import DnsIcon from "@material-ui/icons/Dns";
import DevicesIcon from "@material-ui/icons/Devices";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { DeviceType } from "../../../app/models/deviceType.enum";
import { useStatusColor } from "../../../app/common/util/useStatusColor.hook";
import {DEVICE_TYPE_NAME_SEPERATE_DC,DEVICE_TYPE_SMALL_ICON} from "../../../app/models/deviceType.enum";

const DevicesBar = (devicePanelConfig: IDevicesPanelConfig) => {
  
  let icon =<img src={DEVICE_TYPE_SMALL_ICON[devicePanelConfig.type]} style={{ fill: "#8092a9", width:"15%", height:"50%"}}/>;

  const index =
  devicePanelConfig.ok > 0 || devicePanelConfig.failed > 0
      ? 100 / (devicePanelConfig.ok + devicePanelConfig.failed)
      : 0;
  const result = index * devicePanelConfig.ok;

  useEffect(() => {
    //callMatomo("DevicesBar","/dashboard");
  }, []);

  const useStyles = makeStyles({
    colorPrimary: {
      backgroundColor: "#077187",
      "& ::after": {
        content: '""',
        //height: "100%",
        right: 0,
        width: 2,
        //position: "absolute",
        backgroundColor: "white",
      },
    },
    barColorPrimary: {
      backgroundColor: "#8bc53f",
    },
    testBar: {
      fontFamily: "Roboto",
      paddingTop: "2%",
      paddingLeft:"4%"
    },
    text: {
      color: "#4d5f75",
    },
    alignCenter: {
      alignSelf: "center",
    },
  });

  const classes = useStyles();
  const okIconColor = useStatusColor("passed", devicePanelConfig.ok);
  const failedIconColor = useStatusColor("failed", devicePanelConfig.failed);

  return (
    <Grid container style={{lineHeight:0,height:"52px"}} direction="row" data-test="component-TestBar" className={classes.testBar}>
      <Grid item style={{paddingTop:"0.5em"}} xs={3}>
        {icon} <span className={classes.text}>{DEVICE_TYPE_NAME_SEPERATE_DC[devicePanelConfig.type]}</span>
      </Grid>
      <Grid item xs={7} className={classes.alignCenter} style={{ paddingRight: "10px" }}>
        <LinearProgress
          variant="determinate"
          value={result}
          classes={{
            colorPrimary: classes.colorPrimary,
            barColorPrimary: classes.barColorPrimary,
          }}
        ></LinearProgress>
      </Grid>
      <Grid item xs={2} className={classes.alignCenter}>
        <CheckCircleIcon style={{fontSize: 18, color: okIconColor}} />{" "}
        <span className={classes.text}>{devicePanelConfig.ok}</span>{" "}
        <CrossIcon style={{fontSize: 18,  color: failedIconColor}} />{" "}
        <span className={classes.text}>{devicePanelConfig.failed}</span>
      </Grid>
    </Grid>
  );
};

export default observer(DevicesBar);
