import { Button, Card, CircularProgress, Divider, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField, Tooltip, Typography} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import HelpIcon from '@material-ui/icons/Help';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { runInAction } from 'mobx';
import { Autocomplete } from '@material-ui/lab';
import { EDRAutocomplete } from '../../../app/common/EDRAutocomplete';
import { EDRTextField } from '../../../app/common/EDRTextField';
import Create from '@material-ui/icons/Create';
import { RootStoreContext } from '../../../app/stores/root.store';
import { JobType, LicenseType, NetworkOption } from '../../../app/models/jobProperties.model';
import LockIcon from '@material-ui/icons/Lock';

const TupleCreateComponent: React.FC = () => {
    const [t] = useTranslation("createJobNetwork");
    const rootStore = useContext(RootStoreContext);
    const {jobSettingsStore,createJobStore,displayStore} = rootStore;
    const networkType = createJobStore.networkSetting.NetworkType;
    const useStyles = makeStyles((theme) => ({
        editIcon: {
            cursor: 'pointer'
        },
        button:{
            width: '50%',
            height: '100%',
            borderRadius: '4px',
            border: 'solid 1px #c7cfdb',
            backgroundColor: '#fafafc'
        },
        advanced:{
            width: '14px',
            height: '14px',
            objectFit: 'contain',
        },
        MainHeading:{
            fontFamily: 'Roboto',
            color: '#1f396d',
            fontSize: '16px'
        },
        SecondHeading:{
            fontFamily:'Roboto',
            fontWeight:500,
            fontSize:'100%',
            fontStretch:'normal',
            fontStyle: 'normal',
            lineHeight:'1.14',
            letterSpacing:'normal',
            textAlign:'left'
        },
        card: {
            padding:25,
            height: '100%',
            overflowY: "auto"
        },
        helpIcon:
        {
            color: '#1976d2',
            fontSize: "large"
        },
        formControl: {
            width: '100%',
            '& label': {
                background: "white",
                width: "auto",
                padding:"0 4px 0 4px",
                fontSize: '17px'
              }
        },
        divider:{
            backgroundColor: '#eaeef4',
            width:'85%',
            margin:'0',
            marginTop: '8px',
            marginLeft: '10px'
        }
    }));    
    const classes = useStyles();
    const NetworkOptions: Array<NetworkOption>=[0,1];
    const [DisableOption, setDisableOption] = useState(networkType == NetworkOption.Single ? false: true);
    const [DisableLiveSource, setDisableLiveSource] = useState(rootStore.createJobStore.baseSettings.jobType==JobType.Live ?(networkType == NetworkOption.Single?true:false):false);

    //create mode variables
    const [networkNameDR, setNetworkNameDR] = useState<string>('');
    const [networkNameBubble, setNetworkNameBubble] = useState<string>('');
    const[networkNames, setNetworkNames] = useState([]);
    const {isFirstTrial} = jobSettingsStore;
    useEffect(() => {
        if(createJobStore.networkSetting.NetworkType == NetworkOption.None)
        {
            setDisableOption(true);
            displayStore.updateNetworkSectionSettings({DisableButtonDC:true});
            displayStore.updateNetworkSectionSettings({DisableButtonEDRC:true});
            if(createJobStore.baseSettings.jobType==JobType.Test)
            {
                setNetworkNameDR("");
                setNetworkNameBubble("");
                rootStore.createJobStore.updateNetworkSettings({testDRNetwork: {...rootStore.createJobStore.networkSetting.testDRNetwork,networkMapping : [{sourceNetwork:'',targetNetwork:''}]}});
            }
            else if(createJobStore.baseSettings.jobType==JobType.Live)  
            {
                setNetworkNameDR("");
                setNetworkNameBubble("");
                rootStore.createJobStore.updateNetworkSettings({liveDRNetwork: {...rootStore.createJobStore.networkSetting.liveDRNetwork,networkMapping :[{sourceNetwork:'',targetNetwork:''}]}});
            }
        } 
        else
        {
            setDisableOption(false);
            displayStore.updateNetworkSectionSettings({DisableButtonDC:false});
            displayStore.updateNetworkSectionSettings({DisableButtonEDRC:false});
        }   
        displayStore.updateNextStepValid({networkSettingValid: networkType === 0 ? false:true});
        displayStore.updateNextStepValid({networkMappingValid: networkType === 0 ? true:false});

         
        displayStore.updateScreenSettings({displayNetwork: "EmptyVlan"});
        CheckSectionAndDrawerStatus("EmptyVlan");
        //displayStore.updateNetworkSectionSettings({DisableSection: true});
    },[createJobStore.networkSetting.NetworkType])

    useEffect(() => {
        if(networkType != 0 && networkNameDR)
            displayStore.updateNextStepValid({networkMappingValid: (networkNameDR.includes('Add') ||networkNameDR==="") || (networkNameBubble.includes('Add') ||networkNameBubble==="")  ? false:true});
        
        if(networkNameDR && networkNameDR.includes('Add') && (networkNameBubble && !networkNameBubble.includes('Add')))
            displayStore.updateScreenSettings({addNetwork: "Managment"}); 
    
        if(networkNameDR && !networkNameDR.includes('Add') && (networkNameBubble && networkNameBubble.includes('Add')))
            displayStore.updateScreenSettings({addNetwork: "Bubble"}); 
    },[networkNameDR,networkNameBubble])

    useEffect(() => {
        let list;
        runInAction(() => {
            displayStore.updateScreenSettings({NetworkProcessing:true});
            list = jobSettingsStore.vCenterNetworks.map(n => n.name);
            setNetworkNames(list);
            if(list.length > 1)
                displayStore.updateScreenSettings({NetworkProcessing:false});
            setNetworkNameDR(rootStore.createJobStore.networkSetting.testDRNetwork && rootStore.createJobStore.networkSetting.testDRNetwork.networkMapping && rootStore.createJobStore.networkSetting.testDRNetwork.networkMapping[0].sourceNetwork ? rootStore.createJobStore.networkSetting.testDRNetwork.networkMapping[0].sourceNetwork : "");
            setNetworkNameBubble(rootStore.createJobStore.networkSetting.testDRNetwork && rootStore.createJobStore.networkSetting.testDRNetwork.networkMapping && rootStore.createJobStore.networkSetting.testDRNetwork.networkMapping[0].targetNetwork ? rootStore.createJobStore.networkSetting.testDRNetwork.networkMapping[0].targetNetwork : "");            
        })
        setDisableOption(rootStore.createJobStore.networkSetting.NetworkType == NetworkOption.Single ? false: true);
      }, [jobSettingsStore.vCenterNetworks]); 

    const loadCircularProgress =()=>{
        return <div style={{width:'100%',display:'flex'}}>
        {t('InProgress')}
        <CircularProgress id="NetworkValidation" size={18} style={{color: '#2892D7', marginLeft: '10px',marginTop: '-3px'}}/>
        </div>
    }

    const clearValuesRootStore =()=>{

    }

    const buttonRedirect =(subComponent:any)=>{
        if(displayStore.NetworkDrawerSettings.testDrawerOpen)
        {
          if(displayStore.screenSettings.AddModeNetworks)
          {
            displayStore.updateScreenSettings({displayNetwork: subComponent});
            displayStore.updateScreenSettings({AddModeNetworks: false});
            displayStore.updateNetworkDrawerSettings({testDrawerOpen: true});
            displayStore.updateScreenSettings({displayInfo: "InfoDR"}); 
            displayStore.closeAllOtherBaseDrawersExcept("testDrawer");
          }
          else
          {
            displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
            displayStore.updateNetworkDrawerSettings({testDrawerOpen: false});
          }
        }
        else
        { 
          displayStore.updateScreenSettings({displayNetwork: subComponent});
          displayStore.updateScreenSettings({AddModeNetworks: false});
          displayStore.updateNetworkDrawerSettings({testDrawerOpen: true});
          displayStore.updateScreenSettings({displayInfo: "InfoDR"}); 
          displayStore.closeAllOtherBaseDrawersExcept("testDrawer");
        }  
    }
    
    const handleChangeNameBubble = (event:any,value:any,type:string) => { 
        setNetworkNameBubble(value);
        let mapping= [{sourceNetwork:networkNameDR,targetNetwork: value}]
        if(type=="live")
        rootStore.createJobStore.updateNetworkSettings({liveDRNetwork: {...rootStore.createJobStore.networkSetting.liveDRNetwork,networkMapping : mapping}});
        else
            rootStore.createJobStore.updateNetworkSettings({testDRNetwork: {...rootStore.createJobStore.networkSetting.testDRNetwork,networkMapping : mapping}});
        CheckSectionAndDrawerStatus("");
        let dc= jobSettingsStore.selectedDC;
        dc.domainControllerTargetVLAN =value;
        jobSettingsStore.updateSelectedDC(dc);
        if(value.includes('Add'))
        {
            displayStore.updateScreenSettings({addNetwork: "Bubble"}); 
            buttonRedirect(networkType==1 ?'AdvancedVlan':'TestDRVlan');
        }
        else
            displayStore.updateScreenSettings({ addNetwork:'none'});
        displayStore.updateNextStepValid({networkMappingValid: networkNameDR!="" && networkNameBubble !="" ? true:false});
    };

    const handleChangeNameProd = (event:any,value:any,type:string)  => {  
        if(rootStore.createJobStore.baseSettings.jobType==JobType.LiveTest)
        {
            
        }
        else
        {
            setNetworkNameDR(value);
            let mapping= [{sourceNetwork: event.target.value,targetNetwork:networkNameBubble}]
            rootStore.createJobStore.updateNetworkSettings({testDRNetwork: {...rootStore.createJobStore.networkSetting.testDRNetwork,networkMapping : mapping}});
            if(value.includes('Add'))
            {
                displayStore.updateScreenSettings({addNetwork: "Managment"}); 
                buttonRedirect(networkType==1 ?'AdvancedVlan':'TestDRVlan');
            }
            else
                displayStore.updateScreenSettings({ addNetwork:'none'});
        }
        CheckSectionAndDrawerStatus("");
        displayStore.updateNextStepValid({networkMappingValid: networkNameDR!="" && networkNameBubble !="" ? true:false});
    };

    const CheckSectionAndDrawerStatus= (current:string) =>{
        if(displayStore.screenSettings.displayNetwork=='ControllerSettings'||displayStore.screenSettings.displayNetwork=='' || displayStore.screenSettings.displayNetwork=='CloneSettings' || displayStore.screenSettings.displayNetwork == 'EDRServerSettings')
        {
            if(displayStore.screenSettings.displayNetwork!=current )
                displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});

            displayStore.updateScreenSettings({displayNetwork: 'EmptyVlan'});
            displayStore.updateNetworkDrawerSettings({controllerDrawerOpen:false});
            displayStore.updateNetworkDrawerSettings({cloneDrawerOpen:false});
            displayStore.updateNetworkDrawerSettings({liveDrawerOpen:false});
            displayStore.updateNetworkDrawerSettings({testDrawerOpen:false});
        }
    }

    const loadCreateSection=()=>{
        switch(rootStore.createJobStore.baseSettings.jobType)
        {
            case JobType.Live:
                {
                    return(
                        <React.Fragment>
                        <Grid container direction="column" style={{flexGrow:4 }}>
                            <Grid item container spacing={2} style={{display:'flex',marginTop:'25px',marginLeft:'7%'}}>
                                    <Grid item xs={5} >
                                    <FormControl className={classes.formControl}  style={{backgroundColor:(DisableLiveSource ? 'rgba(235, 238, 244, 0.75)': '#ffffff')}}  variant='outlined'>    
                                        <EDRAutocomplete id="production-network-select-label"
                                        disableClearable
                                        disabled={true}
                                        options={networkNames}
                                        value={networkNameDR!="" ? networkNameDR :'Select'}
                                        onChange={(event, newValue)=> handleChangeNameProd(event,newValue,'live')}
                                        renderInput={(params) => (<EDRTextField {...params}  disabled={DisableLiveSource || displayStore.screenSettings.NetworkProcessing} label={t('managmentNetwork')} 
                                        InputLabelProps={{shrink: true}} 
                                        variant="outlined"
                                        placeholder="Select"
                                        name="production-network-select-label"
                                        />)}
                                        />
                                    </FormControl> 
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormControl className={classes.formControl}  style={{backgroundColor:(DisableLiveSource ? 'rgba(235, 238, 244, 0.75)': '#ffffff'),width:'97%'}}  variant='outlined'>    
                                        <EDRAutocomplete id="bubble-network-select-label"
                                        disableClearable
                                        disabled={true}
                                        options={networkNames}
                                        value={networkNameBubble!="" ? networkNameBubble :'Select'}
                                        onChange={(event, newValue)=> handleChangeNameBubble(event,newValue,'live')}
                                        renderInput={(params) => (<EDRTextField {...params}  disabled={DisableLiveSource || displayStore.screenSettings.NetworkProcessing} label={displayStore.screenSettings.NetworkProcessing ?loadCircularProgress():t('bubbleNetwork')} 
                                        InputLabelProps={{shrink: true}} 
                                        variant="outlined"
                                        placeholder="Select"
                                        name="bubble-network-select-label"
                                        />)}
                                        />
                                    </FormControl> 
                                    </Grid>
                            </Grid>
                        </Grid>
                        </React.Fragment>
                        );  
                }
            case JobType.Test:
                {
                    return(
                        <React.Fragment>
                        <Grid container direction="column" style={{ flexGrow:4}}>
                            <Grid item container spacing={2} style={{display:'flex',marginTop:'2%'}}>
                            <Grid item xs={1} style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                                    {isFirstTrial  ? 
                                    <Tooltip title={t("trialLock")} arrow>
                                        <LockIcon />
                                    </Tooltip>
                                    : null}
                                    </Grid>
                                    <Grid item container xs={10} spacing={2}>
                                    <Grid item xs={6}>
                                    <FormControl className={classes.formControl}  style={{backgroundColor:(DisableOption ? 'rgba(235, 238, 244, 0.75)': '#ffffff')}}  variant='outlined'>    
                                        <EDRAutocomplete id="production-network-select-label"
                                        disableClearable
                                        disabled={true}
                                        options={networkNames}
                                        value={networkNameDR!="" ? networkNameDR :'Select'}
                                        onChange={(event, newValue)=> handleChangeNameProd(event,newValue,'test')}
                                        onFocus={()=>displayStore.updateScreenSettings({ displayInfo: "InfoDR" })}
                                        renderInput={(params) => (<EDRTextField {...params} disabled={DisableOption || displayStore.screenSettings.NetworkProcessing} label={displayStore.screenSettings.NetworkProcessing ?loadCircularProgress():t('managmentNetwork')} 
                                        InputLabelProps={{shrink: true}} 
                                        variant="outlined"
                                        placeholder="Select"
                                        name="production-network-select-label"
                                        />)}
                                        />
                                    </FormControl> 
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl className={classes.formControl}  style={{backgroundColor:(DisableOption ? 'rgba(235, 238, 244, 0.75)': '#ffffff')}}  variant='outlined'>    
                                        <EDRAutocomplete id="bubble-network-select-label"
                                        disableClearable
                                        disabled={true}
                                        options={networkNames}
                                        value={networkNameBubble!="" ? networkNameBubble :'Select'}
                                        onChange={(event, newValue)=> handleChangeNameBubble(event,newValue,'test')}
                                        onFocus={()=>displayStore.updateScreenSettings({ displayInfo: "InfoDR" })}
                                        renderInput={(params) => (<EDRTextField {...params} disabled={DisableOption || displayStore.screenSettings.NetworkProcessing} label={displayStore.screenSettings.NetworkProcessing ?loadCircularProgress():t('bubbleNetwork')} 
                                        InputLabelProps={{shrink: true}} 
                                        variant="outlined"
                                        placeholder="Select"
                                        name="bubble-network-select-label"
                                        />)}
                                        />
                                    </FormControl> 
                                    </Grid>
                                    </Grid>
                            </Grid>
                        </Grid>
                        </React.Fragment>
                        );  
                }
            case JobType.LiveTest:
            {
                return ( null
                /*<React.Fragment>
                    <Grid container direction="column">
                        <Grid item direction="row" style={{marginLeft:'4%'}}>
                                <div style={{display: 'flex',flexDirection:'row',marginTop:'16px'}}>
                                <Typography className={classes.SecondHeading} style={{color: '#1f396d'}}>{t('livedr')}</Typography>
                                <Tooltip title={t("livedrInfo")} arrow>
                                    <HelpIcon className={classes.helpIcon} style={{marginLeft:'16px'}}/>
                                </Tooltip>
                                <Divider className={classes.divider}/>
                                </div>
                        </Grid>
                        <Grid item container spacing={2} style={{display:'flex',marginTop:'10px',justifyContent:'center'}}>
                            <Grid item xs={5}>
                                <FormControl className={classes.formControl} variant='outlined'>
                                        <InputLabel>{t('networkType')}</InputLabel>
                                        <Select
                                        labelId="network-select-label"
                                        value={networkType}
                                        displayEmpty={true}
                                        renderValue={name => name=='' ? 'Select': NetworkOption[name as string]}
                                        onChange={handleChangeNetworkType}
                                        label="None">
                                        {NetworkOptions.map((name) => (
                                        <MenuItem key={name} value={name}>
                                        {NetworkOption[name]}
                                        </MenuItem>
                                        ))}
                                        </Select>
                            </FormControl>
                            </Grid>
                            <Grid item xs={5}> 
                                <FormControl className={classes.formControl}  style={{backgroundColor:(DisableOption ? 'rgba(235, 238, 244, 0.75)': '#ffffff')}} variant='outlined'>
                                <EDRAutocomplete id="live-dr-network-select-label"
                                        disableClearable
                                        disabled={DisableOption || displayStore.screenSettings.NetworkProcessing}
                                        options={networkNames}
                                        value={'Select'}
                                        onChange={(event, newValue)=> handleChangeNameProd(event,newValue,'liveTest')}
                                        renderInput={(params) => (<EDRTextField {...params} disabled={DisableOption || displayStore.screenSettings.NetworkProcessing} label={displayStore.screenSettings.NetworkProcessing ?loadCircularProgress():t('Live DR Network')} 
                                        InputLabelProps={{shrink: true}} 
                                        variant="outlined"
                                        placeholder="Select"
                                        name="live-dr-network-select-label"
                                        />)}
                                        />
                            </FormControl>
                            </Grid>
                            <Grid item spacing={2}>
                                <Button className={classes.button} disabled={networkType==0 || networkType==1} onClick={() => buttonRedirect('LiveDRVlan')}>
                                    <Create className={classes.editIcon}></Create>
                                </Button> 
                            </Grid>
                        </Grid>

                        <Grid item direction="row">
                                <div style={{display: 'flex',flexDirection:'row' ,marginTop:'30px',marginLeft:'4%'}}>
                                <Typography className={classes.SecondHeading} style={{color: '#1f396d'}}>{t('testdr')}</Typography>
                                <Tooltip title={t("testdrInfo")} arrow>
                                    <HelpIcon className={classes.helpIcon} style={{marginLeft:'16px'}}/>
                                </Tooltip>
                                <Divider className={classes.divider}/>
                                </div>
                        </Grid>
                        <Grid item container spacing={2} style={{display:'flex',marginTop:'10px',justifyContent:'center'}}>
                            <Grid item xs={5} >
                            <FormControl className={classes.formControl}  style={{backgroundColor:(DisableOption ? 'rgba(235, 238, 244, 0.75)': '#ffffff')}}  variant='outlined'>    
                            <EDRAutocomplete id="test-source-select-label"
                                        disableClearable
                                        disabled={DisableOption || displayStore.screenSettings.NetworkProcessing}
                                        options={networkNames}
                                        value={'Select'}
                                        onChange={null}
                                        renderInput={(params) => (<EDRTextField {...params} disabled={DisableOption || displayStore.screenSettings.NetworkProcessing} label={displayStore.screenSettings.NetworkProcessing ?loadCircularProgress():t('managmentNetwork')} 
                                        InputLabelProps={{shrink: true}} 
                                        variant="outlined"
                                        placeholder="Select"
                                        name="test-source-select-label"
                                        />)}
                                        />
                                    </FormControl> 
                            </Grid>
                            
                            <Grid item xs={5}>
                            <FormControl className={classes.formControl}  style={{backgroundColor:(DisableOption ? 'rgba(235, 238, 244, 0.75)': '#ffffff')}}  variant='outlined'>    
                            <EDRAutocomplete id="test-target-select-label"
                                disableClearable
                                disabled={DisableOption}
                                options={networkNames}
                                value={'Select' || displayStore.screenSettings.NetworkProcessing}
                                onChange={null}
                                renderInput={(params) => (<EDRTextField {...params} disabled={DisableOption || displayStore.screenSettings.NetworkProcessing} label={displayStore.screenSettings.NetworkProcessing ?loadCircularProgress():t('bubbleNetwork')} 
                                InputLabelProps={{shrink: true}} 
                                variant="outlined"
                                placeholder="Select"
                                name="test-target-select-label"
                                />)}
                                />
                            </FormControl> 
                            </Grid>
                            <Grid item >
                                <Button className={classes.button} disabled={networkType==0 || networkType==1} onClick={() => buttonRedirect('TestDRVlan')}>
                                    <Create className={classes.editIcon}></Create>
                                </Button>
                                </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>*/
                );  
            }
        }
    }

    return(
        <Grid container>
            {loadCreateSection()}
        </Grid>
    );
};

export default observer(TupleCreateComponent);