import  SvgIcon  from "@material-ui/core/SvgIcon";
import React from "react"
import {ReactComponent as CustomSvg} from './svgs/custom.svg';

const CustomIcon: React.FC<React.HTMLProps<SVGElement>> = (props) => {
    const {style, className} = props;
    return <SvgIcon viewBox={"-2,-2, 20, 20"}  className={className} style={style} component={CustomSvg}>
    </SvgIcon>
}


export default CustomIcon;