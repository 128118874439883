import React, { ReactElement } from 'react'
import { makeStyles, Tooltip, GridSize, Grid, Typography, LinearProgress, CircularProgress, withStyles, Hidden } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { useTranslation } from 'react-i18next';

function normalise(max) {
    if (max == 0) return function (value) {
        return 100;
    }
    else return function (value) {
        return (value) * 100 / max;
    }
}

const EDRProgressbar = withStyles({
    root: {
        backgroundColor: "#ebeef4",
        borderRadius: 20
    },
    bar: {
        backgroundColor: '#2892d7',
    },
    colorPrimary:{
        paddingRight:10
    }
})(LinearProgress);

const ResourceProgressBar = (props: { isLoading?: boolean, tooltip?: string, moreToolTip?: string[], additionalTitle: string, value: number, maxValue: number, unit: string , conversionFactor?: number, decimals?: number, renderDifferentCutoff?: number, renderProgressbarFull?: (value) => ReactElement, renderProgressBar?: (value) => ReactElement}) => {
    const [t] = useTranslation("license");
    const useStyles = makeStyles((theme) => ({
        helpIcon:
        {
          color: '#1f396d',
          fontSize: "small"
        },
        bold: {
            fontWeight: 600
        },
        singleLine: {
            whiteSpace: 'nowrap',
            flexWrap: 'nowrap'
        }
      }));
      const classes = useStyles();


    const { value, maxValue, unit, conversionFactor = 1, decimals=0 } = props;
    const disabled = value == 0 && maxValue == 0;
    let columnCount = props.additionalTitle ? 6 : 12;
    let valCalc = normalise(maxValue)(Math.min(value, maxValue));
    let useDifferentRender = props.renderProgressbarFull && valCalc;
    if (useDifferentRender!=null && Number(useDifferentRender) < props.renderDifferentCutoff) useDifferentRender = null; 
    if (props.isLoading!=null && props.isLoading)
    {
        return <Grid container direction="column" className={classes.singleLine}>
            <Grid container direction='row' className={classes.singleLine}>
                {props.additionalTitle && 
                <Grid item xs={6}>
                    <Typography variant="body1" align='left' className={classes.bold}>
                        <Hidden lgDown>
                            {props.additionalTitle}
                        </Hidden>
                        <Tooltip title={          
                            <React.Fragment>
                                <Typography color="inherit">
                                    {props.tooltip ? props.tooltip : t("licenseQuotaTooltip")}
                                </Typography>
                                {props.moreToolTip && props.moreToolTip!=null && props.moreToolTip.map(str=>
                                    <Typography color="inherit">
                                        {str}
                                    </Typography>)}
                            </React.Fragment>
                            
                            
                            
                            
                            } arrow>
                            <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                        </Tooltip>
                    </Typography>
                </Grid>}
                <Grid item xs={Number(columnCount - 1) as GridSize}>
                    <Hidden lgDown>
                        <Typography variant="body1" align='left' className={classes.bold}>
                            {t("loadingLicenseInfo")}
                        </Typography>
                    </Hidden>
                </Grid>
                <Grid item xs={Number(1) as GridSize}>
                    <CircularProgress size={"1em"}/>
                </Grid>
            </Grid>
        </Grid>
    }
    else 
    {
        return <Grid container direction="column" className={classes.singleLine}>
            <Grid container direction='row' className={classes.singleLine}>
            {props.additionalTitle && 
                <Grid item xs={6}>
                    <Typography variant="body1" align='left' className={classes.bold}>
                        <Hidden lgDown>
                            {props.additionalTitle}
                        </Hidden>
                        <Tooltip title={          
                            <React.Fragment>
                                <Typography color="inherit">
                                    {props.tooltip ? props.tooltip : t("licenseQuotaTooltip")}
                                </Typography>
                                <br/>
                                {props.moreToolTip && props.moreToolTip!=null && props.moreToolTip.map(str=>
                                    <Typography color="inherit">
                                        {str}
                                    </Typography>)}
                            </React.Fragment>
                            
                            
                            
                            
                            } arrow>
                            <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                        </Tooltip>
                    </Typography>
                </Grid>}
                <Grid item xs={Number(columnCount) as GridSize}>
                    <Typography align="right" variant="body1"  className={classes.bold}>
                        {(value/conversionFactor).toFixed(decimals)}/{(maxValue/conversionFactor).toFixed(decimals)}
                        <Hidden lgDown>
                            {' ' + unit}
                        </Hidden>
                    </Typography>
                </Grid>
            </Grid>
            {props.renderProgressBar && !useDifferentRender && props.renderProgressBar(valCalc)}
            {props.renderProgressBar && useDifferentRender && props.renderProgressbarFull(valCalc)}
            {!props.renderProgressBar && <EDRProgressbar variant="determinate" classes={{bar: disabled? "disabled-background": ""}} style={{ color: disabled? "lightGray":"#2892d7" }} value={valCalc} />}
        </Grid>
    }    


        
}

export default ResourceProgressBar;