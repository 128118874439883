import React, { useContext, useEffect, useState } from 'react';
import { Grid, makeStyles, Badge, Drawer, Divider, TextField, InputAdornment, withStyles, Hidden, Fab, useMediaQuery, CircularProgress, Typography, Button, LinearProgress, Paper } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import clsx from 'clsx';
import ToggleButton from '@material-ui/lab/ToggleButton';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import FilterIcon from '@material-ui/icons/FilterList';

import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useTranslation } from 'react-i18next';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import TimerIcon from '@material-ui/icons/Timer'
import 'react-dates/initialize';


import AppBar, { AppBarTitle } from '../navigation/appbar/appBar.component';
import { RootStoreContext } from '../../app/stores/root.store';
import queryString from 'querystring';
import { JobResultQuery } from '../../app/models/jobResultQuery.model';
import { cleanObject } from '../../app/common/util/util';
import MiniReportComponent from '../miniReport/miniReport.component';
import CheckBoxFilter from '../../app/common/checkboxFilter';
import JobTable from './jobTable.component';
import JobCategoryIcon from '../../app/common/jobCategoryIcon';
import DateRangePickerComponent from '../../app/common/dateRangePicker.component';
import SelectList from '../../app/common/selectList.component';
import DeviceSearch from '../../app/common/deviceSearch.component';
import JobDeviceSearchProvider from '../../app/common/context.component';
import DataMoverIcon from '../../app/common/dataMoverIcon.component';
import { LastJobRunStatus, LastJobRun } from '../../app/models/lastjobRun.model';
import { UpdateType } from '../../app/stores/activityStore';
import AddIcon from '@material-ui/icons/Add';
import CreateJobConfigurationComponent from '../createJob/CreateJobConfiguration.component';
import { Skeleton } from '@material-ui/lab';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const TableSkeletonLoader = () => {
    return <Grid container direction="column" spacing={2}>
        <Grid item>
            <Skeleton variant="rect" width="100%" height={40} style={{borderRadius: 10}} />
        </Grid>
        <Grid item>
            <Skeleton variant="rect" width="100%" height={40} style={{borderRadius: 10}} />
        </Grid>
        <Grid item>
            <Skeleton variant="rect" width="100%" height={40} style={{borderRadius: 10}} />
        </Grid>
        <Grid item>
            <Skeleton variant="rect" width="100%" height={40} style={{borderRadius: 10}} />
        </Grid>
        <Grid item>
            <Skeleton variant="rect" width="100%" height={40} style={{borderRadius: 10}} />
        </Grid>
        <Grid item>
            <Skeleton variant="rect" width="100%" height={40} style={{borderRadius: 10}} />
        </Grid>
        <Grid item>
            <Skeleton variant="rect" width="100%" height={40} style={{borderRadius: 10}} />
        </Grid>
    </Grid>
}

const useStyles = makeStyles(theme => ({
    button: {
        color:'white',
        borderRadius:'26px',
        width: '145px',
        height: '40px',
        marginRight:'10px',
        textTransform:'none'
        },
    filter_container: {
        height: "100vh",
        background: "white",
        position: "sticky",
        top: 0,
        padding: 15,
        zIndex: theme.zIndex.appBar+1,
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    drawer: {
        flexShrink: 0,
        width: "50%",
        whiteSpace: 'nowrap',
        height: "100vh",
        position: "sticky",
        top: 0,
        overflowX: "hidden"
    },
    drawerClose: {
       marginRight: "-50%",
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    drawerOpne: {
       marginRight: '0%',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    drawerPaper: {
        position: "sticky",
        top: 0,
        overflowX: 'hidden'
    },


    openDrawerIcon: {
        position: "absolute",
        top: "50%",
        right: 0

    },
    content: {
        flexGrow: 1,
        position: "relative",
        // overflowX: "hidden"
    },

    categorySelected: {
        borderRadius: 30,
        background: "#ecf6fc"
    },

    dateInputFocused: {
        border: "1px solid #2892d7"
    },
    arrowRightButton: {
        background: "#eaeef4",
        borderRadius: '50%',
        width: 30,
        height: 30,
        padding: "4px 0 1px 0",
        border: "1px solid #dcd7d7",
        position: "fixed",
        top: "2%",
    },

    arrowLeftButton: {
        padding: "3px 2px 3px 4px",
        border: "1px solid #2892d7",
        top: "2%",
        right: "-15px",
        position: "absolute",
        background: "#2892d7",
        borderRight: "0px",
        zIndex: 1101,
        color: "white",
        cursor: "pointer",
        borderRadius: '50%',
        width: 30,
        height: 30,
    },

    job_category: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer"
    },
    badge: {
        backgroundColor: "#d6d6d9",
        color: "#4d5f75",
        minWidth: 30
    }
}));


const SearchTextField = withStyles({
    root: {
        marginRight: 10,
        padding: 4,
        minWidth: 280,
        '& .MuiInput-underline:hover': {
            border: 0,
        },
        '& .MuiInput-underline:hover:before': {
            border: 0,
        },
        '& .MuiInput-underline:after': {
            border: 0
        },
        '& .MuiInput-underline:before': {
            border: 0
        },
        background: "white",
        borderRadius: 4,
        border: '1px solid #eaeef4',
        overflow: "hidden"
    },
})(TextField);


function getQueryParams(searchParams: string): JobResultQuery {
    if (!searchParams) {
        return {
            category: "all",
            startDate: null,
            endDate: null,
            dataMover: [],
            deviceJobIds: []
        };
    }
    const parsed = queryString.parse(searchParams.slice(1));


    return {
        category: parsed.category as string || "all",
        servers: parsed.servers,
        startDate: parsed.startDate as string,
        endDate: parsed.endDate as string,
        dataMover: parsed.dataMover || [],
        deviceJobIds: parsed.deviceJobIds,
        jobId: parsed.jobId ? parseInt(parsed.jobId as string) : null
    };
}

const jobCategoryOptions: Array<string> = ["all", "atRisk", "recoverable", "resilient", "running"]

const JOB_STATUS_ICON = {
    "all": <JobCategoryIcon status={-1} variant="small" full={true} style={{ height: "12px", marginRight: "5px" }} />,
    "atRisk": <JobCategoryIcon status={LastJobRunStatus.atRisk} variant="small" full={true} style={{ height: "12px", marginRight: "5px" }} />,
    "recoverable": <JobCategoryIcon status={LastJobRunStatus.recoverable} variant="small" full={true} style={{ height: "12px", marginRight: "5px" }} />,
    "resilient": <JobCategoryIcon status={LastJobRunStatus.resillient} variant="small" full={true} style={{ height: "12px", marginRight: "5px" }} />,
    "running": <img src="assets/images/jobIcon/running_small.png"  color="primary" style={{ marginRight: 5}} />


}

const JobResultComponent: React.FC<RouteComponentProps> = (props) => {
    const classes = useStyles();
    const jobResultStore = useContext(RootStoreContext).jobResultStore;
    const jobRunStore = useContext(RootStoreContext).jobRunStore;
    const dashboardStore = useContext(RootStoreContext).dashboardStore;
    const userStore = useContext(RootStoreContext).userStore;
    const activityStore = useContext(RootStoreContext).activityStore;
    const [minMaxdate, setMinMaxDate] = useState({ min: null, max: null });
    const jobCategory = jobResultStore.jobCountByCategories;
    const [drawerOpen, setOpenDrawer] = useState(false);
    const [jobNameSearch, setJobNameSearch] = useState("");
    const [toggleFilter, setToggleFilter] = useState("dataMover");
    const [datamoverFilter,setDataMoverFilter]= useState<Array<string>>([]);
    const [selectedJobDeviceIds, setselectedJobDeviceIds] = useState<Array<number>>([]);
    const [openFilterDrawer, toggleFilterDrawer] = useState(false);
    const [dialogOpen,setDialogOpen]=useState(false);
    const query = jobResultStore.query;
    const [t] = useTranslation("jobResult");
    const location = useLocation();
    const { trackEvent} = useMatomo(); 
    const {isJobsLoading, jobResults} = jobResultStore;
    const setJobCategory = (category: string) => {
        trackEvent({
            category: 'jobs',
            action : 'filterByCategory',
            name: category
        })
        const newQuery = category !== "all" ? { ...query, category: category } : { category };
        props.history.push({ search: queryString.stringify(cleanObject(newQuery)) });
        jobResultStore.setloadedJobResults(false);
    }

// reset filter if there are no running jobs and runnning filter is selected    
useEffect(() => {
    if(jobRunStore.jobRuns.length === 0) {
        if(jobResultStore.query && jobResultStore.query.category === 'running') {
            setJobCategory('all');
        }
    }
},[jobRunStore.jobRuns])

useEffect(() => {
    const updateHandler = () => {
        setOpenDrawer(false);
    }
    activityStore.on(UpdateType.ForceRefresh, updateHandler);
    return ()=>{
        activityStore.off(UpdateType.ForceRefresh, updateHandler);
    }
},[])

// reset mini report drawer state in case no job is selected
useEffect(() => {
    if(!jobResultStore.slectedJobId) {
        setOpenDrawer(false);
    }
}, [jobResultStore.slectedJobId])

useEffect(() => {
    
    if(!isJobsLoading && jobResults.length >0 && !jobResults.find(j => j.jobId === jobResultStore.slectedJobId)) {
        setOpenDrawer(false);
        jobResultStore.selectJobSummaryResult(null);
    }
}, [jobResults])


    useEffect(() => {
        setOpenDrawer(false);
        const query = getQueryParams(location.search);
        if (jobResultStore._jobResults.length == 0) {
            jobResultStore.fetch();
        }
        if (query.jobId) {
            jobResultStore.selectJobSummaryResult(query.jobId);
            setOpenDrawer(true)
        }
        jobResultStore.setQueryParams({ ...query, jobId: null });
        if (query.deviceJobIds && query.deviceJobIds instanceof Array) {
            setselectedJobDeviceIds(query.deviceJobIds.map(d => parseInt(d)));

        } else {
            setselectedJobDeviceIds(query.deviceJobIds ? [parseInt(query.deviceJobIds as string)] : [])
        }
    }, [location]);

    useEffect(() => {
        if (jobResultStore._jobResults && jobResultStore._jobResults.length) {
            setMinMaxDate({ min: jobResultStore._jobResults.slice(-1)[0].runDate, max: jobResultStore._jobResults[0].runDate });
        }
    }, [jobResultStore._jobResults])


        // for job action updates
  useEffect(() => {
    const updateHandler = (update: {jobId: number}) => {
       activityStore.sendMessage(UpdateType.ForceRefresh);
    }
      activityStore.on(UpdateType.JobRemoved, updateHandler);
      activityStore.on(UpdateType.JobRenamed, updateHandler);
      activityStore.on(UpdateType.JobRestored, updateHandler);
      activityStore.on(UpdateType.JobAdded, updateHandler);
      return () => {
        activityStore.off(UpdateType.JobRemoved, updateHandler);
        activityStore.off(UpdateType.JobRenamed, updateHandler);
        activityStore.off(UpdateType.JobRestored, updateHandler);
        activityStore.off(UpdateType.JobAdded, updateHandler);
      }
  }, []);


    const onToggleFilterChange = (event, value) => {
        setToggleFilter(event.currentTarget.value);
    };
    const setDateRange = (startDate: Date, endDate: Date) => {
        try {
            const newQuery = { ...query, startDate: startDate && startDate.toISOString(), endDate: endDate && endDate.toISOString() };
            props.history.push({ search: queryString.stringify(cleanObject(newQuery)) });
        } catch {

        }
    }

    const handleDataMoverFilterChange = (filter: Array<{ label: string, checked: boolean }>) => {
        trackEvent({
            category: 'jobs',
            action : 'filterByDataMover',
            name: filter.map(f => f.label).join(',')
        })
        if (filter.every(option => option.checked)) {
            const filterDataMoverLabel= queryString.stringify({ dataMover: filter.map(option => option.label) })
            props.history.push({ search: filterDataMoverLabel });
            setDataMoverFilter([filterDataMoverLabel]);
        } else {
            const filterDataMoverLabel= filter.filter(f => f.checked).map(f => f.label);
            const newQuery = { ...query, dataMover:filterDataMoverLabel}
            props.history.push({ search: queryString.stringify(newQuery) });
            setDataMoverFilter(newQuery.dataMover);
        }
        jobResultStore.setloadedJobResults(false);
    }
    const toggleDrawer = (open: boolean) => {
        if (open) {
            setOpenDrawer(true);
        } else {
            setOpenDrawer(false);
            //jobResultStore.selectJobSummaryResult(null);
        }
    }


    const onDeviceSelection = (selectedDeviceJobIds: Array<number>) => {
        trackEvent({
            category: 'jobs',
            action : 'filterByDevice',
            name: selectedDeviceJobIds.join(',')
        })
        const query = { deviceJobIds: selectedDeviceJobIds }
        props.history.push({ search: queryString.stringify(query) });
        jobResultStore.setloadedJobResults(false);
    }

    const handleJobTableSelection = (selectedJob: LastJobRun) => {
        const hasActiveJobRun = !!selectedJob.jobId && jobRunStore.getJobRun(selectedJob.jobId);
        const hasLastRun = !!(selectedJob.jobId && selectedJob.id);
        if ((hasLastRun) || hasActiveJobRun) {
            if(selectedJob.jobId === jobResultStore.slectedJobId) {
                setOpenDrawer(!drawerOpen);  // if same job toggle drawer
            } else {
                setOpenDrawer(true);  // different job then open drawer
                jobResultStore.selectJobSummaryResult(selectedJob.jobId);
                trackEvent({
                    category: 'jobs',
                    action: 'Select Job',
                    name: selectedJob.jobName
                })
            }
        } else {
            setOpenDrawer(false);  // different job then open drawer
            jobResultStore.selectJobSummaryResult(selectedJob.jobId);
            trackEvent({
                category: 'jobs',
                action: 'Select Job',
                name: selectedJob.jobName,
                value: -1
            })
        }
    }



    const loadTitleWithFilters=()=>{
        if(query.category=="all" && datamoverFilter.length == 1)
            return `Jobs - `+datamoverFilter[0]+` (${jobResultStore.jobResults.length})`;
        if(query.category!="all" && datamoverFilter.length == 1)
            return `Jobs - `+query.category+`- `+datamoverFilter[0]+` (${jobResultStore.jobResults.length})`;
        if(query.category=="all")
            return `Jobs (${jobResultStore.jobResults.length})`;
        if(query.category!="all")
            return `Jobs - `+query.category+` (${jobResultStore.jobResults.length})`;
    }

    const filterPanel=  <div className={classes.filter_container}>
    <span data-test="filterByJobStatusTitle"><Typography classes={{root: "jobFilter"}}>{t("filterJob")}</Typography></span>
    <SelectList<string>
        options={jobCategoryOptions}
        onChange={option => setJobCategory(option)}
        value={query.category}
        //title={t("filterJob")}
        getLabel={option => <span id={`jobs_category_${option}`}>{t(`jobCategory.${option}`)}</span>}
        getIcon={option => JOB_STATUS_ICON[option]}
        getCountBagde={option => <Badge badgeContent={jobCategory[option]} classes={{ badge: classes.badge }}><React.Fragment></React.Fragment></Badge>}
    />
    <Divider />
    <Grid container justify="center" direction="column" style={{ marginTop: "15px", marginBottom: "15px" }}>
        <ToggleButtonGroup classes={{ root: "toggle_button_group" }} value={toggleFilter} onChange={onToggleFilterChange}>
            <ToggleButton classes={{ root: "toggle_button", selected: "toggle_button_selected" }} value="dataMover">{t("toggleButton.dataMover")}</ToggleButton>
            <ToggleButton classes={{ root: "toggle_button", selected: "toggle_button_selected" }} value="server">{t("toggleButton.servers")}</ToggleButton>
        </ToggleButtonGroup>

        {toggleFilter === "dataMover" && <CheckBoxFilter options={jobResultStore.dataMovers} onChange={handleDataMoverFilterChange}
        showCount={true}
        renderLabel={(option) => <div style={{display: 'flex', alignItems: 'center'}}>
            <DataMoverIcon name={option.label} variant="small" style={{marginRight: 5}}></DataMoverIcon>
            <span id={`jobs_datamover_${option.label}`} style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '80%'}}>{option.label}</span> 
        </div>}
         showAllOption={true} 
         allOptionIcon={<TimerIcon style={{position: 'relative', left: 10}}/>}
         allOptionLabel={<span>{t("allDataMover")}</span>}
         showSearchInput={false}></CheckBoxFilter>}
        <JobDeviceSearchProvider>
        {toggleFilter === "server" &&
            <DeviceSearch selectedDeviceJobIds={selectedJobDeviceIds} onChange={onDeviceSelection} searchDeviceText={t("searchDeviceText")} />
        }
        </JobDeviceSearchProvider>
    </Grid>
</div>
    const showFilter = useMediaQuery('(min-width: 1700px)')
    return <Grid container direction="row" data-test="component-jobResult" style={{height: "100vh", overflow: 'hidden'}}>
      {showFilter && 
      <Grid item xl={2}>
            {filterPanel}    
       </Grid>}
      
      <Hidden xlUp={true}>
           <Drawer variant="temporary" anchor={'left'} open={openFilterDrawer} ModalProps={{  BackdropProps: {invisible: true}  } } onClose={() => toggleFilterDrawer(false)}>
                {filterPanel}
            </Drawer>
      </Hidden>
      <Hidden xlUp>
      <Fab color="primary" style={{position: 'fixed', left: '80px', bottom: '10px', zIndex: 1}} onClick={() => toggleFilterDrawer(true)}>
            <FilterIcon/>
        </Fab>  
      </Hidden>
        <Grid item xs={12} xl={10} style={{ background: "#E9EBEE", height: '100vh', overflowY: 'hidden' }} >
            <AppBar showDevices={true}>
               <Grid container>
                   <Grid xs={9}>
                   <AppBarTitle>
                    <Typography variant='h6'>{loadTitleWithFilters()}</Typography>
                </AppBarTitle>
               
                   </Grid>
                   <Grid xs={3}>
                   </Grid>
               </Grid>
            </AppBar>
            <div style={{ padding: "15px", position: 'relative' }}>
           {/* {isJobsLoading &&  <LinearProgress style={{position: 'absolute', width: '100%', top:0, left:0}} />} */}
                <Grid container direction="row" style={{ background: "transparent", marginBottom: "15px", display: "flex", alignItems: "center" }}>
                    { userStore.user.username!="readonly"?
                        dashboardStore.createEnabled ? 
                        <Button className={classes.button} variant="contained" color="primary" onClick={()=> setDialogOpen(true)}>
                            <AddIcon fontSize="small" style={{color:'white'}}></AddIcon>
                            {t('createjob')}
                        </Button>:null:null
                    } 
                    <span data-test="search_job_input">
                        <SearchTextField
                            placeholder={t("search")} variant="standard"
                            onChange={event => setJobNameSearch(event.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </span>
                    <DateRangePickerComponent
                        startDate={query.startDate}
                        endDate={query.endDate}
                        onChange={setDateRange}
                        minDate={minMaxdate.min}
                        maxDate={minMaxdate.max}
                    />
                    </Grid>

                <div style={{ display: "flex", height: "calc(100vh - 120px)", overflowX: 'hidden' }}>
                    <div style={{height: "100%",}} className={clsx(classes.content, 'hide-scrollbar')}>
                       <div style={{overflowY: 'hidden', height: "100%"}} className='hide-scrollbar'>
                       <div style={{height: '100%'}}>
                            {isJobsLoading && <Paper style={{height: '100%', width: '100%', padding: "80px 25px"}}> 
                                <TableSkeletonLoader/>
                                </Paper>}
                            {!isJobsLoading &&    <JobTable jobResults={jobResultStore.jobResults.filter(j => !jobNameSearch || j.jobName.toLowerCase().indexOf(jobNameSearch.toLocaleLowerCase()) >= 0)}
                            expanded={!drawerOpen} onTableRowSelected={handleJobTableSelection} />}
                       </div>
                       </div>
                      
                            {/* jobResultStore.slectedJobId && */}
                        { drawerOpen && <span className={classes.arrowLeftButton} onClick={() => toggleDrawer(!drawerOpen)}>{drawerOpen ? <ArrowRightIcon /> : <ArrowLeftIcon />} </span>}
                    </div>
                    <Drawer
                        style={{height: "calc(100vh - 120px)"}}
                    classes={{
                        paper: clsx(classes.drawerPaper, 'hide-scrollbar'),
                    }} variant="permanent" className={clsx(classes.drawer, {
                        [classes.drawerOpne]: drawerOpen,
                        [classes.drawerClose]: !drawerOpen
                    })} open={drawerOpen} anchor="right">
                        <div style={{height: "100%"}} className='hide-scrollbar'>
                            <MiniReportComponent />
                        </div>
                    </Drawer>
                </div>
            </div>
        </Grid>
        <CreateJobConfigurationComponent open={dialogOpen} onClose={()=>setDialogOpen(false)}/>
    </Grid>
}





export default observer(JobResultComponent);