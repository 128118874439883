import React, { Dispatch, SetStateAction, useContext, useEffect, useState, Fragment, useRef } from "react";
import { DataMover, VeeamSolution } from "../../../../app/models/BaseSettings.enum";
import { DeviceState, DeviceStatus, DeviceType } from "../../../../app/models/deviceType.enum";
import { JobDevice } from "../../../../app/models/jobDevice/jobDevice.model";
import { RootStoreContext } from "../../../../app/stores/root.store";
import jobAgent from '../../../../app/api/jobAgent';
import { toast } from "react-toastify";
import { CustomDeviceLocation, CustomDeviceState, DevicePlatform, NetworkOption } from "../../../../app/models/jobProperties.model";
import { CONSTS, Plan } from "../../../../app/models/createjob/createJob.model";
import CreateJobStore from "../../../../app/stores/createJob.store";
import { CancelablePromise } from "../../../../app/common/util/cancellablePromise";
import '../../../../app/common/util/cancellablePromise';
import JobSettingsStore from "../../../../app/stores/jobSettings.store";
import { runInAction } from "mobx";
import { useTranslation } from "react-i18next";

type CachePolicy = "cacheFirst" | "resetCache";
export type LoadingState = "none" | "loading" | "failed" | "done";
interface FetchDeviceRequest {
    infrastructureId: number,
    dataMoverId: number,
    dataMoverType: DataMover,
    workflowUser: string;
    workflowPassword: string;
    workflowUserCredsId: number;
    veeamApiPort?: string | number;
    planNames?: Array<string>;
    jobType?: VeeamSolution

}

const Azure_Devices: Array<JobDevice> = [
    {
        deviceName: 'q2e-sql10(+)q2e-fog',
        sourceName: 'n/a',
        planName: 'Q2E',
        planState: null,
        deviceType: DeviceType.CloudService,
        deviceIP: '',
        targetIP: '',
        deviceGroup: '1',
        os: DevicePlatform.Windows,
        id: 992,
        guid: 'q2e-fog-0240ac20-a8c9-43fb-a486-b7edafa5a2a6',
        NumberClickDB: 0,
        NumberClickWeb: 0,
        NumberClick3rd: 0,
        NumberClickAuthen: 0,
        NumberClickScript: 0,
        deviceCredentials: null,
        inheritDefaultTests: false,
        performSecondRestart: false,
        showDeviceAsDC: false,
        newtworkLocation: 'inBubble',
        customCommandData: null,
        customCommandName: '',
        scriptData: null,
        scriptName: '',
        customDeviceState: CustomDeviceState.Active
    },
    {
        deviceName: 'q2e-sql13(+)q2e-fog3',
        sourceName: 'n/a',
        planName: 'Q2E',
        planState: null,
        deviceType: DeviceType.CloudService,
        deviceIP: '',
        targetIP: '',
        deviceGroup: '1',
        os: DevicePlatform.Windows,
        id: 993,
        guid: 'q2e-fog3-0240ac20-a8c9-43fb-a486-b7edafa5a2a6',
        NumberClickDB: 0,
        NumberClickWeb: 0,
        NumberClick3rd: 0,
        NumberClickAuthen: 0,
        NumberClickScript: 0,
        deviceCredentials: null,
        inheritDefaultTests: false,
        performSecondRestart: false,
        showDeviceAsDC: false,
        newtworkLocation: 'inBubble',
        customCommandData: null,
        customCommandName: '',
        scriptData: null,
        scriptName: '',
        customDeviceState: CustomDeviceState.Active
    }
];
const { JobActions, CreateJobActions } = jobAgent;
let CUSTOM_DEVICE: Array<JobDevice> = [];


function fetchDevices(payload: FetchDeviceRequest, cachePolicy: CachePolicy = "cacheFirst"): CancelablePromise<Array<JobDevice>> {

    const fetchDevicepromise = CreateJobActions.ListPlans({ ...payload }, cachePolicy) as CancelablePromise<Array<Plan>>;
    return Promise.resolve(fetchDevicepromise).then(res => {
        const devices: Array<JobDevice> = [];
        res.map(p =>
            p.devices.map(d => {
                devices.push({
                    deviceName: d.deviceName,
                    sourceName: d.sourceName,
                    planName: p.name,
                    planState: p.srmrpState,
                    deviceType: d.deviceType,
                    deviceIP: d.deviceIP,
                    targetIP: 'N/A',
                    deviceGroup: '7',
                    os: d.devicePlatform,
                    id: 0,
                    guid: d.guid,
                    NumberClickDB: 0,
                    NumberClickWeb: 0,
                    NumberClick3rd: 0,
                    NumberClickAuthen: 0,
                    NumberClickScript: 0,
                    deviceCredentials: null,
                    performSecondRestart: false,
                    showDeviceAsDC: d.showDeviceAsDC || false,
                    inheritDefaultTests: false,
                    newtworkLocation: 'inBubble',
                    deviceStatus: d.deviceStatus,
                    deviceState: d.deviceState,
                    customDeviceState: CustomDeviceState.Active,
                    fetchedFromInfra: d.fetchFromInfra
                });
            }));
        return devices;
    }).catch(err => {
        if (payload.dataMoverType == DataMover.PaaS) {
            return Azure_Devices;
        } else {
            return [];
        }
    }).asCancelable(() => fetchDevicepromise.cancel());


}

function fetchCustomDevice(): CancelablePromise<Array<JobDevice>> {
    var p = CreateJobActions.ListCustomDevices();
    return Promise.resolve(p).then(res => {
        return res.map(d => ({
            id: d.id,
            deviceName: d.deviceName,
            sourceName: d.deviceFQDN,
            deviceIP: d.deviceFQDN,
            targetIP: 'N/A',
            deviceGroup: '7',
            os: d.devicePlatform,
            deviceType: d.deviceType,
            planName: 'Custom Devices',
            customDeviceState: d.state,
            newtworkLocation: d.customDeviceLocation === CustomDeviceLocation.InBubble ? "inBubble" : "outBubble",
            inheritDefaultTests: d.selectAllDefultTests,
            NumberClickDB: 0,
            NumberClickWeb: 0,
            NumberClick3rd: 0,
            NumberClickAuthen: 0,
            NumberClickScript: 0,
            deviceCredentials: null,
            performSecondRestart: false,
            showDeviceAsDC: false,
            guid: d.guid,
            deviceStatus: DeviceStatus.Ready,
            deviceState: DeviceState.PoweredOn,
            fetchedFromInfra: false,
            updateDate: d.updateDate
        }))
    }).catch(err => {
        return [];
    }).asCancelable(() => p.cancel());
}

function useDevices(): [Array<JobDevice>, Array<JobDevice>, Array<JobDevice>, string[], Dispatch<SetStateAction<Array<JobDevice>>>, (device: Array<JobDevice>) => void, (device: JobDevice) => void, (device: JobDevice) => void, (cachePolicy?: CachePolicy) => Promise<boolean>,()=> void, LoadingState] {
    const [devices, setdevices] = useState<Array<JobDevice>>([]);
    const [devicesAll, setdevicesAll] = useState<Array<JobDevice>>([]);
    const [CustomDevicesAll, setCustomDevicesAll] = useState<Array<JobDevice>>([]);
    const [customDevices, setCustomdevices] = useState<Array<any>>([]);
    const [plans, setPlans] = useState<string[]>([]);
    const { createJobStore, jobSettingsStore, displayStore } = useContext(RootStoreContext);
    const [loadingState, setLoadingState] = useState<LoadingState>("done");
    const [t] = useTranslation('errors');
    const fetchDevicePromiseRef = useRef<CancelablePromise<any> | null>();
    let fetchAllDevicePromise;
    let customDevicePromise;
    // let fetchDevicePromise;
    
    const addCustomDevice = (device: Array<JobDevice>) => {
        CUSTOM_DEVICE = [...device, ...CUSTOM_DEVICE];
        setdevices([...device, ...devices])
        setCustomdevices([...device, ...customDevices]);

    }

    const removeCustomDevice = (device: JobDevice) => {
        jobAgent.CreateJobActions.DeleteCustomDevice(device.id, 0).then(res => {
            if (res == true) {
                CUSTOM_DEVICE = CUSTOM_DEVICE.filter(d => d.id !== device.id);
                setCustomdevices([...customDevices.filter(d => d.id !== device.id)]);
                setdevices([...devices.filter(d => d.id !== device.id)]);
                toast.success("custom devices removed successfully");
            }
            else {
                toast.error(`Failed to remove custom device ${device.deviceName}`);
            }
        });

    }

    const updateCustomDevice = (customDeviceUpdate: JobDevice) => {
        let customNewDevice: Array<JobDevice> = [];
        customNewDevice = CustomDevicesAll.map(d => {
            if (customDeviceUpdate != null) {
                if (d.id == customDeviceUpdate.id)
                    return customDeviceUpdate;
                else
                    return d;
            }
        });
        if (customDeviceUpdate != null) {
            // let updated =[customDeviceUpdate];
            let sorted = [customDeviceUpdate, ...CustomDevicesAll.filter(c => c.id !== customDeviceUpdate.id)];
            setCustomdevices(sorted);
            setdevices([customDeviceUpdate, ...devices.filter(c => c.id !== customDeviceUpdate.id)])
            setCustomDevicesAll(sorted);
        }
    }

    const loadPlansOnly = (devices: Array<JobDevice>) => {
        let plans = devices.map(item => item.planName).filter((value, index, self) => self.indexOf(value) === index);
        let plansOrig = plans.filter(p => !p.includes("Custom Devices"));
        setPlans(plansOrig);
        let plansObjects = [];
        createJobStore.plans.map((i, idx) => {
            plansObjects.push({
                name: i.name,
                planBootOrder: idx,
                devices: i.devices,
                id: i.id
            });
        });
        createJobStore.updatePlans(plansObjects);
    }

    const loadDevicesWithoutCustom = () => {
        if(createJobStore.baseSettings.planNameFilter.length!=0 && !createJobStore.baseSettings.planNameFilter.includes("Custom Devices"))
            return true;
        //if (createJobStore.networkSetting.NetworkType == NetworkOption.None)
        //    return true;
        if (createJobStore.baseSettings.dataMover == DataMover.VeeamSQL)
            return true;
        return false;
    }

    const loadCustomWithoutDevices =()=>
    {
        if(createJobStore.baseSettings.planNameFilter.length == 1 && createJobStore.baseSettings.planNameFilter.includes("Custom Devices"))
            return true;
        if(IsCustom(createJobStore.baseSettings.dataMover))
            return true;
        return false;
    }

    const IsCustom = (datamover:DataMover) => 
    {
        if(datamover == DataMover.CustomVmware ||datamover == DataMover.CustomAWS ||datamover == DataMover.CustomAzure ||datamover == DataMover.CustomGCP)
            return true;
        else
            return false;
    }

    const showFetchDeviceError =(errResponse) => {
        const err = errResponse.data?.errors[0]||{errorCode: 'failed to fetch devices', errorDetails: ''};
        
        err.errorCode && toast.error(t(err.errorCode));
        err.errorDetails && toast.error(err.errorDetails,{autoClose: 60000});
        if(!err.errorCode && !err.errorDetails) {
            toast.error("failed to fetch devices");
        }
        console.log("Failed to list plans", errResponse);
    }

    const fetchAllDevices: () => CancelablePromise<boolean> = (cachePolicy: "cacheFirst" | "resetCache" = "cacheFirst") => {

        if (loadingState === 'loading') {
            return Promise.resolve(false).asCancelable(() => { });
        }

        setLoadingState('loading')
        displayStore.updateStopLoading(false);
        let requestCancelled = false;
        const fetchDevicePayload: FetchDeviceRequest = {
            infrastructureId: jobSettingsStore.selectedInfra.id,
            dataMoverId: createJobStore.baseSettings.dataMoverId,
            dataMoverType: createJobStore.baseSettings.dataMover,
            workflowPassword: createJobStore.baseSettings.workflowTest.PrimaryPassword,
            workflowUser: createJobStore.baseSettings.workflowTest.PrimaryUser,
            workflowUserCredsId: createJobStore.baseSettings.workflowTest.PrimaryUserCredsId ? createJobStore.baseSettings.workflowTest.PrimaryUserCredsId : 0,
            veeamApiPort: createJobStore.baseSettings.veeamApiPort,
            planNames: createJobStore.baseSettings.planNameFilter.filter(item=>item != "Custom Devices"),
            jobType: createJobStore.baseSettings.veeamSolution
        };

        customDevicePromise = fetchCustomDevice();
        let fetchDevicePromise;
        if(IsCustom(createJobStore.baseSettings.dataMover))
            fetchDevicePromise =null;
        else
            fetchDevicePromise = fetchDevices(fetchDevicePayload, cachePolicy);
        fetchDevicePromiseRef.current = fetchDevicePromise;
        fetchAllDevicePromise = Promise.allSettled([customDevicePromise, fetchDevicePromise]).then(([_customDevices, _devices]) => {
            if (requestCancelled) {
                setLoadingState('none')
                return;
            }

            if (loadCustomWithoutDevices()) {
                if (_customDevices.status == 'fulfilled') {
                    setCustomDevicesAll([..._customDevices.value]);
                    setdevices([..._customDevices.value].filter(device => !createJobStore.selectedDevices.find(selectedDevice => selectedDevice.deviceName === device.deviceName)));
                    setLoadingState('done');
                } else {
                    showFetchDeviceError(_customDevices.reason);
                    setLoadingState('failed');
                }
                return;
            }

          
            if (loadDevicesWithoutCustom()) {
                if (_devices.status == 'fulfilled') {
                    setdevicesAll([..._devices.value]);
                    setCustomDevicesAll([])
                    setdevices([..._devices.value].filter(device => !createJobStore.selectedDevices.find(selectedDevice => selectedDevice.deviceName === device.deviceName)));
                    setLoadingState('done');
                } else {
                    //showFetchDeviceError(_devices.reason);
                    setLoadingState('failed');
                }
                return;
            }




            if (_customDevices.status == 'rejected' && _devices.status == 'rejected') {
                toast.error(" failed to custom devices");
                //showFetchDeviceError(_devices.reason);
                setdevices([]);
                setdevicesAll([]);
                setCustomDevicesAll([]);
                setLoadingState('failed');
                return;
            }
            if (_customDevices.status == 'fulfilled' && _devices.status == 'fulfilled') {
                setdevicesAll([..._customDevices.value, ..._devices.value]);
                setCustomDevicesAll([..._customDevices.value]);
                setdevices([..._customDevices.value, ..._devices.value].filter(device => !createJobStore.selectedDevices.find(selectedDevice => selectedDevice.deviceName === device.deviceName)));
                setLoadingState('done');
                return;
            }

            if(_devices.status === 'rejected') {
                //showFetchDeviceError(_devices.reason);
            }

            if (_customDevices.status == 'fulfilled') {
                
               
                setdevicesAll([]);
                setCustomDevicesAll([])
                setdevices([]);
                setLoadingState('failed');
                return;
            }
            if (_devices.status == 'fulfilled') {
                toast.error(" failed to fetch custom devices");
                setdevicesAll([..._devices.value]);
                setCustomDevicesAll([])
                setdevices([..._devices.value].filter(device => !createJobStore.selectedDevices.find(selectedDevice => selectedDevice.deviceName === device.deviceName)));
                setLoadingState('done');
                return;
            }


            setLoadingState('failed');


            return true;
        }).catch(err => {
            setLoadingState('failed')
            return false;
        }).finally(() => {
            displayStore.updateStopLoading(true);
        })
        return fetchAllDevicePromise.asCancelable(() => {
            requestCancelled = true;
            if(loadCustomWithoutDevices())
            {
                customDevicePromise.cancel();
            }
            else
            {
                fetchDevicePromise.cancel();
                customDevicePromise.cancel();
            }
        })
    }

    const stopFetch =()=>{
      
            fetchDevicePromiseRef.current?.cancel();
        
        if(customDevicePromise!=undefined) 
        {
            customDevicePromise.cancel();
        }
        if(fetchAllDevicePromise!=undefined)
        {
            fetchAllDevicePromise.cancel();
        } 
        setdevices([]);
        setCustomDevicesAll([]);
        setLoadingState('done');
    }

    useEffect(() => {
        if(window.location.href.includes('editJob'))
            return;
        const p = fetchAllDevices();
        return () => {
            p.cancel();
        }
    }, [createJobStore.baseSettings.dataMover])

    useEffect(() => {
        if (devicesAll.length > 0)
            loadPlansOnly(devicesAll);
    }, [devicesAll])

    return [devices, devicesAll, CustomDevicesAll, plans, setdevices, addCustomDevice, removeCustomDevice, updateCustomDevice, fetchAllDevices,stopFetch, loadingState];
}

export default useDevices;