
import Chart from 'chart.js';

const DashboardChartPlugin: Chart.PluginServiceRegistrationOptions = {
    beforeDraw: function (chartInstance: Chart, easing, options) {
        var x = chartInstance.ctx.canvas.clientWidth / 2,
            y = chartInstance.ctx.canvas.clientHeight / 2,
            ctx = chartInstance.ctx;
        ctx.beginPath();
        const outerRadius = chartInstance['outerRadius']-10;
        const radiusLength = chartInstance['radiusLength'];
        ctx.arc(x, y, outerRadius - (radiusLength / 2), 0, 2 * Math.PI);
        ctx.lineWidth = radiusLength;
        ctx.strokeStyle = chartInstance.options['radiusBackground.color'] || '#e6e7ec'; //bebfc5
        ctx.stroke();
    },
    afterDraw: function (chartInstance, ease) {
        var ctx = chartInstance.ctx;
        const arc = chartInstance.getDatasetMeta(0).data[0]
        var vm = arc._view;
        
        const radiusLength = chartInstance['radiusLength'];
        const outerRadius = chartInstance['outerRadius']-10;
        
        var vm = arc._view;
        var angle = vm.endAngle;
        const start = vm.startAngle;

        // draw arc

        const xc = vm.x;
        const yc = vm.y;
   
        ctx.beginPath();
        ctx.arc(xc, yc,  outerRadius - (radiusLength / 2), start, angle);
        ctx.lineWidth = radiusLength;
        ctx.strokeStyle = '#8bc53f';
        ctx.stroke();
        ctx.closePath();


        ctx.beginPath();
        ctx.arc(xc, yc, outerRadius+5, start, angle);
        ctx.lineWidth = 10;
        ctx.strokeStyle = '#c2ea8d';
        ctx.stroke();
        ctx.closePath();

    },
}

export default DashboardChartPlugin;