


import React, { useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/root.store';
import { Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {LicenseType } from '../../../app/models/jobProperties.model';
import { useFormik } from 'formik';
import * as yup from 'yup';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Create from '@material-ui/icons/Create';
import moment from 'moment';
import { License } from '../../../app/models/createjob/createJob.model';
import { toast } from 'react-toastify';

const dateFormat = "DD.MM.YY";

const LicenseComponent: React.FC<{licenseList: Array<License>}> = ({licenseList}) => {
    const rootStore = useContext(RootStoreContext);
    const {jobSettingsStore,displayStore, createJobStore, userStore} =rootStore;
    const {isFirstTrial} = jobSettingsStore;
    const [t] = useTranslation("createJobBase");
    
    const useStyles = makeStyles(() => ({
      editIcon: {
        cursor: 'pointer'
    },
      button: {
        height: '100%',
        borderRadius: '4px',
        border: 'solid 1px #c7cfdb',
        backgroundColor: '#fafafc'
      },
      advanced: {
        width: '14px',
        height: '14px',
        objectFit: 'contain',
      },  
      formControl: {
          width: '100%',
          '& label': {
            background: "white",
            width: "auto",
            padding: "0 4px 0 4px",
            fontSize: '17px'
          }
        }
      }));
    const classes = useStyles();

    const validation = yup.object({
        licenseKey: yup.string(),
        licenseId:yup.number()
      })
    
      const form = useFormik({
        initialValues: {
            licenseId:0,
            licenseKey: '',
        },
        validationSchema:validation,
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: () => { },
      })


    useEffect(() => {
        const initialValues=
        {
          licenseKey:jobSettingsStore.selectedLicense == null ? 'Add New License' : jobSettingsStore.selectedLicense.name,
          licenseId:jobSettingsStore.selectedLicense == null ? 0 : jobSettingsStore.selectedLicense.id
        };
        form.setValues(initialValues);
        if (jobSettingsStore.selectedLicense !=undefined && jobSettingsStore.selectedLicense.name != null) 
          handleLicenseSelected(jobSettingsStore.selectedLicense.name);
        createJobStore.updateBaseSettings({licenseId: jobSettingsStore.selectedLicense?.id});
      }, [jobSettingsStore.selectedLicense]);


    const buttonRedirect= (subComponent:any)=> {
        switch(subComponent)
        {
          case "BasicAdvanced":
            {
              if(displayStore.BaseDrawerSettings.BasicDrawerOpen)
              {
                displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
                displayStore.updateBaseDrawerSettings({BasicDrawerOpen: false});
              }  
            else
            {
                displayStore.updateScreenSettings({displayBaseSettings: subComponent});
                displayStore.updateBaseDrawerSettings({BasicDrawerOpen: true});
                displayStore.closeAllOtherBaseDrawersExcept("BasicDrawer");
            }
              break;
            }
          case "LicenseAdvanced":
            {
              if(displayStore.BaseDrawerSettings.LicenseDrawerOpen)
              {
                if(displayStore.BaseDrawerSettings.addLicense)
                {
                  displayStore.updateBaseDrawerSettings({addLicense:false});
                  displayStore.updateScreenSettings({displayBaseSettings: subComponent});
                  displayStore.updateBaseDrawerSettings({LicenseDrawerOpen: true});
                  displayStore.closeAllOtherBaseDrawersExcept("LicenseDrawer");
                }
                else
                {
                  displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
                  displayStore.updateBaseDrawerSettings({LicenseDrawerOpen: false});
                }
              }
              else
              { 
                displayStore.updateBaseDrawerSettings({addLicense:false});
                displayStore.updateScreenSettings({displayBaseSettings: subComponent});
                displayStore.updateBaseDrawerSettings({LicenseDrawerOpen: true});
                displayStore.closeAllOtherBaseDrawersExcept("LicenseDrawer");
              }  
            break;
          }
        }
    };

    const handleAddLicense =()=>{
        displayStore.updateScreenSettings({displayBaseSettings: "LicenseAdvanced"});
        displayStore.updateBaseDrawerSettings({addLicense: true});
        displayStore.updateBaseDrawerSettings({LicenseDrawerOpen: true});
        displayStore.closeAllOtherBaseDrawersExcept("LicenseDrawer");
    }

    const CheckSectionAndDrawerStatus= () =>{
      if(displayStore.screenSettings.displayBaseSettings=='DataMoverAdvanced' || displayStore.screenSettings.displayBaseSettings=='WorkflowAdvanced' || 
      displayStore.screenSettings.displayBaseSettings=='InfrastructureAdvanced'|| displayStore.screenSettings.displayBaseSettings=='InfrastructureCreateAdvanced' || displayStore.screenSettings.displayBaseSettings=='DataMoverCreateAdvanced')
            {
              displayStore.updateScreenSettings({displayBaseSettings: "EmptyVlan"});
              displayStore.updateBaseDrawerSettings({InfraDrawerOpen: false});
              displayStore.updateBaseDrawerSettings({PrimaryUserDrawerOpen: false});
              displayStore.updateBaseDrawerSettings({SecondaryUserDrawerOpen: false});
              displayStore.updateBaseDrawerSettings({BasicDrawerOpen: true});
              displayStore.updateBaseDrawerSettings({DataMoverDrawerOpen: false});
              displayStore.updateBaseDrawerSettings({WorkflowDrawerOpen: false});
              displayStore.updateBaseDrawerSettings({EDRDrawerOpen: false});
            }
    }
    
    const handleLicenseKeyChange= (event)=>{
        if(displayStore.BaseDrawerSettings.LicenseDrawerOpen)
        {
          displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
          displayStore.updateBaseDrawerSettings({LicenseDrawerOpen: false});
        }  
        form.setFieldValue("licenseKey",event.target.value);
        
        let lic = jobSettingsStore.licensesList.find(l => l.name === event.target.value);
        jobSettingsStore.updateSelectedLicense(lic);
        userStore.updateUsedDevicesById(null);
        rootStore.createJobStore.updateBaseSettings({licenseName: lic && lic != null && lic != undefined ? lic.name : "", licenseId: lic && lic != null && lic != undefined ? lic.id : 0});
        
        CheckSectionAndDrawerStatus();
    };

    const handleLicenseSelected= (name)=>{
      let lic = jobSettingsStore.licensesList.find(l => l.name === name);
      if (lic && new Date().getTime()>new Date(lic.experationDate).getTime()) {
        toast.error(t('InvalidLicenseDate'));
      }
    }

    const loadCircularProgress =()=>{
      return <div style={{width:'100%',display:'flex'}}>
      {t('LicenseInProgress')}
      <CircularProgress id="licenseLoading" size={18} style={{color: '#2892D7', marginLeft: '10px',marginTop: '-3px'}}/>
      </div>
  }


return(
    <Grid container item direction='row' spacing={2} style={{paddingTop:'2%'}}>
    <Grid item xs={1} style={{display:'flex',alignItems:'center'}}>
      <IconButton disabled={true} onClick={()=> {handleAddLicense()}}>
        <AddCircleOutlineIcon />
      </IconButton>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={11}>
        <FormControl className={classes.formControl} variant='outlined' style={{backgroundColor:'#ffffff',paddingTop:'1%',width:'97%'}}>
          <InputLabel style={{transform:'translate(14px, 2px) scale(0.75)'}}>{t('licenseKey')}</InputLabel>
          <Select
                data-test='licenseDropDown'
                required={true}
                labelId="licenseKey"
                disabled={true}
                value={form.values.licenseKey}
                renderValue={name => name<0? 'None': name}
                onChange={handleLicenseKeyChange}>
                {licenseList.map((lic) => (
                <MenuItem key={lic.name} value={lic.name}>
                {lic.name + '('+lic.numberOfServer +' devices - '+(lic.licenseType === LicenseType.Full?'Infinity': moment(lic.experationDate).format(dateFormat))+')'}
                </MenuItem>))}
            </Select>
        </FormControl>
        </Grid>

        <Grid item xs={1} style={{paddingTop:'5px'}}>
          <Button  disabled={true} className={classes.button} onClick={()=> {buttonRedirect('LicenseAdvanced')}}>
          <Create className={classes.editIcon} ></Create>
          </Button>
        </Grid>

      </Grid>
    </Grid>
);
}; 

export default observer(LicenseComponent); 