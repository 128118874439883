
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, ListItemText, makeStyles, Switch, Tooltip, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EDRTextField } from "../../../../app/common/EDRTextField";
import { SaveButton } from "../../../../app/common/saveButton";
import { EDRDialogTitle } from "../../../../app/common/EDRDialogTitle";
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EDRAutocomplete } from '../../../../app/common/EDRAutocomplete';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import { TestType } from '../../../../app/models/jobTest.model';
import { RootStoreContext } from '../../../../app/stores/root.store';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { DatabaseType, DevicePlatform, MachineType } from '../../../../app/models/jobProperties.model';
import HelpIcon from '@material-ui/icons/Help';
import { toast } from 'react-toastify';
import { compressFile } from '../../../../app/common/util/util';
import { flow } from 'mobx';
import jobAgent from '../../../../app/api/jobAgent';

const useStyles = makeStyles(theme => ({
    helpIcon:
    {
      color: '#1976d2',
      fontSize: "large"
    },
    autoCompleteWithButton: {
        '& input': {
            flexGrow: 0,
            width: "calc(100% - 59px) !important"
        }
    },
    BrowseButton:{
        backgroundColor: '#fafafc',
        textTransform: 'none',
      },
    SecondHeading:{
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        flexGrow: 1
    },
    divider: {
        backgroundColor: '#eaeef4',
        maxWidth:'100%',
        margin: '0',
        marginTop: '10px',
        marginLeft: '10px',
    },
    label: {
        textTransform: 'capitalize',
    },
    root: {
        minWidth: '30vw'
    },
    checkbox: {
        marginLeft: theme.spacing(0.5)
    },
    heading:{
        fontFamily: 'Roboto',
        fontSize: '13px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontstyle: 'normal',
        lineHeight: 1.15,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#4d5f75',
    },
    title:{
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontstyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        textAlign: 'left'
    },
    text:{
        '& .MuiTypography-body2':{
            color:'#4d5f75',
            fontWeight:500,
        }
    },
    button: {
        height: '100%',
        borderRadius: '4px',
        border: 'solid 1px #c7cfdb',
        backgroundColor: '#fafafc'
    },
    centerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between'
    },
    toggleItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiListItemText-root' : {
            marginLeft: 10
        },
        '& .MuiSvgIcon-root': {
            color: '#8092a9'
        }
    },
    autoWithButton:{
        '& .MuiOutlinedInput-adornedEnd' : {
            paddingRight:'0px'
        }
    }
}))
export interface TestSettingProps {
    open: boolean;
    onSave :()=> void;
    onClose: () => void;
    deviceName:string;
    isDefault:boolean;
}

export interface PowerupSettingProps {
    open: boolean;
    onSave :()=> void;
    onClose: (changed) => void;
    deviceName:string;
    isDefault:boolean;
}

export interface SettingProps {
    open?: boolean;
    onSave :(useThirdDevice)=> void;
    onClose: () => void;
    deviceName:string;
    isDefault:boolean;
}

const validationSchema = yup.object({
    command: yup.string()
    .test('command','custom command must be not empty',command => command!='')
    .required('Custom Command is a required field'),

    isExt: yup.bool(),

    result: yup.string()
    .nullable()
})
const CustomTestSettingDialog: React.FC<TestSettingProps> = (props) => {
    
    const { open,deviceName,isDefault, onClose,onSave } = props;
    const {createJobStore, jobSettingsStore} = useContext(RootStoreContext);
    const [device, setDevice] = useState(createJobStore.selectedDevices.find(device=>device.deviceName == deviceName));
    const [processingTest,setProcessingTest] = useState(false);

    const defaultSettings = {
        command: device!=undefined ? (device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.customCommandToTestLinux :jobSettingsStore.customCommandToTest):(jobSettingsStore.customCommandToTest),
        result: device!=undefined ? (device.os === DevicePlatform.UNIX ? jobSettingsStore.defaultSettings.customCommandExpectedResultLinux :jobSettingsStore.customCommandExpectedResult):(jobSettingsStore.customCommandExpectedResult),
        isExt: jobSettingsStore.customCommandToTestIsExternal
    };

    const form = useFormik({
        initialValues: {
            command: '',
            result: '',
            isExt: false
        },
        onSubmit: () => { },
        validationSchema
    })

    const loadCustomeList =()=>{
        let customCommand=[];
        let list= device !=undefined ? jobSettingsStore.customList.filter(item=>item.os == device.os) : jobSettingsStore.customList;
        list.map(item=>{
            if(device !=undefined && item.command.includes('netdom'))
                customCommand.push(`${item.command} ${device.deviceName}`);
            else
                customCommand.push(item.command)
        });
        return customCommand;
    }

    const loadCustomeResultList =()=>{
        let customResult=[];
        let list= device !=undefined ? jobSettingsStore.customList.filter(item=>item.os == device.os) : jobSettingsStore.customList;
        list.map(item=>{
            customResult.push(item.result);
        });
        return customResult;
    }

    const loadOS =()=>{
        if(device!=undefined)
            return device.os;
         else
            return DevicePlatform.Windows;
    }


    const classes = useStyles();
    const [deviceCustomTest,setdeviceCustomTest] =useState(device && device.tests.find(test=>test.testType == TestType.custom));
    const getPreviousCustomData = loadCustomeList();
    const getPreviousCustomResult = loadCustomeResultList();
    const [PathState, setPathState] = useState("");
    const [DataState, setDataState] = useState<any>();
    const inputFile = useRef(null);
    const [cancel,setCancel]=useState(false);
    const [OS, setOS] = useState(loadOS());
    const [ShowBrowse,setShowBrowse]= useState(true);
    const { t } = useTranslation('createJobBase');
     
    const handleClose = () => {
        if(form.touched && form.dirty)
            setCancel(!cancel);
        else
            onClose();
    }

    const handleSave=()=>{
        try{
        if(device!=undefined){
            let DeviceTest = {
                testCategory:deviceCustomTest.testCategory, 
                testType:TestType.custom, 
                selected: true,
                customCommandToTest: form.values.command,
                customCommandExpectedResult: form.values.result, 
                customCommandToTestIsExternal: form.values.isExt,
                databaseType: DatabaseType.SQL,
                databaseNameToTest: '',
                databaseQueryToTest: '',
                databaseServerNameToTest: '',
                databaseUsername:'',
                databasePassword: '',
                databasePort:'',
                dbWindowsAuth:false,
                serviceToTest:'',
                testFromEDRC: false,
                script: '',
                thirdDeviceNetworkToTest: '',
                authenticationUserToTest:'',
                authenticationPassToTest:'',
                webPortalToTest: '',
                webPortalResult: ''
            };
            createJobStore.updateTestDevice(TestType.custom,DeviceTest,device.planName.includes("Custom Device") ? device.sourceName : device.deviceName);
            device.customCommandName= form.values.command;
            device.customCommandData= device.customCommandData;
            createJobStore.updateDevice(device);
            toast.success('Save successful');
        }
        else
        {
            jobSettingsStore.updateCustomSettings(form.values.command,form.values.result,form.values.isExt,null,null,OS);
            toast.success('Save successful');
        }
    }
        catch(error){
            toast.success('Save failed'+error);
        }
        setCancel(false);
        if(device!=undefined)
            onSave();
        onClose();
    }

    const handleTest=() =>{        
        var details =
        {
            machineName:device.sourceName,
            customCommand:form.values.command,
            customResult: form.values.result,
            commandData: device.customCommandData,
            devicePlatform: device.os,
            id: device.os == DevicePlatform.UNIX ? createJobStore.baseSettings.workflowTest.SecondaryUserCredsId : createJobStore.baseSettings.workflowTest.PrimaryUserCredsId
        };
        setProcessingTest(true);
        jobAgent.JobTestsActions.TestScript(details).then(res => {
            if(res)
                toast.success("Script Test succeeded");
            else
                toast.error("Script Test failed");
            setProcessingTest(false);
        }).catch(err=>{
        if(err && err.data && err.data.errors)
            toast.error(err.data.errors);
        setProcessingTest(false);
        });       
    }

    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel style={{marginLeft:'10px'}}>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const handleYes=()=>{
        let initialValues = {
            command: deviceCustomTest!=null ? deviceCustomTest.customCommandToTest : jobSettingsStore.customCommandToTest,
            result: deviceCustomTest!=null ? deviceCustomTest.customCommandExpectedResult: jobSettingsStore.customCommandExpectedResult,
            isExt: deviceCustomTest!=null ? deviceCustomTest.customCommandToTestIsExternal: jobSettingsStore.customCommandToTestIsExternal
        };
        form.setValues(initialValues);
        device!=undefined ? setOS(device.os):setOS(DevicePlatform.Windows);
        if(device!=undefined)
        {
            device.customCommandName = device.customCommandName != "" ?  device.customCommandName :'';
            device.customCommandData = device.customCommandData != "" ?  device.customCommandData : null;
            createJobStore.updateDevice(device);
        }
        setCancel(false);
        onClose();
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    useEffect(() => {
        const newDevice = createJobStore.selectedDevices.find(device=>device.deviceName == deviceName);
        setDevice(newDevice);
        const newCustomTest = newDevice!= undefined ? newDevice.tests.find(test=> test.testType == TestType.custom):null;
        setdeviceCustomTest(newCustomTest);
        let initialValues;
        initialValues =
        {
            command: isDefault ? defaultSettings.command : (newCustomTest !=undefined ? newCustomTest.customCommandToTest:''),
            result: isDefault ? defaultSettings.result : (newCustomTest !=undefined ? newCustomTest.customCommandExpectedResult:''),
            isExt: isDefault ? defaultSettings.isExt :(newCustomTest !=undefined ? newCustomTest.customCommandToTestIsExternal:false)
        };
        form.resetForm({values: initialValues});
        initialValues.command.includes("ls") || initialValues.command.includes("cat") ?setOS(DevicePlatform.UNIX) :setOS(DevicePlatform.Windows);
      }, [deviceName]);

    const handleExtChange =(event)=>{
        form.setFieldValue("isExt", event.target.checked);
      }

    const handleCommandChange=(event:any,value:any) => {
        if(event!=null){
            if (value!=null && typeof value === 'object') 
            {
              form.setFieldValue("command",value=="" ? '':value.command,true);
              form.setFieldValue("result",value=="" ? '':value.result,true);
              value.command.includes("ls")||value.command.includes("cat") ?setOS(DevicePlatform.UNIX) :setOS(DevicePlatform.Windows);
            }
            if (typeof value === 'string')
            {
              let commandObj ;
              if(value.includes("netdom"))  
                commandObj = jobSettingsStore.customList.find(e => e.command.toLocaleLowerCase().includes("netdom"));
              else
                commandObj = jobSettingsStore.customList.find(e => e.command.toLocaleLowerCase() == value.toLocaleLowerCase());
              form.setFieldValue("command", commandObj!=undefined ? value:(value==""?'':value),true);
              let res = commandObj== undefined ? '' :commandObj.result;
              form.setFieldValue("result",res,true);
              commandObj !=undefined ? (commandObj.command.includes("ls")||commandObj.command.includes("cat") ?setOS(DevicePlatform.UNIX) :setOS(DevicePlatform.Windows)) : setOS(DevicePlatform.Windows);
            }
            if(event.target.value===''){
            device.customCommandData=null;
            device.customCommandName='';
            form.setFieldValue('command','',true);
            setShowBrowse(true);
            }
            if(event.target.value!==null && event.target.value!=='' && device!=undefined)
                device.customCommandData = event.target.files!=null ? compressFile(event.target.files[0]):null;
          }
    }

    const handleResultChange=(event:any,value:any)=>{
        if(event!=null)
        {
            if(event.type ==="click")
            {
                const Obj = jobSettingsStore.customList.find(e => e.result == value);
              let res = Obj== undefined ? '' :Obj.result;
              form.setFieldValue("result",res,true);
            }
            else if(event.type ==="change")
            {
                form.setFieldValue("result",value=="" ? '':value);
            }
        }
    }

    const onOSChange = (event, value) => {
        setOS(event.currentTarget.value);
      };

      const handleFileUpload = e => {
        const { files } = e.target;
        if (files && files.length) 
        {
        let fileSizebytes=files[0].size/1024;
        const filename = files[0].name;
        var parts = filename.split(".");
        const fileType = parts[parts.length - 1];
        let fileTypePerOSInvalid;
        if(device!=undefined)
        {
            fileTypePerOSInvalid = OS === DevicePlatform.Windows ? ((fileType !== 'bat' && fileType !=='ps1') ?true:false) : ((fileType !== 'py' && fileType !=='sh') ?true:false);
        }
        else
            fileTypePerOSInvalid = OS;
        let invalidFileSize = fileSizebytes > 512;
        if(!invalidFileSize && !fileTypePerOSInvalid)
        {
            form.setFieldValue("command",files[0].name);
            const toBase64 = file => new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = () => resolve(reader.result);
                  reader.onerror = error => reject(error);
                });
            toBase64(files[0]).then(function(res) {
                if(device!=undefined)
                    device.customCommandData = res;
                else
                    createJobStore.updateCustomDefaultScript(res);
                });
            setShowBrowse(false);
            form.setFieldValue("result",'',true);
        }
        else
        {
            if(invalidFileSize)
            form.setErrors({ command: 'custom command script can not exceed 512KB' });
        else
            form.setErrors({ command: 'file type must be '});
        }
    }
    };

    const handleBrowse= ()=>{
        inputFile.current.click();
    }

    const isBackendValue =()=>{
        let val = {command:form.values.command,result:form.values.result,os: form.values.command.includes("ls")||form.values.command.includes("cat") ? DevicePlatform.UNIX :DevicePlatform.Windows};
        let found = jobSettingsStore.customList.find(item=> item.command === val.command);
        return found ? true : false;
    }

    const removeFile =()=>{
        if(device!=undefined)
        {
            device.customCommandData=null;
            device.customCommandName='';
            form.setFieldValue('command','',true);
            setShowBrowse(true);
        }
    }

    const loadOSSection =()=>{
        if(device === undefined)
        return( 
            <Grid item container>
            <Grid item xs={3} container alignItems="center">
                <Typography variant="h6" className={classes.heading}>* {t('operatingSystem')}</Typography>
            </Grid>
            <Grid item xs={9} alignItems="center" style={{marginTop:'12px',display:'flex',justifyContent:'flex-end'}}>
            <ToggleButtonGroup
                classes={{ root: "toggle_button_group" }}
                value={OS}
                onChange={onOSChange}
                >
                <ToggleButton
                disabled={isBackendValue()}
                classes={{ root: "toggle_button", selected: "toggle_button_selected", label: classes.label }}
                value={DevicePlatform.UNIX}>
                {t("toggleButton.linux")}
                </ToggleButton>
                <ToggleButton
                disabled={isBackendValue()}
                classes={{ root: "toggle_button", selected: "toggle_button_selected", label: classes.label }}
                value={DevicePlatform.Windows}>
                {t("toggleButton.windows")}
                </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            </Grid>)
    }

    return <Dialog disableBackdropClick
        open={open}
        classes={{ paper: classes.root }}
        onClose={handleClose}
        scroll="paper"
    >
        <EDRDialogTitle>
        <div style={{ display: 'flex', flexDirection: 'row'}}>
            <Typography variant="h6" className={classes.title} style={{display:'flex',alignItems:'center'}}>{t('customSetting')}</Typography>
            {device !== undefined ? <img style={{marginLeft:'5px'}} src={device.os=== DevicePlatform.UNIX ?"/assets/images/editDeviceIcons/linuxIcon.svg":"/assets/images/editDeviceIcons/windowsIcon.svg"}></img>:null}
        </div>
        </EDRDialogTitle>
        <DialogContent dividers={true}>
        <input style={{ display: "none" }} ref={inputFile} type="file" onChange={(event)=> { 
               handleFileUpload(event) 
               event.target.value=null
        }}/>
        <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row',paddingBottom:'5%'}}>
                    <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{device === undefined ?t('subCustomDefault'):t('subCustom')+deviceName}</Typography>
                    <Tooltip title={device === undefined ?t('customInfoDefault'):t('customInfo')} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip> 
                    <Divider className={classes.divider}/>
                </div>
            </Grid>

            <Grid container direction="column" spacing={2}>
                <Grid item container spacing={2}>
                    <Grid item xs={3} container alignItems="center">
                        <Typography variant="h6" className={classes.heading}>* {t('customCommand')}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                    <EDRAutocomplete id="command"
                        freeSolo
                        disableClearable
                        value={form.values.command}
                        options={getPreviousCustomData}
                        className={classes.autoWithButton}
                        onChange={handleCommandChange}
                        onInputChange={handleCommandChange}
                        renderOption={(option: string|{command: string,result:string}) => (typeof option === 'string' ? option : `${option.command}`)}
	                    getOptionLabel={(option: string|{command: string,result:string}) => (typeof option === 'string' ? option : `${option.command}`)}
                        renderInput=
                        {(params) => (<div style={{position: 'relative', width: '100%'}}>
                                <EDRTextField classes={{root: classes.autoCompleteWithButton}}fullWidth {...params} label={t('customCommand')} 
                        style={{borderColor:'#c7cfdb'}}
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="command"
                        onBlur={form.handleBlur}
                        error={form.touched && form.touched.command && form.errors && Boolean(form.errors.command)}
                        helperText={form.touched && form.touched.command && form.errors && Boolean(form.errors.command) && t(form.errors.command)}
                    />
                     {device!=undefined && ShowBrowse === true ? <Button className={classes.BrowseButton} onClick={()=>inputFile.current.click()} style={{height: '51px', position: 'absolute', right: 1, top: 1, width: 70}}>{ t("browse")}</Button>
                    :<Button size="small" className={classes.BrowseButton} onClick={removeFile} style={{height: '51px', position: 'absolute', right: 1, top: 1, width: 70}}>{t("remove")}</Button>}
                        </div>)}
                    />  
                    </Grid>
                </Grid>

                <Grid item container style={{paddingTop:'4%'}}>
                    <Grid item xs={3} container alignItems="center">
                        <Typography variant="h6" className={classes.heading}>{t('commandResult')}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                    <EDRAutocomplete id="result"
                        freeSolo
                        disableClearable
                        value={form.values.result}
                        options={[]}
                        onChange={handleResultChange}
                        onInputChange={handleResultChange}
                        renderOption={(option: string|{command: string,result:string}) => (typeof option === 'string' ? option : `${option.result}`)}
	                    getOptionLabel={(option: string|{command: string,result:string}) => (typeof option === 'string' ? option : `${option.result}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={t('commandResult')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="result"
                        onBlur={form.handleBlur}
                        error={form.touched.result && Boolean(form.errors.result)}
                        helperText={form.errors.result}
                    />)}
                    />  
                    </Grid>
                </Grid>
                {loadOSSection()}
                <Grid item container xs={12}>
                <Grid item xs={10}>
                <div className={classes.toggleItem}>
                    <ListItemText className={classes.text} style={{marginLeft:'0px',marginTop:'2%'}}>{t("testRemote")}</ListItemText>
                </div>
                </Grid>
                <Grid item xs={2}>
                <div className={classes.centerVertical} style={{justifyContent:'flex-end'}}>
                <Tooltip title="Testing will be done by connecting to the remote device. you can alternatively toggle this button to test remotely from the EnsureDR controller" arrow>
                        <Switch checked={form.values.isExt} name="toggle2nd"  onChange={handleExtChange} onBlur={form.handleBlur} color='primary' />
                </Tooltip>
                </div>
                </Grid>  
            </Grid>

            </Grid>
        </DialogContent>
        <DialogActions>
        <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end',marginRight:'2%'}}>
            <Button onClick={handleClose} color="primary" style={{textTransform:'none'}}>
                {t('cancel')}
            </Button>
            <Button onClick={handleTest} color="primary" disabled={form.values.command =="" || processingTest || form.values.isExt} style={{textTransform:'none',marginLeft:'-11px'}}>
            {processingTest ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                {t('Test')}
            </Button>
            <SaveButton onClick={handleSave} variant="contained" color="primary" style={{textTransform:'none'}} disabled={form.values.command === ''}>
                {t('save')}
            </SaveButton>
            </Grid>
        </DialogActions>
    </Dialog>
}

export default CustomTestSettingDialog;