import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle';
import CrossIcon from '@material-ui/icons/CancelRounded';
import ErrorIcon from '@material-ui/icons/Error';
import { TestFixStyles } from '../report/issuesAndAutofix.component';
import { useStatusColor } from '../../app/common/util/useStatusColor.hook';
import JobCategoryIcon from '../../app/common/jobCategoryIcon';
import { LastJobRunStatus } from '../../app/models/lastjobRun.model';
import ResillientIcon from '../Icons/resillientIcon';
import RecoverableIcon from '../Icons/recoverableIcon';
import AtRiskIcon from '../Icons/atRiskIcon';
const TestOverViewDevices = (props: {
    deviceType: string;
    passed: number;
    warning:number;
    failed: number;
    icon: any;
    jobReportStatus?: LastJobRunStatus
}) => {
    const { icon, deviceType, passed,warning, failed,jobReportStatus } = props;
    const okColor = useStatusColor("passed", passed);
    const warningColor = useStatusColor("warning", warning);
    const failedColor = useStatusColor("failed", failed)
    const total = passed + failed
    return <Grid container data-test="component-testoverview" style={{paddingBottom:'10px'}}>
        <Grid item style={{ flexGrow: 1 }}>
            <Grid container direction="column">
                <Grid item> 
                    <img src={icon} style={{ fill: "#8092a9",display:'flex',height:"50%"}}/>
                </Grid>
                <Grid item> 
                    <Typography variant="body1" noWrap={true} style={{fontWeight: 500, textAlign: 'left', fontSize: 12}}>{deviceType}</Typography>
                </Grid>
                <Grid item data-test="total-test">  
                    <Typography variant="body2" color="textSecondary" style={{textAlign: 'left'}}>{total + ' tested'}</Typography>
                </Grid>
            </Grid>
        </Grid>
        <Grid item>
            <Grid container direction="column">
                <Grid item>
                    <span style={{display: "flex",marginLeft:'0%'}}>
                    <ResillientIcon style={{ ...TestFixStyles.deviceCellIcon,fontSize: "24px", color: jobReportStatus == null ?'lightgray':(jobReportStatus ==  LastJobRunStatus.atRisk ? 'lightgray': okColor)}}/>{passed}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{display: "flex",marginLeft:'0%'}}>
                    <RecoverableIcon style={{ ...TestFixStyles.deviceCellIcon,fontSize: "24px",color: jobReportStatus == null ?'lightgray':(jobReportStatus == LastJobRunStatus.atRisk ? 'lightgray': warningColor)}}/>{warning} 
                    </span>
                </Grid>
                <Grid item>
                    <span style={{display: "flex",marginLeft:'0%'}}>
                    <AtRiskIcon style={{ ...TestFixStyles.deviceCellIcon, fontSize: "24px",color: jobReportStatus == null ?'lightgray':(jobReportStatus ==  LastJobRunStatus.atRisk ? 'lightgray': failedColor)}}/>{failed} 
                    </span>
                </Grid>
            </Grid>
        </Grid>
    </Grid>;
};


export default React.memo(TestOverViewDevices);