export const cities = [
    { "name": "Choose City", "location": "", "country":"", "id": 0, "lng": 0, "lat":0},
    { "name": "New york", "location": "New York, New York, USA", "country":"US", "id": 1, "lng": -78, "lat":39},
    { "name": "Chicago", "location": "Chicago, Illinois, USA", "country":"US", "id": 2, "lng":-98, "lat":41.6},
    { "name": "Los Angeles", "location": "Los Angeles, California, USA", "country":"US", "id": 3, "lng": -120, "lat":36},
    { "name": "San Diego", "location": "San Diego, California, USA", "country":"US", "id": 4 , "lng": -117.161087, "lat":32.715736},
    { "name": "Austin", "location": "Austin, Texas, USA", "country":"US", "id": 5 , "lng": -97.7430608, "lat":30.267153},
    { "name": "Columbus", "location": "Columbus, Ohio, USA", "country":"US", "id": 6 , "lng": -82.983330, "lat":39.983334},
    { "name": "San Francisco", "location": "San Franscisco, California, USA", "country":"US", "id": 7 , "lng": -122.431297, "lat":37.773972},
    { "name": "Denver", "location": "Denver, Colorado, USA", "country":"US", "id": 8 , "lng": -104.991531, "lat":39.742043},
    { "name": "Kansas City", "location": "Kansas City, Missouri, USA", "country":"US", "id": 9 , "lng": -94.578331, "lat":39.099724},
    { "name": "San Antonio", "location": "San Antonio, Texas, USA", "country":"US", "id": 10 , "lng": -98.494614, "lat":29.425171},
    { "name": "Dallas", "location": "Dallas, Texas, USA", "country":"US", "id": 11 , "lng": -96.796989, "lat":32.776665},
    { "name": "Tel aviv Jaffa", "location": "Tel Aviv Jaffa, Center, Israel", "country":"IL", "id": 12, "lng": 34.855499 ,"lat": 32.109333},
    { "name": "Haifa", "location": "Haifa, North, Israel", "country":"IL", "id": 13, "lng": 34.989571, "lat": 	32.794044},
    { "name": "Jerusalem", "location": "Jersualem, Center, Israel", "country":"IL","id": 14, "lng": 35.217018, "lat": 31.771959},
    { "name": "Beer Sheba", "location": "Beer Sheba, South, Israel", "country":"IL","id": 15, "lng": 34.7913017 , "lat": 31.2518101},
    { "name": "Bnei Brak", "location": "Bnei Brak, Center, Israel", "country":"IL","id": 16, "lng": 34.834 , "lat": 32.081},
    { "name": "Rishon Lezion", "location": "Rishon Lezion, Center, Israel", "country":"IL","id": 17, "lng": 34.789 , "lat": 31.971},
    { "name": "Ramat Gan", "location": "Ramat Gan, Center, Israel", "country":"IL","id": 18, "lng": 34.8248 , "lat": 32.0684},
    { "name": "Bat Yam", "location": "Bat Yam, Center, Israel", "country":"IL","id": 19, "lng": 34.752 , "lat": 32.024},
    { "name": "Eilat", "location": "Eilat, South, Israel", "country":"IL","id": 20, "lng": 34.9519 , "lat": 29.5577},
    { "name": "Holon", "location": "Holon, Center, Israel", "country":"IL","id": 21, "lng": 34.779 , "lat": 32.01},
    { "name": "Ashdod", "location": "Ashdod, South, Israel", "country":"IL","id": 22, "lng": 34.65 , "lat": 31.792},
    { "name": "Petach Tikva", "location": "Petach Tikva, Center, Israel", "country":"IL","id": 23, "lng": 34.887 , "lat": 32.087},
    { "name": "Netanya", "location": "Netanya, Hasharon, Israel", "country":"IL","id": 24, "lng": 34.86 , "lat": 32.333},
    { "name": "Rehovot", "location": "Rehovot, Center, Israel", "country":"IL","id": 25, "lng": 34.812 , "lat": 31.894},
    { "name": "Azure North Central", "location": "North Centeral, US, Azure", "country":"ZU","id": 26, "lng":-98, "lat":41.6},
    { "name": "Azure East DoD", "location": "East DoD, US, Azure", "country":"ZU","id": 27,"lng": -78, "lat":39 },
    { "name": "Azure East", "location": "East, US, Azure", "country":"ZU","id": 28, "lng": -78.024902 , "lat": 37.926868},
    { "name": "Azure East 2", "location": "East 2, US, Azure", "country":"ZU","id": 29, "lng": -78.024902 , "lat": 37.926868},
    { "name": "Azure GOV Virginia", "location": "GOV Virginia, US, Azure", "country":"ZU","id": 30, "lng": -78.024902 , "lat": 37.926868},
    { "name": "Azure Central", "location": "Central, US, Azure", "country":"ZU","id": 31, "lng":-93.258133, "lat":44.986656},
    { "name": "Azure Central DoD", "location": "Central DoD, US, Azure", "country":"ZU","id": 32, "lng": -91.530167 , "lat": 	41.661129},
    { "name": "Azure South Central", "location": "South Central, US, Azure", "country":"ZU","id": 33, "lng": -96.808891 , "lat": 32.779167},
    { "name": "Azure West Central", "location": "West Central, US, Azure", "country":"ZU","id": 34, "lng": -111.876183 , "lat": 40.758701},
    { "name": "Azure West", "location": "West, US, Azure", "country":"ZU","id": 35, "lng": -122.431297 , "lat":37.773972},
    { "name": "Azure West 2", "location": "West 2, US, Azure", "country":"ZU","id": 36, "lng": -122.335167 , "lat":47.608013},
    { "name": "Azure GOV Arizona", "location": "GOV Arizona, US, Azure", "country":"ZU","id": 37, "lng": -111.093735 , "lat": 	34.048927},
    { "name": "Azure West 3", "location": "West 3, US, Azure", "country":"ZU","id": 38, "lng": -118.243683 , "lat":34.052235},
    { "name": "Azure GOV Texas", "location": "GOV Texas, US, Azure", "country":"ZU","id": 39, "lng": -100.000000 , "lat": 31.000000},
    { "name": "London", "location": "London, United Kingdom", "country":"EU","id": 40, "lng": -0.118092 , "lat": 51.509865},
    { "name": "Moscow", "location": "Moscow, Russia", "country":"EU","id": 41, "lng": 37.618423 , "lat": 55.751244},
    { "name": "Paris", "location": "Paris, France", "country":"EU","id": 42, "lng": 2.349014 , "lat": 48.864716},
    { "name": "Berlin", "location": "Berlin, Germany", "country":"EU","id": 43, "lng": 13.404954 , "lat": 52.520008},
    { "name": "Zurich", "location": "Zurich, Switzerland", "country":"EU","id": 44, "lng": 8.5391825 , "lat": 47.3686498},
    { "name": "Warshaw", "location": "Warshaw, Poland", "country":"EU","id": 45, "lng": 21.017532 , "lat": 52.237049},
    { "name": "Tel Aviv", "location": "Tel Aviv, Israel", "country":"EU","id": 46, "lng": 34.855499 , "lat": 32.109333},
    { "name": "Madrid", "location": "Madrid, Spain", "country":"EU","id": 47, "lng": -3.703790, "lat": 40.416775},
    { "name": "Lisbon", "location": "Lisbon, Portugal", "country":"EU","id": 48, "lng": -9.142685 , "lat":38.736946},
    { "name": "Vienna", "location": "Vienna, Austria", "country":"EU","id": 49, "lng": 16.363449, "lat":	48.210033},
    { "name": "Oslo", "location": "Oslo, Norway", "country":"EU","id": 50, "lng": 10.757933, "lat":	59.911491},
    { "name": "Stockholm", "location": "Stockholm, Sweden", "country":"EU","id": 51, "lng": 	18.063240 , "lat": 59.334591},
    { "name": "Copenhagen", "location": "Copenhagen, Denmark", "country":"EU","id": 52, "lng": 12.568337 , "lat": 55.676098},
    { "name": "Rome", "location": "Rome, Italy", "country":"EU","id": 53, "lng": 12.496366, "lat": 41.902782},
    { "name": "Athens", "location": "Athens, Greece", "country":"EU","id": 54, "lng":23.727539 , "lat": 37.983810},
    { "name": "Amsterdam", "location": "Amsterdam, Netherlands", "country":"EU","id": 55, "lng": 4.897070 , "lat": 52.377956},
    { "name": "Brussels", "location": "Brussels, Belgium", "country":"EU","id": 56, "lng": 4.3517103 , "lat": 50.8503396},
    { "name": "Prague", "location": "Prague, Czechia", "country":"EU","id": 57, "lng": 14.418540, "lat": 50.073658},
    { "name": "Budapest", "location": "Budapest, Hungary", "country":"EU","id": 58, "lng": 19.040236 , "lat": 47.497913},
    { "name": "Bucharest", "location": "Bucharest, Romania", "country":"EU","id": 59, "lng": 26.096306 , "lat": 44.439663},
    { "name": "Vaduz", "location": "Vaduz, Liechtenstein", "country":"EU","id": 60, "lng": 9.52154 , "lat": 47.14151},
    { "name": "Dubai", "location": "Dubai, UAE", "country":"EU","id": 61, "lng": 55.296249 , "lat": 25.276987},
    { "name": "Abu Dhabi", "location": "Abu Dhabi, UAE", "country":"EU","id": 62, "lng": 54.366669 , "lat": 24.466667},
    { "name": "UK South", "location": "UK South, UK, Azure, ", "country":"ZE","id": 63, "lng": -0.118092 , "lat": 51.509865},
    { "name": "UK West", "location": "UK West, UK, Azure", "country":"ZE","id": 64, "lng": -4.143841 , "lat": 50.376289},
    { "name": "West Europe", "location": "West Europe, Belgium, Azure, ", "country":"ZE","id": 65, "lng": 4.351721 , "lat": 50.850346},
    { "name": "North Europe", "location": "North Europe, Ireland, Azure", "country":"ZE","id": 66, "lng": -8.468399 , "lat": 51.903614},
    { "name": "France Central", "location": "France Central, France, Azure", "country":"ZE","id": 67, "lng": 4.834277 , "lat": 45.763420},
    { "name": "France West", "location": "France West, France, Azure", "country":"ZE","id": 68, "lng": -1.552800 , "lat": 47.218102},
    { "name": "Spain Central", "location": "Spain Central, Spain, Azure", "country":"ZE","id": 69, "lng": -3.703790 , "lat":40.416775},
    { "name": "Switzerland West", "location": "Switzerland West, Switzerland, Azure", "country":"ZE","id": 70, "lng": 7.451123 , "lat": 	46.947456},
    { "name": "Switzerland North", "location": "Switzerland North, Switzerland, Azure", "country":"ZE","id": 71, "lng": 	8.5391825 , "lat": 47.3686498},
    { "name": "Norway West", "location": "Norway West, Norway, Azure", "country":"ZE","id": 72, "lng": 5.324383 , "lat":	60.397076},
    { "name": "Norway East", "location": "Norway East, Norway, Azure", "country":"ZE","id": 73, "lng": 10.757933, "lat":	59.911491},
    { "name": "Sweden Central", "location": "Sweden Central, Sweden, Azure", "country":"ZE","id": 74, "lng": 	18.063240 , "lat": 59.334591},
    { "name": "Denmark East", "location": "Denmark East, Denmark, Azure", "country":"ZE","id": 75, "lng": 12.568337 , "lat": 55.676098},
    { "name": "Germany North", "location": "Germany North, Germany, Azure", "country":"ZE","id": 76, "lng": 9.993682 , "lat":53.551086},
    { "name": "Germany Northeast", "location": "Germany Northeast, Germany, Azure", "country":"ZE","id": 77, "lng": 13.404954 , "lat":52.520008},
    { "name": "Poland Central", "location": "Poland Central, Poland, Azure", "country":"ZE","id": 78, "lng": 21.017532 , "lat": 52.237049},
    { "name": "Austria East", "location": "Austria East, Austria, Azure", "country":"ZE","id": 79, "lng": 21.017532 , "lat": 52.237049},
    { "name": "Germany Central", "location": "Germany Central, Germany, Azure", "country":"ZE","id": 80, "lng":11.061859 , "lat":	49.460983},
    { "name": "Germany West Central", "location": "Germany West Central, Germany, Azure", "country":"ZE","id": 81, "lng":8.682127 , "lat": 50.110924},
    { "name": "Italy North", "location": "Italy North, Italy, Azure", "country":"ZE","id": 82, "lng": 9.188540 , "lat": 45.464664},
    { "name": "Greece South", "location": "Greece South, Greece, Azure", "country":"ZE","id": 83, "lng":23.727539 , "lat": 37.983810},
    { "name": "Israel Central", "location": "Israel Central, Israel, Azure", "country":"ZE","id": 84, "lng": 	34.855499 , "lat": 32.109333},
    { "name": "Qatar Central", "location": "Qatar Central, Qatar, Azure", "country":"ZE","id": 85, "lng": 51.534817 , "lat":25.286106},
    { "name": "UAE Central", "location": "UAE Central, UAE, Azure", "country":"ZE","id": 86, "lng": 54.366669 , "lat":24.466667},
    { "name": "UAE North", "location": "UAE North, UAE, Azure", "country":"ZE","id": 87, "lng": 55.296249 , "lat":25.276987},
    
];