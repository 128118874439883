import { NoticeType } from "./dashboard.enum";

export interface IDashboardEnv {
    dashboardData: IDashboard[];
}

export interface INoticePanel{
    realDowntime:number;
    rpa:number;
    rta:number;
}

export interface INotice{
    noticeType?: NoticeType;
    header: string;
    mainText?: string;
    secondaryText?: string;
    realDowntime?: number;
    rpa?: number;
    rta?: number;
    goodStateIcon?:boolean;
}


export interface IGraph{
    graphHeader: string;
    
}

export interface IJobScore {
    limit: number;
    jobIds:Array<number>;
}
export class JobScore implements IJobScore {
    limit: number;
    jobIds:Array<number>;
    type: string;
    constructor(init?: JobScore) {
       Object.assign(this, init);                         
   }
}

export interface IDashboard {
    id: string;
    jobId: string;
    JobName: string;
    jobResultDate: string;
    RTA: number;
    RTO: number;
    Score: number;
    iopsCapacity: number;
    iopsActual: number;
    ramCapacity: number;
    ramActual: number;
    cpuCapacity: number;
    cpuActual: number;
}

export class Dashboard implements IDashboard {
    id: string = 'test';
    jobId: string = '';
    JobName: string = '';
    jobResultDate: string = "01.01.2020";
    RTA: number = 0;
    RTO: number = 0;
    Score: number = 0;
    iopsCapacity: number = 0;
    iopsActual: number = 0;
    ramCapacity: number = 0;
    ramActual: number = 0;
    cpuCapacity: number = 0;
    cpuActual: number = 0;

    constructor(init?: IDashboard) {
        Object.assign(this, init); //this will initialize our object of class Dashboard
                                  // with the above values
    }
}