import React from 'react'
import {  SvgIcon} from '@material-ui/core';




export const RamIcon = (props) => {
    return <SvgIcon viewBox="0 0 18 18" {...props}>
        <g xmlns="http://www.w3.org/2000/svg" id="prefix__Group_3155" transform="translate(1,-96)" data-name="Group 3155" >
            <path id="prefix__Path_7025" d="M210.687 193.055h2.832v2.971h-2.832z" data-name="Path 7025" transform="translate(-204.103 -87.241)" />
            <path id="prefix__Path_7026" d="M90.055 193.055h2.832v2.971h-2.832z" data-name="Path 7026" transform="translate(-87.241 -87.241)" />
            <path id="prefix__Path_7027" d="M331.32 193.055h2.832v2.971h-2.832z" data-name="Path 7027" transform="translate(-320.966 -87.241)" />
            <path id="prefix__Path_7028" d="M16 103H0v8.6h.963v.939H1.9v-.939h.94v.939h.938v-.939h.939v.939h.938v-.939h.939v.939h.938v-.939h.939v.939h.938v-.939h.939v.939h.938v-.939h.939v.939h.938v-.939h.937v.939h.938v-.939H16zm-14.123 6.722v-4.846h12.246v4.846z"
                data-name="Path 7028" />
        </g>
    </SvgIcon>
}

export const CPUIcon = (props) => {
    return <SvgIcon viewBox="0 0 18 18" {...props}>
        <path xmlns="http://www.w3.org/2000/svg" id="prefix__Path_6994" d="M15.333 7V5.667H14V4.333A1.337 1.337 0 0 0 12.667 3H3.333A1.337 1.337 0 0 0 2 4.333v9.333A1.337 1.337 0 0 0 3.333 15h9.333A1.337 1.337 0 0 0 14 13.667v-1.334h1.333V11H14V9.667h1.333V8.333H14V7zm-2.667 6.667H3.333V4.333h9.333zm-8-4H8v2.667H4.667zm4-4h2.667v2H8.667zm-4 0H8V9H4.667zm4 2.667h2.667v4H8.667z" 
        data-name="Path 6994" />
    </SvgIcon>
}

export const IopsIcon = (props) => {
    return <SvgIcon viewBox="0 0 12 12" {...props}>
        <path d="M12.286 9.028v1.229c0 .963-2.394 1.743-5.143 1.743S2 11.219 2 10.257V9.028c1.244.869 3.6 1.128 5.143 1.128s3.902-.262 5.143-1.128zm-5.143-1.7C5.6 7.323 3.24 7.062 2 6.195v1.217c0 .963 2.395 1.743 5.143 1.743s5.143-.781 5.143-1.743V6.195c-1.244.869-3.603 1.128-5.143 1.128zm0-7.328C4.395 0 2 .781 2 1.744s2.395 1.744 5.143 1.744 5.143-.781 5.143-1.744S9.892 0 7.143 0zm0 4.488C5.6 4.488 3.24 4.226 2 3.36v1.22c0 .963 2.395 1.743 5.143 1.743s5.143-.781 5.143-1.743V3.36c-1.244.869-3.603 1.128-5.143 1.128z" transform="translate(-2)"/>
    </SvgIcon>
}