import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/root.store';
import { Dialog, useMediaQuery, useTheme, DialogContent, Grid, Typography, Button, makeStyles, InputLabel, FormControl, OutlinedInput, FormHelperText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import jobAgent from '../../app/api/jobAgent';
import { toast } from 'react-toastify';
import {JobRename} from "../../app/models/jobactions/jobRename.model";
import {history} from '../../app/common/util/history';
import queryString from 'querystring';
import { JobSummaryGroupByJob } from '../../app/models/jobSummaryGroupByJob.model';
import { UpdateType } from '../../app/stores/activityStore';
import { EDRTextField } from '../../app/common/EDRTextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { SaveButton } from '../../app/common/saveButton';

const { JobActions } = jobAgent;
type RenameProps = {
  jobId: number;
  jobName: string;
  jobNameFromJob: string;
  open: boolean;
  onClose: () => void;
}



const useStyles = makeStyles(() => ({

  textfield: {
    position: 'relative',
    "& .MuiFormHelperText-root": {
      position: 'absolute'
    }
  },
  label: {
    textTransform: 'capitalize',
  }
}))

const RenameJobComponent: React.FC<RenameProps> = (props) => {
    const {jobId,jobNameFromJob,onClose, open} = props;
    const { activityStore } = useContext(RootStoreContext); 
    const theme = useTheme();    
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const {t: errorTranslations} = useTranslation('errors');
    const {t} =  useTranslation('jobmenu');
    

    
    const classes = useStyles();
    
    const validation = yup.object({
      newName: yup.string()
      .max(30, 'up to 30 characters')
      .required('Required')
      .test('empty','job name must be not empty',newName => newName !=='undefined')
      .test('sameName',t("sameNameError"),newName => newName !== jobNameFromJob)
      .matches(/^[a-zA-Z0-9-_]*$/,"name should conatains letters,numbers,hypen and underscore"),
      licenseKey: yup.string(),
      licenseId:yup.number()
    })
  
    const form = useFormik({
      initialValues: {
        newName: ''
      },
      validationSchema:validation,
      validateOnBlur:false,
      validateOnMount: true,
      validateOnChange: true,
      onSubmit: () => { },
    })

    const getFormValues = (field: string) => {
      
    }
    const keyPress = event => {
        if(event.keyCode == 13){
          renameHandler();
        }
    }
    const onChange = event => {
      let valid: boolean;
      form.setFieldValue("newName",event.target.value,true);
      valid = event.target.value !== jobNameFromJob && event.target.value !== " " && !event.target.value.startsWith(" ") && (/[a-zA-Z0-9-_]$/.test(event.target.value));
      if (!valid)  
     {
        if(event.target.value === jobNameFromJob) 
        {
          form.setFieldError("newName",t("sameNameError"));
        } 
        else 
        {
          form.setFieldError("newName",t("badNameError")); 
        }
      }
    }
    
    const renameHandler= () => {
      let values = {
        id: jobId,
        newJobName: form.values.newName,
      }
      if(form.values.newName !== jobNameFromJob)
      {
        JobActions.rename(new JobRename({id: values.id,newJobName:values.newJobName}))
        .then(res => {
          if(res === "success")
          {
              activityStore.sendMessage(UpdateType.JobRenamed, {jobId: values.id});
              toast.success('Job Renamed Successfully')
          }
          else
          {
            if(res === "AlreadyExist")
            {
              toast.error(<React.Fragment>
                <h3>{errorTranslations('Error')}</h3>
                {<div style={{marginLeft: 10}}><i>{errorTranslations('NameExistJobNotChagnedRename')}</i></div>}
              </React.Fragment>, {autoClose: 30000 })   
            }
            else if(res === "AssetNotExist")
            {
              toast.error(<React.Fragment>
                <h3>{errorTranslations('Error')}</h3>
                {<div style={{marginLeft: 10}}><i>{errorTranslations('RunnerServiceNotExistJobNotChagnedRename')}</i></div>}
              </React.Fragment>, {autoClose: 30000 })     
            }
            else if(res === "AssetNotAvailable")
            {
              toast.error(<React.Fragment>
                <h3>{errorTranslations('Error')}</h3>
                {<div style={{marginLeft: 10}}><i>{errorTranslations('RunnerServiceNotAvailableJobNotChagnedRename')}</i></div>}
              </React.Fragment>, {autoClose: 30000 })                 
            }
            else
            {
              toast.error('Failed To Rename Job');
            } 
          }
        })
        .catch(err => {
          if(err && err.data && err.data.errors)
            toast.error(err.data.errors);
        })
        .finally(
          () => onClose()

        );
      }
    }

    const close = () =>
    {
      onClose();
    }  
 
    useEffect(() => {
      //callMatomo("Profile_Form","/user");
    }, []);
    const selectJob = (jobGroup: JobSummaryGroupByJob) => {
      const query = { jobId: jobGroup.jobId, jobResultId: jobGroup.lastJobResultId }
      history.push({ search: queryString.stringify(query) });
    }
      const dialogContents = (
            <Dialog
              style={{ zIndex: 1302 }}
              open={open}
              fullScreen={matchesSM}
            >
              <DialogContent style={{ overflow: "hidden"}}>
                <Grid container direction="column" spacing={3} style={{ overflow: "hidden"}}>
                  <Grid item>
                    <Typography align="left" classes={{ root: "rename_form_title"}}>
                    {t("renameTitle")}
                    </Typography>
                  </Grid>
                  <Grid item >
                    <FormControl variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-username">{t("oldName")}</InputLabel>
                          <OutlinedInput 
                                      disabled
                                      id="oldname"
                                      value={jobNameFromJob}
                                      classes={{ root: "profile_text_box" }}
                                      label={t("oldName")}
                          />  
                    </FormControl>
                  </Grid>
                  <Grid item>
                  
                    <EDRTextField id="newName"
                  name="newName"
                  label={t("newName")}
                  placeholder="Type Here"
                  className={classes.textfield}
                  value={form.values.newName}
                  onChange={onChange}
                  onBlur={form.handleBlur}
                  error={Boolean(form.errors.newName)}
                  helperText={t(form.errors.newName)}
                 InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                variant="outlined">
          </EDRTextField>
                 
                  </Grid>
                  <div style={{width:"372px", height: "40px",marginBottom: "2em", marginTop: "1em", display:"flex",justifyContent:"flex-end"}}>
                    <Grid item>
                      <span data-test="renameCancelButton">
                        <Button
                          classes={{ root: "Cancel", label: classes.label}}
                          style={{ marginRight: "2em", marginTop: "0.5em" }}
                          onClick={() => close()}
                        >
                          { t("renameCancel")}
                        </Button>
                      </span>
                    </Grid>
                    <Grid item>
                      <span data-test="renameChangeButton">
                        <SaveButton
                          disabled={Boolean(form.errors.newName)}
                          variant="contained"
                          color="primary"
                          style={{textTransform:'none'}}
                          onClick={() => {renameHandler()}}>
                          {t("renameChange")}
                        </SaveButton>
                      </span>
                    </Grid>
                  </div>
                </Grid>
        </DialogContent>
    </Dialog>
      );
      return (
        <div data-test='component-set-name'>
          {dialogContents}        
        </div>
    );
}

export default observer(RenameJobComponent);