import React, { useContext, useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/root.store';
import { Dialog, useMediaQuery, useTheme, DialogContent, Grid, Button, makeStyles, DialogActions, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, formatMs, Tooltip, Typography, Divider, FormControlLabel, Chip, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EDRDialogTitle } from '../../app/common/EDRDialogTitle';
import { SaveButton } from '../../app/common/saveButton';
import { EDROutlinedInput } from '../../app/common/EDROutlinedInput';
import ReactDOM from 'react-dom';
import { EDRTextField } from '../../app/common/EDRTextField';
//import ReactPhoneInput, { CountryData } from "react-phone-input-2";
import HelpIcon from '@material-ui/icons/Help';
//import 'react-phone-input-2/lib/style.css';
import { useLocation } from 'react-router-dom';
import * as qs from 'querystring';
import { CustomCheckbox } from '../createJob/baseSettings/Advanced/BasicAdvanced.component';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { EDRAutocomplete } from '../../app/common/EDRAutocomplete';
import settingAgent from '../../app/api/settingAgent';

export interface SupportFormProps {
    open: boolean;
    onClose: () => void;
}

const FormSupportComponent: React.FC<SupportFormProps> = (props) => {
    const { open, onClose } = props;
    const rootStore = useContext(RootStoreContext);
    const { jobResultStore, userStore } = rootStore;
    const [t] = useTranslation("help");
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const [jobOptionList, setJobOptionList] = useState([]);
    let labelRef;
    const [isLoading, setisLoading] = useState(false);
    const useStyles = makeStyles({
        root: {
            minWidth: '25vw',
        },
        helpIcon:
        {
            color: '#1976d2',
            fontSize: "large"
        },
        SecondHeading: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1
        },
        divider: {
            backgroundColor: '#eaeef4',
            width: '290%',
            margin: '0',
            marginTop: '10px',
            marginLeft: '10px',
        },
        phoneInput: {
            height: '48px',
            width: '430px',
        },
        formControl: {
            width: '100%',
            height: '70px',
            '& label': {
                background: "white",
                width: "auto",
                padding: "0 4px 0 4px",
                fontSize: '17px',
            }
        },
        formControlMulti: {
            width: '100%',
            height: '200px',
            '& label': {
                background: "white",
                width: "auto",
                padding: "0 4px 0 4px",
                fontSize: '17px',
            }
        },
        container: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        phoneContainer: {
            width: '75%',
            height: 50,
        },
        button: {
            marginTop: 30,
            width: '75%',
            padding: 10,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'green',
        },
        textInput: {
            paddingVertical: 0,
        }
    })
    const classes = useStyles();

    const validation = yup.object({
        email: yup.string().required('Mandatory'),

        issue: yup.string()
            .required('Mandatory'),

        job: yup.array().min(1).required('Requireed').of(yup.string()),

        description: yup.string().required("Mandatory"),

    })

    const form = useFormik({
        initialValues:
        {
            email: userStore.user.username,
            issue: '',
            job: [],
            description: '',
            sendScreenshot: false,
            sendAllLogs: false
        },
        validationSchema: validation,
        validateOnMount: true,
        onSubmit: () => {
        }
    })

    // useEffect (()=>{
    //     let initialValues =
    //     {
    //        email:userStore.user.username,
    //        issue:'',
    //        job:'',
    //        description:'',
    //        sendScreenshot:false,
    //        sendAllLogs:false
    //     };
    //     form.setValues(initialValues, true);
    // },[])

    useEffect(() => {
        if (jobResultStore.jobResults.length > 0) {
            let list = [];
            jobResultStore.jobResults.map(item => {
                list.push(item.jobName);
            });
            setJobOptionList(list);
        }
        else {
            setJobOptionList([]);
        }
    }, [jobResultStore.jobResults])

    const handleSave = () => {
        let supportDetails = {
            email: form.values.email,
            issue: form.values.issue,
            job: form.values.job.join(','),
            description: form.values.description,
            sendScreenshot: form.values.sendScreenshot,
            sendAllLogs: form.values.sendAllLogs
        };
        setisLoading(true);
        return settingAgent.Setting.sendSupportMail(supportDetails).then(res => {
            toast.success("Support Case was opened Successfully");
            onClose();
        }).catch(err => {
            if (err && err.data && err.data.errors) {
                toast.error("Support Case wasn't opened :" + err.data.errors);
            }
        }).finally(() => {
            setisLoading(false);
        })
    }

    const handleScreenshotChange = (event) => {
        form.setFieldValue('sendScreenshot', event.target.checked, true);
    }

    const handleSendLogsChange = (event) => {
        form.setFieldValue('sendAllLogs', event.target.checked, true);
    }

    const handleJobIssueChange = (event, value: Array<string>) => {
        form.setFieldValue("job", value);
    }

    const handleJobIssueBlur = (event) => {
        const value = event.target.value;
        if (value) {
            form.setFieldValue('job', [...form.values.job, value]);
        }
        form.handleBlur(event);
    }


    return <Dialog disableBackdropClick
        open={open}
        fullScreen={matchesSM}
        onClose={onClose}
        classes={{ paper: classes.root }}
        scroll="paper"
    >
        <EDRDialogTitle >{t("supportCaseForm")}</EDRDialogTitle>
        <DialogContent dividers={true}>
            <Grid container direction="column" spacing={2} style={{ overflow: "hidden" }}>
                <Grid container item direction="row">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography className={classes.SecondHeading} style={{ color: '#4d5f75' }}>{t("supportDetails")}</Typography>
                        <Tooltip title={t("supportInfo")} arrow>
                            <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                        </Tooltip>
                        <Divider className={classes.divider} />
                    </div>
                </Grid>

                <Grid item style={{ paddingTop: '5%' }}>
                    <FormControl className={classes.formControl} variant='outlined'>
                        <EDRTextField
                            name="email"
                            label={<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                {t('email')}
                                <Tooltip title={t("emailInfo")} arrow>
                                    <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                                </Tooltip>
                            </div>}
                            value={form.values.email}
                            autoComplete="off"
                            disabled={true}
                            error={Boolean(form.touched.email && form.errors.email)}
                            helperText={form.touched.email && t(form.errors.email)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>

                <Grid item>
                    <FormControl className={classes.formControl} variant='outlined'>
                        <EDRTextField
                            name="issue"
                            label={t('issue')}
                            value={form.values.issue}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            autoComplete="off"
                            error={Boolean(form.touched.issue && form.errors.issue)}
                            helperText={form.touched.issue && t(form.errors.issue)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>

                <Grid item>
                    <FormControl className={classes.formControl} variant='outlined'>
                        <InputLabel ref={ref => { labelRef = ReactDOM.findDOMNode(ref) }} style={{ transform: 'translate(14px, -6px) scale(0.75)' }}>{t('Jobs With Issue')}</InputLabel>
                        <EDRAutocomplete id="job"
                            disableClearable
                            freeSolo={false}
                            multiple={true}
                            value={form.values.job}
                            options={jobOptionList}
                            style={{ maxWidth: '430px', maxHeight: '100px', overflowY: 'scroll' }}
                            onChange={handleJobIssueChange}
                            renderInput={(params) => (<EDRTextField {...params}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                placeholder="Type Here"
                                onBlur={handleJobIssueBlur}
                                error={form.touched && form.touched.job && form.errors && Boolean(form.errors.job)}
                                helperText={form.touched && form.touched.job && form.errors && Boolean(form.errors.job) && t(form.errors.job as string)}
                            />)}
                        />
                    </FormControl>
                </Grid>

                <Grid item style={{ paddingTop: '4%' }}>
                    <FormControl className={classes.formControl} variant='outlined'>
                        <EDRTextField
                            name="description"
                            label={t('description')}
                            value={form.values.description}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            autoComplete="off"
                            multiline
                            rows="8"
                            error={Boolean(form.touched.description && form.errors.description)}
                            helperText={form.touched.description && t(form.errors.description)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>

                <Grid item style={{ paddingTop: '25%' }}>
                    <FormControlLabel
                        value={t('sendScreenshot')}
                        control={<CustomCheckbox icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} checked={form.values.sendScreenshot} onChange={handleScreenshotChange} />}
                        label={<div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography>{t('sendScreenshot')}</Typography>
                            <Tooltip title={t("sendScreenshotInfo")} arrow>
                                <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                            </Tooltip>
                        </div>}
                    />
                </Grid>

                <Grid item>
                    <FormControlLabel
                        value={t('sendAllLogs')}
                        control={<CustomCheckbox icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} checked={form.values.sendAllLogs} onChange={handleSendLogsChange} />}
                        label={<div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography>{t('sendAllLogs')}</Typography>
                            <Tooltip title={t("AllLogsInfo")} arrow>
                                <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                            </Tooltip>
                        </div>}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={onClose} color="primary" style={{ textTransform: 'none' }}>
                    {t('cancel')}
                </Button>
                <SaveButton color="primary" variant="contained" disabled={!form.isValid|| isLoading} onClick={handleSave} style={{ textTransform: 'none', minWidth: 105 }}>
                    {isLoading && <CircularProgress color='primary' size={20} />}
                    {!isLoading && t('openCase')}
                </SaveButton>
            </Grid>
        </DialogActions>
    </Dialog>
}

export default (observer(FormSupportComponent));