import { Button, Card, CircularProgress, Divider, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { EDRAutocomplete } from '../../../../app/common/EDRAutocomplete';
import { toast } from 'react-toastify';
import { runInAction } from 'mobx';
import * as yup from 'yup';
import Create from '@material-ui/icons/Create';
import { RootStoreContext } from '../../../../app/stores/root.store';
import JobSettingsStore from '../../../../app/stores/jobSettings.store';
import LockIcon from '@material-ui/icons/Lock';
import { LicenseType } from '../../../../app/models/jobProperties.model';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import assetsAgent from '../../../../app/api/assetsAgent';
import { EDRTextField } from '../../../../app/common/EDRTextField';
import CheckCircle from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles({
    editIcon: {
        cursor: 'pointer'
    },
    button: {
        width: '50%',
        height: '100%',
        borderRadius: '4px',
        border: 'solid 1px #c7cfdb',
        backgroundColor: '#fafafc'
    },
    advanced: {
        width: '14px',
        height: '14px',
        objectFit: 'contain',
    },
    MainHeading: {
        fontFamily: 'Roboto',
        color: '#1f396d',
        fontSize: '16px'
    },
    SecondHeading: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '100%',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.14',
        letterSpacing: 'normal',
        textAlign: 'left'
    },
    card: {
        padding: 25,
        height: '100%',
    },
    divider: {
        backgroundColor: '#eaeef4',
        width: '75%',
        marginTop: '8px',
        marginLeft: '7px',
        marginRight: '-107px'
    },
    helpIcon:
    {
        color: '#1976d2',
        fontSize: "large"
    },
    formControl: {
        width: '100%',
        '& label': {
            background: "white",
            width: "auto",
            padding: "0 4px 0 4px",
            fontSize: '17px'
        }
    },
    validateIcon: {
      position: 'absolute',
      top: 33,
      right:8,
      color: '#8bc53f'
    },
    validationStatus: {
        color: 'red'
      }
});

const EDRCreateComponent: React.FC = () => {

    const classes = useStyles();
    const [t] = useTranslation("createJobNetwork");
    const rootStore = useContext(RootStoreContext);
    const {jobSettingsStore,displayStore,createJobStore} = rootStore;
    const {isFirstTrial} = jobSettingsStore;

    const validateEDR = async (value, resolve) => {
        //let edrName = context.from[0].value.EDRName
        try {
          if (!value) {
            displayStore.updateNextStepValid({edrValid:false})
            return;
          }
          await assetsAgent.ValidateEDR({id:0,assetName:value}).then(res => {
            if(res.data === true)
            {
                displayStore.updateNextStepValid({edrValid:true})
                resolve(true);
            }
            else
            {
                displayStore.updateNextStepValid({edrValid:false})
                resolve(false);
            }
          }).catch(err=> {
            if(err && err.data && err.data.errors)
            {
                toast.error(`${value} Validation failed - ${err.data.errors}`)
            }
            displayStore.updateNextStepValid({edrValid:false});
          });
          
        } catch (error) {
          resolve(false);
        }
        };
     const edrValidation = debounce(validateEDR, 800, {
         'leading': false,
         'trailing': true
         });

    const validation = yup.object({
        EDRName:yup.string().required('Required')
        .test('empty','EDR name must be not empty',EDRName => EDRName!='')
        .max(50,'up to 50 characters')
        .test("validateEDR","EDR Validation Failed",function(value,context) {
            return new Promise(res => edrValidation(value, res))
        })
        
        })

    useEffect(() => {
        let initialValues ={
                EDRName:jobSettingsStore.tempEDR && jobSettingsStore.tempEDR.assetName,
        };
        form.setValues(initialValues);
    },[])
    
    const form = useFormik({
        initialValues: 
        {
            EDRName:'',
        },
        validationSchema:validation,
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: () => { },
    })
    const handlehostnameIPChange =(event)=> {
        if(!event)
            return;

        displayStore.updateNextStepValid({edrValid:false});
        form.setFieldValue("EDRName",event.target.value=="" ? '':event.target.value,true);
        jobSettingsStore.updateTempEDR({assetName:event.target.value});
    }
        return (
            <Grid container spacing={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{padding: '0 0 0 80px'}}>
                    {form.isValidating && <Typography variant="body2" className={classes.validationStatus}>Validating EDR...</Typography>}
                </Grid>
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={1} style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
            {isFirstTrial  ? 
            <Tooltip title={t("trialLock")} arrow>
                <LockIcon />
            </Tooltip>
            : null}
            </Grid>
            <Grid item xs={10}> 
            <FormControl className={classes.formControl} variant='outlined'>   
                 <EDRAutocomplete id="servername"
                freeSolo
                disableClearable
                value={form.values && form.values.EDRName}
                options={[]}
                renderOption={(option: string|{EDRName: string}) => (typeof option === 'string' ? option : `${option.EDRName}`)}
                getOptionLabel={(option: string|{EDRName: string}) => (typeof option === 'string' ? option : `${option.EDRName}`)}
                onChange={handlehostnameIPChange}
                onInputChange={handlehostnameIPChange}
                onBlur={()=>{
                    form.setFieldTouched("EDRName", true)
                    }}
                renderInput={(params) => (<EDRTextField {...params}
                label= {t('hostnameIP')}
                InputLabelProps={{shrink: true}} 
                margin="normal" 
                variant="outlined"
                placeholder="EDR Name" 
                aria-autocomplete="none"
                error={form.touched && form.touched.EDRName && form.errors && Boolean(form.errors.EDRName)}
                helperText={form.touched && form.touched.EDRName && form.errors && Boolean(form.errors.EDRName) && t(form.errors.EDRName)}
                />)}
            />
            <React.Fragment>{!form.isValidating && displayStore.nextStepValid.edrValid && <CheckCircle className={classes.validateIcon} />}</React.Fragment>
             </FormControl>
             <Grid item xs={1}></Grid> 
             </Grid>
         </Grid>
         </Grid>
    );
};

export default observer(EDRCreateComponent);