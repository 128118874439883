import { LastJobRun, LastJobRunStatus } from "../../models/lastjobRun.model";
import moment from "moment";
import { JobResultQuery } from "../../models/jobResultQuery.model";

export class JobFilter {
    
    static filterByCategory(category: string, jobResults: Array<LastJobRun>) {
        switch (category) {
            case "all": return jobResults;
            case "resilient": return jobResults.filter(j => j.status === LastJobRunStatus.resillient);
            case "recoverable": return jobResults.filter(j => j.status === LastJobRunStatus.recoverable);
            case "atRisk": return jobResults.filter(j => j.status === LastJobRunStatus.atRisk);
            case "running": return jobResults.filter(j => j.isRunning);
            default: return jobResults;
        }
    }

    
    static filterByDate(query: {startDate?: string, endDate?: string} ,jobResults: Array<LastJobRun>) {
        if (!query.startDate || !query.endDate)
            return jobResults;
        const endDate = moment(query.endDate);
        const startDate = moment(query.startDate);
        return jobResults.filter(j => moment(j.runDate).isBetween(startDate, endDate, 'day', '[]'))
    }

    
    static filterJobResults(query: JobResultQuery, jobResults: Array<LastJobRun>) {
        const { category } = query;
        let dataMovers = [];
        let deviceJobIds = [];
        if (query.dataMover) {
            dataMovers = query.dataMover instanceof Array ? query.dataMover : [query.dataMover];
        }
        if (query.deviceJobIds) {
            deviceJobIds = query.deviceJobIds instanceof Array ? query.deviceJobIds : [query.deviceJobIds];
        }

        jobResults = JobFilter.filterByCategory(category, jobResults);
        jobResults = JobFilter.filterByDate(query, jobResults);
        if (dataMovers.length) {
            jobResults = jobResults.filter(j => !j.dataMover || dataMovers.indexOf(j.dataMover) >= 0);
        }
        if (deviceJobIds.length) {
            jobResults = jobResults.filter(j => deviceJobIds.indexOf(j.jobId + '') >= 0);
        }
        return jobResults;
    }
}