import { DnsResolveType, NetworkType } from "./jobProperties.model"

export const  DNS_RESOLVE_NAME = {
    [DnsResolveType.None]: "None",
    [DnsResolveType.AutoHostFileUpdate]: "Auto host file update",
    [DnsResolveType.DnsServer]: "DNS server resolve "
}

export function getDNS(dnsId:number) {
    switch(dnsId)
    {
        case 0:
            return DnsResolveType.None;
        case 1:
            return DnsResolveType.AutoHostFileUpdate;
        case 2:
            return DnsResolveType.DnsServer;
    }
}

