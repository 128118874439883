import { Button, CircularProgress, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, makeStyles, TextField, Tooltip, Typography} from "@material-ui/core";
import { useContext, useEffect, useRef, useState } from "react";
import { RootStoreContext } from "../../../app/stores/root.store";
import { useFormik, yupToFormErrors } from 'formik';
import * as yup from 'yup';
import { observer } from "mobx-react-lite";
import React from "react";
import { MachineType } from "../../../app/models/jobProperties.model";
import { DataMover } from "../../../app/models/BaseSettings.enum";
import { EDRAutocomplete } from "../../../app/common/EDRAutocomplete";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { EDROutlinedInput } from "../../../app/common/EDROutlinedInput";
import ReactDOM from "react-dom";
import { EDRTextField } from "../../../app/common/EDRTextField";
import { useTranslation } from "react-i18next";
import { UpdateType } from "../../../app/stores/activityStore";
import { CONSTS, CredentialUserType } from "../../../app/models/createjob/createJob.model";
import jobAgent from "../../../app/api/jobAgent";
import { debounce } from 'lodash';
import CheckCircle from "@material-ui/icons/CheckCircle";
import { toast } from "react-toastify";

const useStyles = makeStyles(() => ({
  button: {
    height: '100%',
    borderRadius: '4px',
    border: 'solid 1px #c7cfdb',
    backgroundColor: '#fafafc'
  },
  advanced: {
    width: '14px',
    height: '14px',
    objectFit: 'contain',
  },
  formControl: {
    width: '100%',
    '& label': {
      background: "white",
      width: "auto",
      padding: "0 4px 0 4px",
      fontSize: '17px'
    }
  },
  validateIcon: {
    position: 'absolute',
    top: 15,
    right:8,
    color: '#8bc53f'
  }

}));

const WorkflowPrimaryComponent: React.FC = () => {
    
    let labelRef;
    const rootStore = useContext(RootStoreContext);
    const {createJobStore, activityStore,jobSettingsStore,displayStore} = rootStore;
    const isPaaS= createJobStore.baseSettings.dataMover==  DataMover.PaaS;
    const dataMover= createJobStore.baseSettings.dataMover;
    const [windowsPasswordVisibility, setWindowsPasswordVisibility] = useState(true);
    const [showEyeWindowsPassword, setShowEyeWindowsPassword]= useState(true);
    const previousPrimaryUsername = jobSettingsStore.accessCredentials;
    const [primaryAutocomplete,setPrimaryAutocomplete] = useState(false);
    const [t] = useTranslation("createJobBase");
    const [warningMessage, setwarningMessage] = useState('')
    const warningMsgRef= useRef({msg: null, setwarningMessage});
    const [isFormValidating, setisFormValidating] = useState(false);
    const [formValid, setformValid] = useState(true);

    useEffect(() => {
     warningMsgRef.current = {msg: null, setwarningMessage};
    }, [setwarningMessage])
    useEffect(() => {
    form.resetForm();
    },[createJobStore.PlatformChanged]);

    const useStyles = makeStyles(() => ({
      button: {
        height: '100%',
        borderRadius: '4px',
        border: 'solid 1px #c7cfdb',
        backgroundColor: '#fafafc'
      },
      advanced: {
        width: '14px',
        height: '14px',
        objectFit: 'contain',
      },
      formControl: {
        width: '100%',
        '& label': {
          background: "white",
          width: "auto",
          padding: "0 4px 0 4px",
          fontSize: '17px'
        },
        '& .Mui-error': {
          color: 'red',
      },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: 'red'
      }
      },
      formControlusername: {
        width: '100%',
        '& label': {
          background: "white",
          width: "auto",
          padding: "0 4px 0 2px",
          fontSize: '17px'
        },
        '& .Mui-error': {
          color: 'red',
      },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: 'red'
      }
      },
      validateIcon: {
        position: 'absolute',
        top: 33,
        right:0,
        color: '#8bc53f'
      }
    }));
    const classes = useStyles();
    const validateCredentials = async (value, resolve, context) => {
     
      const username = value;
      const password = context.parent.PrimaryPassword
   
    try {
      if (!username || !password) {
       
        resolve(context.createError({message: 'Invalid User !!', path: "PrimaryUser"}));
        return;
      }

      await jobAgent.JobActions.ValidateDomainCredentials({credsId: 0,username: username , password: password}).then(res => {
        if(res === true)
        {
            resolve(true);
        }
        else
        {
           resolve(context.createError({message: 'Invalid User !!', path: "PrimaryUser"}));
        }
      }).catch(err=> {
        if(err && err.data && err.data.errors)
        {
            if(err.data.errors.includes('Not a domain user'))
            {
                resolve(context.createError({message: `Not a domain user`, path: "PrimaryUser"}));
            }
            else if(err.data.errors.includes('The LDAP server is unavailable'))
            {
                resolve(context.createError({message: ` ${err.data.errors}`, path: "PrimaryUser"}));
            }
            else
            {
                resolve(context.createError({message: `${username} - domain validation failed, ${err.data.errors}`, path: "PrimaryUser"}));
            }
        } else {
          resolve(context.createError({message: `${username} - domain validation failed, ${err.data.errors}`, path: "PrimaryUser"}));
        }
      });
      
    } catch (error) {
      resolve(false);
    }
    };

    const credsValidation = debounce(validateCredentials, 800, {
    'leading': false,
    'trailing': true
    });
    useEffect(() => {
      let initialValues ={
              PrimaryUser:createJobStore.baseSettings.workflowTest.PrimaryUser,
              PrimaryPassword:createJobStore.baseSettings.workflowTest.PrimaryPassword,
              accessCredentialsId:createJobStore.baseSettings.workflowTest.PrimaryUserCredsId
      };
      const touched = form.touched;
      form.resetForm({ values: initialValues, touched: {...touched} });

      const onCopyInfraCredentials = () => {
        if(!isPaaS) {
          form.setValues(
          {
              PrimaryUser:createJobStore.baseSettings.workflowTest.PrimaryUser,
              PrimaryPassword:createJobStore.baseSettings.workflowTest.PrimaryPassword
          }, true
          )
        }
      }

      activityStore.on(UpdateType.CopyInfraCredentials, onCopyInfraCredentials);
      return () => {
        activityStore.off(UpdateType.CopyInfraCredentials, onCopyInfraCredentials);
      }
    }, []); 
    
    const validation = yup.object({
      PrimaryUser:yup.string().required('Required')
      .test('empty','username must be not empty',PrimaryUser => PrimaryUser!='')
      .max(50,'up to 50 characters').when('PrimaryPassword',{
        is: p => p,
        then: yup.string().test('validateCredentials', 'validation failure',function (value, context) {
          return new Promise(res => credsValidation(value, res, context))
        })
      }),

      PrimaryPassword:yup.string().required('Required')
      .test('empty','password must be not empty',PrimaryPassword => PrimaryPassword!='')
      .max(50,'up to 50 characters'),
      })
    
    const form = useFormik({
        initialValues: 
        {
          PrimaryUser:'',
          PrimaryPassword:'',
        },
        validateOnChange: true,
        onSubmit: () => { },
      })

      useEffect(() => {
        displayStore.updateNextStepValid({ primaryValid: isFormValidating ? false : formValid });
      }, [formValid, isFormValidating])
    
     useEffect(() => {
       console.log(warningMsgRef.current.msg);
     }, [warningMsgRef.current.msg])

      useEffect(() => {
        let cancelled = false;
        setisFormValidating(true);
       setTimeout(() => {
        if(cancelled)
          return;
          const formValues = form.values;
          validation.validate(formValues, {abortEarly: false}).then(res => {
            if(cancelled)
             return; //early exit
            form.setErrors({});
            setformValid(true);
          }).catch(err => {
            if(cancelled)
              return //early exit
            

            let formikErrors = yupToFormErrors<{PrimaryUser: any, PrimaryPassword: any}>(err);
            if(formikErrors.PrimaryUser) {
              const PrimaryUserError = formikErrors.PrimaryUser as string;
              if(PrimaryUserError.includes('LDAP') || PrimaryUserError.includes('Not a domain')) {
                setwarningMessage(PrimaryUserError);
                delete formikErrors.PrimaryUser;
                setformValid(true);  
              } else{
                setwarningMessage('');
                setformValid(false);  
              }
            } else{
              setformValid(false);  
            }
            form.setErrors(formikErrors);
          }).finally(() => {
             if(!cancelled)
              setisFormValidating(false);
          })
       }, 400)
        return () => {
          cancelled=true;
        }

      }, [form.values])
    
      const setType =()=> {
        if(rootStore.createJobStore.baseSettings.workflowTest.PrimaryUser!='' && rootStore.createJobStore.baseSettings.workflowTest.SecondaryUser!='')
          createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest, Type: MachineType.WindowsLinux}});
        else if(rootStore.createJobStore.baseSettings.workflowTest.PrimaryUser!='' && rootStore.createJobStore.baseSettings.workflowTest.SecondaryUser =='')
          createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest, Type: MachineType.Windows}});
        else
          createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest, Type: MachineType.Linux}});
      };

      const handlePrimaryUserChange = (event:React.ChangeEvent,value:any) => {
        if(event!=null){
        let credentials;
        let onInput= event.type=="click"? false:true;
         setPrimaryAutocomplete(onInput);
        if (value!=null && typeof value === 'object') 
        {
          form.setFieldValue("PrimaryUser",value=="" ? '':value.userName,true);
          form.setFieldValue("PrimaryPassword",'',true);
          createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest, PrimaryUser: value=="" ? '':value.userName,PrimaryPassword:''}});
          let userNoDomain= value == "" ? '':value.userName.split('\\')[0];
          credentials= {userName:userNoDomain ,password:value=="" ? '':value.password};
          rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController,  domainCredentials: credentials}});
        }
        if (typeof value === 'string')
        {
          const userObj = previousPrimaryUsername.find(e => e.userName.toLocaleLowerCase() == value.toLocaleLowerCase());
          form.setFieldValue("PrimaryUser", userObj!=undefined ? value:(value==""?'':value),true);
          let pass = '';
          form.setFieldValue("PrimaryPassword",pass,true);
          createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest, PrimaryUser: value=="" ? '':value,PrimaryPassword:pass}});
          let userNoDomain= value == "" ? '':value.split('\\')[0];
          credentials= {userName:userNoDomain,password:pass,userType:CredentialUserType.GeneralUser};
          rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController,  domainCredentials: credentials}});
        }
        setShowEyeWindowsPassword(onInput);
        if(!onInput)
        {
          setWindowsPasswordVisibility(true);
        }
        setType();
        CheckSectionAndDrawerStatus("workflow");
      }
    };
    
    const handlePrimaryPasswordChange = (event) => {
        setPrimaryAutocomplete(true);
        setShowEyeWindowsPassword(true);
        form.setFieldValue("PrimaryPassword",event.target.value);
        createJobStore.updateBaseSettings({workflowTest: {...createJobStore.baseSettings.workflowTest,PrimaryPassword : event.target.value}});
        // let credentials= {id: createJobStore.networkSetting.domainController.domainCredentials.id,userName: rootStore.createJobStore.networkSetting.domainController.domainCredentials.userName,password:event.target.value,userType:CredentialUserType.GeneralUser};
        rootStore.createJobStore.updateNetworkSettings({domainController: {...rootStore.createJobStore.networkSetting.domainController,  domainCredentials: {...rootStore.createJobStore.networkSetting.domainController.domainCredentials, password: event.target.value}}});
        CheckSectionAndDrawerStatus("workflow");
    };

    const handleMouseClick =()=>{
      if(form.values.PrimaryPassword === CONSTS.MaskedPassword)
      {
          form.setFieldValue("PrimaryPassword",'',true);
      }
  }

  const handleMouseOut =()=>{
      if(form.dirty && form.values.PrimaryPassword == '')
      {
        if(form.values.PrimaryUser!='')
        {
          return;
        }
        else form.setFieldValue("PrimaryPassword",CONSTS.MaskedPassword,true);
      }
  }

    const handleClickShowPassword  = () => {
        setWindowsPasswordVisibility(windowsPasswordVisibility ? false : true); 
    };

    const CheckSectionAndDrawerStatus= (section:string) =>{
        switch(section)
        {
          case 'EDRServerAdvanced':
            {
              if(displayStore.screenSettings.displayBaseSettings=='InfrastructureAdvanced' || displayStore.screenSettings.displayBaseSettings=='DataMoverAdvanced' ||
              displayStore.screenSettings.displayBaseSettings=='InfrastructureCreateAdvanced' || displayStore.screenSettings.displayBaseSettings=='DataMoverCreateAdvanced' || 
              displayStore.screenSettings.displayBaseSettings=='WorkflowAdvanced')
              {
                  displayStore.updateScreenSettings({displayBaseSettings: 'EmptyVlan'});
                  displayStore.updateBaseDrawerSettings({InfraDrawerOpen: false});
                  displayStore.updateBaseDrawerSettings({SecondaryUserDrawerOpen: false});
                  displayStore.updateBaseDrawerSettings({DataMoverDrawerOpen: false});
              } 
            }
        }
    }

    const loadPrimaryUsername= ()=>{
      return (
      <Tooltip title={t('domainUserPassInfo')} placement="top">
      <FormControl className={classes.formControlusername} variant='outlined'> 
      <EDRAutocomplete id="PrimaryUser"
        freeSolo
        disableClearable
        value={form.values && form.values.PrimaryUser}
        options={previousPrimaryUsername}
        renderOption={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
	      getOptionLabel={(option: string|{userName: string,password:string}) => (typeof option === 'string' ? option : `${option.userName}`)}
        onChange={handlePrimaryUserChange}
        onInputChange={handlePrimaryUserChange}
        onBlur={()=>{
          form.setFieldTouched("PrimaryUser", true)
        }}
        renderInput={(params) => (<EDRTextField {...params}
        label= {t(dataMover=== DataMover.VeeamSQL? 'workflowUser': 'windowsUsername')}
       // style={{backgroundColor:(displayStore.sectionSettings.WorkflowSection ? 'rgb(233, 235, 238)': '#ffffff')}}
        InputLabelProps={{shrink: true}} 
        margin="normal" 
        variant="outlined"
        placeholder="Select/ Create New" 
        aria-autocomplete="none"
        error={form.touched && form.touched.PrimaryUser && form.errors && Boolean(form.errors.PrimaryUser)}
        helperText={form.touched && form.touched.PrimaryUser && form.errors && Boolean(form.errors.PrimaryUser) && t(form.errors.PrimaryUser)}
        />)}
      />
      <React.Fragment>{!isFormValidating && formValid && <CheckCircle className={classes.validateIcon} />}</React.Fragment>
      </FormControl>
      </Tooltip>
);
    }

    const loadPrimaryPassword=()=>{
    return(<Tooltip title={t('domainUserPassInfo')} placement="top">
    <FormControl className={classes.formControl} variant='outlined'>
        <InputLabel ref={ref => {labelRef = ReactDOM.findDOMNode(ref)}}  style={{transform: 'translate(14px, 10px) scale(0.75)',color:(form.touched && form.touched.PrimaryPassword && form.errors &&  Boolean(form.errors.PrimaryPassword)) ?'red':''}}>{t(dataMover=== DataMover.VeeamSQL?'WorkflowPassword':'windowsPass')}</InputLabel>
        <EDROutlinedInput id="PrimaryPassword" className="MuiFormControl-marginNormal"
            value={form.values.PrimaryPassword}
            labelWidth={labelRef ? labelRef.offsetWidth:0}
            autoComplete="off"
            type={windowsPasswordVisibility ? 'password':'text'}
            placeholder="Type Here"
           // style={{backgroundColor:(displayStore.sectionSettings.WorkflowSection? 'rgb(233, 235, 238)': '#ffffff')}}
            onChange={handlePrimaryPasswordChange}
            onClick={handleMouseClick}
            onBlur={handleMouseOut}
            endAdornment={form.values.PrimaryPassword && primaryAutocomplete ?
            <InputAdornment position="end">
            <IconButton
            aria-label="toggle password visibility"
            aria-autocomplete="none"
            onClick={()=>handleClickShowPassword()}
            >
            {windowsPasswordVisibility ?  <VisibilityOff />: <Visibility />}
        </IconButton>
</InputAdornment>
 :null }
/>  
<FormHelperText id="helperTextPrimaryPassword" style={{color:'red',marginTop:'0px'}}>{form.touched && form.touched.PrimaryPassword && form.errors &&  Boolean(form.errors.PrimaryPassword) &&t(form.errors.PrimaryPassword)}</FormHelperText>
{/* <React.Fragment>{!form.isValidating && displayStore.nextStepValid.primaryValid && <CheckCircle className={classes.validateIcon} />}</React.Fragment> */}
<React.Fragment>{!isFormValidating && formValid && <CheckCircle className={classes.validateIcon} />}</React.Fragment>
</FormControl>
</Tooltip>
    )
    }

return(
  <React.Fragment>
    {!isPaaS && 
    <Grid container spacing={2} style={{marginTop: 5}}>
          <Grid item xs={6} style={{position: 'relative'}}> 
          <span style={{position: "absolute", color: 'red', top: -2}}>{ isFormValidating ? 'Validating...': warningMessage}</span>
            {loadPrimaryUsername()}
          </Grid>
          <Grid item xs={6}>
            {loadPrimaryPassword()}
          </Grid>
    </Grid>}
  </React.Fragment>

);
};

export default observer(WorkflowPrimaryComponent); 