import { Card, Grid, makeStyles, Typography} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export interface EmptyProps {
    title: string;
    type:string;
}


const EmptyDevicesComponent: React.FC<EmptyProps> = (props) => {

    const useStyles = makeStyles({
        SecondHeading:{
            color:'black',
            fontFamily:'Roboto',
            fontWeight:500,
            fontSize:'100%',
            fontStretch:'normal',
            fontStyle: 'normal',
            lineHeight:'1.14',
            letterSpacing:'normal',
            textAlign:'left'
        },
        card: {
            padding: 16,
            width: '100%',
            height: '100%'
        },
        advanced:{
            width: '70px',
            height: '40px',
            objectFit: 'contain',
            marginTop: '15px'
        },
        circle:{
            width: '120px',
            height: '120px',
            padding: '24px',
            marginTop: '85%',
            marginLeft: '80px',
            borderRadius: '50%',
            backgroundColor: '#fafafc'
        }
        });
        
    const classes = useStyles();
    const [t] = useTranslation("createJobDevices");
    const { title,type} = props;

    return(
             <Grid container style={{display: 'flex', flexDirection: 'row',justifyContent: 'center' , alignItems: 'center'}}>
                {type =="allIncluded" ?
                <Grid item>
                    <div className={classes.circle} style={{marginTop:'25%',marginLeft:'40%'}}><img className={classes.advanced} src='/assets/images/createjob/cube.svg'></img></div>
                    <Typography variant='h6' className={classes.SecondHeading} style={{marginTop:'25px'}}>{title}</Typography>
                    <Typography variant='h6' className={classes.SecondHeading} style={{marginTop:'10px'}}>{t('allIncludedPart2')}</Typography>
                    <Typography variant='h6' className={classes.SecondHeading} style={{marginTop:'10px'}}>{ t('allIncludedPart3')}</Typography>
                </Grid>
                : 
                <Grid item>
                 <div className={classes.circle} style={{marginTop: type =="selected" ? '85%':'34%',marginLeft:type =="selected" ?'80px':'40%'}}><img className={classes.advanced} src='/assets/images/createjob/cube.svg'></img></div>
                    <Typography variant='h6' className={classes.SecondHeading} style={{marginTop: type =="selected" ? '15px':'3%',marginLeft:type =="selected" ?'18px':'16%',marginRight:type =="selected" ?'0px':'10%',whiteSpace: type =="devices" ?'pre-line':null}}>{title}</Typography>
                    {type=="devices" ? <Typography variant='h6' className={classes.SecondHeading} style={{fontWeight:400 ,marginTop:'3%',marginLeft:'16%',marginRight:'10%',whiteSpace:'pre-line'}}>{t('emptyDevicesInfo')}</Typography>:null}
                 </Grid>}
             </Grid> 
    );
};

export default observer(EmptyDevicesComponent);