import { observable, computed, action, runInAction, when, reaction } from "mobx";
import agentBase from '../api/agentBase';
import { RootStore } from './root.store';
import { history } from '../common/util/history';
import { CreateJobProperties, CredentialUserType, DeviceTest, GlobalTest, newJobObj, Plan, PrePostScriptType } from "../models/createjob/createJob.model";
import { BootCaptureType, DnsResolveType, InfrastructureType, JobBaseSettings, JobNetworkSetting, JobReportSetting, JobScheduleSetting, JobType, MachineType, NetworkOption, NetworkSetting, NetworkType, ScheduleRepeatType, TestGroups } from "../models/jobProperties.model";
import { JobDevice } from "../models/jobDevice/jobDevice.model";
import { DataMover, VeeamSolution } from "../models/BaseSettings.enum";
import jobAgent from "../api/jobAgent";
import { settingObj } from "../models/setting.model";
import moment from "moment";
import { useContext } from "react";
import { CreateJobHelper } from "./createjob.helper";
import { JobEdit } from "../models/jobactions/jobEdit.model";
import { RSA_NO_PADDING } from "constants";
import { TestType } from "../models/jobTest.model";
import DraftsAgent from "../api/draftsAgent";
import { toast } from "react-toastify";

const AZURE_DATAMOVERS = [DataMover.ASR, DataMover.ASRVmwareAzure, DataMover.IaaS, DataMover.PaaS, DataMover.ZertoAzure, DataMover.ZertoVmwareAzure]
const AWS_DATAMOVERS = [DataMover.AWSDRS, DataMover.VmwareAWS];
function isDRActiveByDefault(datamover:DataMover): boolean {
    switch(datamover) {
        case DataMover.VeeamSQL: return false;
        case DataMover.PaaS: return false;
        default: return true;
    }
} 

function getDefaultPort(datamover: DataMover) {
    switch(datamover) {
        case DataMover.ZertoVmWare: return 9669;
        case DataMover.SRM: return 443;
        case DataMover.VeeamVmware: return 9392;
        default: return 0;
    }
}

function InitDefaultDate (){
    const currentDate= new Date();
    var todayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
    var momentVariable = moment(todayDate,'YYYY-MM-DDTHH:mm');  
    return momentVariable.format('YYYY-MM-DDTHH:mm');   
}
export default class CreateJobStore {
    rootStore: RootStore;
    constructor(rootStore:RootStore) {
        this.rootStore = rootStore;
    }


    @observable _supportedAdapters = [];

    @observable showCreateJobIcon = true;

    @observable ConfigurationDialogOpen = false;

    @observable _platformChanged = false;
    @observable _draftFinished = false;
    
    @observable createJobBase: Array<newJobObj> = [];

    @observable private _baseSettings: JobBaseSettings = {
        id:0,
        jobId: 0,
        jobName: '',
        jobType: JobType.Test,
        dataMover: DataMover.SRM,
        veeamSolution: VeeamSolution.Backup,
        infrastructureType: InfrastructureType.VMware,
        licenseId:0,
        originalLicenseId:0,
        licenseName:'',
        infrastructureId:0,
        dataMoverId:0,
        rto: 60,
        powerOnTime:120,
        parallelTestExecution:30,
        maxTestTimeout:300,
        timeEngineFinish:3000,
        failoverTimeout:7200,
        testHeartbeat:true,
        fetchByDefault:true,
        vra: '',
        veeamApiPort: 9392,
        planNameFilter: [],
        scriptsSettings: {
            failoverScriptSetting:{
                preId:0,
                postId:0,
                prePath:'',
                prePathData:null,
                postPath:'',
                postPathData:null,
                preTTL:15,
                postTTL:15,
                type: PrePostScriptType.EDRServer
            },
            cleanupScriptSetting :{
                preId:0,
                postId:0,
                prePath:'',
                prePathData:null,
                postPath:'',
                postPathData:null,
                preTTL:15,
                postTTL:15,
                type: PrePostScriptType.EDRServer  
            },
            TestScriptSetting:{
                preId:0,
                postId:0,
                prePath:'',
                prePathData:null,
                postPath:'',
                postPathData:null,
                preTTL:15,
                postTTL:15,
                type: PrePostScriptType.ControllerServer
            }
        },
        workflowSetting: {
            preTest: true,
            failover: true,
            serverTest: true,
            snapshot: true,
            cleanup: true,
        },
        workflowTest: {
            Type: MachineType.Windows,
            PrimaryUser: '',
            PrimaryPassword: '',
            SecondaryUser: '',
            SecondaryPassword: '',
            PrimaryUserCredsId:0,
            SecondaryUserCredsId:0
        },
        testGroups: {
            id: 0,
            groups:
            [
                {groupOrder: 1,groupName:"Group1"},
                {groupOrder: 2,groupName:"Group2"},
                {groupOrder: 3,groupName:"Group3"},
                {groupOrder: 4,groupName:"Group4"},
                {groupOrder: 5,groupName:"Group5"},
                {groupOrder: 6,groupName:"Group6"},
                {groupOrder: 7,groupName:"Group7"}
            ]
        },
        networkTuppleId: 0
    };

    @observable private _networkSettings: JobNetworkSetting= {
        EnsureDRController: {
            assetName:'',
            assetIP: '',
            assetVMName: '',
            credentials:{
                id: 0,
                userName:'',
                password:'',
                userType: CredentialUserType.GeneralUser 
            },
            id:null
        },
        domainController: {
            domainControllerName:'',
            dcHostServerSource:'',
            dcHostServerTarget:'',
            dcDataStoreTarget:'',
            domainControllerTargetVLAN:'',
            domainName:'',
            domainCredentialsId:0,
            domainCredentials:{
                id: 0,
                userName:'',
                password:'',
                userType: CredentialUserType.GeneralUser },
            domainControllerESXCredentialsId:0,
            domainControllerESXCredentials:{
                id: 0,
                userName:'',
                password:'',
                userType: CredentialUserType.ESXUser },
            id:null
        },
        EDRServer : {
            assetName:'',
            assetIP: '',
            assetVMName: '',
            credentials:{
                id: 0,
                userName:'',
                password:'',
                userType: CredentialUserType.GeneralUser 
            },
            id:null
        },
        liveDRNetwork: {
            type: NetworkOption.Single,
            networkMapping: [{sourceNetwork:'',targetNetwork:''}],
            allowInbubble:false,
            allowOutbubble:false
        },
        testDRNetwork: {
            type: NetworkOption.Single,
            networkMapping: [{sourceNetwork:'',targetNetwork:''}],
            allowInbubble:false,
            allowOutbubble:false
        },
        inPort: 5876,
        outPort: 5876,
        NetworkType:NetworkOption.Single,
        dnsResolve: DnsResolveType.None,
        additionalEdrcIp :['0.0.0.0'],
        additionalEdrcDns :['0.0.0.0'],
        additionalDefaultGateway :['0.0.0.0'],
    };

    @observable private _jobReportSettings: JobReportSetting = {
        emailTo: null,
        phoneNumbersForSMS: null,
        sendEmail: false,
        sendSMS: true,
        bootCaptureType: BootCaptureType.Animation,
        bootTime: 40
        
    }

    @observable private _jobScheduleSettings: JobScheduleSetting = {
        nextRun: InitDefaultDate(),
        scheduleRepeatType: ScheduleRepeatType.Week,
        repeatInterval: 1,
        setSchedule: true,
        setRepeat:true
    }

    @observable private _ICTestActive : boolean = this._baseSettings.dataMover !== DataMover.PaaS ?true:false;

    @observable private _drResTestActive :boolean =  isDRActiveByDefault(this._baseSettings.dataMover);

    @observable private _selectedDevices: Array<JobDevice> = [];

    @observable private _jobDevicesCounter: number = 0;

    @observable private _plans : Array<Plan> =[];

    //@observable private _testGroups : TestGroups;

    @observable private _editedDevice : JobDevice;

    @observable private _customDefaultScript : any;
    
    @observable private _customDefaultScriptLinux : any;

    @observable private _tempDeviceData : JobDevice;
    @observable private _draftId : number;

    @computed
    get isAzure(): boolean {
        return AZURE_DATAMOVERS.indexOf(this.baseSettings.dataMover) >= 0;
    }

    @computed
    get isAWS(): boolean {
        return AWS_DATAMOVERS.indexOf(this.baseSettings.dataMover) >= 0;
    }

    @action updatePlatformChanged(state: boolean){
       this._platformChanged = state;
    }

    @action updateNewJobObj(key,value){
        this.createJobBase.push({createJobKey:key, createJobValue:value});
    }

    @action setOpen = (state: boolean) => {
        this.ConfigurationDialogOpen = state;
    }

    @action setShowCreateJobIcon = (state: boolean) => {
        this.showCreateJobIcon = state;
    }
    @action setDraftId = (draftId: number) => {
        this._draftId = draftId;
    }
    @action updateDraft = (step: number) => {
        const{emailSettings}= this.rootStore.settingStore;
        const jobProperties = new CreateJobProperties();
        runInAction(() =>{
            jobProperties.addBaseSettings(this.baseSettings,this.networkSetting)
            .addDevices(this.selectedDevices,this._plans,true)
            .addNetworkInfo(this.baseSettings,this.networkSetting)
            .addReportSettings(this.jobReportSetting,emailSettings)
            .addScheduleSettings(this.jobScheduleSetting)
            .addGeneralSettings(this.TestGroups,this.DrResTestActive,this.ICTestActive);
            DraftsAgent.Edit({id: this._draftId,jobProperties:jobProperties,jobStep: step}).then(res => {
                toast.success('Draft updated Successfully')
            }).catch(err => {
                toast.error('Failed to update Draft')
            })
        })
        
    }
    @action setFinishDraft = () => {
        this._draftFinished = !this._draftFinished
        DraftsAgent.Delete(this._draftId);
        this.setDraftId(0);
    }
    @computed
    get supportedAdapters(): Array<string> {
        // logic for existing and new customers and if their adapter supported for edit and create
        return this._supportedAdapters;
    }

    @computed
    get baseSettings(): JobBaseSettings {
        return this._baseSettings;
    }

    @computed
    get plans(): Array<Plan> {
        return this._plans;
    }

    @computed
    get PlatformChanged(): boolean {
        return this._platformChanged;
    }

    @computed
    get networkSetting(): JobNetworkSetting {
        return this._networkSettings;
    }

    @computed
    get selectedDevices(): Array<JobDevice> {
        return this._selectedDevices
    }

    @computed
    get jobDevicesCounter(): number {
        return this._jobDevicesCounter;
    }

    @computed
    get editedDevice(): JobDevice {
        return this._editedDevice
    }

    @computed
    get customDefaultScript(): any {
        return this._customDefaultScript;
    }

    @computed
    get customDefaultScriptLinux(): any {
        return this._customDefaultScriptLinux;
    }

    @computed
    get jobReportSetting(): JobReportSetting {
        return this._jobReportSettings;
    }

    @computed
    get jobScheduleSetting(): JobScheduleSetting {
        return this._jobScheduleSettings;
    }

    @computed
    get TestGroups(): TestGroups {
        return this._baseSettings.testGroups;
    }

    @computed
    get  TempDataDevice(): JobDevice {
        return this._tempDeviceData;
    }

    @computed
    get ICTestActive(): boolean {
        return this._ICTestActive;
    }
    @computed
    get isDraft(): boolean {
        return this._draftId && this._draftId != 0;
    }
    @computed
    get DrResTestActive():boolean {
        return this._drResTestActive;
    }

    @action
    updateBaseSettings(obj: Partial<JobBaseSettings>) {
        this._baseSettings = {...this._baseSettings, ...obj};
    }

    @action
    updateEditedDevice(obj: JobDevice) {
        this._editedDevice = obj;
    }

    @action
    updatePlans(plans: any) {
        this._plans = plans;
    }

    @action
    updateCustomDefaultScript(obj: any) {
        this._customDefaultScript = obj;
    }

    @action
    updateCustomDefaultScriptLinux(obj: any) {
        this._customDefaultScriptLinux = obj;
    }

    @action
    updateNetworkSettings(obj: Partial<JobNetworkSetting>) {
        this._networkSettings = {...this._networkSettings, ...obj};
    }

    @action
    updateSelectedDevices(devices: Array<JobDevice>) {
        this._selectedDevices = [...devices];
    }

    @action
    updateDevice(device: JobDevice) {
        let deviceToUpdate = this._selectedDevices.find(selected=>selected.deviceName== device.deviceName)
        let indexofDevice = this._selectedDevices.indexOf(deviceToUpdate);
        this._selectedDevices[indexofDevice]= device;
    }

    @action
    updateTempDataDevice(device: JobDevice) {
        this._tempDeviceData = device;
    }

    @action
    updateTestDevice(type:TestType,deviceTest: DeviceTest,deviceName:string) {
        let deviceToUpdate = this._selectedDevices.find(selected=> selected.planName.includes("Custom Devices") ? selected.sourceName.toLocaleLowerCase()== deviceName.toLocaleLowerCase() : selected.deviceName.toLocaleLowerCase()== deviceName.toLocaleLowerCase());
        let test = deviceToUpdate.tests.find(test=>test.testType === type);
        deviceToUpdate.tests =  deviceToUpdate.tests.map(t => t == test ? {...deviceTest}: t);
       this.updateSelectedDevices(this.selectedDevices.map(d => d.deviceName == deviceToUpdate.deviceName ? deviceToUpdate : d));
    }

    @action
    updatePlanOrder(planName:string,location:number) {
        let planToUpdate = this._plans.find(plan=>plan.name == planName);
        let indexofPlan = this._plans.indexOf(planToUpdate);
        this._plans[indexofPlan].planBootOrder = location;
    }

    @action
    updateICTestStatus(IsActive:boolean) {
        this._ICTestActive = IsActive;
    }
    @action
    updateDRResTestStatus(IsActive:boolean) {
        this._drResTestActive = IsActive;
    }

    @action
    updateTestGroups(testGroups: Array<any>) {
        let newArr=[];
        testGroups.map((item,idx)=>{
            item == testGroups[idx] ? newArr.push(item): newArr.push({groupOrder: testGroups[idx].groupOrder,groupName: testGroups[idx].groupName})
        });
        this._baseSettings.testGroups.groups = newArr;
    }

    @action
    updateJobReportSetting(obj: Partial<JobReportSetting>) {
        this._jobReportSettings = {...this._jobReportSettings, ...obj};
    }

    @action
    updateJobScheduleSetting(obj: Partial<JobScheduleSetting>) {
        this._jobScheduleSettings = {...this._jobScheduleSettings, ...obj};
    }

    createJob(runJob:boolean): Promise<any> {
        let isEdit = window.location.href.includes('editJob');
        const{emailSettings}= this.rootStore.settingStore;
        const jobProperties = new CreateJobProperties();
        jobProperties.addRunJob(runJob);
        jobProperties.addBaseSettings(this.baseSettings,this.networkSetting)
        .addDevices(this.selectedDevices,this._plans,isEdit)
        .addNetworkInfo(this.baseSettings,this.networkSetting)
        .addReportSettings(this.jobReportSetting,emailSettings)
        .addScheduleSettings(this.jobScheduleSetting)
        .addGeneralSettings(this.TestGroups,this.DrResTestActive,this.ICTestActive);
        if(isEdit)
        {
            jobProperties.id= this.baseSettings.id;
            jobProperties.jobId=parseInt((history.location.pathname.split("/")[2]));
            return jobAgent.JobActions.editJob(jobProperties);
        }
        else
            return jobAgent.JobActions.createJob(jobProperties);
    }

    @action
    async getJob(jobId:number, fromJobPreview:boolean):Promise<any> {
        this.rootStore.displayStore.reset();
      return  jobAgent.JobActions.getJob(jobId).then(res => {
            runInAction(() => {
                this._baseSettings= CreateJobHelper.getBaseSettings(this.baseSettings,res);
                this._networkSettings = CreateJobHelper.getNetworkInfo(this.baseSettings,this.networkSetting,res);
                this._selectedDevices= CreateJobHelper.getDevices(res);
                this._jobDevicesCounter = this._selectedDevices?.length | 0;
                this._plans = res.plans;
                this._drResTestActive = res.IsCollectDRResourcesActive;
                this._ICTestActive = res.IsICTestActive;
                this._jobReportSettings= CreateJobHelper.getReportSettings(this.jobReportSetting,this.rootStore.settingStore.emailSettings,res);
                this._jobScheduleSettings= CreateJobHelper.getScheduleSettings(this.jobScheduleSetting,res);
                this.rootStore.jobSettingsStore.getInfras();
                this.rootStore.jobSettingsStore.getCredentials();
                this.rootStore.jobSettingsStore.getLicenese();
                if(fromJobPreview)
                    history.push('/editJob/'+jobId);
            })
            });
    }
    @action
    async getNewJob(platform:any):Promise<any> {
        this.rootStore.displayStore.reset();
        await jobAgent.JobActions.getNewJob(platform).then(res => {
            if(res)
            {
                runInAction(() => {
                    this._baseSettings= CreateJobHelper.getBaseSettingsNew(this.baseSettings,res);
                    this._networkSettings = CreateJobHelper.getNetworkInfo(this.baseSettings,this.networkSetting,res);
                    this._drResTestActive = isDRActiveByDefault(res.dataMoverType);
                    this._jobReportSettings = CreateJobHelper.getReportSettings(this.jobReportSetting, [],res);
                    this._ICTestActive = res.IsICTestActive;
                    if(res.edrControllerServerId == 0)
                        this.updateNetworkSettings({EnsureDRController: null})
                    if(res.edrServerId == 0)
                        this.updateNetworkSettings({EDRServer: null})
                    if(res.domainControllerId == 0)
                        this.updateNetworkSettings({domainController: null})
                    this._jobDevicesCounter = this._selectedDevices?.length | 0;
                })
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            history.push('/createJob?fromPlatform=1');
        });
    }
    @action
    async getDraft(draftId:number):Promise<any> {
        this.rootStore.displayStore.resetStep(1);
        this.rootStore.displayStore.reset();
        this.rootStore.createJobStore.setDraftId(draftId);
        DraftsAgent.Details(draftId).then(res => {
            runInAction(() => {
                this._baseSettings= CreateJobHelper.getBaseSettings(this.baseSettings,res.data.jobProperties);
                this._networkSettings = CreateJobHelper.getNetworkInfo(this.baseSettings,this.networkSetting,res.data.jobProperties);
                this._selectedDevices= CreateJobHelper.getDevices(res.data.jobProperties);
                this._plans = res.data.jobProperties.plans;
                this._drResTestActive=res.data.jobProperties.IsCollectDRResourcesActive;
                this._ICTestActive = res.data.jobProperties.IsICTestActive;
                this._jobReportSettings= CreateJobHelper.getReportSettings(this.jobReportSetting,this.rootStore.settingStore.emailSettings,res.data.jobProperties);
                this._jobScheduleSettings= CreateJobHelper.getScheduleSettings(this.jobScheduleSetting,res.data.jobProperties);
                this.rootStore.jobSettingsStore.getInfras();
                this.rootStore.jobSettingsStore.getCredentials();
                this.rootStore.jobSettingsStore.getLicenese();
                this.rootStore.displayStore.resetStep(0);
                history.push('/createJob');
                })
        }).catch(error => {
            console.log(error);
        });
    }
    @action
    reset(): void {
        this._baseSettings = CreateJobHelper.getDefaultBaseSetting(this.rootStore.createJobStore.baseSettings);
        this._networkSettings = CreateJobHelper.getDefaultNetworkSetting();
        this._selectedDevices= CreateJobHelper.getDefaultSelectedDevices();
        this._jobReportSettings= CreateJobHelper.getDefaultJobReportSetting();
        this._jobScheduleSettings= CreateJobHelper.getDefaultJobScheduleSetting();
        //cancel all request axios 
    }
}

