
import { observable, reaction, runInAction, action, computed } from "mobx";
import JobResultsApi from "../api/jobResult.api";
import { JobResultTimeline } from "../models/JobResultTimeline.model";
import { JobTestOverview } from "../models/JobTestOverview.model";
import { ReportData } from "../models/reportdata.model";
import { PaginatedResponse } from "../models/paginatedResponse.model";
import { JobNamesGroupByJob } from "../models/jobNamesGroupByJob.model";
import { JobSummaryGroupByJob } from "../models/jobSummaryGroupByJob.model";
import CreateJobStore from "./createJob.store";
import { RootStore } from "./root.store";
import { JobProperties } from "../models/jobProperties.model";
import { CreateJobProperties } from "../models/createjob/createJob.model";
import { CancelablePromise } from "../common/util/cancellablePromise";


export class ReportStore {
    @observable JobId: number;
    @observable selectedJobSummaryId: number
    @observable selectedJobResult: ReportData;
    @observable timeline: PaginatedResponse<Array<JobResultTimeline>>
    @observable itemsLoading: number = 0;
    @observable jobTestOverview: JobTestOverview = {
        powerOn: { failed: 0, passed: 0 },
        network: { failed: 0, passed: 0 },
        service: { failed: 0, passed: 0 },
        custom: { failed: 0, passed: 0 },
        database: { failed: 0, passed: 0 },
        web: { failed: 0, passed: 0 },
        prescript: {failed: 0, passed: 0},
        thirdDevice : {failed :0,passed: 0},
        cyber: {failed: 0 ,passed: 0},
        autoFix: 0
    }
    @observable jobList: Array<JobSummaryGroupByJob>;
    rootStore: RootStore;
    
    constructor(rootStore:RootStore) {
        this.rootStore=rootStore;
        // fetch job summary result
        reaction(() => this.selectedJobSummaryId, () => {
            this.fetchJobReport(this.selectedJobSummaryId);
            this.fetchJobTestOverview(this.selectedJobSummaryId);
        });
    }

    @computed
    get isLoading(): boolean {
        return this.itemsLoading > 0 ? true : false;
    }

    @action
    async fetchJobReport(jobResultId: number) {
        this.itemsLoading += 1;
        try {
            const res = await JobResultsApi.getReport(jobResultId);
            runInAction(() => {
                this.selectedJobResult = res;
            })
            return res;
        }
        catch {
            
        } finally {
            runInAction(() => {
                this.itemsLoading -= 1;
            })
        }

    }

    @action
    fetchJobTestOverview(jobResultId: number) {
        this.itemsLoading += 1;
        JobResultsApi.getJobTestOverview(jobResultId).then(res => {
            runInAction(() => {
                this.jobTestOverview = res;
            })
        }).catch(err => { })
            .finally(() => {
                runInAction(() => {
                    this.itemsLoading -= 1;
                })
            });
    }

    @action
    fetchTimeline(page: number, before?: string): Promise<PaginatedResponse<Array<JobResultTimeline>>> {
        return JobResultsApi.getJobResultTimeline(this.JobId, before, page).then(res => {
            runInAction(() => {
                if(res.records.length > 0)
                    this.timeline = res;
            });

            return res;
        })
    }


    @action
    setSelectedJobSummaryId(id: number) {
        this.selectedJobSummaryId = id;
    }

    @action
    setSelectedJobId(id: number) {
        this.JobId = id;
    }

    @action 
    fetchJobList(): CancelablePromise<JobSummaryGroupByJob[]> {
        this.itemsLoading += 1;
        const p= JobResultsApi.getGroupByJob();
        p.then(res => {
            runInAction(() => {
                this.jobList = res;
            });
            return res;
         }).finally(() => {
             runInAction(() => {
                 this.itemsLoading -= 1;
             })
         });
        return p;

    }

}