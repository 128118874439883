import React from 'react'
import { Doughnut } from 'react-chartjs-2';

import DoughnutBackgroundFillPlugin from '../../app/common/chartjsPlgins/doughnutBackgroundFill.plugin';
import RoundedDoughnutArc from '../../app/common/chartjsPlgins/roundedDoughnutArc.plugin';
const defaultPlugins =[DoughnutBackgroundFillPlugin, RoundedDoughnutArc];
const RecoveryReadinessChart: React.FC<{ score: number, height?: number, width?: number, cutoutPercentage?: number, color?: string, plugins?: Array<Chart.PluginServiceRegistrationOptions> }> = (props) => {
const {width, height} = props;
    const chartConfig = {
        datasets: [{
            data: [props.score, 100 - props.score],
            backgroundColor: [
                props.color || '#2892d7',
                'rgba(0,0,0,0)'
            ]
        }]
    };

    
    let dimensions = {}
    if(width || height) {
        dimensions = {width, height};
    }

    return <div style={{position: "relative", maxWidth: "100%", height: '100%'}}>
    <Doughnut 
        {...dimensions}
        redraw={false} data={chartConfig} options={{
        title: { display: false },
        hover: { mode: null },
        elements: {
            arc: {
                borderWidth: 0,
                
            }
        },
        events: [],
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false }, cutoutPercentage: props.cutoutPercentage || 80, layout: { padding: 0 }, tooltips: { enabled: false }
    }}
        plugins={props.plugins || defaultPlugins} />
        </div>


}

export default RecoveryReadinessChart;