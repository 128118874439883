import  SvgIcon  from "@material-ui/core/SvgIcon";
import React from "react"
import {ReactComponent as PowerSvg} from './svgs/poweron.svg';

const PowerIcon: React.FC<React.HTMLProps<SVGElement>> = (props) => {
    const {style, className} = props;
    return <SvgIcon viewBox={"0,0, 20, 20"}  className={className} style={style} component={PowerSvg}>
    </SvgIcon>
}


export default PowerIcon;