import React, { useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, makeStyles, Tooltip, Typography,FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { RootStoreContext } from '../../../../app/stores/root.store';
import ReactDOM from 'react-dom';
import { DataMover, VeeamSolution } from '../../../../app/models/BaseSettings.enum';
import { EDROutlinedInput } from "../../../../app/common/EDROutlinedInput";
import { EDRAutocomplete } from "../../../../app/common/EDRAutocomplete";
import { EDRTextField } from "../../../../app/common/EDRTextField";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { UpdateType } from '../../../../app/stores/activityStore';
import HelpIcon from '@material-ui/icons/Help';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useLabels } from '../hooks/useDatamoverLabels';
import IfCondition from '../../../../app/common/util/if-condition';
import { setDatamoverDefaultPort } from '../../../../app/stores/jobSettings.store';
import { CustomCheckbox } from './BasicAdvanced.component';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

const DataMoverCreateAdvancedComponent: React.FC = () => {
    let sourcelabelRef;
    let targetlabelRef;
    const useStyles = makeStyles({
        helpIcon:
        {
            color: '#1976d2',
            fontSize: "large"
        },
        formControl: {
            width: '100%',
            height: '70px',
            '& label': {
                background: "white",
                width: "auto",
                padding: "0 4px 0 4px",
                fontSize: '17px',
            }
        },
        MainHeading:
        {
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.19',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#1f396d',
            marginLeft: '10px'
        },
        SecondHeading: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1
        },
        divider: {
            backgroundColor: '#eaeef4',
            width: '290%',
            margin: '0',
            marginTop: '10px',
            marginLeft: '10px',
        },
        button: {
            borderRadius: 15,
            color: 'white',
            backgroundColor: '#2892d7',
            width: '23%',
            height: '32px',
            marginTop: '24px',
            marginLeft: '485px'
        }
    });

    const classes = useStyles();
    const [t] = useTranslation("createJobBase");
    const rootStore = useContext(RootStoreContext);
    const { activityStore,jobSettingsStore,createJobStore } = rootStore;
    const isPaaS = rootStore.createJobStore.baseSettings.dataMover == DataMover.PaaS;
    const {dataMover} = rootStore.createJobStore.baseSettings;
    const [sourcepasswordVisibility, setSourcePasswordVisibility] = useState(true);
    const [showEyeSourcePassword, setShowEyeSourcePassword] = useState(true);
    const [toggle, setToggle] = useState("backup");
    const labels = useLabels(dataMover);
    const getPreviousSourceUsername = jobSettingsStore.sourceUserNamesAndPass;
    const portRegex = /^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/;

    const validation = yup.object({
        fetchByDefault:yup.boolean(),
        SourceUsername: yup.string(),

        SourcePassword: yup.string(),

        SourcePort: yup.string()
            .max(6, 'port max length is 5')
            .matches(portRegex, "port is invalid"),

        TargetPort: yup.string()
            .max(6, 'port max length is 5')
            .matches(portRegex, "port is invalid"),
        VeeamApiPort: yup.string().when("datamover", {
                is: v => v== DataMover.VeeamVmware,
                then: yup.string().required(),
                otherwise: yup.string()
            })
    })

    const form = useFormik({
        initialValues:
        {   datamover: dataMover,
            SourceUsername: '',
            SourcePassword: '',
            SourcePort: jobSettingsStore.tempDataMover.sourcePort,
            TargetPort: jobSettingsStore.tempDataMover.targetPort,
            VeeamApiPort: createJobStore.baseSettings.veeamApiPort,
            fetchByDefault:createJobStore.baseSettings.dataMover == DataMover.VeeamSQL ? false: true
        },
        validationSchema: validation,
        onSubmit: () => { },
    })

    useEffect(() => {
        let initialValues;
        if (isPaaS)
            initialValues =
            {datamover: dataMover,
                SourceUsername: '',
                SourcePassword: '',
                SourcePort: jobSettingsStore.tempDataMover.sourcePort,
                TargetPort: jobSettingsStore.tempDataMover.targetPort,
                VeeamApiPort: '',
                fetchByDefault:createJobStore.baseSettings.dataMover == DataMover.VeeamSQL ? false: true
            }
        else initialValues =
        {   datamover: dataMover,
            SourceUsername: '',
            SourcePassword: '',
            SourcePort: jobSettingsStore.tempDataMover.sourcePort,
            TargetPort: jobSettingsStore.tempDataMover.targetPort,
            fetchByDefault:createJobStore.baseSettings.dataMover == DataMover.VeeamSQL ? false: true,
            VeeamApiPort: createJobStore.baseSettings.veeamApiPort

        };
        form.setValues(initialValues);
        createJobStore.baseSettings.veeamSolution == VeeamSolution.Backup ? setToggle("backup") :setToggle("replication");
        if(jobSettingsStore.tempDataMover.sourcePort == 0)
        {
             jobSettingsStore.updateTempDataMover({sourcePort: setDatamoverDefaultPort(dataMover, "source")})
        }
        if(jobSettingsStore.tempDataMover.targetPort == 0)
        {
             jobSettingsStore.updateTempDataMover({targetPort: setDatamoverDefaultPort(dataMover, "target")})
        }

        const onCopyInfraCredentials = () => {
            if (!isPaaS) {
                form.setValues({
                    datamover: dataMover,
                    SourceUsername: '',
                    SourcePassword: '',
                    SourcePort: jobSettingsStore.tempDataMover.sourcePort,
                    TargetPort: jobSettingsStore.tempDataMover.targetPort,
                    fetchByDefault:createJobStore.baseSettings.dataMover == DataMover.VeeamSQL ? false: true,
                    VeeamApiPort: createJobStore.baseSettings.veeamApiPort
                })
            }
        }

        activityStore.on(UpdateType.CopyInfraCredentials, onCopyInfraCredentials);
        return () => {
            activityStore.off(UpdateType.CopyInfraCredentials, onCopyInfraCredentials);
        }
    }, []);

    const handleClickShowPassword = () => {
        setSourcePasswordVisibility(sourcepasswordVisibility ? false : true);
    };

    const handleSourceUsernameChange = (event: React.ChangeEvent, value: any) => {
        let onInput = event.type == "click" ? false : true;
        if (value != null && typeof value === 'object') {
            form.setFieldValue("SourceUsername", value == "" ? '' : value.username);
            form.setFieldValue("SourcePassword", value == "" ? '' : value.password);
            form.setFieldValue("TargetUsername", value == "" ? '' : value.username);
            form.setFieldValue("TargetPassword", value == "" ? '' : value.password);
            rootStore.jobSettingsStore.updateTempDataMover({ sourceDataMoverCredentials: { ...rootStore.jobSettingsStore.tempDataMover.sourceDataMoverCredentials, userName: value == "" ? '' : value.username, password: value == "" ? '' : value.password } });
            rootStore.jobSettingsStore.updateTempDataMover({ targetDataMoverCredentials: { ...rootStore.jobSettingsStore.tempDataMover.targetDataMoverCredentials, userName: value == "" ? '' : value.username, password: value == "" ? '' : value.password } });
        }
        if (typeof value === 'string') {
            const userObj = getPreviousSourceUsername.find(e => e.username.toLocaleLowerCase() == value.toLocaleLowerCase());
            form.setFieldValue("TargetUsername", userObj != undefined ? value : (value == "" ? '' : value));
            let pass = userObj == undefined ? '' : userObj.password;
            form.setFieldValue("TargetPassword", pass);
            rootStore.jobSettingsStore.updateTempDataMover({ sourceDataMoverCredentials: { ...rootStore.jobSettingsStore.tempDataMover.sourceDataMoverCredentials, userName: value == "" ? '' : value, password: pass } });
            rootStore.jobSettingsStore.updateTempDataMover({ targetDataMoverCredentials: { ...rootStore.jobSettingsStore.tempDataMover.targetDataMoverCredentials, userName: value == "" ? '' : value, password: pass } });
        }
        setShowEyeSourcePassword(onInput);
    };

    const handleSourcePassChange = (event) => {
        setShowEyeSourcePassword(true);
        form.setFieldValue("TargetPassword", event.target.value);
        rootStore.jobSettingsStore.updateTempDataMover({ sourceDataMoverCredentials: { ...rootStore.jobSettingsStore.tempDataMover.sourceDataMoverCredentials, password: event.target.value } });
        rootStore.jobSettingsStore.updateTempDataMover({ targetDataMoverCredentials: { ...rootStore.jobSettingsStore.tempDataMover.targetDataMoverCredentials, password: event.target.value } });
    };

    const handleSourcePortChange = (event: any) => {
        rootStore.jobSettingsStore.updateTempDataMover({ sourcePort: parseInt(event.target.value) });
    };

    const handleTargetPortChange = (event: any) => {
        rootStore.jobSettingsStore.updateTempDataMover({ targetPort: parseInt(event.target.value) });
    };

    const handleVeeamPortChange = (event: any) => {
        rootStore.createJobStore.updateBaseSettings({ veeamApiPort: event.target.value});
    };

    useEffect(() => {
        const initialValues =
        {   datamover: dataMover,
            SourceUsername: jobSettingsStore.tempDataMover.sourceDataMoverCredentials.userName,
            SourcePassword: jobSettingsStore.tempDataMover.sourceDataMoverCredentials.password,
            SourcePort: jobSettingsStore.tempDataMover.sourcePort,
            TargetUsername: jobSettingsStore.tempDataMover.targetDataMoverCredentials.userName,
            TargetPassword: jobSettingsStore.tempDataMover.targetDataMoverCredentials.password,
            TargetPort: jobSettingsStore.tempDataMover.targetPort,
            fetchByDefault : rootStore.createJobStore.baseSettings.fetchByDefault !=undefined ? rootStore.createJobStore.baseSettings.fetchByDefault : true,
            VeeamApiPort: createJobStore.baseSettings.veeamApiPort || 9392
        };
        form.setValues(initialValues);
        createJobStore.baseSettings.veeamSolution == VeeamSolution.Backup ? setToggle("backup") :setToggle("replication");
    }, []);


    const handleValueChange = (event: React.ChangeEvent) => {
        form.handleChange(event);
        switch (event.target['name']) {
            case 'SourcePassword': handleSourcePassChange(event); break;
            case 'SourcePort': handleSourcePortChange(event); break;
            case 'TargetPort': handleTargetPortChange(event); break;
            case 'VeeamApiPort': handleVeeamPortChange(event); break;
        }
    }

    const handleAutoCompleteChange = (event: React.ChangeEvent, value: any) => {
        if (event != null) {
            let fieldName = event.target['id'].includes('-') ? event.target['id'].split('-')[0] : event.target['id'];
            form.setFieldValue(fieldName, value);
            switch (fieldName) {
                case 'SourceUsername': handleSourceUsernameChange(event, value); break;
            }
        }
    }


    const loadSourceSettings = () => {
        switch (rootStore.createJobStore.baseSettings.dataMover) {
            case DataMover.ZertoVmWare:
            case DataMover.SRM:
            case DataMover.Recoverpoint:
            {
                    return (
                        <React.Fragment>
                            <Grid item xs={3}>
                                <FormControl className={classes.formControl} variant='outlined'>
                                    <EDRTextField id="SourcePort"
                                        name="SourcePort"
                                        label={t('port')}
                                        placeholder=""
                                        style={{ margin: 8 }}
                                        value={form.values.SourcePort}
                                        onChange={handleValueChange}
                                        onBlur={form.handleBlur}
                                        error={Boolean(form.errors.SourcePort)}
                                        helperText={t(form.errors.SourcePort)}
                                        margin="normal"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined" />
                                </FormControl>
                            </Grid>
                        </React.Fragment>
                    )
                }
            case DataMover.ZertoVmwareAzure: return <React.Fragment></React.Fragment>    
            default:
                {
                    return (
                        <React.Fragment>
                            {dataMover != DataMover.VeeamSQL && dataMover != DataMover.VeeamVmware ?
                            <Grid container style={{ marginTop: '8px' }} spacing={2}>
                                <Grid item xs={6}>
                                <Tooltip title={t('dmUserPassInfo')} placement="top">
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <EDRAutocomplete id="SourceUsername"
                                            freeSolo
                                            value={form.values.SourceUsername}
                                            options={getPreviousSourceUsername}
                                            renderOption={(option: string | { username: string, password: string }) => (typeof option === 'string' ? option : `${option.username}`)}
                                            getOptionLabel={(option: string | { username: string, password: string }) => (typeof option === 'string' ? option : `${option.username}`)}
                                            onChange={handleAutoCompleteChange}
                                            onInputChange={handleAutoCompleteChange}
                                            renderInput={(params) => (<EDRTextField {...params}
                                                label={t('username')}
                                                placeholder="Type Here"
                                                defaultValue={form.values.SourceUsername}
                                                InputLabelProps={{ shrink: true }}
                                                //margin="normal"
                                                variant="outlined"
                                                //style={{ margin: 8 }}
                                                onBlur={form.handleBlur}
                                                error={form.touched.SourceUsername && Boolean(form.errors.SourceUsername)}
                                                helperText={form.touched.SourceUsername && form.errors.SourceUsername && t(form.errors.SourceUsername)}
                                            />)}
                                        />
                                    </FormControl>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6}>
                                <Tooltip title={t('dmUserPassInfo')} placement="top">
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <InputLabel ref={ref => { sourcelabelRef = ReactDOM.findDOMNode(ref) }} style={{ transform: 'translate(14px, -6px) scale(0.75)' }}>{t('password')}</InputLabel>
                                        <EDROutlinedInput id="SourcePassword"
                                            name="SourcePassword"
                                            value={form.values.SourcePassword}
                                            labelWidth={sourcelabelRef ? sourcelabelRef.offsetWidth : 0}
                                            autoComplete="new-password"
                                            type={sourcepasswordVisibility ? 'password' : 'text'}
                                            placeholder="Type Here"
                                            //style={{ margin: 8 }}
                                            error={form.touched.SourcePassword && Boolean(form.errors.SourcePassword)}
                                            onChange={handleValueChange}
                                            endAdornment={showEyeSourcePassword ?
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => handleClickShowPassword()}
                                                    >
                                                        {sourcepasswordVisibility ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                                : null}
                                        />
                                    </FormControl>
                                    </Tooltip>
                                </Grid>
                            </Grid>:null}
                            <Grid item container style={{paddingTop:'2%'}} spacing={2}>
                            <Grid item xs={dataMover == DataMover.VeeamSQL ? 5:dataMover == DataMover.VeeamVmware ? 6:3} style={{marginTop: dataMover == DataMover.VeeamSQL ? '16px':'0px'}}>
                                <FormControl className={classes.formControl} variant='outlined'>
                                    <EDRTextField id="SourcePort"
                                        name="SourcePort"
                                        label={t(labels.sourcePort)}
                                        placeholder=""
                                        type="number"
                                        value={form.values.SourcePort}
                                        onChange={handleValueChange}
                                        onBlur={form.handleBlur}
                                        error={Boolean(form.errors.SourcePort)}
                                        helperText={t(form.errors.SourcePort)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined">
                                    </EDRTextField>
                                </FormControl>
                            </Grid>
                            <IfCondition condition={dataMover == DataMover.VeeamVmware}>
                                <Grid item xs={6}>
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <EDRTextField id="TargetPort"
                                            name="TargetPort"
                                            label={t("veeamTargetPort")}
                                            placeholder=""
                                            style={{  backgroundColor: '#ffffff' }}
                                            type="number"
                                            value={form.values.TargetPort}
                                            onChange={handleValueChange}
                                            onBlur={form.handleBlur}
                                            error={Boolean(form.errors.TargetPort)}
                                            helperText={t(form.errors.TargetPort)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined">
                                        </EDRTextField>
                            </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <EDRTextField id="VeeamApiPort"
                                            name="VeeamApiPort"
                                            label={t('VeeamApiPort')}
                                            placeholder=""
                                            style={{  backgroundColor: '#ffffff' }}
                                            type="number"
                                            value={form.values.VeeamApiPort}
                                            onChange={handleValueChange}
                                            onBlur={form.handleBlur}
                                            error={Boolean(form.errors.VeeamApiPort)}
                                            helperText={t(form.errors.VeeamApiPort)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined">
                                        </EDRTextField>
                            </FormControl>
                                </Grid>
                            </IfCondition>
                            </Grid>
                        </React.Fragment>
                    )
                }
        }
    };

    const  loadTargetSettings = () => {
       return (
            <React.Fragment>
                <Grid container style={{ marginTop: '16px' }} spacing={2}>
                    <Grid item xs={dataMover == DataMover.VeeamSQL ? 5:3}>
                        <FormControl className={classes.formControl} variant='outlined'>
                            <EDRTextField id="TargetPort"
                                name="TargetPort"
                                label={dataMover == DataMover.VeeamSQL ? "* "+t('targetPort​'):"* "+t('port')}
                                placeholder=""
                                style={{ margin: 8, backgroundColor: '#ffffff' }}
                                type="number"
                                value={form.values.TargetPort}
                                onChange={handleValueChange}
                                onBlur={form.handleBlur}
                                error={Boolean(form.errors.TargetPort)}
                                helperText={t(form.errors.TargetPort)}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined">
                            </EDRTextField>
                        </FormControl>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    };

    const onToggleChange = (event) => {
        setToggle(event.currentTarget.value);
    };

    const loadVeeamSolutionSection =()=>{
        if(createJobStore.baseSettings.dataMover==DataMover.VeeamVmware)
        return(
            <Grid item container justify="center" direction="column" style={{ marginTop: "15px", marginBottom: "15px" ,display: 'flex',flexDirection: 'row'}}>
            <Grid item xs={6} style={{display: 'flex',alignItems: 'center',justifyContent: 'start'}}>
                <Typography style={{marginLeft:'4%'}}>{t('chooseSolution')}</Typography>
            </Grid>
            <Grid item xs={6} style={{display: 'flex',justifyContent: 'end'}}>
                <ToggleButtonGroup classes={{ root: "toggle_button_group" }} value={toggle} onChange={onToggleChange}>
                <ToggleButton classes={{ root: "toggle_button", selected: "toggle_button_selected" }} value="backup">{t('Backup')}</ToggleButton>
                <ToggleButton classes={{ root: "toggle_button", selected: "toggle_button_selected" }} value="replication">{t('Replication')}</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
        </Grid>
        )
    }

    const handleFetchByDefaultChange = (event) =>{
        form.setFieldValue('fetchByDefault',event.target.checked,true);
        rootStore.createJobStore.updateBaseSettings({fetchByDefault: event.target.checked});
    };

    return (
        <Grid container direction="column" style={{ paddingRight: 16 }}>

            <div style={{ display: 'flex' }}>
                <Typography variant='h6' className={classes.MainHeading}>{t('datamoverSettings')}</Typography>
            </div>

            <Grid item xs={8} style={{marginTop: '10px'}}>
                <FormControlLabel 
                    value={t('fetchByDefault')}
                    control={<CustomCheckbox icon={<CircleUnchecked/>} checkedIcon={<CircleCheckedFilled />} checked={form.values.fetchByDefault} onChange={handleFetchByDefaultChange}/>}
                    label={<Typography>{t('fetchByDefault')}</Typography>}
                />
            </Grid>

           <IfCondition condition={dataMover != DataMover.ZertoVmwareAzure}>
           <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '30px', marginLeft: '10px' }}>
                    <Typography className={classes.SecondHeading} style={{ color: '#4d5f75' }}>{dataMover == DataMover.VeeamVmware ? t('veeamSettings'):t('sourceSettings')}</Typography>
                    <Tooltip title={t("DMsourceSettingsInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider} />
                </div>
            </Grid>
           </IfCondition>
            {loadSourceSettings()}

            {createJobStore.baseSettings.dataMover!=DataMover.VeeamVmware ? <Grid item container direction="row">
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '30px', marginLeft: '10px' }}>
                    <Typography className={classes.SecondHeading} style={{ color: '#4d5f75' }}>{t('targetSettings')}</Typography>
                    <Tooltip title={createJobStore.baseSettings.dataMover == DataMover.ASR ? t("ASRDMtargetSettingsInfo"):t("DMtargetSettingsInfo")} arrow>
                        <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                    </Tooltip>
                    <Divider className={classes.divider} />
                </div>
            </Grid>:null}
            {createJobStore.baseSettings.dataMover!=DataMover.VeeamVmware ? loadTargetSettings():null}
            {loadVeeamSolutionSection()}
        </Grid>
    );
};

export default observer(DataMoverCreateAdvancedComponent);