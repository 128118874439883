
import { Button, Dialog, DialogActions, DialogContent, FormControl, Grid, InputLabel, makeStyles, OutlinedInput, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SaveButton } from "../../../app/common/saveButton";
import { EDRDialogTitle } from "../../../app/common/EDRDialogTitle";
import { RootStoreContext } from '../../../app/stores/root.store';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EDRTextField } from '../../../app/common/EDRTextField';


const useStyles = makeStyles(theme => ({
    centerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between'
    },
    toggleItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiListItemText-root' : {
            marginLeft: 10
        },
        '& .MuiSvgIcon-root': {
            color: '#8092a9'
        }
    },
    root: {
        minWidth: '30vw',
        maxWidth:'500px'
    },
    checkbox: {
        marginLeft: theme.spacing(0.5)
    },
    SecondHeading: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.19',
        letterSpacing: 'normal',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        flexGrow: 1,
        color: '#4d5f75',
        fontsize:'13px',
        marginTop:'35px'
      },
    formControl: {
        width: '100%',
        '& label': {
          background: "white",
          width: "auto",
          padding: "0 4px 0 4px",
          fontSize: '17px'
        }
      },
}))
export interface props {
    open: boolean;
    onClose: () => void;
}

const ManageTestGroupsDialog: React.FC<props> = ({onClose, open}) => {
    const { createJobStore } = useContext(RootStoreContext);
    const [currnetGroups, setCurrnetGroups] = useState<Array<any>>(createJobStore.TestGroups.groups);
    
    const startState = {
        GroupName7: {groupOrder:7, groupName: createJobStore.TestGroups && createJobStore.TestGroups != null && createJobStore.TestGroups.groups && createJobStore.TestGroups.groups != null ? (createJobStore.TestGroups.groups.find(g => g.groupOrder === 7).groupName !=undefined ? createJobStore.TestGroups.groups.find(g => g.groupOrder === 7).groupName:"Group7") :"Group7"},
        GroupName6: {groupOrder:6, groupName: createJobStore.TestGroups && createJobStore.TestGroups != null && createJobStore.TestGroups.groups && createJobStore.TestGroups.groups != null ? (createJobStore.TestGroups.groups.find(g => g.groupOrder === 6).groupName !=undefined ? createJobStore.TestGroups.groups.find(g => g.groupOrder === 6).groupName:"Group6") :"Group6"},
        GroupName5: {groupOrder:5, groupName: createJobStore.TestGroups && createJobStore.TestGroups != null && createJobStore.TestGroups.groups && createJobStore.TestGroups.groups != null ? (createJobStore.TestGroups.groups.find(g => g.groupOrder === 5).groupName !=undefined ? createJobStore.TestGroups.groups.find(g => g.groupOrder === 5).groupName:"Group5") :"Group5"},
        GroupName4: {groupOrder:4, groupName: createJobStore.TestGroups && createJobStore.TestGroups != null && createJobStore.TestGroups.groups && createJobStore.TestGroups.groups != null ? (createJobStore.TestGroups.groups.find(g => g.groupOrder === 4).groupName !=undefined ? createJobStore.TestGroups.groups.find(g => g.groupOrder === 4).groupName:"Group4") :"Group4"},
        GroupName3: {groupOrder:3, groupName: createJobStore.TestGroups && createJobStore.TestGroups != null && createJobStore.TestGroups.groups && createJobStore.TestGroups.groups != null ? (createJobStore.TestGroups.groups.find(g => g.groupOrder === 3).groupName !=undefined ? createJobStore.TestGroups.groups.find(g => g.groupOrder === 3).groupName:"Group3") :"Group3"},
        GroupName2: {groupOrder:2, groupName: createJobStore.TestGroups && createJobStore.TestGroups != null && createJobStore.TestGroups.groups && createJobStore.TestGroups.groups != null ? (createJobStore.TestGroups.groups.find(g => g.groupOrder === 2).groupName !=undefined ? createJobStore.TestGroups.groups.find(g => g.groupOrder === 2).groupName:"Group2") :"Group2"},
        GroupName1: {groupOrder:1, groupName: createJobStore.TestGroups && createJobStore.TestGroups != null && createJobStore.TestGroups.groups && createJobStore.TestGroups.groups != null ? (createJobStore.TestGroups.groups.find(g => g.groupOrder === 1).groupName !=undefined ? createJobStore.TestGroups.groups.find(g => g.groupOrder === 1).groupName:"Group1") :"Group1"},
    };

    const validation = yup.object({
        groups: yup.array(yup.object({
            groupName: yup.string().max(10,'up to 10 characters'),
            groupOrder: yup.number()

        }))
    })

    const form = useFormik({
        initialValues: 
        {
           groups: [
               {groupName: startState.GroupName1.groupName, groupOrder: startState.GroupName1.groupOrder},
               {groupName: startState.GroupName2.groupName, groupOrder: startState.GroupName2.groupOrder},
               {groupName: startState.GroupName3.groupName, groupOrder: startState.GroupName3.groupOrder},
               {groupName: startState.GroupName4.groupName, groupOrder: startState.GroupName4.groupOrder},
               {groupName: startState.GroupName5.groupName, groupOrder: startState.GroupName5.groupOrder},
               {groupName: startState.GroupName6.groupName, groupOrder: startState.GroupName6.groupOrder},
               {groupName: startState.GroupName7.groupName, groupOrder: startState.GroupName7.groupOrder},
           ]
        },
        validationSchema:validation,
        validateOnChange:true,
        onSubmit: () => { },
        
    })

    const classes = useStyles();
    const { t } = useTranslation('createJobDevices');
    const [cancel,setCancel]=useState(false);

    const handleSaveChange =()=>{
        createJobStore.updateTestGroups(form.values.groups);
        setCancel(false);
        onClose();
    }

    const handleClose =()=>{
        if(form.dirty)
        {
            setCancel(!cancel);
        }
        else
        {
            onClose();
        }  
    }

    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel style={{marginLeft:'10px'}}>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const handleYes=()=>{
        form.setFieldValue("GroupName7",currnetGroups.find(g => g.groupOrder === 7));
        form.setFieldValue("GroupName6",currnetGroups.find(g => g.groupOrder === 6)); 
        form.setFieldValue("GroupName5",currnetGroups.find(g => g.groupOrder === 5)); 
        form.setFieldValue("GroupName4",currnetGroups.find(g => g.groupOrder === 4));
        form.setFieldValue("GroupName3",currnetGroups.find(g => g.groupOrder === 3)); 
        form.setFieldValue("GroupName2",currnetGroups.find(g => g.groupOrder === 2)); 
        form.setFieldValue("GroupName1",currnetGroups.find(g => g.groupOrder === 1)); 
        setCancel(false);
        onClose();
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    return <Dialog disableBackdropClick
        open={open}
        classes={{ paper: classes.root }}
        onClose={handleClose}
        scroll="paper"
    >
        <EDRDialogTitle >{t('manageGroups')}</EDRDialogTitle>
        <DialogContent dividers={true}>
            <Grid container direction="row">
           {form.values.groups.map((group, index) => {

               return  <Grid key={index} item container direction="row" spacing={1}>
               <Grid item xs={2}>
                   <Typography className={classes.SecondHeading}>{t(`Group #${index+1}`)}</Typography>
               </Grid>
               <Grid item xs={10}>
                   <FormControl className={classes.formControl} variant='outlined'>
                       <EDRTextField className="MuiFormControl-marginNormal" 
                           variant="outlined"
                           name={`groups[${index}].groupName`}
                           value={group.groupName}
                           style={{paddingRight:'0px'}}
                           type='text'
                           error={ form.touched?.groups && form.touched?.groups[index]?.groupName && Boolean(form.errors.groups && form.errors.groups[index]?.["groupName"])}
                           helperText={ form.touched?.groups && form.touched?.groups[index]?.groupName && form.errors.groups && form.errors.groups[index]?.["groupName"]}
                           onChange={form.handleChange}
                           onBlur={form.handleBlur}
                           />  
                   </FormControl>
               </Grid>
               </Grid>
           })}
           </Grid>
        </DialogContent>
        <DialogActions>
        <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end'}}>
            <Button color="primary" onClick={handleClose} style={{textTransform:'none'}}>
                {t('cancel')}
            </Button>
            <SaveButton variant="contained" color="primary" disabled={!form.isValid} onClick={handleSaveChange} style={{textTransform:'none'}}>
                {t('save')}
            </SaveButton>
            </Grid>
        </DialogActions>
    </Dialog>
}

export default ManageTestGroupsDialog;