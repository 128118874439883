
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Grid, IconButton, InputLabel, makeStyles, Tooltip, Typography,CircularProgress } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EDRTextField } from "../../../../app/common/EDRTextField";
import { SaveButton } from "../../../../app/common/saveButton";
import { EDRDialogTitle } from "../../../../app/common/EDRDialogTitle";
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EDRAutocomplete } from '../../../../app/common/EDRAutocomplete';
import useDeviceTests from '../getInitalTests.hook';
import { RootStoreContext } from '../../../../app/stores/root.store';
import { TestType } from '../../../../app/models/jobTest.model';
import { DatabaseType, DevicePlatform, MachineType } from '../../../../app/models/jobProperties.model';
import HelpIcon from '@material-ui/icons/Help';
import { toast } from 'react-toastify';
import jobAgent from '../../../../app/api/jobAgent';

const useStyles = makeStyles(theme => ({
    helpIcon:
    {
      color: '#1976d2',
      fontSize: "large"
    },
    SecondHeading:{
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        flexGrow: 1
    },
    divider: {
        backgroundColor: '#eaeef4',
        maxWidth: '200%',
        margin: '0',
        marginTop: '10px',
        marginLeft: '10px',
    },
    centerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between'
    },
    toggleItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiListItemText-root' : {
            marginLeft: 10
        },
        '& .MuiSvgIcon-root': {
            color: '#8092a9'
        }
    },
    root: {
        minWidth: '40vw'
    },
    checkbox: {
        marginLeft: theme.spacing(0.5)
    },
    heading:{
        fontFamily: 'Roboto',
        fontSize: '13px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontstyle: 'normal',
        lineHeight: 1.15,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#4d5f75',
    },
    title:{
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontstyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        textAlign: 'left'
    }
}))
export interface WebPortalTestSettingProps {
    open: boolean;
    onClose: () => void;
    onSave : () => void;
    onChange:(turnOnSwitch:boolean)=>void;
    hostname:string;
    isDefault:boolean;
}

const validationSchema = yup.object({
    url: yup.string().required('Service Name is  a required field'),
    result: yup.string().nullable()
})
const WebPortalTestSettingDialog: React.FC<WebPortalTestSettingProps> = (props) => {
    const { open,hostname,isDefault, onClose,onChange,onSave } = props;
    const rootStore = useContext(RootStoreContext);
    const [testState, setTestState] = useDeviceTests(rootStore.createJobStore.baseSettings.dataMover);
    const classes = useStyles();
    const { t } = useTranslation('createJobBase');
    const {createJobStore,jobSettingsStore} = useContext(RootStoreContext);
    const [device, setDevice] = useState(createJobStore.selectedDevices.find(device=>device.sourceName == hostname));
    const [WebTest,setWebTest] =useState(device!= undefined ? device.tests.find(test=> test.testType == TestType.web):null)
    const [processingTest,setProcessingTest] = useState(false);
    const getPreviousWebPortalSettings = 
    [
        {url:device!= undefined ?'http://'+ (device.planName.includes("Custom Devices") ? device.sourceName:(device.sourceName==null ? '':(device.sourceName.split('.')[0] !=undefined ? device.sourceName.split('.')[0]:device.sourceName))):'',result:''},
        {url:device!= undefined ?'https://'+ (device.planName.includes("Custom Devices") ? device.sourceName:(device.sourceName==null ? '':(device.sourceName.split('.')[0] !=undefined ? device.sourceName.split('.')[0]:device.sourceName))):'',result:''}
    ];
    const [cancel,setCancel]=useState(false);
    const defaultSettings = {
        url: device!=undefined ? ('http://'+ !device.planName.includes("Custom Devices") ? device.deviceName:device.sourceName):'',
        result: device!=undefined ? (WebTest ? WebTest.webPortalResult : device.deviceName):''
    };

    const handleTest=() =>{        
        var details ={webURL:form.values.url,webResult:form.values.result,Hostname:device.sourceName};
        setProcessingTest(true);
        jobAgent.JobTestsActions.TestWeb(details).then(res => {
            if(res)
                toast.success("Web Test succeeded");
            else
                toast.error("Web Test failed");
            setProcessingTest(false);
        }).catch(err=>{
            if(err && err.data && err.data.errors)
                toast.error(err.data.errors);
            setProcessingTest(false);
        });        
    }

    const handleClose = () => {
        if(form.touched && form.dirty)
            setCancel(!cancel);
        else
            onClose();
    }

    const handleSave=()=>{
        try{
        if(device!=undefined){
            let DeviceTest = {
                testCategory:WebTest.testCategory, 
                testType: TestType.web, 
                selected: true,
                customCommandToTest: '',
                customCommandExpectedResult: '', 
                customCommandToTestIsExternal: false,
                databaseType: DatabaseType.SQL,
                databaseNameToTest: '',
                databaseQueryToTest: '',
                databaseServerNameToTest: '',
                databaseUsername:'',
                databasePassword: '',
                databasePort:'',
                dbWindowsAuth:false,
                serviceToTest:'',
                testFromEDRC: false,
                script: '',
                thirdDeviceNetworkToTest: '',
                authenticationUserToTest:'',
                authenticationPassToTest:'',
                webPortalToTest: form.values.url,
                webPortalResult: form.values.result
            };
            createJobStore.updateTestDevice(TestType.web,DeviceTest,device.planName.includes("Custom Devices") ? device.sourceName: device.deviceName);
            let state;
            if(form.values.url !== "")
                state=true;
            else
                state=false;
            let newTestState = {...testState, ["web"]: state};
            setTestState(newTestState);
            //onChange(state);
            toast.success('Save successful');
            onClose();
        }
        else
        {
            jobSettingsStore.updateWebPortalSettings(form.values.url,form.values.result);
            toast.success('Save successful');
            onClose();
        }
    }
        catch(error){
            toast.error('Save failed'+error);
            onClose();
        }
        setCancel(false);
        onSave();
        onClose();
    }
    
    const loadConfirmation=()=>{
        if(cancel)
        {
            return (
                <React.Fragment>
                   <div className={classes.toggleItem} style={{backgroundColor:'#edf6e1'}}>
                        <div className={classes.centerVertical}>
                            <InputLabel style={{marginLeft:'10px'}}>{t('disacrdChanges')}</InputLabel>
                            <Button onClick={handleYes} color="primary" style={{textTransform:'none'}}>{t('yes')}</Button>
                            <Button onClick={handleNo} color="primary" style={{textTransform:'none'}}>{t('no')}</Button>
                        </div>
                    </div> 
                </React.Fragment>
            );
        }
    }

    const handleYes=()=>{
        if(device!=undefined)
        {
            let initialValues = {
                url: WebTest.webPortalToTest,
                result: WebTest.webPortalResult,
            };
            form.setValues(initialValues);
            let newTestState = {...testState, ["web"]: false};
            setTestState(newTestState);
            if(hostname === null)
                onChange(false);
        }
        setCancel(false);
        onClose();
    }

    const handleNo=()=>{
        setCancel(!cancel);
    }

    const form = useFormik({
        initialValues: {
            url: device != undefined ?('http://'+ device && device.planName.includes("Custom Devices") ? device.sourceName:device.deviceName):'',
            result: device != undefined ? device.deviceName :''
        },
        onSubmit: () => { },
        validationSchema
    })

    useEffect(() => {
        const newDevice = createJobStore.selectedDevices.find(device=>device.sourceName == hostname);
        setDevice(newDevice);
        const newWebTest = newDevice!= undefined ? newDevice.tests.find(test=> test.testType == TestType.web):null;
        setWebTest(newWebTest);
        let initialValues;
        initialValues =
        {
            url: newDevice != undefined ? (newWebTest.webPortalToTest === '' ? 'http://'+defaultSettings.url:newWebTest.webPortalToTest): defaultSettings.url,
            result: newDevice != undefined ? (newWebTest.webPortalResult === '' ?defaultSettings.result:newWebTest.webPortalResult) :defaultSettings.result
        };
        form.resetForm({values: initialValues});
      }, [hostname]);

      useEffect(() => {
          console.log("value changed", form.values);
      }, [form.values.result])
    const handleAutoCompleteChange = (event: React.ChangeEvent,value:any) => {
        if(event!=null)
        {
          let fieldName= event.target['id'].includes('-') ? event.target['id'].split('-')[0]: event.target['id'];
          form.setFieldValue(fieldName,value);
          switch(fieldName) {
            case 'url': handleUrlChange(event,value); break;
            case 'result': handleResultChange(event,value); break;
          }
        }
      }
    
      const handleUrlChange=(event:React.ChangeEvent,value:any)=>{
        if(event.type ==="click")
        {
            if(value!=null && typeof value === 'object')
            {
                form.setFieldValue("url",value=="" ? '':value.url);
                form.setFieldValue("result",value=="" ? '':value.result);
            }
        }
        else if(event.type ==="change")
        {
            if (typeof value === 'string')
            {
              const Obj = getPreviousWebPortalSettings.find(e => e.url == value);
              form.setFieldValue("url", Obj!=undefined ? Obj.url:(value==""?'':value));
              let result = Obj == undefined ? '' :Obj.result;
              form.setFieldValue("result",result);
            }
        }
      }

      const handleResultChange=(event:React.ChangeEvent,value:any)=>{
        if(event!=null)
        {
            if(event.type ==="click")
            {
                const Obj = getPreviousWebPortalSettings.find(e => e.result == value);
                let res = Obj== undefined ? '' :Obj.result;
                form.setFieldValue("result",res);
            }
            else if(event.type ==="change")
            {
                form.setFieldValue("result",value=="" ? '':value);
            }
        }
    }

    return <Dialog disableBackdropClick
        open={open}
        classes={{ paper: classes.root }}
        onClose={handleClose}
        scroll="paper"
    >
        <EDRDialogTitle>
        <div style={{ display: 'flex', flexDirection: 'row'}}>
            <Typography variant="h6" className={classes.title} style={{display:'flex',alignItems:'center'}}>{t('webSetting')}</Typography>
            {device !== undefined ? <img style={{marginLeft:'5px'}} src={device.os=== DevicePlatform.UNIX ?"/assets/images/editDeviceIcons/linuxIcon.svg":"/assets/images/editDeviceIcons/windowsIcon.svg"}></img>:null}
        </div>
        </EDRDialogTitle>
        <DialogContent dividers={true}>
        <Grid item container direction="row">
        <div style={{ display: 'flex', flexDirection: 'row',paddingBottom:'5%'}}>
            <Typography className={classes.SecondHeading} style={{color: '#4d5f75'}}>{device != undefined ? t('subWeb')+ device.deviceName:''}</Typography>
            <Tooltip title={t('webInfo')} arrow>
                <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
            </Tooltip> 
            <Divider className={classes.divider}/>
        </div>
    </Grid>
            <Grid container direction="column" spacing={1}>
                <Grid item container>
                    <Grid item xs={4} container alignItems="center">
                        <Typography variant="h6" className={classes.heading}>* {t('url')}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                    <EDRAutocomplete id="url"
                        freeSolo
                        disableClearable
                        value={form.values.url}
                        options={getPreviousWebPortalSettings.map(a => a.url)}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderOption={(option: string|{url: string,result:string}) => (typeof option === 'string' ? option : `${option.url}`)}
	                    getOptionLabel={(option: string|{url: string,result:string}) => (typeof option === 'string' ? option : `${option.url}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={t('url')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="url"
                        onBlur={form.handleBlur}
                        error={form.touched.url && Boolean(form.errors.url)}
                        helperText={form.errors.url}
                    />)}
                    />
                    </Grid>
                    <Grid item xs={1} alignItems="center">
                    </Grid>
                </Grid>

                <Grid item container style={{marginTop:'25px'}}>
                    <Grid item xs={4} container alignItems="center">
                        <Typography variant="h6" className={classes.heading}>{t('webResult')}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                    <EDRAutocomplete id="result"
                        freeSolo
                        disableClearable
                        value={form.values.result}
                        options={getPreviousWebPortalSettings.map(a => a.result)}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderOption={(option: string|{url: string,result:string}) => (typeof option === 'string' ? option : `${option.result}`)}
	                    getOptionLabel={(option: string|{url: string,result:string}) => (typeof option === 'string' ? option : `${option.result}`)}
                        renderInput={(params) => (<EDRTextField {...params} label={t('webResult')} 
                        InputLabelProps={{shrink: true}} 
                        variant="outlined"
                        name="result"
                        onBlur={form.handleBlur}
                        error={form.touched.result && Boolean(form.errors.result)}
                        helperText={form.errors.result}
                    />)}
                    />  
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                </Grid>

            </Grid>
        </DialogContent>
        <DialogActions>
        <Grid item xs={6}>
            {loadConfirmation()}
            </Grid>
            <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end'}}>
            <Button onClick={handleClose} color="primary" style={{textTransform:'none'}}>
                {t('cancel')}
            </Button>
            <Button onClick={handleTest} disabled={form.values.url=='' || processingTest} color="primary" style={{textTransform:'none'}}>
            {processingTest ? <CircularProgress id="processing" size={18} style={{color: '#2892D7', marginRight:'6%',marginTop: '-3px'}}/>:null}
                {t('test')}
            </Button>
            <SaveButton onClick={handleSave} variant="contained" color="primary" style={{textTransform:'none'}} disabled={form.values.url === ''}>
                {t('save')}
            </SaveButton>
            </Grid>
        </DialogActions>
    </Dialog>
}

export default WebPortalTestSettingDialog;