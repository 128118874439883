import React, { useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Chip, CircularProgress, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { RootStoreContext } from '../../../../app/stores/root.store';
import ReactDOM from 'react-dom';
import { DataMover, DATA_MOVER_CONFIGURATION_NAME, VeeamSolution } from '../../../../app/models/BaseSettings.enum';
import { EDROutlinedInput } from "../../../../app/common/EDROutlinedInput";
import { EDRAutocomplete } from "../../../../app/common/EDRAutocomplete";
import { EDRTextField } from "../../../../app/common/EDRTextField";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { UpdateType } from '../../../../app/stores/activityStore';
import HelpIcon from '@material-ui/icons/Help';
import { SaveButton } from '../../../../app/common/saveButton';
import { toast } from 'react-toastify';
import { runInAction } from 'mobx';
import jobAgent from '../../../../app/api/jobAgent';
import { AccessCredentials, CONSTS, CredentialUserType, DataMoverDTO } from '../../../../app/models/createjob/createJob.model';
import { setDatamoverDefaultPort } from '../../../../app/stores/jobSettings.store';
import ConfirmationDialog from '../../../../app/common/confirmationDialog.component';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useLabels } from '../hooks/useDatamoverLabels';
import IfCondition from '../../../../app/common/util/if-condition';
import { CustomCheckbox } from './BasicAdvanced.component';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

const PortLabelName: { [key: number]: string } = {
    [DataMover.SRM]: '',
    [DataMover.VeeamVmware]: 'Veeam SDK ',
    [DataMover.CohesityRunbook]: ''
}
const DataMoverAdvancedComponent: React.FC = () => {
    let sourcelabelRef;
    let targetlabelRef;
    let labelRef;
    const useStyles = makeStyles({
        helpIcon:
        {
            color: '#1976d2',
            fontSize: "large"
        },
        formControl: {
            width: '100%',
            height: '70px',
            '& label': {
                background: "white",
                width: "auto",
                padding: "0 4px 0 4px",
                fontSize: '17px',
            }
        },
        MainHeading:
        {
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.19',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#1f396d',
            marginLeft: '10px'
        },
        SecondHeading: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            flexGrow: 1
        },
        divider: {
            backgroundColor: '#eaeef4',
            width: '290%',
            margin: '0',
            marginTop: '10px',
            marginLeft: '10px',
        },
        button: {
            borderRadius: 15,
            color: 'white',
            backgroundColor: '#2892d7',
            width: '23%',
            height: '32px',
            marginTop: '24px',
            marginLeft: '485px'
        }
    });

    const classes = useStyles();
    const [t] = useTranslation("createJobBase");
    const rootStore = useContext(RootStoreContext);
    const { activityStore, jobSettingsStore, createJobStore, displayStore } = rootStore;
    const { BaseDrawerSettings } = displayStore;
    const isPaaS = rootStore.createJobStore.baseSettings.dataMover == DataMover.PaaS;
    const {dataMover} = rootStore.createJobStore.baseSettings;

    const [sourcepasswordVisibility, setSourcePasswordVisibility] = useState(true);
    const [showEyeSourcePassword, setShowEyeSourcePassword] = useState(true);
    const [showEyeDMTargetPassword, setShowEyeDMTargetPassword] = useState(true);

    const [passwordVisibility, setPasswordVisibility] = useState(true);
    const [DMtargetpasswordVisibility, setDMTargetPasswordVisibility] = useState(true);
    const [UserPassAutocomplete, setUserPassAutocomplete] = useState(false);
    const [DMUserPassAutocomplete, setDMUserPassAutocomplete] = useState(false);
    const [showEyeDMPassword, setShowEyeDMPassword] = useState(true);
    const [openConfirmationDialog, setopenConfirmationDialog] = useState(false);

    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [toggle, setToggle] = useState(createJobStore.baseSettings.veeamSolution == VeeamSolution.Backup ? "backup" : "Replication");
    const previousTargetIPDataMover = jobSettingsStore.PreviousTargetIPDataMover;
    const previousSourceIPDataMover = jobSettingsStore.PreviousSourceIPDataMover;
    const previousSourceUsername = jobSettingsStore.sourceUserNamesAndPass;
    const previousTargetUsername = jobSettingsStore.targetUserNamesAndPass;
    const portRegex = /^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/;
    const labels = useLabels(dataMover);
    const validation = yup.object({
        fetchByDefault:yup.boolean(),
        datamoverTargetIP: yup.string()
            .when("datamover", {
                is: v => v != DataMover.PaaS && v != DataMover.VeeamSQL && v != DataMover.VeeamVmware && v!= DataMover.CohesityRunbook,
                then: yup.string().required("Required").test('datamoverTargeteIP',
                    'Invalid Target Name/IP',
                    function (value) {
                        let IpRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
                        let hostnameRegex = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;
                        let isValidIP = IpRegex.test(value);
                        let isValidHostname = hostnameRegex.test(value);
                        if (isValidIP || isValidHostname) {
                            return true;
                        }
                        return false;
                    })
            }),

        datamoverSourceIP: yup.string()
            .when("datamover", {
                is: v => v != DataMover.PaaS && v!= DataMover.CohesityRunbook && v!= DataMover.ZertoVmwareAzure,
                then: yup.string().required("Required").test('datamoverSourceIP',
                    'Invalid Source Name/IP',
                    function (value) {
                        let IpRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
                        let hostnameRegex = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;
                        let isValidIP = IpRegex.test(value);
                        let isValidHostname = hostnameRegex.test(value);
                        if (isValidIP || isValidHostname) {
                            return true;
                        }
                        return false;
                    })
            }),

        TargetUsername: yup.string().required()
            .when("datamover", {
                is: v => (v != DataMover.PaaS && v != DataMover.SRM && v != DataMover.ZertoVmWare),
                then: yup.string().required("Required").test('empty', 'username must not be empty', DMTargetUsername => DMTargetUsername === undefined || (DMTargetUsername !== undefined && DMTargetUsername != ''))
                    .nullable()
            }),

        TargetPassword: yup.string().required()
            .when("datamover", {
                is: v => (v != DataMover.PaaS && v != DataMover.SRM && v != DataMover.ZertoVmWare),
                then: yup.string().required("Required").test('empty', 'password must not be empty', DMTargetPassword => DMTargetPassword == undefined || (DMTargetPassword !== undefined && DMTargetPassword != ''))
                    .nullable()
            }),

        // vmHosts: yup.string().when('datamover', {
        //     is: (v) => v == DataMover.VeeamVmware || dataMover == DataMover.Netapp,
        //     then: yup.string().required('Required').test('empty', 'vmhosts must not be empty', vmhosts => vmhosts !== ''),
        //     otherwise: yup.string().nullable()
        // }),

        SourceUsername: yup.string(),

        SourcePassword: yup.string(),

        SourcePort: yup.string().required()
            .max(6, 'port max length is 5')
            .matches(portRegex, "port is invalid"),

        TargetPort: yup.string().required()
            .max(6, 'port max length is 5')
            .matches(portRegex, "port is invalid"),
        VeeamApiPort: yup.string().when("datamover", {
            is: v => v== DataMover.VeeamVmware,
            then: yup.string().required(),
            otherwise: yup.string()
        })
    })

    const form = useFormik({
        initialValues:
        {
            datamover: rootStore.createJobStore.baseSettings.dataMover,
            datamoverTargetIP: '',
            TargetPort: 0,
            TargetUsername: '',
            TargetPassword: '',
            datamoverSourceIP: '',
            SourcePort: 0,
            SourceUsername: '',
            SourcePassword: '',
            fetchByDefault:rootStore.createJobStore.baseSettings.dataMover == DataMover.VeeamSQL ? false: true,
            VeeamApiPort: createJobStore.baseSettings.veeamApiPort || 9392,
            vmHosts: ''
        },
        validationSchema: validation,
        validateOnChange: true,
        onSubmit: () => { },
    })

    useEffect(() => {
        let initialValues;
        if (isPaaS)
            initialValues =
            {
                datamover: rootStore.createJobStore.baseSettings.dataMover,
                SourceUsername: '',
                SourcePassword: '',
                TargetUsername: '',
                TargetPassword: '',
                SourcePort: jobSettingsStore.selectedDataMover.sourcePort,
                TargetPort: jobSettingsStore.selectedDataMover.targetPort,
                VeeamApiPort: '',
                fetchByDefault:rootStore.createJobStore.baseSettings.dataMover == DataMover.VeeamSQL ? false: true
            }
        else initialValues =
        {
            datamover: rootStore.createJobStore.baseSettings.dataMover,
            SourceUsername: '',
            SourcePassword: '',
            TargetUsername: BaseDrawerSettings.addDataMover ? (createJobStore.baseSettings.dataMover == DataMover.ZertoVmWare || createJobStore.baseSettings.dataMover == DataMover.SRM ? jobSettingsStore.selectedInfra.targetInfraCredentials.userName:'') : jobSettingsStore.selectedDataMover?.targetDataMoverCredentials?.userName,
            TargetPassword: BaseDrawerSettings.addDataMover ? (createJobStore.baseSettings.dataMover == DataMover.ZertoVmWare || createJobStore.baseSettings.dataMover == DataMover.SRM ? jobSettingsStore.selectedInfra.targetInfraCredentials.password:'') : jobSettingsStore.selectedDataMover?.targetDataMoverCredentials?.password,
            SourcePort: jobSettingsStore.selectedDataMover?.sourcePort,
            TargetPort: jobSettingsStore.selectedDataMover?.targetPort,
            VeeamApiPort: createJobStore.baseSettings.veeamApiPort,
            vmHosts: BaseDrawerSettings.addDataMover ? '' : (createJobStore.baseSettings && createJobStore.baseSettings.vra != null && createJobStore.baseSettings.vra != undefined ? createJobStore.baseSettings.vra : ''),
            fetchByDefault:rootStore.createJobStore.baseSettings.dataMover == DataMover.VeeamSQL ? false: true
        };
        form.resetForm({ values: initialValues });
        if(BaseDrawerSettings.addDataMover)
            form.validateForm(initialValues);
        createJobStore.baseSettings.veeamSolution == VeeamSolution.Backup ? setToggle("backup") : setToggle("replication");
        const onCopyInfraCredentials = () => {
            if (!isPaaS) {
                form.setValues({
                    datamover: rootStore.createJobStore.baseSettings.dataMover,
                    datamoverTargetIP: '',
                    TargetPort: jobSettingsStore.selectedDataMover.targetPort,
                    datamoverSourceIP: '',
                    SourcePort: jobSettingsStore.selectedDataMover.sourcePort,
                    SourceUsername: '',
                    SourcePassword: '',
                    TargetUsername: BaseDrawerSettings.addDataMover ? (createJobStore.baseSettings.dataMover == DataMover.ZertoVmWare || createJobStore.baseSettings.dataMover == DataMover.SRM ? jobSettingsStore.selectedInfra.targetInfraCredentials.userName:'') : jobSettingsStore.selectedDataMover?.targetDataMoverCredentials?.userName,
                    TargetPassword: BaseDrawerSettings.addDataMover ? (createJobStore.baseSettings.dataMover == DataMover.ZertoVmWare || createJobStore.baseSettings.dataMover == DataMover.SRM ? jobSettingsStore.selectedInfra.targetInfraCredentials.password:'') : jobSettingsStore.selectedDataMover?.targetDataMoverCredentials?.password,
                    VeeamApiPort: createJobStore.baseSettings.veeamApiPort,
                    vmHosts: BaseDrawerSettings.addDataMover ? '' : (createJobStore.baseSettings && createJobStore.baseSettings.vra != null && createJobStore.baseSettings.vra != undefined ? createJobStore.baseSettings.vra : ''),
                    fetchByDefault:rootStore.createJobStore.baseSettings.dataMover == DataMover.VeeamSQL ? false: true
                })
            }
            if(!BaseDrawerSettings.addDataMover)
                form.validateForm(initialValues);
        }
        activityStore.on(UpdateType.CopyInfraCredentials, onCopyInfraCredentials);
        return () => {
            activityStore.off(UpdateType.CopyInfraCredentials, onCopyInfraCredentials);
        }
       
    }, []);

    const handleSourceUsernameChange = (event: React.ChangeEvent, value: any) => {
        let onInput = event.type == "click" ? false : true;
        if (value != null && typeof value === 'object') {
            form.setFieldValue("SourceUsername", value == "" ? '' : value.username);
            form.setFieldValue("SourcePassword", '');
            form.setFieldValue("TargetUsername", value == "" ? '' : value.username);
            form.setFieldValue("TargetPassword", '');
        }
        if (typeof value === 'string') {
            const userObj = previousSourceUsername.find(e => e.username.toLocaleLowerCase() == value.toLocaleLowerCase());
            form.setFieldValue("TargetUsername", userObj != undefined ? value : (value == "" ? '' : value));
            let pass = '';
            form.setFieldValue("TargetPassword", pass);
        }
        setShowEyeSourcePassword(onInput);
    };

    const handleSourcePassChange = (event) => {
        setShowEyeSourcePassword(true);
        form.setFieldValue("SourcePassword", event.target.value);
    };

    const handleTargetPassChange = (event) => {
        setDMUserPassAutocomplete(true);
        form.setFieldValue("TargetPassword", event.target.value);
    };

    const handleSourcePortChange = (event: any) => {
        form.setFieldValue("SourcePort", parseInt(event.target.value));
    };

    const handleTargetPortChange = (event: any) => {
        form.setFieldValue("TargetPort", parseInt(event.target.value));
    };

    const handleVeeamPortChange = (event: any) => {
        rootStore.createJobStore.updateBaseSettings({ veeamApiPort: event.target.value });
    };

    const handleSourceIPChange = (event: any) => {
        form.setFieldValue("datamoverSourceIP", event.target.value);
    }

    const handleDMSourceIPChange = (event: object, value: any) => {
        form.setFieldValue("datamoverSourceIP", value, true);
        if (rootStore.createJobStore.baseSettings.dataMover == DataMover.VeeamVmware) {
            form.setFieldValue("datamoverTargetIP", value, true);
        }
    };

    const handleDMTargetIPChange = (event: object, value: any) => {
        form.setFieldValue("datamoverTargetIP", value, true);
    };

    const handleEnterpriseURLChange = (event: any) => {
        form.setFieldValue("datamoverTargetIP", event.target.value);
    }

    const handleTargetUsernameChange = (event: object, value: any, onInput: boolean) => {
        setDMUserPassAutocomplete(onInput);
        if (value != null && typeof value === 'object') {
            form.setFieldValue("TargetUsername", value == "" ? '' : value.username, true);
            form.setFieldValue("TargetPassword", '', true);
            //rootStore.createJobStore.updateBaseSettings({targetDataMover: {...rootStore.createJobStore.baseSettings.targetDataMover, username: value=="" ? '':value.username,password:value=="" ? '':value.password}});
        }
        if (typeof value === 'string') {
            const userObj = previousSourceUsername.find(e => e.username.toLocaleLowerCase() == value.toLocaleLowerCase());
            form.setFieldValue("TargetUsername", value, true);
            let pass = '';
            form.setFieldValue("TargetPassword", pass, true);
            //rootStore.createJobStore.updateBaseSettings({targetDataMover: {...rootStore.createJobStore.baseSettings.targetDataMover, username: value,password:pass}});
        }
        setShowEyeDMTargetPassword(onInput);
    };

    const handleClickShowPassword = (type: string) => {
        switch (type) {
            case "SourcePassword": setSourcePasswordVisibility(sourcepasswordVisibility ? false : true); break;
            case "datamover": setPasswordVisibility(passwordVisibility ? false : true); break;
            case "target": setDMTargetPasswordVisibility(DMtargetpasswordVisibility ? false : true); break;
        }
    };


    useEffect(() => {
        const initialValues =
        {
            datamover: rootStore.createJobStore.baseSettings.dataMover,
            datamoverTargetIP: BaseDrawerSettings.addDataMover ? '' : jobSettingsStore.selectedDataMover != undefined ? jobSettingsStore.selectedDataMover.dataMoverTarget : '',
            datamoverSourceIP: BaseDrawerSettings.addDataMover ? '' : jobSettingsStore.selectedDataMover != undefined ? jobSettingsStore.selectedDataMover.dataMoverSource : '',
            SourceUsername: BaseDrawerSettings.addDataMover ? '' : jobSettingsStore.selectedDataMover != undefined && jobSettingsStore.selectedDataMover.sourceDataMoverCredentials ? jobSettingsStore.selectedDataMover.sourceDataMoverCredentials.userName : '',
            SourcePassword: BaseDrawerSettings.addDataMover ? '' : jobSettingsStore.selectedDataMover != undefined && jobSettingsStore.selectedDataMover.sourceDataMoverCredentials ? jobSettingsStore.selectedDataMover.sourceDataMoverCredentials.password : '',
            TargetUsername: BaseDrawerSettings.addDataMover ? (createJobStore.baseSettings.dataMover == DataMover.ZertoVmWare || createJobStore.baseSettings.dataMover == DataMover.SRM ? jobSettingsStore.selectedInfra.targetInfraCredentials.userName:'') : jobSettingsStore.selectedDataMover != undefined && jobSettingsStore.selectedDataMover.targetDataMoverCredentials ? (createJobStore.baseSettings.dataMover == DataMover.SRM || createJobStore.baseSettings.dataMover == DataMover.ZertoVmWare ? jobSettingsStore.selectedInfra.targetInfraCredentials.userName : jobSettingsStore.selectedDataMover.targetDataMoverCredentials.userName) : '',
            TargetPassword: BaseDrawerSettings.addDataMover ?(createJobStore.baseSettings.dataMover == DataMover.ZertoVmWare || createJobStore.baseSettings.dataMover == DataMover.SRM ? jobSettingsStore.selectedInfra.targetInfraCredentials.password:'') : jobSettingsStore.selectedDataMover != undefined && jobSettingsStore.selectedDataMover.targetDataMoverCredentials ? (createJobStore.baseSettings.dataMover == DataMover.SRM || createJobStore.baseSettings.dataMover == DataMover.ZertoVmWare ? jobSettingsStore.selectedInfra.targetInfraCredentials.password : jobSettingsStore.selectedDataMover.targetDataMoverCredentials.password) : '',
            SourcePort: BaseDrawerSettings.addDataMover ? setDatamoverDefaultPort(createJobStore.baseSettings.dataMover, "source") : jobSettingsStore.selectedDataMover != undefined ? jobSettingsStore.selectedDataMover.sourcePort : setDatamoverDefaultPort(createJobStore.baseSettings.dataMover, "source"),
            TargetPort: BaseDrawerSettings.addDataMover ? setDatamoverDefaultPort(createJobStore.baseSettings.dataMover, "target") : jobSettingsStore.selectedDataMover != undefined ? jobSettingsStore.selectedDataMover.targetPort : setDatamoverDefaultPort(createJobStore.baseSettings.dataMover, "target"),
            VeeamApiPort: createJobStore.baseSettings.veeamApiPort || 9392,
            vmHosts: createJobStore.baseSettings && createJobStore.baseSettings.vra != null && createJobStore.baseSettings.vra != undefined ? createJobStore.baseSettings.vra : '',
            fetchByDefault : rootStore.createJobStore.baseSettings.fetchByDefault !=undefined ? (createJobStore.baseSettings.dataMover == DataMover.VeeamSQL ? false:rootStore.createJobStore.baseSettings.fetchByDefault) : true
        };
        if(dataMover == DataMover.CohesityRunbook)
           {
            initialValues.datamoverTargetIP = 'Helios-dr'
            initialValues.datamoverSourceIP = 'Helios-src'
           }
        form.resetForm({ values: initialValues });
        form.validateForm(initialValues);
    }, [BaseDrawerSettings.addDataMover]);


    const handleValueChange = (event: React.ChangeEvent) => {
        form.handleChange(event);
        switch (event.target['name']) {
            //case 'SourcePassword': handleSourcePassChange(event); break;
            case 'TargetPassword':
            case 'datamoverPassword':
                handleTargetPassChange(event); break;
            case 'SourcePort': handleSourcePortChange(event); break;
            case 'TargetPort': handleTargetPortChange(event); break;
            case 'VeeamApiPort': handleVeeamPortChange(event); break;
            case 'datamoverSourceIP': handleSourceIPChange(event); break;
        }
    }

    const handleAutoCompleteChange = (event: React.ChangeEvent, value: any) => {
        if (event != null) {
            let fieldName = event.target['id'].includes('-') ? event.target['id'].split('-')[0] : event.target['id'];
            form.setFieldValue(fieldName, value);
            switch (fieldName) {
                case 'datamoverSourceIP': handleDMSourceIPChange(event, value); break;
                case 'datamoverTargetIP': handleDMTargetIPChange(event, value); break;
                case 'SourceUsername': handleSourceUsernameChange(event, value); break;
                //case 'SourceUsername': handleSourceUsernameChange(event,value,event.type=="click"?false:true); break;
                case 'datamoverUsername':
                case 'TargetUsername': 
                    handleTargetUsernameChange(event, value, event.type == "click" ? false : true); break;
            }
        }
    }

    const handleMouseClick = () => {
        if (dataMover === DataMover.SRM || dataMover === DataMover.ZertoVmWare)
            return;
        else {
            if (form.values.TargetPassword === CONSTS.MaskedPassword) {
                form.setFieldValue("TargetPassword", '', true);
            }
        }
    }

    const handleMouseOut = () => {
        if (dataMover === DataMover.SRM || dataMover === DataMover.ZertoVmWare)
            return;
        else {
            if (form.dirty && form.values.TargetPassword == '') {
                if(form.values.TargetUsername!='')
                {
                  return;
                }
                else form.setFieldValue("TargetPassword", CONSTS.MaskedPassword, true);
            }
        }
    }

    const loadSourceSettings = () => {
        const dataMover = createJobStore.baseSettings.dataMover;
        switch (rootStore.createJobStore.baseSettings.dataMover) {
            case DataMover.ZertoVmWare:
            case DataMover.SRM:
            case DataMover.Netapp:
            case DataMover.Recoverpoint:
                {
                    return (
                        <React.Fragment>
                            <Grid container style={{ marginTop: '16px' }} spacing={2}>
                                <Grid item xs={6}>
                                    <Tooltip title={t(labels.source, {dataMover:DATA_MOVER_CONFIGURATION_NAME[dataMover]})} placement="top">
                                        <FormControl className={classes.formControl} variant='outlined'>
                                            <EDRAutocomplete id="datamoverSourceIP"
                                                freeSolo
                                                disableClearable
                                                value={form.values.datamoverSourceIP}
                                                options={previousSourceIPDataMover}
                                                onChange={handleAutoCompleteChange}
                                                onInputChange={handleAutoCompleteChange}
                                                renderInput={(params) => (<EDRTextField {...params} label={t(labels.source, {dataMover:DATA_MOVER_CONFIGURATION_NAME[dataMover]})}
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="outlined"
                                                    placeholder="Type Here"
                                                    aria-autocomplete="none"
                                                    name="datamoverSourceIP" style={{ margin: '0px 8px 8px 8px' }}
                                                    onBlur={form.handleBlur}
                                                    error={form.touched && form.touched.datamoverSourceIP && form.errors && Boolean(form.errors.datamoverSourceIP)}
                                                    helperText={form.touched && form.touched.datamoverSourceIP && form.errors && Boolean(form.errors.datamoverSourceIP) && t(form.errors.datamoverSourceIP)}
                                                />)}
                                            />
                                        </FormControl>
                                    </Tooltip>
                                </Grid>

                               <IfCondition condition={dataMover !== DataMover.CohesityRunbook}>
                               <Grid item xs={6}>
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <EDRTextField id="SourcePort"
                                            name="SourcePort"
                                            label={t('port', { name: PortLabelName[dataMover] || '' })}
                                            placeholder=""
                                            style={{ margin: '0px 8px 8px 8px' }}
                                            value={form.values.SourcePort}
                                            onChange={handleValueChange}
                                            onBlur={form.handleBlur}
                                            error={Boolean(form.errors.SourcePort)}
                                            helperText={t(form.errors.SourcePort)}
                                            type="number"
                                            aria-autocomplete="none"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined" />
                                    </FormControl>
                                </Grid>
                               </IfCondition>
        
                            </Grid>

                            {/* <Grid container spacing={2} style={{marginTop:'8px'}}>
                            <Grid item xs={6}>
                            <FormControl className={classes.formControl} variant='outlined'>
                                        <EDRAutocomplete id="SourceUsername"
                                            freeSolo
                                            value={form.values.SourceUsername}
                                            options={previousSourceUsername}
                                            disabled={dataMover === DataMover.SRM || dataMover === DataMover.ZertoVmWare}
                                            renderOption={(option: string | { username: string, password: string }) => (typeof option === 'string' ? option : `${option.username}`)}
                                            getOptionLabel={(option: string | { username: string, password: string }) => (typeof option === 'string' ? option : `${option.username}`)}
                                            onChange={handleAutoCompleteChange}
                                            onInputChange={handleAutoCompleteChange}
                                            renderInput={(params) => (<EDRTextField {...params}
                                                label={t('username')}
                                                placeholder="Type Here"
                                                defaultValue={form.values.SourceUsername}
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                onBlur={form.handleBlur}
                                                error={form.touched.SourceUsername && Boolean(form.errors.SourceUsername)}
                                                helperText={form.touched.SourceUsername && form.errors.SourceUsername && t(form.errors.SourceUsername)}
                                            />)}
                                        />
                                    </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                            <FormControl className={classes.formControl} variant='outlined'>
                                        <InputLabel ref={ref => { sourcelabelRef = ReactDOM.findDOMNode(ref) }} style={{ transform: 'translate(20px,-6px) scale(0.75)' }}>{t('password')}</InputLabel>
                                        <EDROutlinedInput id="SourcePassword"
                                            name="SourcePassword"
                                            value={form.values.SourcePassword}
                                            labelWidth={sourcelabelRef ? sourcelabelRef.offsetWidth : 0}
                                            autoComplete="new-password"
                                            disabled={dataMover === DataMover.SRM || dataMover === DataMover.ZertoVmWare}
                                            type={sourcepasswordVisibility ? 'password' : 'text'}
                                            placeholder="Type Here"
                                            style={{ margin: '0px 8px 8px 8px',backgroundColor: dataMover === DataMover.SRM || dataMover === DataMover.ZertoVmWare ?'rgb(233, 235, 238)':'#ffffff' }}
                                            error={form.touched.SourcePassword && Boolean(form.errors.SourcePassword)}
                                            onChange={handleValueChange}
                                            endAdornment={showEyeSourcePassword ?
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => handleClickShowPassword("SourcePassword")}
                                                    >
                                                        {sourcepasswordVisibility ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                                : null}
                                        />
                                    </FormControl>
                            </Grid>
                        </Grid> */}
                        </React.Fragment>
                    )
                }
            case DataMover.VeeamSQL:
                {
                    return (
                        <React.Fragment>
                            <Grid container style={{ marginTop: '16px' }} spacing={2}>

                                <Grid item xs={6}>
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <EDRAutocomplete id="datamoverSourceIP"
                                            freeSolo
                                            disableClearable
                                            value={form.values.datamoverSourceIP}
                                            options={previousSourceIPDataMover}
                                            onChange={handleAutoCompleteChange}
                                            onInputChange={handleAutoCompleteChange}
                                            renderInput={(params) => (<EDRTextField {...params} label={"Veeam " + t("repositoryHostName")}
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                placeholder="Type Here"
                                                aria-autocomplete="none"
                                                name="datamoverSourceIP" style={{ margin: '0px 8px 8px 8px' }}
                                                onBlur={form.handleBlur}
                                                error={form.touched && form.touched.datamoverSourceIP && form.errors && Boolean(form.errors.datamoverSourceIP)}
                                                helperText={form.touched && form.touched.datamoverSourceIP && form.errors && Boolean(form.errors.datamoverSourceIP) && t(form.errors.datamoverSourceIP)}
                                            />)}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <EDRTextField id="SourcePort"
                                            name="SourcePort"
                                            label={"* Veeam SDK " + t('port')}
                                            placeholder=""
                                            style={{ margin: '0px 8px 8px 8px', backgroundColor: '#ffffff' }}
                                            type="number"
                                            value={form.values.SourcePort}
                                            onChange={handleValueChange}
                                            onBlur={form.handleBlur}
                                            error={Boolean(form.errors.SourcePort)}
                                            helperText={t(form.errors.SourcePort)}
                                            margin="normal"
                                            aria-autocomplete="none"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined">
                                        </EDRTextField>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )
                }
            case DataMover.VeeamVmware:
                {
                    return (
                        <React.Fragment>
                            <Grid container style={{ marginTop: '16px', marginLeft: '0%' }} spacing={2}>
                                <Grid item xs={6}>
                                    <Tooltip title={t(labels.source, {dataMover:DATA_MOVER_CONFIGURATION_NAME[dataMover]})} placement="top">
                                        <FormControl className={classes.formControl} variant='outlined'>
                                            <EDRAutocomplete id="datamoverSourceIP"
                                                freeSolo
                                                disableClearable
                                                value={form.values.datamoverSourceIP}
                                                options={previousSourceIPDataMover}
                                                onChange={handleAutoCompleteChange}
                                                onInputChange={handleAutoCompleteChange}
                                                renderInput={(params) => (<EDRTextField {...params} label={"* " + t(labels.source, {dataMover:DATA_MOVER_CONFIGURATION_NAME[dataMover]})}
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="outlined"
                                                    placeholder="Type Here"
                                                    aria-autocomplete="none"
                                                    name="datamoverSourceIP" 
                                                    onBlur={form.handleBlur}
                                                    error={form.touched && form.touched.datamoverSourceIP && form.errors && Boolean(form.errors.datamoverSourceIP)}
                                                    helperText={form.touched && form.touched.datamoverSourceIP && form.errors && Boolean(form.errors.datamoverSourceIP) && t(form.errors.datamoverSourceIP)}
                                                />)}
                                            />
                                        </FormControl>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6}>
                                <Tooltip title={t(labels.target, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})} placement="top">
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <EDRAutocomplete id="datamoverTargetIP"
                                            freeSolo
                                            disableClearable
                                            value={form.values.datamoverTargetIP}
                                            options={previousTargetIPDataMover}
                                            onChange={handleAutoCompleteChange}
                                            onInputChange={handleAutoCompleteChange}
                                            renderInput={(params) => (<EDRTextField {...params} label={t(labels.target, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})}
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                placeholder="Type Here"
                                                aria-autocomplete="none"
                                                onBlur={form.handleBlur}
                                                error={form.touched && form.touched.datamoverTargetIP && form.errors && Boolean(form.errors.datamoverTargetIP)}
                                                helperText={form.touched && form.touched.datamoverTargetIP && form.errors && Boolean(form.errors.datamoverTargetIP) && t(form.errors.datamoverTargetIP)}
                                            />)}
                                        />
                                    </FormControl>
                                </Tooltip>
                            </Grid>
                            </Grid>
                
                            <Grid container style={{ marginTop: '8px', marginLeft: '0%' }} spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <EDRAutocomplete id="datamoverUsername"
                                            freeSolo
                                            disableClearable
                                            value={BaseDrawerSettings.addDataMover ? '' : form.values && form.values.TargetUsername}
                                            options={[]}
                                            renderOption={(option: string | { username: string, password: string }) => (typeof option === 'string' ? option : `${option.username}`)}
                                            getOptionLabel={(option: string | { username: string, password: string }) => (typeof option === 'string' ? option : `${option.username}`)}
                                            onChange={handleAutoCompleteChange}
                                            onInputChange={handleAutoCompleteChange}
                                            renderInput={(params) => (<EDRTextField {...params}
                                                label={t('Veeam Server Username')}
                                                placeholder="Type Here"
                                                aria-autocomplete="none"
                                                defaultValue={form.values.TargetUsername}
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                onBlur={() => {
                                                    form.setTouched({
                                                        ...form.touched,
                                                        TargetUsername: true
                                                    })
                                                }}
                                                error={!form.isValidating && Boolean(typeof form.errors.TargetUsername == "string" ? form.errors.TargetUsername : '')}
                                                helperText={!form.isValidating && form.touched?.TargetUsername && (t(typeof form.errors?.TargetUsername == "string" ? form.errors.TargetUsername : form.errors?.TargetUsername))}
                                            />)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <InputLabel ref={ref => { labelRef = ReactDOM.findDOMNode(ref) }} style={{ transform: 'translate(14px, -5px) scale(0.75)' }}>{t('Veeam Server Password')}</InputLabel>
                                        <EDROutlinedInput id="datamoverPassword"
                                            name="datamoverPassword"
                                            value={form.values && form.values.TargetPassword}
                                            labelWidth={labelRef ? labelRef.offsetWidth : 0}
                                            autoComplete="off"
                                            type={passwordVisibility ? 'password' : 'text'}
                                            placeholder="Type Here"
                                            aria-autocomplete="none"
                                            //  style={{ backgroundColor:(displayStore.sectionSettings.platformSection ? 'rgb(233, 235, 238)': '#FFFFFF')}}
                                            error={form.touched && form.touched.SourcePassword && Boolean(form.errors.TargetPassword)}
                                            onChange={handleValueChange}
                                            onClick={event => { handleMouseClick() }}
                                            onBlur={event => { handleMouseOut() }}
                                            endAdornment={form.values.TargetPassword && DMUserPassAutocomplete ?
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => handleClickShowPassword("datamover")}
                                                    >
                                                        {passwordVisibility ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                                : null}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: '8px', marginLeft: '0%' }} spacing={2}>
                                
                                <Grid item xs={6}>
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <EDRTextField id="SourcePort"
                                            name="SourcePort"
                                            label={t(labels.sourcePort, {dataMover:DATA_MOVER_CONFIGURATION_NAME[dataMover]})}
                                            placeholder=""
                                            style={{ marginLeft: '0px' }}
                                            value={form.values.SourcePort}
                                            onChange={handleValueChange}
                                            onBlur={form.handleBlur}
                                            error={Boolean(form.errors.SourcePort)}
                                            helperText={t(form.errors.SourcePort)}
                                            type="number"
                                            aria-autocomplete="none"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined" />
                                    </FormControl>
                                </Grid>

                                <Grid xs={6} item>
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <EDRTextField id="TargetPort"
                                            name="TargetPort"
                                            label={t("veeamTargetPort")}
                                            placeholder=""
                                            style={{ backgroundColor: '#ffffff' }}
                                            type="number"
                                            aria-autocomplete="none"
                                            value={form.values.TargetPort}
                                            onChange={handleValueChange}
                                            onBlur={form.handleBlur}
                                            error={Boolean(form.errors.TargetPort)}
                                            helperText={t(form.errors.TargetPort)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined">
                                        </EDRTextField>
                            </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <EDRTextField id="VeeamApiPort"
                                            name="VeeamApiPort"
                                            label={t('VeeamApiPort')}
                                            placeholder=""
                                            style={{ backgroundColor: '#ffffff' }}
                                            type="number"
                                            value={form.values.VeeamApiPort}
                                            onChange={handleValueChange}
                                            onBlur={form.handleBlur}
                                            error={Boolean(form.errors.VeeamApiPort)}
                                            helperText={t(form.errors.VeeamApiPort)}
                                            aria-autocomplete="none"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined">
                                        </EDRTextField>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )
                }
            case DataMover.ASR: return <Tooltip title={t(labels.source, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})} placement="top">
                <FormControl className={classes.formControl} variant='outlined'>
                    <EDRAutocomplete id="datamoverSourceIP"
                        freeSolo
                        disableClearable
                        value={form.values.datamoverSourceIP}
                        options={previousSourceIPDataMover}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteChange}
                        renderInput={(params) => (<EDRTextField {...params} label={t(labels.source, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            placeholder="Type Here"
                            aria-autocomplete="none"
                            name="datamoverSourceIP" style={{ margin: '0px 8px 8px 8px' }}
                            onBlur={form.handleBlur}
                            error={form.touched && form.touched.datamoverSourceIP && form.errors && Boolean(form.errors.datamoverSourceIP)}
                            helperText={form.touched && form.touched.datamoverSourceIP && form.errors && Boolean(form.errors.datamoverSourceIP) && t(form.errors.datamoverSourceIP)}
                        />)}
                    />
                </FormControl>
            </Tooltip>
            default:
                {

                }
        }
    };

    const loadTargetSettings = () => {
        return (
            <React.Fragment>
                <IfCondition condition={dataMover != DataMover.CohesityRunbook}>
                <Grid container style={{ marginTop: '16px' }} spacing={2}>
                    <Grid item xs={6}>
                        <Tooltip title={t(labels.target, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})} placement="top">
                            <FormControl className={classes.formControl} variant='outlined'>
                                <EDRAutocomplete id="datamoverTargetIP"
                                    freeSolo
                                    disableClearable
                                    value={form.values.datamoverTargetIP}
                                    options={previousTargetIPDataMover}
                                    onChange={handleAutoCompleteChange}
                                    onInputChange={handleAutoCompleteChange}
                                    renderInput={(params) => (<EDRTextField {...params} label={t(labels.target, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        placeholder="Type Here"
                                        aria-autocomplete="none"
                                        onBlur={form.handleBlur}
                                        error={form.touched && form.touched.datamoverTargetIP && form.errors && Boolean(form.errors.datamoverTargetIP)}
                                        helperText={form.touched && form.touched.datamoverTargetIP && form.errors && Boolean(form.errors.datamoverTargetIP) && t(form.errors.datamoverTargetIP)}
                                    />)}
                                />
                            </FormControl>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        {(dataMover !== DataMover.ASR && dataMover !== DataMover.CohesityRunbook) && <FormControl className={classes.formControl} variant='outlined'>
                            <EDRTextField id="TargetPort"
                                name="TargetPort"
                                label={t(labels.targetPort, {dataMover:DATA_MOVER_CONFIGURATION_NAME[dataMover]})}
                                placeholder=""
                                style={{ margin: '0px 8px 8px 8px', backgroundColor: '#ffffff' }}
                                type="number"
                                aria-autocomplete="none"
                                value={form.values.TargetPort}
                                onChange={handleValueChange}
                                onBlur={form.handleBlur}
                                error={Boolean(form.errors.TargetPort)}
                                helperText={t(form.errors.TargetPort)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined">
                            </EDRTextField>
                        </FormControl>}

                        {dataMover == DataMover.ASR && loadSourceSettings()}
                    </Grid>
                </Grid>
                </IfCondition>

                <Grid container style={{ marginTop: '8px' }} spacing={2}>
                    <Grid item xs={6}>
                        <Tooltip title={t(labels.targetUsername, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})} placement="top">
                            <FormControl className={classes.formControl} variant='outlined'>
                                <EDRAutocomplete id="TargetUsername"
                                    freeSolo
                                    disableClearable
                                    value={form.values.TargetUsername}
                                    options={previousTargetUsername}
                                    disabled={dataMover === DataMover.SRM || dataMover === DataMover.ZertoVmWare}
                                    renderOption={(option: string | { username: string, password: string }) => (typeof option === 'string' ? option : `${option.username}`)}
                                    getOptionLabel={(option: string | { username: string, password: string }) => (typeof option === 'string' ? option : `${option.username}`)}
                                    onChange={handleAutoCompleteChange}
                                    onInputChange={handleAutoCompleteChange}
                                    renderInput={(params) => (<EDRTextField {...params}
                                        label={t(labels.targetUsername, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        placeholder="Type Here"
                                        aria-autocomplete="none"
                                        onBlur={form.handleBlur}
                                        error={form.touched && form.touched.TargetUsername && form.errors && Boolean(form.errors.TargetUsername)}
                                        helperText={form.touched && form.touched.TargetUsername && form.errors && form.errors.TargetUsername && t(form.errors.TargetUsername)}
                                    />)}
                                />
                            </FormControl>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title={dataMover === DataMover.ASR ?t(labels.targetPassword, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]}):t('dmUserPassInfo')} placement="top">
                            <FormControl className={classes.formControl} variant='outlined'>
                                <InputLabel ref={ref => { targetlabelRef = ReactDOM.findDOMNode(ref) }} disabled={dataMover === DataMover.SRM || dataMover === DataMover.ZertoVmWare} style={{ transform: 'translate(14px, -7px) scale(0.75)' }}>{t(labels.targetPassword, {dataMover: DATA_MOVER_CONFIGURATION_NAME[dataMover]})}</InputLabel>
                                <EDROutlinedInput id="TargetPassword"
                                    name="TargetPassword"
                                    value={form.values.TargetPassword}
                                    label={<InputLabel>{t("targetPassword")}</InputLabel>}
                                    labelWidth={targetlabelRef ? targetlabelRef.offsetWidth : 0}
                                    autoComplete="off"
                                    disabled={dataMover === DataMover.SRM || dataMover === DataMover.ZertoVmWare}
                                    type={DMtargetpasswordVisibility ? 'password' : 'text'}
                                    style={{ margin: '0px 8px 8px 8px', backgroundColor: dataMover === DataMover.SRM || dataMover === DataMover.ZertoVmWare ? 'rgb(233, 235, 238)' : '#ffffff' }}
                                    placeholder="Type Here"
                                    error={form.touched.TargetPassword && Boolean(form.errors.TargetPassword)}
                                    onChange={handleValueChange}
                                    onClick={handleMouseClick}
                                    onBlur={handleMouseOut}
                                    endAdornment={form.values.TargetPassword && DMUserPassAutocomplete ?
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                aria-autocomplete="none"
                                                onClick={() => handleClickShowPassword('target')}
                                            >
                                                {DMtargetpasswordVisibility ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                        : null}
                                />
                            </FormControl>
                        </Tooltip>
                    </Grid>


                    {/* <IfCondition condition={dataMover == DataMover.Netapp}>
                    <Grid item xs={12}>
                                    <FormControl className={classes.formControl} style={{ marginLeft: '7px' }} variant='outlined'>
                                        <EDRAutocomplete id="vmHosts"
                                            freeSolo
                                            disableClearable
                                            multiple={true}
                                            value={form.values && form.values.vmHosts != null && form.values.vmHosts != '' && form.values.vmHosts.split(';')}
                                            options={[]}
                                            onChange={handleVmHostChange}
                                            renderTags={(value: string[], getTagProps) =>
                                                value.map((option: string, index: number) => (
                                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                ))
                                            }
                                            renderInput={(params) => (<EDRTextField {...params} label={"Vmware Host ESXi Name/IP"}
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                placeholder="Type Here"
                                                aria-autocomplete="none"
                                                onBlur={handleVmHostBlur}
                                                error={form.touched && form.touched.vmHosts && form.errors && Boolean(form.errors.vmHosts)}
                                                helperText={form.touched && form.touched.vmHosts && form.errors && Boolean(form.errors.vmHosts) && t(form.errors.vmHosts)}
                                            />)}
                                        />
                                    </FormControl>
                                </Grid>
                    </IfCondition> */}
                </Grid>
            </React.Fragment>
        )
    };
    const getCredsId = (type: string) => {
        if (type == "source") {
            if (jobSettingsStore.selectedInfra && jobSettingsStore.selectedInfra.sourceInfraCredentials)
                return jobSettingsStore.selectedInfra.sourceInfraCredentials.id
        }
        else {
            if (jobSettingsStore.selectedInfra && jobSettingsStore.selectedInfra.targetInfraCredentials)
                return jobSettingsStore.selectedInfra.targetInfraCredentials.id
        }
    }


    const handleAdd = async () => {
        setButtonsDisabled(true);
        let sourceCredentials = new AccessCredentials();
        sourceCredentials = { id: 0, userName: form.values.SourceUsername, password: form.values.SourcePassword, userType: CredentialUserType.InfraUser };
        let targetCredentials = new AccessCredentials();
        targetCredentials = { id: 0, userName: form.values.TargetUsername, password: form.values.TargetPassword, userType: CredentialUserType.InfraUser };
        let DMDetails = new DataMoverDTO()
        DMDetails.sourceDataMoverCredentials = sourceCredentials;
        DMDetails.targetDataMoverCredentials = targetCredentials;
        DMDetails.dataMoverSource = form.values.datamoverSourceIP;
        DMDetails.dataMoverTarget = form.values.datamoverTargetIP;
        DMDetails.sourcePort = form.values.SourcePort
        DMDetails.targetPort = form.values.TargetPort
        DMDetails.dataMoverTypeId = createJobStore.baseSettings.dataMover;
        jobSettingsStore.updateTempDataMover(DMDetails);

        var sourceValidation = true;
        if(dataMover !== DataMover.CohesityRunbook)
            sourceValidation = await jobAgent.CreateJobActions.validateDataMover(getCredsId('source'), DMDetails.dataMoverSource, DMDetails.sourcePort, DMDetails.targetDataMoverCredentials.userName, DMDetails.targetDataMoverCredentials.password, DMDetails.dataMoverTypeId);
        
        var targetValidation = await jobAgent.CreateJobActions.validateDataMover(getCredsId('target'), DMDetails.dataMoverTarget, DMDetails.targetPort, DMDetails.targetDataMoverCredentials.userName, DMDetails.targetDataMoverCredentials.password, DMDetails.dataMoverTypeId);
        if (!sourceValidation) {
            toast.error(`Cannot add DataMover - Failed to autheticate to ${DMDetails.dataMoverSource}`);
            setButtonsDisabled(false);
            displayStore.updateBaseDrawerSettings({ addDataMover: true });
            return;
        }
        if (!targetValidation) {
            toast.error(`Cannot add DataMover - Failed to autheticate to ${DMDetails.dataMoverTarget}`);
            setButtonsDisabled(false);
            displayStore.updateBaseDrawerSettings({ addDataMover: true });
            return;
        }
        try {
            var res = await jobSettingsStore.createDataMover();
            jobSettingsStore.updateSelectedDataMover(res.dataMovers.find(item => item.id == res.addedDataMoverId));
            displayStore.updateBaseDrawerSettings({ DataMoverDrawerOpen: false });
            displayStore.updateScreenSettings({ displayBaseSettings: 'EmptyVlan' });
            displayStore.updateBaseDrawerSettings({ addDataMover: false });
            setButtonsDisabled(false);
        }
        catch
        {
            toast.error(t('dataMoverExist'));
            displayStore.updateBaseDrawerSettings({ DataMoverDrawerOpen: false });
            displayStore.updateScreenSettings({ displayBaseSettings: 'EmptyVlan' });
            displayStore.updateBaseDrawerSettings({ addDataMover: true });
            setButtonsDisabled(false);
            displayStore.updateBaseDrawerSettings({ addDataMover: true });
        }

    }

    const handleUpdate = async () => {
        setButtonsDisabled(true);
        let sourceCredentials = new AccessCredentials();
        sourceCredentials = { id: jobSettingsStore.selectedDataMover.sourceDataMoverCredentials.id, userName: form.values.SourceUsername, password: form.values.SourcePassword, userType: CredentialUserType.InfraUser };
        let targetCredentials = new AccessCredentials();
        targetCredentials = { id: jobSettingsStore.selectedDataMover.targetDataMoverCredentials.id, userName: form.values.TargetUsername, password: form.values.TargetPassword, userType: CredentialUserType.InfraUser };
        let DMDetails = {
            id: createJobStore.baseSettings.dataMoverId,
            dataMoverTypeId: createJobStore.baseSettings.dataMover,
            sourceDataMoverCredentials: sourceCredentials,
            targetDataMoverCredentials: targetCredentials,
            dataMoverSource: form.values.datamoverSourceIP,
            dataMoverTarget: form.values.datamoverTargetIP,
            sourcePort: form.values.SourcePort,
            targetPort: form.values.TargetPort
        };
        try {
            var res = await jobSettingsStore.editDataMover(DMDetails)
            displayStore.updateBaseDrawerSettings({ DataMoverDrawerOpen: false });
            displayStore.updateScreenSettings({ displayBaseSettings: 'EmptyVlan' });
            setButtonsDisabled(false);
        }
        catch
        {
            toast.error(`Cannot update Data Mover`);
            setButtonsDisabled(false);
        }
    }

    const handleDelete = async () => {
        setButtonsDisabled(true);
        await jobSettingsStore.deleteDataMover();
        setButtonsDisabled(false);
    }

    const handleConfirmationPopup = (confirm: boolean) => {
        if (confirm) {
            handleDelete();
        }
        setopenConfirmationDialog(false);
    }

    const onToggleChange = (event) => {
        setToggle(event.currentTarget.value);
        createJobStore.updateBaseSettings({ veeamSolution: event.currentTarget.value == "backup" ? VeeamSolution.Backup : VeeamSolution.Replica });
    };

    const loadVeeamSolutionSection = () => {
        if (createJobStore.baseSettings.dataMover == DataMover.VeeamVmware)
            return (
                <Grid item container justify="center" direction="column" style={{ marginTop: "15px", marginBottom: "15px", display: 'flex', flexDirection: 'row' }}>
                    <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                        <Typography style={{ marginLeft: '4%' }}>{t('chooseSolution')}</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                        <ToggleButtonGroup classes={{ root: "toggle_button_group" }} value={toggle} onChange={onToggleChange}>
                            <ToggleButton classes={{ root: "toggle_button", selected: "toggle_button_selected" }} value="backup">{t('Backup')}</ToggleButton>
                            <ToggleButton classes={{ root: "toggle_button", selected: "toggle_button_selected" }} value="replication">{t('Replication')}</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
            )
    }

    const handleFetchByDefaultChange = (event) =>{
        form.setFieldValue('fetchByDefault',event.target.checked,true);
        rootStore.createJobStore.updateBaseSettings({fetchByDefault: event.target.checked});
    };


    return (
        <Grid container direction="column" style={{ paddingRight: 16 }}>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant='h6' className={classes.MainHeading}>{createJobStore.baseSettings.dataMover == DataMover.ASR ? (BaseDrawerSettings.addDataMover ? t("addRecoveryFault") : t("editRecoveryFault")) :(BaseDrawerSettings.addDataMover ? t("addDataMover") : t("editDataMover"))}</Typography>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {!BaseDrawerSettings.addDataMover ? <SaveButton onClick={() => setopenConfirmationDialog(true)} disabled={buttonsDisabled} variant="contained" color="primary" style={{ height: '25px', textTransform: 'none', marginRight: '10px' }}>
                        {t('delete')}
                        {buttonsDisabled ? <CircularProgress id="processing" size={18} style={{ color: '#2892D7', marginRight: '6%', marginTop: '-3px' }} /> : null}
                    </SaveButton> : null}
                    <SaveButton onClick={BaseDrawerSettings.addDataMover ? handleAdd : handleUpdate} disabled={form.isValid == false || buttonsDisabled} variant="contained" color="primary" style={{ height: '25px', textTransform: 'none' }}>
                        {BaseDrawerSettings.addInfrastructure || BaseDrawerSettings.addDataMover ? t('add') : t('apply')}
                        {buttonsDisabled ? <CircularProgress id="processing" size={18} style={{ color: '#2892D7', marginRight: '6%', marginTop: '-3px' }} /> : null}
                    </SaveButton>
                </div>
            </div>

            <Grid item xs={8} style={{marginTop: '10px'}}>
                <FormControlLabel 
                    value={t('fetchByDefault')}
                    control={<CustomCheckbox icon={<CircleUnchecked/>} checkedIcon={<CircleCheckedFilled />} checked={form.values.fetchByDefault} onChange={handleFetchByDefaultChange}/>}
                    label={<Typography>{t('fetchByDefault')}</Typography>}
                />
            </Grid>

            {createJobStore.baseSettings.dataMover != DataMover.VeeamVmware ?
                <Grid item container direction="row">
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px', marginLeft: '10px' }}>
                        <Typography className={classes.SecondHeading} style={{ color: '#4d5f75' }}>{t('targetSettings')}</Typography>
                        <Tooltip title={createJobStore.baseSettings.dataMover == DataMover.ASR ? t("ASRDMtargetSettingsInfo"):t("DMtargetSettingsInfo")} arrow>
                            <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                        </Tooltip>
                        <Divider className={classes.divider} />
                    </div>
                </Grid> : null}
            {createJobStore.baseSettings.dataMover != DataMover.VeeamVmware ? loadTargetSettings() : null}

            <IfCondition condition={dataMover !== DataMover.ASR && dataMover !== DataMover.CohesityRunbook}>
                <Grid item container direction="row">
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px', marginLeft: '10px' }}>
                        <Typography className={classes.SecondHeading} style={{ color: '#4d5f75' }}>{dataMover == DataMover.VeeamVmware ? t('veeamSettings') : t('sourceSettings')}</Typography>
                        <Tooltip title={t("DMsourceSettingsInfo")} arrow>
                            <HelpIcon className={classes.helpIcon} style={{ marginLeft: '16px' }} />
                        </Tooltip>
                        <Divider className={classes.divider} />
                    </div>
                </Grid>
            </IfCondition>
            {dataMover !== DataMover.ASR && loadSourceSettings()}

            {loadVeeamSolutionSection()}

            <ConfirmationDialog type="deleteDM" device={null} open={openConfirmationDialog} onClose={(confrim) => handleConfirmationPopup(confrim)} children={"You are about to delete existing data mover, are you sure ?"}></ConfirmationDialog>
        </Grid>
    );
};

export default observer(DataMoverAdvancedComponent);

