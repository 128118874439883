import { useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router';
import { License } from '../../../../app/models/createjob/createJob.model'
import { LicenseType } from '../../../../app/models/jobProperties.model';
import { UpdateType } from '../../../../app/stores/activityStore';
import { RootStoreContext } from '../../../../app/stores/root.store';
import { useAnyJobs } from '../../../navigation/hooks/useAnyJobs';

function useLicensesList():[Array<License>, boolean] {
    const [licenses, setlicenses] = useState<Array<License>>([]);
    const location = useLocation();
    const { jobSettingsStore, createJobStore , activityStore} = useContext(RootStoreContext);
    const [anyJobs, isAnyJobCheckLoading] = useAnyJobs()
    const [isLoading, setisLoading] = useState(true);
    useEffect(() => {
       if(isAnyJobCheckLoading)
            return;
            jobSettingsStore.getLicenese().then((licensesList) => {
                const { baseSettings: { licenseId: selectedLicenseId } } = createJobStore;
                if (location.pathname.includes('editJob')) {
    
                    const lic = licensesList.find(l => l.id === selectedLicenseId);
                    if (lic?.licenseType === LicenseType.FirstTrial)
                        setlicenses([...licensesList])
                    else
                        setlicenses(licensesList.filter(l => l.licenseType !== LicenseType.FirstTrial))
        
                    return;
                }
                if (isAnyJobCheckLoading)
                    return;
                if (anyJobs) {
                    // send all licenses except trial
                    setlicenses([...licensesList.filter(l => l.licenseType !== LicenseType.FirstTrial)]);
                } else {
                    setlicenses([...licensesList])
                }
                
                if(!selectedLicenseId) {
                    var firstAvailableLicense = licensesList.find(l => l.licenseType !== LicenseType.FirstTrial);
                     firstAvailableLicense = !firstAvailableLicense && !anyJobs ? licensesList[0] : firstAvailableLicense;
                    createJobStore.updateBaseSettings({licenseName: firstAvailableLicense?.name, licenseId: firstAvailableLicense?.id||0});
                    jobSettingsStore.updateSelectedLicense(firstAvailableLicense);
                }
                
    
    
            }).finally(() => {
                setisLoading(false);
            })
    }, [anyJobs, isAnyJobCheckLoading])

    useEffect(() => {
        const onAddLicense = (license:License) => {
            setlicenses([license, ...licenses]);
        }
        const onDeleteLicense = (licenseId: number) => {
            setlicenses(licenses.filter(l => l.id !== licenseId));
            var tmpLicenses = licenses.filter(l => l.id !== licenseId);
            var firstAvailableLicense = tmpLicenses.find(l => l.licenseType !== LicenseType.FirstTrial);
            firstAvailableLicense = !firstAvailableLicense && !anyJobs ? tmpLicenses[0] : firstAvailableLicense;
            createJobStore.updateBaseSettings({licenseName: firstAvailableLicense?.name, licenseId: firstAvailableLicense?.id||0});
            jobSettingsStore.updateSelectedLicense(firstAvailableLicense);
        }

        const onUpdateLicense = (license: License) => {
            setlicenses(licenses.map(l => l.id === license.id ? license: l))
        }
        
      activityStore.on(UpdateType.LicenseAdded, onAddLicense);
      activityStore.on(UpdateType.LicenseDeleted, onDeleteLicense);
      activityStore.on(UpdateType.LicenseUpdated, onUpdateLicense);
        return () => {
            
      activityStore.off(UpdateType.LicenseAdded, onAddLicense);
      activityStore.off(UpdateType.LicenseDeleted, onDeleteLicense);
      activityStore.off(UpdateType.LicenseUpdated, onUpdateLicense);
        }
    }, [licenses, setlicenses])


    return [licenses, isLoading]
}

export default useLicensesList;