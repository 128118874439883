import React, { useEffect, useContext }  from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/root.store';
import { Card, Grid, makeStyles } from '@material-ui/core';
import { Redirect, Route, Switch } from 'react-router-dom';
import EmptyVlanComponent from '../NetworkInfo/Vlan/EmptyVlan.component';
import clsx from 'clsx';
import DataMoverAdvancedComponent from './Advanced/DataMoverAdvanced.component';
import InfrastructAdvancedComponent from './Advanced/InfrastructAdvanced.component';
import EDRServerSettingsComponent from '../NetworkInfo/Vlan/EDRServerSettings.component';
import BasicAdvancedComponent from './Advanced/BasicAdvanced.component';
import AzureAdvancedComponent from './Advanced/AzureAdvanced.component';
import LicenseAdvancedComponent from './Advanced/LicenseAdvanced.component';
import DataMoverCreateAdvancedComponent from './Advanced/DataMoverCreateAdvanced.component';
import InfrastructCreateAdvancedComponent from './Advanced/InfrastructCreateAdvanced.component';
import PrimaryAdvancedComponent from './Advanced/PrimaryAdvanced.component';
import SecondaryAdvancedComponent from './Advanced/SecondaryAdvanced.component';


const BaseSettingAdvancedComponent : React.FC = () => {
    const {displayStore} = useContext(RootStoreContext); 
    const useStyles = makeStyles({
        card: {
            padding: 16,
            width: '100%',
            height: '99.8%',
            overflowY: 'scroll'
        }
        });
        
    const classes = useStyles();
     
    return(
         <Card elevation={1} classes={{root: clsx([classes.card,"hide-scrollbar"])}}>
         {displayStore.screenSettings.displayBaseSettings== 'EmptyVlan' ? <EmptyVlanComponent /> : null }
         {displayStore.screenSettings.displayBaseSettings== 'BasicAdvanced' ? <BasicAdvancedComponent /> : null }
         {displayStore.screenSettings.displayBaseSettings== 'LicenseAdvanced' ? <LicenseAdvancedComponent /> : null }
         {displayStore.screenSettings.displayBaseSettings== 'DataMoverAdvanced' ? <DataMoverAdvancedComponent /> : null }
         {displayStore.screenSettings.displayBaseSettings== 'InfrastructureAdvanced' ? <InfrastructAdvancedComponent /> : null }
         {displayStore.screenSettings.displayBaseSettings== 'DataMoverCreateAdvanced' ? <DataMoverCreateAdvancedComponent /> : null }
         {displayStore.screenSettings.displayBaseSettings== 'InfrastructureCreateAdvanced' ? <InfrastructCreateAdvancedComponent /> : null }
         {displayStore.screenSettings.displayBaseSettings== 'PrimaryAdvanced' ? <PrimaryAdvancedComponent /> : null }
         {displayStore.screenSettings.displayBaseSettings== 'SecondaryAdvanced' ? <SecondaryAdvancedComponent /> : null }
         {displayStore.screenSettings.displayBaseSettings== 'AzureAdvanced' ? <AzureAdvancedComponent /> : null }
         {displayStore.screenSettings.displayBaseSettings== 'EDRServerSettings' ? <EDRServerSettingsComponent /> : null }
        </Card>    
    );
};

export default observer(BaseSettingAdvancedComponent);