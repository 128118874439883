import  SvgIcon  from "@material-ui/core/SvgIcon";
import React from "react"
import {ReactComponent as NetworkSvg} from './svgs/network.svg';

const NetworkIcon:React.FC<React.HTMLProps<SVGElement>> = (props) => {
    const {style, className} = props;
    return <SvgIcon viewBox={"-2,-2, 20, 20"}  className={className} style={style} component={NetworkSvg}>
    </SvgIcon>
}


export default NetworkIcon;