import { useEffect, useState } from "react";
import jobAgent from "../../api/jobAgent";
import { TestGroup } from "../../models/jobProperties.model";


function useTestGroups(jobId: number): [Array<TestGroup>, { [key: number]: string }] {
    const [testGroups, setTestGroups] = useState<Array<TestGroup>>([]);
    const [testGroupNames, settestGroupNames] = useState<{ [key: number]: string }>({})
  
    useEffect(() => {
        let isCancelled = false;
        if (!jobId)
            return;
        jobAgent.JobActions.listTestGroups(jobId).then(res => {
            if (isCancelled)
                return;
            setTestGroups(res);
            settestGroupNames(res.reduce((acc, group) => {
                acc[group.groupOrder] = group.groupName;
                return acc;
            }, {}))
        }).catch(err => {
            console.log(err);
        })
        return () => {
            isCancelled = true;
        }
    }, [jobId])

    return [testGroups, testGroupNames];
}

export default useTestGroups;