import React, { useEffect, useContext, useState }  from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/root.store';
import { Dialog, useMediaQuery, useTheme, DialogContent, Grid, Typography, Button, makeStyles, InputLabel, FormControl, OutlinedInput, FormHelperText, Select, MenuItem, List, IconButton, ListItem, ListItemIcon, Checkbox, ListItemText, ListItemSecondaryAction, CircularProgress, DialogActions, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import jobAgent from '../../../app/api/jobAgent';
import { toast } from 'react-toastify';
import {JobRename} from "../../../app/models/jobactions/jobRename.model";
import {history} from '../../../app/common/util/history';
import queryString from 'querystring';
import { EDRTextField } from '../../../app/common/EDRTextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { SaveButton } from '../../../app/common/saveButton';
import { CancelablePromise } from '../../../app/common/util/cancellablePromise';
import { DataMover, VeeamSolution } from '../../../app/models/BaseSettings.enum';
import { EDRDialogTitle } from '../../../app/common/EDRDialogTitle';
import useDevices from './hooks/getDevice.hook';

const { JobActions } = jobAgent;
type FilterByJobNameProps = {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
}



const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  textfield: {
    position: 'relative',
    "& .MuiFormHelperText-root": {
      position: 'absolute'
    }
  },
  label: {
    textTransform: 'capitalize',
  },
  planLabel: {
    color:"black"
  },
  formControl: {
    width:'100%',
    '& label': {
      background: "white",
      width: "auto",
      marginTop: "1px",
      padding:"0 4px 0 4px"
    }
  },
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface FetchDeviceRequest {
  infrastructureId: number,
  dataMoverId: number,
  dataMoverType: DataMover,
  workflowUser: string;
  workflowPassword: string;
  workflowUserCredsId: number;
  veeamApiPort?: string | number;
  planNames?: Array<string>;
  jobType?: VeeamSolution

}

const FilterByJobName: React.FC<FilterByJobNameProps> = (props) => {
    const {onClose,onCancel, open} = props;
    const { activityStore,createJobStore,jobSettingsStore } = useContext(RootStoreContext); 
    const theme = useTheme();    
    const [devices,devicesAll]= useDevices();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const {t: errorTranslations} = useTranslation('errors');
    const {t} =  useTranslation('createJobDevices');
    const [selectedPlanNames, setSelectedPlanNames] = React.useState<Array<string>>([]);
    const [planNames, setPlanNames] = React.useState<Array<string>>([]);
    const [anyCustoms, setAnyCustoms] = useState(false);
    const [failedFetch, setFailedFetch] = useState(false);
    
    
    const {CreateJobActions } = jobAgent;
    const classes = useStyles();

    useEffect(()=>{
      const payload: FetchDeviceRequest = {
        infrastructureId: jobSettingsStore.selectedInfra.id,
        dataMoverId: createJobStore.baseSettings.dataMoverId,
        dataMoverType: createJobStore.baseSettings.dataMover,
        workflowPassword: createJobStore.baseSettings.workflowTest.PrimaryPassword,
        workflowUser: createJobStore.baseSettings.workflowTest.PrimaryUser,
        workflowUserCredsId: createJobStore.baseSettings.workflowTest.PrimaryUserCredsId ? createJobStore.baseSettings.workflowTest.PrimaryUserCredsId : 0,
        veeamApiPort: createJobStore.baseSettings.veeamApiPort,
        planNames: createJobStore.baseSettings.planNameFilter,
        jobType: createJobStore.baseSettings.veeamSolution
    };
    CreateJobActions.AnyCustomDevices().then(resCustom => {
      setAnyCustoms(resCustom);
      const fetchPlanNamespromise = CreateJobActions.ListPlanNames({ ...payload }, "cacheFirst") as CancelablePromise<Array<string>>;
      Promise.resolve(fetchPlanNamespromise).then(res => {
        setPlanNames(res);
        if(resCustom)
        {
          if(!res.includes("Custom Devices"))
          {
            res.push("Custom Devices");
          }  
        }
    })
    .catch(err => {
      setFailedFetch(true);
      /*if(resCustom)
        {
          planNames.push("Custom Devices");
        }
      else
      {
        setFailedFetch(true);
      }*/
    });
    });
    },[]);

    const handleSave = () =>
    {
      onClose();
    }
   
    const cancel = () =>
    {
      onCancel();
    }

    const handleToggle = (value) => () => {
    if(selectedPlanNames.includes(value))
    {
      var updatedList = selectedPlanNames.filter(item=>item!=value);
      setSelectedPlanNames(updatedList);
      createJobStore.updateBaseSettings({planNameFilter:updatedList})
    }
    else
    {
      selectedPlanNames.push(value);
      setSelectedPlanNames(selectedPlanNames);
      createJobStore.updateBaseSettings({planNameFilter:selectedPlanNames})
    }
   };

   const IsAllPlanSelected =(value)=>{
    let res=false;
    let countSelected=0;
    let countInPlan=0;
    devices.map(device=>{
      if(device.planName == value)
      {
        countInPlan++;
      }
    });
    createJobStore.selectedDevices.map(item=>{
      if(item.planName == value)
      {
        countSelected ++;
      }
    });
    if(countInPlan == countSelected)
    {
      res =true;
    }
    return res;
   }

return(
  <Dialog disableBackdropClick
  open={open}
  classes={{ paper: classes.root }}
  scroll="paper"
  fullScreen={matchesSM}
>
  <EDRDialogTitle>{t("filterByPlanTitle")}</EDRDialogTitle>
  <DialogContent dividers={true} >
  <Grid container direction="column" spacing={3} >
                  <Grid item>
                    <Typography align="left" style={{width:250}} classes={{ root: "rename_form_title"}}>
                    {t("filterByPlanTitle")}
                    </Typography>
                  </Grid>
                  <Grid item>
                  {planNames.length == 0 ?
                   <div style={{display: 'flex', flexDirection: 'row',justifyContent: 'center' , alignItems: 'center'}}>
                     {failedFetch ? <Typography>{t("emptyDevices")}</Typography> : <CircularProgress  size={50} style={{color: '#2892d7'}}/>}
                    </div>
                  :<List className={classes.root}>
                  {planNames.map((value) => {
                  const labelId = `${value}`;
                  if(createJobStore.selectedDevices.find(item=>item.planName == value) && !IsAllPlanSelected(value))
                  return(
                   <span>
                     <Tooltip title={t("tooltipDisabled")} arrow>
                       <div>
                    <ListItem
                    key={value}
                    role={undefined}
                    disabled ={createJobStore.selectedDevices.find(item=>item.planName == value) ? (!IsAllPlanSelected(value)?false:true):false}
                    dense
                    button
                    onClick={handleToggle(value)}
                  >
            <ListItemIcon>
              <Checkbox
                edge="start"
                color="primary"
                disabled={createJobStore.selectedDevices.find(item=>item.planName == value)? (!IsAllPlanSelected(value)?false:true):false}
                checked={createJobStore.baseSettings.planNameFilter.includes(value)}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": labelId }}
              />
            </ListItemIcon>
            <ListItemText classes={{primary: classes.planLabel}} id={labelId} primary={`${value}`} />
                    </ListItem>
                    </div>
                </Tooltip>
                </span>);
                else return(
                <ListItem
                  key={value}
                  role={undefined}
                  disabled ={createJobStore.selectedDevices.find(item=>item.planName == value) ?(!IsAllPlanSelected(value)?false:true):false}
                  dense
                  button
                  onClick={handleToggle(value)}
                >
          <ListItemIcon>
            <Checkbox
              edge="start"
              color="primary"
              disabled={createJobStore.selectedDevices.find(item=>item.planName == value)?(!IsAllPlanSelected(value)?false:true):false}
              checked={createJobStore.baseSettings.planNameFilter.includes(value)}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": labelId }}
            />
          </ListItemIcon>
          <ListItemText classes={{primary: classes.planLabel}} id={labelId} primary={`${value}`} />
          </ListItem>
          );
              })}
                  </List>}
                  </Grid>
                </Grid>
  </DialogContent>
  <DialogActions>
  <Grid item xs={6} style={{display:'flex',justifyContent:'flex-end'}}>
        <Button
         classes={{ root: "Cancel", label: classes.label}}
         style={{ marginRight: "2em", marginTop: "0.5em" }}
         onClick={() => cancel()}>
        { t("filterCancel")}
        </Button>

        <SaveButton
          disabled={planNames.length == 0}
          variant="contained"
          color="primary"
          style={{textTransform:'none'}}
          onClick={handleSave}>
          {t("filterChange")}
          </SaveButton>
    </Grid>
  </DialogActions>
  </Dialog>
)
}

export default observer(FilterByJobName);