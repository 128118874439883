import { observable, computed, action, runInAction } from "mobx";
import { IUser, IUserFormValues } from "../models/user.model";
import agent from "../api/userAgent";
import agentBase from '../api/agentBase';
import { RootStore } from './root.store';
import { history } from '../common/util/history';
import { IUsedLicences } from "../models/licenseUsage.model";
import { ILicenseDevicesDto } from "../models/licenseDevicesDto.model";

const {axios} = agentBase;

export default class UserStore {
    
    rootStore: RootStore;
  
    constructor(rootStore:RootStore) {
        this.rootStore = rootStore;
        axios.interceptors.response.use(undefined,error => {
            const {status, data, config, headers} = error.response || {};
             if (status === 401 && 
                 String(headers['www-authenticate']).startsWith('Bearer error="invalid_token", error_description="The token expired')) 
                 {
                     window.localStorage.removeItem('jwt');
                     runInAction(() => {
                         this.user = null;
                     })
                 }
             throw error.response;
         })
    }

    @observable user: IUser | null = null;
    @observable userProfile: IUserFormValues | null = null;
    @observable loading = false;
    @observable loginError = false;
    @observable profileUpdateError = false;
    @observable isChecked = true;
    @observable dialogOpen = false;
    @observable registerDialogOpen = false;
    @observable helpDialogOpen = false;
    @observable loadingProfile = false;
    @observable displaynameBar = '';
    @observable registerError = false;
    @observable registerOK = false;
    @observable usedLicences: IUsedLicences | null = null;
    @observable usedDevicesById : ILicenseDevicesDto | null = null;

    get isFirstLogin() {
        const isFIrst = !window.localStorage.getItem('isFirstLogin');
        return isFIrst;
    }

    @computed get isLoggedIn() {
        return !!this.user;
    }

    @action login = async (values: IUserFormValues) => {
        this.loading = true;
        console.log("in rememberme value is"+values.remember)
        try {
            const user =  await agent.User.login(values);
            runInAction(() => { //runInaction is needed, since lines of code after the await are considered "out of action"
                this.user = user; 
                this.loading = false;   
                this.loginError = false;
                this.isChecked = values.remember == "yes" ? true : false;
            })
            this.rootStore.commonStore.setToken(user.token);
            history.push('/dashboard');
        } catch (error) {
            runInAction(() => { 
                this.loading = false;
                this.loginError = true;
            })
            throw(error)
        }
    }

    @action autologin = async () => {
        this.loading = true;
        try {
            const user =  await agent.User.autoLogin();
            window.localStorage.setItem('isFirstLogin', 'Done');
            if(!user) 
                return;
            runInAction(() => { //runInaction is needed, since lines of code after the await are considered "out of action"
                this.user = user; 
                this.loading = false;   
                this.loginError = false;
                // this.isChecked = values.remember == "yes" ? true : false;
            });
          
            this.rootStore.commonStore.setToken(user.token);
            history.push('/jobs');
        } catch (error) {
            runInAction(() => { 
                this.loading = false;
                // this.loginError = true;
            })
            // throw(error)
        }
    }

    @action getUsedLicences = async () => { 
        agent.User.getUsedLicences().then(res => {
            runInAction(() => { this.usedLicences =res});
        })
    }

    @action getLicenseDevices = async (id: number) => { 
        agent.User.getLicenseUsageById(id).then(res => {
            runInAction(() => { this.usedDevicesById =res});
        })
    }

    @action register = async (values: IUserFormValues) => {
        try {
            const user = await agent.User.register(values);
            this.registerOK = true;
            this.rootStore.commonStore.setToken(user.token);
            history.push('/welcome');
        } catch (error) {
            this.registerError = true;
            throw(error);
        }
    }

    @action getUser = async () => {
        if(!this.rootStore.commonStore.token)
         {
             return;
         }
        this.loadingProfile = true;
        try {
            const user = await agent.User.current();
            runInAction(() => {
                this.user = user;
                this.loadingProfile = false;
            })
        } catch (error) {
            console.log(error);    
        }
    }

    @action getUserProfile = async (username: string) => {
        this.loadingProfile = true;
        this.profileUpdateError = false;
        try {
            const userProfile = await agent.User.get(username);
            runInAction(() => {
                this.userProfile = userProfile;
                this.loadingProfile = false;
                return userProfile;
            })
        } catch (error) {
            runInAction(() => {
                this.loadingProfile = false;
            })
            console.log(error);    
        }
    }

    @action logout = () => {
        this.rootStore.commonStore.setToken(null);
        this.user = null;
        history.push('/');
    }

    @action setOpen = (state: boolean) => {
        this.dialogOpen = state;
    }

    @action setOpenRegister = (state: boolean) => {
        this.registerDialogOpen = state;
    }

    @action setHelpOpen = (state: boolean) => {
        this.helpDialogOpen = state;
    }

    @action updateUsedDevicesById(newValue?: ILicenseDevicesDto)
    {
        this.usedDevicesById = newValue;        
    }

    @action updateProfile = async (user: IUserFormValues) => {
        this.loadingProfile = true;
        try{
            await agent.User.updateProfile(user);
            runInAction(() => {
                if (user.displayName !== this.rootStore.userStore.user!.displayName) {
                    this.rootStore.userStore.user!.displayName = user.displayName!;
                }
                this.user = { ...this.user!, ...user}
                this.userProfile = user;
                this.profileUpdateError = false;
                this.loadingProfile = false;
            })
        } catch (error) {
            runInAction(() => {
                this.profileUpdateError = true;
                this.loadingProfile = false;
            })
            console.error('Problem updating profile');
        }
    }
}