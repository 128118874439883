import React, { useReducer } from 'react';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import JobResultsApi from '../api/jobResult.api';
import { useTranslation } from 'react-i18next';
import { RRSTotals } from '../models/reportdata.model';

 type LogViewProps = {
    jobResultId: number;
    jobName: string;
    open: boolean;
    rRSTotals: RRSTotals;
    onClose: () => void;
}

const useStyles = makeStyles({
  root: {
      minWidth: '25vw',
      minHeight: '60vh'
  },
  close_button: {
    width: 60,
    height: 32,
    background: '#2892d7 !important',
    color: 'white !important',
    borderRadius: '28px !important',
    fontSize:14,
    fontWeight:500
  },
  wrapper: {
      counterReset: 'line',
      color: 'black'
  },
  line: {
      '&::before' :{
          content: '""counter(line)""',
          counterIncrement: "line"
      }
  },
  TimeBreakdown: {
    fontSize: '16px',
    fontWeight: 500,
    textAlign: 'left',
    color: '#1f396d'
  },
  card: {
    padding: 16,
    // width: '100%',
    height: '100%',
    marginTop:'-14px',
    marginLeft:'-15px'
  },
  button:{
      backgroundColor: 'transparent',
      backgroundRepeat:'no-repeat',
      cursor:'pointer',
      overflow: 'hidden',
      outline:'none',
  },
  advanced:{
      width: '14px',
      height: '14px',
      objectFit: 'contain'
  },
  textHeading:{
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.14',
      letterSpacing: 'normal',
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      marginLeft: '20px',
      color: '#4d5f75'
  },
  verticalLine:{
      width: '19px',
      height: '0',
      marginLeft: '3px',
      border: '0.5px solid #2892d7'
  },
  divWithoutCircle:{
      height: '7px',
      width: 'auto',
      position: 'relative',
      alignContent: 'center',
      alignItems: 'center',
      "&:before": {
          width: '0',
          height: '100%',
          position: 'absolute',
          content: "' '",
          display: 'inline-block',
          borderLeft: '1px dashed #2892d7',
          left:'3px',
          bottom:'30px'
      },
      "&:after": {
          width: '10px',
          height: '10px',
          display: 'block',
          content:  "' '",
          left: '-1px',
          top: 'calc(50% - 10px)',
          position: 'absolute'
      }
  },
  div :{
      height: '40px',
      width: '40px',
      position: 'relative',
      alignContent: 'center',
      alignItems: 'center',
      "&:before": {
          width: '0',
          height: '100%',
          position: 'absolute',
          content: "' '",
          display: 'inline-block',
          borderLeft: '1px dashed #2892d7',
          left:'16px',
          bottom:'30px'
      },
      "&:after": {
          width: '8px',
          height: '8px',
          display: 'block',
          content:  "' '",
          background: '#2892d7',
          borderRadius: '10px',
          left: '13px',
          top: 'calc(50% - 5px)',
          position: 'absolute'
      },
      "&:first-child:before ": {
          bottom: '-16px'
        },
      "&:last:after": {
          top: 'calc(100% - 5px)'
        }
    },
  divLast :{
      height: '40px',
      width: '40px',
      position: 'relative',
      alignContent: 'center',
      alignItems: 'center',
      "&:before": {
          width: '0',
          height: '40px',
          position: 'absolute',
          content: "' '",
          display: 'inline-block',
          left:'3px',
          bottom:'6px'
      },
      "&:after": {
          width: '8px',
          height: '8px',
          display: 'block',
          content:  "' '",
          background: '#2892d7',
          borderRadius: '10px',
          left: '13px',
          top: 'calc(50% - 5px)',
          position: 'absolute'
      },
      "&:first-child:before ": {
          bottom: '-16px'
        }
    },
    errorText:{
      color:'red',
      marginTop:'10px',
      fontSize: '0.75rem',
      textAlign: 'left',
      fontFamily: "Roboto",
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: '0.03333em'
    },
    helpIcon:
    {
      color: '#1976d2',
      fontSize: "large",
      marginTop:'8px'
    },
    ScriptButton:{
          borderRadius: '4px',
          border: 'solid 1px #c7cfdb',
          backgroundColor: '#fafafc',
          minWidth: '40px',
          minHeight: '35px',
          marginRight: '8px',
    },
    timeHeader:
    {
      fontWeight:500,
      color:'#4d5f75',
      width:'25%',
      marginTop:10
    },
    timeData:
    {
      fontWeight:'normal',
      color:'#4d5f75',
      width:'54%', 
      marginTop:10
    },
    timePercent:
    {
      fontWeight:'normal',
      color:'#4d5f75',
      width:'21%', 
      marginTop:10
    }
})

const RRSDialog: React.FC<LogViewProps> =  ({jobResultId, open, onClose, jobName, rRSTotals}) => {
function reducer (state, action: {type: string, value?: any}): {isLoading: boolean, dataLoaded: boolean, data: string, error: string} {
    switch(action.type) {
        case 'loadingData': return {...state, isLoading: true, error: null};
        case 'dataFetched': return {...state, isLoading: false, dataLoaded: true, data: action.value};
        case 'fetchError': return {...state, isLoading: false, dataLoaded: true, error: action.value}
    }
    return state;
}
const [state, dispatch] = useReducer(reducer, {
    dataLoaded: false,
    data: null,
    isLoading: false,
    error: null
} )

const classes = useStyles();
const [t] = useTranslation("reportDialog");
const descriptionElementRef = React.useRef<HTMLElement>(null);
  
React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
      dispatch({type: 'dataFetched', value: "need to fill data"});
      /*dispatch({type: 'loadingData'});
      JobResultsApi.getRRS(jobResultId).then((data) => {
        let RRSData;
        dispatch({type: 'dataFetched', value: "need to fill data"});
      }).catch(err => {
        dispatch({type: 'fetchError', value: t("fetchErrorRRS")});
      });*/
    }
  }, [open, jobResultId]);

  const handleClose = () => {
      onClose && onClose();
  }

  return (
    <div>
      <Dialog
        open={open}
        classes={{paper: classes.root}}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" style={{padding:'15px 24px 0 24px'}}>
          <Typography variant="body1" style={{fontWeight:500,fontSize:'16px',color:'#1f396d'}}>{t('rrs')}</Typography>
          <Typography variant="body1" style={{fontWeight:500,fontSize:'14px',color:'#8092a9'}}>{jobName} {t('rrssub')}</Typography>
        </DialogTitle>
        <DialogContent dividers={true} style={{border:'none', padding:'0 24px 0 24px'}}>
          <DialogContentText
            style={{whiteSpace: 'pre-wrap'}}
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
          {state.isLoading && <LinearProgress />}
          <div className={classes.wrapper}>
          {rRSTotals != null ? 
            <div style={{overflowY:'hidden'}}>
              <Grid item style={{display:'flex',height:40}}>
              <div style={{width:40}}></div>
              <Typography className={classes.timeHeader} style={{width:'25%'}}></Typography>
              <Typography className={classes.timeHeader} style={{width:'51%'}}>Breakdown</Typography>
              <Typography className={classes.timeHeader} style={{width:'21%'}}>%</Typography>
            </Grid>

            {rRSTotals.extraAlerts!=0 ? <Grid item style={{display:'flex',height:40, borderRadius:4}}>
              <div className={classes.divLast}></div>
              <div style={{width:'100%',display:'flex'}}>
                <Typography className={classes.timeHeader} style={{fontWeight:500}}>{t('extraAlerts')}</Typography>
                <Typography className={classes.timeData} style={{fontWeight:500}}></Typography>
                <Typography className={classes.timePercent} style={{fontWeight:500}}>{rRSTotals.extraAlertsPercent} {'%'}</Typography>
              </div>
            </Grid>:null}

            {rRSTotals.totalApplicationsTested!=0 ? <Grid item style={{display:'flex',height:40, borderRadius:4}}>
              <div className={classes.divLast}></div>
              <div style={{width:'100%',display:'flex'}}>
                <Typography className={classes.timeHeader}>{t('apps')}</Typography>
                <Typography className={classes.timeData}> {rRSTotals.totalOKApplications} {'/'} {rRSTotals.totalApplicationsTested} {'Are Ok'}</Typography>
                <Typography className={classes.timePercent}>{rRSTotals.applicationsPercentage} {'%'}</Typography>
              </div>
            </Grid>:null}
            {rRSTotals.totalServersTested!=0 ? <Grid item style={{display:'flex',height:40, borderRadius:4}}>
              <div className={classes.divLast}></div>
              <div style={{width:'100%',display:'flex'}}>
                <Typography className={classes.timeHeader}>{t('servers')}</Typography>
                <Typography className={classes.timeData}> {rRSTotals.totalOKServers} {'/'} {rRSTotals.totalServersTested} {'Are Ok'}</Typography>
                <Typography className={classes.timePercent}>{rRSTotals.totalPercentage} {'%'}</Typography>
              </div>
            </Grid>:null}

            {rRSTotals.totalDBTested!=0 ? <Grid item style={{display:'flex',height:40, borderRadius:4}}>
              <div className={classes.divLast}></div>
              <div style={{width:'100%',display:'flex'}}>
                <Typography className={classes.timeHeader}>{t('dbs')}</Typography>
                <Typography className={classes.timeData}> {rRSTotals.totalOKDBs} {'/'} {rRSTotals.totalDBTested} {'Are Ok'}</Typography>
                <Typography className={classes.timePercent}>{rRSTotals.dBsPercentage} {'%'}</Typography>
              </div>
            </Grid>:null}

            {rRSTotals.totalAdvancedTested!=0 ? <Grid item style={{display:'flex',height:40, borderRadius:4}}>
              <div className={classes.divLast}></div>
              <div style={{width:'100%',display:'flex'}}>
                <Typography className={classes.timeHeader}>{t('advanced')}</Typography>
                <Typography className={classes.timeData}> {rRSTotals.totalOKAdvanced} {'/'} {rRSTotals.totalAdvancedTested} {'Are Ok'}</Typography>
                <Typography className={classes.timePercent}>{rRSTotals.advancedPercentage} {'%'}</Typography>
              </div>
            </Grid>:null}
            
            {rRSTotals.rto!=0 ? <Grid item style={{display:'flex',height:40, borderRadius:4}}>
              <div className={classes.divLast}></div>
              <div style={{width:'100%',display:'flex'}}>
                <Typography className={classes.timeHeader}>{t('rtaword')}</Typography>
                <Typography className={classes.timeData}> {rRSTotals.rta} {'min /'} {rRSTotals.rto} {'min'}</Typography>
                <Typography className={classes.timePercent}>{rRSTotals.rtaPercentage} {'%'}</Typography>
              </div>
            </Grid>:null}

            {rRSTotals.totalFirewallsTested!=0 ? <Grid item style={{display:'flex',height:40, borderRadius:4}}>
              <div className={classes.divLast}></div>
              <div style={{width:'100%',display:'flex'}}>
                <Typography className={classes.timeHeader}>{t('firewalls')}</Typography>
                <Typography className={classes.timeData}> {rRSTotals.totalOKFirewalls} {'/'} {rRSTotals.totalFirewallsTested} {'Are Ok'}</Typography>
                <Typography className={classes.timePercent}>{rRSTotals.totalPercentage} {'%'}</Typography>
              </div>
            </Grid>:null}

            

            {rRSTotals.totalNetworkDevicesTested!=0 ? <Grid item style={{display:'flex',height:40, borderRadius:4}}>
              <div className={classes.divLast}></div>
              <div style={{width:'100%',display:'flex'}}>
                <Typography className={classes.timeHeader}>{t('networkDevice')}</Typography>
                <Typography className={classes.timeData}> {rRSTotals.totalOKNetworkDevices} {'/'} {rRSTotals.totalNetworkDevicesTested} {'Are Ok'}</Typography>
                <Typography className={classes.timePercent}>{rRSTotals.networkDevicesPercentage} {'%'}</Typography>
              </div>
            </Grid>:null}

            {rRSTotals.totalBOTested!=0 ? <Grid item style={{display:'flex',height:40, borderRadius:4}}>
              <div className={classes.divLast}></div>
              <div style={{width:'100%',display:'flex'}}>
                <Typography className={classes.timeHeader}>{t('bo')}</Typography>
                <Typography className={classes.timeData}> {rRSTotals.totalOKBO} {'/'} {rRSTotals.totalBOTested} {'Are Ok'}</Typography>
                <Typography className={classes.timePercent}>{rRSTotals.boPercentage} {'%'}</Typography>
              </div>
            </Grid>:null}

            {rRSTotals.totalICTested!=0 ? <Grid item style={{display:'flex',height:40, borderRadius:4}}>
              <div className={classes.divLast}></div>
              <div style={{width:'100%',display:'flex'}}>
                <Typography className={classes.timeHeader}>{t('ic')}</Typography>
                <Typography className={classes.timeData}> {rRSTotals.totalOKIC} {'/'} {rRSTotals.totalICTested} {'Are Ok'}</Typography>
                <Typography className={classes.timePercent}>{rRSTotals.icPercentage} {'%'}</Typography>
              </div>
            </Grid>:null}

            {rRSTotals.totalRAM!=0 ? <Grid item style={{display:'flex',height:40, borderRadius:4}}>
              <div className={classes.divLast}></div>
              <div style={{width:'100%',display:'flex'}}>
                <Typography className={classes.timeHeader}>{t('RAMHeader')}</Typography>
                <Typography className={classes.timeData}> {rRSTotals.totalRAMUsage} {'/'} {rRSTotals.totalRAM} {'Used'}</Typography>
                <Typography className={classes.timePercent}>{rRSTotals.ramPercentage} {'%'}</Typography>
              </div>
            </Grid>:null}  

            {rRSTotals.totalCPU!=0 ? <Grid item style={{display:'flex',height:40, borderRadius:4}}>
              <div className={classes.divLast}></div>
              <div style={{width:'100%',display:'flex'}}>
                <Typography className={classes.timeHeader}>{t('CPUHeader')}</Typography>
                <Typography className={classes.timeData}> {rRSTotals.totalCPUUsage} {'/'} {rRSTotals.totalCPU} {'Used'}</Typography>
                <Typography className={classes.timePercent}>{rRSTotals.cpuPercentage} {'%'}</Typography>
              </div>
            </Grid>:null}

            {rRSTotals.totalIOPS!=0 ? <Grid item style={{display:'flex',height:40, borderRadius:4}}>
              <div className={classes.divLast}></div>
              <div style={{width:'100%',display:'flex'}}>
                <Typography className={classes.timeHeader}>{t('IOPSHeader')}</Typography>
                <Typography className={classes.timeData}> {rRSTotals.totalIOPSUsage} {'/'} {rRSTotals.totalIOPS} {'Used'}</Typography>
                <Typography className={classes.timePercent}>{rRSTotals.iopsPercentage} {'%'}</Typography>
              </div>
            </Grid>:null}

            

            <Grid item style={{display:'flex',height:40,boxShadow: '0 8px 12px 0 rgba(31, 57, 109, 0.05)',backgroundColor:'rgba(40,146,215,0.15)', borderRadius:4}}>
              <div className={classes.divLast}></div>
              <div style={{width:'100%',display:'flex'}}>
                <Typography className={classes.timeHeader}>{t('total')}</Typography>
                <Typography className={classes.timeData}> </Typography>
                <Typography className={classes.timePercent}>{rRSTotals.rrs} {'%'}</Typography>
              </div>
            </Grid>
                  
            </div>
          : <Typography variant="body1" style={{fontWeight:500}}>{t('noRRSdataAvailable')}</Typography>}
          </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{padding:16}}>
            <Button onClick={handleClose} color="primary" style={{textTransform:'none'}} className={classes.close_button}>
                {t('close')}
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RRSDialog;
