
import React from 'react'
import { makeStyles, Typography } from '@material-ui/core';
import ArrowUp from '@material-ui/icons/ArrowDropUp';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import { useTranslation } from 'react-i18next';

type TrendIndicatorProps = {
    value: number,
    fontSize?: string,
    showText?: boolean,
    trendUpLabel? : string,
    trendDownLabel?: string
}

const useStyles = makeStyles({
    trendUp: {
        color: "#8bc53f",
        display: "flex",
        alignItems: "center"
    },
    trendDown: {
        color: "#077187",
        display: "flex",
        alignItems: "center"
    },
    noChange: {
        color: "gray",
        display: "flex",
        alignItems: "center"
    }
})
export const TrendIndicator: React.FC<TrendIndicatorProps> = (props) => {
    const classes = useStyles();
    const [t] = useTranslation("report");
    return (
        props.value ==0 ? <span className={classes.noChange}>
            <ArrowUp style={{color: 'transparent'}}/> 
        {props.showText && <Typography variant="body2">{t("common.noChange")} </Typography>}
    </span>:
        props.value >= 0 ? <span className={classes.trendUp}>
            <ArrowUp /> {props.showText && <Typography variant="body2">{props.value}%  {props.trendUpLabel || t("common.better")} </Typography>}
        </span> :
            <span className={classes.trendDown}>
                <ArrowDown /> {props.showText && <Typography variant="body2">{-1 * props.value}% {props.trendDownLabel || t("common.worse")}</Typography>}
            </span>

    )
}
