import React, { useRef } from 'react'
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

interface IProps {
    setImage: (file: Blob) => void;
    imagePreview: string;
}

const PhotoWidgetCropper: React.FC<IProps> = ({setImage, imagePreview}) => {
    const cropper = useRef<Cropper>(null);

    const cropImage = () => {
        if (
            cropper.current && 
                typeof cropper.current.getCroppedCanvas() === 'undefined'
            ) {
            return;
        }
            cropper &&
            cropper.current &&
            cropper.current.getCroppedCanvas().toBlob((blob:any) => {
                setImage(blob);
            }, 'image/png');
        }


    return (
        <Cropper 
        ref={cropper}
        src={imagePreview}
        style={{height: 200, width: '100%'}}
        // Cropper.js options
        aspectRatio={2 / 1}
        preview='.img-preview'
        guides={false} 
        viewMode={0}
        dragMode='move'
        scalable={true}
        cropBoxMovable={true}
        crop={cropImage}
        />
    );
}

export default PhotoWidgetCropper;
