import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/root.store';
import { Button, Card, CardActions, Dialog, DialogContent, DialogTitle, Divider, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { EDRDialogTitle } from "../../app/common/EDRDialogTitle";
import { DataMover, DATA_MOVER_CONFIGURATION_NAME } from '../../app/models/BaseSettings.enum';
import { InfrastructureType } from '../../app/models/jobProperties.model';

import { history } from '../../app/common/util/history';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import jobAgent from '../../app/api/jobAgent';
import { runInAction } from 'mobx';
import JobSettingsStore from '../../app/stores/jobSettings.store';
import DraftsAgent from '../../app/api/draftsAgent';
import { CreateJobProperties } from '../../app/models/createjob/createJob.model';
import { DraftState } from '../../app/models/draft.model';
import ConfirmationDialog from '../../app/common/confirmationDialog.component';
import { useMatomo } from '@datapunt/matomo-tracker-react';

type props = {
    onClose: () => void;
    open: boolean;
}

type DataMoverCategory = "backup"|"replication"|"azure"|"gcp"|"AWS"|"application"|"storage"

const DATAMOVER_CONFIG: Array<{datamover: DataMover, name?: string, enabled: boolean, type: Array<DataMoverCategory>, infra: InfrastructureType}> = [
    {
        datamover: DataMover.SRM,
        enabled: true,
        type:["replication"],
        infra: InfrastructureType.VMware
    },
    {
        datamover: DataMover.ZertoVmWare,
        enabled: true,
        type:["replication"],
        infra: InfrastructureType.VMware
    },
    {
        datamover: DataMover.Recoverpoint,
        enabled: true,
        type:["replication"],
        infra: InfrastructureType.VMware
    },
    {
        datamover: DataMover.ASR,
        enabled: true,
        type:["replication","azure"],
        infra: InfrastructureType.Azure
    },
    {
        datamover: DataMover.VeeamVmware,
        enabled: true,
        type:["replication", "backup"],
        infra: InfrastructureType.VMware
    },
    {
        datamover: DataMover.CohesityRunbook,
        enabled: true,
        type:["replication","backup"],
        infra: InfrastructureType.VMware
    },
    {
        datamover: DataMover.Cohesity,
        enabled: false,
        type:["replication", "backup"],
        infra: InfrastructureType.VMware
    },
    {
        datamover: DataMover.VeeamSQL,
        enabled: true,
        type:["backup"],
        infra: InfrastructureType.VMware
    },
    {
        datamover: DataMover.Netapp,
        enabled: true,
        type:["storage"],
        infra: InfrastructureType.VMware
    },
    {
        datamover: DataMover.PureStorage,
        enabled: false,
        type:["storage"],
        infra: InfrastructureType.VMware
    },
    {
        datamover: DataMover.NetappNAS,
        enabled: false,
        type:["storage"],
        infra: InfrastructureType.VMware
    },
    {
        datamover: DataMover.AWSDRS,
        enabled: false,
        type:["AWS"],
        infra: InfrastructureType.AWS
    },
    {
        datamover: DataMover.CustomAWS,
        enabled: false,
        type:["storage"],
        infra: InfrastructureType.AWS
    }
] 

export const loadIcon = (dataMover: DataMover, clickable: boolean) => {
    switch (dataMover) {
        case DataMover.SRM:
            {
                return clickable ? '/assets/images/platformIcons/srm.svg' : '/assets/images/platformIcons/srmGray.svg';
            }
        case DataMover.ZertoVmWare:
            {
                return clickable ? '/assets/images/platformIcons/zertoVmware.svg' : '/assets/images/platformIcons/zertoVmwareGray.svg';
            }
        case DataMover.ZertoAzure:
            {
                return clickable ? '/assets/images/platformIcons/zertoAzure.svg' : '/assets/images/platformIcons/zertoAzureGray.svg';
            }
        case DataMover.IaaS:
        case DataMover.PaaS:
            {
                return clickable ? '/assets/images/platformIcons/azure.svg' : '/assets/images/platformIcons/zertoAzureGray.svg';
            }
        case DataMover.ASR:
            {
                return clickable ? '/assets/images/platformIcons/asr.svg' : '/assets/images/platformIcons/asrGray.svg';
            }
        case DataMover.Netapp:
            {
                return clickable ? '/assets/images/platformIcons/netapp.svg' : '/assets/images/platformIcons/netappGray.svg';
            }
        case DataMover.Carbonite:
            {
                return clickable ? '/assets/images/platformIcons/carbonite.svg' : '/assets/images/platformIcons/carboniteGray.svg';
            }
        case DataMover.Recoverpoint:
            {
                return clickable ? '/assets/images/platformIcons/recoverpoint.svg' : '/assets/images/platformIcons/recoverpointGray.svg';
            }
        case DataMover.VmwareVc:
            {
                return clickable ? '/assets/images/platformIcons/vcenter.svg' : '/assets/images/platformIcons/vcenterGray.svg';
            }
        case DataMover.VeeamSQL:
        case DataMover.VeeamVmware:
        {
            return  clickable ? '/assets/images/platformIcons/veeam.svg':'/assets/images/platformIcons/veeam.svg';
        }
        case DataMover.CustomVmware:
        {
            return  clickable ? '/assets/images/platformIcons/custom.svg':'/assets/images/platformIcons/customGray.svg';
        }
        case DataMover.CustomAWS:
        {
            return  clickable ? '/assets/images/platformIcons/customAWS.svg':'/assets/images/platformIcons/customAWSGray.svg';
        }
        case DataMover.CustomAzure:
        {
            return  clickable ? '/assets/images/platformIcons/customAzure.svg':'/assets/images/platformIcons/customAzureGray.svg';
        }
        case DataMover.CustomGCP:
        {
            return  clickable ? '/assets/images/platformIcons/customGCP.svg':'/assets/images/platformIcons/customGCPGray.svg';
        }
        case DataMover.Commvault:
                {
                    return  clickable ? '/assets/images/platformIcons/commvault.svg':'/assets/images/platformIcons/commvaultGray.svg';
                }
        case DataMover.Cohesity:
            {
                return clickable ? '/assets/images/platformIcons/cohecity.svg' : '/assets/images/platformIcons/cohecityGray.svg';
            }
        case DataMover.CohesityRunbook:
            {
                return clickable ? '/assets/images/platformIcons/cohesityRunbook.svg' : '/assets/images/platformIcons/cohesityRunbookGray.svg';
            }
        case DataMover.Rubrik:
            {
                return clickable ? '/assets/images/platformIcons/rubrik.svg' : '/assets/images/platformIcons/rubrikGray.svg';
            }
        case DataMover.ZertoVmwareAzure:
            {
                return clickable ? '/assets/images/platformIcons/zertoVmwareAzure.svg' : '/assets/images/platformIcons/zertoVmwareAzureGray.svg';
            }
        case DataMover.ASRVmwareAzure:
        {
            return  clickable ? '/assets/images/platformIcons/asrVmwareAzure.svg':'/assets/images/platformIcons/asrVmwareAzureGray.svg';
        }
        case DataMover.AWSDRS:
        {
            return clickable ? '/assets/images/platformIcons/awsDRS.svg':'/assets/images/platformIcons/awsDRSGray.svg';
        }
        case DataMover.VmwareAWS:
        {
            return clickable ? '/assets/images/platformIcons/vmwareAWS.svg':'/assets/images/platformIcons/vmwareAWSGray.svg';
        }
        case DataMover.AIX:
        {
            return clickable ? '/assets/images/platformIcons/aix.svg':'/assets/images/platformIcons/aixGray.svg';
        }
        case DataMover.PureStorage:
        {
            return clickable ? '/assets/images/platformIcons/pureStorage.svg':'/assets/images/platformIcons/pureStorageGray.svg';
        }
        case DataMover.SapHana:
        {
            return clickable ? '/assets/images/platformIcons/sap.svg':'/assets/images/platformIcons/sapGray.svg';
        }
        case DataMover.Oracle:
        {
            return clickable ? '/assets/images/platformIcons/oracle.svg':'/assets/images/platformIcons/oracleGray.svg';
        }
        case DataMover.MSSqlServerAzure:
        {
            return clickable ? '/assets/images/platformIcons/mssqlAzure.svg':'/assets/images/platformIcons/mssqlAzureGray.svg';
        }
        case DataMover.MSSqlServer:
        {
            return clickable ? '/assets/images/platformIcons/mssqlVmware.svg':'/assets/images/platformIcons/mssqlVmwareGray.svg';
        }
        case DataMover.NetappNAS:
        {
            return clickable ? '/assets/images/platformIcons/netappnas.svg':'/assets/images/platformIcons/netappnasGray.svg';
        }
    }
}

const loadInfrastructure = (datamover: DataMover) => {
    switch (datamover) {
        case DataMover.AWSDRS:
        case DataMover.VmwareAWS:
        case DataMover.CustomAWS:
            {
                return InfrastructureType.AWS;
            }
        case DataMover.AIX:
            {
                return InfrastructureType.Physical;
            }
        case DataMover.ZertoVmwareAzure:
        case DataMover.ASRVmwareAzure:
            {
                return InfrastructureType.VmwareAzure;
            }
        case DataMover.CustomGCP:
            {
                return InfrastructureType.GCP;
            }
        case DataMover.IaaS:
        case DataMover.PaaS:
        case DataMover.ZertoAzure:
        case DataMover.ASR:
        case DataMover.MSSqlServerAzure:
        case DataMover.CustomAzure:
            {
                return InfrastructureType.Azure;
            }
        default: return InfrastructureType.VMware;
    }
}
const CreateJobConfigurationComponent : React.FC<props>= ({onClose,open}) => {
    
    const fullAll=Object.keys(DataMover);
    const fullAWS =[DataMover[DataMover.VmwareAWS],DataMover[DataMover.AWSDRS],DataMover[DataMover.CustomAWS]];
    const fullGCP =[DataMover[DataMover.CustomGCP]];
    const fullReplication=[DataMover[DataMover.SRM],DataMover[DataMover.ZertoVmWare],DataMover[DataMover.Recoverpoint],DataMover[DataMover.ASR],DataMover[DataMover.VeeamVmware],DataMover[DataMover.CohesityRunbook],DataMover[DataMover.Cohesity],DataMover[DataMover.ASRVmwareAzure],DataMover[DataMover.ZertoAzure],DataMover[DataMover.ZertoVmwareAzure],DataMover[DataMover.Carbonite],DataMover[DataMover.VmwareVc],DataMover[DataMover.CustomVmware],DataMover[DataMover.Rubrik]];
    const fullBackup=[DataMover[DataMover.Cohesity],DataMover[DataMover.CohesityRunbook],DataMover[DataMover.VeeamVmware],DataMover[DataMover.VeeamSQL],DataMover[DataMover.Rubrik],DataMover[DataMover.Commvault]];
    const fullAzure= [DataMover[DataMover.ASR],DataMover[DataMover.ASRVmwareAzure],DataMover[DataMover.PaaS],DataMover[DataMover.IaaS],DataMover[DataMover.ZertoAzure],DataMover[DataMover.ZertoVmwareAzure],DataMover[DataMover.MSSqlServerAzure],DataMover[DataMover.CustomAzure]];
    const fullApplication = [DataMover[DataMover.MSSqlServerAzure],DataMover[DataMover.MSSqlServer],DataMover[DataMover.AIX],DataMover[DataMover.SapHana],DataMover[DataMover.Oracle],DataMover[DataMover.CustomVmware]];
    const fullStorage = [DataMover[DataMover.Netapp],DataMover[DataMover.PureStorage],DataMover[DataMover.NetappNAS]];
    const partialAll=[DataMover[DataMover.SRM],DataMover[DataMover.ZertoVmWare],DataMover[DataMover.Recoverpoint],DataMover[DataMover.ASR],DataMover[DataMover.Netapp],DataMover[DataMover.VeeamVmware],DataMover[DataMover.AWSDRS],DataMover[DataMover.CohesityRunbook]];
    const partialReplication=[DataMover[DataMover.SRM],DataMover[DataMover.ZertoVmWare],DataMover[DataMover.Recoverpoint],DataMover[DataMover.ASR],DataMover[DataMover.VeeamVmware],DataMover[DataMover.CohesityRunbook],DataMover[DataMover.Cohesity]];
    const partialBackup=fullBackup;
    const partialAzure=fullAzure;
    const partialAWS=fullAWS;
    const partialApplication = fullApplication;
    const partialStorage = fullStorage;
    const partialGCP = fullGCP

    const rootStore = useContext(RootStoreContext);
    const { displayStore } = rootStore;
    const commonStroe = rootStore.commonStore;
    const { jobSettingsStore } = rootStore;
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const [selectedTab, setSelectedTab] = React.useState("all");
    const [itemsToDisplay, setItemsToDisplay] = useState(partialAll);
    const [openConfirmationDialog, setopenConfirmationDialog] = useState(false);
    const [datamover, setDatamover] = useState<DataMover>();
    const [infra, setInfra] = useState<InfrastructureType>();
    const [t] = useTranslation("createJobConfiguration");
    const { trackEvent} = useMatomo(); 

    const useStyles = makeStyles({
        root: {
            minWidth: '33vw',
        },
        card: {
            padding: 12,
            height: (selectedTab === "backup" || selectedTab === "azure") ? '353px' : 'auto',
            marginLeft: '0px'
        },
        miniHeader: {
            fontFamily: 'Roboto',
            fontSize: '13px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.14,
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#1f396d'
        },
        container: {
            borderradius: '4px',
            border: 'solid 1px #eaeef4',
            backgroundcolor: 'var(--white)',
            gridColumn: 'auto auto auto',
            GridRow: '200px 200px',
            gridGap: '10px',
            marginBottom: '10px',
            "&:hover": {
                backgroundColor: '#cce2ff'
            }
        },
        containerNoHover: {
            borderradius: '4px',
            border: 'solid 1px #eaeef4',
            backgroundcolor: 'var(--white)',
            gridColumn: 'auto auto auto',
            GridRow: '200px 200px',
            gridGap: '10px',
            marginBottom: '10px',
        },
        label: {
            textTransform: 'capitalize',
          },
        topScrollPaper: {
            alignItems: 'flex-start',
        },
        topPaperScrollBody: {
            verticalAlign: 'top',
        }
    });
    const classes = useStyles(); 

    const setSelection = async (datamover: DataMover) => {
        runInAction(() => {
            rootStore.createJobStore.reset();
            rootStore.jobSettingsStore.reset();
            displayStore.reset();
            rootStore.createJobStore.updateBaseSettings({ dataMover: datamover });
            rootStore.createJobStore.updateBaseSettings({ fetchByDefault: datamover == DataMover.VeeamSQL ? false:true });
            rootStore.createJobStore.updateBaseSettings({ infrastructureType: loadInfrastructure(datamover) });
            let newJob;
            rootStore.createJobStore.getNewJob({ infrastructureType: loadInfrastructure(datamover), dataMoverType: datamover }).then(res => {
                newJob = res;
            });
            commonStroe.setValueSelected();
            displayStore.updateSectionSettings({ WorkflowSection: true, platformSection: true, serverDRSection: true });
            let prefix = DATA_MOVER_CONFIGURATION_NAME[rootStore.createJobStore.baseSettings.dataMover] + "_" + InfrastructureType[rootStore.createJobStore.baseSettings.infrastructureType] + "_Job_";
            jobAgent.JobActions.NextJobIdAvilable(prefix).then(res => {

                displayStore.updateScreenSettings({ EDRApplyProcessing: true, EDRCApplyProcessing: true, DCApplyProcessing: true });
                jobSettingsStore.getAssets().finally(() => {
                    displayStore.updateScreenSettings({ EDRApplyProcessing: false, EDRCApplyProcessing: false, DCApplyProcessing: false });
                    displayStore.updateNextStepValid({ networkSettingValid: true });
                })
                rootStore.createJobStore.updateBaseSettings({ jobName: res });
                displayStore.updateNetworkSectionSettings({ DisableSection: false });
                let jobProperties = newJob == null || newJob == '' ? new CreateJobProperties() : newJob;
                jobProperties.jobName = res;
                jobProperties.dataMoverId = rootStore.createJobStore.baseSettings.dataMoverId;
                jobProperties.infrastructureId = rootStore.createJobStore.baseSettings.infrastructureId;
                DraftsAgent.Create({id: 0,jobProperties:jobProperties,jobStep:0,draftState: DraftState.InProgress}).then(res => {
                    rootStore.createJobStore.setDraftId(res.data);
                })
                onClose();
                history.push('/createJob')
            }).catch(error => {
                rootStore.createJobStore.updateBaseSettings({ jobName: DATA_MOVER_CONFIGURATION_NAME[rootStore.createJobStore.baseSettings.dataMover] + "_" + InfrastructureType[rootStore.createJobStore.baseSettings.infrastructureType] + "_Job_" + "TempName" });
                onClose();
                history.push('/createJob')
            })
        });
    }

    const onExitChange = () => {
        onClose();
    }

    const ClickableOption = (datamover:DataMover)=> {
        return true;
        /*if(datamover== DataMover.Netapp || datamover== DataMover.AWSDRS || datamover== DataMover.Recoverpoint || datamover== DataMover.ZertoVmWare || datamover == DataMover.SRM || datamover == DataMover.VeeamVmware || datamover == DataMover.VeeamSQL || datamover == DataMover.CohesityRunbook || datamover == DataMover.ASR)
        //    return true;
        //else
           return false;*/
    }

    const handleClickPlatform =(datamover:DataMover,infra:InfrastructureType)=>{
        setDatamover(datamover);
        setInfra(infra);
        trackEvent({
            category: 'Configuration',
            action : 'Select Platform',
            name: loadName(infra,datamover)
        })
        setopenConfirmationDialog(true);
    }

    const loadOptions = (itemsToDisplay: string[]) => {
        let containerItems = [];
        itemsToDisplay.filter(k => typeof DataMover[k as any] === "number").map(key => {
            let datamover = DataMover[key];
            let infra = loadInfrastructure(datamover);
            let clickable = ClickableOption(datamover);
            containerItems.push(
                <Grid item xs={3} key={datamover} style={{ width: '100%' }}>
                    <Grid item container direction="row" style={{ width: '100%', justifyContent: 'center', alignItems: 'center', cursor: clickable ? 'pointer' : 'auto', backgroundColor: clickable ? '#fffff' : '#e9ebee!important' }} className={ClickableOption(datamover) ? classes.container : classes.containerNoHover} onClick={() => ClickableOption(datamover) ? handleClickPlatform(datamover,infra): null}>
                        <div>
                            <img src={loadIcon(datamover, clickable)} style={{ marginTop: '10px', color: 'rgba(0, 0, 0, 0.38)', height: 40 }} />
                        </div>

                        <div style={{ borderTop: '1px #eaeef4 solid', height: '50%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: clickable ? '#fafafc' : '#e9ebee!important' }}>
                            <Typography className={classes.miniHeader} style={{ marginTop: '10px', color: clickable ? '#1f396d' : 'rgba(0, 0, 0, 0.38)' }}>{datamover === DataMover.VmwareAWS ? "DRS" : DATA_MOVER_CONFIGURATION_NAME[datamover]}</Typography>
                            <Typography className={classes.miniHeader} style={{ color: clickable ? '#8092a9' : 'rgba(0, 0, 0, 0.38)', fontSize: '12px', fontWeight: 0 }}>{"x"}</Typography>
                            <Typography className={classes.miniHeader} style={{ marginBottom: '10px', color: clickable ? '#1f396d' : 'rgba(0, 0, 0, 0.38)' }}>{infra === InfrastructureType.VmwareAzure ? "VMware > Azure" : (datamover === DataMover.VmwareAWS ? "VMware > AWS" : datamover === DataMover.NetappNAS ? "Netapp" : InfrastructureType[infra].toString())}</Typography>
                        </div>
                    </Grid>
                </Grid>
            );
        });
        return containerItems;
    }

    const onHandleTabChange = (event, value) => {
        let arr;
        setSelectedTab(event.currentTarget.value);
        switch (event.currentTarget.value) {
            case "all":
            {
                arr=partialAll;
                break;
            }
        case "replication":
            {
                arr=partialReplication;
                break;
            }
        case "backup":
            {
                arr=partialBackup;
                break;
            }
        case "azure":
            {
                arr =partialAzure;
                break;
            }
        case "aws":
            {
                arr =partialAWS;
                break;
            }
        case "application":
            {
               arr =partialApplication;
               break;
           }
       case "storage":
           {
               arr =partialStorage;
               break;
            }
        case "gcp":
            {
                arr =partialGCP;
                break;
            }
        }
        setItemsToDisplay(arr);
    };

    const ExpandToAllItems = () => {
        let arr;
        switch (selectedTab) {
            case "all":
                {
                    arr = fullAll;
                    break;
                }
            case "replication":
                {
                    arr = fullReplication;
                    break;
                }
            case "backup":
                {
                    arr = fullBackup;
                    break;
                }
            case "azure":
                {
                    arr = fullAzure;
                    break;
                }
            case "aws":
                {
                    arr = fullAWS;
                    break;
                }
            case "application":
                {
                    arr = fullApplication;
                    break;
                }
            case "application":
                {
                    arr = fullStorage;
                    break;
                }
            case "gcp":
                {
                    arr = fullGCP;
                    break;
                }
            case "aws":
                {
                    arr=fullAWS;
                    break;
                }
            case "application":
                {
                    arr=fullApplication;
                    break;
                }
            case "storage":
                {
                    arr= fullStorage;
                    break;
                }
            case "gcp":
                {
                    arr=fullGCP;
                    break;
                }
        }
        setItemsToDisplay(arr);
    }

    const ShrinkToMinItems = () => {
        let arr;
        switch (selectedTab) {
            case "all":
                {
                    arr = partialAll;
                    break;
                }
            case "replication":
                {
                    arr = partialReplication;
                    break;
                }
            case "backup":
                {
                    arr = partialBackup;
                    break;
                }
            case "azure":
                {
                    arr = partialAzure;
                    break;
                }
            case "aws":
                {
                    arr = partialAWS;
                    break;
                }
            case "gcp":
                {
                    arr = partialGCP;
                    break;
                }
            case "aws":
                {
                    arr=partialAWS;
                    break;
                }
            case "application":
                {
                    arr =partialApplication;
                    break;
                }
            case "storage":
                {
                    arr =partialStorage;
                    break;
                }
            case "gcp":
                {
                    arr =partialGCP;
                    break;
                }
        }
        setItemsToDisplay(arr);
    }

    const loadPlatformName =()=>{
        if(datamover!=undefined && infra!=undefined)
        {
            let datamoverName = datamover === DataMover.VmwareAWS ? "DRS" : DATA_MOVER_CONFIGURATION_NAME[datamover];
            let infraName = infra === InfrastructureType.VmwareAzure ? "VMware > Azure" : (datamover === DataMover.VmwareAWS ? "VMware > AWS" : datamover === DataMover.NetappNAS ? "Netapp" : InfrastructureType[infra].toString());
            return datamoverName + " x "+infraName;
        }
        else
            return "";
    }

    const loadName =(infra:InfrastructureType,datamover:DataMover)=>{
        if(datamover!=undefined && infra!=undefined)
        {
            let datamoverName = datamover === DataMover.VmwareAWS ? "DRS" : DATA_MOVER_CONFIGURATION_NAME[datamover];
            let infraName = infra === InfrastructureType.VmwareAzure ? "VMware > Azure" : (datamover === DataMover.VmwareAWS ? "VMware > AWS" : datamover === DataMover.NetappNAS ? "Netapp" : InfrastructureType[infra].toString());
            return datamoverName + " x "+infraName;
        }
        else
            return "";
    }

    const handleClosePopup =()=>{
        onClose();
        setopenConfirmationDialog(false);
    }


      return <Dialog
      classes={{ paper: classes.root ,
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody}}
      open={open}
      fullScreen={matchesSM}
      onClose={()=>onExitChange()}
      scroll="paper"
    >

        <EDRDialogTitle>{t("createJob") + " : " + t("selectPlatform")}</EDRDialogTitle>
        <DialogContent
            style={{minWidth: 600, minHeight: 460}}
        >

            <Grid container justify="center" direction="column" style={{ padding: '25px 25px', boxSizing: 'border-box' }}>
                <ToggleButtonGroup
                classes={{ root: "toggle_button_group" }}
                value={selectedTab}
                onChange={onHandleTabChange}
                >
                <ToggleButton
                classes={{ root: "toggle_button", selected: "toggle_button_selected", label: classes.label }}
                value="all">
                {t("All")}
                </ToggleButton>
                <ToggleButton
                classes={{ root: "toggle_button", selected: "toggle_button_selected", label: classes.label }}
                value="replication">
                {t("Replication")}
                 </ToggleButton>
                 <ToggleButton
                classes={{ root: "toggle_button", selected: "toggle_button_selected", label: classes.label }}
                value="backup">
                {t("Backup")}
                 </ToggleButton>
                 <ToggleButton
                classes={{ root: "toggle_button", selected: "toggle_button_selected", label: classes.label }}
               value="application">
               {t("Application")}
                 </ToggleButton>
                 <ToggleButton
                classes={{ root: "toggle_button", selected: "toggle_button_selected", label: classes.label }}
                value="storage">
                {t("Storage")}
                 </ToggleButton>
                 <ToggleButton
                classes={{ root: "toggle_button", selected: "toggle_button_selected", label: classes.label }}
                value="aws">
                {t("AWS")}
                 </ToggleButton>
                 <ToggleButton
                classes={{ root: "toggle_button", selected: "toggle_button_selected", label: classes.label }}
                value="azure">
                {t("Azure")}
                 </ToggleButton>
                 <ToggleButton
                classes={{ root: "toggle_button", selected: "toggle_button_selected", label: classes.label }}
                value="gcp">
                {t("GCP")}
                 </ToggleButton>
                </ToggleButtonGroup>

                <Grid container direction="row" classes={{ root: clsx([classes.card]) }}>
                    <Grid item container direction="row" spacing={2} >
                        {loadOptions(itemsToDisplay)}
                    </Grid>
                </Grid>

                {itemsToDisplay.length == 8 ? <Button size="small" color="primary" onClick={ExpandToAllItems}>{t("show more")}</Button> : null}
                {itemsToDisplay.length > 8 ? <Button size="small" color="primary" onClick={ShrinkToMinItems}>{t("show less")}</Button> : null}

            </Grid>
        </DialogContent>
        <ConfirmationDialog type="interestedIn" device={null} open={openConfirmationDialog} onClose={()=> handleClosePopup()} children={"Interested in "+ loadPlatformName() +" solution? Click the question mark icon on the top right to learn more on EnsureDR - or simply go to EnsureDR.com and reach out to us"}></ConfirmationDialog>
    </Dialog>

}

export default observer(CreateJobConfigurationComponent);
export {loadInfrastructure}